/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyExecutionState from 'components/EmptyExecutionState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import { isEmpty } from 'utils/isObjectEmpty';
import { labelStatus } from 'utils/handlerStatus';

import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

const Sintegra = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  return (
    <DataSourceCardCollapsePanel
      title={translate(
        'components.dataSourceCards.laborInformation.companies.components.sintegra.index.sintegra.columnLabel.title'
      )}
      customId="labor-infos-component"
      icon="caf-ic_awards"
      toPrint={toPrint}
    >
      <ColumnLabel
        title={translate(
          'components.dataSourceCards.laborInformation.companies.components.sintegra.index.sintegra.columnLabel.title'
        )}
        type={parsedData && labelStatus(parsedData?.stateRegistration)?.type}
        description={
          parsedData &&
          translate(
            labelStatus(parsedData?.stateRegistration, parsedData?.stateRegistration)
              ?.description
          )
        }
      >
        {queryWorked ? (
          <>
            {parsedData && !isEmpty(parsedData) && (
              <span>
                {translate(
                  'components.dataSourceCards.laborInformation.companies.components.sintegra.index.sintegra.columnLabel.querySucceeded.hasData.text'
                )}
                :{' '}
                {parsedData?.stateRegistration ||
                  translate(
                    'components.dataSourceCards.laborInformation.companies.components.sintegra.index.sintegra.columnLabel.querySucceeded.hasData.empty'
                  )}
              </span>
            )}

            {parsedData && isEmpty(parsedData) && (
              <span>
                {translate(
                  'components.dataSourceCards.laborInformation.companies.components.sintegra.index.sintegra.columnLabel.querySucceeded.hasNotData'
                )}
              </span>
            )}
          </>
        ) : (
          <EmptyExecutionState
            statusCode={statusCode}
            message={source?.message || parsedData?.message}
          />
        )}
      </ColumnLabel>
    </DataSourceCardCollapsePanel>
  );
};

export default Sintegra;
