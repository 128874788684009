const eyeAndHairTranslator = (info) => {
  if (!info) return '-';

  switch (info.toUpperCase()) {
    case 'DARK BROWN':
      return 'pages.private.profiles.components.dataSourceCards.restrictiveLists.utils.handlerSanctions.eyeAndHairTranslator.DARK_BROWN';
    case 'LIGHT BROWN':
      return 'pages.private.profiles.components.dataSourceCards.restrictiveLists.utils.handlerSanctions.eyeAndHairTranslator.LIGHT_BROWN';
    case 'BROWN':
      return 'pages.private.profiles.components.dataSourceCards.restrictiveLists.utils.handlerSanctions.eyeAndHairTranslator.BROWN';
    case 'BLACK':
      return 'pages.private.profiles.components.dataSourceCards.restrictiveLists.utils.handlerSanctions.eyeAndHairTranslator.BLACK';
    case 'BLOND':
      return 'pages.private.profiles.components.dataSourceCards.restrictiveLists.utils.handlerSanctions.eyeAndHairTranslator.BLOND';
    case 'RED':
      return 'pages.private.profiles.components.dataSourceCards.restrictiveLists.utils.handlerSanctions.eyeAndHairTranslator.RED';
    case 'BLUE':
      return 'pages.private.profiles.components.dataSourceCards.restrictiveLists.utils.handlerSanctions.eyeAndHairTranslator.BLUE';
    case 'GREEN':
      return 'pages.private.profiles.components.dataSourceCards.restrictiveLists.utils.handlerSanctions.eyeAndHairTranslator.GREEN';
    default:
      return info.toUpperCase();
  }
};

const sanctionType = (info) => {
  if (!info) return '-';

  switch (info.toUpperCase()) {
    case 'LAW ENFORCEMENT':
      return 'pages.private.profiles.components.dataSourceCards.restrictiveLists.utils.handlerSanctions.sanctionType.LAW_ENFORCEMENT';
    case 'MONEY LAUNDERING':
      return 'pages.private.profiles.components.dataSourceCards.restrictiveLists.utils.handlerSanctions.sanctionType.MONEY_LAUNDERING';
    case 'SUSPENSION ALERT':
      return 'pages.private.profiles.components.dataSourceCards.restrictiveLists.utils.handlerSanctions.sanctionType.SUSPENSION_ALERT';
    default:
      return info.toUpperCase();
  }
};

const pepLevel = (info, translate) => {
  if (!info)
    return {
      description: translate(
        'pages.private.profiles.components.dataSourceCards.restrictiveLists.utils.handlerSanctions.pepLevel.hasNotInfo'
      ),
      icon: 'caf-ic_news',
    };

  if (info === '1') {
    return {
      description: translate(
        'pages.private.profiles.components.dataSourceCards.restrictiveLists.utils.handlerSanctions.pepLevel.firstDegreeOfKinship'
      ),
      icon: 'caf-ic-first',
    };
  }

  return {
    description: translate(
      'pages.private.profiles.components.dataSourceCards.restrictiveLists.utils.handlerSanctions.pepLevel.degreeOfKinship',
      { info }
    ),
    icon: 'caf-ic_git-branch',
  };
};

export { eyeAndHairTranslator, sanctionType, pepLevel };
