import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col, Typography, Divider, Tooltip } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import Card from 'components/Card/Standard';
import HiddenParagraph from 'components/HiddenParagraph';
import { findMobileProduct } from 'pages/private/AccessKeys/utils/mobileProducts';
import ActionsList from './components/ActionsList';

import './styles.less';

const { Text } = Typography;

const AccessKeysCard = ({ data, refreshList }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();
  return (
    <Card id="access-keys-card-component">
      <Row className="pdd-horizon-20 no-mrg pdd-vertical-20 flex center space-between">
        <div>
          <b className="no-mrg text-dark">
            {data?.keyName ||
              translate('pages.private.accessKeys.components.accessKeysCard.noName')}
          </b>
        </div>
        <ActionsList data={data} refreshListRef={refreshList} />
      </Row>
      <Divider className="no-mrg no-pdd" />
      <Row className="pdd-horizon-20 no-mrg">
        <Col span={7} className="cells pdd-vertical-10">
          <b className="no-mrg text-dark">
            {translate('pages.private.accessKeys.components.accessKeysCard.clientId')}
          </b>
          <HiddenParagraph text={data?.clientId} copyable />
        </Col>
        <Col span={12} className="cells border-left pdd-vertical-10">
          <b className="no-mrg text-dark">
            {translate('pages.private.accessKeys.components.accessKeysCard.clientSecret')}
          </b>
          <HiddenParagraph text={data?.clientSecret} hidden copyable />
        </Col>
        <Col span={5} className="cells border-left pdd-vertical-10">
          <b className="no-mrg text-dark">
            {translate('pages.private.accessKeys.components.accessKeysCard.createdAt')}
          </b>
          <Text style={{ lineHeight: '34px' }}>
            {i18nFormatDate(data?.createdAt || new Date(), 'default')}
          </Text>
        </Col>
      </Row>

      <Divider className="no-mrg no-pdd" />
      <div className="mrg-vertical-10 pdd-horizon-20">
        {data?.products &&
          data.products.map((p) => {
            const product = findMobileProduct(p);
            return (
              <Tooltip
                key={product?.name}
                placement="top"
                title={translate(product?.description)}
              >
                <div className="validation-tag">
                  {product?.icon && (
                    <i className={`${product.icon} mrg-right-10 no-pdd`} />
                  )}
                  {translate(product?.title)}
                </div>
              </Tooltip>
            );
          })}
      </div>
    </Card>
  );
};

AccessKeysCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    clientId: PropTypes.string,
    clientSecret: PropTypes.string,
    tenantId: PropTypes.string,
    createdAt: PropTypes.string,
    policies: PropTypes.arrayOf(PropTypes.shape({})),
    keyName: PropTypes.string,
  }),
  refreshList: PropTypes.func.isRequired,
};

AccessKeysCard.defaultProps = {
  data: undefined,
};

export default AccessKeysCard;
