import React from 'react';
import { CircleTrue, CircleFalse, Less } from '@combateafraude/icons/general';

export const convertGlobalDocVerificationResultToIcon = ({
  checkResult,
  className = '',
}) => {
  let result;
  switch (checkResult) {
    case 0:
      result = <CircleFalse className={`gx-text-danger ${className}`} />;
      break;
    case 1:
      result = <CircleTrue className={`gx-text-success ${className}`} />;
      break;
    case 2:
      result = <Less className={className ? `${className}` : undefined} />;
      break;
    default:
      result = <Less className={className ? `${className}` : undefined} />;
      break;
  }
  return result;
};
