import React, { useState } from 'react';
import { ArrowRightOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Card, Tag, Collapse, Tooltip } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.less';

const { Panel } = Collapse;

const colors = {
  10: '#39c560',
  20: `#f8b239`,
  30: `#e21b45`,
};

const trustedTranslated = {
  10: 'pages.private.International.GDC.common.reliable',
  20: 'pages.private.International.GDC.common.sufficient',
  30: 'pages.private.International.GDC.common.unreliable',
};

const getColorByScore = (score) => {
  return colors[score] ? colors[score] : '#bbb';
};

const getTranslatedByScore = (score) => {
  return trustedTranslated[score] ? trustedTranslated[score] : 'Not found';
};

const tooltipContentStyles = {
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  dot: (color) => ({
    height: 10,
    width: 10,
    borderRadius: '50%',
    display: 'inline-block',
    backgroundColor: getColorByScore(color),
  }),
  text: {
    margin: '0.4rem',
  },
  greyBar: {
    borderRight: '1px solid grey',
    height: 16,
    margin: '0 0.4rem',
  },
};

const ToolTipColorsExplanation = ({ translate }) => {
  return (
    <div style={tooltipContentStyles.content}>
      <div>
        <div style={tooltipContentStyles.dot(10)} />
        <span style={tooltipContentStyles.text}>
          {translate('pages.private.International.GDC.common.reliable')}
        </span>
      </div>
      <div style={tooltipContentStyles.greyBar} />
      <div>
        <div style={tooltipContentStyles.dot(20)} />
        <span style={tooltipContentStyles.text}>
          {translate('pages.private.International.GDC.common.sufficient')}
        </span>
      </div>
      <div style={tooltipContentStyles.greyBar} />
      <div>
        <div style={tooltipContentStyles.dot(30)} />
        <span style={tooltipContentStyles.text}>
          {translate('pages.private.International.GDC.common.unreliable')}
        </span>
      </div>
    </div>
  );
};

const CardContent = ({ children, name, reliability }) => {
  const { t } = useTranslation();
  const [expandInfo, setExpandInfo] = useState(false);

  return (
    <Card className="content-user-verified">
      <div className="user-content">
        <i className="caf-ic_user_active content-icon" />
        <div className="user-text-info">
          <div className="user-info">
            <h4 className="user-name">{name}</h4>
            <Tag color={getColorByScore(reliability)}>
              {t(trustedTranslated[reliability])}
            </Tag>
          </div>
          <div className="user-reliability-resume">
            <Trans
              i18nKey="pages.private.International.GDC.common.result"
              style={{ marginRight: 10 }}
            >
              This result is deemed
              <strong>{{ trust: t(getTranslatedByScore(reliability)) }}</strong>
              based on the veracity of this variables.
            </Trans>
            <Tooltip
              placement="top"
              title={<ToolTipColorsExplanation translate={t} />}
              overlayStyle={{ maxWidth: '500px' }}
              className="tooltip-content"
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        </div>
      </div>
      <Collapse
        bordered={false}
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => <ArrowRightOutlined rotate={isActive ? 90 : 0} />}
        className="site-collapse-custom-collapse collapse-menu-data"
        onChange={() => setExpandInfo(!expandInfo)}
      >
        <Panel
          header={<strong>{expandInfo ? 'Expand Info' : 'Hide Info'}</strong>}
          key="1"
          className="site-collapse-custom-panel"
        >
          <p>{children}</p>
        </Panel>
      </Collapse>
    </Card>
  );
};

ToolTipColorsExplanation.propTypes = {
  translate: PropTypes.func.isRequired,
};

CardContent.propTypes = {
  name: PropTypes.string.isRequired,
  reliability: PropTypes.number.isRequired,
};

export default CardContent;
