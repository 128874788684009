import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import NotAvailable from 'components/NotAvailable';
import FinancialInfoCollapse from '../../components/Collapse';
import FinancialInfoTable from '../../components/Table';
import apiMap from './apiMap';

import './style.less';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companyDetails.subPages.financialInfo.tabs.accounts';

const Accounts = ({
  className,
  financialData,
  bordered,
  showArrow,
  collapseOpened,
  collapseKeyPrefix,
  print = false,
}) => {
  const { t: translate } = useTranslation();

  const {
    i18nFormatDateOnly,
    i18nFormatCurrency,
    i18nFormatNumber,
  } = useI18nFormatters();

  const collapses = [
    {
      title: translate(`${I18N_BASE_PATH}.collapses.profitAndLoss`),
      statementKey: 'profitAndLoss',
      localInfo: false,
      hasAdditionalInfoHeader: true,
      format: 'currency',
    },
    {
      title: translate(`${I18N_BASE_PATH}.collapses.balanceSheet`),
      statementKey: 'balanceSheet',
      localInfo: false,
      hasAdditionalInfoHeader: false,
      format: 'currency',
    },
    {
      title: translate(`${I18N_BASE_PATH}.collapses.cashFlow`),
      statementKey: 'cashFlow',
      localInfo: true,
      hasAdditionalInfoHeader: false,
      format: 'currency',
    },
    {
      title: translate(`${I18N_BASE_PATH}.collapses.otherFinancials`),
      statementKey: 'otherFinancials',
      localInfo: false,
      hasAdditionalInfoHeader: false,
      format: 'currency',
    },
    {
      title: translate(`${I18N_BASE_PATH}.collapses.ratios`),
      statementKey: 'ratios',
      localInfo: false,
      hasAdditionalInfoHeader: false,
      format: 'decimal',
    },
  ];

  const getStatementsRootKey = useCallback(
    (localInfo) => (localInfo ? 'localFinancialStatements' : 'financialStatements'),
    []
  );

  const filterOnlyIFRSType = useCallback((statement) => {
    return statement?.type?.includes('IFRS');
  }, []);

  const getDates = useCallback(
    (localInfo) => {
      if (!financialData) return [];
      const statementsRootKey = getStatementsRootKey(localInfo);

      const dates = new Set();
      financialData[statementsRootKey]
        ?.filter((s) => (localInfo && filterOnlyIFRSType(s)) || !localInfo)
        ?.forEach((statement) => dates.add(statement.yearEndDate));

      return Array.from(dates);
    },
    [financialData, filterOnlyIFRSType, getStatementsRootKey]
  );

  const getColumns = useCallback(
    ({ localInfo }) => {
      const datesColumns = getDates(localInfo).map((date) => {
        return {
          title: i18nFormatDateOnly(date, 1),
          dataIndex: date,
        };
      });

      const columns = [
        {
          title: '',
          dataIndex: 'infoDescription',
        },
        ...datesColumns,
      ];

      return columns;
    },
    [financialData]
  );

  const getRows = useCallback(
    ({ statementKey, hasAdditionalInfoHeader, localInfo, format }) => {
      if (!financialData) return [];

      const rootKey = localInfo ? 'localFinancialStatements' : 'financialStatements';
      const financialStatements = financialData[rootKey];

      const { currency } = financialStatements?.at(0) || {};
      const dates = getDates(localInfo);
      const rows = {
        empty: [],
        data: [],
      };

      if (hasAdditionalInfoHeader) {
        ['numberOfWeeks', 'currency', 'consolidatedAccounts'].forEach((key) => {
          const datesData = {};
          dates.forEach((date) => {
            const data = financialStatements.find((s) => s.yearEndDate === date)[key];
            if (typeof data === 'boolean') {
              datesData[date] = data
                ? translate(`${I18N_BASE_PATH}.yes`)
                : translate(`${I18N_BASE_PATH}.no`);
            } else {
              datesData[date] = data;
            }
          });

          const row = {
            infoDescription: translate(`${I18N_BASE_PATH}.api.${rootKey}.${key}`, key),
            ...datesData,
          };

          const isEmpty = Object.keys(datesData).every(
            (prop) => !datesData[prop] || datesData[prop] === '-'
          );

          if (isEmpty || !Object.keys(datesData).length) {
            rows.empty.push(row);
          } else {
            rows.data.push(row);
          }
        });
      }

      apiMap[rootKey][statementKey].forEach((key) => {
        const datesData = {};

        dates.forEach((date) => {
          const data = financialStatements
            .filter((s) => (localInfo && filterOnlyIFRSType(s)) || !localInfo)
            .find((s) => s.yearEndDate === date)[statementKey];

          const value = parseFloat(data?.[key]);
          let formatedValue = <NotAvailable />;

          if (!Number.isNaN(value)) {
            formatedValue =
              format === 'decimal'
                ? i18nFormatNumber(value)
                : i18nFormatCurrency(value, null, currency, { maximumFractionDigits: 0 });
          }

          datesData[date] = formatedValue;
        });

        const row = {
          key,
          infoDescription: translate(
            `${I18N_BASE_PATH}.api.${rootKey}.${statementKey}.${key}`,
            key
          ),
          ...datesData,
        };

        const isEmpty = Object.keys(datesData).every(
          (prop) => !datesData[prop] || React.isValidElement(datesData[prop])
        );

        if (isEmpty || !Object.keys(datesData).length) {
          rows.empty.push(row);
        } else {
          rows.data.push(row);
        }
      });

      return rows;
    },
    [financialData]
  );

  return (
    <div className={className}>
      {collapses.map((c) => {
        const columns = getColumns(c);
        const rows = getRows(c);
        return (
          <FinancialInfoCollapse
            key={c.statementKey}
            title={`${c.title} (${rows.data.length})`}
            bordered={bordered}
            showArrow={showArrow}
            activeKey={c.statementKey}
            collapseOpened={collapseOpened}
            collapseKeyPrefix={collapseKeyPrefix}
            print={print && rows.data.length}
          >
            <div className="kyb-company-collapse-item">
              <FinancialInfoTable
                key={c.statementKey}
                columns={columns}
                rows={rows.data}
                empty={rows.empty}
              />
            </div>
          </FinancialInfoCollapse>
        );
      })}
    </div>
  );
};

Accounts.propTypes = {
  className: PropTypes.string,
  financialData: PropTypes.object,
  bordered: PropTypes.bool,
  showArrow: PropTypes.bool,
  collapseOpened: PropTypes.bool,
  collapseKeyPrefix: PropTypes.string,
};

Accounts.defaultProps = {
  className: '',
  financialData: {},
  bordered: true,
  showArrow: false,
  collapseOpened: false,
  collapseKeyPrefix: '',
};

export default Accounts;
