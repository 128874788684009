import React, { useState, useMemo } from 'react';
import { Button } from 'antd';
import { isEmpty } from 'utils/isObjectEmpty';
import { OnboardindBig } from '@combateafraude/icons/docs';
import { Person } from '@combateafraude/icons/users';
import {
  Plus,
  LoupeDocumentSearch,
  Consultas,
  CheckCircle2,
} from '@combateafraude/icons/general';

import { useExecution } from 'hooks/execution';
import { useAuth } from 'hooks/auth';
import { useTranslation } from 'react-i18next';

import OcrData from '../DocumentsValidations/components/Shared/OcrData';
import { verifyFieldsOcrScreen } from './utils/categories';

import Box from './components/Box';
import DocumentLivenessCard from '../DocumentsValidations/components/DocumentLivenessCard';
import ManualReview from './ManualReview';
import CategoryInformation from './CategoryInformation';
import ClientOcr from '../DocumentsValidations/components/Shared/ClientOcr';
import EmptyDocuments from '../DocumentsValidations/components/Shared/EmptyDocuments';
import DocumentAuthenticity from './DocumentAuthenticity';
import DocumentFields from './DocumentFields';
import { convertGlobalDocVerificationResultToIcon } from '../../utils/convertGlobalDocVerificationResultToIcon';

const I18N_BASE_PATH =
  'pages.private.executions.components.tabs.components.documentsValidations.newOcrScreen';

const NewOcrScren = ({ toPrint = false }) => {
  const { execution, loadingExecution } = useExecution();
  const { user } = useAuth();
  const { t: translate } = useTranslation();
  const [isEditingDoc, setEditDoc] = useState(false);
  const [isEditingPersonal, setEditPersonal] = useState(false);
  const [isEditingMiscellaneous, setEditMiscellaneous] = useState(false);

  const statusClientOcr = ['REPROVADO', 'APROVADO', 'PENDENTE'];

  // flags
  const isWorkflowBuilder = user?.accountData?.featureFlags?.isWorkflowBuilder;
  const useClientManualOcr = user?.accountData?.featureFlags?.useClientManualOcr;

  // international types
  const frontDocTypes = ['FRONT', 'PASSPORT_FRONT', 'VISA_FRONT'];
  const backDocTypes = ['BACK', 'PASSPORT_BACK', 'VISA_BACK'];

  // front and back image
  let docFrontImage;
  let docBackImage;
  if (isWorkflowBuilder) {
    execution?.files?.forEach((file) => {
      if (frontDocTypes.includes(file.type)) {
        docFrontImage = file.signUrl;
      } else if (backDocTypes.includes(file.type)) {
        docBackImage = file.signUrl;
      }
    });
  } else {
    docFrontImage = execution?.images?.front;
    docBackImage = execution?.images?.back;
  }

  const ocr = {
    ...execution?.sections?.ocr,
    ...execution?.sections?.globalDocVerification?.data,
  };

  const fields = verifyFieldsOcrScreen(ocr);

  const contentFirstBox = useMemo(() => {
    if (execution?.status === 'PENDENTE OCR') {
      return <OcrData data={execution?.sections?.ocr} type={execution?.type} />;
    }
    if (isEditingDoc) {
      return <ClientOcr fields={fields?.documentVerification} ocr={ocr} />;
    }
    return (
      <CategoryInformation
        fields={fields?.documentVerification}
        executionData={ocr}
        toPrint={toPrint}
      />
    );
  }, [execution, fields, isEditingDoc, ocr, toPrint]);

  return (
    <>
      {(!execution || isEmpty(execution)) && <EmptyDocuments type="authentic-document" />}
      {(execution || !isEmpty(execution)) && (
        <>
          <Box
            title={translate(
              `${I18N_BASE_PATH}.documentVerification.title`,
              'Document verification'
            )}
            subtitle={translate(
              `${I18N_BASE_PATH}.documentVerification.subtitle`,
              'Document identification and information'
            )}
            showButton={
              useClientManualOcr &&
              statusClientOcr.includes(execution?.status) && (
                <Button type="primary" onClick={() => setEditDoc(!isEditingDoc)}>
                  {translate(`${I18N_BASE_PATH}.editOcr.${isEditingDoc}`)}
                </Button>
              )
            }
            showImage
            front={docFrontImage}
            back={docBackImage}
            icon={<OnboardindBig fontSize={28} />}
            toPrint={toPrint}
          >
            {contentFirstBox}
          </Box>
          {fields?.personalData.length !== 0 && (
            <Box
              title={translate(`${I18N_BASE_PATH}.personalData.title`, 'Personal Data')}
              subtitle={translate(
                `${I18N_BASE_PATH}.personalData.subtitle`,
                'Personal information contained in the document'
              )}
              showButton={
                useClientManualOcr &&
                statusClientOcr.includes(execution?.status) && (
                  <Button
                    type="primary"
                    onClick={() => setEditPersonal(!isEditingPersonal)}
                  >
                    {translate(`${I18N_BASE_PATH}.editOcr.${isEditingPersonal}`)}
                  </Button>
                )
              }
              icon={<Person fontSize={28} />}
            >
              {isEditingPersonal ? (
                <ClientOcr fields={fields?.personalData} ocr={ocr} />
              ) : (
                <CategoryInformation
                  fields={fields?.personalData}
                  executionData={ocr}
                  toPrint={toPrint}
                />
              )}
            </Box>
          )}
          {fields?.miscellaneousInformation.length !== 0 && (
            <Box
              title={translate(
                `${I18N_BASE_PATH}.miscellaneousInformation.title`,
                'Miscellaneous Information'
              )}
              subtitle={translate(
                `${I18N_BASE_PATH}.miscellaneousInformation.subtitle`,
                'Other information contained in the document'
              )}
              showButton={
                useClientManualOcr &&
                statusClientOcr.includes(execution?.status) && (
                  <Button
                    type="primary"
                    onClick={() => setEditMiscellaneous(!isEditingMiscellaneous)}
                  >
                    {translate(`${I18N_BASE_PATH}.editOcr.${isEditingMiscellaneous}`)}
                  </Button>
                )
              }
              icon={<Plus fontSize={28} />}
            >
              {isEditingMiscellaneous ? (
                <ClientOcr fields={fields?.miscellaneousInformation} ocr={ocr} />
              ) : (
                <CategoryInformation
                  fields={fields?.miscellaneousInformation}
                  executionData={ocr}
                  toPrint={toPrint}
                />
              )}
            </Box>
          )}
          {execution?.sections?.documentscopy && (
            <Box
              title={translate(`${I18N_BASE_PATH}.manualReview.title`, 'Manual Review')}
              subtitle={translate(
                `${I18N_BASE_PATH}.manualReview.subtitle`,
                'Result of the manual analysis carried out on the document'
              )}
              icon={<LoupeDocumentSearch fontSize={28} />}
            >
              <ManualReview
                data={
                  execution?.sections?.documentscopy?.data ||
                  execution?.sections?.documentscopy
                }
              />
            </Box>
          )}
          {ocr?.validations?.textFieldsCheck && (
            <Box
              title={translate(`${I18N_BASE_PATH}.documentFields.title`)}
              subtitle={translate(
                `${I18N_BASE_PATH}.documentFields.subtitle`,
                'All text fields extracted from the document'
              )}
              icon={<Consultas fontSize={28} />}
            >
              <DocumentFields data={ocr?.validations?.textFieldsCheck} />
            </Box>
          )}
          {(ocr?.validations?.authenticityCheck?.front ||
            ocr?.validations?.authenticityCheck?.back) && (
            <Box
              title={translate(
                `${I18N_BASE_PATH}.documentAuthenticity.title`,
                'Document authenticity'
              )}
              subtitle={translate(
                `${I18N_BASE_PATH}.documentAuthenticity.subtitle`,
                'Result of the authenticity checks carried out on the document'
              )}
              icon={<CheckCircle2 fontSize={28} />}
            >
              <DocumentAuthenticity data={ocr?.validations?.authenticityCheck} />
            </Box>
          )}
        </>
      )}
      {execution?.sections?.documentLiveness && (
        <DocumentLivenessCard loading={loadingExecution} />
      )}
    </>
  );
};

export default NewOcrScren;
