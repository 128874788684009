import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown, Menu, Divider } from 'antd';

import PropTypes from 'prop-types';
import useRestrictionCreateModal from '../../../components/RestrictionCreateModal';
import useRestrictionDeleteModal from '../RestrictionDeleteModal';

import './styles.less';

const DropdownRestrictionOptions = ({ docs, refreshList }) => {
  const { t: translate } = useTranslation();

  // const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const { openModal, RestrictionCreateModal } = useRestrictionCreateModal({
    isEdit: true,
    docs,
    refreshList,
    url: 'internal-restrictive-list',
  });

  const {
    openModal: openDeleteModal,
    RestrictionDeleteModal,
  } = useRestrictionDeleteModal({
    docs,
    refreshList,
  });

  return (
    <>
      {RestrictionCreateModal}
      {RestrictionDeleteModal}

      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="1" onClick={() => openModal()}>
              <i className="caf-ic_edit" />
              {translate(
                'pages.private.restrictions.restrictionList.components.DropdownRestrictionOptions.index.Menu.Item.edit'
              )}
            </Menu.Item>
            <Divider style={{ padding: 0, margin: 0 }} />
            <Menu.Item key="2" onClick={() => openDeleteModal()}>
              <i className="caf-ic_trash opt-cancel" />
              <span className="opt-cancel">
                {translate(
                  'pages.private.restrictions.restrictionList.components.DropdownRestrictionOptions.index.Menu.Item.delete'
                )}
              </span>
            </Menu.Item>
          </Menu>
        }
        trigger={['click']}
        // visible={isDropdownOpened}
        placement="bottomRight"
        // onClick={() => setIsDropdownOpened(true)}
      >
        <div style={{ marginLeft: '75%' }} className="btn-more-icon">
          <i className="caf-ic_more-vertical" />
        </div>
      </Dropdown>
    </>
  );
};

DropdownRestrictionOptions.propTypes = {
  docs: PropTypes.oneOfType({}).isRequired,
  refreshList: PropTypes.func.isRequired,
};

export default DropdownRestrictionOptions;
