import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ModalServices from 'components/ModalServices';
import useNewRegister from 'components/ModalCreateNewRegister';
import useOnboardings from 'pages/private/ProfileModels/ProfileModels/components/Links/components/CreateLinkModal';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';
import { useAuth } from './auth';

const RegisterModalContext = createContext({});

const RegisterModalProvider = ({ children }) => {
  const { t: translate } = useTranslation();
  const { user: loggedUser } = useAuth();

  const [validationType, setValidationType] = useState();

  const {
    openModal: openExecutionRegisterModal,
    CreateNewRegisterModal: ExecutionRegisterModal,
  } = useNewRegister({
    segment: validationType,
    pageTitle: {
      title: translate(
        'components.modalCreateExecution.index.CreateExecution.pageTitle.title'
      ),
      subtitle: translate(
        hasFeatureFlag('isWorkflowBuilder', loggedUser)
          ? 'components.modalCreateExecution.index.CreateExecution.pageTitle.subTitleWorkflow'
          : 'components.modalCreateExecution.index.CreateExecution.pageTitle.subTitle'
      ),
    },
    buttonsText: {
      loading: translate(
        'components.modalCreateExecution.index.CreateExecution.form.button.creatingExecution'
      ),
      default: translate(
        'components.modalCreateExecution.index.CreateExecution.form.button.submit'
      ),
    },
  });

  const { openModal: openOnboardingsModal, CreateLinkModal } = useOnboardings({
    refreshListRef: () => {},
    segment: validationType,
  });

  const { openModal, ServicesModal } = ModalServices({
    openExecutionRegisterModal,
    openOnboardingsModal,
    setValidationType,
    validationType,
  });

  return (
    <RegisterModalContext.Provider
      value={{
        openRegisterModal: openModal,
      }}
    >
      {ServicesModal}
      {ExecutionRegisterModal}
      {CreateLinkModal}
      {children}
    </RegisterModalContext.Provider>
  );
};

function useRegisterModal() {
  const context = useContext(RegisterModalContext);

  if (!context) {
    throw new Error('useRegisterModal must be used within an RegisterModalContext');
  }

  return context;
}

export { RegisterModalProvider, useRegisterModal };
