import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CompanyCard,
  CompanyLink,
  CompanySubtitle1,
  CompanySubtitle2,
} from 'pages/private/KYBCompany/CompanyDetailsV2/components';
import PropTypes from 'prop-types';
import NotAvailable from 'components/NotAvailable';
import CompanyRecordBody from './components/CompanyRecordBody';

const I18N_BASE_PATH = 'pages.private.KYBCompany.pages';
const SUMMARY = `${I18N_BASE_PATH}.companyDetails.subPages.summary`;
const RECORDS = `${SUMMARY}.cards.records`;

const CompanyRecords = ({ records, recordsToShow, disabled }) => {
  const { t: translate } = useTranslation();

  if (disabled) {
    return null;
  }

  return (
    <Row>
      <Col span={24}>
        <CompanyCard>
          <Row>
            <Col span={24}>
              <CompanySubtitle1>
                {translate(`${RECORDS}.title`)} (
                {records?.count >= 100 ? 100 : records.count})
              </CompanySubtitle1>
            </Col>
          </Row>
          {records?.count ? (
            <>
              <Row>
                <Col span={8}>
                  <CompanySubtitle2 level={5}>
                    {translate(`${RECORDS}.recordName`, 'Record name')}
                  </CompanySubtitle2>
                </Col>
                <Col span={5}>
                  <CompanySubtitle2 level={5}>
                    {translate(`${RECORDS}.acquisition`, 'Acquisition')}
                  </CompanySubtitle2>
                </Col>
                <Col span={5}>
                  <CompanySubtitle2 level={5}>
                    {translate(`${RECORDS}.publication`, 'Publication')}
                  </CompanySubtitle2>
                </Col>
                <Col span={6}>
                  <CompanySubtitle2 level={5}>
                    {translate(`${RECORDS}.source`, 'Source')}
                  </CompanySubtitle2>
                </Col>
              </Row>
              {records?.records?.slice(0, recordsToShow)?.map((record) => (
                <CompanyRecordBody
                  className="kyb-company-print-break-avoid"
                  record={record}
                  sources={records?.sources}
                />
              ))}
              <Row justify="end">
                <Col className="kyb-company-summary-footer">
                  <CompanyLink to="?tab=records">
                    {translate(`${I18N_BASE_PATH}.companyDetails.mainCardLink`)}
                  </CompanyLink>
                </Col>
              </Row>
            </>
          ) : (
            <NotAvailable card />
          )}
        </CompanyCard>
      </Col>
    </Row>
  );
};

CompanyRecords.propTypes = {
  records: PropTypes.object.isRequired,
  recordsToShow: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CompanyRecords;
