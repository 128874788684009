/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'utils/isObjectEmpty';
import { labelStatus } from 'utils/handlerStatus';
import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import EmptyState from 'components/EmptyState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

const Sintegra = ({ profile /* urlId, toPrint */ }) => {
  const { t: translate } = useTranslation();

  const sintegra = useMemo(() => profile?.certificates?.sintegra, [profile]);

  const isProcessing = useMemo(() => verifySourcesProcessing(profile, 'sintegra'), [
    profile,
  ]);

  return (
    <ColumnLabel
      title={translate(
        'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.sintegra.index.sintegra.columnLabel.title'
      )}
      type={sintegra && labelStatus(sintegra?.situation)?.type}
      description={
        sintegra &&
        translate(labelStatus(sintegra?.situation, sintegra?.situation)?.description)
      }
    >
      {!sintegra && isProcessing && <EmptyState type="processing" />}

      {!sintegra && !isProcessing && <EmptyState type="waiting_consult" />}

      {sintegra && !isEmpty(sintegra) && (
        <span>
          {translate(
            'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.sintegra.index.sintegra.stateRegistration.text'
          )}{' '}
          {sintegra?.stateRegistration ||
            translate(
              'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.sintegra.index.sintegra.stateRegistration.unavailable'
            )}
        </span>
      )}

      {sintegra && isEmpty(sintegra) && (
        <span>
          {translate(
            'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.sintegra.index.sintegra.hasNoData'
          )}
        </span>
      )}
    </ColumnLabel>
  );
};

export default Sintegra;
