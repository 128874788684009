import { Col, Collapse, Row, Divider } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DottedLabel from 'components/DottedLabel';

import { useI18nFormatters } from 'hooks/i18nFormatters';

// import { Container } from './styles';

const { Panel } = Collapse;

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.companies.components.spcReportParticipationScore.otherInfos';

function ContumaciaSRS({ source = {}, toPrint = false }) {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const parsedData = useMemo(() => source?.data || source, [source]);

  const contumaciaSrs = useMemo(() => parsedData?.contumaciaSrs || {}, [parsedData]);

  return (
    <Collapse className="mrg-top-10" defaultActiveKey={toPrint ? ['1'] : null}>
      <Panel
        key="1"
        disabled={!contumaciaSrs?.details?.length}
        header={
          <Col span={24}>
            <Row align="middle" justify="space-between">
              <Col>
                <span className="text-dark text-bold">
                  {translate(`${I18N_BASE_PATH}.index.contumacy.title`)}
                </span>
              </Col>
              <Col>
                <>
                  <DottedLabel
                    description={`(${contumaciaSrs?.total || 0}) ${translate(
                      `${I18N_BASE_PATH}.index.restrictions`
                    )}`}
                    type={+contumaciaSrs?.total > 0 ? 'danger' : 'primary'}
                  />
                </>
              </Col>
            </Row>
            <Row align="middle" className="mrg-top-10" justify="space-between">
              <Col span={24}>
                <Row>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.contumacy.columns.lastOccurenceDate`
                    )}
                    :
                  </span>
                  <span>
                    {i18nFormatDate(contumaciaSrs?.lastOccurenceDate, 3) || '-'}
                  </span>
                </Row>
              </Col>
            </Row>
          </Col>
        }
      >
        {contumaciaSrs?.details?.map((register) => (
          <>
            <Col span={24}>
              <Row align="middle">
                <span className="text-dark text-bold">
                  {i18nFormatDate(register?.occurenceDate, 1)} -{' '}
                  {register?.initialCheck?.bankData?.name}
                </span>
              </Row>
              <Row align="middle" className="mrg-top-8" justify="space-between">
                <Col>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.contumacy.columns.initialCheckNumber`
                    )}
                    :
                  </span>{' '}
                  <span>{register?.initialCheck?.number || '-'}</span>
                </Col>
                <Col>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.contumacy.columns.lastCheckNumber`
                    )}
                    :
                  </span>{' '}
                  <span>{register?.finalCheck?.number || '-'}</span>
                </Col>
                <Col>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.contumacy.columns.initialCheckAgencyNumber`
                    )}
                    :
                  </span>{' '}
                  <span>{register?.initialCheck?.bankData?.agencyNumber || '-'}</span>
                </Col>
                <Col>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.contumacy.columns.initialCheckBankAccountNumber`
                    )}
                    :
                  </span>{' '}
                  <span>{register?.initialCheck?.bankData?.checkingAccount || '-'}</span>
                </Col>
              </Row>
              <Row align="middle" className="mrg-top-8" justify="space-between">
                <Col>
                  <span className="collapse-label">
                    {translate(`${I18N_BASE_PATH}.index.contumacy.columns.occurenceDate`)}
                    :
                  </span>{' '}
                  <span>{i18nFormatDate(register?.occurenceDate, 1) || '-'}</span>
                </Col>
                <Col>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.contumacy.columns.reasonDescription`
                    )}
                    :
                  </span>{' '}
                  <span>{register?.motive || '-'}</span>
                </Col>
              </Row>
            </Col>
            <Divider />
          </>
        ))}
      </Panel>
    </Collapse>
  );
}

export default ContumaciaSRS;
