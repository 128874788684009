import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import './styles.less';

const NewCircleIcon = ({ icon, danger, warning, backgroundBlue, extraClass }) => {
  const circleClasses = useMemo(() => {
    if (danger) return 'circle-icon danger';
    if (warning) return 'circle-icon warning';
    if (backgroundBlue) return 'circle-icon background-blue';

    return 'circle-icon primary';
  }, [backgroundBlue, danger, warning]);

  return <div className={`${circleClasses} ${extraClass}`}>{icon}</div>;
};

NewCircleIcon.propTypes = {
  icon: PropTypes.element.isRequired,
  danger: PropTypes.bool,
  warning: PropTypes.bool,
  backgroundBlue: PropTypes.bool,
  extraClass: PropTypes.string,
};

NewCircleIcon.defaultProps = {
  danger: false,
  warning: false,
  backgroundBlue: false,
  extraClass: '',
};

export default NewCircleIcon;
