import React from 'react';
// import { Col, Row } from 'antd';

import Wrapper from 'pages/private/wrapper';
import { CardWrapper } from 'components/CardWrapper';

import Filter from './components/Filter';

import FaceAuthenticatorList from './components/List';
import './styles.less';

const FaceAuthenticator = () => {
  return (
    <Wrapper id="liveness-list-component">
      <div className="face-auth-content-grid">
        <div className="face-auth-content-grid-1">
          <CardWrapper hasPadding>
            <FaceAuthenticatorList />
          </CardWrapper>
        </div>
        <div className="face-auth-content-grid-2">
          <CardWrapper hasPadding>
            <Filter />
          </CardWrapper>
        </div>
      </div>
    </Wrapper>
  );
};
export default FaceAuthenticator;
