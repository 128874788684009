import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Divider } from 'antd';

import EmptyExecutionState from 'components/EmptyExecutionState';

import { toMaskedCnpj, toMaskedCpf } from 'utils/formatters';
import { labelStatus } from 'utils/handlerStatus';
import { cpfTranslation } from 'utils/personalTranslations';
import DottedLabel from 'components/DottedLabel';

import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';
import { useI18nFormatters } from 'hooks/i18nFormatters';

import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.shared.spcRestrictionsScore.registrationInfos';

const RegistrationInfosContent = ({ source, toPrint }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const parsedData = useMemo(() => source?.data || source, [source]);

  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const legalNatureFormatted = useMemo(() => {
    const { code, description } = parsedData?.customer?.legalNature || {};
    let legalNature = '';

    if (code) legalNature = code;
    if (code && description) legalNature = legalNature.concat(' - ');
    if (description) legalNature = legalNature.concat(description);

    return legalNature;
  }, [parsedData]);

  const cellPhoneFormatted = useMemo(() => {
    const { areaCode, number } = parsedData?.customer?.cellphone || {};
    let legalNature = '';

    if (areaCode) legalNature = `(${areaCode})`;
    if (areaCode && number) legalNature = legalNature.concat(' ');
    if (number) legalNature = legalNature.concat(number);

    return legalNature;
  }, [parsedData]);

  const officialName = useMemo(() => {
    let name = '-';

    if (parsedData?.spellingLegalEntity?.length > 0) {
      name = parsedData?.spellingLegalEntity[0]?.name;
    }

    return name;
  }, [parsedData]);

  return (
    <>
      <DataSourceCardCollapsePanel
        title={translate(`${I18N_BASE_PATH}.index.title`)}
        icon="caf-ic_file"
        toPrint={toPrint}
        customId="report-spc-last-phone-card"
      >
        {queryWorked ? (
          <>
            {parsedData?.customer?.cnpj && (
              <Row className="pdd-top-10">
                <ColumnTitleValue
                  span={24}
                  title={translate(`${I18N_BASE_PATH}.index.columns.officialName`)}
                  value={officialName || '-'}
                />
                <Divider className="custom-divider" />

                <ColumnTitleValue
                  span={12}
                  title={translate(`${I18N_BASE_PATH}.index.columns.cnpj`)}
                  value={toMaskedCnpj(parsedData?.customer?.cnpj) || '-'}
                />

                <ColumnTitleValue
                  span={12}
                  title={translate(`${I18N_BASE_PATH}.index.columns.situation`)}
                  value={
                    <DottedLabel
                      description={parsedData?.customer?.statusDescription || '-'}
                      type={
                        parsedData?.customer &&
                        labelStatus(parsedData?.customer?.statusDescription).type
                      }
                    />
                  }
                  className="situation-col"
                />

                <Divider className="custom-divider" />

                <ColumnTitleValue
                  span={12}
                  title={translate(`${I18N_BASE_PATH}.index.columns.legalNature`)}
                  value={legalNatureFormatted || '-'}
                />

                <Divider className="custom-divider" />

                <ColumnTitleValue
                  span={24}
                  title={translate(`${I18N_BASE_PATH}.index.columns.mainActivity`)}
                  value={parsedData?.customer?.mainEconomicActivity?.description || '-'}
                />
              </Row>
            )}

            {parsedData?.customer?.cnpj && parsedData?.customer?.cpf && (
              <Divider className="custom-divider" />
            )}

            {parsedData?.customer?.cpf && (
              <Row className="pdd-top-10">
                <ColumnTitleValue
                  span={8}
                  title={translate(`${I18N_BASE_PATH}.index.columns.name`)}
                  value={parsedData?.customer?.name || '-'}
                />

                <ColumnTitleValue
                  span={8}
                  title={translate(`${I18N_BASE_PATH}.index.columns.cpf`)}
                  value={
                    <>
                      <DottedLabel
                        description={translate(
                          cpfTranslation[
                            parsedData?.customer?.statusCpf?.statusDescription || 'NULA'
                          ].description
                        )}
                        type={
                          cpfTranslation[
                            parsedData?.customer?.statusCpf?.statusDescription || 'NULA'
                          ].type
                        }
                      />
                      <span className="mrg-left-15 mrg-top-5">
                        {toMaskedCpf(parsedData?.customer?.cpf?.number) || '-'}
                      </span>
                    </>
                  }
                />

                <ColumnTitleValue
                  span={8}
                  title={translate(`${I18N_BASE_PATH}.index.columns.rg`)}
                  value={parsedData?.customer?.cpf?.customer?.idNumber || '-'}
                />

                <Divider className="custom-divider" />

                <ColumnTitleValue
                  span={8}
                  title={translate(`${I18N_BASE_PATH}.index.columns.mothersName`)}
                  value={parsedData?.customer?.cpf?.customer?.mothersName || '-'}
                />

                <ColumnTitleValue
                  span={8}
                  title={translate(`${I18N_BASE_PATH}.index.columns.birthDate`)}
                  value={
                    i18nFormatDate(parsedData?.customer?.cpf?.customer?.birthday, 1) ||
                    '-'
                  }
                />

                <ColumnTitleValue
                  span={8}
                  title={translate(`${I18N_BASE_PATH}.index.columns.contact`)}
                  value={cellPhoneFormatted || '-'}
                />
                <Divider className="custom-divider" />
              </Row>
            )}
          </>
        ) : (
          <EmptyExecutionState
            statusCode={statusCode}
            message={source?.message || parsedData?.message}
          />
        )}
      </DataSourceCardCollapsePanel>
    </>
  );
};

RegistrationInfosContent.propTypes = {
  company: PropTypes.shape({
    sections: PropTypes.shape({
      pjData: PropTypes.shape({
        data: PropTypes.shape({
          secondaryActivities: PropTypes.arrayOf(
            PropTypes.shape({
              code: PropTypes.string,
              description: PropTypes.string,
            })
          ),
        }),
      }),
    }),
    parameters: PropTypes.shape({
      cnpj: PropTypes.string,
    }),
  }),
  source: PropTypes.objectOf(),
  toPrint: PropTypes.bool,
};

RegistrationInfosContent.defaultProps = {
  company: undefined,
  source: null,
  toPrint: false,
};

export default RegistrationInfosContent;
