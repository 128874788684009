/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from 'antd';
import _ from 'lodash';

import { useTags } from 'hooks/tags';
import { useI18nFormatters } from 'hooks/i18nFormatters';

import { toMaskedCnpj } from 'utils/formatters';
import { generalStatus } from 'utils/handlerStatus';

import DottedLabel from 'components/DottedLabel';
import IconStatus from 'pages/private/Profiles/components/IconStatus';
import CardExecutionTag from 'pages/private/Profiles/components/ProfileTag/CardExecutionTag';
import PermissionWrapper from 'routes/PermissionWrapper';

import './styles.less';

const I18N_BASE_PATH =
  'pages.private.profiles.components.list.components.companyCard.index.content';

const CompanyCard = ({ data, allProfileTemplates }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const { customAllTags: tagsList, loadingAllTags: loadingTags } = useTags();

  const parsedTags = useMemo(() => {
    return data?.tags?.filter((tag) => tag !== null) || [];
  }, [data]);

  const profileTemplateName = useMemo(() => {
    const name = allProfileTemplates?.find(
      (profileTemplate) => profileTemplate?._id === data?.profileTemplateId
    )?.name;

    return name || '-';
  }, [allProfileTemplates, data]);

  const content = useMemo(() => {
    return (
      <Card>
        <div className="card-body">
          <div className="flex-1 card-body-infos flex-column">
            <p className="font-size-11 mrg-btm-5 text-semibold profile-template-name">
              {profileTemplateName}
            </p>

            <p
              title={data?.basicData?.officialName || '-'}
              className="card-title max-lines-2 gx-w-100"
            >
              {data?.basicData?.officialName || '-'}
            </p>

            <div className="card-info">
              <i className="caf-ic_company" />
              <span className={`${!data?.cnpj && 'gx-font-italic'} max-lines-1`}>
                {toMaskedCnpj(data?.cnpj) || '-'}
              </span>
            </div>
          </div>

          {data?.status && (
            <div className="card-info-status">
              <IconStatus status={data?.status} />
              <div className="card-info-status-content">
                <strong>{translate(generalStatus(data?.status)?.value)}</strong>
                <span className="font-size-12">
                  {translate(`${I18N_BASE_PATH}.updatedAt`)}{' '}
                  {i18nFormatDate(data?.updatedAt || data?.createdAt, 8)}
                </span>
              </div>
            </div>
          )}
        </div>
        {(parsedTags?.length > 0 ||
          data?.QSA?.items?.some((partner) => !partner?.onboardingId)) && (
          <div className="flex start-center pdd-top-15 pdd-btm-5 pdd-horizon-15 card-tags-wrapper">
            {!loadingTags && !_.isEmpty(tagsList) && (
              <div id="card-tags">
                {parsedTags?.map((tag) => {
                  return (
                    <CardExecutionTag
                      key={tag}
                      name={tagsList[tag]?.title}
                      color={tagsList[tag]?.color}
                      special={tagsList[tag]?.special}
                      actionDescription={tagsList[tag]?.actionDescription}
                    />
                  );
                })}

                {(!data?.onboarding?.qsa?.length ||
                  data?.onboarding?.qsa?.some(
                    (partner) => partner?.status !== 'COMPLETED'
                  )) && (
                  <DottedLabel
                    className="missing-onboarding-label mrg-btm-10 text-bold"
                    description={translate(
                      `${I18N_BASE_PATH}.pendingOnboardingsLabelText`
                    )}
                    type="warning"
                    fontSize={12}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </Card>
    );
  }, [
    data,
    profileTemplateName,
    translate,
    i18nFormatDate,
    parsedTags,
    loadingTags,
    tagsList,
  ]);

  return (
    <PermissionWrapper
      requiredPermissions={['companies:read:details']}
      forbiddenContent={
        <div
          id="card-companies-component"
          className="overflow-hidden document-card mrg-btm-20"
        >
          {content}
        </div>
      }
    >
      <Link
        id="card-companies-component"
        to={`/profile/companies/${data._id}`}
        className="overflow-hidden document-card mrg-btm-20"
      >
        {content}
      </Link>
    </PermissionWrapper>
  );
};

export default CompanyCard;
