import React, { useMemo } from 'react';
import { Col, Divider, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';
import DottedLabel from 'components/DottedLabel';
import Button from 'components/Button';
import useViolationsModal from './ViolationDetailsModal';

import './styles.less';

const { Title } = Typography;

const I18N_BASE_PATH = 'components.dataSourceCards.denatranDriverViolations';

function ViolationsCollapsePanel({ violations = [], toPrint = false }) {
  const { t: translate } = useTranslation();
  const { ViolationDetailsModal, openModal } = useViolationsModal();
  const customHeader = useMemo(() => {
    const type = violations?.length ? 'danger' : 'primary';
    const description = (
      <span className="pdd-left-10">
        {`(${violations?.length}) ${translate(`${I18N_BASE_PATH}.headerLabel`)}`}
      </span>
    );

    return (
      <Row justify="space-between" style={{ width: '100%' }}>
        <Col span={18}>
          <i className="caf-ic_lawier" />
          <span className="text-dark text-bold pdd-left-10">
            {translate(`${I18N_BASE_PATH}.title`)}
          </span>
        </Col>
        <Col>
          <DottedLabel description={description} type={type} />
        </Col>
      </Row>
    );
  }, [translate, violations]);

  return (
    <>
      {ViolationDetailsModal}
      <DataSourceCardCollapsePanel
        panelProps={{ disabled: !violations?.length }}
        customHeader={customHeader}
        toPrint={toPrint}
      >
        {violations?.map((violation, index) => (
          <>
            <Row>
              <Col span={24}>
                <Title level={5}>{violation.violationDescription}</Title>
              </Col>
            </Row>
            <Row className="denatran-driver-violations mrg-top-10">
              <ColumnTitleValue
                className="inline-infos"
                title={`${translate(`${I18N_BASE_PATH}.columns.violationId`)}:`}
                value={violation.violationId}
                span={10}
              />
              <ColumnTitleValue
                className="inline-infos"
                title={`${translate(
                  `${I18N_BASE_PATH}.columns.violationRegistrationDate`
                )}:`}
                value={violation.violationRegistrationDate}
                span={10}
              />
              <ColumnTitleValue
                className="inline-infos"
                title={`${translate(`${I18N_BASE_PATH}.columns.actuatorAgencyState`)}:`}
                value={violation.actuatorAgencyState}
                span={10}
              />
              <ColumnTitleValue
                className="inline-infos"
                title={`${translate(`${I18N_BASE_PATH}.columns.plate`)}:`}
                value={violation.plate}
                span={10}
              />
            </Row>

            <Row className="mrg-top-10">
              <Button type="link" onClick={() => openModal(violation)}>
                <i className="caf-ic_arrow-right" />
                <span className="mrg-left-10">{`${translate(
                  `${I18N_BASE_PATH}.columns.moreDetails`
                )}`}</span>
              </Button>
            </Row>
            {index < violations?.length - 1 && <Divider />}
          </>
        ))}
      </DataSourceCardCollapsePanel>
    </>
  );
}

export default ViolationsCollapsePanel;
