import React, { useEffect, useMemo } from 'react';
import { Row, Col, Spin, Divider } from 'antd';
import PropTypes from 'prop-types';

import { validationStatus } from 'utils/handlerStatus';

import { useExecution } from 'hooks/execution';
import { useProfileTemplate } from 'hooks/profileTemplate';

const MissingInfosSources = () => {
  const { execution } = useExecution();
  const {
    cafServicesList,
    loadCafServicesList,
    loadingCafServicesList,
  } = useProfileTemplate();

  useEffect(() => {
    loadCafServicesList();
  }, [loadCafServicesList]);

  const parsedMissingInfosSources = useMemo(() => {
    const sectionskeys = Object.keys(execution?.sections || {});

    const parsedInfos = [];

    sectionskeys?.forEach((key) => {
      const section = execution?.sections[key];

      if (section?.statusCode === '02') {
        const relatedService = cafServicesList?.docs?.find(
          (service) => service.sectionAttr === key
        );

        parsedInfos.push({
          name: relatedService?.title,
          status: 'PENDING',
          message: section?.data?.message || section?.message || '-',
        });
      }
    });

    return parsedInfos;
  }, [execution, cafServicesList]);

  return loadingCafServicesList ? (
    <Row align="middle" justify="center">
      <Spin />
    </Row>
  ) : (
    parsedMissingInfosSources?.map((source) => (
      <>
        <Row
          key={source?.rule}
          align="middle"
          justify="center"
          className="compliance-rules-status reset-mrg pdd-top-10 pdd-btm-10"
        >
          <Col span={8} className="reset-mrg no-pdd">
            <span className="rule-title">{source?.name}</span>
          </Col>
          <Col span={2}>
            <i
              style={{ color: validationStatus(source?.status).color }}
              className={`status-icon ${validationStatus(source?.status).icon}`}
            />
          </Col>
          <Col span={14}>{source?.message}</Col>
        </Row>
        <Divider />
      </>
    ))
  );
};

MissingInfosSources.propTypes = {
  item: PropTypes.shape({
    validations: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        description: PropTypes.string,
        title: PropTypes.string,
        rule: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
};

export default MissingInfosSources;
