/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import { isEmpty } from 'utils/isObjectEmpty';
import { labelStatus } from 'utils/handlerStatus';
import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import EmptyState from 'components/EmptyState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

const FGTS = ({ profile, toPrint /* urlId */ }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const fgts = useMemo(() => profile?.certificates?.fgts, [profile]);

  const isProcessing = useMemo(() => verifySourcesProcessing(profile, 'fgts'), [profile]);

  return (
    <ColumnLabel
      title={translate(
        'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.fgts.index.fgts.columnLabel.title'
      )}
      type={fgts && labelStatus(fgts?.status)?.type}
      description={fgts && translate(labelStatus(fgts?.status)?.description)}
      info={
        !toPrint &&
        translate(
          'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.fgts.index.fgts.columnLabel.info'
        )
      }
    >
      {!fgts && isProcessing && <EmptyState type="processing" />}

      {!fgts && !isProcessing && <EmptyState type="waiting_consult" />}

      {fgts &&
        (!isEmpty(fgts) ? (
          <>
            {fgts?.numberCertificate && (
              <span>
                {translate(
                  'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.fgts.index.fgts.numberCertificate'
                )}{' '}
                {fgts?.numberCertificate} (atual)
              </span>
            )}
            {fgts?.dueDate && i18nFormatDate(fgts?.dueDate, 1) !== '-' && (
              <span>
                {translate(
                  'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.fgts.index.fgts.dueDate'
                )}{' '}
                {i18nFormatDate(fgts?.dueDate, 1)}
              </span>
            )}
          </>
        ) : (
          <span>
            {translate(
              'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.fgts.index.fgts.hasNoData'
            )}
          </span>
        ))}
    </ColumnLabel>
  );
};

export default FGTS;
