import React, { useMemo, useState, useCallback, useRef } from 'react';
import { Modal, Form, message, Input, Card, Divider, Radio, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { tagColors } from 'utils/tagColors';
import { useFetch } from 'services/hooks';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.tagsAndReasons.components.ModalTagUpdate';

const ModalTagUpdate = () => {
  const [form] = Form.useForm();
  const inputRef = useRef();
  const refreshListRef = useRef();

  const { t: translate } = useTranslation();
  const { put, post, loading } = useFetch();

  const [modalVisible, setModalVisible] = useState(false);
  const [isNewTag, setIsNewTag] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [data, setData] = useState({});

  const closeModal = useCallback(() => {
    setSearchValue(null);

    form.resetFields();
    setModalVisible(false);
  }, [form]);

  const openModal = useCallback(({ tag, refreshList, isNew }) => {
    refreshListRef.current = refreshList;
    setData(tag);
    setModalVisible(true);
    setIsNewTag(isNew);
  }, []);

  const handleSaveTag = useCallback(async () => {
    await form?.validateFields();
    const values = await form?.getFieldsValue();
    try {
      await post({
        url: `tags`,
        payload: {
          title: values.title,
          color: values.color,
        },
      });
      message.success(translate(`${I18N_BASE_PATH}.handleSaveMessages.success`));
      refreshListRef?.current();
      closeModal();
    } catch (error) {
      message.error(translate(`${I18N_BASE_PATH}.handleSaveMessages.error`));
    }
  }, [form, post, translate, closeModal]);

  const handleUpdateTag = useCallback(async () => {
    await form?.validateFields();
    const values = await form?.getFieldsValue();
    try {
      await put({
        url: `tags/${data?._id}`,
        payload: {
          title: values.title,
          color: values.color,
        },
      });
      message.success(translate(`${I18N_BASE_PATH}.handleUpdateMessages.success`));
      refreshListRef?.current();
      closeModal();
    } catch (error) {
      message.error(translate(`${I18N_BASE_PATH}.handleUpdateMessages.error`));
      refreshListRef?.current();
    }
  }, [form, put, data, translate, closeModal]);

  const TagUpdateModal = useMemo(
    () => (
      <Modal
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        visible={modalVisible}
        width={480}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
      >
        <Card className="add-tags-menu" bordered={false}>
          <Form
            layout="vertical"
            form={form}
            onFinish={isNewTag ? handleSaveTag : handleUpdateTag}
            defaultValue={{ title: data?.title, color: data?.color }}
          >
            <Form.Item
              name="title"
              label={translate(`${I18N_BASE_PATH}.labels.labelName`)}
              className="mrg-btm-15"
              min={3}
              initialValue={data?.title}
              rules={[{ required: true, min: 3 }]}
            >
              <Input ref={inputRef} value={searchValue} />
            </Form.Item>

            <>
              <div className="add-tags-details">
                <span className="tag-colors-group-title">
                  {translate(`${I18N_BASE_PATH}.labels.tagColor`)}
                </span>

                <Form.Item
                  rules={[{ required: true }]}
                  name="color"
                  initialValue={data?.color || tagColors[0].color}
                >
                  <Radio.Group
                    className="tag-colors-group"
                    defaultValue={`#${data?.color || '25b161'}`}
                  >
                    {tagColors.map((color) => (
                      <Radio.Button
                        key={color.color}
                        value={color.color}
                        style={{ background: `#${color.color}` }}
                      >
                        <i className="caf-ic_checkmark" />
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </div>

              <Divider className="mrg-top-5 mrg-btm-10" />

              <div className="add-tags-footer-btns">
                <Button ghost className="add-tag-back-btn" onClick={closeModal}>
                  {translate(`${I18N_BASE_PATH}.buttons.cancel`)}
                </Button>

                <Button
                  ghost
                  className="add-tag-create-btn"
                  htmlType="submit"
                  disabled={loading}
                  loading={loading}
                >
                  {translate(`${I18N_BASE_PATH}.buttons.submit`)}
                </Button>
              </div>
            </>
          </Form>
        </Card>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      form,
      isNewTag,
      handleSaveTag,
      handleUpdateTag,
      data,
      translate,
      searchValue,
      loading,
    ]
  );

  return { openModal, TagUpdateModal };
};

export default ModalTagUpdate;
