import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam } from 'use-query-params';
import _ from 'lodash';

import SearchFilter from 'components/Filters/SearchFilter';
import UpdatedAtFilter from 'components/Filters/UpdatedAtFilter';
import CustomStatusFilter from './components/CustomStatusFilter';

const Filter = () => {
  const { t: translate } = useTranslation();

  const [query, setQuery] = useQueryParams({
    _filter: StringParam,
    isAlive: StringParam,
    _order: StringParam,
    _sort: StringParam,
    createdAt: StringParam,
  });

  const setFilter = useCallback(
    (filterName, event) => {
      let queryParams = {
        [filterName]: event && !_.isEmpty(event) ? event : undefined,
      };

      if (filterName === 'createdAt' && Array.isArray(event)) {
        const startDate = event[0]
          .utcOffset(0)
          .set({ second: 0, millisecond: 0 })
          .valueOf();
        const endDate = event[1]
          .utcOffset(0)
          .set({ second: 59, millisecond: 0 })
          .valueOf();

        queryParams = {
          [filterName]: `custom:${startDate}-${endDate}`,
        };
      }

      setQuery(queryParams);
    },
    [setQuery]
  );

  return (
    <div id="profile-filter-component">
      <SearchFilter
        placeholder={translate(
          'pages.private.liveness.livenessTab.components.filter.searchFilter.placeholder'
        )}
        value={query._filter}
        className="profile-select"
        onChange={(values) => setFilter('_filter', values)}
      />
      <UpdatedAtFilter
        title={translate(
          'pages.private.liveness.livenessTab.components.filter.updatedAtFilter.title'
        )}
        value={query.createdAt}
        onChange={(values) => setFilter('createdAt', values)}
      />
      <CustomStatusFilter
        title={translate(
          'pages.private.liveness.livenessTab.components.filter.customStatusFilter.title'
        )}
        value={query.isAlive}
        onChange={(values) => setFilter('isAlive', values)}
      />
    </div>
  );
};

export default Filter;
