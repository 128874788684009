/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyExecutionState from 'components/EmptyExecutionState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import useCadinSPPendencies from './CadinSPPendenciesModal';

import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

const CadinSP = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(
    () => source?.data?.statusCode || source?.statusCode || source?.data,
    [source]
  );

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const { openModal, CadinSPPendenciesModal } = useCadinSPPendencies({
    data: source,
  });

  const handlerQuantity = useMemo(() => {
    if (!parsedData || queryWorked) return { title: null, type: null };

    if (!parsedData?.pendencies || parsedData?.pendencies?.length === 0) {
      return {
        title: translate(
          'components.dataSourceCards.financialInformation.companies.components.cadinSP.index.handlerQuantity.hasNotPendencies'
        ),
        type: 'primary',
      };
    }

    if (parsedData?.pendencies?.length === 1) {
      return {
        title: translate(
          'components.dataSourceCards.financialInformation.companies.components.cadinSP.index.handlerQuantity.hasOnePendency'
        ),
        type: 'danger',
      };
    }

    return {
      title: translate(
        'components.dataSourceCards.financialInformation.companies.components.cadinSP.index.handlerQuantity.hasMoreThanOnePendencies',
        { value: parsedData?.pendencies?.length }
      ),
      type: 'danger',
    };
  }, [parsedData, queryWorked, translate]);

  const handleOpenModal = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <>
      {CadinSPPendenciesModal}
      <DataSourceCardCollapsePanel
        customId="financial-infos-component"
        title={translate(
          'components.dataSourceCards.financialInformation.companies.components.cadinSP.index.cadinSP.columnLabel.title'
        )}
        icon="caf-ic_company"
        toPrint={toPrint}
      >
        <ColumnLabel
          description={handlerQuantity?.title}
          type={handlerQuantity?.type}
          onClick={handlerQuantity?.type === 'danger' && handleOpenModal}
          info={
            !toPrint &&
            translate(
              'components.dataSourceCards.financialInformation.companies.components.cadinSP.index.cadinSP.columnLabel.info'
            )
          }
        >
          {queryWorked ? (
            <>
              {parsedData &&
                (!parsedData?.pendencies || parsedData?.pendencies?.length === 0) && (
                  <span>
                    {translate(
                      'components.dataSourceCards.financialInformation.companies.components.cadinSP.index.cadinSP.columnLabel.hasNotPendencies'
                    )}
                  </span>
                )}
              {!toPrint && parsedData?.pendencies?.length > 0 && (
                <span>
                  {translate(
                    'components.dataSourceCards.financialInformation.companies.components.cadinSP.index.cadinSP.columnLabel.hasNotToPrint'
                  )}
                </span>
              )}
            </>
          ) : (
            <EmptyExecutionState
              statusCode={statusCode}
              message={parsedData?.message || source?.message}
            />
          )}
        </ColumnLabel>
      </DataSourceCardCollapsePanel>
    </>
  );
};

export default CadinSP;
