import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Collapse, Radio } from 'antd';

const { Panel } = Collapse;

const CustomStatusFilter = ({ value, title, onChange }) => {
  const { t: translate } = useTranslation();

  return (
    <Collapse
      defaultActiveKey={['isMatch']}
      ghost
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <div>
          <i className={isActive ? 'caf-ic_arrow_up' : 'caf-ic_arrow_down'} />
        </div>
      )}
    >
      <Panel header={title} key="isMatch">
        <Radio.Group
          defaultValue=""
          value={value || ''}
          onChange={(e) => onChange(e.target.value)}
        >
          <Radio value="true">
            {translate(
              'pages.private.faceAuthenticator.components.filter.customStatusFilter.options.true'
            )}
          </Radio>
          <Radio value="false">
            {translate(
              'pages.private.faceAuthenticator.components.filter.customStatusFilter.options.false'
            )}
          </Radio>
          <Radio value="">
            {translate(
              'pages.private.faceAuthenticator.components.filter.customStatusFilter.options.all'
            )}
          </Radio>
        </Radio.Group>
      </Panel>
    </Collapse>
  );
};

CustomStatusFilter.propTypes = {
  value: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CustomStatusFilter;
