import React from 'react';
import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import NoResults from 'components/NoResults';
import NotAvailable from 'components/NotAvailable';
import CollapseList from '../../../../CollapseList';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companyDetails.subPages.people.businessIdentity';

const OfficersContent = ({
  officers,
  collapsePrintSuffix,
  collapseOpened,
  print = false,
}) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDateOnly } = useI18nFormatters();

  if (!officers.length) {
    return <NoResults />;
  }

  return (
    <CollapseList
      print={print}
      collapsePrintSuffix={collapsePrintSuffix}
      collapseOpened={collapseOpened}
      columns={[
        {
          prop: 'name',
          title: translate(`${I18N_BASE_PATH}.tabs.officers.columns.name`, 'Name'),
          span: 6,
        },
        {
          prop: 'dateOfBirth',
          title: translate(
            `${I18N_BASE_PATH}.tabs.officers.columns.dateOfBirth`,
            'Date Of Birth'
          ),
          span: 6,
        },
        {
          prop: 'endDate',
          title: translate(`${I18N_BASE_PATH}.tabs.officers.columns.endDate`, 'End date'),
          span: 6,
        },
        {
          prop: 'identifiers',
          title: translate(
            `${I18N_BASE_PATH}.tabs.officers.columns.identifiers`,
            'Identifiers'
          ),
          span: 6,
        },
      ]}
      list={officers?.map((officer, index) => {
        return {
          header: {
            index,
            name: officer?.name || <NotAvailable />,
            dateOfBirth: i18nFormatDateOnly(officer?.birthdate, 1, null) || (
              <NotAvailable />
            ),
            endDate: i18nFormatDateOnly(officer?.toDate, 1, null) || <NotAvailable />,
            identifiers: !officer?.identifiers?.length ? (
              <NotAvailable />
            ) : (
              officer?.identifiers
            ),
          },
          body: [
            {
              title: translate(`${I18N_BASE_PATH}.tabs.officers.collapse.pep`, 'PEP'),
              content: officer?.pep
                ? translate(`${I18N_BASE_PATH}.tabs.officers.yes`, 'Yes')
                : translate(`${I18N_BASE_PATH}.tabs.officers.no`, 'No'),
            },
            {
              title: translate(
                `${I18N_BASE_PATH}.tabs.officers.collapse.sanctioned`,
                'Sanctioned'
              ),
              content: officer?.sanctioned
                ? translate(`${I18N_BASE_PATH}.tabs.officers.yes`, 'Yes')
                : translate(`${I18N_BASE_PATH}.tabs.officers.no`, 'No'),
            },
            {
              title: translate(
                `${I18N_BASE_PATH}.tabs.officers.collapse.basel_aml`,
                'Basel AML Score'
              ),
              content: officer?.riskData?.basel_aml?.value,
            },
            {
              title: translate(
                `${I18N_BASE_PATH}.tabs.officers.collapse.cpi_score`,
                'CPI Score'
              ),
              content: officer?.riskData?.cpi_score?.value,
            },
            {
              title: translate(
                `${I18N_BASE_PATH}.tabs.officers.collapse.former`,
                'Former'
              ),
              content: officer?.former
                ? translate(`${I18N_BASE_PATH}.tabs.officers.yes`, 'Yes')
                : translate(`${I18N_BASE_PATH}.tabs.officers.no`, 'No'),
            },
            {
              title: translate(
                `${I18N_BASE_PATH}.tabs.officers.collapse.latest_adress`,
                'Latest adress'
              ),
              content: officer?.addresses,
            },
          ],
        };
      })}
    />
  );
};

export default OfficersContent;
