/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

import useModalConfirm from './ModalConfirm';

import './styles.less';

const ConfirmDataZoop = ({ execution }) => {
  const { openModal, ModalConfirmData } = useModalConfirm({ data: execution });
  return (
    <div id="confirm-data-zoop">
      {ModalConfirmData}
      <div className="confirm-data-cards" onClick={openModal}>
        <div className="confirm-data-info">
          <div className="confirm-data-description">
            <strong>Solicitar correção</strong>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmDataZoop.propTypes = {
  execution: PropTypes.objectOf().isRequired,
};

export default ConfirmDataZoop;
