import React, { createContext, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { message } from 'antd';

import { useFetch } from 'services/hooks';

const ReasonsContext = createContext({});

const ReasonsProvider = ({ children }) => {
  const { t: translate } = useTranslation();

  const { post, get, delete: _delete } = useFetch();

  const [reasonsList, setReasonsList] = useState([]);

  const [loadingReasons, setLoadingReasons] = useState(false);
  const [loadingCreateReason, setLoadingCreateReason] = useState(false);

  const loadReasons = useCallback(
    async ({ searchValue, type } = {}) => {
      try {
        setLoadingReasons(true);

        const response = await get({
          url: '/reasons',
          config: {
            params: {
              _search: searchValue,
              type,
            },
          },
          showMessage: false,
        });

        setReasonsList(response?.docs);
        setLoadingReasons(false);

        return Promise.resolve(response?.docs);
      } catch (error) {
        setLoadingReasons(false);

        return Promise.reject(error);
      }
    },
    [get]
  );

  const createReason = useCallback(
    async (payload) => {
      try {
        setLoadingCreateReason(true);

        await post({
          url: '/reasons',
          payload,
          showError: false,
        });

        setLoadingCreateReason(false);

        message.success(translate('global.hooks.tags.messages.success.createTag'));
      } catch (error) {
        setLoadingCreateReason(false);
      }
    },
    [post, translate]
  );

  const deleteReason = useCallback(
    async (id) => {
      try {
        await _delete({
          url: `/reasons/${id}`,
          showError: false,
        });

        message.success(translate('global.hooks.tags.messages.success.deleteTag'));
      } catch (error) {
        //
      }
    },
    [_delete, translate]
  );

  return (
    <ReasonsContext.Provider
      value={{
        createReason,
        deleteReason,
        loadReasons,
        reasonsList,
        loadingReasons,
        loadingCreateReason,
      }}
    >
      {children}
    </ReasonsContext.Provider>
  );
};

function useReasons() {
  const context = useContext(ReasonsContext);

  if (!context) {
    throw new Error('useReasons must be used within an ReasonsContext');
  }

  return context;
}

export { ReasonsProvider, useReasons };
