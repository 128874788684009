import { Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CollapseTransparent from '../CollapseTransparent';
import './style.less';

const FinancialInfoTable = ({ key, columns, rows, empty, loading }) => {
  const { t } = useTranslation();

  return (
    <>
      <Table
        className="KYB-financial-info-table"
        columns={columns}
        dataSource={rows}
        pagination={false}
        loading={loading}
        size="small"
      />
      {!empty?.length || !rows?.length ? null : (
        <CollapseTransparent key={key} columns={columns} rows={empty} loading={loading} />
      )}
    </>
  );
};

export default FinancialInfoTable;
