import { Col, Collapse, Row, Divider } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DottedLabel from 'components/DottedLabel';

const { Panel } = Collapse;

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.companies.components.spcReportParticipationScore.otherInfos';

function ComplementaryMessage({ source = {}, toPrint = false }) {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);

  const complementaryMessage = useMemo(() => parsedData?.complementaryMessage || {}, [
    parsedData,
  ]);

  return (
    <Collapse className="mrg-top-10" defaultActiveKey={toPrint ? ['1'] : null}>
      <Panel
        key="1"
        disabled={!complementaryMessage?.details?.length}
        header={
          <Col>
            <Row align="middle" justify="space-between">
              <Col span={17}>
                <span className="text-dark text-bold">
                  {translate(`${I18N_BASE_PATH}.index.complementaryMessage.title`)}
                </span>
              </Col>
              <Col className="mrg-right-15">
                <>
                  <DottedLabel
                    description={`(${complementaryMessage?.total || 0}) ${translate(
                      `${I18N_BASE_PATH}.index.restrictions`
                    )}`}
                    type={+complementaryMessage?.total > 0 ? 'danger' : 'primary'}
                  />
                </>
              </Col>
            </Row>
          </Col>
        }
      >
        {complementaryMessage?.details?.map((register, index) => (
          <>
            <Col span={24}>
              <Row align="middle">
                <span className="text-dark text-bold">
                  {`${register?.origin || ''} ${
                    register?.origin && register?.message ? ' - ' : ''
                  } ${register?.message || ''}`}
                </span>
              </Row>
            </Col>
            {index < complementaryMessage?.details?.length - 1 && <Divider />}
          </>
        ))}
      </Panel>
    </Collapse>
  );
}

export default ComplementaryMessage;
