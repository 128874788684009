import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Menu as StandardMenu, Tooltip } from 'antd';
import { Download2, ArrowDown } from '@combateafraude/icons/general';
import Button from 'components/Button';

import './styles.less';

const Menu = ({ items, setDropdownVisible }) => {
  return (
    <StandardMenu className="export-download-menu">
      {items.map((item) => (
        <StandardMenu.Item
          key={item.title}
          onClick={() => {
            item.onClick();
            setDropdownVisible(false);
          }}
        >
          {item.title}
        </StandardMenu.Item>
      ))}
    </StandardMenu>
  );
};

/** Export download dropdown component following the design system export download button */
const ExportDownloadDropdown = ({ items }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  // Manually remove or add the open class from the dropdown button.
  // For some reason, antd does not work as expected when we manually control the visibility of the dropdown.
  useEffect(() => {
    const openClass = 'ant-dropdown-open';
    const element = document.querySelector('.export-download-button');

    if (!element) return;

    if (dropdownVisible) element.classList.add(openClass);
    else element.classList.remove(openClass);
  }, [dropdownVisible]);

  return (
    <Dropdown
      overlay={<Menu items={items} setDropdownVisible={setDropdownVisible} />}
      trigger={['click']}
      placement="bottomRight"
      visible={dropdownVisible}
      onVisibleChange={setDropdownVisible}
      overlayClassName="export-download-dropdown"
    >
      <Button className="export-download-button">
        <Tooltip placement="top" title="Download">
          <div className="flex">
            <Download2 width={24} height={24} className="mrg-right-5" />
            <ArrowDown width={24} height={24} />
          </div>
        </Tooltip>
      </Button>
    </Dropdown>
  );
};

export default ExportDownloadDropdown;

ExportDownloadDropdown.propTypes = {
  items: PropTypes.arrayOf({
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }).isRequired,
};

Menu.propTypes = {
  items: ExportDownloadDropdown.propTypes.items.isRequired,
  setDropdownVisible: PropTypes.func.isRequired,
};
