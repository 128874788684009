/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Spin } from 'antd';

import { isEmpty } from 'utils/isObjectEmpty';

import DossierCard from 'pages/private/Profiles/components/DossierCard';

import { useExecution } from 'hooks/execution';

import EmptyDocuments from '../Shared/EmptyDocuments';
import PrivateFacesetComponent from './PrivateFacesetComponent';

const I18N_BASE_PATH =
  'pages.private.executions.components.tabs.components.verifiedIdentity.components.privateFaceSet.index';

const PrivateFacesetCard = ({ loading }) => {
  const { t: translate } = useTranslation();
  const { execution } = useExecution();

  const noData = useMemo(
    () =>
      !execution ||
      (isEmpty(execution?.images) && isEmpty(execution?.sections?.privateFaceset)),
    [execution]
  );

  return (
    <DossierCard
      title={translate(`${I18N_BASE_PATH}.dossierCard.title`)}
      subtitle={translate(`${I18N_BASE_PATH}.dossierCard.subtitle`)}
      icon="caf-ic_user_active"
      customId="verified-document-card"
    >
      {loading && (
        <div className="custom-document-loading">
          <Spin />
        </div>
      )}

      <>
        {!loading && noData ? (
          <EmptyDocuments type="verified-identity" />
        ) : (
          <Row>
            <Col>
              <PrivateFacesetComponent />
            </Col>
          </Row>
        )}
      </>
    </DossierCard>
  );
};

export default PrivateFacesetCard;
