import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, Cell, Bar, BarChart, XAxis, YAxis } from 'recharts';
import { useTranslation } from 'react-i18next';
import chroma from 'chroma-js';
import useWindowResize from 'customHooks/useWindowResize';

const I18N_BASE_PATH = 'pages.private.dashboard.components.charts.funnel';

const CustomBar = (props) => {
  const { x, y, width, height, fill, payload, index, data } = props;

  const [nextX, setNextX] = useState(x + width);

  useWindowResize(() => {
    const nextPoly = data.at(index + 1);

    if (nextPoly && width > 0) {
      const polyElement = document.getElementById(`poly-${nextPoly.step}`);

      if (polyElement) {
        setNextX(polyElement.points[1].x);
        return;
      }
    }

    setNextX(x + width);
  }, [data, width, index, x]);

  const points = [
    // top-left
    {
      x,
      y,
    },
    // top-right
    {
      x: x + width,
      y,
    },
    // bottom-right
    {
      x: nextX,
      y: y + height + 1,
    },
    // bottom-left
    {
      x,
      y: y + height + 1,
    },
    // top-left
    {
      x,
      y,
    },
  ];

  return (
    <g>
      <polygon
        id={`poly-${payload.step}`}
        points={points.map(({ x, y }) => `${x} ${y}`).join(', ')}
        fill={fill}
      />
    </g>
  );
};

const CustomTick = (props) => {
  const { x, y, payload, tickFormatter } = props;

  const value = tickFormatter(payload.value);

  const rectWidth = value.length * 8 + 10;

  return (
    <g transform={`translate(${x + 50},${y + 15})`}>
      <rect opacity="0.6" rx="9" x={-8} width={rectWidth} height="21" fill="white" />

      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="start"
        fill="#000"
        style={{
          fontSize: '0.9rem',
          fontWeight: 600,
        }}
      >
        {value}
      </text>
    </g>
  );
};

const VerticalFunnel = (props) => {
  const { t: translate } = useTranslation();
  const [colors, setColors] = useState([]);

  const [firstBarWidth, setFirstBarWidth] = useState(0);
  const [firstBarX, setFirstBarX] = useState(0);

  const { data, handleHover, hoveredIndex } = props;

  const baseColors = useMemo(
    () =>
      chroma
        .scale([
          '#116541',
          '#178E5B',
          '#2DB279',
          '#34D690',
          '#34A5D6',
          '#4490FB',
          '#475EFF',
          '#044AEB',
        ])
        .colors(data.length),
    [data]
  );

  const handleBarResize = async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));

    const firstPolyElement = document.getElementById(`poly-${data.at(0)?.step}`);

    if (firstPolyElement) {
      const { x, width } = firstPolyElement.getBBox();

      setFirstBarWidth(width + 0.5);
      setFirstBarX(x - 0.5);
    }
  };

  useEffect(() => {
    setColors(baseColors);
  }, [baseColors]);

  const getStepName = (step) => {
    let narmalizedStepName = step;

    if (step.startsWith('STEP_')) narmalizedStepName = step.replace('STEP_', '');

    return narmalizedStepName.split('-')[0];
  };

  const tickFormatter = (step) => {
    return translate(`${I18N_BASE_PATH}.steps.${getStepName(step)}.description`);
  };

  const handleMouseOver = (e) => {
    const { step } = e;

    const stepIndex = data.findIndex((item) => item.step === step);

    handleHover(stepIndex);
  };

  const handleMouseOut = () => {
    handleHover(null);
    setColors(baseColors);
  };

  useEffect(() => {
    const newColors = data.map((item, index) => {
      if (index === hoveredIndex) {
        return chroma(baseColors[index]).alpha(0.5).hex();
      }

      return baseColors[index];
    });

    setColors(newColors);
  }, [baseColors, data, hoveredIndex]);

  const borderRadius = 10;

  return (
    <ResponsiveContainer width="99%" onResize={handleBarResize}>
      <BarChart
        data={data}
        barCategoryGap={0}
        style={{
          marginLeft: '-10%',
          fontSize: '0.7rem',
        }}
        layout="vertical"
        stackOffset="wiggle"
        margin={{
          top: 30,
        }}
      >
        <XAxis type="number" hide />
        <Bar
          dataKey="value"
          shape={<CustomBar data={data} />}
          isAnimationActive={false}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onBlur={handleMouseOut}
          onFocus={handleMouseOver}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${entry.step}`} fill={colors[index]} />
          ))}
        </Bar>
        <YAxis
          allowDataOverflow
          dataKey="step"
          type="category"
          scale="band"
          tickFormatter={tickFormatter}
          interval={0}
          tickLine={false}
          tick={<CustomTick />}
        />
        <g>
          <path
            d={`M ${firstBarX + borderRadius} 0
           H ${firstBarX + firstBarWidth - borderRadius}
           Q ${firstBarX + firstBarWidth} 0 ${firstBarX + firstBarWidth} ${borderRadius}
           V 30
           H ${firstBarX}
           V 5
           Q ${firstBarX} 0 ${firstBarX + borderRadius} 0`}
            fill="#004af7"
          />
          <text
            x={firstBarX + 35}
            y="20"
            fill="#fff"
            style={{
              fontSize: '0.9rem',
              fontWeight: 600,
            }}
          >
            {translate(`${I18N_BASE_PATH}.title`)}
          </text>
        </g>
      </BarChart>
    </ResponsiveContainer>
  );
};

VerticalFunnel.propTypes = {
  handleHover: PropTypes.func,
  hoveredIndex: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      step: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
};

CustomBar.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
  payload: PropTypes.shape({
    step: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      step: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
};

CustomTick.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  payload: PropTypes.shape({
    step: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
  tickFormatter: PropTypes.func.isRequired,
};

VerticalFunnel.defaultProps = {
  handleHover: () => {},
  hoveredIndex: null,
};

export default VerticalFunnel;
