import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useFetch } from 'services/hooks';
import DottedLabel from 'components/DottedLabel';
import { validationStatus } from 'utils/handlerStatus';
import { toMaskedCpf } from 'utils/formatters';
import defaultImage from 'assets/images/profile-default.png';

import useModalConfirmAction from 'components/ModalConfirmAction';
import { useCompany } from 'hooks/company';
import useLinkPartnerModal from './LinkPartnerModal';

const QSAPartnerDetails = ({ partner }) => {
  const { t: translate } = useTranslation();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { company, refreshCompany } = useCompany();

  const { get, delete: _delete } = useFetch();
  const [partnerDetails, setPartnerDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const { openModal, ModalLinkPartner } = useLinkPartnerModal();

  const handleRemovePartner = useCallback(
    async (cpf) => {
      setLoadingDelete(true);
      await _delete({
        url: `/profile-relationships?cpf=${cpf}&cnpj=${company?.cnpj}`,
      });
      setLoadingDelete(false);
    },
    [_delete, company]
  );

  const { openModal: openConfirmModal, ConfirmActionModal } = useModalConfirmAction({
    action: handleRemovePartner,
    okButton: translate(
      'pages.private.profiles.components.tabs.components.basicInfos.components.company.qsaPartnerDetails.removePartnerModal.okButton',
      'Remove'
    ),
    cancelButton: translate(
      'pages.private.profiles.components.tabs.components.basicInfos.components.company.qsaPartnerDetails.removePartnerModal.cancelButton',
      'Cancel'
    ),
    loading: loadingDelete,
    danger: true,
    title: translate(
      'pages.private.profiles.components.tabs.components.basicInfos.components.company.qsaPartnerDetails.removePartnerModal.title',
      'Unlink partner profile'
    ),
    subtitle: translate(
      'pages.private.profiles.components.tabs.components.basicInfos.components.company.qsaPartnerDetails.removePartnerModal.subtitle',
      'Are you sure you want to remove the link with this profile?'
    ),
    refreshListRef: () => refreshCompany(),
  });

  const getProfilePhoto = useCallback(
    async (profileId) => {
      setLoading(true);
      if (profileId) {
        const response = await get({
          url: `/people/${profileId}`,
          showError: false,
          showMessage: false,
        });

        setPartnerDetails(response.data);
      }
      setLoading(false);
    },
    [get]
  );

  useEffect(() => {
    getProfilePhoto(partner?.profileId);
  }, [getProfilePhoto, partner]);

  const PartnerDetails = useMemo(() => {
    return (
      <>
        {ModalLinkPartner}
        <div className="partner-card-content">
          <img
            className={`partner-image ${!partnerDetails?.image ? 'grayscale-95' : ''}`}
            src={!loading && partnerDetails?.image ? partnerDetails?.image : defaultImage}
            alt={partner?.name || ''}
          />
          <div className="partner-info-container">
            <div className="partner-info">
              <div className="partner-name-function">
                <span>{partner?.name || '-'}</span>
                <span className="partner-fuction">
                  {partner?.function && `(${partner?.function})`}
                </span>
              </div>
              {partner?.onboardingId ? (
                <DottedLabel
                  className="partner-status"
                  key={partner?.profileId}
                  description={translate(
                    translate(
                      validationStatus(partner?.status || 'WAITING_DOCUMENTS').title
                    )
                  )}
                  type={validationStatus(partner?.status || 'WAITING_DOCUMENTS').type}
                />
              ) : (
                <></>
              )}
            </div>
            <span className="partner-cpf">
              {partner?.cpf ? (
                <>
                  {partner?.isSimilarProfileName !== false ? (
                    toMaskedCpf(partner?.cpf || '00000000000')
                  ) : (
                    <p className="gx-font-italic">
                      {translate(
                        'pages.private.profiles.components.tabs.components.basicInfos.components.company.qsaPartnerDetails.partnerDetails.isNotSimilarProfileName'
                      )}
                    </p>
                  )}
                </>
              ) : (
                <p className="gx-font-italic">
                  {translate(
                    'pages.private.profiles.components.tabs.components.basicInfos.components.company.qsaPartnerDetails.partnerDetails.hasNotDocument'
                  )}
                </p>
              )}
            </span>
          </div>
          {partner.isSimilarProfileName === false && (
            <Tooltip
              className="partner-open-profile-icon"
              title={translate(
                'pages.private.profiles.components.tabs.components.basicInfos.components.company.qsaPartnerDetails.partnerDetails.tooltip.title'
              )}
            >
              <i
                className="caf-ic_alert-triangle gx-text-danger font-size-18"
                style={{ marginTop: 3 }}
              />
            </Tooltip>
          )}
          {partner?.partnerNamePjData && (
            <Tag color="blue" className="mrg-left-10">
              {translate(
                'pages.private.profiles.components.tabs.components.basicInfos.components.company.qsaPartnerDetails.partnerDetails.isManualRelationships'
              )}
            </Tag>
          )}
        </div>
      </>
    );
  }, [ModalLinkPartner, loading, partner, partnerDetails, translate]);

  return (
    <>
      {ConfirmActionModal}
      <div className="partner-card">
        {partner?.profileId ? (
          <Row
            align="middle"
            flex
            style={{ display: 'flex', alignItems: 'center', width: '100%' }}
            justify="space-between"
          >
            <Col>
              <Link
                to={`/profile/people/${partner?.profileId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {PartnerDetails}
              </Link>
            </Col>
            <Col>
              <Tooltip
                className="partner-unlink-button"
                title={translate(
                  'pages.private.profiles.components.tabs.components.basicInfos.components.company.qsaPartnerDetails.partnerUnlinkButton',
                  'Unlink partner profile'
                )}
              >
                <Button
                  type="default"
                  danger
                  onClick={() => openConfirmModal(partner?.cpf)}
                >
                  <i className="caf-ic_circle_false" />
                </Button>
              </Tooltip>
              <Link
                to={`/profile/people/${partner?.profileId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Tooltip
                  className="partner-open-profile-icon"
                  title={translate(
                    'pages.private.profiles.components.tabs.components.basicInfos.components.company.qsaPartnerDetails.openProfileButton',
                    'Open partner profile'
                  )}
                >
                  <i className="caf-ic_arrow_right" />
                </Tooltip>
              </Link>
            </Col>
          </Row>
        ) : (
          <div>
            <Row
              align="middle"
              flex
              style={{ display: 'flex', alignItems: 'center', width: '100%' }}
              justify="space-between"
            >
              <Col>{PartnerDetails}</Col>
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    openModal(partner?.name);
                  }}
                >
                  <Tooltip
                    title={translate(
                      'pages.private.profiles.components.tabs.components.basicInfos.components.company.qsaPartnerDetails.linkPartnerButton',
                      'Link partner profile'
                    )}
                  >
                    <i className="caf-ic_users" />
                  </Tooltip>
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
};

QSAPartnerDetails.defaultProps = {
  partner: PropTypes.shape({}),
};

export default QSAPartnerDetails;
