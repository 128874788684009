import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useHeaderFilter, headerFilters } from 'hooks/headerFilter';
import { useTranslation } from 'react-i18next';
import { useQueryParams, DateParam } from 'use-query-params';

import { Radio, DatePicker, Button } from 'antd';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import './styles.less';

const { RangePicker } = DatePicker;

const I18N_BASE_PATH = 'components.dataHeader.headerFilter';

const HeaderFilter = () => {
  const { t: translate } = useTranslation();
  const { changeFilterPeriod, periodFilter, changeFilterPeriodTime } = useHeaderFilter();
  const { pathname } = useLocation();

  const [openDateModal, setOpenDateModal] = useState(false);
  const [query, setQuery] = useQueryParams({
    customStartDate: DateParam,
    customEndDate: DateParam,
  });

  const customDate = useMemo(() => {
    const date = [moment(), moment()];
    if (query.customStartDate) {
      date[0] = moment(query.customStartDate);
    }
    if (query.customEndDate) {
      date[1] = moment(query.customEndDate);
    }

    return date;
  }, [query]);

  const setCustomDate = (date) => {
    if (!date) {
      setQuery({
        customStartDate: undefined,
        customEndDate: undefined,
      });
    } else {
      const [startDate, endDate] = date;

      setQuery({
        customStartDate: startDate.toDate(),
        customEndDate: endDate.toDate(),
      });
    }
  };

  const handlePeriodChange = (event) => {
    setCustomDate(undefined);
    changeFilterPeriod(event?.target.value);
  };

  const handleCustomDateChange = useCallback(() => {
    changeFilterPeriod(headerFilters.custom);
    setOpenDateModal(false);
  }, [changeFilterPeriod]);

  const onOpenDateModalChange = (status) => {
    if (periodFilter !== headerFilters.custom) setCustomDate(undefined);

    setOpenDateModal(status);
  };

  useEffect(() => {
    if (periodFilter === headerFilters.custom && customDate) {
      const [startDate, endDate] = customDate;

      changeFilterPeriodTime(startDate, endDate.hour(23).minute(59).second(59));
    }
  }, [changeFilterPeriodTime, customDate, periodFilter]);

  return (
    <>
      <div>
        <span className="filter-title">
          {translate(`${I18N_BASE_PATH}.filterByPeriod`, 'Filter by period')}:
        </span>
        <Radio.Group value={periodFilter}>
          {Object.keys(headerFilters).map((key) =>
            key !== headerFilters.custom ? (
              <Radio.Button
                id={`click_filter_${key}_${pathname.substring(1)}`}
                key={key}
                value={headerFilters[key]}
                onClick={handlePeriodChange}
              >
                {translate(`${I18N_BASE_PATH}.${key}`)}
              </Radio.Button>
            ) : (
              <Radio.Button
                id={`click_filter_custom_${pathname.substring(1)}`}
                key={key}
                value={key}
                onClick={() => onOpenDateModalChange(true)}
              >
                {translate(`${I18N_BASE_PATH}.${key}`)}
                <RangePicker
                  open={openDateModal}
                  className="header-datepicker"
                  format="DD/MM/YYYY"
                  onOpenChange={onOpenDateModalChange}
                  onChange={(dates) => setCustomDate(dates)}
                  showTime={false}
                  value={customDate}
                  renderExtraFooter={() => (
                    <footer className="footer-datepicker">
                      <Button
                        onClick={handleCustomDateChange}
                        disabled={!customDate}
                        size="small"
                        type="primary"
                      >
                        {translate(`${I18N_BASE_PATH}.apply`, 'Apply')}
                      </Button>
                    </footer>
                  )}
                />
              </Radio.Button>
            )
          )}
        </Radio.Group>
      </div>
    </>
  );
};

export default HeaderFilter;
