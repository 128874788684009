import React from 'react';
import { Tooltip } from 'antd';
import CountryFlag from 'components/CountryFlag';

import './styles.less';

const TooltipToRenderNextFlags = ({
  children,
  flagsTitles = [],
  tooltipBackground = 'white',
}) => (
  <Tooltip
    color={tooltipBackground}
    title={flagsTitles.map((element) => (
      <div className="wrapper-tooltip-to-render-flags">
        <CountryFlag country={element.flag} showTooltip={false} />
        <span>{element.title}</span>
      </div>
    ))}
  >
    {children}
  </Tooltip>
);

export default TooltipToRenderNextFlags;
