import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import './styles.less';

const ViewMore = ({ onClick, title, type, icon, text }) => {
  return (
    <div id="view-more-container">
      <Button type={type} onClick={onClick}>
        {!text && <i className={icon} />}
        {title}
      </Button>
    </div>
  );
};

ViewMore.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.bool,
};

ViewMore.defaultProps = {
  title: '',
  type: 'default',
  icon: 'caf-ic_plus',
  text: false,
};

export default ViewMore;
