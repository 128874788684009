/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Divider } from 'antd';

import { handlerAssociateRegister } from 'utils/associateRegisters';

import PageTitle from 'components/PageTitle';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import { splitInfos } from './utils/splitInfos';

import './styles.less';

const CNDTDebtsItems = ({ data, toPrint }) => {
  const { t: translate } = useTranslation();

  const customTitle = useMemo(() => {
    return toPrint
      ? `${translate(
          'components.dataSourceCards.laborInformation.companies.components.cndt.cndtDebtsItems.index.customTitle.toPrint'
        )} ${
          data?.number ||
          translate(
            'components.dataSourceCards.laborInformation.companies.components.cndt.cndtDebtsItems.index.customTitle.empty'
          )
        }`
      : `${translate(
          'components.dataSourceCards.laborInformation.companies.components.cndt.cndtDebtsItems.index.customTitle.hasNotToPrint'
        )} ${
          data?.number ||
          translate(
            'components.dataSourceCards.laborInformation.companies.components.cndt.cndtDebtsItems.index.customTitle.empty'
          )
        }`;
  }, [toPrint, data, translate]);

  return (
    <div id="executions-cndt-debts-items">
      <PageTitle
        title={customTitle}
        subtitle={`${
          data?.expedition
            ? `${translate(
                'components.dataSourceCards.laborInformation.companies.components.cndt.cndtDebtsItems.index.cndtDebtsItems.subtitle.hasExpedition'
              )} ${data?.expedition}`
            : translate(
                'components.dataSourceCards.laborInformation.companies.components.cndt.cndtDebtsItems.index.cndtDebtsItems.subtitle.hasNotExpedition'
              )
        } - ${
          data?.expiresOn
            ? `${translate(
                'components.dataSourceCards.laborInformation.companies.components.cndt.cndtDebtsItems.index.cndtDebtsItems.subtitle.hasExpiresOn'
              )} ${data?.expiresOn}`
            : translate(
                'components.dataSourceCards.laborInformation.companies.components.cndt.cndtDebtsItems.index.cndtDebtsItems.subtitle.hasNotExpiresOn'
              )
        }`}
      />

      <Row className="cndt-debts-items-title reset-mrg">
        <strong>
          {handlerAssociateRegister(data?.lawsuitsList?.length, undefined, translate)}
        </strong>
      </Row>

      <Divider className="custom-divider" />

      <Row className="cndt-debts-data reset-mrg">
        {data?.lawsuitsList?.map(({ lawsuit }) => (
          <ColumnLabel
            key={lawsuit}
            description={splitInfos(lawsuit)?.number || '-'}
            type="danger"
          >
            {splitInfos(lawsuit)?.court}
          </ColumnLabel>
        ))}
      </Row>

      <Row className="reset-mrg">
        <span className="cndt-debts-observation">
          {data?.observation
            ? `${data?.observation}`
            : translate(
                'components.dataSourceCards.laborInformation.companies.components.cndt.cndtDebtsItems.index.cndtDebtsItems.footer.empty'
              )}
        </span>
      </Row>
    </div>
  );
};

export default CNDTDebtsItems;
