import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import SMARTPHONE from 'assets/images/selfie_smartphone.svg';

import { useProfileTemplate } from 'hooks/profileTemplate';
import PermissionWrapper from 'routes/PermissionWrapper';

import useOnboardings from 'pages/private/ProfileModels/ProfileModels/components/Links/components/CreateLinkModal';
import useNewRegister from 'components/ModalCreateNewRegister';

import useModalProfileTemplateApiGuide from 'components/ModalProfileTemplateApiGuide';
import PageTitle from 'components/PageTitle';
import Button from 'components/Button';
import RectangleButton from 'components/Button/RectangleButton';

import './styles.less';
import { useAuth } from 'hooks/auth';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';

const Success = () => {
  const { t: translate } = useTranslation();
  const { openModal, ProfileTemplateApiGuideModal } = useModalProfileTemplateApiGuide();
  const { user: loggedUser } = useAuth();

  const { profileTemplate } = useProfileTemplate();
  const { openModal: openOnboardingsModal, CreateLinkModal } = useOnboardings({
    refreshListRef: () => {},
  });

  const {
    openModal: openExecutionRegisterModal,
    CreateNewRegisterModal: ExecutionRegisterModal,
  } = useNewRegister({
    type: 'execution',
    url: hasFeatureFlag('isWorkflowBuilder', loggedUser) ? '/person' : '/executions',
    pageTitle: {
      title: translate(
        'components.modalCreateExecution.index.CreateExecution.pageTitle.title'
      ),
      subtitle: translate(
        hasFeatureFlag('isWorkflowBuilder', loggedUser)
          ? 'components.modalCreateExecution.index.CreateExecution.pageTitle.subTitleWorkflow'
          : 'components.modalCreateExecution.index.CreateExecution.pageTitle.subTitle'
      ),
    },
    buttonsText: {
      loading: translate(
        'components.modalCreateExecution.index.CreateExecution.form.button.creatingExecution'
      ),
      default: translate(
        'components.modalCreateExecution.index.CreateExecution.form.button.submit'
      ),
    },
  });

  return (
    <div id="profile-model-create-success-component">
      {ProfileTemplateApiGuideModal}
      {CreateLinkModal}
      {ExecutionRegisterModal}
      <Row className="gx-flex-nowrap no-mrg">
        <Col className="flex-column gx-justify-content-center" style={{ maxWidth: 900 }}>
          <PageTitle
            title={translate(
              'pages.private.profileModels.profileModelCreate.success.index.success.pageTitle.title'
            )}
            subtitle={translate(
              'pages.private.profileModels.profileModelCreate.success.index.success.pageTitle.subtitle'
            )}
          />
          <Row gutter={[16, 16]} className="mrg-top-40">
            <Col xs={24} sm={12} md={8} lg={6}>
              <RectangleButton
                title={translate(
                  'pages.private.profileModels.profileModelCreate.success.index.success.button.onboardingWeb.title'
                )}
                description={translate(
                  'pages.private.profileModels.profileModelCreate.success.index.success.button.onboardingWeb.description'
                )}
                icon="caf-ic_link"
                onClick={openOnboardingsModal}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <RectangleButton
                title={translate(
                  'pages.private.profileModels.profileModelCreate.success.index.success.button.webPanel.title'
                )}
                description={translate(
                  'pages.private.profileModels.profileModelCreate.success.index.success.button.webPanel.description'
                )}
                icon="caf-ic_edit"
                onClick={openExecutionRegisterModal}
              />
            </Col>
            <PermissionWrapper requiredPermissions={['integration-guide:read']}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <RectangleButton
                  title={translate(
                    'pages.private.profileModels.profileModelCreate.success.index.success.button.api.title'
                  )}
                  description={translate(
                    'pages.private.profileModels.profileModelCreate.success.index.success.button.api.description'
                  )}
                  icon="caf-ic_code"
                  onClick={() => openModal(profileTemplate, true)}
                />
              </Col>
            </PermissionWrapper>
            <Col xs={24} sm={12} md={8} lg={6}>
              <RectangleButton
                title={translate(
                  'pages.private.profileModels.profileModelCreate.success.index.success.button.sdkMobile.title'
                )}
                //  description={translate(
                //   'pages.private.profileModels.profileModelCreate.success.index.success.button.sdkMobile.description'
                // )}
                icon="caf-ic_smartphone"
                comingSoon
              />
            </Col>
          </Row>
          <Row className="mrg-top-15">
            <Col xs={24}>
              <Button
                type="link"
                href="/profile-templates/profile-models?active=true"
                className="link-btn"
              >
                <i className="caf-ic_arrow-right pdd-right-5" />
                {translate(
                  'pages.private.profileModels.profileModelCreate.success.index.success.link'
                )}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col flex={1} span={6} className="flex center mrg-left-60">
          <img
            className="grayscale-95"
            src={SMARTPHONE}
            alt={translate(
              'pages.private.profileModels.profileModelCreate.success.index.success.image.alt'
            )}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Success;
