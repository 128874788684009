/* eslint-disable react/prop-types */
import React, { useState, useCallback, useMemo } from 'react';
import { Modal } from 'antd';

import Items from './components/Items';

import './styles.less';

const PersonalLaborAndFinancialInformation = ({
  data,
  title,
  socialAssistance,
  classOrganization,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const PersonalLaborAndFinancialInformationModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        onCancel={closeModal}
        footer={null}
        style={{ top: 40 }}
        width={700}
      >
        <div id="personal-labor-and-financial-information" className="no-mrg no-pdd">
          <Items
            data={data}
            title={title}
            socialAssistance={socialAssistance}
            classOrganization={classOrganization}
          />
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, data, title, socialAssistance, classOrganization]
  );

  return { openModal, closeModal, PersonalLaborAndFinancialInformationModal };
};

export default PersonalLaborAndFinancialInformation;
