import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import EmptyExecutionState from 'components/EmptyExecutionState';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.shared.spcRestrictionsScore.contactInfos';

const ContactContent = ({ source = {}, toPrint, type }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const parsedData = useMemo(() => source?.data || source, [source]);

  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const phones = useMemo(() => {
    if (!parsedData) return undefined;

    const phonesData = parsedData?.lastTelephoneInformed;

    if (!phonesData?.details?.length) return 'no-data';

    return phonesData?.details || [];
  }, [parsedData]);

  const parsedTelephone = useMemo(() => {
    if (!Array.isArray(phones) || !phones?.length > 0) return <></>;

    if (phones?.length > 0) {
      const { phoneAreaCode, phoneNumber, dateLastConsult } = phones[0];

      const formattedNumber = `(${phoneAreaCode}) ${phoneNumber}`;
      const formattedDate = i18nFormatDate(dateLastConsult, 1);

      return (
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col>
              <i className="caf-ic_smartphone mrg-right-5" />
              <span>{formattedNumber}</span>
            </Col>
            <Col>
              <i className="caf-ic_check_d mrg-right-5" />
              <span>
                {translate(`${I18N_BASE_PATH}.index.columns.dateLastConsult`)}:{' '}
              </span>
              {formattedDate}
            </Col>
          </Row>
        </Col>
      );
    }

    return <></>;
  }, [translate, i18nFormatDate, phones]);

  const formattedCellphone = useMemo(() => {
    const { areaCode, number } = parsedData?.customer?.cellphone || {};

    let formattedNumber = ``;

    if (areaCode) formattedNumber = formattedNumber.concat(`(${areaCode})`);
    if (areaCode && number) formattedNumber = formattedNumber.concat(' ');
    if (number) formattedNumber = formattedNumber.concat(number);

    return formattedNumber;
  }, [parsedData]);

  return queryWorked ? (
    <DataSourceCardCollapsePanel
      title={translate(`${I18N_BASE_PATH}.index.title`)}
      icon="caf-ic_contact_"
      toPrint={toPrint}
      customId="report-spc-last-phone-card"
    >
      <Row>
        {type === 'pj' && (
          <>
            <Col span={24}>
              {phones === 'no-data' && (
                <EmptyExecutionState
                  statusCode={statusCode}
                  message={translate(
                    `${I18N_BASE_PATH}.index.columns.emptyExecutionStateMessage`
                  )}
                />
              )}
            </Col>
            {parsedTelephone}
          </>
        )}
      </Row>

      {type === 'pf' && (
        <Row>
          <Col span={24}>
            {!parsedData?.customer?.cellphone && (
              <EmptyExecutionState
                statusCode={statusCode}
                message={translate(
                  `${I18N_BASE_PATH}.index.columns.emptyExecutionStateMessage`
                )}
              />
            )}
          </Col>
          <Col span={24}>
            <Col>
              <i className="caf-ic_smartphone mrg-right-5" />
              <span>{formattedCellphone || 'Não foram encontrados telefones'}</span>
            </Col>
          </Col>
        </Row>
      )}
    </DataSourceCardCollapsePanel>
  ) : (
    <DataSourceCardCollapsePanel
      title={translate(`${I18N_BASE_PATH}.index.title`)}
      icon="caf-ic_contact_"
      toPrint={toPrint}
      customId="report-spc-last-phone-card"
    >
      <Row gutter={24}>
        <Col span={24}>
          <EmptyExecutionState
            statusCode={statusCode}
            message={source?.message || parsedData?.message}
          />
        </Col>
      </Row>
    </DataSourceCardCollapsePanel>
  );
};

ContactContent.propTypes = {
  person: PropTypes.shape({
    basicData: PropTypes.shape({
      phoneNumber: PropTypes.string,
      companyEmail: PropTypes.string,
    }),
    contact: PropTypes.shape({
      phones: PropTypes.shape({
        lastConsultation: PropTypes.objectOf(),
      }),
      emails: PropTypes.shape({
        lastConsultation: PropTypes.objectOf(),
      }),
    }),
    status: PropTypes.string,
  }),
  source: PropTypes.objectOf(),
  toPrint: PropTypes.bool,
  type: PropTypes.string,
};

ContactContent.defaultProps = {
  person: undefined,
  source: null,
  toPrint: false,
  type: null,
};
export default ContactContent;
