/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Person as PersonIcon } from '@combateafraude/icons/users';
import { Company as CompanyIcon } from '@combateafraude/icons/general';
import { Col, Row } from 'antd';

const EntityHeader = ({ entity }) => {
  const iconProps = {
    width: 24,
    height: 24,
    className: 'mrg-right-10',
  };

  return (
    <Row className="pdd-vertical-10 center">
      <Col span={24} className="flex" style={{ alignItems: 'center' }}>
        {entity.type === 'company' ? (
          <CompanyIcon {...iconProps} />
        ) : (
          <PersonIcon {...iconProps} />
        )}
        <span className="font-size-16">{entity.label}</span>
      </Col>
    </Row>
  );
};

export default EntityHeader;

EntityHeader.propTypes = {
  entity: PropTypes.any.isRequired,
};
