import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Collapse, Radio, Form, Select } from 'antd';

const { Panel } = Collapse;

const I18N_BASE_PATH = 'pages.private.liveness.whiteListTab.components.filter.index';

const CategoryFilter = ({ type, value, setFilter }) => {
  const { t: translate } = useTranslation();
  const [searchType, setSearchType] = useState(type);

  const onChange = (e) => {
    setSearchType(e.target.value);
    setFilter(searchType, undefined);
  };

  return (
    <Collapse
      defaultActiveKey={['category']}
      ghost
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <div>
          <i className={isActive ? 'caf-ic_arrow_up' : 'caf-ic_arrow_down'} />
        </div>
      )}
    >
      <Panel
        header={translate(`${I18N_BASE_PATH}.searchFilter.collapse.panel.header`)}
        key="category"
      >
        <Radio.Group
          value={searchType}
          onChange={(e) => onChange(e)}
          defaultValue={type || 'personId'}
        >
          <Radio value="personId">
            {translate(`${I18N_BASE_PATH}.searchFilter.collapse.panel.radioButton.cpf`)}
          </Radio>
          <Radio value="trackingId">
            {translate(
              `${I18N_BASE_PATH}.searchFilter.collapse.panel.radioButton.trackingId`
            )}
          </Radio>
        </Radio.Group>

        <Form.Item name={searchType} className="mrg-left-5">
          <Select
            mode="tags"
            defaultValue={value}
            value={value}
            placeholder={translate(
              `${I18N_BASE_PATH}.searchFilter.collapse.panel.placeholder`
            )}
            dropdownClassName="gx-d-none"
            className="profile-select mrg-top-10"
            removeIcon={<i className="caf-ic_circle_false" />}
            onChange={(values) => setFilter(searchType, values)}
          />
        </Form.Item>
      </Panel>
    </Collapse>
  );
};

CategoryFilter.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default CategoryFilter;
