import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FeatureFlagWrapper from 'components/FeatureFlagWrapper';

import { useI18nFormatters } from 'hooks/i18nFormatters';

// import currencyFormatter from 'utils/currencyFormatter';

import './styles.less';

const PriceWidget = ({ pricePerProfile, pricePerMonth, hideInfos }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatCurrency } = useI18nFormatters();

  return (
    <FeatureFlagWrapper featureFlag="costPrices">
      <div id="profile-model-price-widget-component" className="gx-w-100 pdd-left-50">
        <div>
          <span>
            {translate(
              'pages.private.profileModels.components.priceWidget.index.title.before'
            )}{' '}
            <br />{' '}
            {translate(
              'pages.private.profileModels.components.priceWidget.index.title.after'
            )}
          </span>
          <div className="prices">
            <strong>{i18nFormatCurrency(pricePerProfile, 'pt-BR')}</strong>
            {!!pricePerMonth && (
              <span>
                + {i18nFormatCurrency(pricePerMonth, 'pt-BR')} /{' '}
                {translate(
                  'pages.private.profileModels.components.priceWidget.index.price.period'
                )}
                {!hideInfos && '*'}
              </span>
            )}
          </div>
        </div>
        {!!pricePerMonth && !hideInfos && (
          <span className="warning">
            *
            {translate('pages.private.profileModels.components.priceWidget.index.footer')}
          </span>
        )}
      </div>
    </FeatureFlagWrapper>
  );
};

PriceWidget.propTypes = {
  pricePerProfile: PropTypes.number,
  pricePerMonth: PropTypes.number,
  hideInfos: PropTypes.bool,
};

PriceWidget.defaultProps = {
  pricePerProfile: 0,
  pricePerMonth: 0,
  hideInfos: true,
};

export default PriceWidget;
