import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { message, Modal, Button } from 'antd';
import PageTitle from 'components/PageTitle';

// import { useGroup } from 'hooks/group';

import api from 'services/api';

import { displayProductsInfos } from 'utils/renderProducts';

const UpdateGroupPermissions = ({ setGroupPermissions }) => {
  const { id: groupId } = useParams();
  // const { loadGroup } = useGroup();
  const { t: translate } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const [permission, setPermission] = useState({});
  const [loadingGroupPermissions, setLoadingGroupPermissions] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(({ id, name, product }) => {
    setPermission({ id, name, product });
    setModalVisible(true);
  }, []);

  const onUpdateGroupPermissions = useCallback(async () => {
    try {
      setLoadingGroupPermissions(true);
      await api.put(
        `${process.env.REACT_APP_BASE_URL_AUTH_API}/users-groups/${groupId}`,
        {
          policyGroups: {
            [permission?.product]: permission?.id || 'no_access',
          },
        }
      );

      // loadGroup({ id: groupId });
      setGroupPermissions({ id: permission?.id, name: permission?.name });
      closeModal();
      message.success(
        translate(
          'pages.private.groups.components.groupActions.updateGroupPermissions.onUpdateGroupPermissions.messageSuccess'
        )
      );
    } catch (err) {
      message.error(
        translate(
          'pages.private.groups.components.groupActions.updateGroupPermissions.onUpdateGroupPermissions.messageError'
        )
      );
    } finally {
      setLoadingGroupPermissions(false);
    }
  }, [permission, groupId, setGroupPermissions, closeModal, translate]);

  const UpdateGroupPermissionsModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        footer={null}
        width={550}
        closable={false}
        wrapClassName="caf-modal"
        onCancel={closeModal}
      >
        <div className="no-mrg no-pdd">
          <PageTitle
            title={translate(
              'pages.private.groups.components.groupActions.updateGroupPermissions.pageTitle.title'
            )}
            align="center"
          />

          <div className="flex center mrg-top-25 mrg-btm-35">
            <span className="text-center font-size-16">
              {translate(
                'pages.private.groups.components.groupActions.updateGroupPermissions.spanContent'
              )}{' '}
              <strong className="text-dark">
                {displayProductsInfos(permission?.product)?.title}
              </strong>{' '}
              {translate(
                'pages.private.groups.components.groupActions.updateGroupPermissions.spanContentAfter'
              )}{' '}
              <strong className="text-dark">{permission?.name}</strong>?{' '}
            </span>
          </div>

          <div className="flex center-end">
            <Button
              type="default"
              onClick={closeModal}
              className="btn-cancel"
              disabled={loadingGroupPermissions}
            >
              {translate(
                'pages.private.groups.components.groupActions.updateGroupPermissions.buttons.cancel'
              )}
            </Button>
            <Button
              type="primary"
              onClick={onUpdateGroupPermissions}
              disabled={loadingGroupPermissions}
              loading={loadingGroupPermissions}
            >
              {loadingGroupPermissions
                ? translate(
                    'pages.private.groups.components.groupActions.updateGroupPermissions.buttons.loading'
                  )
                : translate(
                    'pages.private.groups.components.groupActions.updateGroupPermissions.buttons.okText'
                  )}
            </Button>
          </div>
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      permission,
      loadingGroupPermissions,
      onUpdateGroupPermissions,
      translate,
    ]
  );

  return { openModal, closeModal, UpdateGroupPermissionsModal };
};

export default UpdateGroupPermissions;
