import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

import { useFetch } from 'services/hooks';
import { cleanApi } from 'services/api';

const UploadManagerContext = createContext({});

const I18N_BASE_PATH = 'components.files.transactionRecordsFiles.index';

const UploadManagerProvider = ({ children }) => {
  const { t: translate } = useTranslation();

  const [widgetOpen, setWidgetOpen] = useState(false);
  const [items, setItems] = useState([]);

  const [success, setSuccess] = useState(0);
  const [error, setError] = useState(0);
  const [uploading, setUploading] = useState(false);

  const { get: getUploadRequest } = useFetch();
  const { post: postFiles } = useFetch();

  const openUploadWidget = useCallback(() => {
    setWidgetOpen(true);
  }, []);

  const closeUploadWidget = useCallback(() => {
    setWidgetOpen(false);
    setItems([]);
    setSuccess(0);
    setError(0);
    setUploading(false);
  }, []);

  const setFileToUpload = useCallback((file, extraData) => {
    const newFile = { file, ...extraData };
    setItems((stateItems) => [...stateItems, newFile]);
  }, []);

  const fileToArrayBuffer = useCallback(async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject();
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const upload = useCallback(
    async (file, extraData, { onProgress, onError, onSuccess }) => {
      if (extraData.uploading || extraData.uploaded || extraData.error) return;

      const config = {
        // headers: { 'Content-Type': 'multipart/form-data' },
        headers: { 'Content-Type': file.type },
        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          onProgress(percent);
        },
      };

      const data = await fileToArrayBuffer(file);

      const currentItemIndex = items.findIndex((item) => item.file.uid === file.uid);
      const updatedItems = [...items];
      updatedItems[currentItemIndex].uploading = true;
      setItems(updatedItems);
      setUploading(true);

      try {
        // busca urls assinadas do bucket
        const { uploadUrl, key } = await getUploadRequest({
          url: `/upload-request?permanent=true&fileName=${file.name}&contentType=${file.type}`,
          showMessage: false,
        });

        // envia o arquivo para o bucket
        await cleanApi.put(uploadUrl, data, config);

        // salva a referência do arquivo no banco
        const fileInfo = { key, fileName: file.name, mimeType: file.type };
        const transactionId = extraData.transactionId || extraData.executionId;
        if (extraData.companyId || extraData.personId) {
          const url = extraData.companyId
            ? `/companies/${extraData.companyId}/files`
            : `/people/${extraData.personId}/files`;

          await postFiles({ url, payload: fileInfo });
        } else if (extraData.isTransactionRecord && transactionId) {
          await postFiles({
            showMessage: false,
            url: `/executions/${transactionId}/transaction-records`,
            payload: { type: 'FILE', source: 'TRUST', file: fileInfo },
          });
        }

        updatedItems[currentItemIndex].uploaded = true;
        updatedItems[currentItemIndex].uploading = false;
        updatedItems[currentItemIndex].key = key;
        setItems(updatedItems);
        setSuccess((curState) => curState + 1);
        onSuccess();
      } catch (err) {
        if (extraData.isTransactionRecord && err?.response?.status === 400)
          message.error(translate(`${I18N_BASE_PATH}.uploadAction.limitExceeded`));

        updatedItems[currentItemIndex].error = true;
        updatedItems[currentItemIndex].uploading = false;
        setItems(updatedItems);
        setError((curState) => curState + 1);
        onError(err);
      }
    },
    [fileToArrayBuffer, getUploadRequest, items, postFiles, translate]
  );

  const reupload = useCallback(
    async (file, extraData, { onProgress, onError, onSuccess }) => {
      setError((curState) => curState - 1);
      upload(file, extraData, { onProgress, onError, onSuccess });
    },
    [upload]
  );

  useEffect(() => {
    if (success + error === items?.length) {
      setUploading(false);
      return;
    }
    setUploading(true);
  }, [success, error, items]);

  return (
    <UploadManagerContext.Provider
      value={{
        widgetOpened: widgetOpen,
        openUploadWidget,
        closeUploadWidget,
        setFileToUpload,
        items,
        upload,
        reupload,
        uploading,
        success,
        error,
      }}
    >
      {children}
    </UploadManagerContext.Provider>
  );
};

function useUploadManager() {
  const context = useContext(UploadManagerContext);

  if (!context) {
    throw new Error('useUploadManager must be used within an UploadManagerContext');
  }

  return context;
}

export { UploadManagerProvider, useUploadManager };
