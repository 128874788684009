import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Col } from 'antd';

import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

import CircleIcon from 'components/Icon/CircleIcon';

import { translateLevel, translateContactAndRelation } from './utils/translate';

import './styles.less';

const ContactAndRelationCard = ({
  name,
  type,
  content,
  level,
  relation,
  span,
  warning,
  ...rest
}) => {
  const { t: translate } = useTranslation();

  const customClasses = useMemo(() => {
    return relation
      ? 'relation-and-phone-card-info custom-relation'
      : 'relation-and-phone-card-info';
  }, [relation]);

  return (
    <Col span={span} id="executions-relation-and-phone-card" {...rest}>
      <CircleIcon icon={translateContactAndRelation(type)?.icon} warning={warning} />

      <div className={customClasses}>
        {relation && (
          <>
            <strong className="custom-title max-lines-2">
              {capitalizeFirstLetter(name)}
            </strong>
            <span>
              {translate(
                'pages.private.executions.components.contactAndRelationCard.index.contactAndRelationCard.description'
              )}
              : <strong>{translate(translateContactAndRelation(type)?.title)}</strong>
            </span>
            <span>
              {translate(
                'pages.private.executions.components.contactAndRelationCard.index.contactAndRelationCard.text'
              )}
              : <strong>{translate(translateLevel(level))}</strong>
            </span>
          </>
        )}

        {!relation && (
          <>
            <strong>{translate(translateContactAndRelation(type)?.title)}</strong>
            <span>{content}</span>
          </>
        )}
      </div>
    </Col>
  );
};

ContactAndRelationCard.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  level: PropTypes.string,
  content: PropTypes.string,
  span: PropTypes.number.isRequired,
  relation: PropTypes.bool,
  warning: PropTypes.bool,
};

ContactAndRelationCard.defaultProps = {
  name: '',
  type: '',
  level: '',
  content: '',
  relation: false,
  warning: false,
};

export default ContactAndRelationCard;
