import React from 'react';
import CountryFlag from 'components/CountryFlag';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import classnames from 'classnames';
import NewCircleIcon from 'components/Icon/NewCircleIcon';
import RenderFlagsOfCompany from '../TabsComponent/CompanyInfo/components/RenderFlagsOfCompany';

import './styles.less';

const { Title } = Typography;

const Icon = ({ icon, newIcon }) => {
  if (newIcon) {
    return (
      <NewCircleIcon backgroundBlue icon={newIcon} extraClass={`${icon} no-mrg-left`} />
    );
  }

  return <img src={icon} alt="Tab icon" className="icon" />;
};

const TabTitle = ({
  className,
  icon,
  title,
  country,
  subtitle,
  countries,
  priorityCountry,
  newIcon,
  render = true,
}) => {
  if (!render) return null;

  return (
    <div className={classnames('KYB-tab-title', className)}>
      <Icon icon={icon} newIcon={newIcon} />
      <div className="wrapper-title-and-subtitle">
        <Title className="no-mrg" level={4}>
          {title}
        </Title>
        {subtitle && <div className="subtitle">{subtitle}</div>}
      </div>
      {countries && (
        <RenderFlagsOfCompany countries={countries} priorityCountry={priorityCountry} />
      )}
      {country && <CountryFlag country={country} />}
    </div>
  );
};

export default TabTitle;

TabTitle.propTypes = {
  className: PropTypes.string.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.element,
  country: PropTypes.string,
  countries: PropTypes.array,
  priorityCountry: PropTypes.string,
  newIcon: PropTypes.string,
  render: PropTypes.bool.isRequired,
};

TabTitle.defaultProps = {
  icon: undefined,
  subtitle: undefined,
  country: undefined,
  countries: undefined,
  priorityCountry: undefined,
  newIcon: undefined,
};
