/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Form, DatePicker, Row, Col } from 'antd';
import PageTitle from 'components/PageTitle';
import moment from 'moment';
import _ from 'lodash';

import { useActivities } from 'hooks/activities';

import useInfiniteScroll from 'components/List/hooks/useInfiniteScroll';

import Wrapper from 'pages/private/wrapper';
import CardRecurrenceEvent from './components/CardRecurrenceEvent';

import './styles.less';

const RecurrenceList = () => {
  const [form] = Form.useForm();
  const { t: translate } = useTranslation();

  const { reloadActivities } = useActivities();

  const dateToDisable = useCallback((current) => {
    const beforeOctober = current < moment().month('8').year('2020');
    const afterCurrentMonth = current > moment();

    return beforeOctober || afterCurrentMonth;
  }, []);

  const customDataParser = useCallback(
    ({ INITIAL_CURRENT, oldPagination, oldData, newData }) => {
      const oldDataDates = oldData.map(({ date }) => date);
      const newDataDates = newData.docs.map(({ date }) => date);
      // Pega todas as datas possíveis
      const everyDate = _.uniq([...oldDataDates, ...newDataDates]);

      const newDataParsed = everyDate.map((date) => {
        const foundOldData = oldData.find((od) => od.date === date) || { items: [] };
        const foundNewData = newData.docs.find((nd) => nd.date === date) || { items: [] };

        return {
          date,
          items: [...foundOldData.items, ...foundNewData.items],
        };
      });
      return oldPagination.current === INITIAL_CURRENT ? newData.docs : newDataParsed;
    },
    []
  );

  const { componentList, setFilterData, reloadList } = useInfiniteScroll({
    getParams: {
      url: `/activities`,
      config: {
        params: {
          _groupBy: 'date',
          _sort: 'createdAt',
        },
      },
    },
    component: CardRecurrenceEvent,
    rowKey: 'date',
    customEmptyMessage: translate(
      'pages.private.profileModels.profileModelList.components.activities.index.customEmptyMessage'
    ),
    customDataParser,
  });

  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      const dates = { _startDate: undefined, _endDate: undefined };
      if (values.period) {
        const newStartDate = moment.utc(moment(values.period).startOf('month')).toDate();
        const newEndDate = moment.utc(moment(values.period).endOf('month')).toDate();

        dates._startDate = new Date(newStartDate).getTime();
        dates._endDate = new Date(newEndDate).getTime();
      }

      setFilterData({
        ...values,
        ...dates,
      });
    }, 500),
    []
  );

  useEffect(() => {
    if (reloadActivities !== 0) reloadList();
  }, [reloadActivities]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      <PageTitle
        title={translate(
          'pages.private.profileModels.profileModelList.components.activities.index.pageTitle.title'
        )}
        subtitle={translate(
          'pages.private.profileModels.profileModelList.components.activities.index.pageTitle.subtitle'
        )}
      />
      <Card id="profile-model-activities">
        <Form
          form={form}
          layout="horizontal"
          onValuesChange={handleFormChange}
          initialValues={{ _order: -1 }}
        >
          <Row>
            <Col span={24} className="recurrence-header-filter">
              <div className="period-group-container">
                <i className="caf-ic_calendar" />
                <Form.Item name="period">
                  <DatePicker
                    bordered={false}
                    className="data-filter-period"
                    picker="month"
                    format="MMMM [de] YYYY"
                    inputReadOnly
                    placeholder={translate(
                      'pages.private.profileModels.profileModelList.components.activities.index.allDates'
                    )}
                    disabledDate={dateToDisable}
                    suffixIcon={<i className="caf-ic_arrow_down" />}
                    style={{ width: 180 }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <div>{componentList}</div>
        </Form>
      </Card>
    </Wrapper>
  );
};

export default RecurrenceList;
