import React from 'react';
import { Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import {
  CompanyRow,
  CompanyCard,
  CompanyLink,
  CompanySubtitle1,
  CompanyFinancialRiskTagging,
} from 'pages/private/KYBCompany/CompanyDetailsV2/components';
import PropTypes from 'prop-types';
import NotAvailable from 'components/NotAvailable';

const { Title, Text } = Typography;

const I18N_BASE_PATH = 'pages.private.KYBCompany.pages';
const SUMMARY = `${I18N_BASE_PATH}.companyDetails.subPages.summary`;
const RISK = `${SUMMARY}.cards.financialRisk`;
const LEVEL = `${I18N_BASE_PATH}.companyDetails.components.riskLevelControl`;

const FinancialRiskTitle = ({ children, ...rest }) => {
  if (!children || children === '-') {
    return <NotAvailable />;
  }

  return (
    <Title level={5} className={classnames(rest.className, 'typescale-h5', 'no-mrg')}>
      {children}
    </Title>
  );
};

const FinancialRisk = ({ summary, disabled, lg = 8, print = false }) => {
  const { t } = useTranslation();
  const { i18nFormatCurrency } = useI18nFormatters();

  const internationalScore = summary.internationalScore?.toLowerCase() || '';
  let level = ['a', 'b', 'c'].includes(internationalScore) ? 0 : 100;
  level = ['d', 'e', 'f'].includes(internationalScore) ? 50 : level;
  const leveli18n = `${LEVEL}.levels.${level}`;
  const levelStatusMap = { 0: 'success', 50: 'warning', 100: 'error' };
  const scoreClass = `ant-tag-${levelStatusMap[level]}`;

  return (
    <Col xs={print ? lg : 24} lg={lg}>
      <CompanyCard className="kyb-company-print-break-avoid">
        <Row>
          <Col span={24}>
            <CompanySubtitle1>{t(`${RISK}.title`)}</CompanySubtitle1>
          </Col>
        </Row>
        {summary.internationalScore || summary.creditLimitValue ? (
          <>
            <CompanyRow className="kyb-company-summary-score-content">
              <Col span={24}>
                <FinancialRiskTitle>
                  {internationalScore.toUpperCase()}
                </FinancialRiskTitle>
                <CompanyFinancialRiskTagging
                  i18n={leveli18n}
                  value={internationalScore}
                  className={scoreClass}
                >
                  {t(`${leveli18n}.${internationalScore.toLowerCase()}`, '')}
                </CompanyFinancialRiskTagging>
                <Text className="kyb-company-summary-score-text typescale-subtitle-2">
                  <string>{t(`${RISK}.creditLimit`)}</string>
                </Text>
                <Title level={5} className="typescale-h5 no-mrg">
                  {i18nFormatCurrency(
                    Number.parseFloat(summary.creditLimitValue) || 0,
                    null,
                    summary.creditLimitCurrency
                  )}
                </Title>
              </Col>
            </CompanyRow>
            <Row justify="end">
              <Col className="kyb-company-summary-footer">
                <CompanyLink to="?tab=financial">
                  {t(`${I18N_BASE_PATH}.companyDetails.mainCardLink`)}
                </CompanyLink>
              </Col>
            </Row>
          </>
        ) : (
          <NotAvailable card />
        )}
      </CompanyCard>
    </Col>
  );
};

FinancialRisk.propTypes = {
  summary: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  print: PropTypes.bool.isRequired,
  lg: PropTypes.number.isRequired,
};

export default FinancialRisk;
