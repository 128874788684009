/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import { isEmpty } from 'utils/isObjectEmpty';
import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import EmptyState from 'components/EmptyState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import './styles.less';

const PGFN = ({ profile, type, toPrint /* urlId */ }) => {
  const { t: translate } = useTranslation();

  const { i18nFormatDate } = useI18nFormatters();

  const pgfn = useMemo(() => profile?.certificates?.pgfn, [profile]);

  let showStatusInfo;

  if (pgfn?.pgfnClearance || !pgfn?.status || pgfn?.status === 'UNDEFINED')
    showStatusInfo = pgfn?.remark;

  const handlerPendencies = useMemo(() => {
    if (!pgfn) return { title: null, type: null };

    if (pgfn?.status === 'NEGATIVA') {
      return {
        title: translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.shared.pgfn.index.handlerPendencies.negative'
        ),
        type: 'primary',
      };
    }

    if (pgfn?.status === 'POSITIVA') {
      return {
        title: translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.shared.pgfn.index.handlerPendencies.positive'
        ),
        type: 'danger',
      };
    }

    if (pgfn?.status === 'POSITIVA COM EFEITOS DE NEGATIVA') {
      return {
        title: translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.shared.pgfn.index.handlerPendencies.positiveWithNegativeEffects'
        ),
        type: 'warning',
      };
    }

    if (pgfn?.pgfnClearance) {
      return {
        title: translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.shared.pgfn.index.handlerPendencies.pgfnClearance'
        ),
        type: 'primary',
      };
    }

    return {
      title: translate(
        'pages.private.profiles.components.dataSourceCards.financialInformation.shared.pgfn.index.handlerPendencies.default'
      ),
      type: 'empty',
    };
  }, [pgfn, translate]);

  const isProcessing = useMemo(() => verifySourcesProcessing(profile, 'pgfn'), [profile]);

  return (
    <ColumnLabel
      title={translate(
        'pages.private.profiles.components.dataSourceCards.financialInformation.shared.pgfn.index.pgfn.columnLabel.title'
      )}
      description={handlerPendencies?.title}
      type={handlerPendencies?.type}
      info={
        !toPrint &&
        translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.shared.pgfn.index.pgfn.columnLabel.info'
        )
      }
      statusInfo={showStatusInfo}
    >
      {!pgfn && isProcessing && <EmptyState type="processing" />}

      {!pgfn && !isProcessing && <EmptyState type="waiting_consult" />}

      {pgfn?.status === 'POSITIVA COM EFEITOS DE NEGATIVA' && (
        <span>
          {translate(
            'pages.private.profiles.components.dataSourceCards.financialInformation.shared.pgfn.index.pgfn.positiveWithNegativeEffects'
          )}
        </span>
      )}

      {pgfn?.validityDate && i18nFormatDate(pgfn.validityDate, 1) !== '-' && (
        <span>
          {translate(
            'pages.private.profiles.components.dataSourceCards.financialInformation.shared.pgfn.index.pgfn.validityDate'
          )}{' '}
          {i18nFormatDate(pgfn.validityDate, 1)}
        </span>
      )}

      {pgfn && (isEmpty(pgfn) || pgfn?.status === 'NEGATIVA') && (
        <span>
          {translate(
            'pages.private.profiles.components.dataSourceCards.financialInformation.shared.pgfn.index.pgfn.negative',
            { type }
          )}
        </span>
      )}
    </ColumnLabel>
  );
};

export default PGFN;
