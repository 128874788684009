/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useFetch } from 'services/hooks';
import { Row, Col, Select, Collapse, Pagination } from 'antd';
import RecordsIcon from 'assets/images/company/records.svg';
import usePagination from 'customHooks/usePagination';
import NoResults from 'components/NoResults';
import RecordBody from './components/RecordBody';
import RecordHeader from './components/RecordHeader';
import TabTitle from '../../../../../../CompanyDetails/components/TabTitle';

import './styles.less';
import useCompanyRecords from './hooks/useCompanyRecords';

const I18N_BASE_PATH = 'pages.private.KYBCompany.pages.companyDetails.subPages.records';

const RecordTitle = ({ companyName }) => {
  const { t } = useTranslation();

  return (
    <TabTitle
      icon={RecordsIcon}
      title={t(`${I18N_BASE_PATH}.title`, 'Records')}
      subtitle={
        <Trans
          i18nKey={`${I18N_BASE_PATH}.subtitle`}
          values={{ companyName }}
          components={{ b: <b /> }}
        />
      }
    />
  );
};

const RecordListHeader = ({ print }) => {
  const { t } = useTranslation();

  const colSizes = useMemo(() => (print ? [9, 3, 3, 6, 3] : [7, 3, 3, 5, 5]), [print]);

  return (
    <Row className="mrg-top-30 text-bold text-dark font-size-12">
      <Col span={colSizes[0]}>{t(`${I18N_BASE_PATH}.recordName`, 'Record Name')}</Col>
      <Col span={colSizes[1]}>
        {t(`${I18N_BASE_PATH}.acquisitionDate`, 'Acquisition date')}
      </Col>
      <Col span={colSizes[2]}>
        {t(`${I18N_BASE_PATH}.publicationDate`, 'Publication date')}
      </Col>
      <Col span={colSizes[3]}>{t(`${I18N_BASE_PATH}.source`, 'Source')}</Col>
      <Col span={colSizes[4]}>{t(`${I18N_BASE_PATH}.dataType`, 'Data type')}</Col>
    </Row>
  );
};

const RecordCollapse = ({
  record,
  loading,
  handleViewRecord,
  print,
  children,
  ...rest
}) => {
  return (
    <RecordHeader
      className={rest.className}
      record={record}
      handleViewRecord={handleViewRecord}
      loading={loading}
      print={print}
    />
  );
};

const RecordsList = ({
  data,
  transactionId,
  goToDetails,
  savedRecords,
  loadSavedRecords,
  loadingSavedRecords,
  ...rest
}) => {
  const { t } = useTranslation();
  const { get } = useFetch();

  const topRef = useRef(null);
  const [loadingRecordId, setLoadingRecordId] = useState();
  const [sourceToSearch, setSourceToSearch] = useState();
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
  });

  const { getSourceById, records } = useCompanyRecords({
    data,
    savedRecords,
    sourceToSearch,
  });

  const { paginatedItems, paginationStart, paginationEnd } = usePagination({
    pagination,
    items: records,
  });

  const handleChangePage = (page) => {
    setPagination((old) => ({ ...old, currentPage: page }));
    topRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleViewRecord = async (record) => {
    if (record.saved) {
      return goToDetails({
        ...record.saved,
        source: getSourceById(record.saved.sourceId),
      });
    }

    setLoadingRecordId(record.id);
    const response = await get({
      url: `/company-record`,
      config: {
        params: {
          transactionId,
          recordId: record.id,
        },
      },
    });

    goToDetails({
      ...response.data,
      source: getSourceById(response.data.sourceId),
    });
    setLoadingRecordId(undefined);
    loadSavedRecords();
  };

  return (
    <div {...rest}>
      <RecordTitle companyName={data.companyName} />
      {records.length <= 0 && (
        <Row className="mrg-top-30">
          <NoResults />
        </Row>
      )}
      {records.length > 0 && (
        <div style={{ scrollMarginTop: 100 }} ref={topRef}>
          <RecordListHeader />
          <Row className="mrg-top-5">
            <Col span={24}>
              {paginatedItems.map((record) => (
                <RecordCollapse
                  className="kyb-company-records-items"
                  record={record}
                  loading={record.id === loadingRecordId || loadingSavedRecords}
                  handleViewRecord={handleViewRecord}
                />
              ))}
            </Col>
          </Row>
          <Row className="mrg-top-5 center text-dark">
            <Col span={12}>
              <span>
                {t(`${I18N_BASE_PATH}.pagination`, {
                  start: paginationStart,
                  end: paginationEnd,
                  total: records.length,
                })}
              </span>
            </Col>
            <Col span={12}>
              <Pagination
                current={pagination.currentPage}
                onChange={handleChangePage}
                pageSize={pagination.pageSize}
                total={records.length}
                defaultCurrent={1}
                showSizeChanger={false}
                showSorterTooltip={false}
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default RecordsList;
export { RecordTitle, RecordListHeader, RecordCollapse };

RecordsList.propTypes = {
  data: PropTypes.any.isRequired,
  transactionId: PropTypes.string.isRequired,
  goToDetails: PropTypes.func.isRequired,
  savedRecords: PropTypes.any.isRequired,
  loadSavedRecords: PropTypes.func.isRequired,
  loadingSavedRecords: PropTypes.bool.isRequired,
};
