import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Modal, Form, Input, Checkbox } from 'antd';
import { useFetch } from 'services/hooks';
import InputMask from 'components/Form/InputMask';
import Button from 'components/Button';
import PageTitle from 'components/PageTitle';
import { validateCnpj, validateCpf } from 'utils/validations';

const I18N_BASE_PATH =
  'pages.private.restrictions.denyList.components.RestrictionCreateModal.index';

const I18N_ALT_PATH =
  'pages.private.restrictions.denyList.components.ImportCSVModal.index';

const RestrictionCreate = ({ refreshList, isEdit, docs, inProfile, url }) => {
  const { t: translate } = useTranslation();

  const [form] = Form.useForm();
  const { post, put, loading } = useFetch();
  const [modalVisible, setModalVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [inputMask, setInputMask] = useState('999.999.999-99');
  const [selectedType, setSelectedType] = useState('CPF');

  useEffect(() => {
    if (docs?.identification?.length === 14) {
      setInputMask('99.999.999/9999-99');
      setSelectedType('CNPJ');
    } else {
      setInputMask('999.999.999-99');
      setSelectedType('CPF');
    }
  }, [docs]);

  useEffect(() => {
    if (modalVisible && docs) {
      form.setFieldsValue({
        identification: docs.identification,
        name: docs.name,
        reason: docs.reason,
      });
    }
  }, [modalVisible, docs, form]);

  const closeModal = useCallback(() => {
    form.resetFields();
    setDisabled(true);
    setModalVisible(false);
  }, [form]);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const handleSubmit = useCallback(async () => {
    await form.validateFields();

    const { name, identification, reason } = form.getFieldsValue();

    if (isEdit) {
      await put({
        url: `/${url}/${docs?._id}`,
        payload: {
          name,
          identification: identification?.replace(/\D/g, ''),
          reason,
        },
      });
    } else {
      const register = {
        name,
        identification: identification?.replace(/\D/g, ''),
        reason,
      };

      await post({
        url: `/${url}`,
        payload:
          url === 'deny-list'
            ? {
                name,
                identification: identification?.replace(/\D/g, ''),
                reason,
              }
            : { register },
      });
    }

    if (refreshList?.current) refreshList.current();
    form.resetFields();
    closeModal();
  }, [closeModal, docs, form, isEdit, post, put, refreshList, url]);

  const formContent = useMemo(
    () => (
      <Row>
        <Col span={24}>
          {inProfile ? (
            <PageTitle
              title={
                docs?.type === 'PF'
                  ? translate(`${I18N_BASE_PATH}.formContent.pageTitle.title.addCpf`)
                  : translate(`${I18N_BASE_PATH}.formContent.pageTitle.title.addCnpj`)
              }
              subtitle={
                docs?.type === 'PF'
                  ? translate(`${I18N_BASE_PATH}.formContent.pageTitle.subtitle.addCpf`)
                  : translate(`${I18N_BASE_PATH}.formContent.pageTitle.subtitle.addCnpj`)
              }
            />
          ) : (
            <PageTitle
              title={
                isEdit
                  ? translate(`${I18N_BASE_PATH}.formContent.pageTitle.title.edit`)
                  : translate(`${I18N_BASE_PATH}.formContent.pageTitle.title.create`)
              }
              subtitle={
                isEdit
                  ? translate(`${I18N_BASE_PATH}.formContent.pageTitle.subtitle.edit`)
                  : translate(`${I18N_BASE_PATH}.formContent.pageTitle.subtitle.create`)
              }
            />
          )}
        </Col>

        <Col span={24} className="mrg-vertical-20">
          <Form form={form} layout="vertical" autoComplete="off">
            <div className="flex mrg-btm-20">
              <Checkbox
                disabled={inProfile}
                checked={selectedType === 'CPF'}
                onChange={() => {
                  setSelectedType('CPF');
                  setInputMask('999.999.999-99');
                  form.resetFields(['identification']);
                }}
              >
                {translate('components.additionalInfo.additionalInfoBox.inputs.cpf')}
              </Checkbox>
              <Checkbox
                disabled={inProfile}
                checked={selectedType === 'CNPJ'}
                onChange={() => {
                  setSelectedType('CNPJ');
                  setInputMask('99.999.999/9999-99');
                  form.resetFields(['identification']);
                }}
              >
                {translate('components.additionalInfo.additionalInfoBox.inputs.cnpj')}
              </Checkbox>
            </div>
            <Form.Item
              initialValue={docs?.identification}
              label={translate(
                `${I18N_BASE_PATH}.formContent.form.columnIdentification.label`
              )}
              name="identification"
              rules={[
                { required: true },
                {
                  validator: (_, _value) => {
                    const value = _value?.toString()?.replace(/\D/g, '');

                    if (selectedType === 'CPF') {
                      const validCpf = validateCpf(value);

                      if ((!validCpf || value?.length !== 11) && !inProfile) {
                        setDisabled(true);
                      } else {
                        setDisabled(false);
                      }

                      if (!validCpf && value?.length === 11) {
                        return Promise.reject(
                          new Error(
                            translate(
                              'components.additionalInfo.additionalInfoBox.inputs.validateMessages.invalidCpf'
                            )
                          )
                        );
                      }
                    } else {
                      const validCnpj = validateCnpj(value);

                      if ((!validCnpj || value?.length !== 14) && !inProfile) {
                        setDisabled(true);
                      } else {
                        setDisabled(false);
                      }

                      if (!validCnpj && value?.length === 14) {
                        return Promise.reject(
                          new Error(
                            translate(
                              'components.additionalInfo.additionalInfoBox.inputs.validateMessages.invalidCnpj'
                            )
                          )
                        );
                      }
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputMask mask={inputMask} disabled={inProfile} />
            </Form.Item>
            <Form.Item
              initialValue={docs?.name}
              label={
                <>
                  <span>
                    {translate(`${I18N_BASE_PATH}.formContent.form.columnName.label`)}{' '}
                  </span>
                  <i className="font-size-10 gx-font-italic" style={{ marginLeft: 2 }}>
                    -{' '}
                    {translate(
                      `${I18N_ALT_PATH}.formContent.form.optional` // => Opcional
                    )}
                  </i>
                </>
              }
              name="name"
              rules={[{ required: false, defaultField: '' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              initialValue={docs?.reason}
              label={
                <>
                  <span>
                    {translate(`${I18N_BASE_PATH}.formContent.form.columnReason.label`)}{' '}
                    <i className="font-size-10 gx-font-italic" style={{ marginLeft: 2 }}>
                      -{' '}
                      {translate(
                        `${I18N_ALT_PATH}.formContent.form.optional` // => Opcional
                      )}
                    </i>
                  </span>
                </>
              }
              name="reason"
              rules={[{ required: false, defaultField: '' }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Col>

        <Col span={24} className="flex end">
          <Button
            type="button"
            htmlType="button"
            onClick={() => {
              form.resetFields();
              closeModal();
            }}
          >
            {translate(`${I18N_BASE_PATH}.formContent.buttons.cancel`)}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={handleSubmit}
            loading={loading}
            disabled={(loading || disabled) && !inProfile}
          >
            {translate(`${I18N_BASE_PATH}.formContent.buttons.confirm`)}
          </Button>
        </Col>
      </Row>
    ),
    [
      inProfile,
      docs,
      translate,
      isEdit,
      form,
      selectedType,
      inputMask,
      handleSubmit,
      loading,
      closeModal,
      disabled,
    ]
  );

  const RestrictionCreateModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={() => {
          form.resetFields();
          closeModal();
        }}
        footer={null}
        width={589}
      >
        {formContent}
      </Modal>
    ),
    [modalVisible, closeModal, formContent, form]
  );

  return { openModal, closeModal, RestrictionCreateModal };
};

export default RestrictionCreate;
