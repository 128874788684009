import { useTranslation } from 'react-i18next';

const SUMMARY = `pages.private.KYBCompany.pages.companyDetails.subPages.people.creditReport.summary`;

const usePeopleCredit = ({ creditReportData }) => {
  const { t } = useTranslation();

  const currentDirectorsLength = creditReportData?.currentDirectors?.length || 0;
  const previousDirectorsLength = creditReportData?.previousDirectors?.length || 0;
  const shareHoldersLength = creditReportData?.shareHolders?.length || 0;
  const activePersonsWithSignificantControlLength =
    creditReportData?.personsWithSignificantControl?.active?.length || 0;
  const ceasedPersonsWithSignificantControlLength =
    creditReportData?.personsWithSignificantControl?.ceased?.length || 0;
  const previousDirectorsList = creditReportData?.previousDirectors ?? [];
  const shareholdersList = creditReportData?.shareHolders ?? [];
  const collapsePrintSuffix = 'print';

  const summaryCounter = [
    {
      text: t(`${SUMMARY}.columns.directors`, 'Directors'),
      count: currentDirectorsLength,
    },
    {
      text: t(`${SUMMARY}.columns.shareholders`, 'Shareholders'),
      count: shareHoldersLength,
    },
    {
      text: t(`${SUMMARY}.columns.personWithSignificantControl`, 'PSC/UBO'),
      count: activePersonsWithSignificantControlLength,
    },
  ];

  return {
    currentDirectorsLength,
    previousDirectorsLength,
    shareHoldersLength,
    activePersonsWithSignificantControlLength,
    ceasedPersonsWithSignificantControlLength,
    previousDirectorsList,
    shareholdersList,
    collapsePrintSuffix,
    summaryCounter,
  };
};

export default usePeopleCredit;
