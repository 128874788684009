import React, { useState, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal, Form, Input, message, Button } from 'antd';
import PageTitle from 'components/PageTitle';

import { useFetch } from 'services/hooks';

import PasswordValidations from 'components/PasswordValidations';

import {
  passwordValidation,
  minimunCharacter,
  uppercaseCharacter,
  specialCharacter,
  lowercaseCharacter,
  numberCharacter,
} from 'utils/validations';

import './styles.less';

const ModalChangePassword = ({ user }) => {
  const { t: translate } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);

  const [form] = Form.useForm();

  const firstLoad = useRef(true);

  const { patch, loading } = useFetch();

  const [passwordValue, setPasswordValue] = useState('');

  const validations = useMemo(
    () => ({
      minimun: minimunCharacter.test(passwordValue),
      uppercase: uppercaseCharacter.test(passwordValue),
      special: specialCharacter.test(passwordValue),
      lowercase: lowercaseCharacter.test(passwordValue),
      number: numberCharacter.test(passwordValue),
    }),
    [passwordValue]
  );

  const allValidationsSatisfy = useMemo(
    () =>
      validations.minimun &&
      validations.uppercase &&
      validations.special &&
      validations.lowercase &&
      validations.number,
    [validations]
  );

  const handleCancel = useCallback(() => {
    form.resetFields();
    setModalVisible(false);
  }, [form]);

  const onChangePassword = useCallback((e) => {
    firstLoad.current = false;
    setPasswordValue(e.target.value);
  }, []);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const handleSubmit = useCallback(
    async (payload) => {
      try {
        const { newPassword } = payload;

        await patch({
          url: `${process.env.REACT_APP_BASE_URL_MANAGEMENT_API}/caf/users`,
          payload: {
            username: user?.email,
            password: newPassword,
          },
          showError: false,
          showMessage: false,
        });

        message.success(
          translate(
            'pages.private.users.components.usersPDVActions.changePassword.handleSubmit.messageSuccess'
          )
        );
        handleCancel();
      } catch (err) {
        message.error(
          translate(
            'pages.private.users.components.usersPDVActions.changePassword.handleSubmit.messageError'
          )
        );
      }
    },
    [handleCancel, patch, user, translate]
  );

  const ChangePasswordModal = useMemo(
    () => (
      <Modal
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        visible={modalVisible}
        footer={null}
        width={525}
        onCancel={closeModal}
      >
        <div id="change-password-modal-content">
          <PageTitle
            title={translate(
              'pages.private.users.components.usersPDVActions.changePassword.pageTitle.title'
            )}
            subtitle={translate(
              'pages.private.users.components.usersPDVActions.changePassword.pageTitle.subtitle'
            )}
          />

          {/* {loading ? (
            <div className="loading-spin-container">
              <Spin />
            </div>
          ) : ( */}
          <Form layout="vertical" onFinish={handleSubmit} className="pdd-vertical-20 ">
            <Form.Item
              label={translate(
                'pages.private.users.components.usersPDVActions.changePassword.newPassword'
              )}
              name="newPassword"
              rules={[
                ({ getFieldValue }) => ({
                  validator() {
                    if (passwordValidation.test(getFieldValue('newPassword'))) {
                      return Promise.resolve();
                    }
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject(
                      translate(
                        'pages.private.users.components.usersPDVActions.changePassword.messageNewPassword'
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                disabled={loading}
                size="large"
                prefix={<i className="caf-lock" />}
                value={passwordValue}
                onChange={onChangePassword}
                autoComplete="new-password"
              />
            </Form.Item>
            <Form.Item
              label={translate(
                'pages.private.users.components.usersPDVActions.changePassword.confirmNewPassword'
              )}
              name="confirmNewPassword"
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        translate(
                          'pages.private.users.components.usersPDVActions.changePassword.messageNotEqual'
                        )
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                disabled={loading}
                size="large"
                prefix={<i className="caf-lock" />}
                autoComplete="new-password"
              />
            </Form.Item>
            <PasswordValidations
              validations={validations}
              firstLoad={firstLoad.current}
            />
            <div className="modal-action-buttons-cp">
              <Button
                onClick={handleCancel}
                type="default"
                className="btn-cancel"
                disabled={loading}
              >
                {translate(
                  'pages.private.users.components.usersPDVActions.changePassword.buttons.cancel'
                )}
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                loading={loading}
                disabled={loading || !allValidationsSatisfy}
              >
                {translate(
                  'pages.private.users.components.usersPDVActions.changePassword.buttons.okText'
                )}
              </Button>
            </div>
          </Form>
          {/* )} */}
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      handleSubmit,
      loading,
      passwordValue,
      onChangePassword,
      validations,
      handleCancel,
      allValidationsSatisfy,
      translate,
    ]
  );

  return { openModal, ChangePasswordModal };
};

ModalChangePassword.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
};

export default ModalChangePassword;
