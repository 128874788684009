/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useExecution } from 'hooks/execution';
import { usePerson } from 'hooks/person';
import { useCompany } from 'hooks/company';
import * as executionDataSources from 'utils/executionDataSource';

import FGTS from './components/FGTS';
import Sintegra from './components/Sintegra';
import CNDT from './components/CNDT';
import LaborDebts from '../Shared/LaborDebts';

import DataSourceCardCollapsePanel from '../../DataSourceCardCollapsePanel';

const BusinessLaborInformation = ({ toPrint }) => {
  const { t: translate } = useTranslation();

  const { execution } = useExecution();
  const { company } = useCompany();
  const { person } = usePerson();

  const findSourceSection = useCallback(
    (section) =>
      executionDataSources.findSourceSection(execution, section) ||
      executionDataSources.findSourceSection(company, section) ||
      executionDataSources.findSourceSection(person, section),
    [company, execution, person]
  );

  const showFGTS = useMemo(() => findSourceSection('pjFgts'), [findSourceSection]);

  const showSintegra = useMemo(() => findSourceSection('pjSintegra'), [
    findSourceSection,
  ]);

  const showCNDT = useMemo(() => findSourceSection('pjCndt'), [findSourceSection]);

  const laborDebts = useMemo(() => findSourceSection('pjLaborDebts'), [
    findSourceSection,
  ]);

  return (
    <DataSourceCardCollapsePanel
      customId="labor-infos-component"
      title={translate(
        'components.dataSourceCards.laborInformation.companies.index.companies.dossierCard.title'
      )}
      icon="caf-ic_awards"
      toPrint={toPrint}
    >
      {showFGTS && <FGTS source={showFGTS} toPrint={toPrint} />}
      {showSintegra && <Sintegra source={showSintegra} toPrint={toPrint} />}
      {showCNDT && <CNDT source={showCNDT} toPrint={toPrint} />}

      {/* Não remover o data */}
      {laborDebts && <LaborDebts source={laborDebts} toPrint={toPrint} />}
    </DataSourceCardCollapsePanel>
  );
};

export default BusinessLaborInformation;
