import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import CustomUploadButton from 'pages/private/Profiles/components/CustomUploadButton';
import useManualUploadDocuments from 'pages/private/Profiles/components/ManualUploadDocuments';

import PermissionWrapper from 'routes/PermissionWrapper';

import document from 'assets/images/document.svg';
import selfie from 'assets/images/selfie.svg';

import './styles.less';

const EmptyDocuments = ({ type }) => {
  const { t: translate } = useTranslation();

  const { openModal, ManualUploadDocumentsModal } = useManualUploadDocuments();

  const handlerDetails = useMemo(() => {
    if (type === 'authentic-document') {
      return {
        top: 'mrg-top-20',
        image: document,
        imageDescription: translate(
          'pages.private.executions.components.tabs.components.documentsValidations.components.shared.emptyDocuments.index.handlerDetails.authenticDocument.imageDescription'
        ),
        className: 'empty-documents-img custom-img',
        title: translate(
          'pages.private.executions.components.tabs.components.documentsValidations.components.shared.emptyDocuments.index.handlerDetails.authenticDocument.title'
        ),
        customContent: (
          <span>
            {translate(
              'pages.private.executions.components.tabs.components.documentsValidations.components.shared.emptyDocuments.index.handlerDetails.authenticDocument.customContent.before'
            )}{' '}
            <strong>
              {translate(
                'pages.private.executions.components.tabs.components.documentsValidations.components.shared.emptyDocuments.index.handlerDetails.authenticDocument.customContent.inside'
              )}
            </strong>{' '}
            {translate(
              'pages.private.executions.components.tabs.components.documentsValidations.components.shared.emptyDocuments.index.handlerDetails.authenticDocument.customContent.after'
            )}
          </span>
        ),
      };
    }

    return {
      top: 'mrg-top-40',
      image: selfie,
      imageDescription: translate(
        'pages.private.executions.components.tabs.components.documentsValidations.components.shared.emptyDocuments.index.handlerDetails.default.imageDescription'
      ),
      className: 'empty-documents-img',
      title: translate(
        'pages.private.executions.components.tabs.components.documentsValidations.components.shared.emptyDocuments.index.handlerDetails.default.title'
      ),
      customContent: (
        <span>
          {translate(
            'pages.private.executions.components.tabs.components.documentsValidations.components.shared.emptyDocuments.index.handlerDetails.default.customContent.before'
          )}{' '}
          <strong>
            {translate(
              'pages.private.executions.components.tabs.components.documentsValidations.components.shared.emptyDocuments.index.handlerDetails.default.customContent.inside'
            )}
          </strong>{' '}
          {translate(
            'pages.private.executions.components.tabs.components.documentsValidations.components.shared.emptyDocuments.index.handlerDetails.default.customContent.after'
          )}
        </span>
      ),
    };
  }, [type, translate]);

  return (
    <>
      {ManualUploadDocumentsModal}
      <Row id="empty-documents-component" className={handlerDetails?.top}>
        <Col span={10} className={handlerDetails?.className}>
          <img
            className="grayscale-95"
            src={handlerDetails?.image}
            alt={handlerDetails?.imageDescription || ''}
          />
        </Col>
        <Col span={14} className="send-images-container">
          <strong className="send-images-description">{handlerDetails?.title}</strong>

          <PermissionWrapper requiredPermissions={['people:update']}>
            <CustomUploadButton
              onClick={() => openModal()}
              customClass="send-images-btn"
              customContent={handlerDetails?.customContent}
            />
          </PermissionWrapper>
        </Col>
      </Row>
    </>
  );
};

EmptyDocuments.propTypes = {
  type: PropTypes.oneOf(['authentic-document', 'verified-identity']).isRequired,
};

export default EmptyDocuments;
