import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Collapse, Divider } from 'antd';
import { toMaskedCpf } from 'utils/formatters';

import EmptyExecutionState from 'components/EmptyExecutionState';

import { useI18nFormatters } from 'hooks/i18nFormatters';
import DottedLabel from 'components/DottedLabel';

import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

const { Panel } = Collapse;

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.shared.pfBacenQgi';

const BacenQgiContent = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  return queryWorked ? (
    <DataSourceCardCollapsePanel
      title={translate(`${I18N_BASE_PATH}.index.title`)}
      icon="caf-ic_dollar_sign"
      toPrint={toPrint}
      customId="report-spc-last-phone-card"
    >
      <Row className="mrg-top-25">
        <>
          <Col span={24}>
            {/* Consultas realizadas anteriormente */}
            <Collapse className="mrg-top-10">
              <Panel
                disabled={
                  !parsedData?.disabledList || parsedData?.disabledList?.length <= 0
                }
                header={
                  <span className="text-dark text-bold">
                    {translate(`${I18N_BASE_PATH}.index.infos.title`)}
                  </span>
                }
                extra={
                  <DottedLabel
                    description={`(${parsedData?.disabledList?.length || 0}) ${translate(
                      `${I18N_BASE_PATH}.index.infos.entries`
                    )}`}
                    type={parsedData?.disabledList?.length > 0 ? 'danger' : 'primary'}
                  />
                }
              >
                {parsedData?.disabledList?.map((register, index) => (
                  <>
                    <Col span={24}>
                      <Row align="middle">
                        <span className="text-dark text-bold">
                          {register?.name || '-'}
                        </span>
                      </Row>
                      <Row align="middle" className="mrg-top-10" justify="space-between">
                        <Col>
                          <span className="collapse-label">
                            {translate(`${I18N_BASE_PATH}.index.infos.columns.cpf`)}:
                          </span>{' '}
                          <span>{toMaskedCpf(register?.cpf) || '-'}</span>
                        </Col>
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.index.infos.columns.date.title`
                            )}
                            :
                          </span>{' '}
                          <span>
                            {translate(`${I18N_BASE_PATH}.index.infos.columns.date.from`)}{' '}
                            {i18nFormatDate(register?.publicationDate, 2) || '-'}{' '}
                            {translate(
                              `${I18N_BASE_PATH}.index.infos.columns.date.until`
                            )}{' '}
                            {i18nFormatDate(register?.penaltyDeadline, 2) || '-'}
                          </span>
                        </Col>
                        <Col>
                          <span className="collapse-label">
                            {translate(`${I18N_BASE_PATH}.index.infos.columns.penalty`)}:{' '}
                          </span>{' '}
                          <span>{register?.penalty || '-'}</span>
                        </Col>
                      </Row>
                    </Col>
                    {index < parsedData?.disabledList?.length - 1 && <Divider />}
                  </>
                ))}
              </Panel>
            </Collapse>
          </Col>
        </>
      </Row>
    </DataSourceCardCollapsePanel>
  ) : (
    <DataSourceCardCollapsePanel
      title={translate(`${I18N_BASE_PATH}.index.title`)}
      icon="caf-ic_dollar_sign"
      toPrint={toPrint}
      customId="report-spc-last-phone-card"
    >
      <Row gutter={24}>
        <Col span={24}>
          <EmptyExecutionState
            statusCode={statusCode}
            message={source?.message || parsedData?.message}
          />
        </Col>
      </Row>
    </DataSourceCardCollapsePanel>
  );
};

export default BacenQgiContent;
