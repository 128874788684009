/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Checkbox, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import { onboardingServices } from 'pages/private/ProfileModels/utils';

import './styles.less';

const BackgroundCheckingServices = ({
  services,
  defaultServices,
  handleChangeBackgroundServices,
  hasSelfieImage,
}) => {
  const { t: translate } = useTranslation();
  const { i18nFormatCurrency } = useI18nFormatters();

  return (
    <div id="background-checking-services-container">
      <div className="flex mrg-btm-15 mrg-horizon-15 font-size-15 cols">
        <section>
          <span>
            {translate(
              'pages.private.profiles.components.manualUploadDocuments.components.steps.backgroundCheckingServices.index.backgroundCheckingServices.cols.service'
            )}
          </span>
        </section>
        <section className="flex center">
          <span>
            {translate(
              'pages.private.profiles.components.manualUploadDocuments.components.steps.backgroundCheckingServices.index.backgroundCheckingServices.cols.price'
            )}
          </span>
        </section>
      </div>
      <section className="serivces-list">
        {defaultServices?.map((service) => (
          <>
            <div className="flex">
              <span className="flex-1">
                <i
                  className={`${
                    onboardingServices[service?.source]?.icon
                  } mrg-right-20 font-size-18`}
                />
                {service?.title}
              </span>
              <div className="service-price mrg-right-10">
                <span className="mrg-right-5" id={`service-price-${service?.source}`}>
                  {i18nFormatCurrency(service?.price, 'pt-BR')}
                </span>
                <Form.Item className="no-mrg-btm" name={`${service?.source}-checkbox`}>
                  <Tooltip
                    title={
                      service?.source === 'facematch' &&
                      !hasSelfieImage &&
                      translate(
                        'pages.private.profiles.components.manualUploadDocuments.components.steps.backgroundCheckingServices.index.backgroundCheckingServices.tooltip.title'
                      )
                    }
                  >
                    <Checkbox
                      onClick={(e) => {
                        e.stopPropagation();

                        handleChangeBackgroundServices({
                          source: service?.source,
                          checked: e?.target?.checked,
                        });
                      }}
                      disabled={service?.source === 'facematch' && !hasSelfieImage}
                      defaultChecked={services?.some((s) => {
                        if (s.source === 'facematch' && !hasSelfieImage) {
                          return false;
                        }

                        return s?.source === service?.source;
                      })}
                    />
                  </Tooltip>
                </Form.Item>
              </div>
            </div>
          </>
        ))}
      </section>
    </div>
  );
};

BackgroundCheckingServices.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultServices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleChangeBackgroundServices: PropTypes.func.isRequired,
  hasSelfieImage: PropTypes.string,
};

BackgroundCheckingServices.defaultProps = {
  hasSelfieImage: undefined,
};

export default BackgroundCheckingServices;
