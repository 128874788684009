import React from 'react';
import PropTypes from 'prop-types';
import { Tag as StandardTag } from 'antd';

import './styles.less';

const Tag = ({ outlined, children, ...rest }) => {
  return (
    <div id="tag-component">
      <StandardTag
        {...rest}
        className={`${outlined ? 'tag-outlined' : ''} ${rest?.className}`}
      >
        {children}
      </StandardTag>
    </div>
  );
};

Tag.propTypes = {
  outlined: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.string,
    PropTypes.node,
  ]),
};

Tag.defaultProps = {
  outlined: false,
  children: null,
};

export default Tag;
