import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, message, Button } from 'antd';

import IconSuccess from 'assets/images/icon-success.svg';

import { useFetch } from 'services/hooks';
import PageTitle from 'components/PageTitle';

const ChangePasswordModal = ({ user }) => {
  const { t: translate } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [whichContentShow, setWhichContentShow] = useState(
    'confirm-change-password-action'
  );

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setWhichContentShow('confirm-change-password-action');
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const { post: postChangePassword, loading: loadingChangePassword } = useFetch();

  const handleUserChangePassword = useCallback(async () => {
    try {
      await postChangePassword({
        url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/forgot-password`,
        payload: {
          email: user?.username,
          internalRequest: true,
        },
      });

      setWhichContentShow('change-password-confirmed');
    } catch (error) {
      message.error(
        translate(
          'pages.private.users.components.usersActions.changePasswordModal.handleUserChangePassword.messageError'
        )
      );
    }
  }, [postChangePassword, setWhichContentShow, user, translate]);

  const ChangePasswordModalContent = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        footer={null}
        width={600}
        closable
        wrapClassName="caf-modal"
        onCancel={closeModal}
        closeIcon={<i className="caf-ic_close font-size-18" />}
      >
        <div className="flex center fd-column pdd-vertical-15 ">
          {whichContentShow === 'confirm-change-password-action' ? (
            <>
              <PageTitle
                title={translate(
                  'pages.private.users.components.usersActions.changePasswordModal.pageTitle.title'
                )}
                align="center"
              />

              <div className="flex center mrg-top-25 mrg-btm-35">
                <span className="text-center font-size-16">
                  {translate(
                    'pages.private.users.components.usersActions.changePasswordModal.spanContent'
                  )}
                  <strong className="display-block mrg-top-10 text-dark font-size-16">
                    {user?.name} ({user?.username})
                  </strong>
                </span>
              </div>

              <div className="flex center-end">
                <Button type="default" onClick={closeModal} className="btn-cancel">
                  {translate(
                    'pages.private.users.components.usersActions.changePasswordModal.buttons.cancel'
                  )}
                </Button>
                <Button
                  type="primary"
                  onClick={handleUserChangePassword}
                  loading={loadingChangePassword}
                >
                  {loadingChangePassword
                    ? translate(
                        'pages.private.users.components.usersActions.changePasswordModal.buttons.loading'
                      )
                    : translate(
                        'pages.private.users.components.usersActions.changePasswordModal.buttons.okText'
                      )}
                </Button>
              </div>
            </>
          ) : (
            <>
              <img
                src={IconSuccess}
                alt={translate(
                  'pages.private.users.components.usersActions.changePasswordModal.alt'
                )}
                className="mrg-btm-30"
              />
              <h3 className="text-center width-80 text-bold font-size-25">
                {translate(
                  'pages.private.users.components.usersActions.changePasswordModal.textSuccess'
                )}
              </h3>
            </>
          )}
        </div>
      </Modal>
    ),
    [
      closeModal,
      handleUserChangePassword,
      loadingChangePassword,
      modalVisible,
      user,
      whichContentShow,
      translate,
    ]
  );

  return { openModal, ChangePasswordModalContent };
};

export default ChangePasswordModal;
