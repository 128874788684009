import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam } from 'use-query-params';
import _ from 'lodash';
import { Form } from 'antd';
import PropTypes from 'prop-types';

import UpdatedAtFilter from '../../../components/UpdateAtFilter';
import CategoryFilter from '../../../components/CategoryFilter';

const I18N_BASE_PATH = 'pages.private.liveness.whiteListTab.components.filter.index';

const Filter = ({ handleFormChange }) => {
  const { t: translate } = useTranslation();

  const [query, setQuery] = useQueryParams({
    trackingId: StringParam,
    personId: StringParam,
    _order: StringParam,
    _sort: StringParam,
    createdAt: StringParam,
  });

  const setFilter = useCallback(
    (filterName, event) => {
      let queryParams = {
        [filterName]: event && !_.isEmpty(event) ? event : undefined,
      };

      if (filterName === 'createdAt' && Array.isArray(event)) {
        const startDate = event[0]
          .utcOffset(0)
          .set({ second: 0, millisecond: 0 })
          .valueOf();
        const endDate = event[1]
          .utcOffset(0)
          .set({ second: 59, millisecond: 0 })
          .valueOf();

        queryParams = {
          [filterName]: `custom:${startDate}-${endDate}`,
        };
      }

      setQuery(queryParams);
    },
    [setQuery]
  );

  return (
    <div id="profile-filter-component">
      <Form onValuesChange={handleFormChange}>
        <CategoryFilter
          type={query.trackingId ? 'trackingId' : 'personId'}
          value={query.personId || query.trackingId}
          setFilter={setFilter}
        />

        <UpdatedAtFilter
          title={translate(`${I18N_BASE_PATH}.updatedAtFilter.title`)}
          value={query.createdAt}
          setFilter={setFilter}
        />
      </Form>
    </div>
  );
};

Filter.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
};

export default Filter;
