/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Collapse } from 'antd';

import HeaderInfo from './HeaderInfo';
import HeaderSwitch from './HeaderSwitch';

const { Panel } = Collapse;

const CustomCollapse = ({ children, ruleName, disabled, className, title, icon }) => {
  const [isActive, setIsActive] = useState(true);

  return (
    <Collapse
      activeKey={isActive && [`${ruleName}-switch`]}
      className={className}
      destroyInactivePanel
    >
      <Panel
        showArrow={false}
        collapsible={!isActive ? 'disabled' : 'header'}
        key={`${ruleName}-switch`}
        extra={isActive && ruleName !== 'special-rules' ? <HeaderInfo /> : <></>}
        header={
          <HeaderSwitch
            title={title}
            icon={icon}
            name={[ruleName, 'switch']}
            isActive={isActive}
            setIsActive={setIsActive}
            disabled={disabled}
          />
        }
      >
        <div className="no-mrg no-pdd">{children}</div>
      </Panel>
    </Collapse>
  );
};

export default CustomCollapse;
