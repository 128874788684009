/* eslint-disable react/prop-types */
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam } from 'use-query-params';
import { Modal, Row, Col, Form, Select, Spin } from 'antd';

import { findProfileTemplateById } from 'utils/findProfileTemplateById';

import { useProfileTemplate } from 'hooks/profileTemplate';
import { useCompany } from 'hooks/company';
import { usePerson } from 'hooks/person';

import PageTitle from 'components/PageTitle';
// import RecurrenceTag from 'components/RecurrenceTag';
import Button from 'components/Button';

import EmptyRecurrence from '../EmptyRecurrence';

import './styles.less';

const { Option } = Select;

const ProfileTemplate = ({ profile, url, type }) => {
  const { t: translate } = useTranslation();

  const [form] = Form.useForm();

  const [, setQuery] = useQueryParams({
    tab: StringParam,
  });

  const { refreshCompany } = useCompany();
  const { refreshPerson } = usePerson();
  const {
    loadProfileTemplates,
    allProfileTemplates,
    loadingAllProfileTemplates,
    addProfileTemplate,
    loadingAddProfileTemplate,
  } = useProfileTemplate();

  const [modalVisible, setModalVisible] = useState(false);
  const [selectVisible, setSelectVisible] = useState(false);
  const [refreshRecurrenceField, setRefreshRecurrenceField] = useState(false);
  const [currentProfileTemplate, setCurrentProfileTemplate] = useState(null);

  const closeModal = useCallback(
    ({ addRecurrence, newData }) => {
      setRefreshRecurrenceField(true);
      setModalVisible(false);

      if (addRecurrence) {
        form.setFieldsValue({ profileTemplateId: newData });
        return;
      }

      setCurrentProfileTemplate(null);
      form.resetFields();
    },
    [form]
  );

  const openModal = useCallback(() => {
    setRefreshRecurrenceField(false);
    setModalVisible(true);
  }, []);

  const handleChangeValues = useCallback(
    (values) => {
      const resp = findProfileTemplateById(values.profileTemplateId, allProfileTemplates);
      setCurrentProfileTemplate(resp._id);
    },
    [allProfileTemplates]
  );

  const handleChangeSelectVisible = useCallback((value) => {
    if (value) {
      setSelectVisible(true);
      return;
    }
    setSelectVisible(false);
  }, []);

  const submit = useCallback(
    (payload) => {
      if (payload.profileTemplateId === profile?.profileTemplateId) {
        return;
      }

      addProfileTemplate({
        profileTemplateId: payload.profileTemplateId,
        url,
        notification: translate(
          'pages.private.profiles.components.profileTemplate.index.submit.notification'
        ),
      }).then(() => {
        if (
          type ===
          translate('pages.private.profiles.components.profileTemplate.index.type.person')
        ) {
          refreshCompany();
        }
        if (
          type ===
          translate(
            'pages.private.profiles.components.profileTemplate.index.type.company'
          )
        ) {
          refreshPerson();
        }

        setQuery({ tab: 'activities' });
        closeModal({ addRecurrence: true, newData: payload.profileTemplateId });
      });
    },
    [
      profile,
      type,
      url,
      setQuery,
      closeModal,
      addProfileTemplate,
      refreshCompany,
      refreshPerson,
      translate,
    ]
  );

  useEffect(() => {
    if (!modalVisible) {
      return;
    }
    loadProfileTemplates({ type, active: 'all' });
  }, [loadProfileTemplates, modalVisible, type]);

  const ProfileTemplateModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeModal}
        footer={null}
        style={{ top: 40 }}
        width={650}
      >
        {profile?.profileTemplate && (
          <div className="no-mrg no-pdd profile-model-update-modal">
            <PageTitle
              title={translate(
                'pages.private.profiles.components.profileTemplate.index.profileTemplate.pageTitle.title'
              )}
              subtitle={translate(
                'pages.private.profiles.components.profileTemplate.index.profileTemplate.pageTitle.subtitle'
              )}
            />

            <Form
              form={form}
              layout="vertical"
              initialValues={{ profileTemplateId: profile?.profileTemplateId }}
              onValuesChange={handleChangeValues}
              onFinish={submit}
            >
              <Row className="profile-model-update-content">
                <Col span={24}>
                  {loadingAllProfileTemplates && (
                    <Spin className="flex center gx-w-100" />
                  )}

                  {!loadingAllProfileTemplates && (
                    <Form.Item name="profileTemplateId">
                      <Select
                        bordered={false}
                        className="flex-1 gx-w-100"
                        suffixIcon={<i className="caf-ic_arrow_down select-down" />}
                        onDropdownVisibleChange={handleChangeSelectVisible}
                        open={selectVisible}
                        value={currentProfileTemplate}
                      >
                        {allProfileTemplates
                          .filter(
                            (pf) => pf?._id === profile?.profileTemplateId || pf?.active
                          )
                          .map((template) => (
                            <Option
                              key={template?._id}
                              value={template?._id}
                              disabled={!template?.active}
                            >
                              {template?.name}{' '}
                              {!template?.active &&
                                translate(
                                  'pages.private.profiles.components.profileTemplate.index.profileTemplate.form.profileTemplateId.text'
                                )}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                </Col>
              </Row>

              {/* {!loadingAllProfileTemplates &&
                findProfileTemplateById(
                  currentProfileTemplate || profile?.profileTemplateId,
                  allProfileTemplates
                )?.recurrences?.map((r) => <RecurrenceTag key={r?._id} data={r} />)} */}

              <Row className="footer-btns-container">
                <Button type="default" onClick={closeModal} className="btn-cancel">
                  {translate(
                    'pages.private.profiles.components.profileTemplate.index.profileTemplate.form.button.cancel'
                  )}
                </Button>

                <Button
                  disabled={
                    loadingAllProfileTemplates ||
                    loadingAddProfileTemplate ||
                    form.getFieldValue('profileTemplateId') === profile?.profileTemplateId
                  }
                  type="primary"
                  htmlType="submit"
                >
                  {translate(
                    `pages.private.profiles.components.profileTemplate.index.profileTemplate.form.button.submit.${
                      loadingAddProfileTemplate ? 'loading' : 'default'
                    }`
                  )}
                </Button>
              </Row>
            </Form>
          </div>
        )}

        {!profile?.profileTemplate && (
          <EmptyRecurrence refreshField={refreshRecurrenceField} />
        )}
      </Modal>
    ),
    [
      profile,
      form,
      modalVisible,
      closeModal,
      submit,
      handleChangeValues,
      handleChangeSelectVisible,
      allProfileTemplates,
      loadingAllProfileTemplates,
      loadingAddProfileTemplate,
      selectVisible,
      currentProfileTemplate,
      refreshRecurrenceField,
      translate,
    ]
  );

  return { openModal, closeModal, ProfileTemplateModal };
};

export default ProfileTemplate;
