import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Spin } from 'antd';

import { useAuth } from 'hooks/auth';
import { useCompany } from 'hooks/company';
import { usePerson } from 'hooks/person';
import { useExecution } from 'hooks/execution';

import Subheader from 'pages/private/Profiles/components/ProfileSubheader';
import CompanyContent from 'pages/private/Profiles/components/ProfileContent';

import Wrapper from '../../wrapper';
import Unauthorized from '../../Unauthorized';

const CompanyDetails = () => {
  const { user } = useAuth();

  const { companyId } = useParams();
  const { loadCompany, loadingCompany, company, reloadCompany } = useCompany();
  const { clearPerson } = usePerson();
  const { clearExecution } = useExecution();

  useEffect(() => {
    if (!loadCompany) return;

    loadCompany({ companyId, clearData: true });
    clearPerson();
    clearExecution();
  }, [companyId]); // eslint-disable-line

  useEffect(() => {
    if (!reloadCompany) return;

    loadCompany({ companyId, clearData: true });
    clearPerson();
    clearExecution();
  }, [reloadCompany]); // eslint-disable-line

  return !user?.accountData?.featureFlags?.profilesModule ? (
    <Unauthorized />
  ) : (
    <Wrapper subheader={<Subheader profile={company} />} hideMainHeader>
      {loadingCompany && !company ? (
        <Spin className="flex center mrg-top-30" />
      ) : (
        <CompanyContent profile={company} />
      )}
    </Wrapper>
  );
};

export default CompanyDetails;
