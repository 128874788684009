import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from 'hooks/auth';

import { hasRequiredPermissions } from './permissionFunctions';

const PermissionWrapper = ({
  children,
  or,
  requiredPermissions,
  requiredResources,
  forbiddenContent,
  product,
}) => {
  const { user } = useAuth();

  const content = useMemo(() => {
    return hasRequiredPermissions(
      user,
      requiredPermissions,
      requiredResources || [],
      or,
      product
    )
      ? children
      : forbiddenContent;
  }, [requiredPermissions, user]); // eslint-disable-line

  return content;
};

PermissionWrapper.propTypes = {
  requiredPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  requiredResources: PropTypes.arrayOf(PropTypes.string),
  forbiddenContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  or: PropTypes.bool,
  product: PropTypes.string,
};

PermissionWrapper.defaultProps = {
  requiredResources: [],
  forbiddenContent: <></>,
  or: false,
  product: 'trust',
};

export default memo(PermissionWrapper);
