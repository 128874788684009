export const matchMap = {
  name_exact: {
    i18n:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.matchMap.name_exact',
  },
  name_fuzzy: {
    i18n:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.matchMap.name_fuzzy',
  },
  aka_exact: {
    i18n:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.matchMap.aka_exact',
  },
  aka_fuzzy: {
    i18n:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.matchMap.aka_fuzzy',
  },
  phonetic_name: {
    i18n:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.matchMap.phonetic_name',
  },
  phonetic_aka: {
    i18n:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.matchMap.phonetic_aka',
  },
  equivalent_name: {
    i18n:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.matchMap.equivalent_name',
  },
  unknown: {
    i18n:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.matchMap.unknown',
  },
  equivalent_aka: {
    i18n:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.matchMap.equivalent_aka',
  },
  year_of_birth: {
    i18n:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.matchMap.year_of_birth',
  },
  removed_personal_title: {
    i18n:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.matchMap.removed_personal_title',
  },
  removed_personal_suffix: {
    i18n:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.matchMap.removed_personal_suffix',
  },
  removed_organisation_suffix: {
    i18n:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.matchMap.removed_organisation_suffix',
  },
  removed_clerical_mark: {
    i18n:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.matchMap.removed_clerical_mark',
  },
};

export const backgroundCard = {
  'pep-class-1': {
    backgroundColor: '#FADAE1',
    color: '#521717',
    i18n:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.cardPeps1',
    i18nTooltip:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.pepsTooltip1',
  },
  'pep-class-2': {
    backgroundColor: '#FDE8D6',
    color: '#664014',
    i18n:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.cardPeps2',
    i18nTooltip:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.pepsTooltip2',
  },
  'pep-class-3': {
    backgroundColor: '#FEE0DA',
    color: '#521508',
    i18n:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.cardPeps3',
    i18nTooltip:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.pepsTooltip3',
  },
  'pep-class-4': {
    backgroundColor: '#F2DFF4',
    color: '#4B1052',
    i18n:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.cardPeps4',
    i18nTooltip:
      'components.dataSourceCards.KYCAndCompliance.index.component.card.column.pepsTooltip4',
  },
};
