/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import {
  Company as CompanyIcon,
  Link as CompanyLinksIcon,
  Documentation as RecordsIcon,
  ProgrammingCodeDocumentGroup as InputDataIcon,
} from '@combateafraude/icons/general';
import { MoneyAnalytics as FinancialIcon } from '@combateafraude/icons/financial';
import { Building } from '@combateafraude/icons/builder';
import { Users as PeopleIcon } from '@combateafraude/icons/users';

export const normalizeString = (text) => text.replace(/ /g, '');

export const makeUniqueIdentifier = (text, subtitle) => {
  return `${normalizeString(text)}_${normalizeString(subtitle)}`;
};

export const makePathValueMapByJson = (obj, parentKey = '', result = []) => {
  for (const key of Object.keys(obj)) {
    const value = obj[key];
    const path = parentKey ? `${parentKey}.${key}` : key;
    if (typeof value === 'object' && value !== null) {
      makePathValueMapByJson(value, path, result);
    } else if (path.endsWith('value')) {
      result.push(path);
    }
  }
  return result;
};

const allAnyChildrenLevelChecked = (payload) => {
  const [, ...mapPathsWithValue] = makePathValueMapByJson(payload);
  const result = mapPathsWithValue.every((item) => _.get(payload, item));
  return result;
};

export const handleNavigateToDownHierarchyToKnownIfCheckParent = (data, parentPath) => {
  const parentObject = _.get(data, parentPath);
  if (!parentObject?.items) {
    return [];
  }
  if (parentPath === 'root') {
    return allAnyChildrenLevelChecked(data);
  }
  const childrenNames = Object.keys(parentObject.items);
  const anyChildrenChecked = childrenNames.some((childrenName) => {
    const childrenPath = `${parentPath}.items.${childrenName}.value`;
    const childrenChecked = _.get(data, childrenPath);
    return childrenChecked;
  });
  let newParentValue = parentObject.value;
  if (anyChildrenChecked) {
    newParentValue = true;
  }
  if (!anyChildrenChecked && parentObject.falseWhenNoChildren) {
    newParentValue = false;
  }
  return newParentValue;
};

export const getParentPath = (path) => {
  const indexLastItemsPropsOfPath = path.lastIndexOf('.items.');
  const parentPath = path.substr(0, indexLastItemsPropsOfPath);
  return parentPath;
};

export const getOnlyNameByPath = (path) => {
  return path.replace(/.*\./, '');
};

export const getGrandFatherPath = (checkedPath) => {
  const matches = checkedPath.match(/\.items/g);
  if (matches?.length >= 2) {
    const lastIndex = checkedPath.lastIndexOf('.items');
    const newPathWithoutLastElement = checkedPath.substr(0, lastIndex);
    const penultimateIndex = newPathWithoutLastElement.lastIndexOf('.items');
    return newPathWithoutLastElement.substr(0, penultimateIndex);
  }
  return '';
};

const isToContinue = (path) => path && path !== 'root' && path !== 'root.value';

const checkUncleBasedHierarchy = (payload, parentPath, checkedPath, checkedValue) => {
  const parentObject = getParentPath(parentPath);
  const grandFatherObject = _.get(payload, parentObject);
  if (!grandFatherObject || !grandFatherObject?.items) {
    return payload;
  }
  const findSiblingNames = Object.keys(grandFatherObject?.items);
  if (findSiblingNames && !findSiblingNames?.length) {
    return payload;
  }
  const parentNameByPath = getOnlyNameByPath(parentPath);
  const onlySiblingNames = findSiblingNames?.reduce((acc, name) => {
    if (name !== parentNameByPath) {
      acc.push(name);
      return acc;
    }
    return acc;
  }, []);
  if (!onlySiblingNames?.length) {
    return payload;
  }
  const grandFatherPath = getGrandFatherPath(checkedPath);
  const allUnclesChecked = onlySiblingNames.every((siblingName) => {
    const grandFatherPathIfExist = grandFatherPath ? `${grandFatherPath}.items.` : '';
    const unclePath = `${grandFatherPathIfExist}${siblingName}`;
    const isToCheckUncle = handleNavigateToDownHierarchyToKnownIfCheckParent(
      payload,
      unclePath
    );
    return isToCheckUncle;
  });
  return { allUnclesChecked, grandFatherPath };
};

const checkGrandFatherBasedHierarchy = (
  payload,
  grandFatherPath,
  isToCheckDirectParent
) => {
  if (!isToContinue(grandFatherPath)) {
    return payload;
  }
  const isToCheckGrandFather = isToCheckDirectParent;
  payload = _.set(payload, `${grandFatherPath}.value`, isToCheckGrandFather);
};

export const checkFatherBasedHierarchy = (payload, checkedPath) => {
  const parentPath = getParentPath(checkedPath);
  const parentPathWithValue = `${parentPath}.value`;
  const newParentValue = handleNavigateToDownHierarchyToKnownIfCheckParent(
    payload,
    parentPath
  );
  payload = _.set(payload, parentPathWithValue, newParentValue);
  return {
    parentPath,
    newParentValue,
  };
};

const checkYourSelf = (payload, checkedPath, checkedValue, repeating) => {
  if (!checkedPath || repeating) {
    return payload;
  }
  payload = _.set(payload, checkedPath, checkedValue);
};

const checkYourChildren = (payload, checkedPath, checkedValue, repeating) => {
  if (repeating) {
    return payload;
  }
  const mapPathsWithValue = makePathValueMapByJson(payload);
  const checkedPathWithoutValue = checkedPath.replace('.value', '');
  for (const path of mapPathsWithValue) {
    const pathWithoutValue = path.replace('.value', '');
    const { disabled } = _.get(payload, pathWithoutValue);
    if (path.search(checkedPathWithoutValue) > -1 && !disabled) {
      _.set(payload, path, checkedValue);
    }
  }
};

export const handleCheckBasedHierarchy = (
  data,
  checkedPath,
  checkedValue,
  repeating = false
) => {
  const payload = _.clone(data);
  checkYourSelf(payload, checkedPath, checkedValue, repeating);
  checkYourChildren(payload, checkedPath, checkedValue, repeating);
  if (!isToContinue(checkedPath)) {
    return payload;
  }
  const { parentPath, newParentValue } = checkFatherBasedHierarchy(
    payload,
    checkedPath,
    checkedValue
  );
  const { grandFatherPath } = checkUncleBasedHierarchy(
    payload,
    parentPath,
    checkedPath,
    checkedValue
  );
  if (!isToContinue(grandFatherPath)) {
    return payload;
  }
  checkGrandFatherBasedHierarchy(payload, grandFatherPath, newParentValue);
  if (!isToContinue(grandFatherPath)) {
    return payload;
  }
  handleCheckBasedHierarchy(payload, `${grandFatherPath}.value`, checkedValue, true);
  return payload;
};

export const checkIfThereIsCheckBoxActive = (obj) => {
  if (obj && typeof obj === 'object') {
    if (obj.hasOwnProperty('value') && obj.value === true) {
      return true;
    }

    if (obj.hasOwnProperty('items')) {
      for (const key in obj.items) {
        if (checkIfThereIsCheckBoxActive(obj.items[key])) {
          return true;
        }
      }
    }
  }
  return false;
};

export const getModalData = ({ translate, I18N_BASE_PATH, companyData }) => ({
  root: {
    title: translate(`${I18N_BASE_PATH}.selectAll`, 'Select all'),
    value: false,
    items: {
      summary: {
        title: translate(`${I18N_BASE_PATH}.summary`, 'Summary'),
        value: false,
        icon: CompanyIcon,
      },
      companyInfo: {
        title: translate(`${I18N_BASE_PATH}.companyInfo`, 'Company Info'),
        value: false,
        icon: CompanyIcon,
        falseWhenNoChildren: true,
        disabled: !companyData?.companyInfo?.companyName,
        items: {
          creditReport: {
            title: translate(
              `${I18N_BASE_PATH}.companyInfo.creditReport`,
              'Credit report'
            ),
            value: false,
            disabled: !companyData?.companyInfo?.creditReport,
            items: {
              otherAddresses: {
                title: translate(
                  `${I18N_BASE_PATH}.companyInfo.creditReport.includeOtherKnownAddresses`,
                  'Include Other known addresses'
                ),
                disabled: !companyData?.companyInfo?.creditReport?.otherAddresses,
                value: false,
              },
            },
          },
          businessIdentity: {
            title: translate(
              `${I18N_BASE_PATH}.companyInfo.businessIdentity`,
              'Business identity'
            ),
            value: false,
            disabled: Object.values(companyData.companyInfo.businessIdentity).every(
              (value) => (Array.isArray(value) && value?.length <= 0) || value === '-'
            ),
            items: {
              attributes: {
                title: translate(
                  `${I18N_BASE_PATH}.companyInfo.businessIdentity.attributes`,
                  'Attributes'
                ),
                value: false,
                disabled: !companyData?.companyInfo?.businessIdentity?.attributes?.length,
              },
            },
          },
        },
      },
      financial: {
        title: translate(`${I18N_BASE_PATH}.financial`, 'Financial'),
        value: false,
        icon: FinancialIcon,
        falseWhenNoChildren: true,
        disabled: !(
          companyData?.financial?.financialStatements?.length > 0 ||
          companyData?.financial?.localFinancialStatements?.length > 0 ||
          (companyData?.financial?.negativeInformation?.countyCourtJudgements?.registered
            ?.exact &&
            Object.keys(
              companyData?.financial?.negativeInformation?.countyCourtJudgements
                ?.registered?.exact
            ).length > 0) ||
          companyData?.financial?.negativeInformation?.countyCourtJudgements?.length > 0
        ),
        items: {
          accounts: {
            title: translate(`${I18N_BASE_PATH}.financial.accounts`, 'Accounts'),
            value: false,
            level: 3,
            disabled: !(
              companyData?.financial?.financialStatements?.length > 0 ||
              companyData?.financial?.localFinancialStatements?.length > 0
            ),
          },
          negativeInformations: {
            title: translate(
              `${I18N_BASE_PATH}.financial.negativeInformations`,
              'Negative Informations'
            ),
            value: false,
            level: 3,
            disabled: !(
              (companyData?.financial?.negativeInformation?.countyCourtJudgements
                ?.registered?.exact &&
                Object.keys(
                  companyData?.financial?.negativeInformation?.countyCourtJudgements
                    ?.registered?.exact
                ).length > 0) ||
              companyData?.financial?.negativeInformation?.countyCourtJudgements?.length >
                0
            ),
          },
        },
      },
      comments: {
        title: translate(`${I18N_BASE_PATH}.comments`, 'Comments'),
        value: false,
        disabled: !companyData?.comments?.length,
        icon: PeopleIcon,
      },
      riskFactors: {
        title: translate(`${I18N_BASE_PATH}.riskFactors`, 'Risk factors'),
        value: false,
        disabled: !companyData?.riskFactors?.length,
        icon: PeopleIcon,
      },
      people: {
        title: translate(`${I18N_BASE_PATH}.people`, 'People'),
        value: false,
        icon: PeopleIcon,
        falseWhenNoChildren: true,
        disabled: !(
          companyData?.people?.creditReport?.currentDirectors?.length > 0 ||
          companyData?.people?.creditReport?.previousDirectors?.length > 0 ||
          companyData?.people?.creditReport?.shareHolders?.length > 0 ||
          companyData?.people?.creditReport?.personsWithSignificantControl?.active
            ?.length > 0 ||
          companyData?.people?.businessIdentity?.directors?.length > 0 ||
          companyData?.people?.businessIdentity?.shareHolders?.length > 0 ||
          companyData?.people?.businessIdentity?.officers?.length > 0 ||
          companyData?.people?.businessIdentity?.registeredAgents?.length > 0
        ),
        items: {
          creditReport: {
            title: translate(`${I18N_BASE_PATH}.people.creditReport`, 'Credit report'),
            value: false,
            disabled: !(
              companyData?.people?.creditReport?.currentDirectors.length > 0 ||
              companyData?.people?.creditReport?.previousDirectors.length > 0 ||
              companyData?.people?.creditReport?.shareHolders.length > 0 ||
              companyData?.people?.creditReport?.personsWithSignificantControl?.active
                ?.length > 0
            ),
            items: {
              directors: {
                title: translate(
                  `${I18N_BASE_PATH}.people.creditReport.directors`,
                  'Directors'
                ),
                value: false,
                disabled: !(
                  companyData?.people?.creditReport?.currentDirectors.length > 0 ||
                  companyData?.people?.creditReport?.previousDirectors.length > 0
                ),
              },
              shareholders: {
                title: translate(
                  `${I18N_BASE_PATH}.people.creditReport.shareholders`,
                  'Shareholders'
                ),
                value: false,
                disabled: !companyData?.people?.creditReport?.shareHolders.length > 0,
              },
              pscUbo: {
                title: translate(
                  `${I18N_BASE_PATH}.people.creditReport.pscUbo`,
                  'PSC/UBO'
                ),
                value: false,
                disabled:
                  !companyData?.people?.creditReport?.personsWithSignificantControl
                    ?.active?.length > 0,
              },
            },
          },
          businessIdentity: {
            title: translate(
              `${I18N_BASE_PATH}.people.businessIdentity`,
              'Business identity'
            ),
            value: false,
            disabled: !(
              companyData?.people?.businessIdentity?.directors?.length > 0 ||
              companyData?.people?.businessIdentity?.shareHolders?.length > 0 ||
              companyData?.people?.businessIdentity?.officers?.length > 0 ||
              companyData?.people?.businessIdentity?.registeredAgents?.length > 0
            ),
            items: {
              directors: {
                title: translate(
                  `${I18N_BASE_PATH}.people.businessIdentity.directors`,
                  'Directors'
                ),
                value: false,
                disabled: !companyData?.people?.businessIdentity?.directors?.length > 0,
              },
              shareholders: {
                title: translate(
                  `${I18N_BASE_PATH}.people.businessIdentity.shareholders`,
                  'Shareholders'
                ),
                value: false,
                disabled:
                  !companyData?.people?.businessIdentity?.shareHolders?.length > 0,
              },
              officers: {
                title: translate(
                  `${I18N_BASE_PATH}.people.businessIdentity.officers`,
                  'Officers'
                ),
                value: false,
                disabled: !companyData?.people?.businessIdentity?.officers?.length > 0,
              },
              registeredAgents: {
                title: translate(
                  `${I18N_BASE_PATH}.people.businessIdentity.registeredAgents`,
                  'Registered agents'
                ),
                value: false,
                disabled:
                  !companyData?.people?.businessIdentity?.registeredAgents?.length > 0,
              },
            },
          },
        },
      },
      groupStructure: {
        title: translate(`${I18N_BASE_PATH}.groupStructure`, 'Group structure'),
        value: false,
        icon: Building,
        disabled: !(
          companyData?.companyLinks?.countCompanies ||
          companyData?.companyLinks?.countCountries ||
          companyData?.companyLinks?.groupStructure.length > 0 ||
          companyData?.companyLinks?.holdingCompanyName !== '-' ||
          companyData?.companyLinks?.ownershipStatus !== '-' ||
          companyData?.companyLinks?.ultimateHoldingCompanyName !== '-'
        ),
      },
      records: {
        title: translate(`${I18N_BASE_PATH}.records`, 'Records'),
        value: false,
        icon: RecordsIcon,
        disabled: !(companyData?.records?.records?.length > 0),
        items: {
          includeRecordDetails: {
            level: 3,
            title: translate(
              `${I18N_BASE_PATH}.records.includeRecordsDetails`,
              'Include records details'
            ),
            value: false,
            disabled: !(companyData?.records?.records?.length > 0),
          },
        },
      },
      inputData: {
        title: translate(`${I18N_BASE_PATH}.inputData`, 'Input data'),
        value: false,
        icon: InputDataIcon,
        falseWhenNoChildren: true,
        items: {
          parameters: {
            title: translate(`${I18N_BASE_PATH}.inputData.parameters`, 'Parameters'),
            value: false,
            level: 3,
          },
          metadata: {
            title: translate(`${I18N_BASE_PATH}.inputData.metadata`, 'Metadata'),
            value: false,
            level: 3,
            disabled: Object.keys(companyData?.inputData?.metadata || {}).length <= 0,
          },
        },
      },
    },
  },
});
