import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col, Typography, Divider, Tooltip } from 'antd';
import Tag from 'components/Tag';
import {
  Consultas as ConsultasIcon,
  Admin as AdminIcon,
} from '@combateafraude/icons/general';
import { BrowserShield as BrowserShieldIcon } from '@combateafraude/icons/security';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import Card from 'components/Card/Standard';

import ActionsList from './components/ActionsList';

import './styles.less';

const { Text } = Typography;

const TokenCard = ({ data, refreshList }) => {
  const { t: translate } = useTranslation();
  const permissionsTags = {
    'transactions:read': {
      icon: (
        <Tooltip
          placement="top"
          title={translate(
            'pages.private.accessKeys.tokens.components.tokenCard.index.transactions.read.tooltip'
          )}
        >
          <ConsultasIcon className="mrg-left-5" />
        </Tooltip>
      ),
      title: translate(
        'pages.private.accessKeys.tokens.components.tokenCard.index.transactions.read.title'
      ),
    },
    'transactions:create': {
      icon: (
        <Tooltip
          title={translate(
            'pages.private.accessKeys.tokens.components.tokenCard.index.transactions.create.tooltip'
          )}
        >
          <BrowserShieldIcon className="mrg-left-5" />
        </Tooltip>
      ),
      title: translate(
        'pages.private.accessKeys.tokens.components.tokenCard.index.transactions.create.title'
      ),
    },
    'transactions:*': {
      icon: (
        <Tooltip
          title={translate(
            'pages.private.accessKeys.tokens.components.tokenCard.index.transactions.full.tooltip'
          )}
        >
          <AdminIcon className="mrg-left-5" />
        </Tooltip>
      ),
      title: translate(
        'pages.private.accessKeys.tokens.components.tokenCard.index.transactions.full.title'
      ),
    },
    'transactions-records:read': {
      icon: (
        <Tooltip
          placement="top"
          title={translate(
            'pages.private.accessKeys.tokens.components.tokenCard.index.transactionsRecords.read.tooltip'
          )}
        >
          <ConsultasIcon className="mrg-left-5" />
        </Tooltip>
      ),
      title: translate(
        'pages.private.accessKeys.tokens.components.tokenCard.index.transactionsRecords.read.title'
      ),
    },
    'transactions-records:create': {
      icon: (
        <Tooltip
          title={translate(
            'pages.private.accessKeys.tokens.components.tokenCard.index.transactionsRecords.create.tooltip'
          )}
        >
          <BrowserShieldIcon className="mrg-left-5" />
        </Tooltip>
      ),
      title: translate(
        'pages.private.accessKeys.tokens.components.tokenCard.index.transactionsRecords.create.title'
      ),
    },
    'transactions-records:delete': {
      icon: (
        <Tooltip
          title={translate(
            'pages.private.accessKeys.tokens.components.tokenCard.index.transactionsRecords.delete.tooltip'
          )}
        >
          <BrowserShieldIcon className="mrg-left-5" />
        </Tooltip>
      ),
      title: translate(
        'pages.private.accessKeys.tokens.components.tokenCard.index.transactionsRecords.delete.title'
      ),
    },
    'transactions-records:*': {
      icon: (
        <Tooltip
          title={translate(
            'pages.private.accessKeys.tokens.components.tokenCard.index.transactionsRecords.full.tooltip'
          )}
        >
          <AdminIcon className="mrg-left-5" />
        </Tooltip>
      ),
      title: translate(
        'pages.private.accessKeys.tokens.components.tokenCard.index.transactionsRecords.full.title'
      ),
    },
    'services:*': {
      icon: (
        <Tooltip
          title={translate(
            'pages.private.accessKeys.tokens.components.tokenCard.index.services.full.tooltip'
          )}
        >
          <AdminIcon className="mrg-left-5" />
        </Tooltip>
      ),
      title: translate(
        'pages.private.accessKeys.tokens.components.tokenCard.index.services.full.title'
      ),
    },
    'services:query': {
      icon: (
        <Tooltip
          title={translate(
            'pages.private.accessKeys.tokens.components.tokenCard.index.services.query.tooltip'
          )}
        >
          <BrowserShieldIcon className="mrg-left-5" />
        </Tooltip>
      ),
      title: translate(
        'pages.private.accessKeys.tokens.components.tokenCard.index.services.query.title'
      ),
    },
    'manual-reviews:all': {
      icon: (
        <Tooltip
          title={translate(
            'pages.private.accessKeys.tokens.components.tokenCard.index.manualReviews.full.tooltip'
          )}
        >
          <AdminIcon className="mrg-left-5" />
        </Tooltip>
      ),
      title: translate(
        'pages.private.accessKeys.tokens.components.tokenCard.index.manualReviews.full.title'
      ),
    },
    'onboardings:create': {
      icon: (
        <Tooltip
          title={translate(
            'pages.private.accessKeys.tokens.components.tokenCard.index.onboardings.create.tooltip'
          )}
        >
          <AdminIcon className="mrg-left-5" />
        </Tooltip>
      ),
      title: translate(
        'pages.private.accessKeys.tokens.components.tokenCard.index.onboardings.create.title'
      ),
    },
    'onboardings:*': {
      icon: (
        <Tooltip
          title={translate(
            'pages.private.accessKeys.tokens.components.tokenCard.index.onboardings.full.tooltip'
          )}
        >
          <AdminIcon className="mrg-left-5" />
        </Tooltip>
      ),
      title: translate(
        'pages.private.accessKeys.tokens.components.tokenCard.index.onboardings.full.title'
      ),
    },
  };
  const { i18nFormatDate } = useI18nFormatters();
  return (
    <Card id="access-keys-card-component">
      <Row className="pdd-horizon-20 no-mrg">
        <Col span={8} className="cells flex-jc-center pdd-vertical-20">
          <b className="no-mrg text-dark">{data?.keyName}</b>
        </Col>
        <Col span={8} className="cells border-left flex-jc-center pdd-vertical-20">
          <b className="no-mrg text-dark">
            {translate(
              'pages.private.accessKeys.tokens.components.tokenCard.index.createdAt'
            )}
          </b>
          <Text style={{ lineHeight: '34px' }}>
            {i18nFormatDate(data?.createdAt || new Date(), 'default')}
          </Text>
        </Col>
        <Col span={5} className="cells border-left flex-jc-center pdd-vertical-20">
          <b className="no-mrg text-dark">
            {translate(
              'pages.private.accessKeys.tokens.components.tokenCard.index.lastUsed'
            )}
          </b>
          <Text style={{ lineHeight: '34px' }}>
            {i18nFormatDate(data?.lastUseAt || new Date(), 'default')}
          </Text>
        </Col>
        <Col span={3} className="flex-jc-center pdd-vertical-20">
          <ActionsList data={data} refreshListRef={refreshList} />
        </Col>
      </Row>

      <Divider className="no-mrg no-pdd" />
      <Row className="mrg-vertical-10 pdd-horizon-50">
        {data?.permissions?.map((perm) => (
          <Tag
            className="text-bold mrg-right-10"
            style={{ backgroundColor: 'rgba(130, 130, 130, 0.15)' }}
          >
            {permissionsTags[perm]?.title}
            {permissionsTags[perm]?.icon}
          </Tag>
        ))}
      </Row>
    </Card>
  );
};

TokenCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    clientId: PropTypes.string,
    clientSecret: PropTypes.string,
    tenantId: PropTypes.string,
    createdAt: PropTypes.string,
    policies: PropTypes.arrayOf(PropTypes.shape({})),
    keyName: PropTypes.string,
  }),
  refreshList: PropTypes.func.isRequired,
};

TokenCard.defaultProps = {
  data: undefined,
};

export default TokenCard;
