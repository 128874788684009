/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import EmptyExecutionState from 'components/EmptyExecutionState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';
import usePersonalLaborAndFinancialInformation from 'pages/private/Executions/components/DataSourceModals/PersonalLaborAndFinancialInformation';
import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.people.components.socialAssistance.index';

const SocialAssistance = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatCurrency } = useI18nFormatters();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const {
    openModal,
    PersonalLaborAndFinancialInformationModal,
  } = usePersonalLaborAndFinancialInformation({
    title: translate(`${I18N_BASE_PATH}.usePersonalLaborAndFinancialInformation.title`),
    data: parsedData?.socialAssistances,
    socialAssistance: true,
  });

  const totalIncome = useMemo(() => {
    const assistanceValues = [];

    parsedData?.socialAssistances?.forEach((assistance) =>
      assistanceValues.push(assistance?.assistanceAmount || 0)
    );

    const total = assistanceValues?.reduce((acc, cur) => acc + cur, 0) || 0;

    return total;
  }, [parsedData]);

  const handlerQuantity = useMemo(() => {
    if (!parsedData || !queryWorked) return { title: null, type: null };

    if (!parsedData?.socialAssistances || parsedData?.socialAssistances?.length === 0) {
      return {
        title: translate(`${I18N_BASE_PATH}.handlerQuantity.default`),
        type: 'empty',
      };
    }

    if (parsedData?.socialAssistances?.length === 1) {
      return {
        title: translate(`${I18N_BASE_PATH}.handlerQuantity.hasOneSocialAssistance`),
        type: 'primary',
      };
    }

    return {
      title: translate(
        `${I18N_BASE_PATH}.handlerQuantity.hasMoreThanOneSocialAssistances`,
        { value: parsedData?.socialAssistances?.length }
      ),
      type: 'primary',
    };
  }, [parsedData, queryWorked, translate]);

  const handleOpenModal = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <>
      {PersonalLaborAndFinancialInformationModal}
      <DataSourceCardCollapsePanel
        title={translate(`${I18N_BASE_PATH}.socialAssistance.columnLabel.title`)}
        icon="caf-ic_users"
        toPrint={toPrint}
        customId="financialInfo"
      >
        <ColumnLabel
          title={translate(`${I18N_BASE_PATH}.socialAssistance.columnLabel.title`)}
          description={handlerQuantity?.title}
          type={handlerQuantity?.type}
          onClick={parsedData?.socialAssistances?.length > 0 && handleOpenModal}
        >
          {queryWorked ? (
            <>
              {!parsedData?.socialAssistances ||
              parsedData?.socialAssistances?.length === 0 ? (
                <span>
                  {translate(
                    `${I18N_BASE_PATH}.socialAssistance.columnLabel.description.hasNotData`
                  )}
                </span>
              ) : (
                <>
                  {toPrint && (
                    <span>
                      {translate(
                        `${I18N_BASE_PATH}.socialAssistance.columnLabel.description.hasToPrint.before`
                      )}{' '}
                      {i18nFormatCurrency(totalIncome, 'pt-BR')}{' '}
                      {translate(
                        `${I18N_BASE_PATH}.socialAssistance.columnLabel.description.hasToPrint.after`
                      )}
                    </span>
                  )}
                  {!toPrint && (
                    <span>
                      {translate(
                        `${I18N_BASE_PATH}.socialAssistance.columnLabel.description.hasNotToPrint.before`
                      )}{' '}
                      {i18nFormatCurrency(totalIncome, 'pt-BR')}{' '}
                      {translate(
                        `${I18N_BASE_PATH}.socialAssistance.columnLabel.description.hasNotToPrint.after`
                      )}
                    </span>
                  )}
                </>
              )}
            </>
          ) : (
            <EmptyExecutionState
              statusCode={statusCode}
              message={parsedData?.message || source?.data?.message}
            />
          )}
        </ColumnLabel>
      </DataSourceCardCollapsePanel>
    </>
  );
};

export default SocialAssistance;
