import React from 'react';
import { useTranslation } from 'react-i18next';
import TransactionRecordsFiles from 'components/Files/TransactionRecordsFiles';
import KYBFilesIcon from 'assets/images/company/files.svg';
import TabTitle from '../../TabTitle';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companyDetails.subPages.files.index';

const Files = () => {
  const { t: translate } = useTranslation();

  return (
    <section className="KYB-files">
      <TabTitle
        icon={KYBFilesIcon}
        title={translate(`${I18N_BASE_PATH}.title`, 'Files')}
        subtitle={translate(`${I18N_BASE_PATH}.subtitle`, 'Files')}
      />
      <TransactionRecordsFiles />
    </section>
  );
};

export default Files;
