import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Menu, Dropdown } from 'antd';

import PermissionWrapper from 'routes/PermissionWrapper';

import useDeleteUserFromGroupModal from '../DeleteUserFromGroup';

const UsersInternalActionsList = ({ user, refreshListRef }) => {
  const { t: translate } = useTranslation();

  const {
    openModal: openDeleteUserFromGroupModal,
    DeleteUserFromGroupModal,
  } = useDeleteUserFromGroupModal({ user, refreshListRef });

  return (
    <>
      {DeleteUserFromGroupModal}
      <PermissionWrapper requiredPermissions={['users:update']} product="management">
        <Dropdown
          overlay={
            <Menu className="pdd-vertical-5">
              <Menu.Item
                key="deleteUserInGroup"
                className="text-dark"
                onClick={openDeleteUserFromGroupModal}
              >
                <i className="caf-ic_trash mrg-right-10" />
                <span className="text-semibold">
                  {translate(
                    'pages.private.groups.components.groupContent.usersInternalActions.removeFromGroup'
                  )}
                </span>
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
          placement="bottomRight"
        >
          <div className="btn-more-icon btn-more-icon-hover">
            <i className="caf-ic_more-vertical" />
          </div>
        </Dropdown>
      </PermissionWrapper>
    </>
  );
};

UsersInternalActionsList.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  refreshListRef: PropTypes.shape({
    current: PropTypes.func.isRequired,
  }).isRequired,
};

UsersInternalActionsList.defaultProps = {
  user: {},
};

export default UsersInternalActionsList;
