import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';
import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import { cpfTranslation, executionCpfErrosTranslation } from 'utils/personalTranslations';
import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

const I18N_BASE_PATH =
  'components.dataSourceCards.basicInfos.components.person.registrationInfosContent.registrationInfosContent.basicInfosCard';

export const PfBasicData = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const translatedMessage = useMemo(
    () =>
      statusCode !== '01' && !parsedData?.taxIdStatus?.trim()?.length
        ? executionCpfErrosTranslation[statusCode] || executionCpfErrosTranslation['01']
        : cpfTranslation[(parsedData?.taxIdStatus || '').trim()] || cpfTranslation.NULA,
    [parsedData, statusCode]
  );

  const parsedBirthDate = useMemo(() => {
    if (parsedData?.birthDate) {
      const date = parsedData?.birthDate.substring(0, 10);

      return date.split('-').reverse().join('/');
    }
    return '-';
  }, [parsedData]);

  const parsedTaxIdStatusDate = useMemo(() => {
    if (parsedData?.taxIdStatusDate)
      return i18nFormatDate(parsedData?.taxIdStatusDate, 1);
    return '-';
  }, [i18nFormatDate, parsedData]);

  const parsedCreationDate = useMemo(() => {
    if (parsedData?.creationDate) {
      const date = parsedData?.creationDate.substring(0, 10);

      return date.split('-').reverse().join('/');
    }
    return '-';
  }, [parsedData]);

  const parsedLastUpdateDate = useMemo(() => {
    if (parsedData?.lastUpdateDate) {
      const date = parsedData?.lastUpdateDate.substring(0, 10);

      return date.split('-').reverse().join('/');
    }
    return '-';
  }, [parsedData]);

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_file"
      title={translate(
        'components.dataSourceCards.basicInfos.components.basicInfosCard.sources.pfBasicData'
      )}
      dataIndex="basicData"
      toPrint={toPrint}
    >
      <Row className="pdd-top-10 ">
        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.name.title`)}
        >
          <span className="text-dark">{parsedData?.name || '-'}</span>
        </ColumnLabel>
        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.gender.title`)}
        >
          <span className="text-dark">{parsedData?.gender || '-'}</span>
        </ColumnLabel>

        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.birthDate.title`)}
        >
          <span className="text-dark">{parsedBirthDate}</span>
        </ColumnLabel>

        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.motherName.title`)}
        >
          <span className="text-dark">{parsedData?.motherName || '-'}</span>
        </ColumnLabel>

        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.fatherName.title`)}
        >
          <span className="text-dark">{parsedData?.fatherName || '-'}</span>
        </ColumnLabel>

        <ColumnLabel
          title={translate(`${I18N_BASE_PATH}.columnLabel.status.title`)}
          description={translate(translatedMessage?.description)}
          type={translatedMessage?.type}
          span={8}
        />

        <ColumnLabel
          title={translate(`${I18N_BASE_PATH}.columnLabel.statusOrigin.title`)}
          span={8}
        >
          <span className="text-dark">{parsedData?.taxIdOrigin || '-'}</span>
        </ColumnLabel>
        <ColumnLabel
          title={translate(`${I18N_BASE_PATH}.columnLabel.statusOriginDate.title`)}
          span={8}
        >
          <span className="text-dark">{parsedTaxIdStatusDate}</span>
        </ColumnLabel>

        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.creationDate.title`)}
        >
          <span className="text-dark">{parsedCreationDate}</span>
        </ColumnLabel>

        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.lastUpdateDate.title`)}
        >
          <span className="text-dark">{parsedLastUpdateDate}</span>
        </ColumnLabel>
      </Row>
    </DataSourceCardCollapsePanel>
  );
};
