import { Col, Collapse, Row, Divider } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DottedLabel from 'components/DottedLabel';
import EmptyExecutionState from 'components/EmptyExecutionState';

import { toMaskedCpf, toMaskedCnpj } from 'utils/formatters';
import { isEmpty } from 'utils/isObjectEmpty';

import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

const { Panel } = Collapse;

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.companies.components.pjCompanyOwnersPercentage';

function CompanyOwnersPercentage({ source = {}, toPrint = false }) {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const hasContent = useMemo(
    () =>
      !isEmpty(parsedData) &&
      parsedData?.message !== 'Informações indisponíveis para este documento.' &&
      source?.message !== 'Informações indisponíveis para este documento.',
    [parsedData, source]
  );

  return !queryWorked || !hasContent ? (
    <DataSourceCardCollapsePanel
      customId="financial-infos-component"
      toPrint={toPrint}
      title={translate(`${I18N_BASE_PATH}.index.title`)}
      icon="caf-ic_user_active"
    >
      <EmptyExecutionState
        statusCode={statusCode}
        message={parsedData?.message || source?.message}
      />
    </DataSourceCardCollapsePanel>
  ) : (
    <DataSourceCardCollapsePanel
      customId="financial-infos-component"
      toPrint={toPrint}
      title={translate(`${I18N_BASE_PATH}.index.title`)}
      icon="caf-ic_user_active"
    >
      <Collapse className="mrg-top-10">
        <DataSourceCardCollapsePanel
          disabled={!parsedData?.companyShares?.length}
          toPrint={toPrint}
          customHeader={
            <Col>
              <Row align="middle" justify="space-between">
                <Col span={17}>
                  <span className="text-dark text-bold">
                    {translate(`${I18N_BASE_PATH}.index.collapseTitle`)}
                  </span>
                </Col>
                <Col className="mrg-right-15">
                  <>
                    <DottedLabel
                      description={`(${
                        parsedData?.companyShares?.length || 0
                      }) ${translate(`${I18N_BASE_PATH}.index.registers`)}`}
                      type="primary"
                    />
                  </>
                </Col>
              </Row>
            </Col>
          }
        >
          {parsedData?.companyShares?.map((register, index) => (
            <>
              <Col span={24}>
                <Row align="middle">
                  <span className="text-dark text-bold">
                    {register?.corporateName || '-'}
                  </span>
                </Row>
                <Row align="middle" justify="space-between" className="mrg-top-10">
                  {register?.cpf && (
                    <Col>
                      <span className="collapse-label">
                        {translate(`${I18N_BASE_PATH}.index.columns.cpf`)}:
                      </span>
                      <span> {toMaskedCpf(register?.cpf)}</span>
                    </Col>
                  )}

                  {register?.cnpj && (
                    <Col>
                      <span className="collapse-label">
                        {translate(`${I18N_BASE_PATH}.index.columns.cnpj`)}:
                      </span>
                      <span className=""> {toMaskedCnpj(register?.cnpj)}</span>
                    </Col>
                  )}

                  <Col>
                    <span className="collapse-label">
                      {translate(`${I18N_BASE_PATH}.index.columns.participation`)}:
                    </span>
                    <span className="">{` ${register?.totalShare || '0'}%`}</span>
                  </Col>
                </Row>
              </Col>
              {index < parsedData?.companyShares?.length - 1 && <Divider />}
            </>
          ))}
        </DataSourceCardCollapsePanel>
      </Collapse>
    </DataSourceCardCollapsePanel>
  );
}

export default CompanyOwnersPercentage;
