import React from 'react';

import Approved from 'assets/images/approved-icon.svg';
import Reproved from 'assets/images/reproved-icon.svg';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.dashboard.index.charts.percentageCard';

const PercentageData = ({ data }) => {
  const { t: translate, i18n } = useTranslation();

  const total = data?.approved + data?.reproved;
  const percentageApproved = Math.round((data?.approved / total) * 100);
  const percentageReproved = Math.round((data?.reproved / total) * 100);

  return (
    <div className="PercentageData">
      <div>
        <img src={Approved} alt="" />
        <aside>
          <span>{percentageApproved || 0}%</span>
          <p>
            {translate(
              `${I18N_BASE_PATH}.labels.approved`,
              'of transactions were approved'
            )}
          </p>
        </aside>
      </div>
      <Divider />
      <div>
        <img src={Reproved} alt="" />
        <aside>
          <span>{percentageReproved || 0}%</span>
          <p>
            {translate(
              `${I18N_BASE_PATH}.labels.reproved`,
              'of transactions were reproved'
            )}
          </p>
        </aside>
      </div>
    </div>
  );
};

PercentageData.propTypes = {
  data: PropTypes.shape({
    approved: PropTypes.number,
    reproved: PropTypes.number,
  }).isRequired,
};

export default PercentageData;
