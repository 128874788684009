import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyExecutionState from 'components/EmptyExecutionState';
import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import { toMaskedCnpj } from 'utils/formatters';
import { Col, Row } from 'antd';

import './styles.less';

const I18N_BASE_PATH = 'components.dataSourceCards.basicInfos.components';

const PjSimples = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();
  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_file"
      title={translate(`${I18N_BASE_PATH}.basicInfosCard.sources.pjSimples`)}
      dataIndex="basicData"
      toPrint={toPrint}
    >
      {!queryWorked ? (
        <EmptyExecutionState
          className="mrg-top-10"
          statusCode={statusCode}
          message={parsedData?.message}
        />
      ) : (
        <Row id="simples-data-id">
          <Col span={24} className="mrg-btm-25 col-ant">
            <span className="text-style">
              <strong>
                {translate(`${I18N_BASE_PATH}.company.pjSimples.officialName`)}:{' '}
              </strong>
              {parsedData?.officialName}
            </span>
          </Col>
          <Col span={8} className="col-ant">
            <span className="text-style">
              <strong>{translate(`${I18N_BASE_PATH}.company.pjSimples.cnpj`)}: </strong>
              {toMaskedCnpj(parsedData?.cnpj)}
            </span>
          </Col>
          <Col span={8} className="col-ant">
            <span className="text-style">
              <strong>
                {translate(`${I18N_BASE_PATH}.company.pjSimples.simples`)}:{' '}
              </strong>
              {parsedData?.simples ? 'Sim' : 'Não'}
            </span>
          </Col>
          <Col span={8} className="col-ant">
            <span className="text-style">
              <strong>
                {translate(`${I18N_BASE_PATH}.company.pjSimples.taxRegime`)}:{' '}
              </strong>
              {parsedData?.taxRegime}
            </span>
          </Col>
        </Row>
      )}
    </DataSourceCardCollapsePanel>
  );
};

export default PjSimples;
