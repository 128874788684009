import React from 'react';
import PropTypes from 'prop-types';

import Notifications from './components/Notifications';
import HeaderFilter from './components/HeaderFilter';
import ExportOptions from './components/ExportOptions';

import './styles.less';

const DataHeader = ({ title, config, user }) => {
  const isHubUser = user?.hubData?.hubAccountsData?.length > 1 && user?.hubData?.active;
  return (
    <>
      <div className="header-skeleton">
        <div className="header-row">
          <span className="header-title">
            {title}
            {user?.accountData?.fantasyName &&
              isHubUser &&
              ` | ${user?.accountData?.fantasyName}`}
          </span>
          {config.filters && <HeaderFilter />}
        </div>
        {config.export?.length > 0 && (
          <div className="button-header">
            <ExportOptions options={config.export} />
          </div>
        )}
        <div className="button-header">{config.notifications && <Notifications />}</div>
      </div>
    </>
  );
};

DataHeader.propTypes = {
  title: PropTypes.string.isRequired,
  user: PropTypes.shape({
    accountData: PropTypes.shape({
      fantasyName: PropTypes.string.isRequired,
    }),
    hubData: PropTypes.shape({
      hubAccountsData: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
    }),
  }).isRequired,
  config: PropTypes.shape({
    filters: PropTypes.bool,
    export: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.func,
      })
    ),
    notifications: PropTypes.bool,
  }).isRequired,
};

export default DataHeader;
