import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Breadcrumb, Dropdown, Menu, message, Tooltip } from 'antd';
import { useAuth } from 'hooks/auth';

import Button from 'components/Button';
import useModalExecutionsApiGuide from 'components/ModalExecutionsApiGuide';
import { useFetch } from 'services/hooks';
import { useProfileTemplate } from 'hooks/profileTemplate';
import useDossierPrint from 'pages/private/Executions/components/DossierPrint';

import { useI18nFormatters } from 'hooks/i18nFormatters';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';
import { hasRequiredPermissions } from 'routes/permissionFunctions';
import ExecutionSubheaderParentLink from './components/ParentLink';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.executions.components.executionSubheader.index';
const ExecutionSubheader = ({ execution }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();
  const { user } = useAuth();
  const { openModal, ExecutionApiGuideModal } = useModalExecutionsApiGuide();
  const { post } = useFetch();

  const { loadProfileTemplates, allProfileTemplates } = useProfileTemplate();
  const { openModal: openDossierPrintModal, DossierPrintModal } = useDossierPrint();

  useEffect(() => {
    loadProfileTemplates({});
  }, [loadProfileTemplates]);

  const hasWebhook = useMemo(() => {
    if (execution?.templateId && allProfileTemplates?.length > 0) {
      const template = allProfileTemplates?.find((t) => t._id === execution?.templateId);
      return !!template?.webhook;
    }
    return false;
  }, [allProfileTemplates, execution]);

  const handleCallWebhook = useCallback(async () => {
    try {
      message.info(translate(`${I18N_BASE_PATH}.callWebhookMessages.pending`));
      await post({
        url: `/executions/${execution._id}/webhook`,
        payload: {
          status: execution.status,
        },
        showMessage: false,
      });
      message.success(translate(`${I18N_BASE_PATH}.callWebhookMessages.success`));
    } catch (error) {
      message.error(translate(`${I18N_BASE_PATH}.callWebhookMessages.error`));
    }
  }, [execution, post, translate]);

  const executionData = useMemo(() => {
    return execution;
  }, [execution]);

  const standardBreadcrumbs = useMemo(
    () => (
      <Breadcrumb className="beadcrums-color" separator="/">
        <Breadcrumb.Item className="beadcrums-color">
          <Link
            className="gx-link"
            to={hasFeatureFlag('isWorkflowBuilder', user) ? '/person' : '/executions'}
          >
            {translate(`${I18N_BASE_PATH}.standardBreadcrumbs.link`)}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="beadcrums-color">
          {translate(`${I18N_BASE_PATH}.standardBreadcrumbs.breadcrumbDescription`)}
        </Breadcrumb.Item>
      </Breadcrumb>
    ),
    [translate, user]
  );

  const linkBackoffice = useMemo(() => {
    const reportId = execution?.reportId || '000000000000000000000000';

    if (process.env.REACT_APP_ENV !== 'prod') {
      return `https://${process.env.REACT_APP_ENV}.backoffice.combateafraude.com/executions/details/user/${execution?.metadata?.tenantId}/report/${reportId}/execution/${execution?._id}`;
    }

    return `https://backoffice.combateafraude.com/executions/details/user/${execution?.metadata?.tenantId}/report/${reportId}/execution/${execution?._id}`;
  }, [execution]);

  const showBackofficeLink = useMemo(
    () => user?.expiresAfterLogin || process.env.REACT_APP_ENV !== 'prod',
    [user]
  );

  const handleSource = useMemo(() => {
    if (!executionData?.src) return;

    switch (executionData?.src) {
      case 'ONBOARDING_EXT':
        return translate(`${I18N_BASE_PATH}.handleSource.ONBOARDING_EXT`);
      case 'HEADER_AUTHORIZATION':
        return translate(`${I18N_BASE_PATH}.handleSource.HEADER_AUTHORIZATION`);
      case 'API_TOKEN':
        return translate(`${I18N_BASE_PATH}.handleSource.API_TOKEN`);

      default:
    }
  }, [executionData, translate]);

  const webhookItemContent = useMemo(() => {
    if (hasWebhook) {
      return (
        <>
          <i className="caf-ic_link" />
          {translate(`${I18N_BASE_PATH}.actions.callWebhook`)}
        </>
      );
    }

    return (
      <Tooltip
        placement="left"
        title={translate(`${I18N_BASE_PATH}.actions.callWebhookDisabled`)}
      >
        <i className="caf-ic_link" />
        {translate(`${I18N_BASE_PATH}.actions.callWebhook`)}
      </Tooltip>
    );
  }, [hasWebhook, translate]);

  return (
    <>
      {ExecutionApiGuideModal}
      <section id="execution-subheader">
        <div className="gx-header-horizontal-top">
          <div className="gx-container">
            <div className="execution-subheader-content">
              <div className="profile-info custom-breadcrumbs">
                {standardBreadcrumbs}
                {DossierPrintModal}
                <h5 className="profile-name max-lines-2">
                  {translate(`${I18N_BASE_PATH}.executionSubheader.title`)}
                </h5>

                <p className="profile-last-update no-mrg">
                  {translate(
                    `${I18N_BASE_PATH}.executionSubheader.paragraph.consultation.before`
                  )}{' '}
                  {i18nFormatDate(executionData?.createdAt, 'default')}
                  {executionData?.createdBy?.email && (
                    <>
                      {' '}
                      {translate(
                        `${I18N_BASE_PATH}.executionSubheader.paragraph.consultation.inside`
                      )}{' '}
                      {executionData?.createdBy?.email}
                    </>
                  )}
                  {handleSource && (
                    <>
                      {' '}
                      {translate(
                        `${I18N_BASE_PATH}.executionSubheader.paragraph.consultation.after`
                      )}{' '}
                      {handleSource}
                    </>
                  )}
                  .{' '}
                  {executionData?.updatedAt
                    ? `${translate(
                        `${I18N_BASE_PATH}.executionSubheader.paragraph.update`
                      )} ${i18nFormatDate(executionData?.updatedAt, 'default')}.`
                    : ''}{' '}
                  <ExecutionSubheaderParentLink parentId={execution?.parentId} />
                </p>
              </div>
              <Dropdown
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu id="actions-dropdown-menu-content">
                    {hasRequiredPermissions(user, ['integration-guide:read'], []) && (
                      <>
                        <Menu.Item
                          disabled={!execution}
                          key="1"
                          onClick={() => openModal()}
                        >
                          <i className="caf-ic_code" />
                          {translate(`${I18N_BASE_PATH}.actions.apiGuide`)}
                        </Menu.Item>
                        <Menu.Item
                          disabled={!hasWebhook}
                          key="2"
                          onClick={handleCallWebhook}
                        >
                          {webhookItemContent}
                        </Menu.Item>
                      </>
                    )}
                    {showBackofficeLink && (
                      <Menu.Item key="3">
                        <i className="caf-ic_duplicate" />
                        <a href={linkBackoffice} target="blank">
                          {translate(`${I18N_BASE_PATH}.actions.backofficeExecution`)}
                        </a>
                      </Menu.Item>
                    )}
                    <Menu.Item
                      key="4"
                      disabled={!execution || !allProfileTemplates?.length}
                      onClick={() => {
                        const templateId = execution?.templateId;

                        const templateType = allProfileTemplates?.find(
                          (template) => template?._id === templateId
                        )?.type;

                        openDossierPrintModal(templateType);
                      }}
                    >
                      <i className="caf-ic_printer" />
                      {translate(`${I18N_BASE_PATH}.actions.print`)}
                    </Menu.Item>
                  </Menu>
                }
              >
                {!hasFeatureFlag('isWorkflowBuilder', user) ? (
                  <Button type="primary">
                    {translate(`${I18N_BASE_PATH}.actions.button`)}{' '}
                    <i className="caf-ic_more-horizontal mrg-left-5" />
                  </Button>
                ) : (
                  <></>
                )}
              </Dropdown>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

ExecutionSubheader.propTypes = {
  execution: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ExecutionSubheader;
