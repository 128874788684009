import React, { useState, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { Modal, Checkbox, Row, Col, Divider, Switch } from 'antd';

import * as pjDataSources from 'utils/pjDataSource';
import * as pfDataSources from 'utils/pfDataSource';
import * as executionDataSource from 'utils/executionDataSource';

import PageTitle from 'components/PageTitle';
import Button from 'components/Button';

import { useExecution } from 'hooks/execution';

import { checkboxOptions } from './utils/checkboxOptions';
import { backgroudCheckingOptions } from './utils/backgroudCheckingOptions';

import ToPrint from './components/ToPrint';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.profiles.components.dossierPrint.index';

const DossierPrint = () => {
  const { t: translate } = useTranslation();

  const componentRef = useRef();

  const { execution } = useExecution();

  const [modalVisible, setModalVisible] = useState(false);
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [loadingActivities, setLoadingActivities] = useState(false);

  const [checkAll, setCheckAll] = useState(false);
  const [checkAllBackgroud, setCheckAllBackgroud] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [executionType, setExecutionType] = useState();

  const [checkedList, setCheckedList] = useState([]);
  const [checkedBackgroudList, setCheckedBackgroudList] = useState([]);

  const closeModal = useCallback(() => {
    setCheckedList([]);
    setCheckAll(false);
    setLoadingPrint(false);
    setModalVisible(false);
    setLoadingActivities(false);
  }, []);

  const openModal = useCallback((execType) => {
    setExecutionType(execType);
    setModalVisible(true);
  }, []);

  const personId = useMemo(() => {
    return execution?.parameters?.cpf;
  }, [execution]);

  const companyId = useMemo(() => {
    return execution?.parameters?.cnpj;
  }, [execution]);

  const blockedDataSources = useMemo(() => {
    const mappedDataSources = [];

    if (companyId) {
      const dataSources =
        Object.keys(
          execution?.sources ||
            execution?.sections ||
            execution?.profileTemplate?.backgroundChecking?.services ||
            {}
        )?.map((source) => source) || [];

      if (!pjDataSources.hasFinancialInformation(execution, dataSources))
        mappedDataSources.push('financial_information');
      if (!pjDataSources.hasRestrictiveListsInformation(execution, dataSources))
        mappedDataSources.push('restrictive_lists');
      if (!pjDataSources.hasLaborInformation(execution, dataSources))
        mappedDataSources.push('labor_information');
      if (!pjDataSources.hasLegalInformation(execution, dataSources))
        mappedDataSources.push('legal_information');
      if (
        !executionDataSource.hasBasicInformation(execution, dataSources) &&
        !executionDataSource.hasPjSintegraData(execution, dataSources)
      )
        mappedDataSources.push('basic_infos');
      if (!executionDataSource.hasContactsInformation(execution, dataSources))
        mappedDataSources.push('contact');
      if (!executionDataSource.hasFinancialInformation(execution, dataSources))
        mappedDataSources.push('finance');
      if (!executionDataSource.hasLegalInformation(execution, dataSources))
        mappedDataSources.push('justice');
      if (!executionDataSource.hasRestrictiveListsInformation(execution, dataSources))
        mappedDataSources.push('restrictive_lists_backgroud_checking');
      if (!executionDataSource.hasLaborInformation(execution, dataSources))
        mappedDataSources.push('work');
      if (!executionDataSource.hasMediaExposureInformation(execution, dataSources))
        mappedDataSources.push('media');
      if (!executionDataSource.hasDriverInformation(execution, dataSources))
        mappedDataSources.push('driver');
    }

    if (personId) {
      const dataSources =
        Object.keys(
          execution?.sources ||
            execution?.sections ||
            execution?.profileTemplate?.backgroundChecking?.services ||
            {}
        )?.map((source) => source) || [];

      if (!pfDataSources.hasAddressInformation(execution, dataSources))
        mappedDataSources.push('address');
      if (!pfDataSources.hasContactsInformation(execution, dataSources))
        mappedDataSources.push('contacts');
      if (!pfDataSources.hasCriminalInformation(execution, dataSources))
        mappedDataSources.push('criminal_information');
      if (!pfDataSources.hasFinancialInformation(execution, dataSources))
        mappedDataSources.push('financial_information');
      if (!pfDataSources.hasRestrictiveListsInformation(execution, dataSources))
        mappedDataSources.push('restrictive_lists');
      if (!pfDataSources.hasLaborInformation(execution, dataSources))
        mappedDataSources.push('labor_information');
      if (!pfDataSources.hasDriverInformation(execution, dataSources))
        mappedDataSources.push('driver_information');
      if (!pfDataSources.hasLegalInformation(execution, dataSources))
        mappedDataSources.push('legal_information');
      if (!pfDataSources.hasRelationshipInformation(execution, dataSources))
        mappedDataSources.push('relationships');
      if (!pfDataSources.hasMediaExposureInformation(execution, dataSources))
        mappedDataSources.push('media_exposure');
      if (!executionDataSource.hasBasicInformation(execution, dataSources))
        mappedDataSources.push('basic_infos');
      if (!executionDataSource.hasContactsInformation(execution, dataSources))
        mappedDataSources.push('contact');
      if (!executionDataSource.hasFinancialInformation(execution, dataSources))
        mappedDataSources.push('finance');
      if (!executionDataSource.hasLegalInformation(execution, dataSources))
        mappedDataSources.push('justice');
      if (!executionDataSource.hasRestrictiveListsInformation(execution, dataSources))
        mappedDataSources.push('restrictive_lists_backgroud_checking');
      if (!executionDataSource.hasDriverInformation(execution, dataSources))
        mappedDataSources.push('driver');
      if (!executionDataSource.hasLaborInformation(execution, dataSources))
        mappedDataSources.push('work');
      if (!executionDataSource.hasMediaExposureInformation(execution, dataSources))
        mappedDataSources.push('media');
      if (!executionDataSource.hasCriminalInformation(execution, dataSources))
        mappedDataSources.push('criminal_background');
      if (
        !(
          execution?.mappedValidations?.find((v) => v.rule === 'valid_document')
            ?.status !== 'DISABLED'
        )
      )
        mappedDataSources.push('identity');
    }

    return mappedDataSources;
  }, [personId, companyId, execution]);

  const formattedOptions = useMemo(() => {
    const type = personId ? 'PF' : 'PJ';
    const options = checkboxOptions(type) || [];

    const parsedOptions = options.filter((op) => !blockedDataSources.includes(op._id));

    const optionsId = parsedOptions.map((option) => option._id);

    return { options: parsedOptions, optionsId };
  }, [blockedDataSources, personId]);

  const formattedBackgroudChekingOptions = useMemo(() => {
    const type = personId ? 'PF' : 'PJ';

    const options = backgroudCheckingOptions(type) || [];

    const parsedOptions = options.filter((op) => !blockedDataSources.includes(op._id));

    const optionsId = parsedOptions.map((option) => option._id);

    return { options: parsedOptions, optionsId };
  }, [blockedDataSources, personId]);

  const executionData = useMemo(() => {
    return execution;
  }, [execution]);

  const onChangeList = useCallback(
    (values) => {
      setCheckedList(values);
      setCheckAll(values.length === formattedOptions.optionsId.length);
    },
    [formattedOptions]
  );

  const onChangeBackgroudCheckingList = useCallback(
    (values) => {
      setCheckedBackgroudList(values);
      setCheckAllBackgroud(
        values.length === formattedBackgroudChekingOptions.optionsId.length
      );
    },
    [formattedBackgroudChekingOptions]
  );

  const onChangeCheckAllBackgroundChecking = useCallback(
    (event) => {
      onChangeBackgroudCheckingList(
        event.target.checked ? formattedBackgroudChekingOptions.optionsId : []
      );
      setCheckAllBackgroud(event.target.checked);
    },
    [formattedBackgroudChekingOptions, onChangeBackgroudCheckingList]
  );

  const onChangeCheckAll = useCallback(
    (event) => {
      setCheckedList(event.target.checked ? formattedOptions.optionsId : []);
      setCheckAll(event.target.checked);
      onChangeBackgroudCheckingList(
        event.target.checked ? formattedBackgroudChekingOptions.optionsId : []
      );
      setCheckAllBackgroud(event.target.checked);
    },
    [formattedOptions, onChangeBackgroudCheckingList, formattedBackgroudChekingOptions]
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => closeModal(),
    onBeforeGetContent: () => setLoadingPrint(true),
    documentTitle: `Trust-Monitor_${execution?._id}`,
  });

  const onChangeShowDetails = useCallback(() => {
    setShowDetails((oldState) => !oldState);
  }, []);

  const DossierPrintModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal dossier-print-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        footer={null}
        width={800}
        onCancel={closeModal}
      >
        <div className="no-mrg no-pdd">
          <PageTitle
            title={translate(`${I18N_BASE_PATH}.dossierPrintModal.pageTitle.title`)}
            subtitle={translate(`${I18N_BASE_PATH}.dossierPrintModal.pageTitle.subtitle`)}
          />

          <div hidden>
            <ToPrint
              showDetails={showDetails}
              isPF={executionType === 'PF'}
              ref={componentRef}
              data={executionData}
              checkedList={checkedList}
              checkedBackgroudList={checkedBackgroudList}
              onLoadingChange={(loading) => setLoadingActivities(loading)}
            />
          </div>

          <Checkbox onChange={onChangeCheckAll} checked={checkAll}>
            {translate(`${I18N_BASE_PATH}.dossierPrintModal.checkbox.all`)}
          </Checkbox>

          <Checkbox.Group value={checkedList} onChange={onChangeList}>
            <Row>
              {formattedOptions.options.map((option) => (
                <Col key={option._id} span={12}>
                  <Checkbox value={option._id}>
                    <i className={option.icon} /> {translate(option.label)}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>

          <Divider />

          {formattedBackgroudChekingOptions?.options?.length > 0 && (
            <>
              <Checkbox
                onChange={onChangeCheckAllBackgroundChecking}
                checked={checkAllBackgroud}
              >
                {translate(
                  `${I18N_BASE_PATH}.dossierPrintModal.checkbox.backgroundChecking`
                )}
              </Checkbox>
              <Checkbox.Group
                value={checkedBackgroudList}
                onChange={onChangeBackgroudCheckingList}
              >
                <Row>
                  {formattedBackgroudChekingOptions.options.map((option) => (
                    <Col key={option._id} span={12}>
                      <Checkbox value={option._id}>
                        <i className={option.icon} /> {translate(option.label)}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>

              <Divider />
            </>
          )}

          <div className="dossier-print-footer">
            <div className="dossier-print-show-details mrg-right-20">
              <Switch checked={showDetails} onChange={onChangeShowDetails} />{' '}
              <span>{translate(`${I18N_BASE_PATH}.dossierPrintModal.details`)}</span>
            </div>
            <div className="dossier-print-btns">
              <Button type="default" className="btn-cancel" onClick={closeModal}>
                {translate(`${I18N_BASE_PATH}.dossierPrintModal.button.cancel`)}
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                onClick={() => handlePrint()}
                disabled={
                  (!checkedList.length > 0 && !checkedBackgroudList.length > 0) ||
                  loadingPrint ||
                  loadingActivities
                }
              >
                {translate(
                  `${I18N_BASE_PATH}.dossierPrintModal.button.submit.${
                    loadingPrint || loadingActivities ? 'loading' : 'default'
                  }`
                )}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      translate,
      executionData,
      checkedList,
      checkedBackgroudList,
      onChangeCheckAll,
      checkAll,
      onChangeList,
      formattedOptions,
      onChangeCheckAllBackgroundChecking,
      checkAllBackgroud,
      onChangeBackgroudCheckingList,
      formattedBackgroudChekingOptions,
      loadingPrint,
      loadingActivities,
      handlePrint,
      executionType,
      onChangeShowDetails,
      showDetails,
    ]
  );

  return { openModal, DossierPrintModal };
};

export default DossierPrint;
