import React, { useState, useCallback, useMemo } from 'react';
import { Row, Col, Modal, message } from 'antd';

import { useFetch } from 'services/hooks';

import PageTitle from 'components/PageTitle';
import Button from 'components/Button';

import './styles.less';

const ModalConfirm = ({ data }) => {
  const { post, loading } = useFetch();

  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const onConfirmData = useCallback(async () => {
    try {
      await post({
        url: `/executions/${data?._id}/manual-revision`,
        payload: {
          status: 'REPROVADO',
          reason: 'Solicitação de correção via análise manual.',
          customStatus: 'WAITING_CORRECTIONS',
        },
      });

      message.success('Solicitação de correção realizada com sucesso.');

      closeModal();
    } catch (error) {
      //
    }
  }, [post, data, closeModal]);

  const ModalConfirmData = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal execution-confirm-data-modal"
        onCancel={closeModal}
        closable={false}
        footer={null}
        width={550}
      >
        <div className="no-mrg no-pdd">
          <Row>
            <Col span={24}>
              <div className="confirm-data-title">
                <PageTitle title="Correção da consulta" />
              </div>
            </Col>

            <Col span={24}>
              <span className="confirm-data-description">
                Você realmente deseja solicitar correção dos dados desta consulta?
              </span>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <div className="confirm-data-btns">
                <Button
                  type="default"
                  onClick={closeModal}
                  className="btn-cancel"
                  disabled={loading}
                >
                  Cancelar
                </Button>

                <Button
                  type="primary"
                  onClick={onConfirmData}
                  loading={loading}
                  disabled={loading}
                >
                  {loading ? `Solicitando...` : `Sim, solicitar`}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, loading, onConfirmData]
  );

  return { openModal, closeModal, ModalConfirmData };
};

export default ModalConfirm;
