import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Card, Form, Select, Divider, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useAuth } from 'hooks/auth';

import { useProfileTemplate } from 'hooks/profileTemplate';
import CustomStatusReasons from './CustomStatusReasons';
import ComplianceRulesValidations from './ComplianceRulesValidations';

const { Option } = Select;

const I18N_BASE_PATH =
  'pages.private.profiles.components.profileValidations.components.pendingReviewModal.components.cardReview.cardReview';

const CardReview = ({ formRef, isOpenModal, profile }) => {
  const { t: translate } = useTranslation();
  const { loadProfileTemplates, allProfileTemplates } = useProfileTemplate();

  const [selectedStatus, setSelectedStatus] = useState('');

  useEffect(() => {
    if (!isOpenModal) setSelectedStatus('');
  }, [isOpenModal]);

  useEffect(() => {
    loadProfileTemplates({});
  }, [loadProfileTemplates]);

  const handleSelectChange = useCallback(
    (e) => {
      setSelectedStatus(e);
      if (formRef.getFieldValue('customStatusReasons'))
        formRef.resetFields(['customStatusReasons']);
    },
    [formRef]
  );

  return (
    <Card id="card-review">
      <Form.Item
        name="validationStatus"
        className={`select-reason ${
          formRef.getFieldsValue()?.validationStatus === 'APPROVED' ? 'approve' : ''
        } ${formRef.getFieldsValue()?.validationStatus === 'REPROVED' ? 'reprove' : ''} ${
          formRef.getFieldsValue()?.validationStatus === 'REPROCESS' ? 'reprocess' : ''
        } ${formRef.getFieldValue()?.validationStatus === 'REPROVEDWC' ? 'reprove' : ''}`}
        label={translate(`${I18N_BASE_PATH}.form.validationStatus.label`)}
        onReset={() => setSelectedStatus('')}
        rules={[{ required: true }]}
      >
        <Select
          dropdownClassName="pending-review-action-dropdown"
          onSelect={(e) => handleSelectChange(e)}
          placeholder={
            <>
              <i className="caf-ic_edit_3" />
              {translate(`${I18N_BASE_PATH}.form.validationStatus.select.placeholder`)}
            </>
          }
        >
          <Option value="APPROVED">
            <>
              <i className="caf-ic_checkmark" />
              {translate(`${I18N_BASE_PATH}.form.validationStatus.select.options.aprove`)}
            </>
          </Option>
          <Option value="REPROVED">
            <>
              <i className="caf-ic_close" />
              {translate(
                `${I18N_BASE_PATH}.form.validationStatus.select.options.reprove`
              )}
            </>
          </Option>
        </Select>
      </Form.Item>

      <ComplianceRulesValidations profileValidations={profile?.validations} />

      {(selectedStatus === 'REPROVED' || selectedStatus === 'APPROVED') && (
        <>
          <Divider />
          <div className="mrg-top-10 mrg-btm-5">
            {translate(`${I18N_BASE_PATH}.form.validationReason.label`)}:
          </div>
          <CustomStatusReasons actionType={selectedStatus} formRef={formRef} />
          <Form.Item
            name="validationReason"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    formRef.getFieldsValue()?.validationStatus === 'REPROVED' &&
                    !value &&
                    !getFieldValue('customStatusReasons')?.length
                  ) {
                    return Promise.reject(
                      new Error(
                        translate(`${I18N_BASE_PATH}.form.validationReason.message`)
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            className="reproval-reason"
          >
            <Input.TextArea
              className="mrg-top-10"
              rows="5"
              placeholder={translate(
                `${I18N_BASE_PATH}.form.validationReason.placeholder`
              )}
            />
          </Form.Item>
        </>
      )}
    </Card>
  );
};

CardReview.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
};

export default CardReview;
