import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';
import ViewMoreText from 'components/ViewMoreText';
import EmptyExecutionState from 'components/EmptyExecutionState';
import { parsePgfnNadaConsta, parsePgfnContent } from './utils';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.shared.PGFNRegularCertificate';

function PGFNRegularCertificate({ data }) {
  const { t: translate } = useTranslation();

  const pgfnNadaConsta = useMemo(() => parsePgfnNadaConsta(data), [data]);

  const parsedContent = useMemo(() => parsePgfnContent(data, translate), [
    data,
    translate,
  ]);

  return (
    <ColumnLabel
      title={translate(`${I18N_BASE_PATH}.index.title`)}
      description={translate(pgfnNadaConsta?.translatedStatus?.description)}
      type={pgfnNadaConsta?.translatedStatus?.type}
      span={12}
    >
      {data?.statusCode === '01' || data?.available === true ? (
        <ViewMoreText message={data?.data?.message} content={parsedContent} />
      ) : (
        <EmptyExecutionState
          className="mrg-top-10"
          statusCode={data?.statusCode}
          message={data?.message || data?.data?.message}
        />
      )}
    </ColumnLabel>
  );
}

PGFNRegularCertificate.propTypes = {
  data: PropTypes.objectOf({}),
};

PGFNRegularCertificate.defaultProps = {
  data: {},
};

export default PGFNRegularCertificate;
