const toCurrency = (value, prefix, language) => {
  if (!value && value !== 0) return '';

  const formatted = parseFloat(value).toLocaleString(language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return prefix ? `${prefix} ${formatted}` : formatted;
};

const toNumber = (value) => {
  if (!value && value !== 0) return null;

  let formatted = `${value}`.replace(/R\$|[,|.]+/g, '').replace(/([0-9]{2})$/g, '.$1');
  formatted = parseFloat(formatted);

  if (`${value}`.length === 1) {
    formatted /= 100;
  }

  return formatted;
};

const numberToFormattedString = (value, prefix, language) =>
  `${prefix} ${value.toLocaleString(language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;

const roundNumberTwoPlaces = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100 || 0;
};

const currencyCodeToLocale = (currencyCode) => {
  switch (currencyCode) {
    case 'BRL':
      return 'pt-BR';
    case 'USD':
      return 'en-US';
    case 'EUR':
      return 'de-DE';
    default:
      return 'en-US';
  }
};

const currencyCodeToSymbol = (currencyCode) => {
  switch (currencyCode) {
    case 'BRL':
      return 'R$';
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    default:
      return '$';
  }
};

export default {
  toCurrency,
  toNumber,
  roundNumberTwoPlaces,
  numberToFormattedString,
  currencyCodeToLocale,
  currencyCodeToSymbol,
};
