import React, { useEffect } from 'react';
import { useQueryParams, StringParam } from 'use-query-params';
import { Row, Col, Select, Form } from 'antd';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { useProfileTemplate } from 'hooks/profileTemplate';
import { useAuth } from 'hooks/auth';

import PageTitle from 'components/PageTitle';
import Wrapper from 'pages/private/wrapper';
import { useComponentList } from 'components/List';
import { CardWrapper } from 'components/CardWrapper';
import PermissionWrapper from 'routes/PermissionWrapper';
import Unauthorized from '../../Unauthorized';
import RoutineItem from './components/RoutineItem';
import ModalNewRoutine from './components/ModalNewRoutine';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.routines.list';

const { Option } = Select;

const RoutineList = () => {
  const { t: translate } = useTranslation();
  const { CustomModal, openModal } = ModalNewRoutine();
  const { user } = useAuth();

  const [query, setQuery] = useQueryParams({
    active: StringParam,
  });

  const { loadCafServicesList } = useProfileTemplate();

  const { componentList: activeList, updateParams } = useComponentList({
    component: RoutineItem,
    rowKey: '_id',
    getParams: {
      url: '/routines',
      config: {
        params: {
          active: query?.active || true,
        },
      },
    },
  });

  useEffect(() => {
    updateParams({
      url: '/routines',
      config: {
        params: {
          active: query?.active || true,
        },
      },
    });
  }, [query, updateParams]);

  useEffect(() => {
    loadCafServicesList();
  }, [loadCafServicesList]);

  return !user?.accountData?.featureFlags?.profilesModule ? (
    <Unauthorized />
  ) : (
    <Wrapper>
      {CustomModal}
      <CardWrapper hasPadding>
        <Row align="middle" className="flex no-mrg">
          <Col className="flex-1 page-title">
            <PageTitle subtitle={translate(`${I18N_BASE_PATH}.subtitle`)} />
          </Col>
          <Col className="flex end mrg-left-50">
            <Form layout="inline" initialValues={{ active: query?.active || 'true' }}>
              <Form.Item name="active" className="no-mrg-btm">
                <Select
                  bordered={false}
                  style={{ width: 100, fontWeight: 500 }}
                  className="text-dark"
                  onSelect={(value) =>
                    setQuery({
                      active: value,
                    })
                  }
                >
                  <Option value="all">
                    {translate(`${I18N_BASE_PATH}.filters.all`)}
                    {/* => Todos os links */}
                  </Option>
                  <Option value="true">
                    {translate(`${I18N_BASE_PATH}.filters.active`)}
                    {/* => Ativos */}
                  </Option>
                  <Option value="false">
                    {translate(`${I18N_BASE_PATH}.filters.inactive`)}
                    {/* => Inativos */}
                  </Option>
                </Select>
              </Form.Item>
            </Form>
            <PermissionWrapper requiredPermissions={['routines:create']}>
              <Button
                shape="round"
                type="primary"
                className="flex no-mrg-btm center"
                onClick={() => openModal()}
              >
                {translate(`${I18N_BASE_PATH}.createButtonLabel`)}
                <i className="caf-ic_add font-size-18 mrg-left-5" />
              </Button>
            </PermissionWrapper>
          </Col>
        </Row>
        {activeList}
      </CardWrapper>
    </Wrapper>
  );
};

export default RoutineList;
