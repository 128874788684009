/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'utils/isObjectEmpty';
import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import EmptyState from 'components/EmptyState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import useCNDTDebtsModal from './CNDTDebtsModal';

const CNDT = ({ profile, toPrint /* urlId */ }) => {
  const { t: translate } = useTranslation();

  const cndt = useMemo(() => profile?.certificates?.cndt, [profile]);

  const { openModal, CNDTDebtsModal } = useCNDTDebtsModal({
    data: profile?.certificates?.cndt,
  });

  const handlerStatusAndType = useMemo(() => {
    if (!cndt)
      return {
        description: null,
        type: null,
      };

    if (!cndt?.lawsuitsList || cndt?.lawsuitsList?.length === 0) {
      return {
        description: translate(
          'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.cndt.index.handlerStatusAndType.hasNotLawsuitsList'
        ),
        type: 'primary',
      };
    }

    if (cndt?.lawsuitsList?.length === 1) {
      return {
        description: translate(
          'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.cndt.index.handlerStatusAndType.hasOneLawsuit'
        ),
        type: 'danger',
      };
    }

    return {
      description: translate(
        'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.cndt.index.handlerStatusAndType.hasMoreThanOneLawsuit',
        { value: cndt?.lawsuitsList?.length }
      ),
      type: 'danger',
    };
  }, [cndt, translate]);

  const isProcessing = useMemo(() => verifySourcesProcessing(profile, 'cndt'), [profile]);

  const handleOpenModal = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <>
      {CNDTDebtsModal}

      <ColumnLabel
        title={translate(
          'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.cndt.index.cndt.columnLabel.title'
        )}
        description={cndt && handlerStatusAndType.description}
        type={handlerStatusAndType.type}
        onClick={handlerStatusAndType.type === 'danger' && handleOpenModal}
        info={
          !toPrint &&
          translate(
            'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.cndt.index.cndt.columnLabel.info'
          )
        }
      >
        {!cndt && isProcessing && <EmptyState type="processing" />}

        {!cndt && !isProcessing && <EmptyState type="waiting_consult" />}

        {cndt && isEmpty(cndt) && (
          <span>
            {translate(
              'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.cndt.index.cndt.hasNoData'
            )}
          </span>
        )}

        {cndt && (
          <>
            {cndt?.lawsuitsList?.length > 0 ? (
              <>
                {!toPrint && (
                  <span>
                    {translate(
                      'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.cndt.index.cndt.detail'
                    )}
                  </span>
                )}
              </>
            ) : (
              <span>
                {translate(
                  'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.cndt.index.cndt.hasNotLawsuitsList'
                )}
              </span>
            )}
          </>
        )}
      </ColumnLabel>
    </>
  );
};

export default CNDT;
