import s_warn from 'assets/images/s_warn.svg';
import s_error from 'assets/images/s_error.svg';
import s_approved from 'assets/images/s_approved.svg';
import s_processing from 'assets/images/s_processing.svg';

const generalStatus = (status) => {
  if (!status) return {};
  switch (status.toUpperCase()) {
    case 'APPROVED':
      return {
        value: 'utils.handlerStatus.generalStatus.status.APPROVED.value',
        display: 'utils.handlerStatus.generalStatus.status.APPROVED.display',
        description: 'utils.handlerStatus.generalStatus.status.APPROVED.description',
        icon: 'caf-ic_circle_true',
        color: '#20c26b',
      };
    case 'REPROVED':
      return {
        value: 'utils.handlerStatus.generalStatus.status.REPROVED.value',
        display: 'utils.handlerStatus.generalStatus.status.REPROVED.display',
        description: 'utils.handlerStatus.generalStatus.status.REPROVED.description',
        icon: 'caf-ic_circle_false',
        color: '#eb5757',
      };
    case 'REPROVE':
      return {
        value: 'utils.handlerStatus.generalStatus.status.REPROVED.value',
        display: 'utils.handlerStatus.generalStatus.status.REPROVED.display',
        description: 'utils.handlerStatus.generalStatus.status.REPROVED.description',
        icon: 'caf-ic_circle_false',
        color: '#eb5757',
      };
    case 'PENDING':
      return {
        value: 'utils.handlerStatus.generalStatus.status.PENDING.value',
        display: 'utils.handlerStatus.generalStatus.status.PENDING.display',
        description: 'utils.handlerStatus.generalStatus.status.PENDING.description',
        icon: 'caf-ic_circle_hold',
        color: '#f8bd19',
      };
    case 'PROCESSING':
      return {
        value: 'utils.handlerStatus.generalStatus.status.PROCESSING.value',
        display: 'utils.handlerStatus.generalStatus.status.PROCESSING.display',
        description: 'utils.handlerStatus.generalStatus.status.PROCESSING.description',
        icon: 'caf-ic_processing',
        color: '#2f80ed',
      };
    case 'WAITING_DOCUMENTS':
      return {
        value: 'utils.handlerStatus.generalStatus.status.WAITING_DOCUMENTS.value',
        display: 'utils.handlerStatus.generalStatus.status.WAITING_DOCUMENTS.display',
        description:
          'utils.handlerStatus.generalStatus.status.WAITING_DOCUMENTS.description',
        icon: 'caf-ic_circle_hold',
        color: '#f8bd19',
      };
    default:
      return {};
  }
};

export const checkFraudRisk = (doc) => {
  if (!doc || !doc.validations || !doc.fraud) return false;

  const reprovedAndInvalidValidationCodes = doc?.validations
    ?.filter(
      (v) =>
        (v?.resultStatus === 'REPROVED' || v?.resultStatus === 'REPROVADO') &&
        v?.status === 'INVALID'
    )
    .map((v) => v?.code);

  if (
    reprovedAndInvalidValidationCodes.length === 1 &&
    (reprovedAndInvalidValidationCodes[0] === 'government_document_available' ||
      reprovedAndInvalidValidationCodes[0] === 'government_document_approved')
  ) {
    return true;
  }

  return false;
};

const queryStatusCode = (statusCode) => {
  switch (statusCode?.toString()) {
    case '01':
      return {
        title: 'utils.handlerStatus.queryStatusCode.statusCode.01.title',
        icon: 'caf-ic_check_d',
      };
    case '02':
      return {
        title: 'utils.handlerStatus.queryStatusCode.statusCode.02.title',
        icon: 'caf-ic_x_close',
      };
    case '05':
      return {
        title: 'utils.handlerStatus.queryStatusCode.statusCode.05.title',
        icon: 'caf-ic_processing_line',
      };
    case '50':
      return {
        title: 'utils.handlerStatus.queryStatusCode.statusCode.50.title',
        icon: 'caf-ic_x_close',
      };
    case '55':
      return {
        title: 'utils.handlerStatus.queryStatusCode.statusCode.55.title',
        icon: 'caf-ic_x_close',
      };

    default:
      return {
        title: 'utils.handlerStatus.queryStatusCode.statusCode.default.title',
        icon: 'caf-ic_x_close',
      };
  }
};

const executionStatus = (status) => {
  if (!status) return {};
  switch (status.toUpperCase()) {
    case 'APPROVED':
    case 'APROVADO':
      return {
        value: 'utils.handlerStatus.executionStatus.status.APROVADO.value',
        display: 'utils.handlerStatus.executionStatus.status.APROVADO.display',
        description: 'utils.handlerStatus.executionStatus.status.APROVADO.description',
        icon: 'caf-ic_circle_true',
        color: '#20c26b',
        type: 'primary',
      };
    case 'REPROVED':
    case 'REPROVADO':
      return {
        value: 'utils.handlerStatus.executionStatus.status.REPROVADO.value',
        display: 'utils.handlerStatus.executionStatus.status.REPROVADO.display',
        description: 'utils.handlerStatus.executionStatus.status.REPROVADO.description',
        icon: 'caf-ic_circle_false',
        color: '#eb5757',
        type: 'danger',
      };
    case 'PENDING':
    case 'PENDENTE':
      return {
        value: 'utils.handlerStatus.executionStatus.status.PENDENTE.value',
        display: 'utils.handlerStatus.executionStatus.status.PENDENTE.display',
        description: 'utils.handlerStatus.executionStatus.status.PENDENTE.description',
        icon: 'caf-ic_circle_hold',
        color: '#f8bd19',
        type: 'warning',
      };
    case 'PENDENTE OCR':
      return {
        value: 'utils.handlerStatus.executionStatus.status.PENDENTE_OCR.value',
        display: 'utils.handlerStatus.executionStatus.status.PENDENTE_OCR.display',
        description:
          'utils.handlerStatus.executionStatus.status.PENDENTE_OCR.description',
        icon: 'caf-ic_circle_hold',
        color: '#f8bd19',
        type: 'warning',
      };
    case 'PROCESSING':
    case 'PROCESSANDO':
      return {
        value: 'utils.handlerStatus.executionStatus.status.PROCESSANDO.value',
        display: 'utils.handlerStatus.executionStatus.status.PROCESSANDO.display',
        description: 'utils.handlerStatus.executionStatus.status.PROCESSANDO.description',
        icon: 'caf-ic_processing',
        color: '#2f80ed',
        type: 'info',
      };
    case 'AGUARDANDO DOCUMENTOS':
      return {
        value: 'utils.handlerStatus.executionStatus.status.AGUARDANDO_DOCUMENTOS.value',
        display:
          'utils.handlerStatus.executionStatus.status.AGUARDANDO_DOCUMENTOS.display',
        description:
          'utils.handlerStatus.executionStatus.status.AGUARDANDO_DOCUMENTOS.description',
        icon: 'caf-ic_circle_hold',
        color: '#f8bd19',
        type: 'warning',
      };
    default:
      return {};
  }
};

const svgValidationStatus = (status) => {
  if (!status) return {};
  switch (status.toUpperCase()) {
    case 'APPROVED':
    case 'APROVADO':
      return {
        color: 'success',
        title: () => 'utils.handlerStatus.svgValidationStatus.status.APROVADO.title',
        icon: s_approved,
      };
    case 'PENDING':
    case 'PENDENTE':
      return {
        color: 'warning',
        title: () => 'utils.handlerStatus.svgValidationStatus.status.PENDENTE.title',
        icon: s_warn,
      };

    case 'PENDENTE OCR':
      return {
        color: 'warning',
        title: () => 'utils.handlerStatus.svgValidationStatus.status.PENDENTE_OCR.title',
        icon: s_warn,
      };
    case 'PROCESSING':
    case 'PROCESSANDO':
      return {
        color: 'processing',
        title: () => 'utils.handlerStatus.svgValidationStatus.status.PROCESSANDO.title',
        icon: s_processing,
      };
    case 'REPROVED':
    case 'REPROVADO':
      return {
        color: 'error',
        title: (doc) => {
          if (doc.fraud) {
            return `utils.handlerStatus.svgValidationStatus.status.REPROVADO.title.${
              checkFraudRisk(doc) ? 'isFraudRisk' : 'isFraud'
            }`;
          }

          return 'utils.handlerStatus.svgValidationStatus.status.REPROVADO.title.default';
        },
        icon: s_error,
      };

    case 'DOCUMENTOSCOPIA':
      return {
        title: () =>
          'utils.handlerStatus.svgValidationStatus.status.DOCUMENTOSCOPIA.title',
        icon: s_warn,
      };

    default:
      return {
        title: () => 'utils.handlerStatus.svgValidationStatus.status.default.title',
        icon: s_warn,
      };
  }
};

const validationStatus = (status) => {
  if (!status) return {};
  switch (status.toUpperCase()) {
    case 'APPROVED':
      return {
        color: '#20c26b',
        icon: 'caf-ic_checkmark',
        title: 'utils.handlerStatus.validationStatus.status.APPROVED.title',
        lineColor: '#20c26b',
        type: 'primary',
      };
    case 'VALID':
      return {
        color: '#20c26b',
        icon: 'caf-ic_checkmark',
        title: 'utils.handlerStatus.validationStatus.status.VALID.title',
        lineColor: '#20c26b',
        type: 'primary',
      };
    case 'VALID_OCR':
      return {
        color: '#20c26b',
        icon: 'caf-ic_checkmark',
        title: 'utils.handlerStatus.validationStatus.status.VALID.title',
        lineColor: '#20c26b',
        type: 'primary',
      };
    case 'REPROVED':
      return {
        color: '#eb5757',
        icon: 'caf-ic_close',
        title: 'utils.handlerStatus.validationStatus.status.REPROVED.title',
        lineColor: '#eb5757',
        type: 'danger',
      };
    case 'INVALID':
      return {
        color: '#eb5757',
        icon: 'caf-ic_close',
        title: 'utils.handlerStatus.validationStatus.status.INVALID.title',
        lineColor: '#eb5757',
        type: 'danger',
      };
    case 'PENDING':
      return {
        color: '#f8bd19',
        icon: 'caf-ic_hold',
        title: 'utils.handlerStatus.validationStatus.status.PENDING.title',
        lineColor: '#bdbdbd',
        type: 'warning',
      };
    case 'PENDING_OCR':
      return {
        color: '#f8bd19',
        icon: 'caf-ic_hold',
        title: 'utils.handlerStatus.validationStatus.status.PENDING_OCR.title',
        lineColor: '#bdbdbd',
        type: 'warning',
      };
    case 'DOCUMENTOSCOPIA':
      return {
        color: '#f8bd19',
        icon: 'caf-ic_hold',
        title: 'utils.handlerStatus.validationStatus.status.DOCUMENTOSCOPIA.title',
        lineColor: '#bdbdbd',
        type: 'warning',
      };
    case 'REVIEW':
      return {
        color: '#f8bd19',
        icon: 'caf-ic_hold',
        title: 'utils.handlerStatus.validationStatus.status.REVIEW.title',
        lineColor: '#bdbdbd',
        type: 'warning',
      };
    case 'PROCESSING':
      return {
        color: '#2f80ed',
        icon: 'caf-ic_processing_line',
        title: 'utils.handlerStatus.validationStatus.status.PROCESSING.title',
        lineColor: '#bdbdbd',
        type: 'info',
      };
    case 'EMPTY':
      return {
        color: '#bdbdbd',
        icon: 'caf-ic_close',
        title: 'utils.handlerStatus.validationStatus.status.EMPTY.title',
        lineColor: '#bdbdbd',
        type: 'default',
      };
    case 'NONE':
      return {
        color: '#bdbdbd',
        icon: 'caf-ic_close',
        title: 'utils.handlerStatus.validationStatus.status.NONE.title',
        lineColor: '#bdbdbd',
        type: 'default',
      };
    case 'ENABLED':
      return {
        color: '#bdbdbd',
        icon: 'caf-ic_close',
        title: 'utils.handlerStatus.validationStatus.status.ENABLED.title',
        lineColor: '#bdbdbd',
        type: 'default',
      };
    case 'WAITING_DOCUMENTS':
      return {
        color: '#f8bd19',
        icon: 'caf-ic_hold',
        title: 'utils.handlerStatus.validationStatus.status.WAITING_DOCUMENTS.title',
        lineColor: '#bdbdbd',
        type: 'warning',
      };
    default:
      return {};
  }
};

const complianceRuleStatus = (status) => {
  if (!status) return {};
  switch (status.toUpperCase()) {
    case 'APPROVED':
      return {
        color: '#20c26b',
        icon: 'caf-ic_circle_true',
        title: 'utils.handlerStatus.complianceRuleStatus.status.APPROVED.title',
        background: '#eeffee',
      };
    case 'VALID':
      return {
        color: '#20c26b',
        icon: 'caf-ic_circle_true',
        title: 'utils.handlerStatus.complianceRuleStatus.status.VALID.title',
        background: '#eeffee',
      };
    case 'REPROVED':
      return {
        color: '#eb5757',
        icon: 'caf-ic_circle_false',
        title: 'utils.handlerStatus.complianceRuleStatus.status.REPROVED.title',
        background: '#ffeeee',
      };
    case 'INVALID':
      return {
        color: '#eb5757',
        icon: 'caf-ic_circle_false',
        title: 'utils.handlerStatus.complianceRuleStatus.status.INVALID.title',
        background: '#ffeeee',
      };
    case 'PENDING':
      return {
        color: '#f8bd19',
        icon: 'caf-ic_circle_hold',
        title: 'utils.handlerStatus.complianceRuleStatus.status.PENDING.title',
        background: '#fff7e6',
      };
    case 'PROCESSING':
      return {
        color: '#2f80ed',
        icon: 'caf-ic_processing',
        title: 'utils.handlerStatus.complianceRuleStatus.status.PROCESSING.title',
        background: '#eeeeff',
      };
    default:
      return {
        color: '#bdbdbd',
        icon: 'caf-ic_close',
        title:
          'Indisputils.handlerStatus.complianceRuleStatus.status.default.titleonível',
        background: '#f9f9f9',
      };
  }
};

const documentscopyStatus = (status) => {
  if (!status)
    return {
      title: 'utils.handlerStatus.documentscopyStatus.status.hasNotStatus.title',
      type: 'default',
    };

  switch (status.toUpperCase()) {
    case 'APROVADO':
      return {
        title: 'utils.handlerStatus.documentscopyStatus.status.APROVADO.title',
        type: 'primary',
      };
    case 'APPROVED':
      return {
        title: 'utils.handlerStatus.documentscopyStatus.status.APPROVED.title',
        type: 'primary',
      };
    case 'REPROVADO':
      return {
        title: 'utils.handlerStatus.documentscopyStatus.status.REPROVADO.title',
        type: 'danger',
      };
    case 'REPROVED':
      return {
        title: 'utils.handlerStatus.documentscopyStatus.status.REPROVED.title',
        type: 'danger',
      };
    case 'PENDENTE':
      return {
        title: 'utils.handlerStatus.documentscopyStatus.status.PENDENTE.title',
        type: 'warning',
      };
    case 'PENDING':
      return {
        title: 'utils.handlerStatus.documentscopyStatus.status.PENDING.title',
        type: 'warning',
      };
    case 'PROCESSANDO':
      return {
        title: 'utils.handlerStatus.documentscopyStatus.status.PROCESSANDO.title',
        type: 'info',
      };
    case 'PROCESSING':
      return {
        title: 'utils.handlerStatus.documentscopyStatus.status.PROCESSING.title',
        type: 'info',
      };
    default:
      return {
        title: 'utils.handlerStatus.documentscopyStatus.status.default.title',
        type: 'default',
      };
  }
};

const labelStatus = (status, _default) => {
  if (!status) {
    return {
      description:
        _default || 'utils.handlerStatus.labelStatus.status.hasNotStatus.description',
      type: 'empty',
    };
  }

  switch (status.toUpperCase()) {
    case 'REGULAR':
      return {
        description: 'utils.handlerStatus.labelStatus.status.REGULAR.description',
        type: 'primary',
      };
    case 'HABILITADO':
      return {
        description: 'utils.handlerStatus.labelStatus.status.HABILITADO.description',
        type: 'primary',
      };
    case 'ATIVO':
      return {
        description: 'utils.handlerStatus.labelStatus.status.ATIVO.description',
        type: 'primary',
      };
    case 'ATIVA':
      return {
        description: 'utils.handlerStatus.labelStatus.status.ATIVA.description',
        type: 'primary',
      };
    case 'INATIVO':
      return {
        description: 'utils.handlerStatus.labelStatus.status.INATIVO.description',
        type: 'danger',
      };
    case 'INAPTA':
      return {
        description: 'utils.handlerStatus.labelStatus.status.INAPTA.description',
        type: 'warning',
      };
    case 'SUSPENSO':
      return {
        description: 'utils.handlerStatus.labelStatus.status.SUSPENSO.description',
        type: 'danger',
      };
    case 'SUSPENSA':
      return {
        description: 'utils.handlerStatus.labelStatus.status.SUSPENSA.description',
        type: 'danger',
      };
    case 'DESABILITADO':
      return {
        description: 'utils.handlerStatus.labelStatus.status.DESABILITADO.description',
        type: 'danger',
      };
    case 'NOT REGISTERED':
      return {
        description: 'utils.handlerStatus.labelStatus.status.NOT_REGISTERED.description',
        type: 'empty',
      };
    case 'BAIXADA':
      return {
        description: 'utils.handlerStatus.labelStatus.status.BAIXADA.description',
        type: 'empty',
      };
    case '01':
      return {
        type: 'primary',
      };
    case '02':
      return {
        type: 'danger',
      };
    default:
      return {
        description:
          _default || 'utils.handlerStatus.labelStatus.status.default.description',
        type: 'empty',
      };
  }
};

const labelColor = {
  REPROVADO: '#E21B45',
  REPROVED: '#E21B45',
  APPROVED: '#39C560',
  APROVADO: '#39C560',
  PENDENTE: '#F8B239',
  PENDING: '#F8B239',
  'PENDENTE OCR': '#F8B239',
};

export {
  generalStatus,
  executionStatus,
  validationStatus,
  complianceRuleStatus,
  documentscopyStatus,
  labelStatus,
  svgValidationStatus,
  queryStatusCode,
  labelColor,
};
