import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, Timeline } from 'antd';
import { parseISO, format } from 'date-fns';
import { useI18nFormatters } from 'hooks/i18nFormatters';

import NotificationLogger from './NotificationLogger';

import IconStatus from '../IconStatus';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.notificationCenter.notificationItem';

const NotificationItem = ({ data, reloadList }) => {
  const { t: translate } = useTranslation();
  const { i18nGetDateFnsLocale, i18nFormatDate } = useI18nFormatters();

  const formattedDate = useMemo(() => {
    const day = format(parseISO(data.date), 'dd', {
      locale: i18nGetDateFnsLocale(),
    });
    const month = format(parseISO(data.date), 'MMM', {
      locale: i18nGetDateFnsLocale(),
    });

    return { day, month };
  }, [data, i18nGetDateFnsLocale]);

  const formattedType = useCallback((item) => {
    switch (item?.type) {
      case 'ONBOARDING':
      case 'TRANSACTION_TEMPLATE':
        return item?.type;
      case 'profile_exported':
      case 'restrictive_list_imported':
      case 'executions_exported':
      case 'TRANSACTION_WARNING':
        return item?.type;
      default:
        return 'DOWNLOAD';
    }
  }, []);

  const formattedAction = useCallback((item) => {
    return item?.action ?? 'DOWNLOAD';
  }, []);

  const formattedTimeline = useMemo(() => {
    const { items } = data || [];

    return items?.map((item) => {
      const createdBy = item?.createdBy || 'Trust Monitor';
      const createdAt = i18nFormatDate(item?.createdAt, 9);
      const footer = (
        <div className="card-footer">
          <span className="gx-font-italic gx-font-weight-light font-size-12 pdd-top-5 pdd-btm-5">
            {createdAt} {translate(`${I18N_BASE_PATH}.createdBy`)} {createdBy}
          </span>
        </div>
      );
      return (
        <>
          <Timeline.Item
            key={item?._id}
            dot={<IconStatus type={formattedType(item)} action={formattedAction(item)} />}
          >
            <NotificationLogger
              title={item?.title}
              action={formattedAction(item)}
              type={formattedType(item)}
              data={item}
              reloadList={reloadList}
            >
              {footer}
            </NotificationLogger>
          </Timeline.Item>
        </>
      );
    });
  }, [data, formattedAction, formattedType, reloadList, translate, i18nFormatDate]);

  return (
    formattedDate && (
      <Card id="card-recurrent-notification">
        <div className="timeline-item-container">
          <div className="day-info-content">
            <span>
              {formattedDate.month}
              <strong>{formattedDate.day}</strong>
            </span>
          </div>

          <div className="notification-info-content">
            <div className="timeline-item-tail" />
            <Timeline>{formattedTimeline}</Timeline>
          </div>
        </div>
      </Card>
    )
  );
};

NotificationItem.propTypes = {
  data: PropTypes.shape(PropTypes.string),
  reloadList: PropTypes.func,
};

NotificationItem.defaultProps = {
  data: null,
  reloadList: undefined,
};

export default NotificationItem;
