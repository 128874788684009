import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Card, Form, Select, Divider, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import AdditionalInfo from 'components/AdditionalInfo';
import { useAuth } from 'hooks/auth';

import { useExecution } from 'hooks/execution';
import { useProfileTemplate } from 'hooks/profileTemplate';
import CustomStatusReasons from './CustomStatusReasons';
import ComplianceRulesValidations from './ComplianceRulesValidations';
import MissingInfosSources from './MissingInfosSources';

const { Option } = Select;

const I18N_BASE_PATH =
  'pages.private.profiles.components.profileValidations.components.pendingReviewModal.components.cardReview.cardReview';

const CardReview = ({ formRef, allowReprocessExecution, isOpenModal }) => {
  const { execution } = useExecution();
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const { loadProfileTemplates, allProfileTemplates } = useProfileTemplate();

  const [selectedStatus, setSelectedStatus] = useState('');

  useEffect(() => {
    if (!isOpenModal) setSelectedStatus('');
  }, [isOpenModal]);

  useEffect(() => {
    loadProfileTemplates({});
  }, [loadProfileTemplates]);

  const parsedServices = useMemo(() => {
    if (execution?.templateId) {
      const template = allProfileTemplates?.find((t) =>
        [t?._id, t?.id].includes(execution.templateId)
      );

      return template?.backgroundChecking?.services;
    }

    if (execution?.report?.customData) {
      return execution?.report?.customData;
    }

    if (execution?.report?.customData) {
      return execution?.report?.customData;
    }

    return [];
  }, [allProfileTemplates, execution]);

  const handleSelectChange = useCallback(
    (e) => {
      setSelectedStatus(e);
      if (formRef.getFieldValue('customStatusReasons'))
        formRef.resetFields(['customStatusReasons']);
    },
    [formRef]
  );

  return (
    <Card id="card-review">
      <Form.Item
        name="validationStatus"
        className={`select-reason ${
          formRef.getFieldsValue()?.validationStatus === 'APPROVED' ? 'approve' : ''
        } ${formRef.getFieldsValue()?.validationStatus === 'REPROVED' ? 'reprove' : ''} ${
          formRef.getFieldsValue()?.validationStatus === 'REPROCESS' ? 'reprocess' : ''
        } ${formRef.getFieldValue()?.validationStatus === 'REPROVEDWC' ? 'reprove' : ''}`}
        label={translate(`${I18N_BASE_PATH}.form.validationStatus.label`)}
        onReset={() => setSelectedStatus('')}
        rules={[{ required: true }]}
      >
        <Select
          dropdownClassName="pending-review-action-dropdown"
          onSelect={(e) => handleSelectChange(e)}
          placeholder={
            <>
              <i className="caf-ic_edit_3" />
              {translate(`${I18N_BASE_PATH}.form.validationStatus.select.placeholder`)}
            </>
          }
        >
          <Option value="APPROVED">
            <>
              <i className="caf-ic_checkmark" />
              {translate(`${I18N_BASE_PATH}.form.validationStatus.select.options.aprove`)}
            </>
          </Option>
          <Option value="REPROVED">
            <>
              <i className="caf-ic_close" />
              {translate(
                `${I18N_BASE_PATH}.form.validationStatus.select.options.reprove`
              )}
            </>
          </Option>
          {[
            '999687e1-ccfc-46e9-bb4e-03f869456ec7',
            'f9514663-5bfa-4f99-8b43-22a99ef4d5db',
            'e8a13640-40be-4ac3-91a4-bbbc488dff61', // nova conta de teste (dev)
            'ed7d5fa4-776d-4240-b1d2-8219ed9b455d', // conta de teste (beta)
            'f8996922-8f20-4a6e-a176-f282bd3b6f3c', // conta de teste (prod)
          ].includes(user?.tenantId) && (
            <Option value="REPROVEDWC">
              <>
                <i className="caf-ic_close" />
                {translate(
                  `${I18N_BASE_PATH}.form.validationStatus.select.options.reproveWC`
                )}
              </>
            </Option>
          )}
          {allowReprocessExecution && (
            <Option value="REPROCESS">
              <>
                <i className="caf-ic_processing_line" />
                {translate(
                  `${I18N_BASE_PATH}.form.validationStatus.select.options.reprocess`
                )}
              </>
            </Option>
          )}
        </Select>
      </Form.Item>

      <ComplianceRulesValidations />

      {(selectedStatus === 'REPROVED' || selectedStatus === 'APPROVED') && (
        <>
          <Divider />
          <div className="mrg-top-10 mrg-btm-5">
            {translate(`${I18N_BASE_PATH}.form.validationReason.label`)}:
          </div>
          <CustomStatusReasons actionType={selectedStatus} formRef={formRef} />
          <Form.Item
            name="validationReason"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    formRef.getFieldsValue()?.validationStatus === 'REPROVED' &&
                    !value &&
                    !getFieldValue('customStatusReasons')?.length
                  ) {
                    return Promise.reject(
                      new Error(
                        translate(`${I18N_BASE_PATH}.form.validationReason.message`)
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            className="reproval-reason"
          >
            <Input.TextArea
              className="mrg-top-10"
              rows="5"
              placeholder={translate(
                `${I18N_BASE_PATH}.form.validationReason.placeholder`
              )}
            />
          </Form.Item>
        </>
      )}

      {selectedStatus === 'REPROCESS' && (
        <>
          <Divider />
          <MissingInfosSources />
          <Form.Item
            label={
              <strong className="text-dark text-bold mrg-top-10">
                {translate(`${I18N_BASE_PATH}.form.label`)}
              </strong>
            }
          >
            <Divider className="reset-mrg mrg-top-10" />

            <AdditionalInfo type="EXECUTION" services={parsedServices} />
          </Form.Item>
        </>
      )}
    </Card>
  );
};

CardReview.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    status: PropTypes.string,
    reason: PropTypes.string,
  }).isRequired,
  allowReprocessExecution: PropTypes.bool.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
};

export default CardReview;
