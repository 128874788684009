import React, { useEffect } from 'react';
import { Row, Col, Checkbox, Collapse } from 'antd';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CollapseSuggestion from '../CollapseSuggestion';

const { Panel } = Collapse;

const CollapseProfile = ({ data: profile, checked, setChecked, openModal }) => {
  const { t: translate } = useTranslation();
  useEffect(() => {
    if (
      !checked.includes(profile?._id) &&
      profile?.suggestions?.filter((s) => checked?.includes(s?._id)).length ===
        profile?.suggestions.length
    ) {
      setChecked((old) => [...old, profile?._id]);
    } else if (
      checked.includes(profile?._id) &&
      profile?.suggestions?.filter((s) => checked?.includes(s?._id)).length !==
        profile?.suggestions.length
    ) {
      setChecked((old) => {
        const index = old?.findIndex((e) => e === profile?._id);
        return old?.splice(index, 1);
      });
    }
  }, [checked, profile, setChecked]);
  return (
    <Collapse activeKey={profile?._id} className="collapse-execs mrg-top-10">
      <Panel
        key={profile?._id}
        collapsible="disabled"
        showArrow={false}
        header={
          <Row className="flex center">
            <Col span={10}>
              <Row className="no-mrg no-pdd">
                <Checkbox
                  className="mrg-right-10"
                  value={profile?._id}
                  checked={
                    profile?.suggestions?.filter((s) => checked?.includes(s?._id))
                      .length === profile?.suggestions.length
                  }
                  indeterminate={
                    ![0, profile?.suggestions.length].includes(
                      profile?.suggestions?.filter((s) => checked?.includes(s?._id))
                        .length
                    )
                  }
                />
                <div className="flex-column">
                  <b className="text-default">
                    {translate('pages.private.globalProfiles.index.profile', 'Profile')}
                  </b>
                  <Link
                    to={`/profile/people/${profile?._id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {profile?.summary?.name || '-'}
                  </Link>
                </div>
              </Row>
            </Col>
            <Col span={3}>
              <div className="flex-column">
                <b className="text-default">
                  {translate('pages.private.globalProfiles.index.docId', 'Doc ID')}
                </b>
                <span>{profile?.summary?.identifier || '-'}</span>
              </div>
            </Col>
            <Col span={3}>
              <div className="flex-column">
                <b className="text-default">
                  {translate(
                    'pages.private.globalProfiles.index.dateOfBirth',
                    'Date of birth'
                  )}
                </b>
                <span>{profile?.summary?.birthDate || '-'}</span>
              </div>
            </Col>
            <Col span={8}>
              <Row className="no-mrg no-pdd" justify="space-between">
                <div className="flex-column">
                  <b className="text-default">
                    {translate('pages.private.globalProfiles.index.address', 'Address')}
                  </b>
                  <span>{profile?.summary?.address?.full || '-'}</span>
                </div>
              </Row>
            </Col>
          </Row>
        }
      >
        <div style={{ paddingLeft: 29 }}>
          <b>
            {translate('pages.private.globalProfiles.index.executions', 'Executions')}
          </b>

          {profile?.suggestions?.map((suggestion) => (
            <CollapseSuggestion
              suggestion={suggestion}
              openModal={openModal}
              key={suggestion?._id}
            />
          ))}
        </div>
      </Panel>
    </Collapse>
  );
};

export default CollapseProfile;
