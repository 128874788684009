/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import ViewMore from 'components/ViewMore';
import EmptyState from 'components/EmptyState';
import EmptyExecutionState from 'components/EmptyExecutionState';

import ContactAndRelationCard from 'pages/private/Profiles/components/ContactAndRelationCard';
import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import useAllPersonalAndEconomicRelationships from './AllPersonalAndEconomicRelationships';

const PersonalAndEconomicRelationships = ({ toPrint, source }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => {
    if (!source?.data) return null;

    if (source?.data?.length > 0) {
      return source?.data;
    }
    return [];
  }, [source]);

  const {
    openModal,
    AllPersonalAndEconomicRelationshipsModal,
  } = useAllPersonalAndEconomicRelationships({ data: parsedData });

  const parsedList = useMemo(() => {
    if (!parsedData || !parsedData?.length > 0) return <></>;

    const el = [];
    const _size = parsedData?.length;

    if (_size) {
      for (let i = 0; i < _size; i += 2) {
        el.push(
          <Row className="contact-data-row">
            {parsedData?.slice(i, i + 2)?.map((relationship) => (
              <ContactAndRelationCard
                key={relationship?.entityName || relationship?.relatedEntityName}
                span={12}
                relation
                name={relationship?.entityName || relationship?.relatedEntityName}
                type={relationship?.relationshipType}
                level={relationship?.relationshipLevel}
                className="mrg-top-10 pdd-horizontal-0"
              />
            ))}
          </Row>
        );
      }
    }

    if (el.length > 0) {
      const parsedEl = toPrint ? el : el.splice(0, 1);
      return parsedEl;
    }

    return <></>;
  }, [parsedData, toPrint]);

  const personalQuerySucceeded = useMemo(
    () =>
      source?.statusCode === '01' ||
      source?.available === true ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [source]
  );

  return (
    <>
      {AllPersonalAndEconomicRelationshipsModal}

      <Row className="contact-data-container">
        <ColumnLabel span={24}>
          {personalQuerySucceeded ? (
            <>
              {parsedData && !parsedData?.length > 0 && (
                <EmptyState
                  type="empty-success"
                  description={translate(
                    'components.dataSourceCards.relationships.components.personalAndEconomicRelationships.index.personalAndEconomicRelationships.columnLabel.emptyState.description'
                  )}
                />
              )}

              {parsedList}

              {!toPrint && parsedData?.length > 2 && (
                <Row className="mrg-btm-10">
                  <ViewMore
                    title={translate(
                      'components.dataSourceCards.relationships.components.personalAndEconomicRelationships.index.personalAndEconomicRelationships.columnLabel.viewMore.title'
                    )}
                    type="link"
                    onClick={() => openModal()}
                  />
                </Row>
              )}
            </>
          ) : (
            <EmptyExecutionState
              statusCode={source?.statusCode}
              message={source?.message}
            />
          )}
        </ColumnLabel>
      </Row>
    </>
  );
};

export default PersonalAndEconomicRelationships;
