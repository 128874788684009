/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { Checkbox, Modal, Button } from 'antd';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import CompanyPrint from '../../CompanyPrint';
import {
  getModalData,
  handleCheckBasedHierarchy,
  checkIfThereIsCheckBoxActive,
} from './utils';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.KYBCompany.pages.companyDetails.printModal';

const CheckBoxInner = ({ id, text, checked, disabled, onChange }) => {
  return (
    <Checkbox
      className="mrg-top-10"
      id={id}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
    >
      {text}
    </Checkbox>
  );
};

const CheckBoxArticle = ({ id, children, text, checked, disabled, onChange }) => {
  return (
    <div className="width-100 mrg-btm-10">
      <div className="flex mrg-top-10">
        <Checkbox
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          className="text-bold"
        >
          {text}
        </Checkbox>
      </div>
      <div className="flex" id={id}>
        {children}
      </div>
    </div>
  );
};

const CheckBoxSection = ({ id, children, text, checked, disabled, onChange, Icon }) => {
  return (
    <>
      <div className="flex center width-100 mrg-top-20">
        <Checkbox disabled={disabled} id={text} checked={checked} onChange={onChange}>
          <div className="flex center width-100">
            <span className="font-size-17 text-dark mrg-right-5">{Icon}</span>
            <span className="text-dark text-bold check-box-text">{text}</span>
          </div>
        </Checkbox>
        <hr className="width-100" />
      </div>
      <div className="flex" id={id}>
        {children}
      </div>
    </>
  );
};

const subDataComponentMapByLevel = Object.freeze({
  1: CheckBoxSection,
  2: CheckBoxArticle,
  3: CheckBoxInner,
});

const CheckBoxAny = ({ children, level, data, parentPath, handleChecked }) => {
  const parentObject = _.get(data, parentPath);
  if (!parentObject?.items) {
    return null;
  }
  const nextLevel = level + 1;
  const subDataItemsKeys = Object.keys(parentObject?.items || {});
  return subDataItemsKeys.map((itemName) => {
    const path = `${parentPath}.items.${itemName}`;
    const pathObject = _.get(data, path);
    const pathWithValue = `${path}.value`;
    const Icon = pathObject.icon;
    const CheckBoxComponentByLevel =
      subDataComponentMapByLevel[pathObject.level || level];
    return (
      <CheckBoxComponentByLevel
        key={pathWithValue}
        id={`companyPrintCheckboxes.${path}`}
        text={pathObject.title}
        Icon={<Icon />}
        checked={pathObject.value}
        onChange={handleChecked(pathWithValue)}
        disabled={pathObject.disabled}
      >
        {children}
        <CheckBoxAny
          level={nextLevel}
          data={data}
          parentPath={path}
          handleChecked={handleChecked}
        />
      </CheckBoxComponentByLevel>
    );
  });
};

const PrintModal = ({ modalVisible, handleToggleModal, companyData, createdAt }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const [data, setData] = useState(
    getModalData({ translate, I18N_BASE_PATH, companyData })
  );
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [exportBtnDisabled, setExportBtnDisabled] = useState(true);
  const printComponentRef = useRef();

  const handleChecked = (checkedPath) => (event) => {
    const checkedValue = event.target.checked;
    const newData = handleCheckBasedHierarchy(data, checkedPath, checkedValue);
    setData((old) => {
      return {
        ...old,
        ...newData,
      };
    });

    setExportBtnDisabled(!checkIfThereIsCheckBoxActive(data.root));
  };

  useEffect(() => {
    setData(getModalData({ translate, I18N_BASE_PATH, companyData }));
  }, [modalVisible]);

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    removeAfterPrint: true,
    // onAfterPrint will trigger even if the user cancels the print popup
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/afterprint_event
    onAfterPrint: () => {
      handleToggleModal();
      setLoadingPrint(false);
    },
    onBeforeGetContent: () => setLoadingPrint(true),
    documentTitle: `Company_${companyData.summary.title}_${i18nFormatDate(
      createdAt,
      11
    )}.pdf`,
  });

  return (
    <Modal
      visible={modalVisible}
      onCancel={handleToggleModal}
      footer={null}
      style={{ top: 0, padding: '35px 30px' }}
      width={800}
    >
      {modalVisible && (
        <div hidden>
          <CompanyPrint
            ref={printComponentRef}
            {...companyData}
            createdAt={createdAt}
            itemsToExport={data.root.items}
            setLoading={setLoadingPrint}
          />
        </div>
      )}
      <div className="kyb-company-details-print-modal no-mrg no-pdd">
        <h5 className="text-bold font-size-32 mrg-btm-5">
          {translate(`${I18N_BASE_PATH}.title`, 'Export file')}
        </h5>
        <p className="font-size-16 mrg-btm-30">
          {translate(
            `${I18N_BASE_PATH}.subtitle`,
            'Select the informations that you want to export'
          )}
        </p>
        <CheckBoxSection
          text={data.root.title}
          checked={data.root.value}
          onChange={handleChecked('root.value')}
        />
        <CheckBoxAny
          level={1}
          data={data}
          parentPath="root"
          handleChecked={handleChecked}
        />
        <div className="print-modal-footer">
          <Button shape="round" onClick={() => handleToggleModal()}>
            {translate(`${I18N_BASE_PATH}.cancel`, 'Cancel')}
          </Button>
          <Button
            shape="round"
            disabled={exportBtnDisabled}
            loading={loadingPrint}
            onClick={handlePrint}
          >
            {translate(`${I18N_BASE_PATH}.export`, 'Export')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PrintModal;
