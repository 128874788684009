import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import AuthenticityCheckPopover from './components/AuthenticityCheckPopover';
import TextFieldsCheckPopover from './components/TextFieldsCheckPopover';

import './styles.less';

const GLOBALDOCVERIFICATION_VALIDATIONS_RULE_IDS = {
  '63d1192189b0b64c7e7dcd72': 'authenticityCheck', // Rule "Validate document authenticity" (previous name "Authenticity check is ok")
  '63b5ef1b8428cd97b9572114': 'textFieldsCheck', // Rule "Validate document fields" (previous name "Text status is ok")
};

const ComplianceRuleDetails = ({ execution, validation }) => {
  const globalDocVerificationCheck =
    GLOBALDOCVERIFICATION_VALIDATIONS_RULE_IDS?.[validation?.ruleId];

  const globalDocVerificationValidation = useMemo(() => {
    if (!globalDocVerificationCheck) return null;

    const globalDocVerificationValidations =
      execution?.sections?.globalDocVerification?.data?.validations;
    if (!globalDocVerificationValidations) return null;

    return globalDocVerificationValidations?.[globalDocVerificationCheck];
  }, [execution, globalDocVerificationCheck]);

  const ruleHasValidValidation =
    !!globalDocVerificationValidation &&
    typeof globalDocVerificationValidation === 'object';

  return (
    <>
      {globalDocVerificationCheck === 'authenticityCheck' &&
        ruleHasValidValidation &&
        Object.keys(globalDocVerificationValidation).includes('front') && (
          <AuthenticityCheckPopover authenticityCheck={globalDocVerificationValidation} />
        )}

      {globalDocVerificationCheck === 'textFieldsCheck' && ruleHasValidValidation && (
        <TextFieldsCheckPopover
          ruleName={validation?.ruleName}
          textFieldsCheck={globalDocVerificationValidation}
        />
      )}
    </>
  );
};

ComplianceRuleDetails.propTypes = {
  execution: PropTypes.objectOf(PropTypes.any).isRequired,
  validation: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ComplianceRuleDetails;
