import React from 'react';
import PropTypes from 'prop-types';
import { Card as CardComponent } from 'antd';

// import { Container } from './styles';

const Card = ({ className, children, ...rest }) => {
  return (
    <CardComponent {...rest} className={`gx-card-widget ${className}`}>
      {children}
    </CardComponent>
  );
};

Card.propTypes = {
  className: PropTypes.string,
};

Card.defaultProps = {
  className: '',
};

export default Card;
