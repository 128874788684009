import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { useQueryParams, StringParam } from 'use-query-params';

import PageTitle from 'components/PageTitle';
import RectangleButton from 'components/Button/RectangleButton';
import { useProfileTemplate } from 'hooks/profileTemplate';
import Footer from '../components/Footer';
import useStarterCustomModel from '../StarterCustomModel';
import { productServices } from '../../utils';

import './styles.less';

const { Text } = Typography;

const I18N_BASE_PATH = 'pages.private.profileModels.profileModelCreate.starterFlow.index';

const StarterFlow = ({ formRef }) => {
  const { t: translate } = useTranslation();

  const [, setQuery] = useQueryParams({
    step: StringParam,
  });

  const history = useHistory();
  const [templates, setTemplates] = useState([]);
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(false);

  const {
    resetProfileTemplate,
    cafServicesList,
    setProfileTemplate,
    setProduct,
    setIsStartToCreateNewProfileModel,
  } = useProfileTemplate();
  const { openModal, StarterCustomModelModal } = useStarterCustomModel();

  useEffect(() => {
    const cafTemplates = cafServicesList?.docs?.filter(
      (template) => template?.productType === 'template'
    );
    setTemplates(cafTemplates);
  }, [cafServicesList, setTemplates]);

  const onSubmit = useCallback(() => {
    if (selected === 'CUSTOM') {
      formRef.resetFields();
      setProfileTemplate({ type: null, name: null });
      openModal(selected);
    } else {
      setLoading(true);

      const formValues = {};

      const { template = {} } =
        templates.find(({ source }) => source === selected.source) || {};

      formValues.type = template.type;

      const [bgcServices, onbServices] = ['backgroundChecking', 'onboarding'].map(
        (key) => {
          if (template[key]) {
            return template[key].services?.reduce((obj, item) => {
              const extraDataSource =
                cafServicesList?.docs?.find(({ source }) => source === item.source) || {};
              return { ...obj, [item.source]: { ...extraDataSource, ...item } };
            }, {});
          }
          return {};
        }
      );
      formValues.backgroundCheckingServices = bgcServices;
      formValues.onboardingServices = onbServices;

      const validationsList =
        cafServicesList?.docs
          ?.map(({ relatedRules, category }) =>
            relatedRules?.map((rule) => ({ ...rule, category }))
          )
          ?.flat()
          ?.filter((x) => x) || [];

      formValues.validations = [
        ...(template?.backgroundChecking?.validations || []),
        ...(template?.onboarding?.validations || []),
      ]
        .map((validation) => ({
          ...validation,
          ...validationsList.find(({ rule }) => rule === validation.rule),
          rule: {
            key: validation.rule,
            label: validation.title,
            value: validation.rule,
          },
        }))
        .filter((item) => item?.title);

      setIsStartToCreateNewProfileModel(true);
      formRef.setFieldsValue(formValues);
      setProfileTemplate(formValues);
      setProduct(selected);

      setTimeout(() => {
        setLoading(false);
        setQuery({ step: 'confirmation' });
      }, 1000);
    }
  }, [
    cafServicesList,
    formRef,
    openModal,
    selected,
    setProduct,
    setProfileTemplate,
    setQuery,
    templates,
  ]);

  useEffect(() => {
    resetProfileTemplate();
  }, [resetProfileTemplate]);

  return (
    <div id="profile-model-starter-flow-component">
      {StarterCustomModelModal}

      <Row className="no-mrg mrg-btm-50" align="middle">
        <Col flex={1}>
          <PageTitle
            title={translate(`${I18N_BASE_PATH}.pageTitle.title`)}
            subtitle={translate(`${I18N_BASE_PATH}.pageTitle.subtitle`)}
          />
        </Col>
      </Row>

      <Text className="label-1">{translate(`${I18N_BASE_PATH}.text`)}</Text>

      <Row gutter={[16, 14]} className="mrg-top-30">
        {templates?.map((item) => (
          <Col key={item.source} xs={24} sm={12} md={12} lg={8} xl={8}>
            <RectangleButton
              title={translate(`services.${item.source}.title`, item.title)}
              description={translate(
                `services.${item.source}.description`,
                item.description
              )}
              icon={productServices[item.source]?.icon || 'caf-ic_hash'}
              orientation="horizontal"
              selected={selected?.source === item.source}
              onClick={() => setSelected(item)}
              comingSoon={item.comingSoon}
              price={item.price}
            />
          </Col>
        ))}
        {/* CUSTOM */}
        <Col key={99} xs={24} sm={12} md={12} lg={8} xl={8}>
          <RectangleButton
            title={translate(`${I18N_BASE_PATH}.customProduct.title`)}
            description={translate(`${I18N_BASE_PATH}.customProduct.description`)}
            icon="caf-ic_settings"
            orientation="horizontal"
            selected={selected === 'CUSTOM'}
            onClick={() => setSelected('CUSTOM')}
            comingSoon={false}
          />
        </Col>
      </Row>

      <Footer
        onCancel={() => history.push('/profile-templates/profile-models?active=true')}
        onSubmit={() => onSubmit()}
        disabled={!selected}
        loading={loading}
      />
    </div>
  );
};

StarterFlow.propTypes = {
  formRef: PropTypes.shape().isRequired,
};

export default StarterFlow;
