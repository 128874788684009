import React, { useCallback, useMemo, useState } from 'react';
import { Col, Row, Card, Divider, Switch, Dropdown, Menu, Button } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'services/hooks';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useProfileTemplate } from 'hooks/profileTemplate';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import FeatureFlagWrapper from 'components/FeatureFlagWrapper';
import PermissionWrapper from 'routes/PermissionWrapper';
import { hasRequiredPermissions } from 'routes/permissionFunctions';
import { useAuth } from 'hooks/auth';

const I18N_BASE_PATH = 'pages.private.routines.list.item';

const RoutineItem = ({ data, refreshList }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatCurrency } = useI18nFormatters();
  const { user } = useAuth();
  const history = useHistory();
  const { get, patch } = useFetch();
  const { cafServicesList } = useProfileTemplate();
  const [profilesCount, setProfilesCount] = useState(null);
  const [loadingProfilesCount, setLoadingProfilesCount] = useState(false);

  const executeIn = useMemo(() => {
    if (data?.frequency?.weekday !== '*') {
      return translate(`${I18N_BASE_PATH}.weekdays.${data?.frequency?.weekday}`);
    }
    if (data?.frequency?.month !== '*') {
      return translate(`${I18N_BASE_PATH}.yearly`, {
        day: data?.frequency?.day,
        month: moment()
          .month(parseInt(data?.frequency?.month, 10) - 1)
          .format('MMMM'),
      });
    }
    return translate(`${I18N_BASE_PATH}.monthly`, {
      day: data?.frequency?.day,
    });
  }, [data, translate]);

  const toggleActive = useCallback(
    async (active) => {
      await patch({
        url: `/routines/${data?._id}`,
        payload: {
          active,
        },
        showMessage: false,
      });
      refreshList();
    },
    [data, patch, refreshList]
  );

  const costByRoutine = useMemo(() => {
    const cost = cafServicesList?.docs
      ?.filter((s) => data.services.includes(s.source))
      ?.reduce((prev, curr) => {
        return prev + curr.price;
      }, 0);
    return cost;
  });

  const fetchAffectedProfiles = useCallback(async () => {
    setLoadingProfilesCount(true);
    const response = await get({
      url: `/routines/${data._id}`,
      config: {
        params: {
          onlyAffectedProfileCount: true,
        },
      },
    });
    setProfilesCount(response.data);
    setLoadingProfilesCount(false);
  }, [data, get]);

  return (
    <>
      <Card className="reset-mrg routine-item">
        <Row align="middle" justify="space-between">
          <Col span={24}>
            <Col className="first-line">
              <div>
                <span className="text-dark text-bold font-size-20">{data.name}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Switch
                  size="small"
                  checked={data.active}
                  onChange={toggleActive}
                  disabled={!hasRequiredPermissions(user, ['routines:delete'])}
                />
                <span className="mrg-left-10 text-dark text-bold width-50px">
                  {data.active
                    ? translate(`${I18N_BASE_PATH}.active`)
                    : translate(`${I18N_BASE_PATH}.inactive`)}
                </span>
                <PermissionWrapper requiredPermissions={['routines:update']}>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="1"
                          onClick={() => history.push(`/routines/${data?._id}`)}
                        >
                          <i className="caf-ic_edit" />
                          <span>{translate(`${I18N_BASE_PATH}.editButton`)}</span>
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <div className="btn-more-icon">
                      <i className="caf-ic_more-vertical" />
                    </div>
                  </Dropdown>
                </PermissionWrapper>
              </div>
            </Col>
            <Divider />
            <Col className="second-line">
              <i className="caf-ic_calendar mrg-right-5" />
              <span>{translate(`${I18N_BASE_PATH}.executeIn`)} </span>
              <span className="text-dark">{executeIn}</span>

              <FeatureFlagWrapper featureFlag="costPrices">
                <i className="caf-ic_dollar_sign mrg-left-25 mrg-right-5" />
                <span>{translate(`${I18N_BASE_PATH}.costByRoutine`)} </span>
                <span className="text-dark">
                  {i18nFormatCurrency(costByRoutine, 'pt-BR')}
                </span>
              </FeatureFlagWrapper>

              {profilesCount !== null && (
                <>
                  <i className="caf-ic_person mrg-left-25 mrg-right-5" />
                  <span>{translate(`${I18N_BASE_PATH}.profileCount`)}</span>
                  <span className="text-dark">{profilesCount}</span>
                  <Button
                    className="mrg-top-0 mrg-botton-0"
                    onClick={fetchAffectedProfiles}
                    type="link"
                    icon={<i className="caf-ic_processing_line mrg-right-5" />}
                    disabled={loadingProfilesCount}
                  >
                    {translate(`${I18N_BASE_PATH}.refreshBtn`)}
                  </Button>
                </>
              )}
              {profilesCount === null && (
                <>
                  <Button
                    className="mrg-top-0 mrg-botton-0"
                    onClick={fetchAffectedProfiles}
                    type="link"
                    icon={<i className="caf-ic_processing_line mrg-right-5" />}
                    disabled={loadingProfilesCount}
                  >
                    {translate(`${I18N_BASE_PATH}.profileCountBtn`)}
                  </Button>
                </>
              )}
            </Col>
          </Col>
        </Row>
      </Card>
    </>
  );
};

RoutineItem.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    active: PropTypes.bool,
    frequency: PropTypes.shape({
      weekday: PropTypes.string,
      month: PropTypes.string,
      day: PropTypes.string,
    }),
  }).isRequired,
  refreshList: PropTypes.func.isRequired,
};

export default RoutineItem;
