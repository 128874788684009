/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Collapse, Row, Col, Divider } from 'antd';
import { useTranslation } from 'react-i18next';

import EmptyState from 'components/EmptyState';
import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';
import { format } from 'date-fns';

import DottedLabel from 'components/DottedLabel';
// import { isEmpty } from 'utils/isObjectEmpty';

import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.laborInformation.people.components.classOrganizations.index';

const { Panel } = Collapse;

const ClassOrganizations = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  return (
    <>
      <DataSourceCardCollapsePanel
        title={translate(`${I18N_BASE_PATH}.classOrganizations.columnLabel.title`)}
        customId="labor-infos-component"
        icon="caf-ic_awards"
        toPrint={toPrint}
      >
        <ColumnLabel
          title={translate(`${I18N_BASE_PATH}.classOrganizations.columnLabel.title`)}
          span={24}
          customClassName="no-mrg"
        />
        {queryWorked ? (
          <>
            {parsedData?.memberships?.length ? (
              <Collapse className="mrg-top-25 mrg-btm-15 width-100">
                <Panel
                  disabled={!parsedData?.memberships?.length}
                  header={
                    <span className="text-dark text-bold">
                      {translate(`${I18N_BASE_PATH}.classOrganizations.register`)}
                    </span>
                  }
                  extra={
                    <b className="info-span">
                      {parsedData?.totalMemberships || '0'}{' '}
                      {translate(`${I18N_BASE_PATH}.classOrganizations.register2`)}
                    </b>
                  }
                >
                  {parsedData?.memberships?.map((ms) => (
                    <>
                      <Row align="middle" justify="space-between" className="mrg-top-15">
                        <Col span={24} className="mrg-btm-15">
                          <Row className="no-mrg no-pdd">
                            <b className="text-dark mrg-right-10">
                              {ms?.organizationName}
                            </b>
                            <DottedLabel
                              description={ms?.status}
                              type={ms?.status === 'ATIVO' ? 'primary' : 'danger'}
                            />
                          </Row>
                        </Col>

                        <Col span={24}>
                          <span className="text-gray text-bold">
                            {translate(`${I18N_BASE_PATH}.classOrganizations.register3`)}
                          </span>
                          <span> {ms?.registrationId || '-'}</span>
                        </Col>
                        <Col span={24}>
                          <span className="text-gray text-bold">
                            {translate(`${I18N_BASE_PATH}.classOrganizations.type`)}
                          </span>
                          <span> {ms?.category || '-'}</span>
                        </Col>
                        <Col span={24}>
                          <span className="text-gray text-bold">
                            {translate(`${I18N_BASE_PATH}.classOrganizations.location`)}
                          </span>
                          <span>
                            {' '}
                            {`${ms?.organizationChapter || '-'} - ${
                              ms?.organizationCountry
                            }`}
                          </span>
                        </Col>
                        {ms?.membershipStartDate &&
                          ms?.membershipStartDate.slice(0, 4) !== '0001' && (
                            <Col span={12}>
                              <span className="text-gray text-bold">
                                {translate(
                                  `${I18N_BASE_PATH}.classOrganizations.ingress`
                                )}
                              </span>
                              <span>
                                {' '}
                                {format(new Date(ms?.membershipStartDate), 'dd/MM/yyyy')}
                              </span>
                            </Col>
                          )}
                        {ms?.membershipEndDate &&
                          ms?.membershipEndDate.slice(0, 4) !== '9999' && (
                            <Col span={12}>
                              <span className="text-gray text-bold">
                                {translate(`${I18N_BASE_PATH}.classOrganizations.end`)}
                              </span>
                              <span>
                                {' '}
                                {format(new Date(ms?.membershipEndDate), 'dd/MM/yyyy')}
                              </span>
                            </Col>
                          )}
                        <Divider />
                      </Row>
                    </>
                  ))}
                </Panel>
              </Collapse>
            ) : (
              <span style={{ marginLeft: 16 }}>
                {translate(`${I18N_BASE_PATH}.classOrganizations.message`)}
              </span>
            )}
          </>
        ) : (
          <EmptyState
            statusCode={statusCode}
            message={source?.message || parsedData?.message}
          />
        )}
      </DataSourceCardCollapsePanel>
    </>
  );
};

export default ClassOrganizations;
