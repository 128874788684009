/* eslint-disable react/prop-types */
import React, { useState, useCallback, useMemo } from 'react';
import { Modal, Tabs } from 'antd';

import PageTitle from 'components/PageTitle';
import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import SPCItems from '../SPCItems';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.shared.spcRestrictionsScore.spcItems';

const ALT_I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.shared.spcRestrictionsScore';

const SPCDetails = ({ toPrint }) => {
  const { t: translate } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [data, setData] = useState(null);
  const { i18nFormatDate } = useI18nFormatters();

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback((_data) => {
    setData(_data);
    setModalVisible(true);
  }, []);

  const customTitle = useMemo(() => {
    return toPrint
      ? translate(`${I18N_BASE_PATH}.index.customTitle.hasToPrint`)
      : translate(`${I18N_BASE_PATH}.index.customTitle.hasNotToPrint`);
  }, [toPrint, translate]);

  const SPCDetailsModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeModal}
        footer={null}
        style={{ top: 40 }}
        width={675}
      >
        <PageTitle
          title={customTitle}
          subtitle={`${
            data?.spc?.lastOccurenceDate || data?.spc?.dateLastOccurency
              ? `${translate(
                  `${I18N_BASE_PATH}.index.pageTitle.subtitle.lastUpdate`
                )} ${i18nFormatDate(
                  data?.spc?.lastOccurenceDate || data?.spc?.dateLastOccurency,
                  'default'
                )}`
              : `${translate(`${I18N_BASE_PATH}.index.pageTitle.subtitle.default`)}`
          }`}
        />
        <div id="spc-details-modal" className="no-mrg no-pdd">
          <Tabs>
            <Tabs.TabPane
              key="1"
              tab={translate(`${ALT_I18N_BASE_PATH}.index.spcScoreRange.spcScore3Months`)}
            >
              <SPCItems data={data} spcScoreRange="spcScore3Months" />
            </Tabs.TabPane>
            <Tabs.TabPane
              key="2"
              tab={translate(
                `${ALT_I18N_BASE_PATH}.index.spcScoreRange.spcScore12Months`
              )}
            >
              <SPCItems data={data} spcScoreRange="spcScore12Months" />
            </Tabs.TabPane>
            <Tabs.TabPane
              key="3"
              tab={translate(`${ALT_I18N_BASE_PATH}.index.spcScoreRange.totalDebts`)}
            >
              <SPCItems data={data} spcScoreRange="total" />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, customTitle, data, translate, i18nFormatDate]
  );

  return { openModal, closeModal, SPCDetailsModal };
};

export default SPCDetails;
