/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Layout } from 'antd';

import { useExecution } from 'hooks/execution';

import CustomerCustomizationWrapper from 'components/CustomerCustomizationWrapper';
import { CardWrapper } from 'components/CardWrapper';

import Tabs from 'pages/private/Executions/components/Tabs';
import ExecutionSubheader from 'pages/private/Executions/components/ExecutionSubheader';
import ConfirmDataZoop from 'pages/private/Executions/components/ConfirmDataZoop';
import GeneralStatus from 'pages/private/Executions/components/GeneralStatus';

import InternationalGeneralStatus from 'pages/private/Executions/components/InternationalGeneralStatus';
import ExecutionValidations from 'pages/private/Executions/components/ExecutionValidations';

import DocumentsWidget from 'pages/private/Profiles/People/components/DocumentsWidget';
import { useAuth } from 'hooks/auth';

import './styles.less';

const { Content } = Layout;

const ExecutionContent = () => {
  const { t: translate } = useTranslation();
  const { execution } = useExecution();
  const { user } = useAuth();
  return (
    <div className="executions-content-grid">
      <div className="executions-content-grid-1">
        <CardWrapper hasPadding>
          <ExecutionSubheader execution={execution} />
          <Tabs execution={execution} />
        </CardWrapper>
      </div>
      <div className="executions-content-grid-2">
        <CardWrapper hasPadding>
          {user?.accountData?.featureFlags?.isWorkflowBuilder
            ? execution?.status && <InternationalGeneralStatus data={execution} />
            : execution?.status && <GeneralStatus data={execution} />}

          <CustomerCustomizationWrapper customer="ZOOP">
            {execution?.customStatus === 'WAITING_APPROVAL' && (
              <ConfirmDataZoop execution={execution} />
            )}
          </CustomerCustomizationWrapper>

          {execution?.cpf && <DocumentsWidget />}

          <ExecutionValidations
            isWorkflowBuilder={user?.accountData?.featureFlags?.isWorkflowBuilder}
            execution={execution}
            url=""
            type={translate(
              'pages.private.executions.components.executionContent.index.executionContent.executionValidations.type'
            )}
          />
        </CardWrapper>
      </div>
    </div>
  );
};

export default ExecutionContent;
