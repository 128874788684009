const I18N_BASE_PATH = 'components.dataSourceCards.violationsModal.columns';

/**
 *  Mapear os campos que deseja exibir,
 *  por padrao as labels sao mapeadas com a mesma key no i18n
 * */
export const FIRST_SECTION_FIELDS = [
  'violationId',
  'violationBreakdownId',
  'renainfId',
  'violationRegistrationDate',
  'actuatorAgencyState',
  'plate',
  'renavamId',
  'vehicleKindId',
  'vehicleKindDescription',
  'vehicleBodyworkId',
  'vehicleBodyworkDescription',
  'vehicleCategoryId',
  'vehicleCategoryDescription',
  'vehicleTypeId',
  'vehicleTypeDescription',
  'vehicleColorId',
  'vehicleColorDescription',
  'modelBrandId',
  'modelBrandDescription',
];

export const SECOND_SECTION_FIELDS = [
  'vehicleJurisdictionState',
  'informedRegistrationState',
  'registrationCityId',
  'registrationCityDescription',
  'vehicleCoutryId',
  'vehicleCoutryDescription',
];

export const THIRD_SECTION_FIELDS = [
  'actuatorAgencyId',
  'actuatorAgencyDescription',
  'autoInfractionNumber',
  'autoSignatureId',
  'autoSignatureDescription',
  'driverCnhModelId',
  'driverCnhModelDescription',
];

export const FOURTH_SECTION_FIELDS = [
  'driverCnhRegistrationNumber',
  'driverCnhExpeditionState',
  'violationDate',
  'violationTime',
  'violationOccurrencePlace',
  'violationCityId',
  'violationCityDescription',
  'autoTypeId',
  'autoTypeDescription',
  'notLicensedDriverDocumentNumber',
  'notLicensedDriverDocumentTypeId',
  'notLicensedDriverDocumentTypeDescription',
  'actualMeasurement',
  'allowedLimit',
  'measurementConsidered',
  'measurementUnitId',
  'measurementUnitDescription',
  'violationFullValue',
  'vehicleOwnerOriginId',
  'vehicleOwnerOriginDescription',
  'ownerDocumentTypeId',
  'ownerDocumentTypeDescription',
  'ownerIdentificationNumber',
  'ownerName',
  'notificationNoticeIssueDate',
  'defenseNoticeLimitDate',
  'notificationNoticeIndicator',
  'numberNotificationPenalty',
  'penaltyNotificationIssueDate',
  'penaltyNotificationExpirationDate',
  'penaltyType',
  'penaltyDescription',
  'violatorActualName',
  'violatorActualCnhModelId',
  'violatorActualCnhModelDescription',
  'violatorActualCnhRegistrationNumber',
  'driverActualCnhExpeditionState',
  'scoreAnalysisStatusId',
  'scoreAnalysisStatusDescription',
  'scoredCnhModelId',
  'scoredCnhModelDescription',
  'scoredCnhRegistrationNumber',
  'scoredCnhExpeditionState',
  'enforceabilityId',
  'enforceabilityDescription',
  'paymentState',
  'violationPaymentDate',
  'violationPaidValue',
  'paymentRegistrationDate',
  'paymentsAmount',
  'occurrenceTypeId',
  'occurrenceTypeDescription',
  'occurrenceOriginDescription',
  'proccessNumber',
];

export const FIFTH_SECTION_FIELDS = [
  'restrictionId1',
  'restrictionDescription1',
  'restrictionId2',
  'restrictionDescription2',
  'restrictionId3',
  'restrictionDescription3',
  'restrictionId4',
  'restrictionDescription4',
  'restrictionIndicatorRenajud',
  'restrictionIndicatorRenavam',
  'burglaryTheftIndicatorRenavam',
];

const ALL_MAPPED_FIELDS = [
  ...FIRST_SECTION_FIELDS,
  ...SECOND_SECTION_FIELDS,
  ...THIRD_SECTION_FIELDS,
  ...FOURTH_SECTION_FIELDS,
  ...FIFTH_SECTION_FIELDS,
];

export function isMapped(key) {
  if (ALL_MAPPED_FIELDS.includes(key)) return `${I18N_BASE_PATH}.${key}`;
  return null;
}
