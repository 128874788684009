import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useExecution } from 'hooks/execution';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import PropTypes from 'prop-types';
import { Col, Row, Tooltip } from 'antd';
import { MedalShine } from '@combateafraude/icons/sports';
import Card from 'components/Card';

import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

import CircleIcon from 'components/Icon/CircleIcon';

import { toMaskedCpf } from 'utils/formatters';

import {
  relationshipLevel,
  relationshipTips,
  relationshipType,
} from '../../../../utils/sportRelated';

import './styles.less';

const SportsAndRelationCard = ({ source, relationship, danger, span }) => {
  const { t: translate } = useTranslation();
  const { execution } = useExecution();
  const { i18nFormatDateOnly } = useI18nFormatters();

  return (
    <Col span={span} id="sports-and-related-card">
      <CircleIcon
        className="mrg-left-0"
        icon={relationship !== 'oneself' ? 'caf-ic_users' : 'caf-ic_person'}
        danger={danger}
      />
      <div className="sports-and-related-card-info">
        {relationship !== 'oneself' ? (
          <>
            <h4 className="text-bold no-mrg no-pdd">
              {translate(
                'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.hasRelation'
              )}
            </h4>
            <Row>
              <Col span={24}>
                <span
                  className="mrg-btm-10 mrg-top-20"
                  style={{ color: '#828282', fontWeight: 400, fontSize: 12 }}
                >
                  {translate(
                    'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.peopleConsulted'
                  )}
                </span>
              </Col>
              <Col span={24} className="mrg-btm-5">
                <strong>
                  {translate(
                    'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.name'
                  )}
                </strong>{' '}
                {source?.name}
              </Col>
              <Col span={24} className="mrg-btm-5">
                <strong>
                  {translate(
                    'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.document'
                  )}
                </strong>{' '}
                {toMaskedCpf(execution?.parameters?.cpf)}
              </Col>
              <Col span={24} className="mrg-btm-5">
                <strong>
                  {translate(
                    'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.relation'
                  )}
                </strong>{' '}
                {translate(relationshipLevel(source?.relationship))}
              </Col>
            </Row>
            <Card className="mrg-top-20" style={{ borderColor: '#D1D1D1' }}>
              <div
                className="mrg-btm-20 flex start-center"
                style={{ fontSize: 16, color: '#828282', fontWeight: 700 }}
              >
                <MedalShine height={24} width={24} className="mrg-right-10" />{' '}
                {translate(
                  'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.relatedSportsman'
                )}
              </div>
              <Row className="text-dark">
                <Col span={24} className="mrg-btm-5">
                  <strong>
                    {translate(
                      'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.sportsmanName'
                    )}
                  </strong>{' '}
                  {source?.relationshipName || '-'}
                </Col>
                <Col span={12} className="mrg-btm-5">
                  <strong>
                    {translate(
                      'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.sportsmanDocument'
                    )}
                  </strong>{' '}
                  {toMaskedCpf(source?.relationshipDocument) || '-'}
                </Col>
                <Col span={12} className="mrg-btm-5">
                  <strong>
                    {translate(
                      'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.sport'
                    )}
                  </strong>{' '}
                  {capitalizeFirstLetter(source?.sport[0]) || '-'}
                </Col>
                <Col span={12} className="mrg-btm-5">
                  <strong>
                    {translate(
                      'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.relationshipType'
                    )}
                  </strong>{' '}
                  {translate(relationshipType(source?.sportsRelationshipType[0]))}
                  {!!relationshipTips(source?.sportsRelationshipType[0]) && (
                    <Tooltip
                      placement="bottomLeft"
                      title={translate(
                        relationshipTips(source?.sportsRelationshipType[0])
                      )}
                    >
                      <i className="caf-ic_info" />
                    </Tooltip>
                  )}
                </Col>
                <Col span={12} className="mrg-btm-5">
                  <strong>
                    {translate(
                      'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.entity'
                    )}
                  </strong>{' '}
                  {source?.sportsEntity || '-'}
                </Col>
                <Col span={12} className="mrg-btm-5">
                  <strong>
                    {translate(
                      'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.updateDate'
                    )}
                  </strong>{' '}
                  {i18nFormatDateOnly(source?.updateDate, 1) || '-'}
                </Col>
                <Col span={12} className="mrg-btm-5">
                  <strong>
                    {translate(
                      'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.year'
                    )}
                  </strong>{' '}
                  {source?.sportLastBondYear || '-'}
                </Col>
              </Row>
              {source?.tournaments?.length > 0 && (
                <span
                  className="mrg-btm-10 mrg-top-30"
                  style={{ color: '#828282', fontWeight: 400, fontSize: 12 }}
                >
                  {translate(
                    'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.tournaments'
                  )}
                </span>
              )}
              <Row className="text-dark">
                {source?.tournaments?.map((tournament, idx) => (
                  <Col span={12} className={idx > 1 ? 'mrg-top-20' : ''}>
                    <span className="mrg-btm-5">
                      <strong>
                        {translate(
                          'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.year'
                        )}
                      </strong>{' '}
                      {tournament?.year || '-'}
                    </span>
                    <span className="mrg-btm-5">
                      <strong>
                        {translate(
                          'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.tournamentName'
                        )}
                      </strong>{' '}
                      {tournament?.name || '-'}
                    </span>
                  </Col>
                ))}
              </Row>
            </Card>
          </>
        ) : (
          <>
            <h4 className="text-bold mrg-btm-20">
              {translate(
                'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.isSportsman'
              )}
            </h4>
            <Row>
              <Col span={24} className="mrg-btm-5">
                <strong>
                  {translate(
                    'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.sportsmanName'
                  )}
                </strong>{' '}
                {source?.name || '-'}
              </Col>
              <Col span={12} className="mrg-btm-5">
                <strong>
                  {translate(
                    'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.sportsmanDocument'
                  )}
                </strong>{' '}
                {toMaskedCpf(execution?.parameters?.cpf) || '-'}
              </Col>
              <Col span={12} className="mrg-btm-5">
                <strong>
                  {translate(
                    'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.sport'
                  )}
                </strong>{' '}
                {capitalizeFirstLetter(source?.sport[0]) || '-'}
              </Col>
              <Col span={12} className="mrg-btm-5">
                <strong>
                  {translate(
                    'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.relationshipType'
                  )}
                </strong>{' '}
                {translate(relationshipType(source?.sportsRelationshipType[0])) || '-'}{' '}
                {!!relationshipTips(source?.sportsRelationshipType[0]) && (
                  <Tooltip
                    placement="bottomLeft"
                    title={translate(relationshipTips(source?.sportsRelationshipType[0]))}
                  >
                    <i className="caf-ic_info" />
                  </Tooltip>
                )}
              </Col>
              <Col span={12} className="mrg-btm-5">
                <strong>
                  {translate(
                    'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.entity'
                  )}
                </strong>{' '}
                {source?.sportsEntity || '-'}
              </Col>
              <Col span={12} className="mrg-btm-5">
                <strong>
                  {translate(
                    'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.updateDate'
                  )}
                </strong>{' '}
                {i18nFormatDateOnly(source?.updateDate, 1) || '-'}
              </Col>
              <Col span={12} className="mrg-btm-5">
                <strong>
                  {translate(
                    'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.year'
                  )}
                </strong>{' '}
                {source?.sportLastBondYear || '-'}
              </Col>
            </Row>
            {source?.tournaments?.length > 0 && (
              <span
                className="mrg-btm-10 mrg-top-30"
                style={{ color: '#828282', fontWeight: 400, fontSize: 12 }}
              >
                {translate(
                  'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.tournaments'
                )}
              </span>
            )}
            <Row>
              {source?.tournaments?.map((tournament, idx) => (
                <Col span={12} className={idx > 1 ? 'mrg-top-20' : ''}>
                  <span className="mrg-btm-5">
                    <strong>
                      {translate(
                        'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.year'
                      )}
                    </strong>{' '}
                    {tournament?.year || '-'}
                  </span>
                  <span className="mrg-btm-5">
                    <strong>
                      {translate(
                        'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.tournamentName'
                      )}
                    </strong>{' '}
                    {tournament?.name || '-'}
                  </span>
                </Col>
              ))}
            </Row>
          </>
        )}
      </div>
    </Col>
  );
};

export default SportsAndRelationCard;
