import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import { validationStatus } from 'utils/handlerStatus';

const ComplianceRulesValidations = ({ profileValidations }) => {
  const { t: translate } = useTranslation();

  const validationsFiltered = useMemo(() => {
    return profileValidations?.filter(
      (v) => v?.status === 'INVALID' || v?.status === 'PENDING'
    );
  }, [profileValidations]);

  return validationsFiltered?.map((v) => (
    <Row
      key={v?.rule}
      align="middle"
      justify="center"
      className="compliance-rules-status reset-mrg pdd-top-10 pdd-btm-10"
    >
      <Col span={8} className="reset-mrg no-pdd">
        <span className="rule-title">{translate(`validations.${v?.rule}.title`)}</span>
      </Col>
      <Col span={2}>
        <i
          style={{ color: validationStatus(v?.status).color }}
          className={`status-icon ${validationStatus(v?.status).icon}`}
        />
      </Col>
      <Col span={14}>{v?.description}</Col>
    </Row>
  ));
};

ComplianceRulesValidations.propTypes = {
  profileValidations: PropTypes.array,
};

export default ComplianceRulesValidations;
