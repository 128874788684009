import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { Row, Col } from 'antd';

import CustomUploadButton from 'pages/private/Profiles/components/CustomUploadButton';
import useModalUpload from 'components/ModalUpload';

import PermissionWrapper from 'routes/PermissionWrapper';

import archives from 'assets/images/archives.svg';

import './styles.less';

const EmptyArchives = () => {
  const { t: translate } = useTranslation();

  const matchPeople = useRouteMatch('/profile/people');

  const { openModal, CardUploadModal } = useModalUpload();

  const handleOpenUploadModal = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <>
      {CardUploadModal}

      <Row id="empty-archives-component" className="reset-mrg">
        <Col span={10} className="empty-archives-img custom-img">
          <img className="grayscale-95" src={archives} alt="Ilustração arquivos" />
        </Col>
        <Col span={14} className="send-archives-container">
          <strong className="send-archives-description">
            {translate(
              'pages.private.profiles.components.tabs.components.archivesList.components.emptyArchives.index.emptyArchives.title'
            )}
          </strong>

          <PermissionWrapper
            requiredPermissions={matchPeople ? ['people:update'] : ['companies:update']}
            requiredResources={['files:create']}
          >
            <CustomUploadButton
              onClick={handleOpenUploadModal}
              customBottom="0px"
              customClass="send-archives-btn"
              customContent={
                <span>
                  {translate(
                    'pages.private.profiles.components.tabs.components.archivesList.components.emptyArchives.index.emptyArchives.button.before'
                  )}{' '}
                  <strong>
                    {translate(
                      'pages.private.profiles.components.tabs.components.archivesList.components.emptyArchives.index.emptyArchives.button.inside'
                    )}
                  </strong>{' '}
                  {translate(
                    'pages.private.profiles.components.tabs.components.archivesList.components.emptyArchives.index.emptyArchives.button.after'
                  )}
                </span>
              }
              customIcon="caf-ic_attach font-size-35"
            />
          </PermissionWrapper>
        </Col>
      </Row>
    </>
  );
};

export default EmptyArchives;
