/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Input, Select, Space, Tooltip } from 'antd';
import classNames from 'classnames';
import CountryFlag from 'components/CountryFlag';
import Button from 'components/Button';
import { countries } from './countries';
import { statesFromCA, statesFromUS } from './states';

import './styles.less';

const { Option } = Select;

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companySearching.components.filterCompanies';

const FilterCompanies = ({
  stateToSearch,
  setStateToSearch,
  countryToSearch,
  setCountryToSearch,
  companyToSearch,
  setCompanyToSearch,
  setCompanyNumber,
  companyNumber,
  handleSubmit,
  handleClear,
  workflowToSearch,
  setWorkflowToSearch,
  workflows,
  isLoading,
}) => {
  const [typingCountry, setTypingCountry] = useState('');
  const [houver, setHouver] = useState(false);

  const { t: translate } = useTranslation();

  const countriesAvailableStateSelect = ['CA', 'US'];

  const shouldRenderStateSelect = countriesAvailableStateSelect.includes(countryToSearch);

  const areButtonsDisabled = useMemo(() => {
    if (!workflowToSearch) {
      return true;
    }

    if (!countryToSearch || (!companyToSearch && !companyNumber)) {
      return true;
    }
    if (shouldRenderStateSelect && !stateToSearch) {
      return true;
    }

    return false;
  }, [
    companyToSearch,
    countryToSearch,
    companyNumber,
    shouldRenderStateSelect,
    stateToSearch,
    workflowToSearch,
  ]);

  const onChangeCountryToSearch = (country) => {
    setCountryToSearch(country);
  };

  const onChangeStateToSearch = (state) => {
    setStateToSearch(state);
  };

  const onChangeWorkflowToSearch = (flow) => {
    setWorkflowToSearch(flow);
  };

  useEffect(() => {
    setStateToSearch(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryToSearch]);

  // google chrome only disable autocomplete when is set to "none", antd default is "off"
  useEffect(() => {
    const stateSelect = document.getElementById('searchingState');
    if (stateSelect) stateSelect.autocomplete = 'none';
  }, [countryToSearch]);

  const renderCountryFlag = () =>
    typingCountry.length <= 0 && countryToSearch ? (
      <CountryFlag className="select-input-selected-flag" country={countryToSearch} />
    ) : (
      <></>
    );

  return (
    <>
      <Col span={24} className="title-filter-companies">
        {translate(`${I18N_BASE_PATH}.title`, 'Company search')}
      </Col>
      <h6 className="sub-title">
        {translate(
          `${I18N_BASE_PATH}.description`,
          'Select a workflow, then enter a company name and/or number'
        )}
      </h6>
      <div className="wrapper-inputs-filter">
        <Col className="wrapper-select-filter-inputs">
          <label htmlFor="searchingCountry">
            {translate(`${I18N_BASE_PATH}.country`, 'Select the country')}
          </label>
          <div
            className={classNames(
              'wrapper-select-input-and-flag',
              countryToSearch ? 'is-selected' : ''
            )}
          >
            <Select
              id="searchingCountry"
              showSearch
              allowClear
              onMouseEnter={() => {
                setHouver(true);
              }}
              onMouseLeave={() => {
                setHouver(false);
              }}
              onSearch={(event) => setTypingCountry(event)}
              onSelect={() => setTypingCountry('')}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  setTypingCountry('');
                }
              }}
              className="wrapper-select-input-and-flag"
              value={countryToSearch}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .includes(
                    input
                      .toLowerCase()
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                  )
              }
              filterSort={(optionA, optionB) =>
                optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
              }
              optionLabelProp="label"
              onChange={onChangeCountryToSearch}
              placeholder={translate(
                `${I18N_BASE_PATH}.selectCountry`,
                'Select the country'
              )}
            >
              {countries?.map((country) => {
                return (
                  <Option
                    value={country.value}
                    label={translate(country.label, 'No translation available')}
                  >
                    <Tooltip
                      title={translate(country.label, 'No translation available')}
                      autoAdjustOverflow
                      placement="rightBottom"
                      arrowPointAtCenter
                      trigger="hover"
                      overlayInnerStyle={{
                        padding: '0.5rem',
                      }}
                    >
                      <Space style={{ width: '100%' }}>
                        <span
                          role="img"
                          aria-label={translate(
                            country.label,
                            'No translation available'
                          )}
                        >
                          <CountryFlag country={country.value} />
                        </span>
                        {translate(country.label, 'No translation available')}
                      </Space>
                    </Tooltip>
                  </Option>
                );
              })}
            </Select>
            {renderCountryFlag()}
          </div>
        </Col>
        <Col style={{ width: '100%', height: '100%' }}>
          <label htmlFor="searchingParameters">
            {translate(`${I18N_BASE_PATH}.companyName`, 'Company name')}
          </label>
          <Input
            id="searchingParameters"
            value={companyToSearch}
            onChange={(event) => {
              if (event.target.value.startsWith(' ')) {
                event.target.value = event.target.value.trim();
              }
              setCompanyToSearch(event.target.value);
            }}
            placeholder={translate(
              `${I18N_BASE_PATH}.companyNamePlaceholder`,
              'Search by company name'
            )}
            onKeyPress={(event) => {
              if (event.key === 'Enter' && !areButtonsDisabled && !isLoading) {
                handleSubmit();
              }
            }}
          />
        </Col>
        <Col style={{ width: '33%', height: '100%', paddingLeft: '10px' }}>
          <label htmlFor="companyNumberParameter">
            {translate(`${I18N_BASE_PATH}.companyNumber`, 'Company number')}
          </label>
          <Input
            id="companyNumberParameter"
            value={companyNumber}
            onChange={(event) => {
              if (event.target.value.startsWith(' ')) {
                event.target.value = event.target.value.trim();
              }
              setCompanyNumber(event.target.value);
            }}
            placeholder={translate(
              `${I18N_BASE_PATH}.companyNumberPlaceholder`,
              'Search by company number'
            )}
            onKeyPress={(event) => {
              if (event.key === 'Enter' && !areButtonsDisabled && !isLoading) {
                handleSubmit();
              }
            }}
          />
        </Col>
      </div>
      <div className="wrapper-inputs-filter-secondary">
        {shouldRenderStateSelect && (
          <Col className="wrapper-select-filter-inputs">
            <label htmlFor="searchingWorkflow">
              {translate(`${I18N_BASE_PATH}.state`, 'State')}
            </label>
            <Select
              id="searchingState"
              showSearch
              value={stateToSearch}
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .includes(
                    input
                      .toLowerCase()
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                  )
              }
              filterSort={(optionA, optionB) =>
                optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
              }
              optionLabelProp="label"
              onChange={onChangeStateToSearch}
              placeholder={translate(`${I18N_BASE_PATH}.selectState`, 'Select one state')}
            >
              {(countryToSearch === 'CA' ? statesFromCA : statesFromUS)?.map(
                (country) => {
                  return (
                    <Option
                      value={country.value}
                      label={translate(country.label, 'No translation available')}
                    >
                      {translate(country.label, 'No translation available')}
                    </Option>
                  );
                }
              )}
            </Select>
          </Col>
        )}

        <Col className="wrapper-select-filter-inputs">
          <label htmlFor="searchingWorkflow">
            {translate(`${I18N_BASE_PATH}.workflow`, 'Workflow')}
          </label>
          <Select
            id="searchingWorkflow"
            showSearch
            value={workflowToSearch}
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) => option.label.toLowerCase().includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
            }
            options={workflows}
            onChange={onChangeWorkflowToSearch}
            placeholder={translate(
              `${I18N_BASE_PATH}.selectWorkflow`,
              'Select one workflow'
            )}
          />
        </Col>
      </div>
      <Col span={24} className="submit-on-footer">
        <div className="clean-button-on-footer">
          <Button
            style={{ color: '#004af7' }}
            type="default"
            onClick={handleClear}
            disabled={isLoading}
          >
            {translate(`${I18N_BASE_PATH}.clear`, 'Clear')}
          </Button>
        </div>
        <div className="submit-button-on-footer">
          <Button
            loading={isLoading}
            type="primary"
            disabled={areButtonsDisabled}
            onClick={() => handleSubmit()}
          >
            {translate(`${I18N_BASE_PATH}.submit`, 'Submit')}
          </Button>
        </div>
      </Col>
    </>
  );
};

export default FilterCompanies;
