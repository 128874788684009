import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CompanyRow,
  CompanyCard,
  CompanyLink,
  CompanySubtitle1,
  CompanyContent,
} from 'pages/private/KYBCompany/CompanyDetailsV2/components';
import PropTypes from 'prop-types';
import CountryFlag from 'components/CountryFlag';
import NotAvailable from 'components/NotAvailable';

const I18N_BASE_PATH = 'pages.private.KYBCompany.pages';
const SUMMARY = `${I18N_BASE_PATH}.companyDetails.subPages.summary`;
const GROUP = `${SUMMARY}.cards.groupStructure`;

const GroupStructure = ({ summary, disabled, print = false }) => {
  const { t: translate } = useTranslation();

  return (
    <Col span={12}>
      <CompanyCard className="kyb-company-summary-group-card kyb-company-print-break-avoid">
        <Row>
          <Col span={24}>
            <CompanySubtitle1>
              {translate(`${GROUP}.title`, 'Group Structure')}
            </CompanySubtitle1>
          </Col>
        </Row>
        {summary.companyLinksCountCompanies || summary?.ultimateParentName ? (
          <>
            <CompanyRow className="kyb-company-summary-group-content">
              <CompanyContent
                i18n={GROUP}
                title="group"
                text={
                  summary.companyLinksCountCompanies ? (
                    <>
                      {summary.companyLinksCountCompanies}{' '}
                      {translate(
                        `${SUMMARY}.cards.companyLinks.companiesIn`,
                        'companies in'
                      )}{' '}
                      {summary.companyLinksCountCountries}{' '}
                      {translate(`${SUMMARY}.cards.companyLinks.countries`, 'countries')}
                    </>
                  ) : null
                }
                col={24}
              />
              <CompanyContent
                i18n={GROUP}
                title="ultimateHoldingCompany"
                text={
                  summary?.ultimateParentName ? (
                    <div className="flex start-center">
                      {summary?.ultimateParentCountry ? (
                        <CountryFlag
                          className="font-size-14 mrg-right-5"
                          country={summary?.ultimateParentCountry}
                        />
                      ) : (
                        <></>
                      )}
                      {summary?.ultimateParentName}
                    </div>
                  ) : null
                }
                col={24}
              />
            </CompanyRow>
            <Row justify="end">
              <Col className="kyb-company-summary-footer">
                <CompanyLink to="?tab=groupStructure">
                  {translate(`${I18N_BASE_PATH}.companyDetails.mainCardLink`)}
                </CompanyLink>
              </Col>
            </Row>
          </>
        ) : (
          <NotAvailable card />
        )}
      </CompanyCard>
    </Col>
  );
};

GroupStructure.propTypes = {
  summary: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  print: PropTypes.bool.isRequired,
};

export default GroupStructure;
