import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  BarChart as REBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { Card } from 'antd';
import EmptyMessage from 'components/EmptyMessage';
import Loader from 'components/Loader';

import chroma from 'chroma-js';

import './styles.less';
import { useTranslation } from 'react-i18next';
import currencyFormatter from 'utils/currencyFormatter';

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul className="bar-chart__legend-wrapper">
      {payload.map((entry, index) => (
        <li key={index} className="bar-chart__legend-item">
          <span
            style={{
              backgroundColor: entry.active ? entry.color : '#BDBDBD',
            }}
          />
          <p>{entry.value}</p>
        </li>
      ))}
    </ul>
  );
};

const getPath = (x, y, width, height, borderRadius, isFirst) => {
  const borderRadiusX = Math.min(borderRadius, width / 2);
  const borderRadiusY = Math.min(borderRadius, height / 2);

  const newHeight = height > 0 && !isFirst ? height + borderRadius : height;

  return `M${x},${y + newHeight}
          L${x},${y + borderRadiusY}
          C${x},${y + borderRadiusY * (1 - 0.55228)} ${
    x + borderRadiusX * (1 - 0.55228)
  },${y} ${x + borderRadiusX},${y}
          L${x + width - borderRadiusX},${y}
          C${x + width - borderRadiusX * (1 - 0.55228)},${y} ${x + width},${
    y + borderRadiusY * (1 - 0.55228)
  } ${x + width},${y + borderRadiusY}
          L${x + width},${y + newHeight}
          Z`;
};

const CustomBar = ({ fill, x, y, width, height, value }) => {
  const isFirst = value?.[0] === 0 || typeof value === 'number';

  return <path d={getPath(x, y, width, height, 2, isFirst)} stroke="none" fill={fill} />;
};

const CustomTooltip = ({ active, payload, productMap, hidePrice, currencyCode }) => {
  const { t: translate } = useTranslation();

  if (active && payload && payload.length) {
    const { name: productId, value, color, payload: barData } = payload[0];

    return (
      <Card className="bar-chart__tooltip">
        <p className="bar-chart__tooltip_title">
          <span style={{ backgroundColor: chroma(color._rgb).hex() }} />
          <strong>{productMap[productId]}</strong>
        </p>
        <div>
          {!hidePrice && (
            <p>
              {translate('pages.private.consumption.chart.tooltip.unitValue')}:{' '}
              {currencyFormatter.toCurrency(
                barData[productId].price / barData[productId].amount,
                currencyFormatter.currencyCodeToSymbol(currencyCode),
                currencyFormatter.currencyCodeToLocale(currencyCode)
              )}
            </p>
          )}
          <p>
            {translate('pages.private.consumption.chart.tooltip.queries')}: {value}
          </p>
        </div>
        {!hidePrice && (
          <p>
            <strong>
              {translate('pages.private.consumption.chart.tooltip.total')}:{' '}
              {currencyFormatter.toCurrency(
                barData[productId].price,
                currencyFormatter.currencyCodeToSymbol(currencyCode),
                currencyFormatter.currencyCodeToLocale(currencyCode)
              )}
            </strong>
          </p>
        )}
      </Card>
    );
  }

  return null;
};

const minSaturation = 0.73;

const BarChart = ({ chartData, selectedProduct, hidePrice, loading, currencyCode }) => {
  const { t: translate } = useTranslation();

  const colors = useMemo(
    () =>
      chroma
        .scale([
          '#34D690',
          '#F8B239',
          '#596580',
          '#39C560',
          '#F73F19',
          '#763AD7',
          '#042E2D',
          '#003857',
          '#E21B45',
          '#D64BE6',
          '#AC1434',
          '#00298A',
          '#4490FB',
          '#004AF7',
        ])
        .colors(chartData.products.length)
        .map((color) => {
          let modifiedColor = chroma(color);
          const originalSaturation = modifiedColor.get('hsl.s');
          modifiedColor = modifiedColor.set(
            'hsl.s',
            Math.max(originalSaturation, minSaturation)
          );
          return modifiedColor;
        })
        .reverse(),
    [chartData]
  );

  if (loading) {
    return <Loader size="50px" />;
  }

  if (chartData.products.length === 0) {
    return (
      <EmptyMessage
        type="empty"
        description={translate('pages.private.consumption.chart.emptyMessage')}
        className="mrg-top-55"
      />
    );
  }

  return (
    <ResponsiveContainer width="100%" height={600}>
      <REBarChart
        data={chartData.data}
        reverseStackOrder
        maxBarSize={75}
        className="bar-chart"
      >
        <CartesianGrid vertical={false} />
        <XAxis dataKey="time" tickLine={false} />
        <YAxis tickLine={false} />
        <Tooltip
          content={
            <CustomTooltip
              productMap={chartData.productMap}
              hidePrice={hidePrice}
              currencyCode={currencyCode}
            />
          }
          shared={false}
        />
        <Legend
          content={renderLegend}
          payload={chartData.products.map((productId, index) => ({
            value: chartData.productMap[productId],
            color: colors[index % colors.length],
            active: selectedProduct === 'all' || productId === selectedProduct,
          }))}
        />
        {chartData.products.map((product, index) =>
          selectedProduct !== 'all' ? (
            <>
              {product === selectedProduct && (
                <Bar
                  name={product}
                  dataKey={`${product}.amount`}
                  key={product}
                  fill={colors[index % colors.length]}
                  shape={<CustomBar />}
                />
              )}
            </>
          ) : (
            <Bar
              name={product}
              dataKey={`${product}.amount`}
              key={product}
              stackId="a"
              fill={colors[index % colors.length]}
              shape={<CustomBar />}
            />
          )
        )}
      </REBarChart>
    </ResponsiveContainer>
  );
};

const dataItem = PropTypes.shape({
  time: PropTypes.string,
  [PropTypes.string]: PropTypes.shape({
    amount: PropTypes.number,
    price: PropTypes.number,
  }),
});

export const chartDataType = {
  data: PropTypes.arrayOf(dataItem),
  products: PropTypes.arrayOf(PropTypes.string),
  productMap: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
};

BarChart.propTypes = {
  chartData: PropTypes.shape(chartDataType),
  selectedProduct: PropTypes.string,
  hidePrice: PropTypes.bool,
  loading: PropTypes.bool,
  currencyCode: PropTypes.string.isRequired,
};

BarChart.defaultProps = {
  chartData: {
    data: [],
    products: [],
    productMap: {},
  },
  selectedProduct: 'all',
  hidePrice: false,
  loading: false,
};

CustomBar.propTypes = {
  fill: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]).isRequired,
};

CustomTooltip.propTypes = {
  active: PropTypes.bool.isRequired,
  productMap: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }).isRequired,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.shape({
        _rgb: PropTypes.arrayOf(PropTypes.number),
      }),
      payload: dataItem,
    })
  ).isRequired,
  hidePrice: PropTypes.bool.isRequired,
  currencyCode: PropTypes.string.isRequired,
};

export default BarChart;
