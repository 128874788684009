import { parse, isAfter, isValid as validDate } from 'date-fns';

const isValidCNH = (data, dataIndex, translator) => {
  if (!data) return {};

  const parsedDate = parse(data[dataIndex], 'dd/MM/yyyy', new Date());
  const isValidDate = validDate(parsedDate);

  if (!data[dataIndex] || !isValidDate) {
    return {
      translatedStatus: translator.NULO,
    };
  }

  const isValid = isAfter(parsedDate, new Date());

  return {
    translatedStatus: translator[isValid],
  };
};

export default isValidCNH;
