/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import { renderIncomeEstimate } from 'utils/financeFormatters';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import EmptyState from 'components/EmptyState';
import TooltipInfo from 'components/TooltipInfo';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import './styles.less';

const PersonalFinance = ({ profile /* urlId, toPrint */ }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatCurrency } = useI18nFormatters();

  const incomeEstimates = useMemo(() => profile?.finance?.incomeEstimates, [profile]);
  const totalAssets = useMemo(() => profile?.finance?.totalAssets, [profile]);

  const isProcessingIncomeEstimates = useMemo(
    () => verifySourcesProcessing(profile, 'incomeEstimates'),
    [profile]
  );
  const isProcessingTotalAssets = useMemo(
    () => verifySourcesProcessing(profile, 'totalAssets'),
    [profile]
  );

  return (
    <>
      <Row id="personal-finance-container">
        <ColumnLabel
          span={12}
          title={translate(
            'pages.private.profiles.components.dataSourceCards.financialInformation.people.components.personalFinance.index.personalFinance.columnLabel.incomeEstimates.title'
          )}
        >
          {incomeEstimates?.ibope && (
            <div className="flex start-center">
              <span className="no-mrg">
                {renderIncomeEstimate(incomeEstimates?.ibope?.income, translate)} (IBOPE){' '}
              </span>
              <div className="flex start-center">
                {incomeEstimates?.ibope?.profession && (
                  <TooltipInfo
                    title={translate(
                      'pages.private.profiles.components.dataSourceCards.financialInformation.people.components.personalFinance.index.personalFinance.tooltipInfo.title',
                      { value: incomeEstimates?.ibope?.profession }
                    )}
                    size={14}
                  />
                )}
              </div>
            </div>
          )}
          {incomeEstimates?.spc && (
            <span className="no-mrg">
              {i18nFormatCurrency(incomeEstimates?.spc?.amount, 'pt-BR')} (SPC)
            </span>
          )}
          {incomeEstimates?.ibge && (
            <span className="no-mrg">
              {renderIncomeEstimate(incomeEstimates?.ibge, translate)} (IBGE)
            </span>
          )}
          {incomeEstimates?.mte && (
            <span className="no-mrg">
              {renderIncomeEstimate(incomeEstimates?.mte, translate)} (MTE)
            </span>
          )}

          {!incomeEstimates && isProcessingIncomeEstimates && (
            <EmptyState type="processing" />
          )}

          {!incomeEstimates && !isProcessingIncomeEstimates && (
            <EmptyState type="waiting_consult" />
          )}
        </ColumnLabel>

        <ColumnLabel
          span={12}
          title={translate(
            'pages.private.profiles.components.dataSourceCards.financialInformation.people.components.personalFinance.index.personalFinance.columnLabel.totalAssets.title'
          )}
          customClassName="text-end"
        >
          {totalAssets && (
            <span className="no-mrg">
              {renderIncomeEstimate(totalAssets?.amount, translate)}
            </span>
          )}

          {!totalAssets && isProcessingTotalAssets && <EmptyState type="processing" />}

          {!totalAssets && !isProcessingTotalAssets && (
            <EmptyState type="waiting_consult" />
          )}
        </ColumnLabel>
      </Row>
    </>
  );
};

export default PersonalFinance;
