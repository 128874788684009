import React, { useState, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Modal,
  Avatar,
  Input,
  Form,
  Row,
  Col,
  Button,
  Upload,
  message,
  Spin,
} from 'antd';

import ProfileDefault from 'assets/images/profile-default.png';

import LanguageSelector from 'components/I18n/LanguageSelector';
import PageTitle from 'components/PageTitle';
import ChangePassword from 'components/ModalChangePassword';

import { useAuth } from 'hooks/auth';

import { useFetch } from 'services/hooks';

import './styles.less';

import Cookies from 'js-cookie';
import handleHubSelection from 'utils/handleHubSelection';

const ACCEPT_FILES = ['image/jpeg', 'image/jpg', 'image/png'];

const isLocalhost = !!window.location.origin.startsWith('http://localhost');
const isCafIo = window.location.origin.indexOf('caf.io') !== -1;

const LANGUAGE = isLocalhost ? 'CAF_language' : '__Secure-CAF_language';

// eslint-disable-next-line no-nested-ternary
const COOKIE_OPTIONS = isLocalhost
  ? {
      path: '/',
    }
  : isCafIo
  ? {
      domain: '.caf.io',
      secure: true,
      sameSite: 'strict',
    }
  : {
      domain: '.combateafraude.com',
      secure: true,
      sameSite: 'strict',
    };

const ModalUpdateProfile = () => {
  const { i18n, t: translate } = useTranslation();
  const { openModal: openChangePasswordModal, ChangePasswordModal } = ChangePassword();

  const [modalVisible, setModalVisible] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [file, setFile] = useState(null);

  const firstLoad = useRef(true);

  const [form] = Form.useForm();

  const { put } = useFetch();

  const { user, getLoggedUser } = useAuth();

  const closeModal = useCallback(() => {
    setFile(null);
    setModalVisible(false);
    firstLoad.current = true;
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const handleUpdate = useCallback(
    async (payload) => {
      setLoadingData(true);
      try {
        await put({
          url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/users/${user.id}`,
          payload: {
            name: payload?.name,
            language: payload?.language,
          },
        });
        message.success(
          translate('components.modalUpdateProfile.handleUpdate.messages.success')
        );
        closeModal();

        if (payload?.language !== Cookies.get(LANGUAGE)) {
          Cookies.set(LANGUAGE, payload?.language, COOKIE_OPTIONS);
          window.location.reload();
        }
        getLoggedUser();
      } catch {
        message.error(
          translate('components.modalUpdateProfile.handleUpdate.messages.error')
        );
      }
      setLoadingData(false);
    },
    [closeModal, put, user, translate, getLoggedUser]
  );

  const toBase64 = (f) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadImageOnDrop = useCallback(
    async (f, fType) => {
      if (f) {
        setLoadingImage(true);
        try {
          const base64Img = f.split(';base64,', 2);
          await put({
            url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/users/${user.id}`,
            payload: {
              image: { mime: fType, file: base64Img[1] },
            },
          });
          message.success(
            translate('components.modalUpdateProfile.uploadImageOnDrop.messages.success')
          );
          getLoggedUser();
        } catch {
          message.error(
            translate('components.modalUpdateProfile.uploadImageOnDrop.messages.error')
          );
        }
        setLoadingImage(false);
      }
    },
    [getLoggedUser, put, user, translate]
  );

  const UpdateProfileModal = useMemo(
    () => (
      <Modal
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        visible={modalVisible}
        footer={null}
        width={700}
        onCancel={closeModal}
      >
        {ChangePasswordModal}

        <div id="modal-profile-update">
          <div>
            <PageTitle
              title={translate(
                'components.modalUpdateProfile.updateProfileModal.pageTitle.title'
              )}
            />
          </div>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleUpdate}
            initialValues={{ name: user.name, language: i18n.language }}
          >
            <Row className="mrg-vertical-45">
              <Col span={8} className="col-avatar">
                <Upload
                  showUploadList={false}
                  onRemove={() => {
                    setFile(null);
                  }}
                  beforeUpload={async (f) => {
                    if (f?.size / 1024 / 1024 > 2) {
                      message.error(
                        translate(
                          'components.modalUpdateProfile.updateProfileModal.upload.messages.veryLargeFile'
                        )
                      );
                      return false;
                    }

                    if (!ACCEPT_FILES.includes(f?.type)) {
                      message.error(
                        translate(
                          'components.modalUpdateProfile.updateProfileModal.upload.messages.fileTypeNotAllowed'
                        )
                      );
                      return false;
                    }

                    const result = await toBase64(f).catch((e) => Error(e));
                    setFile(result);
                    uploadImageOnDrop(result, f?.type);
                    return false;
                  }}
                  accept=".png, .jpg, .jpeg"
                >
                  <div className="update-profile-pointer">
                    <Avatar
                      src={file || user?.profileImageUrl || ProfileDefault}
                      size={120}
                      style={{ opacity: loadingImage ? 0.2 : 1 }}
                      className={`${
                        !file && !user?.profileImageUrl ? 'grayscale-95' : ''
                      } ${loadingImage && 'loading-opacity'}`}
                    />
                    {!loadingImage && <i className="caf-ic_camera gtx-text-gray" />}
                    {loadingImage && <Spin className="loading-position" />}
                  </div>
                </Upload>
                <div className="flex font-size-11 mrg-top-20 center">
                  <span>
                    {translate(
                      'components.modalUpdateProfile.updateProfileModal.maximumSize'
                    )}
                  </span>
                </div>
              </Col>
              <Col span={16}>
                <Form.Item
                  label={translate(
                    'components.modalUpdateProfile.updateProfileModal.form.name.label'
                  )}
                  name="name"
                  normalize={(value) => value?.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ\s]/g, '') ?? ''}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input defaultValue={user?.name} />
                </Form.Item>

                <Form.Item
                  label={translate(
                    'components.modalUpdateProfile.updateProfileModal.form.email.label'
                  )}
                >
                  <Input disabled value={user?.username} />
                </Form.Item>

                <LanguageSelector />

                <Button type="primary" onClick={openChangePasswordModal}>
                  {translate(
                    'components.modalUpdateProfile.updateProfileModal.form.button.changePassword.label'
                  )}
                </Button>
              </Col>
            </Row>
            <div className="flex update-profile-buttons end-center">
              <div>
                <Button type="default" onClick={closeModal} className="btn-cancel">
                  {translate(
                    'components.modalUpdateProfile.updateProfileModal.form.button.cancel'
                  )}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loadingData}
                  disabled={loadingData}
                >
                  {translate(
                    'components.modalUpdateProfile.updateProfileModal.form.button.save'
                  )}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      handleUpdate,
      user,
      file,
      loadingImage,
      form,
      loadingData,
      uploadImageOnDrop,
      openChangePasswordModal,
      ChangePasswordModal,
      translate,
      i18n,
    ]
  );

  return { openModal, UpdateProfileModal };
};

export default ModalUpdateProfile;
