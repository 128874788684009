import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import './styles.less';

const CircleIcon = ({ icon, image, danger, warning }) => {
  const circleClasses = useMemo(() => {
    if (danger) return 'circle-icon danger';
    if (warning) return 'circle-icon warning';

    return 'circle-icon primary';
  }, [danger, warning]);

  return (
    <div className={circleClasses}>
      {image ? <img src={icon} alt="" /> : <i className={icon} />}
    </div>
  );
};

CircleIcon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  image: PropTypes.string,
  danger: PropTypes.bool,
  warning: PropTypes.bool,
};

CircleIcon.defaultProps = {
  image: undefined,
  danger: false,
  warning: false,
};

export default CircleIcon;
