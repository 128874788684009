import React from 'react';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import './styles.less';

const AlertNewness = ({ card = false }) => {
  const { t } = useTranslation();
  return (
    <Alert
      message={t('pages.private.KYBCompany.pages.companyDetails.alert.title')}
      description={t('pages.private.KYBCompany.pages.companyDetails.alert.description')}
      className="alert-newness"
      type="info"
      closable
    />
  );
};
export default AlertNewness;
