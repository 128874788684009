import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useQueryParams, StringParam } from 'use-query-params';
import { Select, Button } from 'antd';

import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/auth';

import { useHeaderFilter } from 'hooks/headerFilter';
import { useFetch } from 'services/hooks';
import _ from 'lodash';

import { CardSkeleton, CardTooltip } from 'components/CardSkeleton';

import { Add } from '@combateafraude/icons/general';

import ModalIproovNotification from 'components/ModalIproovNotification';
import PieChart from './components/Charts/Pie';
import BarChart from './components/Charts/Bar';
import LineChart from './components/Charts/Line';
import SlaDATA from './components/Charts/SLAdata';
// import FunnelChart from './components/Charts/Funnel';
import MapChart from './components/Charts/Map';
import FraudData from './components/Charts/FraudData';
import PercentageData from './components/Charts/PercentageData';

import Wrapper from '../wrapper';

import {
  INDIVIDUAL_ONBOARDING_LINK,
  SHARED_ONBOARDING_LINK,
  AUTOMATIC_DISAPPROVAL,
  MANUAL_DISAPPROVAL,
} from './constants';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.dashboard.index';

const Dashboard = () => {
  const { t: translate } = useTranslation();
  const {
    startDate,
    endDate,
    previousStartDate,
    previousEndDate,
    pfdPrintRef,
  } = useHeaderFilter();

  // const [onboardingLinkType, setOnboardingLinkType] = useState();
  const [reprovedQueryType, setReprovedQueryType] = useState();
  // const [funnelFlows, setFunnelFlows] = useState([]);
  const [query, setQuery] = useQueryParams({
    funnelTemplateId: StringParam,
    activeFunnelFlow: StringParam,
  });
  const { search } = useLocation();

  const { user } = useAuth();

  const {
    get: getDocumentAnalysis,
    data: documentAnalysis,
    loading: documentAnalysisLoading,
  } = useFetch();

  const {
    get: getAvoidedFrauds,
    data: avoidedFrauds,
    loading: avoidedFraudsLoading,
  } = useFetch();

  // const {
  //   get: getOnboardingFunnel,
  //   data: onboardingFunnel,
  //   loading: onboardingFunnelLoading,
  // } = useFetch();

  // const {
  //   get: getCurrentIndividualOnboardingLinks,
  //   data: currentIndividualOnboardingLinks,
  //   loading: currentIndividualOnboardingLinksLoading,
  // } = useFetch();

  // const {
  //   get: getCurrentSharedOnboardingLinks,
  //   data: currentSharedOnboardingLinks,
  //   loading: currentSharedOnboardingLinksLoading,
  // } = useFetch();

  // const {
  //   get: getPreviousIndividualOnboardingLinks,
  //   data: previousIndividualOnboardingLinks,
  //   loading: previousIndividualOnboardingLinksLoading,
  //   clearData: clearPreviousIndividualOnboardingLinks,
  // } = useFetch();

  // const {
  //   get: getPreviousSharedOnboardingLinks,
  //   data: previousSharedOnboardingLinks,
  //   loading: previousSharedOnboardingLinksLoading,
  //   clearData: clearPreviousSharedOnboardingLinks,
  // } = useFetch();

  const {
    get: getOnboardingLocation,
    data: onboardingLocation,
    loading: onboardingLocationLoading,
  } = useFetch();

  const {
    get: getManualReprovedQueries,
    data: manualReprovedQueries,
    loading: manualReprovedQueriesLoading,
  } = useFetch();

  const {
    get: getAutomaticReprovedQueries,
    data: automaticReprovedQueries,
    loading: automaticReprovedQueriesLoading,
  } = useFetch();

  const {
    get: getQueryStatus,
    data: queryStatusData,
    loading: queryStatusLoading,
  } = useFetch();

  const {
    get: getStatusProfile,
    data: statusProfile,
    loading: statusProfileLoading,
  } = useFetch();

  const { get: getTemplates, data: templates, loading: templatesLoading } = useFetch();
  const {
    get: getTemplateById,
    data: templateById,
    loading: templateByIdLoading,
  } = useFetch();

  // código para verificar se a conta utiliza o onboarding
  // se o cliente não utiliza, quando criar seu primeiro link a flag será ativada, exibindo os gráficos referente à feature

  const { get: getOnboardingLinks } = useFetch();
  const { patch: patchOnboardingGraph } = useFetch();

  const verifyOnboardingGraphFeature = useCallback(async () => {
    const links = await getOnboardingLinks({
      url: '/onboardings',
      showMessage: false,
    });
    if (links?.docs?.length > 0) {
      await patchOnboardingGraph({
        url: '/feature-flags/showOnboardingGraph',
        showMessage: false,
        payload: {
          active: true,
        },
      });
    } else {
      await patchOnboardingGraph({
        url: '/feature-flags/showOnboardingGraph',
        showMessage: false,
        payload: {
          active: false,
        },
      });
    }
  }, [getOnboardingLinks, patchOnboardingGraph]);

  // código para verificar se a conta utiliza o onboarding ^

  const fetchDocumentAnalysis = useCallback(async () => {
    if (startDate && endDate)
      await getDocumentAnalysis({
        url: '/analytics/document-analysis',
        config: {
          params: {
            _startCreatedDate: startDate,
            _endCreatedDate: endDate,
          },
        },
        showMessage: false,
      });
  }, [getDocumentAnalysis, startDate, endDate]);

  const fetchAvoidedFrauds = useCallback(async () => {
    if (startDate && endDate)
      await getAvoidedFrauds({
        url: '/analytics/frauds-avoided',
        config: {
          params: {
            _startCreatedDate: startDate,
            _endCreatedDate: endDate,
          },
        },
        showMessage: false,
      });
  }, [getAvoidedFrauds, startDate, endDate]);

  // const fetchOnboardingFunnel = useCallback(async () => {
  //   if (query.funnelTemplateId && startDate && endDate)
  //     await getOnboardingFunnel({
  //       url: '/analytics/onboarding-funnel',
  //       config: {
  //         params: {
  //           _startCreatedDate: startDate,
  //           _endCreatedDate: endDate,
  //           profileTemplateId: query.funnelTemplateId,
  //         },
  //       },
  //       showMessage: false,
  //     });
  // }, [getOnboardingFunnel, startDate, endDate, query]);

  // const fetchCurrentIndividualOnboardingLinks = useCallback(async () => {
  //   await getCurrentIndividualOnboardingLinks({
  //     url: '/analytics/onboarding-links',
  //     config: {
  //       params: {
  //         _startCreatedDate: startDate,
  //         _endCreatedDate: endDate,
  //         isSharedLink: false,
  //       },
  //     },
  //     showMessage: false,
  //   });
  // }, [getCurrentIndividualOnboardingLinks, startDate, endDate]);

  // const fetchCurrentSharedOnboardingLinks = useCallback(async () => {
  //   await getCurrentSharedOnboardingLinks({
  //     url: '/analytics/onboarding-links',
  //     config: {
  //       params: {
  //         _startCreatedDate: startDate,
  //         _endCreatedDate: endDate,
  //         isSharedLink: true,
  //       },
  //     },
  //     showMessage: false,
  //   });
  // }, [getCurrentSharedOnboardingLinks, startDate, endDate]);

  // const fetchPreviousIndividualOnboardingLinks = useCallback(async () => {
  //   if (previousStartDate && previousEndDate)
  //     await getPreviousIndividualOnboardingLinks({
  //       url: '/analytics/onboarding-links',
  //       config: {
  //         params: {
  //           _startCreatedDate: previousStartDate,
  //           _endCreatedDate: previousEndDate,
  //           isSharedLink: false,
  //         },
  //       },
  //       showMessage: false,
  //     });
  //   else clearPreviousIndividualOnboardingLinks();
  // }, [
  //   previousStartDate,
  //   previousEndDate,
  //   getPreviousIndividualOnboardingLinks,
  //   clearPreviousIndividualOnboardingLinks,
  // ]);

  // const fetchPreviousSharedOnboardingLinks = useCallback(async () => {
  //   if (previousStartDate && previousEndDate)
  //     await getPreviousSharedOnboardingLinks({
  //       url: '/analytics/onboarding-links',
  //       config: {
  //         params: {
  //           _startCreatedDate: previousStartDate,
  //           _endCreatedDate: previousEndDate,
  //           isSharedLink: true,
  //         },
  //       },
  //       showMessage: false,
  //     });
  //   else clearPreviousSharedOnboardingLinks();
  // }, [
  //   previousStartDate,
  //   previousEndDate,
  //   getPreviousSharedOnboardingLinks,
  //   clearPreviousSharedOnboardingLinks,
  // ]);

  const fetchOnboardingLocation = useCallback(async () => {
    if (startDate && endDate)
      await getOnboardingLocation({
        url: '/analytics/onboarding-location',
        config: {
          params: {
            _startCreatedDate: startDate,
            _endCreatedDate: endDate,
          },
        },
        showMessage: false,
      });
  }, [getOnboardingLocation, startDate, endDate]);

  const fetchManualReprovedQueries = useCallback(async () => {
    if (startDate && endDate)
      await getManualReprovedQueries({
        url: '/analytics/queries-reproved',
        config: {
          params: {
            type: 'manual',
            _startCreatedDate: startDate,
            _endCreatedDate: endDate,
          },
        },
        showMessage: false,
      });
  }, [getManualReprovedQueries, startDate, endDate]);

  const fetchAutomaticReprovedQueries = useCallback(async () => {
    if (startDate && endDate)
      await getAutomaticReprovedQueries({
        url: '/analytics/queries-reproved',
        config: {
          params: {
            type: 'automatic',
            _startCreatedDate: startDate,
            _endCreatedDate: endDate,
          },
        },
        showMessage: false,
      });
  }, [getAutomaticReprovedQueries, startDate, endDate]);

  const queryStatus = useMemo(() => {
    return {
      approved: queryStatusData?.APROVADO ?? 0,
      reproved: queryStatusData?.REPROVADO ?? 0,
      processing: queryStatusData?.PROCESSANDO ?? 0,
      pending:
        (queryStatusData?.PENDENTE ?? 0) + (queryStatusData?.['PENDENTE OCR'] ?? 0),
    };
  }, [queryStatusData]);

  const fetchQueryStatus = useCallback(async () => {
    if (startDate && endDate)
      await getQueryStatus({
        url: '/metrics/count_executions_by_status',
        config: {
          params: {
            _startCreatedDate: startDate,
            _endCreatedDate: endDate,
          },
        },
        showMessage: false,
      });
  }, [getQueryStatus, startDate, endDate]);

  const fetchStatusProfile = useCallback(async () => {
    if (startDate && endDate)
      await getStatusProfile({
        url: '/metrics/count_by_status',
        config: {
          params: {
            _startCreatedDate: startDate,
            _endCreatedDate: endDate,
          },
        },
        showMessage: false,
      });
  }, [getStatusProfile, startDate, endDate]);

  const fetchTemplateById = useCallback(async () => {
    if (query.funnelTemplateId) {
      await getTemplateById({
        url: `/profile-templates/${query.funnelTemplateId}`,
        showMessage: false,
      });
    }
  }, [getTemplateById, query.funnelTemplateId]);

  const fetchTemplates = useCallback(
    async (value) => {
      getTemplates({
        url: '/profile-templates',
        config: {
          params: {
            _search: value ? value.trim() : undefined,
          },
        },
        showMessage: false,
      });
    },
    [getTemplates]
  );

  useEffect(fetchDocumentAnalysis, [fetchDocumentAnalysis]);
  useEffect(fetchAvoidedFrauds, [fetchAvoidedFrauds]);
  // useEffect(fetchCurrentIndividualOnboardingLinks, [
  //   fetchCurrentIndividualOnboardingLinks,
  // ]);
  // useEffect(fetchCurrentSharedOnboardingLinks, [fetchCurrentSharedOnboardingLinks]);
  // useEffect(fetchPreviousIndividualOnboardingLinks, [
  //   fetchPreviousIndividualOnboardingLinks,
  // ]);
  // useEffect(fetchPreviousSharedOnboardingLinks, [fetchPreviousSharedOnboardingLinks]);
  useEffect(fetchOnboardingLocation, [fetchOnboardingLocation]);
  useEffect(fetchManualReprovedQueries, [fetchManualReprovedQueries]);
  useEffect(fetchAutomaticReprovedQueries, [fetchAutomaticReprovedQueries]);
  useEffect(fetchQueryStatus, [fetchQueryStatus]);
  useEffect(fetchStatusProfile, [fetchStatusProfile]);
  // useEffect(fetchOnboardingFunnel, [fetchOnboardingFunnel]);
  useEffect(fetchTemplateById, [fetchTemplateById]);
  useEffect(fetchTemplates, [fetchTemplates]);

  useEffect(() => {
    if (user?.accountData?.featureFlags?.showOnboardingGraph === undefined) {
      verifyOnboardingGraphFeature();
    }
  }, [user, verifyOnboardingGraphFeature]);

  const normalizeQueryStatus = (status) =>
    Object.keys(status ?? [])
      .filter((key) => key !== 'requestId')
      .map((key) => ({
        value: status[key],
        type: key,
      }));

  const normalizeReprovedQueries = useCallback(
    (queries) =>
      Object.keys(queries ?? [])
        .filter((key) => key !== 'requestId')
        .map((key) => ({
          value: queries[key].quantity,
          type: key,
        })),
    []
  );

  const normalizedOnboardingLocation = useMemo(
    () =>
      onboardingLocation
        ? Object.keys(onboardingLocation)
            .filter((key) => key !== 'requestId')
            .map((key) => ({
              value: onboardingLocation[key].quantity,
              id: key,
            }))
        : [],
    [onboardingLocation]
  );

  // const normalizeOnboardingLinks = useCallback((current, previous) => {
  //   if (!current?.onboardingsInfo && !previous?.onboardingsInfo) {
  //     return {
  //       links: [],
  //       total: {
  //         generated: 0,
  //         accessed: 0,
  //         finalized: 0,
  //         abandoned: 0,
  //       },
  //     };
  //   }

  //   let normalizedOnboardingLinksMap = {};

  //   previous?.onboardingsInfo?.forEach(({ _id, finalized }) => {
  //     const key = _id;

  //     normalizedOnboardingLinksMap = {
  //       ...normalizedOnboardingLinksMap,
  //       [key]: {
  //         ...normalizedOnboardingLinksMap?.[key],
  //         previous: finalized,
  //       },
  //     };
  //   });

  //   current?.onboardingsInfo?.forEach(({ _id, finalized }) => {
  //     const key = _id;

  //     normalizedOnboardingLinksMap = {
  //       ...normalizedOnboardingLinksMap,
  //       [key]: {
  //         ...normalizedOnboardingLinksMap?.[key],
  //         current: finalized,
  //       },
  //     };
  //   });

  //   return {
  //     links: Object.keys(normalizedOnboardingLinksMap).map((key) => ({
  //       time: key,
  //       current: normalizedOnboardingLinksMap[key]?.current ?? 0,
  //       previous: normalizedOnboardingLinksMap[key]?.previous ?? 0,
  //     })),
  //     total: {
  //       generated: current?.total.generated ?? 0,
  //       accessed: current?.total.accessed ?? 0,
  //       finalized: current?.total.finalized ?? 0,
  //       abandoned: current?.total.abandoned ?? 0,
  //     },
  //   };
  // }, []);

  const normalizedAutomaticReprovedQueries = useMemo(
    () => normalizeReprovedQueries(automaticReprovedQueries),
    [automaticReprovedQueries, normalizeReprovedQueries]
  );

  const normalizedManualReprovedQueries = useMemo(
    () => normalizeReprovedQueries(manualReprovedQueries),
    [manualReprovedQueries, normalizeReprovedQueries]
  );

  // const normalizedIndividualOnboardingLinks = useMemo(
  //   () =>
  //     normalizeOnboardingLinks(
  //       currentIndividualOnboardingLinks,
  //       previousIndividualOnboardingLinks
  //     ),
  //   [
  //     currentIndividualOnboardingLinks,
  //     normalizeOnboardingLinks,
  //     previousIndividualOnboardingLinks,
  //   ]
  // );

  // const normalizedSharedOnboardingLinks = useMemo(
  //   () =>
  //     normalizeOnboardingLinks(
  //       currentSharedOnboardingLinks,
  //       previousSharedOnboardingLinks
  //     ),
  //   [
  //     currentSharedOnboardingLinks,
  //     normalizeOnboardingLinks,
  //     previousSharedOnboardingLinks,
  //   ]
  // );

  const currentReprovedQueries = useMemo(() => {
    if (reprovedQueryType === AUTOMATIC_DISAPPROVAL) {
      return normalizedAutomaticReprovedQueries.map(({ type, value }) => ({
        type: translate(`validations.${type}.status.INVALID`),
        value,
      }));
    }
    if (reprovedQueryType === MANUAL_DISAPPROVAL) {
      return normalizedManualReprovedQueries;
    }
    return [];
  }, [
    normalizedAutomaticReprovedQueries,
    normalizedManualReprovedQueries,
    reprovedQueryType,
    translate,
  ]);

  // const currentOnboardingLinks = useMemo(() => {
  //   if (onboardingLinkType === INDIVIDUAL_ONBOARDING_LINK) {
  //     return normalizedIndividualOnboardingLinks;
  //   }
  //   if (onboardingLinkType === SHARED_ONBOARDING_LINK) {
  //     return normalizedSharedOnboardingLinks;
  //   }

  //   return {
  //     links: [],
  //     total: {
  //       generated: 0,
  //       accessed: 0,
  //       finalized: 0,
  //       abandoned: 0,
  //     },
  //   };
  // }, [
  //   normalizedIndividualOnboardingLinks,
  //   normalizedSharedOnboardingLinks,
  //   onboardingLinkType,
  // ]);

  useEffect(() => {
    if (
      normalizedAutomaticReprovedQueries.length > 0 &&
      normalizedManualReprovedQueries.length > 0
    ) {
      setReprovedQueryType(AUTOMATIC_DISAPPROVAL);
    } else if (normalizedAutomaticReprovedQueries.length > 0) {
      setReprovedQueryType(AUTOMATIC_DISAPPROVAL);
    } else if (normalizedManualReprovedQueries.length > 0) {
      setReprovedQueryType(MANUAL_DISAPPROVAL);
    } else {
      setReprovedQueryType(undefined);
    }
  }, [normalizedAutomaticReprovedQueries, normalizedManualReprovedQueries]);

  // useEffect(() => {
  //   if (
  //     normalizedIndividualOnboardingLinks.links.length > 0 &&
  //     normalizedSharedOnboardingLinks.links.length > 0
  //   ) {
  //     setOnboardingLinkType(INDIVIDUAL_ONBOARDING_LINK);
  //   } else if (normalizedIndividualOnboardingLinks.links.length > 0) {
  //     setOnboardingLinkType(INDIVIDUAL_ONBOARDING_LINK);
  //   } else if (normalizedSharedOnboardingLinks.links.length > 0) {
  //     setOnboardingLinkType(SHARED_ONBOARDING_LINK);
  //   } else {
  //     setOnboardingLinkType(undefined);
  //   }
  // }, [normalizedIndividualOnboardingLinks, normalizedSharedOnboardingLinks]);

  // const normalizedOnboardingFunnel = useMemo(() => {
  //   // if (!onboardingFunnel) return [];

  //   const funnelFlowKey = query.activeFunnelFlow ?? 'flowDefault';

  //   const funnelFlow = onboardingFunnel[funnelFlowKey];

  //   if (!funnelFlow) return [];

  //   return Object.keys(funnelFlow)
  //     .sort(
  //       (keyA, keyB) =>
  //         onboardingFunnel[funnelFlowKey][keyA].order -
  //         onboardingFunnel[funnelFlowKey][keyB].order
  //     )
  //     .map((key) => ({
  //       value: onboardingFunnel[funnelFlowKey][key].quantity,
  //       step: onboardingFunnel[funnelFlowKey][key].step,
  //     }));
  // }, [onboardingFunnel, query.activeFunnelFlow]);

  const handleSearchChange = useCallback(_.debounce(fetchTemplates, 500), [getTemplates]);

  // useEffect(() => {
  //   if (onboardingFunnel) {
  //     const flows = Object.keys(onboardingFunnel).filter((key) => key !== 'requestId');

  //     setFunnelFlows(flows);

  //     if (flows.length === 1) {
  //       setQuery({
  //         activeFunnelFlow: undefined,
  //       });
  //     } else if (!query.activeFunnelFlow)
  //       setQuery({
  //         activeFunnelFlow: flows[0],
  //       });
  //   }
  // }, [onboardingFunnel, setQuery, query]);

  // const funnelSearchOptions = useMemo(() => {
  //   if (templates?.docs.length > 0) {
  //     return templates.docs.map((doc) => ({
  //       label: doc.name,
  //       value: doc._id,
  //     }));
  //   }

  //   if (templateById) {
  //     return [
  //       {
  //         label: templateById.name,
  //         value: templateById._id,
  //       },
  //     ];
  //   }

  //   return [];
  // }, [templates, templateById]);

  return (
    <Wrapper>
      {/* // ! modal temporário para notificação IPROOV */}
      <ModalIproovNotification />
      <div style={{ width: '100%' }} ref={pfdPrintRef}>
        <div className="dashboard-rows">
          <CardSkeleton
            size="smallSize"
            title={translate(`${I18N_BASE_PATH}.charts.avoidedFrauds.title`)}
            loading={avoidedFraudsLoading}
            tooltipMessage={translate(
              `${I18N_BASE_PATH}.charts.avoidedFrauds.tooltipMessage`
            )}
          >
            <FraudData
              data={{
                total: avoidedFrauds?.quantity ?? 0,
              }}
            />
          </CardSkeleton>
          <CardSkeleton
            size="smallSize"
            title={translate(`${I18N_BASE_PATH}.charts.percentageCard.title`)}
            loading={queryStatusLoading}
          >
            <PercentageData data={queryStatus} />
          </CardSkeleton>
          <CardSkeleton
            size="smallSize"
            title={translate(`${I18N_BASE_PATH}.charts.slaData.title`)}
            loading={documentAnalysisLoading}
            tooltipMessage={translate(`${I18N_BASE_PATH}.charts.slaData.tooltipMessage`)}
            tooltipPlacement="topRight"
          >
            <SlaDATA
              data={{
                total: documentAnalysis?.total ?? 0,
                in1minute: documentAnalysis?.upTo1Minute ?? 0,
                in3minutes: documentAnalysis?.upTo3Minutes ?? 0,
                in5minutes: documentAnalysis?.upTo5Minutes ?? 0,
                in10minutes: documentAnalysis?.upTo10Minutes ?? 0,
                in30minutes: documentAnalysis?.upTo30Minutes ?? 0,
                in1HourOrMore: documentAnalysis?.upTo1HourOrMore ?? 0,
              }}
            />
          </CardSkeleton>
        </div>

        {/* {user?.accountData?.featureFlags?.showOnboardingGraph && (
          <div className="dashboard-rows">
            <CardSkeleton
              size="fullSize"
              title={translate(`${I18N_BASE_PATH}.charts.onboardingFunnel.title`)}
              loading={onboardingFunnelLoading}
              headerEndAdornment={
                <div className="funnel-adornment-container">
                  <div className="funnel-template-config">
                    {funnelFlows?.length > 1 && (
                      <div>
                        {funnelFlows.map((flow) => (
                          <Button
                            type={flow === query.activeFunnelFlow ? 'primary' : 'default'}
                            size="small"
                            onClick={() => setQuery({ activeFunnelFlow: flow })}
                          >
                            {flow === 'flowDefault'
                              ? translate(
                                  `${I18N_BASE_PATH}.charts.onboardingFunnel.flowDefault`
                                )
                              : flow}
                          </Button>
                        ))}
                      </div>
                    )}
                    <div className="funnel-template-config-select">
                      <p>
                        {translate(
                          `${I18N_BASE_PATH}.charts.onboardingFunnel.queryTemplate`
                        )}
                      </p>
                      <Select
                        loading={templatesLoading || templateByIdLoading}
                        showSearch
                        placeholder={translate(
                          'pages.private.profileModels.profileModelList.index.profileModelList.form.profileModel.placeholder'
                        )}
                        onChange={(tempalteId) =>
                          setQuery({
                            funnelTemplateId: tempalteId,
                          })
                        }
                        value={query.funnelTemplateId}
                        searchValue={undefined}
                        onSearch={handleSearchChange}
                        options={funnelSearchOptions}
                        filterOption={(input, option) =>
                          (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        optionFilterProp="children"
                        style={{ minWidth: 150, marginRight: '0.8rem' }}
                        bordered={false}
                      />
                    </div>
                  </div>
                  {query.funnelTemplateId && (
                    <Link
                      className="more-info"
                      to={`/onboarding-statistics${search}`}
                      id="click_more_info_funnel"
                    >
                      <Add />
                      <p>
                        {translate(`${I18N_BASE_PATH}.charts.onboardingFunnel.moreInfo`)}
                      </p>
                    </Link>
                  )}
                </div>
              }
              noData={
                !normalizedOnboardingFunnel || normalizedOnboardingFunnel?.length === 0
              }
            >
              <FunnelChart data={normalizedOnboardingFunnel} />
            </CardSkeleton>
          </div>
        )} */}

        <div className="dashboard-rows">
          <CardSkeleton
            size="halfSize"
            title={translate(`${I18N_BASE_PATH}.charts.disapprovals.title`)}
            loading={automaticReprovedQueriesLoading || manualReprovedQueriesLoading}
            noData={
              (!normalizedAutomaticReprovedQueries && !normalizedManualReprovedQueries) ||
              (normalizedAutomaticReprovedQueries?.length === 0 &&
                normalizedManualReprovedQueries?.length === 0)
            }
            headerEndAdornment={
              <div className="buttons-adornment">
                {normalizedAutomaticReprovedQueries.length > 0 && (
                  <Button
                    type={
                      reprovedQueryType === AUTOMATIC_DISAPPROVAL ? 'primary' : 'default'
                    }
                    onClick={() => setReprovedQueryType(AUTOMATIC_DISAPPROVAL)}
                    size="small"
                  >
                    {translate(
                      `${I18N_BASE_PATH}.charts.disapprovals.automaticDisapprovals`
                    )}
                  </Button>
                )}
                {normalizedManualReprovedQueries.length > 0 && (
                  <Button
                    type={
                      reprovedQueryType === MANUAL_DISAPPROVAL ? 'primary' : 'default'
                    }
                    onClick={() => setReprovedQueryType(MANUAL_DISAPPROVAL)}
                    size="small"
                  >
                    {translate(`${I18N_BASE_PATH}.charts.disapprovals.manualDisapproval`)}
                  </Button>
                )}
              </div>
            }
          >
            <BarChart
              data={currentReprovedQueries}
              titleText={translate(`${I18N_BASE_PATH}.charts.disapprovals.title`)}
            />
          </CardSkeleton>
          <CardSkeleton
            size="smallSize"
            title={translate(`${I18N_BASE_PATH}.charts.queryStatus.title`)}
            loading={queryStatusLoading}
            noData={
              !queryStatus ||
              (queryStatus?.processing === 0 &&
                queryStatus?.approved === 0 &&
                queryStatus?.reproved === 0 &&
                queryStatus?.pending === 0)
            }
          >
            <div className="query-status-wrapper">
              <PieChart data={normalizeQueryStatus(queryStatus)} marginBottom={45} />
              <h5>
                {translate(`${I18N_BASE_PATH}.charts.queryStatus.queriesInProgress`)}
                <span>
                  {queryStatus
                    ? queryStatus.processing ?? 0 + queryStatus.pending ?? 0
                    : 0}
                </span>
              </h5>
            </div>
          </CardSkeleton>
        </div>
        <div className="dashboard-rows">
          <CardSkeleton
            size="smallSize"
            title={translate(`${I18N_BASE_PATH}.charts.clientMap.title`)}
            loading={onboardingLocationLoading}
            noData={
              !normalizedOnboardingLocation || normalizedOnboardingLocation?.length === 0
            }
          >
            <MapChart highlighted={normalizedOnboardingLocation} national />
          </CardSkeleton>
          <CardSkeleton
            size="smallSize"
            title={translate(`${I18N_BASE_PATH}.charts.pf.title`)}
            loading={statusProfileLoading}
            noData={
              !statusProfile ||
              (statusProfile?.PF?.processing === 0 &&
                statusProfile?.PF?.approved === 0 &&
                statusProfile?.PF?.reproved === 0 &&
                statusProfile?.PF?.pending === 0)
            }
          >
            <PieChart data={normalizeQueryStatus(statusProfile?.PF)} />
          </CardSkeleton>
          <CardSkeleton
            size="smallSize"
            title={translate(`${I18N_BASE_PATH}.charts.pj.title`)}
            loading={statusProfileLoading}
            noData={
              !statusProfile ||
              (statusProfile?.PJ?.processing === 0 &&
                statusProfile?.PJ?.approved === 0 &&
                statusProfile?.PJ?.reproved === 0 &&
                statusProfile?.PJ?.pending === 0)
            }
          >
            <PieChart data={normalizeQueryStatus(statusProfile?.PJ)} />
          </CardSkeleton>
        </div>
        {/* {user?.accountData?.featureFlags?.showOnboardingGraph && (
          <div className="dashboard-rows">
            <CardSkeleton
              size="fullSize"
              title={translate(`${I18N_BASE_PATH}.charts.onboardingLinks.title`)}
              loading={
                currentIndividualOnboardingLinksLoading ||
                previousIndividualOnboardingLinksLoading ||
                currentSharedOnboardingLinksLoading ||
                previousSharedOnboardingLinksLoading
              }
              noData={
                !normalizedIndividualOnboardingLinks ||
                (normalizedIndividualOnboardingLinks?.links?.length === 0 &&
                  normalizedSharedOnboardingLinks?.links?.length === 0)
              }
              headerEndAdornment={
                <div className="buttons-adornment">
                  {normalizedIndividualOnboardingLinks.links.length > 0 && (
                    <Button
                      type={
                        onboardingLinkType === INDIVIDUAL_ONBOARDING_LINK
                          ? 'primary'
                          : 'default'
                      }
                      onClick={() => setOnboardingLinkType(INDIVIDUAL_ONBOARDING_LINK)}
                      size="small"
                    >
                      {translate(
                        `${I18N_BASE_PATH}.charts.onboardingLinks.filters.individual`
                      )}
                    </Button>
                  )}
                  {normalizedSharedOnboardingLinks.links.length > 0 && (
                    <Button
                      type={
                        onboardingLinkType === SHARED_ONBOARDING_LINK
                          ? 'primary'
                          : 'default'
                      }
                      onClick={() => setOnboardingLinkType(SHARED_ONBOARDING_LINK)}
                      size="small"
                    >
                      {translate(
                        `${I18N_BASE_PATH}.charts.onboardingLinks.filters.shared`
                      )}
                    </Button>
                  )}
                  <CardTooltip
                    message={translate(
                      `${I18N_BASE_PATH}.charts.onboardingLinks.tooltipMessage`
                    )}
                  />
                </div>
              }
            >
              <div className="onboarding-links-wrapper">
                <LineChart width="88%" data={currentOnboardingLinks.links} />
                <aside>
                  {onboardingLinkType === INDIVIDUAL_ONBOARDING_LINK && (
                    <div>
                      <h5>
                        {translate(
                          `${I18N_BASE_PATH}.charts.onboardingLinks.aside.generated`
                        )}
                      </h5>
                      <span>{currentOnboardingLinks.total.generated}</span>
                    </div>
                  )}
                  <div>
                    <h5>
                      {translate(
                        `${I18N_BASE_PATH}.charts.onboardingLinks.aside.accessed`
                      )}
                    </h5>
                    <span>{currentOnboardingLinks.total.accessed}</span>
                  </div>
                  <div>
                    <h5>
                      {translate(
                        `${I18N_BASE_PATH}.charts.onboardingLinks.aside.finished`
                      )}
                    </h5>
                    <span>{currentOnboardingLinks.total.finalized}</span>
                  </div>
                  <div>
                    <h5>
                      {translate(
                        `${I18N_BASE_PATH}.charts.onboardingLinks.aside.abandoned`
                      )}
                    </h5>
                    <span>{currentOnboardingLinks.total.abandoned}</span>
                  </div>
                </aside>
              </div>
            </CardSkeleton>
          </div>
        )} */}
      </div>
    </Wrapper>
  );
};

export default Dashboard;
