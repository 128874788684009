import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Row } from 'antd';
import { useExecution } from 'hooks/execution';

import { toMaskedCnpj } from 'utils/formatters';
import { labelStatus } from 'utils/handlerStatus';

import DottedLabel from 'components/DottedLabel';

import DossierCard from 'pages/private/Profiles/components/DossierCard';
import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';

import './styles.less';

const IndependentConsultants = () => {
  const { t: translate } = useTranslation();

  const { execution } = useExecution();
  const parameters = useMemo(() => execution?.parameters, [execution]);
  const sections = useMemo(() => execution?.sections, [execution]);

  const pjData = useMemo(() => sections?.pjData?.data, [sections]);
  const pjSintegra = useMemo(() => sections?.pjSintegra?.data, [sections]);

  const pjSimplesValue = useMemo(() => {
    const pjSimples = sections?.pjSimples?.data;

    if (!pjSimples) return '-';

    if (pjSimples?.simples) {
      return translate(
        'pages.private.executions.components.dataSourceCards.independentConsultants.index.pjSimplesValue.pjSimples'
      );
    }

    return translate(
      'pages.private.executions.components.dataSourceCards.independentConsultants.index.pjSimplesValue.default'
    );
  }, [sections, translate]);

  return (
    <DossierCard
      customId="independent-consultants-component"
      title={translate(
        'pages.private.executions.components.dataSourceCards.independentConsultants.index.independentConsultants.dossierCard.title'
      )}
      subtitle={translate(
        'pages.private.executions.components.dataSourceCards.independentConsultants.index.independentConsultants.dossierCard.subtitle'
      )}
      icon="caf-ic_company"
    >
      <Row id="executions-independent-consultants-card">
        <ColumnTitleValue
          span={12}
          title={translate(
            'pages.private.executions.components.dataSourceCards.independentConsultants.index.independentConsultants.dossierCard.columnTitleValue.officialName'
          )}
          value={pjData?.officialName || '-'}
        />

        <ColumnTitleValue
          span={12}
          title={translate(
            'pages.private.executions.components.dataSourceCards.independentConsultants.index.independentConsultants.dossierCard.columnTitleValue.fantasyName'
          )}
          value={pjData?.fantasyName || '-'}
        />

        <Divider className="custom-divider" />

        <ColumnTitleValue
          span={8}
          title={translate(
            'pages.private.executions.components.dataSourceCards.independentConsultants.index.independentConsultants.dossierCard.columnTitleValue.cnpj'
          )}
          value={toMaskedCnpj(parameters?.cnpj) || '-'}
        />

        <ColumnTitleValue
          span={8}
          title={translate(
            'pages.private.executions.components.dataSourceCards.independentConsultants.index.independentConsultants.dossierCard.columnTitleValue.openingDate'
          )}
          value={pjData?.openingDate || '-'}
        />

        <ColumnTitleValue
          span={8}
          title={translate(
            'pages.private.executions.components.dataSourceCards.independentConsultants.index.independentConsultants.dossierCard.columnTitleValue.companySize.title'
          )}
          value={`${pjData?.companySize || '-'} ${
            pjData?.isMei
              ? translate(
                  'pages.private.executions.components.dataSourceCards.independentConsultants.index.independentConsultants.dossierCard.columnTitleValue.companySize.value'
                )
              : ''
          }`}
        />

        <Divider className="custom-divider" />

        <ColumnTitleValue
          span={8}
          title={translate(
            'pages.private.executions.components.dataSourceCards.independentConsultants.index.independentConsultants.dossierCard.columnTitleValue.status'
          )}
          value={
            <DottedLabel
              description={pjData?.status || '-'}
              type={labelStatus(pjData?.status).type}
            />
          }
        />

        <ColumnTitleValue
          span={8}
          title={translate(
            'pages.private.executions.components.dataSourceCards.independentConsultants.index.independentConsultants.dossierCard.columnTitleValue.pjSimplesValue'
          )}
          value={pjSimplesValue}
        />

        <ColumnTitleValue
          span={8}
          title={translate(
            'pages.private.executions.components.dataSourceCards.independentConsultants.index.independentConsultants.dossierCard.columnTitleValue.stateRegistration.title'
          )}
          value={
            pjSintegra?.stateRegistration
              ? `${translate(
                  'pages.private.executions.components.dataSourceCards.independentConsultants.index.independentConsultants.dossierCard.columnTitleValue.stateRegistration.value.hasStateRegistration'
                )} ${
                  pjSintegra?.stateRegistration ||
                  translate(
                    'pages.private.executions.components.dataSourceCards.independentConsultants.index.independentConsultants.dossierCard.columnTitleValue.stateRegistration.value.hasNotStateRegistration'
                  )
                }`
              : '-'
          }
        />
      </Row>
    </DossierCard>
  );
};

export default IndependentConsultants;
