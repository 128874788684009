import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'antd';

import DocumentIndependentConsultants from 'pages/private/Profiles/components/DataSourceCards/IndependentConsultants';
import DocumentCriminalInformation from 'pages/private/Profiles/components/DataSourceCards/CriminalInformation';
import DocumentDriverInformation from 'pages/private/Profiles/components/DataSourceCards/DriverInformation';
import DocumentRelationships from 'pages/private/Profiles/components/DataSourceCards/Relationships';
import DocumentMediaExposure from 'pages/private/Profiles/components/DataSourceCards/MediaExposure';
import RecurrenceList from 'components/RecurrenceList';
import PeopleMediaExposure from 'components/DataSourceCards/MediaExposure/People';
import CompanyMediaExposure from 'components/DataSourceCards/MediaExposure/Companies';
import BasicInfos from 'components/DataSourceCards/BasicInfos';
import ContactInfos from 'components/DataSourceCards/ContactInfos';
import LegalInformation from 'components/DataSourceCards/LegalInformation';
import RestrictiveLists from 'components/DataSourceCards/RestrictiveLists';
import PersonalFinancialInformation from 'components/DataSourceCards/FinancialInfos/People';
import BusinessFinancialInformation from 'components/DataSourceCards/FinancialInfos/Companies';
import PersonalLaborInformation from 'components/DataSourceCards/LaborInformation/People';
import BusinessLaborInformation from 'components/DataSourceCards/LaborInformation/Companies';
import CriminalInformation from 'components/DataSourceCards/CriminalInformation';
import DriverInformation from 'components/DataSourceCards/DriverInformation';

import DocumentHeader from '../DocumentHeader';
import DocumentRegistrationInfos from '../DocumentRegistrationInfos';
import DocumentTrust from '../DocumentTrust';
import DocumentQSA from '../DocumentQSA';
import DocumentAddress from '../DocumentAddress';
import DocumentContacts from '../DocumentContacts';
import DocumentIdentity from '../DocumentIdentity';
import DocumentFinancialInformation from '../DocumentFinancialInformation';
import DocumentRestrictiveLists from '../DocumentRestrictiveLists';
import DocumentLegalInformation from '../DocumentLegalInformation';
import DocumentLaborInformation from '../DocumentLaborInformation';

import './styles.less';

const ToPrint = forwardRef(
  (
    {
      data,
      checkedList,
      checkedBackgroudList,
      checkedLegacyList,
      showDetails,
      onLoadingChange,
    },
    ref
  ) => {
    const { personId, companyId } = useParams();

    const isChecked = useCallback(
      (value) => {
        const includes = checkedList.includes(value);
        return includes;
      },
      [checkedList]
    );
    const isCheckedBackgroud = useCallback(
      (value) => {
        const includes = checkedBackgroudList.includes(value);
        return includes;
      },
      [checkedBackgroudList]
    );
    const isCheckedLegacy = useCallback(
      (value) => {
        const includes = checkedLegacyList.includes(value);
        return includes;
      },
      [checkedLegacyList]
    );

    return (
      <div ref={ref} id="to-print-document">
        <Row>
          <Col span={24}>
            <DocumentHeader data={data} />
          </Col>

          <Row id="to-print-document-row" className="gx-w-100 reset-mrg">
            {isChecked('registration_infos') && (
              <Col className="to-print-document-col" span={24}>
                <DocumentRegistrationInfos data={data} />
              </Col>
            )}

            {isCheckedLegacy('trust_validations') && (
              <Col className="to-print-document-col" span={24}>
                <DocumentTrust />
              </Col>
            )}

            {isChecked('qsa') && companyId && (
              <Col className="to-print-document-col" span={24}>
                <DocumentQSA data={data} />
              </Col>
            )}

            {isChecked('address') && (
              <Col className="to-print-document-col" span={24}>
                <DocumentAddress data={data} />
              </Col>
            )}

            {isChecked('contacts') && (
              <Col className="to-print-document-col" span={24}>
                <DocumentContacts data={data} />
              </Col>
            )}

            {isChecked('identity') && (
              <Col className="to-print-document-col" span={24}>
                {personId && <DocumentIdentity />}
              </Col>
            )}

            {isChecked('independent_consultants') && (
              <Col className="to-print-document-col" span={24}>
                <DocumentIndependentConsultants data={data} />
              </Col>
            )}

            {isCheckedLegacy('criminal_information') && (
              <Col className="to-print-document-col" span={24}>
                {personId && <DocumentCriminalInformation data={data} toPrint />}
              </Col>
            )}

            {isCheckedLegacy('financial_information') && (
              <Col className="to-print-document-col" span={24}>
                <DocumentFinancialInformation data={data} showDetails={showDetails} />
              </Col>
            )}

            {isCheckedLegacy('driver_information') && (
              <Col className="to-print-document-col" span={24}>
                {personId && <DocumentDriverInformation data={data} toPrint />}
              </Col>
            )}

            {isCheckedLegacy('restrictive_lists') && (
              <Col className="to-print-document-col" span={24}>
                <DocumentRestrictiveLists data={data} showDetails={showDetails} />
              </Col>
            )}

            {isCheckedLegacy('legal_information') && (
              <Col className="to-print-document-col" span={24}>
                <DocumentLegalInformation data={data} />
              </Col>
            )}

            {isCheckedLegacy('labor_information') && (
              <Col className="to-print-document-col" span={24}>
                <DocumentLaborInformation data={data} showDetails={showDetails} />
              </Col>
            )}

            {isCheckedLegacy('relationships') && (
              <Col className="to-print-document-col" span={24}>
                {personId && <DocumentRelationships data={data} toPrint />}
              </Col>
            )}

            {isCheckedLegacy('media_exposure') && (
              <Col className="to-print-document-col" span={24}>
                {personId && <DocumentMediaExposure data={data} toPrint />}
              </Col>
            )}
            {isChecked('activities') && (
              <Col className="to-print-document-col" span={24}>
                <RecurrenceList toPrint onLoadingChange={onLoadingChange} />
              </Col>
            )}
            {isCheckedBackgroud('basic_infos') && (
              <Col className="to-print-document-col" span={24}>
                <BasicInfos toPrint />
              </Col>
            )}
            {isCheckedBackgroud('contact') && (
              <Col className="to-print-document-col" span={24}>
                <ContactInfos toPrint />
              </Col>
            )}
            {isCheckedBackgroud('justice') && (
              <Col className="to-print-document-col" span={24}>
                <LegalInformation toPrint />
              </Col>
            )}
            {isCheckedBackgroud('restrictive_lists_backgroud_checking') && (
              <Col className="to-print-document-col" span={24}>
                <RestrictiveLists toPrint toPrintDetails={showDetails} />
              </Col>
            )}
            {isCheckedBackgroud('criminal_background') && (
              <Col className="to-print-document-col" span={24}>
                <CriminalInformation toPrint />
              </Col>
            )}
            {isCheckedBackgroud('DriverInformation') && (
              <Col className="to-print-document-col" span={24}>
                <DriverInformation toPrint />
              </Col>
            )}
            {isCheckedBackgroud('media') && (
              <Col className="to-print-document-col" span={24}>
                {personId ? (
                  <PeopleMediaExposure toPrint />
                ) : (
                  <CompanyMediaExposure toPrint />
                )}
              </Col>
            )}
            {isCheckedBackgroud('finance') && (
              <Col className="to-print-document-col" span={24}>
                {personId ? (
                  <PersonalFinancialInformation toPrint />
                ) : (
                  <BusinessFinancialInformation toPrint />
                )}
              </Col>
            )}
            {isCheckedBackgroud('work') && (
              <Col className="to-print-document-col" span={24}>
                {personId ? (
                  <PersonalLaborInformation toPrint />
                ) : (
                  <BusinessLaborInformation toPrint />
                )}
              </Col>
            )}
          </Row>
        </Row>
      </div>
    );
  }
);

ToPrint.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  checkedList: PropTypes.arrayOf(PropTypes.string),
  checkedBackgroudList: PropTypes.arrayOf(PropTypes.string),
  checkedLegacyList: PropTypes.arrayOf(PropTypes.string),
  showDetails: PropTypes.bool,
  onLoadingChange: PropTypes.func,
};

ToPrint.defaultProps = {
  checkedList: [],
  checkedBackgroudList: [],
  checkedLegacyList: [],
  showDetails: false,
  onLoadingChange: null,
};

ToPrint.displayName = 'ToPrint';

export default ToPrint;
