import React from 'react';
import { Row, Spin } from 'antd';
import Loader from 'components/Loader';

const Loading = (...rest) => {
  return (
    <Row className="flex center" style={{ minHeight: '200px' }} {...rest}>
      <Spin indicator={<Loader size="40px" color="#bdbdbd" />} />
    </Row>
  );
};

export default Loading;
