/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

import defaultImage from 'assets/images/profile-default.png';

import {
  eyeAndHairTranslator,
  sanctionType,
  pepLevel,
} from '../../../utils/handlerSanctions';

import './styles.less';

const I18N_BASE_PATH =
  'pages.private.executions.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index';

const SourceInfosToPrint = ({ data, pep, others }) => {
  const { t: translate } = useTranslation();

  return (
    <div id="executions-source-infos-to-print">
      {data?.length > 0 && (
        <Row className="mrg-top-10 reset-mrg">
          {data?.map((sourceDetailItem) => (
            <Col span={24} className="information-source-data-details">
              {pep ? (
                <>
                  {others && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.others.title'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.source ||
                        translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.others.text'
                        )}
                    </span>
                  )}
                  {sourceDetailItem?.level && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemLevel.title'
                        )}{' '}
                      </strong>
                      {pepLevel(sourceDetailItem?.level, translate)?.description}
                    </span>
                  )}
                  {sourceDetailItem?.jobTitle &&
                    sourceDetailItem?.jobTitle !==
                      translate(
                        'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemJobTitle.value'
                      ) && (
                      <span>
                        <strong>
                          {translate(
                            'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemJobTitle.title'
                          )}{' '}
                        </strong>
                        {capitalizeFirstLetter(sourceDetailItem?.jobTitle)}
                      </span>
                    )}
                  {sourceDetailItem?.department && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemDepartment.title'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.department}
                    </span>
                  )}
                </>
              ) : (
                <>
                  {others && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.others.title'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.source ||
                        translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.others.text'
                        )}
                    </span>
                  )}
                  {sourceDetailItem?.matchRate && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemMatchRate.title'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.matchRate || '100'} %
                    </span>
                  )}
                  {sourceDetailItem?.type && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemType.title'
                        )}{' '}
                      </strong>
                      {translate(sanctionType(sourceDetailItem?.type))}
                    </span>
                  )}
                  {sourceDetailItem?.sanctionName && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemSanctionName.title'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.sanctionName?.toUpperCase() || '-'}
                    </span>
                  )}
                  {sourceDetailItem?.description && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemDescription.title'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.description}
                    </span>
                  )}
                  {sourceDetailItem?.nationalities && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemNationalities.title'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.nationalities}
                    </span>
                  )}
                  {sourceDetailItem?.eyes && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemEyes.title'
                        )}{' '}
                      </strong>
                      {translate(eyeAndHairTranslator(sourceDetailItem?.eyes))}
                    </span>
                  )}
                  {sourceDetailItem?.hair && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemHair.title'
                        )}{' '}
                      </strong>
                      {translate(eyeAndHairTranslator(sourceDetailItem?.hair))}
                    </span>
                  )}
                  {sourceDetailItem?.considerations && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemConsiderations.title'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.considerations}
                    </span>
                  )}
                  {sourceDetailItem?.distinguishingMarks && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemDistinguishingMarks.title'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.distinguishingMarks}
                    </span>
                  )}
                  {sourceDetailItem?.department && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemDepartment.title'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.department}
                    </span>
                  )}
                  {sourceDetailItem?.image && (
                    <div className="information-source-data-image">
                      <img
                        className={`${!sourceDetailItem?.image ? 'grayscale-95' : ''}`}
                        src={sourceDetailItem?.image || defaultImage}
                        alt={translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemImage.title'
                        )}
                      />
                    </div>
                  )}
                  <div className="mrg-left-20">
                    {sourceDetailItem?.details?.title && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.title`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.title}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.nameInformedBySanctioningBody && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.nameInformedBySanctioningBody`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.nameInformedBySanctioningBody}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.nameInOriginalScript && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.nameInOriginalScript`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.nameInOriginalScript}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.nameInSanctionList && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.nameInSanctionList`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.nameInSanctionList}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.originalName && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.originalName`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.originalName}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.sanctionName && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.sanctionName`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.sanctionName}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.processNumber && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.processNumber`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.processNumber}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.sanctioningBody && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.sanctioningBody`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.sanctioningBody}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.sanctioningBodyState && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.sanctioningBodyState`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.sanctioningBodyState}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.publicationDate && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.publicationDate`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.publicationDate}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.informationOrigin && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.informationOrigin`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.informationOrigin}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.publication && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.publication`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.publication}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.legalSubstantiation && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.legalSubstantiation`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.legalSubstantiation}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.legalSubstantiationDescription && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.legalSubstantiationDescription`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.legalSubstantiationDescription}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.actionYear && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.actionYear`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.actionYear}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.agreementNumber && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.agreementNumber`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.agreementNumber}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.amountOfWorkersInvolved && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.amountOfWorkersInvolved`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.amountOfWorkersInvolved}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.cnae && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.cnae`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.cnae}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.completeDecisionURL && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.completeDecisionURL`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.completeDecisionURL}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.decisionType && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.decisionType`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.decisionType}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.deforestedAreaHa && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.deforestedAreaHa`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.deforestedAreaHa}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.deliberationContent && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.deliberationContent`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.deliberationContent}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.designation && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.designation`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.designation}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.employer && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.employer`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.employer}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.establishment && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.establishment`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.establishment}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.finalDeadline && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.finalDeadline`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.finalDeadline}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.fineAmount && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.fineAmount`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.fineAmount}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.grantingBody && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.grantingBody`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.grantingBody}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.impedimentReason && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.impedimentReason`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.impedimentReason}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.informationOriginDate && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.informationOriginDate`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.informationOriginDate}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.infraction && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.infraction`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.infraction}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.irrecorribilityDate && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.irrecorribilityDate`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.irrecorribilityDate}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.irregularAction && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.irregularAction`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.irregularAction}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.judgementDate && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.judgementDate`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.judgementDate}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.judgementDescription && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.judgementDescription`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.judgementDescription}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.nationalIdentificationNumber && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.nationalIdentificationNumber`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.nationalIdentificationNumber}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.nationalities && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.nationalities`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.nationalities}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.nationality && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.nationality`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.nationality}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.organComplement && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.organComplement`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.organComplement}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.penalty && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.penalty`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.penalty}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.penaltyTimeToBeServed && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.penaltyTimeToBeServed`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.penaltyTimeToBeServed}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.permanentReferenceNumber && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.permanentReferenceNumber`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.permanentReferenceNumber}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.processInfoUrl && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.processInfoUrl`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.processInfoUrl}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.propertyCounty && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.propertyCounty`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.propertyCounty}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.propertyLocation && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.propertyLocation`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.propertyLocation}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.propertyUF && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.propertyUF`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.propertyUF}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.referenceDate && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.referenceDate`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.referenceDate}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.registrationDate && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.registrationDate`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.registrationDate}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.resjusdicataDate && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.resjusdicataDate`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.resjusdicataDate}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.sanctioningBodyStateCode && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.sanctioningBodyStateCode`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.sanctioningBodyStateCode}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.scopeDefinedInJudicialDecision && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.scopeDefinedInJudicialDecision`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.scopeDefinedInJudicialDecision}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.selfInfringementNumber && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.selfInfringementNumber`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.selfInfringementNumber}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.state && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.state`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.state}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.tadNumber && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.tadNumber`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.tadNumber}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.timeToBeServed && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.timeToBeServed`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.timeToBeServed}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.transitDueDate && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.transitDueDate`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.transitDueDate}
                        </span>
                      </Row>
                    )}
                    {sourceDetailItem?.details?.otherInformation && (
                      <Row>
                        <span>
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.sourceInfosModal.data.pjModal.otherInformation`
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.details?.otherInformation}
                        </span>
                      </Row>
                    )}
                  </div>
                  <hr />
                </>
              )}
            </Col>
          ))}
        </Row>
      )}

      {!data?.length > 0 && <></>}
    </div>
  );
};

export default SourceInfosToPrint;
