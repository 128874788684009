/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Divider } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import { handlerAssociateRegister } from 'utils/associateRegisters';

import PageTitle from 'components/PageTitle';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import './styles.less';

const SPCItems = ({ data, toPrint }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatCurrency, i18nFormatDate } = useI18nFormatters();

  const customTitle = useMemo(() => {
    return toPrint
      ? translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.shared.spc.spcItems.index.customTitle.hasToPrint'
        )
      : translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.shared.spc.spcItems.index.customTitle.hasNotToPrint'
        );
  }, [toPrint, translate]);

  return (
    <div id="spc-items">
      <PageTitle
        title={customTitle}
        subtitle={`${
          data?.lastUpdate?.date
            ? `${translate(
                'pages.private.profiles.components.dataSourceCards.financialInformation.shared.spc.spcItems.index.spcItems.pageTitle.subtitle.lastUpdateDate'
              )} ${i18nFormatDate(data?.lastUpdate?.date, 'default')}`
            : translate(
                'pages.private.profiles.components.dataSourceCards.financialInformation.shared.spc.spcItems.index.spcItems.pageTitle.subtitle.default'
              )
        }`}
      />

      <Row className="spc-items-title reset-mrg">
        <Col span={16}>
          <strong>
            {handlerAssociateRegister(
              data?.ocurrences?.length,
              translate(
                'pages.private.profiles.components.dataSourceCards.financialInformation.shared.spc.spcItems.index.spcItems.ocurrences'
              ),
              translate
            )}
          </strong>
        </Col>

        <Col span={8} className="end">
          <strong>
            {translate(
              'pages.private.profiles.components.dataSourceCards.financialInformation.shared.spc.spcItems.index.spcItems.totalAmount'
            )}{' '}
            {i18nFormatCurrency(data?.totalAmount, 'pt-BR') || '-'}
          </strong>
        </Col>
      </Row>

      <Divider className="custom-divider" />

      {data?.ocurrences?.length > 0 &&
        data?.ocurrences?.map((ocurrence) => (
          <Row key={ocurrence} className="spc-items-data reset-mrg">
            <ColumnLabel description={ocurrence?.name || '-'} type="danger" span={16}>
              {ocurrence?.inclusionDate && (
                <span>
                  {translate(
                    'pages.private.profiles.components.dataSourceCards.financialInformation.shared.spc.spcItems.index.spcItems.inclusionDate'
                  )}{' '}
                  {i18nFormatDate(ocurrence?.inclusionDate, 1)}
                </span>
              )}
            </ColumnLabel>
            <Col span={8} className="end bold">
              {i18nFormatCurrency(ocurrence?.value, 'pt-BR') || '-'}
            </Col>
          </Row>
        ))}
    </div>
  );
};

export default SPCItems;
