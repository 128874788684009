import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQueryParams, StringParam } from 'use-query-params';
import { Tabs } from 'antd';

import { usePerson } from 'hooks/person';
import { useCompany } from 'hooks/company';
import { useAuth } from 'hooks/auth';

import * as pfDataSources from 'utils/pfDataSource';
import * as pjDataSources from 'utils/pjDataSource';
import * as executionDataSources from 'utils/executionDataSource';

import { hasRequiredPermissions } from 'routes/permissionFunctions';

import RecurrenceList from 'components/RecurrenceList';

import ExecutionDossier from 'components/ExecutionDossier';
import CompanyDossier from './components/CompanyDossier'; // tela do trust desabilitada por hora
import PeopleDossier from './components/PeopleDossier';

import ArchivesList from './components/ArchivesList';
import BasicInfos from './components/BasicInfos';
// import QSAInfos from './components/QSAInfos'; <- tela de socios

import DocumentsValidations from './components/DocumentsValidations';

import './styles.less';

const { TabPane } = Tabs;

const TabsWrapper = () => {
  const { t: translate } = useTranslation();
  const { companyId, personId } = useParams();

  const { user } = useAuth();
  const { person } = usePerson();
  const { company } = useCompany();

  const [query, setQuery] = useQueryParams({
    tab: StringParam,
  });

  // const QSAItems = useMemo(() => company?.QSA?.items, [company]); <- tela de socios

  const hasTrustValidations = useMemo(() => {
    const { profileTemplate } = (personId ? person : company) || {};
    const dataSources =
      profileTemplate?.backgroundChecking?.services?.map(({ source }) => source) || [];
    if (personId) return pfDataSources.hasTrustInformation(person, dataSources);
    if (companyId) return pjDataSources.hasTrustInformation(company, dataSources);
    return true;
  }, [personId, person, company, companyId]);

  const hasBackgroundChecking = useMemo(() => {
    const profile = person || company;

    const dataSources =
      Object.keys(person?.sources || company?.sources || {})?.map((source) => source) ||
      [];

    return (
      executionDataSources.hasTrustInformation(profile, dataSources) ||
      executionDataSources.hasPjSintegraData(profile, dataSources)
    );
  }, [person, company]);

  const activeKey = useMemo(() => {
    if (query.tab === 'dossier') {
      if (hasTrustValidations) return 'dossier';
      return 'infos';
    }
    return query.tab;
  }, [hasTrustValidations, query.tab]);

  useEffect(() => {
    if (query.tab === 'dossier') {
      if (hasTrustValidations) {
        setQuery({ tab: 'dossier' });
        return;
      }
      setQuery({ tab: 'infos' });
    }
  }, [query]); // eslint-disable-line

  const tabNav = (icon, name) => (
    <div className="tab-item">
      <i className={`tab-item-icon caf-ic_${icon}`} /> {name}
    </div>
  );

  const permissionFilesList = useMemo(
    () =>
      hasRequiredPermissions(user, personId ? ['people:read'] : ['companies:read'], [
        'files:read',
      ]),
    [personId, user]
  );

  return (
    <section id="profile-tabs-wrapper">
      <Tabs
        defaultActiveKey={query.tab || 'infos'}
        activeKey={activeKey}
        onChange={(tab) => setQuery({ tab })}
      >
        <TabPane
          tab={tabNav(
            'documentation',
            translate('pages.private.profiles.components.tabs.index.tabs.tabPane.infos')
          )}
          key="infos"
        >
          <BasicInfos />
        </TabPane>

        {/* caso precise de tela de socios, descomentar o codigo abaixo... */}
        {/*
        { companyId && QSAItems?.length && (
          <TabPane tab={tabNav('users', 'Sócios')} key="qsa">
            <QSAInfos data={QSAItems} />
          </TabPane>
        )}
        */}

        {hasTrustValidations && (
          <TabPane
            tab={tabNav(
              'combateafraude',
              translate(
                'pages.private.profiles.components.tabs.index.tabs.tabPane.dossier'
              )
            )}
            key="dossier"
          >
            {companyId ? <CompanyDossier /> : <PeopleDossier />}
          </TabPane>
        )}

        {hasBackgroundChecking && (
          <TabPane
            tab={tabNav(
              'processing_line',
              translate('pages.private.executions.components.tabs.index.tabs.dossier')
            )}
            key="backgroundChecking"
          >
            <ExecutionDossier />
          </TabPane>
        )}

        {/* {hasTrustValidations && personId && (
          <TabPane tab={tabNav('combateafraude', 'Trust')} key="dossier">
            <PeopleDossier />
          </TabPane>
        )} */}

        <TabPane
          tab={tabNav(
            'clock',
            translate(
              'pages.private.profiles.components.tabs.index.tabs.tabPane.activities'
            )
          )}
          key="activities"
        >
          <RecurrenceList />
        </TabPane>

        {personId && (
          <TabPane
            tab={tabNav(
              'onboarding',
              translate(
                'pages.private.profiles.components.tabs.index.tabs.tabPane.documents'
              )
            )}
            key="documents"
          >
            <DocumentsValidations />
          </TabPane>
        )}

        {permissionFilesList && (
          <TabPane
            tab={tabNav(
              'attach',
              translate(
                'pages.private.profiles.components.tabs.index.tabs.tabPane.archives'
              )
            )}
            key="archives"
          >
            <ArchivesList
              url={
                companyId ? `companies/${companyId}/files` : `people/${personId}/files`
              }
            />
          </TabPane>
        )}
      </Tabs>
    </section>
  );
};

export default TabsWrapper;
