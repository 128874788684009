/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import EmptyState from 'components/EmptyState';
import EmptyExecutionState from 'components/EmptyExecutionState';
import TitleDivider from 'components/Divider/TitleDivider';

import { useExecution } from 'hooks/execution';
import { nationalList, internationalList } from '../../utils/sanctionsDetails';

import SourceCard from '../../components/Sources/SourcesCard';

const OwnersSourcesList = ({
  dataSources,
  personalInfo,
  // lastConsultationPersonal,
  toPrint,
  toPrintDetails,
  ownerProfile,
  sanctionsData,
}) => {
  const { t: translate } = useTranslation();

  const { execution } = useExecution();

  const kyCompliance = useMemo(() => ownerProfile, [ownerProfile]);

  const kycComplianceData = useMemo(() => sanctionsData, [sanctionsData]);

  const querySucceeded = useMemo(
    () =>
      ownerProfile?.statusCode === '01' ||
      ownerProfile?.available === true ||
      Object.keys(ownerProfile?.data || {}).length ||
      (Array.isArray(ownerProfile?.data) && ownerProfile?.data?.length),
    [ownerProfile]
  );

  // const lastConsultation = lastConsultationPersonal || data?.lastConsultation?.date;
  // const pepSources = data?.pepHistory || [];

  const mappedData = useMemo(() => {
    if (!sanctionsData) return {};

    const nationalSources = {};
    const internationalSources = {};

    const sanctionsHistory = sanctionsData?.sanctionsHistory || {};

    Object.keys(sanctionsHistory).forEach((key) => {
      const value = sanctionsHistory[key];
      const parsedValue = { [key]: value };

      if (nationalList.includes(key)) {
        Object.assign(nationalSources, parsedValue);
      }
      if (internationalList.includes(key)) {
        Object.assign(internationalSources, parsedValue);
      }
    });

    return { nationalSources, internationalSources };
  }, [sanctionsData]);

  const customSourcesTop = useMemo(() => {
    if (!personalInfo) {
      if (
        !dataSources.includes('pj_kyc_compliance_owners') &&
        !execution?.others?.kycComplianceOwners
      )
        return '0px';
      return '25px';
    }
    return '5px';
  }, [personalInfo, dataSources, execution]);

  const parsedPEPList = useMemo(() => {
    if (!kycComplianceData || !kycComplianceData?.pepHistory?.length > 0) return <></>;

    const el = [];
    const _size = kycComplianceData?.pepHistory?.length;

    if (_size) {
      for (let i = 0; i < _size; i += 2) {
        el.push(
          <Row className="sources-list-row reset-mrg">
            {kycComplianceData?.pepHistory?.slice(i, i + 2)?.map((source) => (
              <Col key={source} span={12} className="sources-list-data">
                <SourceCard
                  pep
                  data={source}
                  pepSource={kycComplianceData?.pepHistory}
                  toPrint={toPrint}
                  toPrintDetails={toPrintDetails}
                />
              </Col>
            ))}
          </Row>
        );
      }
    }
    return el.length > 0 ? el : <></>;
  }, [kycComplianceData, toPrint, toPrintDetails]);

  const parsedNationalList = useMemo(() => {
    if (!kycComplianceData) return <></>;

    const el = [];
    const _size = Object.keys(mappedData?.nationalSources).length;

    if (_size) {
      for (let i = 0; i < _size; i += 2) {
        el.push(
          <Row className="sources-list-row reset-mrg">
            {Object.keys(mappedData?.nationalSources)
              ?.slice(i, i + 2)
              ?.map((source) => (
                <Col key={source} span={12} className="sources-list-data">
                  <SourceCard
                    data={source}
                    others={source === 'OTHERS'}
                    sanctionsSource={kycComplianceData?.sanctionsHistory[source]}
                    lastConsultation={kycComplianceData?.lastConsultation?.date}
                    national
                    toPrint={toPrint}
                    toPrintDetails={toPrintDetails}
                  />
                </Col>
              ))}
          </Row>
        );
      }
    }
    return el.length > 0 ? (
      el
    ) : (
      <EmptyState
        type="empty-success"
        description={translate(
          'components.dataSourceCards.restrictiveLists.lists.sourcesList.ownersSourcesList.parsedNationalList.emptyState.description'
        )}
        className="mrg-btm-25 mrg-top-35"
      />
    );
  }, [kycComplianceData, mappedData, toPrint, toPrintDetails, translate]);

  const parsedInternationalList = useMemo(() => {
    if (!kycComplianceData) return <></>;

    const el = [];
    const _size = Object.keys(mappedData?.internationalSources).length;

    if (_size) {
      for (let i = 0; i < _size; i += 2) {
        el.push(
          <Row className="sources-list-row reset-mrg">
            {Object.keys(mappedData?.internationalSources)
              ?.slice(i, i + 2)
              ?.map((source) => (
                <Col key={source} span={12} className="sources-list-data">
                  <SourceCard
                    data={source}
                    others={source === 'OTHERS'}
                    sanctionsSource={kycComplianceData?.sanctionsHistory[source]}
                    lastConsultation={kycComplianceData?.lastConsultation?.date}
                    international
                    toPrint={toPrint}
                    toPrintDetails={toPrintDetails}
                  />
                </Col>
              ))}
          </Row>
        );
      }
    }
    return el.length > 0 ? (
      el
    ) : (
      <EmptyState
        type="empty-success"
        description={translate(
          'components.dataSourceCards.restrictiveLists.lists.sourcesList.ownersSourcesList.parsedInternationalList.emptyState.description'
        )}
        className="mrg-btm-25 mrg-top-35"
      />
    );
  }, [kycComplianceData, mappedData, toPrint, toPrintDetails, translate]);

  return (
    <Row id="sources-list-container">
      {querySucceeded ? (
        <>
          {personalInfo && (
            <Col span={24} className="sources-list-col">
              <TitleDivider
                title={translate(
                  'components.dataSourceCards.restrictiveLists.lists.sourcesList.ownersSourcesList.ownersSourcesList.personalInfo.titleDivider.title'
                )}
                tooltipInfo={translate(
                  'components.dataSourceCards.restrictiveLists.lists.sourcesList.ownersSourcesList.ownersSourcesList.personalInfo.titleDivider.tooltipInfo'
                )}
                toPrint={toPrint}
                customTop="0px"
                customBottom="40px"
              />

              {!kycComplianceData && (
                <EmptyExecutionState
                  statusCode={execution?.sections?.pfKycCompliance?.statusCode}
                  message={
                    execution?.sections?.pfKycCompliance?.message ||
                    execution?.sections?.pfKycCompliance?.data?.message
                  }
                  className="mrg-btm-25 mrg-top-35"
                />
              )}

              {kycComplianceData && !kycComplianceData?.pepHistory?.length > 0 && (
                <EmptyState
                  type="empty-success"
                  description={translate(
                    'components.dataSourceCards.restrictiveLists.lists.sourcesList.ownersSourcesList.ownersSourcesList.personalInfo.emptyState.description'
                  )}
                  className="mrg-btm-25 mrg-top-35"
                />
              )}

              {parsedPEPList}
            </Col>
          )}

          {kycComplianceData && (
            <>
              <Col span={24} className="sources-list-col">
                <TitleDivider
                  title={translate(
                    'components.dataSourceCards.restrictiveLists.lists.sourcesList.ownersSourcesList.ownersSourcesList.hasKycComplianceData.titleDivider.title.national'
                  )}
                  toPrint={toPrint}
                  customTop={customSourcesTop}
                  customBottom="50px"
                />

                {parsedNationalList}
              </Col>

              <Col span={24} className="sources-list-col">
                <TitleDivider
                  title={translate(
                    'components.dataSourceCards.restrictiveLists.lists.sourcesList.ownersSourcesList.ownersSourcesList.hasKycComplianceData.titleDivider.title.international'
                  )}
                  toPrint={toPrint}
                  customTop="5px"
                  customBottom="50px"
                />

                {parsedInternationalList}
              </Col>
            </>
          )}

          {!kycComplianceData && (
            <Col span={24} className="sources-list-col">
              <TitleDivider
                title={translate(
                  'components.dataSourceCards.restrictiveLists.lists.sourcesList.ownersSourcesList.ownersSourcesList.hasNotKycComplianceData.titleDivider.title'
                )}
                toPrint={toPrint}
                customTop={customSourcesTop}
                customBottom="50px"
              />

              <EmptyExecutionState
                statusCode={kyCompliance?.statusCode}
                message={kyCompliance.message || kyCompliance.data?.message}
                className="mrg-btm-25 mrg-top-35"
              />
            </Col>
          )}
        </>
      ) : (
        <EmptyExecutionState
          statusCode={kyCompliance?.statusCode}
          message={kyCompliance?.message || kyCompliance?.data?.message}
          className="mrg-btm-25 mrg-top-35"
        />
      )}
    </Row>
  );
};

export default OwnersSourcesList;
