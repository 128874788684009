import React, { createContext, useCallback, useContext, useState } from 'react';

import { useFetch } from 'services/hooks';

const ValidationsContext = createContext({});

const ValidationsProvider = ({ children }) => {
  const { get } = useFetch();

  const [validations, setValidations] = useState([]);
  const [loadingValidations, setLoadingValidations] = useState(false);

  const fetchValidations = useCallback(
    async (profileUrl) => {
      try {
        setLoadingValidations(true);

        const response = await get({
          url: `/${profileUrl}/validations`,
          showMessage: false,
        });

        setValidations(response);
        setLoadingValidations(false);
      } catch (error) {
        setLoadingValidations(false);
      }
    },
    [get]
  );

  return (
    <ValidationsContext.Provider
      value={{
        fetchValidations,
        validations,
        loadingValidations,
      }}
    >
      {children}
    </ValidationsContext.Provider>
  );
};

function useValidations() {
  const context = useContext(ValidationsContext);

  if (!context) {
    throw new Error('useValidations must be used within an ValidationsContext');
  }

  return context;
}

export { ValidationsProvider, useValidations };
