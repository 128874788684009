import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col, Button, Collapse, Tag } from 'antd';

import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';

// import EmptyState from 'components/EmptyState';
import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';

import useSelectSourceDetails from 'components/SelectSourceDetails';
import { QSAInfosContentExecutions } from 'components/SelectSourceDetails/utils/sources';

// import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import { useExecution } from 'hooks/execution';
import QSAPartnerDetails from './QSAPartnerDetails';
import SerasaExperianPartnerDetails from './SerasaExperianPartnerDetails';
import QSAParametersPartnerDetails from './QSAParametersPartnerDetails';

const { Panel } = Collapse;

const QSAInfosContent = ({ source, toPrint }) => {
  const { t: translate } = useTranslation();

  const { SelectSource, componentToShowDetails } = useSelectSourceDetails({
    sources: QSAInfosContentExecutions,
  });

  const { execution } = useExecution();

  const [isPanelOpened, setIsPanelOpened] = useState(false);

  const QSAItems = useMemo(() => source?.QSA, [source]);
  const serasaExperianItems = useMemo(() => source?.partners, [source]);
  const QSAParametersItems = useMemo(() => execution?.parameters?.partners, [execution]);

  const QSAPartnersCount = useMemo(() => {
    if (QSAItems?.length === 1) {
      return translate(
        'components.dataSourceCards.basicInfos.components.company.qsaInfosContent.QSAPartnersCount.oneItem'
      );
    }
    if (QSAItems?.length > 1) {
      return translate(
        'components.dataSourceCards.basicInfos.components.company.qsaInfosContent.QSAPartnersCount.moreThanOneItem',
        { value: QSAItems?.length }
      );
    }

    return <></>;
  }, [QSAItems, translate]);

  const serasaExperianPartnersCount = useMemo(() => {
    if (serasaExperianItems?.length === 1) {
      return translate(
        'components.dataSourceCards.basicInfos.components.company.qsaInfosContent.serasaExperianPartnersCount.oneItem'
      );
    }

    if (serasaExperianItems?.length > 1) {
      return translate(
        'components.dataSourceCards.basicInfos.components.company.qsaInfosContent.serasaExperianPartnersCount.moreThanOneItem',
        { value: serasaExperianItems?.length }
      );
    }

    return <></>;
  }, [serasaExperianItems, translate]);

  const QSAParametersPartnersCount = useMemo(() => {
    if (QSAParametersItems?.length === 1) {
      return translate(
        'components.dataSourceCards.basicInfos.components.company.qsaInfosContent.QSAParametersPartnersCount.oneItem'
      );
    }

    if (QSAParametersItems?.length > 1) {
      return translate(
        'components.dataSourceCards.basicInfos.components.company.qsaInfosContent.QSAParametersPartnersCount.moreThanOneItem',
        { value: QSAParametersItems?.length }
      );
    }

    return <></>;
  }, [QSAParametersItems, translate]);

  const onClickHandler = useCallback(() => {
    setIsPanelOpened((oldState) => !oldState);
  }, []);

  const renderPartnerToPrint = (partner) => (
    <div key={`${partner.name}`} className="legal-representative-container">
      <span>
        {partner.name} ({partner.function}){' '}
      </span>
      {partner.isLegalRepresentative === true && (
        <Tag className="legal-representative-tag">
          {translate(
            'components.dataSourceCards.basicInfos.components.company.qsaInfosContent.renderPartnerToPrint.legalRepresentative'
          )}
        </Tag>
      )}
    </div>
  );

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_user_active"
      title={translate(
        'components.dataSourceCards.basicInfos.components.company.qsaInfosContent.qsaInfosContent.basicInfosCard.title'
      )}
      dataIndex="items"
      customId="execution-qsa-content"
      id="execution-qsa-content"
      toPrint={toPrint}
    >
      <Row>
        {componentToShowDetails === 'internalRevenueService' && (
          <ColumnTitleValue
            span={24}
            className="mrg-btm-30"
            title={translate(
              'components.dataSourceCards.basicInfos.components.company.qsaInfosContent.qsaInfosContent.basicInfosCard.columnTitleValue.QSAPartners.title'
            )}
            value={QSAPartnersCount}
            selectSource={SelectSource}
          >
            {/* {!QSAItems && QSAIsProcessing && <EmptyState type="processing" />}

            {!QSAItems && !QSAIsProcessing && <EmptyState type="waiting_consult" />} */}

            {QSAItems?.length > 0 && (
              <>
                <div className="executions-card-partners-content">
                  {!toPrint &&
                    QSAItems?.slice(0, 6)?.map((partner) => (
                      <QSAPartnerDetails partner={partner} />
                    ))}

                  {!toPrint && QSAItems.length > 6 && (
                    <Collapse
                      activeKey={Number(isPanelOpened)}
                      className="ghost-collapse"
                      ghost
                    >
                      <Panel key={1} className="mrg-top-10">
                        {QSAItems?.slice(6, QSAItems.length)?.map((partner) => (
                          <QSAPartnerDetails partner={partner} />
                        ))}
                      </Panel>
                    </Collapse>
                  )}

                  {toPrint && QSAItems?.map((partner) => renderPartnerToPrint(partner))}
                </div>

                {!toPrint && QSAItems.length > 6 && (
                  <Col span={24}>
                    <Button
                      type="link"
                      className="no-mrg no-pdd"
                      onClick={onClickHandler}
                    >
                      <i
                        className={`caf-ic_${
                          isPanelOpened ? 'close' : 'plus'
                        } mrg-right-5`}
                      />
                      {translate(
                        'components.dataSourceCards.basicInfos.components.company.qsaInfosContent.qsaInfosContent.basicInfosCard.columnTitleValue.QSAPartners.showMorePartners'
                      )}{' '}
                      ({QSAItems?.length - 6})
                    </Button>
                  </Col>
                )}
              </>
            )}
          </ColumnTitleValue>
        )}
        {serasaExperianItems?.length > 0 &&
          componentToShowDetails === 'informedViaParameter' && (
            <ColumnTitleValue
              span={24}
              title={translate(
                'components.dataSourceCards.basicInfos.components.company.qsaInfosContent.qsaInfosContent.basicInfosCard.columnTitleValue.QSAPartners.title'
              )}
              className="mrg-btm-30"
              value={serasaExperianPartnersCount}
              selectSource={SelectSource}
            >
              {/* {!serasaExperianItems && serasaExperianIsProcessing && (
                <EmptyState type="processing" />
              )}

              {!serasaExperianItems && !serasaExperianIsProcessing && (
                <EmptyState type="waiting_consult" />
              )} */}

              <div className="executions-card-partners-content">
                {!toPrint &&
                  serasaExperianItems
                    ?.slice(0, 6)
                    ?.map((partner) => (
                      <SerasaExperianPartnerDetails partner={partner} />
                    ))}

                {!toPrint && serasaExperianItems.length > 6 && (
                  <Collapse
                    activeKey={Number(isPanelOpened)}
                    className="ghost-collapse"
                    ghost
                  >
                    <Panel key={1} className="mrg-top-10">
                      {serasaExperianItems
                        ?.slice(6, serasaExperianItems.length)
                        ?.map((partner) => (
                          <SerasaExperianPartnerDetails partner={partner} />
                        ))}
                    </Panel>
                  </Collapse>
                )}
              </div>

              {!toPrint && serasaExperianItems.length > 6 && (
                <Col span={24}>
                  <Button type="link" className="no-mrg no-pdd" onClick={onClickHandler}>
                    <i
                      className={`caf-ic_${isPanelOpened ? 'close' : 'plus'} mrg-right-5`}
                    />
                    {translate(
                      'components.dataSourceCards.basicInfos.components.company.qsaInfosContent.qsaInfosContent.basicInfosCard.columnTitleValue.serasaExperian.showMorePartners'
                    )}{' '}
                    ({serasaExperianItems?.length - 6})
                  </Button>
                </Col>
              )}
            </ColumnTitleValue>
          )}
        {QSAParametersItems?.length > 0 && componentToShowDetails === 'serasaExperian' && (
          <ColumnTitleValue
            span={24}
            title={translate(
              'components.dataSourceCards.basicInfos.components.company.qsaInfosContent.qsaInfosContent.basicInfosCard.columnTitleValue.QSAPartners.title'
            )}
            className="mrg-btm-30"
            value={QSAParametersPartnersCount}
            selectSource={SelectSource}
          >
            {/* {!QSAParametersItems && QSAParametersProcessing && (
              <EmptyState type="processing" />
            )}

            {!QSAParametersItems && !QSAParametersProcessing && (
              <EmptyState type="waiting_consult" />
            )} */}

            <div className="card-partners-content">
              {!toPrint &&
                QSAParametersItems?.slice(0, 6)?.map((partner) => (
                  <QSAParametersPartnerDetails partner={partner} />
                ))}

              {!toPrint && QSAParametersItems.length > 6 && (
                <Collapse
                  activeKey={Number(isPanelOpened)}
                  className="ghost-collapse"
                  ghost
                >
                  <Panel key={1} className="mrg-top-10">
                    {QSAParametersItems?.slice(6, QSAParametersItems.length)?.map(
                      (partner) => (
                        <QSAParametersPartnerDetails partner={partner} />
                      )
                    )}
                  </Panel>
                </Collapse>
              )}
            </div>

            {!toPrint && QSAParametersItems.length > 6 && (
              <Col span={24}>
                <Button type="link" className="no-mrg no-pdd" onClick={onClickHandler}>
                  <i
                    className={`caf-ic_${isPanelOpened ? 'close' : 'plus'} mrg-right-5`}
                  />
                  {translate(
                    'components.dataSourceCards.basicInfos.components.company.qsaInfosContent.qsaInfosContent.basicInfosCard.columnTitleValue.QSAParameters.showMorePartners'
                  )}{' '}
                  ({QSAParametersItems?.length - 6})
                </Button>
              </Col>
            )}
          </ColumnTitleValue>
        )}
      </Row>
    </DataSourceCardCollapsePanel>
  );
};

QSAInfosContent.propTypes = {
  source: PropTypes.objectOf,
  toPrint: PropTypes.bool,
};

QSAInfosContent.defaultProps = {
  source: {},
  toPrint: false,
};

export default QSAInfosContent;
