import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  PieChart as REPieChart,
  Pie,
  ResponsiveContainer,
  Cell,
  Legend,
  Tooltip,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import ChartTooltip from 'components/ChartTooltip';

import useWindowResize from 'customHooks/useWindowResize';

const colors = {
  approved: '#34D690',
  pending: '#004AF7',
  reproved: '#E21B45',
  processing: '#4490FB',
};

const I18N_BASE_PATH = 'pages.private.dashboard.components.charts.pie';

const PieChart = (props) => {
  const { t: translate } = useTranslation();

  const { data, cy, marginBottom, height } = props;
  const [outerRadius, setOuterRadius] = useState('145%');
  const [innerRadius, setInnerRadius] = useState('80%');

  useWindowResize((width) => {
    if (width > 1366) {
      setOuterRadius('160%');
      setInnerRadius('105%');
    } else if (width <= 1366 && width > 1199) {
      setOuterRadius('150%');
      setInnerRadius('95%');
    } else if (width <= 1199 && width > 991) {
      setOuterRadius('125%');
      setInnerRadius('80%');
    } else {
      setOuterRadius('105%');
      setInnerRadius('60%');
    }
  }, []);

  const renderCustomTooltip = (tooltipProps) => {
    const { active, payload } = tooltipProps;

    if (!active) return null;

    const { value, type } = payload[0].payload;

    return (
      <ChartTooltip subtitle={`${translate(`${I18N_BASE_PATH}.${type}`)}: ${value}`} />
    );
  };

  return (
    <ResponsiveContainer height={height} width={320}>
      <REPieChart
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 15,
        }}
        style={{
          fontSize: '0.7rem',
        }}
      >
        <Pie
          data={data}
          cy={cy}
          dataKey="value"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={180}
          endAngle={0}
          paddingAngle={1}
        >
          {data.map((entry) => (
            <Cell key={`cell-${entry.type}`} fill={colors[entry.type]} />
          ))}
        </Pie>
        <Tooltip content={renderCustomTooltip} />
        <Legend
          height={10}
          iconSize={10}
          wrapperStyle={{
            bottom: marginBottom,
            left: 10,
          }}
          payload={data.map(({ type }) => ({
            color: colors[type],
            value: translate(`${I18N_BASE_PATH}.${type}`),
            type: 'square',
          }))}
        />
      </REPieChart>
    </ResponsiveContainer>
  );
};

PieChart.propTypes = {
  cy: PropTypes.string,
  height: PropTypes.string,
  marginBottom: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      type: PropTypes.string,
    })
  ).isRequired,
};

PieChart.defaultProps = {
  cy: '80%',
  height: '85%',
  marginBottom: 20,
};

export default PieChart;
