import React from 'react';
import PropTypes from 'prop-types';

const IconStatus = ({ color, icon }) => (
  <div className="checkpoint-status-icon" style={{ borderColor: color, color }}>
    <i className={icon} />
  </div>
);

IconStatus.propTypes = {
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default IconStatus;
