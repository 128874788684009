import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import { cpfTranslation, executionCpfErrosTranslation } from 'utils/personalTranslations';
import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';
import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.basicInfos.components.person.registrationInfosContent.registrationInfosContent.basicInfosCard';

/**
 * @see https://docs.combateafraude.com/docs/api/secoes-validacoes/background-checking-pf/#consulta-receita-federal-pf1
 */
export const CPF = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const translatedMessage = useMemo(
    () =>
      statusCode !== '00' && !parsedData?.registrationStatusMessage
        ? executionCpfErrosTranslation[statusCode] || executionCpfErrosTranslation['00']
        : cpfTranslation[(parsedData?.registrationStatusMessage || '').trim()] ||
          cpfTranslation.NULA,
    [parsedData, statusCode]
  );

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_file"
      title={translate(
        'components.dataSourceCards.basicInfos.components.basicInfosCard.sources.cpf'
      )}
      dataIndex="basicData"
      toPrint={toPrint}
    >
      <Row className="pdd-top-10 ">
        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.name.title`)}
        >
          <span className="text-dark">{parsedData?.name || '-'}</span>
        </ColumnLabel>

        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.birthDate.title`)}
        >
          <span className="text-dark">{parsedData?.birthDate}</span>
        </ColumnLabel>

        <ColumnLabel
          title={translate(`${I18N_BASE_PATH}.columnLabel.status.title`)}
          description={translate(translatedMessage?.description || '-')}
          type={translatedMessage?.type}
          span={8}
        />

        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.issueDate.title`)}
        >
          <span className="text-dark">{parsedData?.issueDate || '-'}</span>
        </ColumnLabel>

        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.deathYear.title`)}
        >
          <span className="text-dark">{parsedData?.deathYear || '-'}</span>
        </ColumnLabel>

        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.deathMessage.title`)}
        >
          <span className="text-dark">{parsedData?.deathMessage || '-'}</span>
        </ColumnLabel>
      </Row>
    </DataSourceCardCollapsePanel>
  );
};
