import React, { createContext, useCallback, useContext, useState } from 'react';

const SocketContext = createContext({});

const SocketProvider = ({ children }) => {
  const [functionsToRun, setFunctionsToRun] = useState([]);
  const [socket, setSocket] = useState(null);

  const connect = useCallback(
    async (_username, reconnect = false) => {
      if (reconnect || !socket) {
        const newSocket = new WebSocket(process.env.REACT_APP_SOCKET_URL);
        newSocket.addEventListener('open', () => {
          newSocket.send(
            JSON.stringify({
              action: 'subscribeAndPublish',
              topic: `trust-online-username-${_username}`,
              infoData: { action: 'connected' },
            })
          );
        });
        newSocket.addEventListener('message', (event) => {
          const message = JSON.parse(event.data);
          if (message?.content?.forEach) {
            message.content.forEach((c) => {
              if (c?.infoData?.action) {
                functionsToRun.forEach((f) => {
                  f.func(c.infoData);
                });
              }
            });
          }
        });
        newSocket.addEventListener('close', () => {
          connect(_username, true);
        });
        setSocket(newSocket);
      }
    },
    [functionsToRun, socket]
  );

  const addFunction = useCallback(
    (_id, func) => {
      const newFunctionsToRun = functionsToRun;
      const exists = newFunctionsToRun.find((f) => f.id === _id);
      if (!exists) {
        newFunctionsToRun.push({ id: _id, func });
        setFunctionsToRun(newFunctionsToRun);
      }
    },
    [functionsToRun]
  );

  const disconnect = useCallback(() => {
    if (socket !== null) {
      socket.close();
      setSocket(null);
    }
  }, [socket]);

  return (
    <SocketContext.Provider
      value={{
        connect,
        addFunction,
        disconnect,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

function useSocket() {
  const context = useContext(SocketContext);

  if (!context) {
    throw new Error('useSocket must be used within an SocketContext');
  }

  return context;
}

export { SocketProvider, useSocket };
