import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Collapse, Select } from 'antd';

import { useCustomStatus } from 'hooks/customStatus';

const { Panel } = Collapse;
const { Option } = Select;

const CustomStatusFilter = ({ title, value, onChange }) => {
  const { t: translate } = useTranslation();

  const {
    allCustomStatusList = [],
    loadAllCustomStatus,
    loadingAllCustomStatus,
  } = useCustomStatus();

  useEffect(() => loadAllCustomStatus(), [loadAllCustomStatus]);

  return allCustomStatusList?.length > 0 ? (
    <Collapse
      ghost
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <div>
          <i className={isActive ? 'caf-ic_arrow_up' : 'caf-ic_arrow_down'} />
        </div>
      )}
    >
      <Panel header={title} key="customStatus">
        <Select
          mode="multiple"
          value={value}
          onChange={(values) => onChange(values)}
          loading={loadingAllCustomStatus}
          disabled={loadingAllCustomStatus}
          placeholder={translate(
            'components.filters.customStatusFilter.panel.select.placeholder'
          )}
          dropdownClassName="multiselect-filters"
          dropdownAlign={{ offset: [0, 2] }}
          removeIcon={<i className="caf-ic_circle_false" />}
          menuItemSelectedIcon={<i className="caf-ic_checkmark" />}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => {
            return option?.title?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
          }}
        >
          {allCustomStatusList.map((customStatus) => {
            const { _id, action } = customStatus || {};

            return (
              <Option key={_id} value={action} title={action}>
                <div className="multiselect-option">
                  <span className="option-title">{action}</span>
                </div>
              </Option>
            );
          })}
        </Select>
      </Panel>
    </Collapse>
  ) : (
    <></>
  );
};

CustomStatusFilter.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default CustomStatusFilter;
