/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import { cnhTranslation } from 'utils/personalTranslations';
import isValidCNH from 'utils/isValidCNH';
import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import EmptyState from 'components/EmptyState';

import DossierCard from 'pages/private/Profiles/components/DossierCard';
import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

const DriverInfos = ({ data, toPrint }) => {
  const { t: translate } = useTranslation();

  // const driver = useMemo(() => data?.driver, [data]);

  // const urlId = useMemo(() => data?.recurrenceModel?._id, [data]);

  const dataSources = useMemo(
    () =>
      data?.profileTemplate?.backgroundChecking?.services?.map(({ source }) => source) ||
      [],
    [data]
  );

  const detran = useMemo(() => {
    const isProcessing = verifySourcesProcessing(data, 'detran');

    // if (!driver) return undefined;

    const detranData = data?.driver?.detran;

    if (!detranData) {
      return {
        isProcessing,
      };
    }

    const isCNHValid = isValidCNH(detranData?.cnh, 'validityDate', cnhTranslation);

    return {
      validityDate: detranData?.cnh?.validityDate,
      ...isCNHValid,
    };
  }, [data]);

  const antt = useMemo(() => {
    const isProcessing = verifySourcesProcessing(data, 'antt');

    // if (!driver) return undefined;

    const anttData = data?.driver?.antt;

    if (!anttData) {
      return {
        isProcessing,
      };
    }

    if (!anttData?.hasRegister) {
      return {
        ...cnhTranslation.unregistered,
      };
    }
    const isANTTValid = isValidCNH(anttData, 'expirationDate', cnhTranslation);

    return {
      expirationDate: anttData.expirationDate,
      ...isANTTValid,
    };
  }, [data]);

  const showDetran = useMemo(
    () => dataSources.includes('detran') || data?.driver?.detran,
    [data, dataSources]
  );

  const showAntt = useMemo(() => dataSources.includes('antt') || data?.driver?.antt, [
    data,
    dataSources,
  ]);

  return (
    <DossierCard
      customId="driver-infos-component"
      title={translate(
        'pages.private.profiles.components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.title'
      )}
      subtitle={translate(
        'pages.private.profiles.components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.subtitle'
      )}
      icon="caf-ic_driver"
    >
      <Row>
        {showDetran && (
          <ColumnLabel
            title={translate(
              'pages.private.profiles.components.dataSourceCards.driverInformation.index.driverInformation.detran.columnLabel.title'
            )}
            description={translate(detran?.translatedStatus?.description)}
            type={detran?.translatedStatus?.type}
            span={12}
          >
            {/* {!detran && (
              <EmptyState type="empty-recurrence" hiddenAction={toPrint} urlId={urlId} />
            )} */}

            {!detran?.translatedStatus && detran?.isProcessing && (
              <EmptyState type="processing" />
            )}

            {!detran?.translatedStatus && !detran?.isProcessing && (
              <EmptyState type="waiting_consult" />
            )}

            {detran?.validityDate && (
              <span>
                {translate(
                  'pages.private.profiles.components.dataSourceCards.driverInformation.index.driverInformation.detran.validityDate'
                )}{' '}
                {detran?.validityDate}
              </span>
            )}
          </ColumnLabel>
        )}

        {showAntt && (
          <ColumnLabel
            title={translate(
              'pages.private.profiles.components.dataSourceCards.driverInformation.index.driverInformation.antt.columnLabel.title'
            )}
            description={translate(antt?.translatedStatus?.description)}
            type={antt?.translatedStatus?.type}
            span={12}
            info={
              !toPrint &&
              translate(
                'pages.private.profiles.components.dataSourceCards.driverInformation.index.driverInformation.antt.columnLabel.info'
              )
            }
          >
            {/* {!antt && (
              <EmptyState type="empty-recurrence" hiddenAction={toPrint} urlId={urlId} />
            )} */}

            {!antt?.translatedStatus && antt?.isProcessing && (
              <EmptyState type="processing" />
            )}

            {!antt?.translatedStatus && !antt?.isProcessing && (
              <EmptyState type="waiting_consult" />
            )}

            {antt?.expirationDate && (
              <span>
                {translate(
                  'pages.private.profiles.components.dataSourceCards.driverInformation.index.driverInformation.antt.expirationDate'
                )}{' '}
                {antt?.expirationDate}
              </span>
            )}
          </ColumnLabel>
        )}
      </Row>
    </DossierCard>
  );
};

export default DriverInfos;
