import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { executionStatus } from 'utils/handlerStatus';

import './styles.less';

const validStatus = [
  'APPROVED',
  'APROVADO',
  'REPROVED',
  'REPROVADO',
  'PENDING',
  'PENDENTE',
  'PROCESSING',
  'PROCESSANDO',
  'WAITING_DOCUMENTS',
  'AGUARDANDO_DOCUMENTACAO',
];

const GeneralStatus = ({ data = undefined }) => {
  const { t: translate } = useTranslation();

  const statusDescription = useMemo(() => {
    if (!data) return [];

    if (data?.status === 'PENDENTE' || data?.status === 'REPROVADO') {
      const invalidChecks =
        data?.validations?.filter((v) => v?.status === 'INVALID') || [];

      const reprovalReasons =
        data?.reprovalReasons?.map((r) => {
          return { description: r };
        }) || [];

      const statusList = [...reprovalReasons, ...invalidChecks];

      if (statusList?.length > 1) {
        return (
          <ol>
            {statusList?.map((validation) => (
              <li style={{ marginTop: 4 }} key={validation?.description}>
                {validation?.description}
              </li>
            ))}
          </ol>
        );
      }

      if (statusList[0]?.description?.length > 0) {
        return <span>{statusList[0]?.description || ''}</span>;
      }
    }
    if (
      data?.status === 'APROVADO' &&
      data?.approvalReasons &&
      data?.approvalReasons?.length > 0
    ) {
      if (data?.approvalReasons?.length > 1) {
        return (
          <ol>
            {data?.approvalReasons?.map((reason) => (
              <li key={reason}>{reason}</li>
            ))}
          </ol>
        );
      }

      return <span>{data?.approvalReasons[0] || ''}</span>;
    }
    if (data?.status === 'PROCESSANDO') {
      const pendingDocumentscopyValidation = data?.validations?.find(
        (v) => v.rule === 'documentscopy_approved' && v.status === 'PENDING'
      );

      if (pendingDocumentscopyValidation) {
        return pendingDocumentscopyValidation?.description;
      }

      return translate(executionStatus(data?.status)?.description);
    }

    return '';
  }, [data, translate]);

  return (
    <>
      <div
        id="general-status-card"
        hidden={!validStatus.includes(data?.status)}
        style={{
          backgroundColor: executionStatus(data?.status)?.color,
        }}
      >
        <div className="general-status-info">
          <i className={`${executionStatus(data?.status)?.icon} general-status-icon`} />
          <div className="general-status-description">
            <strong>{translate(executionStatus(data?.status)?.display)}</strong>
            {statusDescription}
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralStatus;
