import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/auth';
import { CardWrapper } from 'components/CardWrapper';

import Wrapper from 'pages/private/wrapper';

import LivenessTab from './LivenessTab';
import AttemptsTab from './AttemptsTab';
import WhiteListTab from './WhiteListTab';

const { TabPane } = Tabs;

const Liveness = () => {
  const history = useHistory();
  const { t: translate } = useTranslation();
  const { user } = useAuth();

  const tabHeader = useCallback(
    (title, icon) => (
      <div className="flex align-center text-dark">
        <i className={`${icon} font-size-22 mrg-right-5`} />
        <strong className="text-semibold font-size-16">{title}</strong>
      </div>
    ),
    []
  );

  const handleTabClick = useCallback(
    (key) => {
      history.push(`${key}`);
    },
    [history]
  );

  return (
    <>
      <Wrapper id="liveness-tabs">
        <CardWrapper hasPadding>
          <Tabs onChange={handleTabClick} defaultActiveKey={history.location.pathname}>
            <TabPane
              tab={tabHeader(
                translate('pages.private.liveness.index.livenessTab'),
                'caf-ic_face'
              )}
              key="/liveness"
            >
              <LivenessTab />
            </TabPane>
            {user?.accountData?.featureFlags?.livenessBlock && (
              <>
                <TabPane
                  tab={tabHeader(
                    translate('pages.private.liveness.index.attemptsTab'),
                    'caf-ic_x_close'
                  )}
                  key="/liveness-attempts"
                >
                  <AttemptsTab />
                </TabPane>
                <TabPane
                  tab={tabHeader(
                    translate('pages.private.liveness.index.whiteListTab'),
                    'caf-ic_check_d'
                  )}
                  key="/liveness-whitelist"
                >
                  <WhiteListTab />
                </TabPane>
              </>
            )}
          </Tabs>
        </CardWrapper>
      </Wrapper>
    </>
  );
};
export default Liveness;
