import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Select } from 'antd';
import PropTypes from 'prop-types';

import FileUpload from 'components/Files/FileUpload';

const supportedFiles = ['.png', '.jpg', '.jpeg', '.pdf'];

const { Option } = Select;

const UploadDocumentUpload = ({
  isMissingFrontFile,
  handleChangeFile,
  frontFile,
  backFile,
  selfieFile,
}) => {
  const { t: translate } = useTranslation();

  const [documentType, setDocumentType] = useState(undefined);

  const UploadDocumentUploadContent = useMemo(
    () => (
      <>
        <Row className="mrg-btm-10">
          <Col span={12}>
            <Form.Item
              initialValue="nacional"
              name="documentOrigin"
              label={translate(
                'components.modalCreateExecution.index.CreateExecution.form.select.documentOrigin.label'
              )}
              rules={[{ required: true }]}
            >
              <Select
                optionFilterProp="children"
                placeholder={translate(
                  'components.modalCreateExecution.index.CreateExecution.form.select.documentOrigin.placeholder'
                )}
              >
                <Option default value="nacional">
                  {translate(
                    'components.modalCreateExecution.index.CreateExecution.form.select.documentOrigin.options.national'
                  )}
                </Option>
                <Option disabled value="estrangeiro">
                  {translate(
                    'components.modalCreateExecution.index.CreateExecution.form.select.documentOrigin.options.foreign'
                  )}
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="documentType"
              label={translate(
                'components.modalCreateExecution.index.CreateExecution.form.select.documentType.label'
              )}
              rules={[
                {
                  required: true,
                  message: translate(
                    'components.modalCreateExecution.index.CreateExecution.form.rules.messages.documentType'
                  ),
                },
              ]}
              style={{ marginLeft: '-5px' }}
            >
              <Select
                optionFilterProp="children"
                onSelect={(value) => setDocumentType(value)}
                placeholder={translate(
                  'components.modalCreateExecution.index.CreateExecution.form.select.documentType.placeholder'
                )}
              >
                <Option default value="RG">
                  {translate(
                    'components.modalCreateExecution.index.CreateExecution.form.select.documentType.options.rg'
                  )}
                </Option>
                <Option default value="CNH">
                  {translate(
                    'components.modalCreateExecution.index.CreateExecution.form.select.documentType.options.cnh'
                  )}
                </Option>
                <Option default value="other">
                  {translate(
                    'components.modalCreateExecution.index.CreateExecution.form.select.documentType.options.other'
                  )}
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={translate(
                'components.modalCreateExecution.index.CreateExecution.form.fileUpload.front.label'
              )}
            >
              <FileUpload
                title={translate(
                  'components.modalCreateExecution.index.CreateExecution.form.fileUpload.front.title'
                )}
                isInvalid={isMissingFrontFile && !frontFile}
                file={frontFile}
                onChangeFile={(value) => handleChangeFile('front', value)}
                supportedFiles={supportedFiles}
              />
              <FileUpload
                title={translate(
                  'components.modalCreateExecution.index.CreateExecution.form.fileUpload.back.title'
                )}
                file={backFile}
                onChangeFile={(value) => handleChangeFile('back', value)}
                supportedFiles={supportedFiles}
              />
            </Form.Item>
            <Form.Item
              className="mrg-btm-10"
              label={translate(
                'components.modalCreateExecution.index.CreateExecution.form.fileUpload.selfie.label'
              )}
            >
              <FileUpload
                title={translate(
                  'components.modalCreateExecution.index.CreateExecution.form.fileUpload.selfie.title'
                )}
                file={selfieFile}
                onChangeFile={(value) => handleChangeFile('selfie', value)}
                supportedFiles={supportedFiles}
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    ),
    [backFile, frontFile, handleChangeFile, isMissingFrontFile, selfieFile, translate]
  );

  return {
    UploadDocumentUpload: UploadDocumentUploadContent,
    documentType,
    hasSelfieImage: selfieFile,
  };
};

UploadDocumentUpload.propTypes = {
  isMissingFrontFile: PropTypes.bool,
  handleChangeFile: PropTypes.func,
  frontFile: PropTypes.func,
  backFile: PropTypes.func,
  selfieFile: PropTypes.func,
};

UploadDocumentUpload.defaultProps = {
  isMissingFrontFile: false,
  handleChangeFile: undefined,
  frontFile: undefined,
  backFile: undefined,
  selfieFile: undefined,
};

export default UploadDocumentUpload;
