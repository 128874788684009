import React, { useMemo } from 'react';
import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import EmptyState from 'components/EmptyState';
import EmptyExecutionState from 'components/EmptyExecutionState';
import { Divider } from 'antd';
import './styles.less';

const PfDomains = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDateOnly } = useI18nFormatters();

  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const pfDomains = useMemo(() => source?.data, [source]) || [];

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );
  return (
    <>
      <DataSourceCardCollapsePanel
        title={translate('components.dataSourceCards.index.pfDomains.dossierCard.title')}
        icon="caf-ic_news"
        toPrint={toPrint}
      >
        <div className="no-mrg no-pdd">
          {queryWorked ? (
            <>
              {pfDomains && !pfDomains?.length && (
                <EmptyState
                  type="empty-success"
                  description={translate(
                    'components.dataSourceCards.index.pfDomains.dossierCard.querySucceeded.emptyState.description'
                  )}
                  className="mrg-btm-20 mrg-top-0"
                />
              )}
              {Array.isArray(pfDomains) &&
                pfDomains?.map((value) => (
                  <>
                    <div id="box-fields">
                      <div className="text-field">
                        <strong>
                          {translate(
                            'components.dataSourceCards.index.pfDomains.details.domainNameLabel.title'
                          )}
                        </strong>{' '}
                        {value?.domainName || '-'}
                      </div>
                      <div className="text-field">
                        <strong>
                          {translate(
                            'components.dataSourceCards.index.pfDomains.details.domainClassLabel.title'
                          )}
                        </strong>{' '}
                        {value?.domainClass || '-'}
                      </div>
                      <div className="text-field">
                        <strong>
                          {translate(
                            'components.dataSourceCards.index.pfDomains.details.registrationDateLabel.title'
                          )}
                        </strong>{' '}
                        {i18nFormatDateOnly(value?.registrationDate, 1) || '-'}
                      </div>
                      <div className="text-field">
                        <strong>
                          {translate(
                            'components.dataSourceCards.index.pfDomains.details.expirationDateLabel.title'
                          )}
                        </strong>{' '}
                        {i18nFormatDateOnly(value?.expirationDate, 1) || '-'}
                      </div>
                      <div className="text-field">
                        <strong>
                          {translate(
                            'components.dataSourceCards.index.pfDomains.details.registrationStatusLabel.title'
                          )}
                        </strong>{' '}
                        {value?.registrationStatus || '-'}
                      </div>
                    </div>
                    <Divider />
                  </>
                ))}
            </>
          ) : (
            <EmptyExecutionState
              statusCode={statusCode}
              message={source?.message}
              className="mrg-btm-20 mrg-top-0"
            />
          )}
        </div>
      </DataSourceCardCollapsePanel>
    </>
  );
};

export default PfDomains;
