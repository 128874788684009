/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useExecution } from 'hooks/execution';

import EmptyExecutionState from 'components/EmptyExecutionState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.people.components.unemploymentInsurance.index.unemploymentInsurance';

const UnemploymentInsurance = () => {
  const { t: translate } = useTranslation();

  const { execution } = useExecution();
  const unemploymentInsurance = useMemo(() => execution?.sections?.pfSeguroDesemprego, [
    execution,
  ]);

  return (
    <ColumnLabel
      title={translate(`${I18N_BASE_PATH}.columnLabel.title`)}
      description={
        (unemploymentInsurance?.available === true ||
          unemploymentInsurance?.statusCode === '01') &&
        (unemploymentInsurance?.insuranceStatus ||
          translate(`${I18N_BASE_PATH}.columnLabel.description`))
      }
      type="empty"
    >
      {unemploymentInsurance?.available === true ||
      unemploymentInsurance?.statusCode === '01' ? (
        <>
          {unemploymentInsurance?.procedure && (
            <span>{unemploymentInsurance?.procedure}</span>
          )}

          {unemploymentInsurance && !unemploymentInsurance?.insuranceStatus && (
            <span>
              {translate(`${I18N_BASE_PATH}.columnLabel.hasNotInsuranceStatus`)}
            </span>
          )}
        </>
      ) : (
        <EmptyExecutionState
          statusCode={unemploymentInsurance?.statusCode}
          message={unemploymentInsurance?.message || unemploymentInsurance?.data?.message}
        />
      )}
    </ColumnLabel>
  );
};

export default UnemploymentInsurance;
