/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import EmptyState from 'components/EmptyState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';
import usePersonalLaborAndFinancialInformation from 'pages/private/Profiles/components/DataSourceModals/PersonalLaborAndFinancialInformation';

const SocialAssistance = ({ profile, toPrint /* urlId */ }) => {
  const { t: translate } = useTranslation();

  const { i18nFormatCurrency } = useI18nFormatters();

  const socialAssistance = useMemo(() => profile?.finance?.socialAssistance, [profile]);

  const {
    openModal,
    PersonalLaborAndFinancialInformationModal,
  } = usePersonalLaborAndFinancialInformation({
    title: translate(
      'pages.private.profiles.components.dataSourceCards.financialInformation.people.components.socialAssistance.index.usePersonalLaborAndFinancialInformation.title'
    ),
    data: profile?.finance?.socialAssistance?.socialAssistances,
    socialAssistance: true,
  });

  const handlerQuantity = useMemo(() => {
    if (!socialAssistance) return { title: null, type: null };

    if (
      !socialAssistance?.socialAssistances ||
      socialAssistance?.socialAssistances?.length === 0
    ) {
      return {
        title: translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.people.components.socialAssistance.index.handlerQuantity.hasNotSocialAssistances'
        ),
        type: 'empty',
      };
    }

    if (socialAssistance?.socialAssistances?.length === 1) {
      return {
        title: translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.people.components.socialAssistance.index.handlerQuantity.hasOneSocialAssistance'
        ),
        type: 'primary',
      };
    }

    return {
      title: translate(
        'pages.private.profiles.components.dataSourceCards.financialInformation.people.components.socialAssistance.index.handlerQuantity.hasMoreThanOneSocialAssistance',
        { value: socialAssistance?.socialAssistances?.length }
      ),
      type: 'primary',
    };
  }, [socialAssistance, translate]);

  const isProcessing = useMemo(
    () => verifySourcesProcessing(profile, 'socialAssistance'),
    [profile]
  );

  const handleOpenModal = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <>
      {PersonalLaborAndFinancialInformationModal}

      <ColumnLabel
        title={translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.people.components.socialAssistance.index.socialAssistance.columnLabel.title'
        )}
        description={handlerQuantity?.title}
        type={handlerQuantity?.type}
        onClick={socialAssistance?.socialAssistances?.length > 0 && handleOpenModal}
      >
        {!socialAssistance && isProcessing && <EmptyState type="processing" />}

        {!socialAssistance && !isProcessing && <EmptyState type="waiting_consult" />}

        {socialAssistance && (
          <>
            {!socialAssistance?.socialAssistances ||
            socialAssistance?.socialAssistances?.length === 0 ? (
              <span>
                {translate(
                  'pages.private.profiles.components.dataSourceCards.financialInformation.people.components.socialAssistance.index.socialAssistance.hasNotSocialAssistances'
                )}
              </span>
            ) : (
              <>
                {toPrint && (
                  <span>
                    {translate(
                      'pages.private.profiles.components.dataSourceCards.financialInformation.people.components.socialAssistance.index.socialAssistance.valueOf'
                    )}{' '}
                    {i18nFormatCurrency(socialAssistance?.totalIncome, 'pt-BR')}{' '}
                    {translate(
                      'pages.private.profiles.components.dataSourceCards.financialInformation.people.components.socialAssistance.index.socialAssistance.hasToPrint'
                    )}
                  </span>
                )}
                {!toPrint && (
                  <span>
                    {translate(
                      'pages.private.profiles.components.dataSourceCards.financialInformation.people.components.socialAssistance.index.socialAssistance.valueOf'
                    )}{' '}
                    {i18nFormatCurrency(socialAssistance?.totalIncome, 'pt-BR')}{' '}
                    {translate(
                      'pages.private.profiles.components.dataSourceCards.financialInformation.people.components.socialAssistance.index.socialAssistance.hasNotToPrint'
                    )}
                  </span>
                )}
              </>
            )}
          </>
        )}
      </ColumnLabel>
    </>
  );
};

export default SocialAssistance;
