import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import defaultImage from 'assets/images/profile-default.png';

import { toMaskedCpf } from 'utils/formatters';

const SerasaExperianPartnerDetails = ({ partner }) => {
  const { t: translate } = useTranslation();

  const PartnerDetails = useMemo(() => {
    return (
      <div className="partner-card-content">
        <img
          className="partner-image grayscale-95"
          src={defaultImage}
          alt={partner?.name || ''}
        />
        <div className="partner-info-container">
          <div className="partner-info">
            <div className="partner-name-function">
              <span>{partner?.name || '-'}</span>
            </div>
          </div>
          <span className="partner-cpf">
            {partner?.documentNumber ? (
              toMaskedCpf(partner?.documentNumber || '00000000000')
            ) : (
              <p className="gx-font-italic">
                {translate(
                  'components.dataSourceCards.basicInfos.components.company.serasaExperianPartnerDetails.partnerDetails.hasNotPartnerDocumentNumber'
                )}
              </p>
            )}
            {partner?.participation && (
              <small>
                •{' '}
                {translate(
                  'components.dataSourceCards.basicInfos.components.company.serasaExperianPartnerDetails.partnerDetails.partnerParticipation'
                )}
                : {partner?.participation}%
              </small>
            )}
          </span>
        </div>
      </div>
    );
  }, [partner, translate]);

  return <div className="partner-card">{PartnerDetails}</div>;
};

SerasaExperianPartnerDetails.propTypes = {
  partner: PropTypes.shape({
    name: PropTypes.string,
    documentNumber: PropTypes.string,
    participation: PropTypes.string,
  }),
};

SerasaExperianPartnerDetails.defaultProps = {
  partner: PropTypes.shape({}),
};

export default SerasaExperianPartnerDetails;
