import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import CustomUploadButton from 'pages/private/Profiles/components/CustomUploadButton';
import useModalUpload from 'components/ModalUpload';
import PropTypes from 'prop-types';

import archives from 'assets/images/archives.svg';

import './styles.less';

const I18N_BASE_PATH = 'components.files.transactionRecordsFiles.components.emptyFiles';

const EmptyFiles = ({
  allowedMimeTypes,
  limitFileSize,
  limitFileQuantity,
  modalUploadSubtitle,
}) => {
  const { t: translate } = useTranslation();

  const { openModal, CardUploadModal } = useModalUpload(
    allowedMimeTypes,
    limitFileSize,
    limitFileQuantity,
    modalUploadSubtitle
  );

  const handleOpenUploadModal = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <>
      {CardUploadModal}

      <Row id="empty-archives-component" className="reset-mrg">
        <Col span={10} className="empty-archives-img custom-img">
          <img className="grayscale-95" src={archives} alt="Ilustração arquivos" />
        </Col>
        <Col span={14} className="send-archives-container">
          <strong className="send-archives-description">
            {translate(`${I18N_BASE_PATH}.title`)}
          </strong>

          <CustomUploadButton
            onClick={handleOpenUploadModal}
            customBottom="0px"
            customClass="send-archives-btn"
            customContent={
              <span>
                {translate(`${I18N_BASE_PATH}.button.before`)}{' '}
                <strong>{translate(`${I18N_BASE_PATH}.button.inside`)}</strong>{' '}
                {translate(`${I18N_BASE_PATH}.button.after`)}
              </span>
            }
            customIcon="caf-ic_attach font-size-35"
          />
        </Col>
      </Row>
    </>
  );
};

EmptyFiles.propTypes = {
  allowedMimeTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  limitFileSize: PropTypes.string.isRequired,
  limitFileQuantity: PropTypes.string.isRequired,
  modalUploadSubtitle: PropTypes.string.isRequired,
};

export default EmptyFiles;
