import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Flags from '@combateafraude/icons/flags';

import './style.less';

const CountryFlag = ({ country, style, className, showTooltip = true }) => {
  const { t } = useTranslation();

  if (typeof country !== 'string' || country.length !== 2) {
    console.warn('CountryFlag invalid country');
    return <></>;
  }

  const normalizedCountry =
    country.charAt(0).toUpperCase() + country.charAt(1).toLowerCase();

  const FlagIcon = Flags[`IcFlag${normalizedCountry}`];

  if (!FlagIcon) {
    return <span>{country.toUpperCase()}</span>;
  }

  const FlagComponent = (
    <FlagIcon style={style} className={classNames('icon-country-flag', className)} />
  );

  return showTooltip ? (
    <Tooltip title={t(`countries.${country.toUpperCase()}`)}>{FlagComponent}</Tooltip>
  ) : (
    FlagComponent
  );
};

export default CountryFlag;

CountryFlag.propTypes = {
  country: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
  showTooltip: PropTypes.bool,
};

CountryFlag.defaultProps = {
  style: {},
  className: '',
  showTooltip: true,
};
