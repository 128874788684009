import React from 'react';
import { useTranslation } from 'react-i18next';

import EmptyMessage from 'components/EmptyMessage';

const EmptyPage = () => {
  const { t: translate } = useTranslation();

  return (
    <div>
      <EmptyMessage
        description={translate(
          'pages.private.executions.components.list.components.emptyPage.index.emptyPage.title',
          'No results found.'
        )}
        withCard
      />
    </div>
  );
};

export default EmptyPage;
