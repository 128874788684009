import React from 'react';
import PropTypes from 'prop-types';

import './styles.less';

const DottedLabel = ({ type, description, className, fontSize }) => {
  return (
    <div className={`status-div ${type} ${className}`}>
      {type && <div className={`div-dot ${type}`} />}
      <span className="dotted-label-text" style={{ fontSize }}>
        {description || '-'}
      </span>
    </div>
  );
};

DottedLabel.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  fontSize: PropTypes.number,
};

DottedLabel.defaultProps = {
  fontSize: 16,
  className: '',
};

export default DottedLabel;
