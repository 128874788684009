/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyExecutionState from 'components/EmptyExecutionState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';
import usePersonalLaborAndFinancialInformationModal from 'pages/private/Profiles/components/DataSourceModals/PersonalLaborAndFinancialInformation';

import { isEmpty } from 'utils/isObjectEmpty';

import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

const WorkHistory = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode !== '02' &&
      (statusCode === '01' ||
        source?.available ||
        Object.keys(source?.data || {}).length ||
        (Array.isArray(source?.data) && source?.data?.length)),
    [statusCode, source]
  );

  const {
    openModal,
    PersonalLaborAndFinancialInformationModal,
  } = usePersonalLaborAndFinancialInformationModal({
    data: parsedData?.professions,
    title: translate(
      'pages.private.executions.components.dataSourceCards.laborInformation.people.components.workHistory.index.usePersonalLaborAndFinancialInformationModal.title'
    ),
  });

  const handlerInfos = useMemo(() => {
    if (!parsedData) return null;

    if (isEmpty(parsedData)) {
      return {
        title: translate(
          'pages.private.executions.components.dataSourceCards.laborInformation.people.components.workHistory.index.handlerInfos.isEmpty'
        ),
        type: 'empty',
      };
    }

    if (parsedData?.isEmployed) {
      return {
        title: translate(
          'pages.private.executions.components.dataSourceCards.laborInformation.people.components.workHistory.index.handlerInfos.isEmployed'
        ),
        type: 'primary',
      };
    }

    if (!parsedData?.isEmployed) {
      return {
        title: translate(
          'pages.private.executions.components.dataSourceCards.laborInformation.people.components.workHistory.index.handlerInfos.isNotEmployed'
        ),
        type: 'empty',
      };
    }

    return null;
  }, [parsedData, translate]);

  const handleOpenModal = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <>
      {PersonalLaborAndFinancialInformationModal}
      <DataSourceCardCollapsePanel
        title={translate(
          'pages.private.executions.components.dataSourceCards.laborInformation.people.components.workHistory.index.workHistory.columnLabel.title'
        )}
        customId="labor-infos-component"
        icon="caf-ic_awards"
        toPrint={toPrint}
      >
        {queryWorked ? (
          <>
            <ColumnLabel
              title={translate(
                'pages.private.executions.components.dataSourceCards.laborInformation.people.components.workHistory.index.workHistory.columnLabel.title'
              )}
              description={handlerInfos?.title}
              type={handlerInfos?.type}
              onClick={parsedData?.professions?.length > 0 && handleOpenModal}
            >
              {(isEmpty(parsedData) || !parsedData?.professions?.length > 0) && (
                <span>
                  {translate(
                    'pages.private.executions.components.dataSourceCards.laborInformation.people.components.workHistory.index.workHistory.columnLabel.querySucceeded.isEmpty'
                  )}
                </span>
              )}

              {!toPrint && parsedData?.professions?.length > 0 && (
                <span>
                  {translate(
                    'pages.private.executions.components.dataSourceCards.laborInformation.people.components.workHistory.index.workHistory.columnLabel.hasProfessions'
                  )}
                </span>
              )}
            </ColumnLabel>
          </>
        ) : (
          <EmptyExecutionState
            statusCode={statusCode}
            message={source?.message || parsedData?.message}
          />
        )}
      </DataSourceCardCollapsePanel>
    </>
  );
};

export default WorkHistory;
