import React from 'react';
import { Input } from 'antd';
import ReactInputMask from 'react-input-mask';

const InputMask = ({ disabled, ...rest }) => {
  return (
    <ReactInputMask {...rest} disabled={disabled}>
      {(inputProps) => <Input {...inputProps} disabled={disabled} />}
    </ReactInputMask>
  );
};

export default InputMask;
