import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Row, Col, Spin } from 'antd';
import PageTitle from 'components/PageTitle';
import EmptyMessage from 'components/EmptyMessage';

import { useGroup } from 'hooks/group';

import api from 'services/api';

import PermissionsGroup from './PermissionsGroup';

const GroupContent = ({ children }) => {
  const { t: translate } = useTranslation();
  const { id } = useParams();
  const { group } = useGroup();

  const [policyGroups, setPolicyGroups] = useState([]);
  const [loadingPolicyGroups, setLoadingPolicyGroups] = useState(false);

  const fetchPolicyGroups = useCallback(async ({ policies }) => {
    try {
      setLoadingPolicyGroups(true);

      const currentPolicyGroups = [];
      await Promise.all(
        policies.map(async (p) => {
          await api
            .get(`${process.env.REACT_APP_BASE_URL_AUTH_API}/policy-groups`, {
              params: {
                product: p,
                defaultPolicies: true,
              },
            })
            .then(({ data: response }) => {
              const docs = response?.docs || [];
              currentPolicyGroups.push(...docs);
            });
        })
      );

      setPolicyGroups(currentPolicyGroups);
    } catch (err) {
      // SILENCER
    } finally {
      setLoadingPolicyGroups(false);
    }
  }, []);

  const parsedPolicyGroups = useMemo(
    () =>
      policyGroups.reduce((result, item) => {
        (result[item.product] = result[item.product] || []).push(item);
        return result;
      }, {}),
    [policyGroups]
  );

  useEffect(() => {
    if (!fetchPolicyGroups) return;

    const groupProducts = group?.policyGroups || {};
    const parsedProducts = Object.keys(groupProducts);

    fetchPolicyGroups({ policies: parsedProducts });
  }, [fetchPolicyGroups, group, id]);

  return (
    <main>
      <section className="gx-layout-content gx-container-wrap">
        <PageTitle
          title={translate(
            'pages.private.groups.components.groupContent.index.pageTitle.title'
          )}
          subtitle={translate(
            'pages.private.groups.components.groupContent.index.pageTitle.subtitle'
          )}
        />

        {!policyGroups.length && loadingPolicyGroups && (
          <Spin className="flex center mrg-vertical-50 mrg-horizon-auto" />
        )}

        {!policyGroups.length && !loadingPolicyGroups && (
          <EmptyMessage
            withCard
            type="empty"
            description={translate(
              'pages.private.groups.components.groupContent.index.emptyMessage'
            )}
            className="mrg-top-25"
          />
        )}

        {!!policyGroups.length && !loadingPolicyGroups && (
          <Row gutter={[16, 16]} className="mrg-top-25">
            {Object.keys(parsedPolicyGroups)
              .sort((a, b) => a.localeCompare(b))
              .map((pg) => {
                const permissions = parsedPolicyGroups[pg];
                return (
                  <Col
                    key={pg}
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <PermissionsGroup product={pg} permissions={permissions} />
                  </Col>
                );
              })}
          </Row>
        )}
        {children}
      </section>
    </main>
  );
};

export default GroupContent;
