import { Col, Row, Divider, Modal, Typography } from 'antd';
import React, { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useExecution } from 'hooks/execution';

const { Title } = Typography;

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.companies.components.spcReportParticipationScore.registrationInfos.SubsidiariesInfoModal';

function SubsidiariesInfoModal() {
  const { t: translate } = useTranslation();
  const [visible, setVisible] = useState(false);

  const { execution } = useExecution();

  const spcInfos = useMemo(() => {
    return execution?.sections?.pjSpcCompanyReportParticipationScore;
  }, [execution]);

  const subsidiary = useMemo(() => spcInfos?.data?.subsidiary, [spcInfos]);

  const openModal = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  const closeModal = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const SubsidiariesInfoModalContent = (
    <Modal
      visible={visible}
      closable
      closeIcon={<i className="caf-ic_close font-size-18" />}
      onCancel={closeModal}
      footer={null}
      style={{ top: 40 }}
      width={675}
    >
      <>
        <Title>{translate(`${I18N_BASE_PATH}.title`)}</Title>
        <Divider />
        {subsidiary?.details?.map((item, index) => (
          <Col span={24}>
            <Row align="middle" justify="space-around">
              <Col span={12}>
                <span>{item?.subsidiaryName}</span>{' '}
              </Col>
              <Col span={12}>
                <span className="text-dark text-bold">
                  {translate(`${I18N_BASE_PATH}.columns.embratelCode`)}:
                </span>{' '}
                <span>{item?.codeEmbratel}</span>
              </Col>

              {index < subsidiary?.details?.length - 1 && <Divider />}
            </Row>
          </Col>
        ))}
      </>
    </Modal>
  );

  return { openModal, SubsidiariesInfoModalContent };
}

export default SubsidiariesInfoModal;
