import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Row } from 'antd';

import { toMaskedCpf } from 'utils/formatters';

import PageTitle from 'components/PageTitle';

import SourcesList from '../../../Lists/SourcesList';

import './styles.less';

const Owners = ({ profile, data }) => {
  const { t: translate } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const OwnersModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        onCancel={closeModal}
        footer={null}
        style={{ top: 40 }}
        width={750}
      >
        <div className="no-mrg no-pdd">
          <Row className="owners-modal-title reset-mrg">
            <PageTitle
              title={translate(
                'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.owners.ownersModal.index.ownersModal.pageTitle.title'
              )}
              subtitle={toMaskedCpf(data?.cpf) || '-'}
            />
          </Row>

          <SourcesList personalInfo profile={profile} />
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, data, profile, translate]
  );

  return { openModal, closeModal, OwnersModal };
};

export default Owners;
