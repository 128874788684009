/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Layout } from 'antd';
import { CardWrapper } from 'components/CardWrapper';

// import { useScroll } from 'hooks/scroll';

import Tabs from 'pages/private/GlobalProfiles/components/Tabs';

import PeopleSubheader from 'pages/private/GlobalProfiles/components/ProfileSubheader';

import './styles.less';
import { Notes } from '../Notes';

const { Content } = Layout;

const ProfileContent = ({ profile }) => {
  const { t: translate } = useTranslation();

  return (
    <main>
      <section id="profile-global-content">
        <Content
          id="profile-content-wrapper"
          className="gx-layout-content gx-container-wrap"
        >
          <Row>
            <Col span={24}>
              <CardWrapper hasPadding>
                <div className="mrg-btm-30">
                  <PeopleSubheader profile={profile} />
                </div>
                <div>
                  <Tabs />
                </div>
              </CardWrapper>
            </Col>

            {/* <Col span={6}>
              <CardWrapper style={{ minHeight: '0px' }}>
                <Notes />
              </CardWrapper>
            </Col> */}
          </Row>
        </Content>
      </section>
    </main>
  );
};

export default ProfileContent;
