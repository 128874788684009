/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import EmptyState from 'components/EmptyState';
import TitleDivider from 'components/Divider/TitleDivider';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import OwnersCard from '../../components/Owners/OwnersCard';

const OwnersList = ({ profile /* urlId, toPrint */ }) => {
  const { t: translate } = useTranslation();

  const kycComplianceOwners = useMemo(() => profile?.others?.kycComplianceOwners, [
    profile,
  ]);

  const isProcessing = useMemo(
    () => verifySourcesProcessing(profile, 'kycComplianceOwners'),
    [profile]
  );

  const parsedOwnersList = useMemo(() => {
    if (!kycComplianceOwners || !kycComplianceOwners?.items?.length > 0) return <></>;

    const el = [];
    const _size = kycComplianceOwners?.items?.length;

    if (_size) {
      for (let i = 0; i < _size; i += 2) {
        el.push(
          <Row className="owners-list-row">
            {kycComplianceOwners?.items?.slice(i, i + 2)?.map((owner) => (
              <Col key={owner?.cpf} span={12} className="owners-card-data">
                <OwnersCard profile={profile} data={owner} />
              </Col>
            ))}
          </Row>
        );
      }
    }
    return el.length > 0 ? el : <></>;
  }, [kycComplianceOwners, profile]);

  return (
    <Row id="owners-list-container">
      <Col span={24} className="owners-list-col">
        <TitleDivider
          title={translate(
            'pages.private.profiles.components.dataSourceCards.restrictiveLists.lists.ownersList.index.ownersList.titleDivider.title'
          )}
          customTop="0px"
          customBottom="45px"
        />

        {!kycComplianceOwners && isProcessing && (
          <EmptyState type="processing" className="mrg-top-35" />
        )}

        {!kycComplianceOwners && !isProcessing && (
          <EmptyState type="waiting_consult" className="mrg-top-35" />
        )}

        {kycComplianceOwners && !kycComplianceOwners?.items?.length > 0 && (
          <EmptyState
            type="empty-success"
            description={translate(
              'pages.private.profiles.components.dataSourceCards.restrictiveLists.lists.ownersList.index.ownersList.emptyState.description'
            )}
            className="mrg-top-35"
          />
        )}

        {parsedOwnersList}
      </Col>
    </Row>
  );
};

export default OwnersList;
