import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Select, DatePicker, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import './styles.less';
import { i18nMomentFormats as dateFormats } from 'i18n/utils/i18nFormats';

import BarChart, { chartDataType } from './BarChart';

const defaultSelectValue = 'all';

const { RangePicker } = DatePicker;

const Chart = ({ chartData, date, onDateChange, loading, hidePrice, currencyCode }) => {
  const [selectedDates, setSelectedDates] = useState();
  const [selectedProduct, setSelectedProduct] = useState(defaultSelectValue);

  const { t: translate, i18n } = useTranslation();

  const handleProductChange = (value) => {
    setSelectedProduct(value ?? defaultSelectValue);
  };

  const handleClearFilters = () => {
    setSelectedProduct(defaultSelectValue);
    onDateChange(null);
  };

  const disabledDate = useCallback(
    (current) => {
      if (!current) return;

      if (current > moment()) {
        return true;
      }

      if (date) {
        return (
          (date[0] && current > moment(date[0]).add(12, 'months')) ||
          (date[1] && current < moment(date[1]).subtract(12, 'months'))
        );
      }

      if (selectedDates) {
        return (
          (selectedDates[0] && current > moment(selectedDates[0]).add(12, 'months')) ||
          (selectedDates[1] && current < moment(selectedDates[1]).subtract(12, 'months'))
        );
      }
    },
    [date, selectedDates]
  );

  const handleRangeSelectDateChange = (dates) => {
    setSelectedDates(dates);
  };

  const handleRangeSelectOpenChange = (open) => {
    if (open) {
      setSelectedDates([null, null]);
    } else {
      setSelectedDates(null);
    }
  };

  return (
    <div className="chart">
      <div className="chart__header">
        <Select
          showSearch
          optionFilterProp="label"
          className="chart__select"
          defaultValue={defaultSelectValue}
          allowClear
          disabled={loading || chartData?.products?.length === 0}
          value={selectedProduct}
          onChange={handleProductChange}
          options={[
            {
              value: defaultSelectValue,
              label: translate('pages.private.consumption.chart.select.all'),
            },
          ].concat(
            chartData?.products?.map((productId) => ({
              value: productId,
              label: chartData.productMap[productId],
            })) ?? []
          )}
        />
        <RangePicker
          value={date}
          onChange={onDateChange}
          disabledDate={disabledDate}
          onCalendarChange={handleRangeSelectDateChange}
          disabled={loading}
          format={dateFormats[2][i18n.language]}
          onOpenChange={handleRangeSelectOpenChange}
        />
        <Button onClick={handleClearFilters} type="primary">
          {translate('pages.private.consumption.chart.clearFilters')}
        </Button>
      </div>
      <BarChart
        loading={loading}
        chartData={chartData}
        selectedProduct={selectedProduct}
        hidePrice={hidePrice}
        currencyCode={currencyCode}
      />
    </div>
  );
};

Chart.propTypes = {
  chartData: chartDataType,
  date: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  onDateChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  hidePrice: PropTypes.bool,
  currencyCode: PropTypes.string.isRequired,
};

Chart.defaultProps = {
  chartData: {
    data: [],
    products: [],
    productMap: {},
  },
  loading: false,
  hidePrice: false,
};

export default Chart;
