import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Modal, Select } from 'antd';

import Button from 'components/Button';
import PageTitle from 'components/PageTitle';
import RectangleButton from 'components/Button/RectangleButton';
import './styles.less';
import { useHistory } from 'react-router-dom';

const I18N_BASE_PATH = 'pages.private.routines.list.components.modalNewRoutine';

const ModalNewRoutine = () => {
  const { t: translate } = useTranslation();
  const history = useHistory();

  const [form] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);
  const [type, setType] = useState('recurrence');
  const [profileType, setProfileType] = useState('PF');

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setTimeout(() => {
      form.resetFields();
    }, 1000);
  }, [form]);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const onSubmit = useCallback(async () => {
    // await form.validateFields();
    history.push(`/routines/new?type=${type}&profileType=${profileType}`);
    closeModal();
  }, [history, type, profileType, closeModal]);

  const submitButtonText = useMemo(() => {
    return translate(`${I18N_BASE_PATH}.button.submit`);
  }, [translate]);

  const pageSubtitle = useMemo(() => {
    return translate(`${I18N_BASE_PATH}.pageSubtitle`);
  }, [translate]);

  const CustomModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeModal}
        footer={null}
        width={580}
        destroyOnClose
      >
        <div id="modal-new-routine-custom-component" className="no-mrg no-pdd">
          <PageTitle
            title={translate(`${I18N_BASE_PATH}.pageTitle`)}
            subtitle={pageSubtitle}
          />

          <Form form={form} layout="vertical" className="mrg-top-40">
            <Row>
              <Col span={12}>
                <Form.Item
                  name="type"
                  label={translate(`${I18N_BASE_PATH}.form.type.label`)}
                  rules={[{ required: true }]}
                >
                  <Select defaultValue={type} onChange={(value) => setType(value)}>
                    <Select.Option value="recurrence">
                      {translate(`${I18N_BASE_PATH}.form.types.recurrence`)}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className="mrg-btm-30">
              <Col span={12}>
                <RectangleButton
                  title={translate(`${I18N_BASE_PATH}.form.options.person.title`)}
                  description={translate(
                    `${I18N_BASE_PATH}.form.options.person.description`
                  )}
                  icon="caf-ic_person"
                  selected={profileType === 'PF'}
                  onClick={() => {
                    setProfileType('PF');
                  }}
                />
              </Col>
              <Col span={12}>
                <RectangleButton
                  title={translate(`${I18N_BASE_PATH}.form.options.company.title`)}
                  description={translate(
                    `${I18N_BASE_PATH}.form.options.company.description`
                  )}
                  icon="caf-ic_company"
                  selected={profileType === 'PJ'}
                  onClick={() => {
                    setProfileType('PJ');
                  }}
                />
              </Col>
            </Row>
            <div className="gx-flex-row gx-justify-content-end mrg-top-20">
              <Button type="default" onClick={closeModal} className="btn-cancel">
                {translate(`${I18N_BASE_PATH}.button.cancel`)}
              </Button>
              <Button type="primary" onClick={onSubmit}>
                {submitButtonText}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      translate,
      pageSubtitle,
      form,
      profileType,
      type,
      onSubmit,
      submitButtonText,
    ]
  );

  return { openModal, closeModal, CustomModal };
};

export default ModalNewRoutine;
