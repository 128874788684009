/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import EmptyState from 'components/EmptyState';
import EmptyExecutionState from 'components/EmptyExecutionState';
import TitleDivider from 'components/Divider/TitleDivider';

// import { useExecution } from 'hooks/execution';
import OwnersCard from '../../components/Owners/OwnersCard';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

const OwnersList = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  // const { execution } = useExecution();

  const kycComplianceOwnersData = useMemo(() => source.data, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const querySucceeded = useMemo(
    () =>
      source?.statusCode === '01' ||
      source?.available === true ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [source]
  );

  // const kycComplianceOwnersItems = data?.items;
  // const lastConsultationOwners = data?.lastConsultation?.date;

  const parsedOwnersList = useMemo(() => {
    if (!kycComplianceOwnersData || !kycComplianceOwnersData?.length > 0) return <></>;

    const el = [];
    const _size = kycComplianceOwnersData?.length;

    if (_size) {
      for (let i = 0; i < _size; i += 2) {
        el.push(
          <Row className="owners-list-row">
            {kycComplianceOwnersData?.slice(i, i + 2)?.map((owner) => (
              <Col key={owner?.cpf} span={12} className="owners-card-data">
                <OwnersCard profile={source} data={owner} />
              </Col>
            ))}
          </Row>
        );
      }
    }
    return el.length > 0 ? el : <></>;
  }, [kycComplianceOwnersData, source]);

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_lawier"
      title={translate(
        'components.dataSourceCards.restrictiveLists.lists.ownersList.index.ownersList.titleDivider.title'
      )}
      customId="restrictiveLists"
      toPrint={toPrint}
    >
      <Row id="owners-list-container">
        <Col span={24} className="owners-list-col">
          <TitleDivider
            title={translate(
              'components.dataSourceCards.restrictiveLists.lists.ownersList.index.ownersList.titleDivider.title'
            )}
            customTop="0px"
            // customBottom={kycComplianceOwners ? '35px' : '20px'}
            customBottom="45px"
          />

          {/* {!data && (
          <EmptyState type="empty-recurrence" urlId={urlId} hiddenAction={toPrint} />
        )} */}

          {querySucceeded ? (
            <>
              {kycComplianceOwnersData && !kycComplianceOwnersData?.length > 0 && (
                <EmptyState
                  type="empty-success"
                  description={translate(
                    'components.dataSourceCards.restrictiveLists.lists.ownersList.index.ownersList.emptyState.description'
                  )}
                  className="mrg-top-35"
                />
              )}

              {parsedOwnersList}
            </>
          ) : (
            <EmptyExecutionState
              statusCode={statusCode}
              message={source.message || kycComplianceOwnersData?.message}
              className="mrg-top-35"
            />
          )}
        </Col>
      </Row>
    </DataSourceCardCollapsePanel>
  );
};

export default OwnersList;
