/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';

// import currencyFormatter from 'utils/currencyFormatter';

import './styles.less';

// const { toCurrency } = currencyFormatter;

const SPCInfos = ({ data }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatCurrency } = useI18nFormatters();

  const restriction = data?.restriction;
  const _class = data?.spcScore12Months?.class;
  const score = data?.spcScore12Months?.score;
  const debt = data?.details?.totalAmount;

  return (
    <Row id="executions-spc-row-infos" className="reset-mrg">
      <Col span={7} className="spc-col-info no-left">
        <strong>
          {translate(
            'components.dataSourceCards.financialInformation.shared.spc.spcInfos.index.spcInfos.restriction.description'
          )}
          :
        </strong>
        <span>
          {translate(
            `components.dataSourceCards.financialInformation.shared.spc.spcInfos.index.spcInfos.restriction.value.${
              restriction ? 'hasRestriction' : 'hasNotRestriction'
            }`
          )}
        </span>
      </Col>
      <Col span={7} className="spc-col-info">
        <strong>
          {translate(
            'components.dataSourceCards.financialInformation.shared.spc.spcInfos.index.spcInfos.class.description'
          )}
          :
        </strong>
        <div className="spc-class-info">
          <span>{_class || '-'}</span>
          <span>
            {translate(
              'components.dataSourceCards.financialInformation.shared.spc.spcInfos.index.spcInfos.class.value'
            )}
          </span>
        </div>
      </Col>
      <Col span={5} className="spc-col-info">
        <strong>
          {translate(
            'components.dataSourceCards.financialInformation.shared.spc.spcInfos.index.spcInfos.score.description'
          )}
          :
        </strong>
        <span>{score || '-'}/1000</span>
      </Col>
      <Col span={5} className="spc-col-info no-right end">
        <strong>
          {translate(
            'components.dataSourceCards.financialInformation.shared.spc.spcInfos.index.spcInfos.debt.description'
          )}
          :
        </strong>
        <span>
          {i18nFormatCurrency(debt, 'pt-BR') ||
            translate(
              'components.dataSourceCards.financialInformation.shared.spc.spcInfos.index.spcInfos.debt.value'
            )}{' '}
        </span>
      </Col>
    </Row>
  );
};

export default SPCInfos;
