import React from 'react';
import logoToPrint from 'assets/images/caf-logo-extended.svg';
import { Col, Row } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { formatISO } from 'date-fns';
import { useAuth } from 'hooks/auth';
import {
  CompanyBody1,
  CompanyExecutionStatus,
} from 'pages/private/KYBCompany/CompanyDetailsV2/components';

const ROOT = 'pages.private.KYBCompany.pages.companyDetails';
const I18N_BASE_PATH = `${ROOT}.print.header`;
const IDENTIFICATION = `${ROOT}.subPages.summary.cards.companyIdentificationInformation`;

const CompanyPrintHeader = ({ companyName, identifier, executionStatus, createdAt }) => {
  const { t } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();
  const { user } = useAuth();

  return (
    <header className="text-dark kyb-company-print-header">
      <Row>
        <Col span={7}>
          <img src={logoToPrint} alt="Logotipo" className="kyb-company-print-logo" />
        </Col>
        <Col span={17} className="kyb-company-print-details">
          <b>{t(`${I18N_BASE_PATH}.executionDetails`, 'Execution details')}:</b> <br />
          <Trans
            i18nKey={`${I18N_BASE_PATH}.exportedIn`}
            values={{
              date: i18nFormatDate(formatISO(new Date()), 'default'),
              user: user.name,
            }}
          />
          <br />
          <Trans
            i18nKey={`${I18N_BASE_PATH}.createdAt`}
            values={{
              date: i18nFormatDate(createdAt, 'default'),
            }}
          />
        </Col>
      </Row>
      <Row className="mrg-top-30">
        <Col span={7}>
          <CompanyBody1 className="kyb-company-print-name">
            <strong>{companyName}</strong>
          </CompanyBody1>
          <CompanyBody1 className="kyb-company-print-identifier">
            {t(`${IDENTIFICATION}.identifier`)}: {identifier}
          </CompanyBody1>
        </Col>
        <Col span={17}>
          <CompanyExecutionStatus status={executionStatus} />
        </Col>
      </Row>
    </header>
  );
};

export default CompanyPrintHeader;
