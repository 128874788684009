import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { useQueryParams, StringParam } from 'use-query-params';

import './styles.less';

const { Option } = Select;

const I18N_BASE_PATH = 'pages.private.executions.components.list.components.sortSelect';

const SortSelect = ({ ...rest }) => {
  const { t: translate } = useTranslation();

  const [query, setQuery] = useQueryParams({
    _sort: StringParam,
  });

  const [value, setValue] = useState(query._sort);

  useEffect(() => {
    setValue(() => {
      if (query._sort === 'date') {
        return 'asc';
      }
      return 'desc';
    });
  }, [query]);

  const handleOnChange = useCallback(
    (newValue) => {
      if (newValue === 'asc') {
        setQuery({ _sort: 'date' });
      }
      if (newValue === 'desc') {
        setQuery({ _sort: undefined });
      }
    },
    [setQuery]
  );

  return (
    <Select
      bordered={false}
      style={{ minWidth: 170, fontSize: '14px' }}
      placeholder="Última atualização"
      onChange={handleOnChange}
      value={value}
      className="text-dark"
      {...rest}
    >
      <Option value="asc">{translate(`${I18N_BASE_PATH}.asc`)}</Option>
      <Option value="desc">{translate(`${I18N_BASE_PATH}.desc`)}</Option>
    </Select>
  );
};

export default SortSelect;
