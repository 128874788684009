import { useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

function getModalControlName(id) {
  return `moreRelationships-modalControl-${id}-wasOpened`;
}

const useKybMoreRelationships = ({ businessIdentityId }) => {
  const id = businessIdentityId;
  const [, setQuery] = useQueryParams({
    tab: StringParam,
    subTab: StringParam,
  });
  const [modalTermVisible, setModalTermVisible] = useState(false);
  const [hasAccepted, setHasAccepted] = useState(
    Boolean(JSON.parse(localStorage.getItem(getModalControlName(id)))) ?? false
  );

  const [hasAcceptedExtendRelationships, setHasAcceptedExtendRelationships] = useState(
    Boolean(JSON.parse(localStorage.getItem(`hasExtendedRelationship-${id}`))) ?? false
  );

  function getIsSubTab(subTab) {
    return subTab === 'moreRelationships';
  }

  function getModalControlTermData(id) {
    const name = getModalControlName(id);
    const value = hasAccepted;
    return [name, value];
  }

  function showModalTerm() {
    const [, value] = getModalControlTermData(id);
    if (!value) {
      setModalTermVisible(true);
    }
  }

  function showModalToExpandRelationships() {
    setModalTermVisible(true);
  }

  function isSubTabWithAcceptTerm(subTab) {
    return getIsSubTab(subTab) && hasAccepted;
  }

  function canGoToSubTab(subTab) {
    return getIsSubTab(subTab) && isSubTabWithAcceptTerm(subTab);
  }

  function isToBlockMoreRelationshipsSubTab(subTab) {
    return getIsSubTab(subTab) && !canGoToSubTab(subTab);
  }

  function handleModalTermControl(value) {
    const [name] = getModalControlTermData(id);
    if (!hasAccepted) {
      localStorage.setItem(name, value);
      setHasAccepted(value);
    } else {
      setHasAcceptedExtendRelationships(value);
    }
  }

  function handleAcceptTerm() {
    handleModalTermControl(true);
    setModalTermVisible(false);
    setQuery({ subTab: 'moreRelationships' });
  }

  function handleRejectTerm() {
    handleModalTermControl(false);
    setModalTermVisible(false);
  }

  return {
    modalTermVisible,
    hasAcceptedExtendRelationships,
    handleAcceptTerm,
    handleRejectTerm,
    isSubTabWithAcceptTerm,
    isToBlockMoreRelationshipsSubTab,
    canGoToSubTab,
    getIsSubTab,
    showModalTerm,
    showModalToExpandRelationships,
    setHasAcceptedExtendRelationships,
  };
};

export default useKybMoreRelationships;
