/* eslint-disable no-nested-ternary */
import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { convertGlobalDocVerificationResultToIcon } from '../../../utils/convertGlobalDocVerificationResultToIcon';

import './styles.less';

const I18N_BASE_PATH =
  'pages.private.executions.components.tabs.components.documentsValidations.newOcrScreen.documentFields';

const DocumentFields = ({ data }) => {
  const { t: translate } = useTranslation();

  const tableColumns = [
    {
      title: translate(`${I18N_BASE_PATH}.fieldType`),
      dataIndex: 'fieldType',
      key: 'fieldType',
    },
    {
      title: 'MRZ',
      dataIndex: 'mrz',
      key: 'mrz',
      render: (_, { mrz }) => <span className={mrz?.type}>{mrz?.result || '-'}</span>,
    },
    {
      title: translate(`${I18N_BASE_PATH}.visualZone`),
      dataIndex: 'visualZone',
      key: 'visualZone',
      render: (_, { visualZone }) => (
        <span className={visualZone?.type}>{visualZone?.result || '-'}</span>
      ),
    },
    {
      title: 'MRZ-Viz',
      dataIndex: 'mrzViz',
      key: 'mrzViz',
    },
    {
      title: translate(`${I18N_BASE_PATH}.valid`),
      dataIndex: 'valid',
      key: 'valid',
      render: (_, { valid }) => valid.icon,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.valid.value - b.valid.value,
    },
  ];

  const tableData = Object.keys(data?.fields ?? {})?.map((field, index) => ({
    key: index,
    fieldType: data?.fields?.[field]?.name,
    mrz: {
      type: [0, 1].includes(data?.fields?.[field]?.mrz?.sourceValidity)
        ? data?.fields?.[field]?.mrz?.sourceValidity === 1
          ? 'approval-diagnose'
          : 'failure-diagnose'
        : '',
      result: data?.fields?.[field]?.mrz?.value || '-',
    },
    visualZone: {
      type: [0, 1].includes(data?.fields?.[field]?.visual?.sourceValidity)
        ? data?.fields?.[field]?.visual?.sourceValidity === 1
          ? 'approval-diagnose'
          : 'failure-diagnose'
        : '',
      result: data?.fields?.[field]?.visual?.value || '-',
    },
    mrzViz: convertGlobalDocVerificationResultToIcon({
      checkResult: data?.fields?.[field]?.comparisonStatus,
      className: 'font-size-18',
    }),
    valid: {
      value: data?.fields?.[field]?.status,
      icon: convertGlobalDocVerificationResultToIcon({
        checkResult: data?.fields?.[field]?.status,
        className: 'font-size-18',
      }),
    },
  }));

  return (
    tableData?.length > 0 && (
      <div id="document-fields-container">
        <Table
          columns={tableColumns}
          dataSource={tableData}
          pagination={{ pageSize: 5 }}
          tableLayout="fixed"
        />
      </div>
    )
  );
};

DocumentFields.propTypes = {
  data: PropTypes.shape({
    fields: PropTypes.shape({
      [PropTypes.string]: PropTypes.shape({
        name: PropTypes.string,
        mrz: PropTypes.shape({
          sourceValidity: PropTypes.oneOf([0, 1, 2]),
          value: PropTypes.string,
        }),
        visual: PropTypes.shape({
          sourceValidity: PropTypes.oneOf([0, 1, 2]),
          value: PropTypes.string,
        }),
        comparisonStatus: PropTypes.oneOf([0, 1, 2]),
        status: PropTypes.oneOf([0, 1, 2]),
      }),
    }),
  }).isRequired,
};

export default DocumentFields;
