export const hasFinancialInformation = (profile, dataSources) => {
  return (
    dataSources?.includes('pj_spc') ||
    dataSources?.includes('pj_protesto') ||
    dataSources?.includes('pj_pgfn') ||
    dataSources?.includes('pj_cadin_sp') ||
    profile?.finance?.spc ||
    profile?.finance?.protest ||
    profile?.certificates?.pgfn ||
    profile?.sections?.pjPgfn ||
    profile?.sections?.pjCoaf ||
    profile?.sections?.pjCadinSp ||
    profile?.sections?.pjEcac
  );
};

export const hasLaborInformation = (profile, dataSources) => {
  return (
    dataSources?.includes('pj_fgts') ||
    dataSources?.includes('pj_sintegra') ||
    dataSources?.includes('pj_cndt') ||
    profile?.certificates?.fgts ||
    profile?.certificates?.sintegra ||
    profile?.certificates?.cndt ||
    profile?.sections?.pjSimples ||
    profile?.sections?.pjSimplesNacional ||
    profile?.sections?.pjSintegra ||
    profile?.sections?.pjFgts ||
    profile?.sections?.pjTcuApfCertificates ||
    profile?.sections?.pjCnd ||
    profile?.sections?.pjCndOccupational ||
    profile?.sections?.pjSiproquimCertificate ||
    profile?.sections?.pjSuframa
  );
};

export const hasLegalInformation = (profile, dataSources) => {
  return (
    dataSources?.includes('pj_processes') ||
    dataSources?.includes('pj_processes_alt') ||
    profile?.justice?.processes ||
    profile?.sections?.pjProcesses ||
    profile?.sections?.pjProcessesAlt
  );
};

export const hasRestrictiveListsInformation = (profile, dataSources) => {
  return (
    dataSources?.includes('pj_kyc_compliance_owners') ||
    dataSources?.includes('pj_kyc_compliance') ||
    profile?.others?.kycCompliance ||
    profile?.others?.kycComplianceOwners ||
    profile?.sections?.pjKycCompliance ||
    profile?.sections?.kycComplianceOwners
  );
};

export const hasTrustInformation = (profile, dataSources) => {
  return (
    hasFinancialInformation(profile, dataSources) ||
    hasLaborInformation(profile, dataSources) ||
    hasLegalInformation(profile, dataSources) ||
    hasRestrictiveListsInformation(profile, dataSources)
  );
};
