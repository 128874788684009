import i18n from 'i18next';
import Cookies from 'js-cookie';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const languagesAvailable = ['pt-BR', 'en-US', 'es-MX'];

const isLocalhost = !!window.location.origin.startsWith('http://localhost');

const LANGUAGE = isLocalhost ? 'CAF_language' : '__Secure-CAF_language';

const languageSelected =
  Cookies.get(LANGUAGE) !== undefined && Cookies.get(LANGUAGE) !== 'undefined'
    ? Cookies.get(LANGUAGE)
    : window.navigator.language;

const i18nConfig = {
  partialBundledLanguages: true,
  ns: ['locales'],
  defaultNS: 'locales',
  fallbackLng: 'pt-BR',
  lng: languagesAvailable.includes(languageSelected) ? languageSelected : 'pt-BR',
  whitelist: languagesAvailable,
  react: {
    wait: false,
    useSuspense: false,
  },
  interpolation: {
    escapeValue: false,
  },
  backend: {
    loadPath: '/{{ns}}/{{lng}}.json',
  },
};

i18n.use(Backend).use(initReactI18next).init(i18nConfig);

export default i18n;
