const I18N_BASE_PATH =
  'pages.private.profiles.components.dossierPrint.utils.checkboxOptions';

const peopleOptions = [
  {
    _id: 'registration_infos',
    label: `${I18N_BASE_PATH}.peopleOptions.registrationInfos`,
    icon: 'caf-ic_file',
    order: 1,
  },
  {
    _id: 'address',
    label: `${I18N_BASE_PATH}.peopleOptions.address`,
    icon: 'caf-ic_map_pin',
    order: 2,
  },
  {
    _id: 'contacts',
    label: `${I18N_BASE_PATH}.peopleOptions.contacts`,
    icon: 'caf-ic_contact_',
    order: 3,
  },
  {
    _id: 'identity',
    label: `${I18N_BASE_PATH}.peopleOptions.identity`,
    icon: 'caf-ic_onboarding',
    order: 4,
  },
  {
    _id: 'independent_consultants',
    label: `${I18N_BASE_PATH}.peopleOptions.independentConsultants`,
    icon: 'caf-ic_company',
    order: 5,
  },
  // {
  //   _id: 'activities',
  //   label: `${I18N_BASE_PATH}.peopleOptions.activities`,
  //   icon: 'caf-ic_clock',
  //   order: 15,
  // },
];

const companiesOptions = [
  {
    _id: 'registration_infos',
    label: `${I18N_BASE_PATH}.companiesOptions.registrationInfos`,
    icon: 'caf-ic_file',
    order: 1,
  },
  {
    _id: 'qsa',
    label: `${I18N_BASE_PATH}.companiesOptions.qsa`,
    icon: 'caf-ic_user_active',
    order: 2,
  },
  {
    _id: 'address',
    label: `${I18N_BASE_PATH}.companiesOptions.address`,
    icon: 'caf-ic_map_pin',
    order: 3,
  },
  {
    _id: 'contacts',
    label: `${I18N_BASE_PATH}.companiesOptions.contacts`,
    icon: 'caf-ic_contact_',
    order: 4,
  },
  // {
  //   _id: 'activities',
  //   label: `${I18N_BASE_PATH}.companiesOptions.activities`,
  //   icon: 'caf-ic_clock',
  //   order: 10,
  // },
];

export const checkboxOptions = (type) => {
  return type === 'PF' ? peopleOptions : companiesOptions;
};
