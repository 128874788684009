import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Collapse, Select } from 'antd';

import { useReports } from 'hooks/reports';

import { useProfileTemplate } from 'hooks/profileTemplate';

const { Panel } = Collapse;
const { Option, OptGroup } = Select;

const ReportsFilter = ({ title, value, onChange }) => {
  const { t: translate } = useTranslation();
  const { allReportsList = [], loadingAllReports } = useReports();

  const {
    loadProfileTemplates,
    allProfileTemplates,
    loadingAllProfileTemplates,
  } = useProfileTemplate();

  useEffect(() => {
    loadProfileTemplates({
      active: true,
    });
  }, [loadProfileTemplates]);

  const reportsNotVinculatedToProfileTemplates = useMemo(() => {
    const profileTemplatesDocs = [...allProfileTemplates];

    // pega todos os ids de relatórios vinculados em modelos de consulta
    const refReportIds = profileTemplatesDocs
      .map((pt) => pt.refReportId)
      .filter((pt) => pt);

    // filtra todos os relatórios que não estão vinculados aos modelos de consulta
    return allReportsList?.filter((report) => !refReportIds.includes(report?._id)) || [];
  }, [allProfileTemplates, allReportsList]);

  const formattedValues = useMemo(() => {
    const profileTemplatesDocs = [...allProfileTemplates];

    // faz um parse de id de template com id de relatório
    return value
      .map((val) => {
        // busca se tem um template com o id passado
        const template = profileTemplatesDocs.find((t) => t._id === val);
        // busca se tem um relatório com o id passado na lista dos que não estão vinculados
        // se ele for vinculado, ele deve aparecer como template, não como relatório
        const report = reportsNotVinculatedToProfileTemplates.find((r) => r?._id === val);
        // remover duplicados
        if (!template && !report) return undefined;

        const combinedKey = `${template?._id || null}|${
          template?.refReportId || report?._id || null
        }`;

        return combinedKey;
      })
      .filter((val) => val);
  }, [allProfileTemplates, value, reportsNotVinculatedToProfileTemplates]);

  return (
    <Collapse
      ghost
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <div>
          <i className={isActive ? 'caf-ic_arrow_up' : 'caf-ic_arrow_down'} />
        </div>
      )}
    >
      <Panel header={title} key="reports">
        <Select
          mode="multiple"
          value={formattedValues}
          onChange={(values) => onChange(values)}
          loading={loadingAllProfileTemplates || loadingAllReports}
          disabled={loadingAllProfileTemplates || loadingAllReports}
          placeholder={translate(
            'components.filters.executionProfileTemplatesFilter.collapse.panel.select.placeholder'
          )}
          dropdownClassName="multiselect-filters"
          dropdownAlign={{ offset: [0, 2] }}
          removeIcon={<i className="caf-ic_circle_false" />}
          menuItemSelectedIcon={<i className="caf-ic_checkmark" />}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => {
            return option?.title?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
          }}
        >
          {allProfileTemplates?.map((template) => {
            const { name, _id, refReportId } = template || {};

            return (
              <Option key={_id} value={`${_id}|${refReportId || null}`} title={name}>
                <div className="multiselect-option">
                  <span className="option-title">{name}</span>
                </div>
              </Option>
            );
          })}

          {reportsNotVinculatedToProfileTemplates && (
            <OptGroup label="Outros relatórios" className="text-gray">
              {reportsNotVinculatedToProfileTemplates?.map((report) => {
                const { _id, name } = report || {};
                return (
                  <Option key={_id} value={`${null}|${_id}`} title={name}>
                    <div className="multiselect-option">
                      <span className="option-title">{name}</span>
                    </div>
                  </Option>
                );
              })}
            </OptGroup>
          )}
        </Select>
      </Panel>
    </Collapse>
  );
};

ReportsFilter.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ReportsFilter;
