const statusTag = {
  COMPLETED: {
    color: 'success',
    title:
      'pages.private.profileModels.profileModelList.components.links.components.cardLink.utils.statusTag.COMPLETED.title',
    icon: 'caf-ic_circle_true',
  },
  PENDING: {
    color: 'warning',
    title:
      'pages.private.profileModels.profileModelList.components.links.components.cardLink.utils.statusTag.PENDING.title',
    icon: 'caf-ic_circle_hold',
  },
  oneTimeLinkAcessed: {
    color: 'default',
    title:
      'pages.private.profileModels.profileModelList.components.links.components.cardLink.utils.statusTag.oneTimeLinkAcessed.title',
    icon: 'caf-ic_circle_true',
  },
  noExpire: {
    color: 'processing',
    title:
      'pages.private.profileModels.profileModelList.components.links.components.cardLink.utils.statusTag.noExpire.title',
    icon: 'caf-ic_check_d',
  },
};

export default statusTag;
