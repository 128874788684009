import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col, Button, Collapse, Tag } from 'antd';

import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';

import EmptyState from 'components/EmptyState';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import AttorneyDetails from 'pages/private/Executions/components/Tabs/components/BasicInfos/components/Company/AttorneyDetails';
import BasicInfosCard from '../BasicInfosCard';
import QSAPartnerDetails from './QSAPartnerDetails';
import SerasaExperianPartnerDetails from './SerasaExperianPartnerDetails';

const { Panel } = Collapse;

const QSAInfosContent = ({ company, toPrint }) => {
  const { t: translate } = useTranslation();

  const [isPanelOpened, setIsPanelOpened] = useState(false);

  const QSAItems = useMemo(() => company?.QSA?.items, [company]);

  const attorneyItems = useMemo(() => {
    const executions = company?.executions;
    const qsaFromExecutions = executions?.flatMap((execution) =>
      execution?.parameters?.qsa?.filter((qsa) => qsa?.attorney?.isAttorney)
    );

    const filteredQSA = qsaFromExecutions
      ?.reduce((acc, curr) => {
        const existingItem = acc.find(
          (item) =>
            item?.name === curr?.name &&
            item?.cpf === curr?.cpf &&
            item?.attorney?.qsaName === curr?.attorney?.qsaName &&
            item?.attorney?.qsaTaxId === curr?.attorney?.qsaTaxId &&
            item?.attorney?.type === curr?.attorney?.type
        );

        if (!existingItem) {
          acc.push(curr);
        }

        return acc;
      }, [])
      .filter((item) => item !== undefined);

    return filteredQSA || [];
  }, [company]);

  const serasaExperianItems = useMemo(() => company?.serasaExperian?.qsa?.items, [
    company,
  ]);

  const QSAIsProcessing = useMemo(() => verifySourcesProcessing(company, 'QSA'), [
    company,
  ]);
  const serasaExperianIsProcessing = useMemo(
    () => verifySourcesProcessing(company, 'serasaExperian'),
    [company]
  );

  const QSAPartnersCount = useMemo(() => {
    if (QSAItems?.length === 1) {
      return translate(
        'pages.private.profiles.components.tabs.components.basicInfos.components.company.qsaInfosContent.qsaPartnersCount.onePerson'
      );
    }
    if (QSAItems?.length > 1) {
      return translate(
        'pages.private.profiles.components.tabs.components.basicInfos.components.company.qsaInfosContent.qsaPartnersCount.moreThanOnePerson',
        { data: QSAItems?.length }
      );
    }

    return <></>;
  }, [QSAItems, translate]);

  const serasaExperianPartnersCount = useMemo(() => {
    if (serasaExperianItems?.length === 1) {
      return translate(
        'pages.private.profiles.components.tabs.components.basicInfos.components.company.qsaInfosContent.serasaExperianPartnersCount.onePerson'
      );
    }

    if (serasaExperianItems?.length > 1) {
      return translate(
        'pages.private.profiles.components.tabs.components.basicInfos.components.company.qsaInfosContent.serasaExperianPartnersCount.moreThanOnePerson',
        { data: serasaExperianItems?.length }
      );
    }

    return <></>;
  }, [serasaExperianItems, translate]);

  const onClickHandler = useCallback(() => {
    setIsPanelOpened((oldState) => !oldState);
  }, []);

  const renderPartnerToPrint = (partner) => (
    <div key={`${partner.name}`} className="legal-representative-container">
      <span>
        {partner.name} ({partner.function}){' '}
      </span>
      {partner.isLegalRepresentative === true && (
        <Tag className="legal-representative-tag">
          {translate(
            'pages.private.profiles.components.tabs.components.basicInfos.components.company.qsaInfosContent.renderPartnerToPrint.isLegalRepresentative'
          )}
        </Tag>
      )}
    </div>
  );

  return (
    <BasicInfosCard
      customId="qsa-content"
      icon="caf-ic_user_active"
      title={translate(
        'pages.private.profiles.components.tabs.components.basicInfos.components.company.qsaInfosContent.qsaInfosContent.basicInfosCard.title'
      )}
      data={{ ...(company?.QSA || {}), status: company?.status }}
      dataIndex="items"
    >
      <Row>
        <ColumnTitleValue
          span={24}
          className="mrg-btm-30"
          title={translate(
            'pages.private.profiles.components.tabs.components.basicInfos.components.company.qsaInfosContent.qsaInfosContent.columnTitleValue.qsaPartnersCount.title'
          )}
          value={QSAPartnersCount}
        >
          {!QSAItems && QSAIsProcessing && <EmptyState type="processing" />}

          {!QSAItems && !QSAIsProcessing && <EmptyState type="waiting_consult" />}

          {QSAItems?.length > 0 && (
            <>
              <div className="card-partners-content">
                {!toPrint &&
                  QSAItems?.slice(0, 6)?.map((partner) => (
                    <QSAPartnerDetails partner={partner} />
                  ))}

                {!toPrint && QSAItems.length > 6 && (
                  <Collapse
                    activeKey={Number(isPanelOpened)}
                    className="ghost-collapse"
                    ghost
                  >
                    <Panel key={1} className="mrg-top-10">
                      {QSAItems?.slice(6, QSAItems.length)?.map((partner) => (
                        <QSAPartnerDetails partner={partner} />
                      ))}
                    </Panel>
                  </Collapse>
                )}

                {toPrint && QSAItems?.map((partner) => renderPartnerToPrint(partner))}
              </div>

              {!toPrint && QSAItems.length > 6 && (
                <Col span={24}>
                  <Button type="link" className="no-mrg no-pdd" onClick={onClickHandler}>
                    <i
                      className={`caf-ic_${isPanelOpened ? 'close' : 'plus'} mrg-right-5`}
                    />
                    {translate(
                      'pages.private.profiles.components.tabs.components.basicInfos.components.company.qsaInfosContent.qsaInfosContent.columnTitleValue.qsaPartnersCount.button'
                    )}{' '}
                    ({QSAItems?.length - 6})
                  </Button>
                </Col>
              )}
            </>
          )}
        </ColumnTitleValue>
        {serasaExperianItems?.length > 0 && (
          <ColumnTitleValue
            span={24}
            title={translate(
              'pages.private.profiles.components.tabs.components.basicInfos.components.company.qsaInfosContent.qsaInfosContent.columnTitleValue.serasaExperianPartnersCount.title'
            )}
            value={serasaExperianPartnersCount}
          >
            {!serasaExperianItems && serasaExperianIsProcessing && (
              <EmptyState type="processing" />
            )}

            {!serasaExperianItems && !serasaExperianIsProcessing && (
              <EmptyState type="waiting_consult" />
            )}

            {serasaExperianItems?.length > 0 && (
              <>
                <div className="card-partners-content">
                  {!toPrint &&
                    serasaExperianItems
                      ?.slice(0, 6)
                      ?.map((partner) => (
                        <SerasaExperianPartnerDetails partner={partner} />
                      ))}

                  {!toPrint && serasaExperianItems.length > 6 && (
                    <Collapse
                      activeKey={Number(isPanelOpened)}
                      className="ghost-collapse"
                      ghost
                    >
                      <Panel key={1} className="mrg-top-10">
                        {serasaExperianItems
                          ?.slice(6, serasaExperianItems.length)
                          ?.map((partner) => (
                            <SerasaExperianPartnerDetails partner={partner} />
                          ))}
                      </Panel>
                    </Collapse>
                  )}
                </div>

                {!toPrint && serasaExperianItems.length > 6 && (
                  <Col span={24}>
                    <Button
                      type="link"
                      className="no-mrg no-pdd"
                      onClick={onClickHandler}
                    >
                      <i
                        className={`caf-ic_${
                          isPanelOpened ? 'close' : 'plus'
                        } mrg-right-5`}
                      />
                      {translate(
                        'pages.private.profiles.components.tabs.components.basicInfos.components.company.qsaInfosContent.qsaInfosContent.columnTitleValue.serasaExperianPartnersCount.button'
                      )}{' '}
                      ({serasaExperianItems?.length - 6})
                    </Button>
                  </Col>
                )}
              </>
            )}
          </ColumnTitleValue>
        )}
      </Row>
      {!!attorneyItems.length && (
        <Row>
          <ColumnTitleValue
            span={24}
            className="mrg-btm-30"
            title={translate(
              'pages.private.executions.components.dataSourceCards.basicInfos.components.company.qsaInfosContent.qsaInfosContent.basicInfosCard.attorney'
            )}
          >
            {attorneyItems?.map((attorney) => {
              return <AttorneyDetails partner={attorney} />;
            })}
          </ColumnTitleValue>
        </Row>
      )}
    </BasicInfosCard>
  );
};

QSAInfosContent.propTypes = {
  company: PropTypes.shape({
    QSA: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    executions: PropTypes.arrayOf(PropTypes.shape([])),
    serasaExperian: PropTypes.shape({
      qsa: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
    status: PropTypes.string,
  }),
  toPrint: PropTypes.bool,
};

QSAInfosContent.defaultProps = {
  company: undefined,
  toPrint: false,
};

export default QSAInfosContent;
