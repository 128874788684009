/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';
import CountryFlag from 'components/CountryFlag';
import profileDefault from 'assets/images/profile-default.png';

import './styles.less';

const PersonCard = ({ data }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const content = useMemo(() => {
    return (
      <Card>
        <div className="container-card-body">
          <div
            className={`${!data?.image ? 'grayscale-95' : ''} card-image-profile`}
            style={{ backgroundImage: `url(${data?.image || profileDefault})` }}
          />
          <div className="card-body">
            <div className="flex-column card-body-infos">
              <p title={data?.name || '-'} className="card-title max-lines-1">
                {data?.summary?.name || '-'}
              </p>

              <div className="card-info">
                <div>
                  <CountryFlag
                    country={
                      data?.summary?.country || data?.summary?.address?.countryCode
                    }
                    style={{ height: 24, width: 24 }}
                  />
                </div>
                {!!data?.summary?.identifier && (
                  <div className="flex-row center mrg-horizon-5">
                    <i className="caf-ic_person info-color" />
                    <span
                      className={`${
                        !data?.summary?.identifier && 'gx-font-italic'
                      } max-lines-1`}
                    >
                      <span className="text-default">
                        {translate(
                          'pages.private.globalProfiles.components.list.components.peopleCard.docId',
                          'Doc ID:'
                        )}{' '}
                      </span>
                      <span className="text-dark">
                        {data?.summary?.identifier || '-'}
                      </span>
                    </span>
                  </div>
                )}
                {!!data?.summary?.birthDate && (
                  <div className="flex-row center mrg-horizon-5">
                    <i className="caf-ic_person info-color" />
                    <span
                      className={`${
                        !data?.summary?.birthDate && 'gx-font-italic'
                      } max-lines-1`}
                    >
                      <span className="text-default">
                        {translate(
                          'pages.private.globalProfiles.components.list.components.peopleCard.dateOfBirth',
                          'Birth date:'
                        )}{' '}
                      </span>
                      <span className="text-dark">{data?.summary?.birthDate}</span>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex-column text-dark padding-20" style={{ fontSize: 11 }}>
            <div className="flex-column">
              <b>
                {translate(
                  'pages.private.globalProfiles.components.list.components.peopleCard.createdAt',
                  'Created at:'
                )}
              </b>
              {i18nFormatDate(data?.createdAt, 'default')}
            </div>
            <div className="flex-column mrg-top-5">
              <b>
                {translate(
                  'pages.private.globalProfiles.components.list.components.peopleCard.updatedAt',
                  'Updated at:'
                )}
              </b>
              {data?.updatedAt
                ? i18nFormatDate(data?.updatedAt, 'default')
                : i18nFormatDate(data?.createdAt, 'default')}
            </div>
          </div>
        </div>
      </Card>
    );
  }, [data, translate, i18nFormatDate]);

  return (
    <Link
      to={`/profile/people/${data._id}`}
      id="card-people-global-component"
      className="overflow-hidden document-card mrg-btm-20"
    >
      {content}
    </Link>
  );
};

export default PersonCard;
