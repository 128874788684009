/* eslint-disable react/prop-types */
import React from 'react';
import { Col, Typography } from 'antd';

import './styles.less';

const { Link } = Typography;

const MediaExposureCard = ({ data }) => {
  const url = data?.url;

  return (
    <Col id="media-exposure-card" span={24}>
      <Link href={url} target="_blank" className="media-exposure-link">
        {data?.title}
      </Link>
      <span>{data?.sourceName || 'Fonte da informação indisponível'}</span>
    </Col>
  );
};

export default MediaExposureCard;
