import React, { useMemo } from 'react';
import EmptyExecutionState from 'components/EmptyExecutionState';
import { Row, Col, Collapse, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

import facebook from 'assets/images/Facebook.svg';
import google from 'assets/images/Google.svg';
import twitter from 'assets/images/Twitter.svg';
import instagram from 'assets/images/Instagram.svg';
import skype from 'assets/images/Skype.svg';
import viber from 'assets/images/Viber.svg';
import snapchat from 'assets/images/Snapchat.svg';
import line from 'assets/images/Line.svg';
import kakao from 'assets/images/KAKAO.svg';
import microsoft from 'assets/images/Windows.svg';
import defaultApp from 'assets/images/messages.svg';
import telegram from 'assets/images/Telegram.svg';
import whatsapp from 'assets/images/Whatsapp.svg';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import './styles.less';

const { Panel } = Collapse;

const I18N_BASE_PATH = 'components.dataSourceCards.basicInfos.components';
const PfPhoneAnalysis = ({
  source = {},
  toPrint = false,
  statusCodePfPhoneAnalysis = '',
}) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);
  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_phone_call"
      title={translate(`${I18N_BASE_PATH}.basicInfosCard.sources.phoneAnalysis`)}
      toPrint={toPrint}
    >
      {statusCode !== '01' && statusCodePfPhoneAnalysis !== '01' ? (
        <EmptyExecutionState
          statusCode={statusCode}
          message={source?.message || source?.data?.message}
        />
      ) : (
        <Row id="phone-id">
          <Col span={8} className="mrg-btm-25 mrg-top-10 col-ant">
            <strong>{translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.number`)}</strong>
            <span className="text-style">
              {parsedData?.phoneNumber
                ? parsedData?.phoneNumber
                : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
            </span>
          </Col>
          <Col span={8} className="mrg-btm-25 col-ant mrg-top-10">
            <strong>{translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.valid`)}</strong>
            <span className="text-style">{parsedData?.valid ? 'True' : 'False'}</span>
          </Col>
          <Col span={8} className="mrg-btm-25 col-ant mrg-top-10">
            <strong>
              {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.disposable`)}
            </strong>
            <span className="text-style">
              {parsedData?.disposable ? 'True' : 'False'}
            </span>
          </Col>
          <Col span={8} className="mrg-btm-25 col-ant">
            <strong>{translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.type`)}</strong>
            <span className="text-style">
              {parsedData?.type
                ? capitalizeFirstLetter(parsedData?.type)
                : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
            </span>
          </Col>
          <Col span={8} className="mrg-btm-25 col-ant">
            <strong>{translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.country`)}</strong>
            <span className="text-style">
              {parsedData?.country
                ? parsedData?.country
                : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
            </span>
          </Col>
          <Col span={8} className="mrg-btm-25 col-ant">
            <strong>
              {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.operator`)}
            </strong>
            <span className="text-style">
              {parsedData?.carrier
                ? parsedData?.carrier
                : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
            </span>
          </Col>
          <Col span={24} className="mrg-btm-25 col-ant">
            <strong>{translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.score`)}</strong>
            <span className="text-style">
              {parsedData?.score
                ? parsedData?.score
                : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
            </span>
          </Col>
          <Col
            span={24}
            className="col-ant"
            style={{ marginLeft: '0px', paddingLeft: '0px' }}
          >
            <Collapse defaultActiveKey={['1', '2']} expandIconPosition="right" ghost>
              <Panel
                header={
                  <b>{translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.titleRules`)}</b>
                }
                key="1"
                showArrow
                className="mrg-top-30"
              >
                {parsedData?.appliedRules?.length > 0 ? (
                  <>
                    {parsedData?.appliedRules?.map((rules) => (
                      <>
                        <Row id="phone-id">
                          <Col span={8} className="mrg-btm-25">
                            <strong>
                              {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.id`)}
                            </strong>
                            <span className="text-style">{rules.id}</span>
                          </Col>
                          <Col span={8} className="mrg-btm-25">
                            <strong>
                              {translate(
                                `${I18N_BASE_PATH}.phoneAnalysis.fields.operation`
                              )}
                            </strong>
                            <span className="text-style">{rules.operation}</span>
                          </Col>
                          <Col span={8} className="mrg-btm-25">
                            <strong>
                              {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.score`)}
                            </strong>
                            <span className="text-style">{rules.score}</span>
                          </Col>
                          <Col span={24} className="mrg-btm-25">
                            <strong>
                              {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.name`)}
                            </strong>
                            <span className="text-style">{rules.name}</span>
                          </Col>
                        </Row>
                      </>
                    ))}
                  </>
                ) : (
                  <>{translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.default`)}</>
                )}
              </Panel>
              <Divider />
              <Panel
                header={
                  <b>{translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.titleFlags`)}</b>
                }
                key="2"
                showArrow
                className="mrg-top-60"
              >
                {parsedData?.id ? (
                  <>
                    <strong>
                      {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.id`)}
                    </strong>
                    <span className="text-style">{parsedData?.id}</span>
                  </>
                ) : (
                  <>{translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.default`)}</>
                )}
              </Panel>
              <Divider />
            </Collapse>
          </Col>
          <Col span={24} className="mrg-btm-70 mrg-top-60 col-ant">
            <strong>
              {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.titleApps`)}
            </strong>
          </Col>
          {parsedData?.accountDetails?.facebook?.registered && (
            <>
              <Col span={24} className="col-ant">
                <img src={facebook} alt="" />
                <strong className="mrg-left-10 pdd-top-80 titleApp">FACEBOOK</strong>
              </Col>
              <Col span={24} className="mrg-btm-25 mrg-top-40 col-ant">
                <strong>{translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.url`)}</strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.facebook?.url
                    ? parsedData?.accountDetails?.facebook?.url
                    : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
                </span>
              </Col>
              <Col span={24} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.name`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.facebook?.name
                    ? parsedData?.accountDetails?.facebook?.name
                    : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
                </span>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.google.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={google} alt="" />
                <strong className="mrg-left-10  titleApp">GOOGLE</strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.twitter?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={twitter} alt="" />
                <strong className="mrg-left-10  titleApp">TWITTER</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.default`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.telegram?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={telegram} alt="" />
                <strong className="mrg-left-10  titleApp">TELEGRAM</strong>
              </Col>
              <Col span={24} className="mrg-btm-25 mrg-top-40 col-ant">
                <strong>{translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.url`)}</strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.telegram?.lastSeen
                    ? parsedData?.accountDetails?.telegram?.lastSeen
                    : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
                </span>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.whatsapp?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={whatsapp} alt="" />
                <strong className="mrg-left-10  titleApp">WHATSAPP</strong>
              </Col>
              <Col span={12} className="mrg-btm-25 mrg-top-40 col-ant">
                <strong>
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.seen`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.whatsapp?.lastSeen
                    ? parsedData?.accountDetails?.whatsapp?.lastSeen
                    : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
                </span>
              </Col>
              <Col span={12} className="mrg-btm-25 col-ant mrg-top-40">
                <strong>
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.about`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.whatsapp?.about
                    ? parsedData?.accountDetails?.whatsapp?.about
                    : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
                </span>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.viber?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={viber} alt="" />
                <strong className="mrg-left-10  titleApp">VIBER</strong>
              </Col>
              <Col span={12} className="mrg-btm-25 mrg-top-40 col-ant">
                <strong>{translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.url`)}</strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.viber?.name
                    ? parsedData?.accountDetails?.viber?.name
                    : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
                </span>
              </Col>
              <Col span={12} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.seen`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.viber?.lastSeen
                    ? parsedData?.accountDetails?.viber?.lastSeen
                    : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
                </span>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.ok?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={defaultApp} alt="" />
                <strong className="mrg-left-10  titleApp">OK</strong>
              </Col>
              <Col span={8} className="mrg-btm-25 mrg-top-40 col-ant">
                <strong>
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.city`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.ok?.city
                    ? parsedData?.accountDetails?.ok?.city
                    : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>{translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.age`)}</strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.ok?.age
                    ? parsedData?.accountDetails?.ok?.age
                    : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.dateJoined`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.ok?.dateJoined
                    ? i18nFormatDate(parsedData?.accountDetails?.ok?.dateJoined, 3)
                    : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
                </span>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.zalo?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={defaultApp} alt="" />
                <strong className="mrg-left-10  titleApp">ZALO</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.default`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.microsoft?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={microsoft} alt="" />
                <strong className="mrg-left-10  titleApp">MICROSOFT</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.default`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.snapchat?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={snapchat} alt="" />
                <strong className="mrg-left-10  titleApp">SNAPCHAT</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.default`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.line?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={line} alt="" />
                <strong className="mrg-left-10  titleApp">LINE</strong>
              </Col>
              <Col span={24} className="mrg-btm-25 mrg-top-40 col-ant">
                <strong>
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.name`)}
                </strong>
                <span className="text-style">
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
                </span>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.skype?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={skype} alt="" />
                <strong className="mrg-left-10  titleApp">SKYPE</strong>
              </Col>
              <Col span={8} className="mrg-btm-25 mrg-top-40 col-ant">
                <strong>
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.country`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.skype?.country
                    ? parsedData?.accountDetails?.skype?.country
                    : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-40">
                <strong>
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.city`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.skype?.city
                    ? parsedData?.accountDetails?.skype?.city
                    : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-40">
                <strong>
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.gender`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.skype?.gender
                    ? parsedData?.accountDetails?.skype?.gender
                    : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 mrg-top-7 col-ant">
                <strong>
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.name`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.skype?.name
                    ? parsedData?.accountDetails?.skype?.name
                    : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>{translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.id`)}</strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.skype?.id
                    ? parsedData?.accountDetails?.skype?.id
                    : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.handle`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.skype?.handle
                    ? parsedData?.accountDetails?.skype?.handle
                    : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 mrg-top-7 col-ant">
                <strong>
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.biography`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.skype?.biography
                    ? parsedData?.accountDetails?.skype?.biography
                    : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>{translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.age`)}</strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.skype?.age
                    ? parsedData?.accountDetails?.skype?.age
                    : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.language`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.skype?.language
                    ? parsedData?.accountDetails?.skype?.language
                    : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
                </span>
              </Col>
              <Col span={24} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.state`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.skype?.state
                    ? parsedData?.accountDetails?.skype?.state
                    : translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.errorDefault`)}
                </span>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.instagram?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={instagram} alt="" />
                <strong className="mrg-left-10  titleApp">INSTAGRAM</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.default`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.yahoo?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={defaultApp} alt="" />
                <strong className="mrg-left-10  titleApp">YAHOO</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.default`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.kakao?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={kakao} alt="" />
                <strong className="mrg-left-10  titleApp">KAKAO</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.default`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          <Col span={24} style={{ marginLeft: '0px', paddingLeft: '0px' }}>
            <Collapse defaultActiveKey={['1']} expandIconPosition="right" ghost>
              <Panel
                header={
                  <b>
                    {translate(`${I18N_BASE_PATH}.phoneAnalysis.fields.titleRegister`)}
                  </b>
                }
                key="1"
                showArrow
                className="mrg-top-30"
              >
                {!parsedData?.accountDetails?.facebook?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={facebook} alt="" />
                      <strong className="mrg-left-10  titleApp">FACEBOOK</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.snapchat?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={snapchat} alt="" />
                      <strong className="mrg-left-10  titleApp">SNAPCHAT</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.yahoo?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={defaultApp} alt="" />
                      <strong className="mrg-left-10  titleApp">YAHOO</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.google?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={google} alt="" />
                      <strong className="mrg-left-10  titleApp">GOOGLE</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.instagram?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={instagram} alt="" />
                      <strong className="mrg-left-10  titleApp">INSTAGRAM</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.twitter?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={twitter} alt="" />
                      <strong className="mrg-left-10  titleApp">TWITTER</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.telegram?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={telegram} alt="" />
                      <strong className="mrg-left-10  titleApp">TELEGRAM</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.whatsapp?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={whatsapp} alt="" />
                      <strong className="mrg-left-10  titleApp">WHATSAPP</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.viber?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={viber} alt="" />
                      <strong className="mrg-left-10  titleApp">VIBER</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.kakao?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={kakao} alt="" />
                      <strong className="mrg-left-10  titleApp">KAKAO</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.ok?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={defaultApp} alt="" />
                      <strong className="mrg-left-10  titleApp">OK</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.zalo?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={defaultApp} alt="" />
                      <strong className="mrg-left-10  titleApp">ZALO</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.line?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20 mrg-btm-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={line} alt="" />
                      <strong className="mrg-left-10 titleApp">LINE</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.microsoft?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={microsoft} alt="" />
                      <strong className="mrg-left-10  titleApp">MICROSOFT</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.skype?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={skype} alt="" />
                      <strong className="mrg-left-10  titleApp">SKYPE</strong>
                    </Col>
                    <Divider />
                  </>
                )}
              </Panel>
            </Collapse>
          </Col>
        </Row>
      )}
    </DataSourceCardCollapsePanel>
  );
};
export default PfPhoneAnalysis;
