import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Col, Modal } from 'antd';

import PageTitle from 'components/PageTitle';

import ArchivesUploadCard from './ArchivesUploadCard';

import './styles.less';

const ModalUpload = (
  allowedMimeTypes,
  limitFileSize,
  limitFileQuantity,
  subtitle = 'profile'
) => {
  const { t: translate } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const CardUploadModal = useMemo(
    () => (
      <Modal
        wrapClassName="caf-modal archives-upload-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        visible={modalVisible}
        footer={null}
        width={700}
        onCancel={closeModal}
      >
        <div id="card-archives-upload" className="no-mrg no-pdd">
          <PageTitle
            title={translate(
              'components.modalUpload.index.cardUploadModal.pageTitle.title'
            )}
            subtitle={translate(
              `components.modalUpload.index.cardUploadModal.pageTitle.subtitle.${subtitle}`
            )}
          />
          <Row>
            <Col style={{ top: 12 }} span={24}>
              <ArchivesUploadCard
                closeModal={closeModal}
                allowedMimeTypes={allowedMimeTypes}
                limitFileSize={limitFileSize}
                limitFileQuantity={limitFileQuantity}
                isTransactionRecord={subtitle !== 'profile'}
              />
            </Col>
          </Row>
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      translate,
      limitFileSize,
      limitFileQuantity,
      allowedMimeTypes,
      subtitle,
    ]
  );

  return { openModal, CardUploadModal };
};

export default ModalUpload;
