import { Col, Collapse, Row, Divider } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DottedLabel from 'components/DottedLabel';

import { useI18nFormatters } from 'hooks/i18nFormatters';

const { Panel } = Collapse;

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.companies.components.spcReportParticipationScore.otherInfos';

function Predecessor({ source = {}, toPrint = false }) {
  const { i18nFormatDate } = useI18nFormatters();
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);

  const predecessor = useMemo(() => parsedData?.predecessor || {}, [parsedData]);

  return (
    <Collapse className="mrg-top-10" defaultActiveKey={toPrint ? ['1'] : null}>
      <Panel
        key="1"
        disabled={!predecessor?.details?.length}
        header={
          <Col>
            <Row align="middle" justify="space-between">
              <Col span={17}>
                <span className="text-dark text-bold">
                  {translate(`${I18N_BASE_PATH}.index.predecessor.title`)}
                </span>
              </Col>
              <Col className="mrg-right-15">
                <>
                  <DottedLabel
                    description={`(${predecessor?.total || 0}) ${translate(
                      `${I18N_BASE_PATH}.index.restrictions`
                    )}`}
                    type={+predecessor?.total > 0 ? 'danger' : 'primary'}
                  />
                </>
              </Col>
            </Row>
          </Col>
        }
      >
        {predecessor?.details?.map((register, index) => (
          <>
            <Col span={24}>
              <Row align="middle">
                <span className="text-dark text-bold">
                  {`${i18nFormatDate(register?.motiveData, 1) || '-'} - ${
                    register?.previousCorporateName
                  }`}
                </span>
              </Row>
            </Col>
            {index < predecessor?.details?.length - 1 && <Divider />}
          </>
        ))}
      </Panel>
    </Collapse>
  );
}

export default Predecessor;
