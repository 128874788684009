import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import KYBPeople from 'assets/images/company/people.svg';
import {
  CompanyBody1,
  CompanySubtitle1,
  CompanySupplierGroupButtons,
} from 'pages/private/KYBCompany/CompanyDetailsV2/components';
import TabTitle from '../../TabTitle';
import './styles.less';
import BusinessIdentityComponent from './components/KybBusinessIdentity';
import CreditReportComponent from './components/KybCreditReport';

const I18N = 'pages.private.KYBCompany.pages.companyDetails.subPages.people';

const CompanyPeopleTabs = ({
  tab,
  peopleData,
  summaryData,
  itemsToExport,
  print = false,
}) => {
  if (print) {
    return (
      <>
        <CompanySubtitle1
          className="kyb-company-print-supplier-title"
          render={itemsToExport?.creditReport.value}
        >
          Credit Report
        </CompanySubtitle1>
        <CreditReportComponent
          creditReportData={peopleData.creditReport}
          summaryData={summaryData}
          render={itemsToExport?.creditReport.value}
          itemsToExport={itemsToExport?.creditReport}
          print
        />
        <CompanySubtitle1
          className="kyb-company-print-supplier-title"
          render={itemsToExport?.businessIdentity.value}
        >
          Business Identity
        </CompanySubtitle1>
        <BusinessIdentityComponent
          peopleData={peopleData}
          render={itemsToExport?.businessIdentity.value}
          itemsToExport={itemsToExport?.businessIdentity}
          print
        />
      </>
    );
  }

  return tab === 'credit-report' ? (
    <CreditReportComponent
      creditReportData={peopleData.creditReport}
      summaryData={summaryData}
    />
  ) : (
    <BusinessIdentityComponent peopleData={peopleData} />
  );
};

const People = ({
  query,
  setQuery,
  peopleData,
  summaryData,
  itemsToExport,
  print = false,
}) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(
    peopleData?.creditReport?.statusCode === '01' ? 'credit-report' : 'business-identity'
  );
  if (itemsToExport?.value === false) return null;

  return (
    <section className="KYB-people kyb-company-people-main">
      <TabTitle
        icon={KYBPeople}
        title={t(`${I18N}.title`, 'People')}
        className="kyb-company-main-tab-title"
        render={!print}
      />
      <CompanyBody1 className="kyb-company-info-title-buttons" render={!print}>
        <b>{t(`${I18N}.chooseSource`, 'Choose source:')}</b>
      </CompanyBody1>
      <CompanySupplierGroupButtons value={tab} onChange={setTab} render={!print} />
      <CompanyPeopleTabs
        peopleData={peopleData}
        tab={tab}
        print={print}
        itemsToExport={itemsToExport?.items}
        query={query}
        setQuery={setQuery}
        summaryData={summaryData}
      />
    </section>
  );
};

export default People;
