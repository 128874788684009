import React from 'react';
import RectangleButton from 'components/Button/RectangleButton';
import PageTitle from 'components/PageTitle';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

const ChooseGroupType = ({ typeOfCreate, setTypeOfCreate }) => {
  const I18N = `pages.private.userManagement.usersCreate.components.groupsStep.index`;
  const { t: translate } = useTranslation();

  return (
    <div className="flex-column center pdd-vertical-80">
      <PageTitle
        title={translate(`${I18N}.title`)}
        subtitle={translate(`${I18N}.subtitle`)}
      />
      <div
        style={{
          display: 'flex',
          gap: '0.7rem',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <RectangleButton
          title={translate(`${I18N}.existGroupTitle`)}
          description={translate(`${I18N}.existGroupDescription`)}
          icon="caf-ic_user_active"
          selected={typeOfCreate === 'existing'}
          onClick={() => setTypeOfCreate('existing')}
        />

        <RectangleButton
          title={translate(`${I18N}.newGroupTitle`)}
          description={translate(`${I18N}.newGroupDescription`)}
          icon="caf-ic_edit"
          selected={typeOfCreate === 'newGroup'}
          onClick={() => setTypeOfCreate('newGroup')}
        />
      </div>
    </div>
  );
};

export default ChooseGroupType;
