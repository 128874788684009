import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DollarSign } from '@combateafraude/icons/general';
import EmptyExecutionState from 'components/EmptyExecutionState';
import ScoreIndicator from '../../Components/ScoreIndicator';
import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';
import './styles.less';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.shared.creditScoreSimple';

const CreditScoreSimple = ({ source, toPrint, type }) => {
  const { t: translate } = useTranslation();
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(() => statusCode === '01', [statusCode]);

  return (
    <DataSourceCardCollapsePanel
      customId="credit-score-simple"
      title={
        type === 'PF'
          ? translate(`${I18N_BASE_PATH}.creditScoreForIndividuals`)
          : translate(`${I18N_BASE_PATH}.creditScoreForCompanies`)
      }
      customIcon={
        <div className="div-icon">
          <DollarSign color="#004af7" />
        </div>
      }
      toPrint={toPrint}
    >
      {queryWorked ? (
        <>
          <h3 className="text-bold">
            {translate(`${I18N_BASE_PATH}.positiveApprovalScore`)}
          </h3>
          <ScoreIndicator source={source} />
        </>
      ) : (
        <EmptyExecutionState statusCode={statusCode} message={source?.message} />
      )}
    </DataSourceCardCollapsePanel>
  );
};

export default CreditScoreSimple;
