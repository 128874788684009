import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { toMaskedCpf } from 'utils/formatters';

import defaultImage from 'assets/images/profile-default.png';

function AttorneyDetails({ partner }) {
  const { t: translate } = useTranslation();

  const attorneyType = useMemo(() => {
    if (partner?.attorney?.type === 'company') {
      return translate(
        'pages.private.executions.components.dataSourceCards.basicInfos.components.company.attorneyDetails.attorneyType.company'
      );
    }

    const nameWithTaxId = `${partner?.attorney?.qsaName ?? '-'} / ${
      partner?.attorney?.qsaTaxId ?? '-'
    }`;

    return nameWithTaxId ?? '-';
  }, [partner, translate]);

  return (
    <div className="partner-card">
      <div className="partner-card-content">
        <img
          className="partner-image grayscale-95"
          src={defaultImage}
          alt={partner?.name || ''}
        />
        <div className="partner-info-container">
          <div className="partner-info">
            <span>{partner?.name || '-'}</span>
          </div>
          <span className="partner-cpf">
            {partner?.cpf || partner?.partialCpf ? (
              toMaskedCpf(partner?.cpf || partner.partialCpf)
            ) : (
              <p className="gx-font-italic">
                {translate(
                  'pages.private.executions.components.dataSourceCards.basicInfos.components.company.attorneyDetails.hasNotPartnerCpf'
                )}
              </p>
            )}
          </span>
          <span className="partner-cpf">
            {translate(
              'pages.private.executions.components.dataSourceCards.basicInfos.components.company.attorneyDetails.represents'
            )}{' '}
            {attorneyType}
          </span>
        </div>
      </div>
    </div>
  );
}

AttorneyDetails.propTypes = {
  partner: PropTypes.shape({
    name: PropTypes.string,
    cpf: PropTypes.string,
    partialCpf: PropTypes.string,
    attorney: PropTypes.shape({
      type: PropTypes.string,
      qsaName: PropTypes.string,
      qsaTaxId: PropTypes.string,
    }),
  }),
};

AttorneyDetails.defaultProps = {
  partner: PropTypes.shape({}),
};

export default AttorneyDetails;
