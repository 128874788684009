import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import usePagination from 'customHooks/usePagination';
import { Col, Row, Pagination } from 'antd';
import NoResults from 'components/NoResults';
import {
  CompanyBody1,
  CompanySubtitle2,
} from 'pages/private/KYBCompany/CompanyDetailsV2/components';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companyDetails.subPages.people.creditReport';

const ShareHoldersContent = ({ shareHoldersLength, shareholdersList, print = false }) => {
  const { t: translate } = useTranslation();

  const topRef = useRef(null);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
  });

  const handleChangePage = (page) => {
    setPagination((old) => ({ ...old, currentPage: page }));
    topRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const { paginatedItems, paginationStart, paginationEnd } = usePagination({
    pagination,
    items: shareholdersList,
  });

  if (!shareHoldersLength) {
    return <NoResults />;
  }

  return (
    <>
      <div className="credit-safe-shareholders kyb-company-collapse-list">
        <Row className="credit-safe-shareholders-header no-mrg">
          <Col span={5}>
            <CompanySubtitle2>
              {translate(
                `${I18N_BASE_PATH}.tabs.shareholders.currentShareholders.columns.name`,
                'Name'
              )}
            </CompanySubtitle2>
          </Col>
          <Col span={4}>
            <CompanySubtitle2>
              {translate(
                `${I18N_BASE_PATH}.tabs.shareholders.currentShareholders.columns.currency`,
                'Currency'
              )}
            </CompanySubtitle2>
          </Col>
          <Col span={4}>
            <CompanySubtitle2>
              {translate(
                `${I18N_BASE_PATH}.tabs.shareholders.currentShareholders.columns.shareCount`,
                'Share Count'
              )}
            </CompanySubtitle2>
          </Col>
          <Col span={4}>
            <CompanySubtitle2>
              {translate(
                `${I18N_BASE_PATH}.tabs.shareholders.currentShareholders.columns.shareType`,
                'Share Type'
              )}
            </CompanySubtitle2>
          </Col>
          <Col span={3}>
            <CompanySubtitle2>
              {translate(
                `${I18N_BASE_PATH}.tabs.shareholders.currentShareholders.columns.nominalValue`,
                'Nominal Value'
              )}
            </CompanySubtitle2>
          </Col>
          <Col span={4}>
            <CompanySubtitle2>
              {translate(
                `${I18N_BASE_PATH}.tabs.shareholders.currentShareholders.columns.percentageOfShareCount`,
                '% of Share Count'
              )}
            </CompanySubtitle2>
          </Col>
        </Row>
        {paginatedItems?.map((element) => (
          <Row className="shareholder-line no-mrg" key={element?.name}>
            <Col span={5} className="name">
              <CompanyBody1>{element?.name}</CompanyBody1>
            </Col>
            <Col span={4}>
              <CompanyBody1>{element?.currency}</CompanyBody1>
            </Col>
            <Col span={4}>
              <CompanyBody1>{element?.totalNumberOfSharesOwned}</CompanyBody1>
            </Col>
            <Col span={4}>
              <CompanyBody1>{element?.shareType}</CompanyBody1>
            </Col>
            <Col span={3}>
              <CompanyBody1>{element?.shareClassesValuePerShare?.[0]}</CompanyBody1>
            </Col>
            <Col span={4}>
              <CompanyBody1>{element?.percentSharesHeld}</CompanyBody1>
            </Col>
          </Row>
        ))}
        <Row className="row-show-pagination-people no-mrg">
          <Col span={12}>
            <span>
              {translate(
                `pages.private.KYBCompany.pages.companyDetails.subPages.records.pagination`,
                {
                  start: paginationStart,
                  end: paginationEnd,
                  total: shareholdersList.length,
                }
              )}
            </span>
          </Col>
          <Col span={12}>
            <Pagination
              current={pagination.currentPage}
              onChange={handleChangePage}
              pageSize={pagination.pageSize}
              total={shareholdersList.length}
              defaultCurrent={1}
              showSizeChanger={false}
              showSorterTooltip={false}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ShareHoldersContent;
