import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row } from 'antd';

import EmptyState from 'components/EmptyState';
import EmptyExecutionState from 'components/EmptyExecutionState';

import ContactAndRelationCard from 'pages/private/Executions/components/ContactAndRelationCard';
import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';
import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.relationships.components.allPhoneRelatedPeople.index.allPhoneRelatedPeople.columnLabel';

export const PfRelatedPeoplePhones = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const [isPhonesPanelOpened, setIsPhonesPanelOpened] = useState(false);

  const peoplePhones = useMemo(() => source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const querySucceeded = useMemo(
    () =>
      statusCode === '01' ||
      peoplePhones?.available === true ||
      peoplePhones?.data?.length > 0,
    [peoplePhones, statusCode]
  );

  const onClickHandler = useCallback(() => {
    setIsPhonesPanelOpened((oldState) => !oldState);
  }, []);

  const parsedList = useMemo(() => {
    if (!peoplePhones?.data?.length > 0) return <></>;

    const el = [];
    const _size = peoplePhones?.data?.length;

    if (_size) {
      for (let i = 0; i < _size; i += 3) {
        el.push(
          <Row className="contact-data-row">
            {peoplePhones?.data?.slice(i, i + 3)?.map((peoplePhone) => (
              <ContactAndRelationCard
                key={peoplePhone?.number}
                type={peoplePhone?.type}
                content={`(${peoplePhone?.areaCode || '-'}) ${
                  peoplePhone?.number || '-'
                }`}
                span={8}
                className="mrg-top-10 pdd-horizontal-0"
              />
            ))}
          </Row>
        );
      }
    }

    if (el.length > 0) {
      const parsedEl = isPhonesPanelOpened ? el : el.splice(0, 1);
      return parsedEl;
    }

    return <></>;
  }, [isPhonesPanelOpened, peoplePhones]);

  return (
    <>
      <DataSourceCardCollapsePanel
        title={translate(`${I18N_BASE_PATH}.title`)}
        icon="caf-ic_users"
        toPrint={toPrint}
      >
        <ColumnLabel span={24}>
          {querySucceeded ? (
            <>
              {peoplePhones?.data && !peoplePhones?.data?.length > 0 && (
                <EmptyState
                  type="empty-success"
                  description={translate(
                    `${I18N_BASE_PATH}.querySucceeded.emptyState.description`
                  )}
                />
              )}

              {parsedList}

              {Array.isArray(peoplePhones?.data) && peoplePhones?.data?.length > 3 && (
                <Row className="mrg-top-5 reset-mrg">
                  <Button type="link" className="no-mrg no-pdd" onClick={onClickHandler}>
                    <i
                      className={`mrg-right-5
                    caf-ic_${isPhonesPanelOpened ? 'close' : 'plus'}`}
                    />
                    {translate(`${I18N_BASE_PATH}.querySucceeded.viewMore.title`)} (
                    {peoplePhones?.data?.length - 3})
                  </Button>
                </Row>
              )}
            </>
          ) : (
            <EmptyExecutionState
              statusCode={peoplePhones?.data?.statusCode || peoplePhones?.statusCode}
              message={peoplePhones?.message || peoplePhones?.data?.message}
            />
          )}
        </ColumnLabel>
      </DataSourceCardCollapsePanel>
    </>
  );
};
