import React from 'react';

import ApprovedIcon from 'assets/images/company/status-approved.svg';
import ReprovedIcon from 'assets/images/company/status-reproved.svg';
import PendingIcon from 'assets/images/company/status-pending.svg';
import ProcessingIcon from 'assets/images/company/status-processing.svg';

import './styles.less';
import { useTranslation } from 'react-i18next';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companySearching.components.consultedCompanies.icons';

const getIconByStatus = {
  REPROVED: {
    icon: ReprovedIcon,
    label: `${I18N_BASE_PATH}.rejected.label`,
    fallBack: 'Rejected',
    class: 'rejected',
  },
  APPROVED: {
    icon: ApprovedIcon,
    label: `${I18N_BASE_PATH}.approved.label`,
    fallBack: 'Approved',
    class: 'approved',
  },
  PENDING: {
    icon: PendingIcon,
    label: `${I18N_BASE_PATH}.pending.label`,
    fallBack: 'Pending',
    class: 'pending',
  },
  PROCESSING: {
    icon: ProcessingIcon,
    label: `${I18N_BASE_PATH}.processing.label`,
    fallBack: 'Processing',
    class: 'processing',
  },
};

const Icons = ({ status }) => {
  const { t: translate } = useTranslation();
  const icon = getIconByStatus[status];

  return (
    <div className="card-section fourth">
      <img src={icon?.icon} alt="status" />
      <span className={icon?.class}>{translate(icon?.label, icon?.fallBack)}</span>
    </div>
  );
};

export default Icons;
