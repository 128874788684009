/* eslint-disable react/prop-types */
import React, { useState, useCallback, useMemo } from 'react';
import { Modal } from 'antd';

import ProtestsItems from '../ProtestsItems';

const AllProtests = ({ data }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const AllProtestsModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeModal}
        footer={null}
        style={{ top: 40 }}
        width={720}
      >
        <div id="all-protests-modal" className="no-mrg no-pdd">
          <ProtestsItems data={data} />
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, data]
  );

  return { openModal, closeModal, AllProtestsModal };
};

export default AllProtests;
