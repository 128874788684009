/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'utils/isObjectEmpty';
import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import EmptyState from 'components/EmptyState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import usePersonalLaborAndFinancialInformationModal from 'pages/private/Profiles/components/DataSourceModals/PersonalLaborAndFinancialInformation';

const ClassOrganizations = ({ profile, toPrint /* urlId */ }) => {
  const { t: translate } = useTranslation();

  const classOrganization = useMemo(() => profile?.work?.classOrganization, [profile]);

  const {
    openModal,
    PersonalLaborAndFinancialInformationModal,
  } = usePersonalLaborAndFinancialInformationModal({
    data: profile?.work?.classOrganization?.items,
    title: translate(
      'pages.private.profiles.components.dataSourceCards.laborInformation.people.components.classOrganizations.index.usePersonalLaborAndFinancialInformationModal.title'
    ),
    classOrganization: true,
  });

  const handlerInfos = useMemo(() => {
    if (!classOrganization) return null;

    if (isEmpty(classOrganization)) {
      return {
        title: translate(
          'pages.private.profiles.components.dataSourceCards.laborInformation.people.components.classOrganizations.index.handlerInfos.hasNotClassOrganization'
        ),
        type: 'empty',
      };
    }

    if (classOrganization?.items?.length > 0) {
      return {
        title: translate(
          'pages.private.profiles.components.dataSourceCards.laborInformation.people.components.classOrganizations.index.handlerInfos.participates'
        ),
        type: 'primary',
      };
    }

    if (!classOrganization?.items?.length > 0) {
      return {
        title: translate(
          'pages.private.profiles.components.dataSourceCards.laborInformation.people.components.classOrganizations.index.handlerInfos.notParticipates'
        ),
        type: 'empty',
      };
    }

    return null;
  }, [classOrganization, translate]);

  const isProcessing = useMemo(
    () => verifySourcesProcessing(profile, 'classOrganization'),
    [profile]
  );

  const handleOpenModal = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <>
      {PersonalLaborAndFinancialInformationModal}

      <ColumnLabel
        title={translate(
          'pages.private.profiles.components.dataSourceCards.laborInformation.people.components.classOrganizations.index.classOrganizations.columnLabel.title'
        )}
        description={handlerInfos?.title}
        type={handlerInfos?.type}
        onClick={classOrganization?.items?.length > 0 && handleOpenModal}
      >
        {/* {!classOrganization && (
          <EmptyState type="empty-recurrence" hiddenAction={toPrint} urlId={urlId} />
        )} */}

        {!classOrganization && isProcessing && <EmptyState type="processing" />}

        {!classOrganization && !isProcessing && <EmptyState type="waiting_consult" />}

        {classOrganization &&
          (isEmpty(classOrganization) || !classOrganization?.items?.length > 0) && (
            <span>
              {translate(
                'pages.private.profiles.components.dataSourceCards.laborInformation.people.components.classOrganizations.index.classOrganizations.notParticipates'
              )}
            </span>
          )}

        {!toPrint && classOrganization?.items?.length > 0 && (
          <span>
            {translate(
              'pages.private.profiles.components.dataSourceCards.laborInformation.people.components.classOrganizations.index.classOrganizations.detail'
            )}
          </span>
        )}
      </ColumnLabel>
    </>
  );
};

export default ClassOrganizations;
