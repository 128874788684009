/* eslint-disable react/prop-types */
import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Select, Form, Button, Input, message } from 'antd';

import { useFetch } from 'services/hooks';

import PageTitle from 'components/PageTitle';
import TooltipInfo from 'components/TooltipInfo';

import './styles.less';

const { Option } = Select;

const I18N_BASE_PATH =
  'pages.private.liveness.attemptsTab.components.cardAttempts.components.addWhiteListModal.index';

const AddWhiteList = ({ data }) => {
  const { t: translate } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [time, setTime] = useState('timeInDays');
  const [form] = Form.useForm();

  const { post, loading } = useFetch();

  const closeModal = useCallback(() => {
    setModalVisible(false);
    form.resetFields();
    setTime('timeInDays');
  }, [form]);

  const openModal = useCallback(() => {
    setModalVisible(true);

    form.setFieldsValue({
      type: 'timeInDays',
      days: 1,
    });
  }, [form]);

  const handleSubmit = useCallback(async () => {
    const values = await form.getFieldsValue();

    const payload = {
      personId: data?.person?.personId,
      trackingId: data.trackingId,
      name: values.name,
      isTimeless: values.type === 'indeterminate',
    };

    if (values.type !== 'indeterminate') {
      payload.expireDate = Number(values.days);
    }

    try {
      await post({
        url: `/white-list`,
        payload,
      });
      closeModal();

      message.success(translate(`${I18N_BASE_PATH}.message.success`));
    } catch (error) {
      //
    }
  }, [closeModal, data, form, post, translate]);

  const AddWhiteListModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        onCancel={closeModal}
        footer={null}
        style={{ top: 40 }}
        width={700}
      >
        <div className="no-mrg no-pdd">
          <PageTitle
            title={translate(`${I18N_BASE_PATH}.pageTitle.title`)}
            subtitle={translate(`${I18N_BASE_PATH}.pageTitle.subtitle`)}
          />
        </div>
        <div className="form-select-time">
          <Form
            onFinish={handleSubmit}
            form={form}
            initialValues={{
              name: data?.person?.name || '',
              type: 'timeInDays',
              days: 1,
            }}
          >
            <Form.Item
              name="name"
              label={translate(`${I18N_BASE_PATH}.inputs.name`)}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="type"
              label={
                <div>
                  {translate(`${I18N_BASE_PATH}.select.label`)}
                  <TooltipInfo
                    color="#2f80ed"
                    placement="top"
                    title={() => translate(`${I18N_BASE_PATH}.toolTip.timeReleaseInfo`)}
                    size={12}
                  />
                </div>
              }
            >
              <Select defaultValue="timeInDays" onSelect={(value) => setTime(value)}>
                <Option value="indeterminate" key="indeterminate">
                  {translate(`${I18N_BASE_PATH}.select.options.indeterminate`)}
                </Option>
                <Option value="timeInDays" key="timeInDays">
                  {translate(`${I18N_BASE_PATH}.select.options.defineTime`)}
                </Option>
              </Select>
            </Form.Item>
            {time?.includes('timeInDays') && (
              <Form.Item
                name="days"
                label={
                  <div>
                    {translate(`${I18N_BASE_PATH}.inputs.days`)}
                    <TooltipInfo
                      color="#2f80ed"
                      placement="top"
                      title={() => translate(`${I18N_BASE_PATH}.toolTip.daysInfo`)}
                      size={12}
                    />
                  </div>
                }
              >
                <Input style={{ width: 71 }} type="number" />
              </Form.Item>
            )}
          </Form>
        </div>
        <div className="gx-flex-row gx-justify-content-end">
          <Button type="default" onClick={closeModal} className="btn-cancel">
            {translate(`${I18N_BASE_PATH}.buttons.cancel`)}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            onClick={handleSubmit}
            loading={loading}
            disabled={loading}
          >
            {translate(`${I18N_BASE_PATH}.buttons.confirm`)}
          </Button>
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, translate, handleSubmit, form, data, time, loading]
  );

  return { openModal, closeModal, AddWhiteListModal };
};

export default AddWhiteList;
