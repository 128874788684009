import React, { useCallback, useState } from 'react';
import NotAvailable from 'components/NotAvailable';
import { Dropdown, Menu, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ArrowRight as IconArrowRight,
  AlertTriangle as IconAlertTriangle,
  CheckCircle2 as IconCheckCircle2,
} from '@combateafraude/icons/general';
import CompanyPartnerOnboardingLinkModal from '../CompanyPartnerOnboardingLinkModal';
import './styles.less';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companyDetails.header.partnersList';

const PartnersMenu = ({ partners = [], openModal, loading }) => {
  const onClickPartner = (partner) => {
    const { transactionId } = partner;
    if (transactionId) {
      return window.open(`/person/${transactionId}`, '_blank').focus();
    }

    openModal(partner);
  };

  return (
    <>
      <Menu className="kyb-company-partners-list-menu" selectable={false}>
        {partners.map((partner) => {
          const { transactionId } = partner;
          return (
            <Menu.Item onClick={() => onClickPartner(partner)}>
              <div className="partner-status-and-name">
                {loading && <i className="icon caf-ic_rotate-cw loading-icon" />}
                {!loading && transactionId && (
                  <IconCheckCircle2 className="icon icon-check-circle" />
                )}
                {!loading && !transactionId && (
                  <IconAlertTriangle className="icon icon-alert-triangle" />
                )}
                <span>{partner.name}</span>
              </div>
              <IconArrowRight className="icon-arrow-details" />
            </Menu.Item>
          );
        })}
      </Menu>
    </>
  );
};

export const PartnersList = ({ partners = [], loading }) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const { openModal, ModalComponent } = CompanyPartnerOnboardingLinkModal();

  const openModalLink = useCallback(
    (partner) => {
      setIsVisible(false);
      openModal(partner);
    },
    [openModal]
  );

  if (!partners.length) {
    return <NotAvailable />;
  }

  return (
    <>
      {ModalComponent}
      <Dropdown
        overlayClassName="kyb-company-partners-list-dropdown"
        visible={isVisible}
        onVisibleChange={setIsVisible}
        overlay={
          <PartnersMenu partners={partners} openModal={openModalLink} loading={loading} />
        }
        trigger={['click']}
      >
        <span className="pointer">
          <Space>
            {t(`${I18N_BASE_PATH}.dropdownLabel`, `KYC linked`)}
            <i className={isVisible ? 'caf-ic_arrow_up' : 'caf-ic_arrow_down'} />
          </Space>
        </span>
      </Dropdown>
    </>
  );
};
