/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import { isEmpty } from 'utils/isObjectEmpty';
import { toFormatPercentage } from 'utils/formatters';

import TableInfoCard from 'pages/private/Profiles/components/TableInfoCard/Column';

const ValidationsOfficialData = ({ data }) => {
  const { t: translate } = useTranslation();

  const officialData = useMemo(() => data?.sections?.officialData || {}, [data]);

  const showQueryType = useMemo(() => {
    if (officialData?.source === '2') {
      return translate(
        'pages.private.executions.components.tabs.components.documentsValidations.components.shared.validationsOfficialData.index.validationsOfficialData.queryTypes.governmentalBase'
      );
    }

    if (['6', '8', '9', '10'].includes(officialData?.source)) {
      return translate(
        'pages.private.executions.components.tabs.components.documentsValidations.components.shared.validationsOfficialData.index.validationsOfficialData.queryTypes.privateBase'
      );
    }
  }, [officialData, translate]);

  return (
    <Row gutter={[32, 24]}>
      {(!officialData || isEmpty(officialData)) && (
        <Col span={24}>
          <span className="text-dark gx-font-italic font-size-15">
            {translate(
              'pages.private.executions.components.tabs.components.documentsValidations.components.shared.validationsOfficialData.index.validationsOfficialData.title'
            )}
          </span>
        </Col>
      )}

      {officialData && !isEmpty(officialData) && (
        <>
          <TableInfoCard
            span={8}
            title={translate(
              'pages.private.executions.components.tabs.components.documentsValidations.components.shared.validationsOfficialData.index.validationsOfficialData.tableInfoCard.queryType.title'
            )}
            content={showQueryType}
            bordered={false}
          />
          <TableInfoCard
            span={8}
            title={translate(
              'pages.private.executions.components.tabs.components.documentsValidations.components.shared.validationsOfficialData.index.validationsOfficialData.tableInfoCard.similarity.title'
            )}
            content={toFormatPercentage(officialData?.confidence, translate)}
            bordered={false}
          />
        </>
      )}
    </Row>
  );
};

export default ValidationsOfficialData;
