/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import DossierCard from 'pages/private/Profiles/components/DossierCard';

import WorkHistory from './components/WorkHistory';
import CivilServant from './components/CivilServant';
import ClassOrganizations from './components/ClassOrganizations';
import RAIS from './components/RAIS';

const PersonalLaborInformation = ({ data, toPrint }) => {
  const { t: translate } = useTranslation();

  const dataSources = useMemo(
    () =>
      data?.profileTemplate?.backgroundChecking?.services?.map(({ source }) => source) ||
      [],
    [data]
  );

  const showWorkHistory = useMemo(
    () => dataSources.includes('pf_occupation_data') || data?.work?.history,
    [data, dataSources]
  );
  const showCivilServant = useMemo(
    () => dataSources.includes('pf_profession_data') || data?.work?.civilServant,
    [data, dataSources]
  );
  const showClassOrganizations = useMemo(
    () => dataSources.includes('pf_class_organization') || data?.work?.classOrganization,
    [data, dataSources]
  );
  const showRAIS = useMemo(() => dataSources.includes('pf_rais') || data?.work?.rais, [
    data,
    dataSources,
  ]);

  return (
    <DossierCard
      customId="labor-infos-component"
      title={translate(
        'pages.private.profiles.components.dataSourceCards.laborInformation.people.index.people.dossierCard.title'
      )}
      subtitle={translate(
        'pages.private.profiles.components.dataSourceCards.laborInformation.people.index.people.dossierCard.subtitle'
      )}
      icon="caf-ic_awards"
    >
      <Row>
        {showWorkHistory && (
          <WorkHistory profile={data} /* urlId={urlId} */ toPrint={toPrint} />
        )}
        {showCivilServant && (
          <CivilServant profile={data} /* urlId={urlId} */ toPrint={toPrint} />
        )}
        {showClassOrganizations && (
          <ClassOrganizations
            profile={data}
            /* urlId={urlId} */
            toPrint={toPrint}
          />
        )}
        {showRAIS && <RAIS profile={data} /* urlId={urlId} */ toPrint={toPrint} />}
      </Row>
    </DossierCard>
  );
};

export default PersonalLaborInformation;
