import React from 'react';
import Filter from './components/Filter';
import LivenessList from './components/List';

import './styles.less';

const LivenessTab = () => {
  return (
    <div id="liveness-list-component">
      <div className="gx-flex-row">
        <div id="list">
          <LivenessList />
        </div>
        <div id="filter" className="hide-scroll">
          <Filter />
        </div>
      </div>
    </div>
  );
};
export default LivenessTab;
