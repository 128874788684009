import { useTranslation } from 'react-i18next';

const SUMMARY = `pages.private.KYBCompany.pages.companyDetails.subPages.people.businessIdentity.summary`;

const usePeopleBusiness = ({ businessIdentityData }) => {
  const { t } = useTranslation();

  const directors = businessIdentityData?.directors;
  const officers = businessIdentityData?.officers;
  const shareholders = businessIdentityData?.shareHolders;
  const registeredAgents = businessIdentityData?.registeredAgents;

  const summaryCounter = [
    {
      text: t(`${SUMMARY}.columns.directors`, 'Directors'),
      count: directors?.length,
    },
    {
      text: t(`${SUMMARY}.columns.shareholders`, 'Shareholders'),
      count: shareholders?.length,
    },
    {
      text: t(`${SUMMARY}.columns.registeredAgents`, 'Registered agents'),
      count: registeredAgents?.length,
    },
    {
      text: t(`${SUMMARY}.columns.officers`, 'Officers'),
      count: officers?.length,
    },
  ];

  return {
    directors,
    officers,
    shareholders,
    registeredAgents,
    summaryCounter,
  };
};

export default usePeopleBusiness;
