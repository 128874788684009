/* eslint-disable react/prop-types */
import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import PageTitle from 'components/PageTitle';

import MediaExposureCard from '../MediaExposureCard';

const AllMediaExposure = ({ data, lastConsultation }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const AllMediaExposureModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        onCancel={closeModal}
        footer={null}
        style={{ top: 40 }}
        width={700}
      >
        <div className="custom-dossier-modal no-mrg no-pdd">
          <PageTitle
            title={translate(
              'components.dataSourceCards.mediaExposure.allMediaExposureModal.index.allMediaExposureModal.pageTitle.title'
            )}
            subtitle={`${
              lastConsultation
                ? `${translate(
                    'components.dataSourceCards.mediaExposure.allMediaExposureModal.index.allMediaExposureModal.pageTitle.subtitle.hasLastConsultation'
                  )} ${i18nFormatDate(lastConsultation, 'default')}`
                : translate(
                    'components.dataSourceCards.mediaExposure.allMediaExposureModal.index.allMediaExposureModal.pageTitle.subtitle.hasNotLastConsultation'
                  )
            }`}
          />

          {data?.newsItems?.length > 0 &&
            data?.newsItems?.map((media) => (
              <MediaExposureCard key={media?.url} data={media} />
            ))}
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, data, lastConsultation, translate, i18nFormatDate]
  );

  return { openModal, closeModal, AllMediaExposureModal };
};

export default AllMediaExposure;
