/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useMemo, useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { AlertTriangle } from '@combateafraude/icons/general';
import { Person } from '@combateafraude/icons/users';
import { Ocr } from '@combateafraude/icons/docs';

import { useI18nFormatters } from 'hooks/i18nFormatters';
import { useReports } from 'hooks/reports';
import { useAuth } from 'hooks/auth';

import PermissionWrapper from 'routes/PermissionWrapper';

import IconStatus from 'pages/private/Profiles/components/IconStatus';

import { svgValidationStatus } from 'utils/handlerStatus';
import { toMaskedCnpj, toMaskedCpf } from 'utils/formatters';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';
import { convertSnakeCaseToCamelCase } from '../../utils/convertSnakeCaseToCamelCase';

import InformationBanner from '../../../../ExecutionDetails/components/InformationBanner';

import './styles.less';

const I18N_BASE_PATH =
  'pages.private.executions.components.list.components.executionCard.index';

const ExecutionCard = ({
  data: execution,
  socket,
  allProfileTemplates,
  allWorkflows,
}) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();
  const [operators, setOperators] = useState([]);
  const operatorsRef = useRef([]);
  const { user } = useAuth();

  const { allReportsObject } = useReports();

  useEffect(() => {
    operatorsRef.current = operators;
  }, [operators]);

  useEffect(() => {
    setOperators([]);

    if (socket !== null) {
      socket.addEventListener('message', (event) => {
        const message = JSON.parse(event.data);

        let newOperators = [];
        if (message.type === 'disconnect') {
          const disconnectedClient = message.content;

          newOperators = operatorsRef.current?.filter(
            (operator) => operator.socketId !== disconnectedClient.id
          );

          setOperators(() => [...newOperators]);
        } else if (message.type !== 'error') {
          const executionId = execution.executionId || execution._id || execution.id;

          const messageContentByExecutionId = message.content?.filter(
            (socketClient) =>
              socketClient.infoData?.executionId === executionId &&
              socketClient.topics.find((t) => t === `trust-execution-${executionId}`)
          );
          if (messageContentByExecutionId && messageContentByExecutionId.length > 0) {
            newOperators = messageContentByExecutionId
              .map((socketClient) => ({
                socketId: socketClient.id,
                executionId: socketClient.infoData.executionId,
                name: socketClient.infoData.name,
                userId: socketClient.infoData.userId,
                createdAt: socketClient.createdAt,
              }))
              .sort((a, b) => a.createdAt.localeCompare(b.createdAt));

            setOperators(() => [...newOperators]);
          }
        }
      });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execution, socket]);

  const executionTitle = useMemo(() => {
    const person =
      execution?.data?.name ||
      execution?.parameters?.name ||
      execution?.sections?.cpf?.name ||
      execution?.sections?.ocr?.name ||
      execution?.sections?.pfData?.data?.name ||
      execution?.sections?.pfBasicData?.data?.name ||
      execution?.sections?.pfCpfData?.data?.name ||
      execution?.sections?.pfRfStatus?.data?.name ||
      execution?.sections?.globalDocVerification?.name ||
      execution?.sections?.globalDocVerification?.data?.name;
    const company =
      execution?.data?.corporateName ||
      execution?.parameters?.name ||
      execution?.sections?.pjData?.data?.officialName ||
      execution?.sections?.pjBasicData?.data?.officialName ||
      execution?.sections?.pjBasicData?.data?.tradeName ||
      execution?.sections?.pjBoaVista?.data?.DefineLimite?.IdentificacaoCompleto
        ?.RazaoSocial;

    return person || company || '-';
  }, [execution]);

  const getReprovalReasonLabel = useCallback((rule) => {
    if (rule?.code) return translate(`validations.${rule.code}.status.INVALID`);
    if (execution?.workflowId)
      return translate(`validations.${rule.ruleId}.title`, rule?.ruleName);
    return rule?.description ?? rule?.ruleName;
  }, []);

  const reprovedReasons = useMemo(() => {
    const reprovedStatus = ['REPROVED', 'REPROVADO'];

    if (!reprovedStatus.includes(execution?.status)) {
      return [];
    }

    const reprovedValidations = execution?.validations
      ?.filter((validation) =>
        reprovedStatus.includes(validation.resultStatus ?? validation.status)
      )
      .map((validation) => getReprovalReasonLabel(validation));

    let reprovedReasons = [];

    if (execution?.reprovalReasons) {
      reprovedReasons = [...execution.reprovalReasons];
    }

    if (reprovedValidations) {
      reprovedReasons = [...reprovedReasons, ...reprovedValidations];
    }

    return reprovedReasons;
  }, [execution, translate]);

  const pendingReviewReasons = useMemo(() => {
    const pendingReviewStatus = ['PENDING', 'PENDENTE'];

    if (!pendingReviewStatus.includes(execution?.status)) {
      return [];
    }

    return execution?.validations
      ?.filter((validation) => pendingReviewStatus.includes(validation.resultStatus))
      .map((validation) =>
        translate(`validations.${validation.code}.status.INVALID`, validation.description)
      );
  }, [execution, translate]);

  const executionHasReportOrProfileTemplate = useMemo(() => {
    let exec;

    if (
      (execution?.report || execution?.reportId) &&
      execution?.report !== '000000000000000000000000' &&
      execution?.reportId !== '000000000000000000000000' &&
      execution?.report?.id !== '000000000000000000000000'
    ) {
      exec = allReportsObject[execution?.report || execution?.reportId];

      return exec;
    }

    if (execution?.templateId) {
      exec = allProfileTemplates?.find(
        (profileTemplate) => profileTemplate?._id === execution?.templateId
      );

      return exec;
    }

    if (execution?.profileTemplateId) {
      exec = allProfileTemplates?.find(
        (profileTemplate) => profileTemplate?._id === execution?.profileTemplateId
      );

      return exec;
    }

    if (execution?.metadata?.profileTemplateId) {
      exec = allProfileTemplates?.find(
        (profileTemplate) =>
          profileTemplate?._id === execution?.metadata?.profileTemplateId
      );

      return exec;
    }

    return null;
  }, [execution, allReportsObject, allProfileTemplates]);

  const isCompany = useMemo(
    () =>
      executionHasReportOrProfileTemplate?.inputType?.toUpperCase() ===
        translate(`${I18N_BASE_PATH}.executionDocument.type.company`) ||
      execution?.data?.documentType?.toUpperCase() === 'CNPJ' ||
      executionHasReportOrProfileTemplate?.type === 'PJ',
    [execution, executionHasReportOrProfileTemplate, translate]
  );

  const executionDocumentId = useMemo(() => {
    const cpf =
      execution?.data?.cpf || execution?.parameters?.cpf || execution?.sections?.ocr?.cpf;
    const cnpj =
      execution?.data?.cnpj ||
      execution?.parameters?.cnpj ||
      execution?.parameters?.nationalId;
    const docNumber =
      execution?.sections?.globalDocVerification?.documentNumber ||
      execution?.sections?.globalDocVerification?.data?.documentNumber ||
      execution?.parameters?.nationalId ||
      execution?.parameters?.personId ||
      execution?.parameters?.documentNumber;

    let docId = null;

    if (cpf) docId = toMaskedCpf(cpf);
    if (cnpj) docId = toMaskedCnpj(cnpj);
    if (docNumber) docId = docNumber;

    if (!docId && executionHasReportOrProfileTemplate?.inputType) {
      docId = isCompany
        ? translate(`${I18N_BASE_PATH}.executionDocument.company.companyId.unavailable`)
        : translate(`${I18N_BASE_PATH}.executionDocument.person.docId.unavailable`);
    }

    return (
      <span
        className={classNames({
          'gx-font-italic': !docId,
        })}
      >
        {docId ? (
          <>
            <span style={{ color: '#262626', fontWeight: 300 }}>
              {isCompany
                ? translate(`${I18N_BASE_PATH}.executionDocument.company.companyId`)
                : cpf
                ? translate(`${I18N_BASE_PATH}.executionDocument.person.cpf`)
                : translate(`${I18N_BASE_PATH}.executionDocument.person.docId`)}
            </span>{' '}
            <span>{docId}</span>
          </>
        ) : (
          <span style={{ color: '#262626', fontWeight: 300 }}>
            {translate(`${I18N_BASE_PATH}.executionDocument.unavailable`)}
          </span>
        )}
      </span>
    );
  }, [isCompany, execution, translate, executionHasReportOrProfileTemplate]);

  const executionDocumentType = useMemo(() => {
    const { documentCategory, issueStateCode } =
      execution?.sections?.globalDocVerification || {};

    const possibleGlobalDocTypes = ['DRIVING_LICENSE', 'IDENTITY_CARD', 'PASSPORT'];
    const possibleBrazilianDocTypes = [
      'ID_CARD',
      'PASSPORT',
      'VISA',
      'DRIVING_LICENSE',
      'CNH',
      'RG',
      'CRLV',
      'RNE',
      'RNM',
      'CTPS',
    ];
    let docType;
    if (possibleGlobalDocTypes.includes(documentCategory)) {
      docType = `${translate(
        `${I18N_BASE_PATH}.executionDocument.docType.${convertSnakeCaseToCamelCase(
          documentCategory
        )}`
      )}${issueStateCode ? ` (${issueStateCode})` : ''}`;
    } else if (possibleBrazilianDocTypes.includes(execution?.type?.toUpperCase())) {
      docType = `${translate(
        `${I18N_BASE_PATH}.executionDocument.docType.${convertSnakeCaseToCamelCase(
          execution?.type
        )}`
      )} (BRA)`;
    }

    return (
      <span
        className={classNames({
          'gx-font-italic': !docType,
        })}
      >
        {docType ? (
          <>
            <span style={{ color: '#262626', fontWeight: 300 }}>
              {translate(`${I18N_BASE_PATH}.executionDocument.docType`)}
            </span>{' '}
            <span>{docType}</span>
          </>
        ) : (
          <span style={{ color: '#262626', fontWeight: 300 }}>
            {translate(`${I18N_BASE_PATH}.executionDocument.docType.unavailable`)}
          </span>
        )}
      </span>
    );
  }, [execution, translate]);

  const labelColor = {
    REPROVADO: '#E21B45',
    REPROVED: '#E21B45',
    APPROVED: '#39C560',
    APROVADO: '#39C560',
    PENDENTE: '#F8B239',
    PENDING: '#F8B239',
    'PENDENTE OCR': '#F8B239',
  };

  const renderTooltipAroundIcon = useCallback(
    (isApprovedWithWarning = false) => (
      <div className="card-info-status">
        <Tooltip
          title={
            <div style={{ fontSize: '14px', lineHeight: '14px', padding: '10px' }}>
              {translate(`${I18N_BASE_PATH}.warningMessage`, 'Approved with')}{' '}
              <span style={{ fontWeight: 800 }}>
                {translate(`${I18N_BASE_PATH}.warningBy`, 'warning')}
              </span>
            </div>
          }
          color="#004AF7"
        >
          <div className="wrapper-icon-text">
            {isApprovedWithWarning ? (
              <>
                <IconStatus isApprovedWithWarning={isApprovedWithWarning} />
                <span style={{ color: labelColor[execution.status] ?? '#2E70F0' }}>
                  {translate(
                    svgValidationStatus(execution?.status)?.title(execution ?? {})
                  )}
                </span>
              </>
            ) : (
              <>
                <IconStatus status={execution?.status} />
                <span style={{ color: labelColor[execution.status] ?? '#2E70F0' }}>
                  {translate(
                    svgValidationStatus(execution.status)?.title(execution ?? {})
                  )}
                </span>
              </>
            )}
          </div>
        </Tooltip>
      </div>
    ),
    [execution, translate, labelColor]
  );

  const buildIcon = useCallback(
    () => (
      <div className="card-info-status">
        <div className="wrapper-icon-text">
          <IconStatus status={execution?.status} />
          <span style={{ color: labelColor[execution.status] ?? '#2E70F0' }}>
            {translate(svgValidationStatus(execution.status)?.title(execution ?? {}))}
          </span>
        </div>
      </div>
    ),
    [execution, labelColor, translate]
  );

  const renderIcons = useCallback(() => {
    if (execution?.status) {
      if (
        ['APPROVED', 'APROVADO'].includes(execution.status) &&
        execution?.validations?.length > 0 &&
        execution?.validations?.some((validation) => validation?.status === 'WARNING')
      ) {
        return renderTooltipAroundIcon(true);
      }
      return buildIcon();
    }
    return <></>;
  }, [buildIcon, execution, renderTooltipAroundIcon]);

  const bannerData = useMemo(() => {
    if (!operators || operators.length === 0) return { hideBanner: true };

    const mainOperator = operators[0];

    if (mainOperator.userId === user.username) return { hideBanner: true };
    return {
      operator: mainOperator,
      hideBanner: false,
    };
  }, [operators]); // eslint-disable-line

  const workflowName = useMemo(() => {
    if (execution?.workflowId || execution?.metadata?.workflowId) {
      const executionWorkflowId =
        execution?.workflowId || execution?.metadata?.workflowId;

      const workflow = allWorkflows?.docs?.find(
        (workflow) => workflow._id === executionWorkflowId
      );

      const workflowName = workflow?.definition?.name;

      return workflowName;
    }
    return null;
  }, [allWorkflows, execution]);

  const cardContent = useMemo(
    () => (
      <div className="card-execution-wrapper">
        <div className="card-execution-component overflow-hidden">
          <main className="card-execution-content">
            <section className="person-company-infos">
              <h6>
                {workflowName ||
                  (isCompany
                    ? translate(`${I18N_BASE_PATH}.template.PJ`)
                    : translate(`${I18N_BASE_PATH}.template.PF`))}
              </h6>
              <h3>{executionHasReportOrProfileTemplate?.name}</h3>
              <h3 title={executionTitle} className="max-lines-1">
                {executionTitle}
              </h3>
              <div className="wrapper-identify flex">
                <span className="flex center mrg-btm-5">
                  <Person className="wrapper-identify-icon" />
                  {executionDocumentId}
                </span>
                <span className="flex center">
                  <Ocr className="wrapper-identify-icon" />
                  {executionDocumentType}
                </span>
              </div>
            </section>
            <section className="execution-details">
              <div className="group-dates-details">
                <div className="wrapper-dates">
                  <span className="description-date">
                    {translate(`${I18N_BASE_PATH}.cardContent.createDate.before`)}
                  </span>
                  <span className="content-date">
                    {i18nFormatDate(execution?.createdAt, 4)}
                  </span>
                </div>
                <div className="wrapper-dates">
                  <span className="description-date">
                    {translate(`${I18N_BASE_PATH}.cardContent.updateDate.before`)}
                  </span>
                  <span className="content-date">
                    {execution?.updatedAt
                      ? i18nFormatDate(execution?.updatedAt, 4)
                      : '--/--'}
                  </span>
                </div>
              </div>
              <hr />
              {renderIcons()}
            </section>
          </main>
          <InformationBanner
            icon={<i className="caf-ic_eye" />}
            bannerData={bannerData}
            bannerType="socket-operator-list"
          />
        </div>
        {(reprovedReasons.length > 0 || pendingReviewReasons.length > 0) && (
          <footer className="wrapper-footer">
            {reprovedReasons?.length > 0 &&
              reprovedReasons.map((reason) => (
                <div className="footer-reasons reprove">
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
                    <circle
                      cx="5"
                      cy="5"
                      r="5"
                      fill={
                        reprovedReasons.length ? labelColor.REPROVED : labelColor.PENDING
                      }
                    />
                  </svg>
                  <p>{reason}</p>
                </div>
              ))}
            {pendingReviewReasons?.length > 0 &&
              pendingReviewReasons.map((reason) => (
                <div className="footer-reasons alert">
                  <AlertTriangle />
                  <p>{reason}</p>
                </div>
              ))}
          </footer>
        )}
      </div>
    ),
    [
      executionDocumentId,
      executionDocumentType,
      executionHasReportOrProfileTemplate,
      isCompany,
      executionTitle,
      execution,
      i18nFormatDate,
      translate,
      renderIcons,
      reprovedReasons,
      pendingReviewReasons,
      bannerData,
      labelColor,
    ]
  );

  return (
    <PermissionWrapper
      requiredPermissions={['executions:read:details']}
      forbiddenContent={cardContent}
    >
      <Link
        id="card-execution-component"
        to={`${hasFeatureFlag('isWorkflowBuilder', user) ? '/person' : '/executions'}/${
          execution?._id || execution.id
        }`}
      >
        {cardContent}
      </Link>
    </PermissionWrapper>
  );
};

export default ExecutionCard;
