import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import EmptyState from 'components/EmptyState';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import { toFormatPhoneNumber } from 'utils/formatters';
import BasicInfosCard from '../BasicInfosCard';

const ContactContent = ({ company, toPrint }) => {
  const { t: translate } = useTranslation();

  const renderIcon = useCallback((icon, content) => {
    return (
      <span key={content} className="contact-item">
        <i className={`${icon} mrg-right-5`} /> {content}
      </span>
    );
  }, []);

  const phones = useMemo(() => {
    const phoneNumber = company?.basicData?.phoneNumber;
    if (!phoneNumber) return <></>;

    const phoneSplitted = phoneNumber
      .split('/')
      .map((n) => n.trim())
      .filter((n) => n);

    if (phoneSplitted.length < 1) return <></>;

    return phoneSplitted.map((p) => {
      let phoneNumber = p?.replace(/\D/g, '');

      if (phoneNumber?.length === 10) {
        const phoneSplitted = phoneNumber?.slice(-8);
        const ddd = phoneNumber?.slice(0, 2);
        const firstDigit = phoneSplitted?.slice(0, 1);
        const isNotLandline = !['2', '3', '4', '5'].includes(firstDigit);

        if (phoneSplitted.length === 8 && isNotLandline) {
          phoneNumber = `55${ddd}9${phoneSplitted}`;
        } else {
          phoneNumber = `55${phoneNumber}`;
        }
      }
      return (
        <div className="mrg-left-10">
          {' '}
          {renderIcon('caf-ic_contact_', toFormatPhoneNumber(phoneNumber, 'BR'))}
        </div>
      );
    });
  }, [company, renderIcon]);

  const hasData = useMemo(
    () => company?.basicData?.phoneNumber || company?.basicData?.companyEmail,
    [company]
  );

  const isProcessing = useMemo(() => verifySourcesProcessing(company, 'contacts'), [
    company,
  ]);

  return (
    <BasicInfosCard
      icon="caf-ic_contact_"
      title={translate(
        'pages.private.profiles.components.tabs.components.basicInfos.components.company.contactContent.contactContent.basicInfosCard.title'
      )}
      showDivider={toPrint}
      data={{
        ...(company?.basicData || {}),
        status: company?.status,
        contactInfos: `${company?.basicData?.phoneNumber || ''}${
          company?.basicData?.companyEmail || ''
        }`,
      }}
      dataIndex="contactInfos"
      customId="basic-infos-contacts"
    >
      {!hasData && isProcessing && <EmptyState type="processing" />}

      {!hasData && !isProcessing && <EmptyState type="waiting_consult" />}

      {hasData && (
        <Row>
          <Col span={24} className="mrg-btm-20">
            <div className="ant-row-flex">
              {company?.basicData?.companyEmail &&
                renderIcon(
                  'caf-ic_mail',
                  company?.basicData?.companyEmail?.toLowerCase()
                )}

              {phones}
            </div>
          </Col>
        </Row>
      )}
    </BasicInfosCard>
  );
};

ContactContent.propTypes = {
  company: PropTypes.shape({
    basicData: PropTypes.shape({
      phoneNumber: PropTypes.string,
      companyEmail: PropTypes.string,
    }),
    status: PropTypes.string,
  }),
  toPrint: PropTypes.bool,
};

ContactContent.defaultProps = {
  company: undefined,
  toPrint: false,
};

export default ContactContent;
