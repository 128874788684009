/* eslint-disable react/prop-types */
import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Row } from 'antd';

import PageTitle from 'components/PageTitle';

import ContactAndRelationCard from 'pages/private/Profiles/components/ContactAndRelationCard';

const AllPeopleInternalRestrictiveList = ({ data, danger }) => {
  const { t: translate } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const AllPeopleInternalRestrictiveListModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        onCancel={closeModal}
        footer={null}
        style={{ top: 40 }}
        width={700}
      >
        <div className="custom-dossier-modal no-mrg no-pdd">
          <PageTitle
            title={translate(
              'components.dataSourceCards.relationships.components.peopleInternalRestrictiveList.allPeopleInternalRestrictiveList.index.allPeopleInternalRestrictiveList.pageTitle.title'
            )}
            subtitle={translate(
              'components.dataSourceCards.relationships.components.peopleInternalRestrictiveList.allPeopleInternalRestrictiveList.index.allPeopleInternalRestrictiveList.pageTitle.title',
              { value: data?.length }
            )}
          />

          <Row className="contact-data-container mrg-vertical-5">
            {data?.length > 0 &&
              data?.map((relationship) => (
                <ContactAndRelationCard
                  key={relationship?.cpf}
                  span={12}
                  relation
                  danger={danger}
                  name={relationship?.name}
                  type={relationship?.relationshipType}
                  level={relationship?.relationshipLevel}
                  className="mrg-top-20 mrg-btm-20 pdd-horizontal-0"
                />
              ))}
          </Row>
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, translate, data, danger]
  );

  return { openModal, closeModal, AllPeopleInternalRestrictiveListModal };
};

export default AllPeopleInternalRestrictiveList;
