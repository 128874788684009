import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import DottedLabel from 'components/DottedLabel';
import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';
import { toMaskedCep } from 'utils/formatters';

import { mapZipCodeValidationStatus } from './utils';
import './styles.less';

const I18N_BASE_PATH = 'components.dataSourceCards.zipCodeDetails.index';

export const ZipCodeDetails = ({
  source = {},
  sourceValidation = {},
  cepParameter = '',
  toPrint = false,
}) => {
  const { t: translate } = useTranslation();

  const formattedAddres = useMemo(() => {
    const { city, district, street, uf, ufName } = source?.data || {};

    const address_parts = [];

    if (street?.length) {
      address_parts.push(street);
    }

    if (district?.length) {
      address_parts.push(district);
    }

    if (city?.length) {
      address_parts.push(city);
    }

    if (address_parts?.length) {
      return address_parts?.join(', ')?.concat(` ${uf || ufName}`);
    }

    return null;
  }, [source]);

  return (
    <DataSourceCardCollapsePanel
      title={translate(
        'components.dataSourceCards.basicInfos.components.basicInfosCard.sources.zipCodeDetails'
      )?.toUpperCase()}
      icon="caf-ic_map_pin"
      toPrint={toPrint}
    >
      <div id="zip-code-details-content">
        <Row className="mrg-top-10">
          <ColumnTitleValue
            span={12}
            title={translate(`${I18N_BASE_PATH}.columns.cep`)}
            value={toMaskedCep(cepParameter) || '-'}
          />
          <ColumnTitleValue
            span={12}
            title={translate(`${I18N_BASE_PATH}.columns.situation`)}
            value={
              <DottedLabel
                className="text-bold"
                type={mapZipCodeValidationStatus(sourceValidation?.resultStatus).type}
                description={translate(
                  `${I18N_BASE_PATH}.status.${
                    mapZipCodeValidationStatus(sourceValidation?.resultStatus)?.status ||
                    'undefined'
                  }`
                )}
              />
            }
          />
        </Row>
        <Row className="mrg-top-20">
          <ColumnTitleValue
            span={12}
            title={translate(`${I18N_BASE_PATH}.columns.address`)}
            value={formattedAddres || '-'}
          />
        </Row>

        <Row className="mrg-top-20">
          <ColumnTitleValue
            span={12}
            title={translate(`${I18N_BASE_PATH}.columns.ufCodeIbge`)}
            value={source?.data?.ufCodeIbge || '-'}
          />
          <ColumnTitleValue
            span={12}
            title={translate(`${I18N_BASE_PATH}.columns.cityCodeIbge`)}
            value={source?.data?.cityCodeIbge || '-'}
          />
        </Row>
      </div>
    </DataSourceCardCollapsePanel>
  );
};
