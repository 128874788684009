import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Spin } from 'antd';

import { useExecution } from 'hooks/execution';

import ExecutionContent from 'pages/private/Executions/components/ExecutionContent';
import ExecutionSubheader from 'pages/private/Executions/components/ExecutionSubheader';

import { usePerson } from 'hooks/person';
import { useCompany } from 'hooks/company';
import { useAuth } from 'hooks/auth';

import InformationBanner from './components/InformationBanner';

import Wrapper from '../../wrapper';

import './styles.less';

const ExecutionDetails = () => {
  const { executionId } = useParams();
  const { clearCompany } = useCompany();
  const { clearPerson } = usePerson();
  const [operators, setOperators] = useState([]);

  const operatorsRef = useRef([]);
  useEffect(() => {
    operatorsRef.current = operators;
  }, [operators]);

  const { loadExecution, loadingExecution, execution, reloadExecution } = useExecution();
  const { user } = useAuth();
  useEffect(() => {
    if (!loadExecution) return;

    loadExecution({ executionId, clearData: true });
    clearCompany();
    clearPerson();
  }, [executionId]); // eslint-disable-line

  useEffect(() => {
    if (!reloadExecution) return;
    loadExecution({ executionId, clearData: true });
    clearCompany();
    clearPerson();
  }, [reloadExecution]); // eslint-disable-line

  useEffect(() => {
    const socket = new WebSocket(process.env.REACT_APP_SOCKET_URL);

    socket.addEventListener('open', () => {
      socket.send(
        JSON.stringify({
          action: 'subscribeAndPublish',
          topic: `trust-execution-${executionId}`,
          infoData: { executionId, name: user.name, userId: user.username },
        })
      );
    });

    socket.addEventListener('message', (event) => {
      const message = JSON.parse(event.data);

      let newOperators = [];
      if (message.type === 'disconnect') {
        const disconnectedClient = message.content;

        newOperators = operatorsRef.current.filter(
          (operator) => operator.socketId !== disconnectedClient.id
        );

        setOperators(() => [...newOperators]);
      } else if (message.type !== 'error') {
        const messageContentByExecutionId = message.content.filter(
          (socketClient) =>
            socketClient.infoData?.executionId === executionId &&
            socketClient.topics.find((t) => t === `trust-execution-${executionId}`)
        );

        newOperators = messageContentByExecutionId
          .map((socketClient) => ({
            socketId: socketClient.id,
            executionId: socketClient.infoData.executionId,
            name: socketClient.infoData.name,
            userId: socketClient.infoData.userId,
            createdAt: socketClient.createdAt,
          }))
          .sort((a, b) => a.createdAt.localeCompare(b.createdAt));

        setOperators(() => [...newOperators]);
      }
    });

    return () => {
      socket.close();
    };
  }, []); // eslint-disable-line

  const bannerData = useMemo(() => {
    if (!operators || operators.length === 0) return { hideBanner: true };

    const mainOperator = operators[0];

    if (mainOperator.userId === user.username) return { hideBanner: true };
    return {
      operator: mainOperator,
      hideBanner: false,
    };
  }, [operators]); // eslint-disable-line

  return (
    <>
      <InformationBanner
        icon={<i className="caf-ic_eye" />}
        bannerData={bannerData}
        bannerType="socket-operator"
      />
      <Wrapper
        id="executions-wrapper"
        subheader={<ExecutionSubheader execution={execution} />}
        hideMainHeader
      >
        {loadingExecution && !execution ? (
          <Spin className="flex center mrg-top-30" />
        ) : (
          <>
            <ExecutionContent />
          </>
        )}
      </Wrapper>
    </>
  );
};

export default ExecutionDetails;
