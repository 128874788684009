import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { formatISO } from 'date-fns';
import _ from 'lodash';

import { useAuth } from 'hooks/auth';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { useTags } from 'hooks/tags';
import { useTheme } from 'hooks/theme';

import { toMaskedCpf, toMaskedCnpj } from 'utils/formatters';

import GeneralStatus from 'pages/private/Profiles/components/GeneralStatus';
import CardExecutionTag from 'pages/private/Profiles/components/ProfileTag/CardExecutionTag';

import logoToPrintCAF from 'assets/images/trust-logo.svg';
import logoToPrintABC from 'assets/images/abc-logo-to-print.svg';

import './styles.less';

const DocumentHeader = ({ data }) => {
  const { t: translate } = useTranslation();

  const { i18nFormatDate } = useI18nFormatters();

  const { user } = useAuth();
  const { customAllTags: tagsList } = useTags();
  const { logo: logoTheme, domain } = useTheme();

  const currentDate = useMemo(() => {
    const _now = formatISO(new Date());

    return i18nFormatDate(_now, 'default');
  }, [i18nFormatDate]);

  const profileImage = useMemo(() => {
    return data?.image || null;
  }, [data]);

  const profileName = useMemo(() => {
    const officialName = data?.basicData?.officialName;
    const name = data?.basicData?.name;

    return officialName || name || '-';
  }, [data]);

  const profileFantasyName = useMemo(() => {
    return data?.basicData?.fantasyName ? `${data?.basicData?.fantasyName} |` : null;
  }, [data]);

  const profileIdentification = useMemo(() => {
    const cpf = data?.cpf;
    const cnpj = data?.cnpj;

    return toMaskedCpf(cpf) || toMaskedCnpj(cnpj) || '-';
  }, [data]);

  const profileStatus = useMemo(() => {
    return data?.status || null;
  }, [data]);

  const profileTags = useMemo(() => {
    return data?.tags || [];
  }, [data]);

  const logoToPrint = useMemo(() => {
    if (domain === 'caf') return logoToPrintCAF;
    if (domain === 'bancoabcbrasil') return logoToPrintABC;
    return logoTheme;
  }, [domain, logoTheme]);

  return (
    <div id="to-print-header">
      <header>
        <img src={logoToPrint} alt="Logotipo" />

        <div className="to-print-description">
          <strong>Detalhamento do perfil</strong>
          <span>
            {translate(
              'pages.private.profiles.components.dossierPrint.components.documentHeader.index.documentHeader.title.before'
            )}{' '}
            {currentDate}{' '}
            {translate(
              'pages.private.profiles.components.dossierPrint.components.documentHeader.index.documentHeader.title.inside'
            )}{' '}
            {user?.name || 'Trust Monitor'}
          </span>
        </div>
      </header>

      <div className="to-print-title">
        <div className="profile-details">
          {profileImage && (
            <div
              className="profile-img"
              style={{ backgroundImage: `url(${profileImage})` }}
            />
          )}

          <div className="profile-info">
            <strong className="profile-name">{profileName}</strong>

            <span className="profile-identification">
              {profileFantasyName} {profileIdentification}
            </span>

            {profileTags.length > 0 && !_.isEmpty(tagsList) && (
              <div className="profile-tags">
                {profileTags
                  .filter((tag) => tag !== null)
                  .map((tag) => {
                    return (
                      <CardExecutionTag
                        key={tag?._id}
                        name={tagsList[tag]?.title}
                        color={tagsList[tag]?.color}
                        special={tagsList[tag]?.special}
                        actionDescription={tagsList[tag]?.actionDescription}
                      />
                    );
                  })}
              </div>
            )}
          </div>
        </div>

        {profileStatus && (
          <div className="profile-status">
            <GeneralStatus status={profileStatus} />
          </div>
        )}
      </div>
    </div>
  );
};

DocumentHeader.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DocumentHeader;
