/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import EmptyState from 'components/EmptyState';
import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import ProcessesList from '../../Lists/ProcessesList';

import './styles.less';

const Processes = ({ data, type, toPrint }) => {
  const { t: translate } = useTranslation();

  const hasData = useMemo(() => data?.justice?.processes, [data]);
  const hasContent = useMemo(() => data?.justice?.processes?.items?.length > 0, [data]);
  const isProcessing = useMemo(() => verifySourcesProcessing(data, 'processes'), [data]);

  return (
    <div id="legal-information-processes-container">
      <Row className="legal-information-processes-title reset-mrg">
        <Col span={24}>
          <strong>
            {translate(
              'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.index.processes.title'
            )}
          </strong>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          {!hasData && isProcessing && (
            <EmptyState type="processing" className="mrg-btm-20 mrg-top-0" />
          )}

          {!hasData && !isProcessing && (
            <EmptyState type="waiting_consult" className="mrg-btm-20 mrg-top-0" />
          )}

          {hasData && !hasContent && (
            <EmptyState
              type="empty-success"
              description={translate(
                'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.index.processes.emptyState.description',
                { type }
              )}
              className="mrg-btm-20 mrg-top-0"
            />
          )}

          {hasData && hasContent && (
            <ProcessesList data={data} toPrint={toPrint} displayValue={3} />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Processes;
