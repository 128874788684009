import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import ImageCard from 'components/Image/Card';
import { toMaskedCpf } from 'utils/formatters';

import './styles.less';

const Card = ({ data, refreshList }) => {
  const hascpfOrPersonId = useMemo(() => {
    if (data?.cpf || data?.personId) return true;

    return false;
  }, [data]);
  return (
    <div className="private-faceset-card">
      {data?.similarity && (
        <div className="similarity">{Number(data?.similarity).toFixed(2)}%</div>
      )}
      <ImageCard
        image={data?.image}
        title={hascpfOrPersonId && (toMaskedCpf(data?.cpf) || data?.personId)}
        subtitle={data?.description}
        refreshList={refreshList}
        _id={data?._id}
      />
    </div>
  );
};
Card.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.string,
    cpf: PropTypes.string,
    personId: PropTypes.string,
    description: PropTypes.string,
    similarity: PropTypes.number,
    _id: PropTypes.string,
  }).isRequired,
  refreshList: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
};

export default Card;
