/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Collapse, Checkbox, Tag, Form, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import ToggleIcon from 'components/CollapseHeader/ToggleIcon';
import CollapseTitle from 'components/CollapseHeader/CollapseTitle';
import templateServicesCategories from 'utils/data/templateServicesCategories.json';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import PropTypes from 'prop-types';

import './styles.less';
import FeatureFlagWrapper from 'components/FeatureFlagWrapper';

import PermissionWrapper from 'routes/PermissionWrapper';
import Text from 'antd/lib/typography/Text';

const I18N_BASE_PATH = 'pages.private.routines.detail.components.routineService';

const RoutineService = ({ servicesOptions, services, onChange }) => {
  const { t: translate } = useTranslation();
  const { i18nNumberToFormattedString } = useI18nFormatters();

  const { Panel } = Collapse;

  const [servicesByCategory, setServicesByCategory] = useState([]);
  const [collapseActiveKey, setCollapseActiveKey] = useState('basic_info');

  useEffect(() => {
    const _servicesByCategory =
      servicesOptions?.reduce((categories, currentSource) => {
        if (
          !currentSource?.relatedRules ||
          currentSource.category === 'hidden_category'
        ) {
          return categories;
        }
        const categoryIndex = categories.findIndex(
          (cat) => cat.category === currentSource.category
        );
        const selected = services?.includes(currentSource.source);
        currentSource.selected = selected;
        if (categoryIndex >= 0) {
          categories[categoryIndex].sources.push(currentSource);
          categories[categoryIndex].selected += selected ? 1 : 0;
        } else {
          const categoryInfo = templateServicesCategories[currentSource.category];
          categories.push({
            category: currentSource.category,
            sources: [currentSource],
            name: categoryInfo?.title,
            icon: categoryInfo?.icon,
            selected: selected ? 1 : 0,
          });
        }
        return categories;
      }, []) || [];

    const catInOrder = [];
    Object.keys(templateServicesCategories).forEach((cat) => {
      const catFound = _servicesByCategory.find((serv) => {
        return serv.category === cat;
      });
      if (catFound) catInOrder.push(catFound);
    });

    setServicesByCategory(catInOrder);
  }, [services, servicesOptions]);

  const onCheckboxChangeHandler = useCallback((item) => {
    let newServices = [...services];
    if (item.selected) {
      newServices = newServices.filter((i) => i !== item.source);
    } else {
      newServices.push(item.source);
    }
    onChange(newServices);
  });

  const renderDescription = useCallback(
    (description) => {
      const sanitizedDescription = description?.trim();
      if (!sanitizedDescription.length || description === 'desc')
        return translate(`${I18N_BASE_PATH}.descriptionNotAvailable`);
      return description;
    },
    [translate]
  );
  const customOrder = ['pfCpfData', 'pfBasicData', 'pfData'];

  function customSort(arr, order) {
    return arr.sort((a, b) => {
      const aIndex = order.indexOf(a.sectionAttr);
      const bIndex = order.indexOf(b.sectionAttr);

      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });
  }

  return (
    <>
      <Row align="middle" className="flex no-mrg mrg-top-50">
        <Col className="flex-1 page-title">
          <PageTitle
            title={translate(`${I18N_BASE_PATH}.title`)}
            subtitle={translate(`${I18N_BASE_PATH}.subtitle`)}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="routine-service-collapse-container flex-column">
            <Collapse
              expandIcon={({ isActive }) => <ToggleIcon isActive={isActive} />}
              className="flex-1 gx-w-100 radius-8"
              defaultActiveKey="basic_info"
              accordion
              onChange={(activeKey) => setCollapseActiveKey(activeKey)}
            >
              {servicesByCategory?.map((category) => {
                return (
                  <Panel
                    forceRender
                    key={category?.category}
                    className="profile-model-collapse"
                    showArrow={collapseActiveKey !== category?.category}
                    header={
                      <>
                        <Col span={12}>
                          <CollapseTitle
                            icon={category?.icon}
                            title={translate(category?.name)}
                            extra={
                              category.selected > 0 && (
                                <Tag color="success">{`${category.selected} ${
                                  category.selected === 1
                                    ? translate(
                                        `${I18N_BASE_PATH}.panelHeader.quantity.one`
                                      )
                                    : translate(
                                        `${I18N_BASE_PATH}.panelHeader.quantity.moreThanOne`
                                      )
                                }`}</Tag>
                              )
                            }
                          />
                        </Col>
                        <Col span={12}>
                          {collapseActiveKey === category?.category && (
                            <Row justify="end" className="list-header  text-default">
                              <Col
                                span={16}
                                className="documentation-item-title mrg-right-15"
                              >
                                <span>
                                  {translate(`${I18N_BASE_PATH}.panelHeader.description`)}{' '}
                                </span>
                              </Col>
                              <PermissionWrapper
                                requiredPermissions={['integration-guide:read']}
                              >
                                <Col span={6} className="documentation-item-title">
                                  <span>
                                    {translate(
                                      `${I18N_BASE_PATH}.panelHeader.documentation`
                                    )}
                                  </span>
                                </Col>
                              </PermissionWrapper>
                            </Row>
                          )}
                        </Col>
                      </>
                    }
                  >
                    <Row>
                      <Col flex={1} className="pdd-horizon-45">
                        {category?.sources?.length > 0 &&
                          customSort(category?.sources, customOrder)?.map((item) => (
                            <Row key={item.source} className="data-source-checkbox">
                              <Col span={12} className="column-checkbox">
                                <Form.Item
                                  name={['backgroundCheckingServices', item.source]}
                                  noStyle
                                >
                                  <Checkbox
                                    checked={item.selected}
                                    onChange={() => {
                                      onCheckboxChangeHandler(item);
                                    }}
                                  >
                                    <Text className="max-lines-2">
                                      <Text
                                        className="text-dark"
                                        title={translate(
                                          `services.${item.source}.title`,
                                          item.title
                                        )}
                                      >
                                        {item?.source === 'pf_cpf_data' && (
                                          <Tag color="blue">
                                            <strong>
                                              {translate(
                                                `components.dataSourceCards.new`
                                              )}
                                            </strong>
                                          </Tag>
                                        )}
                                        <Tooltip
                                          placement="right"
                                          title={item.sectionAttr}
                                        >
                                          <span
                                            className="width-100"
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'column',
                                            }}
                                          >
                                            <span style={{ fontWeight: '700' }}>
                                              {!!item.subtitle &&
                                                translate(
                                                  `services.${item.source}.subtitle`,
                                                  item.title
                                                )}
                                            </span>
                                            <span>
                                              {translate(
                                                `services.${item.source}.title`,
                                                item.title
                                              )}
                                            </span>
                                          </span>
                                        </Tooltip>
                                      </Text>
                                      <FeatureFlagWrapper featureFlag="costPrices">
                                        <Text className="data-source-price">
                                          +{' '}
                                          {i18nNumberToFormattedString(
                                            item.price,
                                            'pt-BR'
                                          )}{' '}
                                          /{' '}
                                          {translate(
                                            `${I18N_BASE_PATH}.panelContent.priceConsultation`
                                          )}
                                        </Text>
                                      </FeatureFlagWrapper>
                                    </Text>
                                  </Checkbox>
                                </Form.Item>
                              </Col>
                              <>
                                <Col span={8} className="mrg-right-15">
                                  <span>
                                    {renderDescription(
                                      translate(
                                        `services.${item?.source}.description`,
                                        item?.description
                                      )
                                    )}
                                  </span>
                                </Col>
                                <PermissionWrapper
                                  requiredPermissions={['integration-guide:read']}
                                >
                                  <Col span={2}>
                                    <a
                                      href={item?.documentationUrl}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      Link
                                    </a>
                                  </Col>
                                </PermissionWrapper>
                              </>
                            </Row>
                          ))}
                      </Col>
                    </Row>
                  </Panel>
                );
              })}
            </Collapse>
          </div>
        </Col>
      </Row>
      <Row />
    </>
  );
};

RoutineService.propTypes = {
  servicesOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  services: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RoutineService;
