/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import EmptyState from 'components/EmptyState';
import EmptyExecutionState from 'components/EmptyExecutionState';
import ViewMore from 'components/ViewMore';

import MediaExposureCard from './MediaExposureCard';
import useAllMediaExposureModal from './AllMediaExposureModal';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

const PfMediaProfileAndExposure = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const mediaExposure = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const mediaExposureItems = useMemo(() => mediaExposure?.newsItems, [mediaExposure]);

  const { openModal, AllMediaExposureModal } = useAllMediaExposureModal({
    data: mediaExposure,
    lastConsultation: mediaExposure?.lastUpdateDate,
  });

  return (
    <>
      {AllMediaExposureModal}

      <DataSourceCardCollapsePanel
        customId="media-exposure-component"
        title={translate(
          'components.dataSourceCards.mediaExposure.index.mediaExposureCard.dossierCard.title'
        )}
        icon="caf-ic_news"
        toPrint={toPrint}
      >
        <div className="flex flex-column no-mrg no-pdd">
          {queryWorked ? (
            <>
              {mediaExposure && !mediaExposureItems?.length > 0 && (
                <EmptyState
                  type="empty-success"
                  description={translate(
                    'components.dataSourceCards.mediaExposure.index.mediaExposureCard.dossierCard.querySucceeded.emptyState.description'
                  )}
                  className="mrg-btm-20 mrg-top-0"
                />
              )}

              <Row className="media-exposure-container reset-mrg">
                {mediaExposure &&
                  mediaExposureItems?.length > 0 &&
                  mediaExposureItems
                    ?.slice(0, toPrint ? mediaExposureItems?.length : 4)
                    ?.map((media) => <MediaExposureCard key={media?.url} data={media} />)}
              </Row>

              {!toPrint && mediaExposureItems?.length > 4 && (
                <Row className="mrg-top-20">
                  <ViewMore
                    title={`${translate(
                      'components.dataSourceCards.mediaExposure.index.mediaExposureCard.dossierCard.querySucceeded.viewMore.title'
                    )} (${mediaExposureItems?.length})`}
                    type="link"
                    onClick={() => openModal()}
                  />
                </Row>
              )}
            </>
          ) : (
            <EmptyExecutionState
              statusCode={statusCode}
              message={source?.message || mediaExposure?.message}
              className="mrg-btm-20 mrg-top-0"
            />
          )}
        </div>
      </DataSourceCardCollapsePanel>
    </>
  );
};

export default PfMediaProfileAndExposure;
