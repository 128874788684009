const I18N_BASE_PATH =
  'pages.private.profiles.components.dossierPrint.utils.checkboxOptions';

const peopleOptions = [
  {
    _id: 'trust_validations',
    label: `${I18N_BASE_PATH}.peopleOptions.trustValidations`,
    icon: 'caf-ic_combateafraude',
    order: 1,
  },
  {
    _id: 'independent_consultants',
    label: `${I18N_BASE_PATH}.peopleOptions.independentConsultants`,
    icon: 'caf-ic_company',
    order: 2,
  },
  {
    _id: 'criminal_information',
    label: `${I18N_BASE_PATH}.peopleOptions.criminalInformation`,
    icon: 'caf-ic_criminal',
    order: 3,
  },
  {
    _id: 'financial_information',
    label: `${I18N_BASE_PATH}.peopleOptions.financialInformation`,
    icon: 'caf-ic_dollar_sign',
    order: 4,
  },
  {
    _id: 'restrictive_lists',
    label: `${I18N_BASE_PATH}.peopleOptions.restrictiveLists`,
    icon: 'caf-ic_lawier',
    order: 5,
  },
  {
    _id: 'labor_information',
    label: `${I18N_BASE_PATH}.peopleOptions.laborInformation`,
    icon: 'caf-ic_awards',
    order: 6,
  },
  {
    _id: 'driver_information',
    label: `${I18N_BASE_PATH}.peopleOptions.driverInformation`,
    icon: 'caf-ic_driver',
    order: 7,
  },
  {
    _id: 'legal_information',
    label: `${I18N_BASE_PATH}.peopleOptions.legalInformation`,
    icon: 'caf-ic_law',
    order: 8,
  },
  {
    _id: 'relationships',
    label: `${I18N_BASE_PATH}.peopleOptions.relationships`,
    icon: 'caf-ic_users',
    order: 9,
  },
  {
    _id: 'media_exposure',
    label: `${I18N_BASE_PATH}.peopleOptions.mediaExposure`,
    icon: 'caf-ic_news',
    order: 10,
  },
];

const companiesOptions = [
  {
    _id: 'trust_validations',
    label: `${I18N_BASE_PATH}.companiesOptions.trustValidations`,
    icon: 'caf-ic_combateafraude',
    order: 1,
  },
  {
    _id: 'qsa',
    label: `${I18N_BASE_PATH}.companiesOptions.qsa`,
    icon: 'caf-ic_user_active',
    order: 2,
  },
  {
    _id: 'financial_information',
    label: `${I18N_BASE_PATH}.companiesOptions.financialInformation`,
    icon: 'caf-ic_dollar_sign',
    order: 3,
  },
  {
    _id: 'restrictive_lists',
    label: `${I18N_BASE_PATH}.companiesOptions.restrictiveLists`,
    icon: 'caf-ic_lawier',
    order: 4,
  },
  {
    _id: 'labor_information',
    label: `${I18N_BASE_PATH}.companiesOptions.laborInformation`,
    icon: 'caf-ic_awards',
    order: 5,
  },
  {
    _id: 'legal_information',
    label: `${I18N_BASE_PATH}.companiesOptions.legalInformation`,
    icon: 'caf-ic_law',
    order: 6,
  },
];

export const legacyOptions = (type) => {
  return type === 'PF' ? peopleOptions : companiesOptions;
};
