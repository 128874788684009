import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Collapse } from 'antd';

import { ListExecutions } from 'pages/private/GlobalProfiles/components/ListExecutions/index';
import { CardGlobalExecution } from 'pages/private/GlobalProfiles/components/ListExecutions/CardGlobalExecution';

const { Panel } = Collapse;

const ExecutionsLinked = ({ executions }) => {
  const { t: translate } = useTranslation();
  return (
    <>
      <Row className="no-mrg no-pdd mrg-vertical-30" justify="space-between">
        <div>
          {translate(
            'pages.private.globalProfiles.components.tabs.components.summary.components.executionsLinked.allExecutions'
          )}
        </div>
        {/* <div>
          <Link to="/" target="_blank" rel="noopener noreferrer" className="text-bold">
            See all
          </Link>
        </div> */}
      </Row>
      <ListExecutions showRowTitle>
        {executions?.length &&
          executions?.map((exec) => {
            return <CardGlobalExecution data={exec} />;
          })}
      </ListExecutions>
    </>
  );
};

export default ExecutionsLinked;
