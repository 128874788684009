import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam } from 'use-query-params';
import { Row, Spin } from 'antd';

import { useComponentDocumentDBList } from 'components/List';

import PageTitle from 'components/PageTitle';
import PeopleCard from './components/PeopleCard';

// import EmptyPage from './components/EmptyPage';

import './styles.less';

const ProfilesList = ({ segment }) => {
  const { t: translate } = useTranslation();

  const { data, loading, componentList, updateParams } = useComponentDocumentDBList({
    component: PeopleCard,
    getParams: {
      url: '/global/profiles/profile?segment=person',
      config: {
        params: {},
      },
    },
    rowKey: '_id',
  });

  const [query] = useQueryParams({
    _order: StringParam,
    _sort: StringParam,
  });

  return (
    <div id="profiles-list-component">
      <Row align="middle" justify="space-between" className="no-mrg">
        <PageTitle
          title={translate('pages.private.globalProfiles.components.List.title')}
          subtitle={translate('pages.private.globalProfiles.components.List.subtitle')}
        />
      </Row>
      {loading ? (
        <div className="flex center">
          <Spin className="mrg-top-20 mrg-btm-25 mrg-horizon-25" />
        </div>
      ) : (
        componentList
      )}
    </div>
  );
};

export default ProfilesList;
