import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import RenderPanelContentRow from './RenderPanelContentRow';

const RenderPanel = () => {
  const RenderPanelContent = useCallback(
    ({ formRef, allServices, docs, disabled, onValuesChange, categoryRef }) => {
      return (
        <Row>
          <Col flex={1} className="pdd-horizon-45">
            {docs?.length > 0 &&
              docs.map((item) => (
                <RenderPanelContentRow
                  service={item}
                  disabled={disabled}
                  formRef={formRef}
                  categoryRef={categoryRef}
                  allServices={allServices}
                  onChange={onValuesChange}
                />
              ))}
          </Col>
        </Row>
      );
    },
    []
  );

  return { RenderPanelContent };
};

RenderPanel.propTypes = {
  docs: PropTypes.arrayOf(PropTypes.object),
  allServices: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool.isRequired,
  onValuesChange: PropTypes.func.isRequired,
};

RenderPanel.defaultProps = {
  docs: undefined,
  allServices: [],
};

export default RenderPanel;
