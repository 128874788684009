import pdf from 'assets/images/pdf.svg';
import sheet from 'assets/images/sheet.svg';
import img from 'assets/images/img.svg';
import sound from 'assets/images/sound.svg';
import text from 'assets/images/doc.svg';
import video from 'assets/images/video.svg';
import compress from 'assets/images/compress.svg';

export function renderImgByExtension(mimeType = 'null') {
  const type = mimeType?.split('/');
  if (type && type[0] === 'image') {
    return img;
  }
  if (mimeType === 'text/csv') {
    return sheet;
  }
  if (type && type[0] === 'text') {
    return text;
  }
  if (type && type[0] === 'video') {
    return video;
  }
  if (type && type[0] === 'audio') {
    return sound;
  }
  if (mimeType === 'application/pdf') {
    return pdf;
  }
  if (mimeType === 'application/zip') {
    return compress;
  }
  return text;
}

export function renderColorTagByExtension(mimeType = 'null') {
  const type = mimeType?.split('/');
  if (type && type[0] === 'image') {
    return '#2f80ed';
  }
  if (mimeType === 'application/pdf') {
    return '#eb5757';
  }
  if (mimeType === 'application/zip') {
    return '#faad14';
  }
  if (mimeType === 'text/csv') {
    return '#25b161';
  }
  // if (type[0] === 'text') {
  //   return '';
  // }
  if (type && type[0] === 'video') {
    return 'rgb(255 145 48)';
  }
  // if (type[0] === 'audio') {
  //   return '';
  // }
  return '#828282';
}
