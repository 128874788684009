import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

import './styles.less';

const { Title, Text } = Typography;

const PageTitle = ({ title, subtitle, info, subtitleBreak, align, style }) => {
  return (
    <div id="page-title-component" className={`page-title mrg-btm-15 align-${align}`}>
      <Title style={style} className="title">
        {title}
      </Title>

      {subtitle && (
        <Text className="subtitle">
          {subtitle} {info}
        </Text>
      )}

      {subtitleBreak && <Text className="subtitle-break">{subtitleBreak}</Text>}
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subtitle: PropTypes.string,
  info: PropTypes.node,
  subtitleBreak: PropTypes.string,
  align: PropTypes.string,
  style: PropTypes.object,
};

PageTitle.defaultProps = {
  subtitle: undefined,
  info: undefined,
  subtitleBreak: undefined,
  align: undefined,
  style: undefined,
};

export default PageTitle;
