/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import {
  federalCriminalBackgroundTranslation,
  stateCriminalBackgroundTranslation,
} from 'utils/personalTranslations';
import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import EmptyState from 'components/EmptyState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';
import DossierCard from 'pages/private/Profiles/components/DossierCard';

const CriminalInformation = ({ data /* toPrint */ }) => {
  const { t: translate } = useTranslation();
  // const criminal = useMemo(() => data?.criminal, [data]);

  // const urlId = useMemo(() => data?.recurrenceModel?._id, [data]);

  const dataSources = useMemo(
    () =>
      data?.profileTemplate?.backgroundChecking?.services?.map(({ source }) => source) ||
      [],
    [data]
  );

  const federalCrimes = useMemo(() => {
    const isProcessing = verifySourcesProcessing(data, 'federalCriminalBackground');

    // if (!criminal) return undefined;

    const federalCriminalData = data?.criminal?.federalCriminalBackground;

    if (!federalCriminalData) {
      return {
        isProcessing,
      };
    }

    const translatedStatus = federalCriminalData?.status
      ? federalCriminalBackgroundTranslation[federalCriminalData?.status]
      : federalCriminalBackgroundTranslation.NULO;

    return {
      emissionDate: federalCriminalData?.emissionDate,
      translatedStatus,
    };
  }, [data]);

  const stateCrimes = useMemo(() => {
    const isProcessing = verifySourcesProcessing(data, 'stateCriminalBackground');

    // if (!criminal) return undefined;

    const stateCriminalData = data?.criminal?.stateCriminalBackground;

    if (!stateCriminalData) {
      return {
        isProcessing,
      };
    }

    const translatedStatus = stateCriminalData?.status
      ? stateCriminalBackgroundTranslation[stateCriminalData?.status]
      : stateCriminalBackgroundTranslation.NULO;

    const splittedIssueDate = stateCriminalData?.certificateIssueDate?.split(' ');

    return {
      emissionDate:
        splittedIssueDate?.length === 2
          ? `${splittedIssueDate[1]} de ${splittedIssueDate[0]}`
          : stateCriminalData?.certificateIssueDate,
      translatedStatus,
    };
  }, [data]);

  const showStateCriminal = useMemo(
    () =>
      dataSources.includes('pf_antecedentes') || data?.criminal?.stateCriminalBackground,
    [data, dataSources]
  );

  const showFederalCriminal = useMemo(
    () =>
      dataSources.includes('pf_criminal_background') ||
      data?.criminal?.federalCriminalBackground,
    [data, dataSources]
  );

  return (
    <DossierCard
      customId="criminal-background-component"
      title={translate(
        'pages.private.profiles.components.dataSourceCards.criminalInformation.index.criminalInformation.dossierCard.title'
      )}
      subtitle={translate(
        'pages.private.profiles.components.dataSourceCards.criminalInformation.index.criminalInformation.dossierCard.subtitle'
      )}
      icon="caf-ic_criminal"
    >
      <Row>
        {showStateCriminal && (
          <ColumnLabel
            title={translate(
              'pages.private.profiles.components.dataSourceCards.criminalInformation.index.criminalInformation.stateCriminal.columnLabel.title'
            )}
            description={translate(stateCrimes?.translatedStatus?.description)}
            type={stateCrimes?.translatedStatus?.type}
            span={12}
          >
            {/* {!stateCrimes && (
              <EmptyState type="empty-recurrence" hiddenAction={toPrint} urlId={urlId} />
            )} */}

            {!stateCrimes?.translatedStatus && stateCrimes?.isProcessing && (
              <EmptyState type="processing" />
            )}
            {!stateCrimes?.translatedStatus && !stateCrimes?.isProcessing && (
              <EmptyState type="waiting_consult" />
            )}

            {stateCrimes?.translatedStatus?.type === 'primary' && (
              <span className="mrg-btm-5">
                {translate(
                  'pages.private.profiles.components.dataSourceCards.criminalInformation.index.criminalInformation.stateCriminal.emptyRegister'
                )}
              </span>
            )}
            {stateCrimes?.translatedStatus?.type === 'default' && (
              <span className="mrg-btm-5">
                {data?.backgroundChecking?.services?.pf_antecedentes?.message}
              </span>
            )}

            {stateCrimes?.emissionDate && (
              <span>
                {translate(
                  'pages.private.profiles.components.dataSourceCards.criminalInformation.index.criminalInformation.stateCriminal.emissionDate'
                )}{' '}
                {stateCrimes?.emissionDate}
              </span>
            )}
          </ColumnLabel>
        )}

        {showFederalCriminal && (
          <ColumnLabel
            title={translate(
              'pages.private.profiles.components.dataSourceCards.criminalInformation.index.criminalInformation.federalCriminal.columnLabel.title'
            )}
            description={translate(federalCrimes?.translatedStatus?.description)}
            type={federalCrimes?.translatedStatus?.type}
            span={12}
          >
            {/* {!federalCrimes && (
              <EmptyState type="empty-recurrence" hiddenAction={toPrint} urlId={urlId} />
            )} */}

            {!federalCrimes?.translatedStatus && federalCrimes?.isProcessing && (
              <EmptyState type="processing" />
            )}
            {!federalCrimes?.translatedStatus && !federalCrimes?.isProcessing && (
              <EmptyState type="waiting_consult" />
            )}

            {federalCrimes?.translatedStatus?.type === 'primary' && (
              <span className="mrg-btm-5">
                {translate(
                  'pages.private.profiles.components.dataSourceCards.criminalInformation.index.criminalInformation.federalCriminal.emptyRegister'
                )}
              </span>
            )}

            {federalCrimes?.emissionDate && (
              <span>
                {translate(
                  'pages.private.profiles.components.dataSourceCards.criminalInformation.index.criminalInformation.federalCriminal.emissionDate'
                )}{' '}
                {federalCrimes?.emissionDate}
              </span>
            )}
          </ColumnLabel>
        )}
      </Row>
    </DossierCard>
  );
};

export default CriminalInformation;
