import React from 'react';
import KYBFinancialIcon from 'assets/images/company/financial.svg';
import { Trans, useTranslation } from 'react-i18next';
import {
  CompanyTabPane,
  CompanyTabs,
} from 'pages/private/KYBCompany/CompanyDetailsV2/components';
import TabTitle from '../../TabTitle';
import Accounts from './tabs/Accounts';
import NegativeInfo from './tabs/NegativeInfo';
import './styles.less';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companyDetails.subPages.financialInfo';

const FinancialInfo = ({ financialData, itemsToExport, print = false }) => {
  const { t: translate } = useTranslation();

  if (itemsToExport?.value === false) return null;

  return (
    <section className="KYB-financial-info">
      <TabTitle
        icon={KYBFinancialIcon}
        title={translate(`${I18N_BASE_PATH}.title`, 'Company info')}
        render={!print}
        subtitle={
          <Trans
            i18nKey={`${I18N_BASE_PATH}.subTitle`}
            values={{ companyName: financialData.companyName }}
            components={{ b: <b /> }}
          />
        }
      />
      <CompanyTabs
        className="tabs"
        defaultActiveKey="accounts"
        destroyInactiveTabPane
        print={print}
      >
        <CompanyTabPane
          tab={translate(`${I18N_BASE_PATH}.tabs.accounts.title`, 'Accounts')}
          key="accounts"
          print={print}
          render={!print || itemsToExport?.items.accounts.value === true}
        >
          <Accounts financialData={financialData} print={print} />
        </CompanyTabPane>
        <CompanyTabPane
          tab={translate(`${I18N_BASE_PATH}.tabs.negativeInfo.title`, 'Negative info')}
          key="negativeInfo"
          print={print}
          render={!print || itemsToExport?.items.negativeInformations.value === true}
        >
          <NegativeInfo financialData={financialData} print={print} />
        </CompanyTabPane>
      </CompanyTabs>
    </section>
  );
};

export default FinancialInfo;
