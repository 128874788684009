import React, { useMemo } from 'react';
import './styles.less';
import { useTranslation } from 'react-i18next';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import { Col, Collapse, Row, List } from 'antd';
import EmptyExecutionState from 'components/EmptyExecutionState';

import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

const I18N_BASE_PATH = 'components.dataSourceCards.basicInfos.components';
const { Panel } = Collapse;

const PfPhoneScore = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_phone_call"
      title={translate(`${I18N_BASE_PATH}.basicInfosCard.sources.pfPhoneScore`)}
      toPrint={toPrint}
    >
      {!queryWorked ? (
        <EmptyExecutionState
          className="mrg-top-10"
          statusCode={statusCode}
          message={parsedData?.message}
        />
      ) : (
        <Row id="phone-score-id">
          <Col span={24} className="mrg-btm-25 col-ant">
            <span className="text-style">
              <strong>{translate(`${I18N_BASE_PATH}.phoneScore.fields.action`)}: </strong>
              {capitalizeFirstLetter(parsedData?.risk?.recommendation)}
            </span>
          </Col>
          <Col span={8} className="mrg-btm-25 col-ant">
            <span className="text-style">
              <strong>
                {translate(`${I18N_BASE_PATH}.phoneScore.fields.severity`)}:{' '}
              </strong>
              {capitalizeFirstLetter(parsedData?.risk?.level)}
            </span>
          </Col>
          <Col span={8} className="mrg-btm-25 col-ant">
            <span className="text-style">
              <strong>{translate(`${I18N_BASE_PATH}.phoneScore.fields.score`)}: </strong>
            </span>
          </Col>
          <Col span={24} className="mrg-btm-25 col-ant">
            <span className="text-style">
              <strong>{translate(`${I18N_BASE_PATH}.phoneScore.fields.status`)}: </strong>
              {parsedData?.riskInsights?.status}
            </span>
          </Col>
          <Col span={24} className="mrg-btm-25 col-ant">
            <span className="text-style">
              <strong>
                {translate(`${I18N_BASE_PATH}.phoneScore.fields.category`)}:{' '}
              </strong>
              {!parsedData?.riskInsights?.category.length ? (
                <>{translate(`${I18N_BASE_PATH}.phoneScore.fields.notFound`)}</>
              ) : (
                parsedData?.riskInsights?.category?.map((list) => (
                  <>
                    <span className="text-style span-style">{list}</span>
                  </>
                ))
              )}
            </span>
          </Col>
          <Col span={24} className="mrg-btm-25 col-ant">
            <span className="text-style">
              <strong>{translate(`${I18N_BASE_PATH}.phoneScore.fields.codeIp`)}: </strong>
              {!parsedData?.riskInsights?.numberType.length ? (
                <>{translate(`${I18N_BASE_PATH}.phoneScore.fields.notFound`)}</>
              ) : (
                parsedData?.riskInsights?.numberType?.map((list) => (
                  <>
                    <Col span={24} className="mrg-top-5">
                      <span className="text-style">{list}</span>
                    </Col>
                  </>
                ))
              )}
            </span>
          </Col>
          <Col span={24} className="mrg-btm-25 col-ant">
            <span className="text-style">
              <strong>
                {translate(`${I18N_BASE_PATH}.phoneScore.fields.codeEmail`)}:{' '}
              </strong>
              {!parsedData?.riskInsights?.email?.length ? (
                <>{translate(`${I18N_BASE_PATH}.phoneScore.fields.notFound`)}</>
              ) : (
                parsedData?.riskInsights?.email?.map((list) => (
                  <>
                    <Col span={24} className="mrg-top-5">
                      <span className="text-style">{list}</span>
                    </Col>
                  </>
                ))
              )}
            </span>
          </Col>
          <Col span={24} className="mrg-btm-25 col-ant">
            <span className="text-style">
              <strong>
                {translate(`${I18N_BASE_PATH}.phoneScore.fields.codePhone`)}:{' '}
              </strong>
              {!parsedData?.riskInsights?.numberType.length ? (
                <>{translate(`${I18N_BASE_PATH}.phoneScore.fields.notFound`)}</>
              ) : (
                parsedData?.riskInsights?.numberType?.map((list) => (
                  <>
                    <Col span={24} className="mrg-top-5">
                      <span className="text-style">{list}</span>
                    </Col>
                  </>
                ))
              )}
            </span>
          </Col>
          <Col span={24} className="mrg-top-10">
            <Collapse
              expandIconPosition="right"
              defaultActiveKey={toPrint ? ['1'] : null}
            >
              <Panel
                disabled={!parsedData?.riskInsights?.a2p?.length}
                header={
                  <strong className="list-subtitle">
                    {translate(`${I18N_BASE_PATH}.phoneScore.fields.codeA2P`)}
                  </strong>
                }
                extra={
                  <span>
                    {parsedData?.riskInsights?.a2p?.length}{' '}
                    {parsedData?.riskInsights?.a2p?.length > 0
                      ? translate(`${I18N_BASE_PATH}.phoneScore.fields.foundRegister`)
                      : translate(`${I18N_BASE_PATH}.phoneScore.fields.notFoundRegister`)}
                  </span>
                }
                key="1"
              >
                <List
                  size="small"
                  dataSource={parsedData?.riskInsights?.a2p}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                />
              </Panel>
              <Panel
                disabled={!parsedData?.riskInsights?.p2p?.length}
                header={
                  <strong className="list-subtitle">
                    {translate(`${I18N_BASE_PATH}.phoneScore.fields.codeP2P`)}
                  </strong>
                }
                extra={
                  <span>
                    {parsedData?.riskInsights?.p2p?.length}{' '}
                    {parsedData?.riskInsights?.p2p?.length > 0
                      ? translate(`${I18N_BASE_PATH}.phoneScore.fields.foundRegister`)
                      : translate(`${I18N_BASE_PATH}.phoneScore.fields.notFoundRegister`)}
                  </span>
                }
                key="2"
              >
                <List
                  size="small"
                  dataSource={parsedData?.riskInsights?.p2p}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                />
              </Panel>
            </Collapse>
          </Col>
        </Row>
      )}
    </DataSourceCardCollapsePanel>
  );
};
export default PfPhoneScore;
