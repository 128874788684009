import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';
import { Typography } from 'antd';
import classNames from 'classnames';

import { useProfileTemplate } from 'hooks/profileTemplate';
import EmptyMessage from 'components/EmptyMessage';

import { useAuth } from 'hooks/auth';
import BaseCard from '../BaseCard';

import './styles.less';

const { Text } = Typography;

const I18N_BASE_PATH =
  'pages.private.profileModels.profileModelCreate.confirmation.components.cardComplianceRules.index';

const CardComplianceRules = () => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();

  const { profileTemplate } = useProfileTemplate();

  const showSubjectTitle = useMemo(
    () => !!user?.accountData?.featureFlags?.useRulesSubjectTitle,
    [user]
  );

  const validations = useMemo(
    () =>
      [
        ...(profileTemplate?.validations || []),
        ...(profileTemplate?.profileRules || []),
      ]?.filter(({ actionWhenInvalid }) => actionWhenInvalid) || [],
    [profileTemplate]
  );

  return (
    <div id="confirmation-card-compliance-rules-component">
      <BaseCard
        step="compliance_rules"
        title={translate(`${I18N_BASE_PATH}.baseCard.title`)}
      >
        {validations?.length <= 0 ? (
          <EmptyMessage
            description={translate(`${I18N_BASE_PATH}.emptyMessage.description`)}
          />
        ) : (
          <>
            {validations?.map((validation) => (
              <div
                key={validation?.rule?.value || validation.rule}
                className="validations-list-item"
              >
                <div
                  className={classNames('validation-status', {
                    reprove: validation?.actionWhenInvalid === 'REPROVE',
                    pending:
                      validation?.actionWhenInvalid === 'PENDING' ||
                      validation?.actionWhenInvalid === 'PENDING_SUBMISSION',
                  })}
                />
                <Text>
                  {showSubjectTitle && validation.subjectTitle
                    ? translate(
                        `validations.${validation.rule.key}.subjectTitle`,
                        validation.subjectTitle
                      )
                    : translate(
                        `validations.${validation.rule.key}.title`,
                        validation.title
                      )}
                </Text>
              </div>
            ))}
          </>
        )}
      </BaseCard>
    </div>
  );
};

CardComplianceRules.propTypes = {};

export default CardComplianceRules;
