import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, Row, Col, Card, Button } from 'antd';
import { labelColor, executionStatus } from 'utils/handlerStatus';
import { Link } from 'react-router-dom';
import { useWorkflow } from 'hooks/useWorkflow';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import IconStatus from 'pages/private/Profiles/components/IconStatus';
import ValidationsList from '../../Tabs/components/Summary/components/LastValidations/components/ValidationsList';
import useModalConnectExecution from '../ModalConnectExecution/index';
import './styles.less';

const { Panel } = Collapse;

export const CardGlobalExecution = ({ data: exec, extraComponent = '' }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();
  const spanNumber = extraComponent ? 3 : 4;
  const { data: _workflowsData } = useWorkflow();

  const { ModalComponent, openModal } = useModalConnectExecution();

  const getNameOfWorkflow = useCallback((workflowId) => {
    return (
      _workflowsData?.docs?.find((w) => w?._id === workflowId)?.definition?.name || '-'
    );
  }, []);

  const renderExtraComponent = useMemo(() => {
    if (extraComponent === 'connect-button') {
      return (
        <>
          <Button
            type="primary"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              openModal(exec._id);
            }}
            key={exec._id}
          >
            Connect
          </Button>
        </>
      );
    }
  }, [exec.profileTemplateId, extraComponent, openModal]);

  return (
    <>
      {ModalComponent}
      <Collapse bordered={false} className="collapse-execs">
        <Panel
          header={
            <Row className="flex center reset-mrg reset-pdd">
              <Col span={8}>
                <Link
                  to={`/person/${exec?._id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {exec?.summary?.name || '-'}
                </Link>
              </Col>
              <Col span={4}>{exec?.summary?.identifier || '-'}</Col>
              <Col span={spanNumber}>{exec?.summary?.birthDate || '-'}</Col>
              <Col span={spanNumber}>
                <span className="text-workflow">
                  {getNameOfWorkflow(exec?.workflowId)}
                </span>
              </Col>
              <Col span={spanNumber}>
                <div className="card-info-status">
                  <div className="wrapper-icon-text">
                    <IconStatus status={exec?.status} />
                    <span style={{ color: labelColor[exec.status] ?? '#2E70F0' }}>
                      {translate(executionStatus(exec?.status)?.value)}
                    </span>
                  </div>
                </div>
              </Col>
              {extraComponent && <Col span={3}>{renderExtraComponent}</Col>}
            </Row>
          }
        >
          <Row style={{ paddingLeft: '0px' }}>
            <Col span={24}>
              <Row>
                <Col span={12}>
                  <Row className="row-info">
                    <Col span={12}>
                      <b>
                        {translate(
                          'pages.private.globalProfiles.components.tabs.components.summary.components.keyInformation.dateOfCreation',
                          'Date of creation'
                        )}
                      </b>
                    </Col>
                    <Col span={12}>
                      {i18nFormatDate(exec?.createdAt, 'default') || '-'}
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row className="row-info">
                    <Col span={12}>
                      <b>
                        {translate(
                          'pages.private.globalProfiles.components.tabs.components.summary.components.keyInformation.email',
                          'E-mail'
                        )}
                      </b>
                    </Col>
                    <Col span={12}>{exec?.summary?.email || '-'}</Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row className="row-info">
                    <Col span={12}>
                      <b>
                        {translate(
                          'pages.private.globalProfiles.components.tabs.components.summary.components.keyInformation.phone',
                          'Phone number'
                        )}
                      </b>
                    </Col>
                    <Col span={12}>{exec?.summary?.phoneNumber || '-'}</Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row className="row-info">
                    <Col span={12}>
                      <b>
                        {translate(
                          'pages.private.globalProfiles.components.tabs.components.summary.components.keyInformation.address',
                          'Address'
                        )}
                      </b>
                    </Col>
                    <Col span={12}>{exec?.summary?.address?.full || '-'}</Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Card className="mrg-top-10">
                <span className="font-size-13">
                  {translate(
                    'pages.private.globalProfiles.components.tabs.components.summary.components.lastValidations.index.validations'
                  )}
                </span>
                <ValidationsList validations={exec?.validations} />
              </Card>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </>
  );
};
