import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col, Collapse, Divider } from 'antd';

import EmptyExecutionState from 'components/EmptyExecutionState';

import BasicInfosCard from 'components/DataSourceCards/BasicInfos/components/BasicInfosCard';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import DottedLabel from 'components/DottedLabel';

import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

const { Panel } = Collapse;

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.shared.spcRestrictionsScore.otherInfos';

const ContactContent = ({ source, toPrint }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatCurrency, i18nFormatDate } = useI18nFormatters();

  const parsedData = useMemo(() => source?.data || source, [source]);

  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const ccf = useMemo(() => parsedData?.ccf || {}, [parsedData]);
  const contumacy = useMemo(() => parsedData?.contumacy || {}, [parsedData]);
  const grantedCredit = useMemo(() => parsedData?.grantedCredit || {}, [parsedData]);

  const onlineCheckQuerySrs = useMemo(() => parsedData?.onlineCheckQuerySrs || {}, [
    parsedData,
  ]);
  const queriesPerformed = useMemo(() => parsedData?.queriesPerformed || {}, [
    parsedData,
  ]);

  const judiciaryInformation = useMemo(() => parsedData?.judiciaryInformation || {}, [
    parsedData,
  ]);

  const countermand = useMemo(() => parsedData?.countermand || {}, [parsedData]);

  const countermandDifferentDocument = useMemo(
    () => parsedData?.countermandDifferentDocument || {},
    [parsedData]
  );

  const consultedTelephone = useMemo(() => parsedData?.consultedTelephone || {}, [
    parsedData,
  ]);

  const bindTelephoneSubscriberConsulted = useMemo(
    () => parsedData?.bindTelephoneSubscriberConsulted || {},
    [parsedData]
  );

  const documentAlert = useMemo(() => parsedData?.documentAlert || {}, [parsedData]);

  const shopkeeperCheck = useMemo(() => parsedData?.shopkeeperCheck || {}, [parsedData]);

  return queryWorked ? (
    <DataSourceCardCollapsePanel
      title={translate(`${I18N_BASE_PATH}.index.title`)}
      icon="caf-ic_info"
      toPrint={toPrint}
      customId="report-spc-last-phone-card"
    >
      <Row className="mrg-top-25">
        <>
          <Col span={24}>
            {/* cheques sem fundo */}
            <Collapse>
              <Panel
                header={
                  <span className="text-dark text-bold">
                    {translate(`${I18N_BASE_PATH}.index.ccf.title`)}
                  </span>
                }
                extra={
                  <>
                    <DottedLabel
                      description={`(${ccf?.total || 0}) ${translate(
                        `${I18N_BASE_PATH}.index.restrictions`
                      )}`}
                      type={+ccf?.total > 0 ? 'danger' : 'primary'}
                    />
                  </>
                }
              >
                <Col span={24}>
                  <Row align="middle">
                    <span className="text-dark text-bold">Detalhes dos registros</span>
                  </Row>
                  <Row align="middle" className="mrg-top-8" justify="space-between">
                    <Col>
                      <span className="text-dark text-bold">
                        {translate(`${I18N_BASE_PATH}.index.ccf.columns.reasonCode`)}:
                      </span>{' '}
                      <span>{ccf?.details?.reasonCode || '-'}</span>
                    </Col>
                    <Col>
                      <span className="text-dark text-bold">
                        {translate(
                          `${I18N_BASE_PATH}.index.ccf.columns.reasonDescription`
                        )}
                        :
                      </span>{' '}
                      <span>{ccf?.details?.reasonDescription || '-'}</span>
                    </Col>
                  </Row>
                </Col>
                <Divider />
                <Col span={24}>
                  <Row align="middle">
                    <span className="text-dark text-bold">
                      {translate(`${I18N_BASE_PATH}.index.ccf.columns.lastCheck.title`)}
                    </span>
                  </Row>
                  <Row align="middle" className="mrg-top-8" justify="space-between">
                    <Col>
                      <span className="text-dark text-bold">
                        {translate(
                          `${I18N_BASE_PATH}.index.ccf.columns.lastCheck.columns.agencyNumber`
                        )}
                        :
                      </span>{' '}
                      <span>{ccf?.details?.lastCheck?.agencyNumber || '-'}</span>
                    </Col>
                    <Col>
                      <span className="text-dark text-bold">
                        {translate(
                          `${I18N_BASE_PATH}.index.ccf.columns.lastCheck.columns.date`
                        )}
                        :
                      </span>{' '}
                      <span>
                        {i18nFormatDate(ccf?.details?.lastCheck?.date, 1) || '-'}
                      </span>
                    </Col>
                    <Col>
                      <span className="text-dark text-bold">
                        {translate(
                          `${I18N_BASE_PATH}.index.ccf.columns.lastCheck.columns.code`
                        )}
                        :
                      </span>{' '}
                      <span>{ccf?.details?.lastCheck?.code || '-'}</span>
                    </Col>
                  </Row>
                </Col>
              </Panel>
            </Collapse>

            {/* Registro de contra-ordem */}
            <Collapse className="mrg-top-10">
              <Panel
                disabled
                header={
                  <span className="text-dark text-bold">
                    {translate(`${I18N_BASE_PATH}.index.countermand.title`)}
                  </span>
                }
                extra={
                  <>
                    <DottedLabel
                      description={`(${countermand?.total || 0}) ${translate(
                        `${I18N_BASE_PATH}.index.restrictions`
                      )}`}
                      type={+countermand?.total > 0 ? 'danger' : 'primary'}
                    />
                  </>
                }
              />
            </Collapse>

            {/* Registro de cheque ou talao sustado com documento diferente do
                    consultado */}
            <Collapse className="mrg-btm-10 mrg-top-10 reset-mrg reset-pdd">
              <Panel
                disabled
                header={
                  <span className="text-dark text-bold">
                    {translate(
                      `${I18N_BASE_PATH}.index.countermandDifferentDocument.title`
                    )}
                  </span>
                }
                extra={
                  <DottedLabel
                    description={`(${
                      countermandDifferentDocument?.total || 0
                    }) ${translate(`${I18N_BASE_PATH}.index.restrictions`)}`}
                    type={+countermandDifferentDocument?.total > 0 ? 'danger' : 'primary'}
                  />
                }
              />
            </Collapse>

            {/* Registros de telefones consultados */}
            <Collapse className="mrg-btm-10 mrg-top-10 reset-mrg reset-pdd">
              <Panel
                disabled
                header={
                  <span className="text-dark text-bold">
                    {translate(`${I18N_BASE_PATH}.index.consultedTelephone.title`)}
                  </span>
                }
                extra={
                  <DottedLabel
                    description={`(${consultedTelephone?.total || 0}) ${translate(
                      `${I18N_BASE_PATH}.index.restrictions`
                    )}`}
                    type={+consultedTelephone?.total > 0 ? 'danger' : 'primary'}
                  />
                }
              />
            </Collapse>

            {/* Registros telefones consultados associados ao inscrito consultados */}
            <Collapse className="mrg-btm-10 mrg-top-10 reset-mrg reset-pdd">
              <Panel
                disabled
                header={
                  <span className="text-dark text-bold">
                    {translate(
                      `${I18N_BASE_PATH}.index.bindTelephoneSubscriberConsulted.title`
                    )}
                  </span>
                }
                extra={
                  <DottedLabel
                    description={`(${
                      bindTelephoneSubscriberConsulted?.total || 0
                    }) ${translate(`${I18N_BASE_PATH}.index.restrictions`)}`}
                    type={
                      +bindTelephoneSubscriberConsulted?.total > 0 ? 'danger' : 'primary'
                    }
                  />
                }
              />
            </Collapse>

            {/* Registro de alertas de documento consultados */}
            <Collapse className="mrg-btm-10 mrg-top-10 reset-mrg reset-pdd">
              <Panel
                disabled
                header={
                  <span className="text-dark text-bold">
                    {translate(`${I18N_BASE_PATH}.index.documentAlert.title`)}
                  </span>
                }
                extra={
                  <DottedLabel
                    description={`(${documentAlert?.total || 0}) ${translate(
                      `${I18N_BASE_PATH}.index.restrictions`
                    )}`}
                    type={+documentAlert?.total > 0 ? 'danger' : 'primary'}
                  />
                }
              />
            </Collapse>

            {/* Registros de cheque de logistas consultados */}
            <Collapse className="mrg-btm-10 mrg-top-10 reset-mrg reset-pdd">
              <Panel
                disabled
                header={
                  <span className="text-dark text-bold">
                    {translate(`${I18N_BASE_PATH}.index.shopkeeperCheck.title`)}
                  </span>
                }
                extra={
                  <DottedLabel
                    description={`(${shopkeeperCheck?.total || 0}) ${translate(
                      `${I18N_BASE_PATH}.index.restrictions`
                    )}`}
                    type={+shopkeeperCheck?.total > 0 ? 'danger' : 'primary'}
                  />
                }
              />
            </Collapse>

            {/* Informacoes de contumacia */}
            <Collapse className="mrg-top-10">
              <Panel
                disabled={!contumacy?.details || contumacy?.details?.length <= 0}
                header={
                  <Col span={24}>
                    <Row align="middle" justify="space-between">
                      <Col>
                        <span className="text-dark text-bold">
                          {translate(`${I18N_BASE_PATH}.index.contumacy.title`)}
                        </span>
                      </Col>
                      <Col>
                        <>
                          <DottedLabel
                            description={`(${contumacy?.total || 0}) ${translate(
                              `${I18N_BASE_PATH}.index.restrictions`
                            )}`}
                            type={+contumacy?.total > 0 ? 'danger' : 'primary'}
                          />
                        </>
                      </Col>
                    </Row>
                    <Row align="middle" className="mrg-top-10" justify="space-between">
                      <Col span={6}>
                        <Row>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.index.contumacy.columns.firstDayConsultedByBracket`
                            )}
                            :
                          </span>
                        </Row>
                        <Row>
                          <span>
                            {i18nFormatDate(contumacy?.firstDayConsultedByBracket, 3) ||
                              '-'}
                          </span>
                        </Row>
                      </Col>
                      <Col span={6}>
                        <Row>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.index.contumacy.columns.lastDayConsultedByBracket`
                            )}
                            :
                          </span>
                        </Row>
                        <Row>
                          <span>
                            {i18nFormatDate(contumacy.lastDayConsultedByBracket, 3) ||
                              '-'}
                          </span>
                        </Row>
                      </Col>
                      <Col span={7}>
                        <Row>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.index.contumacy.columns.lastOccurenceDate`
                            )}
                            :
                          </span>
                        </Row>
                        <Row>
                          <span>
                            {i18nFormatDate(contumacy?.lastOccurenceDate, 3) || '-'}
                          </span>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                }
              >
                {contumacy?.details?.map((register) => (
                  <>
                    <Col span={24}>
                      <Row align="middle">
                        <span className="text-dark text-bold">
                          {i18nFormatDate(register?.inclusionDate, 1)} -{' '}
                          {register.initialCheckBankName} -{' '}
                          {register.initialCheckBankCode}
                        </span>
                      </Row>
                      <Row align="middle" className="mrg-top-8" justify="space-between">
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.index.contumacy.columns.initialCheckNumber`
                            )}
                            :
                          </span>{' '}
                          <span>{register.initialCheckNumber || '-'}</span>
                        </Col>
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.index.contumacy.columns.lastCheckNumber`
                            )}
                            :
                          </span>{' '}
                          <span>
                            {register.finalCheckNumber || register.lastCheckNumber || '-'}
                          </span>
                        </Col>
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.index.contumacy.columns.initialCheckAgencyNumber`
                            )}
                            :
                          </span>{' '}
                          <span>
                            {register.initialCheckAgencyNumber ||
                              register.initialCheckBankAgencyNumber ||
                              '-'}
                          </span>
                        </Col>
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.index.contumacy.columns.initialCheckBankAccountNumber`
                            )}
                            :
                          </span>{' '}
                          <span>
                            {register.initialCheckBankAccountNumber ||
                              register.initialCheckAccountNumber ||
                              '-'}
                          </span>
                        </Col>
                      </Row>
                      <Row align="middle" className="mrg-top-8" justify="space-between">
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.index.contumacy.columns.occurenceDate`
                            )}
                            :
                          </span>{' '}
                          <span>{i18nFormatDate(register.occurenceDate, 1) || '-'}</span>
                        </Col>
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.index.contumacy.columns.reasonDescription`
                            )}
                            :
                          </span>{' '}
                          <span>{register.reasonDescription || '-'}</span>
                        </Col>
                        <Col span={8}>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.index.contumacy.columns.origin`
                            )}
                            :
                          </span>{' '}
                          <span>{register.origin || '-'}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Divider />
                  </>
                ))}
              </Panel>
            </Collapse>

            {/* Registros de crédito concedidos consultados */}
            <Collapse className="mrg-btm-10 mrg-top-10 reset-mrg reset-pdd">
              <Panel
                disabled
                header={
                  <span className="text-dark text-bold">
                    {translate(`${I18N_BASE_PATH}.index.grantedCredit.title`)}
                  </span>
                }
                extra={
                  <DottedLabel
                    description={`(${grantedCredit?.total || 0}) ${translate(
                      `${I18N_BASE_PATH}.index.restrictions`
                    )}`}
                    type={+grantedCredit?.total > 0 ? 'danger' : 'primary'}
                  />
                }
              />
            </Collapse>

            {/* Número de cheques sustados, extraviados, roubados e cancelados contumaz */}
            <Collapse className="mrg-btm-10 mrg-top-10 reset-mrg reset-pdd">
              <Panel
                disabled
                header={
                  <Row align="middle" justify="space-between">
                    <Col span={16}>
                      <span className="text-dark text-bold">
                        {translate(`${I18N_BASE_PATH}.index.onlineCheckQuerySrs.title`)}
                      </span>
                    </Col>
                    <Col className="mrg-right-15">
                      <DottedLabel
                        description={`(${onlineCheckQuerySrs?.total || 0}) ${translate(
                          `${I18N_BASE_PATH}.index.restrictions`
                        )}`}
                        type={+onlineCheckQuerySrs?.total > 0 ? 'danger' : 'primary'}
                      />
                    </Col>
                  </Row>
                }
              />
            </Collapse>

            {/* Consultas realizadas anteriormente */}
            <Collapse className="mrg-top-10">
              <Panel
                disabled={
                  !queriesPerformed?.details || queriesPerformed?.details?.length <= 0
                }
                header={
                  <span className="text-dark text-bold">
                    {translate(`${I18N_BASE_PATH}.index.queriesPerformed.title`)}
                  </span>
                }
                extra={
                  <DottedLabel
                    description={`(${queriesPerformed?.total || 0}) Registros`}
                    type={+queriesPerformed?.total > 0 ? 'info' : 'primary'}
                  />
                }
              >
                {queriesPerformed?.details?.map((register) => (
                  <>
                    <Col span={24}>
                      <Row align="middle">
                        <span className="text-dark text-bold">
                          {register?.associateName || '-'}
                        </span>
                      </Row>
                      <Row align="middle" className="mrg-top-10" justify="space-between">
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.index.queriesPerformed.columns.consultDate`
                            )}
                            :
                          </span>{' '}
                          <span>{i18nFormatDate(register?.consultDate, 1) || '-'}</span>
                        </Col>
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.index.queriesPerformed.columns.city`
                            )}
                            :
                          </span>{' '}
                          <span>{register?.city || '-'}</span>
                        </Col>
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.index.queriesPerformed.columns.uf`
                            )}
                            :
                          </span>{' '}
                          <span>{register?.state || '-'}</span>
                        </Col>
                      </Row>
                      <Row align="middle" className="mrg-top-5">
                        <span className="collapse-label">
                          {translate(
                            `${I18N_BASE_PATH}.index.queriesPerformed.columns.entityOriginName`
                          )}
                          :
                        </span>
                        <span className="mrg-left-5">
                          {register?.entityOriginName || '-'}
                        </span>
                      </Row>
                    </Col>
                    <Divider />
                  </>
                ))}
              </Panel>
            </Collapse>

            {/* Informações do poder judiciário */}
            <Collapse className="mrg-top-10">
              <Panel
                disabled={
                  !judiciaryInformation?.details ||
                  judiciaryInformation?.details?.length <= 0
                }
                header={
                  <Col span={24}>
                    <Row align="middle" justify="space-between">
                      <Col>
                        <span className="text-dark text-bold">
                          {translate(
                            `${I18N_BASE_PATH}.index.judiciaryInformation.title`
                          )}
                        </span>
                      </Col>
                      <Col>
                        <DottedLabel
                          description={`(${judiciaryInformation?.total || 0}) ${translate(
                            `${I18N_BASE_PATH}.index.restrictions`
                          )}`}
                          type={+judiciaryInformation?.total > 0 ? 'danger' : 'primary'}
                        />
                      </Col>
                    </Row>
                    <Row align="middle" className="mrg-top-10">
                      <Col span={20}>
                        <Row>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.index.judiciaryInformation.columns.lastOccurenceDate`
                            )}
                            :
                          </span>
                        </Row>
                        <Row>
                          <span>
                            {i18nFormatDate(judiciaryInformation?.lastOccurenceDate, 1) ||
                              '-'}
                          </span>
                        </Row>
                      </Col>

                      <Col span={4}>
                        <Row>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.index.judiciaryInformation.columns.value`
                            )}
                            :
                          </span>
                        </Row>
                        <Row>
                          <span>
                            {i18nFormatCurrency(judiciaryInformation?.value, 'pt-BR') ||
                              '-'}
                          </span>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                }
              >
                {judiciaryInformation?.details?.map((register) => (
                  <>
                    <Col span={24}>
                      <Row align="middle">
                        <span className="text-dark text-bold">
                          {i18nFormatDate(register?.inclusionDate, 1)} -{' '}
                          {i18nFormatCurrency(register?.value, 'pt-BR')}
                        </span>
                      </Row>
                      <Row align="middle" className="mrg-top-8" justify="space-between">
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.index.judiciaryInformation.columns.originEntity`
                            )}
                            :
                          </span>{' '}
                          <span>{register?.originEntity || '-'}</span>
                        </Col>
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.index.judiciaryInformation.columns.lawsuitNumber`
                            )}
                            :
                          </span>{' '}
                          <span>{register.lawsuitNumber || '-'}</span>
                        </Col>
                      </Row>
                      <Row align="middle" className="mrg-top-8" justify="space-between">
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.index.judiciaryInformation.columns.courtRegion`
                            )}
                            :
                          </span>{' '}
                          <span>{register.courtRegion || '-'}</span>
                        </Col>
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.index.judiciaryInformation.columns.court`
                            )}
                            :
                          </span>{' '}
                          <span>{register.court || '-'}</span>
                        </Col>
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.index.judiciaryInformation.columns.courtState`
                            )}
                            :
                          </span>{' '}
                          <span>{register.courtState || '-'}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Divider />
                  </>
                ))}
              </Panel>
            </Collapse>
          </Col>
        </>
      </Row>
    </DataSourceCardCollapsePanel>
  ) : (
    <BasicInfosCard icon="ic_add" title={translate(`${I18N_BASE_PATH}.index.title`)}>
      <Row gutter={24}>
        <Col span={24}>
          <EmptyExecutionState
            statusCode={statusCode}
            message={source?.message || parsedData?.message}
          />
        </Col>
      </Row>
    </BasicInfosCard>
  );
};

ContactContent.propTypes = {
  person: PropTypes.shape({
    basicData: PropTypes.shape({
      phoneNumber: PropTypes.string,
      companyEmail: PropTypes.string,
    }),
    contact: PropTypes.shape({
      phones: PropTypes.shape({
        lastConsultation: PropTypes.objectOf(),
      }),
      emails: PropTypes.shape({
        lastConsultation: PropTypes.objectOf(),
      }),
    }),
    status: PropTypes.string,
  }),
  source: PropTypes.objectOf(),
  toPrint: PropTypes.bool,
};

ContactContent.defaultProps = {
  person: undefined,
  source: null,
  toPrint: false,
};

export default ContactContent;
