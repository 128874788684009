import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'services/hooks';

import { Modal, Button, Input, Form, Spin, message } from 'antd';

import PageTitle from 'components/PageTitle';

const ModalWebhook = () => {
  const { t: translate } = useTranslation();
  const [form] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);
  const [profileModelId, setProfileModelId] = useState(false);
  const [data, setData] = useState(false);
  const { get, loading } = useFetch();
  const { put, loadingPut } = useFetch();

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setProfileModelId(null);
  }, []);

  const openModal = useCallback((id) => {
    setProfileModelId(id);
    setModalVisible(true);
  }, []);

  const getProfileModel = useCallback(async () => {
    const response = await get({
      url: `/profile-templates/${profileModelId}`,
    });
    setData(response);
    form.setFieldsValue(response);
  }, [form, get, profileModelId]);

  const handleChange = useCallback(
    async (payload) => {
      try {
        await put({
          url: `/profile-templates/${profileModelId}`,
          payload: {
            ...data,
            qsaProfileTemplateId:
              data?.qsaProfileTemplateId && data?.qsaProfileTemplateId !== ''
                ? data?.qsaProfileTemplateId
                : undefined,
            ...payload,
          },
        });
        message.success(
          translate(
            'pages.private.accessKeys.integrationGuide.components.webhookModal.messageSuccess'
          )
        );
        closeModal();
      } catch {
        message.error(
          translate(
            'pages.private.accessKeys.integrationGuide.components.webhookModal.messageError'
          )
        );
      }
    },
    [closeModal, data, profileModelId, put, translate]
  );

  useEffect(() => {
    if (profileModelId) getProfileModel();
  }, [getProfileModel, profileModelId]);

  const WebhookModal = useMemo(
    () => (
      <Modal
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        visible={modalVisible}
        footer={null}
        width={700}
        onCancel={closeModal}
      >
        <PageTitle
          title={translate(
            'pages.private.accessKeys.integrationGuide.components.webhookModal.title'
          )}
          subtitle={translate(
            'pages.private.accessKeys.integrationGuide.components.webhookModal.subtitle'
          )}
        />
        <Form layout="vertical" form={form} onFinish={handleChange}>
          {loading ? (
            <Spin className="flex center gx-w-100 mrg-top-30 mrg-btm-50" />
          ) : (
            <>
              <Form.Item
                label={translate(
                  'pages.private.accessKeys.integrationGuide.components.webhookModal.webhook'
                )}
                name="webhook"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={translate(
                  'pages.private.accessKeys.integrationGuide.components.webhookModal.profileWebhook'
                )}
                name="profileWebhook"
              >
                <Input />
              </Form.Item>
              <div className="flex end-center">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loadingPut}
                  disabled={loadingPut}
                >
                  {translate(
                    'pages.private.accessKeys.integrationGuide.components.webhookModal.buttonOk'
                  )}
                </Button>
              </div>
            </>
          )}
        </Form>
      </Modal>
    ),
    [modalVisible, closeModal, translate, form, handleChange, loading, loadingPut]
  );

  return { openModal, WebhookModal };
};

export default ModalWebhook;
