import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import './styles.less';

export const ListExecutions = ({ children, extraComponent, showRowTitle }) => {
  const { t: translate } = useTranslation();
  const spanNumber = extraComponent ? 3 : 4;
  return (
    <>
      <div id="card-executions">
        <Row>
          <Col span={24}>
            {showRowTitle && (
              <Row className="header-collapse reset-pdd reset-mrg">
                <Col span={8}>
                  <b>
                    {translate(
                      'pages.private.globalProfiles.components.tabs.components.summary.components.executionsLinked.name'
                    )}
                  </b>
                </Col>
                <Col span={4}>
                  <b>
                    {translate(
                      'pages.private.globalProfiles.components.tabs.components.summary.components.executionsLinked.docId'
                    )}
                  </b>
                </Col>
                <Col span={spanNumber}>
                  <b>
                    {translate(
                      'pages.private.globalProfiles.components.tabs.components.summary.components.executionsLinked.dateOfBirth'
                    )}
                  </b>
                </Col>
                <Col span={spanNumber}>
                  <b>
                    {translate(
                      'pages.private.globalProfiles.components.tabs.components.summary.components.executionsLinked.workflow'
                    )}
                  </b>
                </Col>
                <Col span={spanNumber}>
                  <b>
                    {translate(
                      'pages.private.globalProfiles.components.tabs.components.summary.components.executionsLinked.status'
                    )}
                  </b>
                </Col>
                {extraComponent && <Col span={3}>{extraComponent}</Col>}
              </Row>
            )}
            {children}
          </Col>
        </Row>
      </div>
    </>
  );
};
