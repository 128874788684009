import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

const HeaderInfo = () => {
  const { t: translate } = useTranslation();

  return (
    <Row className="no-mrg text-bold flex center vertical-align">
      <Col span={6} className="flex center">
        {translate(
          'pages.private.groupsManagement.groupsCreate.components.newGroup.components.policiesPanels.headerInfo.read'
        )}
      </Col>
      <Col span={6} className="flex center">
        {translate(
          'pages.private.groupsManagement.groupsCreate.components.newGroup.components.policiesPanels.headerInfo.update'
        )}
      </Col>
      <Col span={6} className="flex center">
        {translate(
          'pages.private.groupsManagement.groupsCreate.components.newGroup.components.policiesPanels.headerInfo.create'
        )}
      </Col>
      <Col span={6} className="flex center">
        {translate(
          'pages.private.groupsManagement.groupsCreate.components.newGroup.components.policiesPanels.headerInfo.delete'
        )}
      </Col>
    </Row>
  );
};

export default HeaderInfo;
