import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, Typography } from 'antd';
import { useQueryParams, StringParam } from 'use-query-params';

import Button from 'components/Button';

import './styles.less';

const { Title, Text } = Typography;

const BaseCard = ({ children, step, title, subtitle, disabled }) => {
  const { t: translate } = useTranslation();

  const [, setQuery] = useQueryParams({
    step: StringParam,
  });

  const onFooterClick = useCallback(() => {
    setQuery({ step, action: 'update' });
  }, [setQuery, step]);

  return (
    <div id="confirmation-base-card-component">
      <Title level={4} className="title">
        {title}
      </Title>
      <Text className="subtitle">{subtitle}</Text>

      <Card>{children}</Card>
      <Button type="text" onClick={onFooterClick} disabled={disabled}>
        {translate(
          'pages.private.profileModels.profileModelCreate.confirmation.components.baseCard.index.button'
        )}
      </Button>
    </div>
  );
};

BaseCard.propTypes = {
  step: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

BaseCard.defaultProps = {
  disabled: false,
};

export default BaseCard;
