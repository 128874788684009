import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Pagination, Select, Button, Typography, Tooltip } from 'antd';
import CountryFlag from 'components/CountryFlag';
import SearchFilter from 'components/Filters/SearchFilter';
import copyTextToClipboard from 'utils/copyTextToClipboard';
import NoResults from 'components/NoResults';

import './styles.less';

const { Text } = Typography;

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companySearching.components.listCompanies';

const { Option, OptGroup } = Select;

const ListCompanies = ({
  companies,
  totalItems,
  handleClickResult,
  setQuery,
  query,
  pageSize,
}) => {
  const { t: translate } = useTranslation();

  const wrapperRef = useRef(null);

  const currentPage = useMemo(() => {
    return query?.page || 1;
  }, [query.page]);

  const itemOffset = useMemo(() => (currentPage - 1) * pageSize + 1);
  const itemTo = useMemo(() => {
    const itemTo = itemOffset + pageSize - 1;
    return totalItems < itemTo ? totalItems : itemTo;
  }, [pageSize, itemOffset, totalItems]);

  const pageCompanies = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    return companies.slice(startIndex, endIndex);
  }, [currentPage, companies, pageSize]);

  const handleNextPageButtonClick = () => {
    wrapperRef.current.scrollIntoView();
  };

  const onChangePage = useCallback(
    (page) => {
      setQuery({ page });
      handleNextPageButtonClick();
    },
    [setQuery]
  );

  const normalizeStatus = ({ status }) => {
    status = status?.toLowerCase();

    const nonActivities = ['nonactive', 'inactive'];
    const activities = ['active'];

    if (nonActivities.includes(status?.toLocaleLowerCase())) {
      return <span className="status inactive">Inactive</span>;
    }

    if (activities.includes(status?.toLocaleLowerCase())) {
      return <span className="status active">Active</span>;
    }

    return '----';
  };

  const renderSourcesFound = ({ isThereSayariSource, isThereCreditSource }) => (
    <div className="sources-found">
      {isThereCreditSource && (
        <span style={{ whiteSpace: 'nowrap' }}> Credit report</span>
      )}
      {isThereSayariSource && <span> Business identity</span>}
    </div>
  );

  const renderCompanies = () => {
    return pageCompanies?.map((company) => {
      return (
        <Row
          key={company?.id}
          className="row-list-element"
          onClick={(event) => {
            if (event.target.id === 'copyCompanyNumberButton') {
              return;
            }
            handleClickResult({
              businessIdentityId: company?.availableServices?.businessIdentityId ?? null,
              creditReportId: company?.availableServices?.creditReportId ?? null,
            });
          }}
        >
          <Col span={1} className="flex center">
            {company?.company?.country ? (
              <CountryFlag country={company.company.country} />
            ) : (
              '----'
            )}
          </Col>
          <Col span={6} className="apply-border-left apply-border-right">
            {company?.company?.name ?? '----'}
          </Col>
          <Col span={3} className="apply-border-right">
            <Tooltip placement="top" title={company?.company?.number ?? '----'}>
              <Text ellipsis style={{ color: 'black' }}>
                {company?.company?.number ?? '----'}
              </Text>
            </Tooltip>
            <Button
              type="link"
              style={{ paddingLeft: 0 }}
              onClick={() =>
                copyTextToClipboard(
                  company?.company?.number ?? '----',
                  translate(`${I18N_BASE_PATH}.copyButton.trackingCopySuccess`),
                  translate(`${I18N_BASE_PATH}.copyButton.trackingCopyFail`)
                )
              }
            >
              <i
                className="caf-ic_duplicate"
                id="copyCompanyNumberButton"
                style={{ marginLeft: '5px' }}
              />
            </Button>
          </Col>
          <Col span={9} className="apply-border-right">
            {company?.company?.address ?? '----'}
          </Col>
          <Col span={3} className="apply-border-right">
            {renderSourcesFound({
              isThereCreditSource: !!company?.availableServices?.creditReportId,
              isThereSayariSource: !!company?.availableServices?.businessIdentityId,
            })}
          </Col>
          <Col span={2}>{normalizeStatus({ status: company?.company?.status })}</Col>
        </Row>
      );
    });
  };

  return (
    <div className="content-list-companies" ref={wrapperRef}>
      <div className="list">
        <Row className="title-list-companies">
          <Col span={24}>{translate(`${I18N_BASE_PATH}.titles.results`, 'Results')}</Col>
        </Row>
        <Col span={24} className="showing-items-of-companies">
          <Col span={12}>
            <span>
              {translate(`${I18N_BASE_PATH}.countingResults.showing`, 'Showing')}{' '}
              {itemOffset} {translate(`${I18N_BASE_PATH}.countingResults.to`, 'to')}{' '}
              {itemTo} {translate(`${I18N_BASE_PATH}.countingResults.of`, 'of')}{' '}
              {totalItems} {translate(`${I18N_BASE_PATH}.countingResults.items`, 'items')}
            </span>
          </Col>
          <Col span={12} className="left-filters">
            <SearchFilter
              placeholder={translate(
                `${I18N_BASE_PATH}.sortCompanies.searchAddress`,
                'Search address'
              )}
              className="style-placeholder-text"
              onChange={(address) => setQuery({ address, page: 1 })}
            />
            <Select
              style={{ minWidth: 160 }}
              allowClear
              optionFilterProp="children"
              placeholder={translate(`${I18N_BASE_PATH}.sortCompanies.source`, 'Source')}
              className="style-placeholder-select"
              onClear={() => {
                setQuery({ source: undefined });
              }}
              onSelect={(source) => {
                setQuery({ source, page: 1 });
              }}
            >
              <Option value="creditReport">Credit report</Option>
              <Option value="businessIdentity">Business identity</Option>
            </Select>
            <Select
              style={{ minWidth: 115 }}
              allowClear
              className="style-placeholder-select"
              optionFilterProp="children"
              placeholder={translate(`${I18N_BASE_PATH}.sortCompanies.status`, 'Status')}
              onClear={() => {
                setQuery({ status: undefined });
              }}
              onSelect={(status) => {
                setQuery({ status, page: 1 });
              }}
            >
              <Option value="active">
                {translate(
                  `${I18N_BASE_PATH}.sortCompanies.status.option.active`,
                  'Active'
                )}
              </Option>
              <Option value="inactive">
                {translate(
                  `${I18N_BASE_PATH}.sortCompanies.status.option.inactive`,
                  'Inactive'
                )}
              </Option>
            </Select>
          </Col>
        </Col>
        <div className="columns-name-companies-list">
          <Col span={1} style={{ padding: 0 }}>
            {translate(`${I18N_BASE_PATH}.columns.country`, 'Country')}
          </Col>
          <Col span={6}>
            {translate(`${I18N_BASE_PATH}.columns.companyName`, 'Company name')}
          </Col>
          <Col span={3}>
            {translate(`${I18N_BASE_PATH}.columns.companyNumber`, 'Company number')}
          </Col>
          <Col span={9}>{translate(`${I18N_BASE_PATH}.columns.address`, 'Address')}</Col>
          <Col span={3}>{translate(`${I18N_BASE_PATH}.columns.source`, 'Source')}</Col>
          <Col span={2}>{translate(`${I18N_BASE_PATH}.columns.status`, 'Status')}</Col>
        </div>

        {companies?.length <= 0 ? (
          <div className="wrapper-list-companies">
            <NoResults className="mrg-top-10" variant="filter" />
          </div>
        ) : (
          <div className="wrapper-list-companies">{renderCompanies()}</div>
        )}
      </div>
      {companies?.length >= 1 && (
        <div className="pagination">
          <Pagination
            current={currentPage}
            onChange={onChangePage}
            pageSize={pageSize}
            total={totalItems}
            defaultCurrent={1}
            showSizeChanger={false}
            showSorterTooltip={false}
          />
        </div>
      )}
    </div>
  );
};

export default ListCompanies;

ListCompanies.propTypes = {
  companies: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    safeNumber: PropTypes.string.isRequired,
    companyNumber: PropTypes.string.isRequired,
  }).isRequired,
};
