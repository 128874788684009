import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Tooltip } from 'antd';

import './styles.less';

const SpecialTag = ({ description }) => {
  const { t: translate } = useTranslation();

  return (
    <>
      {description && (
        <Tooltip placement="top" title={description}>
          <div className="special-tag">
            {translate('components.tag.specialTag.index.tooltip')}
          </div>
        </Tooltip>
      )}
      {!description && (
        <div className="special-tag">
          {translate('components.tag.specialTag.index.text')}
        </div>
      )}
    </>
  );
};

SpecialTag.propTypes = {
  description: PropTypes.string,
};

SpecialTag.defaultProps = {
  description: null,
};

export default SpecialTag;
