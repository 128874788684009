import { Card, Image } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { toMaskedCpf } from 'utils/formatters';

import './styles.less';

const I18N_BASEPATH =
  'pages.private.liveness.livenessTab.components.list.components.livenessCard';

const LivenessCard = ({ data }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const openEyesProbabilityLessThan50 = useMemo(() => {
    return (
      data?.liveness?.openEyesProbability && data?.liveness?.openEyesProbability < 0.5
    );
  }, [data]);

  const iconClassName = useMemo(() => {
    let icon = data.isAlive ? 'caf-ic_checkmark' : 'caf-ic_close';
    if (openEyesProbabilityLessThan50) {
      icon = 'caf-ic_close';
    }
    return icon;
  }, [data, openEyesProbabilityLessThan50]);

  const hasCpfOrPersonId = useMemo(() => {
    return (
      (data?.person?.cpf && data?.person?.cpf?.length < 15) ||
      (data?.person?.personId && data?.person?.personId?.length < 15)
    );
  }, [data]);

  const imageComponent = () => {
    return (
      <Image
        className="card-image"
        width="200px"
        height="200px"
        src={data.image}
        alt={data._id}
      />
    );
  };

  const videoComponent = () => {
    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      <video
        className="card-image"
        width="200"
        height="200"
        autoPlay
        muted
        onMouseOver={(event) => event.target.play()}
        onMouseOut={(event) => event.target.pause()}
      >
        <source src={data.image} type="video/mp4" />
      </video>
    );
  };

  const mediaComponent = () => {
    const contentUrl = data.image;
    const mp4Extension = '.mp4';
    if (contentUrl.includes(mp4Extension)) {
      return videoComponent();
    }
    return imageComponent();
  };

  return (
    <Card className="liveness-card" type="inner">
      {mediaComponent()}
      <div className="card-info">
        <div className="card-info-status">
          <div className="icon-status-container">
            <i className={iconClassName} />
          </div>
          <div className="card-info-status-content">
            {openEyesProbabilityLessThan50 ? (
              <span className="text-bold">
                {openEyesProbabilityLessThan50 &&
                  translate(`${I18N_BASEPATH}.disapproved`)}
              </span>
            ) : (
              <span className="text-bold">
                {data?.isAlive
                  ? translate(`${I18N_BASEPATH}.approved`)
                  : translate(`${I18N_BASEPATH}.disapproved`)}
              </span>
            )}
            <span className="font-size-12">{`${translate(
              `${I18N_BASEPATH}.validatedAt`
            )} ${i18nFormatDate(data?.createdAt, 4)}`}</span>
          </div>
        </div>
        <div className="card-info-simple">
          <span>{translate(`${I18N_BASEPATH}.ip`)}:</span>
          <span className="pdd-left-5 text-bold">{data?.sourceIp}</span>
        </div>
        <div className="card-info-simple">
          <span>{translate(`${I18N_BASEPATH}.name`)}:</span>
          <span className={`pdd-left-5 ${data?.person?.name ? 'text-bold' : ''}`}>
            {data?.person?.name || translate(`${I18N_BASEPATH}.noName`)}
          </span>
        </div>
        <>
          <div className="card-info-simple">
            <span>{translate(`${I18N_BASEPATH}.cpf`)}:</span>
            <span className={`pdd-left-5 ${hasCpfOrPersonId ? 'text-bold' : ''}`}>
              {hasCpfOrPersonId
                ? toMaskedCpf(data?.person?.cpf) || toMaskedCpf(data?.person?.personId)
                : translate(`${I18N_BASEPATH}.noCpf`)}
            </span>
          </div>
        </>
        {data?.source !== 'iproov' && (
          <div className="card-info-simple">
            <span>{translate(`${I18N_BASEPATH}.probability`)}:</span>
            <span className="pdd-left-5 text-bold">
              {Number(data?.liveness?.probability).toFixed(2)}%
            </span>
          </div>
        )}
        {data?.liveness?.openEyesProbability && data?.source !== 'iproov' && (
          <div className="card-info-simple">
            <span>
              {translate(
                'pages.private.liveness.livenessTab.components.list.components.livenessCard.openEyesProbability'
              )}
            </span>
            <span className="pdd-left-5 text-bold">
              {Number(data?.liveness?.openEyesProbability * 100).toFixed(2)}%
            </span>
          </div>
        )}
      </div>
    </Card>
  );
};

LivenessCard.propTypes = {
  data: PropTypes.node.isRequired,
};

export default LivenessCard;
