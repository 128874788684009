export const getExtensionFile = (fileName) => {
  const extension = fileName.split('.');
  if (extension.length <= 1) {
    return 'N/A';
  }

  return extension.pop();
};

export const getExtension = (doc) => {
  let extension = doc?.fileName?.split('.');

  if (extension?.length > 1) {
    return extension.pop();
  }

  extension = doc?.contentType?.split('/');

  if (extension?.length > 1) {
    return extension.pop();
  }

  return 'N/A';
};
