import React from 'react';
import { Col, Collapse, Row } from 'antd';
import classNames from 'classnames';
import { CompanyBody1 } from 'pages/private/KYBCompany/CompanyDetailsV2/components';
import './style.less';

const FinancialInfoCollapse = ({
  children,
  title,
  rightInfo = '',
  bordered = true,
  showArrow = true,
  activeKey = '',
  collapseOpened = false,
  collapseKeyPrefix = '',
  print = false,
}) => {
  const collapseKey = [collapseKeyPrefix + activeKey];
  const collapseProps = {
    activeKey: collapseKey,
    defaultActiveKey: collapseKey,
  };
  const props = collapseOpened && activeKey ? collapseProps : {};
  const key = collapseKeyPrefix + activeKey;
  return (
    <Collapse
      className={classNames('kyb-company-collapse', 'KYB-financial-info-collapse', {
        'company-print-no-render': !print,
      })}
      expandIconPosition="right"
      bordered={bordered}
      showArrow={showArrow}
      defaultActiveKey={print ? [key] : null}
      {...props}
    >
      <Collapse.Panel
        key={key}
        header={
          <CompanyBody1>
            <b>{title}</b>
          </CompanyBody1>
        }
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  );
};

FinancialInfoCollapse.propTypes = Collapse.propTypes;

FinancialInfoCollapse.defaultProps = Collapse.defaultProps;

export default FinancialInfoCollapse;
