import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Row, Col, Spin, Dropdown, Menu, Tooltip, Card, Button, message } from 'antd';

import { renderImgByExtension, renderColorTagByExtension } from 'utils/renderByExtension';
import { download } from 'utils/downloadFile';

import { useI18nFormatters } from 'hooks/i18nFormatters';
import { useUploadManager } from 'hooks/uploadManager';
import { useFetch } from 'services/hooks';

import useModalUpload from 'components/ModalUpload';
import useModalConfirmAction from 'components/ModalConfirmAction';

import { getExtension } from 'utils/getExtensionFile';
import copyTextToClipboard from 'utils/copyTextToClipboard';
import EmptyFiles from './components/EmptyFiles';

import './styles.less';

const I18N_BASE_PATH = 'components.files.transactionRecordsFiles.index';

const TransactionRecordsFiles = () => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();
  const { executionId, transactionId } = useParams();

  const { get: getRecords, delete: deleteRecords, loading: loadingDelete } = useFetch();
  const { success, error, items: uploadedItems, closeUploadWidget } = useUploadManager();

  const allowedMimeTypes = [
    'text/*',
    'image/*',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];

  const [loading, setLoading] = useState(false);
  const [archivesList, setArchivesList] = useState([]);
  const [records, setRecords] = useState([]);

  const id = useMemo(() => executionId || transactionId, [executionId, transactionId]);
  const modalUploadSubtitle = useMemo(() => (executionId ? 'person' : 'company'), [
    executionId,
  ]);
  const limitFileQuantity = useMemo(() => 20 - (records?.length || 0), [records]);
  const limitFileSize = 20000000;

  const { openModal: openCardUploadModal, CardUploadModal } = useModalUpload(
    allowedMimeTypes,
    limitFileSize,
    limitFileQuantity,
    modalUploadSubtitle
  );

  const loadTransactionRecords = useCallback(async () => {
    setLoading(true);

    const response = await getRecords({
      url: `/executions/${id}/transaction-records?type=FILE`,
    });

    setRecords(response.records || []);
    setLoading(false);
  }, [getRecords, id]);

  const handleDeleteRecord = useCallback(
    async (recordId) => {
      try {
        await deleteRecords({
          url: `/executions/${id}/transaction-records?recordId=${recordId}`,
        });

        setArchivesList(archivesList.filter((archive) => archive._id !== recordId));
        message.success(translate(`${I18N_BASE_PATH}.handleDeleteMessage.success`));
        loadTransactionRecords();
      } catch (error) {
        if (error?.response?.status === 404) {
          message.error(translate(`${I18N_BASE_PATH}.handleDeleteMessage.notfound`));
        } else {
          message.error(translate(`${I18N_BASE_PATH}.handleDeleteMessage.error`));
        }
      }
    },
    [translate, deleteRecords, archivesList, id, loadTransactionRecords]
  );

  const { openModal: openConfirmModal, ConfirmActionModal } = useModalConfirmAction({
    action: handleDeleteRecord,
    loading: loadingDelete,
    danger: true,
    okButton: translate(`${I18N_BASE_PATH}.confirmDeleteModal.okButton`),
    cancelButton: translate(`${I18N_BASE_PATH}.confirmDeleteModal.cancelButton`),
    title: translate(`${I18N_BASE_PATH}.confirmDeleteModal.title`),
    subtitle: translate(`${I18N_BASE_PATH}.confirmDeleteModal.subtitle`),
  });

  useEffect(() => {
    setArchivesList(
      records.map((record) => ({
        _id: record._id,
        fileName: record.file.fileName,
        link: record.file.signedUrl || '',
        mimeType: record.file.mimeType,
        createdAt: record.createdAt,
      }))
    );
  }, [records]);

  useEffect(() => {
    loadTransactionRecords();
  }, [loadTransactionRecords]);

  useEffect(() => {
    const uploadedItemsWithSuccess = uploadedItems.filter(
      (item) => item.uploaded || item.error
    );
    if (
      (success > 0 || error > 0) &&
      uploadedItemsWithSuccess.length === uploadedItems.length
    ) {
      closeUploadWidget();

      if (success > 0) loadTransactionRecords();
    }
  }, [success, error, uploadedItems, closeUploadWidget, loadTransactionRecords]);

  const copyLinkButton = useCallback(
    (doc) => (
      <Menu className="dropdown-generate-link">
        <Menu.Item
          key="0"
          id="transaction-records-files-menu-item-primary"
          onClick={() =>
            copyTextToClipboard(
              doc.link,
              translate(`${I18N_BASE_PATH}.copyLinkButton.copyTextToClipboard.success`),
              translate(`${I18N_BASE_PATH}.copyLinkButton.copyTextToClipboard.error`)
            )
          }
        >
          <i className="caf-ic_link icon-copy-link" />
          <span>{translate(`${I18N_BASE_PATH}.copyLinkButton.button`)}</span>
        </Menu.Item>
        <Menu.Item
          key="1"
          onClick={() => openConfirmModal(doc._id)}
          id="transaction-records-files-menu-item-danger"
        >
          <i className="caf-ic_trash mrg-right-10" />
          <span className="text-semibold">
            {translate(
              'pages.private.accessKeys.components.accessKeysCard.components.actionsList.delete'
            )}
          </span>
        </Menu.Item>
      </Menu>
    ),
    [translate, openConfirmModal]
  );

  return (
    <Card className="mrg-top-20" id="transaction-records-files">
      {CardUploadModal}

      {!loading && archivesList.length > 0 && (
        <div className="card-documents-header">
          <div className="documents-header-container">
            <div className="flex gx-row">
              <Button
                type="primary"
                onClick={openCardUploadModal}
                className="mrg-left-10"
              >
                <i className="caf-ic_attach mrg-right-5" />
                {translate(`${I18N_BASE_PATH}.button`)}
              </Button>
            </div>
          </div>
        </div>
      )}

      {loading && (
        <div className="loading-container">
          <Spin />
        </div>
      )}

      {!loading && archivesList.length < 1 && (
        <div className="flex-row center gx-w-100">
          <EmptyFiles
            allowedMimeTypes={allowedMimeTypes}
            limitFileSize={limitFileSize}
            limitFileQuantity={limitFileQuantity}
            modalUploadSubtitle={modalUploadSubtitle}
          />
        </div>
      )}

      {!loading && archivesList.length > 0 && (
        <>
          {ConfirmActionModal}
          <Row id="card-documents-data" justify="start" gutter={24}>
            {archivesList.map((doc) => {
              const extension = getExtension(doc);

              const name = doc.fileName?.split(`.${extension}`)?.[0] || 'N/A';

              return (
                <Col key={doc._id} xs={12} sm={8} className="pdd-btm-10 pdd-top-10">
                  <div className="card-document">
                    <button
                      type="button"
                      className="document-image-container"
                      onClick={() => {
                        if (doc.link) download(doc.link, doc.fileName, true);
                      }}
                    >
                      <div
                        className="document-extension-tag"
                        style={{
                          backgroundColor: renderColorTagByExtension(
                            doc.mimeType || doc.contentType
                          ),
                        }}
                      >
                        {extension === 'N/A' ? extension : `.${extension}`}
                      </div>
                      <div
                        className="document-image"
                        style={{
                          backgroundImage: `url(${renderImgByExtension(
                            doc.mimeType || doc.contentType
                          )})`,
                        }}
                      />
                    </button>

                    <div className="document-infos-container">
                      <div className="document-infos-text">
                        <Tooltip placement="bottom" title={doc.fileName}>
                          <span className="max-lines-1 file-name">{name}</span>
                        </Tooltip>
                        <span>{i18nFormatDate(doc.createdAt, 'default')}</span>
                      </div>
                      <Dropdown
                        overlay={copyLinkButton(doc)}
                        placement="bottomRight"
                        trigger="click"
                      >
                        <div className="btn-more-icon">
                          <i className="caf-ic_more-vertical" />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </>
      )}
    </Card>
  );
};

export default TransactionRecordsFiles;
