/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Row, Col } from 'antd';

import EmptyExecutionState from 'components/EmptyExecutionState';

import { SoccerBall } from '@combateafraude/icons/sports';
import DottedLabel from 'components/DottedLabel';

// import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';
import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

import './styles.less';
import SportsAndRelationCard from './components/SportsAndRelationCard';

const RelatedSports = ({ source = {}, toPrint = false /* urlId */ }) => {
  const { t: translate } = useTranslation();

  const QuerySucceeded = useMemo(
    () =>
      source?.statusCode === '01' ||
      source?.available === true ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [source]
  );

  return (
    <>
      <DataSourceCardCollapsePanel
        icon={null}
        customIcon={<SoccerBall height={35} width={35} style={{ margin: '0 10px' }} />}
        title={translate(
          `components.dataSourceCards.relationships.components.relatedSports.index.title`
        )}
        customId="restrictiveLists"
        toPrint={toPrint}
      >
        <Row
          id="internal-restrictive-lists"
          className="contact-data-container mrg-left-10"
        >
          {!QuerySucceeded && (
            <EmptyExecutionState
              statusCode={source?.data?.statusCode || source?.statusCode}
              message={source?.data?.message || source?.message}
            />
          )}
          {QuerySucceeded && !source?.data?.sportsRelationshipType?.length && (
            <Col span={24} className="mrg-top-25 mrg-btm-25">
              <DottedLabel
                className="mrg-btm-10"
                type="primary"
                description={translate(
                  `components.dataSourceCards.relationships.components.relatedSports.index.dottedLabelFalse`
                )}
              />
              {translate(
                `components.dataSourceCards.relationships.components.relatedSports.index.subtitleFalse`
              )}
            </Col>
          )}
          {QuerySucceeded && source?.data?.sportsRelationshipType?.length && (
            <>
              <Col span={24} className="mrg-top-25 mrg-btm-25">
                <DottedLabel
                  className="mrg-btm-10"
                  type="danger"
                  description={
                    source.data.relationship === 'oneself'
                      ? translate(
                          `components.dataSourceCards.relationships.components.relatedSports.index.dottedLabelSport`
                        )
                      : translate(
                          `components.dataSourceCards.relationships.components.relatedSports.index.dottedLabelRelation`
                        )
                  }
                />
                {source.data.relationship === 'oneself'
                  ? translate(
                      `components.dataSourceCards.relationships.components.relatedSports.index.subtitleSport`
                    )
                  : translate(
                      `components.dataSourceCards.relationships.components.relatedSports.index.subtitleRelation`
                    )}
              </Col>
              <Divider />
              <SportsAndRelationCard
                span={24}
                relationship={source?.data?.relationship}
                danger
                source={source?.data}
                className="mrg-top-10 pdd-horizontal-0"
              />
            </>
          )}
        </Row>
      </DataSourceCardCollapsePanel>
    </>
  );
};

export default RelatedSports;
