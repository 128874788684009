import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import FinancialInfoCollapse from '../../components/Collapse';
import FinancialInfoTable from '../../components/Table';

import './style.less';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companyDetails.subPages.financialInfo.tabs.negativeInfo';

const NegativeInfo = ({
  className,
  financialData,
  bordered,
  showArrow,
  collapseOpened,
  collapseKeyPrefix,
  print = false,
}) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDateOnly, i18nFormatCurrency } = useI18nFormatters();

  const ccjsCount = useMemo(
    () =>
      financialData?.negativeInformation?.summary?.numberOfExact +
        financialData?.negativeInformation?.summary?.numberOfPossible +
        financialData?.negativeInformation?.summary?.numberOfSatisfied || 0,
    [financialData]
  );

  const writsCount = useMemo(
    () => financialData?.negativeInformation?.summary?.numberOfWrits || 0,
    [financialData]
  );

  const CCJsColumns = useMemo(() => {
    return [
      {
        title: translate(`${I18N_BASE_PATH}.collapses.ccjs.columns.date`),
        dataIndex: 'date',
      },
      {
        title: translate(`${I18N_BASE_PATH}.collapses.ccjs.columns.court`),
        dataIndex: 'court',
      },
      {
        title: translate(`${I18N_BASE_PATH}.collapses.ccjs.columns.amount`),
        dataIndex: 'amount',
      },
      {
        title: translate(`${I18N_BASE_PATH}.collapses.ccjs.columns.status`),
        dataIndex: 'status',
      },
      {
        title: translate(`${I18N_BASE_PATH}.collapses.ccjs.columns.caseNumber`),
        dataIndex: 'caseNumber',
      },
      {
        title: translate(`${I18N_BASE_PATH}.collapses.ccjs.columns.paidDate`),
        dataIndex: 'paidDate',
      },
    ];
  }, [translate]);

  const writsColumns = useMemo(() => {
    return [
      {
        title: translate(`${I18N_BASE_PATH}.collapses.writs.columns.date`),
        dataIndex: 'date',
      },
      {
        title: translate(`${I18N_BASE_PATH}.collapses.writs.columns.court`),
        dataIndex: 'court',
      },
      {
        title: translate(`${I18N_BASE_PATH}.collapses.writs.columns.plaintiff`),
        dataIndex: 'plaintiff',
      },
    ];
  }, [translate]);

  const CCJsRows = useMemo(() => {
    const rows = [];

    financialData?.negativeInformation?.countyCourtJudgements?.registered?.exact?.forEach(
      (judgement) => {
        const row = {
          date: i18nFormatDateOnly(judgement.date, 1),
          court: judgement.court,
          amount: i18nFormatCurrency(judgement.amount, null, judgement.currency),
          status: judgement.status,
          caseNumber: judgement.caseNumber,
          paidDate: i18nFormatDateOnly(judgement.paidDate, 1) || '-',
        };

        rows.push(row);
      }
    );

    return rows;
  }, [financialData, i18nFormatDateOnly, i18nFormatCurrency]);

  const writsRows = useMemo(() => {
    const rows = [];

    financialData?.negativeInformation?.highCourtWrits?.forEach((writ) => {
      const row = {
        date: i18nFormatDateOnly(writ.date, 1),
        court: writ.court,
        plaintiff: writ.plaintiff,
      };

      rows.push(row);
    });

    return rows;
  }, [financialData, i18nFormatDateOnly]);

  return (
    <div className={className}>
      <FinancialInfoCollapse
        title={`${translate(`${I18N_BASE_PATH}.collapses.ccjs.title`)} (${ccjsCount})`}
        activeKey="ccjs"
        collapseOpened={collapseOpened}
        collapseKeyPrefix={collapseKeyPrefix}
        showArrow={showArrow}
        bordered={bordered}
        print={print && ccjsCount}
      >
        <FinancialInfoTable columns={CCJsColumns} rows={CCJsRows} />
      </FinancialInfoCollapse>
      <FinancialInfoCollapse
        title={`${translate(`${I18N_BASE_PATH}.collapses.writs.title`)} (${writsCount})`}
        activeKey="writs"
        collapseOpened={collapseOpened}
        collapseKeyPrefix={collapseKeyPrefix}
        showArrow={showArrow}
        bordered={bordered}
        print={print && writsCount}
      >
        <FinancialInfoTable columns={writsColumns} rows={writsRows} />
      </FinancialInfoCollapse>
    </div>
  );
};

NegativeInfo.propTypes = {
  className: PropTypes.string,
  financialData: PropTypes.object,
  bordered: PropTypes.bool,
  showArrow: PropTypes.bool,
  collapseOpened: PropTypes.bool,
  collapseKeyPrefix: PropTypes.string,
};

NegativeInfo.defaultProps = {
  className: '',
  financialData: {},
  bordered: true,
  showArrow: false,
  collapseOpened: false,
  collapseKeyPrefix: '',
};

export default NegativeInfo;
