/* eslint-disable import/no-cycle */
import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';

import PageTitle from 'components/PageTitle';

import ProcessesList from '../../Lists/ProcessesList';

import './styles.less';

const AllProcesses = ({ data, updateData, hasPersonProcesses }) => {
  const { t: translate } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const processesQuantity = useMemo(() => {
    return data?.length;
  }, [data]);

  const AllProcessesModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        onCancel={closeModal}
        footer={null}
        style={{ top: 40 }}
        width={800}
      >
        <div
          id="executions-all-processes-modal"
          className="custom-dossier-modal no-mrg no-pdd"
        >
          <PageTitle
            title={translate(
              'components.dataSourceCards.legalInformation.componentes.processes.allProcessesModal.index.allProcessesModal.pageTitle.title'
            )}
            subtitle={translate(
              'components.dataSourceCards.legalInformation.componentes.processes.allProcessesModal.index.allProcessesModal.pageTitle.subtitle',
              { processesQuantity }
            )}
          />
          <ProcessesList
            data={data}
            updateData={updateData}
            hasPersonProcesses={hasPersonProcesses}
          />
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      data,
      processesQuantity,
      updateData,
      hasPersonProcesses,
      translate,
    ]
  );

  return { openModal, closeModal, AllProcessesModal };
};

export default AllProcesses;
