import { message } from 'antd';

export default function copyTextToClipboard(textToCopy, successMessage, failMessage) {
  navigator.clipboard.writeText(textToCopy).then(
    () => {
      message.success(successMessage);
    },
    () => {
      message.warning(failMessage);
    }
  );
}
