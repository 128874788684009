import React, { useState, useCallback, useMemo } from 'react';
import { Modal, Row, Col, Collapse, Divider } from 'antd';

import { useTranslation } from 'react-i18next';
import NotAvailable from 'components/NotAvailable';

const { Panel } = Collapse;
const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companyDetailsv2.components.modalMoreInfos';

const ModalMoreInfos = () => {
  const { t: translate } = useTranslation();
  const { t } = useTranslation('kyb-identifiers');
  const [modalVisible, setModalVisible] = useState(false);
  const [data, setData] = useState(null);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(({ array, type }) => {
    setData({ array, type });
    setModalVisible(true);
  }, []);
  const renderList = useCallback(
    (a) => {
      if (data?.type === 'identifiers') {
        return (
          <>
            <Row>
              <Col span={8}>
                <b>{translate(`${I18N_BASE_PATH}.type`)}</b>
              </Col>
              <Col span={6}>
                <b>{translate(`${I18N_BASE_PATH}.value`)}</b>
              </Col>
              <Col span={10}>
                <b>{translate(`${I18N_BASE_PATH}.description`)}</b>
              </Col>
            </Row>
            <Row>
              <Col span={8}>{t(a?.type)}</Col>
              <Col span={6}>{a?.value}</Col>
              <Col span={10}>
                {t(`${a?.type}.description`) !== '-' ? (
                  t(`${a?.type}.description`)
                ) : (
                  <NotAvailable />
                )}
              </Col>
            </Row>
          </>
        );
      }
      if (data?.type === 'phones') {
        return (
          <>
            <Row>
              <Col span={24}>
                <b>{translate(`${I18N_BASE_PATH}.phone`)}</b>
              </Col>
            </Row>
            <Row>
              <Col span={24}>{a}</Col>
            </Row>
          </>
        );
      }
      if (data?.type === 'emails') {
        return (
          <>
            <Row>
              <Col span={24}>
                <b>{translate(`${I18N_BASE_PATH}.email`)}</b>
              </Col>
            </Row>
            <Row>
              <Col span={24}>{a}</Col>
            </Row>
          </>
        );
      }
      if (data?.type === 'addresses') {
        return (
          <>
            <Row>
              <Col span={24}>
                <b>{translate(`${I18N_BASE_PATH}.address`)}</b>
              </Col>
            </Row>
            <Row>
              <Col span={24}>{a}</Col>
            </Row>
          </>
        );
      }
      if (data?.type === 'businessPurpose') {
        return (
          <>
            <Row>
              <Col span={6}>
                <b>{translate(`${I18N_BASE_PATH}.classification`)}</b>
              </Col>
              <Col span={4}>
                <b>{translate(`${I18N_BASE_PATH}.code`)}</b>
              </Col>
              <Col span={14}>
                <b>{translate(`${I18N_BASE_PATH}.description`)}</b>
              </Col>
            </Row>
            <Row>
              <Col span={6}>{a?.classification}</Col>
              <Col span={4}>{a?.code}</Col>
              <Col span={14}>{a?.description}</Col>
            </Row>
          </>
        );
      }
      if (data?.type === 'urls') {
        return (
          <>
            <Row>
              <Col span={24}>
                <b>{translate(`${I18N_BASE_PATH}.url`)}</b>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <a href={a} target="_blank" rel="noreferrer">
                  {a}
                </a>
              </Col>
            </Row>
          </>
        );
      }
    },
    [data, t, translate]
  );
  const MoreInfosModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeModal}
        footer={null}
        width={675}
      >
        <div>
          <Collapse size="small" defaultActiveKey={['1']}>
            <Panel
              header={
                <b>
                  {translate(`${I18N_BASE_PATH}.${data?.type}`)} ({data?.array?.length})
                </b>
              }
              key="1"
              showArrow={false}
            >
              <div>
                {data?.array?.map((a, index) => {
                  return (
                    <div style={{ width: '100%' }}>
                      {renderList(a)}
                      {index < data?.array?.length - 1 && <Divider />}
                    </div>
                  );
                })}
              </div>
            </Panel>
          </Collapse>
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, translate, data, renderList]
  );

  return { openModal, closeModal, MoreInfosModal };
};

export default ModalMoreInfos;
