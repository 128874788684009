import React, { useState, useMemo, useCallback } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';

import allSources from './utils/sources.json';

import './styles.less';

const { Option } = Select;

const SelectSourceDetails = ({ sources }) => {
  const [componentToShowDetails, setComponentToShowDetails] = useState(sources[0]);

  const handleChangeComponentToShow = useCallback((componentName) => {
    setComponentToShowDetails(componentName);
  }, []);

  const SelectSource = useMemo(
    () => (
      <div id="select-source-wrapper">
        <Select
          dropdownClassName="select-source"
          defaultValue={componentToShowDetails}
          onSelect={(componentName) => handleChangeComponentToShow(componentName)}
          bordered={false}
        >
          {sources?.map((source) => (
            <Option value={source}>{allSources[source]}</Option>
          ))}
        </Select>
      </div>
    ),
    [handleChangeComponentToShow, componentToShowDetails, sources]
  );

  return { SelectSource, componentToShowDetails };
};

SelectSourceDetails.propTypes = {
  sources: PropTypes.arrayOf({}),
};

SelectSourceDetails.defaultProps = {
  sources: undefined,
};

export default SelectSourceDetails;
