/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Typography } from 'antd';

import './styles.less';

const { Link } = Typography;

const MediaExposureCard = ({ data }) => {
  const { t: translate } = useTranslation();

  const url = data?.url;

  return (
    <Col id="executions-media-exposure-card" span={24}>
      <Link href={url} target="_blank" className="media-exposure-link">
        {data?.title}
      </Link>
      <span>
        {data?.sourceName ||
          translate(
            'components.dataSourceCards.mediaExposure.mediaExposureCard.index.mediaExposureCard.description'
          )}
      </span>
    </Col>
  );
};

export default MediaExposureCard;
