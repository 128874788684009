/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import { nadaConstaTRFTranslation } from 'utils/personalTranslations';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';
import EmptyExecutionState from 'components/EmptyExecutionState';
import ViewMoreText from 'components/ViewMoreText';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH = 'components.dataSourceCards.criminalInformation.index';

const PfBackgroundCheckTrf = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const pfNadaConstaTRF = useMemo(() => {
    const nadaConstaStatus = parsedData?.status;

    const translatedStatus = nadaConstaStatus
      ? nadaConstaTRFTranslation[nadaConstaStatus] || nadaConstaTRFTranslation.NULO
      : nadaConstaTRFTranslation.NULO;

    return { ...source, translatedStatus };
  }, [parsedData, source]);

  return (
    <DataSourceCardCollapsePanel
      customId="criminal-background-component"
      title={translate(
        `${I18N_BASE_PATH}.criminalInformation.dossierCard.pfBackgroundCheckTrf.title`
      )}
      icon="caf-ic_criminal"
      toPrint={toPrint}
    >
      <Row>
        {parsedData && (
          <ColumnLabel
            title={translate(
              `${I18N_BASE_PATH}.criminalInformation.dossierCard.pfBackgroundCheckTrf.title`
            )}
            description={translate(pfNadaConstaTRF?.translatedStatus?.description)}
            type={pfNadaConstaTRF?.translatedStatus?.type}
            span={12}
          >
            {queryWorked ? (
              <ViewMoreText
                message={pfNadaConstaTRF?.data?.message}
                content={pfNadaConstaTRF?.data?.certificateText}
              />
            ) : (
              <EmptyExecutionState
                className="mrg-top-10"
                statusCode={statusCode}
                message={source?.message || parsedData?.message}
              />
            )}
          </ColumnLabel>
        )}
      </Row>
    </DataSourceCardCollapsePanel>
  );
};

export default PfBackgroundCheckTrf;
