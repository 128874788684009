import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam } from 'use-query-params';
import { Form, Spin } from 'antd';

import Wrapper from 'pages/private/wrapper';
import { CardWrapper } from 'components/CardWrapper';
import { useProfileTemplate } from 'hooks/profileTemplate';

import StarterFlow from './StarterFlow';
import OnboardingServicesStep from './OnboardingServicesStep';
import BackgroundCheckingStep from './BackgroundCheckingStep';
import ComplianceRulesStep from './ComplianceRulesStep';
import Confirmation from './Confirmation';
import Success from './Success';

import './styles.less';

const ProfileModelCreate = () => {
  const { t: translate } = useTranslation();

  const [form] = Form.useForm();
  const [query, setQuery] = useQueryParams({
    step: StringParam,
    id: StringParam,
  });

  const {
    loadCafServicesList,
    setProfileTemplate,
    profileTemplate,
    getProfileTemplateById,
    loadingGetProfileTemplate,
    loadingCafServicesList,
    cafServicesList,
    loadProfileTemplates,
    allProfileTemplates,
    loadingAllProfileTemplates,
  } = useProfileTemplate();

  const onFormValuesChange = useCallback(() => {
    // console.log('onFormValuesChange', values);
    setProfileTemplate({ ...form.getFieldsValue() });
  }, [form, setProfileTemplate]);

  useEffect(() => {
    loadProfileTemplates({
      active: true,
      type: 'PF',
    });
  }, [loadProfileTemplates]);

  useEffect(() => {
    // verifica se existem serviços na lista do usuário, caso não houver, redireciona para proxima etapa
    let servicesList = [];
    if (cafServicesList !== null) {
      servicesList =
        cafServicesList?.docs?.filter((item) => item.productType === 'service') || [];
    }
    if (!profileTemplate) {
      if (query.id) {
        getProfileTemplateById(query.id, true).then((res) => {
          setQuery({
            step:
              res.type === 'PJ' || servicesList.length === 0
                ? 'background_checking'
                : 'onboarding_services',
            action: undefined,
          });
        });
      } else {
        setQuery({ step: 'start', action: undefined });
      }
    }
  }, [
    cafServicesList,
    getProfileTemplateById,
    profileTemplate,
    query,
    setQuery,
    translate,
  ]);

  useEffect(() => {
    loadCafServicesList();
  }, [loadCafServicesList]);

  return (
    <Wrapper id="profile-model-create-component">
      <CardWrapper hasPadding>
        <div className="profile-model-create-wrapper">
          {loadingGetProfileTemplate || loadingCafServicesList ? (
            <Spin className="mrg-top-30" />
          ) : (
            <Form
              form={form}
              layout="vertical"
              // className={`${disabled ? 'disabled' : ''}`}
              onValuesChange={onFormValuesChange}
              className="flex-column center"
              initialValues={{
                backgroundCheckingServices: {},
                onboardingServices: {},
                // backgroundCheckingValidations: [],
                // onboardingValidations: [],
                validations: [],
                profileRules: [],
              }}
            >
              <div className="width-100">
                {(!query.step || query.step === 'start') && (
                  <StarterFlow formRef={form} />
                )}

                {query.step === 'onboarding_services' && (
                  <OnboardingServicesStep
                    formRef={form}
                    onValuesChange={onFormValuesChange}
                  />
                )}

                {query.step === 'background_checking' && (
                  <BackgroundCheckingStep
                    formRef={form}
                    onValuesChange={onFormValuesChange}
                  />
                )}

                {query.step === 'compliance_rules' && (
                  <ComplianceRulesStep
                    formRef={form}
                    onValuesChange={onFormValuesChange}
                    allProfileTemplates={allProfileTemplates}
                    loadingAllProfileTemplates={loadingAllProfileTemplates}
                  />
                )}

                {query.step === 'confirmation' && <Confirmation formRef={form} />}
              </div>
            </Form>
          )}

          {query.step === 'success' && <Success formRef={form} />}
        </div>
      </CardWrapper>
    </Wrapper>
  );
};

export default ProfileModelCreate;
