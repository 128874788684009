const I18N_BASE_PATH = 'components.dataSourceCards.mediaExposure.companies.mappings';

export const sentimentLabelMappings = {
  UNDEFINED: `${I18N_BASE_PATH}.sentimentLabelMappings.UNDEFINED`,
  NEUTRAL: `${I18N_BASE_PATH}.sentimentLabelMappings.NEUTRAL`,
  POSITIVE: `${I18N_BASE_PATH}.sentimentLabelMappings.POSITIVE`,
  NEGATIVE: `${I18N_BASE_PATH}.sentimentLabelMappings.NEGATIVE`,
  POLARIZED: `${I18N_BASE_PATH}.sentimentLabelMappings.POLARIZED`,
  SLIGHTLY_POSITIVE: `${I18N_BASE_PATH}.sentimentLabelMappings.SLIGHTLY_POSITIVE`,
  SLIGHTLY_NEGATIVE: `${I18N_BASE_PATH}.sentimentLabelMappings.SLIGHTLY_NEGATIVE`,
};

export const popularityLevelMappings = {
  A: {
    description: `${I18N_BASE_PATH}.popularityLevelMappings.A`,
    type: 'primary',
  },
  B: {
    description: `${I18N_BASE_PATH}.popularityLevelMappings.B`,
    type: 'primary',
  },
  C: {
    description: `${I18N_BASE_PATH}.popularityLevelMappings.C`,
    type: 'info',
  },
  D: {
    description: `${I18N_BASE_PATH}.popularityLevelMappings.D`,
    type: 'info',
  },
  E: {
    description: `${I18N_BASE_PATH}.popularityLevelMappings.E`,
    type: 'warning',
  },
  F: {
    description: `${I18N_BASE_PATH}.popularityLevelMappings.F`,
    type: 'warning',
  },
  G: {
    description: `${I18N_BASE_PATH}.popularityLevelMappings.G`,
    type: 'danger',
  },
  H: {
    description: `${I18N_BASE_PATH}.popularityLevelMappings.H`,
    type: 'default',
  },
};

export const unpopularityLevelMappings = {
  A: {
    description: `${I18N_BASE_PATH}.popularityLevelMappings.A`,
    type: 'danger',
  },
  B: {
    description: `${I18N_BASE_PATH}.popularityLevelMappings.B`,
    type: 'danger',
  },
  C: {
    description: `${I18N_BASE_PATH}.popularityLevelMappings.C`,
    type: 'warning',
  },
  D: {
    description: `${I18N_BASE_PATH}.popularityLevelMappings.D`,
    type: 'warning',
  },
  E: {
    description: `${I18N_BASE_PATH}.popularityLevelMappings.E`,
    type: 'info',
  },
  F: {
    description: `${I18N_BASE_PATH}.popularityLevelMappings.F`,
    type: 'info',
  },
  G: {
    description: `${I18N_BASE_PATH}.popularityLevelMappings.G`,
    type: 'primary',
  },
  H: {
    description: `${I18N_BASE_PATH}.popularityLevelMappings.H`,
    type: 'default',
  },
};
