import React, { useEffect, useRef, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Form, Menu, notification, message, Dropdown } from 'antd';
import { useQueryParams, StringParam } from 'use-query-params';
import OutsideClickHandler from 'react-outside-click-handler';
import _ from 'lodash';

import SearchInput from 'components/Form/SearchInput';
import PageTitle from 'components/PageTitle';
import { useTable } from 'components/List';
import { CardWrapper } from 'components/CardWrapper';
import Wrapper from 'pages/private/wrapper';
import { toFormatDate, toMaskedCnpj, toMaskedCpf } from 'utils/formatters';
import { useFetch } from 'services/hooks';

import './styles.less';
import Button from 'components/Button';
import PermissionWrapper from 'routes/PermissionWrapper';
import useDocumentCreateModal from './components/DocumentCreateModal';
import DropdownOptions from './components/DropdownOptions';
import BlockedDocumentSwitch from './components/BlockedDocumentSwitch';

const I18N_BASE_PATH = 'pages.private.blockedDocuments.blockedDocumentsList.index';

const BlockedDocuments = () => {
  const { t: translate, i18n } = useTranslation();
  const [exportStatus, setExportStatus] = useState({
    loading: false,
    error: false,
  });
  const { post } = useFetch();
  const [isDropdownExportOpened, setIsDropdownExportOpened] = useState(false);
  const refreshListRef = useRef(() => {});
  const {
    openModal: openCreateDocumentModal,
    BlockedDocumentsCreateModal,
  } = useDocumentCreateModal({
    refreshList: refreshListRef,
  });

  const [query] = useQueryParams({
    _order: StringParam,
    _sort: StringParam,
    _search: StringParam,
    _status: StringParam,
  });

  const columns = useMemo(
    () => [
      {
        key: 'type',
        title: translate(`${I18N_BASE_PATH}.table.columns.type`),
        dataIndex: ['type'],
      },
      {
        key: 'documentNumber',
        title: translate(`${I18N_BASE_PATH}.table.columns.documentNumber`),
        render: (__, register) => {
          if (!register?.documentNumber) return '-';

          const isCnpj =
            register?.type === 'CNPJ' || register?.documentNumber?.length > 11;

          if (isCnpj) {
            return <p>{toMaskedCnpj(register?.documentNumber)}</p>;
          }
          return <p>{toMaskedCpf(register?.documentNumber)}</p>;
        },
      },

      {
        key: 'createdAt',
        title: translate(`${I18N_BASE_PATH}.table.columns.createdAt`),
        render: (__, register) =>
          toFormatDate(register?.createdAt, i18n?.language, translate),
      },
      {
        key: 'isBlocked',
        title: translate(`${I18N_BASE_PATH}.table.columns.isBlocked`),

        render: (__, register) => (
          <BlockedDocumentSwitch register={register} refreshListRef={refreshListRef} />
        ),
      },
      {
        key: 'options',
        title: '',
        render: (__, register) => (
          <DropdownOptions
            register={register}
            openCreateDocumentModal={openCreateDocumentModal}
          />
        ),
      },
    ],
    [i18n, openCreateDocumentModal, translate]
  );

  const { tableContent, refreshList, setFilterData, data } = useTable({
    getParams: {
      url: '/blocked-documents',
      config: {
        params: {},
      },
    },
    queryParams: {
      _search: StringParam,
      _status: StringParam,
    },
    defaultFilter: {
      _order: query?._order || '1',
      _sort: query?._sort || 'name',
    },
    columns,
  });

  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        ...values,
        _search: values._search ? values._search.trim() : undefined,
      });
    }, 500),
    []
  );

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  const mapFilters = useCallback(() => {
    const filters = {};

    if (query?._search?.trim()?.length > 0) {
      filters.documentNumber = query._search.replace(/\D/g, '');
    }

    return filters;
  }, [query]);

  const onExportList = useCallback(async () => {
    const requestName = 'blocked_documents';

    try {
      setExportStatus({ loading: true, error: false });

      const filters = { ...mapFilters() };
      await post({
        url: `/export-request`,
        payload: {
          filters,
          requestName,
        },
      });

      const notificationArgs = {
        type: 'success',
        message: translate(
          `${I18N_BASE_PATH}.form.onExportList.successNotification.message`
        ),
        description: translate(
          `${I18N_BASE_PATH}.form.onExportList.successNotification.description`
        ),
        duration: 4.5,
        className: 'custom-notification',
      };

      notification.open(notificationArgs);
      setExportStatus({ loading: false, error: false });
    } catch (error) {
      message.error(translate(`${I18N_BASE_PATH}.form.onExportList.error.message`));
      setExportStatus({ loading: false, error: true });
    }
  }, [post, mapFilters, translate]);

  const menuExport = useMemo(
    () => (
      <>
        <Menu className="menu-export-list">
          <Menu.Item key="csv_profiles">
            <button
              type="button"
              disabled={!data?.docs?.length > 0}
              className="menu-export-list-btn reset-btn"
              onClick={onExportList}
            >
              <i className="caf-ic_c_download" />
              <span>
                {translate(`${I18N_BASE_PATH}.form.dropdownButton.exportToCSV`)}
              </span>
            </button>
          </Menu.Item>
        </Menu>
      </>
    ),
    [data, onExportList, translate]
  );

  return (
    <>
      {BlockedDocumentsCreateModal}
      <Wrapper>
        <CardWrapper hasPadding>
          <Row id="blocked-documents-list-container">
            <Col span={24}>
              <Row align="middle" justify="center" className="no-mrg mrg-btm-15">
                <Col flex={1}>
                  <PageTitle
                    subtitle={translate(`${I18N_BASE_PATH}.pageTitle.subtitle`)}
                  />
                </Col>

                <Col>
                  <Form
                    layout="inline"
                    onValuesChange={handleFormChange}
                    initialValues={{ _search: query?._search }}
                  >
                    <SearchInput
                      placeholder={translate(`${I18N_BASE_PATH}.form.search.placeholder`)}
                      style={{ width: 320 }}
                    />
                  </Form>
                </Col>

                <PermissionWrapper requiredPermissions={['blocked-documents:create']}>
                  <Button
                    shape="round"
                    type="primary"
                    onClick={() => openCreateDocumentModal({ isNew: true })}
                    className="flex center"
                  >
                    {translate(
                      'pages.private.restrictions.restrictionList.index.wrapperButton'
                    )}
                    <i className="caf-ic_add font-size-18 mrg-left-5" />{' '}
                  </Button>
                </PermissionWrapper>

                <OutsideClickHandler
                  onOutsideClick={() => setIsDropdownExportOpened(false)}
                  disabled={!isDropdownExportOpened}
                >
                  <Dropdown
                    overlay={menuExport}
                    trigger={['click']}
                    visible={isDropdownExportOpened}
                    placement="bottomRight"
                  >
                    <Button
                      type="default"
                      shape="circle"
                      loading={exportStatus.loading}
                      className={`export-list-btn ${exportStatus.error && 'danger'}`}
                      onClick={() => setIsDropdownExportOpened((oldState) => !oldState)}
                    >
                      {!exportStatus.loading && <i className="caf-ic_c_download" />}
                    </Button>
                  </Dropdown>
                </OutsideClickHandler>
              </Row>

              <Row>
                <Col span={24}>{tableContent}</Col>
              </Row>
            </Col>
          </Row>
        </CardWrapper>
      </Wrapper>
    </>
  );
};

export default BlockedDocuments;
