import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Switch } from 'antd';

import { useFetch } from 'services/hooks';

import './styles.less';
import { useAuth } from 'hooks/auth';
import { hasRequiredPermissions } from 'routes/permissionFunctions';

const I18N_BASE_PATH =
  'pages.private.blockedDocuments.blockedDocumentsList.index.BlockedDocumentsSwitch';
function BlockedDocumentSwitch({ register, refreshListRef }) {
  const { put } = useFetch();
  const [loading, setLoading] = useState();
  const { t: translate } = useTranslation();

  const handleBlockSwitchStateChange = useCallback(
    async (checked) => {
      setLoading(true);

      try {
        await put({
          url: `/blocked-documents/${register?.id}`,
          payload: {
            documentNumber: register?.documentNumber,
            documentType: register?.type,
            isBlocked: checked,
          },
          showError: false,
        });

        refreshListRef?.current();
      } catch (error) {
        //
      }
      setLoading(false);
    },
    [put, refreshListRef, register]
  );

  const { user: loggedUser } = useAuth();

  const notHasEditPermission = useMemo(
    () =>
      !hasRequiredPermissions(
        loggedUser,
        ['blocked-documents:update'],
        [],
        false,
        'trust'
      ),
    [loggedUser]
  );

  return (
    <Switch
      className="blocked-document-switch"
      loading={loading}
      disabled={loading || notHasEditPermission}
      style={{ width: 93 }}
      checked={register?.isBlocked}
      checkedChildren={translate(`${I18N_BASE_PATH}.label.blocked`)}
      unCheckedChildren={translate(`${I18N_BASE_PATH}.label.unblocked`)}
      onClick={(checked) => handleBlockSwitchStateChange(checked, register)}
    />
  );
}

BlockedDocumentSwitch.propTypes = {
  register: PropTypes.shape({
    isBlocked: PropTypes.bool,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    documentNumber: PropTypes.string.isRequired,
  }),
  refreshListRef: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
};

BlockedDocumentSwitch.defaultProps = {
  register: PropTypes.shape({
    isBlocked: false,
  }),
};

export default BlockedDocumentSwitch;
