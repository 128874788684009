import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'services/hooks';
import { Company as CompanyIcon } from '@combateafraude/icons/general';
import './styles.less';

const I18N_BASE_PATH = 'pages.private.executions.components.executionSubheader.index';

const ExecutionSubheaderParentLink = ({ parentId }) => {
  const [parentExecutionName, setParentExecutionName] = useState(null);
  const { get, loading } = useFetch();
  const { t } = useTranslation();

  const loadParentExecution = async () => {
    const response = await get({
      url: `/executions/${parentId}`,
      showMessage: false,
    });
    const companyName =
      response.data.sections?.kybCreditReport?.data?.name ||
      response.data.sections?.kybBusinessIdentity?.data?.name ||
      response.data.parameters?.manualCompanyName;
    setParentExecutionName(companyName);
  };

  useEffect(() => {
    if (parentId) {
      loadParentExecution();
    }
  }, [parentId]);

  if (!parentId) return null;

  return (
    <span className="parent-execution">
      <CompanyIcon className="icon" />
      {t(`${I18N_BASE_PATH}.companyRelated`, 'Company related:')}{' '}
      {loading && <i className="caf-ic_rotate-cw loading-icon" />}
      <a
        className="company-name"
        href={`/company/${parentId}`}
        target="_blank"
        rel="noreferrer"
      >
        {parentExecutionName}
      </a>
    </span>
  );
};

ExecutionSubheaderParentLink.propTypes = {
  parentId: PropTypes.string.isRequired,
};

export default ExecutionSubheaderParentLink;
