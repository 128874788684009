import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { convertGlobalDocVerificationResultToIcon } from '../../../../utils/convertGlobalDocVerificationResultToIcon';

const I18N_BASE_PATH =
  'pages.private.executions.components.tabs.components.documentsValidations.newOcrScreen.documentAuthenticity';

const BarcodeFormatCheck = ({ data }) => {
  const { t: translate } = useTranslation();

  const tableColumns = [
    {
      title: translate(`${I18N_BASE_PATH}.elementType`),
      dataIndex: 'elementType',
      key: 'elementType',
    },
    {
      title: translate(`${I18N_BASE_PATH}.diagnose`),
      dataIndex: 'diagnose',
      key: 'diagnose',
      render: (_, { diagnose }) => (
        <span className={diagnose?.type || ''}>{diagnose?.result || '-'}</span>
      ),
    },
    {
      title: translate(`${I18N_BASE_PATH}.result`),
      dataIndex: 'result',
      key: 'result',
    },
  ];

  const tableData = data?.barcode?.map((barcodeElement, index) => ({
    key: index,
    elementType: translate(`${I18N_BASE_PATH}.barcodeFormatCheck.barcode`, 'Barcode'),
    diagnose: {
      type:
        [0, 1].includes(barcodeElement?.result) && barcodeElement?.result === 1
          ? 'approval-diagnose'
          : 'failure-diagnose',
      result: barcodeElement?.diagnose,
    },
    result: convertGlobalDocVerificationResultToIcon({
      checkResult: barcodeElement?.result,
      className: 'font-size-18',
    }),
  }));

  return (
    <div className="mrg-top-20">
      <h3 className="authenticity-check-title">
        {translate(`${I18N_BASE_PATH}.barcodeFormatCheck`)}
        <span>
          {convertGlobalDocVerificationResultToIcon({
            checkResult: data?.result,
            className: 'font-size-18',
          })}
        </span>
      </h3>
      {tableData?.length > 0 && (
        <Table
          columns={tableColumns}
          dataSource={tableData}
          pagination={false}
          tableLayout="fixed"
        />
      )}
    </div>
  );
};

BarcodeFormatCheck.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.oneOf([0, 1, 2]),
    barcode: PropTypes.arrayOf(
      PropTypes.shape({
        diagnose: PropTypes.string,
        result: PropTypes.oneOf([0, 1, 2]),
      })
    ),
  }).isRequired,
};

export default BarcodeFormatCheck;
