import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card } from 'antd';

import useProfileTemplate from 'pages/private/Profiles/components/ProfileTemplate';

import PermissionWrapper from 'routes/PermissionWrapper';

import './styles.less';

const EmptyState = ({ data, type, hiddenAction, description, urlId, ...props }) => {
  const { t: translate } = useTranslation();

  const { companyId, personId } = useParams();
  const matchPeople = useRouteMatch('/profile/people');

  const urlType = companyId
    ? translate('components.emptyState.type.company')
    : translate('components.emptyState.type.person');

  const link = `/profile-templates/profile-models/registration/${urlId}?type=${urlType}`;
  const url = companyId ? `/companies/${companyId}` : `/people/${personId}`;

  const { openModal, ProfileTemplateModal } = useProfileTemplate({
    profile: data,
    url,
    type: urlType,
  });

  const handlerIcon = useCallback((status) => {
    switch (status) {
      case 'empty-recurrence':
        return 'caf-ic_alert-triangle';
      case 'empty-success':
        return 'caf-ic_check_d';
      case 'waiting_consult':
        return 'caf-ic_clock';
      case 'processing':
        return 'caf-ic_processing_line';
      case 'failed':
        return 'caf-ic_x_close';
      default:
        return null;
    }
  }, []);

  const permissions = useMemo(
    () => (matchPeople ? ['people:update'] : ['companies:update']),
    [matchPeople]
  );

  return (
    <>
      {ProfileTemplateModal}

      <Card id="empty-state-card" {...props}>
        <i className={handlerIcon(type)} />

        {type === 'empty-recurrence' && urlId && (
          <strong>
            {translate('components.emptyState.cardText.emptyRecurrenceAndHasUrlId')}
            <PermissionWrapper requiredPermissions={['profile-templates:update']}>
              {!hiddenAction && (
                <>
                  {' '}
                  {translate('components.emptyState.button.text.before')}
                  <Link to={link}>
                    {' '}
                    {translate('components.emptyState.button.text.inside')}
                  </Link>{' '}
                  {translate('components.emptyState.button.text.after')}
                </>
              )}
            </PermissionWrapper>
          </strong>
        )}

        {type === 'empty-recurrence' && !urlId && (
          <strong>
            {translate('components.emptyState.cardText.emptyRecurrenceAndHasNotUrlId')}
            <PermissionWrapper requiredPermissions={permissions}>
              {!hiddenAction && (
                <>
                  {translate('components.emptyState.button.text.before')}{' '}
                  <button
                    type="button"
                    className="empty-state-btn reset-btn"
                    onClick={() => openModal()}
                  >
                    {translate('components.emptyState.button.text.inside')}
                  </button>{' '}
                  {translate('components.emptyState.button.text.after')}
                </>
              )}
            </PermissionWrapper>
          </strong>
        )}

        {type === 'processing' && (
          <strong>{translate('components.emptyState.cardText.processing')}</strong>
        )}

        {type === 'waiting_consult' && (
          <strong>{translate('components.emptyState.cardText.waitingConsult')}</strong>
        )}

        {type !== 'empty-recurrence' && type !== 'processing' && (
          <strong>{description}</strong>
        )}
      </Card>
    </>
  );
};

EmptyState.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.oneOf([
    'empty-recurrence',
    'empty-success',
    'processing',
    'failed',
    'waiting_consult',
  ]).isRequired,
  hiddenAction: PropTypes.bool,
  description: PropTypes.string,
  urlId: PropTypes.string,
};

EmptyState.defaultProps = {
  data: null,
  hiddenAction: false,
  description: '',
  urlId: '',
};

export default EmptyState;
