import React from 'react';

import { Col } from 'antd';
import PropTypes from 'prop-types';

import './styles.less';

const Labelcol = ({ span, title, result, type }) => {
  return (
    <Col id="label-ocr-col" span={span}>
      <div className="label-ocr-col-div">
        <strong>{title}</strong>
        <span className={type}>{result}</span>
      </div>
    </Col>
  );
};
Labelcol.propTypes = {
  title: PropTypes.string.isRequired,
  result: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
  span: PropTypes.number.isRequired,
};

Labelcol.defaultProps = {
  result: '-',
  type: undefined,
};

export default Labelcol;
