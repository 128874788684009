import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Button, Form, Input, message, Row, Col } from 'antd';

import PageTitle from 'components/PageTitle';
import { useFetch } from 'services/hooks';
import { useOnboardingTemplate } from 'hooks/onboardingTemplate';
import InputMask from 'components/Form/InputMask';

import copyTextToClipboard from 'utils/copyTextToClipboard';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import es from 'react-phone-input-2/lang/es.json';
import pt from 'react-phone-input-2/lang/pt.json';

import './styles.less';

const ModalShareProfileTemplateLink = () => {
  const { t: translate, i18n } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [profileTemplate, setProfileTemplate] = useState({});
  const { loadOnboardingTemplates } = useOnboardingTemplate();
  const { post: postOnboardingExt } = useFetch();
  const language = {
    'pt-BR': pt,
    'es-MX': es,
    'en-US': undefined,
  };

  const defaultCountry = {
    'pt-BR': 'br',
    'es-MX': 'mx',
    'en-US': 'us',
  };

  const [form] = Form.useForm();

  const closeModal = useCallback(() => {
    form.resetFields();
    setModalVisible(false);
  }, [form]);

  const openModal = useCallback((payload) => {
    setModalVisible(true);
    setLoading(true);
    setProfileTemplate(payload);
    setLoading(false);
  }, []);

  const submit = useCallback(
    async (payload) => {
      try {
        setLoading(true);
        if (
          profileTemplate?.type ===
            translate('components.modalShareProfileTemplateLink.type.company') &&
          !profileTemplate?.qsaProfileTemplateId
        ) {
          message.warn(
            translate(
              'components.modalShareProfileTemplateLink.submit.messages.warn.profileTemplatePJ'
            ),
            6
          );
        } else if (
          profileTemplate?.type ===
            translate('components.modalShareProfileTemplateLink.type.person') &&
          profileTemplate?.backgroundChecking?.services?.find((service) =>
            ['pf_rais'].includes(service.source)
          )
        ) {
          message.warn(
            translate(
              'components.modalShareProfileTemplateLink.submit.messages.warn.profileTemplatePF'
            ),
            6
          );
        } else {
          const defaultTemplate = await loadOnboardingTemplates();
          const response = await postOnboardingExt({
            url: '/onboardings',
            showError: false,
            payload: {
              noExpire: false,
              qsaTemplateId: profileTemplate?.qsaProfileTemplateId || '',
              templateId:
                profileTemplate?.onboardingTemplateId ||
                defaultTemplate[profileTemplate?.type]?._id,
              trustTemplateId: profileTemplate?._id,
              type: profileTemplate?.type,
              email: payload?.email || '',
              smsPhoneNumber: payload?.sms || '',
              textMessage: translate(
                'components.modalShareProfileTemplateLink.shareLinkModal.textMessage'
              ),
              attributes: {
                cpf: '',
                cnpj: '',
              },
              metadata: { origin: 'TRUST' },
            },
          });

          copyTextToClipboard(
            response.onboardingUrl,
            translate(
              'components.modalShareProfileTemplateLink.submit.copyTextToClipboard.success'
            ),
            translate(
              'components.modalShareProfileTemplateLink.submit.copyTextToClipboard.error'
            )
          );
        }

        closeModal();
      } catch (e) {
        //
      }
      setLoading(false);
    },
    [profileTemplate, translate, closeModal, loadOnboardingTemplates, postOnboardingExt]
  );

  const ShareLinkModal = useMemo(
    () => (
      <Modal
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        visible={modalVisible}
        footer={null}
        width={700}
        onCancel={closeModal}
      >
        <PageTitle
          title={translate(
            'components.modalShareProfileTemplateLink.shareLinkModal.pageTitle.title'
          )}
          subtitle={translate(
            'components.modalShareProfileTemplateLink.shareLinkModal.pageTitle.subtitle'
          )}
        />
        <Form onFinish={(values) => submit(values)} form={form}>
          <div id="content-container-share-link">
            <strong className="profile-name">
              {profileTemplate?.name || profileTemplate?.cpf || profileTemplate?.cnpj}
            </strong>
            <Row className="no-mrg no-pdd">
              <Col span={11} className="no-mrg no-pdd input-phonenumber flex-center">
                <i style={{ color: '#000' }} className="caf-ic_smartphone mrg-left-15" />
                <Form.Item className="no-mrg no-pdd text-dark" name="sms">
                  <PhoneInput
                    localization={language[i18n.language]}
                    country={defaultCountry[i18n.language]}
                  />
                </Form.Item>
              </Col>
              <Col span={2} className="no-mrg no-pdd" />
              <Col span={11} className="no-mrg no-pdd input-phonenumber flex-center">
                <Form.Item className="form-mail no-mrg no-pdd" name="email">
                  <Input
                    type="email"
                    prefix={<i style={{ color: '#000' }} className="caf-ic_mail" />}
                    bordered={false}
                    placeholder={translate(
                      'components.modalShareProfileTemplateLink.shareLinkModal.form.email.input.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="modal-action-buttons" align="middle" justify="end">
            <Button
              type="ghost"
              shape="round"
              className="flex no-mrg-btm center"
              outlined="false"
              onClick={closeModal}
            >
              {translate(
                'components.modalShareProfileTemplateLink.shareLinkModal.form.button.cancel'
              )}
            </Button>
            <Button
              type="primary"
              shape="round"
              htmlType="submit"
              className="flex no-mrg-btm center"
              outlined
              loading={loading}
              disabled={loading}
            >
              {translate(
                'components.modalShareProfileTemplateLink.shareLinkModal.form.button.share'
              )}
            </Button>
          </div>
        </Form>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      translate,
      form,
      profileTemplate,
      language,
      i18n.language,
      defaultCountry,
      loading,
      submit,
    ]
  );

  return { openModal, ShareLinkModal };
};

export default ModalShareProfileTemplateLink;
