/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Row, Progress } from 'antd';
import './styles.less';
import { useI18nFormatters } from 'hooks/i18nFormatters';

const GenericProgress = ({ from, until }) => {
  const { i18nFormatCurrency } = useI18nFormatters();
  const handlerPercent = useMemo(() => {
    const percent = 50;

    let fillColor = '';

    if (percent <= 25) {
      fillColor = '#eb5757';
    } else if (percent <= 50) {
      fillColor = '#faad14';
    } else if (percent <= 75) {
      fillColor = '#A4C626';
    } else {
      fillColor = '#49AF46';
    }

    return { percent, fillColor };
  }, []);

  return (
    <Row id="executions-generic-progress-container" className="reset-mrg">
      <div style={{ width: '100%', minWidth: '100%' }}>
        <Progress
          className="first-progress-mark"
          style={{ width: '25%' }}
          strokeColor="#eb5757"
          showInfo={false}
          percent={100}
        />
        <Progress
          style={{ width: '25%' }}
          strokeColor="#faad14"
          showInfo={false}
          percent={100}
        />
        <Progress
          style={{ width: '25%' }}
          strokeColor="#A4C626"
          showInfo={false}
          percent={100}
        />
        <Progress
          className="last-progress-mark"
          style={{ width: '25%' }}
          strokeColor="#49AF46"
          showInfo={false}
          percent={100}
        />
      </div>

      <svg
        className="generic-progress-marker reset-mrg"
        style={{ left: `${(handlerPercent?.percent || 0) - 3}%` }}
        width="34"
        height="49"
        viewBox="0 0 34 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.13845 23.7263L13.1109 44.2283C14.6868 47.4681 19.3132 47.4681 20.8891 44.2283L30.8615 23.7263C35.8273 13.5174 28.374 1.64381 17 1.64381C5.62598 1.64381 -1.82729 13.5174 3.13845 23.7263Z"
          fill={handlerPercent?.fillColor || ''}
          stroke="white"
          strokeWidth="3"
        />
        <path
          d="M25 16C25 11.5817 21.4183 8 17 8C12.5817 8 9 11.5817 9 16C9 20.4183 12.5817 24 17 24C21.4183 24 25 20.4183 25 16Z"
          fill="white"
        />
      </svg>

      <div className="generic-progress-indicators">
        <span>{i18nFormatCurrency(from || 0, 'pt-BR')}</span>
        <span>
          {i18nFormatCurrency((Number(from || 0) + Number(until || 0)) / 2, 'pt-BR')}
        </span>
        <span>{i18nFormatCurrency(until || 0, 'pt-BR')}</span>
      </div>
    </Row>
  );
};

export default GenericProgress;
