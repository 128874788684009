/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Spin } from 'antd';

import { useValidations } from 'hooks/validations';

import EmptyMessage from 'components/EmptyMessage';

import ProfileWidget from 'pages/private/Profiles/components/ProfileWidget';
import CheckpointModel from 'pages/private/Profiles/components/CheckpointModel';

import useProfileTemplate from 'pages/private/Profiles/components/ProfileTemplate';
import useManualQuery from 'pages/private/Profiles/components/ManualQuery';
// import CustomUploadButton from 'pages/private/Profiles/components/CustomUploadButton';
// import useManualUploadDocuments from 'pages/private/Profiles/components/ManualUploadDocuments';

// import PermissionWrapper from 'routes/PermissionWrapper';

// import servicesCategories from 'utils/data/templateServicesCategories.json';

// import TrustItem from './components/TrustItem';
import ValidationsTimeline from './components/ValidationsTimeline';

const ProfileValidations = ({ profile, toPrint, url, type }) => {
  const { t: translate } = useTranslation();

  const profileTemplate = useMemo(() => profile?.profileTemplate, [profile]);
  // const {
  //   openModal: openManualUploadDocumentsModal,
  //   ManualUploadDocumentsModal,
  // } = useManualUploadDocuments();

  const {
    validations: profileValidations,
    loadingValidations: loading,
  } = useValidations();

  const {
    openModal: openProfileTemplateModal,
    ProfileTemplateModal,
  } = useProfileTemplate({
    profile,
    url,
    type,
  });
  const { openModal: openManualQueryModal, ManualQueryModal } = useManualQuery({
    profileTemplate,
  });

  // const isProfileTemplateActive = useMemo(() => {
  //   return profileTemplate?.active;
  // }, [profileTemplate]);

  // const profileTemplateHasOnboardingServices = useMemo(() => {
  //   return profileTemplate?.onboarding?.services?.length > 0;
  // }, [profileTemplate]);

  const backgroundCheckingDetails = useMemo(() => {
    if (!profileValidations) return undefined;

    const _validations = profileValidations?.validations;

    if (!_validations?.length) return undefined;

    const rule = _validations.find((v) => v.rule === 'valid_background_checking');

    if (!rule) return undefined;
    return rule?.data?.backgroundChecking || [];
  }, [profileValidations]);

  // const complianceRules = useMemo(() => {
  //   if (!profileValidations) return undefined;

  //   const _validations = profileValidations?.validations;

  //   if (!_validations?.length) return undefined;

  //   const complianceRulesData = _validations.filter(
  //     (v) => v.rule === 'valid_document' || v.rule === 'valid_identity'
  //   );

  //   return complianceRulesData;
  // }, [profileValidations]);

  // const invalidValidation = useMemo(() => {
  //   const invalidValidations = complianceRules?.filter((cr) => cr.status === 'INVALID');
  //   return !invalidValidations?.length;
  // }, [complianceRules]);

  // const hasDocumentSend = useMemo(() => {
  //   const validStatus = ['VALID', 'INVALID', 'PROCESSING', 'PENDING'];

  //   const document = complianceRules?.filter((c) => c.rule === 'valid_document') || [];
  //   const documentStatus = document[0]?.status;

  //   return validStatus.includes(documentStatus);
  // }, [complianceRules]);

  // const customBtnContent = useMemo(() => {
  //   if (hasDocumentSend) {
  //     return (
  //       <span>
  //         {translate(
  //           'pages.private.profiles.components.profileValidations.index.customBtnContent.hasDocumentSend.before'
  //         )}{' '}
  //         <strong>
  //           {translate(
  //             'pages.private.profiles.components.profileValidations.index.customBtnContent.hasDocumentSend.after'
  //           )}
  //         </strong>
  //         .
  //       </span>
  //     );
  //   }

  //   return (
  //     <span>
  //       {translate(
  //         'pages.private.profiles.components.profileValidations.index.customBtnContent.default.before'
  //       )}{' '}
  //       <strong>
  //         {translate(
  //           'pages.private.profiles.components.profileValidations.index.customBtnContent.default.inside'
  //         )}
  //       </strong>{' '}
  //       {translate(
  //         'pages.private.profiles.components.profileValidations.index.customBtnContent.default.after'
  //       )}
  //     </span>
  //   );
  // }, [hasDocumentSend, translate]);

  return (
    <ProfileWidget
      title={translate(
        'pages.private.profiles.components.profileValidations.index.profileValidations.profileWidget.title'
      )}
      subtitle={translate(
        'pages.private.profiles.components.profileValidations.index.profileValidations.profileWidget.subtitle'
      )}
      icon="caf-ic_check_d"
      customClassName={
        !loading && backgroundCheckingDetails?.length > 0 ? 'reset-border-top' : ''
      }
    >
      {ProfileTemplateModal}
      {ManualQueryModal}
      {/* {ManualUploadDocumentsModal} */}
      <div className="identity-info">
        {/* {!loading &&
          type ===
            translate(
              'pages.private.profiles.components.profileValidations.index.profileValidations.type.person'
            ) && (
            // <PermissionWrapper requiredPermissions={['people:update']}>
            //   {(!profile?.documents?.length || !invalidValidation) &&
            //   isProfileTemplateActive &&
            //   profileTemplateHasOnboardingServices ? (
            //     <CustomUploadButton
            //       onClick={openManualUploadDocumentsModal}
            //       customIcon={
            //         hasDocumentSend ? 'caf-ic_processing_line' : 'caf-ic_upload'
            //       }
            //       customContent={customBtnContent}
            //       customClass={hasDocumentSend && 'document-resend-btn'}
            //     />
            //   ) : (
            //     <></>
            //   )}
            // </PermissionWrapper>
          )} */}
        {!loading && <ValidationsTimeline validations={profileValidations} />}
        {loading && (
          <div className="custom-loading-spin trust-validations">
            <Spin />
          </div>
        )}

        {!loading && !profileValidations?.validations?.length > 0 && (
          <EmptyMessage
            description={translate(
              'pages.private.profiles.components.profileValidations.index.profileValidations.emptyMessage.description'
            )}
            type="empty"
            style={{ padding: 15 }}
          />
        )}
      </div>

      {!loading && (
        <>
          {/* {backgroundCheckingDetails?.length > 0 && (
            <>
              {backgroundCheckingDetails?.map((group) => (
                <React.Fragment key={group.title}>
                  <div className="compliance-group-title">
                    <span>{group?.title || '-'}</span>
                  </div>

                  {group?.items?.length > 0 ? (
                    group?.items?.map((item) => (
                      <div
                        key={item.category || item.reportId}
                        className="personal-data-info bordered"
                      >
                        <TrustItem
                          title={
                            item.category
                              ? translate(servicesCategories[item.category].title)
                              : item.name
                          }
                          status={item.status}
                          // rule={item.rule}
                          data={item}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="personal-data-info bordered">
                      <span>Nenhuma validação disponível.</span>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </>
                  )} */}

          <footer>
            {profileTemplate ? (
              <CheckpointModel
                hasModel
                openSettings={openProfileTemplateModal}
                openManualQuery={openManualQueryModal}
                title={translate(
                  'pages.private.profiles.components.profileValidations.index.profileValidations.checkpointModel.title.hasProfileTemplate'
                )}
                description={profileTemplate?.name}
                status={profileTemplate?.status}
                isProfileTemplateActive={profileTemplate?.active}
              />
            ) : (
              <CheckpointModel
                openSettings={openProfileTemplateModal}
                title={translate(
                  'pages.private.profiles.components.profileValidations.index.profileValidations.checkpointModel.title.hasNotProfileTemplate'
                )}
                description={translate(
                  'pages.private.profiles.components.profileValidations.index.profileValidations.checkpointModel.description.hasNotProfileTemplate'
                )}
              />
            )}
          </footer>
        </>
      )}

      {toPrint && <Divider className="custom-divider" />}
    </ProfileWidget>
  );
};

export default ProfileValidations;
