import React, { useState, useCallback, useMemo } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import defaultImage from 'assets/images/profile-default.png';
import './styles.less';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.people.components.directorsModal';

const DirectorsModal = () => {
  const { t: translate } = useTranslation();
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [directors, setDirectors] = useState([]);

  const closeModal = useCallback(() => {
    setModalIsVisible(false);
  }, []);

  const openDirectorsModal = useCallback((directorsInput) => {
    setDirectors(directorsInput || []);
    setModalIsVisible(true);
  }, []);

  const DirectorsDetail = useMemo(() => {
    return (
      <>
        <h4 className="ant-typography mrg-btm-25">
          {translate(`${I18N_BASE_PATH}.directors`)}
        </h4>
        {directors.map((director) => (
          <div className="director-card-content">
            <img
              className="director-image"
              src={defaultImage}
              alt={director?.name || ''}
            />
            <div className="director-info">
              <div className="mrg-btm-5">
                <span className="text-bold">{director?.name || '-'}</span>
              </div>
              <div>
                <span className="text-bold">
                  {translate(`${I18N_BASE_PATH}.instruction`)}:{' '}
                </span>
                <span>{director?.instruction || '-'}</span>
              </div>
              <div>
                <span className="text-bold">
                  {translate(`${I18N_BASE_PATH}.startDate`)}:{' '}
                </span>
                <span>{director?.startDate || '-'}</span>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }, [directors, translate]);

  const DirectorsModalContent = useMemo(
    () => (
      <>
        <Modal
          wrapClassName="caf-modal"
          closable
          width={850}
          footer={null}
          closeIcon={<i className="caf-ic_close font-size-18" />}
          onCancel={closeModal}
          visible={modalIsVisible}
        >
          {DirectorsDetail}
        </Modal>
      </>
    ),
    [DirectorsDetail, closeModal, modalIsVisible]
  );

  return { openDirectorsModal, DirectorsModalContent };
};

export default DirectorsModal;
