import React, { useMemo } from 'react';
import { Row, Typography, Divider, Collapse, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import DottedLabel from 'components/DottedLabel';
import EmptyExecutionState from 'components/EmptyExecutionState';
import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';
import useDirectorsModal from 'components/DataSourceCards/FinancialInfos/People/components/DirectorsModal';

import { useI18nFormatters } from 'hooks/i18nFormatters';
import { isEmpty } from 'utils/isObjectEmpty';

import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

const { Title } = Typography;
const { Panel } = Collapse;

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.people.components.associatedCVM';

function AssociateCVM({ source = {}, toPrint = false }) {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const { openDirectorsModal, DirectorsModalContent } = useDirectorsModal();

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  return !queryWorked ? (
    <DataSourceCardCollapsePanel
      title={translate(`${I18N_BASE_PATH}.title`)}
      icon="caf-ic_company"
      toPrint={toPrint}
      customId="financialInfo"
    >
      <EmptyExecutionState
        className="mrg-top-25"
        statusCode={statusCode}
        message={parsedData?.message || source?.message}
      />
    </DataSourceCardCollapsePanel>
  ) : (
    <DataSourceCardCollapsePanel
      title={translate(`${I18N_BASE_PATH}.title`)}
      icon="caf-ic_company"
      toPrint={toPrint}
      customId="financialInfo"
    >
      {DirectorsModalContent}
      <Collapse className="mrg-top-25 mrg-btm-15">
        <Panel
          disabled={!parsedData?.associates?.length}
          header={
            <span className="text-dark text-bold">
              {translate(`${I18N_BASE_PATH}.columns.associates`)}
            </span>
          }
          extra={
            <DottedLabel
              type="primary"
              description={
                <span>
                  {translate(`${I18N_BASE_PATH}.columns.associatesLabel`, {
                    associatesCount: parsedData?.associates?.length || 0,
                  })}
                </span>
              }
            />
          }
        >
          {' '}
          {isEmpty(parsedData?.associates) ? (
            <span>{translate(`${I18N_BASE_PATH}.columns.emptyRegisterInfos`)}</span>
          ) : (
            <>
              {parsedData?.associates?.map((associate) => (
                <Row align="middle" justify="space-between" className="mrg-top-15">
                  <ColumnTitleValue span={24} title={associate.name || '-'} />
                  <Col span={24}>
                    <span className="text-gray text-bold">
                      {translate(`${I18N_BASE_PATH}.columns.type`)}:
                    </span>
                    <span> {associate.type || '-'}</span>
                  </Col>
                  <Col span={24}>
                    <span className="text-gray text-bold">
                      {translate(`${I18N_BASE_PATH}.columns.situation`)}:
                    </span>
                    <span> {associate.situation || '-'}</span>
                  </Col>
                  <Col span={24}>
                    <span className="text-gray text-bold">
                      {translate(`${I18N_BASE_PATH}.columns.recordDate`)}:
                    </span>
                    <span> {associate.recordDate || '-'}</span>
                  </Col>
                  {!isEmpty(associate?.directors) && (
                    <Button
                      type="link"
                      onClick={() => {
                        openDirectorsModal(associate?.directors);
                      }}
                    >
                      <i className="caf-ic_arrow-right mrg-right-10" />
                      <span>
                        {translate(`${I18N_BASE_PATH}.columns.showDirectorsButton`, {
                          directorsCount: associate?.directors?.length,
                        })}
                      </span>
                    </Button>
                  )}

                  <Divider />
                </Row>
              ))}
            </>
          )}
        </Panel>
      </Collapse>
      <Divider />
      <Title level={4} className="mrg-btm-25">
        {translate(`${I18N_BASE_PATH}.columns.registerInfos`)}
      </Title>

      {isEmpty(parsedData?.prestServico) ? (
        <span>{translate(`${I18N_BASE_PATH}.columns.emptyRegisterInfos`)}</span>
      ) : (
        <>
          <Row align="middle" justify="space-between" className="mrg-top-15">
            <ColumnTitleValue
              title={translate(`${I18N_BASE_PATH}.columns.personName`)}
              value={parsedData?.prestServico?.name || '-'}
            />
            <ColumnTitleValue
              title={translate(`${I18N_BASE_PATH}.columns.status`)}
              value={parsedData?.prestServico?.status || '-'}
            />
            <ColumnTitleValue
              title={translate(`${I18N_BASE_PATH}.columns.registerType`)}
              value={parsedData?.prestServico?.type || '-'}
            />
          </Row>
          <Divider />
          <Row align="middle" justify="space-between" className="mrg-top-15">
            <ColumnTitleValue
              title={translate(`${I18N_BASE_PATH}.columns.category`)}
              value={parsedData?.prestServico?.category || '-'}
            />
            <ColumnTitleValue
              span={16}
              title={translate(`${I18N_BASE_PATH}.columns.registrationDate`)}
              value={i18nFormatDate(parsedData?.prestServico?.registrationDate, 1) || '-'}
            />
          </Row>

          <Row className="mrg-top-25">
            <Col span={24}>
              <Collapse>
                <Panel
                  disabled={!parsedData?.prestServico?.serviceProvision?.length}
                  header={
                    <span className="text-dark text-bold">
                      {translate(`${I18N_BASE_PATH}.columns.serviceProvision`)}
                    </span>
                  }
                  extra={
                    <DottedLabel
                      type="primary"
                      description={`(${
                        parsedData?.prestServico?.serviceProvision?.length || 0
                      }) ${translate(`${I18N_BASE_PATH}.columns.registers`)}`}
                    />
                  }
                >
                  {parsedData?.prestServico?.serviceProvision?.map((register, index) => (
                    <>
                      <Row>
                        <Col span={24}>
                          <span className="text-dark text-bold">
                            {register?.name || '-'}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mrg-top-10" span={24}>
                          <span className="collapse-label">
                            {translate(`${I18N_BASE_PATH}.columns.cnpj`)}:{' '}
                          </span>
                          <span>{register?.cnpj}</span>
                        </Col>
                      </Row>
                      {index < parsedData?.prestServico?.serviceProvision?.length - 1 && (
                        <Divider />
                      )}
                    </>
                  ))}
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Row className="mrg-top-25">
            <Col span={24}>
              <Collapse>
                <Panel
                  disabled={!parsedData?.prestServico?.otherTypesRegistration?.length}
                  header={
                    <span className="text-dark text-bold">
                      {translate(`${I18N_BASE_PATH}.columns.otherTypesRegistration`)}
                    </span>
                  }
                  extra={
                    <DottedLabel
                      type="primary"
                      description={`(${
                        parsedData?.prestServico?.otherTypesRegistration?.length || 0
                      }) ${translate(`${I18N_BASE_PATH}.columns.registers`)}`}
                    />
                  }
                >
                  {parsedData?.prestServico?.otherTypesRegistration?.map(
                    (register, index) => (
                      <>
                        <Row>
                          <Col span={24}>
                            <span className="text-dark text-bold">{register || '-'}</span>
                          </Col>
                        </Row>
                        {index <
                          parsedData?.prestServico?.otherTypesRegistration?.length -
                            1 && <Divider />}
                      </>
                    )
                  )}
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </>
      )}

      <Title level={4} className="mrg-top-45 mrg-btm-25">
        {translate(`${I18N_BASE_PATH}.columns.fundoFidc`)}
      </Title>

      {isEmpty(parsedData?.fundoFidc) ? (
        <span>{translate(`${I18N_BASE_PATH}.columns.emptyFundoFidc`)}</span>
      ) : (
        <>
          <Row align="middle" justify="space-between" className="mrg-top-15">
            <ColumnTitleValue
              title={translate(`${I18N_BASE_PATH}.columns.personName`)}
              value={parsedData?.fundoFidc?.name || '-'}
            />
            <ColumnTitleValue
              title={translate(`${I18N_BASE_PATH}.columns.status`)}
              value={parsedData?.fundoFidc?.status || '-'}
            />
            <ColumnTitleValue
              title={translate(`${I18N_BASE_PATH}.columns.registerType`)}
              value={parsedData?.fundoFidc?.type || '-'}
            />
          </Row>
          <Divider />
          <Row align="middle" justify="space-between" className="mrg-top-15">
            <ColumnTitleValue
              title={translate(`${I18N_BASE_PATH}.columns.category`)}
              value={parsedData?.fundoFidc?.category || '-'}
            />
            <ColumnTitleValue
              span={16}
              title={translate(`${I18N_BASE_PATH}.columns.registrationDate`)}
              value={i18nFormatDate(parsedData?.fundoFidc?.registrationDate, 1) || '-'}
            />
          </Row>
        </>
      )}
    </DataSourceCardCollapsePanel>
  );
}

export default AssociateCVM;
