import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Row } from 'antd';
import DottedLabel from 'components/DottedLabel';
import EmptyExecutionState from 'components/EmptyExecutionState';

import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

import './styles.less';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.companies.components.penaltiesOnCvm';

const PenaltiesOnCVM = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () => statusCode === '01' || source?.available || source.lastConsultation,
    [statusCode, source]
  );

  const hasContent = useMemo(() => parsedData?.listOfSentences?.length > 0, [parsedData]);

  return !queryWorked ? (
    <DataSourceCardCollapsePanel
      title={translate(`${I18N_BASE_PATH}.title`)}
      icon="caf-ic_clock"
      toPrint={toPrint}
      customId="financialInfo"
    >
      <EmptyExecutionState
        className="mrg-top-25"
        statusCode={statusCode}
        message={parsedData?.message || source?.message}
      />
    </DataSourceCardCollapsePanel>
  ) : (
    <>
      <DataSourceCardCollapsePanel
        title={translate(`${I18N_BASE_PATH}.title`)}
        icon="caf-ic_clock"
        toPrint={toPrint}
        customId="financialInfo"
      >
        <Row className="mrg-left-5">
          <span>{translate(`${I18N_BASE_PATH}.subtitle`)}</span>
        </Row>
        <Row className="mrg-left-5 mrg-top-20">
          <DottedLabel
            description={
              hasContent
                ? translate(`${I18N_BASE_PATH}.dottedLabel.hasRegisters`, {
                    value: parsedData?.listOfSentences?.length,
                  })
                : translate(`${I18N_BASE_PATH}.dottedLabel.noRegisters`)
            }
            type={hasContent ? 'danger' : 'primary'}
          />
          <Divider />
        </Row>
        <>
          {hasContent ? (
            <>
              {parsedData?.listOfSentences?.map((sentences) => (
                <>
                  <Row className="row-name">
                    <strong className="sentence-name">{sentences?.name}</strong>
                  </Row>
                  <Row className="row-fields">
                    <span className="sentence-fields">
                      <strong>
                        {translate(`${I18N_BASE_PATH}.sentences.processNumber`)}{' '}
                      </strong>
                      {sentences?.processNumber}
                    </span>
                  </Row>
                  <Row className="row-fields">
                    <span className="sentence-fields">
                      <strong>
                        {translate(`${I18N_BASE_PATH}.sentences.decisionType`)}{' '}
                      </strong>
                      {sentences?.decisionType}
                    </span>
                  </Row>
                  <Row className="row-fields">
                    <span className="sentence-fields">
                      <strong>
                        {translate(`${I18N_BASE_PATH}.sentences.judgementDate`)}{' '}
                      </strong>
                      {sentences?.judgementDate}
                    </span>
                  </Row>
                  <Row className="row-fields">
                    <span className="sentence-fields">
                      <strong>
                        {translate(`${I18N_BASE_PATH}.sentences.effectiveDate`)}{' '}
                      </strong>
                      {sentences?.effectiveDate}
                    </span>
                  </Row>
                  <Row className="row-fields">
                    <span className="sentence-fields">
                      <strong>
                        {translate(`${I18N_BASE_PATH}.sentences.timeOfWithdrawal`)}{' '}
                      </strong>
                      {sentences?.timeOfWithdrawal}
                    </span>
                  </Row>
                  {parsedData?.listOfSentences?.length > 1 && <Divider />}
                </>
              ))}
            </>
          ) : (
            <>
              <span className="mrg-left-5">
                {translate(`${I18N_BASE_PATH}.noRegistersFound`)}
              </span>
            </>
          )}
        </>
      </DataSourceCardCollapsePanel>
    </>
  );
};

export default PenaltiesOnCVM;
