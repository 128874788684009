import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useQueryParams,
  StringParam,
  NumberParam,
  ArrayParam,
  withDefault,
} from 'use-query-params';
import { Row, Tabs } from 'antd';
import { useHistory } from 'react-router-dom';
import { useFetch } from 'services/hooks';
import { CardWrapper } from 'components/CardWrapper';
import Loader from 'components/Loader';
import _ from 'lodash';
import ConfirmAction from 'components/ModalConfirmAction/index';
import NoResults from 'components/NoResults';
import Wrapper from '../../wrapper';
import FilterCompanies from './components/FilterCompanies';
import ListCompanies from './components/ListCompanies';
import ConsultedCompanies from './components/ConsultedCompanies/index';
import Filter from './components/Filter';

import './styles.less';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companySearching.components.filterCompanies';

const I18N_BASE_PATH_CONSULTED_COMPANY =
  'pages.private.KYBCompany.pages.companySearching.components.consultedCompanies';

const I18N_BASE_PATH_INDEX = 'pages.private.KYBCompany.pages.companySearching.index';

const PAGE_SIZE = 15;

const CompanySearching = () => {
  const { get: getMethod } = useFetch();
  const { post: postMethod } = useFetch();
  const { t: translate } = useTranslation();
  const history = useHistory();

  const [query, setQuery] = useQueryParams({
    tab: StringParam,
    page: NumberParam,
    source: StringParam,
    status: StringParam,
    byStatus: withDefault(ArrayParam, []),
    address: withDefault(ArrayParam, []),
  });

  /* TODO remove this shit starting state hell */
  const [companies, setCompanies] = useState(null);
  const [stateToSearch, setStateToSearch] = useState(undefined);
  const [countryToSearch, setCountryToSearch] = useState(undefined);
  const [workflowId, setWorkflowId] = useState(null);
  const [companyToSearch, setCompanyToSearch] = useState('');
  const [workflows, setWorkflows] = useState([]);
  const [companyNumber, setCompanyNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [disabledFilters, setDisabledFilters] = useState(false);

  const handleModalAction = async (
    { creditReportId, businessIdentityId },
    closeModalCb
  ) => {
    closeModalCb();
    setIsLoading(true);

    const response = await postMethod({
      url: `/executions`,
      payload: {
        workflowId,
        profileTemplateId: null,
        attributes: {
          searchInput: {
            country: countryToSearch,
            name: companyToSearch,
            number: companyNumber,
            state: stateToSearch,
          },
          services: {
            creditReportId,
            businessIdentityId,
          },
        },
      },
    });

    setIsLoading(false);
    const { transactionId } = response;
    if (!transactionId) return;
    history.push(`/company/${transactionId}?tab=summary&origin=search`);
  };

  const { openModal, closeModal, ConfirmActionModal } = ConfirmAction({
    title: translate(`${I18N_BASE_PATH_INDEX}.modalTitle`, 'Are you sure?'),
    action: async ({ creditReportId, businessIdentityId }) => {
      handleModalAction({ creditReportId, businessIdentityId }, closeModal);
    },
    okButton: translate(`${I18N_BASE_PATH_INDEX}.modal.confirm`, 'Confirm'),
    cancelButton: translate(`${I18N_BASE_PATH_INDEX}.modal.cancel`, 'Cancel'),
  });

  const getCompanyWorkflow = useCallback(async () => {
    const response = await getMethod({
      url: `/workflows`,
      config: {
        params: {
          segment: 'company',
        },
      },
    });

    // getMethod transforms the array returned from the API into a object, so we must convert it to a array again
    const arrayResponse = Object.values(response);

    const workflowsAvailables = arrayResponse?.reduce((result, doc) => {
      if (doc?.segment === 'company') {
        result.push({
          label: doc.definition?.name,
          value: doc._id,
        });
      }
      return result;
    }, []);

    setWorkflows(workflowsAvailables);
  }, [getMethod]);

  const handleClear = () => {
    setCompanies(null);
    setCompanyToSearch('');
    setCompanyNumber('');
    setWorkflowId(undefined);
    setStateToSearch(undefined);
    setCountryToSearch(undefined);
    setQuery({
      page: 1,
      source: undefined,
      status: undefined,
      address: [],
    });
  };

  const loadCompanies = useCallback(
    async (page) => {
      setCompanies(null);
      setIsLoading(true);

      try {
        const response = await getMethod({
          url: `/company-search`,
          config: {
            params: {
              country: countryToSearch,
              company: companyToSearch,
              regNumber: companyNumber,
              state: stateToSearch,
              pageSize: PAGE_SIZE,
              workflowId,
              page: page || query?.page || 1,
            },
          },
        });

        setCompanies(response?.data?.companies);
        setQuery({
          page: response?.data?.currentPage || 1,
          source: undefined,
          status: undefined,
          address: [],
        });
        setIsLoading(false);
      } catch (error) {
        setCompanies([]);
        setQuery({
          page: 1,
          source: undefined,
          status: undefined,
          address: [],
        });
        setIsLoading(false);
      }
    },
    [
      companyNumber,
      companyToSearch,
      countryToSearch,
      getMethod,
      query.page,
      setQuery,
      stateToSearch,
      workflowId,
    ]
  );

  const handleSubmit = useCallback(() => {
    loadCompanies(1);
  }, [loadCompanies]);

  const onChangeTab = (tab) => {
    setQuery({ tab });
  };

  const setFilter = useCallback(
    (filterName, event) => {
      let queryParams = {
        [filterName]: event && !_.isEmpty(event) ? event : undefined,
      };

      if (
        (filterName === 'createdDate' || filterName === 'updatedDate') &&
        Array.isArray(event)
      ) {
        const startDate = event[0]
          .utcOffset(0)
          .set({ second: 0, millisecond: 0 })
          .valueOf();
        const endDate = event[1]
          .utcOffset(0)
          .set({ second: 59, millisecond: 0 })
          .valueOf();

        queryParams = {
          [filterName]: `custom:${startDate}-${endDate}`,
        };
      }

      setQuery(queryParams);
    },
    [setQuery]
  );

  useEffect(() => {
    getCompanyWorkflow();
  }, [getCompanyWorkflow]);

  const companiesFiltered = useMemo(() => {
    const isThereAnyFilter =
      !!query?.status || !!query?.source || !!query?.address?.length > 0;

    if (!isThereAnyFilter) return companies;

    const filteredList = [];

    const sources = {
      creditReport: 'creditReport',
      businessIdentity: 'businessIdentity',
    };

    const inactiveStatusAllowed = ['inactive', 'nonactive'];

    companies?.forEach((element) => {
      let shouldInclude = true;
      const elementStatus = element?.company?.status?.toLowerCase();
      const elementAddress = element?.company?.address?.toLowerCase();
      const isElementCreditReport = !!element?.availableServices?.creditReportId;
      const isElementBusinessIdentity = !!element?.availableServices?.businessIdentityId;

      if (query?.source) {
        if (query?.source === sources.creditReport) {
          shouldInclude = isElementCreditReport;
        } else if (query?.source === sources.businessIdentity) {
          shouldInclude = isElementBusinessIdentity;
        }
      }

      if (query?.status) {
        if (
          query?.status === 'inactive' &&
          !inactiveStatusAllowed.includes(elementStatus)
        ) {
          shouldInclude = false;
        } else if (query?.status === 'active' && elementStatus !== 'active') {
          shouldInclude = false;
        }
      }

      if (query?.address && query?.address.length > 0) {
        let matchFound = false;

        query?.address?.forEach((address) => {
          const addressLowerCase = address?.toLowerCase();
          if (elementAddress?.includes(addressLowerCase)) {
            matchFound = true;
          }
        });

        if (!matchFound) {
          shouldInclude = false;
        }
      }

      if (shouldInclude) {
        filteredList.push(element);
      }
    });

    return filteredList;
  }, [query, companies]);

  const resetFilters = useCallback(() => {
    setQuery({
      page: 1,
      source: undefined,
      status: undefined,
      address: [],
      byStatus: undefined,
      updatedDate: undefined,
      createdDate: undefined,
    });
  }, [setQuery]);

  useEffect(() => {
    resetFilters();
  }, [query.tab]);

  return (
    <Wrapper>
      <div className="wrapper-page">
        {ConfirmActionModal}
        <div className="wrapper-page-1">
          <Row className="card-content">
            <div className="company-tabs-wrapper">
              <Tabs
                defaultActiveKey="companySearch"
                activeKey={query?.tab}
                onChange={onChangeTab}
                destroyInactiveTabPane
              >
                <Tabs.TabPane
                  tab={translate(`${I18N_BASE_PATH}.title`, 'Company search')}
                  key="companySearch"
                >
                  <FilterCompanies
                    setCompanyNumber={setCompanyNumber}
                    companyNumber={companyNumber}
                    companyToSearch={companyToSearch}
                    countryToSearch={countryToSearch}
                    setCompanyToSearch={setCompanyToSearch}
                    setCountryToSearch={setCountryToSearch}
                    setStateToSearch={setStateToSearch}
                    stateToSearch={stateToSearch}
                    handleSubmit={handleSubmit}
                    handleClear={handleClear}
                    setWorkflowToSearch={setWorkflowId}
                    workflowToSearch={workflowId}
                    workflows={workflows}
                    isLoading={isLoading}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={translate(`${I18N_BASE_PATH_CONSULTED_COMPANY}.title`, 'History')}
                  key="history"
                >
                  <ConsultedCompanies setDisabledFilters={setDisabledFilters} />
                </Tabs.TabPane>
              </Tabs>
            </div>
          </Row>

          {query.tab === 'companySearch' && (
            <>
              {companies?.length > 0 && !isLoading && (
                <ListCompanies
                  companies={companiesFiltered}
                  totalItems={companiesFiltered?.length}
                  pageSize={PAGE_SIZE}
                  handleClickResult={({ businessIdentityId, creditReportId }) => {
                    openModal({ businessIdentityId, creditReportId });
                  }}
                  setQuery={setQuery}
                  query={query}
                />
              )}
              {!isLoading && !companies?.length && (
                <NoResults
                  variant={companies === null ? 'search' : 'search.fallback'}
                  showShadow
                />
              )}
            </>
          )}
        </div>
        {query.tab === 'history' && (
          <CardWrapper hasPadding className="wrapper-page-2">
            <Filter
              isLoading={disabledFilters}
              query={query}
              resetFilters={resetFilters}
              setFilter={setFilter}
              translate={translate}
            />
          </CardWrapper>
        )}
      </div>
    </Wrapper>
  );
};

export default CompanySearching;
