import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Divider, Button, Collapse, Col } from 'antd';

import EmptyExecutionState from 'components/EmptyExecutionState';

import { toMaskedCnpj } from 'utils/formatters';
import { labelStatus } from 'utils/handlerStatus';

import DottedLabel from 'components/DottedLabel';

import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import useSubsidiariesInfoModal from './SubsidiariesInfoModal';

import DataSourceCardCollapsePanel from '../../../../../DataSourceCardCollapsePanel';

import './styles.less';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.companies.components.spcReportParticipationScore.registrationInfos';

const { Panel } = Collapse;

const RegistrationInfosContent = ({ source = {}, toPrint }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate, i18nFormatCurrency } = useI18nFormatters();
  const { openModal, SubsidiariesInfoModalContent } = useSubsidiariesInfoModal();
  const [isPanelOpened, setIsPanelOpened] = useState(false);

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const legalNatureFormatted = useMemo(() => {
    const { code, description } = parsedData?.customer?.legalNature || {};
    let legalNature = '';

    if (code) legalNature = code;
    if (code && description) legalNature = legalNature.concat(' - ');
    if (description) legalNature = legalNature.concat(description);

    return legalNature;
  }, [parsedData]);

  const formattedCnpjSituation = useMemo(() => {
    const description = parsedData?.customer?.cnpjSituationDescription;
    const date = parsedData?.customer?.cnpjSituationDate;

    if (description && date) {
      return `${description} (${i18nFormatDate(date, 1)})`;
    }
    if (description) {
      return `${description}`;
    }

    return '-';
  }, [parsedData, i18nFormatDate]);

  const secondaryActivitiesFormatted = useMemo(() => {
    const secondaryActivities = parsedData?.customer?.secondaryEconomicActivities || [];

    const filtered = secondaryActivities.filter(
      (activity) =>
        activity.code !==
          translate(
            'pages.private.executions.components.dataSourceCards.basicInfos.components.company.registrationInfosContent.secondaryActivitiesFormatted.activityCode'
          ) && activity.code !== '********'
    );

    return filtered;
  }, [parsedData, translate]);

  const onClickHandler = useCallback(() => {
    setIsPanelOpened((oldState) => !oldState);
  }, []);

  return (
    <>
      {SubsidiariesInfoModalContent}
      <DataSourceCardCollapsePanel
        icon="caf-ic_file"
        title={translate(`${I18N_BASE_PATH}.index.title`)}
        customId="registration-infos"
        toPrint={toPrint}
      >
        {queryWorked ? (
          <Row className="pdd-top-15">
            <ColumnTitleValue
              span={24}
              title={translate(`${I18N_BASE_PATH}.index.columns.officialName`)}
              value={parsedData?.customer?.corporateName || '-'}
            />

            <ColumnTitleValue
              className="mrg-top-15"
              span={24}
              title={translate(`${I18N_BASE_PATH}.index.columns.fantasyName`)}
              value={parsedData?.customer?.commercialName || '-'}
            />

            <ColumnTitleValue
              className="mrg-top-15"
              span={12}
              title={translate(`${I18N_BASE_PATH}.index.columns.stateRegistration`)}
              value={parsedData?.customer?.stateRegistration || '-'}
            />

            <ColumnTitleValue
              className="mrg-top-15"
              span={12}
              title={translate(`${I18N_BASE_PATH}.index.columns.nirenirc`)}
              value={parsedData?.customer?.nirenirc || '-'}
            />
            <Divider className="custom-divider" />

            <ColumnTitleValue
              span={12}
              title={translate(`${I18N_BASE_PATH}.index.columns.cnpj`)}
              value={toMaskedCnpj(parsedData?.customer?.cnpj) || '-'}
            />

            <ColumnTitleValue
              span={12}
              title={translate(`${I18N_BASE_PATH}.index.columns.situation`)}
              value={
                <DottedLabel
                  description={formattedCnpjSituation || '-'}
                  type={
                    parsedData?.customer &&
                    labelStatus(parsedData?.customer?.cnpjSituationDescription).type
                  }
                />
              }
              className="situation-col"
            />

            <ColumnTitleValue
              className="mrg-top-15"
              span={12}
              title={translate(`${I18N_BASE_PATH}.index.columns.foundationDate`)}
              value={i18nFormatDate(parsedData?.customer?.foundationDate, 1) || '-'}
            />

            <ColumnTitleValue
              className="mrg-top-15"
              span={12}
              title={translate(`${I18N_BASE_PATH}.index.columns.lastRegister`)}
              value={`${
                i18nFormatDate(parsedData?.customer?.lastRegisterDate, 1) || '-'
              } - ${parsedData?.customer?.lastRegisterNumber}`}
            />

            <Divider className="custom-divider" />

            <ColumnTitleValue
              span={12}
              title={translate(`${I18N_BASE_PATH}.index.columns.legalNature`)}
              value={legalNatureFormatted || '-'}
            />

            <Divider className="custom-divider" />

            <ColumnTitleValue
              span={24}
              title={translate(`${I18N_BASE_PATH}.index.columns.mainActivity`)}
              value={parsedData?.customer?.mainEconomicActivity?.description || '-'}
            />

            <ColumnTitleValue
              span={6}
              title={`${translate(`${I18N_BASE_PATH}.index.columns.purchase`)} (%):`}
              value={parsedData?.companyActivity?.purchasePercentage || '-'}
            />
            <ColumnTitleValue
              span={6}
              title={`${translate(`${I18N_BASE_PATH}.index.columns.sale`)} (%):`}
              value={parsedData?.companyActivity?.salesPercentage || '-'}
            />
            <ColumnTitleValue
              span={6}
              title={`${translate(`${I18N_BASE_PATH}.index.columns.employees`)}:`}
              value={parsedData?.companyActivity?.employeesQuantity || '-'}
            />
            <ColumnTitleValue
              span={6}
              title={`${translate(`${I18N_BASE_PATH}.index.columns.subsidiaries`)}:`}
              value={
                parsedData?.companyActivity?.subsidiariesQuantity ? (
                  <Button
                    className="open-subsidiaries-modal-button"
                    type="link"
                    ghost
                    onClick={openModal}
                  >
                    {parsedData?.companyActivity?.subsidiariesQuantity} ({' '}
                    {translate(`${I18N_BASE_PATH}.index.columns.seeMore`)} )
                  </Button>
                ) : (
                  '-'
                )
              }
            />

            {toPrint && (
              <ColumnTitleValue
                span={24}
                title={translate(
                  `${I18N_BASE_PATH}.index.columns.secondaryActivitiesTitle`
                )}
              >
                {secondaryActivitiesFormatted.length > 0 && (
                  <>
                    {secondaryActivitiesFormatted?.map((activity) => (
                      <span key={activity?.code} className="custom-value">
                        {activity?.code} - {activity.description}
                      </span>
                    ))}
                  </>
                )}

                {!secondaryActivitiesFormatted.length > 0 && (
                  <span className="value">-</span>
                )}
              </ColumnTitleValue>
            )}

            {!toPrint && secondaryActivitiesFormatted.length > 0 && (
              <Col span={24}>
                <Button type="link" className="no-mrg no-pdd" onClick={onClickHandler}>
                  <i
                    className={`caf-ic_${
                      isPanelOpened ? 'arrow_up' : 'arrow_down'
                    } mrg-right-5`}
                  />
                  {translate(`${I18N_BASE_PATH}.index.columns.secondaryActivitiesButton`)}
                </Button>

                <Collapse
                  activeKey={Number(isPanelOpened)}
                  className="ghost-collapse"
                  ghost
                >
                  <Panel key={1}>
                    <ul>
                      {secondaryActivitiesFormatted?.map((activity) => (
                        <li key={activity?.code} className="text-dark">
                          {activity?.code} - {activity.description}
                        </li>
                      ))}
                    </ul>
                  </Panel>
                </Collapse>
              </Col>
            )}

            <Divider className="custom-divider" />

            <ColumnTitleValue
              span={8}
              title={`${translate(`${I18N_BASE_PATH}.index.columns.capitalSocial`)}:`}
              value={
                i18nFormatCurrency(parsedData?.shareCapital?.capitalValue, 'pt-BR') || '-'
              }
            />
            <ColumnTitleValue
              span={8}
              title={`${translate(`${I18N_BASE_PATH}.index.columns.authorized`)}:`}
              value={parsedData?.shareCapital?.authorizedCapitalAmount || '-'}
            />
            <ColumnTitleValue
              span={8}
              title={`${translate(`${I18N_BASE_PATH}.index.columns.realized`)}:`}
              value={parsedData?.shareCapital?.realizedCapitalAmount || '-'}
            />

            <ColumnTitleValue
              className="mrg-top-15"
              span={8}
              title={`${translate(`${I18N_BASE_PATH}.index.columns.nationality`)}:`}
              value={parsedData?.shareCapital?.nationality || '-'}
            />
            <ColumnTitleValue
              className="mrg-top-15"
              span={8}
              title={`${translate(`${I18N_BASE_PATH}.index.columns.nature`)}:`}
              value={parsedData?.shareCapital?.nature || '-'}
            />
            <ColumnTitleValue
              className="mrg-top-15"
              span={8}
              title={`${translate(`${I18N_BASE_PATH}.index.columns.origin`)}:`}
              value={parsedData?.shareCapital?.origin || '-'}
            />

            <ColumnTitleValue
              className="mrg-top-15"
              span={8}
              title={`${translate(`${I18N_BASE_PATH}.index.columns.lastUpdate`)}:`}
              value={i18nFormatDate(parsedData?.shareCapital?.lastUpdate, 1) || '-'}
            />
          </Row>
        ) : (
          <EmptyExecutionState
            statusCode={statusCode}
            message={source?.message || parsedData?.message}
          />
        )}
      </DataSourceCardCollapsePanel>
    </>
  );
};

RegistrationInfosContent.propTypes = {
  toPrint: PropTypes.bool,
  source: PropTypes.objectOf(),
};

RegistrationInfosContent.defaultProps = {
  toPrint: false,
  source: null,
};

export default RegistrationInfosContent;
