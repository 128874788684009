/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyState from 'components/EmptyState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';

const RAIS = ({ profile, toPrint /* urlId */ }) => {
  const { t: translate } = useTranslation();

  const rais = useMemo(() => profile?.work?.rais, [profile]);

  const handlerQuantity = useMemo(() => {
    if (!rais) return null;

    if (!rais?.items || !rais?.items?.length > 0)
      return translate(
        'pages.private.profiles.components.dataSourceCards.laborInformation.people.components.rais.index.handlerQuantity.theresNoStatement'
      );

    if (rais?.items?.length === 1)
      return translate(
        'pages.private.profiles.components.dataSourceCards.laborInformation.people.components.rais.index.handlerQuantity.oneStatement'
      );

    return translate(
      'pages.private.profiles.components.dataSourceCards.laborInformation.people.components.rais.index.handlerQuantity.moreThanOneStatement',
      { value: rais?.items?.length }
    );
  }, [rais, translate]);

  const isProcessing = useMemo(() => verifySourcesProcessing(profile, 'rais'), [profile]);

  return (
    <ColumnLabel
      title={translate(
        'pages.private.profiles.components.dataSourceCards.laborInformation.people.components.rais.index.rais.columnLabel.title'
      )}
      description={handlerQuantity}
      type="empty"
      info={
        !toPrint &&
        translate(
          'pages.private.profiles.components.dataSourceCards.laborInformation.people.components.rais.index.rais.columnLabel.info'
        )
      }
    >
      {!rais && isProcessing && <EmptyState type="processing" />}

      {!rais && !isProcessing && <EmptyState type="waiting_consult" />}

      {rais && !rais?.items?.length > 0 && (
        <span>
          {translate(
            'pages.private.profiles.components.dataSourceCards.laborInformation.people.components.rais.index.rais.theresNoCertificateOfRelativeDebts'
          )}
        </span>
      )}
    </ColumnLabel>
  );
};

export default RAIS;
