export const requiredDataUFAntecedentes = {
  rg: '1234567890',
  cpf: '12345678910',
  name: '',
  birthDate: 'DD/MM/AAAA',
  motherName: '',
  rgIssuingAuthority: '',
  rgIssueState: 'UF',
  fatherName: '',
  issueDate: 'DD/MM/AAAA',
};

export const requiredDataUFDetran = {
  rg: '1234567890',
  cpf: '12345678910',
  name: '',
  birthDate: 'DD/MM/AAAA',
  cnhPlaceOfIssue: 'UF',
  cnhRegistrationNumber: '',
  cnhMirrorNumber: '',
  motherName: '',
  cnhRenachNumber: '',
  cnhExpirationDate: 'DD/MM/AAAA',
};
