import React, { useEffect, useState } from 'react';
import { Row } from 'antd';
import NoResults from 'components/NoResults';
import {
  RecordListHeader,
  RecordCollapse,
  RecordTitle,
} from '../../../../CompanyDetailsV2/components/TabsComponent/Records/components/RecordsList';
import RecordDetails from '../../../../CompanyDetailsV2/components/TabsComponent/Records/components/RecordDetails';
import useSavedRecords from '../../TabsComponent/Records/components/RecordsList/hooks/useSavedRecords';
import useCompanyRecords from '../../TabsComponent/Records/components/RecordsList/hooks/useCompanyRecords';

const RecordsPrint = ({
  data,
  companyName,
  transactionId,
  itemsToExport,
  setLoading,
}) => {
  const { loadSavedRecords, savedRecords, loadingSavedRecords } = useSavedRecords({
    transactionId,
  });

  const { records, getSourceById } = useCompanyRecords({
    data,
    savedRecords,
  });

  const [alreadyLoadSavedRecords, setAlreadyLoadSavedRecords] = useState(false);

  useEffect(() => {
    if (itemsToExport.items.includeRecordDetails.value && !alreadyLoadSavedRecords) {
      loadSavedRecords();
      setAlreadyLoadSavedRecords(true);
    }
  }, [itemsToExport.items.includeRecordDetails.value]);

  useEffect(() => {
    setLoading(loadingSavedRecords);
  }, [loadingSavedRecords]);

  if (!itemsToExport.value) return null;

  return (
    <div className="mrg-top-30">
      <RecordListHeader print />
      {records.length <= 0 && (
        <Row>
          <NoResults />
        </Row>
      )}
      {records.map((record) => (
        <div>
          <RecordCollapse record={record} print />
          {record.saved && itemsToExport.items.includeRecordDetails.value && (
            <RecordDetails
              transactionId={transactionId}
              goToList={() => {}}
              record={{
                ...record.saved,
                source: getSourceById(record.saved.sourceId),
              }}
              companyName={companyName}
              pageSize={100}
              collapseOpened
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default RecordsPrint;
