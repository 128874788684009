import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import defaultImage from 'assets/images/default-image.png';
import ZoomedImage from 'components/Image/ZoomedImage';

import './styles.less';

const ValidationsImagesCard = ({
  description,
  firstImageDescription,
  firstImage,
  secondImageDescription,
  secondImage,
  customTop,
  maxColumnWidth,
}) => {
  return (
    <>
      {description && (
        <Row className="custom-validations-title mrg-top-25 mrg-btm-15 reset-mrg">
          <strong>{description}</strong>
        </Row>
      )}

      <Row
        id="validations-images-card"
        style={{ marginTop: customTop }}
        align="middle"
        justify="space-between"
        gutter={[16, 16]}
      >
        <Col
          style={{ maxWidth: maxColumnWidth || 318, width: '50%' }}
          span={12}
          className="validation-content-image-container "
        >
          <ZoomedImage
            label={firstImageDescription}
            className={!firstImage ? 'grayscale-95' : ''}
            src={firstImage || defaultImage}
            alt={firstImageDescription}
          />
        </Col>
        <Col
          style={{ maxWidth: maxColumnWidth || 318, width: '50%' }}
          span={12}
          className="validation-content-image-container "
        >
          <ZoomedImage
            label={secondImageDescription}
            className={!secondImage ? 'grayscale-95' : ''}
            src={secondImage || defaultImage}
            alt={secondImageDescription}
          />
        </Col>
      </Row>
    </>
  );
};

ValidationsImagesCard.propTypes = {
  description: PropTypes.string,
  firstImageDescription: PropTypes.string.isRequired,
  firstImage: PropTypes.string,
  secondImageDescription: PropTypes.string.isRequired,
  secondImage: PropTypes.string,
  maxColumnWidth: PropTypes.number,
  customTop: PropTypes.string.isRequired,
};

ValidationsImagesCard.defaultProps = {
  description: '',
  firstImage: '',
  secondImage: '',
  maxColumnWidth: 318,
};

export default ValidationsImagesCard;
