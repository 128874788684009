import React, { useState, useMemo, useCallback } from 'react';
import { Modal } from 'antd';
import ReactJsonViewCompare from 'react-json-view-compare';

const ModalJsonView = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalDataToCompare, setModalDataToCompare] = useState({});

  const closeModalJsonView = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModalJsonView = useCallback(({ data, dataToCompare }) => {
    if (data) {
      setModalData(JSON.parse(data));
      if (dataToCompare) {
        setModalDataToCompare(JSON.parse(dataToCompare));
      } else {
        setModalDataToCompare(JSON.parse(data));
      }
    }
    setModalVisible(true);
  }, []);

  const content = useMemo(() => {
    if (!modalData) return <></>;
    return <ReactJsonViewCompare oldData={modalData} newData={modalDataToCompare} />;
  }, [modalData, modalDataToCompare]);

  const ModalComponent = useMemo(() => {
    return (
      <Modal
        width={1000}
        visible={modalVisible}
        onCancel={closeModalJsonView}
        footer={null}
      >
        {content}
      </Modal>
    );
  }, [modalVisible, closeModalJsonView, content]);

  return { openModalJsonView, closeModalJsonView, ModalComponent };
};

export default ModalJsonView;
