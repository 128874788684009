/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import EmptyMessage from 'components/EmptyMessage';

import filterValidationsByServices from 'utils/filterValidationsByServices';
import { generalStatus } from 'utils/handlerStatus';

import './styles.less';

const ValidationRulesStepCard = ({ profileTemplate, formRef, modalType, services }) => {
  const { t: translate } = useTranslation();

  const servicesSelectedToRevalidate = useMemo(() => {
    if (formRef?.getFieldValue()?.backgroundCheckingServices)
      return Object.values(formRef.getFieldValue().backgroundCheckingServices);
    return [];
  }, [formRef]);

  const allValidationsServicesRequired = useMemo(() => {
    let validations;

    if (modalType === 'manual-query') {
      validations = filterValidationsByServices(
        profileTemplate?.backgroundChecking?.validations,
        servicesSelectedToRevalidate
      );
    }

    if (modalType === 'manual-upload-documents') {
      validations = filterValidationsByServices(
        profileTemplate?.onboarding?.validations,
        services
      );
    }

    return validations;
  }, [modalType, profileTemplate, servicesSelectedToRevalidate, services]);

  return (
    <div id="validation-rules-step-card-container">
      {!allValidationsServicesRequired || !allValidationsServicesRequired?.length > 0 ? (
        <EmptyMessage
          type="empty"
          description={translate(
            `pages.private.profiles.components.validationRulesStepCard.index.validationRulesStepCard.emptyMessage.description.${
              modalType === 'manual-query' ? 'manualQuery' : 'resendDocuments'
            }`
          )}
          withCard
        />
      ) : (
        <>
          <div className="flex mrg-btm-15 mrg-horizon-15 font-size-14 cols">
            <section>
              <span>
                {translate(
                  'pages.private.profiles.components.validationRulesStepCard.index.validationRulesStepCard.cols.consult'
                )}
              </span>
            </section>
            <section className="flex center">
              <span>
                {translate(
                  'pages.private.profiles.components.validationRulesStepCard.index.validationRulesStepCard.cols.rule'
                )}
              </span>
            </section>
          </div>
          <section className="validation-rules-list">
            {allValidationsServicesRequired?.map((validationService) => (
              <div key={validationService?.rule} className="flex">
                <span className="flex-1">{validationService?.title}</span>
                <div className="status-action">
                  <section className="max-lines-2">
                    <i
                      style={{
                        color: generalStatus(validationService?.actionWhenInvalid)?.color,
                      }}
                      className={`${
                        generalStatus(validationService?.actionWhenInvalid)?.icon
                      } mrg-right-10 font-size-18`}
                    />
                    {validationService?.customActionWhenInvalid
                      ? validationService?.customActionWhenInvalid
                      : validationService?.actionWhenInvalid === 'REPROVE'
                      ? translate(
                          'pages.private.profiles.components.validationRulesStepCard.index.validationRulesStepCard.actionWhenInvalid.reprove'
                        )
                      : validationService?.actionWhenInvalid === 'PENDING'
                      ? translate(
                          'pages.private.profiles.components.validationRulesStepCard.index.validationRulesStepCard.actionWhenInvalid.pending'
                        )
                      : translate(
                          'pages.private.profiles.components.validationRulesStepCard.index.validationRulesStepCard.actionWhenInvalid.pendingSubmission'
                        )}
                  </section>
                </div>
              </div>
            ))}
          </section>
        </>
      )}
    </div>
  );
};

ValidationRulesStepCard.propTypes = {
  profileTemplate: PropTypes.arrayOf(
    PropTypes.shape({
      backgroundChecking: PropTypes.shape({
        validations: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    })
  ).isRequired,
  formRef: PropTypes.node,
  modalType: PropTypes.string.isRequired,
  services: PropTypes.arrayOf(PropTypes.shape({})),
};

ValidationRulesStepCard.defaultProps = {
  formRef: undefined,
  services: undefined,
};

export default ValidationRulesStepCard;
