import React, { useCallback } from 'react';
import { Col, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import classNames from 'classnames';
import EmptyMessage from 'components/EmptyMessage';

import { useTranslation } from 'react-i18next';
import { Tooltip as TooltipIcon } from '@combateafraude/icons/general';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.dashboard.index';

export const CardSkeleton = ({
  size,
  title,
  children,
  headerEndAdornment,
  loading,
  noData,
  tooltipMessage,
  tooltipPlacement,
  fixedHeight,
}) => {
  const mapperWidth = {
    smallSize: '32%',
    halfSize: '66.333%',
    fullSize: '100%',
  };

  const { t: translate } = useTranslation();

  const renderChildren = useCallback(() => {
    if (loading) {
      return <Loader size="60px" strokeWidth={4} />;
    }

    if (noData) {
      return (
        <EmptyMessage
          type="chart"
          description={translate(`${I18N_BASE_PATH}.charts.emptyMessage`)}
        />
      );
    }

    return children;
  }, [children, loading, noData, translate]);

  return (
    <Col
      className={classNames('card-skeleton', {
        'no-data': noData,
      })}
      style={{
        width: mapperWidth[size],
        height: fixedHeight ? 335 : undefined,
      }}
    >
      <div className="heading-skeleton">
        <div className="card-info-skeleton">
          <h2 className="title">{title}</h2>
          {tooltipMessage && (
            <CardTooltip message={tooltipMessage} placement={tooltipPlacement} />
          )}
        </div>
        {headerEndAdornment}
      </div>
      <div className="card-children">{renderChildren()}</div>
    </Col>
  );
};

export const CardTooltip = ({ message, placement }) => (
  <div className="tooltip">
    <Tooltip
      title={
        <p
          className="tooltip-message"
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
      }
      placement={placement}
      autoAdjustOverflow
      arrowPointAtCenter
      trigger="hover"
      overlayInnerStyle={{
        padding: '0.5rem',
      }}
    >
      <TooltipIcon />
    </Tooltip>
  </div>
);

CardSkeleton.propTypes = {
  size: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.elementType.isRequired,
  headerEndAdornment: PropTypes.element,
  loading: PropTypes.bool,
  tooltipMessage: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  noData: PropTypes.bool,
  fixedHeight: PropTypes.bool,
};

CardTooltip.propTypes = {
  message: PropTypes.string.isRequired,
  placement: PropTypes.string,
};

CardSkeleton.defaultProps = {
  size: 'smallSize',
  title: undefined,
  loading: false,
  headerEndAdornment: undefined,
  tooltipMessage: undefined,
  tooltipPlacement: 'top',
  noData: false,
  fixedHeight: true,
};

CardTooltip.defaultProps = {
  placement: 'top',
};
