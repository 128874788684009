import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import classNames from 'classnames';

const InformationBanner = ({ icon, bannerData, bannerType }) => {
  const { t: translate } = useTranslation();
  const informationBanner = useMemo(() => {
    if (bannerType === 'socket-operator' || bannerType === 'socket-operator-list') {
      return (
        <div
          id={bannerType === 'socket-operator' ? 'socket-banner' : 'socket-banner-list'}
          className={classNames({
            hideBanner: bannerData?.hideBanner,
          })}
        >
          <div className="content">
            {icon}
            {translate(
              'pages.private.executions.components.executionDetails.InformationBanner'
            )}
            <b>{bannerData?.operator?.name}</b>
          </div>
        </div>
      );
    }

    return <></>;
  }, [bannerData, bannerType, icon, translate]);

  return <>{informationBanner}</>;
};

InformationBanner.propTypes = {
  icon: PropTypes.node.isRequired,
  bannerType: PropTypes.string.isRequired,
  bannerData: PropTypes.shape({
    hideBanner: PropTypes.bool,
    operator: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};

InformationBanner.defaultProps = {
  bannerData: null,
};

export default InformationBanner;
