import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import EmptyState from 'components/EmptyState';
import ViewMore from 'components/ViewMore';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';
import * as pfDataSources from 'utils/pfDataSource';

import BasicInfosCard from '../../BasicInfosCard';
import useAllAddressesModal from './components/AllAddressesModal';

import './styles.less';

const AddressContent = ({ person = {}, dataSources, toPrint }) => {
  const { t: translate } = useTranslation();

  const addresses = useMemo(
    () =>
      person?.sources?.pfAddressesAlt?.data ||
      person?.addresses ||
      person?.sources?.pfAddresses?.data ||
      {},
    [person]
  );

  const addressesCount = useMemo(
    () => addresses?.addresses?.length || addresses?.items?.length || addresses.length,
    [addresses]
  );

  const { openModal: openAllAddressesModal, AllAddressesModal } = useAllAddressesModal({
    data: addresses?.addresses || addresses?.items || addresses,
  });

  const joinArray = useCallback((array) => {
    return array.filter((n) => n).join(', ');
  }, []);

  const addressFormatted = useMemo(() => {
    if (!addresses) return 'no-recurrence';
    if (addresses?.addresses?.length <= 0 && !addresses?.items && addresses.length <= 0) {
      return 'query-failed';
    }
    if (addresses && addressesCount > 0) {
      const {
        addressMain,
        street,
        number,
        complement,
        neighborhood,
        city,
        state,
        zipCode,
        type,
      } = addresses?.addresses?.[0] || addresses?.[0] || addresses?.items?.[0] || {};

      const address = addressMain || street;
      return (
        <>
          <section className="flex space-between address-information">
            <span>
              {address && `${address},`} {number && `${number},`}{' '}
              {complement && neighborhood ? `${complement} -` : `${complement || ''}`}{' '}
              {neighborhood || ''} <br />
              {city && state ? `${city} /` : `${city || ''}`} {state && `${state},`}{' '}
              {zipCode || ''}
            </span>
          </section>
        </>
      );
    }

    return 'no-data';
  }, [addressesCount, addresses, translate]);

  const src = useMemo(() => {
    let fullAddress;

    if (addresses && addressesCount > 0) {
      const {
        typology,
        addressMain,
        number,
        neighborhood,
        city,
        state,
        zipCode,
        street,
      } = addresses[0] || addresses?.addresses[0] || addresses?.items[0] || {};

      fullAddress = joinArray([
        `${typology} ${addressMain || street}`,
        number,
        zipCode,
        neighborhood,
        city,
        state,
      ]);
    }

    if (!fullAddress) return undefined;

    const encodedFullAddress = encodeURI(fullAddress);

    const url = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&q=${encodedFullAddress}`;

    return url;
  }, [joinArray, addressesCount, addresses]);

  const customShowDivider = useMemo(() => {
    // return dataSources.includes('pf_phones') || dataSources.includes('pf_emails');
    return pfDataSources.hasContactsInformation(person, dataSources);
  }, [dataSources, person]);

  const isProcessing = useMemo(() => verifySourcesProcessing(person, 'addresses'), [
    person,
  ]);

  return (
    <>
      {AllAddressesModal}
      <BasicInfosCard
        icon="caf-ic_map_pin"
        title={translate(
          'pages.private.profiles.components.tabs.components.basicInfos.components.person.addressContent.index.addressContent.basicInfosCard.title'
        )}
        data={{
          status: person?.status,
          addressFormatted: addresses,
        }}
        dataIndex="addressFormatted"
        customQuery={false}
        showDivider={customShowDivider || toPrint}
      >
        {addressFormatted === 'no-recurrence' && isProcessing && (
          <EmptyState type="processing" />
        )}

        {addressFormatted === 'no-recurrence' && !isProcessing && (
          <EmptyState type="waiting_consult" />
        )}

        {addressFormatted === 'no-data' && (
          <EmptyState
            type="failed"
            description={translate(
              'pages.private.profiles.components.tabs.components.basicInfos.components.person.addressContent.index.addressContent.emptyState.description.noData'
            )}
          />
        )}

        {(addressFormatted === 'query-failed' ||
          (!addressFormatted === 'no-data' &&
            !addressFormatted === 'no-recurrence' &&
            !src)) && (
          <EmptyState
            type="failed"
            description={translate(
              'pages.private.profiles.components.tabs.components.basicInfos.components.person.addressContent.index.addressContent.emptyState.description.queryFailed'
            )}
          />
        )}

        {addressFormatted &&
          addressFormatted !== 'no-recurrence' &&
          addressFormatted !== 'no-data' &&
          addressFormatted !== 'query-failed' &&
          src && (
            <Row>
              <Col span={24} className="address-formatted">
                {addressFormatted}
              </Col>

              {src && (
                <Col span={24}>
                  <iframe
                    title="map"
                    width="100%"
                    height="350"
                    src={src}
                    allowFullScreen
                    className="iframe-map"
                  />
                </Col>
              )}

              {!toPrint && addressesCount > 1 && (
                <Row className="pdd-left-15 mrg-top-20">
                  <ViewMore
                    title={translate(
                      'pages.private.profiles.components.tabs.components.basicInfos.components.person.addressContent.index.addressContent.viewMore.title',
                      { addressesCount }
                    )}
                    type="link"
                    onClick={openAllAddressesModal}
                  />
                </Row>
              )}
            </Row>
          )}
      </BasicInfosCard>
    </>
  );
};

AddressContent.propTypes = {
  person: PropTypes.shape({
    addresses: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    status: PropTypes.string,
  }),
  dataSources: PropTypes.arrayOf(PropTypes.string),
  toPrint: PropTypes.bool,
};

AddressContent.defaultProps = {
  person: undefined,
  dataSources: [],
  toPrint: false,
};

export default AddressContent;
