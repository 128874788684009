/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import EmptyState from 'components/EmptyState';
import ViewMore from 'components/ViewMore';

import DossierCard from 'pages/private/Profiles/components/DossierCard';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import MediaExposureCard from './MediaExposureCard';
import useAllMediaExposureModal from './AllMediaExposureModal';

const MediaExposure = ({ data, toPrint }) => {
  const { t: translate } = useTranslation();

  const mediaExposure = useMemo(() => data?.others?.mediaExposure, [data]);
  const mediaExposureItems = useMemo(() => mediaExposure?.items, [mediaExposure]);

  // const lastConsultation = useMemo(() => mediaExposure?.lastConsultation?.date, [
  //   mediaExposure,
  // ]);

  const isProcessing = useMemo(() => verifySourcesProcessing(data, 'mediaExposure'), [
    data,
  ]);

  // const urlId = useMemo(() => data?.recurrenceModel?._id, [data]);

  const { openModal, AllMediaExposureModal } = useAllMediaExposureModal({
    data: mediaExposure,
    lastConsultation: mediaExposure?.lastConsultation?.date,
  });

  return (
    <>
      {AllMediaExposureModal}

      <DossierCard
        customId="media-exposure-component"
        title={translate(
          'pages.private.profiles.components.dataSourceCards.mediaExposure.index.mediaExposure.dossierCard.title'
        )}
        subtitle={translate(
          'pages.private.profiles.components.dataSourceCards.mediaExposure.index.mediaExposure.dossierCard.subtitle'
        )}
        icon="caf-ic_news"
      >
        <div className="flex flex-column no-mrg no-pdd">
          {/* {!mediaExposure && (
            <EmptyState
              type="empty-recurrence"
              hiddenAction={toPrint}
              urlId={urlId}
              className="mrg-btm-20 mrg-top-0"
            />
          )} */}

          {!mediaExposure && isProcessing && (
            <EmptyState type="processing" className="mrg-btm-20 mrg-top-0" />
          )}

          {!mediaExposure && !isProcessing && (
            <EmptyState type="waiting_consult" className="mrg-btm-20 mrg-top-0" />
          )}

          {mediaExposure && !mediaExposureItems?.length > 0 && (
            <EmptyState
              type="empty-success"
              description={translate(
                'pages.private.profiles.components.dataSourceCards.mediaExposure.index.mediaExposure.mediaExposureItemsEmpty'
              )}
              className="mrg-btm-20 mrg-top-0"
            />
          )}

          <Row className="media-exposure-container reset-mrg">
            {mediaExposure &&
              mediaExposureItems?.length > 0 &&
              mediaExposureItems
                ?.slice(0, toPrint ? mediaExposureItems?.length : 4)
                ?.map((media) => <MediaExposureCard key={media?.url} data={media} />)}
          </Row>

          {!toPrint && mediaExposureItems?.length > 4 && (
            <Row className="mrg-top-20">
              <ViewMore
                title={`${translate(
                  'pages.private.profiles.components.dataSourceCards.mediaExposure.index.mediaExposure.viewMore.title'
                )} (${mediaExposureItems?.length})`}
                type="link"
                onClick={() => openModal()}
              />
            </Row>
          )}
        </div>
      </DossierCard>
    </>
  );
};

export default MediaExposure;
