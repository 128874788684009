import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';
import classNames from 'classnames';
import ChartNoData from 'assets/images/chart-no-data.svg';

import './styles.less';

const EmptyMessage = ({ description, type, show, withCard, children, ...rest }) => {
  const image = useMemo(() => {
    switch (type?.toLowerCase()) {
      case 'search':
        return <i className="caf-ic_search" />;
      case 'warning':
        return <i className="caf-ic_alert-triangle" />;
      case 'error':
        return <i className="caf-ic_circle_false text-danger" />;
      case 'image':
        return <i className="caf-ic_image" />;
      case 'compliance':
        return <i className="caf-ic_admin" />;
      case 'chart':
        return <img src={ChartNoData} alt="chart-no-data" />;
      case 'empty':
        return Empty.PRESENTED_IMAGE_SIMPLE;
      default:
        return Empty.PRESENTED_IMAGE_SIMPLE;
    }
  }, [type]);

  return (
    show && (
      <div
        id="empty-message-component"
        className={classNames({
          'with-card': withCard,
          'empty-search': type?.toLowerCase() === 'search',
          'empty-warning': type?.toLowerCase() === 'warning',
          'empty-error': type?.toLowerCase() === 'error',
        })}
      >
        <Empty description={description} image={image} {...rest}>
          {children}
        </Empty>
      </div>
    )
  );
};

EmptyMessage.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.oneOf(['search', 'warning', 'error', 'image', 'empty', 'compliance']),
  show: PropTypes.bool,
  withCard: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
};

EmptyMessage.defaultProps = {
  description: '',
  type: 'empty',
  show: true,
  withCard: false,
  children: null,
};

export default EmptyMessage;
