export const splitArrayInTwoParts = ({ array, splitInIndex }) => {
  const initialValue = [[], []];

  const callBack = (accumulator, _, index) => {
    const arrayBeforeSplit = accumulator[0];
    const arrayAfterSplit = accumulator[1];

    if (index <= splitInIndex) {
      arrayBeforeSplit.push(array[index]);
    } else {
      arrayAfterSplit.push(array[index]);
    }

    return [arrayBeforeSplit, arrayAfterSplit];
  };

  return array.reduce(
    (accumulator, _, index) => callBack(accumulator, _, index),
    initialValue
  );
};
