const I18N_BASE_PATH =
  'pages.private.profiles.components.dossierPrint.utils.checkboxOptions';

const peopleOptions = [
  {
    _id: 'registration_infos',
    label: `${I18N_BASE_PATH}.peopleOptions.registrationInfos`,
    icon: 'caf-ic_file',
    order: 1,
  },
  {
    _id: 'validations',
    label: `${I18N_BASE_PATH}.peopleOptions.validations`,
    icon: 'caf-ic_combateafraude',
    order: 2,
  },
  {
    _id: 'activities',
    label: `${I18N_BASE_PATH}.peopleOptions.activities`,
    icon: 'caf-ic_clock',
    order: 3,
  },
  {
    _id: 'user_data',
    label: `${I18N_BASE_PATH}.peopleOptions.userData`,
    icon: 'caf-ic_person',
    order: 4,
  },
  {
    _id: 'identity',
    label: `${I18N_BASE_PATH}.peopleOptions.identity`,
    icon: 'caf-ic_onboarding',
    order: 5,
  },
];

const companiesOptions = [
  {
    _id: 'registration_infos',
    label: `${I18N_BASE_PATH}.companiesOptions.registrationInfos`,
    icon: 'caf-ic_file',
    order: 1,
  },
  {
    _id: 'validations',
    label: `${I18N_BASE_PATH}.peopleOptions.validations`,
    icon: 'caf-ic_combateafraude',
    order: 2,
  },
  {
    _id: 'activities',
    label: `${I18N_BASE_PATH}.companiesOptions.activities`,
    icon: 'caf-ic_clock',
    order: 3,
  },
  {
    _id: 'user_data',
    label: `${I18N_BASE_PATH}.peopleOptions.userData`,
    icon: 'caf-ic_person',
    order: 4,
  },
];

export const checkboxOptions = (type) => {
  return type === 'PF' ? peopleOptions : companiesOptions;
};
