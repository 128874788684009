import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactJsonView from 'react-json-view';
import IconInputDataParameters from '../../../../../../../assets/images/company/input-data-parameters.svg';
import IconInputDataMetadata from '../../../../../../../assets/images/company/input-data-metadata.svg';
import DossierCard from '../../../../../Executions/components/DossierCard';

const I18N_BASE_PATH = 'pages.private.KYBCompany.pages.companyDetails.subPages.inputData';

const getType = (origin) => (origin === 'ONBOARDING_PJ' ? 'onboarding' : 'api');

const InputDataParameters = ({ origin, parameters }) => {
  const { t: translate } = useTranslation();
  const type = getType(origin);

  return (
    <DossierCard
      className="kyb-company-input kyb-company-input-parameters"
      title={translate(`${I18N_BASE_PATH}.${type}.parameters.title`, 'Parameters')}
      subtitle={translate(
        `${I18N_BASE_PATH}.${type}.parameters.subtitle`,
        'Raw data captured during the onboarding/system/API for the top sub heading'
      )}
      img={IconInputDataParameters}
    >
      <div className="json-view-wrapper">
        <ReactJsonView
          name={false}
          indentWidth={2}
          displayDataTypes={false}
          displayObjectSize={false}
          enableClipboard={false}
          src={parameters}
        />
      </div>
    </DossierCard>
  );
};

const InputDataMetadata = ({ origin, metadata }) => {
  const { t: translate } = useTranslation();
  const type = getType(origin);

  return (
    <DossierCard
      className="kyb-company-input kyb-company-input-metadata"
      title={translate(`${I18N_BASE_PATH}.${type}.metadata.title`, 'Response')}
      subtitle={translate(
        `${I18N_BASE_PATH}.${type}.metadata.subtitle`,
        'List of raw data sent by the browser during onboarding/system/API'
      )}
      img={IconInputDataMetadata}
    >
      <div className="json-view-wrapper">
        <ReactJsonView
          name={false}
          indentWidth={2}
          displayDataTypes={false}
          displayObjectSize={false}
          enableClipboard={false}
          src={metadata}
        />
      </div>
    </DossierCard>
  );
};

const InputData = ({ inputData }) => {
  return (
    <section className="kyb-company-input-data">
      <InputDataParameters
        origin={inputData?.metadata?.origin}
        parameters={inputData?.parameters}
      />
      <InputDataMetadata
        origin={inputData?.metadata?.origin}
        metadata={inputData?.metadata}
      />
    </section>
  );
};

export default InputData;
export { InputDataParameters, InputDataMetadata };
