import React from 'react';
import { Card, Typography, Row, Button, InputNumber } from 'antd';
import PropTypes from 'prop-types';

import './styles.less';
import classNames from 'classnames';

const { Title } = Typography;

const ConfigurationCard = ({
  title,
  icon,
  buttonText,
  handleSave,
  inputValue,
  onChange,
  loading,
}) => (
  <Card
    className={classNames('container-session-configuration')}
    style={{ marginTop: '30px' }}
  >
    <section className="configuration-section">
      <Row className="row-section-liveness">
        <Title level={5}>
          <img src={icon.src} alt={icon.alt} />
          {title}
        </Title>
      </Row>
      <InputNumber
        onChange={onChange}
        style={{ width: '5rem' }}
        value={inputValue}
        min={1}
        step={1}
        max={10}
      />
      <Button
        type="primary"
        style={{ marginLeft: '1rem' }}
        onClick={handleSave}
        loading={loading}
        disabled={loading}
      >
        {buttonText}
      </Button>
    </section>
  </Card>
);

ConfigurationCard.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.objectOf({ src: PropTypes.string, alt: PropTypes.string }),
  buttonText: PropTypes.string,
  handleSave: PropTypes.func,
  inputValue: PropTypes.number,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
};

ConfigurationCard.defaultProps = {
  title: undefined,
  icon: undefined,
  buttonText: 'Save',
  handleSave: () => {},
  inputValue: 1,
  onChange: () => {},
  loading: false,
};

export default ConfigurationCard;
