/* eslint-disable react/prop-types */
import React, { useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQueryParams, StringParam } from 'use-query-params';
import { Spin, Timeline } from 'antd';

import { useValidations } from 'hooks/validations';
import { usePerson } from 'hooks/person';

import { scrollToElement } from 'utils/scroll';

import CheckpointStatus from 'pages/private/Profiles/components/CheckpointStatus';
import CustomUploadButton from 'pages/private/Profiles/components/CustomUploadButton';
import ProfileWidget from 'pages/private/Profiles/components/ProfileWidget';
import useManualUploadDocuments from 'pages/private/Profiles/components/ManualUploadDocuments';

import PermissionWrapper from 'routes/PermissionWrapper';

const DocumentsWidget = () => {
  const { t: translate } = useTranslation();
  const { personId } = useParams();

  const { loadPerson, loadingPerson, person } = usePerson();
  const { validations, loadingValidations: loading } = useValidations();
  const { openModal, ManualUploadDocumentsModal } = useManualUploadDocuments();

  const [, setQuery] = useQueryParams({
    tab: StringParam,
  });

  const profileTemplate = useMemo(() => person?.profileTemplate, [person]);

  const profileTemplateHasOnboardingServices = useMemo(() => {
    return profileTemplate?.onboarding?.services?.length > 0;
  }, [profileTemplate]);

  const complianceRules = useMemo(() => {
    if (!validations) return undefined;

    const _validations = validations?.validations;

    if (!_validations?.length) return undefined;

    const complianceRulesData = _validations.filter(
      (v) => v.rule === 'valid_document' || v.rule === 'valid_identity'
    );

    return complianceRulesData;
  }, [validations]);

  const hasDocumentSend = useMemo(() => {
    const validStatus = ['VALID', 'INVALID', 'PROCESSING', 'PENDING'];

    const document = complianceRules?.filter((c) => c.rule === 'valid_document') || [];
    const documentStatus = document[0]?.status;

    return validStatus.includes(documentStatus);
  }, [complianceRules]);

  const customBtnContent = useMemo(() => {
    if (hasDocumentSend) {
      return (
        <span>
          {translate(
            'pages.private.profiles.people.components.documentsWidget.customBtnContent.hasDocumentSend.before'
          )}{' '}
          <strong>
            {translate(
              'pages.private.profiles.people.components.documentsWidget.customBtnContent.hasDocumentSend.after'
            )}
          </strong>
          .
        </span>
      );
    }

    return (
      <span>
        {translate(
          'pages.private.profiles.people.components.documentsWidget.customBtnContent.default.before'
        )}{' '}
        <strong>
          {translate(
            'pages.private.profiles.people.components.documentsWidget.customBtnContent.default.inside'
          )}
        </strong>{' '}
        {translate(
          'pages.private.profiles.people.components.documentsWidget.customBtnContent.default.after'
        )}
      </span>
    );
  }, [hasDocumentSend, translate]);

  const scrollToComponent = useCallback(
    (rule) => {
      setQuery({ tab: 'documents' });

      setTimeout(() => {
        scrollToElement(
          rule?.rule === 'valid_document'
            ? 'authentic-document-card'
            : 'verified-document-card'
        );
      }, 100);
    },
    [setQuery]
  );

  useEffect(() => {
    if (!loadPerson) return;

    loadPerson({ personId });
  }, [personId]); // eslint-disable-line

  return (
    <>
      {ManualUploadDocumentsModal}

      <ProfileWidget
        title={translate(
          'pages.private.profiles.people.components.documentsWidget.documentsWidget.profileWidget.title'
        )}
        subtitle={translate(
          'pages.private.profiles.people.components.documentsWidget.documentsWidget.profileWidget.subtitle'
        )}
        icon="caf-ic_onboarding"
      >
        <div className="identity-info">
          {(loading || loadingPerson) && (
            <div className="custom-loading-spin identity">
              <Spin />
            </div>
          )}

          {!loading && !loadingPerson && (
            <>
              <PermissionWrapper requiredPermissions={['people:update']}>
                {profileTemplateHasOnboardingServices ? (
                  <CustomUploadButton
                    onClick={() => openModal()}
                    customIcon={
                      hasDocumentSend ? 'caf-ic_processing_line' : 'caf-ic_upload'
                    }
                    customContent={customBtnContent}
                    customClass={hasDocumentSend && 'document-send-btn'}
                  />
                ) : (
                  <></>
                )}
              </PermissionWrapper>

              <Timeline className="checkpoint-status-container mrg-top-5">
                {complianceRules?.map((complianceRule) => (
                  <CheckpointStatus
                    key={complianceRule?.rule}
                    status={complianceRule?.status}
                    title={translate(complianceRule?.title)}
                    description={translate(complianceRule?.description, {
                      value: complianceRule.value,
                    })}
                    rule={complianceRule?.rule}
                    onClick={() => scrollToComponent(complianceRule)}
                  />
                ))}
              </Timeline>
            </>
          )}
        </div>
      </ProfileWidget>
    </>
  );
};

export default DocumentsWidget;
