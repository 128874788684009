import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Table } from 'antd';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';

import { useFetch } from 'services/hooks';

import { initialFilterData } from '../utils';

const useTable = ({
  rowKey,
  getParams,
  columns: propColumns,
  queryParams,
  customDataParser,
}) => {
  const { t: translate } = useTranslation();
  const { data, loading, get } = useFetch();

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    _order: StringParam,
    _sort: StringParam,
    ...queryParams,
  });

  const [customParserData, setCustomParserData] = useState([]);
  const [customParserLoading, setCustomParserLoading] = useState(false);

  const [columns, setColumns] = useState(propColumns || []);
  const [params, setParams] = useState(getParams);
  const [pagination, setPagination] = useState({
    current: query?.page || 1,
    pageSize: 20,
    total: 0,
    showTotal: (total, range) =>
      translate('components.list.hooks.useTable.pagination.showTotal', {
        rangeZero: range[0],
        rangeOne: range[1],
        total,
      }),
  });
  const [sortData, setSortData] = useState({
    _order: query?._order || '',
    _sort: query?._sort || '',
  });
  const [filterData, setFilterData] = useState(() =>
    initialFilterData({ query, queryParams })
  );

  const fetch = useCallback(
    async (current) => {
      if (!params) return;

      try {
        const pageSize = pagination.pageSize || 20;

        const res = await get({
          url: params.url,
          config: {
            params: {
              _limit: pageSize,
              _offset: pageSize * (current - 1),
              ...sortData,
              ...filterData,
              ...params.config.params,
            },
          },
        });

        setPagination((oldState) => ({
          ...oldState,
          current,
          pageSize,
          total: res.totalItems,
        }));

        if (customDataParser) {
          setCustomParserLoading(true);

          setCustomParserData(await customDataParser(res));

          setCustomParserLoading(false);
        }
      } catch (error) {
        setCustomParserData(null);
      }
    },
    [get, params, pagination.pageSize, sortData, filterData, customDataParser]
  );

  const refreshList = useCallback(() => {
    fetch(pagination.current || 1);
  }, [fetch, pagination]);

  const updateParams = useCallback((newParams) => {
    setParams(newParams);
  }, []);

  useEffect(() => {
    if (!data) return;

    fetch(pagination.current || 1);
    setQuery({ page: pagination.current });
  }, [pagination.current, sortData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (pagination?.current === 1 || !data) {
      refreshList();
      setQuery({ page: pagination?.current });
      return;
    }

    setPagination((state) => ({
      ...state,
      current: 1,
    }));
    setQuery({ page: 1 });
  }, [params, filterData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQuery({ ...filterData });
  }, [filterData, setQuery]);

  const onChange = useCallback(
    (_pagination, __, sorter) => {
      if (_pagination) {
        setPagination(_pagination);
      }

      const getSorterParams = (sort) => {
        if (!sort)
          return {
            _order: '',
            _sort: '',
          };

        const { columnKey } = sort;

        const result = {
          _order: '',
          _sort: columnKey || '',
        };

        if (sort.order === 'ascend') {
          result._order = '1';
        } else if (sort.order === 'descend') {
          result._order = '-1';
        }

        return result;
      };

      let newSortData = {};

      if (Array.isArray(sorter)) {
        const _order = [];
        const _sort = [];

        sorter.forEach((sorterItem) => {
          const result = getSorterParams(sorterItem);
          _order.push(result._order);
          _sort.push(result._sort);
        });

        newSortData = {
          _order: _order.join(';'),
          _sort: _sort.join(';'),
        };
      } else {
        newSortData = getSorterParams(sorter);
      }

      setSortData(newSortData);
      setQuery(
        newSortData._order ? newSortData : { _order: undefined, _sort: undefined }
      );
    },
    [setQuery]
  );

  const columnsFormatted = useMemo(() => {
    const _order = query?._order?.split(';');
    const _sort = query?._sort?.split(';');

    if (!_order) return [...columns];

    const joinedSortParams = _order.map((_o, i) => ({ _order: _o, _sort: _sort[i] }));

    const newColumns = columns.map((c) => {
      if (_sort.includes(c.key) || _sort.includes(c.dataIndex)) {
        const sp = joinedSortParams[_sort.indexOf(c.key || c.dataIndex)];

        return {
          ...c,
          defaultSortOrder: sp._order === '1' ? 'ascend' : 'descend',
        };
      }

      return c;
    });

    return [...newColumns];
  }, [query, columns]);

  const getDataSource = useMemo(() => {
    if (customDataParser) return customParserData;

    return data ? data.docs : [];
  }, [customDataParser, data, customParserData]);

  const tableContent = useMemo(
    () =>
      columnsFormatted && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={24}>
            <Table
              columns={[...columnsFormatted]}
              dataSource={getDataSource}
              loading={loading || customParserLoading}
              onChange={onChange}
              pagination={pagination}
              showSorterTooltip={false}
              rowKey={rowKey} // identificação única de cada row
            />
          </Col>
        </Row>
      ),
    [
      columnsFormatted,
      loading,
      customParserLoading,
      onChange,
      pagination,
      rowKey,
      getDataSource,
    ]
  );

  return {
    tableContent,
    setFilterData,
    refreshList,
    updateParams,
    setColumns,
    loading,
    loadingCustomData: customParserLoading,
    data,
  };
};

export default useTable;
