import React, { useEffect, useState } from 'react';
import { Typography, Button, Card, List, Tooltip, message } from 'antd';
import { useAuth } from 'hooks/auth';
import backgroud from 'assets/images/background_accounts.jpg';
import { useFetch } from 'services/hooks';
import handleHubSelection from 'utils/handleHubSelection';
import Cookies from 'js-cookie';
import './styles.less';
import { useTranslation } from 'react-i18next';
import { secondsToDay } from 'utils/formatters';

const { Title, Text } = Typography;
const { Meta } = Card;
const { Item } = List;

const isLocalhost = !!window.location.origin.startsWith('http://localhost');
const isCafIo = window.location.origin.indexOf('caf.io') !== -1;

const ACCESS_TOKEN = isLocalhost ? 'CAF_access_token' : '__Secure-CAF_access_token';

// eslint-disable-next-line no-nested-ternary
const COOKIE_OPTIONS = isLocalhost
  ? {
      path: '/',
    }
  : isCafIo
  ? {
      domain: '.caf.io',
      secure: true,
      sameSite: 'strict',
    }
  : {
      domain: '.combateafraude.com',
      secure: true,
      sameSite: 'strict',
    };

const Accounts = () => {
  const { user } = useAuth();
  const [selected, setSelected] = useState();
  const { post, loading } = useFetch();
  const { t: translate } = useTranslation();

  const handleCardClick = (tenantId) => {
    setSelected(tenantId);
  };

  useEffect(() => {
    if (!handleHubSelection.shouldHubSelect()) {
      window.location.href = '/';
    }
  }, []);

  const handleAcessarClick = async () => {
    try {
      const { expires_in, access_token } = await post({
        url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/token-hub-accounts`,
        payload: {
          username: user?.username,
          tenantId: selected,
        },
      });

      const ACCESS_TOKEN_OPTIONS = {
        ...COOKIE_OPTIONS,
        expires: secondsToDay(expires_in),
      };

      Cookies.set(ACCESS_TOKEN, access_token, ACCESS_TOKEN_OPTIONS);
      handleHubSelection.setHubSelected(true);
      window.location.href = '/';
    } catch {
      message.error(
        translate('components.modalUpdateProfile.handleUpdate.messages.error')
      );
    }
  };

  return (
    <>
      {handleHubSelection.shouldHubSelect() && (
        <div
          className="accounts-container"
          style={{
            backgroundImage: `url(${backgroud})`,
          }}
        >
          <div className="content-container">
            <>
              <Title className="title-container">
                {translate('pages.private.accounts.index.title')}
              </Title>
              <Text className="text-dark">
                <strong>E-mail:</strong> {user?.username}
              </Text>
            </>

            <div className="list-container">
              <List
                grid={{
                  gutter: [16, 0],
                  column: 3,
                }}
                dataSource={user?.hubData?.hubAccountsData}
                pagination={{
                  pageSize: 6,
                  position: 'bottom',
                  size: 'small',
                  hideOnSinglePage: true,
                }}
                renderItem={(account) => (
                  <Item>
                    <Tooltip title={account?.fantasyName || account?.companyName}>
                      <Card
                        className={selected === account.tenantId ? 'selected' : ''}
                        onClick={() => handleCardClick(account.tenantId)}
                        hoverable
                        bordered={selected === account.tenantId}
                      >
                        <Meta
                          title={
                            <Text
                              ellipsis
                              style={{ maxWidth: '150px', minWidth: '150px' }}
                              className="text-dark text-bold"
                            >
                              {account?.fantasyName || account?.companyName}
                            </Text>
                          }
                          description={
                            <Text>
                              <strong>TenantId:</strong> {account?.tenantId}
                            </Text>
                          }
                        />
                      </Card>
                    </Tooltip>
                  </Item>
                )}
              />
            </div>
            <div className="buttons-container">
              <Button
                type="primary"
                onClick={handleAcessarClick}
                disabled={!selected}
                loading={loading}
              >
                {translate('pages.private.accounts.index.button.access')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Accounts;
