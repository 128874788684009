import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import ApprovedWithWarningIcon from 'assets/images/approved-with-warning-icon.svg';

import './styles.less';

const translator = {
  REPROVADO: 'caf-ic_close',
  REPROVED: 'caf-ic_close',
  APPROVED: 'caf-ic_checkmark',
  APROVADO: 'caf-ic_checkmark',
  PENDENTE: 'caf-ic_hold',
  PENDING: 'caf-ic_hold',
  'PENDENTE OCR': 'caf-ic_hold',
  PROCESSING: 'caf-ic_processing_line',
  PROCESSANDO: 'caf-ic_processing_line',
  AGUARDANDO_DOCUMENTACAO: 'caf-ic_hold',
  WAITING_DOCUMENTS: 'caf-ic_hold',
  DOCUMENTSCOPY: 'caf-ic_arrow-up-right',
  DEFAULT: 'caf-ic_plus',
  APPROVED_WARNING_ICON: ApprovedWithWarningIcon,
};

const IconStatus = ({
  status,
  customIcon,
  customIconStatus,
  isApprovedWithWarning = false,
}) => {
  const customClasses = useMemo(() => {
    return customIcon
      ? 'icon-status-container custom-icon-status'
      : 'icon-status-container';
  }, [customIcon]);

  return (
    <div className={customClasses}>
      {isApprovedWithWarning ? (
        <img src={translator.APPROVED_WARNING_ICON} alt="" />
      ) : (
        <i
          className={`${customIconStatus} ${
            customIcon || translator[status] || translator.DEFAULT
          }`}
        />
      )}
    </div>
  );
};

IconStatus.propTypes = {
  status: PropTypes.string,
  customIcon: PropTypes.string,
  customIconStatus: PropTypes.string,
  isApprovedWithWarning: PropTypes.bool,
};

IconStatus.defaultProps = {
  status: 'DEFAULT',
  customIcon: undefined,
  customIconStatus: undefined,
  isApprovedWithWarning: false,
};

export default IconStatus;
