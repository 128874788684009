/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Row } from 'antd';

import ViewMore from 'components/ViewMore';
import ActivitiesListCard from 'pages/private/Profiles/components/ActivitiesListCard';

import useAllProtests from '../AllProtestsModal';

const ProtestsCard = ({ data, children }) => {
  const { t: translate } = useTranslation();

  const { companyId } = useParams();

  const { openModal, AllProtestsModal } = useAllProtests({
    data: [],
  });

  const hasContent = data?.items?.length > 0;

  return (
    <ActivitiesListCard
      title={translate(
        'pages.private.profiles.components.dataSourceCards.financialInformation.shared.protests.activitiesListProtests.index.activitiesListProtests.activitiesListCard.title'
      )}
      footer={children}
    >
      {AllProtestsModal}

      <Row className="reset-mrg">
        {hasContent && (
          <>
            <p className="font-size-15">
              {data?.length > 1
                ? translate(
                    'pages.private.profiles.components.dataSourceCards.financialInformation.shared.protests.activitiesListProtests.index.activitiesListProtests.protests.hasMoreThanOneProtest',
                    { value: data?.length }
                  )
                : translate(
                    'pages.private.profiles.components.dataSourceCards.financialInformation.shared.protests.activitiesListProtests.index.activitiesListProtests.protests.hasOneProtest'
                  )}
            </p>
            <Row className="gx-w-100">
              <ViewMore
                title={translate(
                  'pages.private.profiles.components.dataSourceCards.financialInformation.shared.protests.activitiesListProtests.index.activitiesListProtests.viewMore.title'
                )}
                type="link"
                onClick={() => openModal()}
              />
            </Row>
          </>
        )}

        {!hasContent && (
          <p className="font-size-15">
            {translate(
              `pages.private.profiles.components.dataSourceCards.financialInformation.shared.protests.activitiesListProtests.index.activitiesListProtests.hasNotContent.${
                companyId ? 'company' : 'person'
              }`
            )}
            .
          </p>
        )}
      </Row>
    </ActivitiesListCard>
  );
};

export default ProtestsCard;
