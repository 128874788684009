import React, { createContext, useCallback, useContext, useState } from 'react';

import { useFetch } from 'services/hooks';

const ReportsContext = createContext({});

const ReportsProvider = ({ children }) => {
  const { get } = useFetch();

  const [allReportsList, setAllReportsList] = useState([]);
  const [allReportsObject, setAllReportsObject] = useState({});

  const [loadingAllReports, setLoadingAllReports] = useState(false);

  const generateReportsObject = useCallback(async (array) => {
    const initialValue = {};
    const reportsObject = await array.reduce((obj, item) => {
      return {
        ...obj,
        [item._id]: item,
      };
    }, initialValue);
    reportsObject['000000000000000000000000'] = { name: '-' };
    await setAllReportsObject(reportsObject);
  }, []);

  const loadAllReports = useCallback(async () => {
    try {
      setLoadingAllReports(true);

      const response = await get({
        url: '/reports',
        config: {},
        showMessage: false,
      });

      setAllReportsList(response?.docs);
      await generateReportsObject(response?.docs);
      setLoadingAllReports(false);

      return Promise.resolve(response?.docs);
    } catch (error) {
      setLoadingAllReports(false);
      return Promise.reject(error);
    }
  }, [get, generateReportsObject]);
  return (
    <ReportsContext.Provider
      value={{
        allReportsList,
        allReportsObject,
        loadAllReports,
        loadingAllReports,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

function useReports() {
  const context = useContext(ReportsContext);
  if (!context) {
    throw new Error('useReports must be used within an ReportsContext');
  }

  return context;
}

export { ReportsProvider, useReports };
