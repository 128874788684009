/* eslint-disable no-restricted-syntax */
import { find } from 'lodash';

export function getFieldSharePercentage(payload) {
  const fieldRelationship = payload.relationships?.find(
    (rel) => rel.name === payload.field
  );
  if (!fieldRelationship) return null;
  const shareAttribute = fieldRelationship.data
    .reverse()
    .find((d) => Object.keys(d.attributes).includes('shares'));
  const shares = shareAttribute?.attributes?.shares || [];
  return shares.length ? shares[0].percentage : null;
}

function makeRootTree(id, name, entityType) {
  return {
    id,
    data: { name, entityType: entityType || 'company', root: true },
    children: [],
  };
}

function makeEntityTree(path) {
  return {
    id: path.entity.id,
    data: { name: path.entity.name, entityType: path.entity.entityType },
    children: [],
  };
}

function makeFieldTree(path, translate) {
  const text = translate(path.field);
  const sharePercentage = getFieldSharePercentage(path);
  const hint = sharePercentage ? `(${sharePercentage}%)` : '';
  return {
    id: path.field,
    data: { name: `${text} ${hint}` },
    options: { contentKey: path.field },
    children: [],
  };
}

const findNodeById = (nodes, id) => {
  return find(nodes, (node) => node.id === id);
};

const buildTree = (paths, parentNode, translate) => {
  if (!paths || paths.length === 0) return;
  const [first, ...rest] = paths;
  let node = findNodeById(parentNode.children, first.field);
  if (!node) {
    node = makeFieldTree(first, translate);
    parentNode.children.push(node);
  }
  let entityNode = findNodeById(node.children, first.entity.id);
  if (!entityNode) {
    entityNode = makeEntityTree(first);
    node.children.push(entityNode);
  }
  buildTree(rest, entityNode, translate);
};

export const makeTree = ({ id, name, entityType, data }, translate) => {
  const result = makeRootTree(id, name, entityType);
  for (const item of data) {
    buildTree(item.path, result, translate);
  }
  return result;
};
