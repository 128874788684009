import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, message, Form, Row, Spin, Alert } from 'antd';

import { useFetch } from 'services/hooks';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { usePerson } from 'hooks/person';
import { useActivities } from 'hooks/activities';
import { useProfileTemplate } from 'hooks/profileTemplate';

import Button from 'components/Button';

import PageTitle from 'components/PageTitle';

import QueryPrice from 'pages/private/Profiles/components/QueryPrice';

import useUploadDocumentUpload from './components/Steps/UploadDocuments';
import BackgroundCheckingServices from './components/Steps/BackgroundCheckingServices';

import ValidationRulesStepCard from '../ValidationRulesStepCard';

import './styles.less';

const ManualUploadDocuments = () => {
  const { t: translate } = useTranslation();
  const { i18nFormatCurrency } = useI18nFormatters();

  const { post, loading } = useFetch();
  const { person } = usePerson();
  const { refreshActivities } = useActivities();
  const {
    loadingGetProfileTemplate,
    cafServicesList,
    loadCafServicesList,
    loadingCafServicesList,
  } = useProfileTemplate();
  const [profileTemplate, setProfileTemplate] = useState(null);

  const [form] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);

  const [frontFile, setFrontFile] = useState();
  const [backFile, setBackFile] = useState();
  const [selfieFile, setSelfieFile] = useState();
  const [missingFrontFile, setMissingFrontFile] = useState(false);

  const [step, setStep] = useState(1);
  const [queryPrice, setQueryPrice] = useState(0);
  const [backgroundServicesFiltered, setBackgroundServicesFiltered] = useState([]);

  const closeModal = useCallback(() => {
    setFrontFile(undefined);
    setBackFile(undefined);
    setSelfieFile(undefined);
    setMissingFrontFile(false);
    form.resetFields();

    setBackgroundServicesFiltered([]);
    setModalVisible(false);
    setStep(1);
  }, [form]);

  const openModal = useCallback(() => {
    setModalVisible(true);
    loadCafServicesList();
  }, [loadCafServicesList]);

  const handleChangeFile = useCallback((fileField, value) => {
    if (fileField === 'front') {
      setFrontFile(value);
    }

    if (fileField === 'back') {
      setBackFile(value);
    }

    if (fileField === 'selfie') {
      setSelfieFile(value);
    }
  }, []);

  const { UploadDocumentUpload, documentType, hasSelfieImage } = useUploadDocumentUpload({
    isMissingFrontFile: missingFrontFile,
    handleChangeFile,
    frontFile,
    backFile,
    selfieFile,
  });

  useEffect(() => {
    if (person?.profileTemplate) {
      setProfileTemplate(person.profileTemplate);
    }
  }, [person, setProfileTemplate]);

  const customLoading = useMemo(() => {
    return loading || loadingGetProfileTemplate || loadingCafServicesList;
  }, [loading, loadingCafServicesList, loadingGetProfileTemplate]);

  const handleCancel = useCallback(() => {
    if (step === 1) return closeModal();

    if (step === 3) return setStep(2);

    return setStep(1);
  }, [closeModal, step]);

  const confirmButtonText = useMemo(
    () =>
      step === 1 || step === 2
        ? translate(
            'pages.private.profiles.components.manualUploadDocuments.index.handleCancel.continue'
          )
        : translate(
            'pages.private.profiles.components.manualUploadDocuments.index.handleCancel.sendDocuments'
          ),
    [step, translate]
  );

  const customPageTitlteSubtitle = useMemo(() => {
    if (step === 1) {
      return translate(
        'pages.private.profiles.components.manualUploadDocuments.index.customPageTitlteSubtitle.firstStep'
      );
    }

    if (step === 2) {
      return translate(
        'pages.private.profiles.components.manualUploadDocuments.index.customPageTitlteSubtitle.secondStep'
      );
    }

    return translate(
      'pages.private.profiles.components.manualUploadDocuments.index.customPageTitlteSubtitle.thirdStep'
    );
  }, [step, translate]);

  const onboardingServicesList = useMemo(
    () =>
      cafServicesList?.docs?.filter(
        ({ status, category }) => status !== 'DEPRECATED' && category === 'onboarding'
      ) || [],
    [cafServicesList]
  );

  const profileTemplateServicesFiltered = useMemo(() => {
    const profileTemplateServices = profileTemplate?.onboarding?.services;

    const onboardingServicesListFiltered = onboardingServicesList
      ?.map((service) => {
        const serviceSource = profileTemplateServices?.some(
          (s) => s?.source === service?.source
        );

        return serviceSource ? service : undefined;
      })
      ?.filter((service) => service);

    setBackgroundServicesFiltered([...onboardingServicesListFiltered]);

    if (
      !hasSelfieImage &&
      onboardingServicesListFiltered?.some((s) => s?.source === 'facematch')
    ) {
      const arrayClone = onboardingServicesListFiltered?.filter(
        (s) => s?.source !== 'facematch'
      );
      setBackgroundServicesFiltered([...arrayClone]);
    }

    return onboardingServicesListFiltered;
  }, [onboardingServicesList, profileTemplate, hasSelfieImage]);

  const handleCalculateTotalPrice = useCallback((array) => {
    const prices = [];

    array?.forEach((item) => {
      const currentItem = item || undefined;
      const price = currentItem ? currentItem.price || 0 : 0;

      prices.push(price);
    });

    const totalPrice = prices.reduce((acc, cur) => acc + cur, 0);
    setQueryPrice(totalPrice);
  }, []);

  useEffect(() => {
    handleCalculateTotalPrice(backgroundServicesFiltered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundServicesFiltered]);

  const handleChangeBackgroundServices = useCallback(
    ({ source, checked }) => {
      if (!checked) {
        const arrayUpdated = backgroundServicesFiltered?.filter(
          (s) => s?.source !== source
        );

        setBackgroundServicesFiltered(arrayUpdated);

        handleCalculateTotalPrice(arrayUpdated);

        return null;
      }

      const cloneArray = backgroundServicesFiltered;

      cloneArray?.push(
        profileTemplateServicesFiltered?.find((service) => service?.source === source)
      );

      setBackgroundServicesFiltered(cloneArray);

      handleCalculateTotalPrice(cloneArray);

      return null;
    },
    [
      backgroundServicesFiltered,
      handleCalculateTotalPrice,
      profileTemplateServicesFiltered,
    ]
  );

  const onSubmit = useCallback(async () => {
    if (!frontFile || !backFile || !documentType) {
      message.error(
        translate(
          'pages.private.profiles.components.manualUploadDocuments.index.onSubmit.message.error'
        )
      );
      return;
    }

    await post({
      url: `/people/${person?._id}/onboarding-internal`,
      payload: {
        front: frontFile,
        back: backFile,
        selfie: selfieFile,
        type: documentType,
        parameters: {
          cpf: person?.cpf,
        },
        services: backgroundServicesFiltered,
      },
      showMessage: true,
    });

    closeModal();
    refreshActivities();
    message.success(
      translate(
        'pages.private.profiles.components.manualUploadDocuments.index.onSubmit.message.success'
      )
    );
  }, [
    closeModal,
    post,
    documentType,
    frontFile,
    selfieFile,
    backFile,
    person,
    refreshActivities,
    translate,
    backgroundServicesFiltered,
  ]);

  const ManualUploadDocumentsModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeModal}
        footer={null}
        style={{ top: 40 }}
        width={800}
      >
        <div
          className={`no-mrg no-pdd manual-upload-documents ${
            customLoading && 'loading'
          }`}
        >
          {customLoading ? (
            <Spin className="loading-manual-upload-documents" />
          ) : (
            <>
              <PageTitle
                title={translate(
                  'pages.private.profiles.components.manualUploadDocuments.index.manualUploadDocuments.pageTitle.title'
                )}
                subtitle={customPageTitlteSubtitle}
              />

              <Form
                form={form}
                onFinish={() => (step === 3 ? onSubmit() : setStep(step === 1 ? 2 : 3))}
                style={{ margin: step === 1 ? '0px 16px' : '' }}
              >
                <section className="manual-upload-documents-type-section mrg-top-30">
                  {step === 1 && UploadDocumentUpload}
                  {step === 2 && (
                    <BackgroundCheckingServices
                      handleChangeBackgroundServices={handleChangeBackgroundServices}
                      services={backgroundServicesFiltered}
                      defaultServices={profileTemplateServicesFiltered}
                      hasSelfieImage={selfieFile}
                    />
                  )}
                  {step === 3 && (
                    <ValidationRulesStepCard
                      profileTemplate={profileTemplate}
                      services={backgroundServicesFiltered}
                      modalType="manual-upload-documents"
                    />
                  )}

                  {!backgroundServicesFiltered?.length > 0 && (
                    <div className="mrg-top-15">
                      <Alert
                        message={translate(
                          'pages.private.profiles.components.manualQuery.index.manualQuery.warningAlert'
                        )}
                        type="warning"
                        showIcon
                      />
                    </div>
                  )}
                </section>

                <Row justify="space-between" className="manual-query-footer">
                  <QueryPrice price={i18nFormatCurrency(queryPrice, 'pt-BR')} />

                  <div className="manual-query-btns">
                    <Button type="default" onClick={handleCancel} className="btn-cancel">
                      {translate(
                        `pages.private.profiles.components.manualQuery.index.manualQuery.button.initialStep.${
                          step === 1 ? 'cancel' : 'return'
                        }`
                      )}
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={
                        !frontFile ||
                        !backFile ||
                        loading ||
                        !documentType ||
                        !backgroundServicesFiltered?.length > 0
                      }
                    >
                      {loading
                        ? translate(
                            'pages.private.profiles.components.manualUploadDocuments.index.manualUploadDocuments.button.loading'
                          )
                        : confirmButtonText}
                    </Button>
                  </div>
                </Row>
              </Form>
            </>
          )}
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      customLoading,
      loading,
      translate,
      customPageTitlteSubtitle,
      form,
      step,
      UploadDocumentUpload,
      handleChangeBackgroundServices,
      backgroundServicesFiltered,
      profileTemplateServicesFiltered,
      profileTemplate,
      i18nFormatCurrency,
      queryPrice,
      handleCancel,
      frontFile,
      selfieFile,
      backFile,
      documentType,
      confirmButtonText,
      onSubmit,
    ]
  );

  return { openModal, closeModal, ManualUploadDocumentsModal };
};

export default ManualUploadDocuments;
