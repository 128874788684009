import useFetch from '../fetch';

const { useQuery } = require('react-query');

export function useOnboardingTemplatesCache() {
  const { get, loading } = useFetch();

  const {
    data: allOnboardingTemplates = [],
    isLoading: loadingAllOnboardingTemplates,
  } = useQuery(
    ['onboarding-templates'],
    async () => {
      const response = await get({
        url: '/onboardings/templates',
        config: {
          params: {
            _includeDefault: '',
          },
        },
        showMessage: false,
      });

      return response?.data || [];
    },
    {
      staleTime: 5 * 1000 * 60,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  return {
    allOnboardingTemplates,
    loadingAllOnboardingTemplates: loading || loadingAllOnboardingTemplates,
  };
}
