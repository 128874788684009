import React, { useMemo } from 'react';
import { Row, Col, Divider } from 'antd';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { CompanyBody1 } from 'pages/private/KYBCompany/CompanyDetailsV2/components';
import PropTypes from 'prop-types';

const CompanyRecordBody = ({ record, sources, ...rest }) => {
  const { i18nFormatDateOnly } = useI18nFormatters();
  const getSourceName = useMemo(() => {
    return sources?.find((src) => src?.id === record?.sourceId)?.name;
  }, []);
  return (
    <>
      <Row className={rest.className}>
        <Col span={8}>
          <CompanyBody1>{record?.label}</CompanyBody1>
        </Col>
        <Col span={5}>
          <CompanyBody1>{i18nFormatDateOnly(record?.acquisitionDate, '1')}</CompanyBody1>
        </Col>
        <Col span={5}>
          <CompanyBody1>{i18nFormatDateOnly(record?.publicationDate, '1')}</CompanyBody1>
        </Col>
        <Col span={6}>
          <a
            className="font-size-16"
            href={record?.sourceUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {getSourceName}
          </a>
        </Col>
      </Row>
      <Divider className="kyb-company-summary-records-divider" />
    </>
  );
};

CompanyRecordBody.propTypes = {
  record: PropTypes.object.isRequired,
  sources: PropTypes.array.isRequired,
};

export default CompanyRecordBody;
