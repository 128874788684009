import React from 'react';
import { Divider } from 'antd';
import { nadaConstaPGFNTranslation } from 'utils/personalTranslations';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.shared.PGFNRegularCertificate';

export const parsePgfnNadaConsta = (originalObject) => {
  const nadaConstaStatus = originalObject?.data?.certificateStatus;

  const translatedStatus = nadaConstaStatus
    ? nadaConstaPGFNTranslation[nadaConstaStatus] || nadaConstaPGFNTranslation.NULO
    : nadaConstaPGFNTranslation.NULO;

  return { ...originalObject, translatedStatus };
};

export const parsePgfnContent = (originalObject, translate) => {
  const certificateText = originalObject?.data?.certificateText;
  const certificateCode = originalObject?.data?.certificateCode;
  const certificateValidity = originalObject?.data?.certificateValidity;
  const certificateEmissionDate = originalObject?.data?.emissionDate;

  const sections = [];

  if (certificateText) {
    sections.push(<span>{certificateText}</span>);
  }

  if (certificateCode) {
    sections.push(
      <>
        <span>{translate(`${I18N_BASE_PATH}.index.code`)}:</span>
        <span>{certificateCode}</span>
      </>
    );
  }

  if (certificateEmissionDate) {
    sections.push(
      <>
        <span>{translate(`${I18N_BASE_PATH}.index.emissionDate`)}:</span>
        <span>{certificateEmissionDate}</span>
      </>
    );
  }

  if (certificateValidity) {
    sections.push(
      <>
        <span>{translate(`${I18N_BASE_PATH}.index.validUntil`)}:</span>
        <span>{certificateValidity}</span>
      </>
    );
  }

  return !sections.length ? (
    '-'
  ) : (
    <div className="flex-column">
      {sections?.map((section, index) => (
        <>
          {section}
          {index < sections.length - 1 && <Divider />}
        </>
      ))}
    </div>
  );
};
