import React from 'react';

import TrustValidations from 'pages/private/Profiles/components/TrustValidations';

import './styles.less';

const DocumentTrust = () => {
  return (
    <div id="to-print-trust">
      <TrustValidations toPrint />
    </div>
  );
};

export default DocumentTrust;
