import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyExecutionState from 'components/EmptyExecutionState';

import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';

import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

import './styles.less';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.people.components.pgfnDebts';

const DebtorsPGFN = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  return !queryWorked ? (
    <DataSourceCardCollapsePanel
      title={translate(`${I18N_BASE_PATH}.title`)}
      icon="caf-ic_user_inactive"
      toPrint={toPrint}
      customId="financialInfo"
    >
      <EmptyExecutionState
        className="mrg-top-25"
        statusCode={statusCode}
        message={parsedData?.message || source?.message}
      />
    </DataSourceCardCollapsePanel>
  ) : (
    <>
      <DataSourceCardCollapsePanel
        title={translate(`${I18N_BASE_PATH}.title`)}
        icon="caf-ic_user_inactive"
        toPrint={toPrint}
        customId="financialInfo"
      >
        <ColumnTitleValue
          id="debtors-data-column"
          span={12}
          title={translate(`${I18N_BASE_PATH}.title`)}
          value={
            <span
              className={parsedData?.hasDebts ? 'debtors-has-debts' : 'debtors-no-debts'}
            >
              {parsedData?.hasDebts
                ? translate(`${I18N_BASE_PATH}.debtValue`, {
                    value: parsedData?.debtValue || '-',
                  })
                : translate(`${I18N_BASE_PATH}.noDebts`)}
            </span>
          }
        />
      </DataSourceCardCollapsePanel>
    </>
  );
};

export default DebtorsPGFN;
