import React, { useState } from 'react';
import ViewMore from 'components/ViewMore';

import './styles.less';
import PropTypes from 'prop-types';

function ViewMoreText({ message, content }) {
  const [allTextVisible, setAllTextVisible] = useState(false);

  return (
    <>
      <div className="flex-column">
        {message && message !== 'Consulta realizada com sucesso.' && (
          <span>{message}</span>
        )}

        <p className={`view-more-content-paragraph ${allTextVisible ? 'all-text' : ''}`}>
          {content || '-'}
        </p>
      </div>

      <ViewMore
        title={allTextVisible ? 'Ver menos' : 'Ver mais'}
        icon={allTextVisible ? 'caf-ic_minimize' : 'caf-ic_plus'}
        onClick={() => setAllTextVisible((oldState) => !oldState)}
      />
    </>
  );
}

ViewMoreText.propTypes = {
  message: PropTypes.string,
  content: PropTypes.string.isRequired,
};
ViewMoreText.defaultProps = {
  message: undefined,
};

export default ViewMoreText;
