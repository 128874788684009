/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyExecutionState from 'components/EmptyExecutionState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';
import usePersonalLaborAndFinancialInformationModal from 'pages/private/Profiles/components/DataSourceModals/PersonalLaborAndFinancialInformation';

import { isEmpty } from 'utils/isObjectEmpty';

import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

const CivilServant = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );
  const {
    openModal,
    PersonalLaborAndFinancialInformationModal,
  } = usePersonalLaborAndFinancialInformationModal({
    data: parsedData?.professions,
    title: translate(
      'components.dataSourceCards.laborInformation.people.components.civilServant.index.usePersonalLaborAndFinancialInformationModal.title'
    ),
  });

  const handlerInfos = useMemo(() => {
    if (!parsedData || !queryWorked)
      return {
        title: null,
        type: null,
      };

    if (isEmpty(parsedData)) {
      return {
        title: translate(
          'components.dataSourceCards.laborInformation.people.components.civilServant.index.handlerInfos.isEmpty'
        ),
        type: 'empty',
      };
    }

    if (parsedData?.isEmployed) {
      return {
        title: translate(
          'components.dataSourceCards.laborInformation.people.components.civilServant.index.handlerInfos.isEmployed'
        ),
        type: 'primary',
      };
    }

    if (!parsedData?.isEmployed) {
      return {
        title: translate(
          'components.dataSourceCards.laborInformation.people.components.civilServant.index.handlerInfos.isNotEmployed'
        ),
        type: 'empty',
      };
    }

    return {
      title: null,
      type: null,
    };
  }, [parsedData, queryWorked, translate]);

  const handleOpenModal = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <>
      {PersonalLaborAndFinancialInformationModal}
      <DataSourceCardCollapsePanel
        title={translate(
          'components.dataSourceCards.laborInformation.people.components.civilServant.index.civilServant.columnLabel.title'
        )}
        customId="labor-infos-component"
        icon="caf-ic_awards"
        toPrint={toPrint}
      >
        <ColumnLabel
          title={translate(
            'components.dataSourceCards.laborInformation.people.components.civilServant.index.civilServant.columnLabel.title'
          )}
          description={handlerInfos?.title}
          type={handlerInfos?.type}
          onClick={queryWorked && parsedData?.professions?.length > 0 && handleOpenModal}
        >
          {queryWorked ? (
            <>
              {(isEmpty(parsedData) || !parsedData?.professions?.length > 0) && (
                <span>
                  {translate(
                    'components.dataSourceCards.laborInformation.people.components.civilServant.index.civilServant.columnLabel.querySucceeded.isEmpty'
                  )}
                </span>
              )}
              {!toPrint && parsedData?.professions?.length > 0 && (
                <span>
                  {translate(
                    'components.dataSourceCards.laborInformation.people.components.civilServant.index.civilServant.columnLabel.hasProfessions'
                  )}
                </span>
              )}
            </>
          ) : (
            <EmptyExecutionState
              statusCode={statusCode}
              message={source?.message || parsedData?.message}
            />
          )}
        </ColumnLabel>
      </DataSourceCardCollapsePanel>
    </>
  );
};

export default CivilServant;
