const pfSourceFields = {
  processes: ['pf_processes', 'pf_processes_alt'],
  stateCriminalBackground: ['pf_antecedentes'],
  federalCriminalBackground: ['pf_criminal_background'],
  spc: ['pf_spc', 'pf_spc_income'],
  pgfn: ['pf_pgfn'],
  protest: ['pf_protesto'],
  jointDebts: ['pf_joint_debts_certificate'],
  socialAssistance: ['pf_social_assistance'],
  unemploymentInsurance: ['pf_seguro_desemprego'],
  incomeEstimates: ['pf_finantial_data', 'pf_salesbox', 'pf_spc_income'],
  totalAssets: ['pf_finantial_data'],
  workHistory: ['pf_occupation_data'],
  rais: ['pf_rais'],
  classOrganization: ['pf_class_organization'],
  civilServant: ['pf_profession_data'],
  antt: ['antt'],
  detran: ['detran'],
  kycCompliance: ['pf_kyc_compliance'],
  peoplePhones: ['pf_related_people_phones'],
  businnesAndPersonalRelationships: [
    'pf_business_relationships',
    'pf_personal_relationships',
  ],
  peopleOccurrencesIRL: ['pf_personal_relationships_trust_rl'],
  mediaExposure: ['pf_media_profile_and_exposure'],
  phones: ['pf_phones'],
  emails: ['pf_emails'],
  addresses: ['pf_addresses'],
  scoreCheck: ['pf_score_check'],
};

const pjSourceFields = {
  processes: ['pj_processes', 'pj_processes_alt'],
  spc: ['pj_spc'],
  pgfn: ['pj_pgfn'],
  protest: ['pj_protesto'],
  cadinSp: ['pj_cadin_sp'],
  fgts: ['pj_fgts'],
  sintegra: ['pj_sintegra'],
  cndt: ['pj_cndt'],
  kycCompliance: ['pj_kyc_compliance'],
  kycComplianceOwners: ['pj_kyc_compliance_owners'],
  addresses: ['pj_data'],
  contacts: ['pj_data'],
  QSA: ['pj_data'],
  scoreCheck: ['pj_score_check'],
  serasaExperian: ['pj_serasa_experian'],
};

const verifySourcesProcessing = (profile, sourceField) => {
  if (!profile || !sourceField) return null;

  const sourceFields = profile?.cpf ? pfSourceFields : pjSourceFields;
  if (!sourceFields[sourceField]) return null;

  return sourceFields[sourceField]?.some(
    (source) => profile?.backgroundChecking?.services?.[source]?.status === 'PROCESSING'
  );
};

export default verifySourcesProcessing;
