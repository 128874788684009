import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import useWindowResize from 'customHooks/useWindowResize';

import { Divider } from 'antd';
import './styles.less';

const I18N_BASE_PATH = 'pages.private.dashboard.index.charts.slaData';

const SlaDATA = ({ data }) => {
  const [useAbreviation, setUseAbreviation] = useState(false);
  const { t: translate, i18n } = useTranslation();

  useWindowResize((width) => {
    if (width < 1600) {
      setUseAbreviation(true);
    } else {
      setUseAbreviation(false);
    }
  }, []);

  const optionsOfSLA = [
    {
      title: '1minute',
      value: data.in1minute,
      id: 1,
    },
    {
      title: '3minutes',
      value: data.in3minutes,
      id: 2,
    },
    {
      title: '5minutes',
      value: data.in5minutes,
      id: 3,
    },
    {
      title: '10minutes',
      value: data.in10minutes,
      id: 4,
    },
    {
      title: '30minutes',
      value: data.in30minutes,
      id: 5,
    },
    {
      title: '1hourOrMore',
      value: data.in1HourOrMore,
      id: 6,
    },
  ];

  return (
    <div className="sla-data">
      <h3>
        {translate(`${I18N_BASE_PATH}.labels.totalDocuments`, 'Total documents analyzed')}
        : <span>{data.total.toLocaleString(i18n.language)}</span>
      </h3>
      <Divider
        style={{
          margin: '0px 0px 5px',
        }}
      />
      <div className="sla-data__container">
        {optionsOfSLA.map(({ title, value, id }) => (
          <div className="sla-data__item" key={id}>
            <h5>
              {translate(`${I18N_BASE_PATH}.labels.time.${title}.until`)}{' '}
              {useAbreviation
                ? translate(`${I18N_BASE_PATH}.labels.time.${title}.abreviation`)
                : translate(`${I18N_BASE_PATH}.labels.time.${title}.unit`)}
            </h5>
            <div className="sla-data__item_card">
              <h4>{value}</h4>
              <p>{translate(`${I18N_BASE_PATH}.labels.docs`, 'documents')}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

SlaDATA.propTypes = {
  data: PropTypes.shape({
    total: PropTypes.number,
    in1minute: PropTypes.number,
    in3minutes: PropTypes.number,
    in5minutes: PropTypes.number,
    in10minutes: PropTypes.number,
    in30minutes: PropTypes.number,
    in1HourOrMore: PropTypes.number,
  }).isRequired,
};

export default SlaDATA;
