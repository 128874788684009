import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, Form, Select, DatePicker, Row, Col } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import { useActivities } from 'hooks/activities';
import { useProfileTemplate } from 'hooks/profileTemplate';

import useComponentList from 'components/List/hooks/useComponentList';
import useInfiniteScroll from 'components/List/hooks/useInfiniteScroll';

import { i18nMomentFormats } from 'i18n/utils/i18nFormats';
import CardRecurrenceEvent from './components/CardRecurrenceEvent';

import './styles.less';

const { Option } = Select;

const RecurrenceList = ({ toPrint, onLoadingChange }) => {
  const { t: translate, i18n } = useTranslation();
  const { loadCafServicesList, cafServicesList } = useProfileTemplate();
  const [form] = Form.useForm();
  const { companyId, personId, executionId } = useParams();

  const { reloadActivities } = useActivities();

  const dateToDisable = useCallback((current) => {
    const beforeOctober = current < moment().month('8').year('2020');
    const afterCurrentMonth = current > moment();

    return beforeOctober || afterCurrentMonth;
  }, []);

  const customDataParser = useCallback(
    ({ INITIAL_CURRENT, oldPagination, oldData, newData }) => {
      const oldDataDates = oldData?.map(({ date }) => date);
      const newDataDates = newData?.docs?.map(({ date }) => date);
      // Pega todas as datas possíveis
      const everyDate = _.uniq([...(oldDataDates || []), ...(newDataDates || [])]);

      const newDataParsed = everyDate.map((date) => {
        const foundOldData = oldData.find((od) => od.date === date) || { items: [] };
        const foundNewData = newData?.docs?.find((nd) => nd.date === date) || {
          items: [],
        };

        return {
          date,
          items: [...foundOldData?.items, ...foundNewData?.items],
        };
      });

      return oldPagination?.current === INITIAL_CURRENT ? newData?.docs : newDataParsed;
    },
    []
  );

  const eventsUrl = useMemo(() => {
    if (companyId) return `/companies/${companyId}/events`;
    if (personId) return `/people/${personId}/events`;
    if (executionId) return `/executions/${executionId}/activities`;

    return '';
  }, [companyId, executionId, personId]);

  const {
    componentList: infiniteComponentList,
    setFilterData: setInfiniteFilterData,
    reloadList: reloadInfiniteList,
  } = useInfiniteScroll({
    getParams: {
      url: eventsUrl,
      config: {
        params: {
          _groupBy: 'date',
          _sort: 'createdAt',
        },
      },
    },
    component: CardRecurrenceEvent,
    rowKey: 'date',
    customEmptyMessage: translate(
      'pages.private.profiles.components.tabs.components.recurrenceList.index.useInfiniteScroll.customEmptyMessage'
    ),
    customDataParser,
    toPrint,
  });

  const { componentList, setFilterData, reloadList, loading } = useComponentList({
    getParams: {
      url: eventsUrl,
      config: {
        params: {
          _groupBy: 'date',
          _sort: 'createdAt',
        },
      },
    },
    component: CardRecurrenceEvent,
    rowKey: 'date',
    customEmptyMessage: translate(
      'pages.private.profiles.components.tabs.components.recurrenceList.index.useInfiniteScroll.customEmptyMessage'
    ),
    customDataParser,
    hidePagination: true,
    noLimit: true,
    toPrint,
  });

  useEffect(() => {
    if (onLoadingChange) onLoadingChange(loading);
  }, [onLoadingChange, loading]);

  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      const dates = { _startDate: undefined, _endDate: undefined };
      if (values.period) {
        const newStartDate = moment.utc(moment(values?.period).startOf('month')).toDate();
        const newEndDate = moment.utc(moment(values?.period).endOf('month')).toDate();

        dates._startDate = new Date(newStartDate).getTime();
        dates._endDate = new Date(newEndDate).getTime();
      }

      if (executionId) {
        setFilterData({
          ...values,
          ...dates,
        });
      } else {
        setInfiniteFilterData({
          ...values,
          ...dates,
        });
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (reloadActivities !== 0 && !executionId) reloadInfiniteList();
    if (reloadActivities !== 0 && executionId) reloadList();
    if (!cafServicesList) loadCafServicesList();
  }, [reloadActivities, cafServicesList]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card id="recurrence-container">
      <Form
        form={form}
        layout="horizontal"
        onValuesChange={handleFormChange}
        initialValues={{ _order: -1 }}
      >
        <Row>
          <Col span={24} className="recurrence-header-filter">
            {executionId ? (
              <></>
            ) : (
              <>
                <div className="period-group-container">
                  <i className="caf-ic_calendar" />
                  <Form.Item name="period">
                    <DatePicker
                      bordered={false}
                      className="data-filter-period"
                      picker="month"
                      format={i18nMomentFormats[1][i18n?.language]}
                      inputReadOnly
                      placeholder={translate(
                        'pages.private.profiles.components.tabs.components.recurrenceList.index.recurrenceList.datePicker.placeholder'
                      )}
                      disabledDate={dateToDisable}
                      suffixIcon={<i className="caf-ic_arrow_down" />}
                      style={{ width: 180 }}
                    />
                  </Form.Item>
                </div>
                <div className="input-filter-group">
                  <Form.Item name="_order">
                    <Select
                      bordered={false}
                      className="text-dark custom-search-input ordination-input"
                      style={{ minWidth: 135, width: 135, fontSize: 15 }}
                    >
                      <Option value={-1}>
                        {' '}
                        {translate(
                          'pages.private.profiles.components.tabs.components.recurrenceList.index.recurrenceList.select.latest'
                        )}
                      </Option>
                      <Option value={1}>
                        {translate(
                          'pages.private.profiles.components.tabs.components.recurrenceList.index.recurrenceList.select.older'
                        )}
                      </Option>
                    </Select>
                  </Form.Item>
                </div>
              </>
            )}
          </Col>
        </Row>
        <div style={{ minHeight: 200 }}>
          {executionId || toPrint ? componentList : infiniteComponentList}
        </div>
      </Form>
    </Card>
  );
};

RecurrenceList.propTypes = {
  toPrint: PropTypes.bool,
  onLoadingChange: PropTypes.func,
};

RecurrenceList.defaultProps = {
  toPrint: false,
  onLoadingChange: null,
};

export default RecurrenceList;
