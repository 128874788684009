import React from 'react';
import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import NoResults from 'components/NoResults';
import NotAvailable from 'components/NotAvailable';
import CollapseList from '../../../../CollapseList';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companyDetails.subPages.people.businessIdentity';

const ShareHoldersContent = ({
  shareholders,
  collapsePrintSuffix,
  collapseOpened,
  print = false,
}) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDateOnly, i18nFormatNumber } = useI18nFormatters();

  if (!shareholders.length) {
    return <NoResults />;
  }

  return (
    <CollapseList
      print={print}
      collapsePrintSuffix={collapsePrintSuffix}
      collapseOpened={collapseOpened}
      columns={[
        {
          prop: 'name',
          title: translate(`${I18N_BASE_PATH}.tabs.shareholders.columns.name`, 'Name'),
          span: 6,
        },
        {
          prop: 'dateOfBirth',
          title: translate(
            `${I18N_BASE_PATH}.tabs.shareholders.columns.dateOfBirth`,
            'Date of birth'
          ),
          span: 6,
        },
        {
          prop: 'endDate',
          title: translate(
            `${I18N_BASE_PATH}.tabs.shareholders.columns.endDate`,
            'End date'
          ),
          span: 6,
        },
        {
          prop: 'identifiers',
          title: translate(
            `${I18N_BASE_PATH}.tabs.shareholders.columns.identifiers`,
            'Identifiers'
          ),
          span: 6,
        },
      ]}
      list={shareholders?.map((shareholder, index) => {
        return {
          header: {
            index,
            name: shareholder?.name || <NotAvailable />,
            dateOfBirth: i18nFormatDateOnly(shareholder?.birthdate, 1, null) || (
              <NotAvailable />
            ),
            endDate: i18nFormatDateOnly(shareholder?.toDate, 1, null) || <NotAvailable />,
            identifiers: !shareholder?.identifiers?.length ? (
              <NotAvailable />
            ) : (
              shareholder?.identifiers
            ),
          },
          body: [
            {
              title: translate(`${I18N_BASE_PATH}.tabs.shareholders.collapse.pep`, 'PEP'),
              content: shareholder?.pep
                ? translate(`${I18N_BASE_PATH}.tabs.shareholders.yes`, 'Yes')
                : translate(`${I18N_BASE_PATH}.tabs.shareholders.no`, 'No'),
            },
            {
              title: translate(
                `${I18N_BASE_PATH}.tabs.shareholders.collapse.sanctioned`,
                'Sanctioned'
              ),
              content: shareholder?.sanctioned
                ? translate(`${I18N_BASE_PATH}.tabs.shareholders.yes`, 'Yes')
                : translate(`${I18N_BASE_PATH}.tabs.shareholders.no`, 'No'),
            },
            {
              title: translate(
                `${I18N_BASE_PATH}.tabs.shareholders.collapse.basel_aml`,
                'Basel AML Score'
              ),
              content: shareholder?.riskData?.basel_aml?.value,
            },
            {
              title: translate(
                `${I18N_BASE_PATH}.tabs.shareholders.collapse.shareValue`,
                'Share value'
              ),
              content: i18nFormatNumber(
                shareholder?.attributes?.shares?.[0]?.monetaryValue
              ),
            },
            {
              title: translate(
                `${I18N_BASE_PATH}.tabs.shareholders.collapse.asOfDate`,
                'As of date'
              ),
              content: i18nFormatDateOnly(shareholder?.publicationDate, 1),
            },
            {
              title: translate(
                `${I18N_BASE_PATH}.tabs.shareholders.collapse.cpi_score`,
                'CPI Score'
              ),
              content: shareholder?.riskData?.cpi_score?.value,
            },
            {
              title: translate(
                `${I18N_BASE_PATH}.tabs.shareholders.collapse.former`,
                'Former'
              ),
              content: shareholder?.former
                ? translate(`${I18N_BASE_PATH}.tabs.shareholders.yes`, 'Yes')
                : translate(`${I18N_BASE_PATH}.tabs.shareholders.no`, 'No'),
            },
            {
              title: translate(
                `${I18N_BASE_PATH}.tabs.shareholders.collapse.ownershipPercentage`,
                'Ownership Percentage'
              ),
              content: `${i18nFormatNumber(
                shareholder?.attributes?.shares?.[0]?.percentage
              )}%`,
            },
            {
              title: translate(
                `${I18N_BASE_PATH}.tabs.shareholders.collapse.latest_adress`,
                'Latest adress'
              ),
              content: shareholder?.addresses,
            },
          ],
        };
      })}
    />
  );
};

export default ShareHoldersContent;
