import React, { useMemo } from 'react';
// import { Card } from 'antd';

import { useCompany } from 'hooks/company';

import * as pjDataSources from 'utils/pjDataSource';

import FinancialInformation from 'pages/private/Profiles/components/DataSourceCards/FinancialInformation/Companies';
import RestrictiveLists from 'pages/private/Profiles/components/DataSourceCards/RestrictiveLists';
import LegalInformation from 'pages/private/Profiles/components/DataSourceCards/LegalInformation';
import BusinessLaborInformation from 'pages/private/Profiles/components/DataSourceCards/LaborInformation/Companies';

// import EmptyRecurrence from 'pages/private/Profiles/components/EmptyRecurrence';

import PermissionWrapper from 'routes/PermissionWrapper';

const Dossier = () => {
  const { company } = useCompany();

  const dataSources = useMemo(() => {
    return (
      company?.profileTemplate?.backgroundChecking?.services?.map(
        ({ source }) => source
      ) || []
    );
  }, [company]);

  return (
    <>
      {/* {!company?.recurrenceModel && (
        <Card>
          <EmptyRecurrence />
        </Card>
      )} */}

      {pjDataSources.hasFinancialInformation(company, dataSources) && (
        <FinancialInformation data={company} />
      )}

      {pjDataSources.hasRestrictiveListsInformation(company, dataSources) && (
        <PermissionWrapper
          requiredPermissions={['companies:read']}
          requiredResources={['others:kycCompliance:*']}
        >
          <RestrictiveLists data={company} />
        </PermissionWrapper>
      )}

      {pjDataSources.hasLegalInformation(company, dataSources) && (
        <PermissionWrapper
          or
          requiredPermissions={['companies:read']}
          requiredResources={['others:kycCompliance:*', 'others:kycComplianceOwners:*']}
        >
          <LegalInformation data={company} />
        </PermissionWrapper>
      )}

      {pjDataSources.hasLaborInformation(company, dataSources) && (
        <BusinessLaborInformation data={company} />
      )}
    </>
  );
};

export default Dossier;
