import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import currencyFormatter from 'utils/currencyFormatter';
import CafLogo from 'assets/images/caf-logo-extended.svg';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import './styles.less';

import InvoiceDetails from '../InvoiceDetails';
import { invoiceDetailsPropTypes } from '../InvoiceDetails/InvoiceDetails';

const InvoicePDF = ({
  clientDetails,
  companyDetails,
  invoiceDetails,
  invoiceMetadata,
  printRef,
  currencyCode,
}) => {
  const { t: translate, i18n } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  return (
    <div className="invoice-pdf" ref={printRef}>
      <div className="invoice-pdf__header">
        <h1>{translate('pages.private.consumption.pdf.title')}</h1>
        <img src={CafLogo} alt="CAF" />
      </div>
      <div className="invoice-pdf__metadata">
        <div className="invoice-pdf__metadata__company-details">
          <h2>{translate('pages.private.consumption.pdf.metadata.billTo')}</h2>
          <p>{clientDetails.name}</p>
          <p>{clientDetails.address}</p>
          <p>
            {translate('pages.private.consumption.pdf.metadata.companyNumber')}{' '}
            {clientDetails.document}
          </p>
        </div>

        <div className="invoice-pdf__metadata__company-details">
          <h2>{translate('pages.private.consumption.pdf.metadata.from')}</h2>
          <p>{companyDetails.name}</p>
          <p>{companyDetails.address}</p>
          <p>
            {translate('pages.private.consumption.pdf.metadata.companyNumber')}{' '}
            {companyDetails.document}
          </p>

          <article className="invoice-pdf__metadata__details">
            <section className="invoice-pdf__metadata__details__section">
              <p>
                {translate('pages.private.consumption.pdf.metadata.details.invoiceDate')}
              </p>
              <p>{invoiceMetadata.date}</p>
            </section>
            <section className="invoice-pdf__metadata__details__section">
              <p>
                {translate(
                  'pages.private.consumption.pdf.metadata.details.invoicePeriod'
                )}
              </p>
              <p>
                {i18nFormatDate(invoiceMetadata.period.start, 13)} -{' '}
                {i18nFormatDate(invoiceMetadata.period.end, 13)}
              </p>
            </section>
            <section className="invoice-pdf__metadata__details__section">
              <p>
                {translate('pages.private.consumption.pdf.metadata.details.pricingPlan')}
              </p>
              <p>{invoiceMetadata.pricingPlan}</p>
            </section>
            <section className="invoice-pdf__metadata__details__section invoice-pdf__metadata__details__section_footer">
              <p>
                {translate('pages.private.consumption.pdf.metadata.details.invoiceTotal')}
              </p>
              <p>
                {currencyFormatter.toCurrency(
                  invoiceDetails.total,
                  currencyFormatter.currencyCodeToSymbol(currencyCode),
                  currencyFormatter.currencyCodeToLocale(currencyCode)
                )}
              </p>
            </section>
          </article>
        </div>
      </div>
      <InvoiceDetails
        {...invoiceDetails}
        currencyCode={currencyCode}
        title={translate('pages.private.consumption.pdf.invoiceDetails.title')}
      />
    </div>
  );
};

const detailsPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  document: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
});

InvoicePDF.propTypes = {
  invoiceDetails: invoiceDetailsPropTypes.isRequired,
  clientDetails: detailsPropTypes.isRequired,
  companyDetails: detailsPropTypes.isRequired,
  invoiceMetadata: PropTypes.shape({
    date: PropTypes.number.isRequired,
    period: PropTypes.shape({
      start: PropTypes.number.isRequired,
      end: PropTypes.number.isRequired,
    }),
    pricingPlan: PropTypes.string.isRequired,
  }).isRequired,
  printRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  currencyCode: PropTypes.string.isRequired,
};

export default InvoicePDF;
