/* eslint-disable react/prop-types */
import React from 'react';

import Panel from './Panels';

import './styles.less';

const PoliciesSelect = ({ formRef, disabled, policiesReturn }) => (
  <div id="select-policies">
    <Panel formRef={formRef} disabled={disabled} policiesReturn={policiesReturn} />
  </div>
);
export default PoliciesSelect;
