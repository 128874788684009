import React, { useMemo } from 'react';

import { useValidations } from 'hooks/validations';

import AuthenticDocument from './components/AuthenticDocument';
import VerifiedIdentity from './components/VerifiedIdentity';

import './styles.less';

const DocumentsValidations = () => {
  const { validations, loadingValidations } = useValidations();

  const findValidationsData = useMemo(() => {
    if (!validations) return null;

    const _validations = validations?.validations || [];

    const validIdentity = _validations?.find(
      (validation) => validation.rule === 'valid_identity'
    );
    const validDocument = _validations?.find(
      (validation) => validation.rule === 'valid_document'
    );

    return { validIdentity, validDocument };
  }, [validations]);

  return (
    <>
      <AuthenticDocument
        data={findValidationsData?.validDocument?.data || {}}
        loading={loadingValidations}
        status={findValidationsData?.validDocument?.status}
      />
      <VerifiedIdentity
        data={findValidationsData?.validIdentity?.data || {}}
        loading={loadingValidations}
        status={findValidationsData?.validIdentity?.status}
      />
    </>
  );
};

export default DocumentsValidations;
