import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from 'use-query-params';
import { Row, Col, Table } from 'antd';

import { useFetch } from 'services/hooks';

import ViewMore from 'components/ViewMore';

import initialFilterData from '../utils/initialFilterData';

const useDynamoTable = ({
  rowKey,
  getParams,
  columns,
  queryParams,
  customLoading = false,
}) => {
  const { get, loading } = useFetch();
  const { t: translate } = useTranslation();

  const [data, setData] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(undefined);

  const [params, setParams] = useState(getParams);
  const [query, setQuery] = useQueryParams({
    ...queryParams,
  });
  const [filterData, setFilterData] = useState(() =>
    initialFilterData({ query, queryParams })
  );

  const fetch = useCallback(
    async ({ loadMore }) => {
      if (!params) return;

      const { id, tenantId } = lastEvaluatedKey || {};

      const requestParams =
        lastEvaluatedKey && loadMore
          ? { ...params.config.params, ...filterData, id, tenantId }
          : { ...params.config.params, ...filterData };

      const response = await get({
        url: params.url,
        config: {
          params: requestParams,
        },
        showMessage: false,
      });
      const { LastEvaluatedKey, docs } = response || {};

      setLastEvaluatedKey(LastEvaluatedKey);

      if (loadMore) {
        setData((oldState) => [...oldState, ...(docs || [])]);
      } else {
        setData(docs);
      }
    },
    [get, params, lastEvaluatedKey, filterData]
  );

  const refreshList = useCallback(() => {
    fetch({ loadMore: false });
  }, [fetch]);

  const updateParams = useCallback((newParams) => {
    setParams(newParams);
  }, []);

  useEffect(() => {
    if (!data?.length) return;
    fetch({ loadMore: false });
  }, [filterData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!data?.length) refreshList();
  }, [params, filterData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQuery({ ...filterData });
  }, [filterData, setQuery]);

  const tableContent = useMemo(
    () =>
      columns && (
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={data || []}
                loading={loading || customLoading}
                pagination={false}
                showSorterTooltip={false}
                rowKey={rowKey}
                className="custom-table-dark mrg-btm-30"
              />
            </Col>
          </Row>

          {lastEvaluatedKey && (
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24}>
                <ViewMore
                  title={
                    loading
                      ? translate('components.list.hooks.useDynamoTable.loader.loading')
                      : translate('components.list.hooks.useDynamoTable.loader.showMore')
                  }
                  text={loading}
                  className="custom-table-btn center"
                  loading={loading}
                  onClick={() => fetch({ loadMore: true })}
                />
              </Col>
            </Row>
          )}
        </>
      ),
    [columns, data, loading, customLoading, rowKey, lastEvaluatedKey, translate, fetch]
  );

  return {
    data,
    tableContent,
    refreshList,
    updateParams,
    setFilterData,
  };
};

export default useDynamoTable;
