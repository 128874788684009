import moment from 'moment';

export function normalizeInvoiceList(data) {
  return data
    .sort((a, b) => a.invoiceStartTime - b.invoiceStartTime)
    .reverse()
    .map((item) => ({
      date: `${String(item.month).padStart(2, '0')}/${item.year}`,
      totalAmount: item.invoiceBillInBaseCurrency?.totalPrice,
      currencyCode: item.paymentCurrencyInfo?.paymentCurrency,
      layout: item.version === 'v1' ? 'OLD' : 'NEW',
      period: {
        start: moment.utc(item.invoiceStartTime).format('MM/DD/YYYY HH:mm'),
        end: moment.utc(item.invoiceEndTime).format('MM/DD/YYYY HH:mm'),
      },
      status: item.status,
      id: item.id,
    }));
}
