import React from 'react';

import { AuthProvider } from './auth';
import { ThemeProvider } from './theme';
import { CompanyProvider } from './company';
import { PersonProvider } from './person';
import { ExecutionProvider } from './execution';
import { ValidationsProvider } from './validations';
import { TagsProvider } from './tags';
import { ProfileTemplateProvider } from './profileTemplate';
import { ScrollProvider } from './scroll';
import { UploadManagerProvider } from './uploadManager';
import { ActivitiesProvider } from './activities';
import { I18nFormattersProvider } from './i18nFormatters';
import { ReportsProvider } from './reports';
import { CustomStatusProvider } from './customStatus';
import { RegisterModalProvider } from './registerModal';
import { OnboardingTemplateProvider } from './onboardingTemplate';
import { ReasonsProvider } from './reasons';
import { GroupProvider } from './group';
import { SocketProvider } from './socket';
import {
  WorkflowProvider,
  SecurityOptionsProvider,
  ComplyDataProvider,
} from '../contexts';
import { HeaderFilterProvider } from './headerFilter';

const AppProvider = ({ children }) => (
  <ThemeProvider>
    <SocketProvider>
      <I18nFormattersProvider>
        <AuthProvider>
          <ComplyDataProvider>
            <HeaderFilterProvider>
              <SecurityOptionsProvider>
                <WorkflowProvider>
                  <ScrollProvider>
                    <UploadManagerProvider>
                      <OnboardingTemplateProvider>
                        <ProfileTemplateProvider>
                          <RegisterModalProvider>
                            <ReasonsProvider>
                              <CompanyProvider>
                                <PersonProvider>
                                  <ExecutionProvider>
                                    <ValidationsProvider>
                                      <TagsProvider>
                                        <ActivitiesProvider>
                                          <ReportsProvider>
                                            <GroupProvider>
                                              <CustomStatusProvider>
                                                {children}
                                              </CustomStatusProvider>
                                            </GroupProvider>
                                          </ReportsProvider>
                                        </ActivitiesProvider>
                                      </TagsProvider>
                                    </ValidationsProvider>
                                  </ExecutionProvider>
                                </PersonProvider>
                              </CompanyProvider>
                            </ReasonsProvider>
                          </RegisterModalProvider>
                        </ProfileTemplateProvider>
                      </OnboardingTemplateProvider>
                    </UploadManagerProvider>
                  </ScrollProvider>
                </WorkflowProvider>
              </SecurityOptionsProvider>
            </HeaderFilterProvider>
          </ComplyDataProvider>
        </AuthProvider>
      </I18nFormattersProvider>
    </SocketProvider>
  </ThemeProvider>
);

export default AppProvider;
