import { Col, Collapse, Row, Divider } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DottedLabel from 'components/DottedLabel';

import { useI18nFormatters } from 'hooks/i18nFormatters';

// import { Container } from './styles';

const { Panel } = Collapse;

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.companies.components.spcReportParticipationScore.otherInfos';

function Lawsuit({ source = {}, toPrint = false }) {
  const { t: translate } = useTranslation();
  const { i18nFormatDate, i18nFormatCurrency } = useI18nFormatters();

  const parsedData = useMemo(() => source?.data || source, [source]);

  const judiciaryInformation = useMemo(() => parsedData?.judiciaryInformation, [
    parsedData,
  ]);

  return (
    <Collapse className="mrg-top-10" defaultActiveKey={toPrint ? ['1'] : null}>
      <Panel
        disabled={!judiciaryInformation?.details?.length}
        header={
          <Col span={24}>
            <Row align="middle" justify="space-between">
              <Col>
                <span className="text-dark text-bold">
                  {translate(`${I18N_BASE_PATH}.index.judiciaryInformation.title`)}
                </span>
              </Col>
              <Col>
                <DottedLabel
                  description={`(${judiciaryInformation?.total || 0}) ${translate(
                    `${I18N_BASE_PATH}.index.restrictions`
                  )}`}
                  type={+judiciaryInformation?.total > 0 ? 'danger' : 'primary'}
                />
              </Col>
            </Row>
            <Row align="middle" className="mrg-top-10">
              <Col span={20}>
                <Row>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.judiciaryInformation.columns.lastOccurenceDate`
                    )}
                    :
                  </span>
                </Row>
                <Row>
                  <span>
                    {i18nFormatDate(judiciaryInformation?.lastOccurenceDate, 1) || '-'}
                  </span>
                </Row>
              </Col>

              <Col span={4}>
                <Row>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.judiciaryInformation.columns.value`
                    )}
                    :
                  </span>
                </Row>
                <Row>
                  <span>
                    {i18nFormatCurrency(judiciaryInformation?.totalValue, 'pt-BR') || '-'}
                  </span>
                </Row>
              </Col>
            </Row>
          </Col>
        }
        key="1"
      >
        {judiciaryInformation?.details?.map((register) => (
          <>
            <Col span={24}>
              <Row align="middle">
                <span className="text-dark text-bold">
                  {i18nFormatDate(register?.inclusionDate, 1)} -{' '}
                  {i18nFormatCurrency(register?.value, 'pt-BR')}
                </span>
              </Row>
              <Row align="middle" className="mrg-top-8" justify="space-between">
                <Col>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.judiciaryInformation.columns.actionDistrict`
                    )}
                    :
                  </span>{' '}
                  <span>
                    {`${register?.district?.city} - ${register?.district?.state}` || '-'}
                  </span>
                </Col>
                <Col>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.judiciaryInformation.columns.documentDate`
                    )}
                    :
                  </span>{' '}
                  <span>{i18nFormatDate(register?.documentDate, 1) || '-'}</span>
                </Col>
              </Row>
              <Row align="middle" className="mrg-top-8" justify="space-between">
                <Col>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.judiciaryInformation.columns.lawsuitNumber`
                    )}
                    :
                  </span>{' '}
                  <span>{register.lawsuitNumber || '-'}</span>
                </Col>
                <Col>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.judiciaryInformation.columns.court`
                    )}
                    :
                  </span>{' '}
                  <span>{register.court || '-'}</span>
                </Col>
                <Col>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.judiciaryInformation.columns.originEntity`
                    )}
                    :
                  </span>{' '}
                  <span>{register?.entityOrigin || '-'}</span>
                </Col>
              </Row>
            </Col>
            <Divider />
          </>
        ))}
      </Panel>
    </Collapse>
  );
}

export default Lawsuit;
