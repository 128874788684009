import React, { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Modal, message, Button } from 'antd';

import successImg from 'assets/images/icon-success.svg';

import './styles.less';

const CopyLinkModal = () => {
  const { t: translate } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const [onboardingLink, setOnboardingLink] = useState(null);
  const [onboardingLinkType, setOnboardingLinkType] = useState(null);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(({ link, type }) => {
    setOnboardingLink(link);
    setOnboardingLinkType(type);
    setModalVisible(true);
  }, []);

  const CreateLinkModalContent = useMemo(
    () => (
      <Modal
        wrapClassName="caf-modal archives-upload-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        footer={null}
        width={650}
        visible={modalVisible}
        onCancel={closeModal}
      >
        <div className="flex create-link-wrapper flex-column center pdd-vertical-30">
          <img
            src={successImg}
            alt={translate(
              'pages.private.profileModels.profileModelList.components.links.components.createLinkModal.components.copyLinkModal.index.copyLinkModal.img.alt' // => Link gerado com sucesso
            )}
          />
          <h2 className="text-dark text-bold mrg-top-20">
            {translate(
              'pages.private.profileModels.profileModelList.components.links.components.createLinkModal.components.copyLinkModal.index.copyLinkModal.title.before' // => Link
            )}{' '}
            {translate(
              `pages.private.profileModels.profileModelList.components.links.components.createLinkModal.components.copyLinkModal.index.copyLinkModal.title.inside.${
                onboardingLinkType === 'profile' ? 'individual' : 'shared' // => individual : compartilhado
              }`
            )}{' '}
            {translate(
              'pages.private.profileModels.profileModelList.components.links.components.createLinkModal.components.copyLinkModal.index.copyLinkModal.title.after' // => gerado com sucesso
            )}
          </h2>
          <div className="flex data-link-info flex-column center">
            <p className="font-size-18 text-bold text-dark mrg-top-15">
              <a href={onboardingLink} target="_blank" rel="noreferrer">
                {onboardingLink}
              </a>
            </p>
            <CopyToClipboard
              text={onboardingLink}
              onCopy={() => {
                message.success(
                  translate(
                    'pages.private.profileModels.profileModelList.components.links.components.createLinkModal.components.copyLinkModal.index.copyLinkModal.copyToClipboard.onCopy.message' // => Link de onboarding copiado
                  )
                );
                closeModal();
              }}
            >
              <Button className="flex center" type="primary">
                <small className="font-size-16 text-bold">
                  {translate(
                    'pages.private.profileModels.profileModelList.components.links.components.createLinkModal.components.copyLinkModal.index.copyLinkModal.copyToClipboard.button' // => Copiar e fechar
                  )}
                </small>
              </Button>
            </CopyToClipboard>
          </div>
        </div>
      </Modal>
    ),
    [closeModal, modalVisible, onboardingLink, onboardingLinkType, translate]
  );

  return { openModal, CreateLinkModal: CreateLinkModalContent };
};

export default CopyLinkModal;
