import React from 'react';
import ReactDOM from 'react-dom';
import {
  applyPolyfills,
  defineCustomElements,
} from '@combateafraude/web-components/loader';
import './i18n';
import App from './App';

import('@public/locales/pt-BR.json').then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
});

applyPolyfills().then(() => {
  defineCustomElements();
});
