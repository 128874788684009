import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Progress } from 'antd';
import PropTypes from 'prop-types';

import { useUploadManager } from 'hooks/uploadManager';
import { useActivities } from 'hooks/activities';

// import './styles.less';

const { Text } = Typography;

const FileListItem = ({ item }) => {
  const { t: translate } = useTranslation();

  const [progress, setProgress] = useState(item.uploaded ? 100 : 0);
  const [error, setError] = useState(!!item.error);
  const [success, setSuccess] = useState(!!item.uploaded);

  const { upload } = useUploadManager();
  const { refreshActivities } = useActivities();

  const uploadFile = useCallback(() => {
    const { file, ...extraData } = item;
    upload(file, extraData, {
      onProgress: (percent) => {
        setProgress(percent);
      },
      onSuccess: () => {
        setSuccess(true);
        refreshActivities();
      },
      onError: () => {
        setError(true);
      },
    });
  }, [item, upload, refreshActivities]);

  const reuploadFile = useCallback(() => {
    setError(false);
    uploadFile();
  }, [uploadFile]);

  useEffect(() => {
    if (!item || item.uploading || item.uploaded || item.error) return;

    uploadFile();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderStatusIcon = useMemo(() => {
    if (error) {
      return (
        <div className="status-icon status-icon-error">
          <i className="caf-ic_close" />
        </div>
      );
    }
    if (success) {
      return (
        <div className="status-icon status-icon-success">
          <i className="caf-ic_checkmark" />
        </div>
      );
    }

    return (
      <div className="status-icon">
        <i className="caf-ic_processing_line" />
      </div>
    );
  }, [error, success]);

  const statusProgressBar = useCallback(() => {
    if (error) return 'exception';
    if (progress === 100 && item.uploading) return 'active';
    return null;
  }, [error, item.uploading, progress]);

  const statusInfo = useCallback(() => {
    if (item.uploading) return `${progress}%`;
    if (success)
      return translate('components.uploadFilesManager.fileListItem.statusInfo');
    return '';
  }, [item.uploading, progress, success, translate]);

  return (
    <React.Fragment key={item.file.uid}>
      <div className="progress-list-item-header">
        {renderStatusIcon}
        <Text className="text-dark max-lines-1 pdd-right-10" style={{ flex: 1 }}>
          {item.file.name}
        </Text>
        <span className="status-info">{statusInfo()}</span>

        {/* {progress > 0 && progress < 100 && !error && (
          <button type="button" onClick={() => {}} className="delete-file-btn">
            <i className="caf-ic_close" />
          </button>
        )} */}

        {error && (
          <button type="button" onClick={reuploadFile} className="delete-file-btn">
            <i className="caf-ic_upload" />
          </button>
        )}
      </div>
      <Progress
        key={item.file.uid}
        status={statusProgressBar()}
        percent={progress}
        showInfo={false}
        size="small"
        strokeColor="#20c26b"
      />
    </React.Fragment>
  );
};

FileListItem.propTypes = {
  item: PropTypes.shape({
    file: PropTypes.instanceOf(File),
    companyId: PropTypes.string,
    personid: PropTypes.string,
    uploading: PropTypes.bool,
    uploaded: PropTypes.bool,
    error: PropTypes.bool,
  }).isRequired,
};

export default FileListItem;
