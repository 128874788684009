import React from 'react';
import PropTypes from 'prop-types';

import { Card } from 'antd';

import TooltipInfo from 'components/TooltipInfo';

import './styles.less';

const ActivitiesListCard = ({ title, hasInfo, titleInfo, footer, children }) => {
  return (
    <Card id="activities-list-card-content">
      <div className="content">
        <div className="card-header-title">
          <span>
            {title} {hasInfo && <TooltipInfo title={titleInfo} />}
          </span>
        </div>
        {children}
      </div>
      {footer}
    </Card>
  );
};

ActivitiesListCard.propTypes = {
  title: PropTypes.string.isRequired,
  hasInfo: PropTypes.bool,
  titleInfo: PropTypes.string,
  footer: PropTypes.element,
};

ActivitiesListCard.defaultProps = {
  hasInfo: false,
  titleInfo: '-',
  footer: <></>,
};

export default ActivitiesListCard;
