/* eslint-disable no-unused-vars */
import { Card } from 'antd';
import React, { useMemo, useEffect } from 'react';
import profileDefault from 'assets/images/profile-default.png';
import { useProfileTemplate } from 'hooks/profileTemplate';

import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { useI18nFormatters } from 'hooks/i18nFormatters';
import { useTags } from 'hooks/tags';

import { generalStatus } from 'utils/handlerStatus';

import IconStatus from 'pages/private/Profiles/components/IconStatus';
import CardExecutionTag from 'pages/private/Profiles/components/ProfileTag/CardExecutionTag';

import { toMaskedCpf } from 'utils/formatters';

import './styles.less';

function PartnerCard({ data }) {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();
  const { allProfileTemplates, loadProfileTemplates } = useProfileTemplate();

  const { customAllTags: tagsList, loadingAllTags: loadingTags } = useTags();

  const parsedTags = useMemo(() => {
    return data?.tags?.filter((tag) => tag !== null) || [];
  }, [data]);

  const profileTemplateName = useMemo(() => {
    const name = allProfileTemplates?.find(
      (profileTemplate) => profileTemplate?._id === data?.profileTemplateId
    )?.name;

    return name || '-';
  }, [allProfileTemplates, data]);

  useEffect(() => {
    loadProfileTemplates({
      active: 'all',
    });
  }, [loadProfileTemplates]);

  return (
    <div id="card-partner-component" className="overflow-hidden document-card mrg-btm-20">
      <Card>
        <div className="container-card-body">
          <div
            className={`${!data?.image ? 'grayscale-95' : ''} card-image-profile`}
            style={{ backgroundImage: `url(${data?.image || profileDefault})` }}
          />
          <div className="card-body">
            <div className="flex-column card-body-infos">
              <p className="font-size-11 mrg-btm-5 text-semibold profile-template-name">
                {profileTemplateName}
              </p>

              <p title={data?.name || '-'} className="card-title max-lines-1">
                {data?.name || '-'}
              </p>

              <div className="card-info">
                <i className="caf-ic_person" />
                <span className={`${!data?.cpf && 'gx-font-italic'} max-lines-1`}>
                  {toMaskedCpf(data?.cpf) || '-'}
                </span>
              </div>
            </div>

            {data?.status && (
              <div className="card-info-status">
                <IconStatus status={data?.status} />
                <div className="card-info-status-content">
                  <strong>{translate(generalStatus(data?.status)?.value)}</strong>
                  <span className="font-size-12">
                    {translate(
                      'pages.private.profiles.components.list.components.personCard.index.content.updatedAt'
                    )}{' '}
                    {i18nFormatDate(data?.updatedAt || data?.createdAt, 8)}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        {parsedTags?.length > 0 && (
          <div className="flex start-center pdd-top-15 pdd-btm-5 pdd-horizon-15 card-tags-wrapper">
            {!loadingTags && !_.isEmpty(tagsList) && (
              <div id="card-tags">
                {parsedTags?.map((tag) => {
                  return (
                    <CardExecutionTag
                      key={tag}
                      name={tagsList[tag]?.title}
                      color={tagsList[tag]?.color}
                      special={tagsList[tag]?.special}
                      actionDescription={tagsList[tag]?.actionDescription}
                    />
                  );
                })}
              </div>
            )}
          </div>
        )}
      </Card>
    </div>
  );
}

export default PartnerCard;
