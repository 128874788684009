import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam } from 'use-query-params';

import { useExecution } from 'hooks/execution';

import Tabs from 'components/TabNavigation';
import TabPane from 'components/TabNavigation/TabPanel';

import RecurrenceList from 'components/RecurrenceList';
import TransactionRecordsFiles from 'components/Files/TransactionRecordsFiles';

import * as executionDataSources from 'utils/executionDataSource';
import ExecutionDossier from 'components/ExecutionDossier';

import VerifiedIdentity from './components/VerifiedIdentity';
import ComplianceRules from './components/ComplianceRules';
import BasicInfos from './components/BasicInfos';
import NewOcrScren from './components/NewOcrScreen';
import './styles.less';
import UserData from './components/UserData';

const TabsWrapper = () => {
  const { t: translate } = useTranslation();

  const { execution } = useExecution();

  const dataSources = useMemo(() => {
    return Object.keys(execution?.sections || {})?.map((source) => source) || [];
  }, [execution]);

  const [query, setQuery] = useQueryParams({
    tab: StringParam,
  });

  const tabNav = (icon, name) => (
    <div className="tab-item">
      <i className={`tab-item-icon caf-ic_${icon}`} /> {name}
    </div>
  );

  const showDocumentsTab = useMemo(
    () =>
      execution?.mappedValidations?.find((v) => v.rule === 'valid_document')?.status !==
      'DISABLED',
    [execution]
  );

  const showIdentityTab = useMemo(
    () =>
      execution?.mappedValidations?.find((v) => v.rule === 'valid_identity')?.status !==
      'DISABLED',
    [execution]
  );

  const showBasicInfosTab = useMemo(() => {
    const sources = ['pfEmails', 'pfPhones', 'pjEmails', 'pjPhones'];

    const foundSource = sources.find((s) =>
      executionDataSources.findSourceSection(execution, s)
    );

    return (
      executionDataSources.hasBasicInformation(execution, dataSources) ||
      foundSource ||
      dataSources?.includes('pf_phones') ||
      dataSources?.includes('pf_emails') ||
      execution?.contact?.phones ||
      execution?.contact?.emails ||
      execution?.parameters?.address ||
      execution?.parameters?.email ||
      execution?.parameters?.phone ||
      execution?.parameters?.name
    );
  }, [dataSources, execution]);

  const showTransactionRecordsFilesTab = useMemo(() => !!execution?.workflowId, [
    execution,
  ]);

  const hasTrustValidations = useMemo(() => {
    return executionDataSources.hasTrustInformation(execution, dataSources);
  }, [execution, dataSources]);

  const hasPjSintegraData = useMemo(() => {
    return executionDataSources.hasPjSintegraData(execution, dataSources);
  }, [execution, dataSources]);

  const activeKey = useMemo(() => {
    if (query.tab === 'dossier') {
      if (hasTrustValidations) return 'dossier';
      return 'compliance';
    }
    if (query.tab === 'documents') {
      if (showDocumentsTab) return 'documents';
      return 'compliance';
    }
    if (query.tab === 'identity') {
      if (showIdentityTab) return 'identity';
      return 'compliance';
    }
    return query.tab;
  }, [hasTrustValidations, query.tab, showDocumentsTab, showIdentityTab]);

  return (
    <section id="executions-tabs-wrapper">
      <Tabs
        defaultActiveKey={query.tab || 'documents'}
        activeKey={activeKey}
        onChangeTab={(tab) => setQuery({ tab })}
      >
        {showBasicInfosTab && (
          <TabPane
            tab={tabNav(
              'documentation',
              translate('pages.private.executions.components.tabs.index.tabs.basicInfos')
            )}
            key="basicInfos"
          >
            <BasicInfos />
          </TabPane>
        )}

        {(hasTrustValidations || hasPjSintegraData) && (
          <TabPane
            tab={tabNav(
              'processing_line',
              translate('pages.private.executions.components.tabs.index.tabs.dossier')
            )}
            key="dossier"
          >
            <ExecutionDossier />
          </TabPane>
        )}
        {showDocumentsTab && (
          <TabPane
            tab={tabNav(
              'onboardind_big',
              translate('pages.private.executions.components.tabs.index.tabs.documents')
            )}
            key="documents"
          >
            <NewOcrScren />
          </TabPane>
        )}
        {showIdentityTab && (
          <TabPane
            tab={tabNav(
              'faceid',
              translate('pages.private.executions.components.tabs.index.tabs.identity')
            )}
            key="identity"
          >
            <VerifiedIdentity />
          </TabPane>
        )}

        <TabPane
          tab={tabNav(
            'admin',
            translate('pages.private.executions.components.tabs.index.tabs.compliance')
          )}
          key="compliance"
        >
          <ComplianceRules />
        </TabPane>

        <TabPane
          tab={tabNav(
            'clock',
            translate('pages.private.executions.components.tabs.index.tabs.activities')
          )}
          key="activities"
        >
          <RecurrenceList />
        </TabPane>
        {showTransactionRecordsFilesTab && (
          <TabPane
            tab={tabNav(
              'file',
              translate('pages.private.executions.components.tabs.index.tabs.files')
            )}
            key="files"
          >
            <TransactionRecordsFiles />
          </TabPane>
        )}
        <TabPane
          tab={tabNav(
            'person',
            translate('pages.private.executions.components.tabs.index.tabs.userData')
          )}
          key="userData"
        >
          <UserData />
        </TabPane>
      </Tabs>
    </section>
  );
};

export default TabsWrapper;
