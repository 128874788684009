import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Row, Col, Typography } from 'antd';

import RectangleButton from 'components/Button/RectangleButton';
import Button from 'components/Button';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';
import { useAuth } from 'hooks/auth';

import CreateLinkType from './components/CreateLinkType';

import './styles.less';

const I18N_BASE_PATH =
  'pages.private.profileModels.profileModelList.components.links.components.createLinkModal.index.createLinkModal';

const CreateLinkModal = ({ refreshListRef = () => {}, segment }) => {
  const { t: translate, i18n } = useTranslation();
  const { user } = useAuth();

  const [modalVisible, setModalVisible] = useState(false);
  const [linkType, setLinkType] = useState(undefined);
  const [linkModalContent, setLinkModalContent] = useState('initial');

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setLinkType(undefined);
  }, []);

  const openModal = useCallback(() => {
    setLinkModalContent('initial');
    setModalVisible(true);
  }, []);

  const singleUseLinkDescription = useMemo(() => {
    if (!hasFeatureFlag('isWorkflowBuilder', user)) {
      return translate(`${I18N_BASE_PATH}.button.individual.description`);
    }

    if (i18n.language === 'en-US') {
      if (segment === 'person') {
        return translate(
          `${I18N_BASE_PATH}.button.individual.descriptionWorkflow.person`
        );
      }

      if (segment === 'company') {
        return translate(
          `${I18N_BASE_PATH}.button.individual.descriptionWorkflow.company`
        );
      }
    }

    return translate(`${I18N_BASE_PATH}.button.individual.descriptionWorkflow`);
  }, [i18n, segment, translate, user]);

  const CreateLinkModalContent = useMemo(
    () => (
      <Modal
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        footer={null}
        width={linkModalContent === 'initial' ? 775 : 815}
        visible={modalVisible}
        onCancel={closeModal}
      >
        <div id="create-link-modal-wrapper" className="no-mrg no-pdd">
          {linkModalContent === 'initial' && (
            <section className="mrg-top-42 ant-form-vertical">
              <Row align="middle" justify="center">
                <Typography className="gx-modal-title">
                  {translate(`${I18N_BASE_PATH}.title`)}
                </Typography>
              </Row>
              <Row align="middle" justify="center" className="mrg-vertical-50">
                <Col span={10}>
                  <RectangleButton
                    title={translate(`${I18N_BASE_PATH}.button.individual.title`)}
                    icon="caf-ic_person"
                    selected={linkType === 'profile'}
                    onClick={() => setLinkType('profile')}
                    description={singleUseLinkDescription}
                  />
                </Col>

                <Col span={10}>
                  <RectangleButton
                    title={translate(`${I18N_BASE_PATH}.button.shared.title`)}
                    icon="caf-ic_news"
                    selected={linkType === 'shared'}
                    onClick={() => setLinkType('shared')}
                    description={translate(`${I18N_BASE_PATH}.button.shared.description`)}
                  />
                </Col>
              </Row>

              <div className="gx-flex-row gx-justify-content-end">
                <Button type="default" onClick={closeModal} className="btn-cancel">
                  {translate(`${I18N_BASE_PATH}.button.cancel`)}
                </Button>
                <Button
                  type="primary"
                  disabled={!linkType}
                  onClick={() => setLinkModalContent(linkType)}
                >
                  {translate(`${I18N_BASE_PATH}.button.continue`)}
                </Button>
              </div>
            </section>
          )}
          {linkModalContent === 'profile' && (
            <CreateLinkType
              refreshListRef={refreshListRef}
              onClose={closeModal}
              type="profile"
              segment={segment}
            />
          )}
          {linkModalContent === 'shared' && (
            <CreateLinkType
              refreshListRef={refreshListRef}
              onClose={closeModal}
              type="shared"
              segment={segment}
            />
          )}
        </div>
      </Modal>
    ),
    [
      linkModalContent,
      modalVisible,
      closeModal,
      translate,
      linkType,
      refreshListRef,
      segment,
      singleUseLinkDescription,
    ]
  );

  return { openModal, CreateLinkModal: CreateLinkModalContent };
};

export default CreateLinkModal;
