import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'antd';

import { useCompany } from 'hooks/company';
import { usePerson } from 'hooks/person';

import * as pfDataSources from 'utils/pfDataSource';

import CompanyRegistrationInfosContent from './components/Company/RegistrationInfosContent';
import CompanyQSAInfosContent from './components/Company/QSAInfosContent';
import CompanyContactContent from './components/Company/ContactContent';
import CompanyAddressContent from './components/Company/AddressContent';

import PersonRegistrationInfosContent from './components/Person/RegistrationInfosContent';
import PersonContactContent from './components/Person/ContactContent';
import PersonAddressContent from './components/Person/AddressContent';

import './styles.less';
import './components/Company/styles.less';

const BasicInfos = () => {
  const { companyId, personId } = useParams();

  const { company } = useCompany();
  const { person } = usePerson();

  const dataSources = useMemo(() => {
    const pfData =
      person?.profileTemplate?.backgroundChecking?.services?.map(
        ({ source }) => source
      ) || [];
    const pjData =
      company?.profileTemplate?.backgroundChecking?.services?.map(
        ({ source }) => source
      ) || [];
    return { pfData, pjData };
  }, [person, company]);

  return (
    <Card id="basic-infos-component">
      {companyId && (
        <>
          <CompanyRegistrationInfosContent company={company} />
          <CompanyQSAInfosContent company={company} />
          <CompanyAddressContent company={company} />
          <CompanyContactContent company={company} />
        </>
      )}

      {personId && (
        <>
          <PersonRegistrationInfosContent
            person={person}
            dataSources={dataSources.pfData}
          />
          {pfDataSources.hasAddressInformation(person, dataSources.pfData) && (
            <PersonAddressContent person={person} dataSources={dataSources.pfData} />
          )}
          {pfDataSources.hasContactsInformation(person, dataSources.pfData) && (
            <PersonContactContent person={person} dataSources={dataSources.pfData} />
          )}
        </>
      )}
    </Card>
  );
};

export default BasicInfos;
