import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

const conditionType = (condition) => {
  if (!condition) return 'default';

  switch (condition) {
    case 'AGRAVO DE PETICAO':
      return 'danger';
    case 'EXECUCAO PROVISORIA EM AUTOS SUPLEMENTARES':
      return 'danger';
    case 'EXECUCAO DE TITULO EXTRAJUDICIAL':
      return 'danger';
    case 'BUSCA E APREENSAO':
      return 'danger';
    default:
      return 'default';
  }
};

const statusInfos = (status) => {
  if (!status) return null;

  switch (status?.toUpperCase()) {
    case 'DEFENDANT':
    case 'REU':
      return {
        name:
          'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.processesDetails.statusInfos.REU',
        type: 'danger',
      };
    case 'AUTOR DO FATO':
      return {
        name:
          'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.processesDetails.statusInfos.AUTOR_DO_FATO',
        type: 'danger',
      };
    case 'AUTUADO':
      return {
        name:
          'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.processesDetails.statusInfos.AUTUADO',
        type: 'danger',
      };
    case 'ACUSADO':
      return {
        name:
          'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.processesDetails.statusInfos.ACUSADO',
        type: 'danger',
      };
    case 'INDICIADO':
      return {
        name:
          'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.processesDetails.statusInfos.INDICIADO',
        type: 'danger',
      };
    case 'CONDENADO':
      return {
        name:
          'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.processesDetails.statusInfos.CONDENADO',
        type: 'danger',
      };
    case 'CLAIMED':
      return {
        name:
          'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.processesDetails.statusInfos.CLAIMED',
        type: 'default',
      };
    case 'CLAIMANT':
      return {
        name:
          'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.processesDetails.statusInfos.CLAIMANT',
        type: 'default',
      };
    case 'AUTHOR':
      return {
        name:
          'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.processesDetails.statusInfos.AUTHOR',
        type: 'default',
      };
    case 'LAWYER':
      return {
        name:
          'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.processesDetails.statusInfos.LAWYER',
        type: 'default',
      };
    case 'OTHER':
      return {
        name:
          'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.processesDetails.statusInfos.OTHER',
        type: 'default',
      };
    case 'REPORTER':
      return {
        name:
          'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.processesDetails.statusInfos.REPORTER',
        type: 'default',
      };
    case 'POLO PASSIVO':
      return {
        name:
          'components.dataSourceCards.legalInformation.componentes.processes.utils.processesDetails.statusInfos.POLO_PASSIVO',
        type: 'danger',
      };
    default:
      return {
        name: capitalizeFirstLetter(status),
        type: 'default',
      };
  }
};

const courtInfos = (court) => {
  if (!court) return null;

  switch (court?.toUpperCase()) {
    case 'CIVEL':
      return {
        name:
          'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.processesDetails.courtInfos.CIVEL',
        type: 'default',
      };
    case 'CIVEL / ADMINISTRATIVO':
      return {
        name:
          'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.processesDetails.courtInfos.CIVEL_ADMINISTRATIVO',
        type: 'default',
      };
    case 'ESPECIAL CIVEL':
      return {
        name:
          'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.processesDetails.courtInfos.ESPECIAL_CIVEL',
        type: 'default',
      };
    case 'CIVIL':
      return {
        name:
          'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.processesDetails.courtInfos.CIVIL',
        type: 'default',
      };
    case 'TRABALHISTA':
      return {
        name:
          'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.processesDetails.courtInfos.TRABALHISTA',
        type: 'default',
      };
    case 'TRIBUTARIA':
      return {
        name:
          'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.processesDetails.courtInfos.TRIBUTARIA',
        type: 'default',
      };
    case 'CRIMINAL':
      return {
        name:
          'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.processesDetails.courtInfos.CRIMINAL',
        type: 'danger',
      };
    default:
      return {
        name: court?.toLowerCase(),
        type: 'default',
      };
  }
};

export { conditionType, statusInfos, courtInfos };
