import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { executionStatus } from 'utils/handlerStatusInternational';
import { useExecution } from 'hooks/execution';
import { useComplyData } from 'hooks/useComplyData';
import Countdown from 'components/Countdown';

import useManualReviewModal from './ModalManualReview/index';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.executions.components.generalStatus.index';

const InternationalGeneralStatus = () => {
  const { t: translate } = useTranslation();
  const { execution, refreshExecution } = useExecution();

  const { resultsApproved, resultsReproved, dataSource } = useComplyData();

  const { openModal, ManualReviewModal } = useManualReviewModal({
    data: execution,
    resultsApproved,
    resultsReproved,
    dataSource,
  });

  const complyServices = [
    'kycSanctionsEmergingCountries',
    'kycPepsEmergingCountries',
    'kycSanctionsLatamCountries',
    'kycPepsLatamCountries',
    'kycAdverseMediaEmergingCountries',
    'kycAdverseMediaLatamCountries',
    'kycWarningsEmergingCountries',
    'kycWarningsLatamCountries',
    'sanctions',
  ];

  const services = Object.values(execution.sections).map((section) => section.service);
  const servicesFromComply = complyServices.find((service) => services.includes(service));

  const showMissingDataStatus = useMemo(() => {
    if (execution?.status !== 'PENDING') return false;

    const isThereAnyValidationPending = execution?.validations?.some(
      (validation) => validation?.status === 'PENDING'
    );

    return isThereAnyValidationPending;
  }, [execution]);

  const showRequiredModalComply = useMemo(() => {
    if (servicesFromComply && servicesFromComply?.length > 0) {
      if (resultsApproved.length > 0 || resultsReproved.length > 0) {
        return false;
      }
      return true;
    }
    return false;
  }, [servicesFromComply, resultsApproved, resultsReproved]);

  const statusDescription = useMemo(() => {
    if (!execution) return [];

    if (execution?.status === 'PROCESSING') {
      const pendingDocumentscopyValidation = execution?.validations?.find(
        (v) => v.rule === 'documentscopy_approved' && v.status === 'PENDING'
      );

      if (pendingDocumentscopyValidation) {
        return pendingDocumentscopyValidation?.description;
      }

      return translate(executionStatus(execution?.status)?.description);
    }

    if (execution?.status === 'PENDING') {
      const invalidChecks =
        execution?.validations?.filter((v) => v.status === 'INVALID') || [];

      const reprovalReasons =
        execution?.reprovalReasons?.map((r) => {
          return { description: r };
        }) || [];

      const statusList = [...reprovalReasons, ...invalidChecks];

      if (statusList?.length > 1) {
        return (
          <ol>
            {statusList?.map((validation) => (
              <li style={{ marginTop: 4 }} key={validation.description}>
                {validation.description}
              </li>
            ))}
          </ol>
        );
      }

      if (statusList[0]?.description.length > 0) {
        return <span>{statusList[0]?.description || ''}</span>;
      }
    }

    if (
      execution?.status === 'APPROVED' &&
      execution?.approvalReasons &&
      execution?.approvalReasons?.length > 0
    ) {
      if (execution?.approvalReasons?.length > 1) {
        return (
          <ol>
            {execution?.approvalReasons?.map((reason) => (
              <li key={reason}>{reason}</li>
            ))}
          </ol>
        );
      }

      return <span>{execution?.approvalReasons[0] || ''}</span>;
    }

    if (execution?.statusReasons && execution?.statusReasons?.length > 0) {
      if (execution?.statusReasons?.length > 1) {
        return (
          <ol>
            {execution?.statusReasons?.map((reason) => (
              <li key={reason}>{reason}</li>
            ))}
          </ol>
        );
      }

      return <span>{execution?.statusReasons[0] || ''}</span>;
    }

    return <></>;
  }, [execution, translate]);

  return (
    <>
      {ManualReviewModal}
      {showMissingDataStatus ? (
        <div
          id="general-status-card-executions"
          style={{
            backgroundColor: executionStatus('PENDING')?.color,
          }}
        >
          <div className="general-status-info">
            <i className={`${executionStatus('PENDING')?.icon} general-status-icon`} />
            <div className="general-status-description">
              <strong>
                {translate(`${I18N_BASE_PATH}.missingInfos.pendingManualReview.title`)}
              </strong>
              <span>
                {translate(
                  `${I18N_BASE_PATH}.missingInfos.pendingManualReview.description`
                )}
              </span>
            </div>
          </div>
          <div className="general-status-actions">
            <button
              type="button"
              className="ant-btn-text"
              onClick={() =>
                openModal({
                  isActionRequired: showRequiredModalComply,
                })
              }
            >
              {translate(
                `${I18N_BASE_PATH}.missingInfos.pendingManualReview.reviewButtonText`
              )}
            </button>
          </div>
        </div>
      ) : (
        <>
          {translate(executionStatus(execution?.status)?.value) && (
            <div
              id="general-status-card-executions"
              style={{
                backgroundColor: executionStatus(execution?.status)?.color,
              }}
            >
              <div className="general-status-info">
                <i
                  className={`${
                    executionStatus(execution?.status)?.icon
                  } general-status-icon`}
                />
                <div className="general-status-description">
                  <strong>
                    {execution?.customStatus
                      ? execution?.customStatus
                      : translate(executionStatus(execution?.status)?.display)}
                  </strong>
                  {statusDescription}
                  <br />
                  {execution?.status === 'PROCESSING' && (
                    <Countdown
                      prefix={`${I18N_BASE_PATH}.processingCountdown.prefix`}
                      suffix={`${I18N_BASE_PATH}.processingCountdown.suffix`}
                      onFinish={() => refreshExecution()}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default InternationalGeneralStatus;
