/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Spin } from 'antd';

import { isEmpty } from 'utils/isObjectEmpty';

import DossierCard from 'pages/private/Profiles/components/DossierCard';
// import TableInfoCard from 'pages/private/Profiles/components/TableInfoCard/Column';

import ValidationsImagesCard from '../Shared/ValidationsImagesCard';
import ValidationsCNHData from '../Shared/ValidationsCNHData';
import ValidationsRGData from '../Shared/ValidationsRGData';
import ValidationsRNMData from '../Shared/ValidationsRNMData';
import EmptyDocuments from '../Shared/EmptyDocuments';
// import ValidationsDocumentscopyCard from '../Shared/ValidationsDocumentscopyCard';

const AuthenticDocument = ({ data, loading, status }) => {
  const { t: translate } = useTranslation();

  const statusDetails = useMemo(() => {
    if (!status) return null;

    switch (status?.toUpperCase()) {
      case 'VALID':
        return {
          type: 'primary',
          icon: 'caf-ic_checkmark',
          default: translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.authenticDocument.index.statusDetails.valid'
          ),
        };
      case 'INVALID':
        return {
          type: 'danger',
          icon: 'caf-ic_close',
          default: translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.authenticDocument.index.statusDetails.invalid'
          ),
        };
      default:
        return null;
    }
  }, [status, translate]);

  return (
    <DossierCard
      title={translate(
        'pages.private.profiles.components.tabs.components.documentsValidations.components.authenticDocument.index.authenticDocument.dossierCard.title'
      )}
      subtitle={translate(
        'pages.private.profiles.components.tabs.components.documentsValidations.components.authenticDocument.index.authenticDocument.dossierCard.subtitle'
      )}
      icon="caf-ic_onboarding"
      customId="authentic-document-card"
    >
      {loading && (
        <div className="custom-document-loading">
          <Spin />
        </div>
      )}

      {!loading && (
        <>
          {(!data || isEmpty(data)) && <EmptyDocuments type="authentic-document" />}

          {data && !isEmpty(data) && (
            <>
              {status && (
                <Row
                  className={`document-validation-status reset-mrg ${statusDetails?.type}`}
                >
                  <i className={statusDetails?.icon} />{' '}
                  <span>
                    {translate(data?.statusMessage, { value: data?.value }) ||
                      statusDetails?.default}
                  </span>
                </Row>
              )}

              <ValidationsImagesCard
                firstImageDescription={translate(
                  'pages.private.profiles.components.tabs.components.documentsValidations.components.authenticDocument.index.authenticDocument.validationsImagesCard.firstImageDescription'
                )}
                firstImage={data?.front}
                secondImageDescription={translate(
                  'pages.private.profiles.components.tabs.components.documentsValidations.components.authenticDocument.index.authenticDocument.validationsImagesCard.secondImageDescription'
                )}
                secondImage={data?.back}
                customTop={status ? '15px' : '25px'}
              />

              <Row className="custom-validations-title reset-mrg">
                <strong>
                  {translate(
                    'pages.private.profiles.components.tabs.components.documentsValidations.components.authenticDocument.index.authenticDocument.title'
                  )}
                </strong>
              </Row>

              {data?.type === 'cnh' && <ValidationsCNHData data={data?.ocr} />}

              {data?.type === 'rg' && <ValidationsRGData data={data?.ocr} />}

              {data?.type === 'rnm' && <ValidationsRNMData data={data?.ocr} />}

              {data?.type !== 'cnh' && data?.type !== 'rg' && data?.type !== 'rnm' && (
                <Row>
                  <Col span={24}>
                    <span className="text-dark gx-font-italic font-size-15">
                      {translate(
                        'pages.private.profiles.components.tabs.components.documentsValidations.components.authenticDocument.index.authenticDocument.documentIsNotCnhOrRg'
                      )}
                    </span>
                  </Col>
                </Row>
              )}

              {/* <Row className="custom-validations-title reset-mrg">
                <strong>DOCUMENTOSCOPIA</strong>
              </Row>

              <ValidationsDocumentscopyCard />
          */}
            </>
          )}
        </>
      )}
    </DossierCard>
  );
};

export default AuthenticDocument;
