import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Progress } from 'antd';
import { useQueryParams, StringParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';

import './styles.less';

const I18N_BASE_PATH =
  'pages.private.profileModels.profileModelCreate.backgroundCheckingStep.footer';

const Footer = ({
  buttonCancelTitle,
  buttonSubmitTitle,
  onCancel,
  onSubmit,
  disabled,
  loading,
  hideCancel,
}) => {
  const { t: translate } = useTranslation();

  const [query] = useQueryParams({
    step: StringParam,
  });

  const stepPercentage = useMemo(() => {
    switch (query.step) {
      case 'onboarding_services':
        return 20;
      case 'background_checking':
        return 40;
      case 'compliance_rules':
        return 60;
      case 'confirmation':
        return 80;
      default:
        return 0;
    }
  }, [query.step]);

  return (
    <div id="profile-model-create-footer-component">
      <div className="gx-main-content-wrapper">
        <Row align="middle">
          <Col span={4}>
            <Progress percent={stepPercentage} strokeColor="#004af7" showInfo={false} />
          </Col>
          <Col span={20}>
            <div className="gx-flex-row gx-justify-content-end">
              {!hideCancel && (
                <Button type="default" onClick={onCancel} className="btn-cancel">
                  {buttonCancelTitle ?? translate(`${I18N_BASE_PATH}.cancel`)}
                </Button>
              )}
              <Button
                type="primary"
                onClick={onSubmit}
                disabled={disabled || loading}
                loading={loading}
              >
                {buttonSubmitTitle ?? translate(`${I18N_BASE_PATH}.submit`)}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

Footer.propTypes = {
  buttonCancelTitle: PropTypes.string,
  buttonSubmitTitle: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  hideCancel: PropTypes.bool,
};

Footer.defaultProps = {
  buttonCancelTitle: null,
  buttonSubmitTitle: null,
  onCancel: () => {},
  disabled: false,
  loading: false,
  hideCancel: false,
};

export default Footer;
