import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Timeline } from 'antd';
import { format, parseISO } from 'date-fns';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import { cpfTranslation } from 'utils/personalTranslations';

import Card from 'components/Card';
import DottedLabel from 'components/DottedLabel';

import IconStatus from 'pages/private/Profiles/components/IconStatus';

import { i18nDateFormats } from 'i18n/utils/i18nFormats';

import {
  CardNewExecution,
  CardNewData,
  CardNewAction,
  ProcessesCard,
  ProtestsCard,
} from './components';

import { eventTypes } from './utils';

import './styles.less';

const CardRecurrenceEvent = ({ data, toPrint = false }) => {
  const { t: translate, i18n } = useTranslation();
  const { i18nFormatDate, i18nGetDateFnsLocale } = useI18nFormatters();

  const { personId, executionId } = useParams();

  const formattedDate = useMemo(() => {
    // const newDate = new Date(new Date(data.date).setHours(0, 0, 0, 0)).toISOString();

    const day = format(parseISO(data.date), 'dd', {
      locale: i18nGetDateFnsLocale(),
    });
    const month = format(parseISO(data.date), 'MMM', {
      locale: i18nGetDateFnsLocale(),
    });

    return { day, month };
  }, [data, i18nGetDateFnsLocale]);

  const formattedTimeline = useMemo(() => {
    const { items } = data || [];

    return items?.map((item) => {
      const createdBy = item?.createdBy?.name || 'Trust Monitor';
      const createdAt = format(
        parseISO(
          item?.data?.createdAt || item?.createdAt || item?.date || item?.data?.date
        ),
        `cccc, ${i18nDateFormats[4][i18n?.language]}`,
        {
          locale: i18nGetDateFnsLocale(),
        }
      );
      const footer = (
        <div className="card-footer">
          <span className="gx-font-italic gx-font-weight-light font-size-12 pdd-top-5 pdd-btm-5">
            {createdAt}{' '}
            {translate(
              'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.index.formattedTimeline.by'
            )}{' '}
            {createdBy}
          </span>
        </div>
      );

      const icon = (
        <IconStatus status={item?.status || item?.data?.status || 'PROCESSANDO'} />
      );

      const manualChangeIcon = (
        <IconStatus status={item?.status || 'PROCESSANDO'} customIcon="caf-ic_edit_2" />
      );

      if (item?.type === eventTypes.NEW_EXECUTION) {
        const name = [
          item?.data?.recurrenceModel?.name,
          item?.data?.recurrenceModel?.recurrence?.name,
        ].join(' - ');
        const reportName = item?.data?.report?.name;

        const documentscopyRequest =
          item?.data?.documentscopyRequestDate ||
          item?.data?.execution?.documentscopyRequestDate;

        const customId = item?.data?.executionId;

        const newExecutionIcon = executionId ? <IconStatus status="APPROVED" /> : icon;
        const newExecutionTitle = executionId
          ? translate(
              'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.index.formattedTimeline.eventTypesNewExecution.initial'
            )
          : undefined;

        return (
          <Timeline.Item key={item?._id} dot={newExecutionIcon} id={customId}>
            <CardNewExecution
              status={item?.status}
              title={newExecutionTitle}
              name={name}
              reportName={reportName}
              execution={item?.execution}
              type={item?.data?.executionType}
              firstExecution={item?.data?.firstExecution}
              documentscopyRequest={documentscopyRequest}
              toPrint={toPrint}
            >
              {footer}
            </CardNewExecution>
          </Timeline.Item>
        );
      }

      if (item?.type === eventTypes.NEW_DOCUMENT) {
        return (
          <Timeline.Item key={item?._id} dot={icon}>
            <CardNewExecution
              status={item?.status}
              name={translate(
                'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.index.formattedTimeline.cardNewExecution.newDocument.name'
              )}
              execution={item?.execution}
              firstExecution={item?.data?.firstExecution}
              toPrint={toPrint}
            >
              {footer}
            </CardNewExecution>
          </Timeline.Item>
        );
      }

      if (item?.type === eventTypes.SENT_TO_TSCOPY) {
        return (
          <Timeline.Item key={item?._id} dot={<IconStatus status="DOCUMENTSCOPY" />}>
            <CardNewExecution
              status={item?.status}
              title={translate(
                'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.index.formattedTimeline.eventTypesSentToTscopy.cardNewExecution.title'
              )}
              name={translate(
                'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.index.formattedTimeline.eventTypesSentToTscopy.cardNewExecution.name'
              )}
              execution={item?.execution}
              firstExecution={item?.data?.firstExecution}
              toPrint={toPrint}
            >
              {footer}
            </CardNewExecution>
          </Timeline.Item>
        );
      }

      if (item?.type === eventTypes.DOCUMENTSCOPY_FINALIZED) {
        let customIconStatus;

        if (['APPROVED', 'APROVADO'].includes(item?.data?.status?.toUpperCase())) {
          customIconStatus = 'icon-custom-status-approved';
        }

        if (['REPROVED', 'REPROVADO'].includes(item?.data?.status?.toUpperCase())) {
          customIconStatus = 'icon-custom-status-reproved';
        }

        return (
          <Timeline.Item
            key={item?._id}
            dot={
              <IconStatus
                customIconStatus={customIconStatus}
                customIcon="caf-ic_onboardind_big"
              />
            }
          >
            <CardNewAction
              title={translate(
                'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.index.formattedTimeline.eventTypesTscopyFinalized.cardNewExecution.title'
              )}
              data={item?.data}
              type={item?.type}
              toPrint={toPrint}
            >
              {footer}
            </CardNewAction>
          </Timeline.Item>
        );
      }

      if (item?.type === eventTypes.EXECUTION_FINALIZED) {
        return (
          <Timeline.Item
            key={item?._id}
            dot={<IconStatus customIcon="caf-ic_checkmark" />}
          >
            <CardNewAction
              title={translate(
                'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.index.formattedTimeline.eventTypesExecutionFinalized.cardNewExecution.title'
              )}
              data={item?.data}
              type={item?.type}
              toPrint={toPrint}
            >
              {footer}
            </CardNewAction>
          </Timeline.Item>
        );
      }

      if (item?.type === eventTypes.NEW_MIGRATED_PROFILE) {
        return (
          <Timeline.Item key={item?._id} dot={icon}>
            <CardNewExecution
              status={item?.status}
              name={translate(
                'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.index.formattedTimeline.cardNewExecution.newMigratedProfile.name'
              )}
              execution={item?.execution}
              firstExecution={item?.data?.firstExecution}
              toPrint={toPrint}
            >
              {footer}
            </CardNewExecution>
          </Timeline.Item>
        );
      }

      if (item?.type === eventTypes.NEW_CPF_QUERY) {
        const CpfDescription = () => {
          const cpfData =
            cpfTranslation[
              item?.data?.registrationStatusMessage ||
                parseInt(item?.data?.registrationStatusCode, 10)?.toString() ||
                'NULA'
            ];

          return (
            <div className="cpf-query-details">
              <span className="font-size-15 mrg-right-5 mrg-btm-5">
                {translate(
                  'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.index.formattedTimeline.cardNewExecution.newCpfQuery.name.before'
                )}{' '}
                {i18nFormatDate(item?.data?.createdAt, 1)}{' '}
                {translate(
                  'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.index.formattedTimeline.cardNewExecution.newCpfQuery.name.after'
                )}
              </span>
              <DottedLabel
                description={translate(cpfData.description)}
                type={cpfData.type}
              />
            </div>
          );
        };

        return (
          <Timeline.Item key={item?._id} dot={icon}>
            <CardNewExecution
              title={translate(
                'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.index.formattedTimeline.cardNewExecution.default.title'
              )}
              status={item?.status}
              name={translate(
                'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.index.formattedTimeline.cardNewExecution.default.name'
              )}
              execution={item?.execution}
              firstExecution={item?.data?.firstExecution}
              description={<CpfDescription />}
              toPrint={toPrint}
            >
              {footer}
            </CardNewExecution>
          </Timeline.Item>
        );
      }

      if (item?.type === eventTypes.NEW_SELFIE) {
        return (
          <Timeline.Item key={item?._id} dot={icon}>
            <CardNewExecution
              status={item?.status}
              name={translate(
                'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.index.formattedTimeline.cardNewExecution.newSelfie.name'
              )}
              execution={item?.execution}
              firstExecution={item?.data?.firstExecution}
              toPrint={toPrint}
            >
              {footer}
            </CardNewExecution>
          </Timeline.Item>
        );
      }

      if (item?.type === eventTypes.NEW_DATA_PROCESSES) {
        return (
          <Timeline.Item key={item?._id} dot={icon}>
            <ProcessesCard
              updateData
              data={item?.data?.processes || []}
              toPrint={toPrint}
            >
              {footer}
            </ProcessesCard>
          </Timeline.Item>
        );
      }

      if (item?.type === eventTypes.NEW_DATA) {
        return (
          <Timeline.Item key={item?._id} dot={icon}>
            <CardNewData
              data={item.data?.updateData || {}}
              type={
                personId
                  ? translate(
                      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.index.type.person'
                    )
                  : translate(
                      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.index.type.company'
                    )
              }
              toPrint={toPrint}
            >
              {footer}
            </CardNewData>
          </Timeline.Item>
        );
      }

      if (item?.type === eventTypes.NEW_DATA_PROTESTS) {
        return (
          <Timeline.Item key={item?._id} dot={icon}>
            <ProtestsCard
              data={{ finance: { protest: { items: [...item?.data?.protests] } } }}
              toPrint={toPrint}
            >
              {footer}
            </ProtestsCard>
          </Timeline.Item>
        );
      }

      if (
        item?.type === eventTypes.MANUAL_CHANGE ||
        item?.type === eventTypes.MANUAL_RELATIONSHIP ||
        item?.type === eventTypes.MANUAL_REVISION
      ) {
        const manualRevisionTypes = [
          'TRUST_STATUS_CHANGE',
          'STATUS_CHANGE',
          'SUPPORT_STATUS_CHANGE',
        ];
        const executionIcon =
          executionId && manualRevisionTypes.includes(item?.data?.type?.toUpperCase()) ? (
            <IconStatus status={item?.data?.status} />
          ) : (
            manualChangeIcon
          );

        let executionTitle;

        if (executionId) {
          if (item?.data?.type?.toUpperCase() === 'MANUAL_OCR') {
            executionTitle = translate(
              'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.index.formattedTimeline.eventTypesSentToTscopyManualChange.manualOcr'
            );
          } else if (manualRevisionTypes.includes(item?.data?.type?.toUpperCase())) {
            executionTitle = translate(
              'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.index.formattedTimeline.eventTypesSentToTscopyManualChange.manualReview'
            );
          } else {
            executionTitle = translate(
              'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.index.formattedTimeline.eventTypesSentToTscopyManualChange.att'
            );
          }
        } else if (item?.type === eventTypes.MANUAL_RELATIONSHIP) {
          executionTitle = translate(
            'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.index.formattedTimeline.eventTypesSentToTscopyManualChange.manualRelationship'
          );
          item.data.type = eventTypes.MANUAL_RELATIONSHIP;
        } else if (item?.type === eventTypes.MANUAL_REVISION) {
          executionTitle = translate(
            'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.index.formattedTimeline.eventTypesSentToTscopyManualChange.manualRelationship'
          );
          item.data.type = eventTypes.MANUAL_REVISION;
        }

        return (
          <Timeline.Item key={item?._id} dot={executionIcon}>
            <CardNewAction
              title={executionTitle}
              data={item?.data}
              type={item?.data?.type}
              toPrint={toPrint}
            >
              {footer}
            </CardNewAction>
          </Timeline.Item>
        );
      }

      if (item?.type === eventTypes.MANUAL_RULE_CHANGE) {
        const icon = manualChangeIcon;
        const title = translate(
          'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.index.manualRuleChange.title'
        );

        return (
          <Timeline.Item key={item?._id} dot={icon}>
            <CardNewAction
              title={title}
              data={item?.data}
              type={item?.type}
              toPrint={toPrint}
            >
              {footer}
            </CardNewAction>
          </Timeline.Item>
        );
      }
      return <></>;
    });
  }, [
    data,
    i18n,
    i18nGetDateFnsLocale,
    translate,
    executionId,
    i18nFormatDate,
    personId,
    toPrint,
  ]);

  return (
    formattedDate && (
      <Card id="card-recurrent-event">
        <div className="timeline-item-container">
          <div className="day-info-content">
            <span>
              {formattedDate.month}
              <strong>{formattedDate.day}</strong>
            </span>
          </div>

          <div className="activity-info-content">
            <div className="timeline-item-tail" />
            <Timeline>{formattedTimeline}</Timeline>
          </div>
        </div>
      </Card>
    )
  );
};

CardRecurrenceEvent.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.string.isRequired,
  }).isRequired,
  toPrint: PropTypes.bool,
};

CardRecurrenceEvent.defaultProps = {
  toPrint: false,
};

export default CardRecurrenceEvent;
