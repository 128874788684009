import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import Card from 'components/Card/Standard';
import HiddenParagraph from 'components/HiddenParagraph';
import SwitchRenderer from 'pages/private/ProfileModels/ProfileModels/components/ProfileModelList/components/SwitchRenderer';

import './styles.less';

const { Text } = Typography;

const IntegrationGuideCard = ({ data, customModal }) => {
  const history = useHistory();

  const { t: translate } = useTranslation();
  return (
    <Card id="access-keys-card-component">
      <Row className="pdd-left-20 no-mrg">
        <Col span={8} className="cells pdd-vertical-20 flex center-start">
          <b className="no-mrg text-dark">
            {translate(
              'pages.private.accessKeys.integrationGuide.components.integrationGuideCard.profileModel'
            )}
          </b>
          <Row className="no-mrg">
            <Text style={{ lineHeight: '34px' }}>{data?.name}</Text>
            <Button
              type="link"
              style={{ lineHeight: '34px' }}
              className="mrg-left-5"
              onClick={() =>
                history.push(
                  `/profile-templates/profile-models/${data?._id}?type=${data?.type}`
                )
              }
            >
              {translate(
                'pages.private.accessKeys.integrationGuide.components.integrationGuideCard.access'
              )}
            </Button>
          </Row>
        </Col>
        <Col span={7} className="cells border-left pdd-vertical-20 flex center-start">
          <b className="no-mrg text-dark">
            {translate(
              'pages.private.accessKeys.integrationGuide.components.integrationGuideCard.templateId'
            )}
          </b>
          <HiddenParagraph text={data?._id} copyable />
        </Col>
        <Col span={2} className="cells border-left pdd-vertical-20 flex center-start">
          <b className="no-mrg text-dark">
            {translate(
              'pages.private.accessKeys.integrationGuide.components.integrationGuideCard.type'
            )}
          </b>
          <Text style={{ lineHeight: '34px' }}>{data?.type}</Text>
        </Col>

        <Col span={7} className="cells border-left pdd-top-20 pdd-btm-10 flex center">
          <Row className="flex center">
            <Col span={8}>
              <SwitchRenderer
                data={data}
                openModal={customModal?.openConfirmActionModal}
              />
            </Col>
            <Col span={16}>
              <Button
                type="primary"
                onClick={() => customModal?.openApiIntegrationGuideModal(data)}
                style={{ margin: 0 }}
              >
                {translate(
                  'pages.private.accessKeys.integrationGuide.components.integrationGuideCard.integrationGuide'
                )}
              </Button>
              <Button type="link" onClick={() => customModal?.openModal(data?._id)}>
                {translate(
                  'pages.private.accessKeys.integrationGuide.components.integrationGuideCard.webhooks'
                )}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default IntegrationGuideCard;
