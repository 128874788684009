import React, { useMemo } from 'react';
import { Card } from 'antd';

import { useExecution } from 'hooks/execution';
import { useAuth } from 'hooks/auth';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';
import CompanyRegistrationInfosContent from './components/Company/RegistrationInfosContent';
import CompanyQSAInfosContent from './components/Company/QSAInfosContent';
import CompanyContactContent from './components/Company/ContactContent';
import CompanyAddressContent from './components/Company/AddressContent';

import PersonRegistrationInfosContent from './components/Person/RegistrationInfosContent';
import InternationalRegistrationInfosContent from './components/Person/InternationalRegistrationInfosContent';
import InternationalContactContent from './components/Person/InternationalContactContent';
import InternationalAddressContent from './components/Person/InternationalAddressContent';
import PersonContactContent from './components/Person/ContactContent';
import PersonAddressContent from './components/Person/AddressContent';

import './styles.less';
import './components/Company/styles.less';

const BasicInfos = () => {
  const { user } = useAuth();

  const { execution, loadingExecution } = useExecution();

  const dataSources = useMemo(() => {
    if (loadingExecution) return [];

    return Object.keys(execution?.sections || {});
  }, [execution, loadingExecution]);

  return (
    <Card id="basic-infos-component">
      {execution?.sections?.pjData ? (
        <>
          <CompanyRegistrationInfosContent company={execution} />
          <CompanyQSAInfosContent company={execution} />
          <CompanyAddressContent company={execution} />
          <CompanyContactContent company={execution} />
        </>
      ) : (
        <>
          {hasFeatureFlag('isWorkflowBuilder', user) ? (
            <>
              <InternationalRegistrationInfosContent />
              <InternationalAddressContent />
              <InternationalContactContent />
            </>
          ) : (
            <>
              <PersonRegistrationInfosContent
                person={execution}
                dataSources={dataSources}
              />
              <PersonContactContent person={execution} dataSources={dataSources} />
              <PersonAddressContent person={execution} dataSources={dataSources} />
            </>
          )}
        </>
      )}
    </Card>
  );
};

export default BasicInfos;
