import { Col, Collapse, Row, Divider } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DottedLabel from 'components/DottedLabel';

import { useI18nFormatters } from 'hooks/i18nFormatters';

// import { Container } from './styles';

const { Panel } = Collapse;

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.companies.components.spcReportParticipationScore.otherInfos';

function CCF({ source = {}, toPrint = false }) {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const parsedData = useMemo(() => source?.data || source, [source]);

  const ccf = useMemo(() => parsedData?.ccf || {}, [parsedData]);

  return (
    <Collapse defaultActiveKey={toPrint ? ['1'] : null}>
      <Panel
        header={
          <span className="text-dark text-bold">
            {translate(`${I18N_BASE_PATH}.index.ccf.title`)}
          </span>
        }
        extra={
          <>
            <DottedLabel
              description={`(${ccf?.total || 0}) ${translate(
                `${I18N_BASE_PATH}.index.restrictions`
              )}`}
              type={+ccf?.total > 0 ? 'danger' : 'primary'}
            />
          </>
        }
        key="1"
      >
        <Col span={24}>
          <Row align="middle">
            <span className="text-dark text-bold">Detalhes dos registros</span>
          </Row>
          <Row align="middle" className="mrg-top-8" justify="space-between">
            <Col>
              <span className="text-dark text-bold">
                {translate(`${I18N_BASE_PATH}.index.ccf.columns.reasonCode`)}:
              </span>{' '}
              <span>{ccf?.details?.motive?.code || '-'}</span>
            </Col>
            <Col>
              <span className="text-dark text-bold">
                {translate(`${I18N_BASE_PATH}.index.ccf.columns.reasonDescription`)}:
              </span>{' '}
              <span>{ccf?.details?.motive?.description || '-'}</span>
            </Col>
          </Row>
        </Col>
        <Divider />
        <Col span={24}>
          <Row align="middle">
            <span className="text-dark text-bold">
              {translate(`${I18N_BASE_PATH}.index.ccf.columns.lastCheck.title`)}
            </span>
          </Row>
          <Row align="middle" className="mrg-top-8" justify="space-between">
            <Col>
              <span className="text-dark text-bold">
                {translate(
                  `${I18N_BASE_PATH}.index.ccf.columns.lastCheck.columns.agencyNumber`
                )}
                :
              </span>{' '}
              <span>{ccf?.details?.lastCheck?.bankData?.agencyNumber || '-'}</span>
            </Col>
            <Col>
              <span className="text-dark text-bold">
                {translate(`${I18N_BASE_PATH}.index.ccf.columns.lastCheck.columns.date`)}:
              </span>{' '}
              <span>{i18nFormatDate(ccf?.details?.lastCheck?.date, 1) || '-'}</span>
            </Col>
            <Col>
              <span className="text-dark text-bold">
                {translate(`${I18N_BASE_PATH}.index.ccf.columns.lastCheck.columns.code`)}:
              </span>{' '}
              <span>{ccf?.details?.lastCheck?.bankData?.code || '-'}</span>
            </Col>
          </Row>
        </Col>
      </Panel>
    </Collapse>
  );
}

export default CCF;
