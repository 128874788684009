import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQueryParams, StringParam } from 'use-query-params';
import { Tabs } from 'antd';
import Summary from './components/Summary';
import BackgroundChecks from './components/BackgroundChecks';

import './styles.less';

const { TabPane } = Tabs;

const TabsWrapper = () => {
  const { t: translate } = useTranslation();
  const { companyId, personId } = useParams();

  const [query, setQuery] = useQueryParams({
    tab: StringParam,
  });

  const tabNav = (name) => <div className="tab-item">{name}</div>;

  return (
    <section id="profile-global-tabs-wrapper">
      <Tabs
        defaultActiveKey={query.tab || 'summary'}
        activeKey={query.tab}
        onChange={(tab) => setQuery({ tab })}
      >
        <TabPane
          tab={
            <div className="tab-item">
              {translate(
                'pages.private.globalProfiles.components.tabs.index.summary',
                'Summary'
              )}
            </div>
          }
          key="summary"
        >
          <Summary />
        </TabPane>

        {/* <TabPane
          tab={
            <div className="tab-item">
              {translate(
                'pages.private.globalProfiles.components.tabs.index.activities',
                'Atividades'
              )}
            </div>
          }
          key="activities"
        >
          adjsada
        </TabPane> */}

        <TabPane
          tab={
            <div className="tab-item">
              {translate(
                'pages.private.globalProfiles.components.tabs.index.backgroundChecks',
                'Background checks'
              )}
            </div>
          }
          key="background"
        >
          <BackgroundChecks />
        </TabPane>
      </Tabs>
    </section>
  );
};

export default TabsWrapper;
