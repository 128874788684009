import React from 'react';
import PropTypes from 'prop-types';

import './styles.less';

const ToggleIcon = ({ isActive }) => (
  <div id="collapse-header-toggle-icon" className={isActive && 'active'}>
    <i
      className={isActive ? 'caf-ic_arrow_up' : 'caf-ic_arrow_down'}
      style={{ color: isActive ? '#fff' : '#323232' }}
    />
  </div>
);

ToggleIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default ToggleIcon;
