import React, { createContext, useState } from 'react';

export const ComplyDataContext = createContext({});

export const ComplyDataProvider = ({ children }) => {
  const [results, setResults] = useState([]);
  const [resultsApproved, setResultsApproved] = useState([]);
  const [resultsReproved, setResultsReproved] = useState([]);
  const [dataSource, setDataSource] = useState();

  return (
    <ComplyDataContext.Provider
      value={{
        results,
        setResults,
        resultsApproved,
        setResultsApproved,
        resultsReproved,
        setResultsReproved,
        dataSource,
        setDataSource,
      }}
    >
      {children}
    </ComplyDataContext.Provider>
  );
};
