/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col, Collapse, Button } from 'antd';
import { validationStatus } from 'utils/handlerStatus';
import ReactJsonView from 'react-json-view';
import { useAuth } from 'hooks/auth';
import { useProfileTemplate } from 'hooks/profileTemplate';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';
import GeneralStatus from './GeneralStatus';

const I18N_BASE_PATH =
  'components.recurrenceList.components.cardRecurrenceEvent.components.executionDetails';

const { Panel } = Collapse;
const ExecutionDetails = ({ data, toPrint }) => {
  const { t: translate } = useTranslation();
  const { cafServicesList } = useProfileTemplate();
  const { user } = useAuth();

  const getSourceName = useCallback(
    (source) => {
      if (!cafServicesList) return '';
      const findedSource =
        cafServicesList?.docs?.find((serv) => serv?.sectionAttr === source) || source;
      return translate(
        `services.${findedSource.source}.title`,
        findedSource?.title || findedSource
      );
    },
    [cafServicesList, translate]
  );

  const displaySources = useMemo(() => {
    return (
      <div className="mrg-btm-20">
        <Row className="text-dark no-pdd-horizon no-mrg-horizon mrg-btm-5">
          <Col span={24} className="no-pdd-horizon no-mrg-horizon">
            <strong>{translate(`${I18N_BASE_PATH}.sources`)}</strong>
          </Col>
        </Row>
        {Object.keys(data?.sections)?.map((item, index) => (
          <>
            <Row
              className={`mrg-btm-10 pdd-top-10 no-pdd-horizon no-mrg-horizon ${
                index !== 0 && 'border-divider'
              }`}
            >
              <Col span={24} className="flex start-center no-pdd-horizon no-mrg-horizon">
                {getSourceName(item)}
              </Col>
            </Row>
          </>
        ))}
      </div>
    );
  }, [data, getSourceName, translate]);

  const displayValidations = useMemo(() => {
    return (
      <div className="mrg-btm-20">
        <Row className="text-dark no-pdd-horizon no-mrg-horizon mrg-btm-5">
          <Col span={16} className="no-pdd-horizon no-mrg-horizon">
            <strong>{translate(`${I18N_BASE_PATH}.validations`)}</strong>
          </Col>
          <Col span={8} className="no-pdd-horizon no-mrg-horizon">
            <strong>{translate(`${I18N_BASE_PATH}.status`)}</strong>
          </Col>
        </Row>
        {data?.validations?.map((item, index) => (
          <>
            <Row
              className={`mrg-btm-10 pdd-top-10 no-pdd-horizon no-mrg-horizon ${
                index !== 0 && 'border-divider'
              }`}
            >
              <Col span={16} className="flex start-center no-pdd-horizon no-mrg-horizon">
                <i
                  className={`${
                    validationStatus(item?.status ? item?.status : item?.resultStatus)
                      ?.icon
                  } mrg-right-10 type-${
                    validationStatus(item?.status ? item?.status : item?.resultStatus)
                      ?.type
                  }`}
                />
                {translate(`validations.${item.code}.title`)}
              </Col>
              <Col span={8} className="flex start-center no-pdd-horizon no-mrg-horizon">
                <span>
                  {translate(
                    validationStatus(item?.status ? item?.status : item?.resultStatus)
                      ?.title
                  )}
                </span>
              </Col>
            </Row>
          </>
        ))}
      </div>
    );
  }, [data, translate]);

  return (
    <Row className="no-pdd-horizon no-mrg-horizon">
      <Col span={24} className="no-pdd-horizon no-mrg-horizon">
        <Collapse defaultActiveKey={toPrint ? ['1'] : null}>
          <Panel
            header={
              <b>
                {Object.keys(data?.sections)?.length}{' '}
                {translate(`${I18N_BASE_PATH}.sourcesMin`)} /{' '}
                {data?.validations?.length || '0'}{' '}
                {translate(`${I18N_BASE_PATH}.validationsMin`)}
              </b>
            }
            key="1"
            extra={
              <Button
                className="no-pdd no-mrg lh-1"
                style={{ height: 14 }}
                type="link"
                onClick={() =>
                  window.open(
                    `${window.location.origin}${
                      hasFeatureFlag('isWorkflowBuilder', user)
                        ? '/person'
                        : '/executions'
                    }/${data?._id}`
                  )
                }
              >
                {translate(`${I18N_BASE_PATH}.viewExecution`)}
              </Button>
            }
          >
            <Row>
              <Col span={14}>{!!data?.sections && displaySources}</Col>
              <Col span={toPrint ? 24 : 10}>
                <GeneralStatus data={data} />
              </Col>
            </Row>
            <div>{!!data?.validations?.length && displayValidations}</div>
            <Collapse defaultActiveKey={toPrint ? ['1', '2'] : null}>
              <Panel header={<b>{translate(`${I18N_BASE_PATH}.userParams`)}</b>} key="1">
                <div className="json-view-wrapper">
                  <ReactJsonView
                    name={false}
                    indentWidth={2}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    enableClipboard={false}
                    src={data?.parameters}
                  />
                </div>
              </Panel>
              <Panel header={<b>{translate(`${I18N_BASE_PATH}.metadata`)}</b>} key="2">
                <div className="json-view-wrapper">
                  <ReactJsonView
                    name={false}
                    indentWidth={2}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    enableClipboard={false}
                    src={data?.metadata}
                  />
                </div>
              </Panel>
            </Collapse>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

ExecutionDetails.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  toPrint: PropTypes.bool,
};

ExecutionDetails.defaultProps = {
  data: {},
  toPrint: false,
};

export default ExecutionDetails;
