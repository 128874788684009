/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import { toMaskedCpf } from 'utils/formatters';
import { genderTranslate } from 'utils/translations';

import TableInfoCard from 'pages/private/Profiles/components/TableInfoCard/Column';

const ValidationsRNMData = ({ data }) => {
  const { t: translate } = useTranslation();
  const I18N_VALIDATIONS_RNM =
    'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsRNMData.index.validationsRNMData';

  return (
    <>
      <Row gutter={[32, 24]} className="custom-validations-data">
        <TableInfoCard
          span={8}
          title={translate(`${I18N_VALIDATIONS_RNM}.tableInfoCard.title.name`)}
          content={data?.name}
        />
        <TableInfoCard
          span={8}
          title={translate(`${I18N_VALIDATIONS_RNM}.tableInfoCard.title.birthDate`)}
          content={data?.birthDate}
        />
        <TableInfoCard
          span={8}
          title={translate(`${I18N_VALIDATIONS_RNM}.tableInfoCard.title.gender`)}
          content={translate(genderTranslate(data?.gender))}
        />
      </Row>

      <Row gutter={[32, 24]} className="custom-validations-data">
        <TableInfoCard
          span={8}
          title={translate(`${I18N_VALIDATIONS_RNM}.tableInfoCard.title.motherName`)}
          content={data?.motherName}
        />
        <TableInfoCard
          span={8}
          title={translate(`${I18N_VALIDATIONS_RNM}.tableInfoCard.title.fatherName`)}
          content={data?.fatherName}
        />
        <TableInfoCard
          span={8}
          title={translate(`${I18N_VALIDATIONS_RNM}.tableInfoCard.title.nationality`)}
          content={data?.nationality}
        />
      </Row>

      <Row gutter={[32, 24]} className="custom-validations-data">
        <TableInfoCard
          span={8}
          title={translate(`${I18N_VALIDATIONS_RNM}.tableInfoCard.title.validity`)}
          content={data?.validity}
        />
        <TableInfoCard
          span={8}
          title={translate(`${I18N_VALIDATIONS_RNM}.tableInfoCard.title.rnm`)}
          content={data?.rnm}
        />
        <TableInfoCard
          span={8}
          title={translate(`${I18N_VALIDATIONS_RNM}.tableInfoCard.title.protocol`)}
          content={data?.protocol}
        />
      </Row>

      <Row gutter={[32, 24]} className="custom-validations-data">
        <TableInfoCard
          span={8}
          title={translate(`${I18N_VALIDATIONS_RNM}.tableInfoCard.title.cpf`)}
          content={toMaskedCpf(data?.cpf)}
        />
        <TableInfoCard
          span={8}
          title={translate(`${I18N_VALIDATIONS_RNM}.tableInfoCard.title.classification`)}
          content={data?.classification}
        />
        <TableInfoCard
          span={8}
          title={translate(`${I18N_VALIDATIONS_RNM}.tableInfoCard.title.residenceTerm`)}
          content={data?.residenceTerm}
        />
      </Row>

      <Row gutter={[32, 24]} className="custom-validations-data">
        <TableInfoCard
          span={8}
          title={translate(`${I18N_VALIDATIONS_RNM}.tableInfoCard.title.issueDate`)}
          content={data?.issueDate}
        />
        <TableInfoCard
          span={8}
          title={translate(
            `${I18N_VALIDATIONS_RNM}.tableInfoCard.title.issuingAuthority`
          )}
          content={data?.issuingAuthority}
        />
        <TableInfoCard
          span={8}
          title={translate(`${I18N_VALIDATIONS_RNM}.tableInfoCard.title.legalProtection`)}
          content={data?.legalProtection}
        />
      </Row>
      <Row gutter={[32, 24]} className="custom-validations-data">
        <TableInfoCard
          span={24}
          title={translate(`${I18N_VALIDATIONS_RNM}.tableInfoCard.title.mrz`)}
          content={data?.mrz}
        />
      </Row>
    </>
  );
};

export default ValidationsRNMData;
