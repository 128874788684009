const listToTree = (items) => {
  if (!items || !Array.isArray(items)) return [];

  const itemsWithId = items.map((item, index) => ({
    ...item,
    id: index,
  }));

  const itemsWithParentId = itemsWithId.map((item, index) => {
    const parentId = itemsWithId
      .filter((d) => d.id < index && d.level === item.level - 1)
      .reverse()
      .at(0)?.id;

    return {
      ...item,
      parentId,
    };
  });

  itemsWithParentId.forEach((f) => {
    f.childrens = itemsWithParentId.filter((g) => g.parentId === f.id);
  });

  return itemsWithParentId.filter((f) => f.parentId === undefined);
};

export default listToTree;
