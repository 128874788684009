import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Checkbox, Row, Typography, message } from 'antd';
import { useAuth } from 'hooks/auth';
import { useFetch } from 'services/hooks';

import './styles.less';

const { Title } = Typography;

const I18N_BASE_PATH = 'pages.private.notificationCenter.notificationSettings';

const NotificationSettings = () => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const { put, loading } = useFetch();

  const [stateTemplate, setStateTemplate] = useState(
    user?.configs?.notifications?.transactionTemplate || false
  );
  const [stateOnboarding, setStateOnboarding] = useState(
    user?.configs?.notifications?.onboarding || false
  );

  const handleUpdateConfigs = useCallback(async () => {
    try {
      await put({
        url: '/user-configs',
        payload: {
          notifications: {
            onboarding: stateOnboarding,
            transactionTemplate: stateTemplate,
          },
        },
      });

      message.success(translate(`${I18N_BASE_PATH}.messages.success`));
    } catch (error) {
      message.error(translate(`${I18N_BASE_PATH}.messages.error`));
    }
  }, [put, stateOnboarding, stateTemplate, translate]);

  return (
    <Card id="settings-styled">
      <Title level={3}>{translate(`${I18N_BASE_PATH}.pages.title`)}</Title>
      <p>{translate(`${I18N_BASE_PATH}.pages.subtitle`)}</p>
      <Row className="row-spaced">
        <Checkbox
          checked={stateTemplate}
          onChange={() => setStateTemplate(!stateTemplate)}
        >
          {translate(`${I18N_BASE_PATH}.configs.transactionTemplates`)}
        </Checkbox>
        <Checkbox
          checked={stateOnboarding}
          onChange={() => setStateOnboarding(!stateOnboarding)}
        >
          {translate(`${I18N_BASE_PATH}.configs.onboarding`)}
        </Checkbox>
        <Checkbox disabled checked>
          {translate(`${I18N_BASE_PATH}.configs.downloads`)}
        </Checkbox>
      </Row>
      <Button
        type="primary"
        className="button-save float-right"
        onClick={handleUpdateConfigs}
        loading={loading}
      >
        {translate(`${I18N_BASE_PATH}.pages.button.saveConfigs`)}
      </Button>
    </Card>
  );
};

export default NotificationSettings;
