import { useContext } from 'react';

import { WorkflowContext } from '../contexts';

export const useWorkflow = () => {
  const context = useContext(WorkflowContext);

  if (!context) throw new Error('useWorkflow must be used within an WorkflowContext');

  return context;
};
