/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Select } from 'antd';
import { differenceInMonths } from 'date-fns';

import EmptyExecutionState from 'components/EmptyExecutionState';
import ViewMore from 'components/ViewMore';

import SPCProgress from '../SPCProgress';
import SPCInfos from '../SPCInfos';
import useSPCDetails from '../SPCDetailsModal';

import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

import './styles.less';

const { Option } = Select;

const SpcRangeMonthValues = {
  spcScore12Months: 12,
  spcScore3Months: 3,
};

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.shared.spcRestrictionsScore';

const SPC = ({ source = {}, toPrint = false, toPrintDetails }) => {
  const { t: translate } = useTranslation();

  const [spcScoreRange, setSpcScoreRange] = useState('spcScore12Months');

  const [parsedData, setParsedData] = useState(null);

  const parsedDataSource = useMemo(() => source?.data || source, [source]);

  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const { openModal, SPCDetailsModal } = useSPCDetails({
    data: parsedData?.data,
    spcScoreRange,
  });

  useMemo(() => {
    if (!spcScoreRange) setParsedData(...source);

    const filteredDataByScoreRange = { ...source };

    const inRangeDetails = [];
    const inRangeDebts = [];

    if (SpcRangeMonthValues && SpcRangeMonthValues[spcScoreRange]) {
      filteredDataByScoreRange?.data?.spc?.details?.forEach((d) => {
        if (
          differenceInMonths(new Date(), new Date(d.inclusionDate)) <=
          SpcRangeMonthValues[spcScoreRange]
        ) {
          inRangeDetails.push(d);
          inRangeDebts.push(d.value);
        }
      });
    }

    if (filteredDataByScoreRange?.data && filteredDataByScoreRange?.data[spcScoreRange]) {
      filteredDataByScoreRange.data[spcScoreRange].details = inRangeDetails;
      filteredDataByScoreRange.data[spcScoreRange].total = inRangeDebts.reduce(
        (acc, cur) => acc + cur,
        0
      );
    }

    return setParsedData(filteredDataByScoreRange);
  }, [source, spcScoreRange]);

  const isValidScore = useMemo(() => {
    if (!parsedData?.data || !parsedData?.data[spcScoreRange]) return false;

    const score = parsedData?.data[spcScoreRange]?.score;
    if (!score) return false;

    const formattedScore = Number(score);

    if (formattedScore >= 0 && formattedScore <= 1000) {
      return true;
    }

    return false;
  }, [parsedData, spcScoreRange]);

  return (
    <DataSourceCardCollapsePanel
      title={translate(`${I18N_BASE_PATH}.index.title`).toUpperCase()}
      icon="caf-ic_dollar_sign"
      toPrint={toPrint}
      customId="report-spc-last-phone-card"
    >
      <Row>
        {SPCDetailsModal}

        <Col span={24} style={{ padding: 0, marginBottom: 24 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col
              span={12}
              bordered={false}
              style={{ height: 25 }}
              className="text-dark text-bold reset-mrg"
            >
              <span style={{ height: 25 }} className="text-dark text-bold">
                {translate(`${I18N_BASE_PATH}.index.spcQuery`)}
              </span>
            </Col>
            <Col
              span={12}
              className="text-dark text-bold reset-mrg"
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Select
                  onChange={(value) => setSpcScoreRange(value)}
                  value={spcScoreRange}
                  style={{
                    width: 180,
                    height: 25,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  className="text-dark text-bold reset-mrg"
                  allowClear={false}
                  bordered={false}
                  disabled={!queryWorked}
                >
                  <Option value="spcScore3Months">
                    {translate(`${I18N_BASE_PATH}.index.spcScoreRange.spcScore3Months`)}
                  </Option>
                  <Option value="spcScore12Months">
                    {translate(`${I18N_BASE_PATH}.index.spcScoreRange.spcScore12Months`)}
                  </Option>
                </Select>
              </div>
            </Col>
          </div>
        </Col>

        <Col span={24}>
          {queryWorked ? (
            <>
              {isValidScore ? (
                <Row className="mrg-top-25">
                  <Col span={24}>
                    <SPCProgress data={parsedData?.data[spcScoreRange].score} />
                  </Col>
                </Row>
              ) : (
                <span>{translate(`${I18N_BASE_PATH}.index.invalidScore`)}</span>
              )}

              <SPCInfos
                data={parsedData?.data}
                spcScoreRange={spcScoreRange}
                totalDebts={
                  parsedData?.data[spcScoreRange]
                    ? parsedData?.data[spcScoreRange]?.details
                        .map((d) => d?.value || 0)
                        .reduce((acc, cur) => acc + cur, 0)
                    : undefined
                }
              />

              {!toPrint && parsedDataSource?.spc?.details?.length > 0 && (
                <Row>
                  <ViewMore
                    type="link"
                    title={translate(
                      `${I18N_BASE_PATH}.index.viewMore.title.hasToPrintDetails`
                    )}
                    onClick={() => openModal(parsedData?.data)}
                  />
                </Row>
              )}

              {toPrint && !toPrintDetails && parsedDataSource?.spc?.details?.length > 0 && (
                <Row>
                  <ViewMore
                    text
                    type="link"
                    title={translate(
                      `${I18N_BASE_PATH}.index.viewMore.title.hasNotToPrintDetails`,
                      { value: parsedData?.data?.spc?.details?.length }
                    )}
                  />
                </Row>
              )}
            </>
          ) : (
            <EmptyExecutionState
              statusCode={statusCode}
              message={source?.message || parsedDataSource?.message}
            />
          )}
        </Col>
      </Row>
    </DataSourceCardCollapsePanel>
  );
};

export default SPC;
