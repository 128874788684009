import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import CircleIcon from 'components/Icon/CircleIcon';

import './styles.less';

const AddressItem = ({ data: address }) => {
  const { addressMain, number, complement, neighborhood, city, state, zipCode, type } =
    address || {};

  const addressIconType = useMemo(() => {
    // eslint-disable-next-line no-nested-ternary
    return type === 'HOME' ? 'home' : type === 'WORK' ? 'company' : 'map_pin';
  }, [type]);

  return (
    <div id="address-item-execution-container">
      <CircleIcon icon={`caf-ic_${addressIconType}`} />
      <section className="flex space-between address-item-execution-information">
        <span>
          {addressMain && `${addressMain},`} {number && `${number},`}{' '}
          {complement && neighborhood ? `${complement} -` : `${complement || ''}`}{' '}
          {neighborhood || ''} <br />
          {city && state ? `${city} /` : `${city || ''}`} {state && `${state},`}{' '}
          {zipCode || ''}
        </span>
      </section>
    </div>
  );
};

AddressItem.propTypes = {
  data: PropTypes.shape({
    addressMain: PropTypes.string,
    number: PropTypes.string,
    complement: PropTypes.string,
    neighborhood: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

export default AddressItem;
