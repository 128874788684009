import { Collapse } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DottedLabel from 'components/DottedLabel';

// import { Container } from './styles';

const { Panel } = Collapse;

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.companies.components.spcReportParticipationScore.otherInfos';

function BankruptcyParticipation({ source = {}, toPrint = false }) {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);

  const bankruptcyParticipation = useMemo(
    () => parsedData?.bankruptcyParticipation || {},
    [parsedData]
  );
  return (
    <Collapse
      className="mrg-btm-10 mrg-top-10 reset-mrg reset-pdd"
      defaultActiveKey={toPrint ? ['1'] : null}
    >
      <Panel
        key="1"
        disabled
        header={
          <span className="text-dark text-bold">
            {translate(`${I18N_BASE_PATH}.index.bankruptcyParticipation.title`)}
          </span>
        }
        extra={
          <DottedLabel
            description={`(${bankruptcyParticipation?.total || 0}) ${translate(
              `${I18N_BASE_PATH}.index.restrictions`
            )}`}
            type={+bankruptcyParticipation?.total > 0 ? 'danger' : 'primary'}
          />
        }
      />
    </Collapse>
  );
}

export default BankruptcyParticipation;
