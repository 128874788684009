import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Collapse, Radio, DatePicker, Form } from 'antd';

import moment from 'moment';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const UpdatedAtFilter = ({ value, title, setFilter }) => {
  const { t: translate } = useTranslation();
  const [dateType, setDateType] = useState(value || '');

  const isCustomDate = () => value && value.substr(0, 6) === 'custom';

  const getDatePickerValue = () => {
    if (!isCustomDate() || value === 'custom') return;
    const [startDate, endDate] = value.substr(7).split('-');
    // eslint-disable-next-line consistent-return
    return [
      moment(new Date(parseInt(startDate, 10))),
      moment(new Date(parseInt(endDate, 10))),
    ];
  };

  const onChange = (e) => {
    const filterValue = e?.target?.value;
    setDateType(filterValue);
    setFilter('createdAt', filterValue);
  };

  return (
    <Collapse
      defaultActiveKey={['creationDate']}
      ghost
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <div>
          <i className={isActive ? 'caf-ic_arrow_up' : 'caf-ic_arrow_down'} />
        </div>
      )}
    >
      <Panel header={title} key="creationDate">
        <Form.Item name="creationDate" className="mrg-left-5">
          <Radio.Group
            defaultValue={isCustomDate() ? 'custom' : dateType || ''}
            value={isCustomDate() ? 'custom' : dateType || ''}
            onChange={(e) => onChange(e)}
          >
            <Radio value="">
              {translate(
                'components.filters.updatedAtFilter.collapse.panel.radioGroup.onAnyDate'
              )}
            </Radio>
            <Radio value="last24Hours">
              {translate(
                'components.filters.updatedAtFilter.collapse.panel.radioGroup.last24Hours'
              )}
            </Radio>
            <Radio value="last7Days">
              {translate(
                'components.filters.updatedAtFilter.collapse.panel.radioGroup.last7Days'
              )}
            </Radio>
            <Radio value="last30Days">
              {translate(
                'components.filters.updatedAtFilter.collapse.panel.radioGroup.last30Days'
              )}
            </Radio>
            <Radio value="lastYear">
              {translate(
                'components.filters.updatedAtFilter.collapse.panel.radioGroup.lastYear'
              )}
            </Radio>
            <Radio value="custom">
              {translate(
                'components.filters.updatedAtFilter.collapse.panel.radioGroup.custom'
              )}
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="creationDateCustom" className="mrg-left-5">
          {isCustomDate() && (
            <RangePicker
              className="gx-w-100 mrg-top-5 gx-bg-transparent"
              format="DD/MM/YYYY HH:mm"
              showTime
              value={getDatePickerValue()}
              defaultValue={getDatePickerValue()}
              onChange={(_value) => setFilter('createdAt', _value)}
            />
          )}
        </Form.Item>
      </Panel>
    </Collapse>
  );
};

UpdatedAtFilter.propTypes = {
  value: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default UpdatedAtFilter;
