/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';

// import currencyFormatter from 'utils/currencyFormatter';

import './styles.less';

// const { toCurrency } = currencyFormatter;

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.companies.components.spcReportParticipationScore.spcInfos';

const SPCInfos = ({ data, totalDebts }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatCurrency } = useI18nFormatters();

  const { score, scorePjClass: _class } = data?.scorePj?.scorePjDetails || {};
  const { restriction } = data;

  const debt = totalDebts || data?.spc?.value;

  return (
    <Row id="executions-spc-row-infos" className="reset-mrg">
      <Col span={7} className="spc-col-info no-left">
        <strong>
          {translate(`${I18N_BASE_PATH}.index.spcInfos.restriction.description`)}:
        </strong>
        <span>
          {translate(
            `${I18N_BASE_PATH}.index.spcInfos.restriction.value.${
              restriction ? 'hasRestriction' : 'hasNotRestriction'
            }`
          )}
        </span>
      </Col>
      <Col span={7} className="spc-col-info">
        <strong>
          {translate(`${I18N_BASE_PATH}.index.spcInfos.class.description`)}:
        </strong>
        <div className="spc-class-info">
          <span>{_class || '-'}</span>
          <span>{translate(`${I18N_BASE_PATH}.index.spcInfos.class.value`)}</span>
        </div>
      </Col>
      <Col span={5} className="spc-col-info">
        <strong>
          {translate(`${I18N_BASE_PATH}.index.spcInfos.score.description`)}:
        </strong>
        <span>{score || '-'}/1000</span>
      </Col>
      <Col span={5} className="spc-col-info no-right end">
        <strong>{translate(`${I18N_BASE_PATH}.index.spcInfos.debt.description`)}:</strong>
        <span>
          {i18nFormatCurrency(debt, 'pt-BR') ||
            translate(`${I18N_BASE_PATH}.index.spcInfos.debt.value`)}{' '}
        </span>
      </Col>
    </Row>
  );
};

export default SPCInfos;
