/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';

import PersonalAndEconomicRelationships from './components/PersonalAndEconomicRelationships';

import './styles.less';

export const PfPersonalRelationships = ({ toPrint = false, source = {} }) => {
  const { t: translate } = useTranslation();

  return (
    <DataSourceCardCollapsePanel
      customId="relationships-component"
      title={translate(
        'components.dataSourceCards.basicInfos.components.basicInfosCard.sources.pfPersonalRelationships'
      )}
      icon="caf-ic_users"
      toPrint={toPrint}
    >
      <div className="gx-w-100 no-mrg no-pdd">
        <PersonalAndEconomicRelationships source={source} toPrint={toPrint} />
      </div>
    </DataSourceCardCollapsePanel>
  );
};
