import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Divider, Spin, message } from 'antd';

import { useExecution } from 'hooks/execution';
import { useReasons } from 'hooks/reasons';

import { useFetch } from 'services/hooks';

import PageTitle from 'components/PageTitle';
import Button from 'components/Button';

import './styles.less';

import CardReview from './components/CardReview';

const I18N_BASE_PATH =
  'pages.private.executions.components.generalStatus.pendingStatusModal.index';

const I18N_PENDING_REVIEW_BASE_PATH =
  'pages.private.profiles.components.profileValidations.components.pendingReviewModal.index';

const ModalManualReview = () => {
  const { t: translate } = useTranslation();
  const { loading: loadingExecution, execution, refreshExecution } = useExecution();
  const { loadReasons } = useReasons();

  const [form] = Form.useForm();
  const { loading, post } = useFetch();
  const [modalVisible, setModalVisible] = useState(false);
  const [allowReprocessExecution, setAllowReprocessExecution] = useState(false);

  const closeModal = useCallback(() => {
    form.resetFields();
    setModalVisible(false);
  }, [form]);

  const openModal = useCallback(({ allowReprocess }) => {
    setModalVisible(true);
    setAllowReprocessExecution(allowReprocess);
  }, []);

  const parseReasons = useCallback(
    async ({ customStatusReasons, type }) => {
      if (!customStatusReasons) return undefined;

      const reasonsList = await loadReasons({ type });

      const customReasonsIds = customStatusReasons.map((csr) => csr.key);

      const parsedStatus = reasonsList?.filter((r) => customReasonsIds.includes(r._id));

      return parsedStatus;
    },
    [loadReasons]
  );

  const onConfirmDataZoop = useCallback(async () => {
    try {
      await post({
        url: `/executions/${execution?._id}/manual-revision`,
        payload: {
          status: 'REPROVADO',
          reason: 'Solicitação de correção via análise manual.',
          customStatus: 'WAITING_CORRECTIONS',
        },
      });

      message.success('Solicitação de correção realizada com sucesso.');

      closeModal();
      refreshExecution();
    } catch (error) {
      closeModal();
      refreshExecution();
    }
  }, [post, execution, closeModal, refreshExecution]);

  const onApproveReport = useCallback(
    async (reason, customStatusReasons) => {
      const parsedReasons = await parseReasons({ customStatusReasons, type: 'approval' });
      try {
        await post({
          url: `/executions/${execution?._id}/manual-revision`,
          payload: {
            status: 'APROVADO',
            reason,
            customStatusReasons: parsedReasons,
          },
        });

        message.success(translate(`${I18N_BASE_PATH}.onApproveReport.messages.success`));

        closeModal();
        refreshExecution();
      } catch (error) {
        closeModal();
        refreshExecution();
      }
    },
    [parseReasons, post, execution, translate, closeModal, refreshExecution]
  );

  const onDisapproveReport = useCallback(
    async (reason, customStatusReasons) => {
      const parsedReasons = await parseReasons({ customStatusReasons, type: 'reproval' });

      const payload = {
        status: 'REPROVADO',
        reason,
        customStatusReasons: parsedReasons,
      };

      try {
        await post({
          url: `/executions/${execution?._id}/manual-revision`,
          payload,
        });

        message.success(
          translate(`${I18N_BASE_PATH}.onDisapproveReport.messages.success`)
        );

        closeModal();
        refreshExecution();
      } catch (error) {
        closeModal();
        refreshExecution();
      }
    },
    [parseReasons, translate, post, execution._id, closeModal, refreshExecution]
  );

  const onReprocess = useCallback(
    async (attributes) => {
      try {
        await post({
          url: `/executions/${execution?._id}/retry`,
          payload: {
            ...attributes,
          },
        });

        message.success(translate(`${I18N_BASE_PATH}.onReprocess.messages.success`));

        closeModal();
        refreshExecution();
      } catch (error) {
        //
      }
    },
    [post, execution, translate, closeModal, refreshExecution]
  );

  const handleSubmit = useCallback(
    async (value) => {
      const {
        validationReason,
        validationStatus,
        customStatusReasons,
        ...attributes
      } = value;
      const validStatus = ['APPROVED', 'REPROVED', 'REPROCESS'];

      if (validStatus.includes(validationStatus)) {
        let modalContent = null;

        if (validationStatus === 'APPROVED') {
          modalContent = translate(
            `${I18N_BASE_PATH}.pendingStatusModal.confirmModal.aprove`
          );
        }

        if (validationStatus === 'REPROVED') {
          modalContent = translate(
            `${I18N_BASE_PATH}.pendingStatusModal.confirmModal.reprove`
          );
        }

        if (validationStatus === 'REPROCESS') {
          modalContent = translate(
            `${I18N_BASE_PATH}.pendingStatusModal.confirmModal.reprocess`
          );
        }

        if (modalContent) {
          Modal.confirm({
            centered: true,
            width: 550,
            title: modalContent,
            okText: translate(`${I18N_BASE_PATH}.pendingStatusModal.confirmModal.okText`),
            onOk() {
              if (validationStatus === 'APPROVED') {
                onApproveReport(validationReason, customStatusReasons);
              }

              if (validationStatus === 'REPROVED') {
                onDisapproveReport(validationReason, customStatusReasons);
              }

              if (validationStatus === 'REPROCESS') {
                onReprocess(attributes);
              }
            },
            onCancel() {},
          });
        }
      }

      if (validationStatus === 'REPROVEDWC') {
        Modal.confirm({
          centered: true,
          width: 550,
          title: translate(`${I18N_BASE_PATH}.pendingStatusModal.confirmModal.reprove`),
          okText: translate(`${I18N_BASE_PATH}.pendingStatusModal.confirmModal.okText`),
          onOk() {
            onConfirmDataZoop(validationReason, customStatusReasons);
          },
          onCancel() {},
        });
      }
    },
    [onApproveReport, onDisapproveReport, onReprocess, onConfirmDataZoop, translate]
  );

  const ManualReviewModal = useMemo(
    () => (
      <Modal
        wrapClassName="caf-modal"
        closable
        width={850}
        footer={null}
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeModal}
        visible={modalVisible}
      >
        <div id="pending-review-modal-content">
          <PageTitle
            title={translate(
              `${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.pageTitle.title`
            )}
            subtitle={translate(
              `${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.pageTitle.subtitle`
            )}
          />

          <Form
            id="pending-review-modal-form"
            layout="vertical"
            onFinish={handleSubmit}
            form={form}
          >
            {loadingExecution ? (
              <Spin className="flex center gx-w-100 mrg-top-30 mrg-btm-40" />
            ) : (
              <>
                <div className="pending-review-items">
                  <CardReview
                    allowReprocessExecution={allowReprocessExecution}
                    formRef={form}
                    isOpenModal={modalVisible}
                  />
                  <Divider />
                </div>

                <div className="gx-flex-row gx-justify-content-end mrg-top-40">
                  <Button
                    type="default"
                    className="btn-cancel"
                    disabled={loadingExecution}
                    onClick={closeModal}
                  >
                    {translate(
                      `${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.form.button.cancel`
                    )}
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="apply-changes-button"
                    loading={loading || loadingExecution}
                    disabled={loadingExecution || loading}
                  >
                    {translate(
                      `${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.form.button.submit`
                    )}
                  </Button>
                </div>
              </>
            )}
          </Form>
        </div>
      </Modal>
    ),
    [
      closeModal,
      modalVisible,
      translate,
      handleSubmit,
      form,
      loadingExecution,
      allowReprocessExecution,
      loading,
    ]
  );

  return { openModal, closeModal, ManualReviewModal };
};

export default ModalManualReview;
