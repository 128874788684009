import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from 'hooks/auth';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';

const FeatureFlagWrapper = ({ featureFlag, children }) => {
  const { user } = useAuth();

  const hideWhenTrueFlags = ['costPrices', 'profilesModule'];

  const showContent = useMemo(() => {
    if (hideWhenTrueFlags.includes(featureFlag)) {
      if (hasFeatureFlag(featureFlag, user)) {
        return false;
      }
      return true;
    }

    if (hasFeatureFlag(featureFlag, user)) {
      return true;
    }

    return false;
  }, [featureFlag, hideWhenTrueFlags, user]);

  return showContent ? children : <></>;
};

FeatureFlagWrapper.propTypes = {
  featureFlag: PropTypes.string.isRequired,
};

export default FeatureFlagWrapper;
