import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination } from 'antd';
import { useQueryParams, StringParam } from 'use-query-params';
import _ from 'lodash';

import { useFetch } from 'services/hooks';

import EmptyMessage from 'components/EmptyMessage';
import { scrollToTop } from 'utils/scroll';

import { initialFilterData } from '../utils';

const useComponentNextList = ({
  component: Component,
  hidePagination,
  rowKey,
  getParams,
  queryParams,
  customDataParser,
  customModal,
  customLoader,
  maxTags,
  customApi,
  socket = null,
  componentProps = {},
}) => {
  const { t: translate } = useTranslation();

  const { get } = useFetch({ customApi });

  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [next, setNext] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingFivetimes, setLoadingFivetimes] = useState(false);

  const [fetchingController, setFetchingController] = useState();

  const [query, setQuery] = useQueryParams({
    _order: StringParam,
    _sort: StringParam,
    ...queryParams,
  });

  const [customParserData, setCustomParserData] = useState();
  const [customParserLoading, setCustomParserLoading] = useState(false);
  const [paginationLabel, setPaginationLabel] = useState('');
  const currentRequestId = useRef(null);

  const [params, setParams] = useState(getParams);
  const [pagination, setPagination] = useState({
    current: query?.page || 1,
    pageSize: 10,
    total: 0,
    showTotal: (total, range) =>
      translate('components.list.hooks.useComponentList.pagination.showTotal', {
        rangeZero: range[0],
        rangeOne: range[1],
        total,
      }),
  });
  const [filterData, setFilterData] = useState(() => {
    return initialFilterData({ query, queryParams });
  });

  const handleRequest = useCallback(
    async ({ _next, signal }) => {
      return get({
        url: params.url,
        config: {
          signal,
          params: {
            _limit: 100,
            _next,
            ...filterData,
            ...params.config.params,
          },
        },
      });
    },
    [filterData, get, params]
  );

  const fetch = useCallback(
    async (current, requestId, clear) => {
      if (!params) return;

      try {
        setLoading(true);
        scrollToTop();

        const pageSize = pagination.pageSize || 20;

        const controller = new AbortController();

        fetchingController?.abort();

        setFetchingController(controller);

        const res = await handleRequest({
          _next: clear ? undefined : next,
          signal: controller.signal,
        });

        const allDocs = [];

        if (allData?.docs && !clear) {
          allDocs.push(...allData.docs);
        }
        if (res?.docs) {
          allDocs.push(...res.docs);
        }
        setAllData({ ...res, docs: allDocs });
        setNext(res.next);
        const newData = allDocs.slice(
          pagination.pageSize * (current - 1),
          pagination.pageSize * current
        );

        setData(newData);
        setPagination((oldState) => ({
          ...oldState,
          current,
          pageSize,
          total: allDocs.length,
        }));

        if (customDataParser) {
          setCustomParserLoading(true);

          setCustomParserData(await customDataParser(res));

          setCustomParserLoading(false);
        }

        setLoading(false);
        // eslint-disable-next-line consistent-return
        return { next: res.next, requestId };
      } catch (error) {
        return { requestId };
      }
    },
    [
      params,
      pagination.pageSize,
      fetchingController,
      handleRequest,
      next,
      allData.docs,
      customDataParser,
    ]
  );

  const getListFiveTimes = useCallback(async () => {
    setLoadingFivetimes(true);

    let _next = next;
    const newAllData = allData.docs || [];

    const requestId = Math.floor(Math.random() * 10000000);

    currentRequestId.current = requestId;

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < 5; index++) {
      if (requestId !== currentRequestId.current || !_next) {
        break;
      }

      // eslint-disable-next-line no-await-in-loop
      const res = await handleRequest({ _next });
      _next = res.next;

      newAllData.push(...(res?.docs || []));
      setPagination((oldState) => ({
        ...oldState,
        total: newAllData.length,
      }));
    }

    if (requestId === currentRequestId.current) {
      setNext(_next);
      setAllData({ next: _next, docs: newAllData });

      setPagination((oldState) => ({
        ...oldState,
        total: newAllData.length,
      }));
    }

    setLoadingFivetimes(false);
  }, [allData, handleRequest, next]);

  const handlePaginationChange = useCallback(
    (_page) => {
      const newPagination = {
        ...pagination,
        current: _page,
      };

      const newData = allData.docs.slice(
        newPagination.pageSize * (newPagination.current - 1),
        newPagination.pageSize * newPagination.current
      );

      setData(newData);
      setPagination(newPagination);
    },
    [allData, pagination]
  );

  const refreshList = useCallback(() => {
    fetch(1, null, true);
  }, [fetch]);

  const _updateParams = useCallback(
    (newParams) => {
      setAllData({ docs: [] });
      setData([]);
      setPagination((state) => ({
        ...state,
        current: 1,
      }));
      setPaginationLabel(
        translate(
          'components.list.hooks.useComponentList.loadingLabel',
          'Loading items...'
        )
      );
      setParams(newParams);
    },
    [translate]
  );

  const updateParams = useCallback(_.debounce(_updateParams, 1000), [_updateParams]);

  const emptyMessageData = useMemo(() => {
    const p = params?.config?.params || {};

    if (!_.isEmpty(p._search) || !_.isEmpty(filterData)) {
      return {
        description: translate(
          'components.list.hooks.useComponentList.emptyMessageData.description'
        ),
        type: 'search',
      };
    }

    return {
      description: translate(
        'components.list.hooks.useComponentList.emptyMessageData.description'
      ),
      type: 'empty',
    };
  }, [params, filterData, translate]);

  useEffect(() => {
    if (!data) return;

    const isLastPage = allData?.docs?.length === pagination.pageSize * pagination.current;

    if (isLastPage && next && !loadingFivetimes) {
      fetch(pagination.current);
    }

    setQuery({ page: pagination.current });
  }, [pagination.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetch(1, null, true);
    setPagination((state) => ({
      ...state,
      current: 1,
    }));
    setQuery({ page: 1 });
  }, [params, filterData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQuery({ ...filterData });
  }, [filterData, setQuery]);

  const totalItems = useMemo(() => allData?.length || 0, [allData]);

  const loadingList = useMemo(() => {
    return loading || customLoader;
  }, [customLoader, loading]);

  const componentList = useMemo(
    () => (
      <div className="gx-position-relative mrg-top-15">
        {data?.length > 0 && (
          <>
            {data?.map((d) => (
              <Component
                key={d[rowKey]}
                data={d}
                customParserData={customParserData}
                customParserLoading={customParserLoading}
                customModal={customModal}
                refreshList={refreshList}
                maxTags={maxTags}
                socket={socket}
                {...componentProps}
              />
            ))}
            {!hidePagination && (
              <Pagination
                total={pagination.total}
                pageSize={pagination.pageSize}
                current={pagination.current}
                showTotal={(total, range) => {
                  const paginationLabelText = translate(
                    next
                      ? 'components.list.hooks.useComponentList.pagination.showTotalPlus'
                      : 'components.list.hooks.useComponentList.pagination.showTotal',
                    {
                      rangeZero: range[0],
                      rangeOne: range[1],
                      total,
                    }
                  );

                  setPaginationLabel(paginationLabelText);
                  return paginationLabelText;
                }}
                onChange={(_page) => handlePaginationChange(_page)}
                showSizeChanger={false}
              />
            )}
          </>
        )}
        {!data?.length > 0 && !loadingList && (
          <EmptyMessage
            show={!loadingList}
            description={emptyMessageData?.description}
            withCard
            type={emptyMessageData?.type}
          />
        )}
      </div>
    ),
    [
      loadingList,
      data,
      hidePagination,
      pagination,
      emptyMessageData,
      rowKey,
      customParserData,
      customParserLoading,
      customModal,
      refreshList,
      maxTags,
      translate,
      handlePaginationChange,
      next,
      socket,
      componentProps,
    ]
  );

  return {
    componentList,
    data,
    loading,
    loadingFivetimes,
    setFilterData,
    refreshList,
    updateParams,
    getListFiveTimes,
    paginationLabel,
    allData,
    totalItems,
    next,
  };
};

export default useComponentNextList;
