import React, { useMemo } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { convertGlobalDocVerificationResultToIcon } from '../../../../utils/convertGlobalDocVerificationResultToIcon';

const I18N_BASE_PATH =
  'pages.private.executions.components.tabs.components.documentsValidations.newOcrScreen.documentAuthenticity';

const PhotoEmbedType = ({ data }) => {
  const { t: translate } = useTranslation();

  const tableColumns = [
    {
      title: translate(`${I18N_BASE_PATH}.elementType`),
      dataIndex: 'elementType',
      key: 'elementType',
    },
    {
      title: translate(`${I18N_BASE_PATH}.diagnose`),
      dataIndex: 'diagnose',
      key: 'diagnose',
      render: (_, { diagnose }) => (
        <span className={diagnose?.type || ''}>{diagnose?.result || '-'}</span>
      ),
    },
    {
      title: translate(`${I18N_BASE_PATH}.result`),
      dataIndex: 'result',
      key: 'result',
    },
  ];

  const photoEmbedTypeTable = useMemo(() => {
    const tableData = [];
    data?.facePresence?.forEach((facePresenceElement, index) => {
      tableData.push({
        key: `facePresence-${index}`,
        elementType: translate(
          `${I18N_BASE_PATH}.photoEmbedType.facePresence`,
          'Face presence'
        ),
        diagnose: {
          type:
            [0, 1].includes(facePresenceElement?.result) &&
            facePresenceElement?.result === 1
              ? 'approval-diagnose'
              : 'failure-diagnose',
          result: facePresenceElement?.diagnose,
        },
        result: convertGlobalDocVerificationResultToIcon({
          checkResult: facePresenceElement?.result,
          className: 'font-size-18',
        }),
      });
    });
    data?.landmarkCheck?.forEach((landmarkCheckElement, index) => {
      tableData.push({
        key: `landmarkCheck-${index}`,
        elementType: translate(
          `${I18N_BASE_PATH}.photoEmbedType.landmarkCheck`,
          'Landmark check'
        ),
        diagnose: {
          type:
            [0, 1].includes(landmarkCheckElement?.result) &&
            landmarkCheckElement?.result === 1
              ? 'approval-diagnose'
              : 'failure-diagnose',
          result: landmarkCheckElement?.diagnose,
        },
        result: convertGlobalDocVerificationResultToIcon({
          checkResult: landmarkCheckElement?.result,
          className: 'font-size-18',
        }),
      });
    });

    return (
      tableData?.length > 0 && (
        <Table
          columns={tableColumns}
          dataSource={tableData}
          pagination={false}
          tableLayout="fixed"
        />
      )
    );
  }, [data, translate, tableColumns]);

  return (
    <div className="mrg-top-20">
      <h3 className="authenticity-check-title">
        {translate(`${I18N_BASE_PATH}.photoEmbedType`)}
        <span>
          {convertGlobalDocVerificationResultToIcon({
            checkResult: data?.result,
            className: 'font-size-18',
          })}
        </span>
      </h3>
      {(data?.facePresence?.length > 0 || data?.landmarkCheck?.length > 0) &&
        photoEmbedTypeTable}
    </div>
  );
};

PhotoEmbedType.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.oneOf([0, 1, 2]),
    facePresence: PropTypes.arrayOf(
      PropTypes.shape({
        diagnose: PropTypes.string,
        result: PropTypes.oneOf([0, 1, 2]),
      })
    ),
    landmarkCheck: PropTypes.arrayOf(
      PropTypes.shape({
        diagnose: PropTypes.string,
        result: PropTypes.oneOf([0, 1, 2]),
      })
    ),
  }).isRequired,
};

export default PhotoEmbedType;
