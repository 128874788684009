import React, { createContext, useCallback, useContext, useState } from 'react';

import { useFetch } from 'services/hooks';

const ExecutionContext = createContext({});

const ExecutionProvider = ({ children }) => {
  const {
    data: execution,
    loading: loadingExecution,
    get,
    clearData: clearExecution,
  } = useFetch();

  const { data: routine, get: getRoutine } = useFetch();

  const [reloadExecution, setReloadExecution] = useState(false);

  const loadExecution = useCallback(
    async ({ executionId, clearData, queryParams = {} }) => {
      const params = new URLSearchParams({
        signImagesUrls: 'true',
        initCreatedBy: 'true',
        ...queryParams,
      });
      await get({
        url: `executions/${executionId}?${params.toString()}`,
        clearData,
      }).then(async (response) => {
        if (response?.data?.routineId) {
          await getRoutine({
            url: `routines/${response?.data?.routineId}`,
          });
        }
      });

      setReloadExecution(false);
    },
    [get, getRoutine]
  );

  const refreshExecution = useCallback(() => {
    setReloadExecution(true);
  }, []);

  return (
    <ExecutionContext.Provider
      value={{
        execution: execution?.data,
        routine: routine?.data,
        loadingExecution,
        loadExecution,
        refreshExecution,
        reloadExecution,
        clearExecution,
      }}
    >
      {children}
    </ExecutionContext.Provider>
  );
};

function useExecution() {
  const context = useContext(ExecutionContext);

  if (!context) {
    throw new Error('useExecution must be used within an ExecutionContext');
  }

  return context;
}

export { ExecutionProvider, useExecution };
