import React, { useCallback } from 'react';
import CountryFlag from 'components/CountryFlag';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { splitArrayInTwoParts } from 'utils/array';
import TooltipToRenderNextFlags from '../TooltipToRenderNextFlags';

import './styles.less';

const RenderFlagsOfCompany = ({ countries = [], priorityCountry }) => {
  const { t: translate } = useTranslation();
  const splitInIndex = 4;

  const countriesWithPriority = countries.includes(priorityCountry)
    ? [priorityCountry, ...countries.filter((country) => country !== priorityCountry)]
    : countries;

  const [previousTopFiveFlags, nextFlags] = splitArrayInTwoParts({
    array: countriesWithPriority,
    splitInIndex,
  });

  const flagsTitles = nextFlags.map((countryCode) => ({
    flag: countryCode,
    title: translate(`countries.${countryCode}`),
  }));

  const renderNextFlags = useCallback(() => {
    const quantityOfFlags = nextFlags?.length;
    return quantityOfFlags >= 1 ? (
      <TooltipToRenderNextFlags flagsTitles={flagsTitles}>
        <div className="wrapper-country-flag-next-ones">
          <div className="bullets" />
          <div className="bullets" />
          <div className="bullets" />
        </div>
      </TooltipToRenderNextFlags>
    ) : (
      <></>
    );
  }, [nextFlags, flagsTitles]);

  return (
    <div className="render-flags-of-company">
      {previousTopFiveFlags.map((countryCode) => (
        <Tooltip
          placement="topLeft"
          title={<span>{translate(`countries.${countryCode}`)}</span>}
        >
          <div className="wrapper-country-flag">
            <CountryFlag country={countryCode} showTooltip={false} />
          </div>
        </Tooltip>
      ))}
      {renderNextFlags()}
    </div>
  );
};

export default RenderFlagsOfCompany;
