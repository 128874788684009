import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './styles.less';

const colors = {
  MATCH: '#39c560',
  PARTIAL: `#f8b239`,
  NON_MATCH: `#e21b45`,
};

const getColorByScore = (score) => {
  return colors[score] ? colors[score] : '#bbb';
};

const ItemInformation = ({ fieldName, fieldValue, reliabilityCode }) => {
  const { t } = useTranslation();
  return (
    <div className="item-information">
      <div className="no-break-text">
        <strong>
          {`${t(`pages.private.International.GDC.IdentityVerification.${fieldName}`)}:`}
        </strong>
        <span className="field-value">
          {fieldValue ?? t('pages.private.International.GDC.common.noData')}
        </span>
      </div>
      <div className="line" />
      <div className="score-content no-break-text">
        <div
          className="dot"
          style={{
            backgroundColor: getColorByScore(reliabilityCode),
          }}
        />
      </div>
    </div>
  );
};

ItemInformation.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.string.isRequired,
  reliabilityCode: PropTypes.number.isRequired,
};

export default ItemInformation;
