/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';

import EmptyState from 'components/EmptyState';
import EmptyExecutionState from 'components/EmptyExecutionState';

import ProcessesList from '../../Lists/ProcessesList';
import './styles.less';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.legalInformation.componentes.processes.index.processes';

const Processes = ({ source = {}, type, toPrint = false, hasPersonProcesses }) => {
  const { t: translate } = useTranslation();

  const processes = useMemo(() => source.data, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const processesQuerySucceeded = useMemo(
    () =>
      source?.statusCode === '01' ||
      source?.available === true ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [source]
  );

  const hasContent = useMemo(() => processes?.lawsuits?.length > 0, [processes]);

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_file"
      title={translate(`${I18N_BASE_PATH}.companyProcessesTitle`)}
      toPrint={toPrint}
    >
      <div id="executions-legal-information-processes-container">
        <Col span={24}>
          {processes && (
            <>
              {processesQuerySucceeded ? (
                <>
                  {hasContent ? (
                    <ProcessesList
                      data={processes?.lawsuits}
                      toPrint={toPrint}
                      displayValue={3}
                      hasPersonProcesses={hasPersonProcesses}
                    />
                  ) : (
                    <EmptyState
                      type="empty-success"
                      description={translate(
                        `${I18N_BASE_PATH}.processesAltQuerySucceeded.emptyState.description`,
                        { type }
                      )}
                      className="mrg-btm-20 mrg-top-0"
                    />
                  )}
                </>
              ) : (
                <EmptyExecutionState
                  statusCode={statusCode}
                  message={source?.message || processes?.message}
                />
              )}
            </>
          )}
        </Col>
      </div>
    </DataSourceCardCollapsePanel>
  );
};

export default Processes;
