const handleHubSelection = {
  setHubSelected(isSelected = false) {
    localStorage.setItem('hubIsSelected', isSelected);
  },
  getHubSelected() {
    return localStorage.getItem('hubIsSelected');
  },
  intializeHubSelection() {
    if (handleHubSelection.getHubSelected() === null) {
      handleHubSelection.setHubSelected();
    }
  },
  shouldHubSelect() {
    const hubIsSelected = localStorage.getItem('hubIsSelected') === 'true';
    return !hubIsSelected;
  },
  removeHubSelected() {
    localStorage.removeItem('hubIsSelected');
  },
};

export default handleHubSelection;
