import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import './styles.less';

const ProfileWidgetFooter = ({ title, onClick }) => {
  return (
    <div id="profile-widget-footer-btn">
      <Button type="text" onClick={onClick}>
        {title}
      </Button>
    </div>
  );
};

ProfileWidgetFooter.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ProfileWidgetFooter;
