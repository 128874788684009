/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import { toMaskedCpf } from 'utils/formatters';

import TableInfoCard from 'pages/private/Profiles/components/TableInfoCard/Column';

const ValidationsCNHData = ({ data }) => {
  const { t: translate } = useTranslation();

  return (
    <>
      <Row gutter={[32, 24]} className="custom-validations-data">
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsCNHData.index.validationsCNHData.tableInfoCard.title.name'
          )}
          content={data?.name}
        />
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsCNHData.index.validationsCNHData.tableInfoCard.title.cpf'
          )}
          content={toMaskedCpf(data?.cpf)}
        />
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsCNHData.index.validationsCNHData.tableInfoCard.title.rg'
          )}
          content={data?.rg}
        />
      </Row>

      <Row gutter={[32, 24]} className="custom-validations-data">
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsCNHData.index.validationsCNHData.tableInfoCard.title.birthDate'
          )}
          content={data?.birthDate}
        />
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsCNHData.index.validationsCNHData.tableInfoCard.title.motherName'
          )}
          content={data?.motherName}
        />
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsCNHData.index.validationsCNHData.tableInfoCard.title.fatherName'
          )}
          content={data?.fatherName}
        />
      </Row>

      <Row gutter={[32, 24]} className="custom-validations-data">
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsCNHData.index.validationsCNHData.tableInfoCard.title.category'
          )}
          content={data?.category}
        />
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsCNHData.index.validationsCNHData.tableInfoCard.title.cnhIssueDate'
          )}
          content={data?.cnhIssueDate}
        />
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsCNHData.index.validationsCNHData.tableInfoCard.title.placeOfIssue'
          )}
          content={data?.cnhPlaceOfIssue || data?.placeOfIssue}
        />
      </Row>

      <Row gutter={[32, 24]} className="custom-validations-data">
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsCNHData.index.validationsCNHData.tableInfoCard.title.cnhIssueState'
          )}
          content={data?.cnhIssueState}
        />
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsCNHData.index.validationsCNHData.tableInfoCard.title.expirationDate'
          )}
          content={data?.expirationDate}
        />
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsCNHData.index.validationsCNHData.tableInfoCard.title.firstDriverLicenseDate'
          )}
          content={data?.firstDriverLicenseDate}
        />
      </Row>

      <Row gutter={[32, 24]} className="custom-validations-data">
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsCNHData.index.validationsCNHData.tableInfoCard.title.mirrorNumber'
          )}
          content={data?.mirrorNumber}
        />
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsCNHData.index.validationsCNHData.tableInfoCard.title.registrationNumber'
          )}
          content={data?.registrationNumber}
        />
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsCNHData.index.validationsCNHData.tableInfoCard.title.securityNumber'
          )}
          content={data?.securityNumber}
        />
      </Row>

      <Row gutter={[32, 24]} className="custom-validations-data">
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsCNHData.index.validationsCNHData.tableInfoCard.title.renachNumber'
          )}
          content={data?.renachNumber}
        />
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsCNHData.index.validationsCNHData.tableInfoCard.title.rgIssueState'
          )}
          content={data?.rgIssueState}
          bordered={false}
        />
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsCNHData.index.validationsCNHData.tableInfoCard.title.rgIssuingAuthority'
          )}
          content={data?.rgIssuingAuthority}
          bordered={false}
        />
      </Row>

      <Row gutter={[32, 24]} className="custom-validations-data">
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsCNHData.index.validationsCNHData.tableInfoCard.title.notes'
          )}
          content={data?.notes}
          bordered={false}
        />
      </Row>
    </>
  );
};

export default ValidationsCNHData;
