import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam } from 'use-query-params';
import { Row } from 'antd';

import { useComponentNextList } from 'components/List';

import PageTitle from 'components/PageTitle';

import FaceAuthenticatorCard from './components/FaceAuthenticatorCard';
import EmptyPage from './components/EmptyPage';

// import './styles.less';

const FaceAuthenticatorList = () => {
  const { t: translate } = useTranslation();

  const { data, componentList, updateParams } = useComponentNextList({
    component: FaceAuthenticatorCard,
    rowKey: '_id',
    url: 'faceauthenticator',
    queryParams: {
      peopleId: StringParam,
      isMatch: StringParam,
      createdAt: StringParam,
    },
  });

  const [query] = useQueryParams({
    peopleId: StringParam,
    isMatch: StringParam,
    _order: StringParam,
    _sort: StringParam,
    createdAt: StringParam,
  });

  const mapFilters = useCallback(() => {
    const filters = {};

    if (query.createdAt) {
      switch (query.createdAt) {
        case 'last24Hours':
          filters._startCreatedAt = new Date().getTime() - 24 * 60 * 60 * 1000;
          filters._endCreatedAt = new Date().getTime();
          break;
        case 'last7Days':
          filters._startCreatedAt = new Date().getTime() - 24 * 60 * 60 * 1000 * 7;
          filters._endCreatedAt = new Date().getTime();
          break;
        case 'last30Days':
          filters._startCreatedAt = new Date().getTime() - 24 * 60 * 60 * 1000 * 30;
          filters._endCreatedAt = new Date().getTime();
          break;
        case 'lastYear':
          filters._startCreatedAt = new Date().setFullYear(new Date().getFullYear() - 1);
          filters._endCreatedAt = new Date().getTime();
          break;
        default:
          if (query.createdAt.substr(0, 6) === 'custom') {
            const [startDate, endDate] = query.createdAt.substr(7).split('-');
            filters._startCreatedAt = startDate;
            filters._endCreatedAt = endDate;
          }
          break;
      }
      filters._startCreatedAt = filters._startCreatedAt
        ? new Date(parseInt(filters._startCreatedAt, 10))
        : null;
      filters._endCreatedAt = filters._endCreatedAt
        ? new Date(parseInt(filters._endCreatedAt, 10))
        : null;
    }

    if (query._sort && query._order) {
      filters._sort = query._sort;
      filters._order = query._order;
    }

    filters.peopleId = query.peopleId;
    filters._isMatch = query.isMatch;
    return filters;
  }, [query]);

  useEffect(() => {
    updateParams({
      url: 'faceauthenticator',
      config: {
        params: { ...mapFilters() },
      },
    });
  }, [query]); // eslint-disable-line

  return (
    <div id="profiles-list-component">
      <Row align="middle" justify="space-between" className="no-mrg">
        <PageTitle
          title={translate(
            'pages.private.faceAuthenticator.components.list.pageTitle.title'
          )}
          subtitle={translate(
            'pages.private.faceAuthenticator.components.list.pageTitle.subtitle'
          )}
        />
      </Row>
      {data?.totalItems === 0 ? <EmptyPage /> : componentList}
    </div>
  );
};

export default FaceAuthenticatorList;
