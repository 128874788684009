import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin, Pagination } from 'antd';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
import _ from 'lodash';
import classNames from 'classnames';

import { useFetch } from 'services/hooks';

import EmptyMessage from 'components/EmptyMessage';
import { scrollToTop } from 'utils/scroll';

import { initialFilterData } from '../utils';

const useComponentDocumentDBList = ({
  component: Component,
  hidePagination,
  noLimit,
  rowKey,
  getParams,
  queryParams,
  customDataParser,
  customModal,
  customLoader,
  maxTags,
  customApi,
  toPrint,
  workflowsData = {},
  shouldApplyDefaultMargin = true,
  componentProps = {},
}) => {
  const { t: translate } = useTranslation();

  const { loading, get, clearData } = useFetch({ customApi });
  const [data, setData] = useState();

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    _order: StringParam,
    _sort: StringParam,
    ...queryParams,
  });

  const [customParserData, setCustomParserData] = useState();
  const [customParserLoading, setCustomParserLoading] = useState(false);

  const [params, setParams] = useState(getParams);
  const [pagination, setPagination] = useState({
    current: query?.page || 1,
    pageSize: 10,
    total: 0,
    isAsc: false,
    limit: 10,
    offset: 0,
    order: 'DESC',
    sort: 'updatedAt',
    showTotal: (total, range) =>
      translate('components.list.hooks.useComponentList.pagination.showTotal', {
        rangeZero: range[0],
        rangeOne: range[1],
        total,
      }),
  });
  const [filterData, setFilterData] = useState(() => {
    return initialFilterData({ query, queryParams });
  });

  const fetch = useCallback(
    async (current) => {
      if (!params) return;

      scrollToTop();

      const pageSize = pagination.pageSize || 20;

      const res = await get({
        url: params.url,
        config: {
          params: {
            _limit: noLimit ? undefined : pageSize,
            _offset: noLimit ? undefined : pageSize * (current - 1),
            _sort: pagination.sort,
            _order: pagination.order,
            ...filterData,
            ...params.config.params,
          },
        },
      });

      const { data: responseData, pagination: responsePagination } = res;

      setData(responseData);

      setPagination((oldState) => ({
        ...oldState,
        current,
        pageSize,
        total: responsePagination.total,
        limit: responsePagination.limit,
        offset: responsePagination.offset,
        order: responsePagination.order,
        sort: responsePagination.sort,
      }));

      if (customDataParser) {
        setCustomParserLoading(true);
        setCustomParserData(await customDataParser(responseData));
        setCustomParserLoading(false);
      }
    },
    [params, pagination, get, noLimit, filterData, customDataParser]
  );

  const refreshList = useCallback(() => {
    fetch(pagination.current || 1);
  }, [fetch, pagination]);

  const updateParams = useCallback((newParams) => {
    setParams(newParams);
  }, []);

  const handlePaginationChange = useCallback(
    (page) => {
      const newPagination = {
        ...pagination,
        current: page,
        offset: pagination.limit * (page - 1),
      };

      setPagination(newPagination);
    },
    [pagination]
  );

  const emptyMessageData = useMemo(() => {
    const p = params?.config?.params || {};

    if (!_.isEmpty(p._search) || !_.isEmpty(filterData)) {
      return {
        description: translate(
          'components.list.hooks.useComponentList.emptyMessageData.description'
        ),
        type: 'search',
      };
    }

    return {
      description: translate(
        'components.list.hooks.useComponentList.emptyMessageData.description'
      ),
      type: 'empty',
    };
  }, [params, filterData, translate]);

  useEffect(() => {
    if (!data) return;

    fetch(pagination.current || 1);
    setQuery({ page: pagination.current });
  }, [pagination.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (pagination.current === 1 || !data) {
      refreshList();
      setQuery({ page: pagination.current });
      return;
    }

    setPagination((state) => ({
      ...state,
      current: 1,
    }));
    setQuery({ page: 1 });
  }, [params, filterData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQuery({ ...filterData });
  }, [filterData, setQuery]);

  const loadingList = useMemo(() => {
    return loading || customLoader;
  }, [customLoader, loading]);

  const componentList = useMemo(
    () => (
      <div
        className={classNames('gx-position-relative', {
          'mrg-top-15': !!shouldApplyDefaultMargin,
        })}
      >
        {loadingList && (
          <div className="custom-loading-container">
            <Spin className="mrg-top-30" />
          </div>
        )}
        {(data?.length > 0 || !loadingList) && (
          <>
            {data?.map((d) => (
              <Component
                key={d[rowKey]}
                data={{ ...d, workflowsData }}
                customParserData={customParserData}
                customParserLoading={customParserLoading}
                customModal={customModal}
                refreshList={refreshList}
                maxTags={maxTags}
                toPrint={toPrint}
                {...componentProps}
              />
            ))}
            {!hidePagination && (
              <Pagination
                total={pagination.total}
                pageSize={pagination.pageSize}
                current={pagination.current}
                showTotal={(total, range) =>
                  translate(
                    'components.list.hooks.useComponentList.pagination.showTotal',
                    {
                      rangeZero: range[0],
                      rangeOne: range[1],
                      total,
                    }
                  )
                }
                onChange={(page) => handlePaginationChange(page)}
                showSizeChanger={false}
                style={{ padding: 10 }}
              />
            )}
          </>
        )}
        {!data?.length > 0 && !loadingList && (
          <EmptyMessage
            show={!loadingList}
            description={emptyMessageData?.description}
            withCard
            type={emptyMessageData?.type}
          />
        )}
      </div>
    ),
    [
      loadingList,
      data,
      hidePagination,
      pagination,
      emptyMessageData,
      rowKey,
      customParserData,
      customParserLoading,
      customModal,
      refreshList,
      maxTags,
      toPrint,
      translate,
      componentProps,
      workflowsData,
      shouldApplyDefaultMargin,
    ]
  );

  return {
    componentList,
    data,
    loading,
    setFilterData,
    refreshList,
    updateParams,
    clearData,
  };
};

export default useComponentDocumentDBList;
