import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col, message, Form, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { useQueryParams, StringParam } from 'use-query-params';

import PageTitle from 'components/PageTitle';
import { useProfileTemplate } from 'hooks/profileTemplate';

import Footer from '../components/Footer';
import ComplianceRules from '../../components/ComplianceRules';
import PriceWidget from '../../components/PriceWidget';

const { Option } = Select;

const ComplianceRulesStep = ({
  formRef,
  onValuesChange,
  allProfileTemplates,
  loadingAllProfileTemplates,
}) => {
  const { t: translate } = useTranslation();

  const history = useHistory();
  const [query, setQuery] = useQueryParams({
    step: StringParam,
    action: StringParam,
  });

  const { profileTemplate, summaryPrices, setProfileTemplate } = useProfileTemplate();

  const onAlwaysManualReviewChange = useCallback(
    (value) => {
      if (value) {
        const formValues = formRef.getFieldsValue();
        setProfileTemplate({ ...formValues, alwaysManualReview: value });
      }
    },
    [formRef, setProfileTemplate]
  );

  const onSubmit = useCallback(async () => {
    if (!formRef) return;

    try {
      const values = await formRef.getFieldsValue();

      values?.validations?.map((validation) => {
        if (validation?.rule?.value === 'invalid_range_ages') {
          const hasIntersection = validation?.invalidRangeAgesList?.some(
            (obj, index, array) => {
              const isIntersecting = array?.some(
                (otherObj, otherIndex) =>
                  index !== otherIndex &&
                  !(obj?.max < otherObj?.min || obj?.min > otherObj?.max)
              );
              return isIntersecting;
            }
          );
          if (hasIntersection) {
            message.warning(
              translate(
                translate(
                  'pages.private.profileModels.profileModelCreate.complianceRulesStep.invalidRangeAges.hasIntersection'
                )
              )
            );
            throw new Error('invalidRangeAgesList');
          }
          validation?.invalidRangeAgesList?.map((item) => {
            if (item?.min > item?.max) {
              message.warning(
                translate(
                  'pages.private.profileModels.profileModelCreate.complianceRulesStep.invalidRangeAges.validation'
                )
              );
              throw new Error('invalidRangeAgesList');
            }
          });
        }

        const isScoreCheckRule = (rule) =>
          [
            'score_check_below',
            'score_check_over',
            'score_check_between',
            'credit_score_below',
            'credit_score_over',
            'credit_score_between',
          ].includes(rule);

        const findValidation = (values, rule) =>
          values?.validations?.find((validation) => validation?.rule?.value === rule);

        const showMessage = (key) => {
          message.warning(
            translate(
              `pages.private.profileModels.profileModelCreate.complianceRulesStep.score.validation.${key}`
            )
          );
        };

        if (isScoreCheckRule(validation?.rule?.value)) {
          const scoreCheckOver = findValidation(values, 'score_check_over');
          const scoreCheckBelow = findValidation(values, 'score_check_below');

          const creditScoreOver = findValidation(values, 'credit_score_over');
          const creditScoreBelow = findValidation(values, 'credit_score_below');

          const maxScore = Number(validation?.maxScore);
          const minScore = Number(validation?.minScore);
          const belowScore =
            Number(scoreCheckBelow?.belowScore) || Number(creditScoreBelow?.belowScore);
          const overScore =
            Number(scoreCheckOver?.overScore) || Number(creditScoreOver?.overScore);

          if (maxScore <= minScore) {
            showMessage('between');
            throw new Error('invalidScore');
          } else if (belowScore >= overScore) {
            showMessage('belowOver');
            throw new Error('invalidScore');
          } else if (overScore <= maxScore) {
            showMessage('over');
            throw new Error('invalidScore');
          } else if (belowScore >= minScore) {
            showMessage('below');
            throw new Error('invalidScore');
          }
        }
      });

      await formRef.validateFields();
    } catch (error) {
      const { errorFields } = error;

      if (errorFields?.length > 0) {
        formRef.scrollToField(errorFields[0].name);
      }
      if (!['invalidRangeAgesList', 'invalidScore'].includes(error?.message)) {
        message.warning(
          translate(
            'pages.private.profileModels.profileModelCreate.complianceRulesStep.onSubmit.messages.warning'
          )
        );
      }

      return;
    }

    if (query.action === 'update') {
      setQuery({ step: 'confirmation', action: undefined });
    } else {
      setQuery({ step: 'confirmation' });
    }
  }, [formRef, query.action, setQuery, translate]);

  return (
    <>
      <Row className="gx-flex-nowrap no-mrg mrg-btm-30" align="middle">
        <Col flex={1}>
          <PageTitle
            title={translate(
              'pages.private.profileModels.profileModelCreate.complianceRulesStep.pageTitle.title'
            )}
            subtitle={translate(
              'pages.private.profileModels.profileModelCreate.complianceRulesStep.pageTitle.subtitle'
            )}
          />
        </Col>
        <Col>
          <Form.Item
            name="alwaysManualReview"
            label={translate(
              'pages.private.profileModels.profileModelCreate.complianceRulesStep.manualButton.title'
            )}
            className="no-mrg"
          >
            <Select
              defaultValue={translate(
                'pages.private.profileModels.profileModelCreate.complianceRulesStep.manualButton.false'
              )}
              onChange={(value) => onAlwaysManualReviewChange(value)}
            >
              <Option value>
                {translate(
                  'pages.private.profileModels.profileModelCreate.complianceRulesStep.manualButton.true'
                )}
              </Option>
              <Option value={false}>
                {translate(
                  'pages.private.profileModels.profileModelCreate.complianceRulesStep.manualButton.false'
                )}
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <PriceWidget
            pricePerProfile={summaryPrices?.pricePerProfile}
            pricePerMonth={summaryPrices?.pricePerMonth}
          />
        </Col>
      </Row>

      <ComplianceRules
        profileType={profileTemplate?.type}
        formRef={formRef}
        onValuesChange={onValuesChange}
        profilePfTemplates={allProfileTemplates}
        loadingAllProfileTemplates={loadingAllProfileTemplates}
      />

      <Footer
        onCancel={() => history.goBack()}
        onSubmit={() => onSubmit()}
        buttonCancelTitle={translate(
          'pages.private.profileModels.profileModelCreate.complianceRulesStep.footer.buttonCancelTitle'
        )}
      />
    </>
  );
};

ComplianceRulesStep.propTypes = {
  formRef: PropTypes.shape().isRequired,
  onValuesChange: PropTypes.func.isRequired,
  allProfileTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  loadingAllProfileTemplates: PropTypes.bool.isRequired,
};

export default ComplianceRulesStep;
