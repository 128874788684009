import React, { useState, useCallback, useMemo } from 'react';
import { Modal, Button, Form, message, Input, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import { useFetch } from 'services/hooks';
import PageTitle from 'components/PageTitle';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.tagsAndReasons.components.ModalReasonUpdate';

const ModalReasonUpdate = () => {
  const [form] = Form.useForm();
  const { t: translate } = useTranslation();
  const { put, post, loading } = useFetch();

  const [reason, setReason] = useState();
  const [refreshListRef, setRefreshListRef] = useState();
  const [isNewReason, setIsNewReason] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [reasonType, setReasonType] = useState();

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(({ data, refreshList, type, isNew }) => {
    setReason(data);
    setReasonType(type);
    setIsNewReason(isNew);
    setRefreshListRef(refreshList);
    setModalVisible(true);
  }, []);

  const handleUpdateReason = useCallback(async () => {
    const values = form.getFieldsValue();
    try {
      await put({
        url: `reasons/${reason?._id}`,
        payload: {
          title: values?.title,
          description: values?.description,
          type: reason?.type,
        },
      });

      message.success(translate(`${I18N_BASE_PATH}.handleUpdateMessages.success`));
      refreshListRef?.current();
      closeModal();
    } catch (error) {
      message.error(translate(`${I18N_BASE_PATH}.handleUpdateMessages.error`));
      refreshListRef?.current();
    }
  }, [form, refreshListRef, put, reason, closeModal, translate]);

  const handleSaveReason = useCallback(async () => {
    const values = form.getFieldsValue();
    try {
      await post({
        url: 'reasons',
        payload: {
          title: values.title,
          description: values.description,
          type: reasonType,
        },
      });
      message.success(translate(`${I18N_BASE_PATH}.handleSaveMessages.success`));
      refreshListRef?.current();
      closeModal();
    } catch (error) {
      message.error(translate(`${I18N_BASE_PATH}.handleSaveMessages.error`));
    }
  }, [form, refreshListRef, post, reasonType, closeModal, translate]);

  const ReasonUpdateModal = useMemo(
    () => (
      <Modal
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        visible={modalVisible}
        footer={null}
        width={700}
        onCancel={closeModal}
        destroyOnClose
      >
        <PageTitle
          title={translate(`${I18N_BASE_PATH}.title.${isNewReason ? 'create' : 'edit'}`)}
          subtitle={translate(`${I18N_BASE_PATH}.subtitle`)}
          style={{ marginBottom: 20 }}
        />

        <Col span={24}>
          <Form form={form}>
            <Form.Item
              name="title"
              rules={[{ required: true, min: 3 }]}
              label={translate(`${I18N_BASE_PATH}.labels.title`)}
            >
              <Input defaultValue={reason?.title} />
            </Form.Item>
            <Form.Item name="description">
              <Input.TextArea
                rows="5"
                placeholder={translate(`${I18N_BASE_PATH}.labels.description`)}
                defaultValue={reason?.description}
              />
            </Form.Item>
          </Form>

          <div className="modal-integration-action-buttons" align="middle" justify="end">
            <Button
              shape="round"
              className="flex no-mrg-btm center"
              outlined="false"
              onClick={closeModal}
            >
              {translate(`${I18N_BASE_PATH}.buttons.cancel`)}
            </Button>
            <Button
              type="primary"
              shape="round"
              className="flex no-mrg-btm center"
              outlined="false"
              onClick={isNewReason ? handleSaveReason : handleUpdateReason}
              loading={loading}
              disabled={loading}
            >
              {translate(`${I18N_BASE_PATH}.buttons.submit`)}
            </Button>
          </div>
        </Col>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      translate,
      form,
      reason,
      isNewReason,
      handleSaveReason,
      handleUpdateReason,
      loading,
    ]
  );

  return { openModal, ReasonUpdateModal };
};

export default ModalReasonUpdate;
