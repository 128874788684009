/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useExecution } from 'hooks/execution';
import { usePerson } from 'hooks/person';
import { useCompany } from 'hooks/company';
import * as executionDataSources from 'utils/executionDataSource';
import PjMediaProfileAndExposure from './pjMediaProfileAndExposure';

import DataSourceCardCollapsePanel from '../../DataSourceCardCollapsePanel';

const MediaExposureContent = ({ toPrint = false }) => {
  const { t: translate } = useTranslation();
  const { execution } = useExecution();
  const { company } = useCompany();
  const { person } = usePerson();

  const findSourceSection = useCallback(
    (section) =>
      executionDataSources.findSourceSection(execution, section) ||
      executionDataSources.findSourceSection(company, section) ||
      executionDataSources.findSourceSection(person, section),
    [company, execution, person]
  );

  const mediaExposure = useMemo(() => findSourceSection('pjMediaProfileAndExposure'), [
    findSourceSection,
  ]);

  return (
    <>
      <DataSourceCardCollapsePanel
        customId="media-exposure-component"
        title={translate('components.dataSourceCards.mediaExposure.title')}
        icon="caf-ic_news"
        toPrint={toPrint}
      >
        {mediaExposure && (
          <PjMediaProfileAndExposure source={mediaExposure} toPrint={toPrint} />
        )}
      </DataSourceCardCollapsePanel>
    </>
  );
};

export default MediaExposureContent;
