import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ImagePatterns from './ImagePatterns';
import BarcodeFormatCheck from './BarcodeFormatCheck';
import PhotoEmbedType from './PhotoEmbedType';

import './styles.less';

const I18N_BASE_PATH =
  'pages.private.executions.components.tabs.components.documentsValidations.newOcrScreen';

const DocumentAuthenticity = ({ data }) => {
  const { t: translate } = useTranslation();

  const showDocumentAuthenticityBySide = (sideData, sideKey) => {
    return sideData?.imagePattern ||
      sideData?.barcodeFormatCheck ||
      sideData?.photoEmbedType ? (
      <div
        className={`document-authenticity-container ${
          sideKey === 'back' ? 'mrg-top-30' : ''
        }`}
      >
        <h2>
          <strong>
            {translate(
              `${I18N_BASE_PATH}.documentAuthenticity.${sideKey}`,
              'Side of the document'
            )}
          </strong>
        </h2>
        {sideData?.imagePattern && <ImagePatterns data={sideData?.imagePattern} />}

        {sideData?.barcodeFormatCheck && (
          <BarcodeFormatCheck data={sideData?.barcodeFormatCheck} />
        )}

        {sideData?.photoEmbedType && <PhotoEmbedType data={sideData?.photoEmbedType} />}
      </div>
    ) : (
      <></>
    );
  };

  return (
    <>
      {data?.front && showDocumentAuthenticityBySide(data?.front, 'front')}
      {data?.back && showDocumentAuthenticityBySide(data?.back, 'back')}
    </>
  );
};

DocumentAuthenticity.propTypes = {
  data: PropTypes.shape({
    [PropTypes.oneOf(['front', 'back'])]: PropTypes.shape({
      imagePattern: PropTypes.shape({
        result: PropTypes.oneOf([0, 1, 2]),
        blank: PropTypes.arrayOf(
          PropTypes.shape({
            image: PropTypes.shape({
              signUrl: PropTypes.string,
            }),
            etalonImage: PropTypes.shape({
              signUrl: PropTypes.string,
            }),
            similarity: PropTypes.number,
            diagnose: PropTypes.string,
            result: PropTypes.oneOf([0, 1, 2]),
          })
        ),
      }),
      barcodeFormatCheck: PropTypes.shape({
        result: PropTypes.oneOf([0, 1, 2]),
        barcode: PropTypes.arrayOf(
          PropTypes.shape({
            diagnose: PropTypes.string,
            result: PropTypes.oneOf([0, 1, 2]),
          })
        ),
      }),
      photoEmbedType: PropTypes.shape({
        result: PropTypes.oneOf([0, 1, 2]),
        facePresence: PropTypes.arrayOf(
          PropTypes.shape({
            diagnose: PropTypes.string,
            result: PropTypes.oneOf([0, 1, 2]),
          })
        ),
        landmarkCheck: PropTypes.arrayOf(
          PropTypes.shape({
            diagnose: PropTypes.string,
            result: PropTypes.oneOf([0, 1, 2]),
          })
        ),
      }),
    }),
  }).isRequired,
};

export default DocumentAuthenticity;
