/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import ViewMore from 'components/ViewMore';
import EmptyState from 'components/EmptyState';

import ContactAndRelationCard from 'pages/private/Profiles/components/ContactAndRelationCard';
import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import useAllPhoneRelatedPeopleModal from './AllPhoneRelatedPeopleModal';

const PhoneRelatedPeople = ({ profile, toPrint /* urlId */ }) => {
  const { t: translate } = useTranslation();

  const peoplePhones = useMemo(() => profile?.relationships?.peoplePhones, [profile]);

  const { openModal, AllPhoneRelatedPeopleModal } = useAllPhoneRelatedPeopleModal({
    data: profile?.relationships?.peoplePhones,
  });

  const isProcessing = useMemo(() => verifySourcesProcessing(profile, 'peoplePhones'), [
    profile,
  ]);

  const parsedList = useMemo(() => {
    if (!peoplePhones || !peoplePhones?.items?.length > 0) return <></>;

    const el = [];
    const _size = peoplePhones?.items?.length;

    if (_size) {
      for (let i = 0; i < _size; i += 3) {
        el.push(
          <Row className="contact-data-row">
            {peoplePhones?.items?.slice(i, i + 3)?.map((peoplePhone) => (
              <ContactAndRelationCard
                key={peoplePhone?.number}
                type={peoplePhone?.type}
                content={`(${peoplePhone?.areaCode || '-'}) ${
                  peoplePhone?.number || '-'
                }`}
                span={8}
                className="mrg-top-10 pdd-horizontal-0"
              />
            ))}
          </Row>
        );
      }
    }

    if (el.length > 0) {
      const parsedEl = toPrint ? el : el.splice(0, 1);
      return parsedEl;
    }

    return <></>;
  }, [peoplePhones, toPrint]);

  return (
    <>
      {AllPhoneRelatedPeopleModal}

      <Row className="contact-data-container">
        <ColumnLabel
          title={translate(
            'pages.private.profiles.components.dataSourceCards.relationships.components.phoneRelatedPeople.index.phoneRelatedPeople.columnLabel.title'
          )}
          span={24}
        >
          {!peoplePhones && isProcessing && <EmptyState type="processing" />}

          {!peoplePhones && !isProcessing && <EmptyState type="waiting_consult" />}

          {peoplePhones && !peoplePhones?.items?.length > 0 && (
            <EmptyState
              type="empty-success"
              description={translate(
                'pages.private.profiles.components.dataSourceCards.relationships.components.phoneRelatedPeople.index.phoneRelatedPeople.emptyState.description'
              )}
            />
          )}

          {parsedList}
        </ColumnLabel>
        {/* </Col> */}
      </Row>

      {!toPrint && peoplePhones?.items?.length > 3 && (
        <Row className="mrg-btm-10">
          <ViewMore
            title={translate(
              'pages.private.profiles.components.dataSourceCards.relationships.components.phoneRelatedPeople.index.phoneRelatedPeople.viewMore.title'
            )}
            type="link"
            onClick={() => openModal()}
          />
        </Row>
      )}
    </>
  );
};

export default PhoneRelatedPeople;
