import React, { useCallback, useMemo, useState } from 'react';
import { Button, Modal, Form, Input } from 'antd';
import RequestSuccess from 'components/RequestResult/Success';
import './styles.less';

const ModalConnectExecution = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [executionId, setExecutionId] = useState(undefined);

  const openModal = useCallback((executionId) => {
    setModalVisible(true);
    setExecutionId(executionId);
  }, []);
  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const renderContent = useMemo(() => {
    return (
      <>
        <div id="connect-execution-modal">
          <strong>New connection</strong>
          <span>Are you sure you want to link this execution to this profile?</span>
        </div>
        <footer id="connect-execution-modal-footer">
          <Button type="ghost" onClick={closeModal}>
            Cancelar
          </Button>
          <Button type="primary" onClick={() => setIsFinished(true)}>
            Yes
          </Button>
        </footer>
      </>
    );
  }, [closeModal]);

  const ModalComponent = useMemo(() => {
    return (
      <Modal
        visible={modalVisible}
        footer={null}
        width={800}
        onCancel={closeModal}
        destroyOnClose
      >
        {isFinished ? (
          <RequestSuccess
            title="The selected profiles have been created"
            className="mrg-vertical-50"
          />
        ) : (
          renderContent
        )}
      </Modal>
    );
  }, [modalVisible, closeModal, isFinished, renderContent]);

  return { ModalComponent, openModal };
};

export default ModalConnectExecution;
