/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import DossierCard from 'pages/private/Profiles/components/DossierCard';

import CadinSP from './components/CadinSP';

import SPC from '../Shared/SPC';
import PGFN from '../Shared/PGFN';
import Protests from '../Shared/Protests';
import ZoopScore from '../Shared/Zoop/Score';

const BusinessFinancialInformation = ({ data, toPrint, toPrintDetails }) => {
  const { t: translate } = useTranslation();

  const dataSources = useMemo(
    () =>
      data?.profileTemplate?.backgroundChecking?.services?.map(({ source }) => source) ||
      [],
    [data]
  );

  const showSPC = useMemo(() => dataSources.includes('pj_spc') || data?.finance?.spc, [
    data,
    dataSources,
  ]);

  const showProtest = useMemo(
    () => dataSources.includes('pj_protesto') || data?.finance?.protest,
    [data, dataSources]
  );

  const showPGFN = useMemo(
    () => dataSources.includes('pj_pgfn') || data?.certificates?.pgfn,
    [data, dataSources]
  );

  const showCadinSP = useMemo(
    () => dataSources.includes('pj_cadin_sp') || data?.certificates?.cadinSp,
    [data, dataSources]
  );

  const showZoopScore = useMemo(
    () => dataSources.includes('pjScoreCheck') || data?.finance?.scoreCheck,
    [data, dataSources]
  );

  return (
    <DossierCard
      customId="financial-infos-component"
      title={translate(
        'pages.private.profiles.components.dataSourceCards.financialInformation.companies.index.companies.dossierCard.title'
      )}
      subtitle={translate(
        'pages.private.profiles.components.dataSourceCards.financialInformation.companies.index.companies.dossierCard.subtitle'
      )}
      icon="caf-ic_dollar_sign"
    >
      {showSPC && (
        <Row>
          <SPC
            profile={data}
            /* urlId={urlId} */
            toPrint={toPrint}
            toPrintDetails={toPrintDetails}
          />
        </Row>
      )}

      <Row className="finance-infos-group">
        {showCadinSP && <CadinSP profile={data} /* urlId={urlId} */ toPrint={toPrint} />}
        {showPGFN && (
          <PGFN
            profile={data}
            /* urlId={urlId} */ type={translate(
              'pages.private.profiles.components.dataSourceCards.financialInformation.companies.index.companies.type'
            )}
            toPrint={toPrint}
          />
        )}
        {showProtest && (
          <Protests
            profile={data}
            /* urlId={urlId} */
            type={translate(
              'pages.private.profiles.components.dataSourceCards.financialInformation.companies.index.companies.type'
            )}
            toPrint={toPrint}
          />
        )}

        {showZoopScore && <ZoopScore profile={data} />}
      </Row>
    </DossierCard>
  );
};

export default BusinessFinancialInformation;
