import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Divider, Spin, Input, Row, Col } from 'antd';

import { useExecution } from 'hooks/execution';

import { useFetch } from 'services/hooks';

import Button from 'components/Button';

import Title from 'antd/lib/typography/Title';

import classNames from 'classnames';

import './styles.less';

import ActionRequired from 'assets/images/action_required.svg';
import ApproveStatusIconButton from 'assets/images/approve-status-button.svg';
import ReproveStatusIconButton from 'assets/images/reprove-status-button.svg';

import CustomStatusReasons from './components/CustomStatusReasons';

const I18N_PENDING_REVIEW_BASE_PATH =
  'pages.private.profiles.components.profileValidations.components.pendingReviewModal.index';

const { TextArea } = Input;

const ModalManualReview = ({ data, resultsApproved, resultsReproved, dataSource }) => {
  const { t: translate } = useTranslation();
  const { loading: loadingExecution, loadExecution } = useExecution();

  const { loading, patch } = useFetch();
  const [modalVisible, setModalVisible] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [statusSelected, setStatusSelected] = useState('');
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [isActionRequired, setIsActionRequired] = useState(false);
  const [customStatusReasons, setCustomStatusReasons] = useState([]);

  const closeModal = useCallback(() => {
    setStatusSelected('');
    setTextAreaValue('');
    setSelectedReasons([]);
    setCustomStatusReasons([]);
    setModalVisible(false);
  }, []);

  const onSaveRuleUpdated = useCallback(
    async ({ ruleId, oldStatus }) => {
      const payload = {
        id: ruleId,
        type: 'RULE',
        status: statusSelected,
        data: {
          statusSelected,
          selectedReasons,
          justification: textAreaValue,
          resultsApproved,
          resultsReproved,
          source: dataSource,
          oldStatus,
        },
      };

      await patch({
        url: `/executions/${data?._id}/transaction-manual-review`,
        payload: [payload],
      });

      closeModal();
      await loadExecution({ executionId: data?._id, clearData: true });
    },
    [
      loadExecution,
      closeModal,
      resultsApproved,
      resultsReproved,
      patch,
      statusSelected,
      data._id,
      selectedReasons,
      textAreaValue,
      dataSource,
    ]
  );

  const openModal = useCallback(({ isActionRequired }) => {
    setModalVisible(true);
    setIsActionRequired(isActionRequired);
  }, []);

  const validationPending = useMemo(
    () => data?.validations?.find((validation) => validation?.status === 'PENDING'),
    [data]
  );

  const ActionRequiredModal = useMemo(
    () => (
      <>
        <Title level={3} className="title-action-required">
          <img src={ActionRequired} alt="icon media mentions modal" />
          {translate(
            `${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.actionRequired`,
            'Action Required'
          )}
        </Title>
        <Row className="content-action-required">
          <Col span={24} style={{ fontSize: '16px', color: '#323232' }}>
            <span>
              {translate(
                `${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.info`,
                'You must select at least one of the results as'
              )}{' '}
              <strong style={{ color: '#39C560' }}>
                {' '}
                {translate(
                  `${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.true`,
                  'True'
                )}
              </strong>{' '}
              {translate(`${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.or`, 'or')}{' '}
              <strong style={{ color: '#E21B45 ' }}>
                {translate(
                  `${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.false`,
                  'False'
                )}
              </strong>
              .
            </span>
          </Col>
          <Col span={24} className="mrg-top-20">
            <Button type="primary" onClick={closeModal}>
              {translate(
                `${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.tryAgain`,
                'Try Again'
              )}
            </Button>
          </Col>
        </Row>
      </>
    ),
    [closeModal, translate]
  );

  const PendingAnalysisModal = useMemo(
    () => (
      <>
        <Title level={3}>
          {translate(
            `${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.pendingAnalysis`,
            'Pending Analysis'
          )}
        </Title>
        {loadingExecution ? (
          <Spin className="flex center gx-w-100 mrg-top-30 mrg-btm-40" />
        ) : (
          <>
            {validationPending ? (
              <>
                <h3>
                  {translate(
                    `${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.ruleTitle`,
                    'Select the status of this rule:'
                  )}
                </h3>
                <div className="wrapper-rules-failed">
                  <span>
                    {translate(
                      `validations.${
                        validationPending.ruleId || validationPending.code
                      }.title`,
                      validationPending?.ruleName ||
                        validationPending?.title ||
                        validationPending?.description
                    )}
                  </span>
                  <strong>
                    <i className="caf-ic_circle_hold general-status-icon" />
                    {translate(
                      `${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.pending`,
                      'Pending'
                    )}
                  </strong>
                </div>
              </>
            ) : (
              <></>
            )}

            <Divider style={{ marginTop: '15px', marginBottom: '15px' }} />
            <>
              <main className="form-reasons">
                <div className="wrapper-status-button">
                  <button
                    type="button"
                    onClick={() => setStatusSelected('APPROVED')}
                    className={classNames('button-status button-status-approve', {
                      'button-status-selected': statusSelected === 'APPROVED',
                    })}
                  >
                    <img
                      src={ApproveStatusIconButton}
                      alt="approve icon"
                      width={15}
                      height={12}
                    />
                    {translate(
                      `${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.approve`,
                      'Approve'
                    )}
                  </button>
                  <button
                    type="button"
                    onClick={() => setStatusSelected('REPROVED')}
                    className={classNames('button-status button-status-reprove ', {
                      'button-status-selected': statusSelected === 'REPROVED',
                    })}
                  >
                    <img
                      src={ReproveStatusIconButton}
                      alt="approve icon"
                      width={15}
                      height={12}
                    />
                    {translate(
                      `${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.reprove`,
                      'Reject'
                    )}
                  </button>
                </div>
              </main>
              <CustomStatusReasons
                actionType={statusSelected}
                selectedReasons={selectedReasons}
                setSelectedReasons={setSelectedReasons}
                customStatusReasons={customStatusReasons}
                setCustomStatusReasons={setCustomStatusReasons}
                isDisable={!statusSelected}
              />
              <TextArea
                className="text-area-input"
                rows={4}
                value={textAreaValue}
                onChange={(e) => setTextAreaValue(e.target.value)}
                placeholder={translate(
                  `${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.additionalJustification`,
                  'Additional justification'
                )}
                disabled={!statusSelected}
              />
              <div className="gx-flex-row gx-justify-content-end mrg-top-40">
                <Button
                  type="default"
                  className="btn-cancel"
                  disabled={loadingExecution}
                  onClick={closeModal}
                >
                  {translate(
                    `${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.form.button.cancel`
                  )}
                </Button>
                <Button
                  type="primary"
                  htmlType="button"
                  className="apply-changes-button"
                  loading={loading || loadingExecution}
                  disabled={loadingExecution || loading || !statusSelected}
                  onClick={() =>
                    onSaveRuleUpdated({
                      ruleId: validationPending?.ruleId,
                      oldStatus: validationPending?.status,
                    })
                  }
                >
                  {translate(
                    `${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.form.button.submit`
                  )}
                </Button>
              </div>
            </>
          </>
        )}
      </>
    ),
    [
      closeModal,
      loading,
      loadingExecution,
      statusSelected,
      translate,
      customStatusReasons,
      selectedReasons,
      textAreaValue,
      validationPending,
      onSaveRuleUpdated,
    ]
  );

  const ManualReviewModal = useMemo(
    () => (
      <Modal
        width={700}
        footer={null}
        style={{ top: 40 }}
        onCancel={closeModal}
        visible={modalVisible}
        wrapClassName="caf-modal"
      >
        {isActionRequired ? ActionRequiredModal : PendingAnalysisModal}
      </Modal>
    ),
    [
      ActionRequiredModal,
      PendingAnalysisModal,
      closeModal,
      isActionRequired,
      modalVisible,
    ]
  );

  return { openModal, closeModal, ManualReviewModal };
};

export default ModalManualReview;
