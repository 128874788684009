/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import { stateCriminalBackgroundTranslation } from 'utils/personalTranslations';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';
import EmptyExecutionState from 'components/EmptyExecutionState';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH = 'components.dataSourceCards.criminalInformation.index';

const PfAntecedentes = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const getAntecedentesDescriptionPt1 = useCallback(
    (sectionData = {}) => {
      const data = [];

      if (sectionData.certificateName) data.push(sectionData.certificateName);
      if (sectionData.certificateRg)
        data.push(
          `${translate(
            `${I18N_BASE_PATH}.getAntecedentesDescriptionPt1.certificateRg`
          )} ${sectionData.certificateRg}`
        );
      if (sectionData.certificateBirthDate)
        data.push(
          `${translate(
            `${I18N_BASE_PATH}.getAntecedentesDescriptionPt1.certificateBirthDate`
          )} ${sectionData.certificateBirthDate}`
        );

      return data.length ? data.join(', ') : null;
    },
    [translate]
  );

  const getAntecedentesDescriptionPt2 = useCallback(
    (sectionData = {}) => {
      const data = [];

      if (sectionData.certificateNumber)
        data.push(
          `${translate(
            `${I18N_BASE_PATH}.getAntecedentesDescriptionPt2.certificateNumber`
          )} ${sectionData.certificateNumber}`
        );
      if (sectionData.certificateCode)
        data.push(
          `${translate(
            `${I18N_BASE_PATH}.getAntecedentesDescriptionPt2.certificateCode`
          )} ${sectionData.certificateCode}`
        );
      if (sectionData.certificateIssueDate)
        data.push(
          `${translate(
            `${I18N_BASE_PATH}.getAntecedentesDescriptionPt2.certificateIssueDate`
          )} ${sectionData.certificateIssueDate}`
        );
      if (sectionData.certificateValidity)
        data.push(
          `${translate(
            `${I18N_BASE_PATH}.getAntecedentesDescriptionPt2.certificateValidity`
          )} ${sectionData.certificateValidity}`
        );

      return data.length ? data.join(' - ') : null;
    },
    [translate]
  );

  const stateCrimes = useMemo(() => {
    const stateCriminalData = parsedData;

    const translatedStatus = stateCriminalData?.status
      ? stateCriminalBackgroundTranslation[stateCriminalData?.status] ||
        stateCriminalBackgroundTranslation.NULO
      : stateCriminalBackgroundTranslation.NULO;

    return {
      translatedStatus,
    };
  }, [parsedData]);

  return (
    <DataSourceCardCollapsePanel
      customId="criminal-background-component"
      title={translate(`${I18N_BASE_PATH}.criminalInformation.dossierCard.title`)}
      icon="caf-ic_criminal"
      toPrint={toPrint}
    >
      <Row>
        {parsedData && (
          <ColumnLabel
            title={translate(
              `${I18N_BASE_PATH}.criminalInformation.dossierCard.showStateCriminal.columnLabel.title`
            )}
            description={translate(stateCrimes?.translatedStatus?.description)}
            type={stateCrimes?.translatedStatus?.type}
            span={12}
          >
            {queryWorked ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {parsedData?.message &&
                  parsedData?.message !== 'Consulta realizada com sucesso.' && (
                    <span>{parsedData?.message}</span>
                  )}
                {getAntecedentesDescriptionPt1(parsedData) && (
                  <span>{getAntecedentesDescriptionPt1(parsedData)}</span>
                )}

                {getAntecedentesDescriptionPt2(parsedData) && (
                  <span>{getAntecedentesDescriptionPt2(parsedData)}</span>
                )}

                {parsedData?.certificateText && (
                  <p style={{ marginBottom: '10px', marginTop: '10px', lineHeight: 1.5 }}>
                    {parsedData?.certificateText}
                  </p>
                )}

                {parsedData?.file && (
                  <div
                    style={{ marginBottom: '10px', marginTop: '10px', lineHeight: 1.5 }}
                  >
                    <a href={parsedData?.file || ''} target="_blank" rel="noreferrer">
                      {translate(
                        `${I18N_BASE_PATH}.criminalInformation.dossierCard.showStateCriminal.columnLabel.button`
                      )}
                    </a>
                  </div>
                )}
              </div>
            ) : (
              <EmptyExecutionState
                className="mrg-top-10"
                statusCode={statusCode}
                message={source?.message || parsedData?.message}
              />
            )}
          </ColumnLabel>
        )}
      </Row>
    </DataSourceCardCollapsePanel>
  );
};

export default PfAntecedentes;
