/* eslint-disable react/prop-types */
import React, { useState, useCallback, useMemo } from 'react';
import { Modal } from 'antd';

import CadinSPItems from '../CadinSPItems';

const CadinSPPendencies = ({ data }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const CadinSPPendenciesModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeModal}
        footer={null}
        style={{ top: 40 }}
        width={675}
      >
        <div id="cadin-sp-pendencies-modal" className="no-mrg no-pdd">
          <CadinSPItems data={data} />
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, data]
  );

  return { openModal, closeModal, CadinSPPendenciesModal };
};

export default CadinSPPendencies;
