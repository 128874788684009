import { Col, Collapse, Row, Divider } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DottedLabel from 'components/DottedLabel';

import { useI18nFormatters } from 'hooks/i18nFormatters';

// import { Container } from './styles';

const { Panel } = Collapse;

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.companies.components.spcReportParticipationScore.otherInfos';

function JudicialProtest({ source = {}, toPrint = false }) {
  const { t: translate } = useTranslation();
  const { i18nFormatDate, i18nFormatCurrency } = useI18nFormatters();

  const parsedData = useMemo(() => source?.data || source, [source]);

  const judicialProtest = useMemo(() => parsedData?.judicialProtest || {}, [parsedData]);

  return (
    <Collapse className="mrg-top-10" defaultActiveKey={toPrint ? ['1'] : null}>
      <Panel
        key="1"
        disabled={!judicialProtest?.details?.length}
        header={
          <Col span={24}>
            <Row align="middle" justify="space-between">
              <Col>
                <span className="text-dark text-bold">
                  {translate(`${I18N_BASE_PATH}.index.judicialProtests.title`)}
                </span>
              </Col>
              <Col>
                <DottedLabel
                  description={`(${judicialProtest?.total || 0}) ${translate(
                    `${I18N_BASE_PATH}.index.restrictions`
                  )}`}
                  type={+judicialProtest?.total > 0 ? 'danger' : 'primary'}
                />
              </Col>
            </Row>
            <Row align="middle" className="mrg-top-10">
              <Col span={10}>
                <Row>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.judicialProtests.columns.lastOccurenceDate`
                    )}
                    :
                  </span>
                </Row>
                <Row>
                  <span>
                    {i18nFormatDate(judicialProtest?.lastOccurenceDate, 1) || '-'}
                  </span>
                </Row>
              </Col>
              <Col span={10}>
                <Row>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.judicialProtests.columns.firstOccurrenceDate`
                    )}
                    :
                  </span>
                </Row>
                <Row>
                  <span>
                    {i18nFormatDate(judicialProtest?.monthAndYearFirstOccurence, 1) ||
                      judicialProtest?.monthAndYearFirstOccurence ||
                      '-'}
                  </span>
                </Row>
              </Col>

              <Col span={4}>
                <Row>
                  <span className="collapse-label">
                    {translate(`${I18N_BASE_PATH}.index.judicialProtests.columns.value`)}:
                  </span>
                </Row>
                <Row>
                  <span>
                    {i18nFormatCurrency(judicialProtest?.totalValue, 'pt-BR') || '-'}
                  </span>
                </Row>
              </Col>
            </Row>
          </Col>
        }
      >
        {judicialProtest?.details?.map((register) => (
          <>
            <Col span={24}>
              <Row align="middle">
                <span className="text-dark text-bold">
                  {i18nFormatDate(register?.date, 1)} -{' '}
                  {i18nFormatCurrency(register?.value, 'pt-BR')}
                </span>
              </Row>
              <Row align="middle" className="mrg-top-8" justify="space-between">
                <Col>
                  <span className="collapse-label">
                    {translate(`${I18N_BASE_PATH}.index.judicialProtests.columns.notary`)}
                    :
                  </span>{' '}
                  <span>
                    {`${register?.notarysOffice?.name} - ${register?.notarysOffice?.city} / ${register?.notarysOffice?.state}` ||
                      '-'}
                  </span>
                </Col>
              </Row>
              <Row align="middle" className="mrg-top-8" justify="space-between">
                <Col>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.judicialProtests.columns.description`
                    )}
                    :
                  </span>{' '}
                  <span>{register?.annotationMotive || '-'}</span>
                </Col>
              </Row>
            </Col>
            <Divider />
          </>
        ))}
      </Panel>
    </Collapse>
  );
}

export default JudicialProtest;
