import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { executionStatus, checkFraudRisk } from 'utils/handlerStatus';
import { useExecution } from 'hooks/execution';
import PermissionWrapper from 'routes/PermissionWrapper';

import Countdown from 'components/Countdown';

import useManualReviewModal from './ModalManualReview/index';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.executions.components.generalStatus.index';

const GeneralStatus = () => {
  const { t: translate } = useTranslation();
  const { execution, refreshExecution } = useExecution();

  const PENDING_STATUS = useMemo(() => ['PENDENTE OCR', 'PENDENTE'], []);

  const { ManualReviewModal, openModal } = useManualReviewModal({
    data: execution,
  });

  const showPendingReviewButton = useMemo(
    () => PENDING_STATUS?.includes(execution?.status?.toUpperCase()),
    [PENDING_STATUS, execution.status]
  );

  const statusDescription = useMemo(() => {
    if (!execution) return [];

    if (execution?.fraud) {
      if (checkFraudRisk(execution)) {
        return translate(
          'utils.handlerStatus.executionStatus.status.REPROVADO.fraudRisk.description'
        );
      }

      return translate(
        'utils.handlerStatus.executionStatus.status.REPROVADO.fraud.description'
      );
    }

    if (execution?.status === 'PROCESSANDO') {
      const pendingDocumentscopyValidation = execution?.validations?.find(
        (v) => v.rule === 'documentscopy_approved' && v.status === 'PENDING'
      );

      if (pendingDocumentscopyValidation) {
        return pendingDocumentscopyValidation?.description;
      }

      return translate(executionStatus(execution?.status)?.description);
    }

    if (execution?.status === 'PENDENTE' || execution?.status === 'REPROVADO') {
      const invalidChecks =
        execution?.validations?.filter((v) => v.status === 'INVALID') || [];

      if (!execution?.fraud && !execution?.sections?.documentscopy?.fraud) {
        invalidChecks.forEach((element) => {
          if (element?.description === 'O documento enviado não é autêntico.') {
            element.description = 'Consulta reprovada na documentoscopia.';
          }
        });
      }

      const reprovalReasons =
        execution?.reprovalReasons?.map((r) => {
          return { description: r };
        }) || [];

      const statusList = [...reprovalReasons, ...invalidChecks];

      if (statusList?.length > 1) {
        return (
          <ol>
            {statusList?.map((validation) => (
              <li style={{ marginTop: 4 }} key={validation.description}>
                {validation?.code
                  ? translate(`validations.${validation?.code}.status.INVALID`)
                  : validation?.description}
              </li>
            ))}
          </ol>
        );
      }

      if (statusList[0]?.description.length > 0) {
        return (
          <span>
            {statusList[0]?.code
              ? translate(`validations.${statusList[0]?.code}.status.INVALID`)
              : statusList[0]?.description || ''}
          </span>
        );
      }
    }

    if (
      execution?.status === 'APROVADO' &&
      execution?.approvalReasons &&
      execution?.approvalReasons?.length > 0
    ) {
      if (execution?.approvalReasons?.length > 1) {
        return (
          <ol>
            {execution?.approvalReasons?.map((reason) => (
              <li key={reason}>{reason}</li>
            ))}
          </ol>
        );
      }

      return <span>{execution?.approvalReasons[0] || ''}</span>;
    }

    if (execution?.statusReasons && execution?.statusReasons?.length > 0) {
      if (execution?.statusReasons?.length > 1) {
        return (
          <ol>
            {execution?.statusReasons?.map((reason) => (
              <li key={reason}>{reason}</li>
            ))}
          </ol>
        );
      }

      return <span>{translate(execution?.statusReasons?.[0]) || ''}</span>;
    }

    return <span>{execution?.statusReasons?.[0] || ''}</span>;
  }, [execution, translate]);

  const executionStatusTitle = useMemo(() => {
    if (!execution) return '';

    if (execution?.fraud && checkFraudRisk(execution)) {
      return translate(
        `utils.handlerStatus.svgValidationStatus.status.REPROVADO.title.isFraudRisk`
      );
    }

    if (execution?.fraud) {
      if (checkFraudRisk(execution))
        return translate(
          `utils.handlerStatus.svgValidationStatus.status.REPROVADO.title.isFraudRisk`
        );

      return translate(
        `utils.handlerStatus.svgValidationStatus.status.REPROVADO.title.isFraud`
      );
    }

    return translate(executionStatus(execution?.status)?.display);
  }, [execution, translate]);

  return (
    <>
      {ManualReviewModal}
      {translate(executionStatus(execution?.status)?.value) && (
        <div
          id="general-status-card-executions"
          style={{
            backgroundColor: executionStatus(execution?.status)?.color,
          }}
        >
          <div className="general-status-info">
            <i
              className={`${
                executionStatus(execution?.status)?.icon
              } general-status-icon`}
            />
            <div className="general-status-description">
              <strong>{executionStatusTitle}</strong>
              {statusDescription}
              <br />
              {execution?.status === 'PROCESSANDO' && (
                <Countdown
                  prefix={`${I18N_BASE_PATH}.processingCountdown.prefix`}
                  suffix={`${I18N_BASE_PATH}.processingCountdown.suffix`}
                  onFinish={() => refreshExecution()}
                />
              )}
            </div>
          </div>
          <PermissionWrapper requiredPermissions={[`executions:update`]}>
            {showPendingReviewButton && (
              <div className="general-status-actions">
                <button
                  type="button"
                  className="ant-btn-text"
                  onClick={() => openModal({ allowReprocess: false })}
                >
                  {translate(
                    `${I18N_BASE_PATH}.missingInfos.pendingManualReview.reviewButtonText`
                  )}
                </button>
              </div>
            )}
          </PermissionWrapper>
        </div>
      )}
    </>
  );
};

export default GeneralStatus;
