import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Tag, Tooltip } from 'antd';

import SpecialTag from 'components/Tag/SpecialTag';

import './styles.less';

const CardExecutionTag = ({ name, color, special, actionDescription, background }) => {
  const tooltipTagTitle = useMemo(() => {
    return special ? `${name.toUpperCase()} - ${actionDescription}` : name.toUpperCase();
  }, [name, special, actionDescription]);

  return (
    <Tooltip placement="top" trigger="hover" title={tooltipTagTitle}>
      <Tag
        className="card-execution-tag"
        color={`#${color}`}
        style={{ background: `#${background}` }}
      >
        <span>{name}</span>

        {special && <SpecialTag />}
      </Tag>
    </Tooltip>
  );
};

CardExecutionTag.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  special: PropTypes.bool,
  actionDescription: PropTypes.string,
  background: PropTypes.string,
};

CardExecutionTag.defaultProps = {
  name: '-',
  color: '#828282',
  special: false,
  actionDescription: '-',
  background: '#efefef',
};

export default CardExecutionTag;
