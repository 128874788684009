import React from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import './styles.less';
import PageTitle from 'components/PageTitle';

const UsersInviteStep = ({ setUsers }) => {
  const I18N = `pages.private.userManagement.usersCreate.components.userInviteStep.index`;
  const { t: translate } = useTranslation();
  const emailsToLowerCase = (emails) => {
    const emailsLower = emails.map((email) => email.toLowerCase());
    setUsers(emailsLower);
  };
  return (
    <div className="flex-column center pdd-vertical-80">
      <PageTitle title={translate(`${I18N}.pageTitle`)} />
      <span>{translate(`${I18N}.title`)}</span>
      <span>{translate(`${I18N}.subtitle`)}</span>
      <Form.Item label={translate(`${I18N}.label`)} className="mrg-top-20" name="users">
        <Select
          className="email-input"
          mode="tags"
          onChange={(value) => emailsToLowerCase(value)}
          style={{ width: '400px' }}
          placeholder={translate(`${I18N}.placeholder`)}
        />
      </Form.Item>
    </div>
  );
};

export default UsersInviteStep;
