/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RecordsList from './components/RecordsList';
import RecordDetails from './components/RecordDetails';
import useSavedRecords from './components/RecordsList/hooks/useSavedRecords';

const Records = ({ transactionId, data }) => {
  const { loadSavedRecords, loadingSavedRecords, savedRecords } = useSavedRecords({
    transactionId,
  });

  const [page, setPage] = useState('list');
  const [recordDetails, setRecordDetails] = useState();

  const goToDetails = async (record) => {
    setRecordDetails(record);
    setPage('details');
  };

  const goToList = () => {
    setPage('list');
    setRecordDetails(undefined);
  };

  useEffect(() => {
    loadSavedRecords();
  }, []);

  return (
    <section className="KYB-records">
      {page === 'list' && (
        <RecordsList
          className="kyb-company-records"
          transactionId={transactionId}
          data={data}
          goToDetails={goToDetails}
          savedRecords={savedRecords}
          loadSavedRecords={loadSavedRecords}
          loadingSavedRecords={loadingSavedRecords}
        />
      )}
      {page === 'details' && (
        <RecordDetails
          transactionId={transactionId}
          goToList={goToList}
          record={recordDetails}
          companyName={data.companyName}
        />
      )}
    </section>
  );
};

export default Records;

Records.propTypes = {
  data: PropTypes.any.isRequired,
  transactionId: PropTypes.string.isRequired,
};
