/* eslint-disable */
const capitalizeFirstLetter = (text) => {
  if (!text) return null;

  text = text
    .toLowerCase()
    .replace(/(?:^|\s)\S/g, (capitalize) => capitalize.toUpperCase());
  const PreposM = ['Da', 'Do', 'Das', 'Dos', 'A', 'E', 'De', 'DE', 'Rs', 'Em'];
  const prepos = ['da', 'do', 'das', 'dos', 'a', 'e', 'de', 'de', 'RS', 'em'];

  for (let i = PreposM.length - 1; i >= 0; i--) {
    text = text.replace(
      RegExp(`\\b${PreposM[i].replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')}\\b`, 'g'),
      prepos[i]
    );
  }

  return text;
};

export { capitalizeFirstLetter };
