import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';
import CountryFlag from 'components/CountryFlag';
import { Calendar, Edit } from '@combateafraude/icons/general';

import { useI18nFormatters } from 'hooks/i18nFormatters';
import { useAuth } from 'hooks/auth';
import { useCompany } from 'hooks/company';
import { useFetch } from 'services/hooks';
import { usePerson } from 'hooks/person';
import { useScroll } from 'hooks/scroll';
import { useTags } from 'hooks/tags';
import { useValidations } from 'hooks/validations';

import profileDefault from 'assets/images/profile-default.png';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.profiles.components.profileSubheader.index';

const ProfileSubheader = ({ profile }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const { company, loadingCompany } = useCompany();
  const { person, loadingPerson } = usePerson();
  const { isAtTop } = useScroll();
  const { loadingValidations } = useValidations();
  const { loadingCustomTags } = useTags();
  const { companyId, personId } = useParams();

  const matchPeople = useRouteMatch('/profile/people');

  const profileData = useMemo(() => {
    return companyId ? company : person;
  }, [companyId, company, person]);

  const isAtTopClass = useMemo(() => {
    return isAtTop ? 'expand' : 'expand';
  }, [isAtTop]);

  const isLoading = useMemo(() => {
    const loadingProfile = personId ? loadingPerson : loadingCompany;

    return loadingProfile || loadingValidations || loadingCustomTags;
  }, [personId, loadingPerson, loadingCompany, loadingValidations, loadingCustomTags]);

  const standardBreadcrumbs = useMemo(
    () => (
      <Breadcrumb separator="/">
        <Breadcrumb.Item className="breadcrumbs-color">
          <Link
            className="gx-link"
            to={`/profile/${
              personId
                ? `people?_type=${translate(`${I18N_BASE_PATH}.type.person`)}`
                : `companies?_type${translate(`${I18N_BASE_PATH}.type.company`)}`
            }`}
          >
            {translate(
              `${I18N_BASE_PATH}.standardBreadcrumbs.${personId ? 'people' : 'companies'}`
            )}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="breadcrumbs-color">
          {translate(`${I18N_BASE_PATH}.standardBreadcrumbs.footer`)}
        </Breadcrumb.Item>
      </Breadcrumb>
    ),
    [personId, translate]
  );

  return (
    <section id="profile-global-subheader">
      <div
        className={`gx-header-horizontal-top ${personId && 'custom-horizontal-pdd'}
        ${isAtTopClass}`}
      >
        <div>
          <div className="profile-subheader-content">
            {personId && (
              <img
                className="profile-img"
                src={profileData?.image || profileDefault}
                alt="no file"
              />
            )}
            <div className={`profile-info ${isAtTopClass} ant-breadcrumb-separator`}>
              {standardBreadcrumbs}
              <h5 className="profile-name max-lines-2">
                {person?.profile?.summary?.name}
              </h5>

              <div className="profile-more-infos font-size-12">
                <CountryFlag
                  country={
                    person?.profile?.summary?.country ||
                    person?.profile?.summary?.address?.countryCode
                  }
                  className="font-size-14"
                />
                <Calendar className="mrg-left-10 mrg-right-5 font-size-16" />
                {translate(
                  'pages.private.globalProfiles.components.profileSubheader.index.dateCreated',
                  'Date created:'
                )}{' '}
                {i18nFormatDate(person?.profile?.createdAt, 'default')}
                <Edit className="mrg-left-10 mrg-right-5 font-size-16" />
                {translate(
                  'pages.private.globalProfiles.components.profileSubheader.index.dateUpdated',
                  'Date updated:'
                )}{' '}
                {person?.profile?.updatedAt
                  ? i18nFormatDate(person?.profile?.updatedAt, 'default') || '-'
                  : i18nFormatDate(person?.profile?.createdAt, 'default')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ProfileSubheader.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ProfileSubheader;
