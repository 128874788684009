/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyExecutionState from 'components/EmptyExecutionState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.laborInformation.people.components.rais.index';

const RAIS = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );
  const handlerQuantity = useMemo(() => {
    if (!parsedData || !queryWorked) return null;

    if (!parsedData || !parsedData?.length > 0) {
      return {
        description: translate(`${I18N_BASE_PATH}.handlerQuantity.isEmpty`),
        type: 'default',
      };
    }
    if (parsedData?.length === 1) {
      return {
        description: translate(`${I18N_BASE_PATH}.handlerQuantity.oneStatement`),
        type: 'primary',
      };
    }

    if (parsedData?.length > 1) {
      return {
        description: translate(`${I18N_BASE_PATH}.handlerQuantity.moreThanOneStatement`, {
          value: parsedData?.length,
        }),
        type: 'primary',
      };
    }

    return null;
  }, [parsedData, queryWorked, translate]);

  return (
    <DataSourceCardCollapsePanel
      title={translate(`${I18N_BASE_PATH}.rais.columnLabel.title`)}
      customId="labor-infos-component"
      icon="caf-ic_awards"
      toPrint={toPrint}
    >
      <ColumnLabel
        title={translate(`${I18N_BASE_PATH}.rais.columnLabel.title`)}
        description={handlerQuantity?.description}
        type={handlerQuantity?.type || 'default'}
        info={!toPrint && translate(`${I18N_BASE_PATH}.rais.columnLabel.info`)}
      >
        {(!queryWorked || !handlerQuantity) && (
          <EmptyExecutionState
            statusCode={statusCode}
            message={source?.message || parsedData?.message}
          />
        )}
      </ColumnLabel>
    </DataSourceCardCollapsePanel>
  );
};

export default RAIS;
