/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

import defaultImage from 'assets/images/profile-default.png';

import {
  eyeAndHairTranslator,
  sanctionType,
  pepLevel,
} from '../../../utils/handlerSanctions';

import './styles.less';

const SourceInfosToPrint = ({ data, pep, others }) => {
  const { t: translate } = useTranslation();

  return (
    <div id="source-infos-to-print">
      {data?.length > 0 && (
        <Row className="mrg-top-10 reset-mrg">
          {data?.map((sourceDetailItem) => (
            <Col span={24} className="information-source-data-details">
              {pep ? (
                <>
                  {others && (
                    <span>
                      <strong>
                        {translate(
                          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.source'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.source ||
                        translate(
                          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.hasNotSource'
                        )}
                    </span>
                  )}
                  {sourceDetailItem?.level && (
                    <span>
                      <strong>
                        {translate(
                          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.level'
                        )}{' '}
                      </strong>
                      {pepLevel(sourceDetailItem?.level, translate)?.description}
                    </span>
                  )}
                  {sourceDetailItem?.jobTitle &&
                    sourceDetailItem?.jobTitle !== 'FAMILIAR' && (
                      <span>
                        <strong>
                          {translate(
                            'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.jobTitle'
                          )}{' '}
                        </strong>
                        {capitalizeFirstLetter(sourceDetailItem?.jobTitle)}
                      </span>
                    )}
                  {sourceDetailItem?.department && (
                    <span>
                      <strong>
                        {translate(
                          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.department'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.department}
                    </span>
                  )}
                </>
              ) : (
                <>
                  {others && (
                    <span>
                      <strong>
                        {translate(
                          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.source'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.source ||
                        translate(
                          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.hasNotSource'
                        )}
                    </span>
                  )}
                  {sourceDetailItem?.matchRate && (
                    <span>
                      <strong>
                        {translate(
                          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.matchRate'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.matchRate || '100'} %
                    </span>
                  )}
                  {sourceDetailItem?.type && (
                    <span>
                      <strong>
                        {translate(
                          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.type'
                        )}{' '}
                      </strong>
                      {translate(sanctionType(sourceDetailItem?.type))}
                    </span>
                  )}
                  {sourceDetailItem?.sanctionName && (
                    <span>
                      <strong>
                        {translate(
                          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sanctionName'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.sanctionName?.toUpperCase() || '-'}
                    </span>
                  )}
                  {sourceDetailItem?.description && (
                    <span>
                      <strong>
                        {translate(
                          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.description'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.description}
                    </span>
                  )}
                  {sourceDetailItem?.nationalities && (
                    <span>
                      <strong>
                        {translate(
                          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.nationalities'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.nationalities}
                    </span>
                  )}
                  {sourceDetailItem?.eyes && (
                    <span>
                      <strong>
                        {translate(
                          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.eyes'
                        )}{' '}
                      </strong>
                      {translate(eyeAndHairTranslator(sourceDetailItem?.eyes))}
                    </span>
                  )}
                  {sourceDetailItem?.hair && (
                    <span>
                      <strong>
                        {translate(
                          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.hair'
                        )}{' '}
                      </strong>
                      {translate(eyeAndHairTranslator(sourceDetailItem?.hair))}
                    </span>
                  )}
                  {sourceDetailItem?.considerations && (
                    <span>
                      <strong>
                        {translate(
                          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.considerations'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.considerations}
                    </span>
                  )}
                  {sourceDetailItem?.distinguishingMarks && (
                    <span>
                      <strong>
                        {translate(
                          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.distinguishingMarks'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.distinguishingMarks}
                    </span>
                  )}
                  {sourceDetailItem?.department && (
                    <span>
                      <strong>
                        {translate(
                          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.department'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.department}
                    </span>
                  )}
                  {sourceDetailItem?.image && (
                    <div className="information-source-data-image">
                      <img
                        className={!sourceDetailItem?.image ? 'grayscale-95' : ''}
                        src={sourceDetailItem?.image || defaultImage}
                        alt={translate(
                          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.image.alt'
                        )}
                      />
                    </div>
                  )}
                </>
              )}
            </Col>
          ))}
        </Row>
      )}

      {!data?.length > 0 && <></>}
    </div>
  );
};

export default SourceInfosToPrint;
