import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Collapse } from 'antd';

import { useAuth } from 'hooks/auth';

import PermissionWrapper from 'routes/PermissionWrapper';
import { hasRequiredPermissions } from 'routes/permissionFunctions';

import ProfileWidgetFooter from './ProfileWidgetFooter';

import './styles.less';

const { Panel } = Collapse;

const ProfileWidget = ({
  title,
  subtitle,
  icon,
  children,
  footer,
  customClassName,
  footerPermissions,
  customFooterClick,
}) => {
  const { user } = useAuth();

  const Title = useMemo(
    () => (
      <>
        <span id="profile-widget-title">
          <i className={icon} />
          {title}
        </span>
        {subtitle && <p id="profile-widget-subtitle">{subtitle}</p>}
      </>
    ),
    [title, subtitle, icon]
  );

  return (
    <Row className="mrg-btm-20">
      <Col span={24} className="profile-widget-container">
        <Collapse defaultActiveKey={['data']}>
          <Panel
            header={Title}
            key="data"
            id="profile-widget-collapse"
            className={`${customClassName} ${
              footer &&
              hasRequiredPermissions(user, footerPermissions, []) &&
              'custom-footer'
            }`}
          >
            {children}

            <PermissionWrapper requiredPermissions={footerPermissions}>
              {footer && (
                <ProfileWidgetFooter title={footer} onClick={customFooterClick} />
              )}
            </PermissionWrapper>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

ProfileWidget.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.string.isRequired,
  footerPermissions: PropTypes.arrayOf(PropTypes.string),
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  customClassName: PropTypes.string,
  customFooterClick: PropTypes.func,
};

ProfileWidget.defaultProps = {
  subtitle: '',
  footer: '',
  customClassName: '',
  customFooterClick: undefined,
  footerPermissions: [],
};

export default ProfileWidget;
