import React, { useMemo } from 'react';
import EmptyExecutionState from 'components/EmptyExecutionState';
import { useTranslation } from 'react-i18next';
import { Col, Row, Typography } from 'antd';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import ViolationsCollapsePanel from '../ViolationsCollapsePanel';
import VehicleInfosCollapsePanel from '../VehicleInfosCollapsePanel';

const { Title } = Typography;

export const DenatranVehicleViolations = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const data = useMemo(() => source?.data || {}, [source]);
  const vehicle = useMemo(() => data?.vehicle, [data]);
  const violations = useMemo(() => data?.violations, [data]);

  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_driver"
      title={translate(
        'pages.private.executions.components.dataSourceCards.basicInfos.components.basicInfosCard.sources.denatranVehicleViolations',
        'Vehicle query on Denatran'
      )}
      toPrint={toPrint}
    >
      {statusCode !== '01' ? (
        <EmptyExecutionState
          statusCode={statusCode}
          message={source?.message || source?.data?.message}
        />
      ) : (
        <>
          <Row>
            <Col span={24}>
              <Title level={4}>{vehicle?.modelBrandDescription}</Title>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <VehicleInfosCollapsePanel vehicle={vehicle} toPrint={toPrint} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ViolationsCollapsePanel violations={violations} toPrint={toPrint} />
            </Col>
          </Row>
        </>
      )}
    </DataSourceCardCollapsePanel>
  );
};
