import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useFileViewer from 'components/Files/FileViewer';

import './styles.less';

const Files = () => {
  const { fileId } = useParams();
  const { openFileViewer, fileViewerComponent } = useFileViewer({ closable: false });

  useEffect(() => {
    if (!fileId) return;
    openFileViewer({ fileId });
  }, [fileId]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div className="flex center">{fileViewerComponent}</div>;
};

export default Files;
