import { Col, Row, Divider, Modal, Typography } from 'antd';
import React, { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { sentimentLabelMappings } from '../../utils/mappings';

const { Title } = Typography;

const I18N_BASE_PATH =
  'components.dataSourceCards.mediaExposure.companies.MediaExposureDetailsModal.index';

function MediaExposureDetailsModal() {
  const { t: translate } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [items, setItems] = useState({});

  const openModal = useCallback(
    (value) => {
      setItems(value);
      setVisible(true);
    },
    [setVisible, setItems]
  );

  const closeModal = useCallback(() => {
    setItems({});
    setVisible(false);
  }, [setVisible]);

  const parsedItems = useMemo(() => {
    if (Array.isArray(items)) return items;
    const itemsKeys = Object.keys(items);

    return itemsKeys.map((key) => {
      return {
        name: key,
        label: items[key]?.label || items[key]?.Label,
        ...(items[key] || {}),
      };
    });
  }, [items]);

  const DetailsModalContent = (
    <Modal
      visible={visible}
      closable
      closeIcon={<i className="caf-ic_close font-size-18" />}
      onCancel={closeModal}
      footer={null}
      style={{ top: 40 }}
      width={675}
    >
      <>
        <Title>{translate(`${I18N_BASE_PATH}.title`)}</Title>
        <Divider />
        {parsedItems?.map((item, index) => (
          <Col span={24}>
            <Row align="middle" justify="space-around">
              <Col style={{ flex: 1 }}>
                <span className="text-dark text-bold">
                  {translate(`${I18N_BASE_PATH}.columns.name`)}:
                </span>{' '}
                <span>{item.name}</span>{' '}
              </Col>
              <Col style={{ flex: 1 }}>
                <span className="text-dark text-bold">
                  {translate(`${I18N_BASE_PATH}.columns.sentimentLabel`)}:
                </span>{' '}
                <span>
                  {translate(
                    sentimentLabelMappings[item?.label] ||
                      item?.label ||
                      sentimentLabelMappings.UNDEFINED
                  )}
                </span>
              </Col>
              {index < parsedItems?.length - 1 && <Divider />}
            </Row>
          </Col>
        ))}
      </>
    </Modal>
  );

  return { openModal, DetailsModalContent };
}

export default MediaExposureDetailsModal;
