import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import TableInfoCard from 'pages/private/Profiles/components/TableInfoCard/Column';

const I18N_BASE_PATH =
  'pages.private.profiles.components.tabs.components.documentsValidations.components.verifiedIdentity.index.documentLiveness';

function DocumentLivenessInfos({ information }) {
  const { t: translate } = useTranslation();
  const {
    isPhotocopy,
    confidence,
    reliabilityThreshold,
    reliablePrediction,
    decision,
    indicators,
  } = information;

  const reliabilityDecimalsLength = () => {
    if (Number.isInteger(reliabilityThreshold)) {
      return 0;
    }

    return reliabilityThreshold?.toString().split('.')[1]?.length - 2;
  };

  const resultIfReliabilityHasNoDecimals =
    reliabilityDecimalsLength() <= 2 ? 2 : reliabilityDecimalsLength();

  const returnPercent = (param, decimalNumber) =>
    Number(param || 0)
      .toFixed(2)
      .toString() === '1.00'
      ? `${100}%`
      : `${Number(param * 100 || 0).toFixed(decimalNumber)}%`;

  const returnString = (confidence) => {
    switch (confidence.toUpperCase()) {
      case 'HIGH':
        return translate(`${I18N_BASE_PATH}.columns.high`, 'Alta');
      case 'MEDIUM':
        return translate(`${I18N_BASE_PATH}.columns.medium`, 'Média');
      case 'LOW':
        return translate(`${I18N_BASE_PATH}.columns.low`, 'Baixa');
      default:
        return '-';
    }
  };

  const hasDecision = useMemo(() => {
    return decision !== undefined;
  }, [decision]);

  const resultBooleanDecision = useMemo(
    () =>
      decision
        ? translate(`${I18N_BASE_PATH}.columns.no`)
        : translate(`${I18N_BASE_PATH}.columns.yes`),
    [decision, translate]
  );

  const indicatorMessage = useMemo(() => {
    const possibleIndicators = [
      'real_doc',
      'screen_photo_doc',
      'grayscale_copy_doc',
      'colored_copy_doc',
    ];
    if (indicators?.length > 0) {
      return indicators.map((indicator) => {
        const indicatorId = indicator?.id;

        const hasIndicator = possibleIndicators.includes(indicatorId);

        return translate(
          `${I18N_BASE_PATH}.columns.indicators.${hasIndicator ? indicatorId : 'unknown'}`
        );
      });
    }
  }, [indicators, translate]);

  const resultBooleanIsPhotocopy = useMemo(
    () =>
      isPhotocopy
        ? translate(`${I18N_BASE_PATH}.columns.yes`)
        : translate(`${I18N_BASE_PATH}.columns.no`),
    [isPhotocopy, translate]
  );

  return (
    <>
      {hasDecision ? (
        <Row className="custom-validations-execution">
          <TableInfoCard
            span={12}
            title={translate(
              `${I18N_BASE_PATH}.columns.decision`,
              'Fotocópia ou foto de tela'
            )}
            content={resultBooleanDecision}
            bordered={false}
          />
          <TableInfoCard
            span={12}
            title={translate(`${I18N_BASE_PATH}.columns.confidence`, 'Confiança')}
            content={returnString(confidence)}
            bordered={false}
          />
          <TableInfoCard
            span={24}
            title={translate(`${I18N_BASE_PATH}.columns.indicators`, 'Indicadores')}
            content={indicatorMessage}
            bordered={false}
          />
        </Row>
      ) : (
        <Row className="custom-validations-execution">
          <TableInfoCard
            span={8}
            title={translate(`${I18N_BASE_PATH}.columns.isPhotocopy`)}
            content={
              reliablePrediction
                ? resultBooleanIsPhotocopy
                : translate(`${I18N_BASE_PATH}.columns.unreliablePrediction`)
            }
            bordered={false}
          />
          <TableInfoCard
            span={8}
            title={translate(`${I18N_BASE_PATH}.columns.reliabilityThreshold`)}
            content={returnPercent(
              reliabilityThreshold,
              resultIfReliabilityHasNoDecimals
            )}
            bordered={false}
          />
          <TableInfoCard
            span={8}
            title={translate(`${I18N_BASE_PATH}.columns.confidence`)}
            content={returnPercent(confidence, resultIfReliabilityHasNoDecimals)}
            bordered={false}
          />
        </Row>
      )}
    </>
  );
}

const Props = {
  isPhotocopy: PropTypes.bool.isRequired,
  saturation: PropTypes.number.isRequired,
  reliabilityThreshold: PropTypes.number.isRequired,
  confidence: PropTypes.number.isRequired,
};

DocumentLivenessInfos.propTypes = {
  information: PropTypes.shape(Props).isRequired,
};

export default DocumentLivenessInfos;
