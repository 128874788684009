/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

import ViewMore from 'components/ViewMore';

import {
  courtInfos,
  statusInfos,
} from '../../components/Processes/utils/processesDetails';

import ProcessTag from '../../components/Processes/ProcessTag';
import useAllProcesses from '../../components/Processes/AllProcessesModal';
import useProcessDetails from '../../components/Processes/ProcessDetailsModal';

import './styles.less';

const I18N_BASE_PATH =
  'pages.private.profiles.components.dataSourceCards.legalInformation.lists.processesList.index.processesList';

const ProcessesList = ({ data, updateData, displayValue, toPrint }) => {
  const { t: translate } = useTranslation();

  const { i18nFormatDate, i18nRelativeUpdate } = useI18nFormatters();

  const { openModal: openAllProcessesModal, AllProcessesModal } = useAllProcesses({
    data,
  });
  const {
    openModal: openProcessesDetailsModal,
    ProcessDetailsModal,
  } = useProcessDetails();

  const processes = updateData ? data : data?.justice?.processes?.items;

  return (
    <div id="legal-information-processes-container">
      {ProcessDetailsModal}
      {AllProcessesModal}

      <Row className="legal-information-processes-row">
        {processes
          ?.slice(0, toPrint ? processes?.length : displayValue)
          .map((process) => (
            <Col span={24} className="legal-information-processes-data">
              <Button
                type="default"
                key={process.number}
                className="legal-information-processes-btn reset-btn"
                onClick={() => openProcessesDetailsModal(process)}
              >
                <Row className="processes-row-data reset-mrg">
                  <Col span={24} className="process-data-title process-col-reset">
                    <strong>
                      {toPrint &&
                        `${
                          process.number ||
                          translate(`${I18N_BASE_PATH}.processNumberUnavailable`)
                        } -`}{' '}
                      {process.mainSubject || process.subjects?.join(', ') || '-'}
                      {(process.observationStatus || process.complementaryStatus) && (
                        <span style={{ fontWeight: 400 }}>
                          {' '}
                          • {process.observationStatus || process.complementaryStatus}
                        </span>
                      )}
                    </strong>
                  </Col>

                  <Col span={24} className="process-col-reset">
                    <Row
                      className={`processes-row-details reset-mrg
                      ${
                        !process.courtType && !process.lawBranch && !process.authorType
                          ? ''
                          : 'mrg-btm-15'
                      }`}
                    >
                      <Row className="processes-row-data reset-mrg">
                        {translate(`${I18N_BASE_PATH}.lawsuitId`, {
                          lawsuitId: process.number,
                        })}
                      </Row>
                    </Row>

                    <Row className="processes-row-details reset-mrg">
                      <Col span={8} className="process-data-detail">
                        <strong>{translate(`${I18N_BASE_PATH}.courtType`)}</strong>
                        <span>
                          {translate(`${I18N_BASE_PATH}.courtType`)}{' '}
                          {translate(
                            courtInfos(process.courtType || process.lawBranch)?.name
                          )}{' '}
                          - {process.courtName || process.court}{' '}
                          {process.state && `(${process.state})`}
                        </span>
                      </Col>

                      <Col span={8} className="process-data-detail bd-left">
                        <strong>{translate(`${I18N_BASE_PATH}.type`)}</strong>
                        <span>
                          {capitalizeFirstLetter(process.type || process.lawsuitClass) ||
                            '-'}
                        </span>
                      </Col>

                      <Col span={8} className="process-data-detail bd-left">
                        <strong>{translate(`${I18N_BASE_PATH}.lastUpdate`)}</strong>

                        {!toPrint && (
                          <span>
                            {i18nRelativeUpdate(
                              process.lastUpdate || process?.moves?.[0]?.date
                            ) || translate(`${I18N_BASE_PATH}.hasNotLastUpdateDate`)}
                          </span>
                        )}

                        {toPrint && (
                          <>
                            <span>
                              {translate(`${I18N_BASE_PATH}.publicationDate`)}{' '}
                              {i18nFormatDate(process?.publicationDate, 1)}
                            </span>
                            <span>
                              {translate(`${I18N_BASE_PATH}.lastUpdateDate`)}{' '}
                              {i18nFormatDate(process?.lastUpdate, 1)}
                            </span>
                          </>
                        )}
                      </Col>
                    </Row>

                    {(process.courtType || process.authorType) && (
                      <Row className="processes-row-tags reset-mrg">
                        {(process.courtType || process.lawBranch) && (
                          <ProcessTag
                            title={translate(
                              courtInfos(process.courtType || process.lawBranch)?.name
                            )}
                            type={
                              courtInfos(process.courtType || process.lawBranch)?.type
                            }
                          />
                        )}
                        {process.authorType && (
                          <ProcessTag
                            title={translate(statusInfos(process.authorType)?.name)}
                            type={statusInfos(process.authorType)?.type}
                          />
                        )}
                      </Row>
                    )}
                  </Col>
                </Row>
              </Button>
            </Col>
          ))}
      </Row>

      {!toPrint && displayValue && processes?.length > displayValue && (
        <Row>
          <ViewMore
            type="link"
            title={translate(`${I18N_BASE_PATH}.viewMore.title`, {
              processes: processes?.length,
            })}
            onClick={() => openAllProcessesModal()}
          />
        </Row>
      )}
    </div>
  );
};

export default ProcessesList;
