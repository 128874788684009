import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { Card, Switch, Form, Select, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { uniqBy } from 'lodash';

import TitleDivider from 'components/Divider/TitleDivider';
import TooltipInfo from 'components/TooltipInfo';

import { useAuth } from 'hooks/auth';
import {
  useProfileTemplate,
  PROFILE_RULES_INITIAL_VALUE,
  QSA_RULES_INITIAL_VALUE,
} from 'hooks/profileTemplate';
import { filterRelatedRules } from 'utils/filteredReleatedRules';
import ValidationsFormSelector from './ValidationsFormSelector';

import './styles.less';

const { Option } = Select;

const I18N_BASE_PATH = 'pages.private.profileModels.components.complianceRules.index';

const DisplayQSARules = ({
  translate,
  qsaSwitchChecked,
  setQsaSwitchChecked,
  disabled,
  formRef,
}) => {
  return (
    <>
      <TitleDivider
        title={translate(`${I18N_BASE_PATH}.displayQSARules.titleDivider.title`)}
        customTop="0"
        customBottom="40px"
      />
      <Row className="no-mrg" align="middle">
        <>
          <Form.Item name="qsaSwitch" className="no-mrg">
            <Switch
              defaultChecked={qsaSwitchChecked}
              onChange={(v) => setQsaSwitchChecked(v)}
              className="switch-compliance-rules"
              disabled={disabled || !!formRef?.getFieldValue('qsaProfileTemplateId')}
            />
          </Form.Item>{' '}
          <strong className="mrg-horizon-10 text-dark">
            {translate(
              `${I18N_BASE_PATH}.displayQSARules.titleDivider.description.isNotEditing`
            )}
          </strong>
        </>
        <TooltipInfo
          color="#2f80ed"
          placement="topRight"
          title={
            <div>
              {translate(
                `${I18N_BASE_PATH}.displayQSARules.titleDivider.tooltipInfo.title`
              )}
              <br />
              <br />
              {translate(`${I18N_BASE_PATH}.displayQSARules.alert`)}
            </div>
          }
          size={12}
        />
      </Row>
    </>
  );
};

const CustomLabel = ({ translate, disabled }) => (
  <div className="custom-label-content">
    <span>{translate(`${I18N_BASE_PATH}.customLabel.text`)}</span>
    {!disabled && (
      <Link
        target="_blank"
        to={`/profile-templates/profile-models/registration?type=${translate(
          `${I18N_BASE_PATH}.type.person`
        )}`}
      >
        {translate(`${I18N_BASE_PATH}.customLabel.link`)}
      </Link>
    )}
  </div>
);

const ComplianceRules = ({
  profileType,
  disabled,
  formRef,
  onValuesChange,
  newValidations,
  profilePfTemplates,
  loadingAllProfileTemplates,
}) => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const {
    cafServicesList,
    checkedServices,
    profileTemplate,
    product,
  } = useProfileTemplate();

  const [allowedValidations, setAllowedValidations] = useState([]);
  const [profileRulesValidations, setProfileRulesValidations] = useState([]);
  const [productValidationsRules, setProductValidationsRules] = useState([]);
  const [qsaSwitchChecked, setQsaSwitchChecked] = useState(
    !!formRef?.getFieldValue('qsaProfileTemplateId')
  );

  useMemo(() => {
    const backgroundCheckingRules =
      product?.template?.backgroundChecking?.validations || [];

    const onboardingValidations = product?.template?.onboarding?.validations || [];

    const fixedRules = [...backgroundCheckingRules, ...onboardingValidations].flat();

    if (fixedRules) {
      const keys = [];
      const productRules = [];

      fixedRules.map((v) => keys.push(v?.rule || v?.code));

      const services = cafServicesList?.docs?.filter((s) => s?.productType === 'service');

      keys.forEach((key) =>
        services?.forEach((s) => {
          const rule = s?.relatedRules?.find((r) => r?.rule === key);

          if (rule) {
            productRules.push({ ...rule, marked: true });
          }
        })
      );

      setProductValidationsRules(keys);
      setProductValidationsRules(productRules);
    }
  }, [cafServicesList, product]);

  const profileRulesInitialValue = useMemo(() => {
    const data = PROFILE_RULES_INITIAL_VALUE(translate);
    return data;
  }, [translate]);

  const qsaRulesInitialValue = useMemo(() => {
    const data = QSA_RULES_INITIAL_VALUE(translate);
    return data;
  }, [translate]);

  const personProfile = useMemo(() => {
    return profileType === 'PF';
  }, [profileType]);

  const getHiddenServices = useCallback(() => {
    const hiddenServices =
      cafServicesList?.docs?.filter((service) => {
        return service.category === 'hidden_category';
      }) || [];
    return hiddenServices;
  }, [cafServicesList]);

  const changeAllowedValidations = useCallback(() => {
    const hiddenServices = getHiddenServices();
    const checkedServicesWithHidden = [...checkedServices, ...hiddenServices];
    const onbCheckedServices =
      checkedServicesWithHidden?.filter(({ category }) => category === 'onboarding') ||
      [];
    const bgcCheckedServices =
      checkedServicesWithHidden?.filter(({ category }) => category !== 'onboarding') ||
      [];

    const newAllowedOnboardingValidations = filterRelatedRules(
      onbCheckedServices,
      cafServicesList
    );
    const newAllowedDataSourceValidations = filterRelatedRules(
      [...bgcCheckedServices, ...productValidationsRules],
      cafServicesList
    );

    const mappedValidations = uniqBy(
      [
        ...productValidationsRules,
        ...newAllowedOnboardingValidations,
        ...newAllowedDataSourceValidations,
      ],
      'rule'
    ).map((validation) => {
      if (newValidations?.find((val) => val.rule === validation.rule)) {
        validation.marked = true;
      }
      return validation;
    });

    setAllowedValidations(mappedValidations);
    setProfileRulesValidations([...profileRulesInitialValue]);
  }, [
    cafServicesList,
    checkedServices,
    getHiddenServices,
    newValidations,
    productValidationsRules,
    profileRulesInitialValue,
  ]);

  useEffect(() => {
    changeAllowedValidations();
  }, [changeAllowedValidations]);

  return (
    <section id="profile-model-compliance-rules-component" className="gx-w-100">
      {/* <PageTitle
        title="Regras de compliance"
        subtitle="Crie regras automatizadas para a validação de suas consultas."
      /> */}
      <Card>
        {/* {personProfile && (
          <div className="mrg-btm-50">
            <TitleDivider
              title="Regras de identidade"
              customTop="0"
              customBottom="50px"
            />
            <ValidationsFormSelector
              formFieldName="onboardingValidations"
              validations={allowedOnboardingValidations}
              disabled={disabled}
              formRef={formRef}
            />
          </div>
        )} */}
        <div className="mrg-btm-40">
          {/* <TitleDivider
            title="Regras de background checking"
            customTop="0"
            customBottom="50px"
          /> */}
          <ValidationsFormSelector
            formFieldName="validations"
            validations={allowedValidations}
            disabled={disabled}
            formRef={formRef}
            onValuesChange={onValuesChange}
          />
        </div>
        {personProfile &&
          user?.accountData?.featureFlags?.profilesModule &&
          (!disabled || profileTemplate?.profileRules?.length > 0) && (
            <div>
              <TitleDivider
                title={translate(`${I18N_BASE_PATH}.complianceRules.titleDivider.title`)}
                customTop="0"
                customBottom="40px"
              />
              <ValidationsFormSelector
                formFieldName="profileRules"
                validations={profileRulesValidations}
                disabled={disabled}
                formRef={formRef}
                onValuesChange={onValuesChange}
              />
            </div>
          )}
        {!personProfile && user?.accountData?.featureFlags?.profilesModule && (
          <div>
            <DisplayQSARules
              translate={translate}
              qsaSwitchChecked={qsaSwitchChecked}
              setQsaSwitchChecked={setQsaSwitchChecked}
              disabled={disabled}
              formRef={formRef}
            />
            {qsaSwitchChecked && (
              <>
                <Col className="no-mrg no-pdd pdd-right-10 mrg-btm-15" span={13}>
                  <Form.Item
                    label={<CustomLabel translate={translate} disabled={disabled} />}
                    name="qsaProfileTemplateId"
                    className="mrg-top-15 mrg-btm-15"
                    rules={[
                      { required: true },
                      // BLOQUEIO DE DETERMINADOS MODELOS
                      /* {
                        validator: (_, value) => {
                          const item = profilePfTemplates?.find(
                            (profile) => profile?._id === value
                          );
                          const hasForbiddenType =
                            item?.backgroundChecking?.services?.filter((service) =>
                              ['pf_antecedentes', 'pf_nada_consta', 'pf_rais'].includes(
                                service?.source
                              )
                            )?.length > 0;

                          if (hasForbiddenType) {
                            return Promise.reject(
                              new Error(
                                translate(
                                  'pages.private.profileModels.components.complianceRules.index.complianceRules.form.messages.hasForbiddenType'
                                )
                              )
                            );
                          }

                          return Promise.resolve();
                        },
                      } */
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder={translate(
                        `${I18N_BASE_PATH}.complianceRules.form.select.placeholder`
                      )}
                      optionFilterProp="children"
                      disabled={disabled}
                    >
                      {!loadingAllProfileTemplates &&
                        profilePfTemplates?.map((item) => (
                          <Option key={item._id} value={item._id}>
                            {item.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <ValidationsFormSelector
                  formFieldName="profileRules"
                  validations={qsaRulesInitialValue}
                  disabled={disabled}
                  formRef={formRef}
                  onValuesChange={onValuesChange}
                />
              </>
            )}
          </div>
        )}
      </Card>
    </section>
  );
};

ComplianceRules.propTypes = {
  profileType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onValuesChange: PropTypes.func,
  newValidations: PropTypes.arrayOf(PropTypes.any),
  profilePfTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  loadingAllProfileTemplates: PropTypes.bool.isRequired,
};

ComplianceRules.defaultProps = {
  disabled: false,
  onValuesChange: null,
  newValidations: [],
};

export default ComplianceRules;
