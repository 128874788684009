import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CircleIcon from 'components/Icon/CircleIcon';

import './styles.less';

const AddressItem = ({ data: address }) => {
  const { t: translate } = useTranslation();

  const isString = useMemo(() => {
    return typeof address === 'string';
  }, [address]);

  const {
    number,
    complement,
    neighborhood,
    city,
    state,
    zipCode,
    type,
    street,
    country,
  } = address || {};

  const addressType = useMemo(() => {
    return translate(
      `pages.private.profiles.components.tabs.components.basicInfos.components.person.addressContent.components.addressItem.index.addressType.${
        // eslint-disable-next-line no-nested-ternary
        type === 'HOME' ? 'home' : type === 'WORK' ? 'work' : 'other'
      }`
    );
  }, [type, translate]);

  const addressIconType = useMemo(() => {
    // eslint-disable-next-line no-nested-ternary
    return type === 'HOME' ? 'home' : type === 'WORK' ? 'company' : 'map_pin';
  }, [type]);

  return (
    <div id="address-item-execution-container">
      <CircleIcon icon={`caf-ic_${addressIconType}`} />
      <section className="flex space-between address-item-execution-information">
        {isString ? (
          <span>{address}</span>
        ) : (
          <span>
            {street && `${street},`} {number && `${number},`}{' '}
            {complement && neighborhood ? `${complement} -` : `${complement || ''}`}{' '}
            {neighborhood || ''} <br />
            {city && state ? `${city} /` : `${city || ''}`} {state && `${state},`}{' '}
            {`${zipCode} - ` || ''}
            {country && `${country}`}
          </span>
        )}

        <div className="mrg-top-5 address-item-type-execution-tag">
          <small>{addressType}</small>
        </div>
      </section>
    </div>
  );
};

AddressItem.propTypes = {
  data: PropTypes.shape({
    addressMain: PropTypes.string,
    number: PropTypes.string,
    complement: PropTypes.string,
    neighborhood: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

export default AddressItem;
