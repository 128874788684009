import React, { useCallback } from 'react';
import { InputNumber, Form } from 'antd';
import PropTypes from 'prop-types';
import FormItem from 'antd/lib/form/FormItem';

import './styles.less';

const MfaInput = ({ tokenStatus, onFill, onNotFilled }) => {
  const [form] = Form.useForm();

  if (tokenStatus !== 'valid') {
    form.resetFields();
  }

  const checkFilled = useCallback(() => {
    const digits = Object.values({ ...form.getFieldsValue() }).map((e) => e);
    const token = digits.join('');

    if (token.length === 6) onFill(token);
    else onNotFilled();
  }, [onFill, onNotFilled, form]);

  const inputHandler = useCallback((event) => {
    let id = event?.target?.id;
    id = parseInt(id.charAt(4), 10);
    const newValue = parseInt(event?.nativeEvent?.data, 10);
    document.getElementById(`mfa-${id}`).value =
      newValue || newValue === 0 ? newValue : '';

    if (event?.target?.id === 'mfa-5' && event?.target?.value !== '') {
      document.getElementById('mfa-5').blur();
      return;
    }
    if (event?.target?.value !== '') {
      document.getElementById(`mfa-${id + 1}`).focus();
    }
  }, []);

  return (
    <Form form={form} className="form-align" onValuesChange={checkFilled}>
      <FormItem name="digit-0">
        <InputNumber
          className="input"
          autoFocus
          id="mfa-0"
          min={0}
          max={9}
          stringMode
          onInput={inputHandler}
        />
      </FormItem>
      <FormItem name="digit-1">
        <InputNumber
          className="input"
          id="mfa-1"
          min={0}
          max={9}
          stringMode
          onInput={inputHandler}
        />
      </FormItem>
      <FormItem name="digit-2">
        <InputNumber
          className="input"
          id="mfa-2"
          min={0}
          max={9}
          stringMode
          onInput={inputHandler}
        />
      </FormItem>
      <FormItem name="digit-3">
        <InputNumber
          className="input"
          id="mfa-3"
          min={0}
          max={9}
          stringMode
          onInput={inputHandler}
        />
      </FormItem>
      <FormItem name="digit-4">
        <InputNumber
          className="input"
          id="mfa-4"
          min={0}
          max={9}
          stringMode
          onInput={inputHandler}
        />
      </FormItem>
      <FormItem name="digit-5">
        <InputNumber
          className="input"
          id="mfa-5"
          min={0}
          max={9}
          stringMode
          onInput={inputHandler}
        />
      </FormItem>
    </Form>
  );
};

MfaInput.propTypes = {
  onFill: PropTypes.func.isRequired,
  onNotFilled: PropTypes.func.isRequired,
  tokenStatus: PropTypes.string.isRequired,
};

export default MfaInput;
