import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Row } from 'antd';
import { Info } from '@combateafraude/icons/general';

import EmptyMessage from 'components/EmptyMessage';

import {
  ExclamationStroke,
  IcPauseSmall,
  CorrectStroke,
  ErroStroke,
} from '@combateafraude/icons/others';

import { statusColor } from 'pages/private/Executions/components/Tabs/components/ComplianceRules/utils/statusColor';

import './styles.less';

const I18N_BASE_PATH =
  'pages.private.executions.components.tabs.components.complianceRules.index.complianceRules';

const availableRulesTooltip = [
  '6512f60597d0e53635f0683a',
  '6512f60597d0e53635f0683b',
  '6530a88f23ef4b0d402f9cf9',
  '655b6593fb1c953d25a7d216',
];

const statusIcon = (status, color) => {
  switch (status) {
    case 'APPROVED':
    case 'APROVADO':
    case 'VALID':
      return <CorrectStroke color={color} fontSize={12} style={{ marginRight: '3px' }} />;
    case 'REPROVADO':
    case 'REPROVED':
      return <ErroStroke color={color} fontSize={12} style={{ marginRight: '3px' }} />;
    case 'PENDENTE':
    case 'PENDING':
    case 'PENDING_OCR':
      return <IcPauseSmall color={color} fontSize={12} style={{ marginRight: '3px' }} />;
    case 'WARNING':
      return (
        <ExclamationStroke color={color} fontSize={12} style={{ marginRight: '3px' }} />
      );

    default:
      return <></>;
  }
};
const ValidationsList = ({ validations }) => {
  const { t: translate, i18n } = useTranslation();
  const translateStatus = useCallback((status) => {
    switch (status) {
      case 'APPROVED':
      case 'VALID':
        return `${I18N_BASE_PATH}.translatedStatus.approved`;
      case 'REPROVED':
        return `${I18N_BASE_PATH}.translatedStatus.reproved`;
      case 'WARNING':
        return `${I18N_BASE_PATH}.translatedStatus.warning`;
      default:
        return `${I18N_BASE_PATH}.translatedStatus.pending`;
    }
  }, []);

  return (
    <div id="validations-list">
      {validations?.length > 0 &&
        validations?.map((v) => (
          <Row
            key={v.ruleId}
            align="middle"
            className="compliance-rules-status-profiles reset-mrg no-pdd pdd-top-10 pdd-btm-10 font-size-14"
          >
            <span
              className="rule-title"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {v.code || v.ruleId
                ? translate(
                    `validations.${v.code || v.ruleId}.title`,
                    v.title || v.ruleName
                  )
                : v.title || v.ruleName}
              {(i18n.exists(`validations.${v.ruleId}.tooltip`) || v?.description) && (
                <Tooltip
                  title={
                    v?.description ||
                    translate(`validations.${v.ruleId}.tooltip`, v.ruleName)
                  }
                  color="#323232"
                  overlayInnerStyle={{ textAlign: 'left' }}
                  placement="top"
                >
                  <Info
                    style={{ width: '15.5px', height: '15.5px', marginLeft: 4 }}
                    color="#323232"
                  />
                </Tooltip>
              )}
            </span>
            <div className="line" />
            <span
              className="tag-background-profiles"
              style={{
                color: statusColor[v?.resultStatus || v?.status]?.color,
                background: statusColor[v?.resultStatus || v?.status]?.background,
              }}
            >
              {statusIcon(
                v?.resultStatus || v?.status,
                statusColor[v?.resultStatus || v?.status]?.iconColor
              )}
              <span className="mrg-left-5">
                {translate(translateStatus(v?.resultStatus || v?.status))}
              </span>
            </span>
          </Row>
        ))}

      {validations?.length <= 0 && (
        <EmptyMessage
          type="compliance"
          description={translate(`${I18N_BASE_PATH}.emptyMessage.description`)}
          withCard
        />
      )}
    </div>
  );
};

export default ValidationsList;
