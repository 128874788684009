import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'antd';

import './styles.less';

const CustomUploadButton = ({
  onClick,
  customBottom,
  customClass,
  customContent,
  customIcon,
}) => {
  return (
    <Button
      id="custom-upload-btn-container"
      type="dashed"
      className={customClass}
      onClick={onClick}
      style={{ marginBottom: customBottom }}
    >
      <div className="custom-upload-btn-icon">
        <i className={customIcon} />
      </div>
      {customContent}
    </Button>
  );
};

CustomUploadButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  customBottom: PropTypes.string,
  customClass: PropTypes.string,
  customContent: PropTypes.element.isRequired,
  customIcon: PropTypes.string,
};

CustomUploadButton.defaultProps = {
  customBottom: '30px',
  customClass: '',
  customIcon: 'caf-ic_upload',
};

export default CustomUploadButton;
