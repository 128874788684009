import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Collapse, Checkbox } from 'antd';

const { Panel } = Collapse;

const ExecutionStatusFilter = ({
  value,
  onChange,
  isKYBStatus = false,
  disabled = false,
}) => {
  const { t: translate } = useTranslation();

  return (
    <Collapse
      ghost
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <div>
          <i className={isActive ? 'caf-ic_arrow_up' : 'caf-ic_arrow_down'} />
        </div>
      )}
    >
      <Panel
        header={translate(
          'components.filters.executionStatusFilter.collapse.panel.header'
        )}
        key={!isKYBStatus ? 'status' : 'byStatus'}
      >
        <Checkbox.Group value={value} onChange={(e) => onChange(e)} disabled={disabled}>
          <Checkbox value="PROCESSANDO|PROCESSING">
            {translate(
              'components.filters.executionStatusFilter.collapse.panel.checkbox.processing.text'
            )}
          </Checkbox>
          <Checkbox value="PENDENTE|PENDING">
            {translate(
              'components.filters.executionStatusFilter.collapse.panel.checkbox.pending.text'
            )}
          </Checkbox>
          {!isKYBStatus && (
            <Checkbox value="PENDENTE OCR">
              {translate(
                'components.filters.executionStatusFilter.collapse.panel.checkbox.pendingOcr.text'
              )}
            </Checkbox>
          )}
          <Checkbox value="APROVADO|APPROVED">
            {translate(
              'components.filters.executionStatusFilter.collapse.panel.checkbox.approved.text'
            )}
          </Checkbox>
          <Checkbox value="REPROVADO|REPROVED">
            {translate(
              'components.filters.executionStatusFilter.collapse.panel.checkbox.reproved.text'
            )}
          </Checkbox>
        </Checkbox.Group>
      </Panel>
    </Collapse>
  );
};

ExecutionStatusFilter.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

ExecutionStatusFilter.defaultProps = {
  value: [],
};

export default ExecutionStatusFilter;
