const handlerAssociateRegister = (quantity, customParam, translate) => {
  if (!quantity && quantity !== 0) return null;

  const param =
    customParam ||
    translate('utils.associateRegisters.handlerAssociateRegister.param.default');

  const oneCaseParam = param.replace(
    translate('utils.associateRegisters.handlerAssociateRegister.oneCaseParam.value'),
    translate('utils.associateRegisters.handlerAssociateRegister.oneCaseParam.replace')
  );

  const manyCaseParam = param.replace(
    translate('utils.associateRegisters.handlerAssociateRegister.manyCaseParam.value'),
    translate('utils.associateRegisters.handlerAssociateRegister.manyCaseParam.replace')
  );

  if (quantity === 0) {
    return translate(
      'utils.associateRegisters.handlerAssociateRegister.valueReturned.quantityEqualsToZero',
      { oneCaseParam }
    );
  }

  if (quantity === 1) {
    return translate(
      'utils.associateRegisters.handlerAssociateRegister.valueReturned.quantityEqualsToOne',
      { oneCaseParam }
    );
  }

  return translate(
    'utils.associateRegisters.handlerAssociateRegister.valueReturned.quantityMoreThanOne',
    { quantity, manyCaseParam }
  );
};

export { handlerAssociateRegister };
