/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Row } from 'antd';

import ViewMore from 'components/ViewMore';
import ActivitiesListCard from 'pages/private/Profiles/components/ActivitiesListCard';

import useAllProcesses from '../AllProcessesModal';

const ProcessesCard = ({ data, children }) => {
  const { t: translate } = useTranslation();

  const { companyId } = useParams();

  const { openModal: openAllProcessesModal, AllProcessesModal } = useAllProcesses({
    data,
    updateData: true,
  });

  const hasContent = data?.length > 0;

  return (
    <ActivitiesListCard
      title={translate(
        'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.activitiesListProcesses.index.activitiesListProcesses.activitiesListCard.title'
      )}
      footer={children}
    >
      {AllProcessesModal}

      <Row className="reset-mrg">
        {hasContent && (
          <>
            <p className="font-size-15">
              {data?.length > 1
                ? translate(
                    'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.activitiesListProcesses.index.activitiesListProcesses.subtitle.moreThanOneProcess',
                    { value: data?.length }
                  )
                : translate(
                    'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.activitiesListProcesses.index.activitiesListProcesses.subtitle.oneProcess'
                  )}
            </p>
            <Row className="gx-w-100">
              <ViewMore
                title={translate(
                  'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.activitiesListProcesses.index.activitiesListProcesses.viewMore.title'
                )}
                type="link"
                onClick={() => openAllProcessesModal()}
              />
            </Row>
          </>
        )}

        {!hasContent && (
          <p className="font-size-15">
            {translate(
              'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.activitiesListProcesses.index.activitiesListProcesses.hasNotContent'
            )}{' '}
            {translate(
              `pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.activitiesListProcesses.index.activitiesListProcesses.type.${
                companyId ? 'company' : 'person'
              }`
            )}
            .
          </p>
        )}
      </Row>
    </ActivitiesListCard>
  );
};

export default ProcessesCard;
