export const ufsBrasil = [
  'AC',
  'AL',
  'AM',
  'AP',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MG',
  'MS',
  'MT',
  'PA',
  'PB',
  'PE',
  'PI',
  'PR',
  'RJ',
  'RN',
  'RO',
  'RR',
  'RS',
  'SC',
  'SE',
  'SP',
  'TO',
];

export const sourcesDictionary = {
  cpf: 'cpf',
  detran: 'detran',
  pf_antecedentes: 'pfantecedentes',
  pf_nada_consta: 'pfnadaconsta',
  pf_rais: 'pfrais',
  pj_sintegra_data: 'sintegra',
  bacen_qgi: 'bacen',
  antt: 'antt',
  zip_code_details: 'zipcodedetails',
  pj_cpf_mei: 'pjcpfmei',
  pj_penalties_on_cvm: 'pjpenaltiescvm',
  pf_penalties_on_cvm: 'pfpenaltiescvm',
  suspicious_places: 'suspiciousplaces',
  pf_data: 'pfdata',
  cpf_data: 'cpfdata',
  pf_basic_data: 'pfbasicdata',
  email_age_private: 'emailage',
  denatran_driver_violations: 'denatrandriver',
  denatran_vehicle_violations: 'denatranvehicle',
  pf_address_authentication: 'pfaddress',
  phone_trust_score: 'phonetrust',
  pf_biometria_unico: 'pfbiometriaunico',
  sim_swap_alert: 'simswap',
  pf_mbs_arca_ms2: 'mbsarcams2',
  phone_analysis: 'phoneanalysis',
  email_analysis: 'emailanalysis',
};

// Estados indisponíveis para consulta na pfAntecedentes
export const disabledUFsPfAntecedentes = ['AL', 'DF'];

// Dados obrigatórios para todos os estados na fonte pfAntecedentes
export const allRequiredDataUFAntecedentes = [
  'name',
  'birthDate',
  // 'uf',
  'motherName',
  'fatherName',
  'rg',
  'cpf',
  'issueDate',
  'rgIssuingAuthority',
  'rgIssueState',
];

/**
 * Aqui alguns dos campos podem ser duplicados do array 'allRequiredDataUFAntecedentes',
 * isso serve para evitar problemas caso algum dado do array mude.
 *
 * Os campos específicos do estado não devem dependender exclusivamente do array
 */
export const requiredDataUFAntecedentes = {
  AC: [...allRequiredDataUFAntecedentes, 'rg', 'name', 'birthDate'],
  AL: [], // Consulta indisponível para essa UF
  AM: [...allRequiredDataUFAntecedentes, 'name', 'cpf', 'birthDate'],
  AP: [
    ...allRequiredDataUFAntecedentes,
    'name',
    'rg',
    'cpf',
    'birthDate',
    'motherName',
    'fatherName',
  ],
  BA: [
    ...allRequiredDataUFAntecedentes,
    'name',
    'rg',
    'birthDate',
    'motherName',
    'fatherName',
  ],
  CE: [...allRequiredDataUFAntecedentes, 'rg', 'name', 'birthDate', 'motherName'],
  DF: [], // Consulta indisponível para essa UF
  ES: [...allRequiredDataUFAntecedentes, 'rg', 'name', 'birthDate'],
  GO: [
    ...allRequiredDataUFAntecedentes,
    'name',
    'birthDate',
    'motherName',
    'fatherName',
    'rg',
    'cpf',
    'issueDate',
    'rgIssuingAuthority',
    'rgIssueState',
  ],
  MA: [...allRequiredDataUFAntecedentes, 'name', 'cpf'],
  MG: [...allRequiredDataUFAntecedentes, 'rg', 'name', 'birthDate', 'motherName'],
  MS: [
    ...allRequiredDataUFAntecedentes,
    'rg',
    'name',
    'birthDate',
    'motherName',
    'fatherName',
  ],
  MT: [
    ...allRequiredDataUFAntecedentes,
    'cpf',
    'rg',
    'rgIssuingAuthority',
    'name',
    'birthDate',
  ],
  PA: [...allRequiredDataUFAntecedentes, 'birthDate', 'name', 'motherName', 'rg', 'cpf'],
  PB: [...allRequiredDataUFAntecedentes, 'cpf', 'name'],
  PE: [
    ...allRequiredDataUFAntecedentes,
    'rg',
    'rgIssueState',
    'rgIssuingAuthority',
    'name',
    'birthDate',
    'motherName',
  ],
  PI: [
    ...allRequiredDataUFAntecedentes,
    'name',
    'cpf',
    'rg',
    'rgIssuingAuthority',
    'motherName',
    'fatherName',
  ],
  PR: [
    ...allRequiredDataUFAntecedentes,
    'birthDate',
    'name',
    'motherName',
    'rg',
    'fatherName',
  ],
  RJ: [
    ...allRequiredDataUFAntecedentes,
    'rg',
    'issueDate',
    'name',
    'birthDate',
    'motherName',
  ],
  RN: [...allRequiredDataUFAntecedentes, 'cpf', 'name'],
  RO: [...allRequiredDataUFAntecedentes, 'cpf', 'name'],
  RR: [...allRequiredDataUFAntecedentes, 'name', 'rg', 'birthDate', 'motherName'],
  RS: [...allRequiredDataUFAntecedentes, 'rg', 'name', 'birthDate'],
  SC: [
    ...allRequiredDataUFAntecedentes,
    'birthDate',
    'cpf',
    'name',
    'motherName',
    'rg',
    'fatherName',
  ],
  SE: [...allRequiredDataUFAntecedentes, 'name', 'rg', 'birthDate', 'motherName'],
  SP: [
    ...allRequiredDataUFAntecedentes,
    'name',
    'issueDate',
    'birthDate',
    'rg',
    'motherName',
    'fatherName',
  ],
  TO: [...allRequiredDataUFAntecedentes, 'name', 'cpf'],
  default: [],
};

export const requiredDataUFDetran = {
  // AC: ['cpf', 'name'],
  // AL: ['cpf', 'birthDate', 'cnhPlaceOfIssue'],
  // AM: ['cnhRegistrationNumber'],
  // AP: ['cpf', 'name', 'birthDate'],
  // BA: ['cnhRegistrationNumber'],
  // CE: ['cpf', 'cnhMirrorNumber'],
  // DF: ['cpf', 'name', 'motherName', 'birthDate'],
  // ES: ['cpf', 'cnhRegistrationNumber', 'cnhMirrorNumber'],
  // GO: ['cnhRegistrationNumber'],
  // MA: ['cpf', 'cnhRenachNumber', 'birthDate'],
  // MG: ['birthDate', 'cpf', 'cnhFirstDriveLicenseDate'],
  // MS: ['cpf', 'cnhRegistrationNumber'],
  // MT: ['cpf', 'cnhRegistrationNumber'],
  // PA: ['cpf', 'cnhRegistrationNumber'],
  // PB: ['cpf'],
  // PE: ['cpf'],
  // PI: [],
  // PR: ['cpf', 'cnhRegistrationNumber', 'cnhExpirationDate'],
  // RJ: ['cpf', 'cnhRegistrationNumber'],
  // RN: ['cpf', 'cnhRegistrationNumber'],
  // RO: ['cpf', 'birthDate'],
  // RR: ['birthDate', 'cnhRegistrationNumber'],
  // RS: ['name', 'rg', 'cnhRegistrationNumber', 'birthDate'],
  // SC: ['cnhRenachNumber', 'cpf', 'cnhRegistrationNumber'],
  // SE: ['birthDate', 'cnhRegistrationNumber', 'cnhMirrorNumber'],
  // SP: [
  //   'cpf',
  //   'rg',
  //   'birthDate',
  //   'cnhRegistrationNumber',
  //   'cnhRenachNumber',
  //   'cnhMirrorNumber',
  // ],
  // TO: ['cpf', 'birthDate'],
  default: [
    'name',
    'cpf',
    'rg',
    'birthDate',
    'cnhRegistrationNumber',
    'cnhPlaceOfIssue',
    'cnhRenachNumber',
    'cnhMirrorNumber',
    'motherName',
    'cnhFirstDriveLicenseDate',
    'cnhExpirationDate',
  ],
};
