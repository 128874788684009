import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Collapse, Checkbox } from 'antd';

const { Panel } = Collapse;

const ExecutionStatusFilter = ({ value, onChange }) => {
  const { t: translate } = useTranslation();

  return (
    <Collapse
      ghost
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <div>
          <i className={isActive ? 'caf-ic_arrow_up' : 'caf-ic_arrow_down'} />
        </div>
      )}
    >
      <Panel
        header={translate(
          'components.filters.documentoscopyFilter.collapse.panel.header'
        )}
        key="documentscopy"
      >
        <Checkbox.Group value={value} onChange={(e) => onChange(e)}>
          <Checkbox value="WAITING">
            {translate(
              'components.filters.documentoscopyFilter.collapse.panel.checkbox.waiting'
            )}
          </Checkbox>
          <Checkbox value="APPROVED">
            {translate(
              'components.filters.documentoscopyFilter.collapse.panel.checkbox.approved'
            )}
          </Checkbox>
          <Checkbox value="REPROVED">
            {translate(
              'components.filters.documentoscopyFilter.collapse.panel.checkbox.reproved'
            )}
          </Checkbox>
          <Checkbox value="UNSOLICITED">
            {translate(
              'components.filters.documentoscopyFilter.collapse.panel.checkbox.unsolicited'
            )}
          </Checkbox>
        </Checkbox.Group>
      </Panel>
    </Collapse>
  );
};

ExecutionStatusFilter.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

ExecutionStatusFilter.defaultProps = {
  value: [],
};

export default ExecutionStatusFilter;
