import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

import './styles.less';

const CompanySeparator = ({ className, margin = 30 }) => {
  return (
    <Row
      className={className}
      style={{ marginTop: `${margin}px`, marginBottom: `${margin}px` }}
    >
      <Col className="company-separator" />
    </Row>
  );
};

export default CompanySeparator;

CompanySeparator.propTypes = {
  className: PropTypes.string,
  margin: PropTypes.number,
};

CompanySeparator.defaultProps = {
  className: '',
  margin: 30,
};
