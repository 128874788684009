import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Collapse, Select } from 'antd';
import { useDocumentCategories } from 'hooks/useDocumentCategories';
import { convertSnakeCaseToCamelCase } from 'utils/stringUtils';
import ExpandIcon from 'components/Icon/ExpandIcon';

const { Panel } = Collapse;
const { Option } = Select;

const DocumentCategoriesFilter = ({ title, value, onChange }) => {
  const { t: translate } = useTranslation();

  const { global } = useDocumentCategories();

  const docCategories = [...global, 'UNAVAILABLE'];

  const docCategoryName = (category) => {
    const tKey = [
      'pages.private.executions.components.list.components.executionCard.index.executionDocument.docType',
      convertSnakeCaseToCamelCase(category),
    ].join('.');

    return translate(tKey);
  };

  return (
    <Collapse ghost expandIconPosition="right" expandIcon={ExpandIcon}>
      <Panel header={title} key="documentCatetories">
        <Select
          mode="multiple"
          value={value}
          onChange={(values) => onChange(values)}
          placeholder={translate(
            'components.filters.documentCategoriesFilter.collapse.panel.select.placeholder'
          )}
          dropdownClassName="multiselect-filters"
          dropdownAlign={{ offset: [0, 2] }}
          removeIcon={<i className="caf-ic_circle_false" />}
          menuItemSelectedIcon={<i className="caf-ic_checkmark" />}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.title?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
          }
        >
          {docCategories?.map((category) => {
            const name = docCategoryName(category);
            return (
              <Option key={category} value={category} title={name}>
                <div className="multiselect-option">
                  <span className="option-title">{name}</span>
                </div>
              </Option>
            );
          })}
        </Select>
      </Panel>
    </Collapse>
  );
};

DocumentCategoriesFilter.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default DocumentCategoriesFilter;
