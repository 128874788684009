import React from 'react';
import PropTypes from 'prop-types';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

import './styles.less';

const TooltipInfo = ({ title, size, color, placement }) => (
  <Tooltip placement={placement} title={title}>
    <InfoCircleOutlined
      style={{
        display: 'flex !important',
        color,
        fontSize: size,
        marginLeft: '5px',
      }}
    />
  </Tooltip>
);

TooltipInfo.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  placement: PropTypes.string,
};

TooltipInfo.defaultProps = {
  size: 16,
  color: '#bdbdbd',
  placement: 'top',
};

export default TooltipInfo;
