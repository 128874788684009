import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Row } from 'antd';

import PageTitle from 'components/PageTitle';

import AddressItem from '../AddressItem';

const AllAddressesModal = ({ data: addresses }) => {
  const { t: translate } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const AllAddressesModalContent = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        onCancel={closeModal}
        footer={null}
        width={760}
      >
        <div className="no-mrg no-pdd">
          <PageTitle
            title={translate(
              'pages.private.profiles.components.tabs.components.basicInfos.components.person.addressContent.components.allAddressesModal.index.allAddressesModal.pageTitle.title'
            )}
            subtitle={
              addresses?.length === 1
                ? translate(
                    'pages.private.profiles.components.tabs.components.basicInfos.components.person.addressContent.components.allAddressesModal.index.allAddressesModal.pageTitle.subtitle.oneAddress'
                  )
                : translate(
                    'pages.private.profiles.components.tabs.components.basicInfos.components.person.addressContent.components.allAddressesModal.index.allAddressesModal.pageTitle.subtitle.moreThanOneAddress',
                    { value: addresses?.length }
                  )
            }
          />

          <Row className="address-data-container no-mrg-left no-mrg-right mrg-vertical-30">
            {addresses?.length > 0 &&
              addresses?.map((address) => <AddressItem data={address} />)}
          </Row>
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, addresses, translate]
  );

  return { openModal, AllAddressesModal: AllAddressesModalContent };
};

export default AllAddressesModal;
