import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { renderValidationStatus } from 'utils/passwordValidations';

import './styles.less';

const PasswordValidations = ({ validations, firstLoad }) => {
  const { t: translate } = useTranslation();

  return (
    <div id="password-validations-list" className="mrg-vertical-20">
      <span className="display-block mrg-btm-10">
        {translate('components.passwordValidations.index.text')}:
      </span>
      <ul>
        <li>
          <i
            className={
              firstLoad
                ? 'caf-ic_minimize'
                : renderValidationStatus(validations.minimun).icon
            }
          />
          <span>
            {translate(
              'components.passwordValidations.index.validationList.eightCharacters'
            )}
          </span>
        </li>
        <li>
          <i
            className={
              firstLoad
                ? 'caf-ic_minimize'
                : renderValidationStatus(validations.uppercase).icon
            }
          />
          <span>
            {translate(
              'components.passwordValidations.index.validationList.oneUppercaseCharacters'
            )}
          </span>
        </li>
        <li>
          <i
            className={
              firstLoad
                ? 'caf-ic_minimize'
                : renderValidationStatus(validations.special).icon
            }
          />
          <span>
            {translate(
              'components.passwordValidations.index.validationList.oneSpecialCharacter'
            )}
          </span>
        </li>
        <li>
          <i
            className={
              firstLoad
                ? 'caf-ic_minimize'
                : renderValidationStatus(validations.lowercase).icon
            }
          />
          <span>
            {translate(
              'components.passwordValidations.index.validationList.oneTinyCharacter'
            )}
          </span>
        </li>
        <li>
          <i
            className={
              firstLoad
                ? 'caf-ic_minimize'
                : renderValidationStatus(validations.number).icon
            }
          />
          <span>
            {translate('components.passwordValidations.index.validationList.oneNumber')}
          </span>
        </li>
      </ul>
    </div>
  );
};

PasswordValidations.propTypes = {
  validations: PropTypes.shape({
    minimun: PropTypes.bool,
    uppercase: PropTypes.bool,
    special: PropTypes.bool,
    lowercase: PropTypes.bool,
    number: PropTypes.bool,
  }).isRequired,
  firstLoad: PropTypes.bool.isRequired,
};

export default PasswordValidations;
