const status = (newStatus) => {
  if (!newStatus)
    return {
      title: 'utils.handlerStatus.newDocumentscopyStatus.status.hasNotStatus.title',
      type: 'default',
    };

  switch (newStatus.toUpperCase()) {
    case 'APROVADO':
      return {
        title: 'utils.handlerStatus.newDocumentscopyStatus.status.APROVADO.title',
        type: 'primary',
      };
    case 'APPROVED':
      return {
        title: 'utils.handlerStatus.newDocumentscopyStatus.status.APPROVED.title',
        type: 'primary',
      };
    case 'REPROVADO':
      return {
        title: 'utils.handlerStatus.newDocumentscopyStatus.status.REPROVADO.title',
        type: 'danger',
      };
    case 'REPROVED':
      return {
        title: 'utils.handlerStatus.newDocumentscopyStatus.status.REPROVED.title',
        type: 'danger',
      };
    case 'PENDENTE':
      return {
        title: 'utils.handlerStatus.newDocumentscopyStatus.status.PENDENTE.title',
        type: 'warning',
      };
    case 'PENDING':
      return {
        title: 'utils.handlerStatus.newDocumentscopyStatus.status.PENDING.title',
        type: 'warning',
      };
    case 'PROCESSANDO':
      return {
        title: 'utils.handlerStatus.newDocumentscopyStatus.status.PROCESSANDO.title',
        type: 'info',
      };
    case 'PROCESSING':
      return {
        title: 'utils.handlerStatus.newDocumentscopyStatus.status.PROCESSING.title',
        type: 'info',
      };
    default:
      return {
        title: 'utils.handlerStatus.newDocumentscopyStatus.status.default.title',
        type: 'default',
      };
  }
};
export { status };
