import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useProfileTemplate } from 'hooks/profileTemplate';

import EmptyMessage from 'components/EmptyMessage';
// import currencyFormatter from 'utils/currencyFormatter';

import BackgroundChecking from '../../../../components/BackgroundChecking';
import BaseCard from '../BaseCard';

import './styles.less';

const I18N_BASE_PATH =
  'pages.private.profileModels.profileModelCreate.confirmation.components.cardBackgroundChecking.index';

const CardBackgroundChecking = ({ formRef }) => {
  const { t: translate } = useTranslation();

  const { profileTemplate, checkedServices } = useProfileTemplate();

  const formattedCheckedServices = useMemo(
    () => checkedServices?.filter(({ category }) => category !== 'onboarding') || [],
    [checkedServices]
  );

  return (
    <div
      id="confirmation-card-background-checking-component"
      className={classNames({
        empty: formattedCheckedServices?.length <= 0,
      })}
    >
      <BaseCard
        step="background_checking"
        title={translate(`${I18N_BASE_PATH}.baseCard.title`)}
      >
        {formattedCheckedServices?.length <= 0 ? (
          <EmptyMessage
            description={translate(`${I18N_BASE_PATH}.emptyMessage.description`)}
          />
        ) : (
          <BackgroundChecking
            mode="preview-compact"
            dataSources={formattedCheckedServices}
            profileType={profileTemplate?.type}
            formRef={formRef}
            disabled
          />
        )}
      </BaseCard>
    </div>
  );
};

CardBackgroundChecking.propTypes = {
  formRef: PropTypes.shape().isRequired,
};

export default CardBackgroundChecking;
