import React, { useMemo, useState, useEffect } from 'react';
import { Calendar, Company, InterfaceEdit } from '@combateafraude/icons/general';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import useFetch from 'services/hooks/fetch';
import NotAvailable from 'components/NotAvailable';
import { useWorkflow } from 'hooks/useWorkflow';
import PrintModal from '../../../CompanyDetails/components/Header/PrintModal';
import RenderFlagsOfCompany from '../../../CompanyDetails/components/TabsComponent/CompanyInfo/components/RenderFlagsOfCompany';
import { PartnersList } from './components/PartnersList';
import './styles.less';

const I18N_BASE_PATH = 'pages.private.KYBCompany.pages.companyDetails.header';

export const Header = ({ createdAt, updatedAt, companyData, transactionChildrenIds }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [loadingGetChildrensTransactions, setLoadingGetChildrensTransactions] = useState(
    false
  );
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();
  const { get: getChildrensTransactions } = useFetch();

  const handleToogleModal = () => setModalVisible(!modalVisible);

  const { data: _workflowsData } = useWorkflow();

  const workflowName = useMemo(() => {
    return _workflowsData?.docs?.find((w) => w?._id === companyData?.workflowId)
      ?.definition?.name;
  }, []);
  const [childrensTransactions, setChildrensTransactions] = useState([]);
  const onboardingPartners = companyData?.inputData?.parameters?.qsa || [];

  const getChildrensDetails = async () => {
    setLoadingGetChildrensTransactions(true);
    const responses = await Promise.all(
      transactionChildrenIds.map(
        async (child) =>
          (
            await getChildrensTransactions({
              url: `/executions/${child}?omitSections=true`,
            })
          ).data
      )
    );
    setChildrensTransactions(responses);
    setLoadingGetChildrensTransactions(false);
  };

  useEffect(() => {
    getChildrensDetails();
  }, []);

  const partners = useMemo(
    () =>
      onboardingPartners.map((onbPartner) => {
        const transaction = childrensTransactions.find(
          (t) => t.metadata.onboardingId === onbPartner.onboardingId
        );

        return {
          transactionId: transaction?._id,
          ...onbPartner,
        };
      }),
    [onboardingPartners, childrensTransactions]
  );

  return (
    <>
      <PrintModal
        modalVisible={modalVisible}
        handleToggleModal={handleToogleModal}
        companyData={companyData}
        createdAt={createdAt}
      />
      <div className="kyb-company-detailsv2-header-container">
        <div className="kyb-company-details-header-item">
          <h5 className="kyb-company-detailsv2-header-history">
            <div className="flex-row">
              <Link to="/company?page=1&tab=history">
                <span>{translate(`${I18N_BASE_PATH}.history`, 'History')}</span>
              </Link>
              <span className="kyb-company-detailsV2-header-space">/</span>
              {translate(`${I18N_BASE_PATH}.title`, 'Execution details')}
            </div>
            <div className="flex-row text-default font-size-12">
              <div className="flex center mrg-right-10">
                <Calendar className="kyb-company-details-header-icon mrg-right-5" />
                {translate(`${I18N_BASE_PATH}.createdAt`, 'Date created')}{' '}
                {i18nFormatDate(createdAt, 'default')}
              </div>
              <div className="flex center">
                <InterfaceEdit className="kyb-company-details-header-icon mrg-right-5" />
                {translate(`${I18N_BASE_PATH}.updatedAt`, 'Last modified')}{' '}
                {i18nFormatDate(updatedAt, 'default')}
              </div>
            </div>
          </h5>
          <h5 className="kyb-company-details-header-title">
            {companyData?.companyInfo?.companyName}
          </h5>
          <div className="flex-row space-between">
            <div className="flex-row start-center text-dark">
              <div className="pdd-right-15 divisor-right font-size-18">
                <Company />
              </div>
              <span className="pdd-horizon-15 divisor-right">
                {workflowName || <NotAvailable />}
              </span>
              <div className="pdd-horizon-15">
                <RenderFlagsOfCompany
                  countries={companyData?.summary?.allCountries}
                  priorityCountry={companyData?.summary?.priorityCountry}
                />
              </div>
              {onboardingPartners.length > 0 && (
                <div className="pdd-horizon-15 divisor-left">
                  <PartnersList
                    partners={partners}
                    loading={loadingGetChildrensTransactions}
                  />
                </div>
              )}
            </div>
            <div className="flex center">
              <Button shape="circle" onClick={handleToogleModal}>
                <DownloadOutlined />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
