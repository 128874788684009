import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Tag, Divider } from 'antd';

import './styles.less';

const TitleDivider = ({ title, tooltipInfo, toPrint, customTop, customBottom }) => {
  const customClasses = useMemo(() => {
    return toPrint ? 'to-print' : 'default';
  }, [toPrint]);

  const customMarginTop = useMemo(() => {
    return toPrint ? '10px' : customTop;
  }, [toPrint, customTop]);

  const customMarginBottom = useMemo(() => {
    return toPrint ? '10px' : customBottom;
  }, [toPrint, customBottom]);

  const titleComponent = useMemo(
    () => (
      <div className="title-divider-tag-wrapper">
        <Tag className="title-divider-tag">{title}</Tag>
      </div>
    ),
    [title]
  );

  return (
    <div
      id="title-divider-container"
      className={customClasses}
      style={{ marginTop: customMarginTop, marginBottom: customMarginBottom }}
    >
      {tooltipInfo && !toPrint && (
        <Tooltip placement="top" title={tooltipInfo}>
          {titleComponent}
        </Tooltip>
      )}

      {!tooltipInfo && !toPrint && titleComponent}

      {toPrint && (
        <>
          <div className="divider-line" />
          {titleComponent}
          <div className="divider-line" />
        </>
      )}

      {!toPrint && <Divider />}
    </div>
  );
};

TitleDivider.propTypes = {
  title: PropTypes.string.isRequired,
  tooltipInfo: PropTypes.string,
  customTop: PropTypes.string,
  customBottom: PropTypes.string,
  toPrint: PropTypes.bool,
};

TitleDivider.defaultProps = {
  tooltipInfo: undefined,
  customTop: '25px',
  customBottom: '30px',
  toPrint: false,
};

export default TitleDivider;
