/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import EmptyExecutionState from 'components/EmptyExecutionState';

import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';
import { toMaskedCnpj, toMaskedCpf } from 'utils/formatters';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

const Antt = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const antt = useMemo(() => source.data, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const anttQuerySucceeded = useMemo(
    () =>
      source?.statusCode === '01' ||
      source?.available === true ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [source]
  );

  return (
    <DataSourceCardCollapsePanel
      customId="driver-infos-component"
      title={translate(
        'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showAntt.title'
      )}
      icon="caf-ic_driver"
      toPrint={toPrint}
    >
      {antt && (
        <div id="executions-detran-info-container">
          {anttQuerySucceeded ? (
            <>
              {antt?.hasRegister !== false ? (
                <>
                  <Row style={{ paddingTop: 12 }}>
                    <ColumnTitleValue
                      title={translate(
                        'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showAntt.columnTitleValue.transporter'
                      )}
                      span={8}
                      value={antt?.transporter || '-'}
                    />
                    <ColumnTitleValue
                      title={translate(
                        'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showAntt.columnTitleValue.vehicleType'
                      )}
                      span={8}
                      value={antt?.vehicleType || '-'}
                    />
                    <ColumnTitleValue
                      title={translate(
                        'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showAntt.columnTitleValue.electronicIdSituation'
                      )}
                      span={8}
                      value={antt?.electronicIdSituation || '-'}
                    />
                  </Row>
                  <Row>
                    <ColumnTitleValue
                      title={translate(
                        'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showAntt.columnTitleValue.cpf_cnpj'
                      )}
                      span={8}
                      value={toMaskedCpf(antt?.cpf) || toMaskedCnpj(antt?.cnpj) || '-'}
                    />
                    <ColumnTitleValue
                      title={translate(
                        'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showAntt.columnTitleValue.vehiclePlate'
                      )}
                      span={8}
                      value={antt?.vehiclePlate || '-'}
                    />
                    <ColumnTitleValue
                      title={translate(
                        'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showAntt.columnTitleValue.message'
                      )}
                      span={8}
                      value={antt?.message || '-'}
                    />
                  </Row>
                  <Row>
                    <ColumnTitleValue
                      title={translate(
                        'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showAntt.columnTitleValue.city'
                      )}
                      span={8}
                      value={antt?.city || '-'}
                    />
                    <ColumnTitleValue
                      title={translate(
                        'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showAntt.columnTitleValue.memberSince'
                      )}
                      span={8}
                      value={antt?.memberSince || '-'}
                    />
                  </Row>
                  <Row>
                    <ColumnTitleValue
                      title={translate(
                        'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showAntt.columnTitleValue.rntrc'
                      )}
                      span={8}
                      value={`${antt?.rntrc || '-'} (${antt?.rntrcSituation || ''})`}
                    />
                    <ColumnTitleValue
                      title={translate(
                        'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showAntt.columnTitleValue.expirationDate'
                      )}
                      span={8}
                      value={antt?.expirationDate || '-'}
                    />
                  </Row>
                </>
              ) : (
                <Row style={{ paddingTop: 12 }}>
                  <ColumnTitleValue
                    title=""
                    span={8}
                    value={
                      source?.message ||
                      antt?.message ||
                      translate(
                        'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showAntt.columnTitleValue.empty'
                      )
                    }
                  />
                </Row>
              )}
            </>
          ) : (
            <EmptyExecutionState
              className="mrg-top-10"
              statusCode={statusCode}
              message={antt?.message || source?.message}
            />
          )}
        </div>
      )}
    </DataSourceCardCollapsePanel>
  );
};

export default Antt;
