import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'services/hooks';

import { Modal, Row, Typography } from 'antd';
import Button from 'components/Button';
import { CheckCircle2 } from '@combateafraude/icons/general';

import './styles.less';

const { Title, Text } = Typography;

const I18N_BASE_PATH =
  'pages.private.globalProfiles.components.sugestion.connectionModal';

const NotConnectModal = ({ closeModal, nextStep, postSuggestion, loading }) => {
  const { t: translate } = useTranslation();

  return !nextStep ? (
    <div className="modal-connect-content">
      <section className="mrg-top-42 ant-form-vertical">
        <Row align="middle" justify="center">
          <Title className="title-connect-modal">
            {translate(`${I18N_BASE_PATH}.notConnect`)}
          </Title>
        </Row>
        <Row align="middle" justify="center">
          <Text className="text-connect-modal">
            {translate(`${I18N_BASE_PATH}.notConnect.confimation`)}
          </Text>
        </Row>

        <div className="button-container-connect-modal">
          <Button type="default" onClick={closeModal} className="btn-cancel">
            {translate(`${I18N_BASE_PATH}.buttonCancel`)}
          </Button>
          <Button
            onClick={postSuggestion}
            className="not-connect-ok-button"
            loading={loading}
          >
            {translate(`${I18N_BASE_PATH}.buttonOk`)}
          </Button>
        </div>
      </section>
    </div>
  ) : (
    <div className="modal-connect-content">
      <section className="mrg-top-42 ant-form-vertical">
        <Row align="middle" justify="center">
          <Title className="final-text-not-connect">
            {translate(`${I18N_BASE_PATH}.notConnect.finalMessage`)}
          </Title>
        </Row>
      </section>
    </div>
  );
};

const ConnectModal = ({ closeModal, nextStep, postSuggestion, loading }) => {
  const { t: translate } = useTranslation();

  return !nextStep ? (
    <div className="modal-connect-content">
      <section className="mrg-top-42 ant-form-vertical">
        <Row align="middle" justify="center">
          <Title className="title-connect-modal">
            {translate(`${I18N_BASE_PATH}.connect`)}
          </Title>
        </Row>
        <Row align="middle" justify="center">
          <Text className="text-connect-modal">
            {translate(`${I18N_BASE_PATH}.connect.confimation`)}
          </Text>
        </Row>

        <div className="button-container-connect-modal">
          <Button type="default" onClick={closeModal} className="btn-cancel">
            {translate(`${I18N_BASE_PATH}.buttonCancel`)}
          </Button>
          <Button onClick={postSuggestion} type="primary" loading={loading}>
            {translate(`${I18N_BASE_PATH}.buttonOk`)}
          </Button>
        </div>
      </section>
    </div>
  ) : (
    <div className="modal-connect-content">
      <section className="mrg-top-42 ant-form-vertical">
        <Row align="middle" justify="center">
          <CheckCircle2
            className="icon"
            style={{
              width: '74px',
              height: '74px',
              color: '#004AF7',
              marginTop: '26px',
              marginBottom: '22px',
            }}
          />
          <Title className="final-text-connect">
            {translate(`${I18N_BASE_PATH}.connect.finalMessage`)}
          </Title>
        </Row>
      </section>
    </div>
  );
};

const ConnectionModal = ({ refreshListRef }) => {
  const [nextStep, setNextStep] = useState(false);
  const [modalType, setModalType] = useState('connect');
  const [suggestions, setSuggestions] = useState([]);
  const { post, loading } = useFetch();
  const [modalVisible, setModalVisible] = useState(false);
  const openModal = useCallback(
    ({ type, suggestions }) => {
      setModalType(type);
      setSuggestions(suggestions);
      setModalVisible(true);
    },
    [setModalVisible, setSuggestions, setModalType]
  );

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setNextStep(false);
    setSuggestions([]);
  }, [setModalVisible, setNextStep, setSuggestions]);

  const postSuggestion = useCallback(async () => {
    await post({
      url: `/global/profiles/suggestion/${modalType}`,
      payload: {
        suggestionIds: suggestions,
      },
    });
    refreshListRef.current();
    setNextStep(true);
  }, [modalType, post, refreshListRef, suggestions]);

  const ConnectionsModal = useMemo(() => {
    return (
      <Modal
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        footer={null}
        width="538px"
        centered
        visible={modalVisible}
        onCancel={closeModal}
      >
        {modalType !== 'connect' ? (
          <NotConnectModal
            closeModal={closeModal}
            setNextStep={setNextStep}
            nextStep={nextStep}
            postSuggestion={postSuggestion}
            loading={loading}
          />
        ) : (
          <ConnectModal
            closeModal={closeModal}
            setNextStep={setNextStep}
            nextStep={nextStep}
            postSuggestion={postSuggestion}
            loading={loading}
          />
        )}
      </Modal>
    );
  }, [
    modalVisible,
    postSuggestion,
    loading,
    nextStep,
    setNextStep,
    closeModal,
    modalType,
  ]);
  return { openModal, closeModal, ConnectionsModal };
};

export default ConnectionModal;
