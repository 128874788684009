import { ukNationalities } from 'utils/nationalities';
import moment from 'moment';

export const dynamicBirthDateFormat = (i18nFormat) => (
  birthDate,
  nationality,
  country
) => {
  const isUkNationality =
    ukNationalities.includes(nationality) && ukNationalities.includes(country);
  if (birthDate && isUkNationality) {
    return i18nFormat(birthDate, 10, null);
  }
  return i18nFormat(birthDate, 1, null);
};

export const isValidDate = (date) =>
  date &&
  new Date(date) >= new Date('1800-01-01') &&
  new Date(date) <= new Date('2100-01-01');

export const epochToDate = (epoch) =>
  moment(epoch * 1000)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss');

export const dateToEpoch = (timestamp) => Math.floor(timestamp / 1000);
