import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'antd';

// import ComplianceRulesCard from 'pages/private/Profiles/components/ComplianceRules/ComplianceRulesCard';
import ExecutionDetails from './ExecutionDetails';

const statusTranslator = {
  REPROVADO:
    'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewExecution.statusTranslator.REPROVED',
  REPROVED:
    'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewExecution.statusTranslator.REPROVED',
  APROVADO:
    'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewExecution.statusTranslator.APPROVED',
  APPROVED:
    'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewExecution.statusTranslator.APPROVED',
  PROCESSING:
    'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewExecution.statusTranslator.PROCESSING',
  PROCESSANDO:
    'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewExecution.statusTranslator.PROCESSING',
  PENDING:
    'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewExecution.statusTranslator.PENDING',
  PENDENTE:
    'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewExecution.statusTranslator.PENDING',
};

const CardNewExecution = ({
  firstExecution,
  documentscopyRequest,
  title,
  type,
  status,
  name,
  // reportName,
  execution,
  description,
  children,
  executionOrigin,
  toPrint,
}) => {
  const { t: translate } = useTranslation();
  const [showDocumentscopyRequest, setShowDocumentscopyRequest] = useState(false);
  const { companyId } = useParams();

  const displayedTitle = useMemo(() => {
    if (type === 'CUSTOM_DATA') {
      if (firstExecution) {
        return translate(
          `pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewExecution.displayedTitle.type.customData.firstExecution.${
            companyId ? 'company' : 'person'
          }`
        );
      }
      if (status === 'PROCESSING')
        return translate(
          'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewExecution.displayedTitle.type.customData.statusProcessing',
          { name }
        );
    }
    if (type === 'COMBATE_A_FRAUDE') {
      if (status === 'PROCESSING') {
        if (documentscopyRequest) {
          setShowDocumentscopyRequest(true);
        }
        return translate(
          'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewExecution.displayedTitle.type.combateAFraude.statusProcessing'
        );
      }
      return translate(
        'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewExecution.displayedTitle.type.combateAFraude.default'
      );
    }
    if (type === 'OUTSIDE_PROJECT') {
      // return `Nova consulta em ${reportName}`;
      return translate(
        'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewExecution.displayedTitle.type.outsideProject'
      );
    }

    const parsedName = name.trim() !== '-' ? name : null;

    return (
      parsedName ||
      translate(
        'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewExecution.displayedTitle.type.default'
      )
    );
  }, [type, status, name, firstExecution, documentscopyRequest, companyId, translate]);
  return (
    <Card className="card-new-activity">
      <div className="content">
        <Row className="card-new-activity-header">
          <Col span={24}>
            <span className="title">
              {title ||
                `${translate(
                  'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewExecution.cardNewExecution.title'
                )} / ${
                  translate(statusTranslator[status])
                    ? translate(statusTranslator[status])
                    : translate(
                        'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewExecution.statusTranslator.PROCESSING'
                      )
                }`}
            </span>
            <strong className="subtitle">{displayedTitle}</strong>
          </Col>
        </Row>

        <Row>
          {showDocumentscopyRequest && (
            <Col span={24} className="mrg-btm-30">
              <span className="font-size-15">
                {translate(
                  'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewExecution.cardNewExecution.showDocumentscopyRequest'
                )}
              </span>
            </Col>
          )}

          {description && <Col span={24}>{description}</Col>}

          {execution && (
            <Col span={24}>
              <ExecutionDetails
                data={execution}
                executionOrigin={executionOrigin}
                toPrint={toPrint}
              />
            </Col>
          )}
        </Row>
      </div>
      {children}
    </Card>
  );
};

CardNewExecution.propTypes = {
  firstExecution: PropTypes.bool,
  documentscopyRequest: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
  name: PropTypes.string.isRequired,
  // reportName: PropTypes.string,
  execution: PropTypes.shape({
    status: PropTypes.string,
    report: PropTypes.shape({}),
    validations: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  toPrint: PropTypes.bool,
};

CardNewExecution.defaultProps = {
  firstExecution: false,
  documentscopyRequest: undefined,
  title: undefined,
  type: undefined,
  status: undefined,
  description: undefined,
  toPrint: false,
  // reportName: undefined,
};

export default CardNewExecution;
