import eu from 'assets/images/flag-eu.svg';
import fbi from 'assets/images/flag-fbi.svg';
import uk from 'assets/images/flag-uk.svg';
import interpol from 'assets/images/flag-interpol.svg';
import ofac from 'assets/images/flag-ofac.svg';
import unsc from 'assets/images/flag-unsc.svg';

const sanctionsInfos = (initial) => {
  if (!initial) return {};
  switch (initial.toUpperCase()) {
    case 'EU':
      return {
        subtitle:
          'components.dataSourceCards.restrictiveLists.utils.sanctionsDetails.sanctionsInfos.EU',
        icon: eu,
      };
    case 'FBI':
      return {
        subtitle:
          'components.dataSourceCards.restrictiveLists.utils.sanctionsDetails.sanctionsInfos.FBI',
        icon: fbi,
      };
    case 'GOV UK':
      return {
        subtitle:
          'components.dataSourceCards.restrictiveLists.utils.sanctionsDetails.sanctionsInfos.GOV_UK',
        icon: uk,
      };
    case 'INTERPOL':
      return {
        subtitle:
          'components.dataSourceCards.restrictiveLists.utils.sanctionsDetails.sanctionsInfos.INTERPOL',
        icon: interpol,
      };
    case 'OFAC':
      return {
        subtitle:
          'components.dataSourceCards.restrictiveLists.utils.sanctionsDetails.sanctionsInfos.OFAC',
        icon: ofac,
      };
    case 'UNSC':
      return {
        subtitle:
          'components.dataSourceCards.restrictiveLists.utils.sanctionsDetails.sanctionsInfos.UNSC',
        icon: unsc,
      };
    case 'COAF':
      return {
        subtitle:
          'components.dataSourceCards.restrictiveLists.utils.sanctionsDetails.sanctionsInfos.COAF',
        icon: 'caf-ic_dollar_sign',
      };
    case 'CEAF':
      return {
        subtitle:
          'components.dataSourceCards.restrictiveLists.utils.sanctionsDetails.sanctionsInfos.CEAF',
        icon: 'caf-ic_documentation',
      };
    case 'CNEP':
      return {
        subtitle:
          'components.dataSourceCards.restrictiveLists.utils.sanctionsDetails.sanctionsInfos.CNEP',
        icon: 'caf-ic_alert-triangle',
      };
    case 'MTE':
      return {
        subtitle:
          'components.dataSourceCards.restrictiveLists.utils.sanctionsDetails.sanctionsInfos.MTE',
        icon: 'caf-ic_law',
      };
    case 'CONSELHO NACIONAL DE JUSTIÇA':
      return {
        subtitle:
          'components.dataSourceCards.restrictiveLists.utils.sanctionsDetails.sanctionsInfos.CONSELHO_NACIONAL_DE_JUSTIÇA',
        icon: 'caf-ic_law',
      };
    case 'PPE':
      return {
        subtitle:
          'components.dataSourceCards.restrictiveLists.utils.sanctionsDetails.sanctionsInfos.PPE',
        icon: 'caf-ic_news',
      };
    case 'TSE':
      return {
        subtitle:
          'components.dataSourceCards.restrictiveLists.utils.sanctionsDetails.sanctionsInfos.TSE',
        icon: 'caf-ic_law',
      };
    case 'CEIS':
      return {
        subtitle:
          'components.dataSourceCards.restrictiveLists.utils.sanctionsDetails.sanctionsInfos.CEIS',
        icon: 'caf-ic_company',
      };
    case 'OTHERS':
      return {
        subtitle:
          'components.dataSourceCards.restrictiveLists.utils.sanctionsDetails.sanctionsInfos.OTHERS',
        icon: 'caf-ic_hash',
      };
    case 'TRIBUNAL DE CONTAS DA UNIÃO':
      return {
        subtitle:
          'components.dataSourceCards.restrictiveLists.utils.sanctionsDetails.sanctionsInfos.TRIBUNAL_DE_CONTAS_DA_UNIÃO',
        icon: 'caf-ic_law',
      };
    default:
      return {
        subtitle:
          'components.dataSourceCards.restrictiveLists.utils.sanctionsDetails.sanctionsInfos.default',
        icon: 'caf-ic_news',
      };
  }
};

const nationalList = [
  'COAF',
  'CEAF',
  'CNEP',
  'MTE',
  'CONSELHO NACIONAL DE JUSTIÇA',
  'PPE',
  'TSE',
  'CEIS',
  'OTHERS',
  'CEPIM',
  'TRIBUNAL DE CONTAS DA UNIÃO',
];

const internationalList = ['EU', 'FBI', 'GOV UK', 'INTERPOL', 'OFAC', 'UNSC'];

export { sanctionsInfos, nationalList, internationalList };
