/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';

// import currencyFormatter from 'utils/currencyFormatter';

import './styles.less';

// const { toCurrency } = currencyFormatter;

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.shared.spcRestrictionsScore.spcInfos';

const SPCInfos = ({ data, spcScoreRange, totalDebts }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatCurrency } = useI18nFormatters();

  const restriction = data?.restriction;
  const _class = data[spcScoreRange]?.class;
  const score = data[spcScoreRange]?.score;
  const debt = totalDebts || 0;
  const allPeriodDebts = data?.spc?.details
    ?.map((register) => register?.value || 0)
    ?.reduce((acc, cur) => acc + cur || 0, 0);

  return (
    <Row id="executions-spc-row-infos" justify="space-between" className="reset-mrg">
      <Col span={5} className="spc-col-info no-left">
        <strong>
          {translate(`${I18N_BASE_PATH}.index.spcInfos.restriction.description`)}:
        </strong>
        <span>
          {translate(
            `${I18N_BASE_PATH}.index.spcInfos.restriction.value.${
              restriction ? 'hasRestriction' : 'hasNotRestriction'
            }`
          )}
        </span>
      </Col>
      <Col span={5} className="spc-col-info">
        <strong>
          {translate(`${I18N_BASE_PATH}.index.spcInfos.class.description`)}:
        </strong>
        <div className="spc-class-info">
          <span>
            {_class || '-'}
            <span className="mrg-left-15">
              {translate(`${I18N_BASE_PATH}.index.spcInfos.class.value`)}
            </span>
          </span>
        </div>
      </Col>
      <Col span={3} className="spc-col-info">
        <strong>
          {translate(`${I18N_BASE_PATH}.index.spcInfos.score.description`)}:
        </strong>
        <span>{score || '-'}/1000</span>
      </Col>
      <Col span={5} className="spc-col-info no-right end">
        <strong>{translate(`${I18N_BASE_PATH}.index.spcInfos.debt.description`)}:</strong>
        <span>
          {i18nFormatCurrency(debt, 'pt-BR') ||
            translate(`${I18N_BASE_PATH}.index.spcInfos.debt.value`)}{' '}
        </span>
      </Col>
      <Col span={4} className="spc-col-info no-right end">
        <strong>
          {translate(`${I18N_BASE_PATH}.index.spcInfos.totalDebts.description`)}:
        </strong>
        <span>
          {i18nFormatCurrency(allPeriodDebts, 'pt-BR') ||
            translate(`${I18N_BASE_PATH}.index.spcInfos.totalDebts.value`)}{' '}
        </span>
      </Col>
    </Row>
  );
};

export default SPCInfos;
