import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Form } from 'antd';
import AdditionalInfo from 'components/AdditionalInfo';

const Finish = ({ formRef, setAdditionalInfoFormFunc }) => {
  const services = useMemo(() => {
    if (formRef?.getFieldValue()?.backgroundCheckingServices)
      return Object.values(formRef.getFieldValue().backgroundCheckingServices);
    return [];
  }, [formRef]);

  const [additionalInfoForm] = Form.useForm();

  useMemo(() => {
    setAdditionalInfoFormFunc(additionalInfoForm);
  }, [additionalInfoForm, setAdditionalInfoFormFunc]);

  return (
    <section className="gx-w-100">
      <Form form={additionalInfoForm} layout="vertical">
        <AdditionalInfo services={services} />
      </Form>
    </section>
  );
};

Finish.propTypes = {
  setAdditionalInfoFormFunc: PropTypes.func.isRequired,
};

export default Finish;
