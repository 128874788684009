/* eslint-disable react/forbid-prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Row, Col, Button, Spin } from 'antd';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import NotAvailable from 'components/NotAvailable';

const I18N_BASE_PATH = 'pages.private.KYBCompany.pages.companyDetails.subPages.records';

const RecordHeader = ({ className, record, handleViewRecord, loading, print }) => {
  const { i18nFormatDateOnly } = useI18nFormatters();
  const { t: translate } = useTranslation();
  const recordTypes = (type) => {
    if (type === 'about') return translate(`${I18N_BASE_PATH}.type.about`);
    if (type === 'mentions') return translate(`${I18N_BASE_PATH}.type.mentions`);
    return translate(`${I18N_BASE_PATH}.type.undefined`);
  };

  const colSizes = useMemo(() => (print ? [9, 3, 3, 6, 3] : [7, 3, 3, 5, 3, 3]), [print]);

  return (
    <Row
      className={classnames('pdd-vertical-20 text-dark', className)}
      style={{ borderBottom: '1px solid #BDBDBD' }}
    >
      <Col span={colSizes[0]}>{record.label}</Col>
      <Col span={colSizes[1]}>
        {record.acquisitionDate ? (
          i18nFormatDateOnly(record.acquisitionDate, 1)
        ) : (
          <NotAvailable />
        )}
      </Col>
      <Col span={colSizes[2]}>
        {record.publicationDate ? (
          i18nFormatDateOnly(record.publicationDate, 1)
        ) : (
          <NotAvailable />
        )}
      </Col>
      <Col span={colSizes[3]}>{record.source?.name}</Col>
      <Col span={colSizes[4]}>{recordTypes(record.type)}</Col>
      {!print && (
        <Col span={colSizes[5]} className="font-size-10 flex-column start-center">
          <Button
            type="link"
            onClick={() => handleViewRecord(record)}
            disabled={loading}
            loading={loading}
            className="text-bold"
          >
            {translate(`${I18N_BASE_PATH}.viewRecord`, 'View record')}
          </Button>
          <p className="text-default">
            {!record?.saved
              ? translate(`${I18N_BASE_PATH}.additionalCharge`, 'Additional charge')
              : translate(`${I18N_BASE_PATH}.recordSaved`, 'Record saved')}
          </p>
        </Col>
      )}
    </Row>
  );
};

export default RecordHeader;

RecordHeader.propTypes = {
  record: PropTypes.any.isRequired,
};
