/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

// import BasicInfosCard from 'components/DataSourceCards/BasicInfos/components/BasicInfosCard';
import EmptyExecutionState from 'components/EmptyExecutionState';
import ViewMore from 'components/ViewMore';

import SPCProgress from '../SPCProgress';
import SPCInfos from '../SPCInfos';
import useSPCDetails from '../SPCDetailsModal';

import DataSourceCardCollapsePanel from '../../../../../DataSourceCardCollapsePanel';

import './styles.less';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.companies.components.spcReportParticipationScore';

const SPC = ({ source = {}, toPrint = false, toPrintDetails }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const totalDebts = useMemo(() => parsedData?.spc?.totalValue, [parsedData]);

  const { openModal, SPCDetailsModal } = useSPCDetails({
    data: parsedData?.spc,
  });

  const isValidScore = useMemo(() => {
    if (!parsedData?.scorePj) return false;

    const score =
      parsedData?.scorePj?.score || parsedData?.scorePj?.scorePjDetails?.score;
    if (!score) return false;

    const formattedScore = Number(score);

    if (formattedScore >= 0 && formattedScore <= 1000) {
      return true;
    }

    return false;
  }, [parsedData]);

  return (
    <DataSourceCardCollapsePanel
      title={translate(`${I18N_BASE_PATH}.index.title`)}
      icon="caf-ic_dollar_sign"
      toPrint={toPrint}
      customId="financial-infos-component"
    >
      <Row>
        {SPCDetailsModal}

        <Col span={24} style={{ padding: 0, marginBottom: 24 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col
              span={12}
              bordered={false}
              style={{ height: 25 }}
              className="text-dark text-bold reset-mrg"
            >
              <span style={{ height: 25 }} className="text-dark text-bold">
                {translate(`${I18N_BASE_PATH}.index.spcQuery`)}
              </span>
            </Col>
            <Col
              span={12}
              className="text-dark text-bold reset-mrg"
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            />
          </div>
        </Col>

        <Col span={24}>
          {queryWorked ? (
            <>
              {isValidScore ? (
                <Row className="mrg-top-25">
                  <Col span={24}>
                    <SPCProgress
                      data={
                        parsedData?.scorePj?.score ||
                        parsedData?.scorePj?.scorePjDetails?.score
                      }
                    />
                  </Col>
                </Row>
              ) : (
                <span>
                  {parsedData?.scorePj?.scorePjDetails?.interpretativeMessagingScore ||
                    translate(`${I18N_BASE_PATH}.index.invalidScore`)}
                </span>
              )}

              <SPCInfos data={parsedData} totalDebts={totalDebts} />

              {!toPrint && parsedData?.spc?.details?.length > 0 && (
                <Row>
                  <ViewMore
                    type="link"
                    title={translate(
                      `${I18N_BASE_PATH}.index.viewMore.title.hasToPrintDetails`
                    )}
                    onClick={() => openModal(parsedData)}
                  />
                </Row>
              )}

              {toPrint && !toPrintDetails && parsedData?.spc?.details?.length > 0 && (
                <Row>
                  <ViewMore
                    text
                    type="link"
                    title={translate(
                      `${I18N_BASE_PATH}.index.viewMore.title.hasNotToPrintDetails`,
                      { value: parsedData?.spc?.details?.length }
                    )}
                  />
                </Row>
              )}
            </>
          ) : (
            <EmptyExecutionState
              statusCode={statusCode}
              message={source?.message || parsedData?.message}
            />
          )}
        </Col>
      </Row>
    </DataSourceCardCollapsePanel>
  );
};

export default SPC;
