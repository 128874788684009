/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyState from 'components/EmptyState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import useCadinSPPendencies from './CadinSPPendenciesModal';

const CadinSP = ({ profile, toPrint /* urlId */ }) => {
  const { t: translate } = useTranslation();

  const cadinSP = useMemo(() => profile?.certificates?.cadinSp, [profile]);

  const { openModal, CadinSPPendenciesModal } = useCadinSPPendencies({
    data: profile?.certificates?.cadinSp,
  });

  const handlerQuantity = useMemo(() => {
    if (!cadinSP) return { title: null, type: null };

    if (!cadinSP?.pendencies || cadinSP?.pendencies?.length === 0) {
      return {
        title: translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.companies.components.cadinSP.index.handlerQuantity.hasNotPendencies'
        ),
        type: 'primary',
      };
    }

    if (cadinSP?.pendencies?.length === 1) {
      return {
        title: translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.companies.components.cadinSP.index.handlerQuantity.hasOnePendency'
        ),
        type: 'danger',
      };
    }

    return {
      title: translate(
        'pages.private.profiles.components.dataSourceCards.financialInformation.companies.components.cadinSP.index.handlerQuantity.hasMoreThanOnePendency',
        { value: cadinSP?.pendencies?.length }
      ),
      type: 'danger',
    };
  }, [cadinSP, translate]);

  const isProcessing = useMemo(() => verifySourcesProcessing(profile, 'cadinSp'), [
    profile,
  ]);

  const handleOpenModal = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <>
      {CadinSPPendenciesModal}

      <ColumnLabel
        title={translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.companies.components.cadinSP.index.cadinSP.columnLabel.title'
        )}
        description={handlerQuantity?.title}
        type={handlerQuantity?.type}
        onClick={handlerQuantity?.type === 'danger' && handleOpenModal}
        info={
          !toPrint &&
          translate(
            'pages.private.profiles.components.dataSourceCards.financialInformation.companies.components.cadinSP.index.cadinSP.columnLabel.info'
          )
        }
      >
        {!cadinSP && isProcessing && <EmptyState type="processing" />}

        {!cadinSP && !isProcessing && <EmptyState type="waiting_consult" />}

        {cadinSP && (!cadinSP?.pendencies || cadinSP?.pendencies?.length === 0) && (
          <span>
            {translate(
              'pages.private.profiles.components.dataSourceCards.financialInformation.companies.components.cadinSP.index.cadinSP.hasNotPendencies'
            )}
          </span>
        )}

        {!toPrint && cadinSP?.pendencies?.length > 0 && (
          <span>
            {translate(
              'pages.private.profiles.components.dataSourceCards.financialInformation.companies.components.cadinSP.index.cadinSP.detail'
            )}
          </span>
        )}
      </ColumnLabel>
    </>
  );
};

export default CadinSP;
