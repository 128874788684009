export default {
  normal: [
    'users',
    'users-pdv',
    'permissions',
    'access-keys',
    'consumption',
    'mobile-faces',
    'private-faceset',
  ],
};
