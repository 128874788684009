import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Collapse, Checkbox } from 'antd';

const { Panel } = Collapse;

const StatusFilter = ({ value, onChange }) => {
  const { t: translate } = useTranslation();

  return (
    <Collapse
      ghost
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <div>
          <i className={isActive ? 'caf-ic_arrow_up' : 'caf-ic_arrow_down'} />
        </div>
      )}
    >
      <Panel
        header={translate('components.filters.statusFilter.collapse.panel.header')}
        key="status"
      >
        <Checkbox.Group value={value} onChange={(e) => onChange(e)}>
          <Checkbox value="WAITING_DOCUMENTS">
            {translate(
              'components.filters.statusFilter.collapse.panel.checkboxGroup.waitingDocuments'
            )}
          </Checkbox>
          <Checkbox value="APPROVED">
            {translate(
              'components.filters.statusFilter.collapse.panel.checkboxGroup.approved'
            )}
          </Checkbox>
          <Checkbox value="PROCESSING">
            {translate(
              'components.filters.statusFilter.collapse.panel.checkboxGroup.processing'
            )}
          </Checkbox>
          <Checkbox value="PENDING">
            {translate(
              'components.filters.statusFilter.collapse.panel.checkboxGroup.pending'
            )}
          </Checkbox>
          <Checkbox value="REPROVED">
            {translate(
              'components.filters.statusFilter.collapse.panel.checkboxGroup.reproved'
            )}
          </Checkbox>
        </Checkbox.Group>
      </Panel>
    </Collapse>
  );
};

StatusFilter.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

StatusFilter.defaultProps = {
  value: [],
};

export default StatusFilter;
