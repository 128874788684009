/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useAuth } from 'hooks/auth';

import policiesData from 'utils/data/policiesData';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';
import ProfileTemplatesPanel from './ProfileTemplatesPanel';
import SpecialPanel from './SpecialPanel';

const TrustPanels = ({ formRef, disabled, policiesReturn }) => {
  const { user } = useAuth();

  const tenantId = useMemo(() => user?.accountData?.tenantId, [user]);

  // esconde as colunas relacionadas a perfis caso nao exista a flag
  const hideProfileRelatedRows = useMemo(
    () => !user?.accountData?.featureFlags?.profilesModule,
    [user]
  );

  return (
    <>
      {policiesData
        ?.filter(
          ({ hideWhenFlag }) => !hideWhenFlag || !hasFeatureFlag(hideWhenFlag, user)
        )
        ?.map((policy) =>
          policy?.type === 'special' ? (
            <SpecialPanel
              data={policy}
              formRef={formRef}
              disabled={disabled}
              hideProfileRelatedRows={hideProfileRelatedRows}
            />
          ) : (
            <ProfileTemplatesPanel
              data={policy}
              formRef={formRef}
              disabled={disabled}
              tenantId={tenantId}
              hideProfileRelatedRows={hideProfileRelatedRows}
              policiesReturn={policiesReturn}
            />
          )
        )}
    </>
  );
};

export default TrustPanels;
