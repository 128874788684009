import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import ViewMore from 'components/ViewMore';
import EmptyExecutionState from 'components/EmptyExecutionState';

import AddressItem from './components/AddressItem';

import './styles.less';

export const PfAddresses = ({ source = {}, toPrint = false, type = 'default' }) => {
  const { t: translate } = useTranslation();

  const [allAddressesVisible, setAllAddressesVisible] = useState(false);

  const addressCount = useMemo(() => source?.data?.length, [source]);

  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const addressFormatted = useMemo(() => {
    if (!source) return 'no-recurrence';

    if ((source && !source?.data) || statusCode === '02') {
      return 'query-failed';
    }

    if (source?.data?.addresses?.length > 0) {
      const addressesArray = source?.data?.addresses?.map((address) => {
        const { street, number, complement, neighborhood, city, state, zipCode } =
          address || {};

        return {
          addressMain: street,
          number,
          complement,
          neighborhood,
          city,
          state,
          zipCode,
        };
      });

      return addressesArray;
    }

    if (source?.data?.length > 0) {
      const addressesArray = source?.data?.map((address) => {
        const { addressMain, number, complement, neighborhood, city, state, zipCode } =
          address || {};

        return {
          addressMain,
          number,
          complement,
          neighborhood,
          city,
          state,
          zipCode,
        };
      });

      return addressesArray;
    }

    return 'no-data';
  }, [source, statusCode]);

  const showAddress = useMemo(
    () =>
      addressFormatted !== 'no-recurrence' &&
      addressFormatted !== 'no-data' &&
      addressFormatted !== 'query-failed',
    [addressFormatted]
  );

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_map_pin"
      title={
        type === 'alt'
          ? translate(
              'components.dataSourceCards.basicInfos.components.basicInfosCard.sources.pfAddressesAlt'
            )
          : translate(
              'components.dataSourceCards.basicInfos.components.basicInfosCard.sources.pfAddresses'
            )
      }
      toPrint={toPrint}
    >
      <>
        {showAddress && addressCount > 3 ? (
          <>
            {addressFormatted?.slice(0, 3)?.map((address) => (
              <Row className="special-address-item-row">
                <Col span={24}>
                  <AddressItem data={address} />
                </Col>
              </Row>
            ))}

            {allAddressesVisible &&
              addressFormatted?.slice(3, addressCount?.length)?.map((address) => (
                <Row>
                  <Col span={24}>
                    <AddressItem data={address} />
                  </Col>
                </Row>
              ))}

            <Row className="mrg-top-8">
              <ViewMore
                type="link"
                title={translate(
                  'components.dataSourceCards.basicInfos.components.person.addressContent.addressContent.viewMore.title',
                  { addressCount: addressCount - 3 }
                )}
                icon={!allAddressesVisible ? 'caf-ic_plus' : 'caf-ic_minimize'}
                onClick={() => setAllAddressesVisible((oldState) => !oldState)}
              />
            </Row>
          </>
        ) : (
          <>
            {!showAddress ? (
              <>
                {addressFormatted === 'query-failed' ? (
                  <EmptyExecutionState statusCode={statusCode} />
                ) : (
                  <p>
                    {translate(
                      'components.dataSourceCards.basicInfos.components.person.addressContent.columLabelTitle.hasNotToShowAddress'
                    )}
                  </p>
                )}
              </>
            ) : (
              <>
                {addressFormatted.map((address) => (
                  <Row className="special-address-item-row">
                    <Col span={24}>
                      <AddressItem data={address} />
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </>
        )}
      </>
    </DataSourceCardCollapsePanel>
  );
};
