import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Divider } from 'antd';
import {
  cnhTranslation,
  cpfTranslation,
  electoralSituationTranslation,
} from 'utils/personalTranslations';
import { toMaskedCpf } from 'utils/formatters';
import isValidCNH from 'utils/isValidCNH';
import * as pfDataSources from 'utils/pfDataSource';
import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';
import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';
import { useValidations } from 'hooks/validations';
import BasicInfosCard from '../BasicInfosCard';

const I18N_BASE_PATH =
  'pages.private.profiles.components.tabs.components.basicInfos.components.person.registrationInfosContent.registrationInfosContent';

const RegistrationInfosContent = ({ person = {}, dataSources, toPrint }) => {
  const { t: translate } = useTranslation();
  const { validations, loadingValidations } = useValidations();

  const basicData = useMemo(() => person?.basicData, [person]);

  const cpf = useMemo(() => {
    if (
      !person?.cpfQueries?.length &&
      !person?.sources?.cpf?.data &&
      !person?.sources?.pfBasicData?.data &&
      !person?.sources?.pfData?.data &&
      !person?.sources?.cpfData?.data &&
      !person?.sources?.pfCpfData?.data
    ) {
      return { cpf: person?.cpf, translatedMessage: cpfTranslation.NULA };
    }

    const cpfData =
      person?.sources?.cpfData?.data ||
      person?.sources?.cpf?.data ||
      person?.sources?.pfBasicData?.data ||
      person?.sources?.pfData?.data ||
      person?.sources?.pfCpfData?.data ||
      {};

    const translatedMessage =
      cpfTranslation[
        (
          cpfData.registrationStatusMessage ||
          cpfData.taxIdStatus ||
          parseInt(cpfData.registrationStatusCode, 10)?.toString() ||
          ''
        ).trim()
      ] || cpfTranslation.NULA;

    return {
      cpf: person?.cpf,
      translatedMessage,
      ...cpfData,
    };
  }, [person]);

  const electoralSituation = useMemo(() => {
    const electoralSituationData = person?.others?.electoralSituation;
    if (!electoralSituationData) {
      return {
        voterRegistration: null,
        translatedSituation: null,
      };
    }

    return {
      voterRegistration: electoralSituationData?.voterRegistration,
      translatedSituation:
        electoralSituationTranslation[electoralSituationData?.situation] ||
        electoralSituationTranslation.NULO,
    };
  }, [person]);

  const cnh = useMemo(() => {
    const validationsCnhData = validations?.validations?.find(
      (validation) => validation.rule === 'valid_document'
    );

    const cnhData =
      person?.driver?.detran?.data ||
      person?.ocr?.cnh?.data ||
      person?.sources?.denatranDriverViolations?.data?.driver ||
      validationsCnhData?.data?.ocr;

    if (!cnhData) {
      return {
        cnhNumber: null,
        translatedStatus: null,
      };
    }

    const cnhIndex = () => {
      if (person?.sources?.denatranDriverViolations?.data && cnhData.cnhExpirationDate) {
        return 'cnhExpirationDate';
      }
      if (person?.driver?.detran) {
        return 'validityDateCNH';
      }
      return 'expirationDate';
    };

    const dataIndex = cnhIndex();

    const isCNHValid = isValidCNH(cnhData, dataIndex, cnhTranslation);

    return {
      cnhNumber:
        person?.driver?.detran?.cnhNumber ||
        person?.ocr?.cnh?.registrationNumber ||
        person?.ocr?.cnh?.data?.registrationNumber ||
        person?.sources?.denatranDriverViolations?.data?.driver?.cnhNumber ||
        validationsCnhData?.data?.ocr?.registrationNumber,
      ...isCNHValid,
    };
  }, [person, validations]);

  const customShowDivider = useMemo(() => {
    return (
      pfDataSources.hasContactsInformation(person, dataSources) ||
      pfDataSources.hasAddressInformation(person, dataSources)
    );
  }, [dataSources, person]);

  return (
    <BasicInfosCard
      icon="caf-ic_file"
      title={translate(`${I18N_BASE_PATH}.basicInfosCard.title`)}
      data={person}
      dataIndex="basicData"
      showDivider={customShowDivider || toPrint}
    >
      <Row className="pdd-top-10">
        <ColumnTitleValue
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnTitleValue.name.title`)}
          value={basicData?.name || cpf?.name || '-'}
        />

        <ColumnTitleValue
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnTitleValue.rg.title`)}
          value={basicData?.rg || '-'}
        />

        <ColumnTitleValue
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnTitleValue.birthDate.title`)}
          value={basicData?.birthDate || cpf?.birthDate || '-'}
        />

        <ColumnLabel
          title={translate(`${I18N_BASE_PATH}.columnTitleValue.cpf.title`)}
          description={translate(cpf?.translatedMessage?.description)}
          type={cpf?.translatedMessage?.type}
          span={8}
        >
          <span className="text-dark">
            {toMaskedCpf(cpf.taxIdNumber) || toMaskedCpf(cpf.cpf) || '-'}
          </span>
        </ColumnLabel>

        <ColumnLabel
          title={translate(`${I18N_BASE_PATH}.columnTitleValue.cnh.title`)}
          description={translate(cnh?.translatedStatus?.description)}
          type={cnh?.translatedStatus?.type}
          span={8}
        >
          <span className="text-dark">
            {loadingValidations ? '-' : cnh?.cnhNumber || '-'}
          </span>
        </ColumnLabel>

        <ColumnLabel
          title={translate(`${I18N_BASE_PATH}.columnTitleValue.electoralSituation.title`)}
          description={translate(electoralSituation?.translatedSituation?.description)}
          type={electoralSituation?.translatedSituation?.type}
          span={8}
        >
          <span className="text-dark">
            {electoralSituation?.voterRegistration || '-'}
          </span>
        </ColumnLabel>

        <Divider className="custom-divider" />

        <ColumnTitleValue
          span={8}
          title={translate(`${I18N_BASE_PATH}.columnTitleValue.motherName.title`)}
          value={basicData?.motherName || '-'}
        />

        <ColumnTitleValue
          span={8}
          title={translate(`${I18N_BASE_PATH}.columnTitleValue.fatherName.title`)}
          value={basicData?.fatherName || '-'}
        />

        <ColumnTitleValue
          span={8}
          title={translate(`${I18N_BASE_PATH}.columnTitleValue.nit.title`)}
          value={basicData?.socialSecurityNumber || '-'}
        />
      </Row>
    </BasicInfosCard>
  );
};

RegistrationInfosContent.propTypes = {
  person: PropTypes.shape({
    basicData: PropTypes.shape({}),
    cpf: PropTypes.string,
    cpfQueries: PropTypes.arrayOf(PropTypes.object),
    others: PropTypes.shape({
      electoralSituation: PropTypes.shape({}),
    }),
    driver: PropTypes.shape({
      detran: PropTypes.shape({
        cnhNumber: PropTypes.string,
      }),
    }),
    ocr: PropTypes.shape({
      cnh: PropTypes.shape({
        registrationNumber: PropTypes.string,
      }),
    }),
  }),
  dataSources: PropTypes.shape([]),
  toPrint: PropTypes.bool,
};

RegistrationInfosContent.defaultProps = {
  person: undefined,
  dataSources: [],
  toPrint: false,
};

export default RegistrationInfosContent;
