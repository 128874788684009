import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Spin } from 'antd';
import { useAuth } from 'hooks/auth';
import { useWorkflow } from 'hooks/useWorkflow';

import { usePerson } from 'hooks/person';
import { useCompany } from 'hooks/company';
import { useExecution } from 'hooks/execution';

import PeopleSubheader from 'pages/private/GlobalProfiles/components/ProfileSubheader';
import PeopleContent from 'pages/private/GlobalProfiles/components/ProfileContent';

import Wrapper from '../../wrapper';
import Unauthorized from '../../Unauthorized';

const PeopleDetails = () => {
  const { user } = useAuth();
  const { personId } = useParams();
  const { loadPerson, loadingPerson, person, reloadPerson } = usePerson();
  const { clearCompany } = useCompany();
  const { clearExecution } = useExecution();
  const { getWorkflowsData, loading: loadingWorkflows } = useWorkflow();

  useEffect(() => {
    if (!loadPerson) return;

    loadPerson({ personId, clearData: true });
    getWorkflowsData({ tenantId: user?.tenantId, _limit: 1000 });
    clearCompany();
    clearExecution();
  }, [personId]); // eslint-disable-line

  return !user?.accountData?.featureFlags?.profilesModule ? (
    <Unauthorized />
  ) : (
    <Wrapper subheader={<PeopleSubheader profile={person} />} hideMainHeader>
      {loadingPerson || loadingWorkflows || !person ? (
        <Spin className="flex center mrg-top-30" />
      ) : (
        <PeopleContent profile={person} />
      )}
    </Wrapper>
  );
};

export default PeopleDetails;
