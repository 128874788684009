import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'hooks/auth';
import { hasRequiredPermissions } from 'routes/permissionFunctions';
import { CardWrapper } from 'components/CardWrapper';

import PrivateFaceset from './components/PrivateFaceset';
import MobileFaces from './components/MobileFaces';
import Wrapper from '../wrapper';

const I18N_BASE_PATH = 'pages.private.mobileFaces';
const { TabPane } = Tabs;

const Faces = () => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const { user: loggedUser } = useAuth();

  const handleTabClick = useCallback(
    (key) => {
      history.push(`/faces-base/${key}`);
    },
    [history]
  );

  const tabHeader = useCallback(
    (title, icon) => (
      <div className="flex align-center text-dark">
        <i className={`${icon} font-size-22 mrg-right-5`} />
        <strong className="text-semibold font-size-16">{title}</strong>
      </div>
    ),
    []
  );

  const hasOnboardinPermission = useMemo(
    () =>
      hasRequiredPermissions(loggedUser, ['mobile-faces:read'], [], false, 'management'),
    [loggedUser]
  );

  const hasPrivateFacesetPermission = useMemo(
    () =>
      hasRequiredPermissions(
        loggedUser,
        ['private-faceset:read'],
        [],
        false,
        'management'
      ),
    [loggedUser]
  );

  return (
    <Wrapper>
      <CardWrapper hasPadding>
        <Tabs onChange={handleTabClick} defaultActiveKey="private-faceset">
          {hasPrivateFacesetPermission && (
            <TabPane
              tab={tabHeader(
                translate('pages.private.privateFaceset.indexTitle'),
                'caf-ic_criminal'
              )}
              key="private-faceset"
            >
              <PrivateFaceset />
            </TabPane>
          )}

          {hasOnboardinPermission && (
            <TabPane
              tab={tabHeader(translate(`${I18N_BASE_PATH}.indexTitle`), 'caf-ic_face')}
              key="mobile-faces"
            >
              <MobileFaces />
            </TabPane>
          )}
        </Tabs>
      </CardWrapper>
    </Wrapper>
  );
};

export default Faces;
