import React, { useMemo } from 'react';
import EmptyExecutionState from 'components/EmptyExecutionState';
import { useTranslation } from 'react-i18next';
import ReactJsonView from 'react-json-view';
import { Row } from 'antd';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';

const MbsMs2 = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const data = useMemo(() => source.data, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_file"
      title={translate(
        'components.dataSourceCards.financialInformation.people.components.pfMbsMs2.title'
      )}
      toPrint={toPrint}
    >
      {statusCode !== '01' ? (
        <EmptyExecutionState
          statusCode={statusCode}
          message={source?.message || source?.data?.message}
        />
      ) : (
        <Row className="pdd-top-10 pdd-left-10">
          <ReactJsonView
            name={false}
            indentWidth={2}
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
            src={data}
          />
        </Row>
      )}
    </DataSourceCardCollapsePanel>
  );
};
export default MbsMs2;
