export default {
  financialStatements: {
    profitAndLoss: [
      'revenue',
      'operatingCosts',
      'operatingProfit',
      'wagesAndSalaries',
      'pensionCosts',
      'depreciation',
      'amortisation',
      'financialIncome',
      'financialExpenses',
      'extraordinaryIncome',
      'extraordinaryCosts',
      'tax',
      'profitAfterTax',
      'dividends',
      'minorityInterests',
      'otherAppropriations',
      'retainedProfit',
    ],
    balanceSheet: [
      'landAndBuildings',
      'plantAndMachinery',
      'otherTangibleAssets',
      'totalTangibleAssets',
      'goodwill',
      'otherIntangibleAssets',
      'totalIntangibleAssets',
      'investments',
      'loansToGroup',
      'otherLoans',
      'miscellaneousFixedAssets',
      'totalOtherFixedAssets',
      'totalFixedAssets',
      'rawMaterials',
      'workInProgress',
      'finishedGoods',
      'otherInventories',
      'totalInventories',
      'tradeReceivables',
      'groupReceivables',
      'receivablesDueAfter1Year',
      'miscellaneousReceivables',
      'totalReceivables',
      'cash',
      'otherCurrentAssets',
      'totalCurrentAssets',
      'totalAssets',
      'tradePayables',
      'bankLiabilities',
      'otherLoansOrFinance',
      'groupPayables',
      'miscellaneousLiabilities',
      'totalCurrentLiabilities',
      'tradePayablesDueAfter1Year',
      'bankLiabilitiesDueAfter1Year',
      'otherLoansOrFinanceDueAfter1Year',
      'groupPayablesDueAfter1Year',
      'miscellaneousLiabilitiesDueAfter1Year',
      'totalLongTermLiabilities',
      'totalLiabilities',
      'calledUpShareCapital',
      'sharePremium',
      'revenueReserves',
      'otherReserves',
      'totalShareholdersEquity',
    ],
    otherFinancials: ['contingentLiabilities', 'workingCapital', 'netWorth'],
    ratios: [
      'preTaxProfitMargin',
      'returnOnCaptialEmployed',
      'returnOnTotalAssetsEmployed',
      'returnOnNetAssetsEmployed',
      'salesOrNetWorkingCapital',
      'stockTurnoverRatio',
      'debtorDays',
      'creditorDays',
      'currentRatio',
      'liquidityRatioOrAcidTest',
      'currentDebtRatio',
      'gearing',
      'equityInPercentage',
      'totalDebtRatio',
    ],
  },
  localFinancialStatements: {
    cashFlow: [
      'operatingActivities',
      'investingActivities',
      'financingActivities',
      'decreaseOrIncreaseInCash',
      'netChangeInCash',
      'cashAtBeginningOfYear',
      'exchangeRateEffect',
      'cashAndCashEquivalentsAtYearEnd',
      'netCashFlowFromOperations',
      'netCashFlowBeforeFinancing',
      'netCashFlowFromFinancing',
      'increaseInCash',
      'managementOfLiquidResources',
      'equityDividendsPaid',
      'acquisitionsAndDisposals',
      'capitalExpenditure',
      'taxation',
      'returnOnInvestment',
    ],
  },
};
