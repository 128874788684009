export const mapZipCodeValidationStatus = (status) => {
  switch (status) {
    case 'APPROVED':
      return { status: 'valid', type: 'primary' };

    case 'REPROVED':
      return { status: 'invalid', type: 'danger' };

    default:
      return { status: 'undefined', type: 'default' };
  }
};
