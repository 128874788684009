import React, { useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Form } from 'antd';
import { Link } from 'react-router-dom';
import { useQueryParams, StringParam } from 'use-query-params';
import Tag from 'components/Tag';
import PageTitle from 'components/PageTitle';
import _ from 'lodash';

import { useAuth } from 'hooks/auth';
import { useI18nFormatters } from 'hooks/i18nFormatters';

import SearchInput from 'components/Form/SearchInput';
import useDynamoTable from 'components/List/hooks/useDynamoTable';

import PermissionWrapper from 'routes/PermissionWrapper';

import useAddUsersToGroup from '../AddUsersToGroup';
import ActionsList from '../UsersInternalActions';

// import { Container } from './styles';

const UsersInternalList = ({ groupId }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();
  const { user: loggedUser } = useAuth();

  const refreshListRef = useRef(() => {});
  const { openModal, AddUsersToGroupModal } = useAddUsersToGroup({ refreshListRef });
  const [query] = useQueryParams({
    search: StringParam,
  });

  const columns = [
    {
      key: 'name',
      title: (
        <span className="column-title">
          {translate(
            'pages.private.groups.components.groupContent.usersInternalList.columns.name'
          )}
        </span>
      ),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (__, record) => (
        <Link
          to={`/users-permissions/user/${record?.id}`}
          className="flex align-center text-dark"
        >
          {record?.name}
          {record?.id === loggedUser?.id && (
            <Tag className="mrg-left-10 tag-you">
              {translate(
                'pages.private.groups.components.groupContent.usersInternalList.you'
              )}
            </Tag>
          )}
        </Link>
      ),
    },
    {
      key: 'email',
      title: (
        <span className="column-title">
          {translate(
            'pages.private.groups.components.groupContent.usersInternalList.columns.email'
          )}
        </span>
      ),
      dataIndex: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      key: 'updatedAt',
      title: (
        <span className="column-title">
          {translate(
            'pages.private.groups.components.groupContent.usersInternalList.columns.updatedAt'
          )}
        </span>
      ),
      dataIndex: 'updatedAt',
      sorter: (a, b) => a?.updatedAt?.localeCompare(b?.updatedAt),
      render: (__, record) => i18nFormatDate(record?.updatedAt || record?.createdAt, 6),
    },
    {
      key: 'actions',
      render: (__, record) => (
        <ActionsList user={record} refreshListRef={refreshListRef} />
      ),
      width: 60,
    },
  ];

  const { tableContent, refreshList, setFilterData } = useDynamoTable({
    getParams: {
      url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/users/all`,
      config: {
        params: {
          usersGroupId: groupId,
        },
      },
    },
    queryParams: {
      search: StringParam,
    },
    columns,
    rowKey: 'id',
  });

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        search: values._search ? values._search.trim() : undefined,
      });
    }, 500),
    []
  );
  return (
    <>
      {AddUsersToGroupModal}
      <div className="flex align-center gx-w-100 space-between mrg-top-20">
        <PageTitle
          title={translate(
            'pages.private.groups.components.groupContent.usersInternalList.pageTitle.title'
          )}
          subtitle={translate(
            'pages.private.groups.components.groupContent.usersInternalList.pageTitle.subtitle'
          )}
        />
        <div className="flex row mrg-top-15 mrg-btm-30 align-center">
          <Form
            layout="horizontal"
            className="flex align-center"
            onValuesChange={handleFormChange}
            initialValues={{ _search: query?.search }}
          >
            <SearchInput
              placeholder={translate(
                'pages.private.groups.components.groupContent.usersInternalList.searchInput'
              )}
            />
          </Form>
          <PermissionWrapper requiredPermissions={['users:create']} product="management">
            <div className="mrg-left-15">
              <Button type="primary" ghost className="flex center" onClick={openModal}>
                <i className="caf-ic_plus font-size-15 mrg-right-5" />
                <span>
                  {translate(
                    'pages.private.groups.components.groupContent.usersInternalList.newUser'
                  )}
                </span>
              </Button>
            </div>
          </PermissionWrapper>
        </div>
      </div>
      <div className="gx-w-100 mrg-vertical-20">{tableContent}</div>
    </>
  );
};

UsersInternalList.propTypes = {
  groupId: PropTypes.string.isRequired,
};

export default UsersInternalList;
