import React from 'react';
import { useTranslation } from 'react-i18next';
import DossierCard from 'pages/private/GlobalProfiles/components/DossierCard';
import { Person } from '@combateafraude/icons/users';
import { usePerson } from 'hooks/person';
import KeyInformation from './components/KeyInformation';
import LastValidations from './components/LastValidations';
import ExecutionsLinked from './components/ExecutionsLinked';

const Summary = () => {
  const { t: translate } = useTranslation();
  const { person } = usePerson();
  return (
    <DossierCard
      title={translate(
        'pages.private.globalProfiles.components.tabs.components.summary.index.title',
        'Summary'
      )}
      subtitle={translate(
        'pages.private.globalProfiles.components.tabs.components.summary.index.subtitle',
        'Key information about this person'
      )}
      icon={<Person />}
    >
      <KeyInformation data={person?.profile?.summary} />
      <LastValidations
        validations={person?.lastTransactionValidations}
        lastExecutionId={person?.connectedTransactions?.data[0]?._id}
      />
      <ExecutionsLinked executions={person?.connectedTransactions?.data} />
    </DossierCard>
  );
};

export default Summary;
