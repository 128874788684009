import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Row, Typography, Select, Button, Form, Space } from 'antd';
import './styles.less';
import { RELATIONSHIPS, ENTITY_TYPES, EXCLUDE } from './constants';

import { countries } from '../../../../../../../../CompanySearching/components/FilterCompanies/countries';

const { Text } = Typography;

function getDefaultFormValues() {
  return {
    name: '',
    relationship: [],
    country: [],
    entity: [],
    riskFactor: [],
    exclude: [],
  };
}

const FormComponent = ({ visible, onSubmit, onClear }) => {
  const [form] = Form.useForm();
  const { t: translate, ready } = useTranslation('kyb-more-relationships');

  const handleClear = () => {
    form.setFieldsValue(getDefaultFormValues());
    onClear();
  };

  const handleSubmit = () => {
    onSubmit(form.getFieldsValue());
  };

  if (!ready) return null;

  if (!visible) {
    return null;
  }

  return (
    <div className="kyb-more-relationship-form-content">
      <Form form={form} layout="vertical" autoComplete="off" onFinish={handleSubmit}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div className="kyb-more-relationship-input-slider-container">
            <div className="kyb-more-relationship-input">
              <Text strong>{translate(`moreRelationships.form.name`)}</Text>
              <Form.Item label="" name="name">
                <Input />
              </Form.Item>
            </div>
          </div>
          <div className="kyb-more-relationship-selects-group">
            <div className="kyb-more-relationship-selects">
              <Space
                direction="horizontal"
                style={{
                  width: '100%',
                }}
              >
                <Form.Item label="" name="relationship">
                  <Select
                    dropdownMatchSelectWidth={400}
                    allowClear
                    className="kyb-more-relationship-select"
                    placeholder={translate(`moreRelationships.form.relationship`)}
                    mode="multiple"
                    optionFilterProp="label"
                    maxTagCount={1}
                    maxTagTextLength={15}
                    showArrow
                    options={
                      RELATIONSHIPS.map((value) => ({
                        label: translate(`moreRelationships.${value}`),
                        value,
                      })) || []
                    }
                  />
                </Form.Item>
                <Form.Item label="" name="country">
                  <Select
                    dropdownMatchSelectWidth={400}
                    className="kyb-more-relationship-select"
                    allowClear
                    placeholder={translate(`moreRelationships.form.country`)}
                    mode="multiple"
                    optionFilterProp="label"
                    showArrow
                    maxTagCount={1}
                    maxTagTextLength={15}
                    options={
                      countries.map((country) => ({
                        label: translate(`${country.label}`),
                        value: country.value,
                      })) || []
                    }
                  />
                </Form.Item>
                <Form.Item label="" name="entity">
                  <Select
                    dropdownMatchSelectWidth={400}
                    allowClear
                    className="kyb-more-relationship-select"
                    placeholder={translate(`moreRelationships.form.entity`)}
                    mode="multiple"
                    optionFilterProp="label"
                    showArrow
                    maxTagCount={1}
                    maxTagTextLength={15}
                    options={
                      ENTITY_TYPES.map((value) => ({
                        label: translate(`moreRelationships.${value}`),
                        value,
                      })) || []
                    }
                  />
                </Form.Item>
                <Form.Item label="" name="exclude">
                  <Select
                    dropdownMatchSelectWidth={400}
                    allowClear
                    autoClearSearchValue
                    className="kyb-more-relationship-select"
                    placeholder={translate(`moreRelationships.form.exclude`)}
                    mode="multiple"
                    optionFilterProp="label"
                    maxTagCount={1}
                    maxTagTextLength={10}
                    showArrow
                    options={
                      EXCLUDE.map((value) => ({
                        label: translate(`moreRelationships.form.${value}`),
                        value,
                      })) || []
                    }
                  />
                </Form.Item>
              </Space>
            </div>
          </div>
          <Row>
            <div className="kyb-more-relationship-actions">
              <Button type="link" onClick={handleClear}>
                {translate(`moreRelationships.form.clear`)}
              </Button>
              <Button type="primary" onClick={handleSubmit}>
                {translate(`moreRelationships.form.submit`)}
              </Button>
            </div>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default FormComponent;
