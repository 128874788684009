import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import api from 'services/api';

import { message } from 'antd';

const useFetch = ({ customApi } = {}) => {
  const { t: translate } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);

  api.defaults.baseURL = process.env.REACT_APP_BASE_URL_TRUST_API;

  const clearData = useCallback(() => {
    setData(undefined);
  }, []);

  const get = useCallback(
    async ({
      url,
      config,
      clearData: clearDataParam,
      showError = true,
      showMessage = true,
    }) => {
      try {
        if (clearDataParam) clearData();
        setError(undefined);
        setLoading(true);

        let response;

        if (customApi) {
          response = await customApi.get(url, config);
        } else {
          response = await api.get(url, config);
        }

        setData(response?.data);

        return Promise.resolve(response?.data);
      } catch (err) {
        if (err?.message === 'canceled') {
          return Promise.reject(err);
        }

        setError(err?.response?.data || true);

        const msg =
          err?.response?.status === 400 && showError
            ? `${err?.response?.data?.message}.`
            : translate('global.hooks.fetch.methods.get.error.message');

        if (showMessage) {
          message.error(msg);
        }

        return Promise.reject(err);
      } finally {
        setLoading(false);
      }
    },
    [clearData, customApi, translate]
  );

  const post = useCallback(
    async ({ url, payload, config, showError = true, showMessage = true }) => {
      try {
        setError(undefined);
        setLoading(true);

        let response;

        if (customApi) {
          response = await customApi.post(url, payload, config);
        } else {
          response = await api.post(url, payload, config);
        }

        setData(response?.data);

        return Promise.resolve(response?.data);
      } catch (err) {
        setError(err?.response?.data || true);

        const msg =
          (err?.response?.status === 400 || err?.response?.status === 403) && showError
            ? `${err?.response?.data?.message}`
            : translate('global.hooks.fetch.methods.post.error.message');

        if (showMessage) {
          message.error(msg);
        }

        return Promise.reject(err);
      } finally {
        setLoading(false);
      }
    },
    [customApi, translate]
  );

  const patch = useCallback(
    async ({ url, payload, config, showError }) => {
      try {
        setError(undefined);
        setLoading(true);

        const response = await api.patch(url, payload, config);
        setData(response?.data);

        return Promise.resolve(response?.data);
      } catch (err) {
        setError(err?.response?.data || true);

        const msg =
          err?.response?.status === 400 && showError
            ? `${err?.response?.data?.message}.`
            : translate('global.hooks.fetch.methods.patch.error.message');
        message.error(msg);

        return Promise.reject(err);
      } finally {
        setLoading(false);
      }
    },
    [translate]
  );

  const put = useCallback(
    async ({ url, payload, config, showError, showMessage = true }) => {
      try {
        setError(undefined);
        setLoading(true);

        const response = await api.put(url, payload, config);
        setData(response?.data);

        return Promise.resolve(response?.data);
      } catch (err) {
        setError(err?.response?.data || true);

        const msg =
          err?.response?.status === 400 && showError
            ? `${err?.response?.data?.message}.`
            : translate('global.hooks.fetch.methods.put.error.message');

        if (showMessage) message.error(msg);

        return Promise.reject(err);
      } finally {
        setLoading(false);
      }
    },
    [translate]
  );

  const _delete = useCallback(
    async ({ url, config, showError }) => {
      try {
        setError(undefined);
        setLoading(true);

        const response = await api.delete(url, config);
        setData(response?.data);

        return Promise.resolve(response?.data);
      } catch (err) {
        setError(err?.response?.data || true);

        const msg =
          err?.response?.status === 400 && showError
            ? `${err?.response?.data?.message}.`
            : translate('global.hooks.fetch.methods.delete.error.message');
        message.error(msg);

        return Promise.reject(err);
      } finally {
        setLoading(false);
      }
    },
    [translate]
  );

  return {
    data,
    error,
    loading,
    clearData,
    get,
    post,
    put,
    patch,
    delete: _delete,
  };
};

export default useFetch;
