/* eslint-disable react/prop-types */
import React from 'react';

import Infos from './Infos';

const BacenQgi = ({ source = {}, toPrint = false }) => {
  return <Infos source={source} toPrint={toPrint} />;
};

export default BacenQgi;
