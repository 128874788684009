import React from 'react';
import Wrapper from 'pages/private/wrapper';
import { useAuth } from 'hooks/auth';
import { CardWrapper } from 'components/CardWrapper';

import './styles.less';

const TabsWrapper = () => {
  const { user } = useAuth();

  return (
    <Wrapper style={{ width: '100%', height: '100%' }} customClassName="no-pdd">
      <CardWrapper style={{ height: '100%' }}>
        <div className="workflow-container">
          <iframe
            src={`${process.env.REACT_APP_WORKFLOW_BUILDER_URL}?tenantId=${user.tenantId}&clientCountry=${user?.accountData?.country}`}
            title="Workflows"
            frameBorder="0"
          />
        </div>
      </CardWrapper>
    </Wrapper>
  );
};

export default TabsWrapper;
