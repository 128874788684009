import React from 'react';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CompanyRow,
  CompanyComment,
} from 'pages/private/KYBCompany/CompanyDetailsV2/components';
import PropTypes from 'prop-types';
import TabTitle from 'pages/private/KYBCompany/CompanyDetails/components/TabTitle';
import { BulletListText } from '@combateafraude/icons/builder';
import './styles.less';

const I18N_BASE_PATH = 'pages.private.KYBCompany.pages';
const SUMMARY = `${I18N_BASE_PATH}.companyDetails.subPages.summary`;
const COMMENTS = `${SUMMARY}.cards.comments`;

const Comments = ({ comments, itemsToExport, disabled, print = false }) => {
  const { t: translate } = useTranslation();

  if (disabled || itemsToExport?.value === false) {
    return null;
  }

  return (
    <section className="kyb-company-comments-main">
      <TabTitle
        newIcon={<BulletListText fontSize={20} />}
        title={translate(`${COMMENTS}.title`, 'Comments')}
        render={!print}
      />
      <div>
        <CompanyRow className="text-dark mrg-btm-10">
          <Col span={2}>
            <b>{translate(`${COMMENTS}.score`)}</b>
          </Col>
          <Col span={22}>
            <b>{translate(`${COMMENTS}.description`)}</b>
          </Col>
        </CompanyRow>
        <CompanyRow>
          {comments?.map((c) => (
            <CompanyComment type={c?.indicator} text={c?.text} col={[2, 22]} />
          ))}
        </CompanyRow>
      </div>
    </section>
  );
};

Comments.propTypes = {
  comments: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  itemsToExport: PropTypes.object.isRequired,
  print: PropTypes.bool.isRequired,
};

Comments.defaultProps = {
  disabled: false,
};

export default Comments;
