import { Button, Col, Row } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DottedLabel from 'components/DottedLabel';
import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';

import useDriverInfosDetails from './DriverInfosDetailsModal';
import './styles.less';

const approvedStatus = ['CONFIRMADA', 'EMITIDA'];

const I18N_BASE_PATH = 'components.dataSourceCards.denatranDriverInfos';
function DriverInfosCollapsePanel({ driver = {}, toPrint = false }) {
  const { t: translate } = useTranslation();
  const { openModal, DriverInfosDetailsModal } = useDriverInfosDetails();

  const customHeader = useMemo(() => {
    const type = approvedStatus.includes(driver?.cnhStatus?.toString()?.toUpperCase())
      ? 'primary'
      : 'danger';
    const description = (
      <span className="pdd-left-10">
        {approvedStatus.includes(driver?.cnhStatus?.toString()?.toUpperCase())
          ? translate(`${I18N_BASE_PATH}.issuedCNH`)
          : translate(`${I18N_BASE_PATH}.notIssuedCNH`)}
      </span>
    );

    return (
      <Row justify="space-between" style={{ width: '100%' }}>
        <Col span={18}>
          <i className="caf-ic_person" />
          <span className="text-dark text-bold pdd-left-10">
            {translate(`${I18N_BASE_PATH}.title`)}
          </span>
        </Col>
        <Col>
          <DottedLabel description={description} type={type} />
        </Col>
      </Row>
    );
  }, [driver, translate]);

  return (
    <>
      {DriverInfosDetailsModal}
      <DataSourceCardCollapsePanel
        className="denatran-driver-infos"
        customHeader={customHeader}
        toPrint={toPrint}
      >
        <Row justify="space-between">
          <ColumnTitleValue
            className="inline-infos"
            title={`${translate(`${I18N_BASE_PATH}.columns.cnhNumber`)}:`}
            value={driver?.cnhNumber || '-'}
          />
          <ColumnTitleValue
            span={10}
            className="inline-infos"
            title={`${translate(`${I18N_BASE_PATH}.columns.cnhExpirationDate`)}:`}
            value={driver?.cnhExpirationDate || '-'}
          />
          <ColumnTitleValue
            span={6}
            className="inline-infos"
            title={`${translate(`${I18N_BASE_PATH}.columns.cnhCategory`)}:`}
            value={driver?.cnhCategory || '-'}
          />
        </Row>
        <Row className="mrg-top-10">
          <ColumnTitleValue
            span={10}
            className="inline-infos"
            title={`${translate(`${I18N_BASE_PATH}.columns.cnhIssuerAgency`)}:`}
            value={driver?.cnhIssuerAgency || '-'}
          />
          <ColumnTitleValue
            span={12}
            className="inline-infos"
            title={`${translate(`${I18N_BASE_PATH}.columns.driverFirstLicenseDate`)}:`}
            value={driver?.driverFirstLicenseDate || '-'}
          />
        </Row>
        <Row className="mrg-top-10">
          <Button type="link" onClick={() => openModal(driver)}>
            <i className="caf-ic_arrow-right" />
            <span className="mrg-left-10">{`${translate(
              `${I18N_BASE_PATH}.columns.moreDetails`
            )}`}</span>
          </Button>
        </Row>
      </DataSourceCardCollapsePanel>
    </>
  );
}

export default DriverInfosCollapsePanel;
