import React from 'react';

import { nadaConstaLaborDebtsTranslation } from 'utils/personalTranslations';

export const parseLaborDebtsNadaConsta = (originalObject) => {
  const nadaConstaStatus = originalObject?.data?.status;

  const translatedStatus = nadaConstaStatus
    ? nadaConstaLaborDebtsTranslation[nadaConstaStatus] ||
      nadaConstaLaborDebtsTranslation.NULO
    : nadaConstaLaborDebtsTranslation.NULO;

  return { ...originalObject, translatedStatus };
};

export const parseLaborDebtsContent = (originalObject) => {
  const certificateValidity = originalObject?.data?.certificateValidity;
  const certificateNumber = originalObject?.data?.certificateNumber;

  if (certificateNumber && certificateValidity)
    return (
      <div className="flex-column">
        <span>{certificateNumber}</span>
        <span>{certificateValidity}</span>
      </div>
    );
  if (certificateNumber) return `${certificateNumber}`;
  if (certificateValidity) return `${certificateValidity}`;

  return '-';
};
