import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Button, Divider, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';

import { CardSkeleton, CardTooltip } from 'components/CardSkeleton';
import { useHeaderFilter } from 'hooks/headerFilter';
import { useFetch } from 'services/hooks';

import VerticalFunnel from './components/VerticalFunnel';
import Table from './components/Table';

import Wrapper from '../wrapper';

import './styles.less';

const OnboardingStatistics = () => {
  const { t: translate } = useTranslation();
  const { pfdPrintRef, startDate, endDate } = useHeaderFilter();
  const history = useHistory();
  const { search } = useLocation();

  const [funnelFlows, setFunnelFlows] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState();
  const [query, setQuery] = useQueryParams({
    funnelTemplateId: StringParam,
    activeFunnelFlow: StringParam,
  });

  const {
    get: getOnboardingStatistics,
    data: onboardingStatistics,
    loading: onboardingStatisticsLoading,
  } = useFetch();

  const { get: getTemplates, data: templates, loading: templatesLoading } = useFetch();
  const {
    get: getTemplateById,
    data: templateById,
    loading: templateByIdLoading,
  } = useFetch();

  const handleSearchChange = useCallback(
    _.debounce((value) => {
      getTemplates({
        url: '/profile-templates',
        config: {
          params: {
            _search: value ? value.trim() : undefined,
          },
        },
        showMessage: false,
      });
    }, 500),
    [getTemplates]
  );

  const fetchTemplateById = useCallback(async () => {
    if (query.funnelTemplateId) {
      await getTemplateById({
        url: `/profile-templates/${query.funnelTemplateId}`,
        showMessage: false,
      });
    }
  }, [getTemplateById, query.funnelTemplateId]);

  const fetchOnboardingStatistics = useCallback(async () => {
    if (startDate && endDate)
      await getOnboardingStatistics({
        url: '/analytics/onboarding-statistics',
        config: {
          params: {
            _startCreatedDate: startDate,
            _endCreatedDate: endDate,
            profileTemplateId: query.funnelTemplateId,
          },
        },
        showMessage: false,
      });
  }, [endDate, getOnboardingStatistics, query.funnelTemplateId, startDate]);

  useEffect(fetchTemplateById, [fetchTemplateById]);
  useEffect(handleSearchChange, [handleSearchChange]);
  useEffect(fetchOnboardingStatistics, [fetchOnboardingStatistics]);

  const normalizedOnboardingFunnel = useMemo(() => {
    if (!onboardingStatistics?.onboardingsSteps) return [];

    const { onboardingsSteps } = onboardingStatistics;

    const funnelFlowKey = query.activeFunnelFlow ?? 'flowDefault';

    const funnelFlow = onboardingsSteps[funnelFlowKey];

    if (!funnelFlow) return [];

    return Object.keys(funnelFlow)
      .sort(
        (keyA, keyB) =>
          onboardingsSteps[funnelFlowKey][keyA].order -
          onboardingsSteps[funnelFlowKey][keyB].order
      )
      .map((key) => ({
        value: onboardingsSteps[funnelFlowKey][key].quantity,
        step: onboardingsSteps[funnelFlowKey][key].step,
      }));
  }, [onboardingStatistics, query.activeFunnelFlow]);

  const funnelSearchOptions = useMemo(() => {
    if (templates?.docs.length > 0) {
      return templates.docs.map((doc) => ({
        label: doc.name,
        value: doc._id,
      }));
    }

    if (templateById) {
      return [
        {
          label: templateById.name,
          value: templateById._id,
        },
      ];
    }

    return [];
  }, [templates, templateById]);

  const normalizedOnboardingStatistics = useMemo(() => {
    if (!onboardingStatistics?.onboardingsLinks) return {};

    return onboardingStatistics.onboardingsLinks;
  }, [onboardingStatistics]);

  useEffect(() => {
    if (onboardingStatistics?.onboardingsSteps) {
      const { onboardingsSteps } = onboardingStatistics;

      const flows = Object.keys(onboardingsSteps).filter((key) => key !== 'requestId');

      setFunnelFlows(flows);

      if (flows.length === 1) {
        setQuery({
          activeFunnelFlow: undefined,
        });
      } else if (!query.activeFunnelFlow)
        setQuery({
          activeFunnelFlow: flows[0],
        });
    }
  }, [onboardingStatistics, setQuery, query]);

  const normalizedTableData = useMemo(() => {
    if (!onboardingStatistics?.onboardingsSteps) return [];

    const { onboardingsSteps } = onboardingStatistics;

    const funnelFlowKey = query.activeFunnelFlow ?? 'flowDefault';

    const funnelFlow = onboardingsSteps[funnelFlowKey];

    if (!funnelFlow) return [];

    function formatTime(seconds) {
      seconds = Number(seconds);

      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const sec = Math.round(seconds % 60);

      let formattedTime = '';

      if (hours > 0) {
        formattedTime += `${hours} ${translate(
          'pages.private.onboardingStatistics.table.actions.time.hours',
          'h'
        )} `;
      }

      if (minutes > 0) {
        formattedTime += `${minutes} ${translate(
          'pages.private.onboardingStatistics.table.actions.time.minutes',
          'min'
        )} `;
      }

      if (sec > 0) {
        formattedTime += `${sec} ${translate(
          'pages.private.onboardingStatistics.table.actions.time.seconds',
          'sec'
        )}`;
      }

      return formattedTime.trim();
    }

    return Object.keys(funnelFlow)
      .sort(
        (keyA, keyB) =>
          onboardingsSteps[funnelFlowKey][keyA].order -
          onboardingsSteps[funnelFlowKey][keyB].order
      )
      .map((key) => {
        const step = onboardingsSteps[funnelFlowKey][key];

        return {
          id: step._id,
          users: step.quantity,
          dropouts: step.desistence,
          quitPercentage: step.desistenceTax,
          averageTime: step.avgSla > 0 ? formatTime(step.avgSla) : '-',
          actions: {
            value: step.actions.reduce(
              (acc, action) => (action ? acc + action.quantity : acc),
              0
            ),
            details: step.actions
              .filter((a) => !!a)
              .map(({ action, quantity, percent }) => ({
                detail: translate(
                  `pages.private.onboardingStatistics.table.actions.details.${action}`
                ),
                value: quantity,
                percentage: percent,
              })),
          },
        };
      });
  }, [onboardingStatistics, query.activeFunnelFlow, translate]);

  const handleHover = useCallback(
    (index) => {
      setHoveredIndex(index);
    },
    [setHoveredIndex]
  );

  const haveNoData = useMemo(() => {
    return (
      normalizedOnboardingFunnel?.length === 0 &&
      normalizedTableData?.length === 0 &&
      normalizedOnboardingStatistics?.generated === 0 &&
      normalizedOnboardingStatistics?.finalized === 0 &&
      normalizedOnboardingStatistics?.accessed === 0 &&
      normalizedOnboardingStatistics?.abandoned === 0 &&
      normalizedOnboardingStatistics?.rejectionTax === '0%'
    );
  }, [normalizedOnboardingStatistics, normalizedTableData, normalizedOnboardingFunnel]);

  return (
    <Wrapper>
      <div style={{ width: '100%' }} ref={pfdPrintRef}>
        <CardSkeleton
          fixedHeight={false}
          size="fullSize"
          title="Modelo de Consulta selecionado:"
          loading={onboardingStatisticsLoading}
          noData={haveNoData}
          headerEndAdornment={
            <div className="header-adornment">
              <Select
                size="large"
                loading={templatesLoading || templateByIdLoading}
                showSearch
                placeholder={translate(
                  'pages.private.profileModels.profileModelList.index.profileModelList.form.profileModel.placeholder'
                )}
                onChange={(tempalteId) => setQuery({ funnelTemplateId: tempalteId })}
                value={query.funnelTemplateId}
                searchValue={undefined}
                onSearch={handleSearchChange}
                options={funnelSearchOptions}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionFilterProp="children"
                style={{
                  minWidth: 150,
                  marginRight: '0.8rem',
                  color: '#004af7',
                  fontWeight: 'bold',
                }}
                bordered={false}
              />
              <Button
                id="click_back_to_dashboard"
                type="primary"
                size="small"
                onClick={() => history.push(`/dashboard${search}`)}
              >
                {translate('pages.private.onboardingStatistics.backToDashboard')}
              </Button>
            </div>
          }
        >
          <div className="content-container">
            {funnelFlows?.length > 1 && (
              <div className="flow-buttons-container">
                {funnelFlows.map((flow) => (
                  <Button
                    type={flow === query.activeFunnelFlow ? 'primary' : 'default'}
                    size="small"
                    onClick={() => setQuery({ activeFunnelFlow: flow })}
                  >
                    {flow === 'flowDefault'
                      ? translate(
                          `pages.private.dashboard.index.charts.onboardingFunnel.flowDefault`
                        )
                      : flow}
                  </Button>
                ))}
                <div style={{ width: '100%' }}>
                  <Divider />
                </div>
              </div>
            )}
            {normalizedOnboardingStatistics && (
              <header>
                <div>
                  <h5>
                    {translate(
                      'pages.private.onboardingStatistics.header.generated.title'
                    )}
                    <span>
                      <CardTooltip
                        message={translate(
                          'pages.private.onboardingStatistics.header.generated.tooltip'
                        )}
                      />
                    </span>
                  </h5>
                  <span>{normalizedOnboardingStatistics.generated}</span>
                </div>
                <div>
                  <h5>
                    {translate(
                      'pages.private.onboardingStatistics.header.rejectionTax.title'
                    )}
                    <span>
                      <CardTooltip
                        message={translate(
                          'pages.private.onboardingStatistics.header.rejectionTax.tooltip'
                        )}
                      />
                    </span>
                  </h5>
                  <span>{normalizedOnboardingStatistics.rejectionTax}</span>
                </div>
                <div>
                  <h5>
                    {translate(
                      'pages.private.onboardingStatistics.header.finished.title'
                    )}
                    <span>
                      <CardTooltip
                        message={translate(
                          'pages.private.onboardingStatistics.header.finished.tooltip'
                        )}
                      />
                    </span>
                  </h5>
                  <span>{normalizedOnboardingStatistics.finalized}</span>
                </div>
                <div>
                  <h5>
                    {translate(
                      'pages.private.onboardingStatistics.header.abandoned.title'
                    )}
                    <span>
                      <CardTooltip
                        message={translate(
                          'pages.private.onboardingStatistics.header.abandoned.tooltip'
                        )}
                      />
                    </span>
                  </h5>
                  <span>{normalizedOnboardingStatistics.abandoned}</span>
                </div>
              </header>
            )}
            <Divider />
            <main
              style={{
                height: normalizedOnboardingFunnel
                  ? normalizedOnboardingFunnel.length * 45 + 100
                  : undefined,
              }}
            >
              {normalizedOnboardingFunnel?.length > 0 && (
                <div>
                  <VerticalFunnel
                    data={normalizedOnboardingFunnel}
                    handleHover={handleHover}
                    hoveredIndex={hoveredIndex}
                  />
                </div>
              )}
              {normalizedTableData?.length > 0 && (
                <div>
                  <Table
                    data={normalizedTableData}
                    handleHover={handleHover}
                    hoveredIndex={hoveredIndex}
                  />
                </div>
              )}
            </main>
          </div>
        </CardSkeleton>
      </div>
    </Wrapper>
  );
};

export default OnboardingStatistics;
