import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

const I18N_BASE_PATH = 'components.dataSourceCards.restrictiveLists.utils.translator';
export const motiveTranslator = (motive) => {
  if (!motive || motive === 'OTHER') return null;

  const familyRelationship = [
    'MOTHER',
    'FATHER',
    'BROTHER',
    'GRANDSON',
    'GRANDPARENT',
    'SON',
    'NEPHEW',
    'SPOUSE',
    'UNCLE',
    'COUSIN',
  ];

  if (motive === 'STATE EMPLOYEE') {
    return `${I18N_BASE_PATH}.motiveTranslator.motives.STATE_EMPLOYEE`;
  }

  if (motive === 'CITY EMPLOYEE') {
    return `${I18N_BASE_PATH}.motiveTranslator.motives.CITY_EMPLOYEE`;
  }

  if (motive === 'FEDERAL EMPLOYEE') {
    return `${I18N_BASE_PATH}.motiveTranslator.motives.FEDERAL_EMPLOYEE`;
  }

  if (familyRelationship.includes(motive) || motive === 'RELATIVE') {
    return `${I18N_BASE_PATH}.motiveTranslator.motives.RELATIVE`;
  }

  return motive;
};

export const familyLevelTranslator = (motive) => {
  if (!motive || motive === 'OTHER') return null;
  const familyRelationship = [
    'MOTHER',
    'FATHER',
    'BROTHER',
    'GRANDSON',
    'GRANDPARENT',
    'SON',
    'NEPHEW',
    'SPOUSE',
    'UNCLE',
    'COUSIN',
  ];

  if (familyRelationship.includes(motive)) {
    return `${I18N_BASE_PATH}.familyLevelTranslator.${motive.toUpperCase()}`;
  }

  return capitalizeFirstLetter(motive);
};
