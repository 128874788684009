/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { toMaskedCep, toMaskedCpf } from 'utils/formatters';

import EmptyExecutionState from 'components/EmptyExecutionState';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

import './styles.less';

const I18N_BASE_PATH = 'components.dataSourceCards.basicInfos.components';

const PfAddressAuthentication = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  return (
    <DataSourceCardCollapsePanel
      customId="criminal-background-component"
      title={translate(
        `${I18N_BASE_PATH}.basicInfosCard.sources.pfAddressAuthentication`
      )}
      icon="caf-ic_map_pin"
      toPrint={toPrint}
    >
      <Row className="address-authentication">
        {parsedData && (
          <>
            {!queryWorked ? (
              <EmptyExecutionState
                className="mrg-top-10"
                statusCode={statusCode}
                message={parsedData?.message}
              />
            ) : (
              <>
                <Col span={8}>
                  <span>
                    <strong>
                      {translate(`${I18N_BASE_PATH}.addressAuthentication.fields.cpf`)}:{' '}
                    </strong>
                    {toMaskedCpf(parsedData?.cpf)}
                  </span>
                </Col>
                <Col span={8}>
                  <span>
                    <strong>
                      {translate(
                        `${I18N_BASE_PATH}.addressAuthentication.fields.zipCode`
                      )}
                      :{' '}
                    </strong>
                    {toMaskedCep(parsedData?.zipCode)}
                  </span>
                </Col>
                <Col span={8}>
                  <span>
                    <strong>
                      {translate(
                        `${I18N_BASE_PATH}.addressAuthentication.fields.phoneNumber`
                      )}
                      :{' '}
                    </strong>
                    {parsedData?.phoneNumber}
                  </span>
                </Col>
                <Col span={8} className="mrg-top-20">
                  <span>
                    <strong>
                      {translate(`${I18N_BASE_PATH}.addressAuthentication.fields.score`)}:{' '}
                    </strong>
                    {parsedData?.score}
                  </span>
                </Col>
                <Col span={8} className="mrg-top-20">
                  <span>
                    <strong>
                      {translate(
                        `${I18N_BASE_PATH}.addressAuthentication.fields.flagScore`
                      )}
                      :{' '}
                    </strong>
                    {parsedData?.flagScore}
                  </span>
                </Col>
                <Col span={22} className="mrg-top-20">
                  <span>
                    <strong>
                      {translate(
                        `${I18N_BASE_PATH}.addressAuthentication.fields.flagScoreDescription`
                      )}
                      :{' '}
                    </strong>
                    {parsedData?.flagScoreDescription}
                  </span>
                </Col>
              </>
            )}
          </>
        )}
      </Row>
    </DataSourceCardCollapsePanel>
  );
};

export default PfAddressAuthentication;
