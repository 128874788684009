const languages = [
  {
    value: 'pt-BR',
    title: 'Português (Brasil)',
  },
  {
    value: 'en-US',
    title: 'English',
  },
  {
    value: 'es-MX',
    title: 'Spanish',
  },
];

export default languages;
