/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, useParams, Link } from 'react-router-dom';

import { Row, Col, Form, Select, message } from 'antd';

import { useFetch } from 'services/hooks';
import { useCompany } from 'hooks/company';
import { usePerson } from 'hooks/person';

import Button from 'components/Button';
import EmptyMessage from 'components/EmptyMessage';

import PermissionWrapper from 'routes/PermissionWrapper';

import recurrenceCalendar from 'assets/images/recurrence-calendar.svg';

import './styles.less';

const { Option } = Select;

const NotFoundContent = () => {
  const { t: translate } = useTranslation();

  return (
    <EmptyMessage
      type="empty"
      description={translate(
        'pages.private.profiles.components.emptyRecurrence.index.notFoundContent.description'
      )}
      style={{ padding: 0 }}
    />
  );
};

const EmptyRecurrence = ({ refreshField }) => {
  const { t: translate } = useTranslation();

  const [form] = Form.useForm();

  const { get: getProfileTemplates, loading: loadingGetProfileTemplates } = useFetch();
  const {
    patch: applyProfileTemplate,
    loading: loadingApplyProfileTemplate,
  } = useFetch();

  const matchPeople = useRouteMatch('/profile/people');
  const matchCompany = useRouteMatch('/profile/companies');

  const { companyId, personId } = useParams();

  const { refreshCompany } = useCompany();
  const { refreshPerson } = usePerson();

  const [recurrenceValue, setProfileTemplateValue] = useState('');
  const [recurrenceList, setRecurrenceList] = useState([]);

  const fetch = useCallback(async () => {
    const response = await getProfileTemplates({
      url: companyId
        ? `/profile-templates?type=${translate(
            'pages.private.profiles.components.emptyRecurrence.index.type.company'
          )}`
        : `/profile-templates?type=${translate(
            'pages.private.profiles.components.emptyRecurrence.index.type.person'
          )}`,
      config: {
        params: {
          _limit: 100,
          active: true,
        },
      },
      showMessage: false,
    });

    setRecurrenceList(response?.docs);
  }, [getProfileTemplates, companyId, translate]);

  const firstLoad = useRef(true);
  useEffect(() => {
    if (!firstLoad.current) return;
    firstLoad.current = false;

    fetch();
  }, [fetch]);

  useEffect(() => {
    if (refreshField) {
      setProfileTemplateValue('');
      form.resetFields();
    }
  }, [refreshField]); // eslint-disable-line

  const handleSubmitForm = useCallback(
    async (payload) => {
      await applyProfileTemplate({
        url: companyId ? `companies/${companyId}` : `people/${personId}`,
        payload,
        showError: false,
      });

      message.success(
        translate(
          'pages.private.profiles.components.emptyRecurrence.index.handleSubmitForm.message.success'
        )
      );

      if (companyId) {
        refreshCompany();
      }
      if (personId) {
        refreshPerson();
      }
    },
    [applyProfileTemplate, companyId, personId, refreshCompany, refreshPerson, translate]
  );

  const permissions = useMemo(() => {
    if (matchPeople) {
      return ['people:update'];
    }
    if (matchCompany) {
      return ['companies:update'];
    }
    return [];
  }, [matchPeople, matchCompany]);

  return (
    <Row id="empty-recurrence-component">
      <Col span={10} className="img-container">
        <img className="grayscale-95" src={recurrenceCalendar} alt="Calendário" />
      </Col>
      <Col span={14} className="apply-profile-model-container">
        <strong>
          {translate(
            'pages.private.profiles.components.emptyRecurrence.index.emptyRecurrence.title'
          )}
        </strong>

        <PermissionWrapper requiredPermissions={permissions}>
          <span className="apply-profile-model-description">
            {translate(
              'pages.private.profiles.components.emptyRecurrence.index.emptyRecurrence.subtitle'
            )}
          </span>

          <Form form={form} layout="vertical" onFinish={handleSubmitForm}>
            <Form.Item
              name="profileTemplateId"
              label={translate(
                'pages.private.profiles.components.emptyRecurrence.index.emptyRecurrence.form.profileTemplateId.label'
              )}
            >
              <Select
                bordered={false}
                className="custom-search-input text-dark"
                placeholder={translate(
                  'pages.private.profiles.components.emptyRecurrence.index.emptyRecurrence.form.profileTemplateId.select.placeholder'
                )}
                value={recurrenceValue}
                loading={loadingGetProfileTemplates}
                notFoundContent={<NotFoundContent />}
                onChange={(value) => setProfileTemplateValue(value)}
              >
                {recurrenceList?.map((recurrence) => (
                  <Option key={recurrence._id} value={recurrence._id}>
                    {recurrence.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <span className="add-profile-model">
              {translate(
                'pages.private.profiles.components.emptyRecurrence.index.emptyRecurrence.form.link.before'
              )}{' '}
              <Link
                to={`/profile-templates/profile-models/registration?type=${
                  companyId
                    ? translate(
                        'pages.private.profiles.components.emptyRecurrence.index.type.company'
                      )
                    : translate(
                        'pages.private.profiles.components.emptyRecurrence.index.type.person'
                      )
                }`}
              >
                {translate(
                  'pages.private.profiles.components.emptyRecurrence.index.emptyRecurrence.form.link.inside'
                )}
              </Link>{' '}
              {translate(
                'pages.private.profiles.components.emptyRecurrence.index.emptyRecurrence.form.link.after.default'
              )}{' '}
              {translate(
                `pages.private.profiles.components.emptyRecurrence.index.emptyRecurrence.form.link.after.${
                  companyId ? 'company' : 'person'
                }`
              )}
              .
            </span>

            <Button
              type="primary"
              htmlType="submit"
              className="apply-btn"
              disabled={loadingApplyProfileTemplate || !recurrenceValue}
            >
              <span>
                {translate(
                  `pages.private.profiles.components.emptyRecurrence.index.emptyRecurrence.form.button.${
                    loadingApplyProfileTemplate ? 'loading' : 'default'
                  }`
                )}
              </span>
            </Button>
          </Form>
        </PermissionWrapper>
      </Col>
    </Row>
  );
};

export default EmptyRecurrence;
