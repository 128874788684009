import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import ViewMore from 'components/ViewMore';
import EmptyState from 'components/EmptyState';
import EmptyExecutionState from 'components/EmptyExecutionState';

import ContactAndRelationCard from 'pages/private/Profiles/components/ContactAndRelationCard';

import useAllPersonalAndEconomicRelationships from './AllPersonalAndEconomicRelationships';

import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.laborInformation.people.components.businessRelationships';

const PfBusinessRelationships = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const businnesAndPersonalData = useMemo(() => {
    return source?.data?.businessRelationships;
  }, [source]);

  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const {
    openModal,
    AllPersonalAndEconomicRelationshipsModal,
  } = useAllPersonalAndEconomicRelationships({ data: businnesAndPersonalData });

  const parsedList = useMemo(() => {
    if (!businnesAndPersonalData || !businnesAndPersonalData?.length > 0) return <></>;

    const el = [];
    const _size = businnesAndPersonalData?.length;

    if (_size) {
      for (let i = 0; i < _size; i += 2) {
        el.push(
          <Row className="contact-data-row">
            {businnesAndPersonalData?.slice(i, i + 2)?.map((relationship) => (
              <ContactAndRelationCard
                key={relationship?.entityName || relationship?.relatedEntityName}
                span={24}
                relation
                name={relationship?.entityName || relationship?.relatedEntityName}
                type={relationship?.relationshipType}
                level={relationship?.relationshipLevel}
                taxIdNumber={relationship?.relatedEntityTaxIdNumber}
                startDate={relationship?.relationshipStartDate}
                endDate={relationship?.relationshipEndDate}
                lastUpdateDate={relationship?.lastUpdateDate}
                className="mrg-top-10 pdd-horizontal-0"
              />
            ))}
          </Row>
        );
      }
    }

    if (el.length > 0) {
      const parsedEl = toPrint ? el : el.splice(0, 1);
      return parsedEl;
    }

    return <></>;
  }, [businnesAndPersonalData, toPrint]);

  return (
    <>
      {AllPersonalAndEconomicRelationshipsModal}

      <DataSourceCardCollapsePanel
        title={translate(`${I18N_BASE_PATH}.title`)}
        customId="labor-infos-component"
        icon="caf-ic_awards"
        toPrint={toPrint}
      >
        {queryWorked ? (
          <>
            {businnesAndPersonalData && !businnesAndPersonalData?.length > 0 && (
              <EmptyState
                type="empty-success"
                description={translate(`${I18N_BASE_PATH}.emptyState.description`)}
              />
            )}

            {parsedList}

            {!toPrint && businnesAndPersonalData?.length > 2 && (
              <Row className="mrg-btm-10">
                <ViewMore
                  title={translate(`${I18N_BASE_PATH}.viewMore.title`)}
                  type="link"
                  onClick={() => openModal()}
                />
              </Row>
            )}
          </>
        ) : (
          <EmptyExecutionState
            statusCode={statusCode}
            message={source?.message || source?.data?.message}
          />
        )}
      </DataSourceCardCollapsePanel>
    </>
  );
};

export default PfBusinessRelationships;
