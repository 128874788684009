/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import EmptyExecutionState from 'components/EmptyExecutionState';
import ViewMore from 'components/ViewMore';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import SPCProgress from './SPCProgress';
import SPCInfos from './SPCInfos';
import useSPCDetails from './SPCDetailsModal';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

import './styles.less';

const SPC = ({ source = {}, toPrint = false, toPrintDetails }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const { openModal, SPCDetailsModal } = useSPCDetails({
    data: parsedData,
  });

  const isValidScore = useMemo(() => {
    if (!parsedData?.spcScore12Months?.score) return false;

    const formattedScore = Number(parsedData?.spcScore12Months?.score);

    if (formattedScore >= 0 && formattedScore <= 1000) {
      return true;
    }

    return false;
  }, [parsedData]);

  return (
    <>
      {SPCDetailsModal}

      <DataSourceCardCollapsePanel
        title={translate(
          'components.dataSourceCards.financialInformation.shared.spc.index.spc.columnLabel.title'
        )}
        icon="caf-ic_company"
        toPrint={toPrint}
        customId="financialInfo"
      >
        <ColumnLabel
          span={24}
          info={
            !toPrint &&
            translate(
              'components.dataSourceCards.financialInformation.shared.spc.index.spc.columnLabel.info'
            )
          }
        >
          {queryWorked ? (
            <>
              {isValidScore && <SPCProgress data={parsedData?.spcScore12Months?.score} />}

              <SPCInfos data={parsedData} />

              {!toPrint && parsedData?.details?.ocurrences?.length > 0 && (
                <Row>
                  <ViewMore
                    type="link"
                    title={translate(
                      'components.dataSourceCards.financialInformation.shared.spc.index.spc.columnLabel.viewMore.title.hasToPrintDetails'
                    )}
                    onClick={() => openModal()}
                  />
                </Row>
              )}

              {toPrint && !toPrintDetails && parsedData?.details?.ocurrences?.length > 0 && (
                <Row>
                  <ViewMore
                    text
                    type="link"
                    title={translate(
                      'components.dataSourceCards.financialInformation.shared.spc.index.spc.columnLabel.viewMore.title.hasNotToPrintDetails',
                      { value: parsedData?.details?.ocurrences?.length }
                    )}
                  />
                </Row>
              )}
            </>
          ) : (
            <EmptyExecutionState
              statusCode={statusCode}
              message={source?.message || parsedData?.message}
            />
          )}
        </ColumnLabel>
      </DataSourceCardCollapsePanel>
    </>
  );
};

export default SPC;
