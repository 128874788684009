import React from 'react';
import { Card, Typography, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import AttemptsLimitIcon from 'assets/images/attempts-limit-card.svg';

import { useSecurityOptions } from 'hooks/useSecurityOptions';

import SecurityAndPrivacyWrapper from '../index';
import ConfigurationCard from './components/ConfigurationCard';

const { Title } = Typography;
const I18N_BASE_PATH = 'pages.private.securityAndPrivacy.livenessConfiguration';

const LivenessConfiguration = () => {
  const { t: translate } = useTranslation();

  const {
    doPatchMaxNumberOfAttempts,
    maxNumberOfAttempts,
    setMaxNumberOfAttempts,
    loading,
  } = useSecurityOptions();

  return (
    <SecurityAndPrivacyWrapper>
      <Card style={{ width: '100%' }}>
        <div>
          <Title level={3}>{translate(`${I18N_BASE_PATH}.index.title`)}</Title>
          <Title level={5}>{translate(`${I18N_BASE_PATH}.index.subtitle`)}</Title>
        </div>
        <Col>
          <ConfigurationCard
            loading={loading}
            onChange={setMaxNumberOfAttempts}
            inputValue={maxNumberOfAttempts}
            title={translate(`${I18N_BASE_PATH}.index.description`)}
            icon={{ src: AttemptsLimitIcon, alt: 'Attempts limit icon' }}
            buttonText={translate(`${I18N_BASE_PATH}.index.buttonText`)}
            handleSave={doPatchMaxNumberOfAttempts}
          />
        </Col>
      </Card>
    </SecurityAndPrivacyWrapper>
  );
};

export default LivenessConfiguration;
