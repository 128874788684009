// LinkPartnerModal.jsx

import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Col, Form, Input, message, Modal, Row, Spin } from 'antd';

import Button from 'components/Button';
import PageTitle from 'components/PageTitle';
import { useCompany } from 'hooks/company';

import { useFetch } from 'services/hooks';
import { validateCpf } from 'utils/validations';
import { useTranslation } from 'react-i18next';
import ConfirmModal from './ConfirmLinkPartnerModal';
import PartnerCard from './PartnerCard';

import './styles.less';

const LinkPartnerModal = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [partner, setPartner] = useState(null);
  const [partnerNamePjData, setPartnerNamePjData] = useState(null);
  const { t: translate } = useTranslation();

  const { company, refreshCompany } = useCompany();
  const { get, loading, post } = useFetch();

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback((teste) => {
    setPartnerNamePjData(teste);
    setModalVisible(true);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmVisible(false);
  }, []);

  const openConfirmModal = useCallback(() => {
    setConfirmVisible(true);
  }, []);

  const handleConfirm = useCallback(() => {
    openConfirmModal();
  }, [openConfirmModal]);

  const handleSend = useCallback(async () => {
    const options = {
      url: 'profile-relationships',
      payload: {
        cpf: partner?.cpf,
        cnpj: company?.cnpj,
        partnerNamePjData,
      },
    };

    await post(options);

    message.success(
      translate(
        'pages.private.profiles.components.tabs.components.basicInfos.components.company.linkPartnerModal.success',
        'Action performed successfully'
      )
    );
    refreshCompany();
    closeConfirmModal();
    closeModal();
  }, [closeConfirmModal, closeModal, company, partner, post, refreshCompany, translate]);

  const handleSearch = useCallback(
    async (value) => {
      setPartner(null);

      if (!value?.length) {
        setHasError(true);
        setErrorMessage();
        return;
      }

      const isValidCPF = validateCpf(value);

      if (!isValidCPF) {
        setHasError(true);
        setErrorMessage(
          translate(
            'pages.private.profiles.components.tabs.components.basicInfos.components.company.linkPartnerModal.invalidCPF',
            'Invalid person ID'
          )
        );
        return;
      }

      setHasError(false);
      setErrorMessage(null);

      const data = await get({
        url: '/people',
        config: {
          params: {
            _search: value?.toString()?.replace(/\D/g, ''),
            _limit: 1,
          },
        },
      });

      if (data?.docs?.[0]) {
        setPartner(data?.docs?.[0]);
      }
    },
    [get, translate]
  );

  const ModalLinkPartner = useMemo(
    () => (
      <>
        <Modal
          visible={modalVisible}
          wrapClassName="caf-modal"
          destroyOnClose
          onCancel={closeModal}
          footer={null}
          style={{ top: 40, height: 912 }}
          width={897}
          zIndex={50}
        >
          <PageTitle
            title={translate(
              'pages.private.profiles.components.tabs.components.basicInfos.components.company.linkPartnerModal.title',
              'Link profile'
            )}
            subtitle={translate(
              'pages.private.profiles.components.tabs.components.basicInfos.components.company.linkPartnerModal.subtitle',
              'Link profile to an person ID in the base'
            )}
          />
          <Form>
            <Form.Item
              className="no-mrg-btm"
              validateStatus={hasError ? 'error' : 'success'}
              help={hasError ? errorMessage : false}
            >
              <Input
                style={{ maxWidth: 360 }}
                onChange={(event) => handleSearch(event.target.value)}
                className="custom-search-input"
                autoComplete="off"
                placeholder={translate(
                  'pages.private.profiles.components.tabs.components.basicInfos.components.company.linkPartnerModal.inputPlaceholder',
                  'Search by person ID'
                )}
                suffix={<i className="caf-ic_search" />}
              />
            </Form.Item>
          </Form>
          <Row>
            {loading ? (
              <Col className="flex mrg-top-25 width-100 center">
                <Spin />
              </Col>
            ) : (
              <></>
            )}
            {!loading && partner && <PartnerCard data={partner} />}
          </Row>
          <Row
            align="middle"
            justify="end"
            style={{ width: '100%' }}
            className="mrg-top-25"
          >
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <Button outlined={false} onClick={closeModal}>
                {translate(
                  'pages.private.profiles.components.tabs.components.basicInfos.components.company.linkPartnerModal.closeButton',
                  'Close'
                )}
              </Button>
              <Button
                type="primary"
                disabled={loading || !partner}
                onClick={handleConfirm}
              >
                {translate(
                  'pages.private.profiles.components.tabs.components.basicInfos.components.company.linkPartnerModal.confirmButton',
                  'Confirm'
                )}
              </Button>
            </div>
          </Row>
        </Modal>

        <ConfirmModal
          visible={confirmVisible}
          onClose={closeConfirmModal}
          onConfirm={handleSend}
          loading={loading}
          partner={partner}
        />
      </>
    ),
    [
      modalVisible,
      confirmVisible,
      closeModal,
      closeConfirmModal,
      translate,
      hasError,
      errorMessage,
      loading,
      partner,
      handleSend,
      handleSearch,
      handleConfirm,
    ]
  );

  return {
    openModal,
    closeModal,
    ModalLinkPartner,
  };
};

export default LinkPartnerModal;
