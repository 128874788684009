import React, { Suspense } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import moment from 'moment';
import i18next from 'i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import TagManager from 'react-gtm-module';

import 'assets/vendors/style';
import 'styles/wieldy.less';
import 'assets/css/caf-icons.css';

import momentOptionsLocale from 'utils/momentConfigsLocale';

import AppProvider from 'hooks';
import HttpInterceptor from 'utils/httpInterceptor';
import UploadWidget from 'components/UploadFilesManager/Widget';

import Routes from './routes';

const tagManagerArgs = {
  gtmId: 'GTM-P2TM5DH',
};

TagManager.initialize(tagManagerArgs);

i18next.on('languageChanged', (language) => {
  moment.updateLocale(language.toLowerCase(), {
    weekdaysMin: momentOptionsLocale[language]?.weekdaysMin,
    months: momentOptionsLocale[language]?.months,
  });
});
const queryClient = new QueryClient();

const App = () => (
  <Suspense fallback={<></>}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <AppProvider>
            <Routes />
            <HttpInterceptor />
            <UploadWidget />
          </AppProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </Suspense>
);

export default App;
