import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Checkbox, Collapse, Button, Card } from 'antd';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import IconStatus from 'pages/private/Profiles/components/IconStatus';
import { labelColor } from 'utils/handlerStatus';
import { Close } from '@combateafraude/icons/general';
import { Link } from 'react-router-dom';

import ValidationsList from '../Tabs/components/Summary/components/LastValidations/components/ValidationsList';

const { Panel } = Collapse;

const CollapseSuggestion = ({ suggestion, openModal }) => {
  const { i18nFormatDateOnly } = useI18nFormatters();
  const { t: translate } = useTranslation();
  return (
    <Collapse bordered={false}>
      <Panel
        header={
          <Row className="flex center">
            <Col span={1}>
              <Checkbox
                value={suggestion?._id}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </Col>
            <Col span={9}>
              <Link
                to={`/person/${suggestion?.transaction?._id}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="mrg-left-10"
              >
                {suggestion?.transaction?.summary?.name || '-'}
              </Link>
            </Col>
            <Col span={3} className="pdd-left-5">
              <span>{suggestion?.transaction?.summary?.identifier || '-'}</span>
            </Col>
            <Col span={3} className="pdd-left-10">
              <span>{suggestion?.transaction?.summary?.birthDate || '-'}</span>
            </Col>
            <Col span={5}>
              <span>{suggestion?.transaction?.summary?.address?.full || '-'}</span>
            </Col>
            <Col span={2} className="no-pdd">
              <span>
                <div className="card-info-status">
                  <div className="wrapper-icon-text">
                    <IconStatus status={suggestion?.transaction?.status} />
                    <span
                      style={{
                        color: labelColor[suggestion?.transaction?.status] ?? '#2E70F0',
                      }}
                    >
                      {suggestion?.transaction?.status}
                    </span>
                  </div>
                </div>
              </span>
            </Col>
            <Col span={1} justify="end">
              <Close
                className="font-size-24"
                onClick={(e) => {
                  e.stopPropagation();
                  openModal({ type: 'refuse', suggestions: [suggestion?._id] });
                }}
              />
            </Col>
          </Row>
        }
      >
        <Row>
          <Col span={12}>
            <Row className="row-info">
              <Col span={12}>
                <b>{translate('pages.private.globalProfiles.index.email', 'Email')}</b>
              </Col>
              <Col span={12}>{suggestion?.transaction?.summary?.email || '-'}</Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row className="row-info">
              <Col span={12}>
                <b>
                  {translate(
                    'pages.private.globalProfiles.index.phoneNumber',
                    'Phone number'
                  )}
                </b>
              </Col>
              <Col span={12}>{suggestion?.transaction?.summary?.phoneNumber || '-'}</Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row className="row-info">
              <Col span={12}>
                <b>
                  {translate(
                    'pages.private.globalProfiles.index.createdAt',
                    'Created at'
                  )}
                </b>
              </Col>
              <Col span={12}>
                {i18nFormatDateOnly(suggestion?.transaction?.createdAt || '-', 'default')}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Card className="mrg-top-10">
              <ValidationsList validations={suggestion?.transaction?.validations} />
            </Card>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

export default CollapseSuggestion;
