export const hasAddressInformation = (profile, dataSources) => {
  return (
    dataSources?.includes('pf_addresses') ||
    dataSources?.includes('pf_addresses_alt') ||
    profile?.addresses ||
    profile?.sections?.pfAddresses ||
    profile?.sections?.pfAddressesAlt ||
    profile?.sources?.pfAddressesAlt ||
    profile?.sources?.pfAddresses
  );
};

export const hasContactsInformation = (profile, dataSources) => {
  return (
    dataSources?.includes('pf_phones') ||
    dataSources?.includes('pf_emails') ||
    profile?.contact?.phones ||
    profile?.contact?.emails ||
    profile?.sections?.pfEmails ||
    profile?.sections?.pfPhones
  );
};

export const hasFinancialInformation = (profile, dataSources) => {
  return (
    dataSources?.includes('pf_finantial_data') ||
    dataSources?.includes('pfFinantialData') ||
    dataSources?.includes('pf_spc') ||
    dataSources?.includes('pf_spc_income') ||
    dataSources?.includes('pf_protesto') ||
    dataSources?.includes('pf_pgfn') ||
    dataSources?.includes('pf_joint_debts_certificate') ||
    dataSources?.includes('pf_social_assistance') ||
    dataSources?.includes('pfSocialAssistance') ||
    dataSources?.includes('pf_seguro_desemprego') ||
    dataSources?.includes('pfSeguroDesemprego ') ||
    dataSources?.includes('pf_salesbox') ||
    dataSources?.includes('pf_score_check') ||
    profile?.finance?.totalAssets ||
    profile?.finance?.incomeEstimates ||
    profile?.finance?.taxReturns ||
    profile?.finance?.spc ||
    profile?.finance?.protest ||
    profile?.finance?.socialAssistance ||
    profile?.finance?.scoreCheck ||
    profile?.certificates?.pgfn ||
    profile?.certificates?.jointDebts ||
    profile?.work?.unemploymentInsurance ||
    profile?.sections?.pfFinantialData ||
    profile?.sections?.pfSocialAssistance ||
    profile?.sections?.pfCollections ||
    profile?.sections?.pfSeguroDesemprego ||
    profile?.sections?.pfPgfn ||
    profile?.sections?.pfCoaf ||
    profile?.sections?.pfVehicles ||
    profile?.sections?.pfSpc ||
    profile?.sections?.pfProtesto ||
    profile?.sections?.pfSpcIncome ||
    profile?.sections?.pfScoreCheck
  );
};

export const hasDriverInformation = (profile, dataSources) => {
  return (
    dataSources?.includes('detran') ||
    dataSources?.includes('antt') ||
    profile?.driver?.detran ||
    profile?.driver?.antt ||
    profile?.sections?.detran ||
    profile?.sections?.antt
  );
};

export const hasCriminalInformation = (profile, dataSources) => {
  return (
    dataSources?.includes('pf_antecedentes') ||
    dataSources?.includes('pf_criminal_background') ||
    profile?.criminal?.stateCriminalBackground ||
    profile?.criminal?.federalCriminalBackground ||
    profile?.sections?.pfAntecedentes ||
    profile?.sections?.pfCriminalBackground ||
    profile?.sections?.pfArrestWarrant
  );
};

export const hasLaborInformation = (profile, dataSources) => {
  return (
    dataSources?.includes('pf_occupation_data') ||
    dataSources?.includes('pf_profession_data') ||
    dataSources?.includes('pf_class_organization') ||
    dataSources?.includes('pf_rais') ||
    profile?.work?.history ||
    profile?.work?.civilServant ||
    profile?.work?.classOrganization ||
    profile?.work?.rais ||
    profile?.sections?.pfOccupationData ||
    profile?.sections?.pfProfessionData ||
    profile?.sections?.pfUniversityStudentData ||
    profile?.sections?.pfClassOrganization
  );
};

export const hasLegalInformation = (profile, dataSources) => {
  return (
    dataSources?.includes('pf_processes') ||
    dataSources?.includes('pf_processes_alt') ||
    profile?.justice?.processes ||
    profile?.sections?.pfProcesses ||
    profile?.sections?.pfProcessesAlt
  );
};

export const hasRestrictiveListsInformation = (profile, dataSources) => {
  return (
    dataSources?.includes('pf_kyc_compliance') ||
    profile?.others?.kycCompliance ||
    profile?.sections?.pfKycCompliance
  );
};

export const hasRelationshipInformation = (profile, dataSources) => {
  return (
    dataSources?.includes('pf_related_people_phones') ||
    dataSources?.includes('pf_business_relationships') ||
    dataSources?.includes('pf_personal_relationships') ||
    dataSources?.includes('pf_personal_relationships_trust_rl') ||
    profile?.relationships?.peoplePhones ||
    profile?.relationships?.business ||
    profile?.relationships?.personal ||
    profile?.relationships?.peopleOccurrencesIRL ||
    profile?.sections?.pfRelatedPeoplePhones ||
    profile?.sections?.pfBusinessRelationships ||
    profile?.sections?.pfPersonalRelationships
  );
};

export const hasMediaExposureInformation = (profile, dataSources) => {
  return (
    dataSources?.includes('pf_media_profile_and_exposure') ||
    dataSources?.includes('pf_domains') ||
    profile?.others?.mediaExposure ||
    profile?.sections?.pfMediaProfileAndExposure
  );
};

export const hasIndependentConsultantsInformation = (profile) => {
  return (
    profile?.independentConsultants?.sections?.pjData ||
    profile?.independentConsultants?.sections?.pjSimples ||
    profile?.independentConsultants?.sections?.pjSintegra ||
    profile?.independentConsultants?.parameters?.cnpj
  );
};

export const hasTrustInformation = (profile, dataSources) => {
  return (
    hasFinancialInformation(profile, dataSources) ||
    hasDriverInformation(profile, dataSources) ||
    hasCriminalInformation(profile, dataSources) ||
    hasLaborInformation(profile, dataSources) ||
    hasLegalInformation(profile, dataSources) ||
    hasRestrictiveListsInformation(profile, dataSources) ||
    hasRelationshipInformation(profile, dataSources) ||
    hasMediaExposureInformation(profile, dataSources) ||
    hasIndependentConsultantsInformation(profile)
  );
};

// extra

export const hasPersonalAndBusinessRelationshipInformation = (profile, dataSources) => {
  return (
    dataSources?.includes('pf_business_relationships') ||
    dataSources?.includes('pf_personal_relationships') ||
    profile?.relationships?.business ||
    profile?.relationships?.personal ||
    profile?.sections?.pfBusinessRelationships ||
    profile?.sections?.pfPersonalRelationships
  );
};

export const hasDetranInformation = (profile, dataSources) => {
  return (
    dataSources?.includes('detran') ||
    profile?.driver?.detran ||
    profile?.sections?.detran
  );
};

export const hasElectoralSituationInformation = (profile, dataSources) => {
  return (
    dataSources?.includes('pf_electoral_situation') ||
    profile?.others?.electoralSituation ||
    profile?.sections?.pfElectoralSituation
  );
};
