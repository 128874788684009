import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Card, Form, Input, Button, Select, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'services/hooks';
import Proptypes from 'prop-types';

import PageTitle from 'components/PageTitle';
import { PlusOutlined } from '@ant-design/icons';
import { i18nMomentFormats } from 'i18n/utils/i18nFormats';

import { options } from '../../configs';
import './styles.less';

const I18N_BASE_PATH = 'pages.private.routines.detail.components.routineFilter';

const { Option } = Select;

const RoutineFilter = ({ form, type }) => {
  const { t: translate, i18n } = useTranslation();
  const { get } = useFetch();

  const [filters, setFilters] = useState([]);
  const [customFields, setCustomFields] = useState([]);

  const [tags, setTags] = useState([]);
  const [profileTemplates, setProfileTemplates] = useState([]);

  const fetchProfileTemplates = useCallback(async () => {
    if (type) {
      const res = await get({
        url: '/profile-templates',
        showMessage: false,
        config: {
          params: {
            active: true,
          },
        },
      });
      setProfileTemplates(res?.docs?.filter((template) => template?.type === type));
    }
  }, [get, type]);

  const fetchTags = useCallback(async () => {
    const res = await get({
      url: `tags`,
    });
    setTags(res.docs);
  }, [get]);

  useEffect(() => {
    const formFilters =
      form?.getFieldValue('filters')?.map((filter) => filter.field) || [];
    setFilters(formFilters);
    fetchTags();
    fetchProfileTemplates();
  }, []);

  useEffect(() => {
    const fieldsArray = filters?.map((filter) => {
      const option = options.find((opt) => opt.field === filter);
      const fields = {};

      // Operators Field
      fields.operatorField = (
        <Select>
          {option.operators.map((operator) => (
            <Option value={operator.operator}>{translate(operator.operatorName)}</Option>
          ))}
        </Select>
      );

      // Value fields
      switch (option?.valueType) {
        case 'date':
          fields.valueField = (
            <DatePicker
              inputReadOnly
              format={i18nMomentFormats[2][i18n?.language]}
              suffixIcon={<i className="caf-ic_arrow_down" />}
              style={{ width: '100%' }}
            />
          );
          break;
        case 'select':
          fields.valueField = (
            <Select>
              {option.values.map((value) => (
                <Option value={value.value}>{translate(value.valueName)}</Option>
              ))}
            </Select>
          );
          break;
        case 'tags':
          fields.valueField = (
            <Select>
              {tags.map((tag) => (
                <Option value={tag._id}>{tag.title}</Option>
              ))}
            </Select>
          );
          break;
        case 'profile-templates':
          fields.valueField = (
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, opt) => {
                return opt?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
              }}
            >
              {profileTemplates.map((profileTemplate) => (
                <Option value={profileTemplate._id}>{profileTemplate.name}</Option>
              ))}
            </Select>
          );
          break;
        default:
          break;
      }

      return fields;
    });
    setCustomFields(fieldsArray);
  }, [filters, i18n, profileTemplates, tags, translate]);

  const onFieldChange = useCallback(
    (index, key, value) => {
      // Limpa campos do form
      const formFilter = form.getFieldValue('filters');
      formFilter[index].operator = undefined;
      formFilter[index].value = undefined;
      form.setFieldsValue({ filter: formFilter });

      // Seta novo estado filters
      const newState = [...filters];
      newState[key] = value;
      setFilters(newState);
    },
    [filters, setFilters, form]
  );

  return (
    <>
      <Row align="middle" className="flex no-mrg mrg-top-50">
        <Col className="flex-1 page-title">
          <PageTitle
            title={translate(`${I18N_BASE_PATH}.title`)}
            subtitle={translate(`${I18N_BASE_PATH}.subtitle`)}
          />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Card className="pdd-left-50 pdd-right-50">
            <Form.List name="filters">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <>
                      <Row gutter={50}>
                        <Col span={10}>
                          <Form.Item
                            {...restField}
                            name={[name, 'field']}
                            label={
                              index === 0
                                ? translate(`${I18N_BASE_PATH}.form.filters.field.label`)
                                : undefined
                            }
                            rules={[{ required: true }]}
                          >
                            <Select
                              onChange={(value) => onFieldChange(index, key, value)}
                            >
                              {options.map((option) => (
                                <Option value={option.field}>
                                  {translate(option.fieldName)}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            {...restField}
                            name={[name, 'operator']}
                            label={
                              index === 0
                                ? translate(
                                    `${I18N_BASE_PATH}.form.filters.operator.label`
                                  )
                                : undefined
                            }
                            rules={[{ required: true }]}
                          >
                            {customFields[key] ? (
                              customFields[key].operatorField
                            ) : (
                              <Input />
                            )}
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            name={[name, 'value']}
                            label={
                              index === 0
                                ? translate(`${I18N_BASE_PATH}.form.filters.value.label`)
                                : undefined
                            }
                            rules={[{ required: true }]}
                          >
                            {customFields[key] ? customFields[key].valueField : <Input />}
                          </Form.Item>
                        </Col>
                        <Col span={2} className="flex center-start last-column">
                          <Form.Item
                            label={
                              index === 0
                                ? translate(`${I18N_BASE_PATH}.form.filters.delete.label`)
                                : undefined
                            }
                          >
                            <Button
                              disabled={index === 0}
                              htmlType="button"
                              type="danger"
                              rounded={false}
                              onClick={() => remove(name)}
                              className="remove-button"
                            >
                              <i className="caf-ic_trash" />
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ))}
                  <Row>
                    <Col span={4}>
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          {translate(`${I18N_BASE_PATH}.form.filters.add.label`)}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </Form.List>
          </Card>
        </Col>
      </Row>
    </>
  );
};

RoutineFilter.propTypes = {
  type: Proptypes.string.isRequired,
};

export default RoutineFilter;
