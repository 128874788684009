import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Upload } from 'antd';

import { useFetch } from 'services/hooks';
import { cleanApi } from 'services/api';
import { getExtensionFile } from 'utils/getExtensionFile';
import Loader from 'components/Loader';

import './styles.less';

const { Dragger } = Upload;

// const supportedFiles = ['png', 'jpg', 'jpeg', 'pdf'];

const FileUpload = ({ title, file, onChangeFile, supportedFiles, isInvalid }) => {
  const { t: translate } = useTranslation();

  const { get: getUrls } = useFetch();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  // const [unsupportedFile, setUnsupportedFile] = useState(false);

  const handleRemoveCurrentFile = useCallback(() => {
    onChangeFile(undefined);
  }, [onChangeFile]);

  const fileToArrayBuffer = useCallback(async (newFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject();
      };
      reader.readAsArrayBuffer(newFile);
    });
  }, []);

  const customRequest = useCallback(
    async (params) => {
      setErrorMessage(undefined);
      setLoading(true);
      setSelectedFile(params?.file);

      const { file: newFile, onSuccess, onError } = params;

      // verifica se o arquivo tem os formatos corretos
      if (!supportedFiles.includes(`.${getExtensionFile(newFile.name.toLowerCase())}`)) {
        let msg = translate('components.files.fileUpload.customRequest.msg.default');

        if (supportedFiles?.length) {
          msg = translate(
            'components.files.fileUpload.customRequest.msg.supportedFiles',
            { msg, supportedFiles: supportedFiles?.join(', ') }
          );
        }

        setErrorMessage(msg);
        setLoading(false);

        return;
      }

      try {
        // Transforma em um buffer
        const data = await fileToArrayBuffer(newFile);

        // pega urls para mandar pro bucket temporário
        const { uploadUrl, getUrl } = await getUrls({
          url: `/upload-request`,
          config: {
            params: {
              contentType: newFile?.type,
            },
          },
        });

        // envia o arquivo para o bucket temporário
        await cleanApi.put(uploadUrl, data);

        onChangeFile(getUrl);

        onSuccess('Ok');
      } catch (err) {
        onError({ err });
        setErrorMessage(
          translate('components.files.fileUpload.customRequest.error.message')
        );
      } finally {
        setLoading(false);
      }
    },
    [fileToArrayBuffer, getUrls, onChangeFile, supportedFiles, translate]
  );

  const renderIcon = useMemo(() => {
    if (errorMessage) return <i className="caf-ic_close error-icon" />;
    // if (loading) return <i className="caf-ic_rotate-cw loading-icon" />;
    if (loading) return <Loader padding="3px" size="20px" strokeWidth={4} />;
    if (file) return <i className="caf-ic_checkmark success-icon" />;

    return <i className="caf-ic_upload upload-icon" />;
  }, [errorMessage, loading, file]);

  return (
    <>
      <Dragger
        accept={supportedFiles?.join(',')}
        showUploadList={false}
        disabled={file}
        customRequest={customRequest}
        listType="picture"
        className={`manual-upload-documents-dragger ${
          isInvalid && !loading ? 'invalid' : ''
        } `}
      >
        <div>
          <div className="icons">{renderIcon}</div>
          <strong>{title}</strong>
        </div>

        {!file && !loading ? (
          <span>
            {translate(
              'components.files.fileUpload.dragger.description.hasNotFile.before'
            )}{' '}
            <strong>
              {translate(
                'components.files.fileUpload.dragger.description.hasNotFile.inside'
              )}
            </strong>{' '}
            {translate(
              'components.files.fileUpload.dragger.description.hasNotFile.after',
              { supportedFiles: supportedFiles?.join(', ') }
            )}
          </span>
        ) : (
          <div className="manual-upload-documents-file-info">
            {loading && (
              <span className="max-lines-1">
                {translate('components.files.fileUpload.dragger.description.loadingFile')}
              </span>
            )}

            {!loading && (
              <>
                <span className="max-lines-1">
                  {selectedFile?.name ||
                    translate(
                      'components.files.fileUpload.dragger.description.fileIsReady'
                    )}
                </span>
                <button
                  type="button"
                  className="reset-btn"
                  onClick={handleRemoveCurrentFile}
                >
                  <i className="caf-ic_close" />
                </button>
              </>
            )}
          </div>
        )}
      </Dragger>

      {errorMessage && (
        <span className="manual-upload-documents-unsupported-file">{errorMessage}</span>
      )}
    </>
  );
};

FileUpload.propTypes = {
  title: PropTypes.string.isRequired,
  file: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeFile: PropTypes.func.isRequired,
  supportedFiles: PropTypes.arrayOf(PropTypes.string),
  isInvalid: PropTypes?.bool,
};

FileUpload.defaultProps = {
  supportedFiles: [],
  isInvalid: false,
};

export default FileUpload;
