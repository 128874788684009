import { Col, Collapse, Row, Divider } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DottedLabel from 'components/DottedLabel';

const { Panel } = Collapse;

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.companies.components.spcReportParticipationScore.otherInfos';

function PaymentHistory({ source = {}, toPrint = false }) {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);

  const paymentHistory = useMemo(() => parsedData?.paymentHistory || {}, [parsedData]);

  return (
    <Collapse className="mrg-top-10" defaultActiveKey={toPrint ? ['1'] : null}>
      <Panel
        key="1"
        disabled={!paymentHistory?.details?.length}
        header={
          <Col>
            <Row align="middle" justify="space-between">
              <Col span={17}>
                <span className="text-dark text-bold">
                  {translate(`${I18N_BASE_PATH}.index.paymentHistory.title`)}
                </span>
              </Col>
              <Col className="mrg-right-15">
                <>
                  <DottedLabel
                    description={`(${paymentHistory?.total || 0}) ${translate(
                      `${I18N_BASE_PATH}.index.restrictions`
                    )}`}
                    type={+paymentHistory?.total > 0 ? 'danger' : 'primary'}
                  />
                </>
              </Col>
            </Row>
          </Col>
        }
      >
        {paymentHistory?.details?.map((register) => (
          <>
            <Col span={24}>
              <Row align="middle">
                <span className="text-dark text-bold">
                  {register?.range?.code} - {register?.range?.description} -{' '}
                  {register?.periodDescription}
                </span>
              </Row>
              <Row align="middle" className="mrg-top-8" justify="space-between">
                <Col>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.paymentHistory.columns.percentage`
                    )}
                    :
                  </span>{' '}
                  <span>
                    {register?.percentagePeriodFrom
                      ? `${register?.percentagePeriodFrom}%`
                      : ''}
                  </span>
                  <span>
                    {register?.percentagePeriodFrom && register?.percentagePeriodUntil
                      ? ' - '
                      : ''}
                  </span>
                  <span>
                    {register?.percentagePeriodUntil
                      ? `${register?.percentagePeriodUntil}%`
                      : ''}
                  </span>
                  <span>
                    {!register?.percentagePeriodFrom && !register?.percentagePeriodUntil
                      ? '-'
                      : ''}
                  </span>
                </Col>
                <Col>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.paymentHistory.columns.sourcesQuantity`
                    )}
                    :
                  </span>{' '}
                  <span>{register?.sourcesQuantity || '-'}</span>
                </Col>
                <Col>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.paymentHistory.columns.periodQuantity`
                    )}
                    :
                  </span>{' '}
                  <span>{register?.periodQuantityFrom || ''}</span>
                  <span>
                    {register?.periodQuantityFrom && register?.periodQuantityUntil
                      ? ' - '
                      : ''}
                  </span>
                  <span>{register?.periodQuantityUntil || ''}</span>
                  <span>
                    {!register?.periodQuantityFrom && !register?.periodQuantityUntil
                      ? '-'
                      : ''}
                  </span>
                </Col>
              </Row>
            </Col>
            <Divider />
          </>
        ))}
      </Panel>
    </Collapse>
  );
}

export default PaymentHistory;
