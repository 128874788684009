import React, { useState } from 'react';
import { Card, Typography, Col, message } from 'antd';

import { AlarmBlinkerSquare, LockCheckmarkCircle } from '@combateafraude/icons/security';
import {
  Clock,
  Location,
  Inactivity,
  LockValid,
  СloudStorageSetting,
} from '@combateafraude/icons/general';

import { useTranslation } from 'react-i18next';
import { convertHoursToMinutes } from 'utils/timing';
import { useSecurityOptions } from 'hooks/useSecurityOptions';
import { useAuth } from 'hooks/auth';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import useMfaActivation from './components/ModalMfaActivation';
import SecurityAndPrivacyWrapper from '../index';
import SessionConfigurationCard from './components/SessionConfigurationCard';
import SSOConfiguration from './components/SSOConfigurations';

import TimingOptions from './components/TimingOptions';

import './styles.less';
import { useSSO, ssoStatus } from './hooks/useSSO';

const { Title } = Typography;

const I18N_BASE_PATH = 'pages.private.securityAndPrivacy.securityConfiguration';

const SecurityConfiguration = () => {
  const [showSSO, setShowSSO] = useState(false);

  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const { user } = useAuth();

  const {
    idleTimeoutInMinutes,
    accessTokenExpirationInMinutes,
    allowMultipleLogins,
    setAllowMultipleLogins,
    setAccessTokenExpirationInMinutes,
    setIdleTimeoutInMinutes,
    doPatchAccessTokenExpirationInMinutes,
    doPatchAllowMultipleLogins,
    doInactivationConfigPatch,
    doPatchAllowEmailAlerts,
    allowEmailAlerts,
    setAllowEmailAlerts,
    multifactorAuthentication,
    setMfaAuthenticationPeriodInDays,
    mfaAuthenticationPeriodInDays,
    setMfaUpdatedAt,
    mfaUpdatedAt,
    setMfaUserLastModification,
    mfaUserLastModification,
    doPatchMfaAuthenticationPeriodInDays,
    passwordExpirationPeriod,
    doPatchPasswordExpiration,
    doPatchPasswordExpirationPeriod,
    setPasswordExpirationPeriod,
    passwordExpiration,
    setPasswordExpiration,
    loading,
    suspensionByInactivityInDays,
    setSuspensionByInactivityInDays,
  } = useSecurityOptions();

  const [
    sso,
    { createSSOConfig, updateSSOConfig, updateSSOStatus },
    loadingSSO,
  ] = useSSO();

  const { openModal: openMfaActivationModal, mfaActivationModal } = useMfaActivation({});

  const inactivityOptions = [
    {
      value: 5 /* minutes */,
      label: `5 ${translate(`${I18N_BASE_PATH}.index.options.minutes`, 'minutes')}`,
    },
    {
      value: 15 /* minutes */,
      label: `15 ${translate(`${I18N_BASE_PATH}.index.options.minutes`, 'minutes')}`,
    },
    {
      value: 30 /* minutes */,
      label: `30 ${translate(`${I18N_BASE_PATH}.index.options.minutes`, 'minutes')}`,
    },
    {
      value: convertHoursToMinutes({ hours: 1 }),
      label: `1 ${translate(`${I18N_BASE_PATH}.index.options.hour`, 'hour')}`,
    },
  ];

  const timingLimitOptions = [
    {
      value: convertHoursToMinutes({ hours: 4 }),
      label: `4 ${translate(`${I18N_BASE_PATH}.index.options.hours`, 'hours')}`,
    },
    {
      value: convertHoursToMinutes({ hours: 8 }),
      label: `8 ${translate(`${I18N_BASE_PATH}.index.options.hours`, 'hours')}`,
    },
    {
      value: convertHoursToMinutes({ hours: 12 }),
      label: `12 ${translate(`${I18N_BASE_PATH}.index.options.hours`, 'hours')}`,
    },
  ];

  const mfaTimingLimitOptions = [
    {
      value: 0,
      label: translate(`${I18N_BASE_PATH}.index.options.eachLogin`, 'At each login'),
    },
    {
      value: 2,
      label: `2 ${translate(`${I18N_BASE_PATH}.index.options.days`, 'days')}`,
    },
    {
      value: 3,
      label: `3 ${translate(`${I18N_BASE_PATH}.index.options.days`, 'days')}`,
    },
    {
      value: 5,
      label: `5 ${translate(`${I18N_BASE_PATH}.index.options.days`, 'days')}`,
    },
    {
      value: 7,
      label: `1 ${translate(`${I18N_BASE_PATH}.index.options.week`, 'week')}`,
    },
  ];

  const suspensionByInactivityOptions = [
    {
      value: 30,
      label: `30 ${translate(`${I18N_BASE_PATH}.index.options.days`, 'days')}`,
    },
    {
      value: 45,
      label: `45 ${translate(`${I18N_BASE_PATH}.index.options.days`, 'days')}`,
    },
    {
      value: 60,
      label: `60 ${translate(`${I18N_BASE_PATH}.index.options.days`, 'days')}`,
    },
    {
      value: 90,
      label: `90 ${translate(`${I18N_BASE_PATH}.index.options.days`, 'days')}`,
    },
  ];

  const passwordExpirationOptions = [
    {
      value: 30,
      label: translate(
        `${I18N_BASE_PATH}.index.options.everyThirtyDays`,
        'Every 30 days'
      ),
    },
    {
      value: 60,
      label: translate(`${I18N_BASE_PATH}.index.options.everySixtyDays`, 'Every 60 days'),
    },
    {
      value: 90,
      label: translate(
        `${I18N_BASE_PATH}.index.options.everyNinetyDays`,
        'Every 90 days'
      ),
    },
  ];

  return (
    <SecurityAndPrivacyWrapper>
      {mfaActivationModal}
      <Card style={{ width: '100%' }}>
        <div className="row-information">
          <Title level={3}>
            {translate(`${I18N_BASE_PATH}.index.subtitle`, 'User logout settings')}
          </Title>
          <Title level={5} style={{ fontWeight: 400 }}>
            {translate(
              `${I18N_BASE_PATH}.index.description`,
              'Define which logoff rules will be active during trust user sessions.'
            )}
          </Title>
        </div>
        <Col>
          <SessionConfigurationCard
            title={translate(`${I18N_BASE_PATH}.cards.inactivity.title`, 'Inactivity')}
            Icon={Inactivity}
          >
            <TimingOptions
              defaultValue={idleTimeoutInMinutes}
              title={translate(`${I18N_BASE_PATH}.cards.inactivity.disconection.title`)}
              subtitle={translate(
                `${I18N_BASE_PATH}.cards.inactivity.disconection.description`
              )}
              tooltip={translate(
                `${I18N_BASE_PATH}.cards.inactivity.disconection.tooltip`
              )}
              options={inactivityOptions}
              handleChange={({ target: { value } }) => {
                setIdleTimeoutInMinutes(value);
              }}
              isLoading={loading}
            />
            <TimingOptions
              defaultValue={suspensionByInactivityInDays}
              title={translate(`${I18N_BASE_PATH}.cards.inactivity.suspension.title`)}
              subtitle={translate(
                `${I18N_BASE_PATH}.cards.inactivity.suspension.description`
              )}
              tooltip={translate(`${I18N_BASE_PATH}.cards.inactivity.suspension.tooltip`)}
              textButton={translate(`${I18N_BASE_PATH}.index.save`, 'Save')}
              options={suspensionByInactivityOptions}
              handleChange={({ target: { value } }) => {
                setSuspensionByInactivityInDays(value);
              }}
              handleSave={() => {
                // this funcion save both inactivity and suspension
                doInactivationConfigPatch().then(() => {
                  message.success(
                    translate('global.hooks.fetch.methods.patch.success.message')
                  );
                });
              }}
              isLoading={loading}
            />
          </SessionConfigurationCard>
          <SessionConfigurationCard
            title={translate(
              `${I18N_BASE_PATH}.cards.sessionTimingLimit.title`,
              'Session timeout'
            )}
            Icon={Clock}
          >
            <TimingOptions
              title={translate(
                `${I18N_BASE_PATH}.cards.sessionTimingLimit.subtitle`,
                'Select the maximum session time before the user is logged out.'
              )}
              textButton={translate(`${I18N_BASE_PATH}.index.save`, 'Save')}
              defaultValue={accessTokenExpirationInMinutes}
              options={timingLimitOptions}
              handleChange={({ target: { value } }) => {
                setAccessTokenExpirationInMinutes(value);
              }}
              handleSave={() => {
                doPatchAccessTokenExpirationInMinutes().then(() => {
                  message.success(
                    translate('global.hooks.fetch.methods.patch.success.message')
                  );
                });
              }}
              isLoading={loading}
            />
          </SessionConfigurationCard>
          <SessionConfigurationCard
            handleToggleSwitch={(value) => {
              setAllowMultipleLogins(value);
              doPatchAllowMultipleLogins();
            }}
            isDefaultSwitchChecked={allowMultipleLogins}
            title={translate(
              `${I18N_BASE_PATH}.cards.multipleLogins.title`,
              'Multiple Logins'
            )}
            Icon={Location}
            isDisabledConfiguration
            isDisabledSwitch={false}
          />
          <SessionConfigurationCard
            handleToggleSwitch={(value) => {
              setAllowEmailAlerts(value);
              doPatchAllowEmailAlerts();
            }}
            isDefaultSwitchChecked={allowEmailAlerts}
            title={translate(
              `${I18N_BASE_PATH}.cards.emailAlerts.title`,
              'Security alerts for administrators'
            )}
            Icon={AlarmBlinkerSquare}
            isDisabledConfiguration
            isDisabledSwitch={false}
          />
          <SessionConfigurationCard
            isDisabledSwitch={false}
            isDefaultSwitchChecked={multifactorAuthentication}
            isDisabledConfiguration={!multifactorAuthentication}
            Icon={LockCheckmarkCircle}
            tooltipHint={translate(
              `${I18N_BASE_PATH}.cards.multifactorAuthentication.tooltip`,
              ' Two-step verification helps guaranteeing your company and client information remain private, protected and safe.'
            )}
            handleToggleSwitch={(value) => {
              openMfaActivationModal();
            }}
            title={translate(
              `${I18N_BASE_PATH}.cards.multifactorAuthentication.title`,
              'Multifactor Authentication (MFA)'
            )}
          >
            <TimingOptions
              description={translate(
                `${I18N_BASE_PATH}.cards.multifactorAuthentication.description`,
                'Protect your company and your clients information with Multifactor Authentication. How it works, is that after using the platform for a defined amount of time, the user will have to type a code sent to the e-mail, along with the password.'
              )}
              title={translate(
                `${I18N_BASE_PATH}.cards.multifactorAuthentication.subtitle`,
                'Set the frequency that the Multifactor Authentication will be required to login, for all platform users:'
              )}
              textButton={translate(`${I18N_BASE_PATH}.index.save`, 'Save')}
              defaultValue={mfaAuthenticationPeriodInDays}
              options={mfaTimingLimitOptions}
              handleChange={(event) => {
                setMfaAuthenticationPeriodInDays(event?.target?.value);
                setMfaUpdatedAt(new Date());
                setMfaUserLastModification(user?.username);
              }}
              handleSave={() => {
                doPatchMfaAuthenticationPeriodInDays().then(() => {
                  message.success(
                    translate('global.hooks.fetch.methods.patch.success.message')
                  );
                });
              }}
              isLoading={loading}
            />
            {mfaUserLastModification && mfaUpdatedAt && (
              <span style={{ paddingLeft: '3rem' }}>
                {translate(
                  `${I18N_BASE_PATH}.cards.multifactorAuthentication.definedBy`
                ) +
                  mfaUserLastModification +
                  translate(`${I18N_BASE_PATH}.cards.multifactorAuthentication.in`) +
                  i18nFormatDate(new Date(mfaUpdatedAt), 1) +
                  translate(`${I18N_BASE_PATH}.cards.multifactorAuthentication.at`) +
                  new Date(mfaUpdatedAt).getHours() +
                  translate(`${I18N_BASE_PATH}.cards.multifactorAuthentication.colon`) +
                  new Date(mfaUpdatedAt).getUTCMinutes()}
              </span>
            )}
          </SessionConfigurationCard>
          <SessionConfigurationCard
            isDisabledSwitch={!multifactorAuthentication}
            isDefaultSwitchChecked={passwordExpiration}
            isDisabledConfiguration={!passwordExpiration}
            Icon={LockValid}
            handleToggleSwitch={(value) => {
              setPasswordExpiration(value);
              doPatchPasswordExpiration();
            }}
            title={translate(
              `${I18N_BASE_PATH}.cards.passwordExpiration.title`,
              'Password reset'
            )}
          >
            <TimingOptions
              subtitle={translate(
                `${I18N_BASE_PATH}.cards.passwordExpiration.subtitle`,
                'Set the frequency at which password expiration will occur for all platform users:'
              )}
              textButton={translate(`${I18N_BASE_PATH}.index.save`, 'Save')}
              defaultValue={passwordExpirationPeriod}
              options={passwordExpirationOptions}
              handleChange={(event) => {
                setPasswordExpirationPeriod(event?.target?.value);
              }}
              handleSave={() => {
                doPatchPasswordExpirationPeriod().then(() => {
                  message.success(
                    translate('global.hooks.fetch.methods.patch.success.message')
                  );
                });
              }}
              isLoading={loading}
            />
          </SessionConfigurationCard>
          <SessionConfigurationCard
            title={translate(`${I18N_BASE_PATH}.sso.title`)}
            Icon={СloudStorageSetting}
            isDefaultSwitchChecked={showSSO || sso?.status === ssoStatus.ENABLED}
            isDisabledConfiguration={sso?.status !== ssoStatus.ENABLED}
            handleToggleSwitch={(value) => {
              if (sso?.status !== ssoStatus.NOT_CONFIGURED) {
                updateSSOStatus(value ? ssoStatus.ENABLED : ssoStatus.DISABLED);
              }

              setShowSSO(value);
            }}
            isDisabledSwitch={false}
          >
            <div className="sso-configuration">
              <h6 className="sso-configuration__description">
                {translate(`${I18N_BASE_PATH}.sso.description`)}
              </h6>
              <SSOConfiguration
                isLoading={loadingSSO}
                handleSubmit={(formValues) => {
                  if (sso.status === ssoStatus.NOT_CONFIGURED) {
                    createSSOConfig(formValues);
                  } else {
                    updateSSOConfig(formValues);
                  }
                }}
                configuration={sso}
              />
            </div>
          </SessionConfigurationCard>
        </Col>
      </Card>
    </SecurityAndPrivacyWrapper>
  );
};

export default SecurityConfiguration;
