import React from 'react';

import DocumentsValidations from 'pages/private/Executions/components/Tabs/components/NewOcrScreen';
import VerifedIdentity from 'pages/private/Executions/components/Tabs/components/VerifiedIdentity';

import './styles.less';

const DocumentIdentity = () => {
  return (
    <div id="to-print-identity">
      <DocumentsValidations toPrint />
      <VerifedIdentity toPrint />
    </div>
  );
};

export default DocumentIdentity;
