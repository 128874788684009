import React from 'react';
import PropTypes from 'prop-types';

import LegalInformation from 'pages/private/Profiles/components/DataSourceCards/LegalInformation';

import './styles.less';

const DocumentLegalInformation = ({ data }) => {
  return (
    <div id="to-print-legal-information">
      <LegalInformation data={data} toPrint />
    </div>
  );
};

DocumentLegalInformation.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DocumentLegalInformation;
