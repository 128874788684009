import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';

import Button from 'components/Button';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.routines.detail.components.routineSubheader';
const RoutineSubheader = ({ routine }) => {
  const { t: translate } = useTranslation();

  const standardBreadcrumbs = useMemo(
    () => (
      <Breadcrumb separator=">">
        <Breadcrumb.Item className="breadcrumbs-color">
          <Link className="gx-link" to="/routines">
            {translate(`${I18N_BASE_PATH}.breadcrumbs.link`)}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="breadcrumbs-color">
          {translate(`${I18N_BASE_PATH}.breadcrumbs.description`)}
        </Breadcrumb.Item>
      </Breadcrumb>
    ),
    [translate]
  );

  return (
    <>
      <section id="routine-subheader">
        <div className="gx-header-horizontal-top">
          <div className="gx-container">
            <div className="routine-subheader-content">
              <div className="profile-info custom-breadcrumbs">
                {standardBreadcrumbs}
                <h5 className="profile-name max-lines-2">{routine?.name}</h5>
              </div>
              <Button danger href="/routines">
                {translate(`${I18N_BASE_PATH}.actions.cancelButton`)}{' '}
                <i className="mrg-left-5" />
              </Button>

              <Button type="primary" htmlType="submit">
                {translate(`${I18N_BASE_PATH}.actions.saveButton`)}{' '}
                <i className="mrg-left-5" />
              </Button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

RoutineSubheader.propTypes = {
  routine: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default RoutineSubheader;
