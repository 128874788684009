/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import { useQueryParams, StringParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';

import PermissionWrapper from 'routes/PermissionWrapper';
import { CardWrapper } from 'components/CardWrapper';
import Wrapper from 'pages/private/wrapper';

import { hasRequiredPermissions } from 'routes/permissionFunctions';
import { useAuth } from 'hooks/auth';
import RestrictionList from './RestrictionList';
import DenyList from './DenyList';

const I18N_BASE_PATH = 'pages.private.restrictions.index';

const Restrictions = () => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();

  const [query, setQuery] = useQueryParams({
    tab: StringParam,
  });

  const hasRestrictivePermission = useMemo(() => {
    return hasRequiredPermissions(user, ['restrictive-list:read'], [], false);
  }, [user]);

  const hasDenyPermission = useMemo(() => {
    return hasRequiredPermissions(user, ['deny-list:read'], [], false);
  }, [user]);

  const activeKey = useMemo(() => {
    if (query?.tab) return query.tab;

    if (hasRestrictivePermission && hasDenyPermission) {
      return 'restrictive_list';
    }

    if (!hasRestrictivePermission && hasDenyPermission) {
      return 'deny_list';
    }
  }, [query, hasDenyPermission, hasRestrictivePermission]);

  return (
    <Wrapper>
      <CardWrapper hasPadding>
        <PermissionWrapper
          or
          requiredPermissions={[
            'restrictive-list:read',
            'restrictive-list:create',
            'restrictive-list:update',
            'restrictive-list:delete',
            'deny-list:read',
            'deny-list:create',
            'deny-list:update',
            'deny-list:delete',
          ]}
        >
          <Tabs
            defaultActiveKey={activeKey}
            activeKey={activeKey}
            onChange={(tab) => setQuery({ tab }, 'replace')}
          >
            {hasRestrictivePermission && (
              <Tabs.TabPane
                tab={translate(`${I18N_BASE_PATH}.restrictiveList`)}
                key="restrictive_list"
              >
                <RestrictionList on />
              </Tabs.TabPane>
            )}

            {hasDenyPermission && (
              <Tabs.TabPane
                forceRender
                tab={translate(`${I18N_BASE_PATH}.denyList`)}
                key="deny_list"
              >
                <DenyList />
              </Tabs.TabPane>
            )}
          </Tabs>
        </PermissionWrapper>
      </CardWrapper>
    </Wrapper>
  );
};

export default Restrictions;
