import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, Timeline } from 'antd';
import { format, parseISO } from 'date-fns';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import IconStatus from 'pages/private/Profiles/components/IconStatus';

import CardReview from './CardReview';

const CardExecution = ({ data }) => {
  const { i18nGetDateFnsLocale } = useI18nFormatters();

  const PendingIcon = useMemo(() => <IconStatus status="PENDENTE" />, []);

  const formattedDate = useMemo(() => {
    const day = format(parseISO(data?.date), 'dd', {
      locale: i18nGetDateFnsLocale(),
    });
    const month = format(parseISO(data?.date), 'MMM', {
      locale: i18nGetDateFnsLocale(),
    });

    return { day, month };
  }, [data, i18nGetDateFnsLocale]);

  const formattedTimeline = useMemo(() => {
    return data?.items?.map((item) => (
      <Timeline.Item key={item?._id} dot={PendingIcon}>
        <CardReview item={item} />
      </Timeline.Item>
    ));
  }, [PendingIcon, data]);

  return (
    <Card className="no-border" id="card-recurrent-event">
      <div className="timeline-item-container">
        <div className="day-info-content">
          <span>
            {formattedDate?.month}
            <strong>{formattedDate?.day}</strong>
          </span>
        </div>

        <div className="activity-info-content">
          <div className="timeline-item-tail" />
          <Timeline>{formattedTimeline}</Timeline>
        </div>
      </div>
    </Card>
  );
};

CardExecution.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default CardExecution;
