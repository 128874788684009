export const genderTranslate = (gender) => {
  if (!gender) return '';

  switch (gender.toUpperCase()) {
    case 'MALE':
      return 'pages.private.profiles.components.tabs.components.documentsValidations.utils.translations.genderTranslate.male';
    case 'FEMALE':
      return 'pages.private.profiles.components.tabs.components.documentsValidations.utils.translations.genderTranslate.female';
    default:
      return 'pages.private.profiles.components.tabs.components.documentsValidations.utils.translations.genderTranslate.default';
  }
};

export const documentTypeTranslate = (type) => {
  if (!type) return '';

  switch (type.toUpperCase()) {
    case 'CNH':
      return 'pages.private.profiles.components.tabs.components.documentsValidations.utils.translations.documentTypeTranslate.cnh';
    case 'RG':
      return 'pages.private.profiles.components.tabs.components.documentsValidations.utils.translations.documentTypeTranslate.rg';
    default:
      return 'pages.private.profiles.components.tabs.components.documentsValidations.utils.translations.documentTypeTranslate.default';
  }
};
