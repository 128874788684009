import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Modal, Form, Input, message } from 'antd';
import TooltipInfo from 'components/TooltipInfo';

import { useFetch } from 'services/hooks';

import Button from 'components/Button';
import FileUpload from 'components/Files/FileUpload';
import PageTitle from 'components/PageTitle';
import SuccessStepModal from 'components/SuccessStepModal';

const RestrictionCreate = ({ refreshList }) => {
  const { t: translate } = useTranslation();

  const [form] = Form.useForm();
  const { post, loading } = useFetch();

  const [modalVisible, setModalVisible] = useState(false);
  const [page, setPage] = useState(0);
  const [fileUrl, setFileUrl] = useState();
  const [modalAction, setModalAction] = useState('include');
  const [step, setStep] = useState('');

  const closeModal = useCallback(() => {
    setModalVisible(false);

    setTimeout(() => {
      form.resetFields();
      setPage(0);
      setStep('');
      setModalAction('include');
      setFileUrl(undefined);
    }, 500);
  }, [form]);

  const openModal = useCallback((action) => {
    setModalAction(action);
    setModalVisible(true);
  }, []);

  const handleSubmit = useCallback(async () => {
    await form.validateFields();
    try {
      await post({
        url: `/internal-restrictive-list`,
        payload: {
          onlyAdd: modalAction === 'include',
          remove: modalAction === 'remove',
          file: fileUrl,
          ...form.getFieldsValue(),
        },
      });
      setStep('success');
      if (refreshList?.current) refreshList.current();
    } catch {
      message.error('Erro ao importar CSV.');
    }
  }, [fileUrl, form, modalAction, post, refreshList]);

  const formContent = useMemo(() => {
    if (page === 0) {
      return (
        <Row>
          <Col span={24}>
            <PageTitle
              title={translate(
                `pages.private.restrictions.restrictionList.components.ImportCSVModal.index.formContent.pageTitle.${modalAction}.title`
              )}
              subtitle={translate(
                `pages.private.restrictions.restrictionList.components.ImportCSVModal.index.formContent.pageTitle.${modalAction}.subTitle`
              )}
            />
          </Col>

          <Col span={24} className="mrg-vertical-20">
            <FileUpload
              title={translate(
                'pages.private.restrictions.restrictionList.components.ImportCSVModal.index.formContent.fileUpload.title'
              )}
              file={fileUrl}
              onChangeFile={setFileUrl}
              supportedFiles={['.csv', '.CSV']}
            />
            <Form
              form={form}
              layout="vertical"
              // onFinish={handleSubmit}
              autoComplete="off"
            >
              <Form.Item
                label={
                  <>
                    {translate(
                      'pages.private.restrictions.restrictionList.components.ImportCSVModal.index.formContent.form.columnIdentification.label'
                    )}
                    <TooltipInfo
                      color="#2f80ed"
                      placement="top"
                      title={() =>
                        translate(
                          'pages.private.restrictions.restrictionList.components.ImportCSVModal.index.formContent.form.caseSensitive'
                        )
                      }
                      size={12}
                    />
                  </>
                }
                name="columnIdentification"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              {modalAction !== 'remove' && (
                <Form.Item
                  label={
                    <>
                      <span>
                        {translate(
                          'pages.private.restrictions.restrictionList.components.ImportCSVModal.index.formContent.form.columnName.label'
                        )}{' '}
                      </span>
                      <i
                        className="font-size-10 gx-font-italic"
                        style={{ marginLeft: 2 }}
                      >
                        -{' '}
                        {translate(
                          'pages.private.restrictions.restrictionList.components.ImportCSVModal.index.formContent.form.optional' // => Opcional
                        )}
                      </i>
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() =>
                          translate(
                            'pages.private.restrictions.restrictionList.components.ImportCSVModal.index.formContent.form.caseSensitive'
                          )
                        }
                        size={12}
                      />
                    </>
                  }
                  name="columnName"
                >
                  <Input />
                </Form.Item>
              )}
              {modalAction !== 'remove' && (
                <Form.Item
                  label={
                    <>
                      <span>
                        {translate(
                          'pages.private.restrictions.restrictionList.components.ImportCSVModal.index.formContent.form.columnReason.label'
                        )}{' '}
                      </span>
                      <i
                        className="font-size-10 gx-font-italic"
                        style={{ marginLeft: 2 }}
                      >
                        -{' '}
                        {translate(
                          'pages.private.restrictions.restrictionList.components.ImportCSVModal.index.formContent.form.optional' // => Opcional
                        )}
                      </i>
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() =>
                          translate(
                            'pages.private.restrictions.restrictionList.components.ImportCSVModal.index.formContent.form.caseSensitive'
                          )
                        }
                        size={12}
                      />
                    </>
                  }
                  name="columnReason"
                >
                  <Input />
                </Form.Item>
              )}
            </Form>
          </Col>

          <Col span={24} className="flex end">
            <Button
              type="primary"
              htmlType="button"
              onClick={handleSubmit}
              disabled={!fileUrl || loading}
            >
              {translate(
                'pages.private.restrictions.restrictionList.components.ImportCSVModal.index.formContent.form.button'
              )}
            </Button>
          </Col>
        </Row>
      );
    }

    return <></>;
  }, [page, translate, modalAction, fileUrl, form, handleSubmit, loading]);

  const ImportCSVModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeModal}
        footer={null}
        width={650}
      >
        {step !== 'success' ? (
          formContent
        ) : (
          <SuccessStepModal
            pageTitle={translate(
              `pages.private.restrictions.restrictionList.components.ImportCSVModal.index.successContent.${modalAction}.pageTitle`
            )}
            content={translate(
              `pages.private.restrictions.restrictionList.components.ImportCSVModal.index.successContent.${modalAction}.content`
            )}
          >
            <Button type="primary" onClick={closeModal}>
              {translate(
                `pages.private.restrictions.restrictionList.components.ImportCSVModal.index.successContent.button`
              )}
            </Button>
          </SuccessStepModal>
        )}
      </Modal>
    ),
    [modalVisible, closeModal, step, formContent, translate, modalAction]
  );

  return { openModal, closeModal, ImportCSVModal };
};

export default RestrictionCreate;
