/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Row, Typography, Button } from 'antd';

import EmptyExecutionState from 'components/EmptyExecutionState';
import DottedLabel from 'components/DottedLabel';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

const { Text } = Typography;

const I18N_BASE_PATH =
  'components.dataSourceCards.legalInformation.componentes.processCade';

const ProcessOnCade = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const processOnCade = useMemo(() => source.data, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const processesQuerySucceeded = useMemo(
    () =>
      source?.statusCode === '01' ||
      source?.available === true ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [source]
  );

  const hasContent = useMemo(() => processOnCade?.proceduralProtocols?.length > 0, [
    processOnCade,
  ]);

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_file"
      title={translate(`${I18N_BASE_PATH}.page.title`)}
      toPrint={toPrint}
    >
      <div id="executions-legal-information-processes-container">
        <Row className="legal-information-processes-title reset-mrg">
          <Col span={24}>
            <Text>{translate(`${I18N_BASE_PATH}.page.subtitle`)}</Text>
            {processesQuerySucceeded && (
              <>
                {hasContent ? (
                  <DottedLabel
                    className="mrg-top-25"
                    type="danger"
                    description={translate(`${I18N_BASE_PATH}.dottedLabel.hasProcess`, {
                      value: processOnCade?.proceduralProtocols?.length,
                    })}
                  />
                ) : (
                  <DottedLabel
                    className="mrg-top-25"
                    type="primary"
                    description={translate(`${I18N_BASE_PATH}.dottedLabel.noProcess`)}
                  />
                )}
              </>
            )}
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            {processOnCade && (
              <>
                {processesQuerySucceeded ? (
                  <>
                    {hasContent ? (
                      <>
                        {processOnCade?.proceduralProtocols?.map((protocols) => (
                          <div className="mrg-left-20">
                            <Row>
                              <strong className="text-dark mrg-btm-10">
                                {protocols?.demandOrigin}
                              </strong>
                            </Row>
                            <Row>
                              <Col span={7} className="no-pdd">
                                <span>
                                  <strong className="text-dark">
                                    {translate(
                                      `${I18N_BASE_PATH}.processes.emissionDate`
                                    )}
                                  </strong>
                                  {protocols?.emissionDate}
                                </span>
                              </Col>
                              <Col span={10} className="no-pdd">
                                <span>
                                  <strong className="text-dark">
                                    {translate(
                                      `${I18N_BASE_PATH}.processes.protocolNumber`
                                    )}
                                  </strong>
                                  {protocols?.protocolNumber}
                                </span>
                              </Col>
                              <Col span={7} className="no-pdd">
                                <span>
                                  <strong className="text-dark">
                                    {translate(`${I18N_BASE_PATH}.processes.source`)}
                                  </strong>
                                  {protocols?.source}
                                </span>
                              </Col>
                            </Row>
                            <Row className="mrg-top-15">
                              <span>
                                {translate(
                                  `${I18N_BASE_PATH}.processes.documentRequested`
                                )}
                                {protocols?.documentRequested}
                              </span>
                            </Row>
                            <Row className="mrg-top-10">
                              <Button
                                className="no-pdd"
                                type="link"
                                href={protocols?.documentRequestedUrl}
                              >
                                {translate(
                                  `${I18N_BASE_PATH}.processes.documentRequestedUrl`
                                )}
                                <i className="caf-ic_arrow-right mrg-left-10" />
                              </Button>
                            </Row>
                            <Divider />
                          </div>
                        ))}
                      </>
                    ) : (
                      <span className="text-dark">{processOnCade?.message}</span>
                    )}
                  </>
                ) : (
                  <EmptyExecutionState
                    statusCode={statusCode}
                    message={source?.message || processOnCade?.message}
                  />
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
    </DataSourceCardCollapsePanel>
  );
};

export default ProcessOnCade;
