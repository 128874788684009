import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col, Form, Input } from 'antd';

import InputMask from 'components/Form/InputMask';
import { validateCnpj, validateCpf } from 'utils/validations';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';
import { useAuth } from 'hooks/auth';

const I18N_BASE_PATH =
  'pages.private.profileModels.profileModelList.components.links.components.createLinkModal.components.createLinkType.index.createLinkType';

const ProfileForm = ({ type }) => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();

  return (
    <div>
      <Row>
        <Col span={8}>
          {type === 'PF' || type === 'person' ? (
            <Form.Item
              normalize={(value) => value?.trimStart() ?? ''}
              name={!hasFeatureFlag('isWorkflowBuilder', user) ? 'cpf' : 'personId'}
              label={
                !hasFeatureFlag('isWorkflowBuilder', user)
                  ? translate(
                      `${I18N_BASE_PATH}.form.cpf.label` // => CPF da pessoa
                    )
                  : translate(`${I18N_BASE_PATH}.form.personId.label`) // => Identificador
              }
              rules={[
                { required: true, transform: (value) => value?.trimStart() ?? '' },
                () => ({
                  validator(rule, cpf) {
                    if (hasFeatureFlag('isWorkflowBuilder', user)) {
                      return Promise.resolve();
                    }
                    const validatorCpf = validateCpf(cpf);
                    if (!validatorCpf && cpf.length) {
                      return Promise.reject(
                        new Error(translate(`${I18N_BASE_PATH}.form.cpf.input.error`)) // => CPF inválido
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              {!hasFeatureFlag('isWorkflowBuilder', user) ? (
                <InputMask
                  placeholder={translate(
                    `${I18N_BASE_PATH}.form.cpf.input.placeholder` // => Informe o CPF
                  )}
                  mask={translate(
                    `${I18N_BASE_PATH}.form.cpf.input.mask` // => 999.999.999-99
                  )}
                />
              ) : (
                <Input
                  placeholder={translate(
                    `${I18N_BASE_PATH}.form.personId.input.placeholder` // => Informe o identificador
                  )}
                />
              )}
            </Form.Item>
          ) : (
            <Form.Item
              name={!hasFeatureFlag('isWorkflowBuilder', user) ? 'cnpj' : 'companyId'}
              label={
                !hasFeatureFlag('isWorkflowBuilder', user)
                  ? translate(
                      `${I18N_BASE_PATH}.form.cnpj.label` // => CNPJ
                    )
                  : translate(`${I18N_BASE_PATH}.form.companyId.label`) // => Informe o identificador
              }
              rules={[
                { required: true },
                () => ({
                  validator(rule, cnpj) {
                    if (hasFeatureFlag('isWorkflowBuilder', user)) {
                      return Promise.resolve();
                    }
                    const validatorCnpj = validateCnpj(cnpj);
                    if (!validatorCnpj && cnpj.length) {
                      return Promise.reject(
                        new Error(translate(`${I18N_BASE_PATH}.form.cnpj.input.error`)) // => CNPJ inválido
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              {!hasFeatureFlag('isWorkflowBuilder', user) ? (
                <InputMask
                  placeholder={translate(
                    `${I18N_BASE_PATH}.form.cnpj.input.placeholder` // => Informe o CNPJ
                  )}
                  mask={translate(
                    `${I18N_BASE_PATH}.form.cnpj.input.mask` // => 99.999.999/9999-99
                  )}
                />
              ) : (
                <Input
                  placeholder={translate(
                    `${I18N_BASE_PATH}.form.companyId.input.placeholder` // => Informe o identificador
                  )}
                />
              )}
            </Form.Item>
          )}
        </Col>
        <Col span={16}>
          {(type ===
            translate(
              `${I18N_BASE_PATH}.type.person` // => PF
            ) ||
            type ===
              translate(
                `${I18N_BASE_PATH}.type.company` // => PJ
              ) ||
            type === 'person' ||
            type === 'company') && (
            <Form.Item
              name="name"
              label={
                <>
                  {translate(
                    `${I18N_BASE_PATH}.form.name.label.before.${
                      type ===
                        translate(
                          `${I18N_BASE_PATH}.type.person` // => PF
                        ) || type === 'person'
                        ? 'person' // => Nome da pessoa
                        : 'company' // => Nome da empresa
                    }`
                  )}
                  <i className="font-size-10 gx-font-italic" style={{ marginLeft: 2 }}>
                    -{' '}
                    {translate(
                      `${I18N_BASE_PATH}.form.name.label.after` // => Opcional
                    )}
                  </i>
                </>
              }
            >
              <Input
                placeholder={translate(
                  `${I18N_BASE_PATH}.form.name.input.placeholder` // => Informe o nome
                )}
              />
            </Form.Item>
          )}
        </Col>
      </Row>
    </div>
  );
};

ProfileForm.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ProfileForm;
