import React, { useCallback, useMemo } from 'react';
import EmptyExecutionState from 'components/EmptyExecutionState';
import { useTranslation } from 'react-i18next';
import { DollarSign, AlertTriangle } from '@combateafraude/icons/general';
import { Row, Col, Table, Card, Tag, Collapse, Tooltip } from 'antd';
import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';
import { labelStatus } from 'utils/handlerStatus';

import DottedLabel from 'components/DottedLabel';
import NotAvailable from 'components/NotAvailable';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import ScoreIndicator from '../../../Components/ScoreIndicator';

import './styles.less';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.people.components';

const CreditScorePJ = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const data = useMemo(() => source.data, [source]);
  const statusCode = useMemo(
    () =>
      source?.data?.statusCode ||
      source?.statusCode ||
      source?.data?.code ||
      source?.code,
    [source]
  );
  const renderTags = useCallback(
    (score) => {
      if (score === 'A' || score === 'B') {
        return <Tag className="good-tag">{translate(`${I18N_BASE_PATH}.good`)}</Tag>;
      }
      if (score === 'C') {
        return (
          <Tag className="regular-tag">{translate(`${I18N_BASE_PATH}.regular`)}</Tag>
        );
      }
      return <Tag className="bad-tag">{translate(`${I18N_BASE_PATH}.bad`)}</Tag>;
    },
    [translate]
  );

  const debtIndicator = useMemo(() => {
    return (
      <Tooltip title={translate(`${I18N_BASE_PATH}.hasDebt`)}>
        <AlertTriangle color="#ff9500" fontSize={20} />
      </Tooltip>
    );
  }, [translate]);
  const fraudIndicator = useMemo(() => {
    return (
      <Tooltip title={translate(`${I18N_BASE_PATH}.hasFraud`)}>
        <AlertTriangle color="#a01137" fontSize={20} />
      </Tooltip>
    );
  }, [translate]);

  return (
    <DataSourceCardCollapsePanel
      customIcon={
        <div className="div-icon">
          <DollarSign color="#004af7" />
        </div>
      }
      title={translate(`${I18N_BASE_PATH}.titlePj`)}
      toPrint={toPrint}
    >
      {statusCode !== '01' || !source?.data ? (
        <EmptyExecutionState
          statusCode={statusCode}
          message={source?.message || source?.data?.message}
        />
      ) : (
        <div id="credit-score-pj">
          <div className="mrg-btm-30">
            <h3>{translate(`${I18N_BASE_PATH}.finalDecision`)}</h3>
            <span className="text-dark">{data?.decision}</span>
          </div>
          <div className="mrg-btm-30">
            <h3>{translate(`${I18N_BASE_PATH}.score`)}</h3>
            <ScoreIndicator
              source={{
                data: {
                  score: data?.score?.score,
                  scoreMessage: data?.score?.scoreMessage,
                },
              }}
            />
          </div>
          <Row>
            <Col span={8} className="mrg-btm-10">
              <Card className="height-100">
                <h4>{translate(`${I18N_BASE_PATH}.creditObtained`)}</h4>
                <div className="flex-row start-center mrg-vertical-15">
                  <h1>{data?.creditObtained?.score}</h1>
                </div>
                <span>{translate(`${I18N_BASE_PATH}.finalPontuation`)}</span>
              </Card>
            </Col>
            <Col span={8} className="mrg-btm-10">
              <Card className="height-100">
                <h4>{translate(`${I18N_BASE_PATH}.futureCommitmentC`)}</h4>
                <div className="flex-row start-center mrg-vertical-15">
                  <h1>{data?.futureCommitment?.commitmentsList[0]?.score}</h1>
                </div>
                <span>{translate(`${I18N_BASE_PATH}.futureCommitmentCDescription`)}</span>
              </Card>
            </Col>
            <Col span={8} className="mrg-btm-10">
              <Card className="height-100">
                <h4>{translate(`${I18N_BASE_PATH}.futureCommitmentCredit`)}</h4>
                <div className="flex-row start-center mrg-vertical-15">
                  <h1>{data?.futureCommitment?.creditList[0]?.score}</h1>
                </div>
                <span>{translate(`${I18N_BASE_PATH}.commitmentCreditDescription`)}</span>
              </Card>
            </Col>
            <Col span={8} className="mrg-btm-10">
              <Card className="height-100">
                <h4>{translate(`${I18N_BASE_PATH}.administrators`)}</h4>
                <div className="flex-row start-center mrg-vertical-15">
                  <h1>{data?.administrators?.length}</h1>
                </div>
                <span>{translate(`${I18N_BASE_PATH}.administratorsDescription`)}</span>
              </Card>
            </Col>
            <Col span={8} className="mrg-btm-10">
              <Card className="height-100">
                <h4>{translate(`${I18N_BASE_PATH}.partsInOther`)}</h4>
                <div className="flex-row start-center mrg-vertical-15">
                  <h1>{data?.participationsInOtherCompanies?.length}</h1>
                </div>
                <span>{translate(`${I18N_BASE_PATH}.partnerDescription`)}</span>
              </Card>
            </Col>
            <Col span={8} className="mrg-btm-10">
              <Card className="height-100">
                <h4>{translate(`${I18N_BASE_PATH}.papioc`)}</h4>
                <div className="flex-row start-center mrg-vertical-15">
                  <h1>
                    {!data?.partnerAdministratorParticipationsInOtherCompanies?.message
                      ? data?.partnerAdministratorParticipationsInOtherCompanies?.flatMap(
                          (p) => p?.participatingPartners
                        )?.length
                      : '-'}
                  </h1>
                </div>
                <span>{translate(`${I18N_BASE_PATH}.papiocDescription`)}</span>
              </Card>
            </Col>
          </Row>
          <div className="mrg-vertical-30">
            <Row>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.cnpjSituation`)}
                  value={
                    <DottedLabel
                      description={data?.completeIdentification?.cnpjStatus || '-'}
                      type={
                        data?.completeIdentification?.cnpjStatus &&
                        labelStatus(data?.completeIdentification?.cnpjStatus).type
                      }
                    />
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.statusDate`)}
                  value={data?.completeIdentification?.statusDate || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.federalRevenueInquiryDate`)}
                  value={
                    data?.completeIdentification?.federalRevenueInquiryDate || (
                      <NotAvailable />
                    )
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.nire`)}
                  value={data?.completeIdentification?.nireNumber || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.stateRegistration`)}
                  value={
                    data?.completeIdentification?.stateRegistration || <NotAvailable />
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.foundationDate`)}
                  value={data?.completeIdentification?.foundationDate || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.sintegraStatus`)}
                  value={
                    <DottedLabel
                      description={data?.completeIdentification?.sintegraStatus || '-'}
                      type={
                        data?.completeIdentification?.sintegraStatus &&
                        labelStatus(data?.completeIdentification?.sintegraStatus).type
                      }
                    />
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.sintegraInquiry`)}
                  value={
                    data?.completeIdentification?.sintegraInquiryDate || <NotAvailable />
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.fgtsStatus`)}
                  value={
                    <DottedLabel
                      description={data?.completeIdentification?.fgts?.status || '-'}
                      type={
                        data?.completeIdentification?.fgts?.status &&
                        labelStatus(data?.completeIdentification?.fgts?.status).type
                      }
                    />
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.validityDate`)}
                  value={
                    data?.completeIdentification?.fgts?.validityDate || <NotAvailable />
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.inquiryDate`)}
                  value={
                    data?.completeIdentification?.fgts?.inquiryDate || <NotAvailable />
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.legalNature`)}
                  value={data?.completeIdentification?.legalNature || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.segment`)}
                  value={data?.completeIdentification?.segment || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.initialCapital`)}
                  value={
                    data?.completeIdentification?.initialCapital ? (
                      `${data?.completeIdentification?.moeda_capital_inicial} ${data?.completeIdentification?.initialCapital}`
                    ) : (
                      <NotAvailable />
                    )
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.registrationDate`)}
                  value={
                    data?.completeIdentification?.registrationDate || <NotAvailable />
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.agency`)}
                  value={data?.completeIdentification?.agency || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.currentCapital`)}
                  value={
                    data?.completeIdentification?.currentCapital ? (
                      `${data?.completeIdentification?.currentCapitalCurrency} ${data?.completeIdentification?.currentCapital}`
                    ) : (
                      <NotAvailable />
                    )
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.capitalChangeDate`)}
                  value={
                    data?.completeIdentification?.capitalChangeDate || <NotAvailable />
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.currentAgency`)}
                  value={data?.completeIdentification?.currentAgency || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.primaryCnae`)}
                  value={
                    data?.completeIdentification?.primaryBusinessActivity?.cnae ? (
                      `${data?.completeIdentification?.primaryBusinessActivity?.cnae} - ${data?.completeIdentification?.primaryBusinessActivity?.activity}`
                    ) : (
                      <NotAvailable />
                    )
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.secondaryCnae`)}
                  value={
                    data?.completeIdentification?.secondaryBusinessActivity?.cnae ? (
                      `${data?.completeIdentification?.secondaryBusinessActivity?.cnae} - ${data?.completeIdentification?.secondaryBusinessActivity?.activity}`
                    ) : (
                      <NotAvailable />
                    )
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.otherCnae`)}
                  value={
                    data?.completeIdentification?.otherBusinessActivities?.cnae ? (
                      `${data?.completeIdentification?.otherBusinessActivities?.cnae} - ${data?.completeIdentification?.otherBusinessActivities?.activity}`
                    ) : (
                      <NotAvailable />
                    )
                  }
                  span={24}
                />
              </Col>
            </Row>
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.location`)}</h3>
            <Row>
              <Col span={16}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.address`)}
                  value={
                    data?.completeLocation?.headOffice?.headOfficeAddress || (
                      <NotAvailable />
                    )
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.neighborhood`)}
                  value={
                    data?.completeLocation?.headOffice?.headOfficeNeighborhood || (
                      <NotAvailable />
                    )
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.city`)}
                  value={
                    data?.completeLocation?.headOffice?.headOfficeCity || <NotAvailable />
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.state`)}
                  value={
                    data?.completeLocation?.headOffice?.headOfficeState || (
                      <NotAvailable />
                    )
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.postalCode`)}
                  value={
                    data?.completeLocation?.headOffice?.headOfficeZipCode || (
                      <NotAvailable />
                    )
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.phone`)}
                  value={
                    data?.completeLocation?.headOffice?.headOfficePhone?.phoneNumber || (
                      <NotAvailable />
                    )
                  }
                  span={24}
                />
              </Col>
            </Row>
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.onTimePayment`)}</h3>
            {data?.onTimePayment?.message || !data?.onTimePayment ? (
              <span className="text-dark">
                {data?.onTimePayment?.message ||
                  translate(`${I18N_BASE_PATH}.nadaConsta`)}
              </span>
            ) : (
              <>
                <span className="text-dark">
                  {translate(`${I18N_BASE_PATH}.pontuation`)}{' '}
                  {data?.onTimePayment[data?.onTimePayment?.length - 1]?.monthlyScore}
                </span>
                <Row className="mrg-top-20 mrg-btm-10">
                  {data?.onTimePayment?.map((otp, index) => {
                    if (index + 1 === data?.onTimePayment?.length) return <></>;
                    return (
                      <Col span={2} className="text-bold">
                        {otp?.monthYear}
                      </Col>
                    );
                  })}
                </Row>
                <Row>
                  {data?.onTimePayment?.map((otp, index) => {
                    if (index + 1 === data?.onTimePayment?.length) return <></>;
                    return <Col span={2}>{otp?.monthlyScore}</Col>;
                  })}
                </Row>
              </>
            )}
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.commitments`)}</h3>
            {data?.commitments?.message || !data?.commitments ? (
              <span className="text-dark">
                {data?.commitments?.message || translate(`${I18N_BASE_PATH}.nadaConsta`)}
              </span>
            ) : (
              <>
                <span className="text-dark">
                  {translate(`${I18N_BASE_PATH}.pontuation`)} {data?.commitments?.score}
                </span>
                <Row className="mrg-top-20 mrg-btm-10">
                  {data?.commitments?.behaviorIndicatorList?.map((otp, index) => {
                    return (
                      <Col span={2} className="text-bold">
                        {otp?.monthYear}
                      </Col>
                    );
                  })}
                </Row>
                <Row>
                  {data?.commitments?.behaviorIndicatorList?.map((otp, index) => {
                    return <Col span={2}>{otp?.monthlyScore}</Col>;
                  })}
                </Row>
              </>
            )}
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.creditObtained`)}</h3>
            {data?.creditObtained?.message || !data?.creditObtained ? (
              <span className="text-dark">
                {data?.creditObtained?.message ||
                  translate(`${I18N_BASE_PATH}.nadaConsta`)}
              </span>
            ) : (
              <>
                <span className="text-dark">
                  {translate(`${I18N_BASE_PATH}.pontuation`)}{' '}
                  {data?.creditObtained?.score}
                </span>
                <Row className="mrg-top-20 mrg-btm-10">
                  {data?.creditObtained?.behaviorIndicatorList?.map((otp, index) => {
                    return (
                      <Col span={2} className="text-bold">
                        {otp?.monthYear}
                      </Col>
                    );
                  })}
                </Row>
                <Row>
                  {data?.creditObtained?.behaviorIndicatorList?.map((otp, index) => {
                    return <Col span={2}>{otp?.monthlyScore}</Col>;
                  })}
                </Row>
              </>
            )}
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.futureCommitmentCredit`)}</h3>
            {data?.futureCommitment?.message || !data?.futureCommitment ? (
              <span className="text-dark">
                {data?.futureCommitment?.message ||
                  translate(`${I18N_BASE_PATH}.nadaConsta`)}
              </span>
            ) : (
              <>
                <span className="text-dark">
                  {translate(`${I18N_BASE_PATH}.pontuation`)}{' '}
                  {data?.futureCommitment?.creditList[0]?.score}
                </span>
                <Row className="mrg-top-20 mrg-btm-10">
                  {data?.futureCommitment?.creditList?.map((otp, index) => {
                    if (index === 0) return <></>;
                    return (
                      <Col span={6} className="text-bold">
                        {otp?.period}
                      </Col>
                    );
                  })}
                </Row>
                <Row>
                  {data?.futureCommitment?.creditList?.map((otp, index) => {
                    if (index === 0) return <></>;
                    return <Col span={6}>{otp?.score || <NotAvailable />}</Col>;
                  })}
                </Row>
              </>
            )}
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.futureCommitmentC`)}</h3>
            {data?.futureCommitment?.message || !data?.futureCommitment ? (
              <span className="text-dark">
                {data?.futureCommitment?.message ||
                  translate(`${I18N_BASE_PATH}.nadaConsta`)}
              </span>
            ) : (
              <>
                <span className="text-dark">
                  {translate(`${I18N_BASE_PATH}.pontuation`)}{' '}
                  {data?.futureCommitment?.commitmentsList[0]?.score}
                </span>
                <Row className="mrg-top-20 mrg-btm-10">
                  {data?.futureCommitment?.commitmentsList?.map((otp, index) => {
                    if (index === 0) return <></>;
                    return (
                      <Col span={6} className="text-bold">
                        {otp?.period}
                      </Col>
                    );
                  })}
                </Row>
                <Row>
                  {data?.futureCommitment?.commitmentsList?.map((otp, index) => {
                    if (index === 0) return <></>;
                    return <Col span={6}>{otp?.score || <NotAvailable />}</Col>;
                  })}
                </Row>
              </>
            )}
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.supplierRelations`)}</h3>
            {data?.otherInformation?.supplierRelationshipDuration?.message ||
            !data?.otherInformation?.supplierRelationshipDuration ? (
              <span className="text-dark">
                {data?.otherInformation?.supplierRelationshipDuration?.message ||
                  translate(`${I18N_BASE_PATH}.nadaConsta`)}
              </span>
            ) : (
              <>
                <Row className="mrg-top-20 mrg-btm-10">
                  {data?.otherInformation?.supplierRelationshipDuration?.map(
                    (otp, index) => {
                      return (
                        <Col span={4} className="text-bold">
                          {otp?.period}
                        </Col>
                      );
                    }
                  )}
                </Row>
                <Row>
                  {data?.otherInformation?.supplierRelationshipDuration?.map(
                    (otp, index) => {
                      return <Col span={4}>{otp?.amount || <NotAvailable />}</Col>;
                    }
                  )}
                </Row>
              </>
            )}
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.importantEvents`)}</h3>
            {data?.otherInformation?.importantEvents?.message ||
            !data?.otherInformation?.importantEvents ? (
              <span className="text-dark">
                {data?.otherInformation?.importantEvents?.message ||
                  translate(`${I18N_BASE_PATH}.nadaConsta`)}
              </span>
            ) : (
              <>
                <Row className="mrg-top-20 mrg-btm-10">
                  {data?.otherInformation?.importantEvents?.map((otp, index) => {
                    if (index === 0) return <Col span={6} />;
                    return (
                      <Col span={6} className="text-bold">
                        {otp?.indicator}
                      </Col>
                    );
                  })}
                </Row>
                <Row className="mrg-btm-10">
                  {data?.otherInformation?.importantEvents?.map((otp, index) => {
                    return (
                      <Col span={6} className={index === 0 ? 'text-bold' : ''}>
                        {otp?.period}
                      </Col>
                    );
                  })}
                </Row>
                <Row>
                  {data?.otherInformation?.importantEvents?.map((otp, index) => {
                    return (
                      <Col span={6} className={index === 0 ? 'text-bold' : ''}>
                        {otp?.score}
                      </Col>
                    );
                  })}
                </Row>
              </>
            )}
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.administrators`)}</h3>
            {data?.administrators?.message || !data?.administrators ? (
              <span className="text-dark">
                {data?.administrators?.message ||
                  translate(`${I18N_BASE_PATH}.nadaConsta`)}
              </span>
            ) : (
              <>
                <Table
                  columns={[
                    {
                      title: translate(`${I18N_BASE_PATH}.name`),
                      dataIndex: 'name',
                      key: 'name',
                    },
                    {
                      title: translate(`${I18N_BASE_PATH}.cpf`),
                      dataIndex: 'cpf',
                      key: 'cpf',
                    },
                    {
                      title: translate(`${I18N_BASE_PATH}.entry`),
                      dataIndex: 'entry',
                      key: 'entry',
                    },
                    {
                      title: translate(`${I18N_BASE_PATH}.mandate`),
                      dataIndex: 'mandate',
                      key: 'mandate',
                    },
                    {
                      title: translate(`${I18N_BASE_PATH}.role`),
                      dataIndex: 'position',
                      key: 'position',
                    },
                    {
                      title: translate(`${I18N_BASE_PATH}.cpfStatus`),
                      dataIndex: 'cpfStatus',
                      key: 'cpfStatus',
                    },
                  ]}
                  pagination={{ hideOnSinglePage: true }}
                  dataSource={data?.administrators}
                />
              </>
            )}
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.partsInOther`)}</h3>
            {data?.participationsInOtherCompanies?.message ||
            !data?.participationsInOtherCompanies ? (
              <span className="text-dark">
                {data?.participationsInOtherCompanies?.message ||
                  translate(`${I18N_BASE_PATH}.nadaConsta`)}
              </span>
            ) : (
              <>
                <Table
                  columns={[
                    {
                      title: translate(`${I18N_BASE_PATH}.companyName`),
                      dataIndex: 'participatedCompanyName',
                      key: 'participatedCompanyName',
                    },
                    {
                      title: translate(`${I18N_BASE_PATH}.cnpj`),
                      dataIndex: 'participatedCnpj',
                      key: 'participatedCnpj',
                    },
                    {
                      title: translate(`${I18N_BASE_PATH}.entry`),
                      dataIndex: 'entry',
                      key: 'entry',
                    },
                    {
                      title: translate(`${I18N_BASE_PATH}.participation`),
                      dataIndex: 'participation',
                      key: 'participation',
                    },
                    {
                      title: translate(`${I18N_BASE_PATH}.cnpjStatus`),
                      dataIndex: 'cnpjStatus',
                      key: 'cnpjStatus',
                    },
                  ]}
                  pagination={{ hideOnSinglePage: true }}
                  dataSource={data?.participationsInOtherCompanies}
                />
              </>
            )}
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.papioc`)}</h3>
            {data?.partnerAdministratorParticipationsInOtherCompanies?.message ||
            !data?.partnerAdministratorParticipationsInOtherCompanies ? (
              <span className="text-dark">
                {data?.partnerAdministratorParticipationsInOtherCompanies?.message ||
                  translate(`${I18N_BASE_PATH}.nadaConsta`)}
              </span>
            ) : (
              <>
                <Collapse expandIconPosition="right">
                  {data?.partnerAdministratorParticipationsInOtherCompanies?.map(
                    (papioc) => (
                      <Collapse.Panel
                        header={
                          <Row>
                            <Col span={2}>
                              {Array.isArray(papioc?.participatingPartners) &&
                                !!papioc.participatingPartners.filter(
                                  (pp) => pp?.debtIndicator === 'Sim'
                                ).length &&
                                debtIndicator}
                              {Array.isArray(papioc?.participatingPartners) &&
                                !!papioc.participatingPartners.filter(
                                  (pp) => pp?.fraudIndicator === 'Sim'
                                ).length &&
                                fraudIndicator}
                            </Col>
                            <Col span={12}>{papioc?.partnerName}</Col>
                            <Col span={8}>{papioc?.cpf}</Col>
                          </Row>
                        }
                      >
                        <Table
                          columns={[
                            {
                              title: '',
                              dataIndex: 'indicator',
                              key: 'indicator',
                              render: (_, record) => {
                                return (
                                  <>
                                    {record?.debtIndicator === 'Sim' && debtIndicator}
                                    {record?.fraudIndicator === 'Sim' && fraudIndicator}
                                  </>
                                );
                              },
                            },
                            {
                              title: translate(`${I18N_BASE_PATH}.companyName`),
                              dataIndex: 'companyName',
                              key: 'companyName',
                            },
                            {
                              title: translate(`${I18N_BASE_PATH}.cnpj`),
                              dataIndex: 'cnpj',
                              key: 'cnpj',
                            },
                            {
                              title: translate(`${I18N_BASE_PATH}.cnpjStatus`),
                              dataIndex: 'cnpjStatus',
                              key: 'cnpjStatus',
                            },
                            {
                              title: translate(`${I18N_BASE_PATH}.participation`),
                              dataIndex: 'participation',
                              key: 'participation',
                            },
                          ]}
                          pagination={{ hideOnSinglePage: true }}
                          dataSource={papioc?.participatingPartners}
                        />
                      </Collapse.Panel>
                    )
                  )}
                </Collapse>
              </>
            )}
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.inquiries`)}</h3>
            {data?.inquiries?.message || !data?.inquiries ? (
              <span className="text-dark">
                {data?.inquiries?.message || translate(`${I18N_BASE_PATH}.nadaConsta`)}
              </span>
            ) : (
              <>
                <span className="text-dark">
                  {translate(`${I18N_BASE_PATH}.quantity`)}{' '}
                  {data?.inquiries?.totalQuantity}
                </span>
                <p className="text-dark">
                  {translate(`${I18N_BASE_PATH}.period`)} {data?.inquiries?.initialPeriod}{' '}
                  - {data?.inquiries?.finalPeriod}
                </p>
                <Row className="mrg-top-20 mrg-btm-10">
                  {data?.inquiries?.monthlyInquiries?.map((otp, index) => {
                    if (index + 1 === data?.inquiries?.monthlyInquiries?.length)
                      return <></>;
                    return (
                      <Col span={2} className="text-bold">
                        {otp?.monthYear}
                      </Col>
                    );
                  })}
                </Row>
                <Row>
                  {data?.inquiries?.monthlyInquiries?.map((otp, index) => {
                    if (index + 1 === data?.inquiries?.monthlyInquiries?.length)
                      return <></>;
                    return <Col span={2}>{otp?.quantity || <NotAvailable />}</Col>;
                  })}
                </Row>
              </>
            )}
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.previousInquiries`)}</h3>
            {data?.inquiries?.message || !data?.inquiries ? (
              <span className="text-dark">
                {data?.inquiries?.message || translate(`${I18N_BASE_PATH}.nadaConsta`)}
              </span>
            ) : (
              <>
                <Table
                  columns={[
                    {
                      title: translate(`${I18N_BASE_PATH}.companyName`),
                      dataIndex: 'companyName',
                      key: 'companyName',
                    },
                    {
                      title: translate(`${I18N_BASE_PATH}.date`),
                      dataIndex: 'date',
                      key: 'date',
                    },
                    {
                      title: translate(`${I18N_BASE_PATH}.cnpj`),
                      dataIndex: 'cnpj',
                      key: 'cnpj',
                    },
                  ]}
                  pagination={{ hideOnSinglePage: true }}
                  dataSource={data?.inquiries?.recentInquiries}
                />
              </>
            )}
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.nonSufficientFundsChecks`)}</h3>
            {data?.nonSufficientFundsChecks?.message ||
            !data?.nonSufficientFundsChecks ? (
              <span className="text-dark">
                {data?.nonSufficientFundsChecks?.message ||
                  translate(`${I18N_BASE_PATH}.nadaConsta`)}
              </span>
            ) : (
              <>
                <p className="text-dark">
                  {translate(`${I18N_BASE_PATH}.quantity`)}{' '}
                  {data?.nonSufficientFundsChecks?.totalNsfChecks}
                </p>
                <Table
                  columns={[
                    {
                      title: translate(`${I18N_BASE_PATH}.name`),
                      dataIndex: 'name',
                      key: 'name',
                    },
                    {
                      title: translate(`${I18N_BASE_PATH}.bank`),
                      dataIndex: 'bankName',
                      key: 'bankName',
                    },
                    {
                      title: translate(`${I18N_BASE_PATH}.date`),
                      dataIndex: 'lastDate',
                      key: 'lastDate',
                    },
                  ]}
                  pagination={{ hideOnSinglePage: true }}
                  dataSource={data?.nonSufficientFundsChecks?.latestOccurrences}
                />
              </>
            )}
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.bankruptcies`)}</h3>
            {data?.bankruptciesAndJudicialRecoveries?.message ||
            !data?.bankruptciesAndJudicialRecoveries ? (
              <span className="text-dark">
                {data?.bankruptciesAndJudicialRecoveries?.message ||
                  translate(`${I18N_BASE_PATH}.nadaConsta`)}
              </span>
            ) : (
              <>
                <span className="text-dark">
                  {translate(`${I18N_BASE_PATH}.quantity`)}{' '}
                  {data?.bankruptciesAndJudicialRecoveries?.totalQuantity}
                </span>
                <p className="text-dark">
                  {translate(`${I18N_BASE_PATH}.period`)}{' '}
                  {data?.bankruptciesAndJudicialRecoveries?.initialPeriod} -{' '}
                  {data?.bankruptciesAndJudicialRecoveries?.finalPeriod}
                </p>
                <Table
                  columns={[
                    {
                      title: translate(`${I18N_BASE_PATH}.occurrenceType`),
                      dataIndex: 'occurrenceType',
                      key: 'occurrenceType',
                    },
                    {
                      title: translate(`${I18N_BASE_PATH}.date`),
                      dataIndex: 'date',
                      key: 'date',
                    },
                    {
                      title: translate(`${I18N_BASE_PATH}.city`),
                      dataIndex: 'city',
                      key: 'city',
                    },
                  ]}
                  pagination={{ hideOnSinglePage: true }}
                  dataSource={data?.bankruptciesAndJudicialRecoveries?.latestOccurrences}
                />
              </>
            )}
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.userReportedReturns`)}</h3>
            {data?.userReportedReturns?.message || !data?.userReportedReturns ? (
              <span className="text-dark">
                {data?.userReportedReturns?.message ||
                  translate(`${I18N_BASE_PATH}.nadaConsta`)}
              </span>
            ) : (
              <>
                <span className="text-dark">
                  {translate(`${I18N_BASE_PATH}.quantity`)}{' '}
                  {data?.userReportedReturns?.totalQuantity}
                </span>
                <p className="text-dark">
                  {translate(`${I18N_BASE_PATH}.period`)}{' '}
                  {data?.userReportedReturns?.initialPeriod} -{' '}
                  {data?.userReportedReturns?.finalPeriod}
                </p>
                <Table
                  columns={[
                    {
                      title: translate(`${I18N_BASE_PATH}.bank`),
                      dataIndex: 'bank',
                      key: 'bank',
                    },
                    {
                      title: translate(`${I18N_BASE_PATH}.date`),
                      dataIndex: 'occurrenceDate',
                      key: 'occurrenceDate',
                    },
                  ]}
                  pagination={{ hideOnSinglePage: true }}
                  dataSource={data?.userReportedReturns?.latestOccurrences}
                />
              </>
            )}
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.protests`)}</h3>
            {data?.protests?.message || !data?.protests ? (
              <span className="text-dark">
                {data?.protests?.message || translate(`${I18N_BASE_PATH}.nadaConsta`)}
              </span>
            ) : (
              <>
                <span className="text-dark">
                  {translate(`${I18N_BASE_PATH}.quantity`)}{' '}
                  {data?.protests?.totalProtests}
                </span>
                <p className="text-dark">
                  {translate(`${I18N_BASE_PATH}.totalAmount`)}{' '}
                  {data?.protests?.totalValue}
                </p>
                <Table
                  columns={[
                    {
                      title: translate(`${I18N_BASE_PATH}.amount`),
                      dataIndex: 'amount',
                      key: 'amount',
                    },
                    {
                      title: translate(`${I18N_BASE_PATH}.date`),
                      dataIndex: 'date',
                      key: 'date',
                    },
                  ]}
                  pagination={{ hideOnSinglePage: true }}
                  dataSource={data?.protests?.latestOccurrences}
                />
              </>
            )}
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.civilActions`)}</h3>
            {data?.civilActions?.message || !data?.civilActions ? (
              <span className="text-dark">
                {data?.civilActions?.message || translate(`${I18N_BASE_PATH}.nadaConsta`)}
              </span>
            ) : (
              <>
                <span className="text-dark">
                  {translate(`${I18N_BASE_PATH}.quantity`)}{' '}
                  {data?.civilActions?.totalActions}
                </span>
                <p className="text-dark">
                  {translate(`${I18N_BASE_PATH}.totalAmount`)}{' '}
                  {data?.civilActions?.totalValue}
                </p>
                <Table
                  columns={[
                    {
                      title: translate(`${I18N_BASE_PATH}.action`),
                      dataIndex: 'action',
                      key: 'action',
                    },
                    {
                      title: translate(`${I18N_BASE_PATH}.amount`),
                      dataIndex: 'amount',
                      key: 'amount',
                    },
                    {
                      title: translate(`${I18N_BASE_PATH}.date`),
                      dataIndex: 'distribution',
                      key: 'distribution',
                    },
                  ]}
                  pagination={{ hideOnSinglePage: true }}
                  dataSource={data?.civilActions?.latestOccurrences}
                />
              </>
            )}
          </div>
        </div>
      )}
    </DataSourceCardCollapsePanel>
  );
};
export default CreditScorePJ;
