/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import { cnhTranslation } from 'utils/personalTranslations';
import isValidCNH from 'utils/isValidCNH';
import EmptyExecutionState from 'components/EmptyExecutionState';

import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';
import { toMaskedCpf } from 'utils/formatters';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

import './styles.less';

const Detran = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const showDetran = useMemo(() => source.data, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const detranQuerySucceeded = useMemo(
    () =>
      source?.statusCode === '01' ||
      source?.available === true ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [source]
  );

  const detran = useMemo(() => {
    const detranData = source?.data?.detranResponse || source?.data;

    const isCNHValid = isValidCNH(detranData, 'validityDateCNH', cnhTranslation);

    return {
      validityDateCNH: detranData?.validityDateCNH,
      ...isCNHValid,
      data: detranData,
    };
  }, [source]);

  return (
    <DataSourceCardCollapsePanel
      customId="driver-infos-component"
      title={translate(
        'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.title'
      )}
      icon="caf-ic_driver"
      toPrint={toPrint}
    >
      {showDetran && (
        <div id="executions-detran-info-container">
          <Row style={{ paddingTop: 12, borderBottom: '1px solid #eee' }}>
            <Col span={24} className="detran-info-title">
              <strong>
                {translate(
                  'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showDetran.driverData.title'
                )}
              </strong>
            </Col>
          </Row>

          {detranQuerySucceeded ? (
            <>
              <Row style={{ marginTop: 12 }}>
                {showDetran?.personCPF && (
                  <ColumnTitleValue
                    title={translate(
                      'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showDetran.driverData.columnTitleValue.personCPF'
                    )}
                    span={8}
                    value={toMaskedCpf(detran?.data?.personCPF) || '-'}
                  />
                )}
                {showDetran?.personRG && (
                  <ColumnTitleValue
                    title={translate(
                      'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showDetran.driverData.columnTitleValue.personRG'
                    )}
                    span={8}
                    value={detran?.data?.personRG || '-'}
                  />
                )}
                {showDetran?.personName && (
                  <ColumnTitleValue
                    title={translate(
                      'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showDetran.driverData.columnTitleValue.personName'
                    )}
                    span={8}
                    value={detran?.data?.personName || '-'}
                  />
                )}
                {showDetran?.personBirthDate && (
                  <ColumnTitleValue
                    title={translate(
                      'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showDetran.driverData.columnTitleValue.personBirthDate'
                    )}
                    span={8}
                    value={detran?.data?.personBirthDate || '-'}
                  />
                )}
              </Row>

              <Row style={{ paddingTop: 12, borderBottom: '1px solid #eee' }}>
                <Col span={24} className="detran-info-title">
                  <strong>
                    {translate(
                      'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showDetran.cnhData.title'
                    )}
                  </strong>
                </Col>
              </Row>
              <Row style={{ marginTop: 12 }}>
                {(showDetran?.cnhRegistrationNumber || showDetran?.cnhNumber) && (
                  <ColumnTitleValue
                    title={translate(
                      'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showDetran.cnhData.columnTitleValue.cnhNumber'
                    )}
                    span={8}
                    value={
                      detran?.data?.cnhNumber ||
                      detran?.data?.cnhRegistrationNumber ||
                      '-'
                    }
                  />
                )}

                {showDetran?.cnhCategory && (
                  <ColumnTitleValue
                    title={translate(
                      'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showDetran.cnhData.columnTitleValue.cnhCategory'
                    )}
                    span={8}
                    value={detran?.data?.cnhCategory || '-'}
                  />
                )}

                {(showDetran?.cnhIssueDate || showDetran?.emissionDateCNH) && (
                  <ColumnTitleValue
                    title={translate(
                      'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showDetran.cnhData.columnTitleValue.cnhIssueDate'
                    )}
                    span={8}
                    value={showDetran?.cnhIssueDate || showDetran?.emissionDateCNH || '-'}
                  />
                )}
                {(showDetran?.cnhValidityDate || showDetran?.validityDateCNH) && (
                  <ColumnTitleValue
                    title={translate(
                      'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showDetran.cnhData.columnTitleValue.cnhValidityDate'
                    )}
                    span={8}
                    value={
                      showDetran?.cnhValidityDate || showDetran?.validityDateCNH || '-'
                    }
                  />
                )}

                {detran?.data?.cnhFirstLicenseDate && (
                  <ColumnTitleValue
                    title={translate(
                      'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showDetran.cnhData.columnTitleValue.cnhFirstLicenseDate'
                    )}
                    span={8}
                    value={detran?.data?.cnhFirstLicenseDate || '-'}
                  />
                )}

                {(detran?.data?.cnhRenachNumber || detran?.data?.renachNumber) && (
                  <ColumnTitleValue
                    title={translate(
                      'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showDetran.cnhData.columnTitleValue.renachNumber'
                    )}
                    span={8}
                    value={
                      detran?.data?.cnhRenachNumber || detran?.data?.renachNumber || '-'
                    }
                  />
                )}
                {showDetran?.cnhMirrorNumber && (
                  <ColumnTitleValue
                    title={translate(
                      'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showDetran.cnhData.columnTitleValue.cnhMirrorNumber'
                    )}
                    span={8}
                    value={showDetran?.cnhMirrorNumber || '-'}
                  />
                )}

                {(detran?.data?.ponctuation || detran?.data?.ponctuationPending) && (
                  <ColumnTitleValue
                    title={translate(
                      'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showDetran.cnhData.columnTitleValue.ponctuation.title'
                    )}
                    span={8}
                    value={
                      <>
                        {detran?.data?.ponctuation && (
                          <span>
                            {`${detran?.data?.ponctuation || 0} ${translate(
                              'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showDetran.cnhData.columnTitleValue.ponctuation.value.ponctuation'
                            )}` || '-'}
                          </span>
                        )}
                        {detran?.data?.ponctuationPending && (
                          <span>
                            {`${detran?.data?.ponctuationPending || 0} ${translate(
                              'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.showDetran.cnhData.columnTitleValue.ponctuation.value.ponctuationPending'
                            )}` || '-'}
                          </span>
                        )}
                      </>
                    }
                  />
                )}
              </Row>
            </>
          ) : (
            <EmptyExecutionState
              className="mrg-top-10"
              statusCode={statusCode}
              message={source?.message || showDetran?.message}
            />
          )}
        </div>
      )}
    </DataSourceCardCollapsePanel>
  );
};

export default Detran;
