import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import PersonAddressContent from 'pages/private/Profiles/components/Tabs/components/BasicInfos/components/Person/AddressContent';
import CompanyAddressContent from 'pages/private/Profiles/components/Tabs/components/BasicInfos/components/Company/AddressContent';

import './styles.less';

const DocumentAddress = ({ data }) => {
  const { personId, companyId } = useParams();

  return (
    <div id="to-print-address">
      {personId && <PersonAddressContent person={data} toPrint />}
      {companyId && <CompanyAddressContent company={data} toPrint />}
    </div>
  );
};

DocumentAddress.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DocumentAddress;
