/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import { useExecution } from 'hooks/execution';
import { usePerson } from 'hooks/person';
import { useCompany } from 'hooks/company';

import * as executionDataSources from 'utils/executionDataSource';
import EmptyExecutionState from 'components/EmptyExecutionState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';
import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

import './styles.less';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.people.components.personalFinance.index';

const PersonalFinance = ({ toPrint = false }) => {
  const { t: translate } = useTranslation();

  const { execution } = useExecution();
  const { company } = useCompany();
  const { person } = usePerson();

  const findSourceSection = useCallback(
    (section) =>
      executionDataSources.findSourceSection(execution, section) ||
      executionDataSources.findSourceSection(company, section) ||
      executionDataSources.findSourceSection(person, section),
    [company, execution, person]
  );

  const pfFinantialData = useMemo(() => findSourceSection('pfFinantialData'), [
    findSourceSection,
  ]);

  const pfCollections = useMemo(() => findSourceSection('pfCollections'), [
    findSourceSection,
  ]);

  const statusCodeFinantialData = useMemo(
    () => pfFinantialData?.data?.statusCode || pfFinantialData?.statusCode,
    [pfFinantialData]
  );

  const queryWorkedFinantialData = useMemo(
    () =>
      statusCodeFinantialData === '01' ||
      pfFinantialData?.available ||
      pfFinantialData?.lastConsultation,
    [pfFinantialData, statusCodeFinantialData]
  );

  const statusCodeCollections = useMemo(
    () => pfCollections?.data?.statusCode || pfCollections?.statusCode,
    [pfCollections]
  );

  const queryWorkedCollections = useMemo(
    () =>
      statusCodeCollections === '01' ||
      pfCollections?.available ||
      pfCollections?.lastConsultation,
    [pfCollections, statusCodeCollections]
  );

  const totalAssets = useMemo(() => pfFinantialData?.data?.totalAssets, [
    pfFinantialData,
  ]);

  const renderIncomeEstimate = useCallback(
    (income) => {
      return (
        income &&
        income
          .toUpperCase()
          .replace(
            '1/2 A 1 SM',
            translate(`${I18N_BASE_PATH}.renderIncomeEstimate.1/2_A_1_SM`)
          )
          .replace(
            'DE 1 A 1,5 SM',
            translate(`${I18N_BASE_PATH}.renderIncomeEstimate.DE_1_A_1.5_SM`)
          )
          .replace(
            'ATE 2 SM',
            translate(`${I18N_BASE_PATH}.renderIncomeEstimate.ATE_2_SM`)
          )
          .replace(
            '2 A 4 SM',
            translate(`${I18N_BASE_PATH}.renderIncomeEstimate.2_A_4_SM`)
          )
          .replace(
            '4 A 10 SM',
            translate(`${I18N_BASE_PATH}.renderIncomeEstimate.4_A_10_SM`)
          )
          .replace(
            '10 A 20 SM',
            translate(`${I18N_BASE_PATH}.renderIncomeEstimate.10_A_20_SM`)
          )
          .replace(
            'ACIMA DE 20 SM',
            translate(`${I18N_BASE_PATH}.renderIncomeEstimate.ACIMA_DE_20_SM`)
          )
          .replace(
            'SEM INFORMACAO',
            translate(`${I18N_BASE_PATH}.renderIncomeEstimate.SEM_INFORMACAO`)
          )
      );
    },
    [translate]
  );

  const renderCollections = useCallback(
    (ocurrences, origins) => {
      if (ocurrences === 1) {
        return translate(`${I18N_BASE_PATH}.renderCollections.occurrencesEqualOne`);
      }
      if (ocurrences > 1 && origins === 1) {
        return translate(
          `${I18N_BASE_PATH}.renderCollections.moreThanOneOccurrencesAndOrigins`,
          { ocurrences, origins }
        );
      }
      if (ocurrences > 1) {
        return translate(`${I18N_BASE_PATH}.renderCollections.moreThanOneOccurrences`, {
          ocurrences,
          origins,
        });
      }
      return '';
    },
    [translate]
  );

  return (
    <>
      <DataSourceCardCollapsePanel
        title={translate(`${I18N_BASE_PATH}.title`)}
        icon="caf-ic_person"
        toPrint={toPrint}
        customId="financialInfo"
      >
        <Row id="personal-finance-container">
          {pfFinantialData && (
            <ColumnLabel
              span={12}
              title={translate(
                `${I18N_BASE_PATH}.personalFinance.columnLabel.estimatedIncome.title`
              )}
            >
              {queryWorkedFinantialData ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    width: '100%',
                  }}
                >
                  <span>
                    •{' '}
                    {translate(
                      `${I18N_BASE_PATH}.personalFinance.columnLabel.estimatedIncome.incomeEstimatesBigData`
                    )}
                    :{' '}
                    {renderIncomeEstimate(
                      pfFinantialData?.data?.incomeEstimates?.bigData
                    )}
                  </span>
                  <span>
                    •{' '}
                    {translate(
                      `${I18N_BASE_PATH}.personalFinance.columnLabel.estimatedIncome.incomeEstimatesCompanyOwnership`
                    )}
                    :{' '}
                    {renderIncomeEstimate(
                      pfFinantialData?.data?.incomeEstimates?.companyOwnership
                    )}
                  </span>
                </div>
              ) : (
                <EmptyExecutionState
                  statusCode={statusCodeFinantialData}
                  message={pfFinantialData?.message || pfFinantialData?.data?.message}
                />
              )}
            </ColumnLabel>
          )}

          {pfFinantialData && (
            <ColumnLabel
              span={12}
              title={translate(
                `${I18N_BASE_PATH}.personalFinance.columnLabel.estimatedEquity.title`
              )}
              customClassName="text-start"
            >
              {queryWorkedFinantialData ? (
                <span className="no-mrg">
                  {totalAssets
                    ? renderIncomeEstimate(totalAssets)
                    : translate(
                        `${I18N_BASE_PATH}.personalFinance.columnLabel.estimatedEquity.totalAssets`
                      )}
                </span>
              ) : (
                <EmptyExecutionState
                  statusCode={statusCodeFinantialData}
                  message={pfFinantialData?.message || pfFinantialData?.data?.message}
                />
              )}
            </ColumnLabel>
          )}

          {pfCollections && (
            <ColumnLabel
              span={12}
              title={translate(
                `${I18N_BASE_PATH}.personalFinance.columnLabel.presenceInCollection.title`
              )}
              customClassName="text-start"
            >
              {queryWorkedCollections ? (
                <span>
                  {pfCollections?.data?.collectionOcurrences
                    ? renderCollections(
                        pfCollections?.data?.collectionOcurrences,
                        pfCollections?.data?.collectionOrigins
                      )
                    : translate(
                        `${I18N_BASE_PATH}.personalFinance.columnLabel.presenceInCollection.collectionOrigins`
                      )}
                </span>
              ) : (
                <EmptyExecutionState
                  statusCode={statusCodeCollections}
                  message={pfCollections?.message || pfCollections?.data?.message}
                />
              )}
            </ColumnLabel>
          )}

          {pfFinantialData && (
            <ColumnLabel
              span={12}
              title={translate(
                `${I18N_BASE_PATH}.personalFinance.columnLabel.IRRefunds.title`
              )}
              customClassName="text-start"
            >
              {queryWorkedFinantialData ? (
                <>
                  {pfFinantialData?.data?.taxReturns?.length > 0 ? (
                    <ul>
                      {pfFinantialData?.data?.taxReturns?.map((item) => (
                        <li>
                          {translate(
                            `${I18N_BASE_PATH}.personalFinance.columnLabel.IRRefunds.taxReturns.date`
                          )}{' '}
                          {item?.year} ({item?.status})
                        </li>
                      ))}
                    </ul>
                  ) : (
                    translate(
                      `${I18N_BASE_PATH}.personalFinance.columnLabel.IRRefunds.taxReturns.default`
                    )
                  )}
                </>
              ) : (
                <EmptyExecutionState
                  statusCode={statusCodeFinantialData}
                  message={pfFinantialData?.message || pfFinantialData?.data?.message}
                />
              )}
            </ColumnLabel>
          )}
        </Row>
      </DataSourceCardCollapsePanel>
    </>
  );
};

export default PersonalFinance;
