import React, { useCallback, useState } from 'react';
import { useAuth } from 'hooks/auth';
import { Avatar, message, Popover, Select, Form, Button, Menu, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useUpdateProfileModal from 'components/ModalUpdateProfile';
import { ArrowDown, ArrowUp } from '@combateafraude/icons/general';
import { useFetch } from 'services/hooks';
import classNames from 'classnames';

import './styles.less';
import Cookies from 'js-cookie';
import { secondsToDay } from 'utils/formatters';

const languages = [
  {
    value: 'pt-BR',
    title: 'Português (Brasil)',
  },
  {
    value: 'en-US',
    title: 'English',
  },
  {
    value: 'es-MX',
    title: 'Spanish',
  },
];

const isLocalhost = !!window.location.origin.startsWith('http://localhost');
const isCafIo = window.location.origin.indexOf('caf.io') !== -1;

const ACCESS_TOKEN = isLocalhost ? 'CAF_access_token' : '__Secure-CAF_access_token';

// eslint-disable-next-line no-nested-ternary
const COOKIE_OPTIONS = isLocalhost
  ? {
      path: '/',
    }
  : isCafIo
  ? {
      domain: '.caf.io',
      secure: true,
      sameSite: 'strict',
    }
  : {
      domain: '.combateafraude.com',
      secure: true,
      sameSite: 'strict',
    };

const languageMap = (lang) => {
  switch (lang) {
    case 'en-US':
      return 'en.';
    case 'es-MX':
      return 'es.';
    default:
      return 'www.';
  }
};

const UserMenu = (props) => {
  const { handleCollapse, collapsed } = props;

  const { t: translate, i18n } = useTranslation();
  const { openModal, UpdateProfileModal } = useUpdateProfileModal();
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const { user, signOut } = useAuth();
  const [form] = Form.useForm();

  const { put, post } = useFetch();

  const handleUserMenuOpenChange = (open) => {
    setUserMenuOpen(open);

    if (open) {
      handleCollapse(false);
    }
  };

  const handleOpenUserModal = () => {
    setUserMenuOpen(false);
    openModal();
  };

  const handleUpdateLanguage = useCallback(
    async (payload) => {
      try {
        await put({
          url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/users/${user.id}`,
          payload: {
            name: user.name,
            language: payload?.language,
          },
        });
        window.location.reload();
      } catch {
        message.error(
          translate('components.modalUpdateProfile.handleUpdate.messages.error')
        );
      }
    },
    [put, user, translate]
  );

  const handleChangeAccount = useCallback(
    async (tenantId) => {
      try {
        const { expires_in, access_token } = await post({
          url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/token-hub-accounts`,
          payload: {
            username: user?.username,
            tenantId,
          },
        });

        const ACCESS_TOKEN_OPTIONS = {
          ...COOKIE_OPTIONS,
          expires: secondsToDay(expires_in),
        };

        Cookies.set(ACCESS_TOKEN, access_token, ACCESS_TOKEN_OPTIONS);
        window.location.href = '/';
      } catch {
        message.error(
          translate('components.modalUpdateProfile.handleUpdate.messages.error')
        );
      }
    },
    [post, user, translate]
  );

  return (
    <div
      className={classNames('sidebar-user-info', {
        collapsed,
      })}
    >
      {UpdateProfileModal}
      <Popover
        onOpenChange={handleUserMenuOpenChange}
        open={userMenuOpen}
        content={
          <div className="sidebar-user-menu">
            <Button
              className="sidebar-user-edit-menu-button"
              onClick={handleOpenUserModal}
              block
            >
              {translate(`components.userInfo.index.editButton`)}
            </Button>

            {user?.hubData?.active && user?.hubData?.hubAccountsData?.length > 1 && (
              <>
                <hr />
                <Form
                  form={form}
                  layout="vertical"
                  initialValues={{ account: user?.accountData?.tenantId }}
                  style={{ width: '90%' }}
                >
                  <div className="mrg-left-5">
                    <p className="text-dark">
                      {translate('components.userInfo.index.hubAccounts.label')}
                    </p>
                  </div>
                  <Form.Item name="account" className="preferred-language">
                    <Select
                      className="sidebar-user-language-select"
                      onChange={(e) => {
                        handleChangeAccount(e);
                      }}
                      style={{ width: '100%', maxWidth: '227px' }}
                    >
                      {user?.hubData?.hubAccountsData?.map((account) => (
                        <Select.Option key={account?.tenantId} value={account?.tenantId}>
                          <Tooltip title={account?.fantasyName || account?.companyName}>
                            {account?.fantasyName || account?.companyName}
                          </Tooltip>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form>
              </>
            )}
            <hr />

            <Form
              form={form}
              layout="vertical"
              onFinish={handleUpdateLanguage}
              initialValues={{ language: i18n.language }}
              style={{ width: '90%' }}
            >
              <div className="mrg-left-5">
                <p className="text-dark">
                  {translate('components.userInfo.index.language.label')}
                </p>
              </div>
              <Form.Item name="language" className="preferred-language">
                <Select
                  className="sidebar-user-language-select"
                  onChange={() => {
                    form.submit();
                  }}
                  style={{ width: '100%' }}
                >
                  {languages?.map((language) => (
                    <Select.Option key={language?.value} value={language?.value}>
                      {language?.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
            <hr />

            <Menu>
              <Menu.Item>
                <a
                  href={`https://${languageMap(i18n.language)}caf.io/privacy-policy`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg height="20" width="20">
                    <circle cx="10" cy="10" r="4" fill="#004af7" />
                  </svg>
                  <p>{translate(`components.userInfo.index.links.privacyPolicy`)}</p>
                </a>
              </Menu.Item>
              <Menu.Item>
                <a
                  href={`https://${languageMap(
                    i18n.language
                  )}caf.io/politicas/termos-e-condicoes-de-uso`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg height="20" width="20">
                    <circle cx="10" cy="10" r="4" fill="#004af7" />
                  </svg>
                  <p>
                    {translate(`components.userInfo.index.links.termsAndConditionsOfUse`)}
                  </p>
                </a>
              </Menu.Item>
            </Menu>
            <hr />
            <Button type="primary" style={{ marginTop: 10 }} onClick={signOut}>
              {translate(`components.userInfo.index.button`)}
            </Button>
          </div>
        }
        trigger="click"
        placement="bottom"
      >
        <Button
          className="sidebar-user-menu-button"
          type="link"
          onClick={() => handleUserMenuOpenChange(true)}
        >
          <Avatar
            className="sidebar-user-avatar"
            src={user?.profileImageUrl}
            size={collapsed ? 50 : 70}
          >
            <span>{user?.name?.charAt(0)?.toUpperCase()}</span>
          </Avatar>
          {!collapsed && (
            <p>
              {user?.name ?? user?.username}
              {userMenuOpen ? (
                <ArrowUp style={{ width: '25px', height: '25px' }} />
              ) : (
                <ArrowDown style={{ width: '25px', height: '25px' }} />
              )}
            </p>
          )}
        </Button>
      </Popover>
    </div>
  );
};

export default UserMenu;

UserMenu.propTypes = {
  handleCollapse: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
};
