import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

const SearchInput = ({
  placeholder,
  icon,
  name,
  disabled,
  className,
  allowClear,
  ...rest
}) => {
  return (
    <Form.Item {...rest} name={name} className={`no-mrg-btm ${className}`}>
      <Input
        disabled={disabled}
        className="custom-search-input"
        autoComplete="off"
        placeholder={placeholder}
        prefix={<i className={icon} />}
        allowClear={allowClear}
      />
    </Form.Item>
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  allowClear: PropTypes.bool,
};

SearchInput.defaultProps = {
  placeholder: undefined,
  icon: 'caf-ic_search',
  name: '_search',
  disabled: false,
  className: '',
  allowClear: false,
};

export default SearchInput;
