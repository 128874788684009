import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Modal, Spin, Form, Divider, message } from 'antd';

import { useFetch } from 'services/hooks';

import { usePerson } from 'hooks/person';
import { useCompany } from 'hooks/company';

import PageTitle from 'components/PageTitle';
import Button from 'components/Button';
import useModalConfirmAction from 'components/ModalConfirmAction';
import CardExecution from './components/CardExecution';

import './styles.less';

const I18N_BASE_PATH =
  'pages.private.profiles.components.profileValidations.components.pendingReviewModal.index';

const PendingReviewModal = () => {
  const { t: translate } = useTranslation();

  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [onlyPendingExecutions, setOnlyPendingExecutions] = useState();
  const [form] = Form.useForm();

  const { companyId } = useParams();
  const { company, loadingCompany } = useCompany();
  const { person, loadingPerson } = usePerson();

  const { post: postExecutions, loading: loadingSendingExecutions } = useFetch();
  const {
    get: getPendingExecutions,
    data: pendingExecutions,
    loading: loadingPendingExecutions,
    clearData: clearPendingExecutions,
  } = useFetch();

  const profileData = useMemo(() => {
    return companyId ? company : person;
  }, [companyId, company, person]);

  const idsOfPendingExecutions = useMemo(() => {
    const allExecutionsData = [];

    profileData?.migrationReports?.map((report) => {
      return allExecutionsData.push(report);
    });

    profileData?.compliances?.map((report) => {
      return allExecutionsData.push(report);
    });

    profileData?.executions?.map((report) => {
      return allExecutionsData.push(report);
    });

    const backgroundCheckingValidations = Object.entries(
      profileData?.backgroundChecking?.services || {}
    );

    backgroundCheckingValidations.map(([_, value]) => {
      return allExecutionsData.push(value);
    });

    const onlyPendingExecutionsArray = allExecutionsData?.filter(
      (execution) => execution?.status === 'PENDING'
    );

    const allExecutionsIds = onlyPendingExecutionsArray?.map((execution) => {
      return `${execution?.executionId}`;
    });

    const executionsIds = [...new Set(allExecutionsIds)];

    setOnlyPendingExecutions(onlyPendingExecutionsArray);
    return executionsIds?.join('|');
  }, [profileData]);

  const loadExecutionsGroupedByDate = useCallback(async () => {
    await getPendingExecutions({
      url: 'executions',
      config: {
        params: {
          _ids: idsOfPendingExecutions,
          _groupBy: 'date',
        },
      },
    });
  }, [getPendingExecutions, idsOfPendingExecutions]);

  const handleCancel = useCallback(() => {
    setModalIsVisible(false);
  }, []);

  const closeModal = useCallback(() => {
    setModalIsVisible(false);
    clearPendingExecutions();
    form.resetFields();
  }, [clearPendingExecutions, form]);

  const openPendingReviewModal = useCallback(() => {
    setModalIsVisible(true);
    loadExecutionsGroupedByDate();
  }, [loadExecutionsGroupedByDate]);

  const handleSubmit = useCallback(
    async (fields) => {
      const fieldsArray = Object.keys(fields || {});

      const hasOneEmptyField = fieldsArray?.find((field) =>
        ['APPROVED', 'REPROVED'].includes(fields[field].validationStatus)
      );

      if (!hasOneEmptyField) {
        message.error(
          translate(`${I18N_BASE_PATH}.handleSubmit.hasNotOneEmptyField`),
          1.5
        );
        return;
      }

      try {
        await form.validateFields();
      } catch (error) {
        return;
      }

      const executions = [];

      pendingExecutions?.docs?.map((doc) => {
        return doc?.items?.map((item) => {
          return executions.push({
            _id: item?._id,
            reportId: item?.report,
            status: item?.status,
            reason: item?.reason,
            executionOrigin: onlyPendingExecutions?.find(
              (e) => e?.executionId === item?._id
            )?.executionOrigin,
          });
        });
      });

      try {
        await postExecutions({
          url: `/${companyId ? 'companies' : 'people'}/${profileData?._id}/manual-review`,
          payload: { executions, hasMissingOnboarding: fields.hasMissingOnboarding },
        });

        message.success(translate(`${I18N_BASE_PATH}.handleSubmit.messages.success`));
      } catch (error) {
        message.error(translate(`${I18N_BASE_PATH}.handleSubmit.messages.error`));
      } finally {
        setModalIsVisible(false);
      }
    },
    [
      pendingExecutions,
      form,
      onlyPendingExecutions,
      postExecutions,
      companyId,
      profileData,
      translate,
    ]
  );

  const { openModal: openConfirmModal, ConfirmActionModal } = useModalConfirmAction({
    action: handleSubmit,
    okButton: translate(`${I18N_BASE_PATH}.hasMissingOnboardingModal.form.button.submit`),
    loading: loadingSendingExecutions,
    cancelButton: translate(
      `${I18N_BASE_PATH}.hasMissingOnboardingModal.form.button.cancel`
    ),
    title: translate(`${I18N_BASE_PATH}.hasMissingOnboardingModal.pageTitle.title`),
    subtitle: translate(`${I18N_BASE_PATH}.hasMissingOnboardingModal.pageTitle.subtitle`),
  });

  const validatePartnerOnboardings = useCallback(
    async (fields) => {
      if (!company) return handleSubmit(fields);

      const arrayStatus = pendingExecutions?.docs?.map((doc) => {
        return doc?.items?.map((item) => {
          return item?.status;
        });
      });

      const hasApprovedStatus = arrayStatus
        ?.flat()
        ?.some((status) => status === 'APPROVED');

      const hasMissingOnboarding = company?.QSA?.items?.some(
        (partner) => !partner?.onboardingId
      );
      if (!hasMissingOnboarding || !hasApprovedStatus) return handleSubmit(fields);

      fields.hasMissingOnboarding = hasMissingOnboarding;
      return openConfirmModal(fields);
    },
    [company, handleSubmit, openConfirmModal, pendingExecutions]
  );

  const PendingReviewModalContent = useMemo(
    () => (
      <>
        {ConfirmActionModal}
        <Modal
          wrapClassName="caf-modal"
          closable
          width={850}
          footer={null}
          closeIcon={<i className="caf-ic_close font-size-18" />}
          onCancel={closeModal}
          visible={modalIsVisible}
        >
          <div id="pending-review-modal-content">
            <PageTitle
              title={translate(`${I18N_BASE_PATH}.pendingReviewModal.pageTitle.title`)}
              subtitle={translate(
                `${I18N_BASE_PATH}.pendingReviewModal.pageTitle.subtitle`
              )}
            />

            <Form
              id="pending-review-modal-form"
              layout="vertical"
              onFinish={validatePartnerOnboardings}
              form={form}
            >
              {loadingPendingExecutions ? (
                <Spin className="flex center gx-w-100 mrg-top-30 mrg-btm-40" />
              ) : (
                <>
                  {pendingExecutions?.docs?.map((execution) => (
                    <div className="pending-review-items">
                      <CardExecution key={execution?.date} data={execution} />
                      <Divider />
                    </div>
                  ))}

                  <div className="gx-flex-row gx-justify-content-end mrg-top-40">
                    <Button
                      type="default"
                      className="btn-cancel"
                      disabled={loadingPendingExecutions}
                      onClick={handleCancel}
                    >
                      {translate(
                        `${I18N_BASE_PATH}.pendingReviewModal.form.button.cancel`
                      )}
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="apply-changes-button"
                      loading={loadingSendingExecutions}
                      disabled={
                        loadingPendingExecutions ||
                        loadingCompany ||
                        loadingPerson ||
                        loadingSendingExecutions
                      }
                    >
                      {translate(
                        `${I18N_BASE_PATH}.pendingReviewModal.form.button.submit`
                      )}
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </div>
        </Modal>
      </>
    ),
    [
      ConfirmActionModal,
      closeModal,
      modalIsVisible,
      translate,
      validatePartnerOnboardings,
      form,
      loadingPendingExecutions,
      pendingExecutions,
      handleCancel,
      loadingSendingExecutions,
      loadingCompany,
      loadingPerson,
    ]
  );

  return { openPendingReviewModal, PendingReviewModalContent };
};

export default PendingReviewModal;
