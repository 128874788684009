import React, { useEffect, useState } from 'react';
import { Card, Typography, Row, Switch, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import GearIcon from 'assets/images/gear.svg';
import { Tooltip as TooltipIcon } from '@combateafraude/icons/general';

import './styles.less';

const { Title } = Typography;

const SessionConfigurationCard = ({
  title,
  Icon,
  tooltipHint,
  loading,
  children,
  isDisabledSwitch,
  handleToggleSwitch,
  isDefaultSwitchChecked,
  isDisabledConfiguration,
}) => {
  const [showChildren, setShowChildren] = useState(false);

  const handleClickConfiguration = () =>
    setShowChildren((previousValue) => !previousValue);

  const toggleSwitch = (value) => {
    handleToggleSwitch(value);
  };

  useEffect(() => {
    if (isDefaultSwitchChecked) {
      setShowChildren(true);
    } else {
      setShowChildren(false);
    }
  }, [isDefaultSwitchChecked]);

  return (
    <Card className="container-session-configuration">
      <section className="configuration-section">
        <Row className="row-section">
          {Icon && <Icon />}
          {title && (
            <Title level={5} className="title">
              {title}
            </Title>
          )}
          {tooltipHint && (
            <Tooltip
              title={tooltipHint}
              autoAdjustOverflow
              arrowPointAtCenter
              color="#323232"
              trigger="hover"
              overlayInnerStyle={{
                padding: '0.5rem',
              }}
            >
              <TooltipIcon className="tooltip-icon" />
            </Tooltip>
          )}
        </Row>
        <div className="control-buttons">
          {!isDisabledConfiguration && (
            <button type="button" onClick={handleClickConfiguration}>
              <img src={GearIcon} alt="gear icon" />
            </button>
          )}
          {!isDisabledSwitch && (
            <Switch
              checked={isDefaultSwitchChecked}
              defaultChecked={isDefaultSwitchChecked}
              autoFocus
              onChange={toggleSwitch}
              loading={loading}
            />
          )}
        </div>
      </section>

      {showChildren && !!children && children}
    </Card>
  );
};

SessionConfigurationCard.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  Icon: PropTypes.elementType,
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  isDisabledSwitch: PropTypes.bool,
  handleToggleSwitch: PropTypes.func,
  isDefaultSwitchChecked: PropTypes.bool,
  isDisabledConfiguration: PropTypes.bool,
  tooltipHint: PropTypes.string,
};

SessionConfigurationCard.defaultProps = {
  title: undefined,
  Icon: undefined,
  children: null,
  isDisabledSwitch: true,
  handleToggleSwitch: () => {},
  isDisabledConfiguration: false,
  isDefaultSwitchChecked: null,
  loading: false,
  tooltipHint: undefined,
};

export default SessionConfigurationCard;
