/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyExecutionState from 'components/EmptyExecutionState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import { isEmpty } from 'utils/isObjectEmpty';

import useCNDTDebtsModal from './CNDTDebtsModal';

import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

const CNDT = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const { openModal, CNDTDebtsModal } = useCNDTDebtsModal({
    data: source,
  });

  const handlerStatusAndType = useMemo(() => {
    if (!parsedData || !queryWorked)
      return {
        description: null,
        type: null,
      };

    if (!parsedData?.lawsuitsList || parsedData?.lawsuitsList?.length === 0) {
      return {
        description: translate(
          'components.dataSourceCards.laborInformation.companies.components.cndt.index.handlerStatusAndType.hasNotLawsuitsList'
        ),
        type: 'primary',
      };
    }

    if (parsedData?.lawsuitsList?.length === 1) {
      return {
        description: translate(
          'components.dataSourceCards.laborInformation.companies.components.cndt.index.handlerStatusAndType.oneLawsuit'
        ),
        type: 'danger',
      };
    }

    return {
      description: translate(
        'components.dataSourceCards.laborInformation.companies.components.cndt.index.handlerStatusAndType.moreThanOneLawsuits',
        { value: parsedData?.lawsuitsList?.length }
      ),
      type: 'danger',
    };
  }, [parsedData, queryWorked, translate]);

  const handleOpenModal = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <>
      {CNDTDebtsModal}
      <DataSourceCardCollapsePanel
        title={translate(
          'components.dataSourceCards.laborInformation.companies.components.cndt.index.cndt.columnLabel.title'
        )}
        customId="labor-infos-component"
        icon="caf-ic_awards"
        toPrint={toPrint}
      >
        <ColumnLabel
          title={translate(
            'components.dataSourceCards.laborInformation.companies.components.cndt.index.cndt.columnLabel.title'
          )}
          description={queryWorked && handlerStatusAndType.description}
          type={handlerStatusAndType.type}
          onClick={handlerStatusAndType.type === 'danger' && handleOpenModal}
          info={
            !toPrint &&
            translate(
              'components.dataSourceCards.laborInformation.companies.components.cndt.index.cndt.columnLabel.info'
            )
          }
        >
          {queryWorked ? (
            <>
              {isEmpty(parsedData) && (
                <span>
                  {translate(
                    'components.dataSourceCards.laborInformation.companies.components.cndt.index.cndt.columnLabel.querySucceeded.empty'
                  )}
                </span>
              )}
              {parsedData && (
                <>
                  {parsedData?.lawsuitsList?.length > 0 ? (
                    <>
                      {!toPrint && (
                        <span>
                          {translate(
                            'components.dataSourceCards.laborInformation.companies.components.cndt.index.cndt.columnLabel.querySucceeded.hasData'
                          )}
                        </span>
                      )}
                    </>
                  ) : (
                    <span>
                      {translate(
                        'components.dataSourceCards.laborInformation.companies.components.cndt.index.cndt.columnLabel.querySucceeded.hasNotData'
                      )}
                    </span>
                  )}
                </>
              )}
            </>
          ) : (
            <EmptyExecutionState
              statusCode={statusCode}
              message={source?.message || parsedData?.message}
            />
          )}
        </ColumnLabel>
      </DataSourceCardCollapsePanel>
    </>
  );
};

export default CNDT;
