import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col, Upload, Typography, message } from 'antd';
import { bytesToMb } from 'utils/formatters';
import { useUploadManager } from 'hooks/uploadManager';
import Card from 'components/Card';

const { Text } = Typography;
const { Dragger } = Upload;

const wordFormats = '.doc,.docm,.docx,.dot,.dotm,.dotx,.odt,.rtf,.pdf,.xml,.xps';
const excelFormats = '.csv,.ods,.xla,.xlam,.xls,.xlsb,.xlsm,.xlsx,.xlt,.xltm,.xltx,.xlw';
const powerPointFormats =
  '.odp,.pot,.potm,.potx,.ppa,.ppam,.pps,.ppsm,.ppsx,.ppt,.pptm,.pptx';

const ArchivesUploadCard = ({
  closeModal,
  allowedMimeTypes = [],
  limitFileSize,
  limitFileQuantity,
  isTransactionRecord = false,
}) => {
  const { t: translate } = useTranslation();

  const { companyId, personId, transactionId, executionId } = useParams();
  const { openUploadWidget, setFileToUpload } = useUploadManager();
  let errorNotificated = false;

  return (
    <Card id="card-archives-upload">
      <Dragger
        className="dragger"
        name="contracts"
        multiple
        accept={
          allowedMimeTypes.length < 1
            ? `text/*,image/*,audio/*,video/*,${wordFormats},${excelFormats},${powerPointFormats}`
            : allowedMimeTypes.join(',')
        }
        beforeUpload={(file, fileList) => {
          const exceededSize = !!limitFileSize && limitFileSize < file.size;
          const exceededQuantity =
            !!limitFileQuantity && fileList.length > limitFileQuantity;

          if (exceededSize || exceededQuantity) {
            if (!errorNotificated) {
              errorNotificated = true;
              const translationKey = exceededSize
                ? 'max_size_exceeded'
                : 'max_quantity_exceeded';
              message.error(translate(`validations.upload.${translationKey}`));
              closeModal();
            }
            return false;
          }
          setFileToUpload(file, {
            companyId,
            personId,
            executionId,
            transactionId,
            isTransactionRecord,
          });
          if (file.uid === fileList[fileList.length - 1].uid) {
            closeModal();
            openUploadWidget();
          }
          return false;
        }}
        showUploadList={false}
      >
        <Row>
          <Col className="upload-icon-container" span={24}>
            <div className="upload-icon">
              <i className="caf-ic_upload" />
            </div>
          </Col>

          <Col className="mrg-top-20" span={24}>
            <Text className="upload-title">
              {translate('components.modalUpload.archivesUploadCard.text.before')}{' '}
              <span className="upload-title-select">
                {translate('components.modalUpload.archivesUploadCard.text.after')}
              </span>
            </Text>

            <span className="upload-title-supported">
              {translate(
                `components.modalUpload.archivesUploadCard.${
                  isTransactionRecord ? 'descriptionTransactionRecords' : 'description'
                }`
              )}
            </span>
            {limitFileSize && (
              <span className="upload-title-supported">
                {translate(`components.modalUpload.archivesUploadCard.limitFileSize`)}
                {bytesToMb(limitFileSize)} MB
              </span>
            )}
            {isTransactionRecord && (
              <span className="upload-title-supported">
                {translate(`components.modalUpload.archivesUploadCard.maximumQuantity`)}
              </span>
            )}
          </Col>
        </Row>
      </Dragger>
    </Card>
  );
};

ArchivesUploadCard.propTypes = {
  closeModal: PropTypes.func.isRequired,
  allowedMimeTypes: PropTypes.arrayOf(PropTypes.string),
  limitFileSize: PropTypes.string,
  limitFileQuantity: PropTypes.number,
  isTransactionRecord: PropTypes.bool,
};

ArchivesUploadCard.defaultProps = {
  allowedMimeTypes: [],
  limitFileSize: null,
  limitFileQuantity: undefined,
  isTransactionRecord: false,
};

export default ArchivesUploadCard;
