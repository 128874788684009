import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

import './styles.less';

const ProcessTag = ({ title, type }) => {
  return (
    <Tag id="executions-custom-process-tag" className={type}>
      {title}
    </Tag>
  );
};

ProcessTag.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
};

ProcessTag.defaultProps = {
  type: 'default',
};

export default ProcessTag;
