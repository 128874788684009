import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Row } from 'antd';
import Tag from 'components/Tag';

import PageTitle from 'components/PageTitle';

const I18N = `pages.private.userManagement.usersCreate.components.confirmationStep.index`;

const { Title } = Typography;

const ConfirmationStep = ({ users, selectedGroup }) => {
  const { t: translate } = useTranslation();
  return (
    <div className="flex-column center pdd-vertical-80">
      <PageTitle title={translate(`${I18N}.sendTo`)} />
      <Row>
        {users?.map((user) => (
          <Tag color="success" className="mrg-right-5">
            {user}
          </Tag>
        ))}
      </Row>
      <Title level={3} className="mrg-top-30">
        {translate(`${I18N}.groupPermissions`)}
      </Title>
      <div>{selectedGroup?.name}</div>
    </div>
  );
};

export default ConfirmationStep;
