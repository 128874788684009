import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import {
  cnhTranslation,
  cpfTranslation,
  electoralSituationTranslation,
  executionCpfErrosTranslation,
} from 'utils/personalTranslations';
import { toMaskedCpf } from 'utils/formatters';
import isValidCNH from 'utils/isValidCNH';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';
import { useExecution } from 'hooks/execution';
import BasicInfosCard from '../BasicInfosCard';

const I18N_BASE_PATH =
  'pages.private.executions.components.dataSourceCards.basicInfos.components.person.registrationInfosContent.registrationInfosContent.basicInfosCard';

const RegistrationInfosContent = () => {
  const { t: translate } = useTranslation();
  const { i18nFormatDateOnly } = useI18nFormatters();

  const { execution } = useExecution();

  const basicData = useMemo(() => execution?.sections?.pfBasicData?.data, [execution]);

  const cpf = useMemo(() => {
    if (
      !execution?.sections?.cpf &&
      !execution?.sections?.pfData &&
      !execution?.sections?.cpfData &&
      !execution?.sections?.pfCpfData
    ) {
      return {
        cpf: null,
        translatedMessage: cpfTranslation.NULA,
      };
    }

    const cpfData = execution?.sections?.cpf || {};
    const pfData = execution?.sections?.pfData || {};
    const pfCpfData = execution?.sections?.pfCpfData || {};

    const parsedRegistrationStatusCode = cpfData?.registrationStatusCode
      ? parseInt(cpfData?.registrationStatusCode, 10)?.toString()
      : undefined;

    const translatedMessage =
      cpfData?.statusCode !== '00' &&
      (!pfData?.statusCode || pfData?.statusCode !== '01') &&
      pfCpfData?.statusCode !== '01'
        ? executionCpfErrosTranslation[pfCpfData?.statusCode] ||
          executionCpfErrosTranslation[cpfData?.statusCode] ||
          executionCpfErrosTranslation[pfData?.statusCode] ||
          executionCpfErrosTranslation['01']
        : cpfTranslation[
            (
              pfCpfData?.data?.taxIdStatus ||
              cpfData.registrationStatusMessage ||
              parsedRegistrationStatusCode ||
              pfData?.data?.taxIdStatus ||
              ''
            ).trim()
          ] || cpfTranslation.NULA;

    return {
      cpf: execution?.parameters?.cpf,
      translatedMessage,
      ...cpfData,
    };
  }, [execution]);

  const electoralSituation = useMemo(() => {
    const electoralSituationData = execution?.sections?.pfElectoralSituation;
    if (!electoralSituationData) {
      return {
        voterRegistration: null,
        translatedSituation: null,
      };
    }

    return {
      voterRegistration: electoralSituationData?.voterRegistration,
      translatedSituation:
        electoralSituationTranslation[electoralSituationData?.situation] ||
        electoralSituationTranslation.NULO,
    };
  }, [execution]);

  const hasCpfNumber = useMemo(() => {
    return !!(
      execution?.data?.cpf ||
      execution?.parameters?.cpf ||
      execution?.sections?.pfBasicData?.data?.taxIdNumber ||
      execution?.sections?.ocr?.cpf
    );
  }, [execution]);

  const cnh = useMemo(() => {
    const cnhData = execution?.sections?.detran?.data || execution?.sections?.ocr;

    if (
      !execution?.sections?.detran?.data &&
      !execution?.sections?.ocr?.registrationNumber
    ) {
      return {
        cnhNumber: null,
        translatedStatus: null,
      };
    }

    const dataIndex = execution?.driver?.detran ? 'validityDateCNH' : 'expirationDate';

    const isCNHValid = isValidCNH(cnhData, dataIndex, cnhTranslation);

    return {
      cnhNumber:
        execution?.sections?.detran?.data || execution?.sections?.ocr?.registrationNumber,
      ...isCNHValid,
    };
  }, [execution]);

  const parsedBirthDate = useMemo(() => {
    if (execution?.data?.birthDate) return execution?.data?.birthDate;
    if (basicData?.birthDate) return i18nFormatDateOnly(basicData?.birthDate, 1);
    if (execution?.sections?.pfCpfData?.data?.birthDate)
      return execution?.sections?.pfCpfData?.data?.birthDate;
    if (execution?.sections?.cpfData?.birthDate)
      return i18nFormatDateOnly(execution?.sections?.cpfData?.birthDate, 1);
    if (execution?.sections?.cpf?.birthDate)
      return i18nFormatDateOnly(execution?.sections?.cpf?.birthDate, 1);
    if (execution?.sections?.pfData?.data?.birthDate)
      return execution?.sections?.pfData?.data?.birthDate;
    return '-';
  }, [execution, basicData, i18nFormatDateOnly]);

  return (
    <BasicInfosCard
      icon="caf-ic_file"
      title={translate(`${I18N_BASE_PATH}.title`)}
      data={execution}
      dataIndex="basicData"
    >
      <Row className="pdd-top-10 ">
        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.name.title`)}
        >
          <span>
            {execution?.data?.name ||
              execution?.sections?.pfCpfData?.data?.name ||
              execution?.sections?.cpf?.name ||
              execution?.sections?.pfData?.data?.name ||
              execution?.sections?.pfBasicData?.data?.name ||
              execution?.sections?.pfRfStatus?.data?.name ||
              (execution?.type?.toUpperCase() !== 'CRLV' || execution?.sections?.ocr?.cpf
                ? execution?.sections?.ocr?.name
                : null) ||
              '-'}
          </span>
        </ColumnLabel>

        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.rg.title`)}
        >
          <span>{basicData?.rg || execution?.sections?.ocr?.rg || '-'}</span>
        </ColumnLabel>

        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.birthDate.title`)}
        >
          <span className="text-dark">{parsedBirthDate}</span>
        </ColumnLabel>

        <ColumnLabel
          title={translate(`${I18N_BASE_PATH}.columnLabel.cpf.title`)}
          description={translate(cpf?.translatedMessage?.description)}
          type={cpf?.translatedMessage?.type}
          info={translate(cpf?.translatedMessage?.info)}
          span={8}
        >
          <span className="text-dark">
            {hasCpfNumber
              ? toMaskedCpf(
                  execution?.data?.cpf ||
                    execution?.parameters?.cpf ||
                    execution?.sections?.pfBasicData?.data?.taxIdNumber ||
                    execution?.sections?.ocr?.cpf
                )
              : '-'}
          </span>
        </ColumnLabel>

        <ColumnLabel
          title={translate(`${I18N_BASE_PATH}.columnLabel.cnh.title`)}
          description={translate(cnh?.translatedStatus?.description)}
          type={cnh?.translatedStatus?.type}
          span={8}
        >
          <span className="text-dark">
            {execution?.sections?.ocr?.registrationNumber ||
              cnh?.cnhNumber?.message ||
              '-'}
          </span>
        </ColumnLabel>

        <ColumnLabel
          title={translate(`${I18N_BASE_PATH}.columnLabel.voterRegistration.title`)}
          description={translate(electoralSituation?.translatedSituation?.description)}
          type={electoralSituation?.translatedSituation?.type}
          span={8}
        >
          <span className="text-dark">
            {electoralSituation?.voterRegistration || '-'}
          </span>
        </ColumnLabel>

        <ColumnLabel
          span={8}
          title={translate(`${I18N_BASE_PATH}.columnLabel.motherName.title`)}
        >
          <span className="text-dark">
            {basicData?.motherName ||
              execution?.data?.motherName ||
              execution?.sections?.pfBasicData?.data?.motherName ||
              execution?.sections?.ocr?.motherName ||
              '-'}
          </span>
        </ColumnLabel>

        <ColumnLabel
          span={8}
          title={translate(`${I18N_BASE_PATH}.columnLabel.fatherName.title`)}
        >
          <span className="text-dark">
            {basicData?.fatherName ||
              execution?.data?.fatherName ||
              execution?.sections?.pfBasicData?.data?.fatherName ||
              execution?.sections?.ocr?.fatherName ||
              '-'}
          </span>
        </ColumnLabel>
        <ColumnLabel
          span={8}
          title={translate(`${I18N_BASE_PATH}.columnLabel.nit.title`)}
        >
          <span className="text-dark">
            {execution?.parameters?.nit ||
              execution?.sections?.pfBasicData?.data?.alternativeIdNumbers
                ?.SocialSecurityNumber ||
              '-'}
          </span>
        </ColumnLabel>
      </Row>
    </BasicInfosCard>
  );
};

export default RegistrationInfosContent;
