import React, { useMemo } from 'react';
// import { Card } from 'antd';

import { useExecution } from 'hooks/execution';
import { useCompany } from 'hooks/company';
import { usePerson } from 'hooks/person';

import * as executionDataSources from 'utils/executionDataSource';

import PersonalFinancialInformation from 'components/DataSourceCards/FinancialInfos/People';

import PersonalLaborInformation from 'components/DataSourceCards/LaborInformation/People';
import BusinessFinancialInformation from 'components/DataSourceCards/FinancialInfos/Companies';
import BusinessLaborInformation from 'components/DataSourceCards/LaborInformation/Companies';

import CriminalInformation from 'components/DataSourceCards/CriminalInformation';
import DriverInformation from 'components/DataSourceCards/DriverInformation';
// import Relationships from 'pages/private/Executions/components/DataSourceCards/Relationships';
import PeopleMediaExposure from 'components/DataSourceCards/MediaExposure/People';
import CompanyMediaExposure from 'components/DataSourceCards/MediaExposure/Companies';
import BasicInfos from 'components/DataSourceCards/BasicInfos';
import ContactInfos from 'components/DataSourceCards/ContactInfos';
import LegalInformation from 'components/DataSourceCards/LegalInformation';
import RestrictiveLists from 'components/DataSourceCards/RestrictiveLists';
import IndependentConsultants from 'pages/private/Executions/components/DataSourceCards/IndependentConsultants';
// import CreditOperationsScr from 'pages/private/Executions/components/DataSourceCards/CreditOperationsScr';

// import EmptyRecurrence from 'pages/private/Profiles/components/EmptyRecurrence';

const Dossier = ({ toPrint = false }) => {
  const { execution } = useExecution();
  const { company } = useCompany();
  const { person } = usePerson();

  const dataSources = useMemo(() => {
    return (
      Object.keys(execution?.sections || person?.sources || company?.sources || {})?.map(
        (source) => source
      ) || []
    );
  }, [execution, person, company]);

  return (
    <>
      {(executionDataSources.hasBasicInformation(execution, dataSources) ||
        executionDataSources.hasBasicInformation(person, dataSources) ||
        executionDataSources.hasPjSintegraData(execution, dataSources) ||
        executionDataSources.hasBasicInformation(company, dataSources)) && (
        <BasicInfos toPrint={toPrint} />
      )}

      {(executionDataSources.hasContactsInformation(execution, dataSources) ||
        executionDataSources.hasContactsInformation(person, dataSources) ||
        executionDataSources.hasContactsInformation(company, dataSources)) && (
        <ContactInfos toPrint={toPrint} />
      )}

      {(execution?.sections?.independentConsultants || execution?.sections?.pjData) &&
        execution?.parameters?.cpf && (
          <IndependentConsultants data={execution} toPrint={toPrint} />
        )}

      {(executionDataSources.hasCriminalInformation(execution, dataSources) ||
        executionDataSources.hasCriminalInformation(person, dataSources) ||
        executionDataSources.hasCriminalInformation(company, dataSources)) && (
        <CriminalInformation toPrint={toPrint} />
      )}

      {(executionDataSources.hasFinancialInformation(execution, dataSources) ||
        executionDataSources.hasFinancialInformation(person, dataSources) ||
        executionDataSources.hasFinancialInformation(company, dataSources)) && (
        <>
          {(executionDataSources.hasPFFinancialInformation(execution, dataSources) ||
            executionDataSources.hasPFFinancialInformation(person, dataSources)) && (
            <PersonalFinancialInformation toPrint={toPrint} />
          )}
          {(executionDataSources.hasPJFinancialInformation(execution, dataSources) ||
            executionDataSources.hasPJFinancialInformation(company, dataSources)) && (
            <BusinessFinancialInformation toPrint={toPrint} />
          )}
        </>
      )}

      {(executionDataSources.hasLaborInformation(execution, dataSources) ||
        executionDataSources.hasLaborInformation(person, dataSources) ||
        executionDataSources.hasLaborInformation(company, dataSources)) && (
        <>
          {(executionDataSources.hasPJLaborInformation(execution, dataSources) ||
            executionDataSources.hasPJLaborInformation(company, dataSources)) && (
            <BusinessLaborInformation toPrint={toPrint} />
          )}

          {(executionDataSources.hasPFLaborInformation(execution, dataSources) ||
            executionDataSources.hasPFLaborInformation(person, dataSources)) && (
            <PersonalLaborInformation toPrint={toPrint} />
          )}
        </>
      )}

      {(executionDataSources.hasDriverInformation(execution, dataSources) ||
        executionDataSources.hasDriverInformation(person, dataSources) ||
        executionDataSources.hasDriverInformation(company, dataSources)) && (
        <DriverInformation toPrint={toPrint} />
      )}

      {(executionDataSources.hasLegalInformation(execution, dataSources) ||
        executionDataSources.hasLegalInformation(person, dataSources) ||
        executionDataSources.hasLegalInformation(company, dataSources)) && (
        <LegalInformation toPrint={toPrint} />
      )}

      {(executionDataSources.hasRestrictiveListsInformation(execution, dataSources) ||
        executionDataSources.hasRestrictiveListsInformation(person, dataSources) ||
        executionDataSources.hasRestrictiveListsInformation(company, dataSources)) && (
        <RestrictiveLists toPrint={toPrint} />
      )}

      {/* {executionDataSources.hasRelationshipInformation(execution, dataSources) && (
        <Relationships data={execution} />
      )} */}

      {(executionDataSources.hasMediaExposureInformation(execution, dataSources) ||
        executionDataSources.hasMediaExposureInformation(person, dataSources) ||
        executionDataSources.hasMediaExposureInformation(company, dataSources)) && (
        <>
          {(executionDataSources.hasPFMediaExposureInformation(execution, dataSources) ||
            executionDataSources.hasPFMediaExposureInformation(person, dataSources)) && (
            <PeopleMediaExposure toPrint={toPrint} />
          )}
          {(executionDataSources.hasPJMediaExposureInformation(execution, dataSources) ||
            executionDataSources.hasPJMediaExposureInformation(company, dataSources)) && (
            <CompanyMediaExposure toPrint={toPrint} />
          )}
        </>
      )}

      {/* {executionDataSources.hasCreditOperationsScr(execution, dataSources) && (
        <CreditOperationsScr data={execution} toPrint={toPrint} />
      )} */}
    </>
  );
};

export default Dossier;
