import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Collapse, Radio, DatePicker } from 'antd';

import moment from 'moment';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const UpdatedAtFilter = ({ value, title, onChange, disabled = false }) => {
  const { t: translate } = useTranslation();

  const isCustomDate = () => value && value.substr(0, 6) === 'custom';

  const getDatePickerValue = () => {
    if (!isCustomDate() || value === 'custom') return;
    const [startDate, endDate] = value.substr(7).split('-');
    // eslint-disable-next-line consistent-return
    return [
      moment(new Date(parseInt(startDate, 10))),
      moment(new Date(parseInt(endDate, 10))),
    ];
  };

  return (
    <Collapse
      ghost
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <div>
          <i className={isActive ? 'caf-ic_arrow_up' : 'caf-ic_arrow_down'} />
        </div>
      )}
    >
      <Panel header={title} key="updateDate">
        <Radio.Group
          disabled={disabled}
          defaultValue=""
          value={isCustomDate() ? 'custom' : value || ''}
          onChange={(e) => onChange(e.target.value)}
        >
          <Radio value="">
            {translate(
              'pages.private.executions.components.list.components.filter.updatedAtFilter.radioGroup.anyDate'
            )}
          </Radio>
          <Radio value="last24Hours">
            {translate(
              'pages.private.executions.components.list.components.filter.updatedAtFilter.radioGroup.last24Hours'
            )}
          </Radio>
          <Radio value="last7Days">
            {translate(
              'pages.private.executions.components.list.components.filter.updatedAtFilter.radioGroup.last7Days'
            )}
          </Radio>
          <Radio value="last30Days">
            {translate(
              'pages.private.executions.components.list.components.filter.updatedAtFilter.radioGroup.last30Days'
            )}
          </Radio>
          <Radio value="lastYear">
            {translate(
              'pages.private.executions.components.list.components.filter.updatedAtFilter.radioGroup.lastYear'
            )}
          </Radio>
          <Radio value="custom">
            {translate(
              'pages.private.executions.components.list.components.filter.updatedAtFilter.radioGroup.custom'
            )}
          </Radio>
        </Radio.Group>

        {isCustomDate() && (
          <RangePicker
            className="gx-w-100 mrg-top-5 gx-bg-transparent"
            format="DD/MM/YYYY HH:mm"
            showTime
            value={getDatePickerValue()}
            onChange={(_value) => onChange(_value)}
          />
        )}
      </Panel>
    </Collapse>
  );
};

UpdatedAtFilter.propTypes = {
  value: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default UpdatedAtFilter;
