import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from 'components/Button';

const ResetFilters = ({ onClick }) => {
  const { t: translate } = useTranslation();

  return (
    <div className="mrg-right-15">
      <Button type="default" block onClick={onClick} className="btn-filters">
        {translate('components.filters.resetFilters.button')}
      </Button>
    </div>
  );
};

ResetFilters.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ResetFilters;
