/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import { federalCriminalBackgroundTranslation } from 'utils/personalTranslations';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';
import EmptyExecutionState from 'components/EmptyExecutionState';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH = 'components.dataSourceCards.criminalInformation.index';

const PfBackgroundCheckFederalPolice = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const renderValidity = useCallback(
    (validity) => {
      return (
        validity &&
        validity
          .toUpperCase()
          .replace('DAYS', translate(`${I18N_BASE_PATH}.renderValidity`))
      );
    },
    [translate]
  );

  return (
    <DataSourceCardCollapsePanel
      customId="criminal-background-component"
      title={translate(
        `${I18N_BASE_PATH}.criminalInformation.dossierCard.showFederalCriminal.columnLabel.title`
      )}
      icon="caf-ic_criminal"
      toPrint={toPrint}
    >
      <Row>
        {parsedData && (
          <ColumnLabel
            title={translate(
              `${I18N_BASE_PATH}.criminalInformation.dossierCard.showFederalCriminal.columnLabel.title`
            )}
            description={translate(
              federalCriminalBackgroundTranslation[parsedData?.status]?.description
            )}
            type={federalCriminalBackgroundTranslation[parsedData?.status]?.type}
            span={24}
          >
            {/* {!federalCrimes && (
              <EmptyExecutionState type="empty-recurrence" hiddenAction={toPrint} urlId={urlId} />
            )} */}

            {queryWorked ? (
              <>
                {parsedData && Object.keys(parsedData || {}).length > 0 && (
                  <span>{parsedData?.certificateText}</span>
                )}
                {parsedData?.certificateNumber ? (
                  <span className="mrg-top-10 mrg-btm-10">
                    {translate(
                      `${I18N_BASE_PATH}.criminalInformation.dossierCard.showFederalCriminal.columnLabel.description.certificateNumber`
                    )}{' '}
                    {parsedData?.certificateNumber}
                    {parsedData?.emissionDate && (
                      <span>
                        •{' '}
                        {translate(
                          `${I18N_BASE_PATH}.criminalInformation.dossierCard.showFederalCriminal.columnLabel.description.emissionDate`
                        )}{' '}
                        {parsedData?.emissionDate}
                      </span>
                    )}
                    {parsedData?.validity && (
                      <span>
                        •{' '}
                        {translate(
                          `${I18N_BASE_PATH}.criminalInformation.dossierCard.showFederalCriminal.columnLabel.description.validity`
                        )}{' '}
                        {renderValidity(parsedData?.validity)}
                      </span>
                    )}
                  </span>
                ) : (
                  <span>
                    {translate(
                      `${I18N_BASE_PATH}.criminalInformation.dossierCard.showFederalCriminal.columnLabel.description.empty`
                    )}
                  </span>
                )}{' '}
              </>
            ) : (
              <EmptyExecutionState
                className="mrg-top-10"
                statusCode={statusCode}
                message={source?.message || parsedData?.message}
              />
            )}
          </ColumnLabel>
        )}
      </Row>
    </DataSourceCardCollapsePanel>
  );
};

export default PfBackgroundCheckFederalPolice;
