/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Checkbox } from 'antd';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import { useComplyData } from 'hooks/useComplyData';
import EmptyExecutionState from 'components/EmptyExecutionState';

import { Card, ComplyModal } from './components';

import './style.less';

const I18N_BASE_PATH = 'components.dataSourceCards.KYCAndCompliance.index';

export const KYCAndCompliance = ({
  toPrint = false,
  source = {},
  executionStatus,
  executionReviews,
  type = 'default',
}) => {
  const hits = source?.data?.hits || source?.data?.sanctionsList || [];
  const isGlobalSanctionsSource = !!source?.data?.sanctionsList;

  const { t: translate } = useTranslation();
  const { openModal, closeModal, ShowMore, MediaDetails, MODAL } = ComplyModal({
    translate,
  });
  const isProfile = window.location.pathname?.includes('profile/people');
  const {
    results,
    setResults,
    resultsApproved,
    setResultsApproved,
    resultsReproved,
    setResultsReproved,
    setDataSource,
  } = useComplyData();

  const [filterBy, setFilterBy] = useState();
  const [filterOptions, setFilterOptions] = useState([]);

  const hitsOrderedByHigherScore = useMemo(
    () => hits.sort((prevElement, nextElement) => prevElement.score - nextElement.score),
    [hits]
  );

  const scores = useMemo(() => hitsOrderedByHigherScore.map((hit) => hit.score), [
    hitsOrderedByHigherScore,
  ]);

  const theBestScore = useMemo(
    () =>
      scores.reduce(
        (prevElement, nextElement) => Math.max(prevElement, nextElement),
        -Infinity
      ),
    [scores]
  );

  const allowEditing = useMemo(() => executionStatus === 'PENDING', [executionStatus]);

  const updateFilterOptions = useCallback((types) => {
    const getAllOptionsFromDocTypes = () => {
      if (isGlobalSanctionsSource) {
        const [firstArray, ...arrayN] = types.map((doc) => doc.types);
        return [firstArray, ...arrayN];
      }
      const [firstArray, ...arrayN] = types.map(({ doc }) => doc.types);
      return [firstArray, ...arrayN];
    };

    const mergeArrays = (initialArray, ...arrayN) => {
      const arrayMerged = initialArray?.concat(...arrayN);
      const duplicateValuesRemoved = [...new Set(arrayMerged)];
      return duplicateValuesRemoved;
    };

    const sortStringArrayBySmallerSize = (array) =>
      array.sort((currentValue, nextValue) => currentValue.length - nextValue.length);

    const mapperArrayToLabelAndValueObject = (array) =>
      array.map((element) => ({ label: element, value: element }));

    const [firstArray, ...arrayN] = getAllOptionsFromDocTypes();
    const array = mergeArrays(firstArray, ...arrayN);
    const arraySorted = sortStringArrayBySmallerSize(array);
    const arrayMapped = mapperArrayToLabelAndValueObject(arraySorted);

    setFilterOptions(arrayMapped);
  }, []);

  const handleChangeApprovedResult = (key) => {
    const allReproved = resultsReproved.filter((result) => result.key !== key);
    setResultsReproved(allReproved);
    setResultsApproved((oldState) => [...oldState, { key, value: 'TRUE' }]);
  };

  const handleChangeReprovedResult = (key) => {
    const allApproved = resultsApproved.filter((result) => result.key !== key);
    setResultsApproved(allApproved);
    setResultsReproved((oldState) => [...oldState, { key, value: 'FALSE' }]);
  };

  const handleChangeUnselectApproved = (key) => {
    const allApproved = resultsApproved.filter((result) => result.key !== key);
    setResultsApproved(allApproved);
  };

  const handleChangeUnselectReproved = (key) => {
    const allReproved = resultsReproved.filter((result) => result.key !== key);
    setResultsReproved(allReproved);
  };

  const handleChangeApproveAll = () => {
    setResultsReproved([]);
    setResultsApproved([
      ...results.map((result) => ({ key: result.key, value: 'TRUE' })),
    ]);
  };

  const handleChangeUnselectAll = () => {
    setResultsApproved([]);
    setResultsReproved([]);
  };

  setDataSource(source.service);

  const isCardApproved = (cardKey) => {
    const card = resultsApproved.find((resultApproved) => resultApproved.key === cardKey);

    if (executionReviews?.length > 0 && executionStatus !== 'PENDING') {
      const reviewsApproved = executionReviews?.map((executionReview) => {
        return executionReview?.resultsApproved?.find((resultApproved) => {
          if (resultApproved?.key === cardKey) {
            return resultApproved;
          }
        });
      });
      return reviewsApproved[0]?.key === cardKey && reviewsApproved[0]?.value === 'TRUE';
    }
    return card?.value === 'TRUE';
  };

  const isCardReproved = (cardKey) => {
    const card = resultsReproved.find((resultReproved) => resultReproved.key === cardKey);

    if (executionReviews?.length > 0 && executionStatus !== 'PENDING') {
      const reviewsReproved = executionReviews?.map((executionReview) => {
        return executionReview?.resultsReproved?.find((resultReproved) => {
          if (resultReproved?.key === cardKey) {
            return resultReproved;
          }
        });
      });
      return reviewsReproved[0]?.key === cardKey && reviewsReproved[0]?.value === 'FALSE';
    }
    return card?.value === 'FALSE';
  };

  useEffect(() => {
    const hitsOrderedByHigherScore = hits.sort(
      (previousElement, nextElement) => previousElement.score - nextElement.score
    );
    const resultsMapped = hitsOrderedByHigherScore.map((hit) => ({
      key: hit?.doc?.id || hit?.id,
      value: null,
    }));

    setResults(resultsMapped);
    updateFilterOptions(hitsOrderedByHigherScore);
  }, [hits, updateFilterOptions, setResults]);

  const renderFilteredResults = () => {
    if (!filterBy) {
      return hitsOrderedByHigherScore.map((hit) => (
        <Card
          key={hit?.doc?.id || hit?.id}
          details={hit}
          modalsKey={MODAL}
          translate={translate}
          openModal={openModal}
          closeModal={closeModal}
          isApproved={isCardApproved(hit?.doc?.id || hit?.id)}
          isReproved={isCardReproved(hit?.doc?.id || hit?.id)}
          allowEditing={allowEditing}
          isTheBestScore={hit.score === theBestScore}
          onChangeApproved={handleChangeApprovedResult}
          onChangeReproved={handleChangeReprovedResult}
          onChangeUnselectReproved={handleChangeUnselectReproved}
          onChangeUnselectApproved={handleChangeUnselectApproved}
          type={type}
        />
      ));
    }

    const filteredResults = hitsOrderedByHigherScore.filter((hit) => {
      if (hit?.doc?.types.includes(filterBy)) return hit;
    });

    if (filteredResults?.length > 0) {
      return filteredResults.map((hit) => (
        <Card
          key={hit?.doc?.id || hit?.id}
          details={hit}
          modalsKey={MODAL}
          translate={translate}
          openModal={openModal}
          closeModal={closeModal}
          isApproved={isCardApproved(hit?.doc?.id || hit?.id)}
          isReproved={isCardReproved(hit?.doc?.id || hit?.id)}
          allowEditing={allowEditing}
          isTheBestScore={hit.score === theBestScore}
          onChangeApproved={handleChangeApprovedResult}
          onChangeReproved={handleChangeReprovedResult}
          onChangeUnselectReproved={handleChangeUnselectReproved}
          onChangeUnselectApproved={handleChangeUnselectApproved}
          type={type}
        />
      ));
    }
    return <></>;
  };

  const title = useMemo(() => {
    if (type === 'peps') return translate(`${I18N_BASE_PATH}.titlePeps`, `Peps`);
    if (type === 'sanctions')
      return translate(`${I18N_BASE_PATH}.titleSanctions`, `Sanctions`);
    if (type === 'adverseMedia')
      return translate(`${I18N_BASE_PATH}.titleAdverseMedia`, `Adverse Media`);
    if (type === 'warnings')
      return translate(`${I18N_BASE_PATH}.titleWarnings`, `Warnings`);
    return translate(`${I18N_BASE_PATH}.title`, `KYC and Compliance`);
  }, [translate, type]);

  return (
    <>
      {ShowMore}
      {MediaDetails}
      <DataSourceCardCollapsePanel
        icon="caf-ic_news"
        dataIndex="KYCAndCompliance"
        toPrint
        title={title}
      >
        {!hitsOrderedByHigherScore?.length ? (
          <EmptyExecutionState
            className="mrg-top-20"
            statusCode={source?.statusCode}
            message={
              source?.statusCode === '01'
                ? translate(
                    'components.dataSourceCards.KYCAndCompliance.index.noFoundMessage'
                  )
                : source?.message || source?.data?.message
            }
          />
        ) : (
          <>
            <header className="KYC-compliance-header">
              {allowEditing ? (
                <>
                  <Checkbox
                    className="select-all-results"
                    checked={results.length === resultsApproved.length}
                    onChange={(event) =>
                      !event.target.checked
                        ? handleChangeUnselectAll()
                        : handleChangeApproveAll()
                    }
                  >
                    {translate(
                      `${I18N_BASE_PATH}.component.subHeading.results`,
                      'Select all'
                    )}
                  </Checkbox>
                  <hr />
                </>
              ) : (
                <></>
              )}
              {!isProfile && (
                <section className="filter-matching-type">
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '').includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    style={{
                      width: '100%',
                      minWidth: 200,
                    }}
                    options={filterOptions}
                    onChange={setFilterBy}
                    placeholder={translate(
                      `${I18N_BASE_PATH}.component.subHeading.filterBy`,
                      'Filter by'
                    )}
                  />
                </section>
              )}
            </header>
            <div className="content-card-overflow">{renderFilteredResults()}</div>
          </>
        )}
      </DataSourceCardCollapsePanel>
    </>
  );
};
