import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { message, Modal, Button } from 'antd';
import PageTitle from 'components/PageTitle';

import api from 'services/api';

const I18N = `pages.private.users.components.usersListPDV.components.changeUserStatus`;

const ChangeUsersStatus = ({ refreshListRef }) => {
  const { t: translate } = useTranslation();

  const [user, setUser] = useState();
  const [isActive, setIsActive] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [loadingRemoveUser, setLoadingRemoveUser] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setUser(undefined);
  }, []);

  const openModal = useCallback((record) => {
    setIsActive(!record?.deletedAt);
    setUser(record);
    setModalVisible(true);
  }, []);

  const onChangeUserStatus = useCallback(async () => {
    try {
      setLoadingRemoveUser(true);

      if (isActive) {
        await api.delete(
          `${process.env.REACT_APP_BASE_URL_MANAGEMENT_API}/caf/users/${user?._id}`
        );
      } else {
        await api.patch(`${process.env.REACT_APP_BASE_URL_MANAGEMENT_API}/caf/users`, {
          reactivate: true,
          userId: user?._id,
        });
      }

      refreshListRef.current();
      closeModal();
      message.success(
        translate(
          `${I18N}.${isActive ? 'onInactivateUser' : 'onReactivateUser'}.messageSuccess`
        )
      );
    } catch (err) {
      message.error(
        translate(
          `${I18N}.${isActive ? 'onInactivateUser' : 'onReactivateUser'}.messageError`
        )
      );
    } finally {
      setLoadingRemoveUser(false);
    }
  }, [user, refreshListRef, closeModal, translate, isActive]);

  const ChangeUserStatusModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        footer={null}
        width={550}
        closable={false}
        wrapClassName="caf-modal"
        onCancel={closeModal}
      >
        <div className="no-mrg no-pdd">
          <PageTitle
            title={translate(
              `${I18N}.pageTitle.${isActive ? 'titleInactivate' : 'titleReactivate'}`
            )}
            align="center"
          />
          <div className="flex center mrg-top-25 mrg-btm-35">
            <span className="text-center font-size-16">
              {translate(
                `${I18N}.${isActive ? 'spanContentInactivate' : 'spanContentReactivate'}`
              )}
              <strong className="display-block mrg-top-10 text-dark font-size-16">
                {user?.name} ({user?.email})
              </strong>
            </span>
          </div>

          <div className="flex center-end">
            <Button
              type="default"
              onClick={closeModal}
              className="btn-cancel"
              disabled={loadingRemoveUser}
            >
              {translate(`${I18N}.buttons.cancel`)}
            </Button>
            <Button
              type={isActive ? 'danger' : 'primary'}
              onClick={onChangeUserStatus}
              disabled={loadingRemoveUser}
              loading={loadingRemoveUser}
            >
              {loadingRemoveUser
                ? translate(`${I18N}.buttons.loading`)
                : translate(
                    `${I18N}.buttons.${
                      isActive ? 'okTextInactivate' : 'okTextReactivate'
                    }`
                  )}
            </Button>
          </div>
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      translate,
      isActive,
      user,
      loadingRemoveUser,
      onChangeUserStatus,
    ]
  );

  return { openModal, closeModal, ChangeUserStatusModal };
};

export default ChangeUsersStatus;
