import { Col, Row, Collapse, Pagination } from 'antd';
import NotAvailable from 'components/NotAvailable';
import React, { useState, useRef } from 'react';
import usePagination from 'customHooks/usePagination';
import { useTranslation } from 'react-i18next';
import { CompanyBody1, CompanySubtitle2 } from '../../../CompanyDetailsV2/components';

const { Panel } = Collapse;

const CollapseItem = ({ payload }) => {
  return (
    <div className="information-collapse">
      <CompanySubtitle2>{payload?.title}</CompanySubtitle2>
      <CompanyBody1>{payload?.content}</CompanyBody1>
    </div>
  );
};

const CollapseHeader = ({ header, columns }) => {
  return (
    <Row className="kyb-company-collapse-header-panel">
      {columns.map((column) => (
        <Col span={column.span}>
          <CompanyBody1>
            {!Array.isArray(header[column.prop])
              ? header[column.prop]
              : header[column.prop]?.map((element) =>
                  !element ? null : (
                    <>
                      {element} <br />
                    </>
                  )
                )}
          </CompanyBody1>
        </Col>
      ))}
    </Row>
  );
};

const CollapseList = ({
  columns = [],
  list = [],
  index = '',
  collapsePrintSuffix = '',
  collapseOpened = false,
  print = false,
}) => {
  const topRef = useRef(null);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: print ? list.length : 5,
  });

  const handleChangePage = (page) => {
    setPagination((old) => ({ ...old, currentPage: page }));
    topRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const { paginatedItems, paginationStart, paginationEnd } = usePagination({
    pagination,
    items: list,
  });

  const { t } = useTranslation();

  return (
    <div className="KYB-collapse-list kyb-company-collapse-list">
      <Row className="columns">
        {columns?.map((element) => (
          <Col span={element?.span}>
            <CompanySubtitle2>{element?.title}</CompanySubtitle2>
          </Col>
        ))}
      </Row>
      {paginatedItems?.map((item, i) => {
        const collapseKey = (
          (item?.header?.id || '') +
          collapsePrintSuffix +
          i +
          index
        ).toString();

        return (
          <>
            <Collapse
              className="kyb-company-collapse kyb-company-collapse-small"
              defaultActiveKey={print ? [collapseKey] : null}
              key={collapseKey}
            >
              <Panel
                header={<CollapseHeader header={item?.header} columns={columns} />}
                key={collapseKey}
              >
                <div className="kyb-company-collapse-item kyb-company-print-break-avoid">
                  <section className="kyb-company-info-section">
                    <article className="kyb-company-info-article">
                      {item?.body?.map((value) => (
                        <CollapseItem payload={value} />
                      ))}
                    </article>
                  </section>
                </div>
              </Panel>
            </Collapse>
          </>
        );
      })}
      <Row className="row-show-pagination kyb-company-pagination-area">
        <Col span={12}>
          <span>
            {t(
              `pages.private.KYBCompany.pages.companyDetails.subPages.records.pagination`,
              {
                start: paginationStart,
                end: paginationEnd,
                total: list.length,
              }
            )}
          </span>
        </Col>
        <Col span={12}>
          <Pagination
            current={pagination.currentPage}
            onChange={handleChangePage}
            pageSize={pagination.pageSize}
            total={list.length}
            defaultCurrent={1}
            showSizeChanger={false}
            showSorterTooltip={false}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CollapseList;
