/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import ExportDownloadDropdown from 'components/Dropdown/ExportDownloadDropdown';
import { download } from 'utils/downloadFile';

const I18N_BASE_PATH = 'pages.private.KYBCompany.pages.companyDetails.subPages.records';

const ExportDropdown = ({ record }) => {
  const { t } = useTranslation();
  const { i18nFormatDateOnly } = useI18nFormatters();

  const generateCsv = () => {
    const arraySeparator = ' | ';

    const header = [
      t(`${I18N_BASE_PATH}.recordName`, 'Record name'),
      t(`${I18N_BASE_PATH}.acquisitionDate`, 'Acquisition date'),
      t(`${I18N_BASE_PATH}.publicationDate`, 'Publication date'),
      t(`${I18N_BASE_PATH}.source`, 'Source'),
      t(`${I18N_BASE_PATH}.sourceURL`, 'Source URL'),
      t(`${I18N_BASE_PATH}.entity`, 'Entity'),
      t(`${I18N_BASE_PATH}.entityType`, 'Entity type'),
      t(`${I18N_BASE_PATH}.identifiers`, 'Identifiers'),
      t(`${I18N_BASE_PATH}.PEP`, 'PEP'),
      t(`${I18N_BASE_PATH}.sanctioned`, 'Sanctioned'),
      t(`${I18N_BASE_PATH}.sourceCount`, 'Source count'),
      t(`${I18N_BASE_PATH}.addresses`, 'Addresses'),
      t(`${I18N_BASE_PATH}.dateOfBirth`, 'Date of birth'),
      t(`${I18N_BASE_PATH}.countries`, 'Countries'),
    ];

    let csv = `${header.join(',')}\n`;

    // eslint-disable-next-line no-restricted-syntax
    for (const { entity } of record?.entities?.data || []) {
      const identifiers = entity.identifiers
        .map((id) => `${id.label} - ${id.value}`)
        .join(arraySeparator);
      const sources = entity.counts.sources
        .map((source) => `${source.label} (${source.count})`)
        .join(arraySeparator);
      const addresses = entity.addresses.join(arraySeparator);
      const countries = entity.countries.join(arraySeparator);

      const row = [
        record.label,
        i18nFormatDateOnly(record.acquisitionDate, 1),
        i18nFormatDateOnly(record.publicationDate, 1),
        record.source?.name,
        record.sourceUrl,
        entity.label,
        t(`${I18N_BASE_PATH}.types.${entity.type}`, entity.type),
        identifiers,
        t(`${I18N_BASE_PATH}.${entity.pep ? 'true' : 'false'}`),
        t(`${I18N_BASE_PATH}.${entity.sanctioned ? 'true' : 'false'}`),
        sources,
        addresses,
        entity.registrationDate,
        countries,
      ];

      csv += `${row.map((col) => (col ? `"${col}"` : '')).join(',')}\n`;
    }

    return csv;
  };

  const handleExportEntitiesToCsv = () => {
    const csv = generateCsv();
    const url = window.URL.createObjectURL(new Blob([csv]));

    download(url, `${record.label}_${record.publicationDate}.csv`);
  };

  const dropdownItems = [
    {
      title: `${t(`${I18N_BASE_PATH}.entitiesMentioned`, 'Entities Mentioned')} (CSV)`,
      onClick: handleExportEntitiesToCsv,
    },
  ];

  return <ExportDownloadDropdown items={dropdownItems} />;
};

export default ExportDropdown;

ExportDropdown.propTypes = {
  record: PropTypes.any.isRequired,
};
