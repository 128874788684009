import { useCallback, useEffect } from 'react';

const useWindowResize = (handleWindowResize, callbackDeps = []) => {
  const windowResizeHandler = useCallback(handleWindowResize, callbackDeps);

  useEffect(() => {
    windowResizeHandler(window.innerWidth, window.innerHeight);
    window.addEventListener('resize', () =>
      windowResizeHandler(window.innerWidth, window.innerHeight)
    );

    return () => {
      window.removeEventListener('resize', () =>
        windowResizeHandler(window.innerWidth, window.innerHeight)
      );
    };
  }, [windowResizeHandler]);
};

export default useWindowResize;
