/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'utils/isObjectEmpty';
import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import EmptyState from 'components/EmptyState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import usePersonalLaborAndFinancialInformationModal from 'pages/private/Profiles/components/DataSourceModals/PersonalLaborAndFinancialInformation';

const WorkHistory = ({ profile, toPrint /* urlId */ }) => {
  const { t: translate } = useTranslation();

  const workHistory = useMemo(() => profile?.work?.history, [profile]);

  const {
    openModal,
    PersonalLaborAndFinancialInformationModal,
  } = usePersonalLaborAndFinancialInformationModal({
    data: profile?.work?.history?.professions,
    title: translate(
      'pages.private.profiles.components.dataSourceCards.laborInformation.people.components.workHistory.index.usePersonalLaborAndFinancialInformationModal.title'
    ),
  });

  const handlerInfos = useMemo(() => {
    if (!workHistory) return null;

    if (isEmpty(workHistory)) {
      return {
        title: translate(
          'pages.private.profiles.components.dataSourceCards.laborInformation.people.components.workHistory.index.handlerInfos.empty'
        ),
        type: 'empty',
      };
    }

    if (workHistory?.isEmployed) {
      return {
        title: translate(
          'pages.private.profiles.components.dataSourceCards.laborInformation.people.components.workHistory.index.handlerInfos.isEmployed'
        ),
        type: 'primary',
      };
    }

    if (!workHistory?.isEmployed) {
      return {
        title: translate(
          'pages.private.profiles.components.dataSourceCards.laborInformation.people.components.workHistory.index.handlerInfos.isNotEmployed'
        ),
        type: 'empty',
      };
    }

    return null;
  }, [workHistory, translate]);

  const isProcessing = useMemo(() => verifySourcesProcessing(profile, 'workHistory'), [
    profile,
  ]);

  const handleOpenModal = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <>
      {PersonalLaborAndFinancialInformationModal}

      <ColumnLabel
        title={translate(
          'pages.private.profiles.components.dataSourceCards.laborInformation.people.components.workHistory.index.workHistory.columnLabel.title'
        )}
        description={handlerInfos?.title}
        type={handlerInfos?.type}
        onClick={workHistory?.professions?.length > 0 && handleOpenModal}
      >
        {!workHistory && isProcessing && <EmptyState type="processing" />}

        {!workHistory && !isProcessing && <EmptyState type="waiting_consult" />}

        {workHistory &&
          (isEmpty(workHistory) || !workHistory?.professions?.length > 0) && (
            <span>
              {translate(
                'pages.private.profiles.components.dataSourceCards.laborInformation.people.components.workHistory.index.workHistory.professionsEmpty'
              )}
            </span>
          )}

        {!toPrint && workHistory?.professions?.length > 0 && (
          <span>
            {translate(
              'pages.private.profiles.components.dataSourceCards.laborInformation.people.components.workHistory.index.workHistory.detail'
            )}
          </span>
        )}
      </ColumnLabel>
    </>
  );
};

export default WorkHistory;
