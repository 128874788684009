import { uniqBy } from 'lodash';

export const filterRelatedRules = (services, cafServicesList) => {
  return uniqBy(
    cafServicesList?.docs
      ?.filter((x) => services.map(({ source }) => source).includes(x.source))
      ?.map(({ relatedRules, category }) =>
        relatedRules?.map((rule) => ({ ...rule, category }))
      )
      ?.flat()
      ?.filter((x) => x) || [],
    'rule'
  );
};
