import React from 'react';
import PropTypes from 'prop-types';

import QSAInfosContent from 'pages/private/Profiles/components/Tabs/components/BasicInfos/components/Company/QSAInfosContent';

import './styles.less';

const DocumentQSA = ({ data }) => {
  return (
    <div id="to-print-qsa">
      <QSAInfosContent company={data} toPrint />
    </div>
  );
};

DocumentQSA.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DocumentQSA;
