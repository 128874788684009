import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Checkbox as AntCheckbox } from 'antd';
import PropTypes from 'prop-types';

const Checkbox = ({ onClick, children, ...rest }) => {
  const checkboxRef = useRef();

  useEffect(() => {
    if (!checkboxRef?.current || !onClick) return;

    const domNode = ReactDOM.findDOMNode(checkboxRef.current); // eslint-disable-line
    if (domNode) {
      domNode.addEventListener('click', onClick);
      // eslint-disable-next-line consistent-return
      return () => {
        domNode.removeEventListener('click', onClick);
      };
    }
  }, [checkboxRef, onClick]);

  return (
    <AntCheckbox ref={checkboxRef} {...rest}>
      {children}
    </AntCheckbox>
  );
};

Checkbox.propTypes = {
  onClick: PropTypes.func,
  ...AntCheckbox?.prototype?.props,
};

Checkbox.defaultProps = {
  onClick: null,
};

export default Checkbox;
