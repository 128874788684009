/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.less';

export const CardWrapper = ({ children, hasPadding, className, style }) => {
  return (
    <div className={classNames('card-wrapper', className)} style={style}>
      {hasPadding ? <div className="padding-30">{children}</div> : <>{children}</>}
    </div>
  );
};

CardWrapper.propTypes = {
  children: PropTypes.elementType.isRequired,
  hasPadding: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};

CardWrapper.defaultProps = {
  hasPadding: false,
  className: '',
  style: {},
};
