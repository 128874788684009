/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.less';
import RegistrationInfosContent from './RegistrationInfosContent';
import AddressContent from './AddressContent';
import ContactContent from './ContactContent';
import SPCContent from './SCPContent';
import OtherInfo from './OtherInfo';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

const ReportSPC = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  return (
    <DataSourceCardCollapsePanel
      title={translate(
        'components.dataSourceCards.financialInformation.shared.spcRestrictionsScore.title'
      )}
      icon="caf-ic_dollar_sign"
      toPrint={toPrint}
      customId="financialInfo"
    >
      <SPCContent source={source} toPrint={toPrint} />
      <RegistrationInfosContent source={source} toPrint={toPrint} />
      <AddressContent source={source} toPrint={toPrint} />
      <ContactContent source={source} toPrint={toPrint} type="pf" />
      <OtherInfo source={source} toPrint={toPrint} />
    </DataSourceCardCollapsePanel>
  );
};

export default ReportSPC;
