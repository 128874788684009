import React from 'react';
import { Row, Col, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam } from 'use-query-params';
import { CardWrapper } from 'components/CardWrapper';

import PageTitle from 'components/PageTitle';
import Wrapper from 'pages/private/wrapper';

import ReasonsList from './components/Lists/ReasonsList';
import TagsList from './components/Lists/TagsList';

const { TabPane } = Tabs;

const I18N_BASE_PATH = 'pages.private.tagsAndReasons';

const OnboardingFaces = () => {
  const { t: translate } = useTranslation();

  const [query, setQuery] = useQueryParams({
    _filter: StringParam,
    tab: StringParam,
  });

  const tabNav = (icon, name) => (
    <div className="tab-item">
      <i className={`tab-item-icon caf-ic_${icon}`} /> {name}
    </div>
  );

  return (
    <Wrapper>
      <CardWrapper hasPadding>
        <Row align="middle" className="flex no-mrg">
          <Col className="flex-1 page-title">
            <PageTitle subtitle={translate(`${I18N_BASE_PATH}.subtitle`)} />
          </Col>
        </Row>

        <Tabs
          defaultActiveKey={query?.tab || 'approval'}
          onChange={(tab) => setQuery({ tab })}
        >
          <TabPane
            tab={tabNav('check_d', translate(`${I18N_BASE_PATH}.tabs.approval`))}
            key="approval"
          >
            <ReasonsList type="approval" />
          </TabPane>
          <TabPane
            tab={tabNav('close', translate(`${I18N_BASE_PATH}.tabs.reproval`))}
            key="reproval"
          >
            <ReasonsList type="reproval" />
          </TabPane>
          <TabPane
            tab={tabNav('tag', translate(`${I18N_BASE_PATH}.tabs.tags`))}
            key="tags"
          >
            <TagsList />
          </TabPane>
        </Tabs>
      </CardWrapper>
    </Wrapper>
  );
};

export default OnboardingFaces;
