import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { CardWrapper } from 'components/CardWrapper';
import Countdown from 'components/Countdown';

const CustomFetchLoader = ({ refreshExecution, message }) => {
  const iconLoader = <LoadingOutlined style={{ fontSize: 80 }} spin />;

  return (
    <CardWrapper hasPadding className="remove-bottom-top">
      <Spin indicator={iconLoader} className="flex center mrg-top-30" />
      <strong className="flex center mrg-top-35">{message}</strong>
      <Countdown onFinish={() => refreshExecution()} amountOfSeconds={10} />
    </CardWrapper>
  );
};

export default CustomFetchLoader;
