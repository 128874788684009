/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { labelStatus } from 'utils/handlerStatus';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import './styles.less';

import { Row, Col } from 'antd';
import TooltipInfo from 'components/TooltipInfo';

const I18N_BASE_PATH = 'components.dataSourceCards.basicInfos.components';

const PfBiometriaUnico = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const translateError = useMemo(() => {
    if (parsedData?.errorCode)
      return (
        translate(`${I18N_BASE_PATH}.biometriaUnico.fields.info`) +
        translate(`${I18N_BASE_PATH}.biometriaUnico.fields.errorCode`) +
        parsedData?.errorCode
      );
    return translate(`${I18N_BASE_PATH}.biometriaUnico.fields.info`);
  }, [parsedData]);

  const translateScore = useMemo(() => {
    if (parsedData?.score >= 1 && parsedData?.score <= 49) return 'noEvidence';

    if (parsedData?.score >= 50 && parsedData?.score <= 100) return 'noRisk';

    if (parsedData?.score === 0) return 'riskB';

    if (parsedData?.score >= -100 && parsedData?.score <= -11) return 'fraudConfirmed';

    if (parsedData?.score >= -10 && parsedData?.score <= -1) return 'riskB';

    if (parsedData?.score >= -110 && parsedData?.score <= -101) return 'riskB';

    return '';
  }, [parsedData]);

  const faixaScore = useMemo(() => {
    if (parsedData?.score >= 1 && parsedData?.score <= 49) return '5';

    if (parsedData?.score >= 50 && parsedData?.score <= 100) return '6';

    if (parsedData?.score === 0) return '0';

    if (parsedData?.score >= -100 && parsedData?.score <= -11) return '2';

    if (parsedData?.score >= -10 && parsedData?.score <= -1) return '3';

    if (parsedData?.score >= -110 && parsedData?.score <= -101) return '1';

    return '';
  }, [parsedData]);

  const translateProbability = useMemo(() => {
    if (parsedData?.score >= 1 && parsedData?.score <= 49) return 'mediumProbability';

    if (parsedData?.score >= 50 && parsedData?.score <= 100) return 'highProbability';

    if (parsedData?.score === 0) return 'lowProbality';

    if (parsedData?.score >= -100 && parsedData?.score <= -11) return 'lowProbability';

    if (parsedData?.score >= -10 && parsedData?.score <= -1) return 'veryLowProbability';

    if (parsedData?.score >= -110 && parsedData?.score <= -101) return 'lowProbability';

    return '';
  }, [parsedData]);

  const score = useMemo(() => {
    let scoreBiometria = '';

    if (statusCode === '01' && parsedData?.errorCode !== 500) {
      scoreBiometria =
        translate(`${I18N_BASE_PATH}.biometriaUnico.fields.score`) + parsedData?.score;
    } else {
      scoreBiometria =
        translate(`${I18N_BASE_PATH}.biometriaUnico.fields.score`) +
        translate(`${I18N_BASE_PATH}.biometriaUnico.fields.scoreUndefined`);
    }
    return scoreBiometria;
  }, [parsedData]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  return (
    <DataSourceCardCollapsePanel
      title={translate(`${I18N_BASE_PATH}.basicInfosCard.sources.pfBiometriaUnico`)}
      customId="labor-infos-component"
      icon="caf-ic_faceid"
      toPrint={toPrint}
    >
      <ColumnLabel
        title={score}
        type={queryWorked && labelStatus(statusCode)?.type}
        description={
          statusCode === '01'
            ? translate(`${I18N_BASE_PATH}.biometriaUnico.fields.statusSuccessful`)
            : translate(`${I18N_BASE_PATH}.biometriaUnico.fields.status`)
        }
      >
        {statusCode !== '01' || parsedData?.errorCode ? (
          <Row>
            <Col span={24} className="text-dark mrg-btm-10 mrg-top-10">
              <span className="span-style">
                <strong>
                  {translate(`${I18N_BASE_PATH}.biometriaUnico.fields.error`)}
                </strong>
              </span>
              <span>
                {parsedData?.message}
                <TooltipInfo title={translateError} />
              </span>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col span={24} className="text-dark mrg-btm-10 mrg-top-10">
                <span className="span-style">
                  <strong>
                    {translate(`${I18N_BASE_PATH}.biometriaUnico.fields.scoreRange`)}
                  </strong>
                </span>
                <span className="span-style">{faixaScore} - </span>
                <span>
                  {translate(
                    `${I18N_BASE_PATH}.biometriaUnico.fields.${translateProbability}`
                  )}
                </span>
              </Col>
              <Col span={24} className="span-style" info="teste">
                <strong>
                  {translate(`${I18N_BASE_PATH}.biometriaUnico.fields.${translateScore}`)}
                </strong>
              </Col>
            </Row>
          </>
        )}
      </ColumnLabel>
    </DataSourceCardCollapsePanel>
  );
};

export default PfBiometriaUnico;
