/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Plyr from 'plyr';
import { Modal, Tooltip, Spin, message } from 'antd';
import Viewer from 'react-file-viewer';
import { useFetch } from 'services/hooks';

import { download } from 'utils/downloadFile';
import { getExtension } from 'utils/getExtensionFile';

import Button from 'components/Button';
import Loader from 'components/Loader';

import './styles.less';
import 'plyr/dist/plyr.css';

const MEDIA_TYPES_SUPPORTED = ['audio', 'video'];
const DOC_TYPES_SUPPORTED = ['image', 'text', 'application'];

const FileViewer = ({ closable } = {}) => {
  const { t: translate } = useTranslation();

  const player = useRef(null);

  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [hasFileViewSupport, setHasFileViewSupport] = useState(false);

  const [fileViewerPath, setFileViewerPath] = useState('');
  const [fileViewerType, setFileViewerType] = useState('');

  const { get } = useFetch();

  const startViewerMedia = useCallback((file) => {
    return new Promise((resolve, reject) => {
      const fileType = file?.contentType
        ? file?.contentType?.split('/')[0]
        : file?.mimeType?.split('/')[0];

      if (fileType === 'audio' || fileType === 'video') {
        if (player.current) player.current.destroy();

        player.current = new Plyr(
          fileType === 'video' ? '#player-video' : '#player-audio'
        );

        player.current.once('loadeddata', () => {
          // setLoading(false);
          resolve(true);
        });
        player.current.on('error', () => {
          // setError(true);
          reject();
        });

        player.current.source = {
          type: fileType,
          sources: [
            {
              src: file.url,
              type: file?.contentType ? file?.contentType : file?.mimeType,
            },
          ],
        };
      } else {
        reject();
      }
    });
  }, []);

  const startViewerDoc = useCallback(async (file) => {
    const fileExtension = getExtension(file);

    setLoading(true);

    setFileViewerPath(file.url);
    setFileViewerType(fileExtension);

    setLoading(false);
  }, []);

  const loadFile = useCallback(
    async (_fileId) => {
      setLoading(true);
      setError(false);

      try {
        const file = await get({ url: `/files/${_fileId}`, showMessage: false });

        if (!file) throw file;

        // setCurrentFile(file.data);

        const fileType = file?.data?.contentType
          ? file?.data?.contentType?.split('/')[0]
          : file?.data?.mimeType?.split('/')[0];

        if (MEDIA_TYPES_SUPPORTED.includes(fileType)) {
          setHasFileViewSupport(true);
          await startViewerMedia(file.data);
          setLoading(false);
        } else if (DOC_TYPES_SUPPORTED.includes(fileType)) {
          setHasFileViewSupport(true);
          await startViewerDoc(file.data);
          setLoading(false);
        } else {
          setHasFileViewSupport(false);
          setLoading(false);
        }

        setCurrentFile(file.data);
      } catch (e) {
        setError(true);
        setLoading(false);
      }
    },
    [get, startViewerMedia, startViewerDoc]
  );

  const closeFileViewer = useCallback(() => {
    setModalVisible(false);
    setFileViewerPath('');
    setFileViewerType('');

    if (player.current) {
      player.current.off('loadeddata', () => {});
      player.current.off('error', () => {});
      player.current.destroy();
      player.current = null;
    }
  }, []);

  const openFileViewer = useCallback(
    ({ fileId, preloadFile }) => {
      setModalVisible(true);

      if (!fileId) closeFileViewer();

      if (fileId !== currentFile?._id) {
        if (preloadFile) setCurrentFile(preloadFile);
        else setCurrentFile(null);

        loadFile(fileId);
      } else {
        const fileType = currentFile?.contentType
          ? currentFile?.contentType?.split('/')[0]
          : currentFile?.mimeType?.split('/')[0];

        if (MEDIA_TYPES_SUPPORTED.includes(fileType)) {
          startViewerMedia(currentFile);
        } else if (DOC_TYPES_SUPPORTED.includes(fileType)) {
          startViewerDoc(currentFile);
        }
      }
    },
    [closeFileViewer, currentFile, loadFile, startViewerMedia, startViewerDoc]
  );

  const downloadFile = useCallback(async () => {
    try {
      await download(currentFile.downloadUrl, currentFile.fileName, true);
    } catch (e) {
      message.error(translate('components.files.fileViewer.downloadFile.error.message'));
    }
  }, [currentFile, translate]);

  const fileViewerComponent = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="file-viewer-modal"
        closable={false}
        centered
        onCancel={closeFileViewer}
        footer={null}
        width="auto"
        maskStyle={{ backgroundColor: 'rgba(0,0,0,0.94)' }}
        keyboard={closable}
        maskClosable={closable}
      >
        <div id="file-viewer-component">
          <div
            className="file-viewer-header"
            style={{ display: loading ? 'none' : 'flex' }}
          >
            <div className="flex center">
              {closable && (
                <Tooltip
                  placement="bottom"
                  title={translate(
                    'components.files.fileViewer.fileViewerComponent.fileViewerHeader.tooltipTitle.closable'
                  )}
                  overlayClassName="file-viewer-tooltip"
                >
                  <Button
                    type="text"
                    className="header-btn back-btn"
                    onClick={closeFileViewer}
                  >
                    <i className="caf-ic_arrow-right" />
                  </Button>
                </Tooltip>
              )}
              <p className="no-mrg gx-font-weight-light">{currentFile?.fileName}</p>
            </div>

            <div className="flex center" style={{ display: loading ? 'none' : 'flex' }}>
              <Tooltip
                placement="bottom"
                title={translate(
                  'components.files.fileViewer.fileViewerComponent.fileViewerHeader.tooltipTitle.closable'
                )}
                overlayClassName="file-viewer-tooltip"
              >
                <Button
                  type="text"
                  className="header-btn back-btn"
                  onClick={closeFileViewer}
                >
                  <i className="caf-ic_arrow-right" />
                </Button>
              </Tooltip>
              {fileViewerType !== 'pdf' && (
                <Tooltip
                  placement="bottom"
                  title={translate(
                    'components.files.fileViewer.fileViewerComponent.fileViewerHeader.tooltipTitle.download'
                  )}
                  overlayClassName="file-viewer-tooltip"
                >
                  <Button type="text" className="header-btn" onClick={downloadFile}>
                    <i className="caf-ic_download" />
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>

          <div
            className="content-wrapper"
            style={{
              overflowY: fileViewerType === 'pdf' ? 'hidden' : 'auto',
            }}
          >
            <div
              className={`custom-file-viewer-scrollbar ${
                fileViewerType === 'pdf' ? 'content-pdf' : 'content'
              }`}
            >
              {loading && <Spin indicator={<Loader size="46px" color="#20c26b" />} />}

              <div hidden={loading || error}>
                {!loading && (
                  <div
                    className="viewer-container"
                    hidden={
                      loading ||
                      error ||
                      currentFile?.contentType?.startsWith('video') ||
                      currentFile?.contentType?.startsWith('audio') ||
                      currentFile?.mimeType?.startsWith('video') ||
                      currentFile?.mimeType?.startsWith('audio')
                    }
                  >
                    {fileViewerType === 'pdf' && (
                      <div className="frame">
                        <iframe
                          src={fileViewerPath}
                          title={fileViewerType}
                          seamless="seamless"
                        />
                      </div>
                    )}

                    {currentFile?.mimeType?.startsWith('image') && (
                      <img src={fileViewerPath} alt={currentFile.fileName} />
                    )}

                    {!currentFile?.mimeType?.startsWith('image') &&
                      fileViewerType !== 'pdf' && (
                        <Viewer
                          fileType={fileViewerType}
                          filePath={fileViewerPath}
                          unsupportedComponent={() => (
                            <>
                              <span className="unsupported">
                                {translate(
                                  'components.files.fileViewer.fileViewerComponent.contentWrapper.viewer.text'
                                )}
                              </span>

                              <Tooltip
                                placement="bottom"
                                title={translate(
                                  'components.files.fileViewer.fileViewerComponent.contentWrapper.viewer.tooltipTitle'
                                )}
                                overlayClassName="file-viewer-tooltip"
                              >
                                <Button
                                  type="text"
                                  className="header-btn"
                                  onClick={downloadFile}
                                >
                                  <i className="caf-ic_download font-size-25" />
                                </Button>
                              </Tooltip>
                            </>
                          )}
                          onError={
                            <div>
                              {translate(
                                'components.files.fileViewer.fileViewerComponent.contentWrapper.viewer.onErrorText'
                              )}
                            </div>
                          }
                        />
                      )}
                  </div>
                )}

                <div
                  hidden={
                    !currentFile?.contentType?.startsWith('video') &&
                    !currentFile?.mimeType?.startsWith('video')
                  }
                >
                  <video
                    id="player-video"
                    playsinline
                    controls
                    hidden={
                      !currentFile?.contentType?.startsWith('video') &&
                      !currentFile?.mimeType?.startsWith('video')
                    }
                  />
                  <audio
                    id="player-audio"
                    controls
                    hidden={
                      !currentFile?.contentType?.startsWith('audio') &&
                      !currentFile?.mimeType?.startsWith('audio')
                    }
                  />
                </div>

                {(currentFile?.contentType?.startsWith('video') ||
                  currentFile?.contentType?.startsWith('audio') ||
                  currentFile?.mimeType?.startsWith('video') ||
                  currentFile?.mimeType?.startsWith('audio')) &&
                  !hasFileViewSupport && (
                    <div className="not-support">
                      {translate(
                        'components.files.fileViewer.fileViewerComponent.contentWrapper.footer.hasNotFileViewSupport'
                      )}
                    </div>
                  )}
              </div>

              {error && (
                <div>
                  {translate(
                    'components.files.fileViewer.fileViewerComponent.contentWrapper.footer.errorText'
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    ),
    [
      closable,
      closeFileViewer,
      currentFile,
      downloadFile,
      error,
      hasFileViewSupport,
      loading,
      modalVisible,
      fileViewerPath,
      fileViewerType,
      translate,
    ]
  );

  return { openFileViewer, closeFileViewer, fileViewerComponent };
};

FileViewer.propTypes = {
  closable: PropTypes.bool,
};

FileViewer.defaultProps = {
  closable: true,
};

export default FileViewer;
