import React, { useMemo } from 'react';
import { useExecution } from 'hooks/execution';

import VerifiedIdentityCard from './components/VerifiedIdentityCard';
import PrivateFacesetCard from './components/PrivateFacesetCard';

import './styles.less';

const VerifiedIdentity = () => {
  const { execution, loadingExecution } = useExecution();

  const findValidationsData = useMemo(() => {
    if (!execution) return null;

    const _validations = execution?.validations || [];

    const validIdentity = _validations?.some(
      (validation) => validation.rule === 'facematch_is_equal'
    );

    return { validIdentity };
  }, [execution]);

  return (
    <>
      <VerifiedIdentityCard
        loading={loadingExecution}
        status={findValidationsData?.validIdentity?.status}
      />

      {execution?.sections?.privateFaceset && (
        <PrivateFacesetCard
          loading={loadingExecution}
          status={findValidationsData?.validIdentity?.status}
        />
      )}
    </>
  );
};

export default VerifiedIdentity;
