import React, { useMemo, useState } from 'react';
import { Col, Collapse, Row, Divider, Tag, Button, Pagination, Popover } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DottedLabel from 'components/DottedLabel';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';

import { sentimentLabelMappings } from '../../utils/mappings';
import MediaExposureDetailsModal from './MediaExposureDetailsModal';

import './styles.less';

const ITEMS_PER_PAGE = 10;
const MAX_VISIBLE_TAGS = 5;

const I18N_BASE_PATH =
  'components.dataSourceCards.mediaExposure.companies.MediaExposureItemsCollapse.index';

const { Panel } = Collapse;
function MediaExposureItemsCollapse({ items, title, itemsCountType, toPrint = false }) {
  const [currentPage, setCurrentPage] = useState(1);
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();
  const { openModal, DetailsModalContent } = MediaExposureDetailsModal();

  const currentPageItems = useMemo(() => {
    const startIndex = currentPage - 1;
    const endIndex = startIndex > 0 ? startIndex + ITEMS_PER_PAGE : ITEMS_PER_PAGE;
    const paginatedItems = items?.slice(startIndex, endIndex);

    return paginatedItems;
  }, [currentPage, items]);

  return (
    <>
      {DetailsModalContent}
      <DataSourceCardCollapsePanel
        className="media-exposure-collapse"
        toPrint={toPrint}
        disabled={!items?.length || items?.length <= 0}
        customHeader={
          <div className="flex space-between">
            <span className="text-dark text-bold">{title || '-'}</span>
            <DottedLabel
              type={itemsCountType || 'default'}
              description={`(${items?.length || 0}) ${translate(
                `${I18N_BASE_PATH}.extra.itemsLabel`
              )}`}
            />
          </div>
        }
      >
        {currentPageItems?.map((item, index) => (
          <>
            <Row align="middle" justify="space-between">
              <Col span={20}>
                <span className="text-dark text-bold">{item?.title || '-'}</span>
              </Col>
              <Col span={4}>
                <a
                  disabled={!item?.url}
                  href={item?.url || '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="media-link-button"
                >
                  {translate(`${I18N_BASE_PATH}.items.anchorText`)}
                </a>
              </Col>
            </Row>
            <Row align="middle" justify="space-between" className="mrg-top-5">
              <Col span={24}>
                <span className="entities-text">
                  {translate(`${I18N_BASE_PATH}.items.entitiesText.start`)}{' '}
                  <Button
                    className={
                      item?.sentimentAnalysis?.entities?.PEOPLE
                        ? 'open-modal-button'
                        : 'collapse-label open-modal-button'
                    }
                    type="link"
                    disabled={!item?.sentimentAnalysis?.entities?.PEOPLE}
                    ghost
                    onClick={() => openModal(item?.sentimentAnalysis?.entities?.PEOPLE)}
                  >
                    {translate(`${I18N_BASE_PATH}.items.entitiesText.peopleCount`, {
                      count: item?.sentimentAnalysis?.peopleCount || 0,
                    })}
                  </Button>
                  <Button
                    className={
                      item?.sentimentAnalysis?.entities?.PLACES
                        ? 'open-modal-button'
                        : 'collapse-label open-modal-button'
                    }
                    disabled={!item?.sentimentAnalysis?.entities?.PLACES}
                    type="link"
                    ghost
                    onClick={() => openModal(item?.sentimentAnalysis?.entities?.PLACES)}
                  >
                    {translate(`${I18N_BASE_PATH}.items.entitiesText.placesCount`, {
                      count: item?.sentimentAnalysis?.placesCount || 0,
                    })}
                  </Button>
                  <Button
                    type="link"
                    className={
                      item?.sentimentAnalysis?.entities?.ORGANIZATIONS
                        ? 'open-modal-button'
                        : 'collapse-label open-modal-button'
                    }
                    ghost
                    disabled={!item?.sentimentAnalysis?.entities?.ORGANIZATIONS}
                    onClick={() =>
                      openModal(item?.sentimentAnalysis?.entities?.ORGANIZATIONS)
                    }
                  >
                    {translate(
                      `${I18N_BASE_PATH}.items.entitiesText.organizationsCount`,
                      {
                        count: item?.sentimentAnalysis?.organizationsCount || 0,
                      }
                    )}
                  </Button>
                </span>
              </Col>
            </Row>
            <Row align="middle" justify="space-between" className="mrg-top-20">
              <Col span={8}>
                <span className="collapse-label">
                  {translate(`${I18N_BASE_PATH}.items.sourceName`)}:
                </span>{' '}
                <span>{item?.sourceName || '-'}</span>
              </Col>
              <Col span={8}>
                <span className="collapse-label">
                  {translate(`${I18N_BASE_PATH}.items.sentimentAnalysisLabel`)}:
                </span>{' '}
                <span>
                  {translate(
                    sentimentLabelMappings[item?.sentimentAnalysis?.label] ||
                      item?.sentimentAnalysis?.label ||
                      sentimentLabelMappings.UNDEFINED
                  )}
                </span>
              </Col>
              <Col span={8}>
                <span className="collapse-label">
                  {translate(`${I18N_BASE_PATH}.items.publicationDate`)}:
                </span>{' '}
                <span>{i18nFormatDate(item?.publicationDate, 1) || '-'}</span>
              </Col>
            </Row>
            {item?.categories?.length && (
              <>
                <Divider />
                <Row>
                  <Col span={24}>
                    {item?.categories
                      ?.slice(
                        0,
                        item?.categories?.length > MAX_VISIBLE_TAGS
                          ? MAX_VISIBLE_TAGS
                          : item?.categories?.length
                      )
                      .map((category) => (
                        <Tag
                          outlined
                          color="default"
                          className="mrg-left-10 mrg-right-10"
                        >
                          <span className="text-bold">{category}</span>
                        </Tag>
                      ))}

                    {item?.categories?.length > MAX_VISIBLE_TAGS && (
                      <Popover
                        trigger="click"
                        content={item?.categories
                          ?.slice(MAX_VISIBLE_TAGS, item?.categories?.length)
                          .map((category) => (
                            <Tag
                              outlined
                              color="default"
                              className="mrg-left-10 mrg-right-10"
                            >
                              <span className="text-bold">{category}</span>
                            </Tag>
                          ))}
                      >
                        <Tag
                          outlined
                          color="default"
                          className="mrg-left-10 mrg-right-10"
                        >
                          <span className="text-bold">
                            + ({' '}
                            {(item?.categories?.length - MAX_VISIBLE_TAGS)
                              .toString()
                              .padStart(2, '0')}{' '}
                            )
                          </span>
                        </Tag>
                      </Popover>
                    )}
                  </Col>
                </Row>
              </>
            )}
            {index < (currentPageItems?.length || 0) - 1 && (
              <Row>
                <Divider />
              </Row>
            )}
          </>
        ))}
      </DataSourceCardCollapsePanel>
      <Pagination
        onChange={(page) => setCurrentPage(page)}
        pageSize={ITEMS_PER_PAGE}
        total={items?.length}
        defaultCurrent={1}
        showTotal={(total, range) =>
          translate(`${I18N_BASE_PATH}.items.pagination.showTotal`, {
            rangeZero: range[0],
            rangeOne: range[1],
            total,
          })
        }
        hideOnSinglePage
        showSorterTooltip={false}
      />
    </>
  );
}

MediaExposureItemsCollapse.propTypes = {
  items: PropTypes.arrayOf({}),
  title: PropTypes.string.isRequired,
  itemsCountType: PropTypes.string.isRequired,
  toPrint: PropTypes.bool.isRequired,
};
MediaExposureItemsCollapse.defaultProps = {
  items: [],
};

export default MediaExposureItemsCollapse;
