import React, { createContext, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { message } from 'antd';

import { useFetch } from 'services/hooks';

const TagsContext = createContext({});

const TagsProvider = ({ children }) => {
  const { t: translate } = useTranslation();

  const { post, get, delete: deleteTagFromProfile } = useFetch();

  const [allTagsList, setAllTagsList] = useState([]);
  const [customAllTags, setCustomAllTags] = useState(null);

  const [success, setSuccess] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [loadingAllTags, setLoadingAllTags] = useState(false);
  const [loadingCustomTags, setLoadingCustomTags] = useState(false);

  const [loadingCreateTag, setLoadingCreateTag] = useState(false);
  const [loadingAddTag, setLoadingAddTag] = useState(false);

  const openTagsMenu = useCallback(() => {
    setDropdownOpen(true);
  }, []);

  const closeTagsMenu = useCallback(() => {
    setDropdownOpen(false);
    setSuccess(0);
  }, []);

  const loadAllTags = useCallback(
    async (searchValue, hasSearchChange) => {
      try {
        setLoadingAllTags(true);

        if (!searchValue && !hasSearchChange) {
          setLoadingCustomTags(true);
        }

        const response = await get({
          url: '/tags',
          config: {
            params: {
              _search: searchValue || '',
              _limit: 1000,
            },
          },
          showMessage: false,
        });

        setAllTagsList(response?.docs);
        setLoadingAllTags(false);

        if (!searchValue && !hasSearchChange) {
          setLoadingCustomTags(false);
        }

        return Promise.resolve(response?.docs);
      } catch (error) {
        setLoadingAllTags(false);

        if (!searchValue && !hasSearchChange) {
          setLoadingCustomTags(false);
        }

        return Promise.reject(error);
      }
    },
    [get]
  );

  const createTag = useCallback(
    async (url, payload) => {
      try {
        setSuccess(0);
        setLoadingCreateTag(true);

        const response = await post({
          url: '/tags',
          payload,
          showError: false,
        });
        await post({
          url: `${url}/tags`,
          payload: {
            tagId: response.data._id,
          },
          showError: false,
        });

        setSuccess((curState) => curState + 1);
        setLoadingCreateTag(false);

        closeTagsMenu();
        message.success(translate('global.hooks.tags.messages.success.createTag'));
      } catch (error) {
        setLoadingCreateTag(false);
      }
    },
    [post, closeTagsMenu, translate]
  );

  const addTag = useCallback(
    async (url, data) => {
      try {
        setSuccess(0);
        setLoadingAddTag(true);

        await post({
          url: `${url}/tags`,
          payload: {
            tagId: data._id,
            title: data.title,
            color: data.color,
          },
          showError: false,
        });

        setSuccess((curState) => curState + 1);
        setLoadingAddTag(false);

        closeTagsMenu();
        message.success(translate('global.hooks.tags.messages.success.addTag'));
      } catch (error) {
        setLoadingAddTag(false);
      }
    },
    [post, closeTagsMenu, translate]
  );

  const deleteTag = useCallback(
    async (url, id) => {
      try {
        setSuccess(0);

        await deleteTagFromProfile({
          url: `${url}/tags/${id}`,
          showError: false,
        });

        setSuccess((curState) => curState + 1);
        message.success(translate('global.hooks.tags.messages.success.deleteTag'));
      } catch (error) {
        //
      }
    },
    [deleteTagFromProfile, translate]
  );

  const fillCustomAllTags = useCallback((tagsList) => {
    const parsedTagsList = tagsList?.reduce(
      (obj, item) => ({ ...obj, [item._id]: item }),
      {}
    );

    setCustomAllTags(parsedTagsList);
  }, []);

  return (
    <TagsContext.Provider
      value={{
        success,
        createTag,
        addTag,
        deleteTag,
        loadAllTags,
        openTagsMenu,
        closeTagsMenu,
        fillCustomAllTags,
        dropdownOpen,
        allTagsList,
        customAllTags,
        loadingAllTags,
        loadingCustomTags,
        loadingCreateTag,
        loadingAddTag,
      }}
    >
      {children}
    </TagsContext.Provider>
  );
};

function useTags() {
  const context = useContext(TagsContext);

  if (!context) {
    throw new Error('useTags must be used within an TagsContext');
  }

  return context;
}

export { TagsProvider, useTags };
