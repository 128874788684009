import React, { useCallback } from 'react';
import { Col, Row, Card, Dropdown, Menu, Tag, message } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useFetch } from 'services/hooks';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import useModalConfirmAction from 'components/ModalConfirmAction';

import useModalTagUpdate from './Modals/ModalTagUpdate';

const I18N_BASE_PATH = 'pages.private.tagsAndReasons.components.TagCard';

const TagCard = ({ data, refreshList }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();
  const { delete: deleteTag, loading } = useFetch();
  const { openModal: openUpdateTagModal, TagUpdateModal } = useModalTagUpdate();

  const handleDeleteTag = useCallback(async () => {
    try {
      await deleteTag({
        url: `tags/${data?._id}`,
      });
      message.success(translate(`${I18N_BASE_PATH}.handleDeleteMessage.success`));
    } catch (error) {
      message.error(translate(`${I18N_BASE_PATH}.handleDeleteMessage.error`));
    }

    refreshList();
  }, [data, deleteTag, refreshList, translate]);

  const { openModal: openConfirmModal, ConfirmActionModal } = useModalConfirmAction({
    action: handleDeleteTag,
    refreshListRef: refreshList?.current,
    loading,
    danger: true,
    okButton: translate(`${I18N_BASE_PATH}.confirmDeleteModal.okButton`),
    cancelButton: translate(`${I18N_BASE_PATH}.confirmDeleteModal.cancelButton`),
    title: translate(`${I18N_BASE_PATH}.confirmDeleteModal.title`),
    subtitle: translate(`${I18N_BASE_PATH}.confirmDeleteModal.subtitle`),
  });

  return (
    <>
      {TagUpdateModal}
      {ConfirmActionModal}
      <Card loading={loading}>
        <Row align="middle" justify="space-between">
          <Col span={20}>
            <Col>
              <Tag color={`#${data?.color}`}>{data?.title}</Tag>

              <span>
                <span className="text-dark text-bold mrg-left-20">
                  {translate(`${I18N_BASE_PATH}.columns.createdAt`)}:
                </span>{' '}
                {i18nFormatDate(data?.createdAt, 1)}
              </span>
            </Col>
          </Col>
          <Col span={4}>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key="1"
                    onClick={() =>
                      openUpdateTagModal({
                        tag: data,
                        refreshList,
                        isNew: false,
                      })
                    }
                  >
                    <i className="caf-ic_edit" />
                    <span>
                      {translate(`${I18N_BASE_PATH}.dropdownMenuOptions.updateRegister`)}
                    </span>
                  </Menu.Item>
                  <Menu.Item key="2" onClick={openConfirmModal}>
                    <i className="caf-ic_trash opt-cancel" />
                    <span className="opt-cancel">
                      {translate(`${I18N_BASE_PATH}.dropdownMenuOptions.delete`)}
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
              placement="bottomRight"
            >
              <div style={{ marginLeft: '75%' }} className="btn-more-icon">
                <i className="caf-ic_more-vertical" />
              </div>
            </Dropdown>
          </Col>
        </Row>
      </Card>
    </>
  );
};

TagCard.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string,
    color: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
  refreshList: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
};

export default TagCard;
