import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Collapse } from 'antd';

import { useUploadManager } from 'hooks/uploadManager';
import FileListItem from './FileListItem';

import './styles.less';

const { Panel } = Collapse;

const Widget = () => {
  const { t: translate } = useTranslation();

  const { widgetOpened, items, closeUploadWidget, uploading } = useUploadManager();
  const [title, setTitle] = useState('');

  const fileList = useMemo(
    () =>
      items &&
      items.length > 0 &&
      items.map((item) => <FileListItem key={item.file.uid} item={item} />),
    [items]
  );

  useEffect(() => {
    let newTitle = '';

    const uploadingFiles = items?.filter((item) => item.uploading === true);

    if (uploadingFiles?.length === 0) {
      if (items.length === 1)
        newTitle = translate(
          'components.uploadFilesManager.widget.newTitle.uploadingFilesEmpty.justOneItem'
        );
      else
        newTitle = translate(
          'components.uploadFilesManager.widget.newTitle.uploadingFilesEmpty.moreThanOneItem',
          { items: items.length }
        );
    } else if (uploadingFiles?.length === 1) {
      newTitle = translate(
        'components.uploadFilesManager.widget.newTitle.oneUploadingFile'
      );
    } else {
      newTitle = translate(
        'components.uploadFilesManager.widget.newTitle.uploadingFilesEmpty.moreThanOneFileLoading',
        { uploadingFiles: uploadingFiles?.length }
      );
    }

    setTitle(newTitle);
  }, [items, uploading, translate]);

  return (
    <Card id="upload-card-container" style={{ display: widgetOpened ? 'block' : 'none' }}>
      <div className="close-upload-card">
        <button type="button" disabled={uploading} onClick={closeUploadWidget}>
          <i className="caf-ic_close" />
        </button>
      </div>
      <Collapse
        defaultActiveKey={['uploads']}
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <div className="expand-icon">
            <i className={isActive ? 'caf-ic_arrow_down' : 'caf-ic_arrow_up'} />
          </div>
        )}
      >
        <Panel header={title} key="uploads" id="upload-panel">
          {fileList}
        </Panel>
      </Collapse>
    </Card>
  );
};

export default Widget;
