import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Pagination } from 'antd';
import usePagination from 'customHooks/usePagination';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companyDetails.subPages.people.creditReport';

const PreviousDirectors = ({ previousDirectorsList, print = false }) => {
  const { t: translate } = useTranslation();

  const topRef = useRef(null);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: print ? previousDirectorsList?.length : 10,
  });

  const handleChangePage = (page) => {
    setPagination((old) => ({ ...old, currentPage: page }));
    topRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const { paginatedItems, paginationStart, paginationEnd } = usePagination({
    pagination,
    items: previousDirectorsList,
  });

  const { t } = useTranslation();

  return (
    <div className="previous-director-list">
      <Row>
        <Col span={9} className="previous-director-column" style={{ fontSize: '14px' }}>
          {translate(
            `${I18N_BASE_PATH}.tabs.directors.previousDirectors.columns.name`,
            'Name'
          )}
        </Col>
        <Col span={1}>
          <div className="current" />
        </Col>
        <Col span={4} className="previous-director-column">
          {translate(
            `${I18N_BASE_PATH}.tabs.directors.previousDirectors.columns.currentActiveAppointments`,
            'Current Active Appointments'
          )}
        </Col>
        <Col span={1}>
          <div className="previous" />
        </Col>
        <Col span={4} className="previous-director-column">
          {translate(
            `${I18N_BASE_PATH}.tabs.directors.previousDirectors.columns.previousDirectorships`,
            'Previous Directorships'
          )}
        </Col>
        <Col span={1}>
          <div className="dissolved" />
        </Col>
        <Col span={4} className="previous-director-column">
          {translate(
            `${I18N_BASE_PATH}.tabs.directors.previousDirectors.columns.dissolvedDirectorships`,
            'Dissolved Directorships'
          )}
        </Col>
      </Row>
      <div className="mrg-btm-25" />
      {paginatedItems?.map((element) => (
        <Row key={element?.id} className="line">
          <Col span={9} className="director-name">
            {element?.name}
          </Col>
          <Col span={5} className="director-infos">
            {element?.additionalData?.presentAppointments}
          </Col>
          <Col span={5} className="director-infos">
            {element?.additionalData?.previousAppointments}
          </Col>
          <Col span={5} className="director-infos">
            {element?.additionalData?.dissolvedAppointments}
          </Col>
        </Row>
      ))}
      <Row className="row-show-pagination-people">
        <Col span={12}>
          <span>
            {t(
              `pages.private.KYBCompany.pages.companyDetails.subPages.records.pagination`,
              {
                start: paginationStart,
                end: paginationEnd,
                total: previousDirectorsList.length,
              }
            )}
          </span>
        </Col>
        <Col span={12}>
          <Pagination
            current={pagination.currentPage}
            onChange={handleChangePage}
            pageSize={pagination.pageSize}
            total={previousDirectorsList.length}
            defaultCurrent={1}
            showSizeChanger={false}
            showSorterTooltip={false}
          />
        </Col>
      </Row>
    </div>
  );
};

export default PreviousDirectors;
