import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FeatureFlagWrapper from 'components/FeatureFlagWrapper';

import './styles.less';

const QueryPrice = ({ price }) => {
  const { t: translate } = useTranslation();

  return (
    <FeatureFlagWrapper featureFlag="costPrices">
      <div id="query-price-container">
        <span>
          {translate(
            'pages.private.profiles.components.queryPrice.index.queryPrice.title'
          )}
        </span>
        <strong>{price}</strong>
      </div>
    </FeatureFlagWrapper>
  );
};

QueryPrice.propTypes = {
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default QueryPrice;
