import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Select, Spin } from 'antd';
import { useQueryParams, StringParam, ArrayParam } from 'use-query-params';
import { useWorkflow } from 'hooks/useWorkflow';
import { useAuth } from 'hooks/auth';
import { useComponentNextList } from 'components/List';
import _ from 'lodash';
import { useProfileTemplate } from 'hooks/profileTemplate';
import CompanyCard from './CompanyCard';

import './styles.less';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companySearching.components.consultedCompanies';

const ConsultedCompanies = ({ setDisabledFilters }) => {
  const { t: translate } = useTranslation();
  const [query, setQuery] = useQueryParams({
    _sort: StringParam,
    byStatus: ArrayParam,
    updatedDate: StringParam,
    createdDate: StringParam,
  });

  const {
    allProfileTemplates,
    loadProfileTemplates,
    loadingAllProfileTemplates,
  } = useProfileTemplate();

  const { data: allWorkflows, getWorkflowsData } = useWorkflow();
  const { user } = useAuth();

  const [sortValue, setSortValue] = useState('asc');

  const handleOnChangeOrder = useCallback(
    (newValue) => {
      if (newValue === 'asc') {
        setQuery({ _sort: 'date' });
      }
      if (newValue === 'desc') {
        setQuery({ _sort: undefined });
      }
    },
    [setQuery]
  );

  useEffect(() => {
    setSortValue(() => {
      if (query._sort === 'date') {
        return 'asc';
      }
      return 'desc';
    });
  }, [query]);

  const {
    componentList,
    updateParams,
    allData,
    loadingFivetimes,
    loading,
    paginationLabel,
  } = useComponentNextList({
    component: CompanyCard,
    rowKey: '_id',
    url: 'executions',
    componentProps: {
      allProfileTemplates,
      allWorkflows,
    },
    customLoader: loadingAllProfileTemplates,
  });

  useEffect(() => {
    loadProfileTemplates({
      active: 'all',
    });
    getWorkflowsData({ tenantId: user?.tenantId, _limit: 1000 });
  }, [loadProfileTemplates, getWorkflowsData, user]);

  const mapFilters = useCallback(() => {
    const filters = {};

    if (query.updatedDate) {
      switch (query.updatedDate) {
        case 'last24Hours':
          filters._startUpdatedDate = new Date().getTime() - 24 * 60 * 60 * 1000;
          filters._endUpdatedDate = new Date().getTime();
          break;
        case 'last7Days':
          filters._startUpdatedDate = new Date().getTime() - 24 * 60 * 60 * 1000 * 7;
          filters._endUpdatedDate = new Date().getTime();
          break;
        case 'last30Days':
          filters._startUpdatedDate = new Date().getTime() - 24 * 60 * 60 * 1000 * 30;
          filters._endUpdatedDate = new Date().getTime();
          break;
        case 'lastYear':
          filters._startUpdatedDate = new Date().setFullYear(
            new Date().getFullYear() - 1
          );
          filters._endUpdatedDate = new Date().getTime();
          break;
        default:
          if (query.updatedDate.substr(0, 6) === 'custom') {
            const [startDate, endDate] = query.updatedDate.substr(7).split('-');
            filters._startUpdatedDate = startDate;
            filters._endUpdatedDate = endDate;
          }
          break;
      }
    }

    if (query.createdDate) {
      switch (query.createdDate) {
        case 'last24Hours':
          filters._startCreatedDate = new Date().getTime() - 24 * 60 * 60 * 1000;
          filters._endCreatedDate = new Date().getTime();
          break;
        case 'last7Days':
          filters._startCreatedDate = new Date().getTime() - 24 * 60 * 60 * 1000 * 7;
          filters._endCreatedDate = new Date().getTime();
          break;
        case 'last30Days':
          filters._startCreatedDate = new Date().getTime() - 24 * 60 * 60 * 1000 * 30;
          filters._endCreatedDate = new Date().getTime();
          break;
        case 'lastYear':
          filters._startCreatedDate = new Date().setFullYear(
            new Date().getFullYear() - 1
          );
          filters._endCreatedDate = new Date().getTime();
          break;
        default:
          if (query.createdDate.substr(0, 6) === 'custom') {
            const [startDate, endDate] = query.createdDate.substr(7).split('-');
            filters._startCreatedDate = startDate;
            filters._endCreatedDate = endDate;
          }
          break;
      }
    } else {
      filters._startCreatedDate = new Date().setFullYear(new Date().getFullYear() - 1);
      filters._endCreatedDate = new Date().getTime();
    }

    if (query._sort) {
      filters._sort = query._sort;
    }

    if (query.byStatus && !_.isEmpty(query.byStatus)) {
      filters.status = query.byStatus.join('|');
    }

    return filters;
  }, [query]);

  useEffect(() => {
    updateParams({
      url: 'executions',
      config: {
        params: {
          _limit: 100,
          segment: 'company',
          ...mapFilters(),
        },
      },
    });
  }, [query, updateParams, mapFilters]);

  const parsedListContent = useMemo(() => {
    setDisabledFilters(loading || loadingFivetimes);
    if (loading || loadingFivetimes) return <></>;

    return componentList;
  }, [setDisabledFilters, loading, loadingFivetimes, componentList]);

  return (
    <div className="consulted-companies-list">
      <Row className="title">
        <Col span={24}>{translate(`${I18N_BASE_PATH}.title`, 'History')}</Col>
      </Row>
      <Row>
        <Col span={12} className="pagination-label">
          {paginationLabel}
        </Col>
        <Col span={12} className="filters">
          <Select
            style={{ minWidth: 170, fontSize: '14px' }}
            placeholder={translate(`${I18N_BASE_PATH}.sort.placeholder`, 'order')}
            onChange={handleOnChangeOrder}
            value={sortValue}
            className="text-dark"
          >
            <Select.Option value="asc">
              {translate(`${I18N_BASE_PATH}.sort.option.asc`, 'Oldest')}
            </Select.Option>
            <Select.Option value="desc">
              {translate(`${I18N_BASE_PATH}.sort.option.desc`, 'Latest')}
            </Select.Option>
          </Select>
        </Col>
      </Row>

      {(loading || loadingFivetimes) && (
        <div className="loading">
          <Spin />
        </div>
      )}
      {parsedListContent}
    </div>
  );
};

export default ConsultedCompanies;
