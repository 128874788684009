import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useI18nFormatters } from 'hooks/i18nFormatters';
import FeatureFlagWrapper from 'components/FeatureFlagWrapper';

import './styles.less';

const PriceWidget = ({ pricePerProfile }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatCurrency } = useI18nFormatters();
  const I18N_BASE_PATH = 'pages.private.routines.detail.components.priceWidget';

  return (
    <FeatureFlagWrapper featureFlag="costPrices">
      <div id="profile-model-price-widget-component" className="gx-w-100 pdd-left-50">
        <div>
          <span>
            {translate(`${I18N_BASE_PATH}.title.before`)} <br />{' '}
            {translate(`${I18N_BASE_PATH}.title.after`)}
          </span>
          <div className="prices">
            <strong>{i18nFormatCurrency(pricePerProfile, 'pt-BR')}</strong>
          </div>
        </div>
      </div>
    </FeatureFlagWrapper>
  );
};

PriceWidget.propTypes = {
  pricePerProfile: PropTypes.number,
};

PriceWidget.defaultProps = {
  pricePerProfile: 0,
};

export default PriceWidget;
