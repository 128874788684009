import React from 'react';
import { useTranslation } from 'react-i18next';

// import { useValidations } from 'hooks/validations';
import { useExecution } from 'hooks/execution';
import ReactJsonView from 'react-json-view';

import DossierCard from '../../../DossierCard';
import './styles.less';

const UserData = () => {
  const { t: translate } = useTranslation();

  const { execution } = useExecution();

  return (
    <>
      <DossierCard
        title={translate(
          'pages.private.executions.components.tabs.components.userData.parameters.title'
        )}
        subtitle={translate(
          'pages.private.executions.components.tabs.components.userData.parameters.subtitle'
        )}
        icon="caf-ic_person"
      >
        <div className="json-view-wrapper">
          <ReactJsonView
            name={false}
            indentWidth={2}
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
            src={execution?.parameters}
          />
        </div>
      </DossierCard>
      <DossierCard
        title={translate(
          'pages.private.executions.components.tabs.components.userData.metadata.title'
        )}
        subtitle={translate(
          'pages.private.executions.components.tabs.components.userData.metadata.subtitle'
        )}
        icon="caf-ic_code"
      >
        <div className="json-view-wrapper">
          <ReactJsonView
            name={false}
            indentWidth={2}
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
            src={execution?.metadata}
          />
        </div>
      </DossierCard>
    </>
  );
};

export default UserData;
