/* eslint-disable react/prop-types */
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useRouteMatch } from 'react-router-dom';
import _ from 'lodash';
import { Modal, Row, Col, Form, Spin, message, Tooltip, Alert } from 'antd';

import { useFetch } from 'services/hooks';
import { useAuth } from 'hooks/auth';
import { useCompany } from 'hooks/company';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { usePerson } from 'hooks/person';

import { hasRequiredPermissions } from 'routes/permissionFunctions';

import templateServicesCategories from 'utils/data/templateServicesCategories.json';

import Button from 'components/Button';
import PageTitle from 'components/PageTitle';
import ValidationRulesStepCard from 'pages/private/Profiles/components/ValidationRulesStepCard';

import QueryPrice from 'pages/private/Profiles/components/QueryPrice';

import { hasFeatureFlag } from 'utils/verifyFeatureFlag';
import BackgroundChecking from './components/steps/BackgroundChecking';
import Finish from './components/steps/Finish';

import './styles.less';

const ManualQuery = ({ profileTemplate }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatCurrency } = useI18nFormatters();

  const [form] = Form.useForm();
  const [additionalDataForm, setAdditionalInfoForm] = useState(null);
  const [showAlertInfo, setShowAlertInfo] = useState(true);

  const { user } = useAuth();

  const {
    data: templateServicesList,
    get: getTemplateServicesList,
    loading: loadingServicesList,
  } = useFetch();

  const { post: executeCustomData, loading: loadingExecute } = useFetch();

  const { personId, companyId } = useParams();
  const matchPeople = useRouteMatch('/profile/people');

  const { refreshPerson, person, loadingPerson } = usePerson();
  const { refreshCompany, company, loadingCompany } = useCompany();

  const [modalVisible, setModalVisible] = useState(false);
  const [queryPrice, setQueryPrice] = useState(0);
  const [step, setStep] = useState(0);
  const [profileType, setProfileType] = useState(profileTemplate?.type);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setQueryPrice(0);
    setStep(0);

    form.resetFields();
  }, [form]);

  const handleCancel = useCallback(() => {
    if (step === 0) return closeModal();

    if (step === 2) return setStep(1);

    return setStep(0);
  }, [closeModal, step]);

  const openModal = useCallback(() => {
    setModalVisible(true);
    setProfileType(profileTemplate?.type);
  }, [profileTemplate]);

  const backgroundCheckingServices = useMemo(() => {
    return profileTemplate?.backgroundChecking?.services || [];
  }, [profileTemplate]);

  const profileId = useMemo(() => {
    if (personId) return personId;
    if (companyId) return companyId;
    return '';
  }, [personId, companyId]);

  const profile = useMemo(() => {
    if (personId && !loadingPerson && person) return person;
    if (companyId && !loadingCompany && company) return company;
    return undefined;
  }, [personId, loadingPerson, person, companyId, loadingCompany, company]);

  const formattedServicesList = useMemo(() => {
    if (!templateServicesList || (!person && !company)) return {};

    const categoryFormatted = {};
    const docsFormatted = {};

    const currentProfileTemplateSources =
      profile?.profileTemplate?.backgroundChecking?.services?.map((s) => s.source) || [];

    const activeServicesList =
      templateServicesList?.docs?.filter(({ source }) =>
        currentProfileTemplateSources.includes(source)
      ) || [];

    const categoryKeys = activeServicesList.map((d) => d.category).flat();
    const uniqueKeys = _.uniqBy(categoryKeys);

    const result = uniqueKeys.map((category) => {
      const documents = activeServicesList.filter((cd) => cd.category.includes(category));

      const pfDocs = documents.filter(
        (doc) =>
          doc.type ===
          translate('pages.private.profiles.components.manualQuery.index.type.person')
      );
      const pjDocs = documents.filter(
        (doc) =>
          doc.type ===
          translate('pages.private.profiles.components.manualQuery.index.type.company')
      );

      return {
        category: templateServicesCategories[category],
        docs: {
          pfDocs: personId && pfDocs,
          pjDocs: companyId && pjDocs,
        },
      };
    });

    result.forEach((res) => {
      const _category = res.category || {};
      const _docs = personId ? res.docs.pfDocs || [] : res.docs.pjDocs || [];

      const categoryParsed = { [_category.id]: _category };
      const docsParsed = _docs?.reduce(
        (obj, item) => ({ ...obj, [item.source]: item }),
        {}
      );

      Object.assign(categoryFormatted, { ...categoryParsed });
      Object.assign(docsFormatted, { ...docsParsed });
    });

    return { docs: docsFormatted, categories: categoryFormatted };
  }, [templateServicesList, person, company, profile, personId, companyId, translate]);

  const showContent = useMemo(() => {
    return (
      !loadingServicesList &&
      !loadingPerson &&
      !loadingCompany &&
      backgroundCheckingServices?.length > 0 &&
      !_.isEmpty(formattedServicesList)
    );
  }, [
    loadingServicesList,
    loadingPerson,
    loadingCompany,
    backgroundCheckingServices,
    formattedServicesList,
  ]);

  const handleChange = useCallback(() => {
    const reports = form.getFieldValue().backgroundCheckingServices || {};
    const prices = [];
    /* if (hasFeatureFlag('costPrices', user)) {
      return setQueryPrice(1);
    } */
    Object.keys(reports).forEach((item) => {
      const currentItem = reports[item] || undefined;
      const price = currentItem ? currentItem.price || 0 : 0;

      prices.push(price);
    });

    const totalPrice = prices.reduce((acc, cur) => acc + cur, 0);
    return setQueryPrice(totalPrice);
  }, [form]);

  const submit = useCallback(async () => {
    try {
      await additionalDataForm?.validateFields();

      const services = form.getFieldValue().backgroundCheckingServices || [];
      const additionalData = additionalDataForm.getFieldValue();

      const additional = {
        cpf: additionalData.cpf?.replace(/\D/g, ''),
        cnpj: additionalData.cnpj?.replace(/\D/g, ''),
        uf: additionalData?.uf?.toUpperCase(),
        birthDate: additionalData.birthDate,
        name: additionalData?.name?.toUpperCase(),
        motherName: additionalData?.motherName?.toUpperCase(),
        fatherName: additionalData?.fatherName?.toUpperCase(),
        rgIssuingAuthority: additionalData?.rgIssuingAuthority?.toUpperCase(),
        rgIssueDate: additionalData?.issueDate,
        rg: additionalData?.rg?.toUpperCase(),
        nit: additionalData?.nit,
        cnhRegistrationNumber: additionalData?.cnhRegistrationNumber,
        cnhRenachNumber: additionalData?.cnhRenachNumber,
        cnhMirrorNumber: additionalData?.cnhMirrorNumber,
        cnhFirstDriveLicenseDate: additionalData?.cnhFirstDriveLicenseDate,
        cnhExpirationDate: additionalData?.cnhExpirationDate,
        plate: additionalData?.plate,
        cep: additionalData?.cep,
      };

      const selectedServices = [];

      Object.keys(services).forEach((item) => {
        const currentItem = services[item]?.source ? services[item] : undefined;
        if (currentItem) selectedServices.push(currentItem);
      });

      const options = {
        url: `/${companyId ? 'companies' : 'people'}/${profileId}/execute`,
        payload: {
          services: selectedServices,
          additionalInfo: additional,
        },

        showError: true,
      };

      await executeCustomData(options);

      message.success(
        translate(
          'pages.private.profiles.components.manualQuery.index.submit.message.success'
        )
      );

      closeModal();

      if (personId) {
        refreshPerson();
      }
      if (companyId) {
        refreshCompany();
      }
    } catch (err) {
      // message.error(
      //   translate(
      //     'pages.private.profiles.components.manualQuery.index.submit.message.error'
      //   )
      // );
    }

    return null;
  }, [
    form,
    additionalDataForm,
    companyId,
    profileId,
    executeCustomData,
    personId,
    refreshPerson,
    refreshCompany,
    closeModal,
    translate,
  ]);

  const dataSourcesList = useMemo(() => {
    const list = Object.values(formattedServicesList?.docs || {}) || [];

    return list;
  }, [formattedServicesList]);

  const confirmButtonText = useMemo(
    () =>
      translate(
        `pages.private.profiles.components.manualQuery.index.confirmButtonText.${
          step === 0 || step === 1 ? 'continue' : 'consult'
        }`
      ),
    [step, translate]
  );

  const subtitleText = useMemo(
    () =>
      translate(
        `pages.private.profiles.components.manualQuery.index.subtitleText.${
          // eslint-disable-next-line no-nested-ternary
          step === 0 ? 'initialStep' : step === 1 ? 'secondStep' : 'thirdStep'
        }`
      ),
    [step, translate]
  );

  const tooltipMessageCustomizePermissions = useMemo(() => {
    let tooltipMessage;

    if (
      hasRequiredPermissions(
        user,
        ['profile-templates:create', 'profile-templates:update'],
        [],
        true
      )
    ) {
      tooltipMessage = translate(
        'pages.private.profiles.components.manualQuery.index.tooltipMessageCustomizePermissions.hasAllPermissions'
      );

      return tooltipMessage;
    }

    if (hasRequiredPermissions(user, ['profile-templates:create'], [])) {
      tooltipMessage = translate(
        'pages.private.profiles.components.manualQuery.index.tooltipMessageCustomizePermissions.hasOnlyCreatePermission'
      );

      return tooltipMessage;
    }

    if (hasRequiredPermissions(user, ['profile-templates:update'], [])) {
      tooltipMessage = translate(
        'pages.private.profiles.components.manualQuery.index.tooltipMessageCustomizePermissions.hasOnlyUpdatePermission'
      );

      return tooltipMessage;
    }

    if (
      hasRequiredPermissions(
        user,
        matchPeople ? ['people:update'] : ['companies:update'],
        []
      )
    ) {
      tooltipMessage = translate(
        'pages.private.profiles.components.manualQuery.index.tooltipMessageCustomizePermissions.hasAnyPermissions'
      );

      return tooltipMessage;
    }

    tooltipMessage = translate(
      'pages.private.profiles.components.manualQuery.index.tooltipMessageCustomizePermissions.default'
    );

    return tooltipMessage;
  }, [user, matchPeople, translate]);

  useEffect(() => {
    if (modalVisible) {
      getTemplateServicesList({ url: '/template-services', showMessage: false });
    }
  }, [getTemplateServicesList, modalVisible]);

  const ManualQueryModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeModal}
        footer={null}
        style={{ top: 40 }}
        width={700}
      >
        {profileTemplate && (
          <div className="no-mrg no-pdd manual-query-modal">
            <div className="tooltip-message-customize-permissions">
              <PageTitle
                title={translate(
                  'pages.private.profiles.components.manualQuery.index.manualQuery.pageTitle.title'
                )}
                subtitle={subtitleText}
              />

              {step === 0 && (
                <button onClick={() => setShowAlertInfo(!showAlertInfo)} type="button">
                  <Tooltip
                    title={
                      !showAlertInfo &&
                      translate(
                        'pages.private.profiles.components.manualQuery.index.manualQuery.tooltipInfo'
                      )
                    }
                    placement="leftBottom"
                  >
                    <i
                      style={{ color: '#828282', fontSize: '18px' }}
                      className="caf-ic_help-circle"
                    />
                  </Tooltip>
                </button>
              )}
            </div>

            {step === 0 && showAlertInfo && (
              <div className="mrg-vertical-5">
                <Alert
                  className="alert-info-form"
                  message={tooltipMessageCustomizePermissions}
                  type="info"
                  showIcon
                  closable
                  onClose={() => setShowAlertInfo(false)}
                />
              </div>
            )}

            <Form
              form={form}
              onValuesChange={handleChange}
              onFinish={() => (step === 2 ? submit() : setStep(step === 0 ? 1 : 2))}
            >
              <Row className="manual-query-content">
                {loadingServicesList && (
                  <Col span={24}>
                    <Spin className="flex center gx-w-100" />
                  </Col>
                )}

                {showContent && step === 0 && (
                  <Col span={24} className="manual-query-collapse-container">
                    <BackgroundChecking
                      profileType={profileType}
                      dataSources={dataSourcesList}
                      disabled={false}
                      formRef={form}
                      onValuesChange={handleChange}
                    />
                  </Col>
                )}

                {showContent && step === 1 && (
                  <Col span={24}>
                    <ValidationRulesStepCard
                      profileTemplate={profileTemplate}
                      formRef={form}
                      modalType="manual-query"
                    />
                  </Col>
                )}

                {showContent && step === 2 && (
                  <Col span={24}>
                    <Finish
                      profileType={profileType}
                      dataSources={dataSourcesList}
                      formRef={form}
                      onValuesChange={handleChange}
                      setAdditionalInfoFormFunc={setAdditionalInfoForm}
                    />
                  </Col>
                )}
              </Row>

              <Row
                justify="space-between"
                className={
                  hasFeatureFlag('costPrices', user)
                    ? 'manual-query-footer-with-flag'
                    : 'manual-query-footer'
                }
              >
                {loadingServicesList && <div className="flex flex-1" />}

                {!loadingServicesList && (
                  <>
                    {!hasFeatureFlag('costPrices', user) && (
                      <QueryPrice price={i18nFormatCurrency(queryPrice, 'pt-BR')} />
                    )}
                  </>
                )}

                <div className="manual-query-btns">
                  <Button type="default" onClick={handleCancel} className="btn-cancel">
                    {translate(
                      `pages.private.profiles.components.manualQuery.index.manualQuery.button.initialStep.${
                        step === 0 ? 'cancel' : 'return'
                      }`
                    )}
                  </Button>

                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={loadingServicesList || loadingExecute}
                  >
                    {loadingExecute
                      ? translate(
                          'pages.private.profiles.components.manualQuery.index.manualQuery.button.secondStep'
                        )
                      : confirmButtonText}
                  </Button>
                </div>
              </Row>
            </Form>
          </div>
        )}
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      profileTemplate,
      translate,
      subtitleText,
      step,
      showAlertInfo,
      tooltipMessageCustomizePermissions,
      form,
      handleChange,
      loadingServicesList,
      showContent,
      profileType,
      dataSourcesList,
      user,
      i18nFormatCurrency,
      queryPrice,
      handleCancel,
      loadingExecute,
      confirmButtonText,
      submit,
    ]
  );

  return { openModal, closeModal, ManualQueryModal };
};

export default ManualQuery;
