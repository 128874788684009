import React, { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Collapse, Row, Col, Tabs, Card, Alert } from 'antd';
import { uniqBy } from 'lodash';
import classNames from 'classnames';

import servicesCategories from 'utils/data/templateServicesCategories.json';
import ToggleIcon from 'components/CollapseHeader/ToggleIcon';
import CollapseTitle from 'components/CollapseHeader/CollapseTitle';
import Tag from 'components/Tag';
import AdditionalInfo from 'components/AdditionalInfo';
import SearchInput from 'components/Form/SearchInput';
import EmptyState from 'components/EmptyState';

import PermissionWrapper from 'routes/PermissionWrapper';

import { BOTH_TYPES_SOURCES } from './utils';
import RenderPanelContent from './RenderPanelContent';

import './styles.less';

const { Panel } = Collapse;
const { TabPane } = Tabs;

const I18N_BASE_PATH = 'pages.private.profileModels.components.backgroundChecking.index';

const ContentTab = ({
  results,
  translate,
  formRef,
  onValuesChange,
  mode,
  disabled,
  dataSources,
  text,
}) => {
  const [collapseActiveKey, setCollapseActiveKey] = useState('basic_info');

  const formCheckboxValues = formRef.getFieldValue('backgroundCheckingServices');

  const getObjectKeyWhoAreNotUndefined = Object.keys(formCheckboxValues).filter(
    (key) => formCheckboxValues[key] !== undefined
  );

  const resultCheckedInfos = useCallback(
    (itemSelected) => {
      return results
        .find((item) => item.category.id === itemSelected)
        .docs.filter((result) => getObjectKeyWhoAreNotUndefined.includes(result.source))
        .length;
    },
    [getObjectKeyWhoAreNotUndefined, results]
  );

  const QuantityTag = useCallback(
    ({ category }) => {
      const qnty = `${`0${resultCheckedInfos(category.id) || 0}`.slice(-2)} ${
        resultCheckedInfos(category.id) === 1
          ? translate(`${I18N_BASE_PATH}.contentTab.collapse.quantity.one`)
          : translate(`${I18N_BASE_PATH}.contentTab.collapse.quantity.moreThanOne`)
      }`;

      return <Tag color="blue">{qnty}</Tag>;
    },
    [resultCheckedInfos, translate]
  );

  const _onValuesChange = useCallback(
    (value) => {
      const data = {
        backgroundCheckingServices: value,
      };
      formRef.setFieldsValue(data);
      if (onValuesChange) onValuesChange(data);
    },
    [formRef, onValuesChange]
  );

  return (
    <div className="profile-model-collapse-container flex-column">
      <Collapse
        expandIcon={({ isActive }) => <ToggleIcon isActive={isActive} />}
        className="flex-1 gx-w-100 radius-8"
        defaultActiveKey="basic_info"
        accordion
        onChange={(activeKey) => setCollapseActiveKey(activeKey)}
      >
        {results.map(({ category, docs }) => {
          return (
            docs?.length && (
              <Panel
                forceRender
                key={category?.id}
                className="profile-model-collapse"
                showArrow={collapseActiveKey !== category?.id}
                header={
                  <>
                    <Col span={12}>
                      <CollapseTitle
                        icon={category?.icon}
                        title={translate(category?.title)}
                        extra={
                          mode !== 'preview-compact' &&
                          resultCheckedInfos(category.id) > 0 && (
                            <QuantityTag category={category} />
                          )
                        }
                      />
                    </Col>
                    <Col span={12}>
                      {mode !== 'preview-compact' && collapseActiveKey === category?.id && (
                        <Row justify="end" className="list-header  text-default">
                          <Col
                            span={16}
                            className="documentation-item-title mrg-right-15"
                          >
                            <span>
                              {translate(
                                `${I18N_BASE_PATH}.contentTab.collapse.panel.extra.description`
                              )}{' '}
                            </span>
                          </Col>
                          <PermissionWrapper
                            requiredPermissions={['integration-guide:read']}
                          >
                            <Col span={6} className="documentation-item-title">
                              <span>
                                {translate(
                                  `${I18N_BASE_PATH}.contentTab.collapse.panel.extra.documentation`
                                )}
                              </span>
                            </Col>
                          </PermissionWrapper>
                        </Row>
                      )}
                    </Col>
                  </>
                }
              >
                <RenderPanelContent
                  formRef={formRef}
                  docs={docs || []}
                  disabled={disabled}
                  allServices={dataSources}
                  onValuesChange={_onValuesChange}
                  mode={mode}
                  text={text}
                />
              </Panel>
            )
          );
        })}
      </Collapse>
    </div>
  );
};

const BackgroundChecking = ({
  mode,
  dataSources,
  profileType,
  disabled,
  formRef,
  onValuesChange,
}) => {
  const { t: translate } = useTranslation();

  const profileTypeReadable = useMemo(() => {
    if (profileType === 'PF')
      return translate(`${I18N_BASE_PATH}.profileTypeReadable.person`);
    if (profileType === 'PJ')
      return translate(`${I18N_BASE_PATH}.profileTypeReadable.company`);
    return '';
  }, [profileType, translate]);

  const formattedDataSources = useMemo(() => {
    if (!dataSources?.length) return [];

    const categoryKeys = dataSources
      .filter((ds) => ds.productType === 'source')
      .map((d) => d.category)
      .flat();
    const uniqueKeys = uniqBy(categoryKeys);

    return Object.keys(servicesCategories)
      .filter((category) => uniqueKeys.includes(category))
      .map((category) => {
        // Filtra por categoria e pelo profileType do modelo
        const sources = dataSources.filter(
          (source) =>
            source.productType === 'source' &&
            source.category?.includes(category) &&
            (profileType === source.type || BOTH_TYPES_SOURCES.includes(source.source))
        );

        return {
          category: servicesCategories[category],
          docs: sources,
        };
      });
  }, [dataSources, profileType]);

  const tabNav = (icon, name) => (
    <div className="tab-item">
      <i className={`tab-item-icon caf-ic_${icon}`} /> {name}
    </div>
  );
  const [text, setText] = useState('');

  const onChangeHandler = (value) => {
    const targetValue = value;
    setText(targetValue);
  };

  const results = formattedDataSources.filter((result) => {
    return result.docs.find(
      (resultFind) =>
        resultFind.description.toLowerCase().includes(text.toLocaleLowerCase()) ||
        resultFind.title.toLowerCase().includes(text.toLocaleLowerCase())
    );
  });

  return (
    <section
      id="profile-model-background-checking-component"
      className={classNames('profile-model-recurrence-props gx-w-100', {
        'preview-compact-mode': mode === 'preview-compact',
        'edit-mode': mode === 'edit',
      })}
    >
      {mode === 'edit' ? (
        <Tabs
          defaultActiveKey="background"
          tabBarExtraContent={
            <SearchInput
              placeholder={translate(`${I18N_BASE_PATH}.filter.placeHolder`)}
              name="filter"
              onChange={(event) => {
                onChangeHandler(event.target.value);
              }}
              style={{ width: 350 }}
            />
          }
        >
          <TabPane
            tab={tabNav(
              'documentation',
              translate(`${I18N_BASE_PATH}.backgroundChecking.tabs.background`)
            )}
            key="background"
          >
            {results.length !== 0 ? (
              <ContentTab
                results={results}
                translate={translate}
                formRef={formRef}
                onValuesChange={onValuesChange}
                formattedDataSources={formattedDataSources}
                mode={mode}
                disabled={disabled}
                dataSources={dataSources}
                text={text}
              />
            ) : (
              <EmptyState
                type="failed"
                description={translate(`${I18N_BASE_PATH}.filter.emptyState`)}
              />
            )}
          </TabPane>
          <TabPane
            tab={tabNav(
              'eye',
              translate(`${I18N_BASE_PATH}.backgroundChecking.tabs.form.title`)
            )}
            key="form"
          >
            <Card className="profile-model-preview-form-container">
              <Alert
                className="alert-info-form"
                message={translate(
                  `${I18N_BASE_PATH}.backgroundChecking.tabs.form.alert.necessaryInformation`
                )}
                type="info"
                showIcon
              />
              <Alert
                className="alert-info-form"
                message={translate(
                  `${I18N_BASE_PATH}.backgroundChecking.tabs.form.alert.form`
                )}
                type="info"
                showIcon
              />
              <div className="pdd-top-15" style={{ maxWidth: 800, margin: '0 auto' }}>
                <Card
                  title={`${translate(
                    `${I18N_BASE_PATH}.backgroundChecking.tabs.form.card.title`
                  )} - ${profileTypeReadable}`}
                  className="no-mrg"
                >
                  <AdditionalInfo
                    services={Object.values(
                      formRef.getFieldValue('backgroundCheckingServices') || {}
                    )}
                    type={profileType}
                    preForm
                  />
                </Card>
              </div>
            </Card>
          </TabPane>
        </Tabs>
      ) : (
        <ContentTab
          results={results}
          translate={translate}
          formRef={formRef}
          onValuesChange={onValuesChange}
          formattedDataSources={formattedDataSources}
          mode={mode}
          disabled={disabled}
          dataSources={dataSources}
          text={text}
        />
      )}
    </section>
  );
};

BackgroundChecking.propTypes = {
  mode: PropTypes.oneOf(['preview-compact', 'edit']),
  dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
  profileType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onValuesChange: PropTypes.func,
};

ContentTab.propTypes = {
  mode: PropTypes.oneOf(['preview-compact', 'edit']).isRequired,
  dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool.isRequired,
  onValuesChange: PropTypes.func.isRequired,
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  translate: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

BackgroundChecking.defaultProps = {
  mode: 'edit',
  disabled: false,
  onValuesChange: null,
};

export default BackgroundChecking;
