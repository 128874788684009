/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col, Typography } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import CircleIcon from 'components/Icon/CircleIcon';
import TooltipInfo from 'components/TooltipInfo';

import BRFlag from 'assets/images/flag-br.svg';

import { sanctionsInfos } from '../../../utils/sanctionsDetails';
import { pepLevel } from '../../../utils/handlerSanctions';
import { motiveTranslator } from '../../../utils/translator';

import SourceDetails from '../SourcesDetails';
import SourceInfosToPrint from '../SourceInfosToPrint';

import './styles.less';

const { Title } = Typography;

const SourcesCard = ({
  data,
  sanctionsSource,
  pepSource,
  lastConsultation,
  national,
  international,
  others,
  pep,
  toPrint,
  toPrintDetails,
}) => {
  const { t: translate } = useTranslation();

  const { i18nFormatDate } = useI18nFormatters();

  const sourceTitle = useMemo(() => {
    if (!others) {
      if (pep)
        return (
          translate(motiveTranslator(data?.motive)) ||
          translate(
            'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourcesCard.index.sourceTitle.hasNotMotive'
          )
        );
      return data || '-';
    }
    return 'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourcesCard.index.sourceTitle.default';
  }, [data, pep, others, translate]);

  const sourceSubtitle = useMemo(() => {
    if (pep) {
      if (!data?.source && data?.level >= 2)
        return translate(
          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourcesCard.index.sourceSubtitle.relationships'
        );
      if (!data?.source && !data?.level >= 2)
        return translate(
          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourcesCard.index.sourceSubtitle.uninformedSource'
        );
      return data?.source || '-';
    }
    return translate(sanctionsInfos(data)?.subtitle) || '-';
  }, [pep, data, translate]);

  const showTooltipInfo = useMemo(() => {
    return (
      !toPrint &&
      pep &&
      sourceSubtitle !== '-' &&
      sourceSubtitle !==
        translate(
          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourcesCard.index.showTooltipInfo.uninformedSource'
        )
    );
  }, [toPrint, pep, sourceSubtitle, translate]);

  return (
    <Card bordered={false} id="information-source-card">
      <Row>
        <Col flex="0 1" className="information-source-icons">
          <CircleIcon
            image={!others && international && sanctionsInfos(data)?.icon}
            icon={
              pep ? pepLevel(data?.level, translate)?.icon : sanctionsInfos(data)?.icon
            }
            danger={pep ? pepSource?.length > 0 : sanctionsSource?.sanctions?.length > 0}
          />

          {national && (
            <img
              src={BRFlag}
              alt={translate(
                'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourcesCard.index.sourcesCard.image.national.alt'
              )}
              className="flag-icon"
            />
          )}
        </Col>

        <Col flex="0.95 1" className="information-source-content">
          <Title
            level={4}
            className={`information-source-title
            ${pep && !translate(motiveTranslator(data?.motive)) && 'gx-font-italic'}`}
          >
            {sourceTitle}
          </Title>

          <Typography
            className={`information-source-subtitle
            ${pep && !data?.source && !data?.level >= 2 && 'gx-font-italic'}`}
          >
            {sourceSubtitle}

            {showTooltipInfo && (
              <TooltipInfo
                title={translate(
                  'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourcesCard.index.sourcesCard.tooltipInfo.title'
                )}
                size={14}
              />
            )}
          </Typography>

          <SourceDetails
            pep={pep}
            others={others}
            data={pep ? pepSource : sanctionsSource}
            title={pep ? translate(motiveTranslator(data?.motive)) : sourceTitle}
            subtitle={sourceSubtitle}
          />

          <Typography className="information-source-updated">
            {pep
              ? i18nFormatDate(data?.lastUpdateDate, 6)
              : i18nFormatDate(lastConsultation, 6)}
          </Typography>

          {toPrint && toPrintDetails && (
            <SourceInfosToPrint
              pep={pep}
              others={others}
              data={pep ? pepSource : sanctionsSource?.sanctions}
            />
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default SourcesCard;
