import { useContext } from 'react';

import { SecurityOptionsContext } from '../contexts';

export const useSecurityOptions = () => {
  const context = useContext(SecurityOptionsContext);

  if (!context)
    throw new Error('useSecurityOptions must be used within an SecurityOptionsContext');

  return context;
};
