import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Button, Form, Input } from 'antd';

import PageTitle from 'components/PageTitle';
import { useFetch } from 'services/hooks';
import InputMask from 'components/Form/InputMask';
import { toMaskedCpf, toMaskedCnpj } from 'utils/formatters';
import { useOnboardingTemplate } from 'hooks/onboardingTemplate';

import './styles.less';

const ModalShareNewLink = ({ openCopyLinkModal }) => {
  const { t: translate } = useTranslation();

  const { post: postOnboardingExt } = useFetch();
  const [form] = Form.useForm();
  const { loadOnboardingTemplates } = useOnboardingTemplate();

  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const closeModal = useCallback(() => {
    form.resetFields();
    setModalVisible(false);
  }, [form]);

  const openModal = useCallback((entityData, refreshListCallback) => {
    setModalVisible(true);
    setLoading(true);
    setData({ entityData, refreshListCallback });
    setLoading(false);
  }, []);

  const submit = useCallback(
    async (payload) => {
      try {
        setLoading(true);
        const defaultTemplate = await loadOnboardingTemplates();
        const response = await postOnboardingExt({
          url: '/onboardings',
          payload: {
            noExpire: false,
            trustTemplateId: data?.entityData?.profileTemplateId,
            email: payload?.email,
            templateId:
              data?.entityData?.profileTemplate?.onboardingTemplateId ||
              defaultTemplate[data?.entityData?.type]?._id,
            smsPhoneNumber: payload?.phone,
            type: data?.entityData?.type,
            attributes: {
              cpf: data?.entityData?.cpf,
              cnpj: data?.entityData?.cnpj,
            },
            metadata: { origin: 'TRUST' },
          },
        });

        openCopyLinkModal(response.onboardingUrl, 'share');
        if (data.refreshListCallback) data.refreshListCallback();
        closeModal();
      } catch (e) {
        //
      }
      setLoading(false);
    },
    [closeModal, data, openCopyLinkModal, postOnboardingExt]
  );

  const ShareLinkModal = useMemo(
    () => (
      <Modal
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        visible={modalVisible}
        footer={null}
        width={700}
        onCancel={closeModal}
      >
        <PageTitle
          title={translate(
            'pages.private.profiles.components.list.components.modalShareNewLink.index.shareLinkModal.pageTitle.title'
          )}
          subtitle={translate(
            'pages.private.profiles.components.list.components.modalShareNewLink.index.shareLinkModal.pageTitle.subtitle'
          )}
        />
        <Form form={form} onFinish={submit}>
          <div id="content-container-share-link">
            <strong className="profile-name">
              {data?.entityData?.name ||
                toMaskedCpf(data?.entityData?.cpf) ||
                toMaskedCnpj(data?.entityData?.cnpj)}
            </strong>
            <div>
              <div className="input-grid">
                <Form.Item className="form-phone no-mrg" name="phone">
                  <InputMask
                    mask={translate(
                      'pages.private.profiles.components.list.components.modalShareNewLink.index.shareLinkModal.form.phone.mask'
                    )}
                    prefix={<i style={{ color: '#000' }} className="caf-ic_smartphone" />}
                    bordered={false}
                    placeholder={translate(
                      'pages.private.profiles.components.list.components.modalShareNewLink.index.shareLinkModal.form.phone.placeholder'
                    )}
                  />
                </Form.Item>
                <Form.Item className="form-mail" name="email">
                  <Input
                    type="email"
                    prefix={<i style={{ color: '#000' }} className="caf-ic_mail" />}
                    bordered={false}
                    placeholder={translate(
                      'pages.private.profiles.components.list.components.modalShareNewLink.index.shareLinkModal.form.email.placeholder'
                    )}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="modal-action-buttons" align="middle" justify="end">
            <Button
              type="ghost"
              shape="round"
              className="flex no-mrg-btm center"
              outlined="false"
              onClick={closeModal}
            >
              {translate(
                'pages.private.profiles.components.list.components.modalShareNewLink.index.shareLinkModal.form.button.cancel'
              )}
            </Button>
            <Button
              type="primary"
              shape="round"
              htmlType="submit"
              className="flex no-mrg-btm center"
              outlined
              loading={loading}
              disabled={loading}
            >
              {translate(
                'pages.private.profiles.components.list.components.modalShareNewLink.index.shareLinkModal.form.button.share'
              )}
            </Button>
          </div>
        </Form>
      </Modal>
    ),
    [modalVisible, closeModal, submit, data, loading, form, translate]
  );

  return { openModal, ShareLinkModal };
};

export default ModalShareNewLink;
