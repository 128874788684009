import { DatePicker, Form, Select, Input } from 'antd';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'services/hooks';
import PropTypes from 'prop-types';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';
import { useAuth } from 'hooks/auth';
import { useWorkflow } from 'hooks/useWorkflow';
import { Search } from '@combateafraude/icons/general';

import './styles.less';

const I18N_BASE =
  'pages.private.profileModels.profileModelList.components.links.index.links.components.customFilters';

const CustomFilters = ({ form, query }) => {
  const { t: translate } = useTranslation();
  const { get } = useFetch();
  const { user } = useAuth();

  const { getWorkflowsData, data: workflowsData } = useWorkflow();

  const fetchWorkflows = useCallback(async () => {
    await getWorkflowsData({ tenantId: user?.tenantId });
  }, [getWorkflowsData, user]);

  useEffect(() => {
    fetchWorkflows();
  }, [fetchWorkflows]);

  const [filterSelected, setFilterSelected] = useState(() => {
    if (query?.name) return 'name';
    if (query?.cpf) return 'cpf';
    if (query?.cnpj) return 'cnpj';
    if (query?.startDate) return 'createdAt';
    if (query?.templateId) return 'templateId';
    if (query?.status) return 'status';
    if (query?.personId) return 'personId';
    if (query?.companyId) return 'companyId';
    if (query?.workflowId) return 'workflowId';
    return 'token';
  });
  const [profileTemplates, setProfileTemplates] = useState([]);

  useEffect(() => {
    get({
      url: '/profile-templates',
      showMessage: false,
      config: {
        params: {
          active: true,
        },
      },
    }).then((response) => {
      setProfileTemplates(response.docs);
    });
  }, [get]);

  const onFilterSelected = useCallback(
    (value) => {
      form.setFieldsValue({
        token: undefined,
        name: undefined,
        cpf: undefined,
        cnpj: undefined,
        startDate: undefined,
        endDate: undefined,
        templateId: undefined,
        status: undefined,
        companyId: undefined,
        personId: undefined,
        workflowId: undefined,
      });
      setFilterSelected(value);
    },
    [form]
  );

  const filterByToken = useMemo(
    () => (
      <Form.Item name="token">
        <Input
          className="mrg-top-5"
          allowClear
          prefix={<Search style={{ color: '#004af7' }} width={23} height={25} />}
          autoComplete="off"
          placeholder={translate(`${I18N_BASE}.placeholder`)}
        />
      </Form.Item>
    ),
    [translate]
  );

  const filterByName = useMemo(
    () => (
      <Form.Item name="name">
        <Input
          className="mrg-top-5"
          allowClear
          prefix={<Search style={{ color: '#004af7' }} width={23} height={25} />}
          autoComplete="off"
          placeholder={translate(`${I18N_BASE}.placeholder`)}
        />
      </Form.Item>
    ),
    [translate]
  );

  const filterByCpf = useMemo(
    () => (
      <Form.Item name="cpf">
        <Input
          className="mrg-top-5"
          allowClear
          prefix={<Search style={{ color: '#004af7' }} width={23} height={25} />}
          autoComplete="off"
          placeholder={translate(`${I18N_BASE}.placeholder`)}
        />
      </Form.Item>
    ),
    [translate]
  );

  const filterByCreatedAt = useMemo(
    () => (
      <Form.Item name="createdAt" className="mrg-top-5">
        <DatePicker.RangePicker format="DD/MM/YYYY" placeholder={['De', 'Até']} />
      </Form.Item>
    ),
    []
  );

  const filterByCnpj = useMemo(
    () => (
      <Form.Item name="cnpj">
        <Input
          className="mrg-top-5"
          allowClear
          prefix={<Search style={{ color: '#004af7' }} width={23} height={25} />}
          autoComplete="off"
          placeholder={translate(`${I18N_BASE}.placeholder`)}
        />
      </Form.Item>
    ),
    [translate]
  );

  const filterByTemplateId = useMemo(
    () => (
      <Form.Item name="templateId">
        <Select
          allowClear
          defaultValue={query.templateId}
          placeholder={translate(`${I18N_BASE}.placeholder`)}
          showSearch
          removeIcon
          className="mrg-top-5"
          style={{ minWidth: 130 }}
          filterOption={(input, option) => {
            return option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
          }}
        >
          {profileTemplates.map((template) => (
            <Select.Option value={template._id}>{template.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
    ),
    [profileTemplates, query.templateId, translate]
  );

  const filterByStatus = useMemo(
    () => (
      <Form.Item name="status">
        <Select
          bordered={false}
          placeholder={translate(`${I18N_BASE}.placeholder`)}
          defaultValue={
            query.status ? query.status : translate(`${I18N_BASE}.placeholder`)
          }
          allowClear
          className="status-searching mrg-top-13"
        >
          <Select.Option value="COMPLETED">
            {translate(`${I18N_BASE}.filterByStatus.COMPLETED`)}
          </Select.Option>
          <Select.Option value="PENDING">
            {translate(`${I18N_BASE}.filterByStatus.PENDING`)}
          </Select.Option>
          <Select.Option value="MULTIPLE">
            {translate(`${I18N_BASE}.filterByStatus.MULTIPLE`)}
          </Select.Option>
        </Select>
      </Form.Item>
    ),
    [query, translate]
  );

  const filterByPersonId = useMemo(
    () => (
      <Form.Item name="personId">
        <Input
          className="mrg-top-5"
          allowClear
          prefix={<Search style={{ color: '#004af7' }} width={23} height={25} />}
          autoComplete="off"
          placeholder={translate(`${I18N_BASE}.placeholder`)}
        />
      </Form.Item>
    ),
    [translate]
  );

  const filterByCompanyId = useMemo(
    () => (
      <Form.Item name="companyId">
        <Input
          className="mrg-top-5"
          allowClear
          prefix={<Search style={{ color: '#004af7' }} width={23} height={25} />}
          autoComplete="off"
          placeholder={translate(`${I18N_BASE}.placeholder`)}
        />
      </Form.Item>
    ),
    [translate]
  );

  const filterByWorkflowId = useMemo(
    () => (
      <Form.Item name="workflowId">
        <Select
          removeIcon
          className="mrg-top-5"
          style={{ minWidth: 130 }}
          allowClear
          placeholder={translate(`${I18N_BASE}.placeholder`)}
          showSearch
          filterOption={(input, option) => {
            return option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
          }}
        >
          {workflowsData?.docs?.map((wf) => (
            <Select.Option value={wf._id}>{wf.definition.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
    ),
    [workflowsData, query.workflowId, translate]
  );

  return (
    <>
      <div className="wrapper-searching">
        <span className="title-searching">
          {translate(`${I18N_BASE}.labels.filterBy`)}
        </span>
        <Form.Item>
          <Select
            onChange={(value) => onFilterSelected(value)}
            defaultValue={filterSelected}
            bordered={false}
            style={{ minWidth: 125, height: 25 }}
            className="apply-searching-by"
          >
            <Select.Option value="token">
              {translate(`${I18N_BASE}.filterOptions.token`)}
            </Select.Option>
            <Select.Option value="name">
              {translate(`${I18N_BASE}.filterOptions.name`)}
            </Select.Option>
            {!hasFeatureFlag('isWorkflowBuilder', user) && (
              <>
                <Select.Option value="cpf">
                  {translate(`${I18N_BASE}.filterOptions.cpf`)}
                </Select.Option>
                <Select.Option value="cnpj">
                  {translate(`${I18N_BASE}.filterOptions.cnpj`)}
                </Select.Option>
                <Select.Option value="templateId">
                  {translate(`${I18N_BASE}.filterOptions.templateId`)}
                </Select.Option>
              </>
            )}
            <Select.Option value="createdAt">
              {translate(`${I18N_BASE}.filterOptions.createdAt`)}
            </Select.Option>
            <Select.Option value="status">
              {translate(`${I18N_BASE}.filterOptions.status`)}
            </Select.Option>
            {hasFeatureFlag('isWorkflowBuilder', user) && (
              <>
                <Select.Option value="personId">Person ID</Select.Option>
                <Select.Option value="companyId">Company ID</Select.Option>
                <Select.Option value="workflowId">Workflow</Select.Option>
              </>
            )}
          </Select>
        </Form.Item>
      </div>
      <div className="mrg-top-20">
        {filterSelected === 'token' && filterByToken}
        {filterSelected === 'name' && filterByName}
        {filterSelected === 'cpf' && filterByCpf}
        {filterSelected === 'cnpj' && filterByCnpj}
        {filterSelected === 'createdAt' && filterByCreatedAt}
        {filterSelected === 'templateId' && filterByTemplateId}
        {filterSelected === 'status' && filterByStatus}
        {filterSelected === 'personId' && filterByPersonId}
        {filterSelected === 'companyId' && filterByCompanyId}
        {filterSelected === 'workflowId' && filterByWorkflowId}
      </div>
    </>
  );
};

CustomFilters.propTypes = {
  query: PropTypes.shape({
    token: PropTypes.string,
    name: PropTypes.string,
    cpf: PropTypes.string,
    cnpj: PropTypes.string,
    startDate: PropTypes.string,
    templateId: PropTypes.string,
    status: PropTypes.string,
    personId: PropTypes.string,
    companyId: PropTypes.string,
    workflowId: PropTypes.string,
  }).isRequired,
};

export default CustomFilters;
