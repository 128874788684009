/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyState from 'components/EmptyState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import useAllProtestsModal from './AllProtestsModal';

const Protests = ({ profile, type, toPrint /* urlId */ }) => {
  const { t: translate } = useTranslation();

  const protests = useMemo(() => profile?.finance?.protest, [profile]);

  const { openModal, AllProtestsModal } = useAllProtestsModal({
    data: profile?.finance?.protest || [],
  });

  const handlerQuantity = useMemo(() => {
    if (!protests) return { title: null, type: null };

    if (!protests?.items || protests?.items?.length === 0) {
      return {
        title: translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.shared.protests.index.handlerQuantity.regular'
        ),
        type: 'primary',
      };
    }

    if (protests?.items?.length === 1) {
      return {
        title: translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.shared.protests.index.handlerQuantity.hasOneProcess'
        ),
        type: 'danger',
      };
    }

    return {
      title: translate(
        'pages.private.profiles.components.dataSourceCards.financialInformation.shared.protests.index.handlerQuantity.hasMoreThanOneProcess',
        { value: protests?.items?.length }
      ),
      type: 'danger',
    };
  }, [protests, translate]);

  const isProcessing = useMemo(() => verifySourcesProcessing(profile, 'protest'), [
    profile,
  ]);

  const handleOpenModal = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <>
      {AllProtestsModal}

      <ColumnLabel
        title={translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.shared.protests.index.protests.columnLabel.title'
        )}
        description={handlerQuantity?.title}
        type={handlerQuantity?.type}
        onClick={handlerQuantity?.type === 'danger' && handleOpenModal}
      >
        {!protests && isProcessing && <EmptyState type="processing" />}

        {!protests && !isProcessing && <EmptyState type="waiting_consult" />}

        {protests && (!protests?.items || protests?.items?.length === 0) && (
          <span>
            {translate(
              'pages.private.profiles.components.dataSourceCards.financialInformation.shared.protests.index.protests.hasNoProcesses',
              { type }
            )}
          </span>
        )}

        {!toPrint && protests?.items?.length > 0 && (
          <span>
            {translate(
              'pages.private.profiles.components.dataSourceCards.financialInformation.shared.protests.index.protests.detail'
            )}
          </span>
        )}
      </ColumnLabel>
    </>
  );
};

export default Protests;
