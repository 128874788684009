import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { useQueryParams, StringParam } from 'use-query-params';

import PageTitle from 'components/PageTitle';
import { useProfileTemplate } from 'hooks/profileTemplate';

import Footer from '../components/Footer';
import OnboardingServices from '../../components/OnboardingServices';
import PriceWidget from '../../components/PriceWidget';

const OnboardingServicesStep = ({ formRef, onValuesChange }) => {
  const { t: translate } = useTranslation();

  const history = useHistory();
  const [query, setQuery] = useQueryParams({
    step: StringParam,
    action: StringParam,
  });

  const { cafServicesList, summaryPrices } = useProfileTemplate();

  const onSubmit = useCallback(() => {
    if (query.action === 'update') {
      setQuery({ step: 'confirmation', action: undefined });
    } else {
      setQuery({ step: 'background_checking' });
    }
  }, [query.action, setQuery]);

  const onboardingServicesList = useMemo(
    () =>
      cafServicesList?.docs?.filter(({ category }) => category === 'onboarding') || [],
    [cafServicesList]
  );

  return (
    <>
      <Row className="gx-flex-nowrap no-mrg mrg-btm-30" align="middle">
        <Col flex={1}>
          <PageTitle
            title={translate(
              'pages.private.profileModels.profileModelCreate.onboardingServicesStep.pageTitle.title'
            )}
            subtitle={translate(
              'pages.private.profileModels.profileModelCreate.onboardingServicesStep.pageTitle.subtitle'
            )}
          />
        </Col>
        <Col>
          <PriceWidget
            pricePerProfile={summaryPrices?.pricePerProfile}
            pricePerMonth={summaryPrices?.pricePerMonth}
          />
        </Col>
      </Row>

      <OnboardingServices
        services={onboardingServicesList}
        formRef={formRef}
        onValuesChange={onValuesChange}
      />

      <Footer
        onCancel={() => history.goBack()}
        onSubmit={() => onSubmit()}
        buttonCancelTitle={translate(
          'pages.private.profileModels.profileModelCreate.onboardingServicesStep.footer.buttonCancelTitle'
        )}
      />
    </>
  );
};

OnboardingServicesStep.propTypes = {
  formRef: PropTypes.shape().isRequired,
  onValuesChange: PropTypes.func.isRequired,
};

export default OnboardingServicesStep;
