import React from 'react';
import PropTypes from 'prop-types';

import './styles.less';

const Loader = ({ size, color, strokeWidth, padding, show }) => {
  return (
    show && (
      <div id="loader-component" className="showbox" style={{ padding }}>
        <div className="loader" style={{ width: size }}>
          <svg className="circular" viewBox="25 25 50 50">
            <circle
              className="path"
              cx="50"
              cy="50"
              r="20"
              style={{ strokeWidth, stroke: color || null }}
              fill="none"
              strokeMiterlimit="10"
            />
          </svg>
        </div>
      </div>
    )
  );
};

Loader.propTypes = {
  padding: PropTypes.string,
  size: PropTypes.string,
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
  show: PropTypes.bool,
};

Loader.defaultProps = {
  padding: '5%',
  size: '30px',
  strokeWidth: 2,
  color: null,
  show: true,
};

export default Loader;
