import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useFetch } from 'services/hooks';
import { message } from 'antd';

import frequencyMultiplier from 'utils/data/frequencyMultiplier.json';

const ProfileTemplateContext = createContext({});

const QSA_RULES_INITIAL_VALUE = (translate) => [
  {
    rule: 'completed_qsa_onboarding',
    title: translate(
      'global.hooks.profileTemplate.profileTemplateInitialValues.QSA_RULES_INITIAL_VALUE.completed_qsa_onboarding.title'
    ),
    _indicated: false,
  },
  {
    rule: 'none_invalid_qsa_profiles',
    title: translate(
      'global.hooks.profileTemplate.profileTemplateInitialValues.QSA_RULES_INITIAL_VALUE.none_invalid_qsa_profiles.title'
    ),
    _indicated: false,
  },
  {
    rule: 'some_valid_qsa_admin_profile',
    title: translate(
      'global.hooks.profileTemplate.profileTemplateInitialValues.QSA_RULES_INITIAL_VALUE.some_valid_qsa_admin_profile.title'
    ),
    _indicated: false,
  },
  {
    rule: 'some_valid_qsa_profile',
    title: translate(
      'global.hooks.profileTemplate.profileTemplateInitialValues.QSA_RULES_INITIAL_VALUE.some_valid_qsa_profile.title'
    ),
    _indicated: false,
  },
  {
    rule: 'valid_all_qsa_admin_profiles',
    title: translate(
      'global.hooks.profileTemplate.profileTemplateInitialValues.QSA_RULES_INITIAL_VALUE.valid_all_qsa_admin_profiles.title'
    ),
    _indicated: false,
  },
  {
    rule: 'is_attorney',
    title: translate(
      'global.hooks.profileTemplate.profileTemplateInitialValues.PROFILE_RULES_INITIAL_VALUE.is_attorney.title'
    ),
    _indicated: false,
  },
];

const PROFILE_RULES_INITIAL_VALUE = (translate) => [
  {
    rule: 'has_profile_documents',
    title: translate(
      'global.hooks.profileTemplate.profileTemplateInitialValues.PROFILE_RULES_INITIAL_VALUE.has_profile_documents.title'
    ),
    _indicated: false,
  },
  {
    rule: 'has_profile_selfie',
    title: translate(
      'global.hooks.profileTemplate.profileTemplateInitialValues.PROFILE_RULES_INITIAL_VALUE.has_profile_selfie.title'
    ),
    _indicated: false,
  },
  {
    rule: 'is_attorney',
    title: translate(
      'global.hooks.profileTemplate.profileTemplateInitialValues.PROFILE_RULES_INITIAL_VALUE.is_attorney.title'
    ),
    _indicated: false,
  },
];

const ProfileTemplateProvider = ({ children }) => {
  const { t: translate } = useTranslation();
  const { patch, post } = useFetch();
  const { get: fetchProfileTemplateById } = useFetch();
  const {
    get: fetchProfileTemplate,
    loading: loadingAllProfileTemplates,
    data: allProfileTemplatesData,
  } = useFetch();
  const { get: fetchCafServicesList, loading: loadingCafServicesList } = useFetch();

  // Temporário
  const [cafServicesListCustom, setCafServicesListCustom] = useState(null);
  // const { cafServicesList, loadingCafServicesList } = useTemplatesServicesCache();

  const [success, setSuccess] = useState(false);
  const [product, setProduct] = useState(null);
  const [checkedServices, setCheckedServices] = useState([]);
  const [summaryPrices, setSummaryPrices] = useState([]);
  const [isStartingToCreateNewProfileModel, setIsStartToCreateNewProfileModel] = useState(
    false
  );
  const [cleanProfileTemplate, setCleanProfileTemplate] = useState(null);
  const [profileTemplate, setProfileTemplate] = useState(null);
  const [profileIsActive, setProfileIsActive] = useState(true);
  const [loadingAddProfileTemplate, setLoadingAddProfileTemplate] = useState(false);
  const [loadingCreateProfileTemplate, setLoadingCreateProfileTemplate] = useState(false);

  const applyCustomValues = (data) => {
    if (!data) {
      return data;
    }
    const docs = data?.docs?.map((service) => {
      if (service.source === 'ocr') {
        const cpfService = data.docs.find((s) => {
          return s.source === 'cpf';
        });

        // une as regras de compliance relacionadas as da fonte de CPF e do servico OCR
        const bothSourcesRelatedRules = [];

        if (cpfService?.relatedRules?.length) {
          bothSourcesRelatedRules.push(...cpfService?.relatedRules);
        }

        if (service?.relatedRules?.length) {
          bothSourcesRelatedRules.push(...service.relatedRules);
        }

        // remove duplicatas criando um Set e transforma o mesmo em um array novamente
        const mergedRules = Array.from(new Set(bothSourcesRelatedRules));

        return {
          ...service,
          price: (service.price || 0) + (cpfService?.price || 0),
          priceExtra: cpfService?.price,
          title: `OCR + Consulta na Receita Federal`,
          description: 'Extração de dados do documento e confirmação na Receita Federal',
          relatedRules: mergedRules,
        };
      }
      return service;
    });

    const custom = { ...data, docs };
    return custom;
  };

  const changeCheckedServices = useCallback(
    (_profileTemplate) => {
      if (_profileTemplate) {
        const services = {
          ...(_profileTemplate?.backgroundCheckingServices || {}),
          ...(_profileTemplate?.onboardingServices || {}),
        };

        const newCheckedServices =
          Object.keys(services)
            .filter((key) => services[key])
            .map((key) => ({ source: key, ...services[key] })) || [];

        if (newCheckedServices.length !== checkedServices.length) {
          setCheckedServices(newCheckedServices);
        }

        return newCheckedServices;
      }
      return [];
    },
    [checkedServices]
  );

  const serviceIsOnProduct = useCallback(
    (serviceName) => {
      if (!serviceName) {
        return false;
      }
      const bgServices = product?.template?.backgroundChecking?.services?.map(
        (service) => service.source
      );
      const onboardingServices = product?.template?.onboarding?.services?.map(
        (service) => service.source
      );
      return (
        !!onboardingServices?.includes(serviceName) || !!bgServices?.includes(serviceName)
      );
    },
    [product]
  );

  const recalculateSummaryPrices = useCallback(
    (services) => {
      const priceProduct = product?.price ?? 0;
      const prices = services?.reduce(
        (acc, cur) => {
          if (serviceIsOnProduct(cur.source)) {
            return acc;
          }
          if (cur.category === 'onboarding') {
            acc.pricePerProfileOnboarding += cur.price || 0;
          }
          if (cur.onProfileCreation) {
            acc.pricePerProfileSources += cur.price || 0;
          }
          if (cur.frequency) {
            acc.pricePerMonth += (cur.price || 0) * frequencyMultiplier[cur.frequency];
          }
          return acc;
        },
        { pricePerProfileSources: 0, pricePerProfileOnboarding: 0, pricePerMonth: 0 }
      );
      setSummaryPrices({
        ...prices,
        pricePerProfile:
          prices.pricePerProfileSources + prices.pricePerProfileOnboarding + priceProduct,
      });
    },
    [serviceIsOnProduct, product]
  );

  useEffect(() => {
    recalculateSummaryPrices(checkedServices);
  }, [checkedServices, recalculateSummaryPrices, product]);

  const _setProduct = useCallback((_product) => {
    setProduct(_product);
  }, []);

  const _setProfileTemplate = useCallback(
    (data) => {
      const updatedData = { ...profileTemplate, ...data };
      setProfileTemplate(updatedData);

      if (data?.onboardingServices || data?.backgroundCheckingServices) {
        const newCheckedServices = changeCheckedServices(updatedData);
        updatedData.validations =
          updatedData?.validations?.filter((validation) => validation?.rule?.key) ||
          undefined;
        recalculateSummaryPrices(newCheckedServices);
      }
    },
    [changeCheckedServices, profileTemplate, recalculateSummaryPrices]
  );

  const resetProfileTemplate = useCallback(() => {
    setProfileTemplate(null);
    setProduct(null);
    setCheckedServices([]);
    setSummaryPrices([]);
  }, []);

  // Temporário
  // const applyCustomValues = useCallback((data) => {
  //   if (!data) {
  //     return data;
  //   }
  //   const docs = data?.docs?.map((service) => {
  //     if (service.source === 'ocr') {
  //       const cpfService = data.docs.find((s) => {
  //         return s.source === 'cpf';
  //       });
  //       return {
  //         ...service,
  //         price: service.price + cpfService.price,
  //         priceExtra: cpfService.price,
  //         title: `OCR + Consulta na Receita Federal`,
  //         description: 'Extração de dados do documento e confirmação na Receita Federal',
  //       };
  //     }
  //     return service;
  //   });
  //
  //   const custom = { ...data, docs };
  //   return custom;
  // }, []);

  const loadCafServicesList = useCallback(async () => {
    const response = await fetchCafServicesList({
      url: '/template-services',
      showMessage: false,
    });

    const cafServiceListWithCustomValues = applyCustomValues(response);
    setCafServicesListCustom(cafServiceListWithCustomValues);

    return cafServiceListWithCustomValues;
  }, [fetchCafServicesList]);

  const loadProfileTemplates = useCallback(
    async ({ type, active = true }) => {
      try {
        const response = await fetchProfileTemplate({
          url: '/profile-templates',
          config: {
            params: {
              type,
              active: active === 'all' ? undefined : active,
              _limit: 100,
            },
          },
          showMessage: true,
        });

        return response;
      } catch (error) {
        return [];
      }
    },
    [fetchProfileTemplate]
  );

  const getProfileTemplateById = useCallback(
    async (id, isCreateAModel) => {
      const response = await fetchProfileTemplateById({
        url: `/profile-templates/${id}`,
        showMessage: true,
      });

      if ((response?.product, isCreateAModel)) {
        let serviceslist = cafServicesListCustom;

        if (!serviceslist) serviceslist = await loadCafServicesList();

        const productData = serviceslist?.docs?.find(
          (s) => s.productType === 'template' && s?.source === response.product
        );

        const productOnbVal = productData?.template?.onboarding?.validations || [];
        const profTemplateOnbVals = response?.onboarding?.validations || [];

        const allOnbRules = profTemplateOnbVals?.map((val) => ({
          ...val,
          category: 'onboarding',
        }));

        productOnbVal.forEach((r) => {
          if (!allOnbRules?.find((x) => x.rule === r.rule)) {
            allOnbRules.push({ category: 'onboarding', ...r });
          }
        });

        const productBgVal = productData?.template?.backgroundChecking?.validations || [];
        const profTemplateBgVals = response?.backgroundChecking?.validations || [];

        const allBgCheckRules = profTemplateBgVals?.map((val) => ({
          ...val,
          category: 'backgroundChecking',
        }));

        productBgVal.forEach((r) => {
          if (!allBgCheckRules?.find((x) => x.rule === r.rule)) {
            allBgCheckRules.push({ ...r, category: 'backgroundChecking' });
          }
        });

        response.onboarding.validations = allOnbRules;
        response.backgroundChecking.validations = allBgCheckRules;
      }
      if (response?._id) {
        setCleanProfileTemplate(response);
        setProfileTemplate(response);
        setProfileIsActive(response?.active || false);
      }

      return response;
    },
    [cafServicesListCustom, fetchProfileTemplateById, loadCafServicesList]
  );

  const addProfileTemplate = useCallback(
    async ({ profileTemplateId, url, notification }) => {
      try {
        setLoadingAddProfileTemplate(true);

        await patch({
          url,
          payload: {
            profileTemplateId: profileTemplateId === 'null' ? null : profileTemplateId,
          },
          showError: false,
        });

        setSuccess(true);
        setLoadingAddProfileTemplate(false);

        message.success(notification);
      } catch (error) {
        setLoadingAddProfileTemplate(false);
      }
    },
    [patch]
  );

  const createProfileTemplate = useCallback(
    async (payload) => {
      try {
        setLoadingCreateProfileTemplate(true);

        const response = await post({
          url: '/profile-templates',
          payload,
          showError: true,
        });

        setSuccess(true);
        setLoadingCreateProfileTemplate(false);

        message.success(
          translate('global.hooks.profileTemplate.messages.success.createProfileTemplate')
        );
        return response;
      } catch (error) {
        setLoadingCreateProfileTemplate(false);
        return Promise.reject(error);
      }
    },
    [post, translate]
  );

  const allProfileTemplates = useMemo(() => {
    return allProfileTemplatesData?.docs || [];
  }, [allProfileTemplatesData]);

  return (
    <ProfileTemplateContext.Provider
      value={{
        allProfileTemplates,
        loadingAllProfileTemplates,
        loadingAddProfileTemplate,
        loadingCreateProfileTemplate,
        loadProfileTemplates,
        addProfileTemplate,
        createProfileTemplate,
        success,
        setProfileTemplate: _setProfileTemplate,
        profileTemplate,
        cleanProfileTemplate,
        loadCafServicesList,
        cafServicesList: cafServicesListCustom,
        loadingCafServicesList,
        getProfileTemplateById,
        loadingGetProfileTemplate: loadingAllProfileTemplates,
        checkedServices,
        summaryPrices,
        resetProfileTemplate,
        profileIsActive,
        setProduct: _setProduct,
        product,
        serviceIsOnProduct,
        setIsStartToCreateNewProfileModel,
        isStartingToCreateNewProfileModel,
      }}
    >
      {children}
    </ProfileTemplateContext.Provider>
  );
};

function useProfileTemplate() {
  const context = useContext(ProfileTemplateContext);

  if (!context) {
    throw new Error('useProfileTemplate must be used within an ProfileTemplateContext');
  }

  return context;
}

export {
  ProfileTemplateProvider,
  useProfileTemplate,
  PROFILE_RULES_INITIAL_VALUE,
  QSA_RULES_INITIAL_VALUE,
};
