import React, { useEffect, useState, useCallback } from 'react';
import { Form, Select, Row, Col, Spin } from 'antd';

import './styles.less';
import PageTitle from 'components/PageTitle';
import policiesData from 'utils/data/policiesData';
import { useFetch } from 'services/hooks';
import { useTranslation } from 'react-i18next';
import { useGroup } from 'hooks/group';
import { isGroupAdmin } from 'utils/translations';

const { Option } = Select;

const ExistingGroup = ({ selectedGroup, setSelectedGroup }) => {
  const I18N = `pages.private.userManagement.usersCreate.components.permissionStep.components.existingGroup.index`;
  const { t: translate } = useTranslation();
  const { listGroups, loading, getListGroups } = useGroup();

  const [loadingGroup, setLoadingGroup] = useState(false);
  const { get } = useFetch();

  useEffect(() => {
    getListGroups();
  }, [getListGroups]);

  const getGroupById = useCallback(
    async (id) => {
      setLoadingGroup(true);
      if (id) {
        const response = await get({
          url: `/groups/${id}`,
          config: {
            params: {},
          },
        });
        setSelectedGroup(response?.data);
      } else {
        setSelectedGroup({
          id: '',
          name: translate('global.hooks.group.getListGroups.noAccess'),
          policyGroups: {
            trust: '',
            management: '',
          },
          tenantId: 'combateafraude',
        });
      }
      setLoadingGroup(false);
    },
    [get, setSelectedGroup, translate]
  );

  const verifyExistsInGroup = useCallback(
    (name) => {
      if (selectedGroup?.policies?.find((policy) => policy?.name === name))
        return 'active';
      return '';
    },
    [selectedGroup]
  );

  return (
    <div id="existing-group" className="flex-column center pdd-vertical-40">
      {loading ? (
        <Spin />
      ) : (
        <>
          <PageTitle
            title={translate(`${I18N}.groupSelectTitle`)}
            subtitle={translate(`${I18N}.groupSelectSubtitle`)}
          />
          <Form.Item
            label={translate(`${I18N}.groupSelectLabel`)}
            className="mrg-top-20"
            name="groupId"
          >
            <Select
              style={{ width: '400px' }}
              onSelect={(value) => getGroupById(value)}
              showSearch
              optionFilterProp="children"
            >
              {listGroups?.map((group) => (
                <Option value={group?.id}>
                  {isGroupAdmin(group?.id)
                    ? translate('global.hooks.group.getListGroups.admin')
                    : group?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {loadingGroup ? (
            <Spin />
          ) : (
            <Row className="font-size-14">
              {policiesData?.map((policie) => (
                <Col
                  span={policie?.type === 'special' ? 12 : 6}
                  className="pdd-horizon-10 pdd-top-20"
                >
                  <Row className="no-mrg text-bold text-dark font-size-16 mrg-btm-10">
                    {translate(policie?.title)}
                  </Row>
                  {policie?.policies?.map((p) => (
                    <Row className="no-mrg pdd-top-5">
                      <i
                        className={`mrg-right-10 caf-ic_check_d text-bold ${verifyExistsInGroup(
                          p?.name
                        )}`}
                      />
                      {translate(p?.title)}
                    </Row>
                  ))}
                </Col>
              ))}
            </Row>
          )}
        </>
      )}
    </div>
  );
};

export default ExistingGroup;
