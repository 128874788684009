/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import { isEmpty } from 'utils/isObjectEmpty';

import EmptyExecutionState from 'components/EmptyExecutionState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

import './styles.less';

const I18N_BASE_PATH = 'components.dataSourceCards.financialInformation.shared.pgfn';

const PGFN = ({ source = {}, type, toPrint }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  let showStatusInfo;

  if (
    parsedData?.pgfnClearance?.toString()?.toLowerCase() === 'true' ||
    !parsedData?.baseStatus ||
    parsedData?.baseStatus === 'UNDEFINED' ||
    !parsedData?.status ||
    parsedData?.status === 'UNDEFINED'
  ) {
    showStatusInfo = parsedData?.remark;
  }

  const handlerPendencies = useMemo(() => {
    if (!parsedData || queryWorked) return { title: null, type: null };

    if (type === 'pessoa') {
      if (
        parsedData?.baseStatus ===
        translate(
          `${I18N_BASE_PATH}.handlerPendencies.type.person.baseStatus.NEGATIVA.text`
        )
      ) {
        return {
          title: translate(
            `${I18N_BASE_PATH}.handlerPendencies.type.person.baseStatus.NEGATIVA.title`
          ),
          type: 'primary',
        };
      }

      if (
        parsedData?.baseStatus ===
        translate(
          `${I18N_BASE_PATH}.handlerPendencies.type.person.baseStatus.POSITIVA.text`
        )
      ) {
        return {
          title: translate(
            `${I18N_BASE_PATH}.handlerPendencies.type.person.baseStatus.POSITIVA.title`
          ),
          type: 'danger',
        };
      }

      if (
        parsedData?.baseStatus ===
        translate(
          `${I18N_BASE_PATH}.handlerPendencies.type.person.baseStatus.POSITIVA_COM_EFEITOS_DE_NEGATIVA.text`
        )
      ) {
        return {
          title: translate(
            `${I18N_BASE_PATH}.handlerPendencies.type.person.baseStatus.POSITIVA_COM_EFEITOS_DE_NEGATIVA.title`
          ),
          type: 'warning',
        };
      }
    }

    if (type === 'empresa') {
      if (
        parsedData?.status ===
        translate(`${I18N_BASE_PATH}.handlerPendencies.type.company.status.NEGATIVA.text`)
      ) {
        return {
          title: translate(
            `${I18N_BASE_PATH}.handlerPendencies.type.company.status.NEGATIVA.title`
          ),
          type: 'primary',
        };
      }

      if (
        parsedData?.status ===
        translate(`${I18N_BASE_PATH}.handlerPendencies.type.company.status.POSITIVA.text`)
      ) {
        return {
          title: translate(
            `${I18N_BASE_PATH}.handlerPendencies.type.company.status.POSITIVA.title`
          ),
          type: 'danger',
        };
      }

      if (
        parsedData?.status ===
        translate(
          `${I18N_BASE_PATH}.handlerPendencies.type.company.status.POSITIVA_COM_EFEITOS_DE_NEGATIVA.text`
        )
      ) {
        return {
          title: translate(
            `${I18N_BASE_PATH}.handlerPendencies.type.company.status.POSITIVA_COM_EFEITOS_DE_NEGATIVA.title`
          ),
          type: 'warning',
        };
      }
    }

    if (parsedData?.pgfnClearance?.toString()?.toLowerCase() === 'true') {
      return {
        title: translate(`${I18N_BASE_PATH}.handlerPendencies.negative.title`),
        type: 'primary',
      };
    }

    if (parsedData?.pgfnClearance?.toString()?.toLowerCase() === 'false') {
      return {
        title: translate(`${I18N_BASE_PATH}.handlerPendencies.positive.title`),
        type: 'danger',
      };
    }

    return {
      title: translate(`${I18N_BASE_PATH}.handlerPendencies.default.title`),
      type: 'empty',
    };
  }, [parsedData, queryWorked, type, translate]);

  return (
    <DataSourceCardCollapsePanel
      title={translate(`${I18N_BASE_PATH}.pgfn.columnLabel.title`)}
      icon="caf-ic_dollar_sign"
      toPrint={toPrint}
      customId="report-spc-last-phone-card"
    >
      <ColumnLabel
        description={handlerPendencies?.title}
        type={handlerPendencies?.type}
        info={!toPrint && translate(`${I18N_BASE_PATH}.pgfn.columnLabel.info`)}
        statusInfo={showStatusInfo}
      >
        {queryWorked ? (
          <>
            {(parsedData?.baseStatus === 'POSITIVA COM EFEITOS DE NEGATIVA' ||
              parsedData?.status === 'POSITIVA COM EFEITOS DE NEGATIVA') && (
              <span>{translate(`${I18N_BASE_PATH}.pgfn.columnLabel.description`)}</span>
            )}

            {parsedData?.validityDate &&
              i18nFormatDate(parsedData?.validityDate, 1) !== '-' && (
                <span>
                  {translate(`${I18N_BASE_PATH}.pgfn.columnLabel.dueDate`)}{' '}
                  {i18nFormatDate(parsedData?.validityDate, 1)}
                </span>
              )}

            {parsedData &&
              (isEmpty(parsedData) ||
                parsedData?.baseStatus === 'NEGATIVA' ||
                parsedData?.status === 'NEGATIVA') && (
                <span>
                  {translate(`${I18N_BASE_PATH}.pgfn.columnLabel.emptyDescription`, {
                    type,
                  })}
                </span>
              )}
          </>
        ) : (
          <EmptyExecutionState
            statusCode={statusCode}
            message={source?.message || parsedData?.message}
          />
        )}
      </ColumnLabel>
    </DataSourceCardCollapsePanel>
  );
};

export default PGFN;
