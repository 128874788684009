import React, { useState, useCallback, useMemo } from 'react';
import { Modal, Button, Typography, Form } from 'antd';
import GearIcon from 'assets/images/gear.svg';
import { useFetch } from 'services/hooks';
import { useAuth } from 'hooks/auth';
import { useTranslation } from 'react-i18next';
import { useSecurityOptions } from 'hooks/useSecurityOptions';
import MfaInput from './MfaInput';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.securityAndPrivacy.securityConfiguration.mfaModal';
const { Text } = Typography;

const MfaActivation = () => {
  const { get, loading } = useFetch();
  const { user } = useAuth();
  const { t: translate } = useTranslation();

  const {
    doPatchMultifactorAuthentication,
    setMultifactorAuthentication,
    multifactorAuthentication,
    setMfaUpdatedAt,
    setMfaUserLastModification,
  } = useSecurityOptions();

  const email = user?.username;

  const ommitedEmail = useMemo(() => {
    const replace = email.indexOf('@') - 1;
    const str = email.substring(replace / 2);
    return str.padStart(email.length, '*');
  }, [email]);

  const [modalVisible, setModalVisible] = useState(false);
  const [done, setDone] = useState(false);
  const [tokenStatus, setTokenStatus] = useState('valid');
  const [verifyError, setVerifyError] = useState(false);
  const [token, setToken] = useState(false);

  const setFilledHandler = useCallback((code) => setToken(code), []);
  const setNotFilledHandler = useCallback(() => setToken(false), []);

  const resetData = useCallback(async () => {
    setVerifyError(false);
    setToken(false);
    setTokenStatus('valid');
    setDone(false);
  }, []);

  const getToken = useCallback(async () => {
    get({
      url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/send-otp`,
    });
    resetData();
  }, [get, resetData]);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    getToken();
    setModalVisible(true);
  }, [getToken]);

  const updateMfa = useCallback(async () => {
    setMultifactorAuthentication(!multifactorAuthentication);
    setMfaUpdatedAt(new Date());
    setMfaUserLastModification(email);
    doPatchMultifactorAuthentication();
    closeModal();
  }, [
    doPatchMultifactorAuthentication,
    multifactorAuthentication,
    setMfaUpdatedAt,
    setMfaUserLastModification,
    email,
    setMultifactorAuthentication,
    closeModal,
  ]);

  const validateToken = useCallback(async () => {
    const response = await get({
      url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/verify-otp`,
      config: {
        params: {
          token,
        },
      },
    });

    if (response.verified) {
      setDone(true);
    } else {
      setVerifyError(true);
    }
    if (response.message === 'Token expirado') {
      setTokenStatus('expired');
    }
    if (response.message === 'Token invalidado') {
      setTokenStatus('invalid');
    }
  }, [get, token]);

  const mfaActivationModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal confirm-action"
        closable={false}
        onCancel={closeModal}
        footer={null}
        width={600}
        destroyOnClose
      >
        {!done && (
          <div className="no-mrg no-pdd">
            <Typography className="gx-modal-title">
              {translate(`${I18N_BASE_PATH}.activation.title`)}
            </Typography>
            {tokenStatus === 'valid' && (
              <>
                <div align="middle" justify="center" style={{ padding: '1rem' }}>
                  <Text>{translate(`${I18N_BASE_PATH}.activation.email`)}</Text>
                  <br />
                  <Text>{ommitedEmail}</Text>
                </div>
                <div align="middle" justify="center">
                  <Text strong>{translate(`${I18N_BASE_PATH}.activation.code`)}</Text>
                  <MfaInput
                    tokenStatus={tokenStatus}
                    onFill={setFilledHandler}
                    onNotFilled={setNotFilledHandler}
                  />
                  <div>
                    {verifyError && (
                      <>
                        <Text className="error-text">
                          {translate(`${I18N_BASE_PATH}.errors.invalidCode`)}
                        </Text>
                        <br />
                      </>
                    )}
                    <Text>
                      {translate(`${I18N_BASE_PATH}.activation.codeNotSent`)}
                      <Button onClick={getToken} type="link">
                        {translate(`${I18N_BASE_PATH}.button.resendCode`)}
                      </Button>
                    </Text>
                  </div>
                </div>
                <div align="end" style={{ padding: '1rem' }}>
                  <Button type="text" onClick={closeModal} className="btn-close">
                    {translate(`${I18N_BASE_PATH}.button.cancel`)}
                  </Button>
                  <Button
                    loading={loading}
                    type="primary"
                    disabled={!token}
                    onClick={validateToken}
                  >
                    {translate(`${I18N_BASE_PATH}.button.verify`)}
                  </Button>
                </div>
              </>
            )}
            {tokenStatus === 'invalid' && (
              <>
                <div style={{ padding: '3rem' }}>
                  <div align="middle" justify="center" style={{ padding: '1rem' }}>
                    <Text className="error-text" style={{ fontSize: '1.5rem' }}>
                      {translate(`${I18N_BASE_PATH}.errors.expiredCode`)}
                    </Text>
                  </div>
                  <div align="middle" justify="center" style={{ padding: '1rem' }}>
                    <Text>{translate(`${I18N_BASE_PATH}.exceededAttempts.text`)}</Text>
                  </div>
                </div>
                <div align="end" style={{ padding: '1rem' }}>
                  <Button type="text" onClick={closeModal} className="btn-close">
                    {translate(`${I18N_BASE_PATH}.button.cancel`)}
                  </Button>
                  <Button loading={loading} type="primary" onClick={getToken}>
                    {translate(`${I18N_BASE_PATH}.button.newCode`)}
                  </Button>
                </div>
              </>
            )}
            {tokenStatus === 'expired' && (
              <>
                <div align="middle" justify="center" style={{ padding: '3rem' }}>
                  <Text>{translate(`${I18N_BASE_PATH}.expiredToken.text`)}</Text>
                </div>
                <div align="end" style={{ padding: '1rem' }}>
                  <Button type="text" onClick={closeModal} className="btn-close">
                    {translate(`${I18N_BASE_PATH}.button.cancel`)}
                  </Button>
                  <Button loading={loading} type="primary" onClick={getToken}>
                    {translate(`${I18N_BASE_PATH}.button.newCode`)}
                  </Button>
                </div>
              </>
            )}
          </div>
        )}

        {done && (
          <div className="no-mrg no-pdd">
            <Typography className="gx-modal-title">
              {translate(
                multifactorAuthentication
                  ? `${I18N_BASE_PATH}.done.disableTitle`
                  : `${I18N_BASE_PATH}.done.enableTitle`
              )}
            </Typography>
            <div
              align="middle"
              justify="center"
              style={{ padding: '1rem', textAlign: 'justify' }}
            >
              {!multifactorAuthentication && (
                <>
                  <Text>{translate(`${I18N_BASE_PATH}.done.frequencyText`)}</Text>
                  <img src={GearIcon} alt="gear icon" />
                  <Text>{translate(`${I18N_BASE_PATH}.done.frequencyTextEnd`)}</Text>
                  <br />
                  <br />
                  <Text>{translate(`${I18N_BASE_PATH}.done.enableText`)}</Text>
                </>
              )}
              {multifactorAuthentication && (
                <>
                  <Text>{translate(`${I18N_BASE_PATH}.done.disableText`)}</Text>
                </>
              )}
            </div>

            <div align="end">
              <Button type="text" onClick={closeModal} className="btn-close">
                {translate(`${I18N_BASE_PATH}.button.cancel`)}
              </Button>
              <Button loading={loading} type="primary" onClick={updateMfa}>
                {translate(`${I18N_BASE_PATH}.button.confirm`)}
              </Button>
            </div>
          </div>
        )}
      </Modal>
    ),
    [
      setNotFilledHandler,
      setFilledHandler,
      validateToken,
      closeModal,
      translate,
      updateMfa,
      getToken,
      multifactorAuthentication,
      ommitedEmail,
      modalVisible,
      tokenStatus,
      verifyError,
      loading,
      token,
      done,
    ]
  );

  return { openModal, mfaActivationModal };
};

export default MfaActivation;
