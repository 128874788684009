/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Row, Divider, Col } from 'antd';

import PageTitle from 'components/PageTitle';
import TooltipInfo from 'components/TooltipInfo';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { motiveTranslator, familyLevelTranslator } from '../../../utils/translator';

import './styles.less';

const I18N_BASE_PATH =
  'components.dataSourceCards.restrictiveLists.components.sources.pepHistoryModal';

const PepHistory = ({ data, others, title, subtitle }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const showTooltipInfo = useMemo(() => {
    return data?.department && translate(`${I18N_BASE_PATH}.tooltipInfo`);
  }, [data, translate]);

  const PepHistoryModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        onCancel={closeModal}
        footer={null}
        style={{ top: 40 }}
        width={700}
      >
        <div className="no-mrg no-pdd">
          <Row
            className={`source-infos-modal-title reset-mrg ${!title && 'custom-title'} ${
              !subtitle && 'custom-subtitle'
            }`}
          >
            <PageTitle
              title={title || translate(`${I18N_BASE_PATH}.pageTitle.title`)}
              subtitle={subtitle}
              info={showTooltipInfo && <TooltipInfo title={showTooltipInfo} />}
            />
          </Row>
          <Row className="mrg-left-5">
            <Divider />
          </Row>
          <Row className="mrg-top-10 mrg-btm-10 reset-mrg">
            {others && (
              <span>
                <strong>{translate(`${I18N_BASE_PATH}.data.others.title`)} </strong>
                {data?.source || translate(`${I18N_BASE_PATH}.data.others.text`)}
              </span>
            )}
            <Col className="no-pdd" span={24}>
              <span>
                <strong>
                  {translate(`${I18N_BASE_PATH}.data.sourceDetailItemLevel.title`)}{' '}
                </strong>
                {data?.level}
              </span>
            </Col>
            {(data?.link || data?.source) && (
              <Col className="no-pdd" span={24}>
                <span>
                  <strong>
                    {translate(`${I18N_BASE_PATH}.data.sourceLink.title`)}:{' '}
                  </strong>
                </span>
                <a
                  className="link"
                  href={data?.link ? data?.link : data?.source}
                  disabled={!data?.link}
                >
                  {data?.link
                    ? translate(`${I18N_BASE_PATH}.data.sourceLink.text`)
                    : data?.source}{' '}
                  {data?.link && <i className="caf-ic_arrow-right" />}
                </a>
              </Col>
            )}

            <Col className="no-pdd" span={24}>
              <span>
                <strong>{translate(`${I18N_BASE_PATH}.data.motive`)}: </strong>
                {data?.jobTitle === 'FAMILIAR'
                  ? translate(familyLevelTranslator(data?.motive))
                  : translate(motiveTranslator(data?.motive))}
              </span>
            </Col>
            <Col className="no-pdd" span={24}>
              <span>
                <strong>{translate(`${I18N_BASE_PATH}.data.startDate`)}: </strong>
                {i18nFormatDate(data?.startDate, 1) || '-'}
              </span>
            </Col>
            <Col className="no-pdd" span={24}>
              <span>
                <strong>{translate(`${I18N_BASE_PATH}.data.endDate`)}: </strong>
                {i18nFormatDate(data?.endDate, 1) || '-'}
              </span>
            </Col>
          </Row>
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      title,
      subtitle,
      translate,
      showTooltipInfo,
      others,
      data,
      i18nFormatDate,
    ]
  );

  return { openModal, closeModal, PepHistoryModal };
};

export default PepHistory;
