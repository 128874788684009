import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, message, Row, Col, Checkbox } from 'antd';

import PageTitle from 'components/PageTitle';

import { useFetch } from 'services/hooks';

import './styles.less';

const ConfirmAction = ({ refreshListRef, type, refreshStatus }) => {
  const { t: translate } = useTranslation();

  const { delete: _delete, patch, loading } = useFetch();

  const [modalVisible, setModalVisible] = useState(false);
  const [switchState, setSwitchState] = useState(false);
  const [hasToDisableOnboardings, setHasToDisableOnboardings] = useState(false);
  const [id, setId] = useState();

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback((switchValue, idProfile) => {
    setModalVisible(true);
    setId(idProfile);
    setSwitchState(switchValue);
    setHasToDisableOnboardings(false);
  }, []);

  const submitMessageSuccess = useMemo(() => {
    if (type === 'profile-template') {
      if (switchState) {
        return translate(
          'pages.private.profileModels.profileModelList.components.confirmAction.handleSwitchChange.messages.success.profileTemplate.active'
        );
      }

      return translate(
        'pages.private.profileModels.profileModelList.components.confirmAction.handleSwitchChange.messages.success.profileTemplate.inactive'
      );
    }

    if (type === 'onboarding-link') {
      if (switchState) {
        return translate(
          'pages.private.profileModels.profileModelList.components.confirmAction.handleSwitchChange.messages.success.onboardingLink.active'
        );
      }

      return translate(
        'pages.private.profileModels.profileModelList.components.confirmAction.handleSwitchChange.messages.success.onboardingLink.inactive'
      );
    }

    return '';
  }, [type, switchState, translate]);

  const submitMessageError = useMemo(() => {
    if (type === 'profile-template') {
      if (switchState) {
        return translate(
          'pages.private.profileModels.profileModelList.components.confirmAction.handleSwitchChange.messages.error.profileTemplate.activate'
        );
      }

      return translate(
        'pages.private.profileModels.profileModelList.components.confirmAction.handleSwitchChange.messages.error.profileTemplate.inactivate'
      );
    }

    if (type === 'onboarding-link') {
      if (switchState) {
        return translate(
          'pages.private.profileModels.profileModelList.components.confirmAction.handleSwitchChange.messages.error.onboardingLink.activate'
        );
      }

      return translate(
        'pages.private.profileModels.profileModelList.components.confirmAction.handleSwitchChange.messages.error.onboardingLink.inactivate'
      );
    }

    return '';
  }, [type, switchState, translate]);

  const customTitle = useMemo(() => {
    if (type === 'profile-template') {
      if (switchState) {
        return translate(
          'pages.private.profileModels.profileModelList.components.confirmAction.confirmAction.pageTitle.title.profileTemplate.activate'
        );
      }

      return translate(
        'pages.private.profileModels.profileModelList.components.confirmAction.confirmAction.pageTitle.title.profileTemplate.inactivate'
      );
    }

    if (type === 'onboarding-link') {
      if (switchState) {
        return translate(
          'pages.private.profileModels.profileModelList.components.confirmAction.confirmAction.pageTitle.title.onboardingLink.activate'
        );
      }

      return translate(
        'pages.private.profileModels.profileModelList.components.confirmAction.confirmAction.pageTitle.title.onboardingLink.inactivate'
      );
    }

    return '';
  }, [translate, type, switchState]);

  const customSubtitle = useMemo(() => {
    if (type === 'profile-template') {
      if (switchState) {
        return translate(
          'pages.private.profileModels.profileModelList.components.confirmAction.confirmAction.pageTitle.subtitle.profileTemplate.activate'
        );
      }

      return translate(
        'pages.private.profileModels.profileModelList.components.confirmAction.confirmAction.pageTitle.subtitle.profileTemplate.inactivate'
      );
    }

    if (type === 'onboarding-link') {
      if (switchState) {
        return translate(
          'pages.private.profileModels.profileModelList.components.confirmAction.confirmAction.pageTitle.subtitle.onboardingLink.activate'
        );
      }

      return translate(
        'pages.private.profileModels.profileModelList.components.confirmAction.confirmAction.pageTitle.subtitle.onboardingLink.inactivate'
      );
    }

    return '';
  }, [translate, type, switchState]);

  const handleSwitchChange = useCallback(async () => {
    if (type === 'onboarding-link') {
      try {
        await patch({
          url: `/onboardings/${id}`,
          payload: {
            active: switchState,
          },
        });

        if (refreshListRef) refreshListRef();

        closeModal();

        message.success(submitMessageSuccess);
      } catch (err) {
        message.error(submitMessageError);
      }

      return;
    }

    try {
      await _delete({
        url: `/profile-templates/${id}`,
        config: {
          params: {
            action: switchState ? 'activate' : 'inactivate',
            disableOnboardings: hasToDisableOnboardings,
          },
        },
      });

      if (refreshListRef) refreshListRef?.current();
      else refreshStatus();

      closeModal();

      message.success(submitMessageSuccess);
    } catch (err) {
      message.error(submitMessageError);
    }
  }, [
    type,
    patch,
    id,
    switchState,
    refreshListRef,
    closeModal,
    submitMessageSuccess,
    submitMessageError,
    _delete,
    hasToDisableOnboardings,
    refreshStatus,
  ]);

  const ConfirmActionModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal activate-confirm"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeModal}
        footer={null}
        width={550}
        destroyOnClose
      >
        <div className="no-mrg no-pdd">
          <Row>
            <Col span={24}>
              <div className="activate-confirm-title">
                <PageTitle title={customTitle} />
              </div>
            </Col>
            <Col span={24}>
              <span className="activate-confirm-description">{customSubtitle}</span>
            </Col>
          </Row>

          {!switchState && type === 'profile-template' && (
            <Row className="disabled-onboarding-links">
              <Col span={24}>
                <Checkbox
                  checked={hasToDisableOnboardings}
                  onChange={() => setHasToDisableOnboardings(!hasToDisableOnboardings)}
                >
                  {translate(
                    'pages.private.profileModels.profileModelList.components.confirmAction.confirmAction.inactiveOnboardingLinks.checkbox' // => Também inativar links de onboarding gerados com esse modelo de consulta
                  )}
                </Checkbox>
              </Col>
            </Row>
          )}

          <Row>
            <Col span={24}>
              <div className="activate-confirm-btns">
                <Button
                  type="default"
                  onClick={closeModal}
                  className="btn-cancel"
                  disabled={loading}
                >
                  {translate(
                    'pages.private.profileModels.profileModelList.components.confirmAction.confirmAction.button.cancel'
                  )}
                  {/* => Cancelar */}
                </Button>

                <Button
                  type={switchState ? 'primary' : 'danger'}
                  htmlType="submit"
                  onClick={handleSwitchChange}
                  loading={loading}
                >
                  {switchState ? (
                    <>
                      {translate(
                        `${
                          loading
                            ? 'pages.private.profileModels.profileModelList.components.confirmAction.confirmAction.button.confirmAction.actived.loading'
                            : 'pages.private.profileModels.profileModelList.components.confirmAction.confirmAction.button.confirmAction.actived.default'
                          // => Ativando : Ativar
                        }`
                      )}
                    </>
                  ) : (
                    <>
                      {translate(
                        `${
                          loading
                            ? 'pages.private.profileModels.profileModelList.components.confirmAction.confirmAction.button.confirmAction.inactivated.loading'
                            : 'pages.private.profileModels.profileModelList.components.confirmAction.confirmAction.button.confirmAction.inactivated.default'
                          // => Inativando : Inativar
                        }`
                      )}
                    </>
                  )}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      customTitle,
      customSubtitle,
      hasToDisableOnboardings,
      translate,
      loading,
      switchState,
      handleSwitchChange,
      type,
    ]
  );

  return { openModal, closeModal, ConfirmActionModal };
};

export default ConfirmAction;
