export const permissions = [
  {
    key: 'transactions',
    title: 'utils.tokensPermissions.transactions.full.title', // Transações
    description: 'utils.tokensPermissions.transactions.full.description', // Full control of transctions
    permission: 'transactions:*',
    allowedPermissions: [
      {
        key: 'read',
        title: 'utils.tokensPermissions.transactions.read.title', // Read
        permission: 'transactions:read',
        description: 'utils.tokensPermissions.transactions.read.description', // Access to read transactions
      },
      {
        key: 'create',
        title: 'utils.tokensPermissions.transactions.create.title', // Create
        permission: 'transactions:create',
        description: 'utils.tokensPermissions.transactions.create.description', // Allows access to create transactions
      },
    ],
  },
  {
    key: 'transactions-records',
    title: 'utils.tokensPermissions.transactionsRecords.full.title', // Transactions records
    description: 'utils.tokensPermissions.transactionsRecords.full.description', // Full control of transactions records
    permission: 'transactions-records:*',
    allowedPermissions: [
      {
        key: 'read',
        title: 'utils.tokensPermissions.transactionsRecords.read.title', // Read
        permission: 'transactions-records:read',
        description: 'utils.tokensPermissions.transactionsRecords.read.description', // Access to read transactions records
      },
      // {
      //   key: 'create',
      //   title: 'utils.tokensPermissions.transactionsRecords.create.title', // Create
      //   permission: 'transactions-records:create',
      //   description: 'utils.tokensPermissions.transactionsRecords.create.description', // Allows access to create transactions records
      // },
      {
        key: 'delete',
        title: 'utils.tokensPermissions.transactionsRecords.delete.title', // Delete
        permission: 'transactions-records:delete',
        description: 'utils.tokensPermissions.transactionsRecords.delete.description', // Allows access to delete transactions records
      },
    ],
  },
  {
    key: 'services',
    title: 'utils.tokensPermissions.services.full.title', // Services
    description: 'utils.tokensPermissions.services.full.description', // Full control of services
    permission: 'services:*',
    allowedPermissions: [
      {
        key: 'query',
        title: 'utils.tokensPermissions.services.query.title', // Query
        permission: 'services:query',
        description: 'utils.tokensPermissions.services.query.description', // Access to query services
      },
    ],
  },
  {
    key: 'manual-reviews',
    title: 'utils.tokensPermissions.manualReviews.full.title', // Manual reviews
    description: 'utils.tokensPermissions.manualReviews.full.description', // Full control of manual reviews
    permission: 'manual-reviews:*',
    allowedPermissions: [
      {
        key: 'update',
        title: 'utils.tokensPermissions.manualReviews.update.title', // Update
        permission: 'manual-reviews:update',
        description: 'utils.tokensPermissions.manualReviews.update.description', // Access to update manual reviews
      },
    ],
  },
  {
    key: 'onboardings',
    title: 'utils.tokensPermissions.onboardings.full.title', // onboardings
    description: 'utils.tokensPermissions.onboardings.full.description', // Full control of onboardings
    permission: 'onboardings:*',
    allowedPermissions: [
      {
        key: 'create',
        title: 'utils.tokensPermissions.onboardings.create.title', // Create
        permission: 'onboardings:create',
        description: 'utils.tokensPermissions.onboardings.create.description', // Access to create onboardings
      },
    ],
  },
];
