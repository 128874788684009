import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Row, Col, Divider, Space, Typography } from 'antd';

import { useTheme } from 'hooks/theme';

import './styles.less';

const { Content } = Layout;
const { Title, Text } = Typography;

const Wrapper = ({ children }) => {
  const { t: translate } = useTranslation();

  const { logo: logoTheme } = useTheme();

  return (
    <Layout
      id="public-wrapper-component"
      className="gx-app-layout auth-wrapper"
      style={{ height: '100vh' }}
    >
      <Content className="gx-layout-content gx-container-wrap">
        <Row className="no-mrg-horizon height-100">
          <Col xs={24} sm={24} md={14} lg={10}>
            <Row align="middle" justify="center" className="mrg-top-75">
              <Row className="auth-form">
                <Col span={24}>
                  <img
                    src={logoTheme}
                    alt={translate(
                      'pages.public.wrapper.index.layout.content.logoImg.alt'
                    )}
                  />
                  <Divider className="mrg-vertical-30" />
                </Col>
                <Col span={24}>
                  <Space direction="vertical" size="large" className="auth-form-content">
                    {children}
                  </Space>
                </Col>
              </Row>
            </Row>
          </Col>

          <Col className="right-panel" xs={0} sm={0} md={10} lg={14}>
            <Row align="middle" justify="end">
              <Col span={12} pull={3} className="mrg-top-75 text-right">
                <Title className="auth-right-title">
                  {translate('pages.public.wrapper.index.layout.content.title')}
                </Title>
                <Text strong className="auth-right-subtitle">
                  {translate('pages.public.wrapper.index.layout.content.text')}
                </Text>
              </Col>
            </Row>

            {/* <Row align="middle" justify="end" className="no-pdd">
              <Col span={12} pull={4} className="no-mrg mrg-top-25 text-right">
                <Button outlined>
                  <Link to="/signup">Solicitar meu acesso</Link>
                </Button>
              </Col>
            </Row> */}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Wrapper;
