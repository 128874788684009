import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';
import ViewMoreText from 'components/ViewMoreText';
import EmptyExecutionState from 'components/EmptyExecutionState';
import { parseLaborDebtsNadaConsta, parseLaborDebtsContent } from './utils';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.laborInformation.shared.components.pjLaborDebts';

function LaborDebts({ source, toPrint }) {
  const { t: translate } = useTranslation();

  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const laborDebtsNadaConsta = useMemo(() => parseLaborDebtsNadaConsta(source), [source]);

  const parsedContent = useMemo(() => parseLaborDebtsContent(source), [source]);

  return (
    <DataSourceCardCollapsePanel
      title={translate(`${I18N_BASE_PATH}.index.title`)}
      customId="labor-infos-component"
      icon="caf-ic_awards"
      toPrint={toPrint}
    >
      <ColumnLabel
        title={translate(`${I18N_BASE_PATH}.index.title`)}
        description={translate(laborDebtsNadaConsta?.translatedStatus?.description)}
        type={laborDebtsNadaConsta?.translatedStatus?.type}
        span={12}
        toPrint={toPrint}
      >
        {queryWorked ? (
          <ViewMoreText message={source?.source?.message} content={parsedContent} />
        ) : (
          <EmptyExecutionState
            className="mrg-top-10"
            statusCode={source?.statusCode}
            message={source?.message || source?.source?.message}
          />
        )}
      </ColumnLabel>
    </DataSourceCardCollapsePanel>
  );
}

LaborDebts.propTypes = {
  source: PropTypes.objectOf({}),
  toPrint: PropTypes.bool,
};

LaborDebts.defaultProps = {
  source: {},
  toPrint: false,
};

export default LaborDebts;
