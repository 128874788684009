/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useExecution } from 'hooks/execution';
import { usePerson } from 'hooks/person';
import { useCompany } from 'hooks/company';
import * as executionDataSources from 'utils/executionDataSource';
import WorkHistory from './components/WorkHistory';
import CivilServant from './components/CivilServant';
import ClassOrganizations from './components/ClassOrganizations';
import RAIS from './components/RAIS';
import LaborDebts from '../Shared/LaborDebts';
import PfBusinessRelationships from './components/pfBusinessRelationships';

import DataSourceCardCollapsePanel from '../../DataSourceCardCollapsePanel';

const PersonalLaborInformation = ({ toPrint = false }) => {
  const { t: translate } = useTranslation();

  const { execution } = useExecution();
  const { company } = useCompany();
  const { person } = usePerson();

  const findSourceSection = useCallback(
    (section) =>
      executionDataSources.findSourceSection(execution, section) ||
      executionDataSources.findSourceSection(company, section) ||
      executionDataSources.findSourceSection(person, section),
    [company, execution, person]
  );

  const showWorkHistory = useMemo(() => findSourceSection('pfOccupationData'), [
    findSourceSection,
  ]);
  const showCivilServant = useMemo(() => findSourceSection('pfProfessionData'), [
    findSourceSection,
  ]);
  const showClassOrganizations = useMemo(() => findSourceSection('pfClassOrganization'), [
    findSourceSection,
  ]);
  const showRAIS = useMemo(() => findSourceSection('pfRais'), [findSourceSection]);

  const laborDebts = useMemo(() => findSourceSection('pfLaborDebts'), [
    findSourceSection,
  ]);

  const showBusinessRelationships = useMemo(
    () => findSourceSection('pfBusinessRelationships'),
    [findSourceSection]
  );
  return (
    <DataSourceCardCollapsePanel
      customId="labor-infos-component"
      title={translate(
        'components.dataSourceCards.laborInformation.people.index.people.dossierCard.title'
      )}
      icon="caf-ic_awards"
      toPrint={toPrint}
    >
      {showWorkHistory && <WorkHistory source={showWorkHistory} toPrint={toPrint} />}
      {showCivilServant && <CivilServant source={showCivilServant} toPrint={toPrint} />}
      {showClassOrganizations && (
        <ClassOrganizations source={showClassOrganizations} toPrint={toPrint} />
      )}
      {showRAIS && <RAIS source={showRAIS} toPrint={toPrint} />}
      {showBusinessRelationships && (
        <PfBusinessRelationships source={showBusinessRelationships} toPrint={toPrint} />
      )}

      {/* Não remover o data */}
      {laborDebts && <LaborDebts source={laborDebts} toPrint={toPrint} />}
    </DataSourceCardCollapsePanel>
  );
};

export default PersonalLaborInformation;
