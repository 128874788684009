/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Row, Typography } from 'antd';

import EmptyExecutionState from 'components/EmptyExecutionState';
import DottedLabel from 'components/DottedLabel';
import DossierCard from 'pages/private/Profiles/components/DossierCard';
import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';

import { mapSuspiciousPlacesStatus } from './utils';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

import './styles.less';

const { Text } = Typography;

const I18N_BASE_PATH = 'components.dataSourceCards.denyListPrivate.index';

// NÃO TRADUZIR O TYPE PELO AMOR DE DEUS
const DenyListPrivate = ({ source = {}, type = 'pf', toPrint = false }) => {
  const { t: translate } = useTranslation();

  const isOnDenyList = useMemo(() => source?.data?.recordFound, [source]);

  if (!source?.data) {
    return (
      <EmptyExecutionState
        statusCode={source?.statusCode}
        message={source?.message || source?.data?.message}
      />
    );
  }

  return (
    <DataSourceCardCollapsePanel
      title={translate(`${I18N_BASE_PATH}.title.${type}`, `Deny List`)}
      icon="caf-ic_lawier"
      toPrint={toPrint}
      customId="restrictiveLists"
    >
      <div id="deny-list-content">
        <Text>
          {translate(
            `${I18N_BASE_PATH}.description`,
            `Check if the informed document is in the DenyList`
          )}
        </Text>
        {!source?.data ? (
          <EmptyExecutionState
            className="mrg-top-20"
            statusCode={source?.statusCode}
            message={source?.message || source?.data?.message}
          />
        ) : (
          <>
            <Row className="mrg-top-40">
              <Col span={24}>
                <DottedLabel
                  className="text-bold"
                  type={mapSuspiciousPlacesStatus(isOnDenyList).type}
                  description={translate(
                    `${I18N_BASE_PATH}.status.${
                      mapSuspiciousPlacesStatus(isOnDenyList)?.status
                    }`
                  )}
                />
              </Col>
            </Row>
            {isOnDenyList && (
              <>
                <Divider className="mrg-top-5 mrg-btn-5" />
                <Row className="mrg-top-10">
                  <ColumnTitleValue
                    className="single-line"
                    title={translate(`${I18N_BASE_PATH}.columns.name`)}
                    value={source?.data?.name || '-'}
                    span={24}
                  />
                  {type === 'pf' && (
                    <ColumnTitleValue
                      className="single-line"
                      title={translate(`${I18N_BASE_PATH}.columns.cpf`)}
                      value={source?.data?.cpf || '-'}
                      span={24}
                    />
                  )}
                  {type === 'pj' && (
                    <ColumnTitleValue
                      className="single-line"
                      title={translate(`${I18N_BASE_PATH}.columns.cnpj`)}
                      value={source?.data?.cnpj || '-'}
                      span={24}
                    />
                  )}
                </Row>
              </>
            )}
          </>
        )}
      </div>
    </DataSourceCardCollapsePanel>
  );
};

export default DenyListPrivate;
