/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select } from 'antd';

import { useFetch } from 'services/hooks';

import { useReports } from 'hooks/reports';
import CustomCollapse from '../CustomCollapse';
import PolicyRow from '../PolicyRow';

const SKY_TENANTS_IDS = [
  'c94b990c-1eab-40ea-bbdf-684635bc5af2', // sky
  '2ba38e96-5eb2-4e23-ac60-8bfbcbe41ae6', // sky beta
  '7991fee7-cb5a-45d0-9e1c-f6c2b9007823', // sky beta
  'c25c21d5-7ac8-4490-a147-c09f68eba950', // PS dev
  '8441880f-7371-429a-9450-1d7640de4ed6', // PS beta
];

const { Option, OptGroup } = Select;
const ProfileTemplatesPanel = ({
  data,
  disabled,
  formRef,
  tenantId,
  hideProfileRelatedRows,
  policiesReturn,
}) => {
  const {
    get: getProfileTemplates,
    data: profileTemplates,
    loading: loadingProfileTemplates,
  } = useFetch();
  const { allReportsList = [], loadingAllReports, loadAllReports } = useReports();
  const firstLoad = useRef(true);
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (!firstLoad.current || data?.name !== 'profile-templates') return;
    firstLoad.current = false;
    loadAllReports();
    getProfileTemplates({
      url: `/profile-templates`,
    });
  }, [data, getProfileTemplates, loadAllReports]);

  const policiesData = useMemo(() => {
    if (SKY_TENANTS_IDS.includes(tenantId)) {
      return data?.policies?.map((policy) => policy);
    }
    return data?.policies?.filter((policy) => policy?.name !== 'blocked-documents');
  }, [data, tenantId]);

  const ProfileTemplatesResources = useMemo(() => {
    const getOptions = (type) => (
      <OptGroup
        label={translate(
          `pages.private.profileModels.profileModelList.components.links.components.createLinkModal.components.createLinkType.index.createLinkType.form.profileTemplate.optGroup.${
            type === 'PF' ? 'person' : 'company'
          }`
        )}
      >
        {profileTemplates?.docs?.map(
          (action) =>
            action.type === type && (
              <Option value={`profile-templates:${action._id}`}>{action.name}</Option>
            )
        )}
      </OptGroup>
    );
    const getReports = () => (
      <OptGroup label="Outros Relatórios">
        {allReportsList?.map((report) => {
          const { _id, name } = report || {};
          return (
            <Option key={_id} value={`executions:${_id}`}>
              {name}
            </Option>
          );
        })}
      </OptGroup>
    );

    return (
      <Form.Item name={['profile-templates', 'resources']} className="no-mrg no-pdd">
        <Select
          disabled={disabled || loadingProfileTemplates}
          mode="multiple"
          optionFilterProp="children"
          loading={loadingProfileTemplates}
          allowClear
          maxTagCount={3}
        >
          {getOptions('PF')}
          {getOptions('PJ')}
          {getReports()}
        </Select>
      </Form.Item>
    );
  }, [disabled, loadingProfileTemplates, profileTemplates, allReportsList]);

  return (
    <CustomCollapse
      ruleName={data?.name}
      disabled={disabled}
      className="no-border-top"
      title={translate(data?.title)}
      icon={data?.icon}
    >
      {policiesData?.map(
        (policy) =>
          (!policy?.hideProfileRelatedRows || !hideProfileRelatedRows) && (
            <>
              <PolicyRow
                policiesReturn={policiesReturn}
                formRef={formRef}
                disabled={disabled}
                title={translate(policy?.title)}
                ruleName={policy?.name}
                availability={policy?.perms}
                ResourcesComponent={
                  policy?.name === 'profile-templates'
                    ? ProfileTemplatesResources
                    : undefined
                }
              />
            </>
          )
      )}
    </CustomCollapse>
  );
};

export default ProfileTemplatesPanel;
