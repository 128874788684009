import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Typography } from 'antd';
import classnames from 'classnames';
import NewCircleIcon from 'components/Icon/NewCircleIcon';
import CircleIcon from 'components/Icon/CircleIcon';
import './styles.less';

const { Title } = Typography;

const Icon = ({ img, icon, newIcon }) => {
  if (img) {
    return <img src={img} alt="Icon" className="dossier-icon mrg-right-10" />;
  }

  if (newIcon) {
    return (
      <NewCircleIcon backgroundBlue icon={newIcon} extraClass={`${icon} no-mrg-left`} />
    );
  }

  return <CircleIcon icon={icon} />;
};

const DossierCard = ({
  title,
  subtitle,
  icon,
  img,
  footer,
  customId,
  children,
  noCol,
  newIcon,
  ...rest
}) => {
  return (
    <Card
      className={classnames('executions-dossier-card-container', rest.className)}
      id={customId}
    >
      <Row className="reset-mrg">
        <Col flex="0 1" className="flex center-start">
          <Icon img={img} icon={img || icon} newIcon={newIcon} />
        </Col>

        <Col flex="0.95 1" className="dossier-card-content">
          <Title level={4} className="dossier-card-title">
            {title}
          </Title>

          {subtitle && (
            <Typography className="dossier-card-subtitle">{subtitle}</Typography>
          )}

          {!noCol && (
            <>
              {children}
              {footer}
            </>
          )}
        </Col>
      </Row>
      {noCol && (
        <>
          {children}
          {footer}
        </>
      )}
    </Card>
  );
};

DossierCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  footer: PropTypes.element,
  customId: PropTypes.string,
  noCol: PropTypes.bool,
  newIcon: PropTypes.element,
};

DossierCard.defaultProps = {
  footer: <></>,
  customId: '',
  noCol: false,
  newIcon: <></>,
};

export default DossierCard;
