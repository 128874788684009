/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prefer-spread */
import React, { useMemo, useState } from 'react';
import chroma from 'chroma-js';
import PropTypes from 'prop-types';
import { Tooltip as ANTDTooltip } from 'antd';
import {
  BarChart as REBarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import ChartTooltip from 'components/ChartTooltip';

import './styles.less';

const BarChart = (props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const { data, titleText } = props;

  const baseColors = ['#004af7', '#34d690', '#4490fb', '#39c560'];
  const labelPages = useMemo(() => {
    const pages = [];
    let currentPage = [];

    data.forEach((item) => {
      if (currentPage.length < 5) {
        currentPage.push(item);
      } else {
        pages.push(currentPage);
        currentPage = [item];
      }
    });

    if (currentPage.length > 0) {
      pages.push(currentPage);
    }

    return pages;
  }, [data]);

  const colors = chroma.scale(baseColors).colors(data.length);

  const renderCustomTooltip = (tooltipProps) => {
    const { active, payload } = tooltipProps;

    if (!active || !payload[0]?.payload) return null;

    const { value, type } = payload[0].payload;

    return <ChartTooltip subtitle={type} title={`${value} ${titleText}`} />;
  };

  return (
    <div className="chart-container">
      <ResponsiveContainer width="65%">
        <REBarChart
          width="100%"
          height={40}
          data={data}
          style={{
            fontSize: '0.7rem',
            marginLeft: -20,
          }}
        >
          <XAxis tick={false} padding={{ left: 15, right: 15 }} />
          <YAxis
            style={{
              fontSize: '0.6rem',
            }}
            tickLine={false}
            tickCount={10}
          />
          <Bar dataKey="value" maxBarSize={12}>
            {data.map((entry, index) => (
              <Cell key={`cell-${entry.type}`} fill={colors[index]} />
            ))}
          </Bar>
          <Tooltip cursor={false} content={renderCustomTooltip} />
        </REBarChart>
      </ResponsiveContainer>
      <div className="label-card">
        <div className="label-list-container">
          {labelPages.length > 0 &&
            labelPages[currentPage]?.map(({ type, value }, index) => (
              <div key={type} className="label-container">
                <span
                  style={{
                    minWidth: 7,
                    width: 7,
                    height: 7,
                    backgroundColor: colors[index + currentPage * 5],
                  }}
                />
                <ANTDTooltip
                  title={
                    <div style={{ textAlign: 'left' }}>
                      {value} {titleText}
                      <br />
                      {type}
                    </div>
                  }
                >
                  <p className="label">{type}</p>
                </ANTDTooltip>
              </div>
            ))}
        </div>
        {labelPages.length > 1 && (
          <div className="pagination">
            {labelPages.map((labels, index) => (
              <div
                key={`pagination-${labels.length}-${Math.random()}`}
                className={`pagination-item ${index === currentPage ? 'active' : ''}`}
                onClick={() => setCurrentPage(index)}
                aria-label={`Page ${index + 1}`}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

BarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      type: PropTypes.string,
    })
  ).isRequired,
  titleText: PropTypes.string.isRequired,
};

export default BarChart;
