const handlerPendencies = (quantity, translate) => {
  if (!quantity)
    return {
      title: null,
      quantity: null,
    };

  if (quantity === 1) {
    return {
      title: translate(
        'pages.private.profiles.components.dataSourceCards.financialInformation.companies.components.cadinSP.cadinSPItems.utils.handlerPendencies.handlerPendencies.hasOnePendency.title'
      ),
      quantity: translate(
        'pages.private.profiles.components.dataSourceCards.financialInformation.companies.components.cadinSP.cadinSPItems.utils.handlerPendencies.handlerPendencies.hasOnePendency.quantity'
      ),
    };
  }

  return {
    title: translate(
      'pages.private.profiles.components.dataSourceCards.financialInformation.companies.components.cadinSP.cadinSPItems.utils.handlerPendencies.handlerPendencies.hasMoreThanOnePendency.title',
      { quantity }
    ),
    quantity: translate(
      'pages.private.profiles.components.dataSourceCards.financialInformation.companies.components.cadinSP.cadinSPItems.utils.handlerPendencies.handlerPendencies.hasMoreThanOnePendency.quantity',
      { quantity }
    ),
  };
};

export { handlerPendencies };
