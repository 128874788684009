import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spin } from 'antd';
import PageTitle from 'components/PageTitle';

import PermissionWrapper from 'routes/PermissionWrapper';

import useDynamoComponentList from 'components/List/hooks/useDynamoComponentList';

import useGenerateToken from './components/GenerateToken';
import AccessKeysCard from './components/TokenCard';

import './styles.less';

const Tokens = () => {
  const refreshListRef = useRef(() => {});
  const { t: translate } = useTranslation();

  const { openModal, GenerateTokenModal } = useGenerateToken({ refreshListRef });

  const { componentList, refreshList, loading } = useDynamoComponentList({
    component: AccessKeysCard,
    rowKey: 'client_secret',
    getParams: {
      url: `${process.env.REACT_APP_BASE_URL_TRUST_API}/tokens`,
      config: {
        params: { _limit: 10 },
      },
    },
  });

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  return (
    <>
      {GenerateTokenModal}
      <>
        <div className="flex align-center gx-w-100 mrg-btm-40 space-between">
          <PageTitle
            title={translate('pages.private.accessKeys.tokens.index.title')}
            subtitle={translate('pages.private.accessKeys.tokens.index.subtitle')}
          />
          <div className="flex end-center mrg-right-20">
            <div className="flex center">
              <PermissionWrapper
                product="management"
                requiredPermissions={['integration-guide:create']}
              >
                <Button type="primary" onClick={openModal}>
                  {translate('pages.private.accessKeys.tokens.index.newToken')}
                </Button>
              </PermissionWrapper>
            </div>
          </div>
        </div>
      </>
      {loading ? (
        <Spin className="flex center gx-w-100 mrg-top-30 mrg-btm-50" />
      ) : (
        componentList
      )}
    </>
  );
};

export default Tokens;
