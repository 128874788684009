/* eslint-disable react/prop-types */
import { Divider, Dropdown, Menu, message } from 'antd';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { uuid } from 'uuidv4';

import { useFetch } from 'services/hooks';
import { useAuth } from 'hooks/auth';
import { hasRequiredPermissions } from 'routes/permissionFunctions';
import { useOnboardingTemplate } from 'hooks/onboardingTemplate';
import confirm from 'antd/lib/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import ic_share from 'assets/images/ic_share.svg';
import copyTextToClipboard from 'utils/copyTextToClipboard';

const I18N_BASE_PATH =
  'pages.private.profileModels.profileModelList.components.dropdownModelOptions.index';

function DropdownModelOptions({
  data,
  openCardShareLinkModal,
  openApiIntegrationGuideModal,
  refreshList,
}) {
  const { t: translate } = useTranslation();

  const { post: postOnboardingExt } = useFetch();
  const { loggedUser, user } = useAuth();
  const [
    profileModelAlreadyHasOnboardingUrl,
    setProfileModelAlreadyHasOnboardingUrl,
  ] = useState(data?.onboardingUrl);

  const { loadOnboardingTemplates } = useOnboardingTemplate();

  const handleCopyLink = useCallback(async () => {
    if (
      data?.type ===
        translate(
          'pages.private.profileModels.profileModelList.components.dropdownModelOptions.index.handleCopyLink.type.company'
        ) &&
      !data?.qsaProfileTemplateId
    ) {
      message.warn(
        translate(
          'pages.private.profileModels.profileModelList.components.dropdownModelOptions.index.handleCopyLink.messages.warn.company'
        ),
        6
      );
    } else if (
      data?.type ===
        translate(
          'pages.private.profileModels.profileModelList.components.dropdownModelOptions.index.handleCopyLink.type.person'
        ) &&
      data?.backgroundChecking?.services?.find((service) =>
        ['pf_rais'].includes(service.source)
      )
    ) {
      message.warn(
        translate(
          'pages.private.profileModels.profileModelList.components.dropdownModelOptions.index.handleCopyLink.messages.warn.person'
        ),
        6
      );
    } else if (!data?.onboardingUrl && !profileModelAlreadyHasOnboardingUrl) {
      try {
        message.loading({
          content: translate(
            'pages.private.profileModels.profileModelList.components.dropdownModelOptions.index.handleCopyLink.messages.loading.hasNotOnboardingUrlAndHasNotprofileModelAlreadyHasOnboardingUrl'
          ),
          duration: 0,
          key: 'copyLink',
        });

        const defaultTemplate = await loadOnboardingTemplates();

        const response = await postOnboardingExt({
          url: '/onboardings',
          payload: {
            noExpire: true,
            trustTemplateId: data._id,
            qsaTemplateId: data.qsaProfileTemplateId,
            templateId: data?.onboardingTemplateId || defaultTemplate[data?.type]?._id,
            email: '',
            type: data.type,
            smsPhoneNumber: '',
            attributes: {
              cpf: '',
              cnpj: '',
            },
            metadata: { origin: 'TRUST' },
          },
          showMessage: false,
        });

        setProfileModelAlreadyHasOnboardingUrl(response.onboardingUrl);

        try {
          navigator.clipboard.writeText(response.onboardingUrl);

          message.success({
            content: translate(
              'pages.private.profileModels.profileModelList.components.dropdownModelOptions.index.handleCopyLink.messages.success.linkCopied'
            ),
            duration: 2.5,
            key: 'copyLink',
          });
        } catch (error) {
          message.warn({
            content: translate(
              'pages.private.profileModels.profileModelList.components.dropdownModelOptions.index.handleCopyLink.messages.warn.copyLinkAlert'
            ),
            key: 'copyLink',
          });
        }
      } catch (e) {
        message.error({
          content: translate(
            'pages.private.profileModels.profileModelList.components.dropdownModelOptions.index.handleCopyLink.messages.error.uncopiedLink'
          ),
          duration: 2.5,
          key: 'copyLink',
        });
      }
    } else {
      copyTextToClipboard(
        data.onboardingUrl,
        translate(
          'pages.private.profileModels.profileModelList.components.dropdownModelOptions.index.handleCopyLink.copyTextToClipboard.success'
        ),
        translate(
          'pages.private.profileModels.profileModelList.components.dropdownModelOptions.index.handleCopyLink.copyTextToClipboard.error'
        )
      );
    }
  }, [
    data,
    loadOnboardingTemplates,
    postOnboardingExt,
    profileModelAlreadyHasOnboardingUrl,
    translate,
  ]);

  const { post: postDuplicate } = useFetch();
  const handleDuplicate = useCallback(
    async (_data) => {
      await confirm({
        title: translate(`${I18N_BASE_PATH}.duplicateComfirmModal.confirmDuplicate`),
        icon: <ExclamationCircleOutlined />,
        content: translate(
          `${I18N_BASE_PATH}.duplicateComfirmModal.confirmDuplicateDescription`
        ),
        async onOk() {
          await postDuplicate({
            url: `profile-templates`,
            payload: { _id: _data._id },
          });
          if (refreshList) refreshList();
        },
      });
    },
    [postDuplicate, refreshList, translate]
  );

  const { get: getDownload } = useFetch();
  const handleDownload = useCallback(
    async (_data) => {
      const response = await getDownload({
        url: `profile-templates/${_data._id}`,
        config: {
          params: {
            _export: true,
          },
        },
      });
      window.open(response.url, '_blank');
    },
    [getDownload]
  );

  return (
    <>
      <Dropdown
        className="dropdown-generate-link-container"
        overlay={
          <Menu className="dropdown-generate-link">
            {/* <Menu.Item key={uuid()}>
            <i style={{ fontSize: 18 }} className="caf-ic_edit" />
            <span>Alterar Cadastro</span>
          </Menu.Item> */}
            {hasRequiredPermissions(loggedUser, ['profile-templates:update'], []) && (
              <Menu.Item key={uuid()}>
                <Link
                  // id="card-profile-model-component"
                  className="overflow-hidden mrg-btm-20"
                  to={`/profile-templates/profile-models/${data?._id}?type=${data.type}`}
                >
                  <i style={{ fontSize: 18 }} className="caf-ic_combateafraude" />
                  <span>
                    {translate(`${I18N_BASE_PATH}.dropdownModelOptions.menuItem.edit`)}
                  </span>
                </Link>
              </Menu.Item>
            )}
            {hasRequiredPermissions(loggedUser, ['profile-templates:update'], []) && (
              <Divider style={{ margin: '1px 5px !important' }} />
            )}
            <Menu.Item key={uuid()} onClick={handleCopyLink}>
              <i style={{ fontSize: 18 }} className="caf-ic_link icon-copy-link" />
              <span>
                {translate(`${I18N_BASE_PATH}.dropdownModelOptions.menuItem.copyLink`)}
              </span>
            </Menu.Item>

            <Menu.Item key={uuid()} onClick={() => openCardShareLinkModal(data)}>
              <img
                src={ic_share}
                style={{ height: '18px', marginRight: '8px' }}
                alt={translate(`${I18N_BASE_PATH}.dropdownModelOptions.menuItem.share`)}
              />
              <span>
                {translate(`${I18N_BASE_PATH}.dropdownModelOptions.menuItem.share`)}
              </span>
            </Menu.Item>
            {hasRequiredPermissions(user, ['integration-guide:read'], []) && (
              <Menu.Item
                key={uuid()}
                onClick={() => {
                  openApiIntegrationGuideModal(data);
                }}
              >
                <i style={{ fontSize: 18 }} className="caf-ic_code" />
                <span>
                  {translate(
                    `${I18N_BASE_PATH}.dropdownModelOptions.menuItem.integrations`
                  )}
                </span>
              </Menu.Item>
            )}
            {hasRequiredPermissions(user, ['profile-templates:update'], []) && (
              <>
                <Menu.Item
                  key={uuid()}
                  onClick={() => {
                    handleDuplicate(data);
                  }}
                >
                  <i style={{ fontSize: 18 }} className="caf-ic_duplicate" />
                  <span>
                    {translate(
                      `${I18N_BASE_PATH}.dropdownModelOptions.menuItem.duplicate`
                    )}
                  </span>
                </Menu.Item>
                <Menu.Item
                  key={uuid()}
                  onClick={() => {
                    handleDownload(data);
                  }}
                >
                  <i style={{ fontSize: 18 }} className="caf-ic_download" />
                  <span>
                    {translate(`${I18N_BASE_PATH}.dropdownModelOptions.menuItem.export`)}
                  </span>
                </Menu.Item>
              </>
            )}
          </Menu>
        }
        trigger={['click']}
        placement="bottomRight"
      >
        <div className="btn-more-icon">
          <i className="caf-ic_more-vertical" />
        </div>
      </Dropdown>
    </>
  );
}

export default DropdownModelOptions;
