import React from 'react';
// import { Form, Select } from 'antd';

import './styles.less';
// import PageTitle from 'components/PageTitle';
import NewGroup from '../../../../GroupsManagement/GroupsCreate/components/NewGroup';
import ExistingGroup from './components/ExistingGroup';

const GroupsStep = ({ formRef, typeOfCreate, selectedGroup, setSelectedGroup }) => {
  return (
    <div className="flex-column center">
      {typeOfCreate === 'existing' ? (
        <ExistingGroup
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
        />
      ) : (
        <NewGroup formRef={formRef} disabled={false} />
      )}
    </div>
  );
};

export default GroupsStep;
