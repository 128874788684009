/* eslint-disable no-return-assign */
import React, { useState, useRef, useCallback, useLayoutEffect } from 'react';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import './styles.less';

const TabNavigation = ({ children, onChangeTab, defaultActiveKey }) => {
  const [buttonsWidth, setButtonsWidth] = useState([]);
  const [widthToMove, setWidthToMove] = useState([]);
  const [sliderWidth, setSliderWidth] = useState(0);
  const [hideButton, setHideButton] = useState({
    leftButton: true,
    rightButton: false,
    hideAll: false,
  });

  const listOfElementsWithoutErrors = children.filter((element) => {
    return (
      element !== undefined &&
      element !== false &&
      element !== true &&
      element !== null &&
      element !== ''
    );
  });

  const getArrayDefaultKey = listOfElementsWithoutErrors.findIndex(
    (item) => item.key === defaultActiveKey || 0
  );

  const getDefaultIndex = getArrayDefaultKey !== -1 ? getArrayDefaultKey : 0;

  const activeTab = listOfElementsWithoutErrors[getDefaultIndex];
  const [activeTabIndex, setActiveTabIndex] = useState(getDefaultIndex);
  const containerRef = useRef(null);
  const tabRef = useRef([]);
  const tabComponent = useRef(null);

  const addWidthToMove = useCallback(() => {
    const resultWidthsArray = buttonsWidth.map(((s) => (a) => (s += a))(0));

    setWidthToMove([0, ...resultWidthsArray]);
  }, [buttonsWidth]);

  useLayoutEffect(() => {
    setButtonsWidth(tabRef.current.map((item) => item?.clientWidth + 16));
    addWidthToMove();
    if (tabComponent.current.offsetWidth > containerRef.current.offsetWidth) {
      setHideButton((prevState) => ({
        ...prevState,
        hideAll: true,
      }));
    }
    setSliderWidth(containerRef.current.scrollWidth - containerRef.current.offsetWidth);
  }, [children, activeTabIndex]);

  const onClickRightButton = useCallback(() => {
    containerRef.current.scrollLeft += 200;
  }, []);

  const onClickLeftButton = useCallback(() => {
    containerRef.current.scrollLeft -= 200;
  }, []);

  const onHScroll = () => {
    const el = containerRef.current.scrollLeft;
    if (el > 0) {
      setHideButton((prevState) => ({
        ...prevState,
        leftButton: false,
      }));
    } else {
      setHideButton((prevState) => ({
        ...prevState,
        leftButton: true,
      }));
    }
    if (el < sliderWidth) {
      setHideButton((prevState) => ({
        ...prevState,
        rightButton: false,
      }));
    } else {
      setHideButton((prevState) => ({
        ...prevState,
        rightButton: true,
      }));
    }
  };

  const onClickButton = useCallback(
    (tab, index) => {
      setActiveTabIndex(index);
      onChangeTab(tab.key);
    },
    [onChangeTab]
  );

  return (
    <div className="tabs-cmpnt-container">
      <div className="tabs-cmpnt-content">
        {hideButton.hideAll ? (
          <button
            type="button"
            className="button-icon leftside-icon"
            onClick={onClickLeftButton}
            disabled={hideButton.leftButton}
          >
            <LeftOutlined />
          </button>
        ) : null}

        <div className="tab-content" ref={containerRef} onScroll={() => onHScroll()}>
          <div className="tab-component" ref={tabComponent}>
            <div className="tabs">
              {listOfElementsWithoutErrors.map((tab, i) => (
                <button
                  className="tab-btn"
                  type="button"
                  ref={(el) => (tabRef.current[i] = el)}
                  onClick={(event) => onClickButton(tab, i, event)}
                  key={tab.props.key}
                >
                  {tab.props.tab}
                </button>
              ))}
            </div>

            <div className="tab-indicator-container">
              <div
                className="tab-indicator"
                style={{
                  width: `${buttonsWidth[activeTabIndex]}px`,
                  transform: `translateX(${widthToMove[activeTabIndex]}px)`,
                }}
              />
            </div>
          </div>
        </div>
        {hideButton.hideAll ? (
          <button
            type="button"
            className="button-icon rightside-icon"
            onClick={onClickRightButton}
            disabled={hideButton.rightButton}
          >
            <RightOutlined />
          </button>
        ) : null}
      </div>

      {activeTab.props.children}
    </div>
  );
};

export default TabNavigation;
