import React from 'react';

import PropTypes from 'prop-types';
import { Radio, Col, Button, Tooltip } from 'antd';
import { Tooltip as TooltipIcon } from '@combateafraude/icons/general';

import './styles.less';

const TimingOptions = ({
  isLoading,
  title,
  description,
  subtitle,
  options,
  handleSave,
  defaultValue,
  handleChange,
  textButton,
  tooltip,
}) => (
  <Col className="container-timing-options">
    {description && <span className="text option-description">{description}</span>}
    {title && (
      <span className="text option-title">
        {title}
        {tooltip && (
          <Tooltip
            title={tooltip}
            autoAdjustOverflow
            arrowPointAtCenter
            trigger="hover"
            overlayInnerStyle={{
              padding: '0.5rem',
            }}
          >
            <TooltipIcon />
          </Tooltip>
        )}
      </span>
    )}

    {subtitle && <span className="text option-subtitle">{subtitle}</span>}
    <div className="wrapper-timing-options">
      <Radio.Group onChange={handleChange} value={defaultValue} className="padding-left">
        {options.map(({ value, label }) => (
          <Radio value={value} key={value}>
            {label}
          </Radio>
        ))}
      </Radio.Group>
      {handleSave && (
        <Button onClick={handleSave} loading={isLoading} type="primary">
          {textButton}
        </Button>
      )}
    </div>
  </Col>
);

TimingOptions.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  title: PropTypes.string,
  handleSave: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf({ value: PropTypes.string, label: PropTypes.string })
    .isRequired,
  textButton: PropTypes.string,
  description: PropTypes.string,
  subtitle: PropTypes.string,
  isLoading: PropTypes.string,
  tooltip: PropTypes.string,
};

TimingOptions.defaultProps = {
  textButton: 'Save',
  description: '',
  subtitle: '',
  title: '',
  isLoading: false,
  tooltip: undefined,
};

export default TimingOptions;
