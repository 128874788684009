import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Row,
  Col,
  Spin,
  Form,
  Pagination,
  Select,
  Dropdown,
  Menu,
  Input,
  Tooltip,
  Card,
  Button,
  message,
} from 'antd';

import { renderImgByExtension, renderColorTagByExtension } from 'utils/renderByExtension';

import { useI18nFormatters } from 'hooks/i18nFormatters';
import { useUploadManager } from 'hooks/uploadManager';
import { useFetch } from 'services/hooks';

import useModalUpload from 'components/ModalUpload';
import useModalConfirmAction from 'components/ModalConfirmAction';

import EmptyMessage from 'components/EmptyMessage';
import useFileViewer from 'components/Files/FileViewer';
import PermissionWrapper from 'routes/PermissionWrapper';

import { getExtension } from 'utils/getExtensionFile';
import copyTextToClipboard from 'utils/copyTextToClipboard';
import EmptyArchives from './components/EmptyArchives';

import './styles.less';

const { Option } = Select;

const ArchivesList = ({ url }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const [form] = Form.useForm();

  const { get, delete: _delete, loading } = useFetch();
  const { success } = useUploadManager();

  const { openFileViewer, fileViewerComponent } = useFileViewer();
  const { openModal: openCardUploadModal, CardUploadModal } = useModalUpload();

  const [archivesList, setArchivesList] = useState([]);
  const [totalArchives, setTotalArchives] = useState(0);
  const [searchChange, setSearchChange] = useState(false);

  const [filterSelected, setFilterSelected] = useState('recent');
  const [searchValue, setSearchValue] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    // showTotal: (total, range) => `Exibindo ${range[0]} a ${range[1]} de ${total} itens`,
    showTotal: (total, range) =>
      translate(
        'pages.private.profiles.components.tabs.components.archivesList.index.showTotal',
        {
          rangeOne: range[0],
          rangeTwo: range[1],
          total,
        }
      ),
  });

  const fetch = useCallback(
    async (current) => {
      const pageSize = pagination.pageSize || 20;

      function filterOrder() {
        if (filterSelected === 'recent' || filterSelected === 'z-a') return -1;
        if (filterSelected === 'old' || filterSelected === 'a-z') return 1;
        return -1;
      }

      const response = await get({
        url,
        config: {
          params: {
            _limit: pageSize,
            _offset: pageSize * (current - 1),
            _sort:
              filterSelected === 'a-z' || filterSelected === 'z-a'
                ? 'fileName'
                : 'createdAt',
            _order: filterOrder(),
            _search: searchValue,
          },
        },
        showMessage: false,
      });

      setTotalArchives(response.totalItems);
      setArchivesList(response.docs);

      setPagination((oldState) => ({
        ...oldState,
        current,
        pageSize,
        total: totalArchives,
      }));
    },
    [get, pagination.pageSize, url, totalArchives, filterSelected, searchValue]
  );

  const deleteFile = useCallback(
    async (fileId) => {
      try {
        await _delete({
          url: `${url}/${fileId}`,
          config: {},
          showMessage: true,
        });
        message.success(
          translate(
            'pages.private.profiles.components.tabs.components.archivesList.index.deleteFile.messageSuccess'
          )
        );
      } catch {
        message.error(
          translate(
            'pages.private.profiles.components.tabs.components.archivesList.index.deleteFile.messageError'
          )
        );
      }
    },
    [_delete, translate, url]
  );

  const { openModal: openConfirmActionModal, ConfirmActionModal } = useModalConfirmAction(
    {
      action: deleteFile,
      refreshListRef: () => fetch(1),
      loading,
      danger: true,
      okButton: translate(
        'pages.private.profiles.components.tabs.components.archivesList.index.modalConfirm.okButton'
      ),
      cancelButton: translate(
        'pages.private.profiles.components.tabs.components.archivesList.index.modalConfirm.cancelButton'
      ),
      title: translate(
        'pages.private.profiles.components.tabs.components.archivesList.index.modalConfirm.title'
      ),
      subtitle: translate(
        'pages.private.profiles.components.tabs.components.archivesList.index.modalConfirm.subtitle'
      ),
    }
  );

  useEffect(() => {
    fetch(pagination.current);
  }, [pagination.current, filterSelected, searchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (success !== 0) fetch(pagination.current);
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps

  const copyLinkButton = useCallback(
    (doc) => (
      <Menu className="dropdown-generate-link">
        <Menu.Item
          key="0"
          onClick={() =>
            copyTextToClipboard(
              doc.link,
              translate(
                'pages.private.profiles.components.tabs.components.archivesList.index.copyLinkButton.copyTextToClipboard.success'
              ),
              translate(
                'pages.private.profiles.components.tabs.components.archivesList.index.copyLinkButton.copyTextToClipboard.error'
              )
            )
          }
        >
          <i className="caf-ic_link icon-copy-link" />
          <span>
            {translate(
              'pages.private.profiles.components.tabs.components.archivesList.index.copyLinkButton.button'
            )}
          </span>
        </Menu.Item>
        <PermissionWrapper requiredPermissions={['files:delete']}>
          <Menu>
            <Menu.Divider />
          </Menu>
        </PermissionWrapper>
        <PermissionWrapper requiredPermissions={['files:delete']}>
          <Menu>
            <Menu.Item
              key="1"
              className="gx-text-danger"
              onClick={() => {
                openConfirmActionModal(doc._id);
              }}
            >
              <i className="caf-ic_trash icon-copy-link" />
              <span>
                {translate(
                  'pages.private.profiles.components.tabs.components.archivesList.index.copyLinkButton.delete'
                )}
              </span>
            </Menu.Item>
          </Menu>
        </PermissionWrapper>
      </Menu>
    ),
    [translate] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleChangeInputValue = useCallback(
    _.debounce((__, values) => {
      setSearchValue(values.archive);
      setSearchChange(true);
    }, 300),
    []
  );

  const showHeader = archivesList.length > 0 || searchValue !== '' || searchChange;

  return (
    <Card id="card-content-documents">
      {CardUploadModal}
      {ConfirmActionModal}
      {showHeader && (
        <div className="card-documents-header">
          {fileViewerComponent}
          <div className="documents-header-container">
            <Form form={form} layout="horizontal" onValuesChange={handleChangeInputValue}>
              <Form.Item name="archive">
                <Input
                  className="custom-search-input"
                  placeholder={translate(
                    'pages.private.profiles.components.tabs.components.archivesList.index.archivesList.form.archive.placeholder'
                  )}
                  style={{ width: 320 }}
                  value={searchValue}
                  prefix={<i className="caf-ic_search" />}
                />
              </Form.Item>
            </Form>
            <div className="flex gx-row">
              <Form.Item name="select">
                <Select
                  bordered={false}
                  defaultValue={filterSelected}
                  className="text-dark"
                  style={{ minWidth: 140, fontSize: 15 }}
                  onChange={(value) => setFilterSelected(value)}
                >
                  <Option value="a-z">
                    {translate(
                      'pages.private.profiles.components.tabs.components.archivesList.index.archivesList.form.select.options.aTOz'
                    )}
                  </Option>
                  <Option value="z-a">
                    {translate(
                      'pages.private.profiles.components.tabs.components.archivesList.index.archivesList.form.select.options.zTOa'
                    )}
                  </Option>
                  <Option value="recent">
                    {translate(
                      'pages.private.profiles.components.tabs.components.archivesList.index.archivesList.form.select.options.recent'
                    )}
                  </Option>
                  <Option value="old">
                    {translate(
                      'pages.private.profiles.components.tabs.components.archivesList.index.archivesList.form.select.options.old'
                    )}
                  </Option>
                </Select>
              </Form.Item>
              <PermissionWrapper requiredPermissions={['files:create']}>
                <Button
                  type="primary"
                  onClick={openCardUploadModal}
                  className="mrg-left-10"
                >
                  <i className="caf-ic_attach mrg-right-5" />
                  {translate(
                    'pages.private.profiles.components.tabs.components.archivesList.index.archivesList.button'
                  )}
                </Button>
              </PermissionWrapper>
            </div>
          </div>
        </div>
      )}

      {loading && (
        <div className="loading-container">
          <Spin />
        </div>
      )}

      {!loading && archivesList.length < 1 && !showHeader && (
        <div className="flex-row center gx-w-100">
          <EmptyArchives />
        </div>
      )}

      {!loading && archivesList.length < 1 && showHeader && searchValue !== '' && (
        <div className="flex-row center gx-w-100 mrg-top-30 mrg-btm-30">
          <EmptyMessage
            type="search"
            description={translate(
              'pages.private.profiles.components.tabs.components.archivesList.index.archivesList.emptyMessage.description',
              { searchValue: `"${searchValue}"` }
            )}
          />
        </div>
      )}

      {!loading && archivesList.length < 1 && showHeader && searchValue === '' && (
        // evita que retorno seja 'nenhum arquivo com "" encontrado.'
        <div className="loading-container">
          <Spin />
        </div>
      )}

      {!loading && archivesList.length > 0 && (
        <>
          <Row id="card-documents-data" justify="start" gutter={24}>
            {archivesList.map((doc) => {
              const extension = getExtension(doc);

              const name = doc.fileName.split(`.${extension}`)[0];

              return (
                <Col key={doc._id} xs={12} sm={8} className="pdd-btm-10 pdd-top-10">
                  <div className="card-document">
                    <button
                      type="button"
                      className="document-image-container"
                      onClick={() =>
                        openFileViewer({ fileId: doc._id, preloadFile: doc })
                      }
                    >
                      <div
                        className="document-extension-tag"
                        style={{
                          backgroundColor: renderColorTagByExtension(
                            doc.mimeType || doc.contentType
                          ),
                        }}
                      >
                        {extension === 'N/A' ? extension : `.${extension}`}
                      </div>
                      <div
                        className="document-image"
                        style={{
                          backgroundImage: `url(${renderImgByExtension(
                            doc.mimeType || doc.contentType
                          )})`,
                        }}
                      />
                    </button>

                    <div className="document-infos-container">
                      <div className="document-infos-text">
                        <Tooltip placement="bottom" title={doc.fileName}>
                          <span className="max-lines-1 file-name">{name}</span>
                        </Tooltip>
                        <span>{i18nFormatDate(doc.createdAt, 'default')}</span>
                      </div>

                      <Dropdown overlay={copyLinkButton(doc)} placement="bottomRight">
                        <div className="btn-more-icon">
                          <i className="caf-ic_more-vertical" />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                </Col>
              );
            })}

            <div className="pagination-container">
              <Pagination
                total={totalArchives}
                pageSize={pagination.pageSize}
                current={pagination.current}
                showTotal={(total, range) =>
                  translate(
                    'pages.private.profiles.components.tabs.components.archivesList.index.showTotal',
                    {
                      rangeOne: range[0],
                      rangeTwo: range[1],
                      total,
                    }
                  )
                }
                onChange={(page) =>
                  setPagination((state) => ({ ...state, current: page }))
                }
                showSizeChanger={false}
              />
            </div>
          </Row>
        </>
      )}
    </Card>
  );
};

ArchivesList.propTypes = {
  url: PropTypes.string.isRequired,
};

export default ArchivesList;
