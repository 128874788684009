import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Divider } from 'antd';

import ContactAndRelationCard from 'pages/private/Profiles/components/ContactAndRelationCard';

import { useExecution } from 'hooks/execution';
import BasicInfosCard from '../BasicInfosCard';

const InternationalContactContent = () => {
  const { t: translate } = useTranslation();

  const { execution } = useExecution();

  return (
    <BasicInfosCard
      icon="caf-ic_contact_"
      title={translate(
        'pages.private.executions.components.dataSourceCards.basicInfos.components.person.contactContent.contactContent.basicInfosCard.title'
      )}
      dataIndex="contacts"
      customQuery={false}
      customId="basic-infos-contacts"
    >
      <Row className="contact-data-container gx-w-100">
        {execution?.parameters?.phoneNumber && (
          <Col span={24} className="contact-data-col">
            <p className="contact-section-title">
              {translate(
                'pages.private.executions.components.dataSourceCards.basicInfos.components.person.contactContent.contactContent.basicInfosCard.phones.title'
              )}
            </p>
            <Row className="contact-data-row">
              <ContactAndRelationCard
                key={execution?.parameters?.phoneNumber}
                span={8}
                type="MOBILE"
                content={execution?.parameters?.phoneNumber}
                className="pdd-vertical-10 pdd-horizontal-0"
              />
            </Row>

            {execution?.parameters?.email && <Divider />}
          </Col>
        )}

        {execution?.parameters?.email && (
          <Col span={24} className="contact-data-col">
            <p className="contact-section-title">
              {translate(
                'pages.private.executions.components.dataSourceCards.basicInfos.components.person.contactContent.contactContent.basicInfosCard.emails.title'
              )}
            </p>

            <Row className="contact-data-row">
              <ContactAndRelationCard
                key={execution?.parameters?.email}
                span={8}
                type="PERSONAL"
                content={execution?.parameters?.email}
                className="pdd-vertical-10 pdd-horizontal-0"
              />
            </Row>
          </Col>
        )}

        {!execution?.parameters?.phoneNumber && !execution?.parameters?.email && (
          <Col span={24} className="contact-data-col">
            {translate(
              'components.dataSourceCards.basicInfos.components.person.addressContent.columLabelTitle.hasNotToShowContact',
              'No contact found linked to this person'
            )}
          </Col>
        )}
      </Row>
    </BasicInfosCard>
  );
};

export default InternationalContactContent;
