/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Input, Form, Col, Button, message } from 'antd';
import { useParams } from 'react-router-dom';

import InputMask from 'components/Form/InputMask';

import { useExecution } from 'hooks/execution';

import { useFetch } from 'services/hooks';

import uppercaseObject from 'utils/uppercaseObject';

const I18N_BASE_PATH =
  'pages.private.executions.components.tabs.components.documentsValidations';

const ClientOcr = ({ fields, ocr }) => {
  const { t: translate } = useTranslation();

  const { executionId } = useParams();
  const { loadExecution } = useExecution();

  const { post, loading } = useFetch();

  const handleSubmit = useCallback(
    async (values) => {
      try {
        const payload = uppercaseObject(values);

        await post({
          url: `/executions/${executionId}/manual-ocr`,
          payload: { ...payload, isClientOcr: true },
          showMessage: false,
        });
        message.success(
          translate(
            'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.handleSubmit.messages.success'
          )
        );

        loadExecution({
          executionId,
          clearData: true,
        });
      } catch (error) {
        message.error(
          translate(
            'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.handleSubmit.messages.error'
          )
        );
      }
    },
    [executionId, loadExecution, post, translate]
  );

  const renderOptionalLabel = useCallback(
    (label) => {
      return (
        <>
          {label}
          <i className="font-size-10" style={{ marginLeft: 2 }}>
            - {translate(`${I18N_BASE_PATH}.newOcrScreen.editOcr.optional`, 'Optional')}
          </i>
        </>
      );
    },
    [translate]
  );

  return (
    <Form layout="vertical" className="uppercase-form" onFinish={handleSubmit}>
      <Row className="custom-validations-data">
        {fields?.map((field) => {
          return (
            <Col span={8}>
              <Form.Item
                name={field}
                label={renderOptionalLabel(
                  translate(`${I18N_BASE_PATH}.newOcrScreen.fields.${field}`, field)
                )}
                initialValue={ocr?.[field]}
              >
                <Input className="uppercase-input" />
              </Form.Item>
            </Col>
          );
        })}
      </Row>
      <Row
        style={{ display: 'flex', justifyContent: 'flex-end' }}
        className="no-mrg-right"
      >
        <Col>
          <Button htmlType="submit" type="primary" loading={loading}>
            {translate(`${I18N_BASE_PATH}.newOcrScreen.editOcr.save`)}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ClientOcr;
