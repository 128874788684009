import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Collapse, Radio } from 'antd';

const { Panel } = Collapse;

const RestrictiveListFilter = ({ value, onChange }) => {
  const { t: translate } = useTranslation();

  return (
    <Collapse
      ghost
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <div>
          <i className={isActive ? 'caf-ic_arrow_up' : 'caf-ic_arrow_down'} />
        </div>
      )}
    >
      <Panel
        header={translate(
          'components.filters.restrictiveListFilter.collapse.panel.header'
        )}
        key="_internalRestrictiveList"
      >
        <Radio.Group
          defaultValue=""
          value={value || ''}
          onChange={(e) => onChange(e.target.value)}
        >
          <Radio value="">
            {translate(
              'components.filters.restrictiveListFilter.collapse.panel.radioGroup.all'
            )}
          </Radio>
          <Radio value="MATCH">
            {translate(
              'components.filters.restrictiveListFilter.collapse.panel.radioGroup.match'
            )}
          </Radio>
          <Radio value="NO_MATCH">
            {translate(
              'components.filters.restrictiveListFilter.collapse.panel.radioGroup.noMatch'
            )}
          </Radio>
        </Radio.Group>
      </Panel>
    </Collapse>
  );
};

RestrictiveListFilter.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RestrictiveListFilter;
