export const relationshipType = (relationshipType) => {
  switch (relationshipType) {
    case 'atleta':
      return 'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.athlete';
    case 'agente-operador':
      return 'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.operatingAgent';
    case 'agente-publico':
      return 'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.publicOfficial';
    case 'agente-sistemas':
      return 'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.systemsAgent';
    case 'funcionario':
      return 'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.employee';
    case 'arbitragem':
      return 'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.arbitration';
    case 'agente-intermediario':
      return 'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.intermediaryAgent';
    case 'agente-fiscalizador':
      return 'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.supervisoryAgent';
    default:
      return '';
  }
};

export const relationshipTips = (relationshipType) => {
  switch (relationshipType) {
    case 'atleta':
      return 'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.athleteTip';
    case 'agente-operador':
      return 'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.operatingAgentTip';
    case 'agente-publico':
      return 'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.publicOfficialTip';
    case 'agente-sistemas':
      return 'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.systemsAgentTip';
    case 'funcionario':
      return 'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.employeeTip';
    case 'arbitragem':
      return 'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.arbitrationTip';
    case 'agente-intermediario':
      return 'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.intermediaryAgentTip';
    case 'agente-fiscalizador':
      return 'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.supervisoryAgentTip';
    default:
      return '';
  }
};

export const relationshipLevel = (relationship) => {
  switch (relationship) {
    case 'first-degree':
      return 'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.firstDegreeLevel';
    case 'second-degree':
      return 'components.dataSourceCards.relationships.components.relatedSports.index.components.SportsAndRelationCard.secondDegreeLevel';
    default:
      return '-';
  }
};
