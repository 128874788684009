export const funcSumPrice = (_services, cafServicesList) => {
  return cafServicesList?.docs
    .filter((i) => _services.includes(i.source))
    .reduce((prev, curr) => prev + curr.price, 0);
};

export const funcExtractValitions = (_services, cafServicesList, selectedValidations) => {
  const selected = selectedValidations?.map((s) => s?.rule) || [];
  return cafServicesList?.docs
    .filter((i) => _services.includes(i.source))
    .map((i) => i.relatedRules)
    .reduce((prev, curr) => prev.concat(curr), [])
    .map((i) => {
      return { ...i, disabled: selected.includes(i.rule) };
    });
};

export const funcRemoveValidationsFromFormField = (formFields, validationOpt) => {
  const options = validationOpt.map((opt) => opt.rule);
  const newFormFields = formFields.filter((field) => {
    return options.includes(field?.rule);
  });
  return newFormFields;
};
