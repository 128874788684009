/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import './styles.less';

const SPCInfos = ({ data }) => {
  const { t: translate } = useTranslation();

  const { i18nFormatCurrency } = useI18nFormatters();

  const restriction = data?.restriction;
  const _class = data?.spcScore12Months?.class;
  const score = data?.spcScore12Months?.score;
  const debt = data?.totalAmount;

  return (
    <Row id="spc-row-infos" className="reset-mrg">
      <Col span={7} className="spc-col-info no-left">
        <strong>
          {translate(
            'pages.private.profiles.components.dataSourceCards.financialInformation.shared.spc.spcInfos.index.spcInfos.restriction'
          )}
        </strong>
        <span>
          {translate(
            `pages.private.profiles.components.dataSourceCards.financialInformation.shared.spc.spcInfos.index.spcInfos.restrictionValue.${
              restriction ? 'yes' : 'no'
            }`
          )}{' '}
        </span>
      </Col>
      <Col span={7} className="spc-col-info">
        <strong>
          {translate(
            'pages.private.profiles.components.dataSourceCards.financialInformation.shared.spc.spcInfos.index.spcInfos.class'
          )}
        </strong>
        <div className="spc-class-info">
          <span>{_class || '-'}</span>
          <span>(A-F)</span>
        </div>
      </Col>
      <Col span={5} className="spc-col-info">
        <strong>
          {translate(
            'pages.private.profiles.components.dataSourceCards.financialInformation.shared.spc.spcInfos.index.spcInfos.score'
          )}
        </strong>
        <span>{score || '-'}/1000</span>
      </Col>
      <Col span={5} className="spc-col-info no-right end">
        <strong>
          {translate(
            'pages.private.profiles.components.dataSourceCards.financialInformation.shared.spc.spcInfos.index.spcInfos.debt'
          )}
        </strong>
        <span>
          {i18nFormatCurrency(debt, 'pt-BR') ||
            translate(
              'pages.private.profiles.components.dataSourceCards.financialInformation.shared.spc.spcInfos.index.spcInfos.hasNotDebt'
            )}{' '}
        </span>
      </Col>
    </Row>
  );
};

export default SPCInfos;
