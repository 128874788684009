import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { message, Modal, Button, Form, Input, Row, Col } from 'antd';
import PageTitle from 'components/PageTitle';

import api from 'services/api';

const RemoveKey = ({ data, refreshListRef }) => {
  const { t: translate } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const onRemoveToken = useCallback(async () => {
    try {
      setLoading(true);

      await api.delete(`${process.env.REACT_APP_BASE_URL_TRUST_API}/tokens/${data?.id}`);
      refreshListRef();
      message.success(
        translate(
          'pages.private.accessKeys.tokens.components.tokenCard.components.removeToken.messageSuccess'
        )
      );
      closeModal();
    } catch (error) {
      message.error(
        translate(
          'pages.private.accessKeys.tokens.components.tokenCard.components.removeToken.messageError'
        )
      );
    } finally {
      setLoading(false);
    }
  }, [closeModal, data, refreshListRef, translate]);

  const onEditToken = useCallback(
    async (payload) => {
      try {
        setLoading(true);
        const { tokenName } = payload;
        await api.put(`${process.env.REACT_APP_BASE_URL_TRUST_API}/tokens/${data?.id}`, {
          keyName: tokenName,
        });
        refreshListRef();
        message.success(
          translate(
            'pages.private.accessKeys.tokens.components.tokenCard.components.removeToken.messageSuccessEdit'
          )
        );
        closeModal();
      } catch (error) {
        message.error(
          translate(
            'pages.private.accessKeys.tokens.components.tokenCard.components.removeToken.messageErrorEdit'
          )
        );
      } finally {
        setLoading(false);
      }
    },
    [closeModal, data, refreshListRef, translate]
  );

  const RemoveTokenModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        footer={null}
        width={550}
        closable={false}
        wrapClassName="caf-modal"
        onCancel={closeModal}
      >
        <div className="no-mrg no-pdd">
          <PageTitle
            title={translate(
              'pages.private.accessKeys.tokens.components.tokenCard.components.removeToken.pageTitle'
            )}
            align="center"
          />

          <div className="flex center mrg-top-25 mrg-btm-35">
            <span className="text-center font-size-16">
              {translate(
                'pages.private.accessKeys.tokens.components.tokenCard.components.removeToken.span'
              )}
              <strong className="display-block mrg-top-10 text-dark font-size-16">
                {data?.keyName ||
                  translate(
                    'pages.private.accessKeys.tokens.components.tokenCard.components.removeToken.noName'
                  )}
              </strong>
            </span>
          </div>

          <div className="flex center-end">
            <Button
              type="default"
              onClick={closeModal}
              className="btn-cancel"
              disabled={loading}
            >
              {translate(
                'pages.private.accessKeys.tokens.components.tokenCard.components.removeToken.buttonCancel'
              )}
            </Button>
            <Button
              type="danger"
              onClick={onRemoveToken}
              disabled={loading}
              loading={loading}
            >
              {loading
                ? translate(
                    'pages.private.accessKeys.tokens.components.tokenCard.components.removeToken.buttonLoading'
                  )
                : translate(
                    'pages.private.accessKeys.tokens.components.tokenCard.components.removeToken.buttonOk'
                  )}
            </Button>
          </div>
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, translate, data, loading, onRemoveToken]
  );
  const EditTokenModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        footer={null}
        width={550}
        closable={false}
        wrapClassName="caf-modal"
        onCancel={closeModal}
      >
        <div className="no-mrg no-pdd">
          <div className="flex left-end">
            <Row>
              <Col className="col-ant mrg-right-20 mrg-btm-20" span={24}>
                <PageTitle
                  title={
                    data?.keyName ||
                    translate(
                      'pages.private.accessKeys.tokens.components.tokenCard.components.removeToken.noName'
                    )
                  }
                  subtitle={translate(
                    'pages.private.accessKeys.tokens.components.tokenCard.components.removeToken.subtitleEdit'
                  )}
                />
              </Col>
              <Col span={24}>
                <Form
                  form={form}
                  layout="vertical"
                  className="no-mrg"
                  onFinish={onEditToken}
                >
                  <Form.Item
                    name="tokenName"
                    label={translate(
                      'pages.private.accessKeys.tokens.components.tokenCard.components.removeToken.tokenName'
                    )}
                    rules={[
                      {
                        required: true,
                        message: translate(
                          'pages.private.accessKeys.components.generateAccessKey.error'
                        ),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Button
                    className="mrg-top-20"
                    type="primary"
                    htmlType="submit"
                    disabled={loading}
                  >
                    {loading
                      ? translate(
                          'pages.private.accessKeys.tokens.components.tokenCard.components.removeToken.loading'
                        )
                      : translate(
                          'pages.private.accessKeys.tokens.components.tokenCard.components.removeToken.save'
                        )}
                  </Button>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, data, translate, form, onEditToken, loading]
  );
  return { openModal, closeModal, RemoveTokenModal, EditTokenModal };
};

export default RemoveKey;
