import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import EmptyState from 'components/EmptyState';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import EmptyExecutionState from 'components/EmptyExecutionState';

import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.shared.spcRestrictionsScore.addressInfos';

const AddressContent = ({ source, toPrint, company /* toPrint */ }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);

  const statusCode = useMemo(
    () => source?.data?.statusCode || source?.statusCode || source?.data,
    [source]
  );

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const joinArray = useCallback((array) => {
    return array.filter((n) => n).join(', ');
  }, []);

  const addressFormatted = useMemo(() => {
    if (!parsedData?.customer?.address) return 'no-recurrence';

    if (parsedData?.customer?.address && _.isEmpty(parsedData?.customer?.address)) {
      return 'query-failed';
    }

    if (parsedData?.customer?.address) {
      const { street, number, neighborhood, district, city, state, zipCode, complement } =
        parsedData?.customer?.address || {};

      return (
        <span>
          {street && `${street},`} {number && `${number},`}{' '}
          {complement && (neighborhood || district)
            ? `${complement} -`
            : `${complement || ''}`}{' '}
          {neighborhood || district || ''} <br />
          {city && state ? `${city} /` : `${city || ''}`} {state && `${state},`}{' '}
          {zipCode || ''}
        </span>
      );
    }

    return 'no-data';
  }, [parsedData]);

  const src = useMemo(() => {
    let fullAddress;

    if (parsedData?.customer?.address) {
      const { street, number, neighborhood, city, state } =
        parsedData?.customer?.address || {};
      fullAddress = joinArray([street, number, neighborhood, city, state]);
    }

    if (!fullAddress) return undefined;

    const encodedFullAddress = encodeURI(fullAddress);

    const url = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&q=${encodedFullAddress}`;

    return url;
  }, [joinArray, parsedData]);

  const isProcessing = useMemo(() => verifySourcesProcessing(company, 'addresses'), [
    company,
  ]);

  return queryWorked ? (
    <DataSourceCardCollapsePanel
      title={translate(`${I18N_BASE_PATH}.index.title`)}
      icon="caf-ic_map_pin"
      toPrint={toPrint}
      customId="report-spc-last-phone-card"
    >
      {addressFormatted === 'no-recurrence' && isProcessing && (
        <EmptyState type="processing" />
      )}

      {addressFormatted === 'no-recurrence' && !isProcessing && (
        <EmptyState type="waiting_consult" />
      )}

      {addressFormatted === 'no-data' && (
        <EmptyState
          type="failed"
          description={translate(
            `${I18N_BASE_PATH}.index.emptyState.description.addressFormattedEqualsNoData`
          )}
        />
      )}

      {(addressFormatted === 'query-failed' ||
        (parsedData?.customer?.address && !src)) && (
        <EmptyState
          type="failed"
          description={translate(
            `${I18N_BASE_PATH}.index.emptyState.description.addressFormattedEqualsQueryFailed`
          )}
        />
      )}

      {addressFormatted &&
        addressFormatted !== 'no-recurrence' &&
        addressFormatted !== 'no-data' &&
        addressFormatted !== 'query-failed' &&
        src && (
          <Row>
            <Col span={24} className="address-formatted">
              {addressFormatted}
            </Col>

            {src && (
              <Col span={24}>
                <iframe
                  title="map"
                  width="100%"
                  height="350"
                  src={src}
                  allowFullScreen
                  className="iframe-map"
                />
              </Col>
            )}
          </Row>
        )}
    </DataSourceCardCollapsePanel>
  ) : (
    <DataSourceCardCollapsePanel
      title={translate(`${I18N_BASE_PATH}.index.title`)}
      icon="caf-ic_map_pin"
      toPrint={toPrint}
      customId="report-spc-last-phone-card"
    >
      <EmptyExecutionState
        statusCode={statusCode}
        message={source?.message || parsedData?.message}
      />
    </DataSourceCardCollapsePanel>
  );
};

AddressContent.propTypes = {
  company: PropTypes.shape({
    sections: PropTypes.shape({
      pjData: PropTypes.shape({
        data: PropTypes.shape({
          address: PropTypes.shape({
            street: PropTypes.string,
            number: PropTypes.string,
            neighborhood: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string,
            zipCode: PropTypes.string,
            complement: PropTypes.string,
          }),
          status: PropTypes.string,
        }),
      }),
    }),
  }),
  toPrint: PropTypes.bool,
  source: PropTypes.objectOf(),
};

AddressContent.defaultProps = {
  company: undefined,
  toPrint: false,
  source: null,
};

export default AddressContent;
