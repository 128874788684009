import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from 'hooks/auth';

export function checkCustomerCustomization(user, customer) {
  const userTenantId = user?.tenantId;

  if (
    [
      '7324cdd9-4c3d-4557-8cb6-650b1893fd73',
      'fb3d6bd4-7892-436a-b9b1-9f284389995e', // conta de teste (dev)
      'e8a13640-40be-4ac3-91a4-bbbc488dff61', // nova conta de teste (dev)
      'ed7d5fa4-776d-4240-b1d2-8219ed9b455d', // conta de teste (beta)
      'f8996922-8f20-4a6e-a176-f282bd3b6f3c', // conta de teste (prod)
    ].includes(userTenantId)
  ) {
    return true;
  }

  switch (customer?.toUpperCase()) {
    case 'SKY':
      return [
        'c94b990c-1eab-40ea-bbdf-684635bc5af2', // sky
        '2ba38e96-5eb2-4e23-ac60-8bfbcbe41ae6', // sky beta
        '7991fee7-cb5a-45d0-9e1c-f6c2b9007823', // sky beta
        'c25c21d5-7ac8-4490-a147-c09f68eba950', // PS dev
        '8441880f-7371-429a-9450-1d7640de4ed6', // PS beta
      ].includes(userTenantId);
    case 'ZOOP':
      return [
        '999687e1-ccfc-46e9-bb4e-03f869456ec7',
        'f9514663-5bfa-4f99-8b43-22a99ef4d5db',
      ].includes(userTenantId);
    default:
      return false;
  }
}

const CustomerCustomizationWrapper = ({ customer, extraCondition, children }) => {
  const { user } = useAuth();

  const showContent = useMemo(() => checkCustomerCustomization(user, customer), [
    user,
    customer,
  ]);

  return extraCondition && showContent ? children : <></>;
};

CustomerCustomizationWrapper.propTypes = {
  customer: PropTypes.string.isRequired,
  extraCondition: PropTypes.bool,
};

CustomerCustomizationWrapper.defaultProps = {
  extraCondition: true,
};

export default CustomerCustomizationWrapper;
