/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Divider } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import PageTitle from 'components/PageTitle';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import { handlerPendencies } from './utils/handlerPendencies';

import './styles.less';

const CadinSPItems = ({ data, toPrint }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const customTitle = useMemo(() => {
    return toPrint
      ? translate(
          'pages.private.executions.components.dataSourceCards.financialInformation.companies.components.cadinSP.cadinSPItems.index.customTitle.hasToPrint'
        )
      : translate(
          'pages.private.executions.components.dataSourceCards.financialInformation.companies.components.cadinSP.cadinSPItems.index.customTitle.hasNotToPrint'
        );
  }, [toPrint, translate]);

  return (
    <div id="executions-cadin-sp-items">
      <PageTitle
        title={customTitle}
        subtitle={`${
          data?.lastUpdate?.date
            ? `${translate(
                'pages.private.executions.components.dataSourceCards.financialInformation.companies.components.cadinSP.cadinSPItems.index.cadinSPItems.pageTitle.subtitle.lastUpdateDate'
              )} ${i18nFormatDate(data?.lastUpdate?.date, 'default')}`
            : `${translate(
                'pages.private.executions.components.dataSourceCards.financialInformation.companies.components.cadinSP.cadinSPItems.index.cadinSPItems.pageTitle.subtitle.default'
              )}`
        }`}
      />

      <Row className="cadin-sp-items-title reset-mrg">
        <strong>
          {handlerPendencies(data?.pendencies?.length, translate)?.title || '-'}
        </strong>
      </Row>

      <Divider className="custom-divider" />

      <Row className="cadin-sp-items-data reset-mrg">
        {data?.pendencies?.map((pendency) => (
          <ColumnLabel key={pendency} description={pendency?.entity || '-'} type="danger">
            {pendency?.quantityPendencies && (
              <span className="mrg-top-5">
                {handlerPendencies(pendency?.quantityPendencies)?.quantity}
              </span>
            )}

            {pendency?.dateCadinInsertion && (
              <span>
                {translate(
                  'pages.private.executions.components.dataSourceCards.financialInformation.companies.components.cadinSP.cadinSPItems.index.cadinSPItems.dateCadinInsertion'
                )}{' '}
                {i18nFormatDate(pendency?.dateCadinInsertion, 1)}
              </span>
            )}
          </ColumnLabel>
        ))}
      </Row>
    </div>
  );
};

export default CadinSPItems;
