import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useQueryParams, StringParam, withDefault, ArrayParam } from 'use-query-params';
import _ from 'lodash';
import OutsideClickHandler from 'react-outside-click-handler';
import { message, notification, Row, Menu, Dropdown } from 'antd';

import { useFetch } from 'services/hooks';
import { useProfileTemplate } from 'hooks/profileTemplate';

import { SortSelect } from 'components/List/components';
import { useComponentNextWithOffsetList } from 'components/List';

import PageTitle from 'components/PageTitle';
import Button from 'components/Button';

import PermissionWrapper from 'routes/PermissionWrapper';
import CustomerCustomizationWrapper from 'components/CustomerCustomizationWrapper';

import { UnderscoreObjectParam } from 'utils/customObjectParam';

import useModalCopyLink from 'components/ModalCopyLink';
// import useCreateNewRegister from 'components/ModalCreateNewRegister';
import useModalShareNewLink from './components/ModalShareNewLink';

import CompanyCard from './components/CompanyCard';
import PersonCard from './components/PersonCard';

import EmptyPage from './components/EmptyPage';

import './styles.less';

const ProfilesList = ({ matchPeople, matchCompanies }) => {
  const { t: translate } = useTranslation();
  const { get } = useFetch();

  const [maxTags, setMaxTags] = useState(2);
  const [width, setWidth] = useState(window.innerWidth);

  const {
    allProfileTemplates,
    loadProfileTemplates,
    loadingAllProfileTemplates,
  } = useProfileTemplate();

  const resizeListener = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeListener);
    if (width >= 1400) return setMaxTags(2);
    if (width >= 1200) return setMaxTags(1);
    return setMaxTags(0);
  }, [resizeListener, width]);

  useEffect(() => {
    loadProfileTemplates({ active: 'all' });
  }, [loadProfileTemplates]);

  const { openModal: openCopyLinkModal, CardCopyLinkModal } = useModalCopyLink();
  const { openModal: openShareNewLinkModal, ShareLinkModal } = useModalShareNewLink({
    openCopyLinkModal,
    type: matchPeople ? 'people' : 'companies',
  });

  const [isDropdownExportOpened, setIsDropdownExportOpened] = useState(false);
  const [exportStatus, setExportStatus] = useState({
    loading: false,
    error: false,
  });

  const { data, loading, componentList, updateParams } = useComponentNextWithOffsetList({
    component: matchPeople ? PersonCard : CompanyCard,
    rowKey: '_id',
    customModal: openShareNewLinkModal,
    maxTags,
    componentProps: {
      allProfileTemplates,
    },
    customLoader: loadingAllProfileTemplates,
  });

  // const {
  //   openModal: openPersonRegisterModal,
  //   CreateNewRegisterModal: PersonRegisterModal,
  // } = useCreateNewRegister({
  //   refreshList,
  //   type: translate('pages.private.profiles.components.list.index.type.person'),
  //   url: '/people',
  //   pageTitle: {
  //     title: translate(
  //       'components.modalProfiles.personRegister.index.personRegister.pageTitle.title'
  //     ),
  //     subtitle: translate(
  //       'components.modalProfiles.personRegister.index.personRegister.pageTitle.subtitle'
  //     ),
  //   },
  //   buttonsText: {
  //     loading: translate(
  //       'components.modalProfiles.personRegister.index.personRegister.form.button.loading'
  //     ),
  //     default: translate(
  //       'components.modalProfiles.personRegister.index.personRegister.form.button.default'
  //     ),
  //   },
  // });

  // const {
  //   openModal: openCompanyRegisterModal,
  //   CreateNewRegisterModal: CompanyRegisterModal,
  // } = useCreateNewRegister({
  //   refreshList,
  //   type: translate('pages.private.profiles.components.list.index.type.company'),
  //   url: '/companies',
  //   pageTitle: {
  //     title: translate(
  //       'components.modalProfiles.companyRegister.index.companyRegister.pageTitle.title'
  //     ),
  //     subtitle: translate(
  //       'components.modalProfiles.companyRegister.index.companyRegister.pageTitle.subtitle'
  //     ),
  //   },
  //   buttonsText: {
  //     loading: translate(
  //       'components.modalProfiles.companyRegister.index.companyRegister.form.button.loading'
  //     ),
  //     default: translate(
  //       'components.modalProfiles.companyRegister.index.companyRegister.form.button.default'
  //     ),
  //   },
  // });

  const [query] = useQueryParams({
    updatedDate: StringParam,
    tags: withDefault(ArrayParam, []),
    search: withDefault(ArrayParam, []),
    status: withDefault(ArrayParam, []),
    _order: StringParam,
    _sort: StringParam,
    _type: StringParam,
    _internalRestrictiveList: StringParam,
    profileTemplates: withDefault(UnderscoreObjectParam, {}),
  });
  const querySnapshot = useRef({});

  const mapFilters = useCallback(() => {
    const filters = {};

    if (query.updatedDate) {
      switch (query.updatedDate) {
        case 'last24Hours':
          filters._startUpdatedDate = new Date().getTime() - 24 * 60 * 60 * 1000;
          filters._endUpdatedDate = new Date().getTime();
          break;
        case 'last7Days':
          filters._startUpdatedDate = new Date().getTime() - 24 * 60 * 60 * 1000 * 7;
          filters._endUpdatedDate = new Date().getTime();
          break;
        case 'last30Days':
          filters._startUpdatedDate = new Date().getTime() - 24 * 60 * 60 * 1000 * 30;
          filters._endUpdatedDate = new Date().getTime();
          break;
        case 'lastYear':
          filters._startUpdatedDate = new Date().setFullYear(
            new Date().getFullYear() - 1
          );
          filters._endUpdatedDate = new Date().getTime();
          break;
        default:
          if (query.updatedDate.substr(0, 6) === 'custom') {
            const [startDate, endDate] = query.updatedDate.substr(7).split('-');
            filters._startUpdatedDate = startDate;
            filters._endUpdatedDate = endDate;
          }
          break;
      }
    }

    if (query.search && !_.isEmpty(query.search)) {
      filters._search = query.search.map((s) => s.replace(/[^0-9\w ]/g, ''))?.join(';');
    }

    if (query._sort && query._order) {
      filters._sort = query._sort;
      filters._order = query._order;
    }

    if (query.tags && !_.isEmpty(query.tags)) {
      filters._tags = query.tags.join(';');
    }

    if (query.status && !_.isEmpty(query.status)) {
      filters._status = query.status.join(';');
    }

    if (query._internalRestrictiveList) {
      filters._internalRestrictiveList = query._internalRestrictiveList;
    }

    if (query.profileTemplates && !_.isEmpty(query.profileTemplates)) {
      filters._profileTemplates = Object.keys(query.profileTemplates).join(';');
    }

    filters._cursor = true;

    return filters;
  }, [query]);

  useEffect(() => {
    // Não recarregar quando filtro de 'Intervalo personalizado' for selecionado (updatedDate === 'custom')
    const changeUpdatedDate =
      querySnapshot.current.updatedDate !== query.updatedDate &&
      query.updatedDate === 'custom';

    if (_.isEqual(querySnapshot.current, query)) return;
    querySnapshot.current = query;

    if (!changeUpdatedDate) {
      updateParams({
        url: matchPeople ? 'people' : 'companies',
        config: {
          params: { ...mapFilters() },
        },
      });
    }
  }, [query]); // eslint-disable-line

  const onExportList = useCallback(
    async ({ type }) => {
      try {
        setIsDropdownExportOpened(false);
        setExportStatus({ loading: true, error: false });

        let _exportType;

        if (type === 'processes') _exportType = 'processes';
        if (type === 'relationships') _exportType = 'restrictedPersonalRelationships';

        await get({
          url: `${matchPeople ? 'people' : 'companies'}/export`,
          config: {
            params: {
              ...mapFilters(),
              _exportType,
            },
          },
        });

        const notificationArgs = {
          type: 'success',
          message: translate(
            'pages.private.profiles.components.list.index.onExportList.notificationArgs.message'
          ),
          description: translate(
            'pages.private.profiles.components.list.index.onExportList.notificationArgs.description'
          ),
          duration: 4.5,
          className: 'custom-notification',
        };

        notification.open(notificationArgs);
        setExportStatus({ loading: false, error: false });
      } catch (error) {
        message.error(
          translate(
            'pages.private.profiles.components.list.index.onExportList.message.error'
          )
        );

        setIsDropdownExportOpened(false);
        setExportStatus({ loading: false, error: true });
      }
    },
    [get, mapFilters, matchPeople, translate]
  );

  const menuExport = useMemo(
    () => (
      <>
        <Menu className="menu-export-list">
          <Menu.Item key="csv_profiles">
            <button
              type="button"
              disabled={!data?.length > 0 || loading || loadingAllProfileTemplates}
              className="menu-export-list-btn reset-btn"
              onClick={() => onExportList({ type: 'profiles' })}
            >
              <i className="caf-ic_c_download" />
              <span>
                {translate(
                  'pages.private.profiles.components.list.index.menuExport.title.profile'
                )}
              </span>
            </button>
          </Menu.Item>
        </Menu>

        <Menu className="menu-export-list">
          <Menu.Item key="csv_legal_information">
            <button
              type="button"
              disabled={!data?.length > 0 || loading || loadingAllProfileTemplates}
              className="menu-export-list-btn reset-btn"
              onClick={() => onExportList({ type: 'processes' })}
            >
              <i className="caf-ic_c_download" />
              <span>
                {translate(
                  'pages.private.profiles.components.list.index.menuExport.title.processes'
                )}
              </span>
            </button>
          </Menu.Item>
        </Menu>

        <CustomerCustomizationWrapper customer="SKY" extraCondition={matchPeople}>
          <Menu className="menu-export-list">
            <Menu.Item key="csv_internal_restrictive_list">
              <button
                type="button"
                disabled={!data?.length > 0 || loading || loadingAllProfileTemplates}
                className="menu-export-list-btn reset-btn"
                onClick={() => onExportList({ type: 'relationships' })}
              >
                <i className="caf-ic_c_download" />
                <span>
                  {translate(
                    'pages.private.profiles.components.list.index.menuExport.title.restrictiveList'
                  )}
                </span>
              </button>
            </Menu.Item>
          </Menu>
        </CustomerCustomizationWrapper>
      </>
    ),
    [onExportList, data, loading, matchPeople, loadingAllProfileTemplates, translate]
  );

  return (
    <div id="profiles-list-component">
      {/* {CompanyRegisterModal} */}
      {/* {PersonRegisterModal} */}
      {ShareLinkModal}
      {CardCopyLinkModal}
      <Row align="middle" justify="space-between" className="no-mrg">
        <PageTitle
          title={translate(
            `pages.private.profiles.components.list.index.list.pageTitle.title.${
              matchPeople ? 'person' : 'company'
            }`
          )}
          subtitle={translate(
            'pages.private.profiles.components.list.index.list.pageTitle.subtitle'
          )}
        />
        <div className="flex mrg-btm-15">
          {data?.totalItems > 0 && (
            <SortSelect
              className="mrg-right-5"
              matchPeople={matchPeople}
              matchCompanies={matchCompanies}
            />
          )}

          <PermissionWrapper
            requiredPermissions={matchPeople ? ['people:create'] : ['companies:create']}
          >
            {/* <Button
              type="primary"
              outlined
              className="flex center"
              onClick={matchPeople ? openPersonRegisterModal : openCompanyRegisterModal}
            >
              <i className="caf-ic_plus font-size-14 pdd-right-5" />
              {translate(
                'pages.private.profiles.components.list.index.list.button.new.default'
              )}{' '}
              {translate(
                `pages.private.profiles.components.list.index.list.button.new.${
                  matchPeople ? 'person' : 'company'
                }`
              )}
            </Button> */}

            <OutsideClickHandler
              onOutsideClick={() => setIsDropdownExportOpened(false)}
              disabled={!isDropdownExportOpened}
            >
              <Dropdown
                overlay={menuExport}
                trigger={['click']}
                visible={isDropdownExportOpened}
                placement="bottomRight"
              >
                <Button
                  type="default"
                  shape="circle"
                  loading={exportStatus.loading}
                  className={`export-list-btn ${exportStatus.error && 'danger'}`}
                  onClick={() => setIsDropdownExportOpened((oldState) => !oldState)}
                >
                  {!exportStatus.loading && <i className="caf-ic_c_download" />}
                </Button>
              </Dropdown>
            </OutsideClickHandler>
          </PermissionWrapper>
        </div>
      </Row>

      {data?.totalItems === 0 ? <EmptyPage /> : componentList}
    </div>
  );
};

ProfilesList.propTypes = {
  matchPeople: PropTypes.bool.isRequired,
  matchCompanies: PropTypes.bool.isRequired,
};

export default ProfilesList;
