import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'antd';

const pjDataTranslation = {
  basicData:
    'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pjDataTranslation.basicData',
  address:
    'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pjDataTranslation.address',
  QSA:
    'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pjDataTranslation.QSA',
  justice: {
    processes:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pjDataTranslation.justice.processes',
  },
  finance: {
    spc:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pjDataTranslation.finance.spc',
    protest:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pjDataTranslation.finance.protest',
  },
  certificates: {
    cndt:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pjDataTranslation.certificates.cndt',
    pgfn:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pjDataTranslation.certificates.pgfn',
    sintegra:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pjDataTranslation.certificates.sintegra',
    cadinSp:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pjDataTranslation.certificates.cadinSp',
    fgts:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pjDataTranslation.certificates.fgts',
  },
  others: {
    kycCompliance:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pjDataTranslation.others.kycCompliance',
    kycComplianceOwners:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pjDataTranslation.others.kycComplianceOwners',
  },
};

const pfDataTranslation = {
  basicData:
    'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.basicData',
  addresses:
    'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.addresses',
  driver: {
    detran:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.driver.detran',
    antt:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.driver.antt',
  },
  finance: {
    socialAssistance:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.finance.socialAssistance',
    financialData:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.finance.financialData',
    spcIncome:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.finance.spcIncome',
    spc:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.finance.spc',
    protest:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.finance.protest',
  },
  justice: {
    processes:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.justice.processes',
  },
  work: {
    classOrganization:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.work.classOrganization',
    history:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.work.history',
    civilServant:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.work.civilServant',
    rais:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.work.rais',
    unemploymentInsurance:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.work.unemploymentInsurance',
    mei:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.work.mei',
  },
  contact: {
    emails:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.contact.emails',
    phones:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.contact.phones',
  },
  relationships: {
    peoplePhones:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.relationships.peoplePhones',
    business:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.relationships.business',
    personal:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.relationships.personal',
    peopleOccurrencesIRL:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.relationships.peopleOccurrencesIRL',
  },
  criminal: {
    stateCriminalBackground:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.criminal.stateCriminalBackground',
    federalCriminalBackground:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.criminal.federalCriminalBackground',
  },
  certificates: {
    pgfn:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.certificates.pgfn',
    nadaConsta:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.certificates.nadaConsta',
    jointDebts:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.certificates.jointDebts',
  },
  others: {
    domains:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.others.domains',
    kycCompliance:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.others.kycCompliance',
    mediaExposure:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.others.mediaExposure',
    electoralSituation:
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.pfDataTranslation.others.electoralSituation',
  },
};

const CardNewExecution = ({ data, children, type }) => {
  const { t: translate } = useTranslation();

  const updatedData = useMemo(() => {
    const dataTranslation =
      type ===
      translate(
        'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.type.person'
      )
        ? pfDataTranslation
        : pjDataTranslation;
    const keys = Object.keys(data || {});

    const arr = keys.map((k) => {
      if (typeof dataTranslation[k] === 'string') return translate(dataTranslation[k]);
      if (typeof dataTranslation[k] === 'object') {
        return Object.keys(data[k]).map((k2) => {
          if (typeof dataTranslation[k][k2] === 'string')
            return translate(dataTranslation[k][k2]);
          if (typeof dataTranslation[k][k2] === 'object') {
            return Object.keys(dataTranslation[k][k2]).map((k3) =>
              translate(dataTranslation[k][k2][k3])
            );
          }

          return undefined;
        });
      }
      return undefined;
    });

    return arr.length
      ? `${arr
          .flat()
          .filter((d) => d)
          .join(', ')}.`
      : translate(
          'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.updatedData.default'
        );
  }, [data, type, translate]);

  return (
    <Card className="card-new-activity">
      <div className="content">
        <Row className="card-new-activity-header">
          <Col span={24}>
            <span className="title">
              {translate(
                'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.cardNewData.title'
              )}
            </span>
            <strong className="subtitle">
              {translate(
                'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewData.cardNewData.subtitle'
              )}
            </strong>
          </Col>
        </Row>

        <Row>
          <Col span={24}>{updatedData}</Col>
        </Row>
      </div>

      {children}
    </Card>
  );
};

CardNewExecution.propTypes = {
  data: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
};

export default CardNewExecution;
