import { useCallback, useState } from 'react';
import { useFetch } from 'services/hooks';

const useSavedRecords = ({ transactionId }) => {
  const { get, loading: loadingSavedRecords } = useFetch();
  const [savedRecords, setSavedRecords] = useState([]);

  const loadSavedRecords = useCallback(async () => {
    const response = await get({
      url: `/executions/${transactionId}/transaction-records?type=COMPANY_RECORD`,
    });

    setSavedRecords(response.records || []);
  }, [get, transactionId]);

  return {
    loadSavedRecords,
    loadingSavedRecords,
    savedRecords,
  };
};

export default useSavedRecords;
