import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col, Collapse, Divider } from 'antd';

import EmptyExecutionState from 'components/EmptyExecutionState';

import { useI18nFormatters } from 'hooks/i18nFormatters';
import DottedLabel from 'components/DottedLabel';

import DataSourceCardCollapsePanel from '../../../../../DataSourceCardCollapsePanel';

const { Panel } = Collapse;

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.companies.components.spcReportParticipationScore.QSAInfo';

const QSAInfoContent = ({ source, toPrint }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () => statusCode === '01' || source?.available || source.lastConsultation,
    [statusCode, source]
  );
  const companyParticipation = useMemo(() => parsedData?.companyParticipation || {}, [
    parsedData,
  ]);

  const partner = useMemo(() => parsedData?.partner || {}, [parsedData]);
  const manager = useMemo(() => parsedData?.manager || {}, [parsedData]);

  return queryWorked ? (
    <DataSourceCardCollapsePanel
      customId="report-spc-last-phone-card"
      icon="caf-ic_info"
      title={translate(`${I18N_BASE_PATH}.title`)}
      toPrint={toPrint}
    >
      <Row className="mrg-top-10">
        <>
          <Col span={24}>
            {/* PARTICIPAÇÃO EM EMPRESAS */}
            <Collapse defaultActiveKey={toPrint ? ['1'] : null}>
              <Panel
                key="1"
                disabled={!companyParticipation?.details?.length}
                header={
                  <span className="text-dark text-bold">
                    {translate(
                      `${I18N_BASE_PATH}.information.companyParticipation.title`
                    )}
                  </span>
                }
                extra={
                  <DottedLabel
                    description={`(${companyParticipation?.total || 0}) Registros`}
                    type={+companyParticipation?.total > 0 ? 'info' : 'primary'}
                  />
                }
              >
                {companyParticipation?.details?.map((register, index) => (
                  <>
                    <Col span={24}>
                      <Row align="middle">
                        <span className="text-dark text-bold">
                          {register?.name || '-'}
                        </span>
                      </Row>
                      <Row align="middle" className="mrg-top-10" justify="space-between">
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.information.companyParticipation.documentNumber`
                            )}
                            :
                          </span>{' '}
                          <span>{register?.document || '-'}</span>
                        </Col>
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.information.companyParticipation.documentSituation`
                            )}
                            :
                          </span>{' '}
                          <span>{register?.documentSituation || '-'}</span>
                        </Col>
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.information.companyParticipation.relationshipType`
                            )}
                            :
                          </span>{' '}
                          <span>{register?.relationshipType || '-'}</span>
                        </Col>
                      </Row>
                      <Row align="middle" className="mrg-top-5">
                        <span className="collapse-label">
                          {translate(
                            `${I18N_BASE_PATH}.information.companyParticipation.lastUpdate`
                          )}
                          :
                        </span>
                        <span className="mrg-left-5">
                          {i18nFormatDate(register?.lastUpdate, 1) || '-'}
                        </span>
                      </Row>
                    </Col>
                    {index < companyParticipation?.details?.length - 1 && <Divider />}
                  </>
                ))}
              </Panel>
            </Collapse>

            {/* SÓCIOS */}
            <Collapse className="mrg-top-10" defaultActiveKey={toPrint ? ['1'] : null}>
              <Panel
                key="1"
                disabled={!partner?.details?.length}
                header={
                  <span className="text-dark text-bold">
                    {translate(`${I18N_BASE_PATH}.information.partners.title`)}
                  </span>
                }
                extra={
                  <DottedLabel
                    description={`(${partner?.total || 0}) Registros`}
                    type={+partner?.total > 0 ? 'info' : 'primary'}
                  />
                }
              >
                {partner?.details?.map((register, index) => (
                  <>
                    <Col span={24}>
                      <Row align="middle">
                        <span className="text-dark text-bold">
                          {register?.participationPercentage
                            ? `(${register?.participationPercentage}%) - `
                            : ''}
                          {register?.name || '-'}
                          {register?.nationality ? ` - ${register?.nationality}` : ''}
                        </span>
                      </Row>
                      <Row align="middle" className="mrg-top-10" justify="space-between">
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.information.partners.documentNumber`
                            )}
                            :
                          </span>{' '}
                          <span>{register?.document || '-'}</span>
                        </Col>
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.information.partners.documentSituation`
                            )}
                            :
                          </span>{' '}
                          <span>{register?.documentSituation || '-'}</span>
                        </Col>
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.information.partners.relationshipType`
                            )}
                            :
                          </span>{' '}
                          <span>{register?.relationshipType || '-'}</span>
                        </Col>
                      </Row>
                      <Row align="middle" className="mrg-top-5">
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.information.partners.startDate`
                            )}
                            :
                          </span>
                          <span className="mrg-left-5">
                            {i18nFormatDate(register?.startDate, 1) || '-'}
                          </span>
                        </Col>
                        <Col span={12}>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.information.partners.lastUpdate`
                            )}
                            :
                          </span>
                          <span className="mrg-left-5">
                            {i18nFormatDate(register?.lastUpdate, 1) || '-'}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    {index < partner?.details?.length - 1 && <Divider />}
                  </>
                ))}
              </Panel>
            </Collapse>

            {/* ADMINISTRADORES */}
            <Collapse className="mrg-top-10" defaultActiveKey={toPrint ? ['1'] : null}>
              <Panel
                key="1"
                disabled={!manager?.details?.length}
                header={
                  <span className="text-dark text-bold">
                    {translate(`${I18N_BASE_PATH}.information.managers.title`)}
                  </span>
                }
                extra={
                  <DottedLabel
                    description={`(${manager?.total || 0}) Registros`}
                    type={+manager?.total > 0 ? 'info' : 'primary'}
                  />
                }
              >
                {manager?.details?.map((register, index) => (
                  <>
                    <Col span={24}>
                      <Row align="middle">
                        <span className="text-dark text-bold">
                          {register?.position ? `(${register?.position}) - ` : ''}
                          {register?.name || '-'}
                          {register?.nationality ? ` - ${register?.nationality}` : ''}
                        </span>
                      </Row>
                      <Row align="middle" className="mrg-top-10">
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.information.managers.documentNumber`
                            )}
                            :
                          </span>{' '}
                          <span>{register?.document || '-'}</span>
                        </Col>
                        <Col span={12}>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.information.managers.relationshipType`
                            )}
                            :
                          </span>{' '}
                          <span>{register?.relationshipType || '-'}</span>
                        </Col>
                      </Row>
                      <Row align="middle" className="mrg-top-5" justify="space-between">
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.information.managers.startDate`
                            )}
                            :
                          </span>
                          <span className="mrg-left-5">
                            {i18nFormatDate(register?.startDate, 1) || '-'}
                          </span>
                        </Col>
                        <Col>
                          <span className="collapse-label">
                            {translate(
                              `${I18N_BASE_PATH}.information.managers.lastUpdate`
                            )}
                            :
                          </span>
                          <span className="mrg-left-5">
                            {i18nFormatDate(register?.lastUpdate, 1) || '-'}
                          </span>
                        </Col>
                        <Col>
                          <span className="collapse-label">
                            {translate(`${I18N_BASE_PATH}.information.managers.exitDate`)}
                            :
                          </span>
                          <span className="mrg-left-5">
                            {i18nFormatDate(register?.exitDate, 1) || '-'}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    {index < manager?.details?.length - 1 && <Divider />}
                  </>
                ))}
              </Panel>
            </Collapse>
          </Col>
        </>
      </Row>
    </DataSourceCardCollapsePanel>
  ) : (
    <DataSourceCardCollapsePanel
      customId="report-spc-last-phone-card"
      icon="caf-ic_info"
      title={translate(`${I18N_BASE_PATH}.title`)}
      toPrint={toPrint}
    >
      <Row gutter={24}>
        <Col span={24}>
          <EmptyExecutionState
            statusCode={statusCode}
            message={source?.message || parsedData?.message}
          />
        </Col>
      </Row>
    </DataSourceCardCollapsePanel>
  );
};

QSAInfoContent.propTypes = {
  person: PropTypes.shape({
    basicData: PropTypes.shape({
      phoneNumber: PropTypes.string,
      companyEmail: PropTypes.string,
    }),
    contact: PropTypes.shape({
      phones: PropTypes.shape({
        lastConsultation: PropTypes.objectOf(),
      }),
      emails: PropTypes.shape({
        lastConsultation: PropTypes.objectOf(),
      }),
    }),
    status: PropTypes.string,
  }),
  source: PropTypes.objectOf(),
  toPrint: PropTypes.bool,
};

QSAInfoContent.defaultProps = {
  person: undefined,
  source: null,
  toPrint: false,
};

export default QSAInfoContent;
