import React from 'react';
import { useTranslation } from 'react-i18next';
import AlertOrange from 'assets/images/alert-orange.svg';
import { Company, ArrowRight, Glasses } from '@combateafraude/icons/general';
import { Person } from '@combateafraude/icons/users';
import NotAvailable from 'components/NotAvailable';
import {
  KybRiskFactorList,
  KybRiskFactorPopover,
  ExplorePopover,
} from 'pages/private/KYBCompany/CompanyDetailsV2/components';

import './styles.less';
import { getFieldSharePercentage } from 'utils/kybExploreTree';

const RelationshipArrow = () => {
  return (
    <div className="kyb-relationship-list-arrow">
      <ArrowRight className="kyb-relationship-list-icon" />
    </div>
  );
};

export const RelationshipType = ({ entityType, ...rest }) => {
  if (entityType === 'company') {
    return (
      <div className="kyb-relationship-list-type" {...rest}>
        <Company className="kyb-relationship-list-icon" />
      </div>
    );
  }
  if (entityType === 'person') {
    return (
      <div className="kyb-relationship-list-type" {...rest}>
        <Person className="kyb-relationship-list-icon" />
      </div>
    );
  }
  if (entityType === 'intellectual_property') {
    return (
      <div className="kyb-relationship-list-type" {...rest}>
        <Glasses className="kyb-relationship-list-icon" />
      </div>
    );
  }
  return null;
};

const RelationshipField = ({ field, hint }) => {
  const { t } = useTranslation('kyb-business-identity-relationship');

  if (!field) {
    return null;
  }

  return (
    <>
      <RelationshipArrow />
      <div className="kyb-relationship-list-field">
        <span className="field">{t(field)}</span>
        <span className="hint">{hint}</span>
      </div>
    </>
  );
};

const RelationshipRiskFactorPopover = ({ entity, risks }) => {
  if (!risks?.length) return null;

  const content = entity.risk ? (
    <img src={AlertOrange} className="kyb-relationship-risk-img" alt="alert" />
  ) : null;

  return (
    <KybRiskFactorPopover className="kyb-relationship-risk" content={content}>
      <KybRiskFactorList risks={entity.risk} />
    </KybRiskFactorPopover>
  );
};

const RelationshipPath = ({ payload, index }) => {
  const entity = payload?.entity;
  const sharePercentage = getFieldSharePercentage(payload);
  const hint = sharePercentage ? `(${sharePercentage}%)` : '';

  return (
    <div className="kyb-relationship-list-path">
      <RelationshipField field={payload.field} hint={hint} />
      <RelationshipArrow />
      <RelationshipType entityType={entity.entityType} />
      <div className="kyb-relationship-list-info">
        <ExplorePopover entity={entity} key={index}>
          {entity.name}
        </ExplorePopover>
        <RelationshipRiskFactorPopover entity={entity} risks={entity.risk} />
      </div>
    </div>
  );
};

const RelationshipListItem = ({ payload, entityType }) => {
  return (
    <div className="kyb-relationship-list-item">
      <div className="kyb-relationship-list-inner">
        <RelationshipType entityType={entityType || 'company'} />
        {payload?.path?.map((item, index) => (
          <RelationshipPath key={item.entity.id} payload={item} index={index} />
        ))}
      </div>
    </div>
  );
};

const CompanyBusinessIdentityRelationshipList = ({ payload, entityType }) => {
  if (!payload?.length) {
    return <NotAvailable card />;
  }

  return (
    <div className="kyb-relationship-list">
      {payload?.map((item) => (
        <RelationshipListItem
          key={item.target.id}
          payload={item}
          entityType={entityType}
        />
      ))}
    </div>
  );
};

export default CompanyBusinessIdentityRelationshipList;
