import React, { useCallback, useMemo, useRef } from 'react';
import { Col, Form, Row, Spin } from 'antd';
import { StringParam, useQueryParams } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Button from 'components/Button';
import { useComponentList } from 'components/List';
import SearchInput from 'components/Form/SearchInput';

import useModalReasonUpdate from '../Modals/ModalReasonUpdate';
import ReasonCard from '../ReasonCard';

const I18N_BASE_PATH = 'pages.private.tagsAndReasons.components.ReasonsList';

function ReasonsList({ type }) {
  const { t: translate } = useTranslation();
  const refreshListRef = useRef();
  const { openModal: openRegisterModal, ReasonUpdateModal } = useModalReasonUpdate();

  const [query] = useQueryParams({
    _search: StringParam,
  });

  const { componentList, loading, refreshList, setFilterData } = useComponentList({
    component: ReasonCard,
    rowKey: '_id',
    getParams: {
      url: '/reasons',
      config: {
        params: {
          type,
        },
      },
    },
    queryParams: {
      _search: StringParam,
    },
  });

  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        ...values,
        _search: values._search ? values._search.trim() : undefined,
      });
    }, 500),
    []
  );

  useMemo(() => {
    if (refreshList) refreshListRef.current = refreshList;
  }, [refreshList]);

  return (
    <>
      {ReasonUpdateModal}
      <Row align="middle" justify="end">
        <Col className="flex end flex-1">
          <Form
            layout="inline"
            onValuesChange={handleFormChange}
            initialValues={{ _search: query?._search }}
          >
            <SearchInput
              name="_search"
              placeholder={translate(`${I18N_BASE_PATH}.searchInputPlaceholder`)}
              style={{ minWidth: 350 }}
            />
          </Form>
        </Col>
        <Col span={3}>
          <Button
            className="flex center"
            onClick={() =>
              openRegisterModal({
                data: {},
                refreshList: refreshListRef,
                type,
                isNew: true,
              })
            }
          >
            <span>{translate(`${I18N_BASE_PATH}.createButtonLabel`)}</span>
            <i className="caf-ic_add mrg-left-10" />
          </Button>
        </Col>
      </Row>
      {loading ? (
        <Row align="middle" justify="center">
          <Spin />
        </Row>
      ) : (
        <div>{componentList}</div>
      )}
    </>
  );
}

ReasonsList.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ReasonsList;
