const filterValidationsByServices = (validations, services) => {
  if (!validations || validations?.length === 0 || !services || services?.length === 0)
    return [];

  // Filtra as validações pertinentes às fontes

  /*
  const relatedValidations = templateServicesList
    ?.filter(({ source }) => services?.some((service) => service?.source === source)) // pega todos os dados dos customData/services desejados
    ?.map(({ relatedRules }) => relatedRules) // extrai todas as relatedRules
    ?.filter((r) => r) // tira os objetos undefined
    ?.flat() // concatena todos os sub-arrays de forma recursiva
    ?.filter(({ rule }) => validations.map((v) => v?.rule)?.includes(rule)) // verifica se alguma validation do template coincide com a validation do customData
    ?.map(({ rule }) => validations.find((v) => v?.rule === rule)) // pega os dados da validation salvos no modelo
    ?.map(({ rule, ...rest }) => ({ code: rule, ...rest })); // mapeia as validations para transactions
*/

  const relatedRules = services
    ?.map((service) => service?.relatedRules)
    ?.filter((r) => r)
    ?.flat();

  if (!relatedRules) return false;

  const validationsFull = validations
    ?.map((v) => {
      const ruleFull = relatedRules?.find(({ rule }) => rule === v?.rule);

      return {
        rule: ruleFull?.rule,
        title: ruleFull?.title,
        actionWhenInvalid: v?.actionWhenInvalid,
        customActionWhenInvalid: v?.customActionWhenInvalid,
      };
    })
    ?.filter(
      (validation) =>
        validation?.rule && validation?.title && validation?.actionWhenInvalid
    );

  return validationsFull;
};

export default filterValidationsByServices;
