import React, { createContext, useCallback, useContext, useState } from 'react';

import { useFetch } from 'services/hooks';

const CustomStatusContext = createContext({});

const CustomStatusProvider = ({ children }) => {
  const { get } = useFetch();

  const [allCustomStatusList, setAllCustomStatusList] = useState([]);

  const [loadingAllCustomStatus, setLoadingAllCustomStatus] = useState(false);

  const loadAllCustomStatus = useCallback(async () => {
    try {
      setLoadingAllCustomStatus(true);

      const response = await get({
        url: '/custom-status',
        config: {},
        showMessage: false,
      });

      setAllCustomStatusList(response?.docs);
      setLoadingAllCustomStatus(false);

      return Promise.resolve(response?.docs);
    } catch (error) {
      setLoadingAllCustomStatus(false);
      return Promise.reject(error);
    }
  }, [get]);
  return (
    <CustomStatusContext.Provider
      value={{
        allCustomStatusList,
        loadAllCustomStatus,
        loadingAllCustomStatus,
      }}
    >
      {children}
    </CustomStatusContext.Provider>
  );
};

function useCustomStatus() {
  const context = useContext(CustomStatusContext);
  if (!context) {
    throw new Error('useCustomStatus must be used within an CustomStatusContext');
  }

  return context;
}

export { CustomStatusProvider, useCustomStatus };
