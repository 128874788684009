import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col, Collapse } from 'antd';

import { validationStatus } from 'utils/handlerStatus';

const { Panel } = Collapse;

const ProfileRulesList = ({ validations }) => {
  const { t: translate } = useTranslation();

  return (
    <div id="profile-rules">
      <div className="rules">
        {validations?.map((value) => (
          <Panel
            className="items"
            key={translate(`profileValidations.${value?.rule}.title`, value?.title)}
            header={
              <Row
                key={translate(`profileValidations.${value?.rule}.title`, value?.title)}
                align="middle"
                justify="center"
                className="compliance-rules-status reset-mrg"
              >
                <Col span={8} className="reset-mrg no-pdd">
                  <span>
                    {translate(`profileValidations.${value?.rule}.title`, value?.title)}
                  </span>
                </Col>
                <Col span={1}>
                  <i
                    style={{
                      color: validationStatus(value?.status).color,
                    }}
                    className={`status-icon ${validationStatus(value?.status).icon}`}
                  />
                </Col>
                <Col span={15}>
                  <span>
                    {translate(
                      `profileValidations.${value?.rule}.description`,
                      value?.description
                    )}
                  </span>
                </Col>
              </Row>
            }
          />
        ))}
      </div>
    </div>
  );
};

ProfileRulesList.propTypes = {
  validations: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      description: PropTypes.string,
      title: PropTypes.string,
      rule: PropTypes.string,
    })
  ).isRequired,
};

export default ProfileRulesList;
