import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useI18nFormatters } from 'hooks/i18nFormatters';

import { Col, Collapse, Divider, Row } from 'antd';

import CircleIcon from 'components/Icon/CircleIcon';

import './styles.less';

const { Panel } = Collapse;
const I18N_BASE_PATH = `pages.private.profiles.components.tabs.components.basicInfos.components.person.addressContent.components.addressItem.index`;

const AddressItem = ({ data: address }) => {
  const { addressMain, number, complement, neighborhood, city, state, zipCode } =
    address || {};

  return (
    <div>
      <div id="address-item-execution-container">
        <CircleIcon icon="caf-ic_map_pin" />
        <section className="flex space-between address-item-execution-information">
          <span>
            {addressMain && `${addressMain},`} {number && `${number},`}{' '}
            {complement && neighborhood ? `${complement} -` : `${complement || ''}`}{' '}
            {neighborhood || ''} <br />
            {city && state ? `${city} /` : `${city || ''}`} {state && `${state},`}{' '}
            {zipCode || ''}
          </span>
        </section>
      </div>
    </div>
  );
};

AddressItem.propTypes = {
  data: PropTypes.shape({
    addressMain: PropTypes.string,
    number: PropTypes.string,
    complement: PropTypes.string,
    neighborhood: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

export default AddressItem;
