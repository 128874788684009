/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import ViewMore from 'components/ViewMore';
import EmptyState from 'components/EmptyState';

import ContactAndRelationCard from 'pages/private/Profiles/components/ContactAndRelationCard';
import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import useAllPersonalAndEconomicRelationships from './AllPersonalAndEconomicRelationships';

const PersonalAndEconomicRelationships = ({ profile, toPrint /* urlId */ }) => {
  const { t: translate } = useTranslation();

  const businnesAndPersonalData = useMemo(() => {
    const business = profile?.relationships?.business;
    const personal = profile?.relationships?.personal;

    if (!business && !personal) return null;

    if (business?.items?.length > 0) {
      if (personal?.items?.length > 0) {
        return business?.items?.concat(personal?.items);
      }
      return business?.items;
    }
    if (personal?.items?.length > 0) {
      if (business?.items?.length > 0) {
        return personal?.items?.concat(business?.items);
      }
      return personal?.items;
    }
    return [];
  }, [profile]);

  const {
    openModal,
    AllPersonalAndEconomicRelationshipsModal,
  } = useAllPersonalAndEconomicRelationships({ data: businnesAndPersonalData });

  const isProcessing = useMemo(
    () => verifySourcesProcessing(profile, 'businnesAndPersonalRelationships'),
    [profile]
  );

  const parsedList = useMemo(() => {
    if (!businnesAndPersonalData || !businnesAndPersonalData?.length > 0) return <></>;

    const el = [];
    const _size = businnesAndPersonalData?.length;

    if (_size) {
      for (let i = 0; i < _size; i += 2) {
        el.push(
          <Row className="contact-data-row">
            {businnesAndPersonalData?.slice(i, i + 2)?.map((relationship) => (
              <ContactAndRelationCard
                key={relationship?.entityName || relationship?.relatedEntityName}
                span={12}
                relation
                name={relationship?.entityName || relationship?.relatedEntityName}
                type={relationship?.relationshipType}
                level={relationship?.relationshipLevel}
                className="mrg-top-10 pdd-horizontal-0"
              />
            ))}
          </Row>
        );
      }
    }

    if (el.length > 0) {
      const parsedEl = toPrint ? el : el.splice(0, 1);
      return parsedEl;
    }

    return <></>;
  }, [businnesAndPersonalData, toPrint]);

  return (
    <>
      {AllPersonalAndEconomicRelationshipsModal}

      <Row className="contact-data-container">
        <ColumnLabel
          title={translate(
            'pages.private.profiles.components.dataSourceCards.relationships.components.personalAndEconomicRelationships.index.personalAndEconomicRelationships.columnLabel.title'
          )}
          span={24}
        >
          {!businnesAndPersonalData && isProcessing && <EmptyState type="processing" />}

          {!businnesAndPersonalData && !isProcessing && (
            <EmptyState type="waiting_consult" />
          )}

          {businnesAndPersonalData && !businnesAndPersonalData?.length > 0 && (
            <EmptyState
              type="empty-success"
              description={translate(
                'pages.private.profiles.components.dataSourceCards.relationships.components.personalAndEconomicRelationships.index.personalAndEconomicRelationships.emptyState.description'
              )}
            />
          )}

          {parsedList}
        </ColumnLabel>
        {/* </Col> */}
      </Row>

      {!toPrint && businnesAndPersonalData?.length > 2 && (
        <Row className="mrg-btm-10">
          <ViewMore
            title={translate(
              'pages.private.profiles.components.dataSourceCards.relationships.components.personalAndEconomicRelationships.index.personalAndEconomicRelationships.viewMore.title'
            )}
            type="link"
            onClick={() => openModal()}
          />
        </Row>
      )}
    </>
  );
};

export default PersonalAndEconomicRelationships;
