import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';

import moment from 'moment';
import { useQueryParam, withDefault, StringParam } from 'use-query-params';

const HeaderFilterContext = createContext({});

export const headerFilters = {
  today: 'today',
  month: 'month',
  fortnight: 'fortnight',
  quarter: 'quarter',
  semester: 'semester',
  custom: 'custom',
};

const HeaderFilterProvider = ({ children }) => {
  const [periodFilter, setPeriodFilter] = useQueryParam(
    'period',
    withDefault(StringParam, headerFilters.fortnight)
  );
  const [periodFilterTime, setPeriodFilterTime] = useState();
  const [previousFilterTime, setPreviousFilterTime] = useState();

  const pfdPrintRef = useRef();

  const changeFilterPeriodTime = useCallback((startDate, endDate) => {
    setPeriodFilterTime({
      startDate,
      endDate,
    });
  }, []);

  const changeFilterPeriod = (optionSelected) => {
    setPeriodFilter(headerFilters[optionSelected]);
  };

  useEffect(() => {
    let startDate = moment();
    let endDate = moment();

    let previousStartDate = moment();
    let previousEndDate = moment();

    switch (periodFilter) {
      case headerFilters.today:
        startDate = startDate.hours(0).minutes(0).seconds(0).milliseconds(0);

        previousStartDate = previousStartDate
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .subtract(1, 'days');
        previousEndDate = previousEndDate.subtract(1, 'days');

        break;
      case headerFilters.fortnight:
        startDate = startDate
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .subtract(14, 'days');

        previousEndDate = previousEndDate.subtract(15, 'days');
        previousStartDate = previousStartDate
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .subtract(29, 'days');

        break;

      case headerFilters.month:
        startDate = startDate.date(1).hours(0).minutes(0).seconds(0).milliseconds(0);

        previousStartDate = previousStartDate
          .date(1)
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .subtract(1, 'months');
        previousEndDate = previousEndDate.subtract(1, 'months').endOf('month');

        break;

      case headerFilters.quarter:
        startDate = startDate.quarter(moment().quarter()).startOf('quarter');
        endDate = endDate.quarter(moment().quarter()).endOf('quarter');

        previousStartDate = previousStartDate
          .quarter(moment().quarter())
          .subtract(1, 'quarters')
          .startOf('quarter');
        previousEndDate = previousEndDate
          .quarter(moment().quarter())
          .subtract(1, 'quarters')
          .endOf('quarter');

        break;

      case headerFilters.semester:
        if (startDate.month() <= 5) {
          startDate = startDate.month(0).startOf('month');
          endDate = endDate.month(5).endOf('month');

          previousStartDate = previousStartDate
            .subtract(1, 'years')
            .month(6)
            .startOf('month');
          previousEndDate = previousEndDate.subtract(1, 'years').month(11).endOf('month');
        } else {
          startDate = startDate.month(6).startOf('month');
          endDate = endDate.month(11).endOf('month');

          previousStartDate = previousStartDate.month(0).startOf('month');
          previousEndDate = previousEndDate.month(5).endOf('month');
        }

        break;

      default:
    }

    if (periodFilter !== headerFilters.custom) {
      changeFilterPeriodTime(startDate, endDate);
      setPreviousFilterTime({
        startDate: previousStartDate,
        endDate: previousEndDate,
      });
    } else {
      setPreviousFilterTime(null);
    }
  }, [changeFilterPeriodTime, periodFilter]);

  const normalizeDate = useCallback((date) => {
    if (date) {
      return moment(date).utc().format('YYYY-MM-DD HH:mm');
    }
    return null;
  }, []);

  const startDate = useMemo(() => normalizeDate(periodFilterTime?.startDate), [
    normalizeDate,
    periodFilterTime,
  ]);
  const endDate = useMemo(() => normalizeDate(periodFilterTime?.endDate), [
    normalizeDate,
    periodFilterTime,
  ]);

  const previousStartDate = useMemo(() => normalizeDate(previousFilterTime?.startDate), [
    normalizeDate,
    previousFilterTime,
  ]);
  const previousEndDate = useMemo(() => normalizeDate(previousFilterTime?.endDate), [
    normalizeDate,
    previousFilterTime,
  ]);

  return (
    <HeaderFilterContext.Provider
      value={{
        previousFilterTime,
        changeFilterPeriod,
        changeFilterPeriodTime,
        periodFilter,
        periodFilterTime,
        pfdPrintRef,
        startDate,
        endDate,
        previousStartDate,
        previousEndDate,
      }}
    >
      {children}
    </HeaderFilterContext.Provider>
  );
};

function useHeaderFilter() {
  const context = useContext(HeaderFilterContext);

  if (!context) {
    throw new Error('useHeaderFilter must be used within an HeaderFilterContext');
  }

  return context;
}

export { HeaderFilterProvider, useHeaderFilter };
