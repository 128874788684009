import { capitalizeFirstLetter } from './capitalizeFirstLetter';

const renderIncomeEstimate = (income, translate) => {
  if (!income)
    return translate('utils.financeFormatters.renderIncomeEstimate.hasNotIncome');

  switch (income?.toUpperCase()) {
    case translate('utils.financeFormatters.renderIncomeEstimate.0.5_A_1_SM.text'):
      return translate('utils.financeFormatters.renderIncomeEstimate.0.5_A_1_SM.value');
    case translate('utils.financeFormatters.renderIncomeEstimate.1_1.5_SM.text'):
      return translate('utils.financeFormatters.renderIncomeEstimate.1_1.5_SM.value');
    case translate('utils.financeFormatters.renderIncomeEstimate.ATE_2_SM.text'):
      return translate('utils.financeFormatters.renderIncomeEstimate.ATE_2_SM.value');
    case translate('utils.financeFormatters.renderIncomeEstimate.2_A_4_SM.text'):
      return translate('utils.financeFormatters.renderIncomeEstimate.2_A_4_SM.value');
    case translate('utils.financeFormatters.renderIncomeEstimate.4_A_10_SM.text'):
      return translate('utils.financeFormatters.renderIncomeEstimate.4_A_10_SM.value');
    case translate('utils.financeFormatters.renderIncomeEstimate.10_A_20_SM.text'):
      return translate('utils.financeFormatters.renderIncomeEstimate.10_A_20_SM.value');
    case translate('utils.financeFormatters.renderIncomeEstimate.ACIMA_DE_20_SM.text'):
      return translate(
        'utils.financeFormatters.renderIncomeEstimate.ACIMA_DE_20_SM.value'
      );
    case translate('utils.financeFormatters.renderIncomeEstimate.ABAIXO_DE_100K.text'):
      return translate(
        'utils.financeFormatters.renderIncomeEstimate.ABAIXO_DE_100K.value'
      );
    case translate('utils.financeFormatters.renderIncomeEstimate.100K_A_250K.text'):
      return translate('utils.financeFormatters.renderIncomeEstimate.100K_A_250K.value');
    case translate('utils.financeFormatters.renderIncomeEstimate.250K_A_500K.text'):
      return translate('utils.financeFormatters.renderIncomeEstimate.250K_A_500K.value');
    case translate('utils.financeFormatters.renderIncomeEstimate.500K_A_1_MM.text'):
      return translate('utils.financeFormatters.renderIncomeEstimate.500K_A_1_MM.value');
    case translate('utils.financeFormatters.renderIncomeEstimate.1_A_5_MM.text'):
      return translate('utils.financeFormatters.renderIncomeEstimate.1_A_5_MM.value');
    case translate('utils.financeFormatters.renderIncomeEstimate.ACIMA_DE_1_MM.text'):
      return translate(
        'utils.financeFormatters.renderIncomeEstimate.ACIMA_DE_1_MM.value'
      );
    case translate('utils.financeFormatters.renderIncomeEstimate.ACIMA_DE_2_MM.text'):
      return translate(
        'utils.financeFormatters.renderIncomeEstimate.ACIMA_DE_2_MM.value'
      );
    case translate('utils.financeFormatters.renderIncomeEstimate.ACIMA_DE_3_MM.text'):
      return 'Acima de R$ 3 milhões';
    case translate('utils.financeFormatters.renderIncomeEstimate.ACIMA_DE_4_MM.text'):
      return translate(
        'utils.financeFormatters.renderIncomeEstimate.ACIMA_DE_4_MM.value'
      );
    case translate('utils.financeFormatters.renderIncomeEstimate.ACIMA_DE_5_MM.text'):
      return translate(
        'utils.financeFormatters.renderIncomeEstimate.ACIMA_DE_5_MM.value'
      );
    case translate('utils.financeFormatters.renderIncomeEstimate.SEM_INFORMACAO.text'):
      return translate(
        'utils.financeFormatters.renderIncomeEstimate.SEM_INFORMACAO.value'
      );
    default:
      return capitalizeFirstLetter(income);
  }
};

export { renderIncomeEstimate };
