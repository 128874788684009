import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'antd';

import defaultImage from 'assets/images/default-image.png';

import './styles.less';

const ValidationsImagesCard = ({
  description,
  firstImageDescription,
  firstImage,
  secondImageDescription,
  secondImage,
  customTop,
}) => {
  return (
    <>
      {description && (
        <Row className="custom-validations-title mrg-top-25 mrg-btm-15 reset-mrg">
          <strong>{description}</strong>
        </Row>
      )}

      <Row
        id="validations-images-card"
        className={description && 'custom-top'}
        style={{ marginTop: customTop }}
        gutter={[16, 16]}
      >
        <Col span={12} className="validation-content-image-container ">
          <Card>
            <span>{firstImageDescription}</span>
            <img
              className={!firstImage ? 'grayscale-95' : ''}
              src={firstImage || defaultImage}
              alt={firstImageDescription}
            />
          </Card>
        </Col>
        <Col span={12} className="validation-content-image-container ">
          <Card>
            <span>{secondImageDescription}</span>
            <img
              className={!secondImage ? 'grayscale-95' : ''}
              src={secondImage || defaultImage}
              alt={secondImageDescription}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

ValidationsImagesCard.propTypes = {
  description: PropTypes.string,
  firstImageDescription: PropTypes.string.isRequired,
  firstImage: PropTypes.string,
  secondImageDescription: PropTypes.string.isRequired,
  secondImage: PropTypes.string,
  customTop: PropTypes.string.isRequired,
};

ValidationsImagesCard.defaultProps = {
  description: '',
  firstImage: '',
  secondImage: '',
};

export default ValidationsImagesCard;
