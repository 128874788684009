import React, { useCallback, useMemo, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { useHeaderFilter } from 'hooks/headerFilter';
import { useFetch } from 'services/hooks';
import PreviewModal from 'components/PreviewModal/Modal';

const usePreviewModal = ({ url: urlGetPreview, type }) => {
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  const [query] = useQueryParams({
    funnelTemplateId: StringParam,
  });

  const { startDate, endDate } = useHeaderFilter();

  const {
    get: getPreviewUrl,
    data: previewUrl,
    loading: previewUrlLoading,
    clearData,
  } = useFetch();

  const closeModal = useCallback(() => {
    setPreviewModalOpen(false);
  }, []);

  const openModal = useCallback(() => {
    setPreviewModalOpen(true);
  }, []);

  const fetchPreviewUrl = useCallback(async () => {
    openModal();
    clearData();
    await getPreviewUrl({
      url: urlGetPreview,
      config: {
        params: {
          profileTemplateId: query.funnelTemplateId,
          _startCreatedDate: startDate,
          _endCreatedDate: endDate,
          type,
        },
      },
    });
  }, [
    endDate,
    getPreviewUrl,
    openModal,
    query.funnelTemplateId,
    startDate,
    type,
    urlGetPreview,
    clearData,
  ]);

  const Modal = useMemo(
    () => (
      <PreviewModal
        handleClose={closeModal}
        isOpen={previewModalOpen}
        previewUrl={previewUrl?.url}
        type={type}
        isLoading={previewUrlLoading}
      />
    ),
    [closeModal, previewModalOpen, previewUrl, previewUrlLoading, type]
  );

  return { Modal, fetchPreviewUrl };
};

export default usePreviewModal;
