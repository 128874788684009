import React, { useState, useMemo, useCallback } from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import useFetch from 'services/hooks/fetch';
import PageTitle from 'components/PageTitle';
import copyTextToClipboard from 'utils/copyTextToClipboard';
import { CompanyBody1, CompanyH5 } from '../../..';
import './styles.less';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companyDetails.header.partnerOnboardingLinkModal';

const CompanyPartnerOnboardingLinkModal = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [onboarding, setOnboarding] = useState(null);
  const [partner, setPartner] = useState(null);
  const { get: getOnboarding, loading: loadingGetOnboarding } = useFetch();
  const { t } = useTranslation();

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setOnboarding(null);
    setPartner(null);
  }, []);

  const loadOnboarding = useCallback(
    async (onboardingId) => {
      const onboarding = await getOnboarding({
        url: `/onboardings/${onboardingId}`,
      });
      setOnboarding(onboarding.data);
    },
    [getOnboarding]
  );

  const openModal = useCallback(
    (data) => {
      const { onboardingId, ...partner } = data;
      setModalVisible(true);
      loadOnboarding(onboardingId);
      setPartner(partner);
    },
    [loadOnboarding]
  );

  const ModalComponent = useMemo(() => {
    return (
      <Modal
        width={600}
        visible={modalVisible}
        onCancel={closeModal}
        footer={null}
        className="kyb-company-partner-onboarding-link-modal"
      >
        <PageTitle
          title={t(`${I18N_BASE_PATH}.title`, 'Onboarding incomplete')}
          subtitle={t(
            `${I18N_BASE_PATH}.subtitle`,
            'Send this to the shareholder/director to complete'
          )}
        />
        <div className="partner-data">
          <div className="partner-data-item">
            <CompanyH5 level={3}>{t(`${I18N_BASE_PATH}.name`, 'Name')}</CompanyH5>
            <CompanyBody1>{partner?.name}</CompanyBody1>
          </div>
          <div className="partner-data-item">
            <CompanyH5 level={3}>{t(`${I18N_BASE_PATH}.email`, 'Email')}</CompanyH5>
            <CompanyBody1>{partner?.email}</CompanyBody1>
          </div>
          <div className="partner-data-item">
            <CompanyH5 level={3}>{t(`${I18N_BASE_PATH}.phone`, 'Phone')}</CompanyH5>
            <CompanyBody1>{partner?.phoneNumber}</CompanyBody1>
          </div>
          <div className="partner-data-item">
            <CompanyH5 level={3}>
              {t(`${I18N_BASE_PATH}.link`, 'Link')}
              {onboarding?.url && (
                <Button
                  type="link"
                  className="partner-data-button-copy-link"
                  onClick={() => {
                    copyTextToClipboard(
                      onboarding?.url,
                      t(`${I18N_BASE_PATH}.copied`, 'Copied')
                    );
                  }}
                >
                  {t(`${I18N_BASE_PATH}.copy`, 'Copy')}
                </Button>
              )}
            </CompanyH5>
            <CompanyBody1>
              {loadingGetOnboarding && <i className="caf-ic_rotate-cw loading-icon" />}
              {onboarding?.url}
            </CompanyBody1>
          </div>
        </div>
      </Modal>
    );
  }, [modalVisible, closeModal, onboarding, loadingGetOnboarding, partner, t]);

  return { openModal, closeModal, ModalComponent };
};

export default CompanyPartnerOnboardingLinkModal;
