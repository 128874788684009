import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'antd';

import EmptyState from 'components/EmptyState';
import EmptyExecutionState from 'components/EmptyExecutionState';

import ContactAndRelationCard from 'pages/private/Profiles/components/ContactAndRelationCard';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.basicInfos.components.person.contactContent.contactContent.basicInfosCard';

export const PfEmails = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const [isEmailsPanelOpened, setIsEmailsPanelOpened] = useState(false);

  const pfEmails = useMemo(() => source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const emails = useMemo(() => {
    if (pfEmails && statusCode !== '01' && statusCode !== '00' && !pfEmails?.available)
      return 'query-failed';

    if (pfEmails && pfEmails?.data?.length <= 0) return 'no-data';

    return pfEmails?.data || [];
  }, [pfEmails, statusCode]);

  const onClickHandler = useCallback((param) => {
    if (param === 'emails') {
      setIsEmailsPanelOpened((oldState) => !oldState);
    }
  }, []);

  const parsedEmailsList = useMemo(() => {
    if (!Array.isArray(emails) || !emails?.length > 0) return <></>;

    const el = [];
    const _size = emails?.length;

    if (_size) {
      for (let i = 0; i < _size; i += 2) {
        el.push(
          <Row className="contact-data-row">
            {emails?.slice(i, i + 2)?.map((item) => (
              <ContactAndRelationCard
                key={item?.number}
                span={12}
                content={item?.email || '-'}
                type={item?.type}
                className="pdd-vertical-10 pdd-horizontal-0"
              />
            ))}
          </Row>
        );
      }
    }

    if (el.length > 0) {
      const parsedEl = isEmailsPanelOpened ? el : el.splice(0, 1);
      return parsedEl;
    }

    return <>-</>;
  }, [emails, isEmailsPanelOpened]);

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_mail"
      title={translate(`${I18N_BASE_PATH}.emails.title`)}
      dataIndex="contacts"
      customQuery={false}
      customId="basic-infos-contacts"
      toPrint={toPrint}
    >
      <Row className="contact-data-container gx-w-100">
        {!!source && (
          <Col span={24} className="contact-data-col">
            {emails === 'no-data' && (
              <EmptyState
                type="empty-success"
                description={translate(`${I18N_BASE_PATH}.emails.emptyState.description`)}
              />
            )}

            {emails === 'query-failed' && <EmptyExecutionState statusCode={statusCode} />}

            {parsedEmailsList}

            {Array.isArray(emails) && emails.length > 2 && (
              <Row className="mrg-top-5 reset-mrg">
                <Button
                  type="link"
                  className="no-mrg no-pdd"
                  onClick={() => onClickHandler('emails')}
                >
                  <i
                    className={`mrg-right-5
                    caf-ic_${isEmailsPanelOpened ? 'close' : 'plus'}`}
                  />
                  {translate(`${I18N_BASE_PATH}.viewMoreEmails`)} ({emails?.length - 2})
                </Button>
              </Row>
            )}
          </Col>
        )}

        {!source && emails !== 'no-data' && emails !== 'query-failed' && (
          <Col span={24} className="contact-data-col">
            -
          </Col>
        )}
      </Row>
    </DataSourceCardCollapsePanel>
  );
};
