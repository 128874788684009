function isValidLength(value) {
  if (!value) return false;

  if (value.length >= 3 && value.length <= 30) {
    return true;
  }

  return false;
}

export { isValidLength };
