import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from 'use-query-params';
import { Row, Col, Spin } from 'antd';
import EmptyMessage from 'components/EmptyMessage';

import { useFetch } from 'services/hooks';

import ViewMore from 'components/ViewMore';

import initialFilterData from '../utils/initialFilterData';

const useComponentList = ({ component: Component, rowKey, getParams, queryParams }) => {
  const { get, loading } = useFetch();
  const { t: translate } = useTranslation();

  const [data, setData] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(undefined);

  const [params, setParams] = useState(getParams);
  const [query, setQuery] = useQueryParams({
    ...queryParams,
  });
  const [filterData, setFilterData] = useState(() =>
    initialFilterData({ query, queryParams })
  );

  const fetch = useCallback(
    async ({ loadMore }) => {
      if (!params) return;

      const { id, tenantId } = lastEvaluatedKey || {};

      const requestParams =
        lastEvaluatedKey && loadMore
          ? { ...params.config.params, ...filterData, id, tenantId, lastKey: id }
          : { ...params.config.params, ...filterData };

      const { data: response } = await get({
        url: params.url,
        config: {
          params: requestParams,
        },
        showMessage: false,
      });
      const { LastEvaluatedKey, docs } = response || {};

      setLastEvaluatedKey(LastEvaluatedKey);

      if (loadMore) {
        setData((oldState) => [...oldState, ...(docs || [])]);
      } else {
        setData(docs);
      }
    },
    [get, params, filterData, lastEvaluatedKey]
  );

  const refreshList = useCallback(() => {
    fetch({ loadMore: false });
  }, [fetch]);

  const updateParams = useCallback((newParams) => {
    setParams(newParams);
  }, []);

  useEffect(() => {
    if (!data) return;
    fetch({ loadMore: false });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!data) refreshList();
  }, [params, filterData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQuery({ ...filterData });
  }, [filterData, setQuery]);

  const componentList = useMemo(
    () => (
      <div className="gx-position-relative mrg-top-30">
        {loading && (
          <div className="custom-loading-container">
            <Spin className="mrg-top-30" />
          </div>
        )}

        {!loading && !data?.length > 0 && (
          <EmptyMessage
            type="empty"
            description={translate(
              'components.list.hooks.useDynamoComponentList.notFound'
            )}
            withCard
            className="pdd-vertical-50"
          />
        )}

        {data?.length > 0 && (
          <>
            {data?.map((d) => (
              <Component key={d[rowKey]} data={d} refreshList={refreshList} />
            ))}

            {lastEvaluatedKey && (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={24}>
                  <ViewMore
                    title={
                      loading
                        ? translate(
                            'components.list.hooks.useDynamoComponentList.loader.loading'
                          )
                        : translate(
                            'components.list.hooks.useDynamoComponentList.loader.showMore'
                          )
                    }
                    text={loading}
                    className="custom-table-btn center"
                    loading={loading}
                    onClick={() => fetch({ loadMore: true })}
                  />
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    ),
    [loading, data, translate, lastEvaluatedKey, rowKey, refreshList, fetch]
  );

  return {
    componentList,
    data,
    loading,
    setFilterData,
    refreshList,
    updateParams,
  };
};

export default useComponentList;
