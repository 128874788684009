/* eslint-disable no-restricted-syntax */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { flattenDeep } from 'lodash';
import {
  CompanySubtitle1,
  CompanySubtitle2,
  CompanyBody1,
  CompanyShareCapital,
  CompanyCaption,
} from '../atoms';
import { CompanyStatus, CompanyTextToPopover } from '../molecules';

const PAGE = 'pages.private.KYBCompany.pages';
const BASE = `${PAGE}.companyDetails`;
const I18N = `${BASE}.subPages.companyInfo`;
const SUMMARY = `${BASE}.subPages.summary`;
const INFO = `${SUMMARY}.cards.companyIdentificationInformation`;
const REPORT = `${I18N}.creditReport.companyIdentificationInformation`;
const ADDITIONAL = `${I18N}.creditReport.additionalInformation`;
const ADDRESS_CONTACT = `${I18N}.creditReport.addressAndContactDetails`;
const HOLDING = `${SUMMARY}.cards.ultimateHoldingCompany`;
const PRINT = `${BASE}.printModal.companyInfo`;
const OTHER_ADDRESSES =
  'pages.private.KYBCompany.pages.companyDetails.printModal.companyInfo.creditReport.includeOtherKnownAddresses';

const OtherAddress = ({ payload, itemsToExport, print = false }) => {
  const { t } = useTranslation();

  if (!print || itemsToExport?.items?.otherAddresses?.value === false) return null;

  return (
    <section className="kyb-company-info-section">
      <div className="kyb-company-other-address">
        <CompanySubtitle2>{t(OTHER_ADDRESSES)}</CompanySubtitle2>
        {payload?.map((item) => (
          <div key={item} className="kyb-company-other-address-item">
            <CompanyCaption>{item}</CompanyCaption>
          </div>
        ))}
      </div>
    </section>
  );
};

export const CompanyCreditReport = ({ payload, itemsToExport, print = false }) => {
  const { t } = useTranslation();
  const { i18nFormatDateOnly } = useI18nFormatters();

  const fullAddress = payload?.creditReport?.address
    ? [{ fullAddress: payload?.creditReport?.address }]
    : [];
  const address = fullAddress
    .concat(payload?.creditReport?.otherAddresses)
    .map((item) => {
      const values = Object.keys(item).map((prop) => item[prop].split(', '));
      const result = [...new Set(flattenDeep(values))];
      return result.join(', ');
    });

  if (itemsToExport?.value === false) return null;

  return (
    <>
      <CompanySubtitle1 render={print}>
        {t(`${PRINT}.creditReport`, 'Credit report')}
      </CompanySubtitle1>
      <section className="kyb-company-info-section kyb-company-print-break-avoid">
        <CompanySubtitle1 print={!print}>
          {t(`${SUMMARY}.cards.companyIdentificationInformation.title`)}
        </CompanySubtitle1>
        <article className="kyb-company-info-article">
          <div>
            <CompanySubtitle2>{t(`${INFO}.identifier`)}</CompanySubtitle2>
            <CompanyBody1>{payload?.creditReport?.number}</CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>{t(`${HOLDING}.columns.companyName`)}</CompanySubtitle2>
            <CompanyBody1>{payload?.creditReport?.name}</CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>{t(`${INFO}.status`)}</CompanySubtitle2>
            <CompanyStatus>{payload?.creditReport?.status}</CompanyStatus>
          </div>
          <div>
            <CompanySubtitle2>
              {t(`${REPORT}.columns.incorporationDate`)}
            </CompanySubtitle2>
            <CompanyBody1>
              {i18nFormatDateOnly(payload?.creditReport?.registrationDate || '', 1)}
            </CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>{t(`${REPORT}.columns.type`)}</CompanySubtitle2>
            <CompanyBody1>{payload?.creditReport?.companyType}</CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>{t(`${REPORT}.columns.VATNumber`)}</CompanySubtitle2>
            <CompanyBody1>{payload?.creditReport?.vatNumber}</CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>{t(`${REPORT}.columns.code`)}</CompanySubtitle2>
            <CompanyBody1>{payload?.creditReport?.sic07Code}</CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>{t(`${REPORT}.columns.codeDescription`)}</CompanySubtitle2>
            <CompanyBody1>{payload?.creditReport?.sic07Description}</CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>{t(`${ADDRESS_CONTACT}.columns.address`)}</CompanySubtitle2>
            <CompanyTextToPopover payload={address} />
          </div>
          <div>
            <CompanySubtitle2>
              {t(`${ADDRESS_CONTACT}.columns.telephone`)}
            </CompanySubtitle2>
            <CompanyBody1>{payload?.creditReport?.telephone}</CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>{t(`${ADDRESS_CONTACT}.columns.email`)}</CompanySubtitle2>
            <CompanyBody1>{payload?.creditReport?.email}</CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>{t(`${ADDRESS_CONTACT}.columns.url`)}</CompanySubtitle2>
            <CompanyBody1>{payload?.creditReport?.url}</CompanyBody1>
          </div>
        </article>
      </section>
      <OtherAddress payload={address} print={print} itemsToExport={itemsToExport} />
      <section className="kyb-company-info-section kyb-company-print-break-avoid">
        <CompanySubtitle1>{t(`${ADDITIONAL}.title`)}</CompanySubtitle1>
        <article className="kyb-company-info-article">
          <div>
            <CompanySubtitle2>{t(`${ADDITIONAL}.columns.FTSEIndex`)}</CompanySubtitle2>
            <CompanyBody1>{payload?.creditReport?.ftseIndex}</CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>{t(`${ADDITIONAL}.columns.shareCapital`)}</CompanySubtitle2>
            <CompanyShareCapital payload={payload} />
          </div>
          <div>
            <CompanySubtitle2>{t(`${ADDITIONAL}.columns.currency`)}</CompanySubtitle2>
            <CompanyBody1>{payload?.creditReport?.shareCapitalCurrency}</CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>{t(`${ADDITIONAL}.columns.charges`)}</CompanySubtitle2>
            <CompanyBody1>{payload?.creditReport?.charges}</CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>{t(`${ADDITIONAL}.columns.landRegistry`)}</CompanySubtitle2>
            <CompanyBody1>{payload?.creditReport?.landRegistry}</CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>
              {t(`${ADDITIONAL}.columns.principalActivity`)}
            </CompanySubtitle2>
            <CompanyBody1>{payload?.creditReport?.mainActivity}</CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>
              {t(`${ADDITIONAL}.columns.filingDateOfAccounts`)}
            </CompanySubtitle2>
            <CompanyBody1>
              {!payload?.creditReport?.filingDateOfAccounts
                ? null
                : i18nFormatDateOnly(payload?.creditReport?.filingDateOfAccounts, 1)}
            </CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>
              {t(`${ADDITIONAL}.columns.charityNumber`)}
            </CompanySubtitle2>
            <CompanyBody1>{payload?.creditReport?.charityNumber}</CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>{t(`${ADDITIONAL}.columns.accountant`)}</CompanySubtitle2>
            <CompanyBody1>{payload?.creditReport?.accountant}</CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>{t(`${ADDITIONAL}.columns.tradeDebtors`)}</CompanySubtitle2>
            <CompanyBody1>{payload?.creditReport?.tradeDebtors}</CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>
              {t(`${ADDITIONAL}.columns.TradeCreditors`)}
            </CompanySubtitle2>
            <CompanyBody1>
              {i18nFormatDateOnly(payload?.creditReport?.TradeCreditors, 1)}
            </CompanyBody1>
          </div>
        </article>
      </section>
    </>
  );
};

CompanyCreditReport.propTypes = {
  payload: PropTypes.object.isRequired,
  print: PropTypes.bool.isRequired,
  itemsToExport: PropTypes.object.isRequired,
};
OtherAddress.propTypes = CompanyCreditReport.propTypes;
