import React, { useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Input, Typography } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import Button from 'components/Button';
import { useAuth } from 'hooks/auth';

import Wrapper from '../wrapper';

const { Title, Text } = Typography;

const Login = () => {
  const { t: translate } = useTranslation();

  const { signIn, loadingSignIn, errorSignIn } = useAuth();
  const reRef = useRef();
  const handleSubmit = useCallback(
    ({ email, password }) => {
      const captchaToken = reRef.current.getValue();
      signIn({ email, password, captchaToken });
      reRef.current.reset();
    },
    [signIn]
  );

  return (
    <Wrapper id="login-component">
      <Row>
        <Col span={24}>
          <Title className="title">
            {translate('pages.public.login.index.wrapperTitle')}
          </Title>
        </Col>
        <Col span={24}>
          <Text className="subtitle">
            {translate('pages.public.login.index.wrapperText')}
          </Text>
        </Col>
      </Row>

      <Form onFinish={handleSubmit} layout="vertical" validateTrigger="finish">
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: 'email',
              message: translate('form.rules.emailMessage'),
            },
          ]}
        >
          <Input
            allowClear
            size="large"
            placeholder={translate('form.placeholder.email')}
            prefix={<i className="caf-ic_mail" />}
          />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true }]}>
          <Input.Password
            size="large"
            placeholder={translate('form.placeholder.password')}
            prefix={<i className="caf-lock" />}
          />
        </Form.Item>

        {errorSignIn && errorSignIn.message && (
          <div className="gx-text-danger">{errorSignIn.message}</div>
        )}

        <Form.Item name="reToken" rules={[{ required: true }]}>
          <ReCAPTCHA
            className="mrg-top-15 pdd-left-15"
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
            ref={reRef}
          />
        </Form.Item>
        <Form.Item>
          <Button
            block
            size="large"
            type="primary"
            htmlType="submit"
            loading={loadingSignIn}
            className="mrg-top-15"
          >
            {translate('pages.public.login.index.wrapperButton')}
          </Button>
        </Form.Item>
      </Form>

      {/* <Divider className="links-with-divider">
        <Link to="/signup">Solicitar meu acesso</Link>
      </Divider> */}
      {/* <Divider className="links-with-divider">
        <Link to="/forgot-password">Esqueci minha senha</Link>
      </Divider> */}
    </Wrapper>
  );
};

export default Login;
