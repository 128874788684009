import React from 'react';
import { Row } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import NotAvailable from 'components/NotAvailable';
import CompanyInformation from './CompanyInformation';
import FinancialRisk from './FinancialRisk';
import People from './People';
import GroupStructure from './GroupStructure';
import Records from './Records';
import RiskFactors from './RiskFactors';
import Comments from './Comments';
import './styles.less';

const Summary = ({
  className,
  summary,
  records,
  companyInfo,
  disabled,
  children,
  ...rest
}) => {
  if (disabled.summary) {
    return <NotAvailable card />;
  }

  const disableFirstLine = Boolean(
    disabled.financialRisk && disabled.comments && disabled.riskFactors
  );
  const disableSecondLine = Boolean(disabled.people && disabled.groupStructure);

  return (
    <section
      className={classnames('kyb-company-summary kyb-company-summary-main', className)}
      {...rest}
    >
      <CompanyInformation summary={summary} disabled={disabled.companyInfo} />
      {disableFirstLine || children ? null : (
        <Row>
          <FinancialRisk summary={summary} disabled={disabled.financialRisk} />
          <Comments summary={summary} disabled={disabled.comments} />
          <RiskFactors companyInfo={companyInfo} disabled={disabled.riskFactors} />
        </Row>
      )}
      {children}
      {disableSecondLine ? null : (
        <Row>
          <People summary={summary} disabled={disabled.people} />
          <GroupStructure summary={summary} disabled={disabled.groupStructure} />
        </Row>
      )}
      <Records records={records} recordsToShow={2} disabled={disabled.records} />
    </section>
  );
};

export const SummaryPrint = ({
  summary,
  companyInfo,
  disabled,
  itemsToExport,
  ...rest
}) => {
  const disableFirstLine = Boolean(disabled.financialRisk && disabled.comments);
  const disableSecondLine = Boolean(disabled.riskFactors);

  if (!itemsToExport.value) return null;

  return (
    <Summary
      summary={summary}
      companyInfo={companyInfo}
      disabled={disabled}
      print
      {...rest}
    >
      {disableFirstLine ? null : (
        <Row>
          <FinancialRisk
            lg={10}
            summary={summary}
            disabled={disabled.financialRisk}
            print
          />
          <Comments lg={14} summary={summary} disabled={disabled.comments} print />
        </Row>
      )}
      {disableSecondLine ? null : (
        <Row>
          <RiskFactors
            lg={24}
            companyInfo={companyInfo}
            disabled={disabled.riskFactors}
            className="kyb-company-print-riskfactors"
            print
          />
        </Row>
      )}
    </Summary>
  );
};

Summary.propTypes = {
  className: PropTypes.string.isRequired,
  summary: PropTypes.object.isRequired,
  records: PropTypes.object.isRequired,
  companyInfo: PropTypes.object.isRequired,
  disabled: PropTypes.object.isRequired,
  itemsToExport: PropTypes.object.isRequired,
};
SummaryPrint.propTypes = Summary.propTypes;

export default Summary;
