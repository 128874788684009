import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import EmptyState from 'components/EmptyState';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import BasicInfosCard from '../BasicInfosCard';

const AddressContent = ({ company /* toPrint */ }) => {
  const { t: translate } = useTranslation();

  const joinArray = useCallback((array) => {
    return array.filter((n) => n).join(', ');
  }, []);

  const addressFormatted = useMemo(() => {
    if (!company?.sections?.pjData?.data?.address) return 'no-recurrence';

    if (
      company?.sections?.pjData?.data?.address &&
      _.isEmpty(company?.sections?.pjData?.data?.address)
    ) {
      return 'query-failed';
    }

    if (company?.sections?.pjData?.data?.address) {
      const { street, number, neighborhood, city, state, zipCode, complement } =
        company?.sections?.pjData?.data?.address || {};

      return (
        <span>
          {street && `${street},`} {number && `${number},`}{' '}
          {complement && neighborhood ? `${complement} -` : `${complement || ''}`}{' '}
          {neighborhood || ''} <br />
          {city && state ? `${city} /` : `${city || ''}`} {state && `${state},`}{' '}
          {zipCode || ''}
        </span>
      );
    }

    return 'no-data';
  }, [company]);

  const src = useMemo(() => {
    let fullAddress;

    if (company?.sections?.pjData?.data?.address) {
      const { street, number, neighborhood, city, state } =
        company?.sections?.pjData?.data?.address || {};
      fullAddress = joinArray([street, number, neighborhood, city, state]);
    }

    if (!fullAddress) return undefined;

    const encodedFullAddress = encodeURI(fullAddress);

    const url = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&q=${encodedFullAddress}`;

    return url;
  }, [company, joinArray]);

  const isProcessing = useMemo(() => verifySourcesProcessing(company, 'addresses'), [
    company,
  ]);

  return (
    <BasicInfosCard
      icon="caf-ic_map_pin"
      title={translate(
        'pages.private.executions.components.dataSourceCards.basicInfos.components.company.addressContent.addressContent.basicInfosCard.title'
      )}
      data={{
        status: company?.sections?.pjData?.data?.status,
        addressFormatted: company?.sections?.pjData?.data?.address,
      }}
      dataIndex="addressFormatted"
      customQuery={false}
    >
      {addressFormatted === 'no-recurrence' && isProcessing && (
        <EmptyState type="processing" />
      )}

      {addressFormatted === 'no-recurrence' && !isProcessing && (
        <EmptyState type="waiting_consult" />
      )}

      {addressFormatted === 'no-data' && (
        <EmptyState
          type="failed"
          description={translate(
            'pages.private.executions.components.dataSourceCards.basicInfos.components.company.addressContent.addressContent.basicInfosCard.emptyState.description.addressFormattedEqualsNoData'
          )}
        />
      )}

      {(addressFormatted === 'query-failed' ||
        (company?.sections?.pjData?.data?.address && !src)) && (
        <EmptyState
          type="failed"
          description={translate(
            'pages.private.executions.components.dataSourceCards.basicInfos.components.company.addressContent.addressContent.basicInfosCard.emptyState.description.addressFormattedEqualsQueryFailed'
          )}
        />
      )}

      {addressFormatted &&
        addressFormatted !== 'no-recurrence' &&
        addressFormatted !== 'no-data' &&
        addressFormatted !== 'query-failed' && (
          // && src
          <Row>
            <Col span={24} className="address-formatted">
              {addressFormatted}
            </Col>

            {/* {src && (
              <Col span={24}>
                <iframe
                  title="map"
                  width="100%"
                  height="350"
                  style={{ border: 0 }}
                  src={src}
                  allowFullScreen
                />
              </Col>
            )} */}
          </Row>
        )}
    </BasicInfosCard>
  );
};

AddressContent.propTypes = {
  company: PropTypes.shape({
    sections: PropTypes.shape({
      pjData: PropTypes.shape({
        data: PropTypes.shape({
          address: PropTypes.shape({
            street: PropTypes.string,
            number: PropTypes.string,
            neighborhood: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string,
            zipCode: PropTypes.string,
            complement: PropTypes.string,
          }),
          status: PropTypes.string,
        }),
      }),
    }),
  }),
  // toPrint: PropTypes.bool,
};

AddressContent.defaultProps = {
  company: undefined,
  // toPrint: false,
};

export default AddressContent;
