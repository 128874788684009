/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import defaultImage from 'assets/images/default-image.png';

import { convertGlobalDocVerificationResultToIcon } from '../../../../utils/convertGlobalDocVerificationResultToIcon';

import './styles.less';

const I18N_BASE_PATH =
  'pages.private.executions.components.tabs.components.documentsValidations.newOcrScreen.documentAuthenticity';

const ImagePatterns = ({ data }) => {
  const { t: translate } = useTranslation();

  const tableColumns = [
    {
      title: translate(`${I18N_BASE_PATH}.image`),
      dataIndex: 'image',
      key: 'image',
      render: (_, { image }) => (
        <img src={image?.signUrl || defaultImage} alt="blank-element-image" />
      ),
    },
    {
      title: translate(`${I18N_BASE_PATH}.etalonImage`),
      dataIndex: 'etalonImage',
      key: 'etalonImage',
      render: (_, { etalonImage }) => (
        <img
          src={etalonImage?.signUrl || defaultImage}
          alt="blank-element-etalon-image"
        />
      ),
    },
    {
      title: translate(`${I18N_BASE_PATH}.similarity`),
      dataIndex: 'similarity',
      key: 'similarity',
    },
    {
      title: translate(`${I18N_BASE_PATH}.diagnose`),
      dataIndex: 'diagnose',
      key: 'diagnose',
      render: (_, { diagnose }) => (
        <span className={diagnose?.type || ''}>{diagnose?.result || '-'}</span>
      ),
    },
    {
      title: translate(`${I18N_BASE_PATH}.result`),
      dataIndex: 'result',
      key: 'result',
    },
  ];

  const tableData = data?.blank?.map((blankElement, index) => ({
    key: index,
    image: blankElement?.image,
    etalonImage: blankElement?.etalonImage,
    similarity: blankElement?.similarity ? `${blankElement.similarity}%` : '-',
    diagnose: {
      type:
        [0, 1].includes(blankElement?.result) && blankElement?.result === 1
          ? 'approval-diagnose'
          : 'failure-diagnose',
      result: blankElement?.diagnose,
    },
    result: convertGlobalDocVerificationResultToIcon({
      checkResult: blankElement?.result,
      className: 'font-size-18',
    }),
  }));

  return (
    <div className="image-patterns-container mrg-top-20">
      <h3 className="authenticity-check-title">
        {translate(`${I18N_BASE_PATH}.imagePatterns`)}
        <span>
          {convertGlobalDocVerificationResultToIcon({
            checkResult: data?.result,
            className: 'font-size-18',
          })}
        </span>
      </h3>
      {tableData?.length > 0 && (
        <Table
          columns={tableColumns}
          dataSource={tableData}
          pagination={false}
          tableLayout="fixed"
        />
      )}
    </div>
  );
};

ImagePatterns.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.oneOf([0, 1, 2]),
    blank: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          signUrl: PropTypes.string,
        }),
        etalonImage: PropTypes.shape({
          signUrl: PropTypes.string,
        }),
        similarity: PropTypes.number,
        diagnose: PropTypes.string,
        result: PropTypes.oneOf([0, 1, 2]),
      })
    ),
  }).isRequired,
};

export default ImagePatterns;
