import React, { createContext, useState, useCallback, useContext } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import api from 'services/api';
import { useFetch } from 'services/hooks';

const GroupContext = createContext({});

const GroupProvider = ({ children }) => {
  const { t: translate } = useTranslation();
  const [group, setGroup] = useState();
  const [listGroups, setListGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const { get } = useFetch();

  const clearData = useCallback(() => {
    setGroup();
  }, []);

  const loadGroup = useCallback(
    async ({ id, clearData: clearDataParam }) => {
      try {
        if (clearDataParam) clearData();

        setLoading(true);

        const { data: response } = await api.get(
          `${process.env.REACT_APP_BASE_URL_AUTH_API}/users-groups/${id}`
        );

        setGroup(response?.data);
      } catch (err) {
        if (err?.response?.status === 404) {
          const errMessage =
            err?.response?.data?.message ||
            translate('global.hooks.group.loadGroup.notFound');
          message.error(errMessage);
        } else {
          message.error(translate('global.hooks.group.loadGroup.error'));
        }
      } finally {
        setLoading(false);
      }
    },
    [clearData, translate]
  );

  const getListGroups = useCallback(async () => {
    try {
      setLoading(true);

      const response = await get({
        url: `${process.env.REACT_APP_BASE_URL_TRUST_API}/groups`,
        config: {
          params: {
            defaultPolicies: true,
          },
        },
      });
      response?.docs?.push({
        id: '',
        name: translate('global.hooks.group.getListGroups.noAccess'),
        policyGroups: {
          trust: '',
          management: '',
        },
        tenantId: 'combateafraude',
      });
      setListGroups(response?.docs);
    } catch (err) {
      if (err?.response?.status === 404) {
        const errMessage =
          err?.response?.docs?.message ||
          translate('global.hooks.group.getListGroups.notFound');
        message.error(errMessage);
      } else {
        message.error(translate('global.hooks.group.getListGroups.error'));
      }
    } finally {
      setLoading(false);
    }
  }, [get, translate]);

  return (
    <GroupContext.Provider
      value={{
        group,
        loadGroup,
        loading,
        clearData,
        listGroups,
        getListGroups,
      }}
    >
      {children}
    </GroupContext.Provider>
  );
};

function useGroup() {
  const context = useContext(GroupContext);

  if (!context) {
    throw new Error('useGroup must be used within an GroupProvider');
  }

  return context;
}

export { GroupProvider, useGroup };
