/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyState from 'components/EmptyState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';

const UnemploymentInsurance = ({ profile /* urlId, toPrint */ }) => {
  const { t: translate } = useTranslation();

  const unemploymentInsurance = useMemo(() => profile?.work?.unemploymentInsurance, [
    profile,
  ]);

  const isProcessing = useMemo(
    () => verifySourcesProcessing(profile, 'unemploymentInsurance'),
    [profile]
  );

  return (
    <ColumnLabel
      title={translate(
        'pages.private.profiles.components.dataSourceCards.financialInformation.people.components.unemploymentInsurance.index.unemploymentInsurance.columnLabel.title'
      )}
      description={
        unemploymentInsurance &&
        (unemploymentInsurance?.insuranceStatus ||
          translate(
            'pages.private.profiles.components.dataSourceCards.financialInformation.people.components.unemploymentInsurance.index.unemploymentInsurance.columnLabel.description'
          ))
      }
      type="empty"
    >
      {!unemploymentInsurance && isProcessing && <EmptyState type="processing" />}

      {!unemploymentInsurance && !isProcessing && <EmptyState type="waiting_consult" />}

      {unemploymentInsurance?.procedure && (
        <span>{unemploymentInsurance?.procedure}</span>
      )}

      {unemploymentInsurance && !unemploymentInsurance?.insuranceStatus && (
        <span>
          {translate(
            'pages.private.profiles.components.dataSourceCards.financialInformation.people.components.unemploymentInsurance.index.unemploymentInsurance.hasNotInsuranceStatus'
          )}
        </span>
      )}
    </ColumnLabel>
  );
};

export default UnemploymentInsurance;
