/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import EmptyState from 'components/EmptyState';
import ViewMore from 'components/ViewMore';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import SPCProgress from './SPCProgress';
import SPCInfos from './SPCInfos';
import useSPCDetails from './SPCDetailsModal';

import './styles.less';

const SPC = ({ profile, toPrint, toPrintDetails /* urlId */ }) => {
  const { t: translate } = useTranslation();

  const spc = useMemo(() => profile?.finance?.spc, [profile]);

  const { openModal, SPCDetailsModal } = useSPCDetails({ data: profile?.finance?.spc });

  const isValidScore = useMemo(() => {
    if (!spc?.spcScore12Months?.score) return false;

    const formattedScore = Number(spc?.spcScore12Months?.score);

    if (formattedScore >= 0 && formattedScore <= 1000) {
      return true;
    }

    return false;
  }, [spc]);

  const isProcessing = useMemo(() => verifySourcesProcessing(profile, 'spc'), [profile]);

  return (
    <>
      {SPCDetailsModal}

      <ColumnLabel
        title={translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.shared.spc.index.spc.columnLabel.title'
        )}
        span={24}
        info={
          !toPrint &&
          translate(
            'pages.private.profiles.components.dataSourceCards.financialInformation.shared.spc.index.spc.columnLabel.info'
          )
        }
      >
        {!spc && isProcessing && <EmptyState type="processing" />}

        {!spc && !isProcessing && <EmptyState type="waiting_consult" />}

        {spc && (
          <>
            {isValidScore && <SPCProgress data={spc?.spcScore12Months?.score} />}

            <SPCInfos data={spc} />

            {!toPrint && spc?.ocurrences?.length > 0 && (
              <Row>
                <ViewMore
                  type="link"
                  title={translate(
                    'pages.private.profiles.components.dataSourceCards.financialInformation.shared.spc.index.spc.viewMore.title.hasNotToPrint'
                  )}
                  onClick={() => openModal()}
                />
              </Row>
            )}

            {toPrint && !toPrintDetails && spc?.ocurrences?.length > 0 && (
              <Row>
                <ViewMore
                  text
                  type="link"
                  title={translate(
                    'pages.private.profiles.components.dataSourceCards.financialInformation.shared.spc.index.spc.viewMore.title.hasToPrint',
                    { value: spc?.ocurrences?.length }
                  )}
                />
              </Row>
            )}
          </>
        )}
      </ColumnLabel>
    </>
  );
};

export default SPC;
