/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';

import { Checkbox, Tooltip, Row, Col } from 'antd';
import classNames from 'classnames';
import { useI18nFormatters } from 'hooks/i18nFormatters';

import SuccessIcon from 'assets/images/success-icon.svg';
import { Person } from '@combateafraude/icons/users';
import { Lawier, News } from '@combateafraude/icons/general';
import { AlarmBlinkerSquare } from '@combateafraude/icons/security';

import { backgroundCard, matchMap } from './utils';

import './styles.less';

const I18N_BASE_PATH = 'components.dataSourceCards.KYCAndCompliance.index.component.card';

export const Card = ({
  details,
  openModal,
  translate,
  modalsKey,
  isApproved,
  isReproved,
  isTheBestScore,
  allowEditing,
  onChangeApproved,
  onChangeReproved,
  onChangeUnselectReproved,
  onChangeUnselectApproved,
  type,
}) => {
  const doc = details?.doc || details || {};
  const isProfile = window.location.pathname?.includes('profile/people');
  const { matchTypes = [] } = details;
  const relevance = matchTypes;
  const { i18nFormatDateOnly } = useI18nFormatters();

  const openAllDetailsModal = () => {
    openModal({
      data: doc,
      modalShouldBeOpen: modalsKey[type],
    });
  };

  const icon = useMemo(() => {
    if (type === 'peps') return <Person color="blue" fontSize={26} />;
    if (type === 'sanctions') return <Lawier color="blue" fontSize={26} />;
    if (type === 'adverseMedia') return <News color="blue" fontSize={26} />;
    if (type === 'warnings') return <AlarmBlinkerSquare color="blue" fontSize={26} />;
    return <Person />;
  }, [type]);

  const renderAppearsOn = useCallback(
    (types) => {
      if (Array.isArray(types) && types.length > 0) {
        let matchTypes;
        if (type === 'warnings') {
          matchTypes = (
            <span className="tag-background">
              {translate(`${I18N_BASE_PATH}.column.warnings`, 'Warnings')}
            </span>
          );
        }
        if (type === 'adverseMedia') {
          matchTypes = (
            <span className="tag-background">
              {translate(`${I18N_BASE_PATH}.column.adverseMediaLabel`, 'Adverse Media')}
            </span>
          );
        }
        if (type === 'sanctions') {
          matchTypes = (
            <span className="tag-background">
              {' '}
              {translate(`${I18N_BASE_PATH}.column.sanctionLabel`, 'Sanction')}
            </span>
          );
        }
        if (type === 'peps') {
          matchTypes = types.map((pep) => {
            return (
              backgroundCard[pep] && (
                <Tooltip title={translate(backgroundCard[pep]?.i18nTooltip)}>
                  <span
                    className="tag-background"
                    style={{
                      background: backgroundCard[pep]?.backgroundColor,
                      color: backgroundCard[pep]?.color,
                    }}
                  >
                    {translate(backgroundCard[pep]?.i18n)}
                  </span>
                </Tooltip>
              )
            );
          });
        }
        if (matchTypes) return matchTypes;
      }
      return null;
    },
    [translate, type]
  );
  const renderRelevance = useCallback(() => {
    let matchMapRelevance;
    if (Array.isArray(relevance) && relevance.length > 0) {
      matchMapRelevance = relevance.map((v, index) => {
        return (
          matchMap[v] && (
            <span className="text-return">
              {translate(matchMap[v]?.i18n)}
              {index !== relevance.length - 1 && ', '}
            </span>
          )
        );
      });
      if (matchMapRelevance.length > 0) return matchMapRelevance;
    }
    return null;
  }, [relevance, translate]);

  const renderCountries = useCallback(
    (fields) => {
      if (Array.isArray(fields)) {
        const matchingCountries = fields?.map((value, index) => {
          return (
            <span key={value} className="text-return">
              {translate(`countries.${value}`)}
              {index !== fields.length - 1 && ', '}
            </span>
          );
        });
        if (matchingCountries.length > 0) return matchingCountries;
      }
      return null;
    },
    [translate]
  );

  const renderBirthDate = useCallback(
    (fields) => {
      if (fields && Array.isArray(fields)) {
        const filteredArray = fields.map((value, index) => (
          <span key={value} className="text-return">
            {i18nFormatDateOnly(value, 6)}
            {index !== fields.length - 1 && ', '}
          </span>
        ));
        const showSixFirstElements = filteredArray;
        if (showSixFirstElements.length > 0) return showSixFirstElements;
      }
      return null;
    },
    [i18nFormatDateOnly]
  );

  return (
    <div
      className={classNames('wrapper-card-KYC', {
        'applying-opacity': isReproved,
      })}
    >
      <div className="header-infos">
        {icon}
        <strong>{doc?.name ?? '-'}</strong>
        {isTheBestScore ? (
          <span className="results">
            <img src={SuccessIcon} alt="Success icon" />
            {translate(`${I18N_BASE_PATH}.column.potentialResult`, 'Potential match')}
          </span>
        ) : (
          <></>
        )}
      </div>
      <section className="infos">
        <Row>
          <Col span={5}>
            <span className="title-categories">
              {translate(`${I18N_BASE_PATH}.column.matchingType`, 'Appears on')}{' '}
            </span>{' '}
          </Col>
          <Col span={19}>{renderAppearsOn(doc?.types) || '-'}</Col>
        </Row>
        <Row>
          <Col span={5}>
            <span className="title-categories">
              {translate(`${I18N_BASE_PATH}.column.relevance`, 'Relevance')}{' '}
            </span>{' '}
          </Col>
          <Col span={19}>{renderRelevance() || '-'} </Col>
        </Row>
        <Row>
          <Col span={5}>
            <span className="title-categories">
              {translate(`${I18N_BASE_PATH}.column.matchingCountry`, 'Countries')}
            </span>{' '}
          </Col>
          <Col span={19}>{renderCountries(doc?.countryCodes) || '-'}</Col>
        </Row>
        <Row>
          <Col span={5}>
            <span className="title-categories">
              {translate(`${I18N_BASE_PATH}.column.birthDate`, 'Date of birth')}
            </span>{' '}
          </Col>
          <Col span={19}>
            {renderBirthDate(doc?.birthDate) && (
              <span style={{ color: '#323232' }}>
                {translate(`${I18N_BASE_PATH}.column.birthDateLabel`, 'Potential DOBs:')}{' '}
              </span>
            )}
            {renderBirthDate(doc?.birthDate) || '-'}
          </Col>
        </Row>
      </section>
      <section className="wrapper-check-buttons">
        <button type="button" onClick={openAllDetailsModal} className="mrg-top-10">
          {translate(`${I18N_BASE_PATH}.column.showMore`, 'Show more')}
        </button>
        {!isProfile && (
          <div className="radio-group-display">
            <Checkbox
              checked={isApproved}
              disabled={!allowEditing}
              className="true-match-class"
              onChange={(event) => {
                if (allowEditing) {
                  return event.target.checked
                    ? onChangeApproved(doc?.id)
                    : onChangeUnselectApproved(doc?.id);
                }
                return () => {};
              }}
            >
              {translate(`${I18N_BASE_PATH}.button.trueMatch`, 'True Match')}
            </Checkbox>
            <Checkbox
              checked={isReproved}
              disabled={!allowEditing}
              className="false-match-class"
              onChange={(event) => {
                if (allowEditing) {
                  return event.target.checked
                    ? onChangeReproved(doc?.id)
                    : onChangeUnselectReproved(doc?.id);
                }
                return () => {};
              }}
            >
              {translate(`${I18N_BASE_PATH}.button.falseMatch`, 'False Match')}
            </Checkbox>
          </div>
        )}
      </section>
    </div>
  );
};
