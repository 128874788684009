import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Editing } from '@combateafraude/icons/builder';
import { Divider } from 'antd';

import { NoteCard } from './components/NoteCard';
import useNotesModal from './components/NotesModal/index';
import { test } from './test';
import './styles.less';

export const Notes = () => {
  const { t: translate } = useTranslation();
  const { ModalComponent, openModal } = useNotesModal();

  return (
    <>
      {ModalComponent}
      <div>
        {test.length && (
          <>
            <div id="notes-box">
              <header className="notes-box-header">
                <h4>
                  {translate(
                    'pages.private.globalProfiles.components.notes.index.notes',
                    'Anotações'
                  )}
                </h4>
              </header>
              <div className="notes-box-view">
                {test?.map((note) => {
                  return (
                    <NoteCard
                      noteId={note?.noteId}
                      title={note?.title}
                      description={note?.description}
                      key={note?.noteId}
                    />
                  );
                })}
              </div>
            </div>
            <Divider style={{ margin: '0px', padding: '0px' }} />
          </>
        )}
        <footer id="notes-footer">
          <button type="button" className="reset-btn" onClick={openModal}>
            <span>
              <Editing fontSize={16} style={{ marginRight: '5px' }} color="#323232" />
              {translate(
                'pages.private.globalProfiles.components.notes.index.newNote',
                'Nova anotação'
              )}
            </span>
          </button>
        </footer>
      </div>
    </>
  );
};
