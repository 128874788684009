import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

import { useProfileTemplate } from 'hooks/profileTemplate';

import EmptyMessage from 'components/EmptyMessage';
// import currencyFormatter from 'utils/currencyFormatter';

import { onboardingServices } from '../../../../utils';
import BaseCard from '../BaseCard';

import './styles.less';

const { Text } = Typography;

const I18N_BASE_PATH =
  'pages.private.profileModels.profileModelCreate.confirmation.components.cardOnboarding.index';

const CardOnboarding = ({ disabled }) => {
  const { t: translate } = useTranslation();

  const { checkedServices } = useProfileTemplate();

  const onboardingCheckedServices = useMemo(
    () => checkedServices?.filter(({ category }) => category === 'onboarding') || [],
    [checkedServices]
  );

  return (
    <div id="confirmation-card-onboarding-component">
      <BaseCard
        step="onboarding_services"
        title={translate(`${I18N_BASE_PATH}.baseCard.title`)}
        disabled={disabled}
      >
        {onboardingCheckedServices?.length <= 0 ? (
          <EmptyMessage
            description={translate(`${I18N_BASE_PATH}.emptyMessage.description`)}
          />
        ) : (
          <>
            {onboardingCheckedServices?.map((service) => (
              <div key={service.source} className="services-list-item">
                <i
                  className={onboardingServices[service.source]?.icon || 'caf-ic_hash'}
                />
                <Text>
                  {translate(`services.${service.source}.title`, service.title)}
                </Text>
              </div>
            ))}
          </>
        )}
      </BaseCard>
    </div>
  );
};

CardOnboarding.propTypes = {
  disabled: PropTypes.bool,
};

CardOnboarding.defaultProps = {
  disabled: false,
};

export default CardOnboarding;
