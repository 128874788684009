import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Row, Col, Divider } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import { toFormatDocument } from 'utils/formatters';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

import PageTitle from 'components/PageTitle';
import EmptyMessage from 'components/EmptyMessage';

import { statusInfos, courtInfos } from '../utils/processesDetails';

import ProcessTag from '../ProcessTag';

import './styles.less';

const ProcessDetails = () => {
  const { t: translate } = useTranslation();

  const { i18nFormatDate } = useI18nFormatters();

  const [process, setProcess] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback((data) => {
    setProcess(data);
    setModalVisible(true);
  }, []);

  const parties = useMemo(() => {
    if (process?.parties?.length > 0) {
      return process?.parties;
    }
    if (process?.actives || process?.passives) {
      return [...(process?.actives || []), ...(process?.passives || [])];
    }
    return [];
  }, [process]);

  const ProcessDetailsModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        onCancel={closeModal}
        footer={null}
        style={{ top: 20 }}
        width={750}
      >
        <div className="processes-details-modal no-mrg no-pdd">
          <Row className="reset-mrg">
            <PageTitle
              title={process.mainSubject || process.subjects?.join(', ') || '-'}
              subtitle={`${process.number} - ${translate(
                'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.processDetailsModal.index.processDetailsModal.courtType'
              )} ${translate(
                courtInfos(process.courtType || process.lawBranch)?.name
              )} - ${process.courtName || process.court} ${
                process.state ? `(${process.state})` : ''
              }`}
            />
          </Row>

          {(process.courtType || process.lawBranch || process.authorType) && (
            <Row className="reset-mrg mrg-btm-15">
              <Col className="reset-pdd" span={process?.url ? 19 : 24}>
                {(process.courtType || process.lawBranch) && (
                  <ProcessTag
                    title={translate(
                      courtInfos(process.courtType || process.lawBranch)?.name
                    )}
                    type={courtInfos(process.courtType || process.lawBranch)?.type}
                  />
                )}
                {process.authorType && (
                  <ProcessTag
                    title={translate(statusInfos(process.authorType)?.name)}
                    type={statusInfos(process.authorType)?.type}
                  />
                )}
              </Col>
              {process?.url && (
                <Col className="reset-pdd" span={5}>
                  <a
                    id="access-process-link"
                    href={process.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {translate(
                      'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.processDetailsModal.index.processDetailsModal.processUrl'
                    )}
                  </a>
                </Col>
              )}
            </Row>
          )}

          <Divider className="custom-divider mrg-top-5" />

          <Row className="reset-mrg processes-details-infos mrg-vertical-5">
            <Col span={24}>
              <span>
                <strong>
                  {translate(
                    'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.processDetailsModal.index.processDetailsModal.publicationDate'
                  )}
                </strong>{' '}
                {i18nFormatDate(process?.publicationDate || process?.distributionDate, 6)}
              </span>
            </Col>

            <Col span={24}>
              <span>
                <strong>
                  {translate(
                    'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.processDetailsModal.index.processDetailsModal.lastUpdate'
                  )}
                </strong>{' '}
                {i18nFormatDate(process?.lastUpdate || process?.moves?.[0]?.date, 6)}
              </span>
            </Col>
            <Col span={24}>
              <span>
                <strong>
                  {translate(
                    'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.processDetailsModal.index.processDetailsModal.type'
                  )}
                </strong>{' '}
                {capitalizeFirstLetter(process.type || process.lawsuitClass) || '-'}
              </span>
            </Col>
            {(process.observationStatus || process.complementaryStatus) && (
              <Col span={24}>
                <span>
                  <strong>
                    {translate(
                      'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.processDetailsModal.index.processDetailsModal.status'
                    )}
                  </strong>{' '}
                  {capitalizeFirstLetter(
                    process.observationStatus || process.complementaryStatus
                  ) || '-'}
                </span>
              </Col>
            )}
          </Row>

          <Divider className="custom-divider mrg-top-5" />

          <Row className="processes-details-parties-title">
            <Col span={18}>
              <strong>
                {translate(
                  'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.processDetailsModal.index.processDetailsModal.participant'
                )}
              </strong>
            </Col>
            <Col span={6}>
              <strong>
                {translate(
                  'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.processDetailsModal.index.processDetailsModal.condition'
                )}
              </strong>
            </Col>
          </Row>

          <Divider className="custom-divider" />

          {parties?.length > 0 &&
            parties?.map((p) => (
              <Row
                key={p.doc || p.cpf || p.cnpj}
                className="processes-details-parties-data reset-mrg"
              >
                <Col span={18}>
                  <strong>{p.name || '-'}</strong>
                  <span>{toFormatDocument(p.doc || p.cpf || p.cnpj)}</span>
                </Col>

                <Col span={6}>
                  <span className="font-size-15">
                    {translate(
                      statusInfos(
                        p.type ||
                          translate(
                            'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.processDetailsModal.index.processDetailsModal.statusInfosUnavailable'
                          )
                      )?.name
                    )}
                  </span>
                </Col>
              </Row>
            ))}

          {parties?.length <= 0 && (
            <div className="empty-message-container">
              <EmptyMessage
                type="empty"
                style={{ padding: 0 }}
                description={translate(
                  'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.processDetailsModal.index.processDetailsModal.emptyMessage.description'
                )}
              />
            </div>
          )}
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, process, i18nFormatDate, parties, translate]
  );

  return { openModal, closeModal, ProcessDetailsModal };
};

export default ProcessDetails;
