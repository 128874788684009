import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import PersonRegistrationInfosContent from 'pages/private/Profiles/components/Tabs/components/BasicInfos/components/Person/RegistrationInfosContent';
import CompanyRegistrationInfosContent from 'pages/private/Profiles/components/Tabs/components/BasicInfos/components/Company/RegistrationInfosContent';

const DocumentRegistrationInfos = ({ data }) => {
  const { personId, companyId } = useParams();

  return (
    <div className="to-print-registration-infos">
      {personId && <PersonRegistrationInfosContent person={data} toPrint />}
      {companyId && <CompanyRegistrationInfosContent company={data} toPrint />}
    </div>
  );
};

DocumentRegistrationInfos.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DocumentRegistrationInfos;
