import React, { useCallback, useMemo, useState } from 'react';
import { Button, Modal, Input, Spin } from 'antd';
import './styles.less';
import { useComponentNextWithOffsetList } from 'components/List';
import { ListExecutions } from 'pages/private/GlobalProfiles/components/ListExecutions';
import { CardGlobalExecution } from 'pages/private/GlobalProfiles/components/ListExecutions/CardGlobalExecution';
import PageTitle from 'components/PageTitle';

const SearchUnconnectedExecutionsModal = () => {
  const [searchText, setSearchText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [clear, setClear] = useState(true);

  const openModal = useCallback(() => setModalVisible(true), []);
  const closeModal = useCallback(() => {
    setSearchText('');
    setClear(true);
    setModalVisible(false);
  }, []);

  const handleChangeText = useCallback((event) => {
    setSearchText(event.target.value);
  }, []);

  const {
    loading,
    componentList,
    paginationLabel,
    loadingFivetimes,
    data,
    updateParams,
  } = useComponentNextWithOffsetList({
    component: CardGlobalExecution,
    rowKey: '_id',
    componentProps: {
      extraComponent: 'connect-button',
    },
    size: 4,
    customEmptyMessage: true,
    customDebounce: 5,
    customLoadingInitialState: false,
    hideEmptyMessage: true,
  });

  const parsedListContent = useMemo(() => {
    if (loading || loadingFivetimes) {
      return (
        <div id="spin-container">
          <Spin />
        </div>
      );
    }

    return componentList;
  }, [componentList, loading, loadingFivetimes]);

  const renderUnconnectedExecutions = useMemo(() => {
    if (!clear) {
      return (
        <>
          <PageTitle
            style={{ fontSize: '20px' }}
            className="no-mrg"
            title="Results"
            subtitle={!!data.length || loading || loadingFivetimes ? paginationLabel : ''}
          />
          <ListExecutions showRowTitle={!!data.length} extraComponent={<></>}>
            {parsedListContent}
          </ListExecutions>
        </>
      );
    }
  }, [clear, data.length, loading, loadingFivetimes, paginationLabel, parsedListContent]);

  const handleClear = useCallback((event) => {
    setClear(true);
    setSearchText('');

    event.currentTarget.blur();
  }, []);

  const handleSubmit = useCallback(() => {
    setClear(false);

    updateParams({
      url: 'people',
      config: {
        params: {
          _search: searchText,
        },
      },
    });
  }, [searchText, updateParams]);

  const ModalComponent = useMemo(() => {
    return (
      <>
        <Modal
          visible={modalVisible}
          footer={null}
          width={1200}
          onCancel={closeModal}
          maskClosable={false}
          destroyOnClose
          bodyStyle={{ paddingTop: '20px', paddingLeft: '20px' }}
        >
          <div id="search-executions-modal">
            <header>
              <strong>Search for unconnected executions</strong>
              <p>
                Search for executions to connect to this profile. When the results appear,
                click Connect to link the execution to the profile
              </p>
            </header>
            <section>
              <div>Execution</div>
              <Input
                onChange={handleChangeText}
                placeholder="Search for name, doc ID, address"
                value={searchText}
                onPressEnter={searchText && !loading && handleSubmit}
                disabled={loading}
              />
            </section>
            <footer>
              <Button type="ghost" className="button-clear" onClick={handleClear}>
                Clear
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="button-submit"
                disabled={!searchText && !loading}
                onClick={handleSubmit}
                loading={loading}
              >
                Submit
              </Button>
            </footer>
          </div>
          {renderUnconnectedExecutions}
        </Modal>
      </>
    );
  }, [
    modalVisible,
    closeModal,
    handleChangeText,
    searchText,
    handleSubmit,
    handleClear,
    loading,
    renderUnconnectedExecutions,
  ]);

  return { ModalComponent, openModal };
};

export default SearchUnconnectedExecutionsModal;
