import React from 'react';
import PropTypes from 'prop-types';

import { Card, Row, Col, Typography } from 'antd';

import CircleIcon from 'components/Icon/CircleIcon';

import './styles.less';

const { Title } = Typography;

const DossierCard = ({ title, subtitle, icon, footer, customId, children }) => {
  return (
    <Card className="dossier-card-container" id={customId}>
      <Row className="reset-mrg">
        <Col flex="0 1" className="flex center-start mrg-right-10">
          <CircleIcon icon={icon} />
        </Col>

        <Col flex="0.95 1" className="dossier-card-content">
          <Title level={4} className="dossier-card-title">
            {title}
          </Title>

          {subtitle && (
            <Typography className="dossier-card-subtitle">{subtitle}</Typography>
          )}

          {children}

          {footer}
        </Col>
      </Row>
    </Card>
  );
};

DossierCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  footer: PropTypes.element,
  customId: PropTypes.string,
};

DossierCard.defaultProps = {
  footer: <></>,
  customId: '',
};

export default DossierCard;
