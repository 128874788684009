import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import { validationStatus } from 'utils/handlerStatus';

const ComplianceRulesValidations = ({ item }) => {
  const validationsFiltered = useMemo(() => {
    return item?.validations?.filter(
      (v) => v?.status === 'INVALID' || v?.status === 'PENDING'
    );
  }, [item]);

  return validationsFiltered?.map((v) => (
    <Row
      key={v?.rule}
      align="middle"
      justify="center"
      className="compliance-rules-status reset-mrg pdd-top-10 pdd-btm-10"
    >
      <Col span={8} className="reset-mrg no-pdd">
        <span className="rule-title">{v?.title}</span>
      </Col>
      <Col span={2}>
        <i
          style={{ color: validationStatus(v?.status).color }}
          className={`status-icon ${validationStatus(v?.status).icon}`}
        />
      </Col>
      <Col span={14}>{v?.description}</Col>
    </Row>
  ));
};

ComplianceRulesValidations.propTypes = {
  item: PropTypes.shape({
    validations: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        description: PropTypes.string,
        title: PropTypes.string,
        rule: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
};

export default ComplianceRulesValidations;
