import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import useSearchUnconnectedExecutionsModal from './components/ModalSearchUnconnectedExecutions';

import ValidationsList from './components/ValidationsList';

import './styles.less';

const I18N_BASE_PATH =
  'pages.private.executions.components.tabs.components.complianceRules.index.complianceRules';

const LastValidations = ({ validations, lastExecutionId }) => {
  const { t: translate } = useTranslation();
  const { ModalComponent, openModal } = useSearchUnconnectedExecutionsModal();

  return (
    <>
      {ModalComponent}
      <div id="last-validations">
        <Row className="no-mrg no-pdd mrg-vertical-30" justify="space-between">
          <div>
            {translate(
              'pages.private.globalProfiles.components.tabs.components.summary.components.lastValidations.index.title'
            )}
          </div>
          <div>
            <Link
              to={`/person/${lastExecutionId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-bold"
            >
              {translate(
                'pages.private.globalProfiles.components.tabs.components.summary.components.lastValidations.index.seeExecution'
              )}
            </Link>
          </div>
        </Row>
        {/* <button
              type="button"
              className="reset-btn"
              onClick={openModal}
              style={{ cursor: 'pointer' }}
            >
              <strong style={{ color: '#004af7' }}>
                Search for unconnected executions
              </strong>
        </button> */}
        <ValidationsList validations={validations} />
      </div>
    </>
  );
};

export default LastValidations;
