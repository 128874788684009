import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import IconGroupStructure from 'assets/images/company/group-structure.svg';
import TabTitle from 'pages/private/KYBCompany/CompanyDetails/components/TabTitle';
import TreeTableCompanyLinks from 'pages/private/KYBCompany/CompanyDetails/components/TabsComponent/CompanyLink/components/TreeTable';
import { CompanyGroupSummary, CompanySummaryCounterCard } from '../../molecules';
import './styles.less';

const PAGE = 'pages.private.KYBCompany.pages';
const BASE = `${PAGE}.companyDetails.subPages.companyLinks.groupStructure`;
const STAT = `${BASE}.cards.groupStructureStatistics`;
const SUMMARY = `${BASE}.cards.groupStructureSummary`;

const GroupStructure = ({ payload, itemsToExport, disabled, print = false }) => {
  const { t } = useTranslation();

  if (disabled || itemsToExport?.value === false) {
    return null;
  }

  const summaryCounter = [
    {
      text: t(`${STAT}.companies`, 'Companies'),
      count: payload.countCompanies,
    },
    {
      text: t(`${STAT}.countries`, 'Countries'),
      count: payload.countCountries,
    },
  ];

  const groupSummary = [
    {
      text: t(`${SUMMARY}.columns.holdingCompany`),
      info: payload.holdingCompanyName,
    },
    {
      text: t(`${SUMMARY}.columns.ultimateHoldingCompany`),
      info: payload.ultimateHoldingCompanyName,
    },
    {
      text: t(`${SUMMARY}.columns.ownershipStatus`),
      info: payload.ownershipStatus,
    },
  ];

  return (
    <section className="kyb-company-groupstructure-main">
      <TabTitle
        icon={IconGroupStructure}
        title={t(`${BASE}.title`, 'Group structure')}
        render={!print}
      />
      <div className="kyb-company-groupstructure-summary-area">
        <CompanySummaryCounterCard payload={summaryCounter} />
        <CompanyGroupSummary
          payload={groupSummary}
          className="kyb-company-groupstructure-group-summary"
        />
      </div>
      <div>
        <TreeTableCompanyLinks groupStructure={payload.groupStructure} />
      </div>
    </section>
  );
};

GroupStructure.propTypes = {
  payload: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  itemsToExport: PropTypes.object.isRequired,
  print: PropTypes.bool.isRequired,
};

GroupStructure.defaultProps = {
  disabled: false,
};

export default GroupStructure;
