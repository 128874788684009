import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Col } from 'antd';

import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

import CircleIcon from 'components/Icon/CircleIcon';

import { isValidDate } from 'utils/date';
import { toMaskedCnpj } from 'utils/formatters';
import { useI18nFormatters } from 'hooks/i18nFormatters';

import { translateLevel, translateContactAndRelation } from './utils/translate';

import './styles.less';

const ContactAndRelationCard = ({
  name,
  type,
  content,
  level,
  relation,
  span,
  warning,
  danger,
  taxIdNumber,
  endDate,
  startDate,
  lastUpdateDate,
  ...rest
}) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDateOnly } = useI18nFormatters();

  const customClasses = useMemo(() => {
    return relation
      ? 'relation-and-phone-card-info custom-relation'
      : 'relation-and-phone-card-info';
  }, [relation]);

  const isValidStartDate = useMemo(() => isValidDate(startDate), [startDate]);
  const isValidEndDate = useMemo(() => isValidDate(endDate), [endDate]);
  const isValidLastUpdateDate = useMemo(() => isValidDate(lastUpdateDate), [
    lastUpdateDate,
  ]);

  return (
    <Col span={span} id="relation-and-phone-card" {...rest}>
      <CircleIcon
        icon={translateContactAndRelation(type)?.icon}
        warning={warning}
        danger={danger}
      />

      <div className={customClasses}>
        {relation && (
          <>
            <strong className="custom-title max-lines-2">
              {capitalizeFirstLetter(name)}
            </strong>
            {taxIdNumber && (
              <span>
                CNPJ:
                <strong> {toMaskedCnpj(taxIdNumber)}</strong>
              </span>
            )}
            <span>
              {translate(
                'pages.private.profiles.components.contactAndRelationCard.index.contactAndRelationCard.relation'
              )}{' '}
              <strong>{translate(translateContactAndRelation(type)?.title)}</strong>
            </span>
            <span>
              {translate(
                'pages.private.profiles.components.contactAndRelationCard.index.contactAndRelationCard.level'
              )}{' '}
              <strong>{translate(translateLevel(level))}</strong>
            </span>
            {isValidStartDate && (
              <span>
                {translate(
                  'pages.private.profiles.components.contactAndRelationCard.index.contactAndRelationCard.startDate'
                )}{' '}
                <strong>{i18nFormatDateOnly(startDate, 1)}</strong>
              </span>
            )}
            {isValidEndDate && (
              <span>
                {translate(
                  'pages.private.profiles.components.contactAndRelationCard.index.contactAndRelationCard.endDate'
                )}{' '}
                <strong>{i18nFormatDateOnly(endDate, 1)}</strong>
              </span>
            )}
            {isValidLastUpdateDate && (
              <span>
                {translate(
                  'pages.private.profiles.components.contactAndRelationCard.index.contactAndRelationCard.lastUpdateDate'
                )}{' '}
                <strong>{i18nFormatDateOnly(lastUpdateDate, 1)}</strong>
              </span>
            )}
          </>
        )}

        {!relation && (
          <>
            <strong>{translate(translateContactAndRelation(type)?.title)}</strong>
            <span>{content}</span>
          </>
        )}
      </div>
    </Col>
  );
};

ContactAndRelationCard.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  level: PropTypes.string,
  content: PropTypes.string,
  taxIdNumber: PropTypes.string,
  span: PropTypes.number.isRequired,
  relation: PropTypes.bool,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  lastUpdateDate: PropTypes.string,
};

ContactAndRelationCard.defaultProps = {
  name: '',
  type: '',
  level: '',
  content: '',
  taxIdNumber: '',
  relation: false,
  warning: false,
  danger: false,
  startDate: null,
  endDate: null,
  lastUpdateDate: null,
};

export default ContactAndRelationCard;
