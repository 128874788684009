export const tagColors = [
  {
    color: '20c26b',
    background: '20c26b12',
  },
  {
    color: '2f80ed',
    background: '2f80ed0f',
  },
  {
    color: 'eb5757',
    background: 'ec4c4c0d',
  },
  {
    color: 'faad14',
    background: 'fbaf180f',
  },
  {
    color: 'fc9f2d',
    background: 'fc9f2d0d',
  },
  {
    color: '7159c1',
    background: '#7159c112',
  },
];

export const tagBackgroundColors = (parsedColor) => {
  if (!parsedColor) return null;

  switch (parsedColor.toLowerCase()) {
    case 'x20c26b':
      return {
        background: '20c26b12',
      };
    case 'x2f80ed':
      return {
        background: '2f80ed0f',
      };
    case 'xeb5757':
      return {
        background: 'ec4c4c0d',
      };
    case 'xfaad14':
      return {
        background: 'fbaf180f',
      };
    case 'xfc9f2d':
      return {
        background: 'fc9f2d0d',
      };
    case 'x7159c1':
      return {
        background: '7159c112',
      };

    default:
      return null;
  }
};
