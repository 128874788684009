import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import FraudImage from 'assets/images/Frauds.svg';
import './styles.less';

const I18N_BASE_PATH = 'pages.private.dashboard.index.charts.avoidedFrauds';

const FraudData = ({ data }) => {
  const { i18nNumberToFormattedString } = useI18nFormatters();
  const { t: translate, i18n } = useTranslation();

  return (
    <>
      <div className="imgContainer">
        <img src={FraudImage} alt="" />
      </div>
      <div className="fraudContainer">
        <div>
          <h1>{data.total.toLocaleString(i18n.language)}</h1>
          <span>{translate(`${I18N_BASE_PATH}.labels.loss`)}</span>
          <h2>{i18nNumberToFormattedString(data.total * 6000)}</h2>
        </div>
      </div>
    </>
  );
};

FraudData.propTypes = {
  data: PropTypes.shape({
    total: PropTypes.number,
  }).isRequired,
};

export default FraudData;
