import React from 'react';
import { Row, Col, Typography } from 'antd';
import defaultImage from 'assets/images/default-image.png';
import ZoomedImage from 'components/Image/ZoomedImage';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import NewCircleIcon from 'components/Icon/NewCircleIcon';

import './styles.less';

const { Title } = Typography;

const I18N_BASE_PATH =
  'pages.private.executions.components.tabs.components.documentsValidations.newOcrScreen';

const Box = ({
  children,
  title,
  showButton,
  showImage,
  front,
  back,
  icon,
  subtitle,
  toPrint = false,
}) => {
  const { t: translate } = useTranslation();

  return (
    <>
      <div id="box">
        <Row className="reset-mrg">
          <Col flex="0 1" className="flex center-start mrg-right-10">
            <NewCircleIcon icon={icon} />
          </Col>

          <Col flex="0.95 1" className="dossier-card-content">
            <div className="flex space-between">
              <Title level={4} className="dossier-card-title">
                {title}
              </Title>

              {showButton}
            </div>

            {subtitle && (
              <Typography className="dossier-card-subtitle">{subtitle}</Typography>
            )}

            {showImage && (
              <Row gutter={48} style={{ marginBottom: '2rem' }}>
                <Col span={12}>
                  <strong className="image-title">
                    {translate(`${I18N_BASE_PATH}.imageView.front`)}
                  </strong>
                  <ZoomedImage src={front || defaultImage} toPrint={toPrint} />
                </Col>
                <Col span={12}>
                  <strong className="image-title">
                    {translate(`${I18N_BASE_PATH}.imageView.back`)}
                  </strong>
                  <ZoomedImage src={back || defaultImage} toPrint={toPrint} />
                </Col>
              </Row>
            )}
            <section>{children}</section>
          </Col>
        </Row>
      </div>
    </>
  );
};
Box.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  showButton: PropTypes.bool,
  showImage: PropTypes.bool,
  front: PropTypes.string,
  back: PropTypes.string,
  icon: PropTypes.element.isRequired,
  subtitle: PropTypes.string,
  toPrint: PropTypes.bool,
};

Box.defaultProps = {
  showButton: false,
  showImage: undefined,
  front: undefined,
  back: undefined,
  subtitle: undefined,
  toPrint: false,
};
export default Box;
