const translateWorkLevel = (level) => {
  if (!level)
    return 'pages.private.executions.components.dataSourceModals.personalLaborAndFinancialInformation.utils.translateWorkLevel.hasNotLevel';

  switch (level.toUpperCase()) {
    case 'EMPLOYEE':
      return 'pages.private.executions.components.dataSourceModals.personalLaborAndFinancialInformation.utils.translateWorkLevel.EMPLOYEE';
    case 'ENTREPRENEUR | BUSINESS OWNER':
      return 'pages.private.executions.components.dataSourceModals.personalLaborAndFinancialInformation.utils.translateWorkLevel.ENTREPRENEUR_BUSINESS_OWNER';
    case 'FREELANCE':
      return 'pages.private.executions.components.dataSourceModals.personalLaborAndFinancialInformation.utils.translateWorkLevel.FREELANCE';
    case 'PRODUCT RESELLER':
      return 'pages.private.executions.components.dataSourceModals.personalLaborAndFinancialInformation.utils.translateWorkLevel.PRODUCT_RESELLER';
    case 'SELF-EMPLOYED':
      return 'pages.private.executions.components.dataSourceModals.personalLaborAndFinancialInformation.utils.translateWorkLevel.SELF_EMPLOYED';
    case 'UNKNOW':
      return 'pages.private.executions.components.dataSourceModals.personalLaborAndFinancialInformation.utils.translateWorkLevel.UNKNOW';
    case 'UNKNOWN':
      return 'pages.private.executions.components.dataSourceModals.personalLaborAndFinancialInformation.utils.translateWorkLevel.UNKNOWN';
    default:
      return level.toUpperCase();
  }
};

const translateAssociationType = (type) => {
  if (!type)
    return 'pages.private.executions.components.dataSourceModals.personalLaborAndFinancialInformation.utils.translateAssociationType.hasNotType';

  switch (type.toUpperCase()) {
    case 'CLASS ASSOCIATION':
      return 'pages.private.executions.components.dataSourceModals.personalLaborAndFinancialInformation.utils.translateAssociationType.CLASS_ASSOCIATION';
    case 'PROFESSIONAL ORGANIZATION':
      return 'pages.private.executions.components.dataSourceModals.personalLaborAndFinancialInformation.utils.translateAssociationType.PROFESSIONAL_ORGANIZATION';
    default:
      return 'pages.private.executions.components.dataSourceModals.personalLaborAndFinancialInformation.utils.translateAssociationType.default';
  }
};

const renderWorkStatus = (status) => {
  if (!status)
    return {
      title:
        'pages.private.executions.components.dataSourceModals.personalLaborAndFinancialInformation.utils.renderWorkStatus.hasNotStatus',
      type: 'empty',
    };

  switch (status.toUpperCase()) {
    case 'ACTIVE':
      return {
        title:
          'pages.private.executions.components.dataSourceModals.personalLaborAndFinancialInformation.utils.renderWorkStatus.ACTIVE',
        type: 'primary',
      };
    case 'ATIVO':
      return {
        title:
          'pages.private.executions.components.dataSourceModals.personalLaborAndFinancialInformation.utils.renderWorkStatus.ATIVO',
        type: 'primary',
      };
    case 'BAIXADO':
      return {
        title:
          'pages.private.executions.components.dataSourceModals.personalLaborAndFinancialInformation.utils.renderWorkStatus.BAIXADO',
        type: 'warning',
      };
    case 'CONTATE O CRECI-MS':
      return {
        title:
          'pages.private.executions.components.dataSourceModals.personalLaborAndFinancialInformation.utils.renderWorkStatus.CONTATE_O_CRECI_MS',
        type: 'empty',
      };
    case 'INACTIVE':
      return {
        title:
          'pages.private.executions.components.dataSourceModals.personalLaborAndFinancialInformation.utils.renderWorkStatus.INACTIVE',
        type: 'danger',
      };
    case 'INATIVO':
      return {
        title:
          'pages.private.executions.components.dataSourceModals.personalLaborAndFinancialInformation.utils.renderWorkStatus.INATIVO',
        type: 'danger',
      };
    case '':
      return {
        title:
          'pages.private.executions.components.dataSourceModals.personalLaborAndFinancialInformation.utils.renderWorkStatus.empty',
        type: 'empty',
      };
    default:
      return {
        title:
          'pages.private.executions.components.dataSourceModals.personalLaborAndFinancialInformation.utils.renderWorkStatus.default',
        type: 'empty',
      };
  }
};

export { translateWorkLevel, translateAssociationType, renderWorkStatus };
