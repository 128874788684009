import React from 'react';

import { useAuth } from 'hooks/auth';
import { hasRequiredPermissions } from 'routes/permissionFunctions';

import pdvTenants from 'utils/skyPDVTenants';

import UsersListPDV from './components/UsersListPDV';

const UsersPDV = () => {
  const { user } = useAuth();

  return (
    <>
      {hasRequiredPermissions(user, ['users-pdv:read'], [], false, 'management') &&
        pdvTenants?.includes(user?.tenantId) && <UsersListPDV />}
    </>
  );
};

export default UsersPDV;
