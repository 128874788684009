import React from 'react';
import classnames from 'classnames';
import { Row, Col, Typography, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import NotAvailable from 'components/NotAvailable';
import {
  CompanyRow,
  CompanyCard,
  CompanyLink,
  CompanySubtitle1,
  CompanyTagging,
} from 'pages/private/KYBCompany/CompanyDetailsV2/components';
import Loading from 'components/Loading';
import PropTypes from 'prop-types';
import { getUSALevel } from '../../RiskFactors';

const { Text } = Typography;

const I18N_BASE_PATH = 'pages.private.KYBCompany.pages';
const RISK = `${I18N_BASE_PATH}.companyDetails.subPages.companyInfo.businessIdentity.riskFactors`;
const REASON = 'riskFactors.reason';

const RiskFactorsItems = ({ risks }) => {
  const { t: tr, ready } = useTranslation('risk-factors');

  if (!ready) {
    return <Loading />;
  }

  if (!risks.length) {
    return <NotAvailable card />;
  }

  return risks?.slice(0, 3).map((item) => (
    <>
      <CompanyRow
        className="kyb-company-summary-risk-content kyb-company-print-break-avoid"
        justify="start"
        key={item.name}
      >
        <Col span={24} className="kyb-company-summary-risk-inner">
          <CompanyTagging
            className="kyb-company-summary-risk-tag"
            level={getUSALevel(tr(`${REASON}.${item.name}.level`, ''))}
          >
            {tr(`${REASON}.${item.name}.level`, '')}
          </CompanyTagging>
          <Text className={classnames('typescale-body-2')}>
            {tr(`${REASON}.${item.name}.name`, '') || <NotAvailable />}
          </Text>
        </Col>
      </CompanyRow>
      <Row>
        <Col span={24}>
          <Divider className="no-mrg" />
        </Col>
      </Row>
    </>
  ));
};

const RiskFactorsContent = ({ risks }) => {
  const { t } = useTranslation();

  return (
    <>
      <RiskFactorsItems risks={risks} />
      <Row justify="end">
        <Col className="kyb-company-summary-footer">
          <CompanyLink to="?tab=riskFactors">
            {t(`${I18N_BASE_PATH}.companyDetails.mainCardLink`)}
          </CompanyLink>
        </Col>
      </Row>
    </>
  );
};

const RiskFactors = ({ companyInfo, disabled, lg = 8, ...rest }) => {
  const { t } = useTranslation();
  const risks = companyInfo?.businessIdentity?.risks || [];

  return (
    <Col xs={24} lg={lg}>
      <CompanyCard className={classnames('kyb-company-summary-risk', rest.className)}>
        <Row>
          <Col span={24}>
            <CompanySubtitle1>
              {t(`${RISK}.title`)} {!risks?.length ? '' : `(${risks.length})`}
            </CompanySubtitle1>
          </Col>
        </Row>
        <RiskFactorsContent risks={risks} />
      </CompanyCard>
    </Col>
  );
};

RiskFactors.propTypes = {
  companyInfo: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  lg: PropTypes.number,
};

RiskFactors.defaultProps = {
  lg: 8,
};

export default RiskFactors;
