import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

import PermissionWrapper from 'routes/PermissionWrapper';

import './styles.less';

const CheckpointModel = ({
  title,
  description,
  // openSettings,
  status,
  hasModel,
}) => {
  const { t: translate } = useTranslation();

  // const { personId, companyId } = useParams();

  // const updatePermissions = useMemo(() => {
  //   if (personId) return ['people:update'];
  //   if (companyId) return ['companies:update'];
  //   return [];
  // }, [personId, companyId]);

  return (
    <div id="executions-checkpoint-model-container">
      <div className="checkpoint-model-infos">
        <strong>{title || '-'}</strong>
        <span className="max-lines-2">{description || '-'}</span>
      </div>

      <PermissionWrapper requiredPermissions={['profile-templates:execute']}>
        {hasModel && (
          <Tooltip
            placement="top"
            title={translate(
              'pages.private.executions.components.checkpointModel.index.checkpointModel.tooltip.title'
            )}
            trigger={['hover']}
          >
            <button type="button" className="reset-btn" onClick={() => {}}>
              <i
                className={`caf-ic_processing_line ${
                  status === 'PROCESSING' && 'rotate'
                }`}
              />
            </button>
          </Tooltip>
        )}
      </PermissionWrapper>

      {/* <PermissionWrapper requiredPermissions={updatePermissions}>
        <Tooltip placement="top" title="Configurar modelo" trigger={['hover']}>
          <button type="button" className="reset-btn" onClick={openSettings}>
            <i className="caf-ic_settings" />
          </button>
        </Tooltip>
      </PermissionWrapper> */}
    </div>
  );
};

CheckpointModel.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  // openSettings: PropTypes.func,
  status: PropTypes.string,
  hasModel: PropTypes.bool,
};

CheckpointModel.defaultProps = {
  // openSettings: undefined,
  status: undefined,
  hasModel: false,
};

export default CheckpointModel;
