import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Carousel } from 'antd';

import { useExecution } from 'hooks/execution';

import EmptyExecutionState from 'components/EmptyExecutionState';
import ZoomedImage from 'components/Image/ZoomedImage';

import './styles.less';

const I18N_BASE_PATH =
  'pages.private.executions.components.tabs.components.verifiedIdentity.components.privateFaceSet.index';

const PrivateFacesetComponent = () => {
  const { t: translate } = useTranslation();
  const { execution } = useExecution();

  const sourceSection = useMemo(() => execution?.sections?.privateFaceset || [], [
    execution,
  ]);

  const faceMatches = useMemo(() => sourceSection?.data?.faceMatches || [], [
    sourceSection,
  ]);

  const displayedFaces = useMemo(() => {
    return (
      faceMatches?.length > 0 &&
      faceMatches?.map((face) => {
        return (
          <>
            <div
              key={face?.faceset?.image}
              className="text-center mrg-btm-20 carousel-zoomed-images"
            >
              <span className="text-dark">
                <span className="text-bold" />
              </span>
              <ZoomedImage
                className="carousel-zoomed-image"
                src={face?.faceset?.image}
                label={translate(`${I18N_BASE_PATH}.suspect`)}
              />
              <p className="mrg-top-25">
                {translate(`${I18N_BASE_PATH}.similarity`)}: {face?.similarity}%
              </p>
            </div>
          </>
        );
      })
    );
  }, [faceMatches, translate]);

  return (
    <Row align="middle" justify="center" className="mrg-btm-35">
      {sourceSection?.statusCode !== '01' ? (
        <Col className="mrg-left-20" span={24}>
          <EmptyExecutionState
            statusCode={sourceSection?.statusCode}
            message={sourceSection?.message || sourceSection?.data?.message}
          />
        </Col>
      ) : (
        <>
          {faceMatches?.length > 0 && (
            <>
              <Col span={8}>
                <Carousel className="suspects-carousel" dots dotPosition="bottom">
                  <ZoomedImage
                    src={execution?.images?.front}
                    label={translate(`${I18N_BASE_PATH}.documentFront`)}
                  />
                </Carousel>
              </Col>
              <Col span={8}>
                <Carousel className="suspects-carousel" dots dotPosition="bottom">
                  <ZoomedImage src={execution?.images?.selfie} label="Selfie" />
                </Carousel>
              </Col>
              <Col span={8}>
                <Carousel className="suspects-carousel" dots dotPosition="bottom">
                  {displayedFaces}
                </Carousel>
              </Col>
            </>
          )}

          {faceMatches?.length <= 0 && (
            <>
              <Col className="mrg-left-20" span={24}>
                <EmptyExecutionState
                  statusCode={sourceSection?.statusCode}
                  message={translate(`${I18N_BASE_PATH}.noMatch`)}
                />
              </Col>
            </>
          )}
        </>
      )}
    </Row>
  );
};

export default PrivateFacesetComponent;
