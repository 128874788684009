import { Collapse } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DottedLabel from 'components/DottedLabel';

// import { Container } from './styles';

const { Panel } = Collapse;

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.companies.components.spcReportParticipationScore.otherInfos';

function CounterOrder({ source = {}, toPrint = false }) {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);

  const counterOrder = useMemo(() => parsedData?.counterOrder || {}, [parsedData]);

  return (
    <Collapse className="mrg-top-10" defaultActiveKey={toPrint ? ['1'] : null}>
      <Panel
        disabled
        header={
          <span className="text-dark text-bold">
            {translate(`${I18N_BASE_PATH}.index.countermand.title`)}
          </span>
        }
        extra={
          <>
            <DottedLabel
              description={`(${counterOrder?.total || 0}) ${translate(
                `${I18N_BASE_PATH}.index.restrictions`
              )}`}
              type={+counterOrder?.total > 0 ? 'danger' : 'primary'}
            />
          </>
        }
        key="1"
      />
    </Collapse>
  );
}

export default CounterOrder;
