import React from 'react';
import PropTypes from 'prop-types';
import {
  LineChart as RELineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import ChartTooltip from 'components/ChartTooltip';

const I18N_BASE_PATH = 'pages.private.dashboard.components.charts.line';

const LineChart = (props) => {
  const { t: translate } = useTranslation();
  const { data, width } = props;

  const renderCustomTooltip = (tooltipProps) => {
    const { active, payload } = tooltipProps;

    if (!active) return null;

    return (
      <ChartTooltip
        subtitle={
          <>
            {payload.map(({ value, name }) => (
              <>
                {translate(`${I18N_BASE_PATH}.${name}`)}: {value}
                <br />
              </>
            ))}
          </>
        }
      />
    );
  };

  return (
    <ResponsiveContainer width={width}>
      <RELineChart
        data={data}
        style={{
          fontSize: '0.7rem',
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis dataKey="time" axisLine={false} tickLine={false} interval="preserveEnd" />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickCount={6}
          tickFormatter={(tick) => (tick !== 0 ? tick : '')}
        />
        <Tooltip content={renderCustomTooltip} cursor={false} />
        <Legend
          wrapperStyle={{
            top: -40,
            right: 0,
          }}
          verticalAlign="top"
          align="right"
          payload={[
            {
              value: translate(`${I18N_BASE_PATH}.current`),
              color: '#3751FF',
              type: 'plainline',
              payload: { strokeDasharray: '0' },
            },
            {
              value: translate(`${I18N_BASE_PATH}.previous`),
              color: '#34D690',
              type: 'plainline',
              payload: { strokeDasharray: '0' },
            },
          ]}
        />
        <Line
          type="monotone"
          strokeWidth={2}
          dataKey="current"
          stroke="#3751FF"
          dot={false}
        />
        <Line
          type="monotone"
          strokeWidth={2}
          dataKey="previous"
          stroke="#34D690"
          dot={false}
        />
      </RELineChart>
    </ResponsiveContainer>
  );
};

LineChart.propTypes = {
  width: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.string,
      current: PropTypes.number,
      previous: PropTypes.string,
    })
  ).isRequired,
};

LineChart.defaultProps = {
  width: '100%',
};

export default LineChart;
