import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

import { useExecution } from 'hooks/execution';

import ViewMore from 'components/ViewMore';

import EmptyExecutionState from 'components/EmptyExecutionState';
import * as executionDataSources from 'utils/executionDataSource';

import BasicInfosCard from '../../BasicInfosCard';
import AddressItem from './components/AddressItem';

import './styles.less';

const AddressContent = ({ dataSources }) => {
  const { t: translate } = useTranslation();

  const { execution } = useExecution();

  const [allAddressesVisible, setAllAddressesVisible] = useState(false);

  const sourceName = useMemo(() =>
    execution?.sections?.pfAddressesAlt ? 'pfAddressesAlt' : 'pfAddresses'
  );
  const addressCount = useMemo(
    () =>
      execution?.sections?.[sourceName]?.data?.length ||
      execution?.sections?.[sourceName]?.data?.addresses?.length,
    [execution]
  );

  const statusCode = useMemo(
    () =>
      execution?.sections?.[sourceName]?.statusCode ||
      execution?.sections?.[sourceName]?.data?.statusCode,
    [execution]
  );

  const addressFormatted = useMemo(() => {
    if (!execution?.sections?.[sourceName]) return 'no-recurrence';

    if (
      (execution?.sections?.[sourceName] && !execution?.sections?.[sourceName]?.data) ||
      statusCode === '02'
    ) {
      return 'query-failed';
    }

    if (execution?.sections?.pfAddresses?.data?.length > 0) {
      const addressesArray = execution?.sections?.pfAddresses?.data.map((address) => {
        const {
          addressMain,
          number,
          complement,
          neighborhood,
          city,
          state,
          zipCode,
          type,
        } = address || {};

        return {
          addressMain,
          number,
          complement,
          neighborhood,
          city,
          state,
          zipCode,
          type,
        };
      });
      return addressesArray;
    }
    if (execution?.sections?.pfAddressesAlt?.data?.addresses?.length > 0) {
      const addressesArray = execution?.sections?.pfAddressesAlt?.data?.addresses?.map(
        (address) => {
          const {
            street: addressMain,
            number,
            complement,
            neighborhood,
            city,
            state,
            zipCode,
            type,
          } = address || {};

          return {
            addressMain,
            number,
            complement,
            neighborhood,
            city,
            state,
            zipCode,
            type,
          };
        }
      );
      return addressesArray;
    }

    return 'no-data';
  }, [execution, statusCode]);

  const showAddress = useMemo(
    () =>
      addressFormatted !== 'no-recurrence' &&
      addressFormatted !== 'no-data' &&
      addressFormatted !== 'query-failed',
    [addressFormatted]
  );

  const customShowDivider = useMemo(() => {
    // return dataSources.includes('pf_phones') || dataSources.includes('pf_emails');
    return executionDataSources.hasBasicInformation(execution, dataSources);
  }, [dataSources, execution]);

  return (
    <BasicInfosCard
      icon="caf-ic_map_pin"
      title={translate(
        'pages.private.executions.components.dataSourceCards.basicInfos.components.person.addressContent.addressContent.basicInfosCard.title'
      )}
      dataIndex="addressFormatted"
      customQuery={false}
      showDivider={customShowDivider}
    >
      <>
        {showAddress && addressCount > 3 ? (
          <>
            {addressFormatted?.slice(0, 3)?.map((address) => (
              <Row className="special-address-item-row">
                <Col span={24}>
                  <AddressItem data={address} />
                </Col>
              </Row>
            ))}

            {allAddressesVisible &&
              addressFormatted?.slice(3, addressCount?.length)?.map((address) => (
                <Row>
                  <Col span={24}>
                    <AddressItem data={address} />
                  </Col>
                </Row>
              ))}

            <Row className="mrg-top-8">
              <ViewMore
                type="link"
                title={translate(
                  'pages.private.executions.components.dataSourceCards.basicInfos.components.person.addressContent.addressContent.viewMore.title',
                  { addressCount: addressCount - 3 }
                )}
                icon={!allAddressesVisible ? 'caf-ic_plus' : 'caf-ic_minimize'}
                onClick={() => setAllAddressesVisible((oldState) => !oldState)}
              />
            </Row>
          </>
        ) : (
          <>
            {!showAddress ? (
              <>
                {addressFormatted === 'query-failed' ? (
                  <EmptyExecutionState statusCode={statusCode} />
                ) : (
                  <p>
                    {translate(
                      'components.dataSourceCards.basicInfos.components.person.addressContent.columLabelTitle.hasNotToShowAddress'
                    )}
                  </p>
                )}
              </>
            ) : (
              <>
                {addressFormatted.map((address) => (
                  <Row className="special-address-item-row">
                    <Col span={24}>
                      <AddressItem data={address} />
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </>
        )}
      </>
    </BasicInfosCard>
  );
};

AddressContent.propTypes = {
  person: PropTypes.shape({
    sections: PropTypes.shape({
      pfAddress: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        statusCode: PropTypes.string,
      }),
    }),
    status: PropTypes.string,
  }),
  dataSources: PropTypes.arrayOf(PropTypes.string),
};

AddressContent.defaultProps = {
  person: undefined,
  dataSources: [],
};

export default AddressContent;
