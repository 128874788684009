import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import PersonContactContent from 'pages/private/Profiles/components/Tabs/components/BasicInfos/components/Person/ContactContent';
import CompanyContactContent from 'pages/private/Profiles/components/Tabs/components/BasicInfos/components/Company/ContactContent';

import './styles.less';

const DocumentContacts = ({ data }) => {
  const { personId, companyId } = useParams();

  const dataSources = useMemo(
    () =>
      data?.profileTemplate?.backgroundChecking?.services?.map(({ source }) => source) ||
      [],
    [data]
  );

  return (
    <div id="to-print-contacts">
      {personId && (
        <PersonContactContent person={data} dataSources={dataSources} toPrint />
      )}
      {companyId && <CompanyContactContent company={data} toPrint />}
    </div>
  );
};

DocumentContacts.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DocumentContacts;
