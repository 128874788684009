import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, message, Button } from 'antd';
import PageTitle from 'components/PageTitle';

import { useFetch } from 'services/hooks';

const DeleteUserFromGroup = ({ user, refreshListRef }) => {
  const { post, loading } = useFetch();
  const { t: translate } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const onDeleteUserFromGroup = useCallback(async () => {
    try {
      await post({
        url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/users-groups/no_access/users`,
        payload: { usersIds: [user?.id] },
      });
      refreshListRef.current();
      closeModal();
      message.success(
        translate(
          'pages.private.groups.components.groupContent.deleteUserFromGroup.onDeleteUserFromGroup.messageSuccess'
        )
      );
    } catch {
      message.error(
        translate(
          'pages.private.groups.components.groupContent.deleteUserFromGroup.onDeleteUserFromGroup.messageError'
        )
      );
    }
  }, [post, user, refreshListRef, closeModal, translate]);

  const DeleteUserFromGroupModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        footer={null}
        width={600}
        wrapClassName="caf-modal"
        onCancel={closeModal}
      >
        <div className="no-mrg no-pdd">
          <PageTitle
            align="center"
            title={translate(
              'pages.private.groups.components.groupContent.deleteUserFromGroup.pageTitle.title'
            )}
          />
          <div className="flex center mrg-top-25 mrg-btm-35">
            <span className="text-center font-size-16">
              {translate(
                'pages.private.groups.components.groupContent.deleteUserFromGroup.spanContentBefore'
              )}{' '}
              <strong>{user?.name}</strong>{' '}
              {translate(
                'pages.private.groups.components.groupContent.deleteUserFromGroup.spanContentAfter'
              )}
            </span>
          </div>

          <div className="flex center-end mrg-top-40">
            <Button
              type="default"
              onClick={closeModal}
              className="btn-cancel"
              disabled={loading}
            >
              {translate(
                'pages.private.groups.components.groupContent.deleteUserFromGroup.buttons.cancel'
              )}
            </Button>
            <Button
              type="danger"
              htmlType="submit"
              loading={loading}
              disabled={loading}
              onClick={onDeleteUserFromGroup}
            >
              {loading
                ? translate(
                    'pages.private.groups.components.groupContent.deleteUserFromGroup.buttons.loading'
                  )
                : translate(
                    'pages.private.groups.components.groupContent.deleteUserFromGroup.buttons.okText'
                  )}
            </Button>
          </div>
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, user, loading, onDeleteUserFromGroup, translate]
  );

  return { openModal, closeModal, DeleteUserFromGroupModal };
};

export default DeleteUserFromGroup;
