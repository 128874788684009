import React, { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Dropdown,
  Menu,
  Spin,
  Typography,
  notification,
  Form,
  Select,
  Badge,
} from 'antd';
import { Notification as NotificationIcon } from '@combateafraude/icons/general';
import { differenceInSeconds, parseISO, formatISO } from 'date-fns';
import { useAuth } from 'hooks/auth';

// import { useFetch } from 'services/hooks';
import useInterval from 'customHooks/useInterval';
import _ from 'lodash';

import { messageNotification, typeNotification } from 'utils/handlerNotifications';

import EmptyMessage from 'components/EmptyMessage';
import useInfiniteScroll from 'components/List/hooks/useInfiniteScroll';
import Notification from 'assets/images/Notification.svg';
import useWindowResize from 'customHooks/useWindowResize';

import { NavLink } from 'react-router-dom';
import NotificationItem from './components/NotificationItem';

import './styles.less';

const { Title } = Typography;
const { Option } = Select;

const I18N_BASE_PATH = 'components.notifications.index';

const MenuNotifications = ({ notificationsList, loading, notificationsFilter }) => {
  const { t: translate } = useTranslation();

  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      const type = { _type: undefined };
      if (values.type) {
        type._type = values.type && values.type !== 'all' ? values.type : undefined;
      }

      notificationsFilter({
        ...values,
        ...type,
      });
    }, 500),
    []
  );

  const { user } = useAuth();

  const hasOnboardingConfigs = useMemo(() => {
    return user?.configs?.notifications?.onboarding;
  }, [user]);

  const hasTransactionTemplateConfigs = useMemo(() => {
    return user?.configs?.notifications?.transactionTemplate;
  }, [user]);

  return (
    <Menu id="menu-notifications">
      <div className="title-notifications">
        <Title>{translate(`${I18N_BASE_PATH}.menuNotifications.menu.title`)}</Title>
        <Form
          layout="horizontal"
          onValuesChange={handleFormChange}
          initialValues={{ _order: -1 }}
          className="form-filter"
        >
          <div className="no-pdd">
            <Form.Item name="type">
              <Select
                bordered={false}
                style={{ width: 190, fontWeight: 500 }}
                className="text-dark"
                defaultValue="all"
              >
                <Option value="all">
                  {translate(
                    `${I18N_BASE_PATH}.menuNotifications.menu.select.options.all`
                  )}
                </Option>
                <Option value="downloads">
                  {translate(
                    `${I18N_BASE_PATH}.menuNotifications.menu.select.options.downloads`
                  )}
                </Option>
                <Option value="warning">
                  {translate(
                    `${I18N_BASE_PATH}.menuNotifications.menu.select.options.warning`
                  )}
                </Option>
                {hasOnboardingConfigs && (
                  <Option value="links">
                    {translate(
                      `${I18N_BASE_PATH}.menuNotifications.menu.select.options.onboardingLinks`
                    )}
                  </Option>
                )}
                {hasTransactionTemplateConfigs && (
                  <Option value="models">
                    {translate(
                      `${I18N_BASE_PATH}.menuNotifications.menu.select.options.transactionTemplates`
                    )}
                  </Option>
                )}
              </Select>
            </Form.Item>
          </div>
        </Form>
        {loading && <Spin size="small" />}
      </div>

      <Menu.Divider />

      {notificationsList}

      {loading && (
        <div className="flex center">
          <Spin size="small" />
        </div>
      )}
      <Menu.Divider />
      <Menu.Item disabled={loading} className="ant-menu-item">
        <NavLink to="/notifications?tab=active" className="ant-item">
          <span>
            {translate(`${I18N_BASE_PATH}.menuNotifications.menu.notificationCenter`)}
          </span>
        </NavLink>
      </Menu.Item>
    </Menu>
  );
};

MenuNotifications.propTypes = {
  notificationsList: PropTypes.element.isRequired,
  loading: PropTypes.bool.isRequired,
  notificationsFilter: PropTypes.func.isRequired,
};

const EmptyNotification = ({ show }) => {
  const { t: translate } = useTranslation();

  return (
    <div className="empty-notifications">
      <EmptyMessage
        show={show}
        type="empty"
        description={translate(
          `${I18N_BASE_PATH}.emptyNotification.emptyMessage.description`
        )}
      />
    </div>
  );
};

EmptyNotification.propTypes = {
  show: PropTypes.bool.isRequired,
};

const Notifications = () => {
  const { t: translate } = useTranslation();

  const [badgeSize, setBadgeSize] = useState('default');
  const [isOpened, setIsOpened] = useState(false);

  useWindowResize((width) => {
    if (width < 1200) {
      setBadgeSize('small');
    } else {
      setBadgeSize('default');
    }
  }, []);

  const {
    data,
    componentList,
    loading,
    reloadList,
    setFilterData: notificationsFilter,
  } = useInfiniteScroll({
    getParams: {
      url: '/notifications',
      config: {
        params: {
          _limit: 5,
          _configs: true,
        },
      },
    },
    rowKey: '_id',
    component: NotificationItem,
    hiddenLoading: true,
    emptyComponent: EmptyNotification,
    customScroll: true,
  });

  const displayNewNotifications = useCallback(() => {
    if (!data?.docs?.length > 0) return;

    const currentDate = parseISO(formatISO(new Date()));

    data.docs.forEach((item) => {
      if (item?.isRead) return;

      const dateToCompare = parseISO(formatISO(new Date(item?.createdAt)));
      const _difference = differenceInSeconds(currentDate, dateToCompare);

      if (_difference < 60) {
        const notificationArgs = {
          type: typeNotification(item?.status),
          message: translate(messageNotification(item?.status, item?.type)),
          description: translate(
            `${I18N_BASE_PATH}.notifications.displayNewNotifications.notificationArgs.description`
          ),
          duration: 3,
          className: 'custom-notification hover',
          onClick() {
            setIsOpened(true);
          },
        };

        notification.open(notificationArgs);
      }
    });
  }, [data, translate]);

  useInterval(() => {
    // refreshes list every 30 seconds
    if (!isOpened) {
      reloadList();
      displayNewNotifications();
    }
  }, 30000);

  return (
    <Dropdown
      overlay={
        <MenuNotifications
          notificationsList={componentList}
          loading={loading}
          notificationsFilter={notificationsFilter}
        />
      }
      visible={isOpened}
      onVisibleChange={(visible) => {
        setIsOpened(visible);
        if (visible) {
          reloadList();
        }
      }}
      trigger={['click']}
      placement="bottomLeft"
      className="notification-dropdown"
    >
      <Badge count={data?.hasUnreadNotification} style={{ zIndex: 10 }} size={badgeSize}>
        <button type="button" className="notification-btn reset-btn">
          <NotificationIcon />
        </button>
      </Badge>
    </Dropdown>
  );
};

export default Notifications;
