import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';

import './styles.less';

const TableInfoColumnCard = ({ title, content, type, span, bordered }) => {
  return (
    <Col id="card-table-info-column" span={span}>
      <div className={`card-table-info-column-data ${bordered && 'custom-border'}`}>
        <strong>{title}</strong>
        <span className={type}>{content || '-'}</span>
      </div>
    </Col>
  );
};

TableInfoColumnCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
  bordered: PropTypes.bool,
  span: PropTypes.number.isRequired,
};

TableInfoColumnCard.defaultProps = {
  content: '-',
  type: 'default',
  bordered: true,
};

export default TableInfoColumnCard;
