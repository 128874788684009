import React from 'react';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParams } from 'use-query-params';
import {
  CompanySummaryCounterCard,
  CompanyTabs,
  CompanyTabPane,
  CompanySubtitle1,
} from 'pages/private/KYBCompany/CompanyDetailsV2/components';
import DirectorsContent from './DirectorsContent';
import ShareHoldersContent from './ShareHoldersContent';
import PersonWithSignificantControlContent from './PersonWithSignificantControlContent';
import usePeopleCredit from '../../hooks/UsePeopleCredit';
import './styles.less';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companyDetails.subPages.people.creditReport';

const CreditReportComponent = ({
  creditReportData,
  itemsToExport,
  render = true,
  print = false,
}) => {
  const { t: translate } = useTranslation();

  const {
    currentDirectorsLength,
    previousDirectorsLength,
    shareHoldersLength,
    activePersonsWithSignificantControlLength,
    ceasedPersonsWithSignificantControlLength,
    summaryCounter,
  } = usePeopleCredit({ creditReportData });

  const [query, setQuery] = useQueryParams({
    tab: StringParam,
    subTab: StringParam,
  });

  const subTabsAllowed = ['directors', 'shareholders', 'PSCAndUBO'];

  const getActiveTab = (tab) => {
    return subTabsAllowed.includes(tab) ? tab : 'directors';
  };

  if (!render) return null;

  return (
    <>
      <div className="kyb-company-summary-counter-card">
        <CompanySummaryCounterCard
          payload={summaryCounter}
          render={itemsToExport?.value}
        />
      </div>
      <aside className="KYB-people-creditreport">
        <CompanyTabs
          className="wrapper-tabs"
          defaultActiveKey="summary"
          activeKey={getActiveTab(query?.subTab)}
          onChange={(subTab) => {
            setQuery(() => ({ subTab }));
          }}
          destroyInactiveTabPane
          print={print}
        >
          <CompanyTabPane
            print={print}
            tab={translate(`${I18N_BASE_PATH}.tabs.directors.title`, 'Directors')}
            key="directors"
            render={!print || itemsToExport?.items.directors.value === true}
          >
            <CompanySubtitle1 render={print}>
              {translate(`${I18N_BASE_PATH}.tabs.directors.currentDirectors.title`)}
            </CompanySubtitle1>
            <DirectorsContent
              currentDirectorsLength={currentDirectorsLength}
              previousDirectorsLength={previousDirectorsLength}
              previousDirectorsList={creditReportData?.previousDirectors ?? []}
              creditReportData={creditReportData}
              print={print}
            />
          </CompanyTabPane>
          <CompanyTabPane
            print={print}
            tab={translate(`${I18N_BASE_PATH}.tabs.shareholders.title`, 'Shareholders')}
            key="shareholders"
            render={!print || itemsToExport?.items.shareholders.value === true}
          >
            <CompanySubtitle1 render={print}>
              {translate(`${I18N_BASE_PATH}.tabs.shareholders.currentShareholders.title`)}
            </CompanySubtitle1>
            <ShareHoldersContent
              shareHoldersLength={shareHoldersLength}
              shareholdersList={creditReportData?.shareHolders}
              print={print}
            />
          </CompanyTabPane>
          <CompanyTabPane
            print={print}
            tab={translate(
              `${I18N_BASE_PATH}.tabs.personWithSignificantControl.title`,
              'PSC/UBO'
            )}
            key="PSCAndUBO"
            render={!print || itemsToExport?.items.pscUbo.value === true}
          >
            <CompanySubtitle1 render={print}>
              {translate(
                `${I18N_BASE_PATH}.tabs.directors.personWithSignificantControl.cardCount.title`
              )}
            </CompanySubtitle1>
            <PersonWithSignificantControlContent
              creditReportData={creditReportData}
              activePersonsWithSignificantControlLength={
                activePersonsWithSignificantControlLength
              }
              active={activePersonsWithSignificantControlLength}
              ceased={ceasedPersonsWithSignificantControlLength}
              total={
                activePersonsWithSignificantControlLength +
                ceasedPersonsWithSignificantControlLength
              }
              print={print}
            />
          </CompanyTabPane>
        </CompanyTabs>
      </aside>
    </>
  );
};

export default CreditReportComponent;
