import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyExecutionState from 'components/EmptyExecutionState';
import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';

import CompanyRegistrationInfosContent from './components/RegistrationInfosContent';
import CompanyQSAInfosContent from './components/QSAInfosContent';
import CompanyContactContent from './components/ContactContent';
import CompanyAddressContent from './components/AddressContent';

const PjData = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();
  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_file"
      title={translate(
        'components.dataSourceCards.basicInfos.components.basicInfosCard.sources.pjData'
      )}
      dataIndex="basicData"
      toPrint={toPrint}
    >
      {!queryWorked ? (
        <EmptyExecutionState
          className="mrg-top-10"
          statusCode={statusCode}
          message={parsedData?.message}
        />
      ) : (
        <>
          <CompanyRegistrationInfosContent company={parsedData} toPrint={toPrint} />
          <CompanyQSAInfosContent source={parsedData} toPrint={toPrint} />
          <CompanyAddressContent company={parsedData} toPrint={toPrint} />
          <CompanyContactContent company={parsedData} toPrint={toPrint} />
        </>
      )}
    </DataSourceCardCollapsePanel>
  );
};

export default PjData;
