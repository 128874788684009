import { useCallback } from 'react';
import { useFetch } from 'services/hooks';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { useTranslation } from 'react-i18next';
import { epochToDate } from 'utils/date';
import { useAuth } from 'hooks/auth';

import { normalizeChartData } from './normalizers/chartData';
import { normalizeInvoiceList } from './normalizers/invoiceList';
import { normalizeInvoiceDetails } from './normalizers/invoiceDetails';

export const cafDetails = {
  name: 'CAF',
  address: 'R. Tiradentes, 1077, 95800-000, Venâncio Aires/Rio Grande do Sul, BR',
  document: '34.102.645/0001-57',
};

export default function useAmberflo() {
  const {
    get: getConsumption,
    loading: loadingConsumption,
    error: consumptionError,
  } = useFetch();
  const { get: getInvoicesList, loading: loadingInvoicesList } = useFetch();
  const { get: getInvoiceDetails, loading: loadingInvoiceDetails } = useFetch();

  const { user } = useAuth();
  const { i18n } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const fetchInvoicesList = useCallback(
    async (startDate, endDate) => {
      const response = await getInvoicesList({
        url: `${process.env.REACT_APP_BASE_URL_MANAGEMENT_API}/invoices?startDate=${startDate}&endDate=${endDate}`,
      });

      if (!response.docs) return;

      return normalizeInvoiceList(response.docs);
    },
    [getInvoicesList]
  );

  const fetchConsumptions = useCallback(
    async (startDate, endDate) => {
      let timeGroupingInterval = 'DAY';

      // difference is 1 month
      if (endDate - startDate > 31 * 24 * 60 * 60) {
        timeGroupingInterval = 'WEEK';
      }

      // difference is 4 month
      if (endDate - startDate > 120 * 24 * 60 * 60) {
        timeGroupingInterval = 'MONTH';
      }

      const response = await getConsumption({
        url: `${process.env.REACT_APP_BASE_URL_MANAGEMENT_API}/consumption?startTimeInSeconds=${startDate}&endTimeInSeconds=${endDate}&timeGroupingInterval=${timeGroupingInterval}`,
        showError: false,
        showMessage: false,
      });

      if (!response.docs) return;

      return normalizeChartData(response.docs, (date) =>
        i18nFormatDate(epochToDate(date), 12)
      );
    },
    [getConsumption, i18nFormatDate]
  );

  const fetchInvoiceDetails = useCallback(
    async (id) => {
      const response = await getInvoiceDetails({
        url: `${process.env.REACT_APP_BASE_URL_MANAGEMENT_API}/invoices/${
          id ?? 'latest'
        }`,
        showError: false,
        showMessage: false,
      });

      if (!response.doc) return;

      return normalizeInvoiceDetails(
        response.doc,
        i18n.language,
        user.accountData.companyName
      );
    },
    [getInvoiceDetails, i18n, user]
  );

  return {
    fetchConsumptions,
    loadingConsumption,
    consumptionError,
    fetchInvoiceDetails,
    loadingInvoiceDetails,
    fetchInvoicesList,
    loadingInvoicesList,
  };
}
