import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Col } from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import es from 'react-phone-input-2/lang/es.json';
import pt from 'react-phone-input-2/lang/pt.json';
import FileUpload from 'components/Files/FileUpload';
import InputMask from 'components/Form/InputMask';
import './styles.less';

const language = {
  'pt-BR': pt,
  'es-MX': es,
  'en-US': undefined,
};

const InputInfo = ({
  type,
  name,
  max,
  min,
  label,
  tooltip,
  rules,
  mask,
  placeholder,
  country,
  supportedFiles,
  title,
  form,
  exclusiveCountryTooltip,
  files,
  setFiles,
  missingFiles,
}) => {
  const { t: translate, i18n } = useTranslation();
  const handleChangeFile = useCallback(
    (value) => {
      setFiles((currentFiles) => ({ ...currentFiles, [name]: value }));
    },
    [name, setFiles]
  );
  const validateBirthDate = useCallback((value) => {
    const formattedDate = value.replace(/[/_]/g, '');
    if (formattedDate.length >= 1 && formattedDate.length < 8) {
      return false;
    }
    return true;
  }, []);
  const renderTooltip = useMemo(() => {
    if (!tooltip) return;
    if (exclusiveCountryTooltip)
      return <div className="tooltip-docs">{translate(`${tooltip}.${country}`)}</div>;
    return translate(tooltip);
  }, [exclusiveCountryTooltip, tooltip, country]);
  if (type === 'inputString')
    return (
      <Col span={12} className="width-100 mrg-top-5" id="input-info">
        <Form.Item
          className="no-mrg no-pdd text-dark"
          name={name}
          label={translate(label)}
          rules={rules}
          tooltip={renderTooltip}
        >
          <Input mask={mask} placeholder={placeholder} />
        </Form.Item>
      </Col>
    );
  if (type === 'inputDate') {
    const dateRules = rules ? [...rules] : [];
    dateRules.push({
      validator: (_, value = '') => {
        const isValidDate = validateBirthDate(value);
        if (!isValidDate && value.length) {
          return Promise.reject(
            new Error(
              translate(
                'utils.personalTranslations.executionCpfErrosTranslation.02.info',
                'Invalid date of birth'
              )
            )
          );
        }
        return Promise.resolve();
      },
    });
    return (
      <Col span={12} className="width-100 mrg-top-5">
        <Form.Item
          className="no-mrg no-pdd text-dark"
          name={name}
          label={translate(label)}
          rules={dateRules}
          tooltip={renderTooltip}
        >
          <InputMask mask="99/99/9999" placeholder="DD/MM/YYYY" />
        </Form.Item>
      </Col>
    );
  }
  if (type === 'phoneNumber')
    return (
      <Col span={12} className="width-100 mrg-top-5" id="input-info">
        <Form.Item
          className="no-mrg no-pdd text-dark width-100"
          name={name}
          rules={rules}
          label={translate(label)}
          tooltip={renderTooltip}
        >
          <PhoneInput
            localization={language[i18n.language]}
            country={country?.toLowerCase()}
            className="input-phone width-100"
          />
        </Form.Item>
      </Col>
    );
  if (type === 'imageUpload')
    return (
      <Col span={24} className="width-100 mrg-top-5" id="input-info">
        <Form.Item
          label={translate(label)}
          className="no-mrg no-pdd text-dark width-100"
          tooltip={renderTooltip}
        >
          <FileUpload
            title={translate(title)}
            file={files[name]}
            isInvalid={!!missingFiles[name] && !files[name]}
            onChangeFile={(value) => handleChangeFile(value)}
            supportedFiles={supportedFiles}
          />
        </Form.Item>
      </Col>
    );
};

export default InputInfo;
