export const statesFromCA = [
  {
    value: 'AB',
    label: 'states.CA.AB',
  },
  {
    value: 'BC',
    label: 'states.CA.BC',
  },
  {
    value: 'MB',
    label: 'states.CA.MB',
  },
  {
    value: 'NB',
    label: 'states.CA.NB',
  },
  {
    value: 'NL',
    label: 'states.CA.NL',
  },
  {
    value: 'NS',
    label: 'states.CA.NS',
  },
  {
    value: 'NT',
    label: 'states.CA.NT',
  },
  {
    value: 'NU',
    label: 'states.CA.NU',
  },
  {
    value: 'ON',
    label: 'states.CA.ON',
  },
  {
    value: 'PE',
    label: 'states.CA.PE',
  },
  {
    value: 'QC',
    label: 'states.CA.QC',
  },
  {
    value: 'SK',
    label: 'states.CA.SK',
  },
  {
    value: 'YT',
    label: 'states.CA.YT',
  },
];

export const statesFromUS = [
  {
    value: 'AL',
    label: 'states.US.AL',
  },
  {
    value: 'AK',
    label: 'states.US.AK',
  },
  {
    value: 'AZ',
    label: 'states.US.AZ',
  },
  {
    value: 'AR',
    label: 'states.US.AR',
  },
  {
    value: 'CA',
    label: 'states.US.CA',
  },
  {
    value: 'CO',
    label: 'states.US.CO',
  },
  {
    value: 'CT',
    label: 'states.US.CT',
  },
  {
    value: 'DE',
    label: 'states.US.DE',
  },
  {
    value: 'FL',
    label: 'states.US.FL',
  },
  {
    value: 'GA',
    label: 'states.US.GA',
  },
  {
    value: 'HI',
    label: 'states.US.HI',
  },
  {
    value: 'ID',
    label: 'states.US.ID',
  },
  {
    value: 'IL',
    label: 'states.US.IL',
  },
  {
    value: 'IN',
    label: 'states.US.IN',
  },
  {
    value: 'IA',
    label: 'states.US.IA',
  },
  {
    value: 'KS',
    label: 'states.US.KS',
  },
  {
    value: 'KY',
    label: 'states.US.KY',
  },
  {
    value: 'LA',
    label: 'states.US.LA',
  },
  {
    value: 'ME',
    label: 'states.US.ME',
  },
  {
    value: 'MD',
    label: 'states.US.MD',
  },
  {
    value: 'MA',
    label: 'states.US.MA',
  },
  {
    value: 'MI',
    label: 'states.US.MI',
  },
  {
    value: 'MN',
    label: 'states.US.MN',
  },
  {
    value: 'MS',
    label: 'states.US.MS',
  },
  {
    value: 'MO',
    label: 'states.US.MO',
  },
  {
    value: 'MT',
    label: 'states.US.MT',
  },
  {
    value: 'NE',
    label: 'states.US.NE',
  },
  {
    value: 'NV',
    label: 'states.US.NV',
  },
  {
    value: 'NH',
    label: 'states.US.NH',
  },
  {
    value: 'NJ',
    label: 'states.US.NJ',
  },
  {
    value: 'NM',
    label: 'states.US.NM',
  },
  {
    value: 'NY',
    label: 'states.US.NY',
  },
  {
    value: 'NC',
    label: 'states.US.NC',
  },
  {
    value: 'ND',
    label: 'states.US.ND',
  },
  {
    value: 'OH',
    label: 'states.US.OH',
  },
  {
    value: 'OK',
    label: 'states.US.OK',
  },
  {
    value: 'OR',
    label: 'states.US.OR',
  },
  {
    value: 'PA',
    label: 'states.US.PA',
  },
  {
    value: 'RI',
    label: 'states.US.RI',
  },
  {
    value: 'SC',
    label: 'states.US.SC',
  },
  {
    value: 'SD',
    label: 'states.US.SD',
  },
  {
    value: 'TN',
    label: 'states.US.TN',
  },
  {
    value: 'TX',
    label: 'states.US.TX',
  },
  {
    value: 'UT',
    label: 'states.US.UT',
  },
  {
    value: 'VT',
    label: 'states.US.VT',
  },
  {
    value: 'VA',
    label: 'states.US.VA',
  },
  {
    value: 'WA',
    label: 'states.US.WA',
  },
  {
    value: 'WV',
    label: 'states.US.WV',
  },
  {
    value: 'WI',
    label: 'states.US.WI',
  },
  {
    value: 'WY',
    label: 'states.US.WY',
  },
];
