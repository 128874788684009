/* eslint-disable react/prop-types */
import React, { useState, useCallback, useMemo } from 'react';
import { Col, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { fieldLabelMappings } from './mapping';

const DriverInfosDetails = () => {
  const { t: translate } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [driver, setDriver] = useState({});

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback((data) => {
    setDriver(data);
    setModalVisible(true);
  }, []);

  const content = useMemo(() => {
    if (!driver) return <></>;
    const driverKeys = Object.keys(driver || {});

    const elements = driverKeys.map((key) => {
      if (fieldLabelMappings(key)) {
        return (
          <Row className="mrg-btm-10">
            <Col span={24}>
              <span className="text-bold text-dark">
                {translate(fieldLabelMappings(key))}:
              </span>{' '}
              {driver[key]}
            </Col>
          </Row>
        );
      }

      return <></>;
    });

    return elements;
  }, [driver, translate]);

  const DriverInfosDetailsModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        onCancel={closeModal}
        footer={null}
        width={700}
        title={driver?.driverName}
        destroyOnClose
      >
        {content?.length ? content : '-'}
      </Modal>
    ),
    [modalVisible, closeModal, driver, content]
  );

  return {
    openModal,
    closeModal,
    DriverInfosDetailsModal,
  };
};

export default DriverInfosDetails;
