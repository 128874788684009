import { Col, Collapse, Row } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DottedLabel from 'components/DottedLabel';

// import { Container } from './styles';

const { Panel } = Collapse;

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.companies.components.spcReportParticipationScore.otherInfos';

function CounterOrderDifferentDocument({ source = {}, toPrint = false }) {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);

  const counterOrderDifferentDocument = useMemo(
    () => parsedData?.counterOrderDifferentDocument || {},
    [parsedData]
  );

  return (
    <Collapse
      className="mrg-btm-10 mrg-top-10 reset-mrg reset-pdd"
      defaultActiveKey={toPrint ? ['1'] : null}
    >
      <Panel
        disabled
        header={
          <Row align="middle" justify="space-between">
            <Col span={17}>
              <span className="text-dark text-bold">
                {translate(`${I18N_BASE_PATH}.index.countermandDifferentDocument.title`)}
              </span>
            </Col>
            <Col className="mrg-right-15">
              <DottedLabel
                description={`(${counterOrderDifferentDocument?.total || 0}) ${translate(
                  `${I18N_BASE_PATH}.index.restrictions`
                )}`}
                type={+counterOrderDifferentDocument?.total > 0 ? 'danger' : 'primary'}
              />
            </Col>
          </Row>
        }
        key="1"
      />
    </Collapse>
  );
}

export default CounterOrderDifferentDocument;
