import React, { useCallback } from 'react'; // useEffect
import PropTypes from 'prop-types';
import { Row, Col, Typography, Form } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';
import FeatureFlagWrapper from 'components/FeatureFlagWrapper';

import Checkbox from 'components/Form/Checkbox';

const { Text } = Typography;

const RenderPanelContentRow = ({ service, disabled, onChange }) => {
  const { i18nNumberToFormattedString } = useI18nFormatters();

  const onCheckboxClick = useCallback(
    (e) => {
      const data = e?.target?.checked
        ? {
            [service.source]: {
              ...service,
            },
          }
        : {
            [service.source]: undefined,
          };

      if (onChange) onChange(data, service.category);
    },
    [onChange, service]
  );

  // useEffect(() => {
  //   if (formRef.getFieldValue()[categoryRef]);
  // }, [categoryRef, formRef]);

  // useEffect(() => {
  //   if (formRef.getFieldValue()[categoryRef]);
  //   const source = formRef.getFieldValue(['backgroundCheckingServices', service.source]);
  //   if (source) setChecked(true);
  // }, [formRef, service, categoryRef]);

  return (
    <Row key={service.source} className="data-source-checkbox">
      <Col className="column-checkbox">
        <Form.Item
          name={['backgroundCheckingServices', service.source]}
          noStyle
          valuePropName="checked"
        >
          <Checkbox
            disabled={disabled}
            // checked={formRef.getFieldValue([
            //   'backgroundCheckingServices',
            //   service.source,
            // ])}
            onChange={onCheckboxClick}
          >
            <Text className="max-lines-1">
              <Text className="text-dark" title={service.title}>
                {service.title}
              </Text>
              <FeatureFlagWrapper featureFlag="costPrices">
                <Text className="data-source-price">
                  + {i18nNumberToFormattedString(service.price || 0, 'pt-BR')}
                </Text>
              </FeatureFlagWrapper>
            </Text>
          </Checkbox>
        </Form.Item>
      </Col>
    </Row>
  );
};

RenderPanelContentRow.propTypes = {
  service: PropTypes.shape({
    source: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    price: PropTypes.number,
    category: PropTypes.string.isRequired,
    relatedRules: PropTypes.arrayOf(PropTypes.shape({ rule: PropTypes.string })),
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  // categoryRef: PropTypes.string.isRequired,
};

export default RenderPanelContentRow;
