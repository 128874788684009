import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useExecution } from 'hooks/execution';
import { useFetch } from 'services/hooks';
import PendingKYBIcon from 'assets/images/ic_pending-kyb.svg';
import ApprovedKYBIcon from 'assets/images/ic_approved-kyb.svg';
import ReprovedKYBIcon from 'assets/images/ic_reproved-kyb.svg';

import './styles.less';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companyDetails.components.executionCardStatus';

const PendingCardStatus = ({ translate, onApprove, onReprove, loading }) => (
  <div className="KYB-execution-card-status execution-card-status-pending">
    {loading && (
      <div className="status-loading-overlay">
        <span>Loading...</span>
      </div>
    )}
    <div className="execution-card-status-wrapper-infos">
      <img src={PendingKYBIcon} alt="Status Pending" />
      <span className="execution-card-status-description">
        {translate(`${I18N_BASE_PATH}.pendingCardStatus.title`, 'Pending')}
      </span>
    </div>
    <div className="execution-card-status-wrapper-action company-print-no-render">
      <button
        type="button"
        className="action-execution-status-button approve"
        onClick={onApprove}
      >
        {translate(`${I18N_BASE_PATH}.buttons.approved`, 'Approved')}
      </button>
      <button
        type="button"
        className="action-execution-status-button reprove"
        onClick={onReprove}
      >
        {translate(`${I18N_BASE_PATH}.buttons.reject`, 'Reject')}
      </button>
    </div>
  </div>
);

const ApprovedCardStatus = ({ translate }) => {
  return (
    <div className="KYB-execution-card-status execution-card-status-approved">
      <div className="execution-card-status-wrapper-infos">
        <img src={ApprovedKYBIcon} alt="Status Approved" />
        <span className="execution-card-status-description">
          {translate(`${I18N_BASE_PATH}.approvedCardStatus.title`, 'Approved')}
        </span>
      </div>
    </div>
  );
};

const ReprovedCardStatus = ({ translate }) => (
  <div className="KYB-execution-card-status execution-card-status-reproved">
    <div className="execution-card-status-wrapper-infos">
      <img src={ReprovedKYBIcon} alt="Status Rejected" />
      <span className="execution-card-status-description">
        {translate(`${I18N_BASE_PATH}.rejectedCardStatus.title`, 'Rejected')}
      </span>
    </div>
  </div>
);

const ExecutionCardStatus = ({ type, ...rest }) => {
  const { t: translate } = useTranslation();

  const { execution, refreshExecution } = useExecution();
  const { loading: loadingChangeStatus, patch } = useFetch();

  const requestChangeStatus = async (status) => {
    const payloadReview = [];

    execution?.reviews?.map((review) => {
      payloadReview.push({
        id: review.id,
        type: 'RULE',
        status,
      });
    });
    await patch({
      url: `/executions/${execution?._id}/transaction-manual-review`,
      payload: payloadReview,
    });
    refreshExecution();
  };

  const onApprove = useCallback(async () => {
    await requestChangeStatus('APPROVED');
  });

  const onReprove = useCallback(async () => {
    await requestChangeStatus('REPROVED');
  });

  const cards = {
    pending: (
      <PendingCardStatus
        translate={translate}
        onApprove={onApprove}
        onReprove={onReprove}
        loading={loadingChangeStatus}
        {...rest}
      />
    ),
    approved: <ApprovedCardStatus translate={translate} {...rest} />,
    reproved: <ReprovedCardStatus translate={translate} {...rest} />,
  };

  return (
    <>
      {cards[type?.toLowerCase()] ?? (
        <PendingCardStatus translate={translate} {...rest} />
      )}
    </>
  );
};

export default ExecutionCardStatus;

ExecutionCardStatus.propTypes = {
  type: PropTypes.string.isRequired,
};

ReprovedCardStatus.propTypes = {
  translate: PropTypes.func.isRequired,
};

ApprovedCardStatus.propTypes = {
  translate: PropTypes.func.isRequired,
};

PendingCardStatus.propTypes = {
  translate: PropTypes.func.isRequired,
};
