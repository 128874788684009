/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import { Timeline } from 'antd';

import { validationStatus } from 'utils/handlerStatus';
import profileValidations from 'utils/data/profileValidations';

import CheckpointStatusIcon from './CheckpointStatusIcon';

import './styles.less';

const CheckpointStatus = ({
  title,
  description,
  status,
  onClick,
  showIcon,
  children,
  rule,
}) => {
  return (
    <Timeline.Item
      dot={
        <CheckpointStatusIcon
          color={validationStatus(status)?.color}
          icon={profileValidations[rule]?.icon}
        />
      }
    >
      <div
        className="checkpoint-status-line-validation"
        style={{ borderColor: validationStatus(status)?.lineColor }}
      />

      <button type="button" className="checkpoint-status-info" onClick={onClick}>
        <div>
          <span>{title || '-'}</span>
          {description && <p className="max-lines-2">{description}</p>}
        </div>

        {showIcon && <i className="caf-ic_arrow_right" />}
      </button>
      <div>{children}</div>
    </Timeline.Item>
  );
};

CheckpointStatus.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  status: PropTypes.string,
  showIcon: PropTypes.bool,
  rule: PropTypes.string,
};

CheckpointStatus.defaultProps = {
  status: 'EMPTY',
  showIcon: true,
  rule: '',
};

export default CheckpointStatus;
