import React, { useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form, Tooltip, Select, Popover, Tag } from 'antd';
import { useQueryParams, StringParam } from 'use-query-params';
import { Link, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useTable } from 'components/List';
import { useAuth } from 'hooks/auth';

import * as pfDataSources from 'utils/pfDataSource';
import * as pjDataSources from 'utils/pjDataSource';

import Button from 'components/Button';
import SearchInput from 'components/Form/SearchInput';
import PageTitle from 'components/PageTitle';
import { CardWrapper } from 'components/CardWrapper';

import useModalShareProfileTemplateLink from 'components/ModalShareProfileTemplateLink';
import useModalCopyLink from 'components/ModalCopyLink';

import PermissionWrapper from 'routes/PermissionWrapper';
import templateServicesCategories from 'utils/data/templateServicesCategories.json';
import useModalProfileTemplateApiGuide from 'components/ModalProfileTemplateApiGuide';
import useModalImportProfileTemplate from 'components/ModalImportProfileTemplate';
// import { onboardingServices } from '../utils/index';

import Wrapper from 'pages/private/wrapper';
import SwitchRenderer from './components/SwitchRenderer';
import DropdownModelOptions from './components/DropdownModelOptions';
import useConfirmAction from '../ConfirmAction';

import './styles.less';

const { Option } = Select;

// eslint-disable-next-line react/prop-types

const ProfileModelList = () => {
  const { isAdmin } = useAuth();
  const { t: translate } = useTranslation();
  const {
    openModal: openApiIntegrationGuideModal,
    ProfileTemplateApiGuideModal,
  } = useModalProfileTemplateApiGuide();

  const [query] = useQueryParams({
    _search: StringParam,
    _order: StringParam,
    _sort: StringParam,
    active: StringParam,
  });
  const refreshListRef = useRef(() => {});
  const history = useHistory();

  const { openModal: openCardCopyLinkModal, CardCopyLinkModal } = useModalCopyLink();

  const { openModal: openConfirmActionModal, ConfirmActionModal } = useConfirmAction({
    refreshListRef,
    type: 'profile-template',
  });

  const {
    openModal: openCardShareLinkModal,
    ShareLinkModal,
  } = useModalShareProfileTemplateLink({
    openCardCopyLinkModal,
  });

  const {
    openModal: openImportProfileTemplateModal,
    ImportProfileTemplateModal,
  } = useModalImportProfileTemplate({
    refreshListRef,
  });

  const renderIcons = (docs) => {
    const services = [];
    const backgroundCheckingServices = [];
    const maxVisibleTags = 5;

    const dataSources =
      docs?.backgroundChecking?.services?.map(({ source }) => source) || [];

    if (pfDataSources.hasAddressInformation({}, dataSources)) {
      services.push({
        title: translate(templateServicesCategories.basic_info.title),
        icon: templateServicesCategories.basic_info.icon,
      });
    }

    if (pfDataSources.hasContactsInformation({}, dataSources)) {
      services.push({
        title: translate(templateServicesCategories.contact.title),
        icon: templateServicesCategories.contact.icon,
      });
    }

    if (
      pfDataSources.hasFinancialInformation({}, dataSources) ||
      pjDataSources.hasFinancialInformation({}, dataSources)
    ) {
      services.push({
        title: translate(templateServicesCategories.finance.title),
        icon: templateServicesCategories.finance.icon,
      });
    }

    if (
      pfDataSources.hasLegalInformation({}, dataSources) ||
      pjDataSources.hasLegalInformation({}, dataSources)
    ) {
      services.push({
        title: translate(templateServicesCategories.justice.title),
        icon: templateServicesCategories.justice.icon,
      });
    }

    if (pfDataSources.hasCriminalInformation({}, dataSources)) {
      services.push({
        title: translate(templateServicesCategories.criminal_background.title),
        icon: templateServicesCategories.criminal_background.icon,
      });
    }

    if (pfDataSources.hasDriverInformation({}, dataSources)) {
      services.push({
        title: translate(templateServicesCategories.driver.title),
        icon: templateServicesCategories.driver.icon,
      });
    }

    if (
      pfDataSources.hasRestrictiveListsInformation({}, dataSources) ||
      pjDataSources.hasRestrictiveListsInformation({}, dataSources)
    ) {
      services.push({
        title: translate(templateServicesCategories.restrictive_lists.title),
        icon: templateServicesCategories.restrictive_lists.icon,
      });
    }

    if (
      pfDataSources.hasLaborInformation({}, dataSources) ||
      pjDataSources.hasLaborInformation({}, dataSources)
    ) {
      services.push({
        title: translate(templateServicesCategories.work.title),
        icon: templateServicesCategories.work.icon,
      });
    }

    if (pfDataSources.hasMediaExposureInformation({}, dataSources)) {
      services.push({
        title: translate(templateServicesCategories.media.title),
        icon: templateServicesCategories.media.icon,
      });
    }

    // docs.onboarding?.services?.forEach((service) => {
    //   if (onboardingServices[service.source]?.icon)
    //     services.push({
    //       icon: onboardingServices[service.source]?.icon,
    //       title:
    //         cafServicesList?.docs?.find((svc) => svc.source === service.source)?.title ||
    //         service.source,
    //     });
    // });

    return (
      <div
        style={{
          maxWidth: 160,
          minWidth: 160,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {services?.slice(0, maxVisibleTags).map((s) => (
          <span className="profile-service-icon-container">
            <Tooltip title={translate(s.title)}>
              <i key={translate(s.title)} style={{ fontSize: 18 }} className={s.icon} />
            </Tooltip>
          </span>
        ))}

        {(services?.length || 0) + (backgroundCheckingServices.length || 0) >
          maxVisibleTags && (
          <Popover
            className="profile-services-icon-popover"
            content={services?.slice(maxVisibleTags).map((s) => (
              <span className="profile-service-icon-container">
                <Tooltip title={translate(s.title)}>
                  <i
                    key={translate(s.title)}
                    style={{ fontSize: 18 }}
                    className={s.icon}
                  />
                </Tooltip>
              </span>
            ))}
            trigger="hover"
            overlayClassName="popover-tag-container"
          >
            <Tag style={{ display: 'inline-block', marginLeft: '8px' }}>
              <span>+{services?.slice(maxVisibleTags).length}</span>
            </Tag>
          </Popover>
        )}
      </div>
    );
  };

  const { tableContent, setFilterData, refreshList } = useTable({
    getParams: {
      url: '/profile-templates',
      config: { params: {} },
    },
    queryParams: {
      _search: StringParam,
      _order: StringParam,
      _sort: StringParam,
      active: StringParam,
    },

    rowKey: '_id',
    columns: [
      {
        key: 'name',
        title: translate(
          'pages.private.profileModels.profileModelList.index.useTable.title.name'
        ),
        dataIndex: ['name'],
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text, docs) => (
          <Link to={`/profile-templates/profile-models/${docs?._id}?type=${docs.type}`}>
            <p className="profile-template-name">{text}</p>
          </Link>
        ),
      },
      {
        key: 'type',
        title: translate(
          'pages.private.profileModels.profileModelList.index.useTable.title.type'
        ),
        dataIndex: ['type'],
      },
      {
        key: 'services',
        title: translate(
          'pages.private.profileModels.profileModelList.index.useTable.title.services'
        ),
        render: (__, docs) => renderIcons(docs),
      },
      {
        key: 'active',
        title: translate(
          'pages.private.profileModels.profileModelList.index.useTable.title.active'
        ),
        dataIndex: ['active'],
        render: (__, docs) => (
          <SwitchRenderer data={docs} openModal={openConfirmActionModal} />
        ),
        // sorter: (a, b) => (a.active === false ? -1 : 0) - (b.active === false ? -1 : 0),
      },

      {
        key: 'dropdown',
        title: '',
        dataIndex: '',
        render: (__, docs) => (
          <>
            {docs?.active && (
              <DropdownModelOptions
                data={docs}
                openCardShareLinkModal={openCardShareLinkModal}
                openApiIntegrationGuideModal={openApiIntegrationGuideModal}
                refreshList={() => {
                  refreshList();
                }}
              />
            )}
          </>
        ),
      },
    ],
    defaultFilter: {
      _order: query?._order || '1',
      _sort: query?._sort || 'name',
      active: query?.active || undefined,
    },
  });

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        ...values,
        active: values.active && values.active !== 'all' ? values.active : undefined,
        _search: values._search ? values._search.trim() : undefined,
      });
    }, 500),
    []
  );

  return (
    <Wrapper>
      {ProfileTemplateApiGuideModal}
      {ShareLinkModal}
      {CardCopyLinkModal}
      {ConfirmActionModal}
      {ImportProfileTemplateModal}
      <CardWrapper hasPadding>
        <Row align="middle" className="flex no-mrg">
          <Col className="flex-1 page-title">
            <PageTitle
              title={translate(
                'pages.private.profileModels.profileModelList.index.profileModelList.pageTitle.title'
              )}
              subtitle={translate(
                'pages.private.profileModels.profileModelList.index.profileModelList.pageTitle.subtitle'
              )}
            />
          </Col>
          <Col className="flex end mrg-left-50">
            <Form
              layout="inline"
              onValuesChange={handleFormChange}
              initialValues={{ _search: query?._search, active: query?.active || 'all' }}
            >
              <SearchInput
                placeholder={translate(
                  'pages.private.profileModels.profileModelList.index.profileModelList.form.profileModel.placeholder'
                )}
                style={{ minWidth: 150 }}
              />
              <Form.Item name="active" className="no-mrg-btm">
                <Select
                  bordered={false}
                  style={{ width: 100, fontWeight: 500 }}
                  className="text-dark"
                >
                  <Option value="all">
                    {translate(
                      'pages.private.profileModels.profileModelList.index.profileModelList.form.select.options.all'
                    )}
                  </Option>
                  <Option value="true">
                    {translate(
                      'pages.private.profileModels.profileModelList.index.profileModelList.form.select.options.true'
                    )}
                  </Option>
                  <Option value="false">
                    {translate(
                      'pages.private.profileModels.profileModelList.index.profileModelList.form.select.options.false'
                    )}
                  </Option>
                </Select>
              </Form.Item>
            </Form>
            <PermissionWrapper requiredPermissions={['profile-templates:create']}>
              {/* <Dropdown
              overlay={<DropdownNewModel />}
              overlayClassName="custom-dropdown custom-dropdown-recurrence"
              trigger="click"
              placement="bottomRight"
            > */}
              <Button
                shape="round"
                type="primary"
                // href="/profile-templates/profile-models/registration"
                className="flex no-mrg-btm center"
                onClick={() => {
                  history.push(`/profile-templates/profile-models/registration`);
                }}
              >
                {translate(
                  'pages.private.profileModels.profileModelList.index.profileModelList.button'
                )}
                <i className="caf-ic_add font-size-18 mrg-left-5" />
              </Button>
              {/* </Dropdown> */}
              {isAdmin() && (
                <Button
                  type="default"
                  shape="circle"
                  onClick={() => openImportProfileTemplateModal()}
                >
                  <i className="caf-ic_upload" />
                </Button>
              )}
            </PermissionWrapper>
          </Col>
        </Row>

        <div className="gx-w-100 mrg-top-10">{tableContent}</div>
      </CardWrapper>
    </Wrapper>
  );
};

export default ProfileModelList;
