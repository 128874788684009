import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Timeline } from 'antd';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import Card from 'components/Card';

import IconStatus from '../IconStatus';

import { CardLogger } from './components';

import './styles.less';

const CardRecurrenceEvent = ({ data }) => {
  const formattedDate = useMemo(() => {
    const day = format(parseISO(data.date), 'dd', { locale: ptBR });
    const month = format(parseISO(data.date), 'MMM', { locale: ptBR });

    return { day, month };
  }, [data]);

  const formattedTimeline = useMemo(() => {
    const { items } = data || [];

    return items?.map((item) => {
      const createdBy = item?.createdBy || 'Trust Monitor';
      const createdAt = format(parseISO(item?.createdAt), "cccc, dd/MM/yyyy 'às' HH:mm", {
        locale: ptBR,
      });
      const footer = (
        <div className="card-footer">
          <span className="gx-font-italic gx-font-weight-light font-size-12 pdd-top-5 pdd-btm-5">
            {createdAt} por {createdBy}
          </span>
        </div>
      );
      return (
        <>
          <Timeline.Item
            key={item?._id}
            dot={<IconStatus type={item?.type} action={item?.action} />}
          >
            <CardLogger
              action={item?.action}
              type={item?.type}
              data={item?.data || {}}
              transactionTemplate={item?.transactionTemplate || {}}
              transactionTemplateName={item?.transactionTemplateName || ''}
            >
              {footer}
            </CardLogger>
          </Timeline.Item>
        </>
      );
    });
  }, [data]);

  return (
    formattedDate && (
      <Card id="card-recurrent-event">
        <div className="timeline-item-container">
          <div className="day-info-content">
            <span>
              {formattedDate.month}
              <strong>{formattedDate.day}</strong>
            </span>
          </div>

          <div className="activity-info-content">
            <div className="timeline-item-tail" />
            <Timeline>{formattedTimeline}</Timeline>
          </div>
        </div>
      </Card>
    )
  );
};

CardRecurrenceEvent.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.string.isRequired,
  }).isRequired,
};

export default CardRecurrenceEvent;
