/* eslint-disable react/prop-types */
import React, { useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Row, Col, Layout } from 'antd';

import { useValidations } from 'hooks/validations';
import { CardWrapper } from 'components/CardWrapper';

// import { useScroll } from 'hooks/scroll';

import Tabs from 'pages/private/Profiles/components/Tabs';

import GeneralStatus from 'pages/private/Profiles/components/GeneralStatus';
import TagsList from 'pages/private/Profiles/components/TagsList';
import ProfileValidations from 'pages/private/Profiles/components/ProfileValidations';
import PeopleSubheader from 'pages/private/Profiles/components/ProfileSubheader';

// import TrustValidations from 'pages/private/Profiles/components/TrustValidations';
// import DocumentsWidget from 'pages/private/Profiles/People/PeopleDetails/components/DocumentsWidget';

import './styles.less';

const { Content } = Layout;

const ProfileContent = ({ profile }) => {
  const { t: translate } = useTranslation();

  const { personId, companyId } = useParams();
  // const { isAtTop } = useScroll();

  const { fetchValidations } = useValidations();

  // const isAtTopClass = useMemo(() => {
  //   return isAtTop ? 'expand' : 'retract'
  // }, [isAtTop])

  const status = useMemo(() => {
    return profile?.status;
  }, [profile]);

  const profileUrl = useMemo(() => {
    return personId ? `people/${personId}` : `companies/${companyId}`;
  }, [personId, companyId]);

  const firstLoad = useRef(true);
  useEffect(() => {
    if (!firstLoad.current) return;
    firstLoad.current = false;

    fetchValidations(profileUrl);
  }, [fetchValidations]); // eslint-disable-line

  return (
    <main>
      <section id="profile-content">
        <Content
          id="profile-content-wrapper"
          className="gx-layout-content gx-container-wrap"
        >
          <Row>
            <Col span={17}>
              <CardWrapper hasPadding>
                <div className="mrg-btm-60 mrg-top-20">
                  <PeopleSubheader profile={profile} />
                </div>
                <div>
                  <Tabs />
                </div>
              </CardWrapper>
            </Col>

            <Col
              span={7}

              // id="widgets" className={`hide-scroll ${isAtTopClass}`}
            >
              <CardWrapper hasPadding>
                {status && <GeneralStatus status={status} profile={profile} />}

                {/* {personId && <DocumentsWidget />}

              {personId ? (
                <TrustValidations profile={profile} url={profileUrl} type="PF" />
              ) : (
                <ProfileValidations profile={profile} url={profileUrl} type="PJ" />
              )} */}

                <ProfileValidations
                  profile={profile}
                  url={profileUrl}
                  type={
                    personId
                      ? translate(
                          'pages.private.profiles.components.profileContent.index.type.person'
                        )
                      : translate(
                          'pages.private.profiles.components.profileContent.index.type.company'
                        )
                  }
                />

                <TagsList url={profileUrl} />
              </CardWrapper>
            </Col>
          </Row>
        </Content>
      </section>
    </main>
  );
};

export default ProfileContent;
