export const statusColor = {
  PENDING: {
    color: '#664014',
    background: '#F16E0029',
    iconColor: '#F16E00',
  },
  PENDING_OCR: {
    color: '#664014',
    background: '#F16E0029',
    iconColor: '#F16E00',
  },
  APPROVED: {
    color: '#17521B',
    background: '#2D994B29',
    iconColor: '#0BAA43',
  },
  VALID: {
    color: '#17521B',
    background: '#2D994B29',
    iconColor: '#0BAA43',
  },
  REPROVED: {
    color: '#521717',
    background: '#E21B4529',
    iconColor: '#E21B45',
  },
  PENDENTE: {
    color: '#664014',
    background: '#F16E0029',
    iconColor: '#F16E00',
  },
  APROVADO: {
    color: '#17521B',
    background: '#2D994B29',
    iconColor: '#0BAA43',
  },
  REPROVADO: {
    color: '#521717',
    background: '#E21B4529',
    iconColor: '#E21B45',
  },
  WARNING: {
    color: '#391D66',
    background: '#763AD729',
    iconColor: '#763AD7',
  },
};
