import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import { useExecution } from 'hooks/execution';

import BasicInfosCard from '../../BasicInfosCard';
import AddressItem from './components/AddressItem';

import './styles.less';

const InternationalAddressContent = () => {
  const { t: translate } = useTranslation();

  const { execution } = useExecution();

  const isString = useMemo(() => {
    return typeof execution?.parameters?.address === 'string';
  }, [execution]);

  const addressFormatted = useMemo(() => {
    if (!execution?.parameters?.address) return 'no-recurrence';

    if (execution?.parameters?.address) {
      if (isString) return execution?.parameters?.address;
      const { country, number, neighborhood, city, state, zipCode, type, street } =
        execution?.parameters?.address || {};

      const address = {
        country,
        number,
        neighborhood,
        city,
        state,
        zipCode,
        type,
        street,
      };

      return address;
    }

    return 'no-data';
  }, [execution, isString]);

  const showAddress = useMemo(
    () =>
      addressFormatted !== 'no-recurrence' &&
      addressFormatted !== 'no-data' &&
      addressFormatted !== 'query-failed',
    [addressFormatted]
  );

  return (
    <BasicInfosCard
      icon="caf-ic_map_pin"
      title={translate(
        'pages.private.executions.components.dataSourceCards.basicInfos.components.person.addressContent.addressContent.basicInfosCard.title'
      )}
      dataIndex="addressFormatted"
      customQuery={false}
    >
      <>
        <>
          {!showAddress ? (
            <p>
              {translate(
                'pages.private.executions.components.dataSourceCards.basicInfos.components.person.addressContent.columLabelTitle.hasNotToShowAddress'
              )}
            </p>
          ) : (
            <>
              <Row className="special-address-item-row">
                <Col span={24}>
                  <AddressItem data={addressFormatted} />
                </Col>
              </Row>
            </>
          )}
        </>
      </>
    </BasicInfosCard>
  );
};

export default InternationalAddressContent;
