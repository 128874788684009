import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { Card } from 'antd';

import { queryStatusCode } from 'utils/handlerStatus';

import './styles.less';

const EmptyState = ({ statusCode, message, icon, ...props }) => {
  const { t: translate } = useTranslation();

  return (
    <>
      <Card id="empty-state-card" {...props}>
        <i className={icon || queryStatusCode(statusCode).icon} />

        <strong>{message || translate(queryStatusCode(statusCode).title)}</strong>
      </Card>
    </>
  );
};

EmptyState.propTypes = {
  statusCode: PropTypes.string,
  icon: PropTypes.string,
  message: PropTypes.string,
};

EmptyState.defaultProps = {
  statusCode: undefined,
  icon: undefined,
  message: undefined,
};

export default EmptyState;
