/* eslint-disable react/prop-types */
import React from 'react';
import { Form, Checkbox } from 'antd';

import { useAuth } from 'hooks/auth';

const SpecialPolicyRow = ({
  ruleName,
  policyName,
  title,
  icon,
  disabled,
  allowedTenants = [],
}) => {
  const { user } = useAuth();

  return (
    (allowedTenants.length === 0 || allowedTenants.includes(user.tenantId)) && (
      <div className="divider-checkboxes">
        <Form.Item
          className="no-mrg no-pdd"
          key={ruleName}
          name={[policyName, ruleName]}
          valuePropName="checked"
        >
          <Checkbox disabled={disabled}>
            <div className="text-default">
              <i
                className={`caf-${icon} font-size-20 mrg-right-10 ${
                  icon === 'ic_close_small' ? 'danger-color' : 'success-color'
                }`}
              />
              {title}
            </div>
          </Checkbox>
        </Form.Item>
      </div>
    )
  );
};

export default SpecialPolicyRow;
