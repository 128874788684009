import React from 'react';
import PropTypes from 'prop-types';

const ExpandIcon = ({ isActive }) => (
  <div>
    <i className={isActive ? 'caf-ic_arrow_up' : 'caf-ic_arrow_down'} />
  </div>
);

ExpandIcon.propTypes = {
  isActive: PropTypes.bool,
};

ExpandIcon.defaultProps = {
  isActive: false,
};

export default ExpandIcon;
