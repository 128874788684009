import React from 'react';
import PropTypes from 'prop-types';

import { Select } from 'antd';

const SearchFilter = ({ placeholder, value, className, onChange }) => {
  return (
    <Select
      mode="tags"
      value={value}
      placeholder={placeholder}
      dropdownClassName="gx-d-none"
      className={className}
      removeIcon={<i className="caf-ic_circle_false" />}
      onChange={(values) => onChange(values)}
    />
  );
};

SearchFilter.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchFilter;
