export const genderTranslate = (gender) => {
  if (!gender) return '';

  switch (gender.toUpperCase()) {
    case 'MALE':
      return 'pages.private.executions.components.tabs.components.verifiedIdentity.utils.translations.genderTranslate.MALE';
    case 'FEMALE':
      return 'pages.private.executions.components.tabs.components.verifiedIdentity.utils.translations.genderTranslate.FEMALE';
    default:
      return 'pages.private.executions.components.tabs.components.verifiedIdentity.utils.translations.genderTranslate.default';
  }
};

export const documentTypeTranslate = (type) => {
  if (!type) return '';

  switch (type.toUpperCase()) {
    case 'CNH':
      return 'CARTEIRA NACIONAL DE HABILITAÇÃO';
    case 'RG':
      return 'REGISTRO GERAL';
    default:
      return 'DOCUMENTO FUNCIONAL';
  }
};
