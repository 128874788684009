import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, Form, Select, Input } from 'antd';
import classNames from 'classnames';

import ComplianceRulesValidations from './ComplianceRulesValidations';

const { Option } = Select;

const CardReview = ({ item }) => {
  const { t: translate } = useTranslation();

  const [validationReason, setValidationReason] = useState(null);
  const [reprovalReason, setReprovalReason] = useState('');

  useMemo(() => {
    item.status = validationReason || item.status;
    if (validationReason === 'REPROVED') {
      item.reason = reprovalReason;
    }
  }, [item, validationReason, reprovalReason]);

  return (
    <Card id="card-review">
      <Form.Item
        name={[item?._id, 'validationStatus']}
        className={classNames('select-reason', {
          approve: validationReason === 'APPROVED',
          reprove: validationReason === 'REPROVED',
        })}
        label={translate(
          'pages.private.profiles.components.profileValidations.components.pendingReviewModal.components.cardReview.cardReview.form.validationStatus.label'
        )}
      >
        <Select
          dropdownClassName="pending-review-action-dropdown"
          onSelect={(e) => setValidationReason(e)}
          placeholder={
            <>
              <i className="caf-ic_edit_3" />
              {translate(
                'pages.private.profiles.components.profileValidations.components.pendingReviewModal.components.cardReview.cardReview.form.validationStatus.select.placeholder'
              )}
            </>
          }
        >
          <Option value="APPROVED">
            <>
              <i className="caf-ic_checkmark" />
              {translate(
                'pages.private.profiles.components.profileValidations.components.pendingReviewModal.components.cardReview.cardReview.form.validationStatus.select.options.aprove'
              )}
            </>
          </Option>
          <Option value="REPROVED">
            <>
              <i className="caf-ic_close" />
              {translate(
                'pages.private.profiles.components.profileValidations.components.pendingReviewModal.components.cardReview.cardReview.form.validationStatus.select.options.reprove'
              )}
            </>
          </Option>
          {/* <Option value="REPROCESS">
                <>
                  <i className="caf-ic_processing_line" />
                  Re-processar
                </>
              </Option> */}
        </Select>
      </Form.Item>

      {/* {validationReason === 'REPROCESS' && (
            <Alert description="Reprocessar uma execução pode levar algum tempo e uma cobranca adicional no mesmo valor da execução anterior será feita." />
          )} */}

      <ComplianceRulesValidations item={item} />

      {validationReason === 'REPROVED' && (
        <Form.Item
          name={[item?._id, 'validationReason']}
          label={translate(
            'pages.private.profiles.components.profileValidations.components.pendingReviewModal.components.cardReview.cardReview.form.validationReason.label'
          )}
          rules={[
            {
              required: true,
              message: translate(
                'pages.private.profiles.components.profileValidations.components.pendingReviewModal.components.cardReview.cardReview.form.validationReason.message'
              ),
            },
          ]}
          className="reproval-reason"
        >
          <Input.TextArea
            onChange={(e) => setReprovalReason(e?.target?.value)}
            rows="5"
            placeholder={translate(
              'pages.private.profiles.components.profileValidations.components.pendingReviewModal.components.cardReview.cardReview.form.validationReason.placeholder'
            )}
          />
        </Form.Item>
      )}
    </Card>
  );
};

CardReview.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    status: PropTypes.string,
    reason: PropTypes.string,
  }).isRequired,
};

export default CardReview;
