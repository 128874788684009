/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { Info } from '@combateafraude/icons/general';
import { useTranslation } from 'react-i18next';

import { convertGlobalDocVerificationResultToIcon } from '../../../../../../utils/convertGlobalDocVerificationResultToIcon';

const I18N_BASE_PATH =
  'pages.private.executions.components.tabs.components.complianceRules.index.complianceRules.ruleDetails.globalDocVerification';

const mapAuthenticityCheckType = (checkType) => {
  let result = '';
  switch (checkType) {
    case 'result':
      result = `${I18N_BASE_PATH}.authenticityCheck.result`;
      break;
    case 'imagePattern':
      result = `${I18N_BASE_PATH}.authenticityCheck.imagePatterns`;
      break;
    case 'barcodeFormatCheck':
      result = `${I18N_BASE_PATH}.authenticityCheck.barcodeFormatCheck`;
      break;
    case 'photoEmbedType':
      result = `${I18N_BASE_PATH}.authenticityCheck.photoEmbedType`;
      break;
    default:
      result = `"${checkType}"`;
      break;
  }
  return result;
};

const AuthenticityCheckPopover = ({ authenticityCheck }) => {
  const { t: translate } = useTranslation();

  const authenticityCheckDetails = useCallback(
    (imageFieldKey) => {
      return (
        <div className="flex fd-column doc-side-check-container">
          <div className="flex start-center popover-title">
            <h3>
              {translate(
                `${I18N_BASE_PATH}.authenticityCheck.${imageFieldKey}OfDocument`
              )}
            </h3>
            {convertGlobalDocVerificationResultToIcon({
              checkResult: authenticityCheck[imageFieldKey].result,
              className: 'font-size-12 mrg-left-5',
            })}
          </div>
          <div className="check-list">
            {Object.keys(authenticityCheck[imageFieldKey] || {})?.map(
              (checkTypeKey) =>
                checkTypeKey !== 'result' && (
                  <div
                    className="flex center space-between check-item"
                    key={checkTypeKey}
                  >
                    {translate(mapAuthenticityCheckType(checkTypeKey))}
                    {convertGlobalDocVerificationResultToIcon({
                      checkResult: authenticityCheck[imageFieldKey][checkTypeKey].result,
                      className: 'font-size-12',
                    })}
                  </div>
                )
            )}
          </div>
        </div>
      );
    },
    [authenticityCheck, translate]
  );

  return (
    <Popover
      overlayClassName="compliance-rule-details-popover"
      trigger="hover"
      content={
        <div className="flex fd-column">
          {Object.hasOwn(authenticityCheck, 'front') && authenticityCheckDetails('front')}
          {Object.hasOwn(authenticityCheck, 'back') && authenticityCheckDetails('back')}
        </div>
      }
    >
      <Info className="font-size-13 mrg-left-5 gx-text-info" />
    </Popover>
  );
};

AuthenticityCheckPopover.propTypes = {
  authenticityCheck: PropTypes.shape({
    [PropTypes.oneOf(['front', 'back'])]: PropTypes.shape({
      result: PropTypes.oneOf([0, 1, 2]),
      [PropTypes.oneOf([
        'imagePattern',
        'barcodeFormatCheck',
        'photoEmbedType',
      ])]: PropTypes.shape({
        result: PropTypes.oneOf([0, 1, 2]),
      }),
    }),
  }).isRequired,
};

export default AuthenticityCheckPopover;
