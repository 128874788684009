const handlerPendencies = (quantity, translate) => {
  if (!quantity)
    return {
      title: null,
      quantity: null,
    };

  if (quantity === 1) {
    return {
      title: translate(
        'components.dataSourceCards.financialInformation.companies.components.cadinSP.cadinSPItems.utils.handlerPendencies.quantityEqualsToOne'
      ),
      quantity: '1 pendência',
    };
  }

  return {
    title: translate(
      'components.dataSourceCards.financialInformation.companies.components.cadinSP.cadinSPItems.utils.handlerPendencies.quantityEqualsToOne',
      { quantity }
    ),
    quantity: `${quantity} pendências`,
  };
};

export { handlerPendencies };
