/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  Duplicate as CopyIcon,
  Checkmark as CopiedIcon,
  Add as AddDomainIcon,
  CircleFalse as RemoveDomainIcon,
} from '@combateafraude/icons/general';
import { useGroup } from 'hooks/group';
import { isGroupAdmin } from 'utils/translations';

import { ssoConfigurationShape, protocolTypes, providers } from '../../hooks/useSSO';

import './styles.less';

const { Option } = Select;

const I18N_BASE_PATH = 'pages.private.securityAndPrivacy.securityConfiguration.sso';

const SSOConfiguration = ({ handleSubmit, isLoading, configuration }) => {
  const [addedDomains, setAddedDomains] = useState(new Set());

  const [form] = Form.useForm();
  const { listGroups, getListGroups } = useGroup();

  const { t: translate } = useTranslation();

  const handleAddDomain = async () => {
    await form.validateFields(['domain']);

    const domain = form.getFieldValue('domain');
    const domainsList = new Set(form.getFieldValue('domains') ?? []);

    if (!domain || domainsList.has(domain)) return;

    domainsList.add(domain);

    form.setFieldsValue({ domain: '' });
    form.setFieldsValue({ domains: Array.from(domainsList) });
    setAddedDomains(domainsList);
  };

  const handleRemoveDomain = (domain) => {
    const domainList = new Set(form.getFieldValue('domains') ?? []);

    const newDomains = new Set(domainList);
    newDomains.delete(domain);

    form.setFieldsValue({ domains: Array.from(newDomains) });
    setAddedDomains(newDomains);
  };

  useEffect(() => {
    getListGroups();
  }, [getListGroups]);

  useEffect(() => {
    if (configuration) {
      setAddedDomains(new Set(configuration?.domains));
    }
  }, [configuration]);

  return (
    <div style={{ position: 'relative' }}>
      <Form
        className="sso-form"
        name="SSO"
        validateTrigger="onBlur"
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        initialValues={configuration}
      >
        <Form.Item
          label={translate(`${I18N_BASE_PATH}.form.fields.identifier`)}
          className="sso-form__input-wrapper"
        >
          <Form.Item name="identifier" id="identifier" className="sso-form__input">
            <Input disabled />
          </Form.Item>
          <CopyButton getCopyText={() => form.getFieldValue('identifier')} />
        </Form.Item>
        <Form.Item
          label={translate(`${I18N_BASE_PATH}.form.fields.replyUrl`)}
          className="sso-form__input-wrapper"
        >
          <Form.Item name="replyUrl" className="sso-form__input">
            <Input disabled />
          </Form.Item>
          <CopyButton getCopyText={() => form.getFieldValue('replyUrl')} />
        </Form.Item>
        <Form.Item
          label={translate(`${I18N_BASE_PATH}.form.fields.usersGroupId`)}
          name="usersGroupId"
          className="sso-form__input"
          tooltip={{
            title: translate(`${I18N_BASE_PATH}.form.fields.usersGroupId.tooltip`),
          }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            placeholder={translate(
              `${I18N_BASE_PATH}.form.fields.usersGroupId.placeholder`
            )}
          >
            {listGroups?.map((group) => (
              <Option value={group?.id}>
                {isGroupAdmin(group?.id)
                  ? translate('global.hooks.group.getListGroups.admin')
                  : group?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={translate(`${I18N_BASE_PATH}.form.fields.provider`)}
          name="provider"
          className="sso-form__input"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder={translate(`${I18N_BASE_PATH}.form.fields.provider.placeholder`)}
          >
            {providers.map((provider) => (
              <Option value={provider} key={provider}>
                {translate(`${I18N_BASE_PATH}.form.fields.provider.${provider}`)}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={translate(`${I18N_BASE_PATH}.form.fields.protocol`)}
          name="providerType"
          className="sso-form__input"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder={translate(`${I18N_BASE_PATH}.form.fields.protocol.placeholder`)}
          >
            {protocolTypes.map((protocol) => (
              <Option value={protocol} key={protocol}>
                {translate(`${I18N_BASE_PATH}.form.fields.protocol.${protocol}`)}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={translate(`${I18N_BASE_PATH}.form.fields.metadataUrl`)}
          className="sso-form__input-wrapper sso-form__input_no-margin-bottom"
        >
          <Form.Item
            name="metadataUrl"
            className="sso-form__input sso-form__input_no-margin-bottom"
            normalize={(value) => value?.trim()}
            rules={[
              {
                required: true,
              },
              {
                message: translate(`${I18N_BASE_PATH}.form.fields.metadataUrl.invalid`),
                type: 'url',
              },
            ]}
          >
            <Input
              placeholder={translate(
                `${I18N_BASE_PATH}.form.fields.metadataUrl.placeholder`
              )}
            />
          </Form.Item>
          <CopyButton getCopyText={() => form.getFieldValue('metadataUrl')} />
        </Form.Item>
        <Form.Item>
          <span className="sso-form__input_hint">
            {translate(`${I18N_BASE_PATH}.form.fields.metadataUrl.hint`)}
          </span>
        </Form.Item>
        <Form.Item
          label={translate(`${I18N_BASE_PATH}.form.fields.dnsVerificationRecord`)}
          className="sso-form__input-wrapper"
        >
          <Form.Item
            name="dnsVerificationRecord"
            id="dnsVerificationRecord"
            className="sso-form__input"
          >
            <Input disabled />
          </Form.Item>
          <CopyButton getCopyText={() => form.getFieldValue('dnsVerificationRecord')} />
        </Form.Item>
        <Form.Item
          label={translate(`${I18N_BASE_PATH}.form.fields.domainList`)}
          className="sso-form__input-wrapper"
        >
          <Form.Item
            name="domain"
            className="sso-form__input"
            validateTrigger="onBlur"
            rules={[
              {
                pattern: new RegExp(/^(\w+)(\.\w+)+$/),
                message: translate(`${I18N_BASE_PATH}.form.fields.domainList.invalid`),
              },
            ]}
            normalize={(value) => value?.trim()}
          >
            <Input
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') handleAddDomain();
              }}
            />
          </Form.Item>
          <Button
            onClick={handleAddDomain}
            className="sso-form__button"
            icon={<AddDomainIcon />}
            type="link"
          >
            {translate(`${I18N_BASE_PATH}.form.buttons.add`)}
          </Button>
        </Form.Item>
        <Form.Item
          label={translate(`${I18N_BASE_PATH}.form.fields.addedDomains`)}
          name="domains"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <div
            className="ant-input sso-form__domain-list"
            style={{
              overflowY: addedDomains.size > 4 ? 'scroll' : 'auto',
            }}
          >
            {addedDomains.size === 0 ? (
              <span className="ant-select-selection-placeholder">
                {translate(`${I18N_BASE_PATH}.form.fields.addedDomains.emptyList`)}
              </span>
            ) : (
              <>
                {Array.from(addedDomains).map((addedDomain) => (
                  <div key={addedDomain} className="sso-form__domain-list-item">
                    {addedDomain}
                    <button
                      type="button"
                      className="sso-form__remove-domain-button"
                      onClick={() => handleRemoveDomain(addedDomain)}
                    >
                      <RemoveDomainIcon />
                    </button>
                  </div>
                ))}
              </>
            )}
          </div>
        </Form.Item>
      </Form>
      <Button
        loading={isLoading}
        type="primary"
        htmlType="button"
        className="sso-form__submit-button"
        onClick={() => form.submit()}
      >
        {translate(`${I18N_BASE_PATH}.form.buttons.save`)}
      </Button>
    </div>
  );
};

const CopyButton = (props) => {
  const { getCopyText, disabled } = props;

  const [copied, setCopied] = useState(false);

  const { t: translate } = useTranslation();

  const handleButtonClick = () => {
    navigator.clipboard.writeText(getCopyText());
    setCopied(true);
  };

  return (
    <Button
      onClick={handleButtonClick}
      className="sso-form__button"
      type="link"
      icon={copied ? <CopiedIcon /> : <CopyIcon />}
      disabled={disabled}
    >
      {copied
        ? translate(`${I18N_BASE_PATH}.form.buttons.copied`)
        : translate(`${I18N_BASE_PATH}.form.buttons.copy`)}
    </Button>
  );
};

SSOConfiguration.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  configuration: ssoConfigurationShape,
};

SSOConfiguration.defaultProps = {
  configuration: undefined,
};

CopyButton.propTypes = {
  getCopyText: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

CopyButton.defaultProps = {
  disabled: false,
};

export default SSOConfiguration;
