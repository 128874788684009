import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Modal } from 'antd';
import { useQueryParams, StringParam } from 'use-query-params';

import { useOnboardingTemplate } from 'hooks/onboardingTemplate';

import { useProfileTemplate } from 'hooks/profileTemplate';
import Button from 'components/Button';
import PageTitle from 'components/PageTitle';
import RectangleButton from 'components/Button/RectangleButton';
import './styles.less';

const I18N_BASE_PATH =
  'pages.private.profileModels.profileModelCreate.starterCustomModel';

const StarterCustomModel = () => {
  const { t: translate } = useTranslation();

  const [, setQuery] = useQueryParams({
    step: StringParam,
  });

  const [form] = Form.useForm();
  const {
    loadOnboardingTemplates,
    loading: loadingOnboardingTemplates,
  } = useOnboardingTemplate();

  const {
    profileTemplate,
    setProfileTemplate,
    loadingCreateProfileTemplate,
    cafServicesList,
  } = useProfileTemplate();

  const [modalVisible, setModalVisible] = useState(false);
  const [predefinedTemplateType, setPredefinedTemplateType] = useState(null);
  const [selectedOnboardingTemplateId, setSelectedOnboardingTemplateId] = useState(null);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setTimeout(() => {
      form.resetFields();
    }, 1000);
  }, [form]);

  const openModal = useCallback((_predefinedTemplateType) => {
    if (!_predefinedTemplateType) return;
    setPredefinedTemplateType(_predefinedTemplateType);
    setModalVisible(true);
  }, []);

  const onSubmit = useCallback(async () => {
    await form.validateFields();

    const onboardingTemplateId = selectedOnboardingTemplateId;

    setProfileTemplate({ onboardingTemplateId });
    closeModal();
    // verifica se existem serviços na lista do usuário, caso não houver, redireciona para proxima etapa
    let servicesList = [];
    if (cafServicesList !== null) {
      servicesList =
        cafServicesList?.docs?.filter((item) => item.productType === 'service') || [];
    }
    let redirectStep = 'onboarding_services';
    if (predefinedTemplateType !== 'CUSTOM') redirectStep = 'confirmation';
    else if (profileTemplate?.type === 'PJ' || servicesList.length === 0)
      redirectStep = 'background_checking';
    setQuery({
      step: redirectStep,
      profileType: profileTemplate?.type,
    });
  }, [
    form,
    selectedOnboardingTemplateId,
    setProfileTemplate,
    closeModal,
    cafServicesList,
    predefinedTemplateType,
    profileTemplate,
    setQuery,
  ]);

  const submitButtonText = useMemo(() => {
    return translate(`${I18N_BASE_PATH}.submitButtonText.default`);
  }, [translate]);

  const pageSubtitle = useMemo(() => {
    return translate(`${I18N_BASE_PATH}.pageSubtitle.templateTypeStep`);
  }, [translate]);

  useEffect(() => {
    loadOnboardingTemplates();
    if (!profileTemplate?.type)
      setProfileTemplate({
        type: 'PF',
      });
  }, [loadOnboardingTemplates, profileTemplate, setProfileTemplate]);

  const StarterCustomModelModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeModal}
        footer={null}
        width={580}
        destroyOnClose
      >
        <div id="profile-model-create-starter-custom-component" className="no-mrg no-pdd">
          <PageTitle
            title={translate(`${I18N_BASE_PATH}.starterCustomModelModal.pageTitle.title`)}
            subtitle={pageSubtitle}
          />

          <Form form={form} layout="vertical" className="mrg-top-40">
            <Row className="mrg-btm-30">
              <Col span={12}>
                <RectangleButton
                  title={translate(
                    `${I18N_BASE_PATH}.starterCustomModelModal.form.options.person.title`
                  )}
                  description={translate(
                    `${I18N_BASE_PATH}.starterCustomModelModal.form.options.person.description`
                  )}
                  icon="caf-ic_person"
                  selected={profileTemplate?.type === 'PF'}
                  onClick={() => {
                    setSelectedOnboardingTemplateId(undefined);
                    form.setFieldsValue({ onboardingTemplateId: null });

                    setProfileTemplate({
                      type: 'PF',
                    });
                  }}
                  disabled={predefinedTemplateType !== 'CUSTOM'}
                />
              </Col>
              <Col span={12}>
                <RectangleButton
                  title={translate(
                    `${I18N_BASE_PATH}.starterCustomModelModal.form.options.company.title`
                  )}
                  description={translate(
                    `${I18N_BASE_PATH}.starterCustomModelModal.form.options.company.description`
                  )}
                  icon="caf-ic_company"
                  selected={profileTemplate?.type === 'PJ'}
                  onClick={() => {
                    setSelectedOnboardingTemplateId(undefined);
                    form.setFieldsValue({ onboardingTemplateId: null });

                    setProfileTemplate({
                      type: 'PJ',
                    });
                  }}
                  disabled={predefinedTemplateType !== 'CUSTOM'}
                />
              </Col>
            </Row>
            <div className="gx-flex-row gx-justify-content-end mrg-top-20">
              <Button
                type="default"
                onClick={closeModal}
                className="btn-cancel"
                disabled={loadingCreateProfileTemplate}
              >
                {translate(
                  `${I18N_BASE_PATH}.starterCustomModelModal.form.button.cancel`
                )}
              </Button>
              <Button
                type="primary"
                disabled={loadingCreateProfileTemplate || loadingOnboardingTemplates}
                loading={loadingCreateProfileTemplate || loadingOnboardingTemplates}
                onClick={onSubmit}
              >
                {submitButtonText}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      translate,
      pageSubtitle,
      loadingOnboardingTemplates,
      form,
      profileTemplate,
      predefinedTemplateType,
      loadingCreateProfileTemplate,
      onSubmit,
      submitButtonText,
      setProfileTemplate,
    ]
  );

  return { openModal, closeModal, StarterCustomModelModal };
};

export default StarterCustomModel;
