import React, { useCallback, useMemo, useState } from 'react';
import { Modal as AntdModal } from 'antd';
import CompanyModalAddressesBody from './components/Body';

const ModalAddresses = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [addresses, setAddresses] = useState([]);

  const closeModal = useCallback(() => setModalVisible(false), []);
  const openModal = useCallback(({ addresses }) => {
    setAddresses(addresses);
    setModalVisible(true);
  }, []);

  const Modal = useMemo(
    () => (
      <AntdModal
        width={700}
        footer={null}
        style={{ top: 40 }}
        onCancel={closeModal}
        visible={modalVisible}
        wrapClassName="caf-modal"
      >
        <CompanyModalAddressesBody addresses={addresses} />
      </AntdModal>
    ),
    [closeModal, modalVisible, addresses]
  );

  return { openModal, modalVisible, Modal };
};

export default ModalAddresses;
