/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import * as executionDataSources from 'utils/executionDataSource';

import { useExecution } from 'hooks/execution';
import { usePerson } from 'hooks/person';
import { useCompany } from 'hooks/company';

import PfAntecedentes from './components/pfAntecedentes';
import PfArrestWarrant from './components/pfArrestWarrant';
import PfBackgroundCheckTrf from './components/pfBackgroundCheckTrf';
import PfBackgroundCheckFederalPolice from './components/pfBackgroundCheckFederalPolice';
import PfCriminalBackground from './components/pfCriminalBackground';
import PfNadaConsta from './components/pfNadaConsta';

import DataSourceCardCollapsePanel from '../DataSourceCardCollapsePanel';

import './styles.less';

const I18N_BASE_PATH = 'components.dataSourceCards.criminalInformation.index';

const CriminalInformation = ({ toPrint = false }) => {
  const { t: translate } = useTranslation();

  const { execution } = useExecution();
  const { company } = useCompany();
  const { person } = usePerson();

  const findSourceSection = useCallback(
    (section) =>
      executionDataSources.findSourceSection(execution, section) ||
      executionDataSources.findSourceSection(company, section) ||
      executionDataSources.findSourceSection(person, section),
    [company, execution, person]
  );

  const federalCrimes = useMemo(() => findSourceSection('pfCriminalBackground'), [
    findSourceSection,
  ]);
  const stateCrimes = useMemo(() => findSourceSection('pfAntecedentes'), [
    findSourceSection,
  ]);
  const pfNadaConsta = useMemo(() => findSourceSection('pfNadaConsta'), [
    findSourceSection,
  ]);
  const pfNadaConstaTRF = useMemo(() => findSourceSection('pfBackgroundCheckTrf'), [
    findSourceSection,
  ]);
  const showFederalCriminalBackground = useMemo(
    () => findSourceSection('pfBackgroundCheckFederalPolice'),
    [findSourceSection]
  );
  const showArrestWarrant = useMemo(() => findSourceSection('pfArrestWarrant'), [
    findSourceSection,
  ]);

  return (
    <DataSourceCardCollapsePanel
      customId="criminal-background-component"
      title={translate(`${I18N_BASE_PATH}.criminalInformation.dossierCard.title`)}
      icon="caf-ic_criminal"
      toPrint={toPrint}
    >
      {stateCrimes && <PfAntecedentes source={stateCrimes} toPrint={toPrint} />}
      {showArrestWarrant && (
        <PfArrestWarrant source={showArrestWarrant} toPrint={toPrint} />
      )}
      {pfNadaConstaTRF && (
        <PfBackgroundCheckTrf source={pfNadaConstaTRF} toPrint={toPrint} />
      )}
      {showFederalCriminalBackground && (
        <PfBackgroundCheckFederalPolice
          source={showFederalCriminalBackground}
          toPrint={toPrint}
        />
      )}
      {federalCrimes && <PfCriminalBackground source={federalCrimes} toPrint={toPrint} />}
      {pfNadaConsta && <PfNadaConsta source={pfNadaConsta} toPrint={toPrint} />}
    </DataSourceCardCollapsePanel>
  );
};

export default CriminalInformation;
