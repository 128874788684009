import React from 'react';
import PropTypes from 'prop-types';

import './styles.less';

const ImageCard = ({ image, title, subtitle, height, width, classHover }) => {
  return (
    <div className="image-card-container">
      <img
        className="image"
        src={image}
        height={height}
        width={width}
        alt={title || 'image'}
      />
      <div className={classHover ? 'content-wrapper-nohover' : 'content-wrapper'}>
        <p className="title">{title}</p>
        <p>{subtitle}</p>
      </div>
    </div>
  );
};
ImageCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  classHover: PropTypes.bool,
};

ImageCard.defaultProps = {
  image: '',
  title: undefined,
  subtitle: undefined,
  classHover: false,
};

export default ImageCard;
