const typeNotification = (status) => {
  if (!status) return 'info';

  switch (status) {
    case 'SUCCESS':
      return 'success';
    case 'ERROR':
      return 'error';
    default:
      return 'info';
  }
};

const messageNotification = (status, type = 'default') => {
  if (!status)
    return 'utils.handlerNotifications.messageNotification.status.hasNotStatus';

  if (type === 'restrictive_list_imported') {
    switch (status) {
      case 'SUCCESS':
        return 'utils.handlerNotifications.messageNotificationDenyList.status.SUCCESS';
      case 'ERROR':
        return 'utils.handlerNotifications.messageNotificationDenyList.status.ERROR';
      default:
        return 'utils.handlerNotifications.messageNotificationDenyList.status.default';
    }
  }
  switch (status) {
    case 'SUCCESS':
      return 'utils.handlerNotifications.messageNotification.status.SUCCESS';
    case 'ERROR':
      return 'utils.handlerNotifications.messageNotification.status.ERROR';
    default:
      return 'utils.handlerNotifications.messageNotification.status.default';
  }
};

export { typeNotification, messageNotification };
