import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { formatISO } from 'date-fns';
import _ from 'lodash';

import { useAuth } from 'hooks/auth';
import { useI18nFormatters } from 'hooks/i18nFormatters';

import { toMaskedCpf, toMaskedCnpj } from 'utils/formatters';

import GeneralStatus from 'pages/private/Executions/components/GeneralStatus';

import logoToPrintCAF from 'assets/images/trust-logo.svg';

import './styles.less';

const DocumentHeader = ({ data }) => {
  const { t: translate } = useTranslation();

  const { i18nFormatDate } = useI18nFormatters();

  const { user } = useAuth();

  const currentDate = useMemo(() => {
    const _now = formatISO(new Date());

    return i18nFormatDate(_now, 'default');
  }, [i18nFormatDate]);

  const executionName = useMemo(() => {
    const name =
      data?.data?.name ||
      data?.parameters?.name ||
      data?.sections?.cpf?.name ||
      data?.sections?.ocr?.name ||
      data?.sections?.pfData?.data?.name ||
      data?.sections?.pfBasicData?.data?.name ||
      data?.sections?.pfCpfData?.data?.name ||
      data?.sections?.pfRfStatus?.data?.name;

    const officialName =
      data?.data?.corporateName ||
      data?.parameters?.name ||
      data?.sections?.pjData?.data?.officialName ||
      data?.sections?.pjBasicData?.data?.officialName ||
      data?.sections?.pjBasicData?.data?.tradeName ||
      data?.sections?.pjBoaVista?.data?.DefineLimite?.IdentificacaoCompleto?.RazaoSocial;

    return officialName || name || '-';
  }, [data]);

  const executionFantasyName = useMemo(() => {
    return data?.basicData?.fantasyName ? `${data?.basicData?.fantasyName} |` : null;
  }, [data]);

  const executionIdentification = useMemo(() => {
    const cpf =
      data?.parameters?.cpf ||
      data?.parameters?.peopleId ||
      data?.sections?.pfBasicData?.data?.cpf ||
      data?.sections?.pfBasicData?.data?.taxIdNumber;

    const cnpj =
      data?.parameters?.cnpj ||
      data?.parameters?.companyId ||
      data?.sections?.pjData?.data?.cnpj;

    return toMaskedCpf(cpf) || toMaskedCnpj(cnpj) || '-';
  }, [data]);

  const executionStatus = useMemo(() => {
    return data?.status || null;
  }, [data]);

  return (
    <div id="to-print-header">
      <header>
        <img src={logoToPrintCAF} alt="Logotipo" />

        <div className="to-print-description">
          <strong>
            {translate(
              'pages.private.profiles.components.dossierPrint.components.documentHeader.index.documentHeader.title'
            )}{' '}
          </strong>
          <span>
            {translate(
              'pages.private.profiles.components.dossierPrint.components.documentHeader.index.documentHeader.title.before'
            )}{' '}
            {currentDate}{' '}
            {translate(
              'pages.private.profiles.components.dossierPrint.components.documentHeader.index.documentHeader.title.inside'
            )}{' '}
            {user?.name || 'Trust Monitor'}
          </span>
        </div>
      </header>

      <div className="to-print-title">
        <div className="execution-details">
          <div className="execution-info">
            <strong className="execution-name">{executionName}</strong>

            <span className="execution-identification">
              {executionFantasyName} {executionIdentification}
            </span>
          </div>
        </div>

        {executionStatus && (
          <div className="execution-status">
            <GeneralStatus status={executionStatus} />
          </div>
        )}
      </div>
    </div>
  );
};

DocumentHeader.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DocumentHeader;
