import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyExecutionState from 'components/EmptyExecutionState';
import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import { toMaskedCnpj, toMaskedCep } from 'utils/formatters';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { Col, Row, Collapse, Divider } from 'antd';

import './styles.less';

const I18N_BASE_PATH = 'components.dataSourceCards.basicInfos.components';

const { Panel } = Collapse;

const PjSintegraData = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_file"
      title={translate(`${I18N_BASE_PATH}.basicInfosCard.sources.pjSintegraData`)}
      dataIndex="basicData"
      toPrint={toPrint}
    >
      {!queryWorked ? (
        <EmptyExecutionState
          className="mrg-top-10"
          statusCode={statusCode}
          message={parsedData?.message}
        />
      ) : (
        <Row id="sintegra-data-id">
          <Col span={16} className="mrg-btm-25 col-ant">
            <span className="text-style">
              <strong>
                {translate(`${I18N_BASE_PATH}.company.pjSintegraData.officialName`)}:{' '}
              </strong>
              {parsedData?.officialName}
            </span>
          </Col>
          <Col span={8} className="mrg-btm-25 col-ant">
            <span className="text-style">
              <strong>
                {translate(`${I18N_BASE_PATH}.company.pjSintegraData.uf`)}:{' '}
              </strong>
              {parsedData?.uf}
            </span>
          </Col>
          <Col span={16} className="mrg-btm-25 col-ant">
            <span className="text-style">
              <strong>
                {translate(`${I18N_BASE_PATH}.company.pjSintegraData.fantasyName`)}:{' '}
              </strong>
              {parsedData?.fantasyName || '-'}
            </span>
          </Col>
          <Col span={8} className="col-ant">
            <span className="text-style">
              <strong>
                {translate(`${I18N_BASE_PATH}.company.pjSintegraData.cnpj`)}:{' '}
              </strong>
              {toMaskedCnpj(parsedData?.cnpj)}
            </span>
          </Col>
          {parsedData?.message !== 'Consulta realizada com sucesso.' ? (
            <Col span={24} className="col-ant">
              <span className="text-style">
                <strong>
                  {translate(`${I18N_BASE_PATH}.company.pjSintegraData.message`)}:{' '}
                </strong>
                {parsedData?.message}
              </span>
            </Col>
          ) : (
            <></>
          )}
          <Col span={24} className="mrg-top-10">
            <Collapse
              expandIconPosition="right"
              defaultActiveKey={toPrint ? ['1'] : null}
            >
              <Panel
                disabled={!parsedData?.stateRegistrationList?.length}
                header={
                  <strong className="list-subtitle">
                    {translate(`${I18N_BASE_PATH}.company.pjSintegraData.list.title`)}
                  </strong>
                }
                extra={
                  <span>
                    {parsedData?.stateRegistrationList?.length}{' '}
                    {parsedData?.stateRegistrationList?.length > 0
                      ? translate(
                          `${I18N_BASE_PATH}.company.pjSintegraData.list.foundRegisters`
                        )
                      : translate(
                          `${I18N_BASE_PATH}.company.pjSintegraData.list.notFoundRegister`
                        )}
                  </span>
                }
                key="1"
              >
                {parsedData?.stateRegistrationList?.map((list) => (
                  <>
                    <Row>
                      <Col span={12}>
                        <span className="text-style">
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.company.pjSintegraData.list.cnpjStatus`
                            )}
                            :{' '}
                          </strong>
                          {capitalizeFirstLetter(list?.cnpjStatus)}
                        </span>
                      </Col>
                      <Col span={12}>
                        <span className="text-style">
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.company.pjSintegraData.list.companySize`
                            )}
                            :{' '}
                          </strong>
                          {capitalizeFirstLetter(list?.companySize)}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mrg-top-10">
                      <Col span={12}>
                        <span className="text-style">
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.company.pjSintegraData.list.mainActivityCnae`
                            )}
                            :{' '}
                          </strong>
                          {list?.mainActivityCnae}
                        </span>
                      </Col>
                      <Col span={12}>
                        <span className="text-style">
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.company.pjSintegraData.list.stateRegistration`
                            )}
                            :{' '}
                          </strong>
                          {list?.stateRegistration}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mrg-top-10">
                      <Col span={12}>
                        <span className="text-style">
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.company.pjSintegraData.list.stateRegistrationStatus`
                            )}
                            :{' '}
                          </strong>
                          {capitalizeFirstLetter(list?.stateRegistrationStatus)}
                        </span>
                      </Col>
                      <Col span={12}>
                        <span className="text-style">
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.company.pjSintegraData.list.stateRegistrationConsignee`
                            )}
                            :{' '}
                          </strong>
                          {capitalizeFirstLetter(list?.stateRegistrationConsignee)}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mrg-top-10">
                      <Col span={12}>
                        <span className="text-style">
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.company.pjSintegraData.list.stateRegistrationType`
                            )}
                            :{' '}
                          </strong>
                          {list?.stateRegistrationType || ' - '}
                        </span>
                      </Col>
                      <Col span={12}>
                        <span className="text-style">
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.company.pjSintegraData.list.stateRegistrationStatusDate`
                            )}
                            :{' '}
                          </strong>
                          {list?.stateRegistrationStatusDate}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mrg-top-10">
                      <Col span={12}>
                        <span className="text-style">
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.company.pjSintegraData.list.taxRegime`
                            )}
                            :{' '}
                          </strong>
                          {capitalizeFirstLetter(list?.taxRegime)}
                        </span>
                      </Col>
                      <Col span={12}>
                        <span className="text-style">
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.company.pjSintegraData.list.initialDateActivity`
                            )}
                            :{' '}
                          </strong>
                          {list?.initialDateActivity}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mrg-top-10">
                      <Col span={12}>
                        <span className="text-style">
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.company.pjSintegraData.list.finalDateActivity`
                            )}
                            :{' '}
                          </strong>
                          {list?.finalDateActivity || ' - '}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mrg-top-15">
                      <Col span={24} className="mrg-btm-10 mrg-top-15">
                        <strong className="text-style">
                          {translate(
                            `${I18N_BASE_PATH}.company.pjSintegraData.list.address.title`
                          )}
                          :{' '}
                        </strong>
                      </Col>
                      <Col span={11}>
                        <span className="text-style">
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.company.pjSintegraData.list.address.street`
                            )}
                            :{' '}
                          </strong>
                          {list?.address?.street}
                        </span>
                      </Col>
                      <Col span={5}>
                        <span className="text-style">
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.company.pjSintegraData.list.address.number`
                            )}
                            :{' '}
                          </strong>
                          {list?.address?.number}
                        </span>
                      </Col>
                      <Col span={8}>
                        <span className="text-style">
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.company.pjSintegraData.list.address.neighborhood`
                            )}
                            :{' '}
                          </strong>
                          {list?.address?.neighborhood}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mrg-top-10">
                      <Col span={11}>
                        <span className="text-style">
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.company.pjSintegraData.list.address.complement`
                            )}
                            :{' '}
                          </strong>
                          {list?.address?.complement}
                        </span>
                      </Col>
                      <Col span={5}>
                        <span className="text-style">
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.company.pjSintegraData.list.address.state`
                            )}
                            :{' '}
                          </strong>
                          {list?.address?.state}
                        </span>
                      </Col>
                      <Col span={8}>
                        <span className="text-style">
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.company.pjSintegraData.list.address.zipCode`
                            )}
                            :{' '}
                          </strong>
                          {toMaskedCep(list?.address?.zipCode)}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mrg-top-10">
                      <Col span={8}>
                        <span className="text-style">
                          <strong>
                            {translate(
                              `${I18N_BASE_PATH}.company.pjSintegraData.list.address.city`
                            )}
                            :{' '}
                          </strong>
                          {list?.address?.city.split('-').reverse().splice(0, 1)}
                        </span>
                      </Col>
                    </Row>

                    <Collapse
                      expandIconPosition="right"
                      className="mrg-top-15"
                      defaultActiveKey={toPrint ? ['1'] : null}
                    >
                      <Panel
                        disabled={!list?.historic?.length}
                        header={
                          <strong className="list-subtitle">
                            {translate(
                              `${I18N_BASE_PATH}.company.pjSintegraData.list.historic.title`
                            )}
                          </strong>
                        }
                        extra={
                          <span>
                            {list?.historic?.length}{' '}
                            {list?.historic?.length > 1
                              ? translate(
                                  `${I18N_BASE_PATH}.company.pjSintegraData.list.foundRegisters`
                                )
                              : translate(
                                  `${I18N_BASE_PATH}.company.pjSintegraData.list.notFoundRegister`
                                )}
                          </span>
                        }
                        key="1"
                      >
                        {list?.historic?.map((historic) => (
                          <>
                            <Row>
                              <Col span={12}>
                                <span className="text-style">
                                  <strong>
                                    {translate(
                                      `${I18N_BASE_PATH}.company.pjSintegraData.list.historic.cnpj`
                                    )}
                                    :{' '}
                                  </strong>
                                  {toMaskedCnpj(historic?.cnpj)}
                                </span>
                              </Col>
                              <Col span={12}>
                                <span className="text-style">
                                  <strong>
                                    {translate(
                                      `${I18N_BASE_PATH}.company.pjSintegraData.list.historic.cnpjStatus`
                                    )}
                                    :{' '}
                                  </strong>
                                  {capitalizeFirstLetter(historic?.cnpjStatus)}
                                </span>
                              </Col>
                            </Row>
                            <Row className="mrg-top-10">
                              <Col span={12}>
                                <span className="text-style">
                                  <strong>
                                    {translate(
                                      `${I18N_BASE_PATH}.company.pjSintegraData.list.historic.reason`
                                    )}
                                    :{' '}
                                  </strong>
                                  {historic?.reason}
                                </span>
                              </Col>
                              <Col span={12}>
                                <span className="text-style">
                                  <strong>
                                    {translate(
                                      `${I18N_BASE_PATH}.company.pjSintegraData.list.historic.stateRegistration`
                                    )}
                                    :{' '}
                                  </strong>
                                  {historic?.stateRegistration}
                                </span>
                              </Col>
                            </Row>
                            <Row className="mrg-top-10">
                              <Col span={12}>
                                <span className="text-style">
                                  <strong>
                                    {translate(
                                      `${I18N_BASE_PATH}.company.pjSintegraData.list.historic.stateRegistrationStatus`
                                    )}
                                    :{' '}
                                  </strong>
                                  {capitalizeFirstLetter(
                                    historic?.stateRegistrationStatus
                                  )}
                                </span>
                              </Col>
                              <Col span={12}>
                                <span className="text-style">
                                  <strong>
                                    {translate(
                                      `${I18N_BASE_PATH}.company.pjSintegraData.list.historic.taxRegime`
                                    )}
                                    :{' '}
                                  </strong>
                                  {capitalizeFirstLetter(historic?.taxRegime)}
                                </span>
                              </Col>
                            </Row>
                            <Row className="mrg-top-10">
                              <Col span={12}>
                                <span className="text-style">
                                  <strong>
                                    {translate(
                                      `${I18N_BASE_PATH}.company.pjSintegraData.list.historic.situationDate`
                                    )}
                                    :{' '}
                                  </strong>
                                  {historic?.situationDate}
                                </span>
                              </Col>
                              <Col span={12}>
                                <span className="text-style">
                                  <strong>
                                    {translate(
                                      `${I18N_BASE_PATH}.company.pjSintegraData.list.historic.updateDate`
                                    )}
                                    :{' '}
                                  </strong>
                                  {historic?.updateDate}
                                </span>
                              </Col>
                            </Row>
                            <Row className="mrg-top-10">
                              <Col span={12}>
                                <span className="text-style">
                                  <strong>
                                    {translate(
                                      `${I18N_BASE_PATH}.company.pjSintegraData.list.historic.uf`
                                    )}
                                    :{' '}
                                  </strong>
                                  {historic?.uf}
                                </span>
                              </Col>
                            </Row>
                            {list?.historic?.length > 1 && <Divider />}
                          </>
                        ))}
                      </Panel>
                    </Collapse>
                    {parsedData?.stateRegistrationList?.length > 1 && <Divider />}
                  </>
                ))}
              </Panel>
            </Collapse>
          </Col>
        </Row>
      )}
    </DataSourceCardCollapsePanel>
  );
};

export default PjSintegraData;
