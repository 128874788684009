import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col } from 'antd';

import DottedLabel from 'components/DottedLabel';
import TooltipInfo from 'components/TooltipInfo';

import './styles.less';

const ColumnLabel = ({
  title,
  customClassName,
  description,
  type,
  onClick,
  span,
  bordered,
  info,
  statusInfo,
  children,
}) => {
  return (
    <Col id="column-label-container" className={customClassName} span={span}>
      {title && (
        <div
          className="column-label-title"
          style={{ borderBottom: bordered ? '1px solid' : 'none' }}
        >
          <strong>{title}</strong> {info && <TooltipInfo title={info} />}
        </div>
      )}

      {onClick && (
        <button
          type="button"
          className="column-label-btn custom-label reset-btn active"
          onClick={onClick}
        >
          {description && type && <DottedLabel description={description} type={type} />}

          <div
            className={`column-label-description ${description && type && 'custom-pdd'}`}
          >
            {children}
          </div>
        </button>
      )}

      {!onClick && (
        <div className="custom-label">
          {description && type && (
            <div
              className={classNames({
                'flex start-center': statusInfo,
              })}
            >
              <DottedLabel description={description} type={type} />
              {statusInfo && <TooltipInfo title={statusInfo} />}
            </div>
          )}

          <div
            className={`column-label-description ${description && type && 'custom-pdd'}`}
          >
            {children}
          </div>
        </div>
      )}
    </Col>
  );
};

ColumnLabel.propTypes = {
  title: PropTypes.string,
  customClassName: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  span: PropTypes.number,
  bordered: PropTypes.bool,
  info: PropTypes.string,
  statusInfo: PropTypes.string,
};

ColumnLabel.defaultProps = {
  title: '',
  customClassName: '',
  description: '',
  type: '',
  onClick: undefined,
  span: 12,
  bordered: true,
  info: undefined,
  statusInfo: undefined,
};

export default ColumnLabel;
