/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col, Typography, Button } from 'antd';

import CircleIcon from 'components/Icon/CircleIcon';
import TooltipInfo from 'components/TooltipInfo';

import BRFlag from 'assets/images/flag-br.svg';

import { toMaskedCpf } from 'utils/formatters';
import { sanctionsInfos } from '../../../utils/sanctionsDetails';
import { pepLevel } from '../../../utils/handlerSanctions';
import { motiveTranslator } from '../../../utils/translator';

import SourceDetails from '../SourcesDetails';
import SourceInfosToPrint from '../SourceInfosToPrint';
import usePepHistoryModal from '../PepHistoryModal';

import './styles.less';

const I18N_BASE_PATH =
  'components.dataSourceCards.restrictiveLists.components.sources.sourcesCard.index';

const { Title } = Typography;

const SourcesCard = ({
  data,
  sanctionsSource,
  pepSource,
  // lastConsultation,
  national,
  international,
  others,
  pep,
  toPrint,
  toPrintDetails,
}) => {
  const { t: translate } = useTranslation();

  const sourceTitle = useMemo(() => {
    if (!others) {
      if (pep) {
        if (data?.jobTitle !== 'FAMILIAR') {
          return data?.jobTitle;
        }
        return (
          translate(motiveTranslator(data?.motive)) ||
          translate(`${I18N_BASE_PATH}.sourceTitle.withPep`)
        );
      }

      return data.toUpperCase() || '-';
    }
    return translate(`${I18N_BASE_PATH}.sourceTitle.default`);
  }, [data, pep, others, translate]);

  const sourceSubtitle = useMemo(() => {
    if (pep) {
      if (!data?.link && data?.level >= 2)
        return translate(`${I18N_BASE_PATH}.sourceSubtitle.hasLevel`);
      if (!data?.link && !data?.level >= 2)
        return translate(`${I18N_BASE_PATH}.sourceSubtitle.hasNotLevel`);
      return data?.source || '-';
    }
    return translate(sanctionsInfos(data)?.subtitle) || '-';
  }, [pep, data, translate]);

  const showTooltipInfo = useMemo(() => {
    return (
      !toPrint &&
      pep &&
      sourceSubtitle !== '-' &&
      sourceSubtitle !== translate(`${I18N_BASE_PATH}.showTooltipInfo.text`)
    );
  }, [toPrint, pep, sourceSubtitle, translate]);

  const { openModal, PepHistoryModal } = usePepHistoryModal({
    data,
    others,
    title: sourceTitle,
    subtitle: data?.department,
  });

  return (
    <>
      {PepHistoryModal}
      <Card bordered={false} id="executions-information-source-card">
        <Row>
          <Col flex="0 1" className="information-source-icons">
            <CircleIcon
              image={!others && international && sanctionsInfos(data)?.icon}
              icon={
                pep ? pepLevel(data?.level, translate)?.icon : sanctionsInfos(data)?.icon
              }
              danger={
                pep
                  ? pepSource?.length > 0
                  : sanctionsSource?.sanctions?.length > 0 || sanctionsSource?.length > 0
              }
            />

            {national && (
              <img
                src={BRFlag}
                alt={translate(`${I18N_BASE_PATH}.sourcesCard.BRFlag.alt`)}
                className="flag-icon"
              />
            )}
          </Col>

          <Col flex="0.95 1" className="information-source-content">
            <Title
              level={4}
              className={`information-source-title
            ${pep && !translate(motiveTranslator(data?.motive)) && 'gx-font-italic'}`}
            >
              {sourceTitle}
            </Title>

            <Typography
              className={`information-source-subtitle
            ${pep && !data?.source && !data?.level >= 2 && 'gx-font-italic'}`}
            >
              {!pep && (
                <>
                  {sourceSubtitle}

                  {showTooltipInfo && (
                    <TooltipInfo
                      title={translate(
                        `${I18N_BASE_PATH}.sourcesCard.typography.tooltipInfo.title`
                      )}
                      size={14}
                    />
                  )}
                </>
              )}
            </Typography>
            {pep && (
              <>
                <Col className="no-pdd">
                  <span>
                    <strong>{translate(`${I18N_BASE_PATH}.pepPartnerDocument`)}</strong>
                  </span>
                  <span>{toMaskedCpf(data?.cpf)}</span>
                </Col>
                <Col className="no-pdd">
                  <span>
                    <strong>{translate(`${I18N_BASE_PATH}.pepRelatedDocument`)}</strong>
                  </span>
                  <span>
                    {data?.firstLevelPepCpf ? toMaskedCpf(data?.firstLevelPepCpf) : '-'}
                  </span>
                </Col>
                <Col className="no-pdd">
                  <span>
                    <strong>{translate(`${I18N_BASE_PATH}.pepLevel`)}:</strong>
                    {data?.level}
                  </span>
                </Col>
                <Col className="no-pdd">
                  <Button type="link" className="no-pdd" onClick={() => openModal()}>
                    {translate(`${I18N_BASE_PATH}.pepViewMoreButton`)}
                    <i className="mrg-left-5 caf-ic_plus" />
                  </Button>
                </Col>
              </>
            )}

            {!pep && (
              <SourceDetails
                pep={pep}
                others={others}
                data={pep ? pepSource : sanctionsSource}
                title={pep ? translate(motiveTranslator(data?.motive)) : sourceTitle}
                subtitle={sourceSubtitle}
              />
            )}

            {/* <Typography className="information-source-updated">
            {pep
              ? i18nFormatDate(data?.lastUpdateDate, 6)
              : i18nFormatDate(lastConsultation, 6)}
          </Typography> */}

            {toPrint && toPrintDetails && (
              <SourceInfosToPrint
                pep={pep}
                others={others}
                data={pep ? pepSource : sanctionsSource?.sanctions}
              />
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default SourcesCard;
