/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useMemo, useState } from 'react';
import { Modal, Row, Col, Divider, Tooltip } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import Title from 'antd/lib/typography/Title';
import { News, ArrowDown, ArrowUp } from '@combateafraude/icons/general';
import CountryFlag from 'components/CountryFlag';
import ShowMoreText from 'react-show-more-text';
import { backgroundCard, matchMap } from '../Card/utils';
import { translateTypes } from './utils';

import './styles.less';

const I18N_BASE_PATH =
  'components.dataSourceCards.KYCAndCompliance.index.component.modal';

export const ComplyModal = ({ translate }) => {
  const { i18nFormatDate } = useI18nFormatters();

  const [modalData, setModalData] = useState(null);
  const [modalOpen, setModalOpen] = useState(null);

  const MODAL = {
    adverseMedia: 'adverseMedia',
    sanctions: 'sanctions',
    peps: 'peps',
    warnings: 'warnings',
  };

  const openModal = useCallback(
    ({ data, modalShouldBeOpen }) => {
      setModalData(data);
      setModalOpen(MODAL[modalShouldBeOpen]);
    },
    [MODAL]
  );

  const closeModal = useCallback(() => {
    setModalOpen(null);
    setModalData(null);
  }, []);

  const renderAppearsOn = useCallback(
    (types) => {
      if (Array.isArray(types) && types.length > 0) {
        let matchTypes;
        if (modalOpen === 'warnings') {
          matchTypes = (
            <span className="tag-background">
              {translate(
                `components.dataSourceCards.KYCAndCompliance.index.component.card.column.warnings`,
                'Warnings'
              )}
            </span>
          );
        }
        if (modalOpen === MODAL.adverseMedia) {
          matchTypes = (
            <span className="tag-background">
              {types?.length}{' '}
              {translate(
                `components.dataSourceCards.KYCAndCompliance.index.component.card.column.adverseMediaLabel`,
                'Types of Adverse Media'
              )}
            </span>
          );
        }
        if (modalOpen === MODAL.sanctions) {
          matchTypes = (
            <span className="tag-background">
              {' '}
              {translate(
                `components.dataSourceCards.KYCAndCompliance.index.component.card.column.sanctionLabel`,
                'Sanction'
              )}
            </span>
          );
        }
        if (modalOpen === MODAL.peps) {
          matchTypes = types.map((pep) => {
            return (
              backgroundCard[pep] && (
                <Tooltip title={translate(backgroundCard[pep]?.i18nTooltip)}>
                  <span
                    className="tag-background"
                    style={{
                      background: backgroundCard[pep]?.backgroundColor,
                      color: backgroundCard[pep]?.color,
                    }}
                  >
                    {translate(backgroundCard[pep]?.i18n)}
                  </span>
                </Tooltip>
              )
            );
          });
        }
        if (matchTypes) return matchTypes;
      }
      return null;
    },
    [modalOpen, MODAL, translate]
  );

  const renderMatchingCountries = (fields) => {
    const availableCountries = fields
      ?.filter((field) => {
        if (
          !field?.value.includes('http') &&
          !field?.value.includes('https') &&
          !field?.value.includes('0') &&
          !field?.value.includes('1') &&
          !field?.value.includes('2') &&
          !field?.value.includes('3') &&
          !field?.value.includes('4') &&
          !field?.value.includes('5') &&
          !field?.value.includes('6') &&
          !field?.value.includes('7') &&
          !field?.value.includes('8') &&
          !field?.value.includes('9')
        ) {
          if (!field?.tag) {
            return field;
          }
          if (field?.tag !== 'date_of_birth') {
            return field;
          }
        }
      })
      .map((element) => element?.value);

    const uniqueValues = [...new Set(availableCountries)];

    return uniqueValues?.sort((prevElement, nextElement) =>
      prevElement.localeCompare(nextElement)
    );
  };

  const renderBirthDates = (fields) => {
    const allTags = fields
      .filter((field) => {
        if (field?.value && field?.tag && field?.tag === 'date_of_birth') {
          return field;
        }
      })
      .map((element) => element?.value);

    const uniqueValues = [...new Set(allTags)];
    return uniqueValues;
  };

  const mediaTitle = useCallback(
    (title) => {
      if (title) {
        if (title === '(no title)') {
          return translate(
            `${I18N_BASE_PATH}.mediaDetails.labels.undefinedTitle`,
            'No title'
          );
        }
        return title;
      }
      return translate(
        `${I18N_BASE_PATH}.mediaDetails.labels.undefinedTitle`,
        'No title'
      );
    },
    [translate]
  );

  const MediaDetails = useMemo(
    () => (
      <Modal
        width={700}
        footer={null}
        style={{ top: 40 }}
        onCancel={closeModal}
        visible={modalOpen === MODAL.adverseMedia && modalData}
        wrapClassName="caf-modal"
      >
        <Title level={3} className="titleModal">
          {`${translate(`${I18N_BASE_PATH}.showFullResult.labels.entity`, 'Entity')} '${
            modalData?.name
          }'`}
        </Title>
        <Row className="no-mrg no-pdd mrg-btm-40">
          <Col className="no-mrg no-pdd">
            {translate(
              'components.dataSourceCards.KYCAndCompliance.index.component.modal.appearsOn'
            )}{' '}
            {renderAppearsOn(modalData?.types)}
          </Col>
        </Row>
        <span className="font-size-12">
          {translate(
            `${I18N_BASE_PATH}.showFullResult.titles.keyInformation`,
            'KEY INFORMATION'
          )}
        </span>
        <Row className="text-dark mrg-vertical-20">
          {modalData?.name && (
            <Col span={24} className="mrg-btm-10">
              <Row>
                <Col span={8}>
                  <b>
                    {translate(`${I18N_BASE_PATH}.showFullResult.labels.name`, 'Name')}
                  </b>
                </Col>
                <Col span={16}>
                  <span>{modalData?.name}</span>
                </Col>
              </Row>
            </Col>
          )}
          {modalData?.entityType && (
            <Col span={24} className="mrg-btm-10">
              <Row>
                <Col span={8}>
                  <b>
                    {translate(
                      `${I18N_BASE_PATH}.showFullResult.labels.entityType`,
                      'Entity type'
                    )}
                  </b>
                </Col>
                <Col span={16}>
                  <span>{modalData?.entityType}</span>
                </Col>
              </Row>
            </Col>
          )}
          {!!modalData?.countryCodes?.length && (
            <Col span={24} className="mrg-btm-10">
              <Row>
                <Col span={8}>
                  <b>
                    {translate(
                      `${I18N_BASE_PATH}.showFullResult.labels.countries`,
                      'Countries'
                    )}
                  </b>
                </Col>
                <Col span={16}>
                  <span>
                    {modalData?.countryCodes?.map((cc, i) => {
                      return `${translate(`countries.${cc}`) || cc}${
                        i < modalData?.countryCodes?.length - 1 ? ', ' : ''
                      }`;
                    })}
                  </span>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Divider />
        {modalOpen === MODAL.adverseMedia ? (
          <div className="content-overflow-modal">
            {modalData?.media?.map((data, ix) => (
              <Row className="no-mrg no-pdd">
                <Col span={1} className="iconLinkMedia no-mrg no-pdd">
                  <a
                    href={`${data?.url}`}
                    className="linkModal"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <News style={{ fontSize: 24, marginTop: 10 }} />
                  </a>
                </Col>
                <Col span={23}>
                  <Row>
                    <Col span={24}>
                      <a
                        href={`${data?.url}`}
                        className="linkModal"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {mediaTitle(data?.title)}
                      </a>
                    </Col>
                    <Col span={24} className="date">
                      {data?.date ? (
                        <div>
                          <span>
                            {translate(
                              `${I18N_BASE_PATH}.mediaDetails.labels.published`,
                              'Published'
                            )}
                          </span>{' '}
                          {i18nFormatDate(data?.date, 6)}
                        </div>
                      ) : (
                        <div>
                          <span>
                            {translate(`${I18N_BASE_PATH}.mediaDetails.labels.published`)}
                          </span>{' '}
                          {translate(`${I18N_BASE_PATH}.mediaDetails.labels.noDate`)}
                        </div>
                      )}
                    </Col>
                    <Col span={24} className="snippet">
                      {data?.snippet}
                    </Col>
                  </Row>
                </Col>
                {ix < modalData?.media?.length - 1 ? <Divider /> : <></>}
              </Row>
            ))}
          </div>
        ) : (
          <></>
        )}
      </Modal>
    ),
    [
      closeModal,
      modalOpen,
      MODAL,
      modalData,
      translate,
      renderAppearsOn,
      mediaTitle,
      i18nFormatDate,
    ]
  );
  const ShowMore = useMemo(
    () => (
      <Modal
        width={700}
        footer={null}
        style={{ top: 40 }}
        onCancel={closeModal}
        visible={modalOpen !== MODAL.adverseMedia && modalData}
        wrapClassName="caf-modal"
      >
        <Title level={3} className="titleModal">
          {`${translate(`${I18N_BASE_PATH}.showFullResult.labels.entity`, 'Entity')} '${
            modalData?.name
          }'`}
        </Title>
        <Row className="no-mrg no-pdd mrg-btm-40">
          <Col className="no-mrg no-pdd">
            {translate(
              'components.dataSourceCards.KYCAndCompliance.index.component.modal.appearsOn'
            )}{' '}
            {renderAppearsOn(modalData?.types)}
          </Col>
        </Row>
        <span className="font-size-12">
          {translate(
            `${I18N_BASE_PATH}.showFullResult.titles.keyInformation`,
            'KEY INFORMATION'
          )}
        </span>
        <Row className="text-dark mrg-vertical-20">
          {modalData?.name && (
            <Col span={24} className="mrg-btm-10">
              <Row>
                <Col span={8}>
                  <b>
                    {translate(`${I18N_BASE_PATH}.showFullResult.labels.name`, 'Name')}
                  </b>
                </Col>
                <Col span={16}>
                  <span>{modalData?.name}</span>
                </Col>
              </Row>
            </Col>
          )}
          {modalData?.entityType && (
            <Col span={24} className="mrg-btm-10">
              <Row>
                <Col span={8}>
                  <b>
                    {translate(
                      `${I18N_BASE_PATH}.showFullResult.labels.entityType`,
                      'Entity type'
                    )}
                  </b>
                </Col>
                <Col span={16}>
                  <span>{modalData?.entityType}</span>
                </Col>
              </Row>
            </Col>
          )}
          {!!modalData?.countryCodes?.length && (
            <Col span={24} className="mrg-btm-10">
              <Row>
                <Col span={8}>
                  <b>
                    {translate(
                      `${I18N_BASE_PATH}.showFullResult.labels.countries`,
                      'Countries'
                    )}
                  </b>
                </Col>
                <Col span={16}>
                  <span>
                    {modalData?.countryCodes?.map((cc, i) => {
                      return `${translate(`countries.${cc}`) || cc}${
                        i < modalData?.countryCodes?.length - 1 ? ', ' : ''
                      }`;
                    })}
                  </span>
                </Col>
              </Row>
            </Col>
          )}
          {!!modalData?.birthDate?.length && (
            <Col span={24} className="mrg-btm-10">
              <Row>
                <Col span={8}>
                  <b>
                    {translate(
                      `${I18N_BASE_PATH}.showFullResult.labels.birthDate`,
                      'Birth Date'
                    )}
                  </b>
                </Col>
                <Col span={16}>
                  <span>
                    {modalData?.birthDate?.map((bd, i) => {
                      return `${bd}${i < modalData?.birthDate?.length - 1 ? ', ' : ''}`;
                    })}
                  </span>
                </Col>
              </Row>
            </Col>
          )}
          {!!modalData?.deathDate?.length && (
            <Col span={24} className="mrg-btm-10">
              <Row>
                <Col span={8}>
                  <b>
                    {translate(
                      `${I18N_BASE_PATH}.showFullResult.labels.deathDate`,
                      'Date of death'
                    )}
                  </b>
                </Col>
                <Col span={16}>
                  <span>
                    {modalData?.deathDate?.map((dd, i) => {
                      return `${dd}${i < modalData?.deathDate?.length - 1 ? ', ' : ''}`;
                    })}
                  </span>
                </Col>
              </Row>
            </Col>
          )}
          {modalData?.associates && (
            <Col span={24} className="mrg-btm-10">
              <Row>
                <Col span={8}>
                  <b>
                    {translate(
                      `${I18N_BASE_PATH}.showFullResult.labels.associates`,
                      'Associates'
                    )}
                  </b>
                </Col>
                <Col span={16}>
                  <ShowMoreText
                    lines={3}
                    more={
                      <p className="showMoreAssociates">
                        {translate(
                          'components.dataSourceCards.KYCAndCompliance.index.component.modal.showMore'
                        )}{' '}
                        <ArrowDown width={20} height={20} />
                      </p>
                    }
                    less={
                      <p className="showMoreAssociates">
                        {translate(
                          'components.dataSourceCards.KYCAndCompliance.index.component.modal.showLess'
                        )}{' '}
                        <ArrowUp width={20} height={20} />
                      </p>
                    }
                    expanded={false}
                  >
                    {modalData?.associates?.map((ass, i) => {
                      return `${ass?.name} (${ass?.association})${
                        i < modalData?.associates?.length - 1 ? ', ' : ''
                      }`;
                    })}
                  </ShowMoreText>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Divider />
        <span className="font-size-12">
          {translate(`${I18N_BASE_PATH}.showFullResult.titles.appearsOn`, 'APPEARS ON')}
        </span>
        <div className="mrg-vertical-20">
          {modalData?.fields &&
            !Array.isArray(modalData?.fields) &&
            Object.keys(modalData?.fields)
              .sort((a, b) => {
                if (a.length && b.startsWith('other')) return -1;
                if (a.startsWith('other') && b.length) return 1;
                return a.localeCompare(b);
              })
              .map((listType, ix) => {
                return (
                  <>
                    <Row className="text-dark">
                      <Col span={7}>
                        <Row className="no-mrg no-pdd">
                          <Col
                            span={
                              Object?.keys(modalData?.fields[listType])?.length > 9
                                ? 6
                                : 4
                            }
                            className="no-mrg no-pdd"
                          >
                            <span style={{ fontSize: 32, fontWeight: 700 }}>
                              {Object?.keys(modalData?.fields[listType])?.length}
                            </span>
                          </Col>
                          <Col span={11} className="no-mrg no-pdd pdd-top-5">
                            <span>
                              {translate(translateTypes[listType]?.title) || listType}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={17}>
                        {Object.keys(modalData?.fields[listType])?.map((listName, i) => {
                          return (
                            <div style={{ marginTop: i > 0 ? 20 : 0 }}>
                              <p className="flex start-center">
                                {modalData?.sourceNotes[listName]?.countryCodes
                                  ?.length && (
                                  <CountryFlag
                                    className="mrg-right-10"
                                    style={{ minWidth: 'fit-content' }}
                                    country={
                                      modalData?.sourceNotes[listName]?.countryCodes[0]
                                    }
                                  />
                                )}
                                {modalData?.sourceNotes[listName]?.url ? (
                                  <a
                                    className="text-bold"
                                    style={{
                                      textDecoration: 'underline',
                                    }}
                                    href={modalData?.sourceNotes[listName]?.url}
                                    target="_blank"
                                  >
                                    {modalData?.sourceNotes[listName]?.name || listName}
                                  </a>
                                ) : (
                                  <b className="text-dark">
                                    {modalData?.sourceNotes[listName]?.name || listName}
                                  </b>
                                )}
                              </p>
                              <Row>
                                {modalData?.fields &&
                                modalData?.fields[listType] &&
                                modalData?.fields[listType][listName] ? (
                                  modalData?.fields[listType][listName]?.map((field) => {
                                    return (
                                      <Col span={24} className="mrg-btm-10">
                                        <Row>
                                          <Col span={8} className="text-bold">
                                            {field?.name}
                                          </Col>
                                          <Col span={16}>
                                            {field?.value?.includes('http') ? (
                                              <a
                                                href={field?.value}
                                                style={{ textDecoration: 'underline' }}
                                                target="_blank"
                                              >
                                                {field?.value}
                                              </a>
                                            ) : (
                                              field?.value
                                            )}
                                          </Col>
                                        </Row>
                                      </Col>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </Row>
                            </div>
                          );
                        })}
                      </Col>
                    </Row>
                    {ix < Object.keys(modalData?.fields)?.length - 1 ? (
                      <Divider />
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
        </div>
      </Modal>
    ),
    [closeModal, modalOpen, MODAL, modalData, translate, renderAppearsOn]
  );

  return {
    MODAL,
    ShowMore,
    openModal,
    closeModal,
    MediaDetails,
  };
};
