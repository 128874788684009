/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Divider } from 'antd';

import { handlerAssociateRegister } from 'utils/associateRegisters';

import PageTitle from 'components/PageTitle';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import { splitInfos } from './utils/splitInfos';

import './styles.less';

const CNDTDebtsItems = ({ data, toPrint }) => {
  const { t: translate } = useTranslation();

  const customTitle = useMemo(() => {
    return toPrint
      ? `${translate(
          'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.cndt.cndtDebtsItems.index.customTitle.hasToPrint'
        )} ${
          data?.number ||
          translate(
            'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.cndt.cndtDebtsItems.index.customTitle.hasNotNumber'
          )
        }`
      : `${translate(
          'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.cndt.cndtDebtsItems.index.customTitle.hasNotToPrint'
        )} ${
          data?.number ||
          translate(
            'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.cndt.cndtDebtsItems.index.customTitle.hasNotNumber'
          )
        }`;
  }, [toPrint, data, translate]);

  return (
    <div id="cndt-debts-items">
      <PageTitle
        title={customTitle}
        subtitle={`${
          data?.expedition
            ? `${translate(
                'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.cndt.cndtDebtsItems.index.cndtDebtsItems.pageTitle.subtitle.expeditionDate.default'
              )} ${data?.expedition}`
            : translate(
                'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.cndt.cndtDebtsItems.index.cndtDebtsItems.pageTitle.subtitle.expeditionDate.hasNotExpeditionDate'
              )
        } - ${
          data?.expiresOn
            ? `${translate(
                'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.cndt.cndtDebtsItems.index.cndtDebtsItems.pageTitle.subtitle.expiresOn.default'
              )} ${data?.expiresOn}`
            : translate(
                'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.cndt.cndtDebtsItems.index.cndtDebtsItems.pageTitle.subtitle.expiresOn.hasNotExpiresOnDate'
              )
        }`}
      />

      <Row className="cndt-debts-items-title reset-mrg">
        <strong>
          {handlerAssociateRegister(data?.lawsuitsList?.length, undefined, translate)}
        </strong>
      </Row>

      <Divider className="custom-divider" />

      <Row className="cndt-debts-data reset-mrg">
        {data?.lawsuitsList?.map(({ lawsuit }) => (
          <ColumnLabel
            key={lawsuit}
            description={splitInfos(lawsuit)?.number || '-'}
            type="danger"
          >
            {splitInfos(lawsuit)?.court}
          </ColumnLabel>
        ))}
      </Row>

      <Row className="reset-mrg">
        <span className="cndt-debts-observation">
          {data?.observation
            ? `${data?.observation}`
            : translate(
                'pages.private.profiles.components.dataSourceCards.laborInformation.companies.components.cndt.cndtDebtsItems.index.cndtDebtsItems.hasNotObservation'
              )}
        </span>
      </Row>
    </div>
  );
};

export default CNDTDebtsItems;
