import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import EmptyExecutionState from 'components/EmptyExecutionState';

import CCF from './Components/CCF';
import CounterOrder from './Components/CounterOrder';
import CounterOrderDifferentDocument from './Components/CounterOrderDifferentDocument';
import Lawsuit from './Components/Lawsuit';
import JudiciaryInformation from './Components/JudiciaryInformation';
import JudicialProtest from './Components/JudicialProtest';
import QueriesPerformed from './Components/QueriesPerformed';
import ContumaciaSRS from './Components/ContumaciaSRS';
import BankruptcyParticipation from './Components/BankruptcyParticipation';
import FinancialPending from './Components/FinancialPending';
import ReferentialBusiness from './Components/ReferentialBusiness';
import PaymentHistory from './Components/PaymentHistory';

import ConsultationLogs from './Components/ConsultationLogs';
import LastConsultations from './Components/LastConsultations';
import Predecessor from './Components/Predecessor';
import CompanyMergerSplit from './Components/CompanyMergerSplit';
import ComplementaryMessage from './Components/ComplementaryMessage';

import DataSourceCardCollapsePanel from '../../../../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.companies.components.spcReportParticipationScore.otherInfos';

const OtherInfoContent = ({ source, toPrint }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () => statusCode === '01' || source?.available || source.lastConsultation,
    [statusCode, source]
  );

  return queryWorked ? (
    <DataSourceCardCollapsePanel
      customId="report-spc-last-phone-card"
      icon="caf-ic_info"
      title={translate(`${I18N_BASE_PATH}.index.title`)}
      toPrint={toPrint}
    >
      <Row className="mrg-top-25">
        <Col span={24}>
          {/* cheques sem fundo */}
          <CCF source={source} toPrint={toPrint} />

          {/* Registro de contra-ordem */}
          <CounterOrder source={source} toPrint={toPrint} />

          {/* Registro de cheque ou talao sustado com documento diferente do consultado */}
          <CounterOrderDifferentDocument source={source} toPrint={toPrint} />

          {/* Registro de ações judiciais */}
          <Lawsuit source={source} toPrint={toPrint} />

          {/* Informações do poder judiciário */}
          <JudiciaryInformation source={source} toPrint={toPrint} />

          {/* Registro de ações protestos */}
          <JudicialProtest source={source} toPrint={toPrint} />

          {/* Consultas realizadas anteriormente */}
          <QueriesPerformed source={source} toPrint={toPrint} />

          {/* Informacoes de contumacia */}
          <ContumaciaSRS source={source} toPrint={toPrint} />

          {/* Participação do CNPJ consultado em empresas falidas */}
          <BankruptcyParticipation source={source} toPrint={toPrint} />

          {/* Registros de inadimplência registrada por diversos setores da econômia em outras fontes de informação */}
          <FinancialPending source={source} toPrint={toPrint} />

          {/* Informações referenciais para o estabelecimento de limites de crédito */}
          <ReferentialBusiness source={source} toPrint={toPrint} />

          {/* Informações sobre em relação aos pagamentos no mês atual e nos últimos 13 meses */}
          <PaymentHistory source={source} toPrint={toPrint} />

          {/* Informações acerca das consultas sobre a empresa no mês atual e nos 13 meses anteriores */}
          <ConsultationLogs source={source} toPrint={toPrint} />

          {/* Últimas consultas realizadas ao CNPJ */}
          <LastConsultations source={source} toPrint={toPrint} />

          {/* Razões que antecederam a atual */}
          <Predecessor source={source} toPrint={toPrint} />

          {/* Informações relativas a fusões, cisões ou incorporações */}
          <CompanyMergerSplit source={source} toPrint={toPrint} />

          {/* Mensagens diversas */}
          <ComplementaryMessage source={source} toPrint={toPrint} />
        </Col>
      </Row>
    </DataSourceCardCollapsePanel>
  ) : (
    <DataSourceCardCollapsePanel
      customId="report-spc-last-phone-card"
      icon="caf-ic_info"
      title={translate(`${I18N_BASE_PATH}.index.title`)}
      toPrint={toPrint}
    >
      <Row gutter={24}>
        <Col span={24}>
          <EmptyExecutionState
            statusCode={statusCode}
            message={source?.message || parsedData?.message}
          />
        </Col>
      </Row>
    </DataSourceCardCollapsePanel>
  );
};

OtherInfoContent.propTypes = {
  person: PropTypes.shape({
    basicData: PropTypes.shape({
      phoneNumber: PropTypes.string,
      companyEmail: PropTypes.string,
    }),
    contact: PropTypes.shape({
      phones: PropTypes.shape({
        lastConsultation: PropTypes.objectOf(),
      }),
      emails: PropTypes.shape({
        lastConsultation: PropTypes.objectOf(),
      }),
    }),
    status: PropTypes.string,
  }),
  source: PropTypes.objectOf(),
  toPrint: PropTypes.bool,
};

OtherInfoContent.defaultProps = {
  person: undefined,
  source: null,
  toPrint: false,
};

export default OtherInfoContent;
