import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useWorkflow } from 'hooks/useWorkflow';

import ExpandIcon from 'components/Icon/ExpandIcon';
import { Collapse, Select } from 'antd';

const { Panel } = Collapse;
const { Option } = Select;

const WorkflowsFilter = ({ title, value, onChange }) => {
  const { t: translate } = useTranslation();

  const { data: allWorkflows, loading: loadingWorkflows } = useWorkflow();

  return (
    <Collapse ghost expandIconPosition="right" expandIcon={ExpandIcon}>
      <Panel header={title} key="workflows">
        <Select
          mode="multiple"
          value={value}
          onChange={(values) => onChange(values)}
          loading={loadingWorkflows}
          disabled={loadingWorkflows}
          placeholder={translate(
            'components.filters.workflowsFilter.collapse.panel.select.placeholder'
          )}
          dropdownClassName="multiselect-filters"
          dropdownAlign={{ offset: [0, 2] }}
          removeIcon={<i className="caf-ic_circle_false" />}
          menuItemSelectedIcon={<i className="caf-ic_checkmark" />}
          showSearch={!loadingWorkflows}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return option?.title?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
          }}
        >
          {allWorkflows?.docs?.map((workflow) => {
            const {
              definition: { name },
              _id,
            } = workflow || {};

            return (
              <Option key={_id} value={_id} title={name}>
                <div className="multiselect-option">
                  <span className="option-title">{name}</span>
                </div>
              </Option>
            );
          })}
        </Select>
      </Panel>
    </Collapse>
  );
};

WorkflowsFilter.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default WorkflowsFilter;
