import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import Tag from 'antd/es/tag';

import PermissionWrapper from 'routes/PermissionWrapper';

import './styles.less';

const CheckpointModel = ({
  title,
  description,
  // openSettings,
  status,
  hasModel,
  openManualQuery,
  isProfileTemplateActive,
}) => {
  const { t: translate } = useTranslation();

  const { personId, companyId } = useParams();

  const updatePermissions = useMemo(() => {
    if (personId) return ['people:update'];
    if (companyId) return ['companies:update'];
    return [];
  }, [personId, companyId]);

  return (
    <div id="checkpoint-model-container">
      <div className="checkpoint-model-infos">
        <strong>{title || '-'}</strong>
        <span className="max-lines-2">{description || '-'}</span>
      </div>

      <PermissionWrapper requiredPermissions={updatePermissions}>
        {hasModel && isProfileTemplateActive ? (
          <Tooltip
            placement="top"
            title={translate(
              'pages.private.profiles.components.checkpointModel.index.checkpointModel.tooltip.title.manualQuery'
            )}
            trigger={['hover']}
          >
            <button type="button" className="reset-btn" onClick={openManualQuery}>
              <i
                className={`caf-ic_processing_line ${
                  status === 'PROCESSING' && 'rotate'
                }`}
              />
            </button>
          </Tooltip>
        ) : (
          <>
            {!isProfileTemplateActive && (
              <Tag className="tag-inactive">
                {translate(
                  'pages.private.profiles.components.checkpointModel.index.checkpointModel.isNotProfileTemplateActive'
                )}
              </Tag>
            )}
          </>
        )}
      </PermissionWrapper>

      {/* <PermissionWrapper requiredPermissions={updatePermissions}>
        <Tooltip
          placement="top"
          title={translate(
            'pages.private.profiles.components.checkpointModel.index.checkpointModel.tooltip.title.profileTemplate'
          )}
          trigger={['hover']}
        >
          <button type="button" className="reset-btn" onClick={openSettings}>
            <i className="caf-ic_settings" />
          </button>
        </Tooltip>
      </PermissionWrapper> */}
    </div>
  );
};

CheckpointModel.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  // openSettings: PropTypes.func,
  openManualQuery: PropTypes.func,
  status: PropTypes.string,
  hasModel: PropTypes.bool,
  isProfileTemplateActive: PropTypes.bool,
};

CheckpointModel.defaultProps = {
  // openSettings: undefined,
  openManualQuery: undefined,
  status: undefined,
  hasModel: false,
  isProfileTemplateActive: true,
};

export default CheckpointModel;
