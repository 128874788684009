export const mapSuspiciousPlacesStatus = (status) => {
  switch (status) {
    case false:
      return { status: 'valid', type: 'primary' };

    case true:
      return { status: 'invalid', type: 'danger' };

    default:
      return { status: 'undefined', type: 'default' };
  }
};
