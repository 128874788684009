import { useContext } from 'react';

import { ComplyDataContext } from '../contexts';

export const useComplyData = () => {
  const context = useContext(ComplyDataContext);

  if (!context) throw new Error('useComplyData must be used within an ComplyDataContext');

  return context;
};
