export const supportedFiles = ['.png', '.jpg', '.jpeg', '.pdf'];
export const optionalSources = ['authentic_document', 'documentscopy', 'ocr'];
export const requiredImagesSources = [
  'authentic_document',
  'documentscopy',
  'ocr',
  'official_data',
  'official_data_latam',
  'facematch',
  'document_liveness',
  'shared_faceset',
];

export const requiredSelfieSources = [
  'private_faceset',
  'facematch',
  'shared_faceset',
  'pf_biometria_unico',
  'pf_biometria_serasa',
  'pf_biometria_vivo',
  'pf_biometria_id_unico',
];
