import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFetch } from 'services/hooks';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/auth';

export const ssoStatus = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  NOT_CONFIGURED: 'NOT_CONFIGURED',
};

export const protocolTypes = ['OIDC', 'SAML'];

export const providers = ['GOOGLE', 'MICROSOFT'];

export function useSSO() {
  const [sso, setSSO] = useState();

  const { t: translate } = useTranslation();
  const { user } = useAuth();

  const { patch, get, post, put, loading } = useFetch();

  const updateSSOStatus = async (status) => {
    await patch({
      url: 'sso',
      payload: {
        status,
      },
    });

    setSSO({ ...sso, status });
  };

  const createSSOConfig = async (ssoConfigs) => {
    try {
      await post({
        url: 'sso',
        payload: {
          ...ssoConfigs,
        },
      });

      setSSO({ ...ssoConfigs, status: ssoStatus.ENABLED });
      message.success(translate('global.hooks.fetch.methods.post.success.message'));
    } catch (error) {
      message.error(translate('global.hooks.fetch.methods.post.error.message'));
    }
  };

  const updateSSOConfig = async (ssoConfigs) => {
    try {
      await put({
        url: 'sso',
        payload: {
          ...ssoConfigs,
        },
      });

      setSSO({ ...ssoConfigs, status: ssoStatus.ENABLED });
      message.success(translate('global.hooks.fetch.methods.patch.success.message'));
    } catch (error) {
      message.error(translate('global.hooks.fetch.methods.patch.error.message'));
    }
  };

  const getSSOConfig = async () => {
    const response = await get({
      url: 'sso',
    });

    setSSO({ ...response });
  };

  useEffect(() => {
    getSSOConfig();
  }, [user]);

  return [
    sso,
    {
      updateSSOStatus,
      createSSOConfig,
      updateSSOConfig,
    },
    loading,
  ];
}

export const ssoConfigurationShape = PropTypes.shape({
  identifier: PropTypes.string,
  replyUrl: PropTypes.string,
  providerType: PropTypes.oneOf(protocolTypes),
  provider: PropTypes.oneOf(providers),
  metadataUrl: PropTypes.string,
  usersGroupId: PropTypes.string,
  domains: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.oneOf([ssoStatus.ENABLED, ssoStatus.DISABLED]),
  dnsVerificationRecord: PropTypes.string,
});
