/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { Popover, Tag } from 'antd';
import { useQueryParams, StringParam } from 'use-query-params';

import { scrollToElement } from 'utils/scroll';
import { validationStatus } from 'utils/handlerStatus';

import DottedLabel from 'components/DottedLabel';

import PermissionWrapper from 'routes/PermissionWrapper';

import { redirectTo } from '../../utils/redirectTo';
// import usePendingStatusModal from '../PendingStatusModal';

import './styles.less';

const TrustItem = ({ title, status, data }) => {
  const { t: translate } = useTranslation();

  const matchPeople = useRouteMatch('/profile/people');

  const [, setQuery] = useQueryParams({
    tab: StringParam,
  });

  // const { PendingStatusModal, openModal } = usePendingStatusModal({
  //   data,
  // });

  const reprovalReasons = useMemo(() => {
    return data?.reprovalReasons || [];
  }, [data]);

  const translatedStatus = useMemo(() => {
    const translatedStatusData = validationStatus(status);

    if ((status === 'REPROVED' && data?.numberInvalid > 0) || status === 'PENDING') {
      const totalInvalid = reprovalReasons?.length + Number(data?.numberInvalid);
      return {
        ...translatedStatusData,
        title: `${totalInvalid} ${translate(
          `pages.private.profiles.components.trustValidations.components.trustItem.index.translatedStatus.title.${
            totalInvalid > 1 ? 'moreThanOneAlert' : 'oneAlert'
          }`
        )}`,
      };
    }

    return translatedStatusData;
  }, [status, data, reprovalReasons, translate]);

  const filteredValidations = useMemo(() => {
    if (!data?.validations?.length > 0) return [];

    const filteredValidationsData = data?.validations?.filter(
      (v) => v.status === 'INVALID'
    );

    return filteredValidationsData;
  }, [data]);

  const reprovalReasonsList = useMemo(() => {
    const reasons = [];

    if (reprovalReasons?.length > 0) {
      reprovalReasons.forEach((reason) => {
        const manualDisapproval = translate(
          'pages.private.profiles.components.trustValidations.components.trustItem.index.reprovalReasonsList.manualDisapproval',
          { reason }
        );
        reasons.push({ rule: 'manual_disapproval', description: manualDisapproval });
      });
    }

    filteredValidations.forEach((validation) => reasons.push(validation));

    if (reasons?.length > 0) {
      return (
        <ul className="reproved-reasons-list">
          {reasons.map((reason) => (
            <>
              <li key={reason?.rule}>
                <i className="caf-ic_circle_false" />
                <span>{reason?.description}</span>
              </li>
              {reason.rule === 'service_available' && (
                <div className="unavailable-sources-list mrg-top-10">
                  {reason.unavailableServices.map((item) => (
                    <div key={item.source}>
                      <span>{item.title}</span>
                      <span>
                        {item.message ||
                          translate(
                            'pages.private.profiles.components.trustValidations.components.trustItem.index.reprovalReasonsList.hasNoData'
                          )}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </>
          ))}
        </ul>
      );
    }

    return (
      <div className="reproved-reasons-empty">
        <span>
          {translate(
            'pages.private.profiles.components.trustValidations.components.trustItem.index.reprovalReasonsList.emptyData'
          )}
        </span>
      </div>
    );
  }, [reprovalReasons, filteredValidations, translate]);

  const scrollToComponent = useCallback(() => {
    const destiny = translate(redirectTo(data?.name, data?.executionId));

    setQuery({ tab: destiny?.tab });

    setTimeout(() => {
      scrollToElement(destiny?.id);
    }, 100);
  }, [setQuery, data, translate]);

  // const permissions = useMemo(
  //   () => (matchPeople ? ['people:update'] : ['companies:update']),
  //   [matchPeople]
  // );

  const permissionsRead = useMemo(
    () => (matchPeople ? ['people:read'] : ['companies:read']),
    [matchPeople]
  );

  const permissionsReadResources = useMemo(
    () =>
      matchPeople
        ? ['justice:*', 'criminal:*', 'others:kycCompliance:*']
        : ['justice:*', 'others:kycCompliance:*', 'others:kycComplianceOwners:*'],
    [matchPeople]
  );

  const TagStatus = useMemo(
    () => (
      <Tag className={`squared-tag ${translatedStatus?.type || 'default'}`}>
        {translatedStatus?.title}
      </Tag>
    ),
    [translatedStatus]
  );

  return (
    <div id="trust-item">
      {/* {PendingStatusModal} */}

      <header onClick={scrollToComponent}>
        <span className="title">
          <DottedLabel description={title} type={translatedStatus?.type} />
        </span>

        {status !== 'NONE' && (
          <>
            {status === 'REPROVED' || status === 'PENDING' ? (
              <PermissionWrapper
                requiredPermissions={permissionsRead}
                requiredResources={permissionsReadResources}
                forbiddenContent={TagStatus}
              >
                <Popover
                  placement="left"
                  content={reprovalReasonsList}
                  title={<span>{title}</span>}
                  overlayClassName="trust-item-reproved-reasons"
                >
                  {TagStatus}
                </Popover>
              </PermissionWrapper>
            ) : (
              TagStatus
            )}
          </>
        )}
      </header>

      {/* {status === 'PENDING' && (
        <>
          <PermissionWrapper
            requiredPermissions={permissionsRead}
            requiredResources={permissionsReadResources}
          >
            {filteredValidations?.length > 0 && (
              <ul className="trust-items-list mrg-top-10">
                {filteredValidations.map((fv) => (
                  <li key={fv?.rule}>{fv?.description || fv?.title || fv?.rule}</li>
                ))}
              </ul>
            )}
          </PermissionWrapper>
          <PermissionWrapper requiredPermissions={permissions}>
            <button
              type="button"
              className="warning mrg-left-15"
              onClick={() => openModal('REPROVED')}
            >
              <i className="caf-ic_close" /> Reprovar
            </button>
            <button
              type="button"
              className="warning"
              onClick={() => openModal('APPROVED')}
            >
              <i className="caf-ic_checkmark" /> Aprovar
            </button>
          </PermissionWrapper>
        </>
      )} */}
    </div>
  );
};

export default TrustItem;
