export function normalizeChartData(data, formatDate) {
  const { costList, secondsSinceEpochIntervals } = data;

  const dataMap = {};
  const productMap = {};

  secondsSinceEpochIntervals.forEach((interval) => {
    dataMap[interval] = {};
  });

  costList.forEach(({ groupInfos, costs }) => {
    productMap[groupInfos.product_item_key] = groupInfos.product_item_name;

    costs.forEach(({ startTimeInSeconds, price, meteredUnits }) => {
      if (price === 0) return;

      dataMap[startTimeInSeconds] = {
        ...dataMap[startTimeInSeconds],
        [groupInfos.product_item_key]: { amount: meteredUnits, price },
      };
    });
  });

  return {
    data: Object.entries(dataMap).map(([time, products]) => ({
      time: formatDate(time),
      ...products,
    })),
    productMap,
    products: Object.keys(productMap),
  };
}
