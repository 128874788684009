import { format as formatDateFns } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import enUS from 'date-fns/locale/en-US';
import esMX from 'date-fns/locale/es';
import { AsYouType } from 'libphonenumber-js';

export const langMap = (language) => {
  switch (language) {
    case 'pt-BR':
      return ptBR;
    case 'en-US':
      return enUS;
    case 'es-MX':
      return esMX;
    default:
      return ptBR;
  }
};

export const toFormatDate = (date, language, translate, _format, _default) => {
  try {
    if (!date || date.includes('0001-01-01')) return _default || '-';

    const defaultDateToFormat = `dd/MM/yyyy '
      ${translate('utils.formatters.toFormatDate.formatDateFns.defaultFormat')}' HH:mm`;

    return formatDateFns(new Date(date), _format || defaultDateToFormat, {
      locale: langMap(language),
    });
  } catch (error) {
    return _default || '-';
  }
};

export const toMaskedCpf = (value) => {
  if (!value) return '';
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
};

export const toMaskedCep = (value) => {
  if (!value) return '';
  return value.replace(/(\d{5})(\d{3})/g, '$1-$2');
};

export const toMaskedCnpj = (value) => {
  if (!value) return '';
  return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
};

export const toFormatPhoneNumber = (phone) => {
  if (!phone) return '';

  const formattedPhoneNumber = new AsYouType().input(`+${phone}`);

  return formattedPhoneNumber;
};

export const toFormatDocument = (doc, _default, notPrefix) => {
  if (!doc) return _default || 'Documento não informado';

  if (doc.length === 11) {
    return notPrefix ? toMaskedCpf(doc) : `CPF: ${toMaskedCpf(doc)}`;
  }
  if (doc.length === 14) {
    return notPrefix ? toMaskedCnpj(doc) : `CNPJ: ${toMaskedCnpj(doc)}`;
  }

  return `DOCUMENTO: ${doc}`;
};

export const toFormatPercentage = (percentage, translate) => {
  if (!percentage)
    return translate('utils.formatters.toFormatPercentage.hasNotPercentage');

  const formattedValue = percentage * 100;

  return `${formattedValue.toFixed(2)}%`;
};

export const secondsToDay = (seconds) => {
  const parsedSeconds = parseInt(seconds, 10);
  const formatted = parsedSeconds / 86400;

  return formatted;
};

export const toFormatDistance = (distanceInKm, translate) => {
  if (distanceInKm > 1) {
    return `${distanceInKm.toFixed(2)} ${translate(
      'utils.formatters.toFormatDistance.kilometers'
    )}`;
  }
  return `${`${distanceInKm * 1000} ${translate(
    'utils.formatters.toFormatDistance.meters'
  )}`}`;
};

export const bytesToMb = (bytes) => {
  return Math.ceil(bytes / 1024 / 1000);
};
