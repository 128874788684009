export default {
  normal: [
    'metrics',
    'people',
    'companies',
    'executions',
    'profile-templates',
    'onboardings',
    'deny-list',
    'blocked-documents',
    'restrictive-list',
    'integration-guide',
    'routines',
    'faceliveness',
    'face-authenticator',
    'onboarding-builder',
  ],
  special: {
    'block-profile-details': {
      effect: 'Deny',
      actions: ['people:read:details', 'companies:read:details'],
      resources: ['*'],
      special: true,
    },
    'block-executions-details': {
      effect: 'Deny',
      actions: ['executions:read:details'],
      resources: ['*'],
      special: true,
    },
    'allow-manual-query': {
      effect: 'Allow',
      actions: ['people:execute', 'companies:execute'],
      resources: ['*'],
      special: true,
    },
    'block-companies-confidential-info': {
      effect: 'Deny',
      actions: ['companies:read'],
      resources: ['justice:*', 'others:kycCompliance:*', 'others:kycComplianceOwners:*'],
      special: true,
    },
    'block-people-confidential-info': {
      effect: 'Deny',
      actions: ['people:read'],
      resources: ['justice:*', 'criminal:*', 'others:kycCompliance:*'],
      special: true,
    },
    'block-profiles-by-tag': {
      effect: 'Deny',
      actions: ['tags:read', 'companies:read', 'people:read'],
      resources: ['tag:6001ef5f3b0e4a2953d05960', 'tag:600654070c39ce1cc4dc2c45'],
      special: true,
    },
  },
};
