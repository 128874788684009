import React, { useCallback } from 'react';
import { Tabs } from 'antd';
import PageTitle from 'components/PageTitle';
import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam } from 'use-query-params';
import { CardWrapper } from 'components/CardWrapper';

import NotificationList from './components/NotificationList';
import NotificationSettings from './components/NotificationSettings';

import Wrapper from '../wrapper';

import './styles.less';

const { TabPane } = Tabs;

const I18N_BASE_PATH = 'pages.private.notificationCenter.page';

const NotificationCenter = () => {
  const { t: translate } = useTranslation();

  const tabHeader = useCallback(
    (title, icon) => (
      <div className="flex align-center text-dark">
        <i className={`${icon} font-size-22 mrg-right-5`} />
        <strong className="text-semibold font-size-16">{title}</strong>
      </div>
    ),
    []
  );

  const [query, setQuery] = useQueryParams({
    tab: StringParam,
  });

  return (
    <Wrapper id="notification-center">
      <CardWrapper hasPadding>
        <PageTitle title={translate(`${I18N_BASE_PATH}.pageTitle.subtitle`)} />
        <Tabs
          defaultActiveKey="active"
          activeKey={query.tab}
          onChange={(tab) => setQuery({ tab })}
          destroyInactiveTabPane
        >
          <TabPane
            tab={tabHeader(
              translate(`${I18N_BASE_PATH}.tabPane.active`),
              'caf-ic_notification'
            )}
            key="active"
          >
            <NotificationList isRead={false} />
          </TabPane>
          <TabPane
            tab={tabHeader(
              translate(`${I18N_BASE_PATH}.tabPane.archived`),
              'caf-ic_archive'
            )}
            key="archived"
          >
            <NotificationList isRead />
          </TabPane>
          <TabPane
            tab={tabHeader(
              translate(`${I18N_BASE_PATH}.tabPane.settings`),
              'caf-ic_settings'
            )}
            key="settings"
          >
            <NotificationSettings />
          </TabPane>
        </Tabs>
      </CardWrapper>
    </Wrapper>
  );
};

export default NotificationCenter;
