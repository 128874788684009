import React from 'react';
import PropTypes from 'prop-types';

import './styles.less';

const ChartTooltip = ({ title, subtitle }) => {
  return (
    <div className="Tooltip">
      <p className="Tooltip__title">{title}</p>
      {subtitle && <p className="Tooltip__subtitle">{subtitle}</p>}
    </div>
  );
};

ChartTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.element,
};

ChartTooltip.defaultProps = {
  subtitle: null,
};

export default ChartTooltip;
