import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import './styles.less';

const FloatingActionButton = ({ placement, children, ...rest }) => {
  return (
    <Button id="floating-action-button-component" className={placement} {...rest}>
      {children}
    </Button>
  );
};

FloatingActionButton.propTypes = {
  placement: PropTypes.oneOf(['right-bottom', 'left-bottom']),
};

FloatingActionButton.defaultProps = {
  placement: 'right-bottom',
};

export default FloatingActionButton;
