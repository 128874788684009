import axios from 'axios';
import Cookies from 'js-cookie';

// Verificação para utilizar cookies locais quando em localhost
const isLocalhost = !!window.location.origin.startsWith('http://localhost');

const ACCESS_TOKEN = isLocalhost ? 'CAF_access_token' : '__Secure-CAF_access_token';
const access_token = Cookies.get(ACCESS_TOKEN);

const api = axios.create({
  headers: {
    Authorization: `Bearer ${access_token}`,
  },
});

export const cleanApi = axios.create();

export default api;
