import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import PersonFinancialInformation from 'pages/private/Profiles/components/DataSourceCards/FinancialInformation/People';
import CompanyFinancialInformation from 'pages/private/Profiles/components/DataSourceCards/FinancialInformation/Companies';

import SPCItems from 'pages/private/Profiles/components/DataSourceCards/FinancialInformation/Shared/SPC/SPCItems';
import ProtestsItems from 'pages/private/Profiles/components/DataSourceCards/FinancialInformation/Shared/Protests/ProtestsItems';
import CadinSPItems from 'pages/private/Profiles/components/DataSourceCards/FinancialInformation/Companies/components/CadinSP/CadinSPItems';
import PersonalLaborAndFinancialInformation from 'pages/private/Profiles/components/DataSourceModals/PersonalLaborAndFinancialInformation/components/Items';

import './styles.less';

const DocumentFinancialInformation = ({ data, showDetails }) => {
  const { t: translate } = useTranslation();

  const { personId, companyId } = useParams();

  const spcData = useMemo(() => {
    const spc = data?.finance?.spc;
    return spc;
  }, [data]);

  const protestsData = useMemo(() => {
    const protests = data?.finance?.protest;
    return protests;
  }, [data]);

  const cadinSPData = useMemo(() => {
    const cadinSP = data?.certificates?.cadinSp;
    return cadinSP;
  }, [data]);

  const socialAssistanceData = useMemo(() => {
    const socialAssistance = data?.finance?.socialAssistance;
    return socialAssistance;
  }, [data]);

  return (
    <div id="to-print-financial-information">
      {personId && (
        <PersonFinancialInformation data={data} toPrint toPrintDetaisl={showDetails} />
      )}
      {companyId && (
        <CompanyFinancialInformation data={data} toPrint toPrintDetails={showDetails} />
      )}

      {showDetails && spcData?.ocurrences?.length > 0 && (
        <SPCItems data={spcData} toPrint />
      )}
      {showDetails && protestsData?.items?.length > 0 && (
        <ProtestsItems data={protestsData} toPrint />
      )}
      {showDetails && cadinSPData?.pendencies?.length > 0 && (
        <CadinSPItems data={cadinSPData} toPrint />
      )}

      {showDetails && socialAssistanceData?.socialAssistances?.length > 0 && (
        <PersonalLaborAndFinancialInformation
          data={socialAssistanceData?.socialAssistances}
          title={translate(
            'pages.private.profiles.components.dossierPrint.components.documentFinancialInformation.index.documentFinancialInformation.socialAssistanceData.title'
          )}
          socialAssistance
        />
      )}
    </div>
  );
};

DocumentFinancialInformation.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  showDetails: PropTypes.bool.isRequired,
};

export default DocumentFinancialInformation;
