import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Form,
  Modal,
  Row,
  Col,
  message,
  Button,
  Input,
  Alert,
  Space,
} from 'antd';
import PageTitle from 'components/PageTitle';

import { permissions } from 'utils/tokensPermissions';

import api from 'services/api';

// import LabelDivider from 'components/Divider/LabelDivider';
import RequestSuccess from 'components/RequestResult/Success';
import HiddenParagraph from 'components/HiddenParagraph';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.accessKeys.tokens.components.generateToken';

const GenerateToken = ({ refreshListRef }) => {
  const [form] = Form.useForm();
  const { t: translate } = useTranslation();
  const [checkedList, setCheckedList] = useState([]);
  const [checkAll, setCheckAll] = useState({});

  const [steps, setSteps] = useState('permissions');

  const [modalVisible, setModalVisible] = useState(false);
  const [finishedData, setFinishedData] = useState({});
  const [loadingGenerateAccessKey, setLoadingGenerateAccessKey] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setTimeout(() => {
      form.resetFields();
      setSteps('permissions');
      setDisabledSubmit(true);
    }, []);
  }, [form]);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const handleFormChange = useCallback((values) => {
    if (!values?.products?.length && !values?.keyName?.length) {
      setDisabledSubmit(true);
    } else {
      setDisabledSubmit(false);
    }
  }, []);
  const onGenerateAccessKey = useCallback(
    async (payload) => {
      try {
        setLoadingGenerateAccessKey(true);

        const { keyName } = payload;
        const objectKeys = Object.keys(checkAll);
        let listPermissions = checkedList;
        objectKeys.forEach((ok) => {
          if (checkAll[ok]) {
            listPermissions = listPermissions?.filter((lp) => !lp.includes(ok));
            listPermissions.push(`${ok}:*`);
          }
        });
        const body = {
          keyName,
          permissions: listPermissions,
        };

        const { data: response } = await api.post(
          `${process.env.REACT_APP_BASE_URL_TRUST_API}/tokens`,
          body
        );
        const { data: responseData } = response || {};
        setFinishedData(responseData);
        setSteps('finished');

        refreshListRef.current();
      } catch (error) {
        message.error(translate(`${I18N_BASE_PATH}.onGenerateToken.messageError`));
      } finally {
        setLoadingGenerateAccessKey(false);
      }
    },
    [checkAll, checkedList, refreshListRef, translate]
  );
  const onChange = useCallback(
    (e, p) => {
      let checkedListTemp = checkedList;
      if (e.target.checked) {
        checkedListTemp = checkedListTemp.concat(p);
      } else {
        checkedListTemp = checkedListTemp.filter((cl) => cl !== p);
      }
      setCheckedList(checkedListTemp);
    },
    [checkedList]
  );
  const onCheckAllChange = useCallback(
    (e, p) => {
      setCheckAll({ ...checkAll, [p]: e.target.checked });
    },
    [checkAll]
  );

  const stepsModal = useMemo(() => {
    if (steps === 'finished') {
      return (
        <RequestSuccess
          title={translate(`${I18N_BASE_PATH}.requestSuccess.title`)}
          className="mrg-vertical-50"
          footer={
            <div>
              <Space style={{ width: '100%' }}>
                <Alert
                  // className="alert-info-form"
                  className="gx-w-100"
                  message={translate(`${I18N_BASE_PATH}.warning`)}
                  type="warning"
                  showIcon
                />
              </Space>

              <Row className="mrg-top-40 mrg-horizon-80">
                <Col span="4">
                  <b className="text-dark">{translate(`${I18N_BASE_PATH}.token`)}</b>
                </Col>
                <Col span={20}>
                  <HiddenParagraph text={finishedData?.token} hidden copyable />
                </Col>
              </Row>
            </div>
          }
        />
      );
    }
    if (steps === 'permissions') {
      return (
        <>
          <PageTitle
            title={translate(`${I18N_BASE_PATH}.pageTitle.title`)}
            subtitle={translate(`${I18N_BASE_PATH}.pageTitle.subtitle`)}
          />
          <div className="div-checkbox">
            {permissions.map((p, index) => (
              <>
                <Row
                  className="no-mrg no-pdd flex start-center"
                  style={{
                    borderTop: index > 0 ? '1px solid #e0e0e0' : 'none',
                    borderBottom: '1px solid #e0e0e0',
                  }}
                >
                  <Checkbox
                    onChange={(e) => onCheckAllChange(e, p?.key)}
                    checked={checkAll[p?.key]}
                    className="checkbox-all"
                  >
                    {translate(p?.title)}
                  </Checkbox>
                  <span className="font-size-12">{translate(p?.description)}</span>
                </Row>
                {p?.allowedPermissions?.map((ap) => (
                  <Row className="no-mrg no-pdd flex start-center">
                    <Checkbox
                      onChange={(e) => onChange(e, `${p?.key}:${ap?.key}`)}
                      disabled={checkAll[p?.key]}
                      checked={
                        checkedList?.find((cl) => cl === `${p?.key}:${ap?.key}`) ||
                        checkAll[p?.key]
                      }
                      className="checkbox-default"
                    >
                      {translate(ap?.title)}
                    </Checkbox>
                    <span className="font-size-12">{translate(ap?.description)}</span>
                  </Row>
                ))}
              </>
            ))}
          </div>
          <div className="flex end-center mrg-top-40">
            <Button
              type="default"
              onClick={closeModal}
              className="btn-cancel"
              disabled={loadingGenerateAccessKey}
            >
              {translate(`${I18N_BASE_PATH}.buttons.cancel`)}
            </Button>
            <Button type="primary" onClick={() => setSteps('name')}>
              {translate(`${I18N_BASE_PATH}.buttons.next`)}
            </Button>
          </div>
        </>
      );
    }
    if (steps === 'name') {
      return (
        <>
          <PageTitle
            title={translate(`${I18N_BASE_PATH}.pageTitle.title`)}
            subtitle={translate(`${I18N_BASE_PATH}.pageTitle.subtitle2`)}
          />

          <Form
            form={form}
            layout="vertical"
            className="mrg-top-40"
            onValuesChange={handleFormChange}
            onFinish={onGenerateAccessKey}
          >
            <Form.Item
              name="keyName"
              className="mrg-top-20"
              label={translate(`${I18N_BASE_PATH}.tokenName`)}
              rules={[
                {
                  required: true,
                  message: translate(`${I18N_BASE_PATH}.error`),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <div className="flex end-center mrg-top-40">
              <Button
                type="default"
                onClick={closeModal}
                className="btn-cancel"
                disabled={loadingGenerateAccessKey}
              >
                {translate(`${I18N_BASE_PATH}.buttons.cancel`)}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={disabledSubmit || loadingGenerateAccessKey}
                loading={loadingGenerateAccessKey}
              >
                {loadingGenerateAccessKey
                  ? translate(`${I18N_BASE_PATH}.buttons.okLoading`)
                  : translate(`${I18N_BASE_PATH}.buttons.okText`)}
              </Button>
            </div>
          </Form>
        </>
      );
    }
  }, [
    checkAll,
    checkedList,
    closeModal,
    disabledSubmit,
    finishedData.token,
    form,
    handleFormChange,
    loadingGenerateAccessKey,
    onChange,
    onCheckAllChange,
    onGenerateAccessKey,
    steps,
    translate,
  ]);

  const GenerateTokenModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        footer={null}
        width={800}
        wrapClassName="caf-modal"
        onCancel={closeModal}
      >
        <div id="generate-token-component" className="no-mrg no-pdd">
          {stepsModal}
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, stepsModal]
  );

  return { openModal, closeModal, GenerateTokenModal };
};

export default GenerateToken;
