export const i18nDateFormats = {
  default: {
    'pt-BR': "dd/MM/yyyy 'às' HH:mm",
    'en-US': "MM/dd/yyyy 'at' HH:mm",
    'es-MX': "dd/MM/yyyy 'a las' HH:mm",
  },
  1: {
    'pt-BR': 'dd/MM/yyyy',
    'en-US': 'MM/dd/yyyy',
    'es-MX': 'dd/MM/yyyy',
  },
  2: {
    'pt-BR': 'dd/MM/yy',
    'en-US': 'MM/dd/yy',
    'es-MX': 'dd/MM/yy',
  },
  3: {
    'pt-BR': "dd/MM/yyyy 'às' HH:mm:ss",
    'en-US': "MM/dd/yyyy 'at' HH:mm:ss",
    'es-MX': "dd/MM/yyyy 'a las' HH:mm:ss",
  },
  4: {
    'pt-BR': "dd/MM/yyyy 'às' HH:mm",
    'en-US': "MM/dd/yyyy 'at' HH:mm",
    'es-MX': "dd/MM/yyyy 'a las' HH:mm",
  },
  5: {
    'pt-BR': "dd 'de' MMMM",
    'en-US': "MMM 'of' ddd",
    'es-MX': "dd 'de' MMMM",
  },
  6: {
    'pt-BR': "dd 'de' MMMM 'de' yyyy",
    'en-US': "MMM 'of' dd 'of' yyyy",
    'es-MX': "dd 'de' MMMM 'de' yyyy",
  },
  7: {
    'pt-BR': "dd/MM/yy 'às' HH:mm:ss",
    'en-US': "MM/dd/yy 'at' HH:mm:ss",
    'es-MX': "dd/MM/yy 'a las' HH:mm:ss",
  },
  8: {
    'pt-BR': 'dd/MM/yy',
    'en-US': 'MM/dd/yy',
    'es-MX': 'dd/MM/yy',
  },
  9: {
    'pt-BR': "cccc, dd/MM/yyyy 'às' HH:mm",
    'en-US': "cccc, MM/dd/yyyy 'at' HH:mm",
    'es-MX': "cccc, dd/MM/yyyy 'a las' HH:mm",
  },
  10: {
    'pt-BR': 'MM/yyyy',
    'en-US': 'MM/yyyy',
    'es-MX': 'MM/yyyy',
  },
  11: {
    'pt-BR': 'dd/MM/yyyy_HH:mm',
    'en-US': 'dd/MM/yyyy_HH:mm',
    'es-MX': 'dd/MM/yyyy_HH:mm',
  },
  12: {
    'pt-BR': 'dd/MM',
    'en-US': 'MM/dd',
    'es-MX': 'dd/MM',
  },
  13: {
    'pt-BR': 'dd/MM/yyyy HH:mm',
    'en-US': 'MM/dd/yyyy HH:mm',
    'es-MX': 'dd/MM/yyyy HH:mm',
  },
};

export const i18nCurrencyFormats = {
  'pt-BR': 'R$',
  'en-US': 'R$',
  'es-MX': 'R$',
};

export const i18nMomentFormats = {
  1: {
    'pt-BR': 'MMMM [de] YYYY',
    'en-US': 'MMMM [of] YYYY',
    'es-MX': 'MMMM [de] YYYY',
  },
  2: {
    'pt-BR': 'DD/MM/YYYY',
    'en-US': 'MM/DD/YYYY',
    'es-MX': 'DD/MM/YYYY',
  },
  3: {
    'pt-BR': 'DD [de] MMMM',
    'en-US': 'MMMM DD',
    'es-MX': 'DD [de] MMMM',
  },
  4: {
    'pt-BR': 'MM/YYYY',
    'en-US': 'MM/YYYY',
    'es-MX': 'MM/YYYY',
  },
  5: {
    'pt-BR': 'DD/MM/YYYY HH:mm',
    'en-US': 'MM/DD/YYYY HH:mm',
    'es-MX': 'DD/MM/YYYY HH:mm',
  },
};
