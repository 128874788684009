import React, { useEffect, useRef, useState } from 'react';
import { Tabs, Card, Alert } from 'antd';
import { useReactToPrint } from 'react-to-print';
import { useAuth } from 'hooks/auth';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';
import { useQueryParam, StringParam, withDefault } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Cookies from 'js-cookie';

import { dateToEpoch } from 'utils/date';
import Loader from 'components/Loader';

import Wrapper from '../wrapper';
import useAmberflo, { cafDetails } from './hooks/useAmberflo';

import BillingHeader from './components/BillingHeader';
import InvoiceDetails from './components/InvoiceDetails';
import InvoiceList from './components/InvoiceList';
import Chart from './components/Chart';
import InvoicePDF from './components/InvoicePDF';

import './styles.less';

const getDefatultDateFilter = () => [moment().startOf('month'), moment()];

const Consumptions = () => {
  const [chartData, setChartData] = useState();
  const [invoiceDetails, setInvoiceDetails] = useState();
  const [chartFilterDate, setChartFilterDate] = useState(getDefatultDateFilter());

  const { user } = useAuth();
  const { t: translate } = useTranslation();
  const contentToPrint = useRef(null);
  const printToPdf = useReactToPrint({
    content: () => contentToPrint.current,
    bodyClass: 'print-body',
    documentTitle: translate('pages.private.consumption.details.pdf.title', {
      invoiceId: invoiceDetails?.id,
    }),
  });

  const {
    fetchConsumptions,
    consumptionError,
    loadingConsumption,
    fetchInvoiceDetails,
    loadingInvoiceDetails,
  } = useAmberflo();

  const [activeTab, setActiveTab] = useQueryParam(
    'tab',
    withDefault(StringParam, consumptionError ? '2' : '1')
  );

  const hidePrice = hasFeatureFlag('costPrices', user);

  useEffect(() => {
    const dateFilter = {
      start: dateToEpoch(moment(chartFilterDate[0]).utc(true).valueOf()),
      end: dateToEpoch(moment(chartFilterDate[1]).utc().valueOf()),
    };

    fetchConsumptions(dateFilter.start, dateFilter.end).then(setChartData);
  }, [chartFilterDate, fetchConsumptions]);

  useEffect(() => {
    fetchInvoiceDetails().then(setInvoiceDetails);
  }, [fetchInvoiceDetails]);

  useEffect(() => {
    if (consumptionError) {
      setActiveTab('2');
    }
  }, [consumptionError]);

  const handleDateFilterChange = (date) => {
    if (!date) {
      return setChartFilterDate(getDefatultDateFilter());
    }

    setChartFilterDate(date);
  };

  const handleCloseWarning = () => {
    Cookies.set('hideConsumptionWarning', 'true');
  };

  return (
    <Wrapper id="consumptions-component">
      <Card className="consumptions-page">
        <BillingHeader
          hidePrice={hidePrice}
          loading={loadingInvoiceDetails}
          companyName={user.accountData.companyName}
          exportOptions={
            activeTab === '1' &&
            !loadingInvoiceDetails && [
              {
                label: translate('components.exportOptions.options.pdf'),
                onClick: printToPdf,
              },
            ]
          }
        />
        {Cookies.get('hideConsumptionWarning') !== 'true' && (
          <Alert
            className="consumptions-page__warning"
            message={translate('pages.private.consumption.index.warning')}
            type="warning"
            closable
            onClose={handleCloseWarning}
          />
        )}

        {loadingConsumption ? (
          <Loader size="50px" />
        ) : (
          <Tabs
            defaultActiveKey={consumptionError ? '2' : '1'}
            activeKey={activeTab}
            onChange={setActiveTab}
          >
            {!consumptionError && (
              <Tabs.TabPane
                tab={translate('pages.private.consumption.index.tabs.actualInvoice')}
                key="1"
              >
                <div className="consumptions-page__main-tab">
                  {/* <Chart
                  loading={loadingConsumption}
                  chartData={chartData}
                  date={chartFilterDate}
                  onDateChange={handleDateFilterChange}
                  hidePrice={hidePrice}
                  currencyCode={invoiceDetails?.currencyCode}
                /> */}
                  {invoiceDetails && (
                    <InvoiceDetails
                      currencyCode={invoiceDetails.currencyCode}
                      data={invoiceDetails.products}
                      total={invoiceDetails.total ?? 0}
                      hidePrice={hidePrice}
                    />
                  )}
                </div>
              </Tabs.TabPane>
            )}
            <Tabs.TabPane
              tab={translate('pages.private.consumption.index.tabs.pastInvoices')}
              key="2"
            >
              <div>
                <InvoiceList hidePrice={hidePrice} />
              </div>
            </Tabs.TabPane>
          </Tabs>
        )}
        {!hidePrice && invoiceDetails && (
          <div
            style={{
              display: 'none',
            }}
            aria-hidden
          >
            <InvoicePDF
              printRef={contentToPrint}
              clientDetails={invoiceDetails.companyInfo}
              companyDetails={cafDetails}
              currencyCode={invoiceDetails.currencyCode}
              invoiceDetails={{
                data: invoiceDetails.products,
                total: invoiceDetails.total,
              }}
              invoiceMetadata={{
                date: invoiceDetails.date,
                period: {
                  start: invoiceDetails.period.start,
                  end: invoiceDetails.period.end,
                },
                pricingPlan: invoiceDetails.planName,
              }}
            />
          </div>
        )}
      </Card>
    </Wrapper>
  );
};

export default Consumptions;
