/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyState from 'components/EmptyState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';

const JointDebts = ({ profile /* urlId, toPrint */ }) => {
  const { t: translate } = useTranslation();

  const joinDebts = useMemo(() => profile?.certificates?.jointDebts, [profile]);

  const handlerInfos = useMemo(() => {
    if (!joinDebts) return { title: null, type: null };

    if (joinDebts?.hasDebts)
      return {
        title: translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.people.components.jointDebts.index.handlerInfos.hasDebts'
        ),
        type: 'danger',
      };

    return {
      title: translate(
        'pages.private.profiles.components.dataSourceCards.financialInformation.people.components.jointDebts.index.handlerInfos.default'
      ),
      type: 'primary',
    };
  }, [joinDebts, translate]);

  const isProcessing = useMemo(() => verifySourcesProcessing(profile, 'jointDebts'), [
    profile,
  ]);

  return (
    <ColumnLabel
      title={translate(
        'pages.private.profiles.components.dataSourceCards.financialInformation.people.components.jointDebts.index.jointDebts.columnLabel.title'
      )}
      description={handlerInfos?.title}
      type={handlerInfos?.type}
    >
      {!joinDebts && isProcessing && <EmptyState type="processing" />}

      {!joinDebts && !isProcessing && <EmptyState type="waiting_consult" />}

      {joinDebts && (!joinDebts?.jointDebts || joinDebts?.jointDebts?.length === 0) && (
        <span>
          {translate(
            'pages.private.profiles.components.dataSourceCards.financialInformation.people.components.jointDebts.index.jointDebts.hasNotJointDebts'
          )}
        </span>
      )}
    </ColumnLabel>
  );
};

export default JointDebts;
