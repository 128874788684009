import { Button, Col, Divider, Row } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DottedLabel from 'components/DottedLabel';
import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';

import useVehicleInfosDetails from './VehicleInfosDetailsModal';
import './styles.less';

const I18N_BASE_PATH = 'components.dataSourceCards.denatranVehicleInfos';
function VehicleInfosCollapsePanel({ vehicle = {}, toPrint = false }) {
  const { t: translate } = useTranslation();
  const { openModal, VehicleInfosDetailsModal } = useVehicleInfosDetails();

  const customHeader = useMemo(() => {
    return (
      <Row justify="space-between" style={{ width: '100%' }}>
        <Col span={18}>
          <i className="caf-ic_driver" />
          <span className="text-dark text-bold pdd-left-10">
            {translate(`${I18N_BASE_PATH}.title`)}
          </span>
        </Col>
      </Row>
    );
  }, [translate]);

  const restrictionsInfo = useMemo(() => {
    let type = 'primary';

    const {
      restriction1Id,
      restriction2Id,
      restriction3Id,
      restriction4Id,
      restrictionIndicatorRenajud,
    } = vehicle;

    const allRestrictions = [
      restriction1Id,
      restriction2Id,
      restriction3Id,
      restriction4Id,
      restrictionIndicatorRenajud,
    ]?.filter((r) => r === true);

    if (allRestrictions?.length) {
      type = 'danger';
    }

    const description =
      allRestrictions.length === 1
        ? translate(`${I18N_BASE_PATH}.infos.restriction`, {
            qtd: allRestrictions?.length,
          })
        : translate(`${I18N_BASE_PATH}.infos.restrictions`, {
            qtd: allRestrictions?.length,
          });

    return { description, type };
  }, [translate, vehicle]);

  const recallInfo = useMemo(() => {
    let type = 'primary';

    const {
      recallIndicator1,
      recallIndicator2,
      recallIndicator3,
      manufacturerRecallIndicator,
    } = vehicle;
    const allRecals = [
      recallIndicator1,
      recallIndicator2,
      recallIndicator3,
      manufacturerRecallIndicator,
    ]?.filter((r) => r === true);

    if (allRecals?.length) {
      type = 'danger';
    }

    const description =
      allRecals.length === 1
        ? translate(`${I18N_BASE_PATH}.infos.recall`, {
            qtd: allRecals?.length,
          })
        : translate(`${I18N_BASE_PATH}.infos.recalls`, {
            qtd: allRecals?.length,
          });

    return { description, type };
  }, [translate, vehicle]);

  return (
    <>
      {VehicleInfosDetailsModal}
      <DataSourceCardCollapsePanel
        className="denatran-driver-infos"
        customHeader={customHeader}
        toPrint={toPrint}
      >
        <Row>
          <ColumnTitleValue
            span={24}
            className="inline-infos"
            title={`${translate(`${I18N_BASE_PATH}.columns.modelBrandDescription`)}:`}
            value={vehicle?.modelBrandDescription || '-'}
          />
        </Row>
        <Row justify="space-between" className="mrg-top-10">
          <ColumnTitleValue
            span={8}
            className="inline-infos"
            title={`${translate(`${I18N_BASE_PATH}.columns.manufactureYear`)}:`}
            value={vehicle?.manufactureYear || '-'}
          />
          <ColumnTitleValue
            span={4}
            className="inline-infos"
            title={`${translate(`${I18N_BASE_PATH}.columns.power`)}:`}
            value={vehicle?.power || '-'}
          />
          <ColumnTitleValue
            span={8}
            className="inline-infos"
            title={`${translate(`${I18N_BASE_PATH}.columns.plate`)}:`}
            value={vehicle?.plate || '-'}
          />
        </Row>
        <Row className="mrg-top-10">
          <ColumnTitleValue
            span={10}
            className="inline-infos"
            title={`${translate(`${I18N_BASE_PATH}.columns.chassiSerial`)}:`}
            value={vehicle?.chassiSerial || '-'}
          />
          <ColumnTitleValue
            span={8}
            className="inline-infos"
            title={`${translate(`${I18N_BASE_PATH}.columns.colorDescription`)}:`}
            value={vehicle?.colorDescription || '-'}
          />
        </Row>
        <Row className="mrg-top-10">
          <Button type="link" onClick={() => openModal(vehicle)}>
            <i className="caf-ic_arrow-right" />
            <span className="mrg-left-10">{`${translate(
              `${I18N_BASE_PATH}.columns.moreDetails`
            )}`}</span>
          </Button>
        </Row>
        <Divider />
        <Row
          className="mrg-top-0 pdd-left-15 pdd-right-15"
          align="middle"
          justify="space-between"
        >
          <Col className="text-dark text-bold">
            <i className="caf-ic_law mrg-left-10 mrg-right-25" />
            <span>{translate(`${I18N_BASE_PATH}.infos.restrictionsLabel`)}</span>
          </Col>
          <Col>
            <DottedLabel
              description={restrictionsInfo?.description}
              type={restrictionsInfo?.type}
            />
          </Col>
        </Row>
        <Divider />
        <Row
          className="mrg-top-0 pdd-left-15 pdd-right-15"
          align="middle"
          justify="space-between"
        >
          <Col className="text-dark text-bold">
            <i className="caf-ic_settings mrg-left-10 mrg-right-25" />
            <span>{translate(`${I18N_BASE_PATH}.infos.recallLabel`)}</span>
          </Col>
          <Col>
            <DottedLabel description={recallInfo?.description} type={recallInfo?.type} />
          </Col>
        </Row>
      </DataSourceCardCollapsePanel>
    </>
  );
}

export default VehicleInfosCollapsePanel;
