import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CardTooltip } from 'components/CardSkeleton';
import { Add, WrongCircle } from '@combateafraude/icons/general';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './styles.less';
import { Popover } from 'antd';

function ActionDetail({ details, handleClose }) {
  const { t: translate } = useTranslation();

  return (
    <div className="action-detail">
      <header>
        {translate('pages.private.onboardingStatistics.table.details.header')}
        <WrongCircle onClick={handleClose} />
      </header>
      <table>
        <thead>
          <tr>
            <th>
              {translate('pages.private.onboardingStatistics.table.details.detail')}
            </th>
            <th style={{ textAlign: 'center' }}>
              {translate('pages.private.onboardingStatistics.table.details.total')}
            </th>
            <th style={{ textAlign: 'center' }}>
              {translate('pages.private.onboardingStatistics.table.details.%')}
            </th>
          </tr>
        </thead>
        <tbody>
          {details.map(({ detail, value, percentage }) => (
            <tr key={`${detail}-${value}-${percentage}`}>
              <td style={{ minWidth: 145 }}>{detail}</td>
              <td style={{ textAlign: 'right' }}>{value}</td>
              <td style={{ textAlign: 'center' }}>{percentage}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const Table = ({ data, handleHover, hoveredIndex }) => {
  const { t: translate } = useTranslation();

  const [visiblePopover, setVisiblePopover] = useState();

  const handlePopoverOpen = (index) => {
    setVisiblePopover(index);
  };

  const handlePopoverClose = () => {
    setVisiblePopover(null);
  };

  return (
    <table className="main-table">
      <thead>
        <tr>
          <th>
            <div className="thead-th">
              {translate('pages.private.onboardingStatistics.table.users')}
            </div>
          </th>
          <th>
            <div className="thead-th">
              {translate('pages.private.onboardingStatistics.table.dropouts')}
            </div>
          </th>
          <th>
            <div className="thead-th">
              {translate('pages.private.onboardingStatistics.table.quitPercentage')}
            </div>
          </th>
          <th>
            <div className="thead-th">
              {translate('pages.private.onboardingStatistics.table.averageTime')}
              <CardTooltip
                message={translate(
                  'pages.private.onboardingStatistics.table.tooltips.averageTime'
                )}
                iconColor="white"
                placement="topRight"
              />
            </div>
          </th>
          <th>
            <div className="thead-th">
              {translate('pages.private.onboardingStatistics.table.actions.title')}
              <CardTooltip
                placement="topRight"
                iconColor="white"
                message={translate(
                  'pages.private.onboardingStatistics.table.tooltips.actions'
                )}
              />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr
            key={item.id}
            onMouseEnter={() => handleHover(index)}
            onMouseLeave={() => handleHover(null)}
            className={classNames({
              hovered: hoveredIndex === index,
            })}
          >
            <td>{item.users}</td>
            <td>{item.dropouts}</td>
            <td>{item.quitPercentage}</td>
            <td>{item.averageTime}</td>
            <td>
              <div className="action">
                {item.actions.value}
                {item.actions.details.length > 0 && (
                  <Popover
                    visible={visiblePopover === index}
                    placement="bottom"
                    onVisibleChange={(visible) => {
                      if (visible) {
                        handlePopoverOpen(index);
                      } else {
                        handlePopoverClose();
                      }
                    }}
                    content={
                      <ActionDetail
                        details={item.actions.details}
                        handleClose={handlePopoverClose}
                      />
                    }
                    trigger="click"
                  >
                    <Add
                      id="click_more_onboarding_stats"
                      onClick={() => handlePopoverOpen(index)}
                    />
                  </Popover>
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  handleHover: PropTypes.func,
  hoveredIndex: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      users: PropTypes.number,
      dropouts: PropTypes.number,
      quitPercentage: PropTypes.string,
      averageTime: PropTypes.string,
      actions: PropTypes.shape({
        value: PropTypes.number,
        details: PropTypes.arrayOf(
          PropTypes.shape({
            detail: PropTypes.string,
            value: PropTypes.number,
            percentage: PropTypes.string,
          })
        ),
      }),
    })
  ).isRequired,
};

ActionDetail.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      detail: PropTypes.string,
      value: PropTypes.number,
      percentage: PropTypes.string,
    })
  ).isRequired,
  handleClose: PropTypes.func.isRequired,
};

Table.defaultProps = {
  handleHover: () => {},
  hoveredIndex: null,
};

export default Table;
