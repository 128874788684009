/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomCollapse from '../CustomCollapse';
import SpecialPolicyRow from '../SpecialPolicyRow';

const SpecialPanel = ({ data, disabled, hideProfileRelatedRows }) => {
  const { t: translate } = useTranslation();
  return (
    <CustomCollapse
      ruleName={data?.name}
      disabled={disabled}
      className="no-border-top"
      title={translate(data?.title)}
      icon={data?.icon}
    >
      <div className="special-rules">
        {data?.policies?.map(
          (policy) =>
            (!policy?.hideProfileRelatedRows || !hideProfileRelatedRows) && (
              <>
                <SpecialPolicyRow
                  disabled={disabled}
                  title={translate(policy?.title)}
                  icon="ic_close_small"
                  ruleName={policy?.name}
                  policyName={data?.name}
                />
              </>
            )
        )}
      </div>
    </CustomCollapse>
  );
};

export default SpecialPanel;
