/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyExecutionState from 'components/EmptyExecutionState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.people.components.personalFinance.index';

const PfIbopeIncome = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const { i18nFormatCurrency } = useI18nFormatters();

  const pfIbopeIncome = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorkedIbopeIncome = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const handlerStatus = useMemo(() => {
    if (!queryWorkedIbopeIncome) return { title: null, type: null };

    if (pfIbopeIncome?.ibopeIncome) {
      return {
        title: translate(`${I18N_BASE_PATH}.handlerStatus.approved`),
        type: 'primary',
      };
    }

    return {
      title: translate(`${I18N_BASE_PATH}.handlerStatus.default`),
      type: 'empty',
    };
  }, [pfIbopeIncome, queryWorkedIbopeIncome, translate]);

  return (
    <>
      <DataSourceCardCollapsePanel
        title={translate(
          `${I18N_BASE_PATH}.personalFinance.columnLabel.ibopeIncome.title`
        )}
        icon="caf-ic_dollar_sign"
        toPrint={toPrint}
        customId="financialInfo"
      >
        {pfIbopeIncome && (
          <ColumnLabel
            span={12}
            title={translate(
              `${I18N_BASE_PATH}.personalFinance.columnLabel.ibopeIncome.title`
            )}
            description={handlerStatus?.title}
            type={handlerStatus?.type}
          >
            {queryWorkedIbopeIncome ? (
              <span>{i18nFormatCurrency(pfIbopeIncome?.ibopeIncome, 'pt-BR')}</span>
            ) : (
              <EmptyExecutionState
                statusCode={statusCode}
                message={source?.message || pfIbopeIncome?.message}
              />
            )}
          </ColumnLabel>
        )}
      </DataSourceCardCollapsePanel>
    </>
  );
};

export default PfIbopeIncome;
