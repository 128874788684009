import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography } from 'antd';
import classNames from 'classnames';
import './styles.less';

const { Title } = Typography;

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companyDetails.subPages.summary.components.modalAddresses';

const CompanyModalAddressesBody = ({ addresses, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const shouldRenderStateColumn = addresses?.some((address) => !!address?.state);
  const shouldRenderCityColumn = addresses?.some((address) => !!address?.city);

  const renderRow = useCallback(
    (address) => (
      <Row className="address-line" key={address?.id}>
        <Col span={!shouldRenderCityColumn && !shouldRenderStateColumn ? 19 : 11}>
          {address?.fullAddress ?? '-'}
        </Col>
        {shouldRenderCityColumn && (
          <Col span={shouldRenderStateColumn ? 6 : 8}>{address?.city}</Col>
        )}
        {shouldRenderStateColumn && <Col span={2}>{address?.state}</Col>}
        <Col span={5}>{address?.postalCode}</Col>
      </Row>
    ),
    [shouldRenderStateColumn, shouldRenderCityColumn]
  );

  return (
    <>
      <Title level={3} className="no-pdd no-mrg font-size-20">
        {translate(`${I18N_BASE_PATH}.title`, 'Other Known Addresses')}
      </Title>
      <div
        className={classNames('modal-addresses-content mrg-top-30', {
          'no-height-limit': toPrint,
        })}
      >
        <Row>
          <Col span={!shouldRenderCityColumn && !shouldRenderStateColumn ? 19 : 10}>
            <Title level={5}>
              {translate(`${I18N_BASE_PATH}.columns.street`, 'Street')}
            </Title>
          </Col>
          {shouldRenderCityColumn && (
            <Col span={shouldRenderStateColumn ? 5 : 9}>
              <Title level={5}>
                {translate(`${I18N_BASE_PATH}.columns.town`, 'Town')}
              </Title>
            </Col>
          )}
          {shouldRenderStateColumn && (
            <Col span={4}>
              <Title level={5}>
                {translate(`${I18N_BASE_PATH}.columns.state`, 'State')}
              </Title>
            </Col>
          )}
          <Col span={5}>
            <Title level={5}>
              {translate(`${I18N_BASE_PATH}.columns.zipCode`, ' Zip Code')}
            </Title>
          </Col>
        </Row>

        {addresses?.map((address) => renderRow(address))}
      </div>
    </>
  );
};

export default CompanyModalAddressesBody;
