import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import EmptyExecutionState from 'components/EmptyExecutionState';

import DataSourceCardCollapsePanel from '../../../../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.shared.spcRestrictionsScore.contactInfos';

const ContactContent = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () => statusCode === '01' || source?.available || source.lastConsultation,
    [statusCode, source]
  );

  const phone = useMemo(() => {
    if (!parsedData) return undefined;

    const phonesData = parsedData?.phone;

    if (!phonesData) return 'no-data';

    return phonesData || [];
  }, [parsedData]);

  const parsedTelephone = useMemo(() => {
    if (phone) {
      const { ddd, number } = phone;

      const formattedNumber = `(${ddd}) ${number}`;

      return (
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col>
              <i className="caf-ic_smartphone mrg-right-5" />
              <span>{formattedNumber || 'Não foram encontrados telefones'}</span>
            </Col>
          </Row>
        </Col>
      );
    }

    return <></>;
  }, [phone]);

  return queryWorked ? (
    <DataSourceCardCollapsePanel
      customId="report-spc-last-phone-card"
      icon="caf-ic_contact_"
      title={translate(`${I18N_BASE_PATH}.index.title`)}
      toPrint={toPrint}
    >
      <Row>
        <Col span={24}>
          {phone === 'no-data' ? (
            <EmptyExecutionState
              statusCode={statusCode}
              message={translate(
                `${I18N_BASE_PATH}.index.columns.emptyExecutionStateMessage`
              )}
            />
          ) : (
            <>{parsedTelephone}</>
          )}
        </Col>
      </Row>
    </DataSourceCardCollapsePanel>
  ) : (
    <DataSourceCardCollapsePanel
      customId="report-spc-last-phone-card"
      icon="caf-ic_contact_"
      title={translate(`${I18N_BASE_PATH}.index.title`)}
      toPrint={toPrint}
    >
      <Row gutter={24}>
        <Col span={24}>
          <EmptyExecutionState
            statusCode={statusCode}
            message={source?.message || parsedData?.message}
          />
        </Col>
      </Row>
    </DataSourceCardCollapsePanel>
  );
};

export default ContactContent;
