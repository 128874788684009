import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { Row, Typography } from 'antd';
import Tag from 'components/Tag';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import NotAvailable from 'components/NotAvailable';

const { Title, Text } = Typography;

export const CompanyRow = ({ children, className, ...rest }) => {
  return (
    <Row
      gutter={16}
      className={classnames(className, 'kyb-company-summary-row')}
      {...rest}
    >
      {children}
    </Row>
  );
};

export const CompanyCard = ({ children, className, ...rest }) => (
  <div className={classnames(className, 'kyb-company-summary-card')} {...rest}>
    {children}
  </div>
);

export const CompanyH5 = ({ children, className, level, render = true }) => {
  if (!render) {
    return null;
  }
  return (
    <Title level={level} className={classnames(className, 'typescale-subtitle-1')}>
      {children}
    </Title>
  );
};

export const CompanyHeadlineSmall = ({
  children,
  className,
  level = 4,
  render = true,
}) => {
  if (!render) {
    return null;
  }
  return (
    <Title level={level} className={classnames(className, 'typescale-headline-small')}>
      {children}
    </Title>
  );
};

export const CompanyCaption = ({ children, render = true, ...rest }) => {
  if (!render) {
    return null;
  }
  return (
    <Text className={classnames(rest.className, 'typescale-caption')}>{children}</Text>
  );
};

export const CompanySubtitle1 = ({ children, className, level, render = true }) => {
  if (!render) {
    return null;
  }
  return (
    <Title level={level} className={classnames(className, 'typescale-subtitle-1')}>
      {children && children !== '-' ? children : <NotAvailable />}
    </Title>
  );
};

export const CompanySubtitle2 = ({ children, className, level = 5, render = true }) => {
  if (!render) {
    return null;
  }
  return (
    <Title level={level} className={classnames(className, 'typescale-subtitle-2')}>
      {children && children !== '-' ? children : <NotAvailable />}
    </Title>
  );
};

export const CompanyBody1 = ({ children, render = true, ...rest }) => {
  if (!render) {
    return null;
  }
  return (
    <Text className={classnames(rest.className, 'typescale-body-1')}>
      {children && children !== '-' ? children : <NotAvailable />}
    </Text>
  );
};

export const CompanyLink = ({ children, to }) => (
  <Link to={to} className="kyb-company-summary-link">
    {children}
  </Link>
);

export const CompanyTag = ({ children, className, ...rest }) => {
  if (rest.empty) {
    return <div className="kyb-company-summary-tag kyb-company-summary-tag-empty" />;
  }
  return (
    <Tag className={classnames(className, 'kyb-company-summary-tag')} {...rest}>
      {children}
    </Tag>
  );
};

export const CompanyName = ({ i18n, name }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line react/no-danger
  return <span dangerouslySetInnerHTML={{ __html: t(i18n, { companyName: name }) }} />;
};

export const CompanyShareCapital = ({ payload }) => {
  const { i18nFormatCurrency, i18nFormatNumber } = useI18nFormatters();
  let value = null;

  if (!payload?.creditReport?.shareCapitalValue) return <NotAvailable />;

  if (payload?.creditReport?.shareCapitalCurrency) {
    value = i18nFormatCurrency(
      payload?.creditReport?.shareCapitalValue,
      null,
      payload?.creditReport?.shareCapitalCurrency
    );
  }

  value = i18nFormatNumber(payload?.creditReport?.shareCapitalValue);

  return <CompanyBody1>{value}</CompanyBody1>;
};

const propTypesSubtitle = {
  className: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  render: PropTypes.bool.isRequired,
};
const propTypesLink = {
  to: PropTypes.string.isRequired,
};
const basicPropTypesTag = {
  className: PropTypes.string.isRequired,
};
const propTypesTag = basicPropTypesTag;
const propTypesRow = basicPropTypesTag;
const propTypesCard = basicPropTypesTag;

CompanyShareCapital.propTypes = {
  payload: PropTypes.object.isRequired,
};
CompanyRow.propTypes = propTypesRow;
CompanyTag.propTypes = propTypesTag;
CompanyCard.propTypes = propTypesCard;
CompanyLink.propTypes = propTypesLink;
CompanyH5.propTypes = propTypesSubtitle;
CompanyHeadlineSmall.propTypes = propTypesSubtitle;
CompanySubtitle1.propTypes = propTypesSubtitle;
CompanySubtitle2.propTypes = propTypesSubtitle;
CompanyBody1.propTypes = {
  render: PropTypes.bool.isRequired,
};
CompanyCaption.propTypes = propTypesSubtitle;
CompanyName.propTypes = {
  i18n: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
