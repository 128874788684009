import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

import phoneId from 'assets/images/phone_id.svg';

import './styles.less';

const { Title } = Typography;

const EmptyPage = () => {
  const { t: translate } = useTranslation();

  return (
    <div
      className="empty-page"
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: 72,
      }}
    >
      <img
        src={phoneId}
        className="grayscale-95"
        alt={translate(
          'pages.private.profiles.components.list.components.emptyPage.index.emptyPage.image'
        )}
        style={{ width: 327, height: 291 }}
      />

      <Title className="title" align="center">
        {translate(
          'pages.private.profiles.components.list.components.emptyPage.index.emptyPage.title'
        )}
      </Title>

      <p className="text">
        {translate(
          'pages.private.profiles.components.list.components.emptyPage.index.emptyPage.paragraph'
        )}
      </p>
    </div>
  );
};

export default EmptyPage;
