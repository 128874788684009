import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import './styles.less';

const KeyInformation = ({ data }) => {
  const { t: translate } = useTranslation();
  return (
    <div id="key-information">
      <Row>
        <Col span={12}>
          <span>
            {translate(
              'pages.private.globalProfiles.components.tabs.components.summary.components.keyInformation.title',
              'PERSON INFORMATION'
            )}
          </span>
          <Row className="row-info">
            <Col span={12}>
              <b>
                {translate(
                  'pages.private.globalProfiles.components.tabs.components.summary.components.keyInformation.name',
                  'Name'
                )}
              </b>
            </Col>
            <Col span={12}>{data?.name || '-'}</Col>
          </Row>
          <Row className="row-info">
            <Col span={12}>
              <b>
                {translate(
                  'pages.private.globalProfiles.components.tabs.components.summary.components.keyInformation.docId',
                  'Doc ID'
                )}
              </b>
            </Col>
            <Col span={12}>{data?.identifier || '-'}</Col>
          </Row>
          <Row className="row-info">
            <Col span={12}>
              <b>
                {translate(
                  'pages.private.globalProfiles.components.tabs.components.summary.components.keyInformation.dateOfBirth',
                  'Date of birth'
                )}
              </b>
            </Col>
            <Col span={12}>{data?.birthDate || '-'}</Col>
          </Row>
        </Col>
        <Col span={12}>
          <span>
            {translate(
              'pages.private.globalProfiles.components.tabs.components.summary.components.keyInformation.contact',
              'CONTACT DETAILS'
            )}
          </span>
          <Row className="row-info">
            <Col span={12}>
              <b>
                {translate(
                  'pages.private.globalProfiles.components.tabs.components.summary.components.keyInformation.email',
                  'E-mail'
                )}
              </b>
            </Col>
            <Col span={12}>{data?.email || '-'}</Col>
          </Row>
          <Row className="row-info">
            <Col span={12}>
              <b>
                {translate(
                  'pages.private.globalProfiles.components.tabs.components.summary.components.keyInformation.phone',
                  'Phone'
                )}
              </b>
            </Col>
            <Col span={12}>{data?.phoneNumber || '-'}</Col>
          </Row>
          <Row className="row-info">
            <Col span={12}>
              <b>
                {translate(
                  'pages.private.globalProfiles.components.tabs.components.summary.components.keyInformation.address',
                  'Address'
                )}
              </b>
            </Col>
            <Col span={12}>{data?.address?.full || '-'}</Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default KeyInformation;
