import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';

import RectangleButton from 'components/Button/RectangleButton';

import { onboardingServices } from '../../utils';
import { usePopoverRemoveValidations } from '../PopoverRemoveValidations';

const ServiceButton = ({
  service,
  disabled,
  formRef,
  onChange,
  allServices,
  profileTemplate,
  isNew,
}) => {
  const [selected, setSelected] = useState(false);
  const { t: translate } = useTranslation();

  const extraDisabled = useMemo(
    () =>
      profileTemplate?.onboardingServices?.authentic_document &&
      ['official_data', 'documentscopy', 'ocr'].includes(service.source),
    [profileTemplate, service.source]
  );

  const {
    open: openRemoveValidationPopover,
    content: removeValidationPopoverContent,
    isVisible,
  } = usePopoverRemoveValidations();

  const onValuesChange = useCallback(
    (checked) => {
      setSelected(checked);

      const data = checked
        ? {
            [service.source]: {
              ...service,
            },
            ...(service.source === 'authentic_document'
              ? { official_data: undefined, documentscopy: undefined, ocr: undefined }
              : {}),
          }
        : {
            [service.source]: undefined,
          };

      // if (onValuesChangeHandler)
      //   onValuesChangeHandler({ onboarding: { services: true } });
      if (onChange) onChange(data);
    },
    [onChange, service]
  );

  const onClick = useCallback(
    (e) => {
      const checked = !selected;

      if (!checked) {
        openRemoveValidationPopover(e, { allServices, service, formRef }, () => {
          onValuesChange(checked);
        });
      } else {
        onValuesChange(checked);
      }
    },
    [allServices, formRef, onValuesChange, openRemoveValidationPopover, selected, service]
  );

  useEffect(() => {
    // const currentService = formRef.getFieldValue([
    //   'onboardingServices',
    //   service.source,
    // ]);
    setSelected(!!profileTemplate?.onboardingServices?.[service.source]);
  }, [formRef, service, profileTemplate]);

  return (
    <Popover
      content={removeValidationPopoverContent}
      visible={isVisible}
      placement="topLeft"
      overlayClassName="custom-card-popover remove-template-validations"
    >
      <div className="height-100">
        <RectangleButton
          title={translate(`services.${service.source}.title`, service.title)}
          description={translate(
            `services.${service.source}.description`,
            service.description
          )}
          icon={onboardingServices[service.source]?.icon || 'caf-ic_hash'}
          selected={selected}
          onClick={onClick}
          disabled={disabled || extraDisabled}
          price={service.price}
          priceExtra={service.priceExtra}
          orientation="horizontal"
          isNew={isNew}
        />
      </div>
    </Popover>
  );
};

ServiceButton.propTypes = {
  service: PropTypes.shape({
    source: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    priceExtra: PropTypes.number,
    category: PropTypes.string.isRequired,
    relatedRules: PropTypes.arrayOf(PropTypes.shape({ rule: PropTypes.string })),
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  allServices: PropTypes.arrayOf(PropTypes.object),
  profileTemplate: PropTypes.shape(),
  isNew: PropTypes.bool,
};

ServiceButton.defaultProps = {
  allServices: [],
  profileTemplate: {},
  onChange: null,
  isNew: false,
};

export default ServiceButton;
