import { Card, Image, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { toMaskedCpf } from 'utils/formatters';
import copyTextToClipboard from 'utils/copyTextToClipboard';

import './styles.less';
import Button from 'components/Button';
import useAddWhiteListModal from './components/AddWhiteListModal';

const I18N_BASE_PATH = 'pages.private.liveness.attemptsTab.components.cardAttempts.index';

const AttemptsCard = ({ data }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const { openModal: openAddWhiteListModal, AddWhiteListModal } = useAddWhiteListModal({
    data,
  });

  const imageComponent = () => {
    return (
      <Image
        className="card-image"
        width="113px"
        height="113px"
        src={data?.imageUrlTemp}
        alt={data?._id}
      />
    );
  };

  return (
    <>
      {AddWhiteListModal}
      <Card className="attempts-card" type="inner">
        <div className="card-info-content">
          {imageComponent() && imageComponent()}
          <div className="card-info">
            <div className="card-info-data">
              <div className="card-info-simple font-size-22 text-bold">
                <span className="">{translate(`${I18N_BASE_PATH}.ip`)}:</span>
                <span className="pdd-left-5 ">{data?.sourceIp}</span>
              </div>

              <div className="card-info-simple">
                <span>{translate(`${I18N_BASE_PATH}.cpf`)}:</span>
                <span
                  className={`pdd-left-5 ${data?.person?.personId ? 'text-bold' : ''}`}
                >
                  {toMaskedCpf(data?.person?.personId) ||
                    translate(`${I18N_BASE_PATH}.noCpf`)}
                </span>
              </div>

              {data?.trackingId && (
                <div className="card-info-simple">
                  <span>{translate(`${I18N_BASE_PATH}.trackingID`)}:</span>
                  <Tooltip title={data?.trackingId}>
                    <span className="pdd-left-5 text-bold tracking-id">
                      {data?.trackingId}
                    </span>
                  </Tooltip>

                  <Tooltip title={translate(`${I18N_BASE_PATH}.tooltip.copy`)}>
                    <Button
                      type="link"
                      style={{ paddingLeft: 0 }}
                      onClick={() =>
                        copyTextToClipboard(
                          data?.trackingId,
                          translate(`${I18N_BASE_PATH}.copyButton.trackingCopySuccess`),
                          translate(`${I18N_BASE_PATH}.copyButton.trackingCopyFail`)
                        )
                      }
                    >
                      <i className="caf-ic_duplicate" />
                    </Button>
                  </Tooltip>
                </div>
              )}
            </div>
            <div className="card-info-status">
              <div className="card-info-status-content">
                <span className="font-size-12">{`${translate(
                  `${I18N_BASE_PATH}.blockedAt`
                )} ${i18nFormatDate(data?.createdAt, 4)}`}</span>
              </div>
              <Button
                className="btn-attempts"
                type="text"
                onClick={openAddWhiteListModal}
              >
                {translate(`${I18N_BASE_PATH}.button.addWhiteList`)}
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

AttemptsCard.propTypes = {
  data: PropTypes.node.isRequired,
};

export default AttemptsCard;
