import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { Info } from '@combateafraude/icons/general';
import { useTranslation } from 'react-i18next';

import { convertGlobalDocVerificationResultToIcon } from '../../../../../../utils/convertGlobalDocVerificationResultToIcon';

const I18N_BASE_PATH =
  'pages.private.executions.components.tabs.components.complianceRules.index.complianceRules.ruleDetails.globalDocVerification';

const TextFieldsCheckPopover = ({ ruleName, textFieldsCheck }) => {
  const { t: translate } = useTranslation();

  return (
    <Popover
      overlayClassName="compliance-rule-details-popover"
      trigger="hover"
      content={
        <>
          <div className="flex start-center popover-title">
            <h3>{ruleName}</h3>
            {convertGlobalDocVerificationResultToIcon({
              checkResult: textFieldsCheck?.status,
              className: 'font-size-12 mrg-left-5',
            })}
          </div>
          <div className="check-list">
            <div className="flex center space-between check-item">
              {translate(`${I18N_BASE_PATH}.textFieldsCheck.documentExpiry`)}
              {convertGlobalDocVerificationResultToIcon({
                checkResult: textFieldsCheck?.documentExpiry?.result,
                className: 'font-size-12',
              })}
            </div>
            <div className="flex center space-between check-item">
              {translate(`${I18N_BASE_PATH}.textFieldsCheck.textFields`)}
              {convertGlobalDocVerificationResultToIcon({
                checkResult: textFieldsCheck?.status,
                className: 'font-size-12',
              })}
            </div>
            <div className="flex center space-between check-item">
              {'MRZ'}
              {convertGlobalDocVerificationResultToIcon({
                checkResult: textFieldsCheck?.mrz,
                className: 'font-size-12',
              })}
            </div>
          </div>
        </>
      }
    >
      <Info className="font-size-13 mrg-left-5 gx-text-info" />
    </Popover>
  );
};

TextFieldsCheckPopover.propTypes = {
  ruleName: PropTypes.string.isRequired,
  textFieldsCheck: PropTypes.shape({
    status: PropTypes.oneOf([0, 1, 2]),
    documentExpiry: PropTypes.shape({
      result: PropTypes.oneOf([0, 1, 2]),
    }),
    mrz: PropTypes.oneOf([0, 1, 2]),
  }).isRequired,
};

export default TextFieldsCheckPopover;
