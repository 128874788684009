import React, { useMemo } from 'react';
// import { Card } from 'antd';

import { usePerson } from 'hooks/person';

import * as pfDataSources from 'utils/pfDataSource';

import FinancialInformation from 'pages/private/Profiles/components/DataSourceCards/FinancialInformation/People';
import CriminalInformation from 'pages/private/Profiles/components/DataSourceCards/CriminalInformation';
import DriverInformation from 'pages/private/Profiles/components/DataSourceCards/DriverInformation';
import PersonalLaborInformation from 'pages/private/Profiles/components/DataSourceCards/LaborInformation/People';
import Relationships from 'pages/private/Profiles/components/DataSourceCards/Relationships';
import MediaExposure from 'pages/private/Profiles/components/DataSourceCards/MediaExposure';
import LegalInformation from 'pages/private/Profiles/components/DataSourceCards/LegalInformation';
import RestrictiveLists from 'pages/private/Profiles/components/DataSourceCards/RestrictiveLists';
import IndependentConsultants from 'pages/private/Profiles/components/DataSourceCards/IndependentConsultants';

// import EmptyRecurrence from 'pages/private/Profiles/components/EmptyRecurrence';

import PermissionWrapper from 'routes/PermissionWrapper';

const Dossier = () => {
  const { person } = usePerson();

  const dataSources = useMemo(() => {
    return (
      person?.profileTemplate?.backgroundChecking?.services?.map(
        ({ source }) => source
      ) || []
    );
  }, [person]);

  return (
    <>
      {/* {!person?.recurrenceModel && (
        <Card>
          <EmptyRecurrence />
        </Card>
      )} */}

      {person?.independentConsultants && <IndependentConsultants data={person} />}

      {pfDataSources.hasCriminalInformation(person, dataSources) && (
        <PermissionWrapper
          requiredPermissions={['people:read']}
          requiredResources={['criminal:*']}
        >
          <CriminalInformation data={person} />
        </PermissionWrapper>
      )}

      {pfDataSources.hasFinancialInformation(person, dataSources) && (
        <FinancialInformation data={person} />
      )}

      {pfDataSources.hasLaborInformation(person, dataSources) && (
        <PersonalLaborInformation data={person} />
      )}

      {pfDataSources.hasDriverInformation(person, dataSources) && (
        <DriverInformation data={person} />
      )}

      {pfDataSources.hasLegalInformation(person, dataSources) && (
        <PermissionWrapper
          requiredPermissions={['people:read']}
          requiredResources={['justice:*']}
        >
          <LegalInformation data={person} />
        </PermissionWrapper>
      )}

      {pfDataSources.hasRestrictiveListsInformation(person, dataSources) && (
        <PermissionWrapper
          requiredPermissions={['people:read']}
          requiredResources={['others:kycCompliance:*']}
        >
          <RestrictiveLists data={person} />
        </PermissionWrapper>
      )}

      {pfDataSources.hasRelationshipInformation(person, dataSources) && (
        <Relationships data={person} />
      )}

      {pfDataSources.hasMediaExposureInformation(person, dataSources) && (
        <MediaExposure data={person} />
      )}
    </>
  );
};

export default Dossier;
