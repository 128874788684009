/* eslint-disable react/prop-types */
import React, { useState, useCallback, useMemo } from 'react';
import { Col, Divider, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  isMapped,
  FIRST_SECTION_FIELDS,
  SECOND_SECTION_FIELDS,
  THIRD_SECTION_FIELDS,
} from './mapping';

const VehicleInfosDetails = () => {
  const { t: translate } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [violation, setViolation] = useState({});

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback((data) => {
    setViolation(data);
    setModalVisible(true);
  }, []);

  const generateSectionFields = useCallback(
    (fieldsArray) => {
      return fieldsArray.map((key) => {
        if (isMapped(key)) {
          return (
            <Row className="mrg-btm-10">
              <Col span={24}>
                <span className="text-bold text-dark">{translate(isMapped(key))}:</span>{' '}
                <span>{violation[key] || '-'}</span>
              </Col>
            </Row>
          );
        }

        return <></>;
      });
    },
    [translate, violation]
  );

  const content = useMemo(() => {
    if (!violation) return <></>;

    const sections = [];

    const firstSection = generateSectionFields(FIRST_SECTION_FIELDS) || [];
    const secondSection = generateSectionFields(SECOND_SECTION_FIELDS) || [];
    const thirdSection = generateSectionFields(THIRD_SECTION_FIELDS) || [];

    sections.push(...firstSection, <Divider />);
    sections.push(...secondSection, <Divider />);
    sections.push(...thirdSection);

    return <>{sections}</>;
  }, [violation, generateSectionFields]);

  const VehicleInfosDetailsModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        onCancel={closeModal}
        footer={null}
        width={700}
        title={violation?.violationDescription}
        destroyOnClose
      >
        {content}
      </Modal>
    ),
    [modalVisible, closeModal, violation, content]
  );

  return {
    openModal,
    closeModal,
    VehicleInfosDetailsModal,
  };
};

export default VehicleInfosDetails;
