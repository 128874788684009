export const redirectTo = (name, id) => {
  if (!name) return { id: '', tab: 'infos' };

  switch (name) {
    case 'pages.private.profiles.components.trustValidations.utils.redirectTo.redirectTo.name.registrationData':
      return {
        id: 'basic-infos-card',
        tab: 'infos',
      };
    case 'pages.private.profiles.components.trustValidations.utils.redirectTo.redirectTo.name.basicInformation':
      return {
        id: 'basic-infos-card',
        tab: 'infos',
      };
    case 'pages.private.profiles.components.trustValidations.utils.redirectTo.redirectTo.name.formsOfContact':
      return {
        id: 'basic-infos-contacts',
        tab: 'infos',
      };
    case 'pages.private.profiles.components.trustValidations.utils.redirectTo.redirectTo.name.financialInformation':
      return {
        id: 'financial-infos-component',
        tab: 'dossier',
      };
    case 'pages.private.profiles.components.trustValidations.utils.redirectTo.redirectTo.name.driverInformationDriver':
      return {
        id: 'driver-infos-component',
        tab: 'dossier',
      };
    case 'pages.private.profiles.components.trustValidations.utils.redirectTo.redirectTo.name.criminalAncestor':
      return {
        id: 'criminal-background-component',
        tab: 'dossier',
      };
    case 'pages.private.profiles.components.trustValidations.utils.redirectTo.redirectTo.name.laborInformation':
      return {
        id: 'labor-infos-component',
        tab: 'dossier',
      };
    case 'pages.private.profiles.components.trustValidations.utils.redirectTo.redirectTo.name.judicialInformation':
      return {
        id: 'processess-infos-component',
        tab: 'dossier',
      };
    case 'pages.private.profiles.components.trustValidations.utils.redirectTo.redirectTo.name.other':
      return {
        id: 'basic-infos-card',
        tab: 'infos',
      };
    case 'pages.private.profiles.components.trustValidations.utils.redirectTo.redirectTo.name.mediaPresence':
      return {
        id: 'media-exposure-component',
        tab: 'dossier',
      };
    default:
      return {
        id,
        tab: 'activities',
      };
  }
};
