/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DossierCard from 'pages/private/Profiles/components/DossierCard';

import PhoneRelatedPeople from './components/PhoneRelatedPeople';
import PersonalAndEconomicRelationships from './components/PersonalAndEconomicRelationships';
import PeopleInternalRestrictiveList from './components/PeopleInternalRestrictiveList';

import './styles.less';

const Relationships = ({ data, toPrint }) => {
  const { t: translate } = useTranslation();

  const urlId = useMemo(() => data?.recurrenceModel?._id, [data]);

  const dataSources = useMemo(
    () =>
      data?.profileTemplate?.backgroundChecking?.services?.map(({ source }) => source) ||
      [],
    [data]
  );

  const showPhoneRelatedPeople = useMemo(
    () =>
      dataSources.includes('pf_related_people_phones') ||
      data?.relationships?.peoplePhones,
    [data, dataSources]
  );
  const showPersonalAndEconomicRelationships = useMemo(
    () =>
      dataSources?.includes('pf_business_relationships') ||
      dataSources?.includes('pf_personal_relationships') ||
      data?.relationships?.business ||
      data?.relationships?.personal,
    [data, dataSources]
  );
  const showPeopleInternalRestrictiveList = useMemo(
    () =>
      dataSources.includes('pf_personal_relationships_trust_rl') ||
      data?.relationships?.peopleOccurrencesIRL,
    [data, dataSources]
  );

  // const customInternalRestrictiveListExibition = useMemo(() => {
  //   const customRelatedPhones =
  //     showPeopleInternalRestrictiveList && showPhoneRelatedPeople;

  //   const customPersonalAndBusiness =
  //     showPeopleInternalRestrictiveList && showPersonalAndEconomicRelationships;

  //   return customRelatedPhones || customPersonalAndBusiness;
  // }, [
  //   showPeopleInternalRestrictiveList,
  //   showPhoneRelatedPeople,
  //   showPersonalAndEconomicRelationships,
  // ]);

  return (
    <DossierCard
      customId="relationships-component"
      title={translate(
        'pages.private.profiles.components.dataSourceCards.relationships.index.relationships.dossierCard.title'
      )}
      subtitle={translate(
        'pages.private.profiles.components.dataSourceCards.relationships.index.relationships.dossierCard.subtitle'
      )}
      icon="caf-ic_users"
    >
      <div className="gx-w-100 no-mrg no-pdd">
        {showPhoneRelatedPeople && (
          <PhoneRelatedPeople profile={data} urlId={urlId} toPrint={toPrint} />
        )}

        {/* {showPhoneRelatedPeople && showPersonalAndEconomicRelationships && (
          <Divider className="custom-divider reset-divider" />
        )} */}

        {showPersonalAndEconomicRelationships && (
          <PersonalAndEconomicRelationships
            profile={data}
            // customTop={showPhoneRelatedPeople && showPersonalAndEconomicRelationships}
            urlId={urlId}
            toPrint={toPrint}
          />
        )}

        {/* {customInternalRestrictiveListExibition && (
          <Divider className="custom-divider reset-divider" />
        )} */}

        {showPeopleInternalRestrictiveList && (
          <PeopleInternalRestrictiveList
            profile={data}
            // customTop={customInternalRestrictiveListExibition}
            urlId={urlId}
            toPrint={toPrint}
          />
        )}
      </div>
    </DossierCard>
  );
};

export default Relationships;
