import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Timeline } from 'antd';
import { useQueryParams, StringParam } from 'use-query-params';

import { scrollToElement } from 'utils/scroll';

// import servicesCategories from 'utils/data/templateServicesCategoriesPj.json';
// import TrustItem from '../TrustItem';
import CheckpointStatus from 'pages/private/Profiles/components/CheckpointStatus';
import WarningComplianceRule from 'assets/images/warning_compliance_rule.svg';
import ReprovedComplianceRule from 'assets/images/reproved_compliance_rule_icon.svg';

import './styles.less';

const ValidationsTimeline = ({ validations, status = null }) => {
  const { t: translate } = useTranslation();

  const [, setQuery] = useQueryParams({
    tab: StringParam,
  });

  const complianceRules = useMemo(() => {
    if (!validations) return undefined;

    const _validations = validations;

    if (!_validations?.length) return undefined;

    const complianceRulesData = _validations.filter(
      (v) =>
        v.rule === 'valid_company_register' ||
        v.rule === 'valid_background_checking' ||
        v.rule === 'valid_company_qsa_onboarding' ||
        v.rule === 'valid_migration_reports' ||
        v.rule === 'valid_compliance_rules' ||
        v.rule === 'valid_document' ||
        v.rule === 'valid_identity'
    );

    return complianceRulesData;
  }, [validations]);

  const scrollToComponent = useCallback(
    (rule) => {
      setTimeout(() => {
        let switchTab = {};

        switch (rule?.rule) {
          case 'valid_document':
            switchTab = { tab: 'documents', element: 'authentic-document-card' };
            break;
          case 'valid_identity':
            switchTab = { tab: 'identity', element: 'verified-document-card' };
            break;
          case 'valid_compliance_rules':
            switchTab = { tab: 'compliance', element: 'compliance-rules-card' };
            break;

          default:
            break;
        }

        setQuery({
          tab: switchTab.tab,
        });

        scrollToElement(switchTab.tab);
      }, 100);
    },
    [setQuery]
  );

  const renderValidationRules = (rules) => {
    const rulesFilteredByStatus = rules?.filter((rule) =>
      ['PENDING', 'REPROVED'].includes(rule?.status)
    );

    return rulesFilteredByStatus?.map((rule) => (
      <span key={rule.ruleId}>
        {rule.status === 'PENDING' && (
          <img src={WarningComplianceRule} alt="warning icon" />
        )}
        {rule.status === 'REPROVED' && (
          <img src={ReprovedComplianceRule} alt="reproved icon" />
        )}
        {translate(
          `validations.${rule.ruleId || rule.code}.title`,
          rule.ruleName || rule.title || rule.description
        )}
      </span>
    ));
  };
  return (
    <Timeline className="checkpoint-status-container mrg-top-5">
      {complianceRules?.map((complianceRule) => (
        <CheckpointStatus
          key={complianceRule?.rule}
          status={
            complianceRule?.rule === 'valid_compliance_rules' && status
              ? status
              : complianceRule?.status
          }
          title={translate(complianceRule?.title)}
          description={
            status === 'APPROVED' && complianceRule?.rule === 'valid_compliance_rules'
              ? undefined
              : translate(complianceRule?.description, {
                  value: complianceRule.value,
                })
          }
          rule={complianceRule?.rule}
          onClick={() => scrollToComponent(complianceRule)}
        >
          {status !== 'APPROVED' && complianceRule?.complianceRules?.length > 0 && (
            <div className="compliance-rules-workflow-builder">
              {renderValidationRules(complianceRule?.complianceRules)}
            </div>
          )}
        </CheckpointStatus>
      ))}
    </Timeline>
  );
};

export default ValidationsTimeline;
