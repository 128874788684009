/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';

import { Tag, Popconfirm } from 'antd';

import SpecialTag from 'components/Tag/SpecialTag';

import './styles.less';

const ProfileTag = ({
  isClosable,
  onClose,
  name,
  addType,
  color,
  special,
  actionDescription,
  background,
}) => {
  const { t: translate } = useTranslation();

  const [isPopConfirmVisible, setIsPopConfirmVisible] = useState(false);

  const handleCancelDelete = useCallback(() => {
    setIsPopConfirmVisible(false);
  }, []);

  const handleConfirmDelete = useCallback(() => {
    onClose();
    setIsPopConfirmVisible(false);
  }, [onClose]);

  const handleOpenPopConfirm = useCallback(() => {
    setIsPopConfirmVisible(true);
  }, []);

  return (
    <Tag
      className="profile-tag"
      color={`#${color}`}
      style={{
        cursor: addType ? 'pointer' : 'inherit',
        margin: addType ? '0px' : '0px 10px 10px 0px',
        color: `#${background}`,
        background: `#${color}`,
      }}
    >
      <span>{name}</span>

      {special && <SpecialTag description={actionDescription} />}

      {isClosable && (
        <OutsideClickHandler onOutsideClick={handleCancelDelete}>
          <Popconfirm
            id="pop-confirm-profile-tag"
            placement="top"
            visible={isPopConfirmVisible}
            title={translate(
              'pages.private.profiles.components.profileTag.index.profileTag.popConfirm.title'
            )}
            okType="danger"
            okText={translate(
              'pages.private.profiles.components.profileTag.index.profileTag.popConfirm.okText'
            )}
            cancelText={translate(
              'pages.private.profiles.components.profileTag.index.profileTag.popConfirm.cancelText'
            )}
            onConfirm={handleConfirmDelete}
            onCancel={handleCancelDelete}
          >
            <button
              className="delete-tag-btn"
              type="button"
              onClick={handleOpenPopConfirm}
            >
              <i className="caf-ic_circle_false" />
            </button>
          </Popconfirm>
        </OutsideClickHandler>
      )}
    </Tag>
  );
};

ProfileTag.propTypes = {
  isClosable: PropTypes.bool,
  name: PropTypes.string,
  addType: PropTypes.bool,
  color: PropTypes.string,
  special: PropTypes.bool,
  actionDescription: PropTypes.string,
  background: PropTypes.string,
};

ProfileTag.defaultProps = {
  isClosable: false,
  name: '-',
  addType: false,
  color: '#828282',
  special: false,
  actionDescription: '-',
  background: '#efefef',
};

export default ProfileTag;
