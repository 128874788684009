import React, { useMemo, useState, useCallback } from 'react';
import { Steps, Form, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useFetch } from 'services/hooks';
import { CardWrapper } from 'components/CardWrapper';
import trustPoliciesKeys from 'utils/trustPoliciesKeys';
import managementPoliciesKeys from 'utils/managementPoliciesKeys';
import { useAuth } from 'hooks/auth';
import Wrapper from '../../wrapper';
import UsersInviteStep from './components/UsersInviteStep';

import './styles.less';
import GroupsStep from './components/GroupsStep';
import PermissionsStep from './components/PermissionsStep';
import ConfirmationStep from './components/ConfirmationStep';

const permissionsActions = ['read', 'update', 'create', 'delete'];

const I18N = `pages.private.userManagement.usersCreate.index`;

const { Step } = Steps;
const Users = () => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const { post } = useFetch();
  const [current, setCurrent] = useState(0);
  const [users, setUsers] = useState([]);
  const [typeOfCreate, setTypeOfCreate] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  const renderSteps = useMemo(() => {
    if (current === 0) {
      return <UsersInviteStep setUsers={setUsers} />;
    }

    if (current === 1) {
      return <GroupsStep typeOfCreate={typeOfCreate} setTypeOfCreate={setTypeOfCreate} />;
    }

    if (current === 2) {
      return (
        <PermissionsStep
          formRef={form}
          typeOfCreate={typeOfCreate}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
        />
      );
    }

    if (current === 3) {
      return <ConfirmationStep users={users} selectedGroup={selectedGroup} />;
    }

    return <></>;
  }, [current, form, selectedGroup, typeOfCreate, users]);

  const stepStatusUser = useMemo(() => {
    if (current > 0) return 'finish';
    return 'process';
  }, [current]);

  const stepStatusGroup = useMemo(() => {
    if (current < 1) return 'wait';
    if (current > 1) return 'finish';
    return 'process';
  }, [current]);

  const stepStatusPerms = useMemo(() => {
    if (current < 2) return 'wait';
    if (current > 2) return 'finish';
    return 'process';
  }, [current]);

  const stepStatusInvite = useMemo(() => {
    if (current < 3) return 'wait';
    if (current > 3) return 'finish';
    return 'process';
  }, [current]);

  const verifyDisabled = useMemo(() => {
    if (current === 0) {
      return !users?.length;
    }
    if (current === 1) {
      return !typeOfCreate;
    }
    if (current === 2) {
      if (typeOfCreate === 'existing')
        return !(selectedGroup?.id || selectedGroup?.tenantId);
      return false;
    }
    return false;
  }, [current, selectedGroup, typeOfCreate, users]);

  const handleInvite = useCallback(async () => {
    let response = {};
    setLoading(true);
    try {
      if (typeOfCreate !== 'existing') {
        const newPoliciesPayload = [];

        const specialKeys = Object.keys(trustPoliciesKeys.special);

        trustPoliciesKeys.normal?.forEach((key) => {
          if (
            !selectedGroup[key]?.read &&
            !selectedGroup[key]?.create &&
            !selectedGroup[key]?.update &&
            !selectedGroup[key]?.delete
          ) {
            return;
          }

          newPoliciesPayload.push({
            name: key,
            effect: 'Allow',
            actions: permissionsActions
              .map((perm) => (selectedGroup[key][perm] ? `${key}:${perm}` : null))
              .filter((v) => v !== null),
            resources: [...(selectedGroup[key].resources || []), '*'],
            special: false,
            product: 'trust',
          });
        });

        managementPoliciesKeys.normal?.forEach((key) => {
          if (
            !selectedGroup[key]?.read &&
            !selectedGroup[key]?.create &&
            !selectedGroup[key]?.update &&
            !selectedGroup[key]?.delete
          ) {
            return;
          }

          newPoliciesPayload.push({
            name: key,
            effect: 'Allow',
            actions: permissionsActions
              .map((perm) => (selectedGroup[key][perm] ? `${key}:${perm}` : null))
              .filter((v) => v !== null),
            resources: [...(selectedGroup[key].resources || []), '*'],
            special: false,
            product: 'management',
          });
        });

        specialKeys.forEach((key) => {
          if (!selectedGroup['special-rules'] || !selectedGroup['special-rules'][key])
            return;

          newPoliciesPayload.push({
            ...trustPoliciesKeys.special[key],
            name: key,
            product: 'trust',
          });
        });

        response = await post({
          url: '/groups',
          payload: {
            name: selectedGroup?.name,
            policies: newPoliciesPayload,
          },
          showError: true,
        });
      }
      const { data } = await post({
        url: '/users',
        payload: {
          emails: users?.join('|'),
          groupId: selectedGroup?.id || response?.data?.id,
          language: user.language,
        },
        showError: true,
      });
      data?.result.map((info) => {
        if (info?.status === 'success') {
          message.success(
            `${translate(
              `pages.private.userManagement.usersCreate.index.message.success`
            )} ${info?.email}`
          );
        } else {
          message.error(
            `${translate(
              `pages.private.userManagement.usersCreate.index.message.error`
            )} ${info?.email}`
          );
        }
      });
      history.push('/users');
    } catch {
      message.error(
        `${translate(
          `pages.private.userManagement.usersCreate.index.message.errorTryCatch`
        )}`
      );
    }
    setLoading(false);
  }, [history, post, selectedGroup, typeOfCreate, users, translate]);
  return (
    <>
      <Wrapper id="users-component">
        <CardWrapper hasPadding>
          <Steps
            type="navigation"
            size="small"
            current={current}
            className="site-navigation-steps"
          >
            <Step
              status={stepStatusUser}
              title={<>1. {translate(`${I18N}.steps.stepStatusUser`)}</>}
              // disabled
            />
            <Step
              status={stepStatusGroup}
              title={<>2. {translate(`${I18N}.steps.stepStatusGroup`)}</>}
              // disabled
            />
            <Step
              status={stepStatusPerms}
              title={<>3. {translate(`${I18N}.steps.stepStatusPerms`)}</>}
              // disabled
            />
            <Step
              status={stepStatusInvite}
              title={<>4. {translate(`${I18N}.steps.stepStatusInvite`)}</>}
              // disabled
            />
          </Steps>
          <Form layout="vertical" form={form}>
            <div className="steps-content">{renderSteps}</div>

            <div className="flex space-between">
              <Button
                type="secundary"
                onClick={() => setCurrent((oldState) => oldState - 1)}
                disabled={current === 0}
              >
                {translate(`${I18N}.buttonBack`)}
              </Button>
              {current !== 3 ? (
                <Button
                  type="primary"
                  onClick={() => {
                    if (
                      current === 2 &&
                      typeOfCreate !== 'existing' &&
                      !form.getFieldValue('name')
                    ) {
                      message.error(translate(`${I18N}.messageError`));
                      return false;
                    }

                    if (current === 2 && typeOfCreate !== 'existing')
                      setSelectedGroup(form.getFieldsValue());

                    setCurrent((oldState) => oldState + 1);
                    return false;
                  }}
                  disabled={verifyDisabled}
                >
                  {translate(`${I18N}.buttonNext`)}
                </Button>
              ) : (
                <Button type="primary" onClick={handleInvite} loading={loading}>
                  {translate(`${I18N}.buttonConfirm`)}
                </Button>
              )}
            </div>
          </Form>
        </CardWrapper>
      </Wrapper>
    </>
  );
};

export default Users;
