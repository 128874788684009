import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Checkbox, Button, Spin } from 'antd';
import { useComponentDocumentDBList } from 'components/List';

import PageTitle from 'components/PageTitle';
import _ from 'lodash';

import './styles.less';
import useConnectionsModal from './ConnectionModal';
import CollapseProfile from '../CollapseProfile';

const SuggestionsScreen = () => {
  const { t: translate } = useTranslation();
  const refreshListRef = useRef(() => {});

  const [checked, setChecked] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const { openModal, ConnectionsModal } = useConnectionsModal({
    refreshListRef,
  });

  const [checkAll, setCheckAll] = useState(false);

  const { data, loading, componentList, refreshList } = useComponentDocumentDBList({
    component: CollapseProfile,
    getParams: {
      url: 'global/profiles/suggestion/grouped-by-profile',
      config: {
        params: {},
      },
    },
    componentProps: { checked, setChecked, openModal },
    rowKey: '_id',
  });

  const refreshListSugs = useCallback(() => {
    refreshList();
    setChecked([]);
  }, [setChecked, refreshList]);

  useEffect(() => {
    refreshListRef.current = refreshListSugs;
  }, [refreshList, refreshListSugs]);

  const profileIds = useMemo(() => {
    return data?.map((s) => s?._id);
  }, [data]);

  const suggestionsIds = useMemo(() => {
    return data?.flatMap((s) => s.suggestions.map((sug) => sug._id));
  }, [data]);
  useEffect(() => {
    setIndeterminate(
      checked.filter((c) => !profileIds.includes(c)).length > 0 &&
        checked.filter((c) => !profileIds.includes(c)).length < suggestionsIds?.length
    );
    setCheckAll(
      checked.filter((c) => !profileIds.includes(c)).length === suggestionsIds?.length
    );
  }, [checked, profileIds, suggestionsIds]);

  const onCheckAllChange = useCallback(
    (e) => {
      const newChecked = e.target.checked ? suggestionsIds?.concat(profileIds) : [];
      setChecked(newChecked);
    },
    [suggestionsIds, profileIds]
  );

  const suggestionsMarked = useMemo(() => {
    return checked?.filter((c) => suggestionsIds?.includes(c));
  }, [checked, suggestionsIds]);

  const selectAllSuggestions = useCallback(
    (profileId) => {
      const sug = data.find((s) => s?._id === profileId);
      if (sug.suggestions.every((s) => checked.includes(s?._id))) {
        setChecked(
          checked.filter(
            (c) => !sug.suggestions.some((s) => s?._id === c) && c !== profileId
          )
        );
      } else {
        setChecked([
          ...new Set(
            checked.concat(sug.suggestions?.map((s) => s._id)).concat([profileId])
          ),
        ]);
      }
    },
    [setChecked, data, checked]
  );

  return (
    <div id="global-profiles-list">
      {ConnectionsModal}
      <PageTitle
        title={translate(
          'pages.private.globalProfiles.index.connectExecutions',
          'Connect executions'
        )}
        subtitle={translate(
          'pages.private.globalProfiles.index.connectExecutionsSubtitle',
          'Connect the executions with the suggested profiles'
        )}
      />
      {loading ? (
        <div className="flex center">
          <Spin className="mrg-top-20 mrg-btm-25 mrg-horizon-25" />
        </div>
      ) : (
        <>
          {!!data?.length && (
            <>
              <Row className="no-mrg no-pdd" justify="space-between">
                <div className="flex-row start-center">
                  <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll}
                    style={{ marginLeft: 12, marginRight: 12 }}
                  />{' '}
                  <b>
                    {translate(
                      'pages.private.globalProfiles.index.selectAll',
                      'Select all'
                    )}
                  </b>
                </div>
                <div className="flex-row center">
                  <Button
                    disabled={!suggestionsMarked?.length}
                    type="link"
                    onClick={() =>
                      openModal({ type: 'refuse', suggestions: suggestionsMarked })
                    }
                  >
                    {translate(
                      'pages.private.globalProfiles.index.notConnect',
                      'Not connect'
                    )}
                  </Button>
                  <Button
                    disabled={!suggestionsMarked?.length}
                    type="primary"
                    className="mrg-left-10"
                    onClick={() =>
                      openModal({ type: 'connect', suggestions: suggestionsMarked })
                    }
                  >
                    {translate('pages.private.globalProfiles.index.connect', 'Connect')}
                  </Button>
                </div>
              </Row>
            </>
          )}
          <Checkbox.Group
            style={{ width: '100%' }}
            value={checked}
            onChange={(checkedValues) => {
              const arrayDiff = _.difference(checked, checkedValues).concat(
                _.difference(checkedValues, checked)
              )[0];
              if (profileIds?.includes(arrayDiff)) {
                selectAllSuggestions(arrayDiff);
              } else {
                setChecked(checkedValues);
              }
            }}
          >
            {componentList}
          </Checkbox.Group>
        </>
      )}
    </div>
  );
};

export default SuggestionsScreen;
