import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, DatePicker, Card, Row, Button, Tooltip, Divider, Select } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import EmptyMessage from 'components/EmptyMessage';
import useInfiniteScroll from 'components/List/hooks/useInfiniteScroll';
import PropTypes from 'prop-types';

import { useFetch } from 'services/hooks';

import NotificationItem from './NotificationItem';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.notificationCenter.notificationList';
const { Option } = Select;

const NotificationList = ({ isRead }) => {
  const { t: translate } = useTranslation();
  const { patch } = useFetch();

  const dateToDisable = useCallback((current) => {
    const beforeOctober = current < moment().month('8').year('2020');
    const afterCurrentMonth = current > moment();

    return beforeOctober || afterCurrentMonth;
  }, []);

  const EmptyNotification = ({ show }) => {
    return (
      <div className="empty-notifications">
        <EmptyMessage
          show={show}
          type="empty"
          description={translate(
            `${I18N_BASE_PATH}.emptyNotification.emptyMessage.description`
          )}
        />
      </div>
    );
  };

  EmptyNotification.propTypes = {
    show: PropTypes.bool.isRequired,
  };

  const customDataParser = useCallback(
    ({ INITIAL_CURRENT, oldPagination, oldData, newData }) => {
      const oldDataDates = oldData.map(({ date }) => date);
      const newDataDates = newData.docs.map(({ date }) => date);
      // Pega todas as datas possíveis
      const everyDate = _.uniq([...oldDataDates, ...newDataDates]);

      const newDataParsed = everyDate.map((date) => {
        const foundOldData = oldData.find((od) => od.date === date) || { items: [] };
        const foundNewData = newData.docs.find((nd) => nd.date === date) || { items: [] };

        return {
          date,
          items: [...foundOldData.items, ...foundNewData.items],
        };
      });
      return oldPagination.current === INITIAL_CURRENT ? newData.docs : newDataParsed;
    },
    []
  );

  const {
    componentList: notificationsComponent,
    setFilterData: notificationsFilter,
    reloadList,
    loading,
  } = useInfiniteScroll({
    getParams: {
      url: '/notifications',
      config: {
        params: {
          _groupBy: 'date',
          _isRead: isRead,
        },
      },
    },
    rowKey: '_id',
    component: NotificationItem,
    emptyComponent: EmptyNotification,
    customDataParser,
  });

  const handleReadNotification = useCallback(async () => {
    await patch({
      url: `/notifications`,
      payload: {
        isRead: !isRead,
      },
      showMessage: false,
    });

    reloadList();
  }, [patch, isRead, reloadList]);

  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      const dates = { _startDate: undefined, _endDate: undefined };
      const type = { _type: undefined };
      if (values.period) {
        const newStartDate = moment.utc(moment(values.period).startOf('month')).toDate();
        const newEndDate = moment.utc(moment(values.period).endOf('month')).toDate();

        dates._startDate = new Date(newStartDate).getTime();
        dates._endDate = new Date(newEndDate).getTime();
      }
      if (values.type) {
        type._type = values.type && values.type !== 'all' ? values.type : undefined;
      }

      notificationsFilter({
        ...values,
        ...dates,
        ...type,
      });
    }, 500),
    []
  );

  return (
    <Card id="profile-model-notifications" className="mrg-top-0">
      <Form
        layout="horizontal"
        onValuesChange={handleFormChange}
        initialValues={{ _order: -1 }}
      >
        <Row>
          <Col span={24} className="recurrence-header-filter">
            <div className="period-group-container">
              <i className="caf-ic_calendar" />
              <Form.Item name="period">
                <DatePicker
                  bordered={false}
                  className="data-filter-period"
                  picker="month"
                  format="MMMM [de] YYYY"
                  inputReadOnly
                  placeholder={translate(`${I18N_BASE_PATH}.allDates`)}
                  disabledDate={dateToDisable}
                  suffixIcon={<i className="caf-ic_arrow_down" />}
                  style={{ width: 180 }}
                />
              </Form.Item>
            </div>
            <Row>
              <div>
                <Form.Item name="type">
                  <Select
                    bordered={false}
                    style={{ width: 190, fontWeight: 500 }}
                    className="text-dark"
                    defaultValue="all"
                  >
                    <Option value="all">
                      {translate(`${I18N_BASE_PATH}.select.options.all`)}
                    </Option>
                    <Option value="downloads">
                      {translate(`${I18N_BASE_PATH}.select.options.downloads`)}
                    </Option>
                    <Option value="links">
                      {translate(`${I18N_BASE_PATH}.select.options.onboardingLinks`)}
                    </Option>
                    <Option value="models">
                      {translate(`${I18N_BASE_PATH}.select.options.transactionTemplates`)}
                    </Option>
                    <Option value="warning">
                      {translate(`${I18N_BASE_PATH}.select.options.warning`)}
                    </Option>
                  </Select>
                </Form.Item>
              </div>
              <div>
                <Tooltip
                  placement="top"
                  title={
                    isRead
                      ? translate(`${I18N_BASE_PATH}.toolTip.isRead.true`)
                      : translate(`${I18N_BASE_PATH}.toolTip.isRead.false`)
                  }
                >
                  <Button
                    type="link"
                    className="reset-btn"
                    onClick={handleReadNotification}
                    disabled={isRead || loading}
                  >
                    <>
                      <i
                        className={
                          isRead ? 'caf-ic_check_d isRead-icon' : 'caf-ic_check-square'
                        }
                      />
                      {isRead ? (
                        <span className="btn-span ">
                          {translate(`${I18N_BASE_PATH}.toolTip.button.setNotRead`)}
                        </span>
                      ) : (
                        <span className="btn-span ">
                          {translate(`${I18N_BASE_PATH}.toolTip.button.setIsRead`)}
                        </span>
                      )}
                    </>
                  </Button>
                </Tooltip>
              </div>
            </Row>
          </Col>
          <Divider className="mrg-top-5" />
        </Row>
        <div>{notificationsComponent}</div>
      </Form>
    </Card>
  );
};

NotificationList.propTypes = {
  isRead: PropTypes.bool,
};

NotificationList.defaultProps = {
  isRead: null,
};

export default NotificationList;
