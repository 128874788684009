import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { Modal, Spin, Tabs, Alert, Typography } from 'antd';

import PageTitle from 'components/PageTitle';
import EmptyMessage from 'components/EmptyMessage';

import { usePerson } from 'hooks/person';
import { useCompany } from 'hooks/company';
import { useValidations } from 'hooks/validations';

import ComplianceRulesList from './components/ComplianceRulesList';
import ProfileRulesList from './components/ProfileRulesList';

import './styles.less';

const { Title } = Typography;

const { TabPane } = Tabs;

const PROFILE_RULES = [
  'completed_qsa_onboarding',
  'none_invalid_qsa_profiles',
  'some_valid_qsa_admin_profile',
  'some_valid_qsa_profile',
  'valid_all_qsa_admin_profiles',
  'valid_company_register',
  'is_attorney',
  'valid_document',
  'valid_identity',
  'valid_background_checking',
];

const ComplianceRulesValidations = () => {
  const { t: translate } = useTranslation();

  const [modalIsVisible, setModalIsVisible] = useState(false);

  const { companyId } = useParams();
  const { company, loading: loadingCompany } = useCompany();
  const { person, loading: loadingPerson } = usePerson();
  const { validations } = useValidations();

  const profileData = useMemo(() => {
    return companyId ? company : person;
  }, [companyId, company, person]);

  const validComplianceRules = useMemo(() => {
    const obj = validations?.validations?.find(
      (v) => v.rule === 'valid_compliance_rules'
    );

    return obj?.data?.mappedValidations?.backgroundChecking || [];
  }, [validations]);

  const validIdentityRules = useMemo(() => {
    const obj = validations?.validations?.find(
      (v) => v.rule === 'valid_compliance_rules'
    );

    return obj?.data?.mappedValidations?.identity || [];
  }, [validations]);

  const validProfileRules = useMemo(() => {
    const rules = person?.validations || company?.validations;
    const profileRules = rules?.filter((v) => PROFILE_RULES.includes(v.rule));
    return profileRules || [];
  }, [company, person]);

  const closeComplianceRulesModal = useCallback(() => {
    setModalIsVisible(false);
  }, []);

  const openComplianceRulesModal = useCallback(() => {
    setModalIsVisible(true);
  }, []);

  const ComplianceRulesValidationsContent = useMemo(
    () => (
      <Modal
        wrapClassName="caf-modal"
        closable
        width={1300}
        footer={null}
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeComplianceRulesModal}
        visible={modalIsVisible}
        className={classNames('compliance-rules-modal', {
          'load-modal-infos': loadingCompany || loadingPerson,
        })}
      >
        {loadingCompany || loadingPerson ? (
          <Spin size="large" />
        ) : (
          <>
            <div id="compliance-rules-modal-content">
              <PageTitle
                title={translate(
                  'pages.private.profiles.components.profileValidations.components.complianceRulesModal.index.complianceRulesValidationsContent.pageTitle.title'
                )}
                subtitle={translate(
                  'pages.private.profiles.components.profileValidations.components.complianceRulesModal.index.complianceRulesValidationsContent.pageTitle.subtitle'
                )}
              />
              <Tabs defaultActiveKey="1">
                <TabPane
                  tab={translate(
                    'pages.private.profiles.components.profileValidations.components.complianceRulesModal.index.complianceRulesValidationsContent.tabs.compliance'
                  )}
                  key="1"
                >
                  {Boolean(validProfileRules?.length) && (
                    <>
                      <Title level={5} className="mrg-btm-20">
                        {translate(
                          'pages.private.profiles.components.profileValidations.components.complianceRulesModal.index.complianceRulesValidationsContent.tabs.compliance.profileRules'
                        )}
                      </Title>
                      <ProfileRulesList
                        lastConsultationDate={profileData?.lastExecution?.date}
                        validations={validProfileRules}
                      />
                    </>
                  )}
                  {Boolean(validComplianceRules?.length) && (
                    <>
                      <Title level={5} className="mrg-btm-20">
                        {translate(
                          'pages.private.profiles.components.profileValidations.components.complianceRulesModal.index.complianceRulesValidationsContent.tabs.compliance.executionRules'
                        )}
                      </Title>
                      <ComplianceRulesList
                        lastConsultationDate={profileData?.lastExecution?.date}
                        validations={validComplianceRules}
                      />
                    </>
                  )}
                  {!validComplianceRules?.length && !validProfileRules?.length && (
                    <EmptyMessage
                      description={translate(
                        'pages.private.profiles.components.profileValidations.components.complianceRulesModal.index.complianceRulesValidationsContent.emptyMessageCompliance.description'
                      )}
                      withCard={false}
                    />
                  )}
                </TabPane>
                <TabPane
                  tab={translate(
                    'pages.private.profiles.components.profileValidations.components.complianceRulesModal.index.complianceRulesValidationsContent.tabs.identity'
                  )}
                  key="2"
                >
                  {!validIdentityRules?.length ? (
                    <EmptyMessage
                      description={translate(
                        'pages.private.profiles.components.profileValidations.components.complianceRulesModal.index.complianceRulesValidationsContent.emptyMessageIdentity.description'
                      )}
                      withCard={false}
                    />
                  ) : (
                    <>
                      <Alert
                        className="alert-info-form"
                        message={translate(
                          'pages.private.profiles.components.profileValidations.components.complianceRulesModal.index.complianceRulesValidationsContent.alert'
                        )}
                        type="info"
                        showIcon
                      />
                      <ComplianceRulesList
                        lastConsultationDate={profileData?.lastExecution?.date}
                        validations={validIdentityRules}
                      />
                    </>
                  )}
                </TabPane>
              </Tabs>
            </div>
          </>
        )}
      </Modal>
    ),
    [
      closeComplianceRulesModal,
      modalIsVisible,
      loadingCompany,
      loadingPerson,
      translate,
      validComplianceRules,
      profileData,
      validIdentityRules,
      validProfileRules,
    ]
  );

  return { openComplianceRulesModal, ComplianceRulesValidationsContent };
};

export default ComplianceRulesValidations;
