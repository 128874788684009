import React, { createContext, useCallback, useContext, useState } from 'react';
import { useOnboardingTemplatesCache } from 'services/hooks';

const OnboardingTemplateContext = createContext({});

const OnboardingTemplateProvider = ({ children }) => {
  const [defaultTemplate, setDefaultTemplate] = useState({});

  const {
    allOnboardingTemplates,
    loadingAllOnboardingTemplates,
  } = useOnboardingTemplatesCache();

  const loadOnboardingTemplates = useCallback(async () => {
    const defaults = {
      PF: allOnboardingTemplates?.find(
        (t) => t?.type === 'PF' && t?.name?.toLowerCase() === 'default'
      ),
      PJ: allOnboardingTemplates?.find(
        (t) => t?.type === 'PJ' && t?.name?.toLowerCase() === 'default'
      ),
    };

    setDefaultTemplate(defaults);

    return defaults;
  }, [allOnboardingTemplates]);

  return (
    <OnboardingTemplateContext.Provider
      value={{
        loadOnboardingTemplates,
        allOnboardingTemplates,
        defaultTemplate,
        loading: loadingAllOnboardingTemplates,
      }}
    >
      {children}
    </OnboardingTemplateContext.Provider>
  );
};

function useOnboardingTemplate() {
  const context = useContext(OnboardingTemplateContext);

  if (!context) {
    throw new Error(
      'useOnboardingTemplate must be used within an OnboardingTemplateContext'
    );
  }

  return context;
}

export { OnboardingTemplateProvider, useOnboardingTemplate };
