import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CircleIcon from 'components/Icon/CircleIcon';

import './styles.less';

const AddressItem = ({ data: address }) => {
  const { t: translate } = useTranslation();

  const [isMapAddressOpen, setIsMapAddressOpen] = useState(false);

  const joinArray = useCallback((array) => {
    return array.filter((n) => n).join(', ');
  }, []);

  const {
    typology,
    addressMain,
    street,
    number,
    complement,
    neighborhood,
    city,
    state,
    zipCode,
    type,
  } = address || {};

  const addressMapSource = useMemo(() => {
    const fullAddress = joinArray([
      `${typology} ${addressMain || street}`,
      number,
      zipCode,
      neighborhood,
      city,
      state,
    ]);

    if (!fullAddress) return undefined;

    const encodedFullAddress = encodeURI(fullAddress);

    const url = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&q=${encodedFullAddress}`;

    return url;
  }, [joinArray, typology, addressMain, number, zipCode, neighborhood, city, state]);

  return (
    <div id="address-item-container">
      <section>
        <CircleIcon icon="caf-ic_map_pin" />
        <div className="address-item-data-container">
          <section className="flex space-between address-item-information">
            <span>
              {(addressMain || street) && `${addressMain || street},`}{' '}
              {number && `${number},`}{' '}
              {complement && neighborhood ? `${complement} -` : `${complement || ''}`}{' '}
              {neighborhood || ''} <br />
              {city && state ? `${city} /` : `${city || ''}`} {state && `${state},`}{' '}
              {zipCode || ''}
            </span>
            <button
              type="button"
              onClick={() => setIsMapAddressOpen(!isMapAddressOpen)}
              className="flex flex-column center"
            >
              <i
                className={`caf-ic_${
                  !isMapAddressOpen ? 'news' : 'close'
                } font-size-18 mrg-btm-5 gx-text-info`}
              />
              <span className="gx-text-info font-size-15 text-semibold">
                {translate(
                  `pages.private.profiles.components.tabs.components.basicInfos.components.person.addressContent.components.addressItem.index.addressItem.button.${
                    !isMapAddressOpen ? 'openMap' : 'closeMap'
                  }`
                )}
              </span>
            </button>
          </section>
        </div>
      </section>
      {addressMapSource && isMapAddressOpen && (
        <div className="mrg-top-25">
          <iframe
            title="map"
            width="100%"
            height="350"
            src={addressMapSource}
            allowFullScreen
            className="iframe-map"
          />
        </div>
      )}
    </div>
  );
};

AddressItem.propTypes = {
  data: PropTypes.shape({
    typology: PropTypes.string,
    addressMain: PropTypes.string,
    number: PropTypes.string,
    complement: PropTypes.string,
    neighborhood: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

export default AddressItem;
