import React, { useCallback, useMemo, useState } from 'react';
import { Col, Divider, Form, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import TooltipInfo from 'components/TooltipInfo';
import { useReasons } from 'hooks/reasons';

import './styles.less';

const { Option } = Select;

const I18N_BASE_PATH =
  'pages.private.executions.components.generalStatus.pendingStatusModal.index';

function CustomStatusReasons({ actionType }) {
  const { t: translate } = useTranslation();
  const { loadReasons, loadingReasons } = useReasons();

  const [selectedReasons, setSelectedReasons] = useState();
  const [customStatusReasons, setCustomStatusReasons] = useState();

  const handleSelectedReasons = useCallback(
    (payload) => {
      const selectedCustomReasons = payload?.map((r) =>
        customStatusReasons.find((customReason) => customReason?._id === r?.key)
      );
      setSelectedReasons(selectedCustomReasons);
    },
    [customStatusReasons]
  );

  const reloadList = useCallback(
    async (type) => {
      const reasonsList = await loadReasons({
        type,
      });

      setCustomStatusReasons(reasonsList || []);
    },
    [loadReasons]
  );

  const isReproval = useMemo(() => actionType === 'REPROVED', [actionType]);

  useMemo(async () => {
    setSelectedReasons([]);
    setCustomStatusReasons([]);
    reloadList(isReproval ? 'reproval' : 'approval');
  }, [reloadList, isReproval]);

  return (
    <div className={isReproval ? 'reproval' : ''} id="custom-status-reasons">
      <Form.Item name="customStatusReasons">
        <Select
          mode="multiple"
          labelInValue
          placeholder={translate(
            `${I18N_BASE_PATH}.pendingStatusModal.form.multiselect.placeholder`
          )}
          optionLabelProp="label"
          dropdownClassName="multiselect-filters"
          filterOption={false}
          listHeight={320}
          dropdownAlign={{ offset: [0, 2] }}
          removeIcon={<i className="caf-ic_circle_false" />}
          menuItemSelectedIcon={<i className="caf-ic_checkmark" />}
          loading={loadingReasons}
          disabled={loadingReasons}
          onChange={handleSelectedReasons}
        >
          {customStatusReasons?.map((d) => (
            <Option key={d?._id} label={d?.title} teste="ok" value={d?._id}>
              <div className="multiselect-option">
                <span className="option-title">{d?.title}</span>
              </div>
            </Option>
          ))}
        </Select>
      </Form.Item>
      {!!selectedReasons?.length && (
        <Row>
          <Col className="mrg-top-10 mrg-btm-5 text-dark" span={24}>
            {translate(
              `${I18N_BASE_PATH}.pendingStatusModal.form.multiselect.selectedReasons`
            )}
            :
          </Col>
          {selectedReasons?.map((r) => (
            <Col className="mrg-top-5" span={24}>
              <span>{r?.description}</span>
              <TooltipInfo size={12} color="#2f80ed" title={r?.title} />
            </Col>
          ))}
          <Divider />
        </Row>
      )}
    </div>
  );
}

CustomStatusReasons.propTypes = {
  actionType: PropTypes.string.isRequired,
};

export default CustomStatusReasons;
