import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useAuth } from 'hooks/auth';

import Wrapper from 'pages/private/wrapper';
import { CardWrapper } from 'components/CardWrapper';

import List from './components/List';
import Filter from './components/List/components/Filter';

import Unauthorized from '../Unauthorized';
import './styles.less';

const Profiles = () => {
  const { user } = useAuth();
  const matchPeople = useRouteMatch('/profile/people');
  const matchCompanies = useRouteMatch('/profile/companies');

  return !user?.accountData?.featureFlags?.profilesModule ? (
    <Unauthorized />
  ) : (
    <Wrapper id="profiles-list-component">
      <div className="profile-content-grid">
        <div className="profile-content-grid-1">
          <CardWrapper hasPadding>
            <List matchPeople={matchPeople} matchCompanies={matchCompanies} />
          </CardWrapper>
        </div>
        <div className="profile-content-grid-2">
          <CardWrapper hasPadding>
            <Filter matchPeople={matchPeople} />
          </CardWrapper>
        </div>
      </div>
    </Wrapper>
  );
};

export default Profiles;
