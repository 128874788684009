import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Row, Collapse, Col, Typography } from 'antd';
import DottedLabel from 'components/DottedLabel';
import { toMaskedCpf, toMaskedCnpj } from 'utils/formatters';
import currencyFormatter from 'utils/currencyFormatter';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import EmptyExecutionState from 'components/EmptyExecutionState';

import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

import './styles.less';

const { toCurrency } = currencyFormatter;

const { Panel } = Collapse;
const { Title } = Typography;

const I18N_BASE_PATH = 'components.dataSourceCards.creditOperationsScr.index';

const CreditOperationsScr = ({ source = {}, toPrint = false, type }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);

  const queryWorked = useMemo(
    () =>
      parsedData?.statusCode === '01' ||
      source?.statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [parsedData, source]
  );

  const statusCode = useMemo(() => {
    if (!queryWorked) {
      return (
        <DottedLabel
          type="danger"
          description={<span>{translate(`${I18N_BASE_PATH}.notFound`)}</span>}
        />
      );
    }
    return (
      <DottedLabel
        type="primary"
        description={<span>{translate(`${I18N_BASE_PATH}.found`)}</span>}
      />
    );
  }, [queryWorked, translate]);

  const totalDebts = useCallback((debts) => {
    let total = 0;
    debts?.forEach((debt) => {
      total += Number(debt?.value);
    });
    return total.toFixed(2);
  }, []);

  const orderedSumaryList = useMemo(() => {
    if (!Array.isArray(parsedData?.operationSumaryList)) {
      return [];
    }
    const orderList = parsedData?.operationSumaryList?.sort((a, b) =>
      // eslint-disable-next-line no-nested-ternary
      a.modalityCategoryCode > b.modalityCategoryCode
        ? 1
        : b.modalityCategoryCode > a.modalityCategoryCode
        ? -1
        : 0
    );
    return orderList;
  }, [parsedData]);

  const orderedList = useCallback((list) => {
    const orderList = list?.sort((a, b) =>
      // eslint-disable-next-line no-nested-ternary
      a.expirationCode > b.expirationCode
        ? 1
        : b.expirationCode > a.expirationCode
        ? -1
        : 0
    );
    return orderList;
  }, []);

  return queryWorked && parsedData?.clientCode ? (
    <DataSourceCardCollapsePanel
      customId="independent-consultants-component"
      title={translate(`${I18N_BASE_PATH}.title`)}
      icon="caf-ic_company"
      toPrint={toPrint}
    >
      <div id="credit-operations-scr">
        <Title level={5}>{translate(`${I18N_BASE_PATH}.infos`)}</Title>
        <Row id="executions-independent-consultants-card">
          {type === 'PF' ? (
            <ColumnTitleValue
              span={12}
              title={translate(`${I18N_BASE_PATH}.cpf`)}
              value={toMaskedCpf(parsedData?.clientCode) || '-'}
            />
          ) : (
            <ColumnTitleValue
              span={12}
              title={translate(`${I18N_BASE_PATH}.cnpj`)}
              value={toMaskedCnpj(parsedData?.clientCode) || '-'}
            />
          )}
          <ColumnTitleValue
            span={12}
            title={translate(`${I18N_BASE_PATH}.queryStatus`)}
            value={statusCode}
          />
          <ColumnTitleValue
            span={12}
            title={translate(`${I18N_BASE_PATH}.registerDate`)}
            value={
              parsedData?.registrationDate
                ? format(
                    new Date(`${parsedData?.registrationDate}T12:00:00.000Z`),
                    'dd/MM/yyyy',
                    {
                      locale: ptBR,
                    }
                  )
                : '-'
            }
          />
          <ColumnTitleValue
            span={12}
            title={translate(`${I18N_BASE_PATH}.baseData`)}
            value={
              parsedData?.baseDate
                ? format(new Date(`${parsedData?.baseDate}T12:00:00.000Z`), 'MM/yyyy', {
                    locale: ptBR,
                  })
                : '-'
            }
          />
          <Divider className="custom-divider" />
          <ColumnTitleValue
            span={24}
            title={translate(`${I18N_BASE_PATH}.assumedCredit`)}
            value={toCurrency(parsedData?.assumedCoobligation, 'R$') || '-'}
          />
          <ColumnTitleValue
            span={24}
            title={translate(`${I18N_BASE_PATH}.receivedCredit`)}
            value={toCurrency(parsedData?.receivedCoobligation, 'R$') || '-'}
          />
          <ColumnTitleValue
            span={24}
            title={translate(`${I18N_BASE_PATH}.waitDocs`)}
            value={`${parsedData?.processedDocumentesPercentage}%` || '-'}
          />
          <ColumnTitleValue
            span={24}
            title={translate(`${I18N_BASE_PATH}.volume`)}
            value={`${parsedData?.processedVolumePercentage}%` || '-'}
          />
          <Divider className="custom-divider" />
          <ColumnTitleValue
            span={24}
            title={translate(`${I18N_BASE_PATH}.ifs`)}
            value={parsedData?.totalOfInstitutions || '-'}
          />
          <ColumnTitleValue
            span={24}
            title={translate(`${I18N_BASE_PATH}.sfn`)}
            value={parsedData?.totalOfTransactions || '-'}
          />
          <ColumnTitleValue
            span={24}
            title={translate(`${I18N_BASE_PATH}.deniedTransactions`)}
            value={parsedData?.totalDisagreementTransactions || '-'}
          />
          <ColumnTitleValue
            span={24}
            title={translate(`${I18N_BASE_PATH}.disagreementTransactions`)}
            value={parsedData?.totalSubJudiceTransactions || '-'}
          />
          <ColumnTitleValue
            span={24}
            title={translate(`${I18N_BASE_PATH}.disagreementManifests`)}
            value={parsedData?.fullLiabilityDisagreement || '-'}
          />
          <ColumnTitleValue
            span={24}
            title={translate(`${I18N_BASE_PATH}.subManifests`)}
            value={parsedData?.fullLiabilitySubJudice || '-'}
          />
          <ColumnTitleValue
            span={24}
            title={translate(`${I18N_BASE_PATH}.risk`)}
            value={parsedData?.indirectVendorRisk || '-'}
          />
        </Row>
        <Collapse
          className="mrg-top-25 mrg-btm-15"
          defaultActiveKey={toPrint ? ['1'] : null}
        >
          <Panel
            key="1"
            disabled={!parsedData?.operationSumaryList?.length}
            header={
              <span className="text-dark text-bold">
                {translate(`${I18N_BASE_PATH}.operations`)}
              </span>
            }
            extra={
              <b className="info-span">
                {translate(`${I18N_BASE_PATH}.numOperations`, {
                  numOperations: parsedData?.operationSumaryList?.length,
                })}
              </b>
            }
          >
            {orderedSumaryList?.map((op) => (
              <>
                <Row align="middle" justify="space-between" className="mrg-top-15">
                  <ColumnTitleValue
                    span={24}
                    title={`${op?.modalityCategoryCode} - ${op?.modalityCategoryDescription}`}
                  />
                  <Col span={24}>
                    <span className="text-gray text-bold">
                      {translate(`${I18N_BASE_PATH}.subcategory`)}
                    </span>
                    <span> {op?.modalitySubCategoryDescription || '-'}</span>
                  </Col>
                  <Col span={24}>
                    <span className="text-gray text-bold">
                      {translate(`${I18N_BASE_PATH}.subcategoryCode`)}
                    </span>
                    <span> {op?.modalitySubCategoryCode || '-'}</span>
                  </Col>
                  <Col span={24}>
                    <Collapse
                      className="mrg-top-25 mrg-btm-15 "
                      defaultActiveKey={toPrint ? ['1'] : null}
                      ghost
                    >
                      <Panel
                        key="1"
                        header={
                          <>
                            <i className="caf-ic_arrow-right mrg-right-10 button-panel" />
                            <span className="button-panel">
                              {translate(`${I18N_BASE_PATH}.debts`)}
                            </span>
                          </>
                        }
                        extra={
                          <b className="danger-span">
                            - {toCurrency(totalDebts(op?.listOfDebts), 'R$')}
                          </b>
                        }
                        showArrow={false}
                      >
                        <div id="modal-debts">
                          {orderedList(op?.listOfDebts)?.map((debt) => (
                            <Row
                              className="no-mrg no-pdd span-list"
                              justify="space-between"
                            >
                              <span>{`${debt?.expirationCode} - ${debt?.expirationDescription}`}</span>
                              <span>{`- ${toCurrency(debt?.value, 'R$')}`}</span>
                            </Row>
                          ))}
                          <Divider />
                          <Row className="no-mrg no-pdd" justify="space-between">
                            <span className="total-title">
                              {translate(
                                `components.dataSourceCards.creditOperationsScr.components.modalDebts.total`
                              )}
                            </span>
                            <span className="total-span total-title">{`- ${toCurrency(
                              totalDebts(op?.listOfDebts),
                              'R$'
                            )}`}</span>
                          </Row>
                        </div>
                      </Panel>
                    </Collapse>
                  </Col>

                  <Divider />
                </Row>
              </>
            ))}
          </Panel>
        </Collapse>
      </div>
    </DataSourceCardCollapsePanel>
  ) : (
    <DataSourceCardCollapsePanel
      customId="independent-consultants-component"
      title={translate(`${I18N_BASE_PATH}.title`)}
      icon="caf-ic_company"
      toPrint={toPrint}
    >
      <EmptyExecutionState
        statusCode={source?.statusCode || parsedData?.statusCode}
        message={source?.message || parsedData?.message}
      />
    </DataSourceCardCollapsePanel>
  );
};

export default CreditOperationsScr;
