/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Collapse, Divider } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import ViewMore from 'components/ViewMore';
import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';

import {
  courtInfos,
  statusInfos,
  partyInfos,
} from '../../components/utils/processesDetails';

import ProcessTag from '../../components/ProcessTag';
import useAllProcesses from '../../components/AllProcessesModal';
import useProcessDetails from '../../components/ProcessDetailsModal';

import './styles.less';

const I18N_BASE_PATH =
  'components.dataSourceCards.legalInformation.lists.processesList.index.processesList';
const { Panel } = Collapse;

const ProcessesList = ({ data, displayValue, toPrint, hasPersonProcesses }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDateOnly } = useI18nFormatters();

  const { openModal: openAllProcessesModal, AllProcessesModal } = useAllProcesses({
    data,
    hasPersonProcesses,
  });
  const { ProcessDetailsModal } = useProcessDetails();
  return (
    <div id="executions-legal-information-processes-container">
      {ProcessDetailsModal}
      {AllProcessesModal}
      <Row className="legal-information-processes-title">
        {Array.isArray(data) &&
          data?.slice(0, toPrint ? data?.length : displayValue)?.map((process) => (
            <Col
              className="col-ant"
              span={24}
              style={{
                paddingLeft: '0px',
                paddingRight: '0px',
              }}
            >
              <DataSourceCardCollapsePanel
                title={process.mainSubject || process.subjects?.join(', ') || '-'}
                toPrint={toPrint}
                icon="caf-ic_file"
                extra={
                  process.authorParty &&
                  hasPersonProcesses && (
                    <ProcessTag
                      title={translate(partyInfos(process.authorParty).name)}
                      type={partyInfos(process.authorParty).type}
                    />
                  )
                }
              >
                <Row className="mrg-left-5 mrg-right-5 mrg-top-5">
                  <Col span={12}>
                    <strong>{translate(`${I18N_BASE_PATH}.id`)}</strong>
                    <p>{process?.id || '-'}</p>
                  </Col>
                  <Col span={12}>
                    <strong>{translate(`${I18N_BASE_PATH}.idProcess`)}</strong>
                    <p>{process?.lawsuitId || process?.number || '-'}</p>
                  </Col>
                  <Col span={24}>
                    <strong>{translate(`${I18N_BASE_PATH}.oldNumber`)}</strong>
                    <p>{process?.oldLawsuitNumber || '-'}</p>
                  </Col>
                  <Col span={8}>
                    <strong>{translate(`${I18N_BASE_PATH}.judgingBody`)}</strong>
                    <p>{process?.judgingBody || '-'}</p>
                  </Col>
                  <Col span={8}>
                    <strong>{translate(`${I18N_BASE_PATH}.statusDate`)}</strong>
                    <p>
                      {((process.statusDate || process?.lastUpdate) &&
                        i18nFormatDateOnly(
                          process?.statusDate || process?.lastUpdate,
                          1
                        )) ||
                        '-'}
                    </p>
                  </Col>
                  <Col span={8}>
                    <strong>{translate(`${I18N_BASE_PATH}.url`)}</strong>
                    <p>
                      {process.lawsuitUrl ? (
                        <a href={process.lawsuitUrl || '-'} target="blank">
                          {translate(`${I18N_BASE_PATH}.visualize`) || '-'}
                        </a>
                      ) : (
                        '-'
                      )}
                    </p>
                  </Col>
                  <Col
                    span={24}
                    className="col-ant"
                    style={{
                      marginLeft: '0px',
                      paddingLeft: '0px',
                      marginRight: '0px',
                      paddingRight: '0px',
                    }}
                  >
                    <Collapse expandIconPosition="right" ghost>
                      <Panel
                        header={<b>{translate(`${I18N_BASE_PATH}.details`)}</b>}
                        key="1"
                        showArrow
                        className="mrg-top-30"
                      >
                        <Row
                          className="mrg-left-5 mrg-right-5 mrg-top-5"
                          style={{ marginLeft: '0px', paddingLeft: '0px' }}
                        >
                          <Col span={8}>
                            <strong>
                              {translate(`${I18N_BASE_PATH}.distribuationDate`)}
                            </strong>
                            <p>
                              {((process?.distributionDate ||
                                (process?.publicationDate &&
                                  process?.publicationDate.split('T')[0] !==
                                    '0001-01-01')) &&
                                i18nFormatDateOnly(
                                  process?.distributionDate || process?.publicationDate,
                                  1
                                )) ||
                                '-'}
                            </p>
                          </Col>
                          <Col span={8}>
                            <strong>{translate(`${I18N_BASE_PATH}.category`)}</strong>
                            <p>{process?.criminalCategory || '-'}</p>
                          </Col>
                          <Col span={8}>
                            <strong>{translate(`${I18N_BASE_PATH}.type`)}</strong>
                            <p>{process?.type || process?.authorType || '-'}</p>
                          </Col>
                          <Col span={8}>
                            <strong>{translate(`${I18N_BASE_PATH}.localization`)}</strong>
                            <p>{process?.physicalLocation || '-'}</p>
                          </Col>
                          <Col span={8}>
                            <strong>{translate(`${I18N_BASE_PATH}.digital`)}</strong>
                            <p>{process?.isDigitalLawsuit ? 'Sim' : 'Não' || '-'}</p>
                          </Col>
                          <Col span={8}>
                            <strong>{translate(`${I18N_BASE_PATH}.degree`)}</strong>
                            <p>{process?.lawsuitDegree || process?.courtLevel || '-'}</p>
                          </Col>
                          <Col span={8}>
                            <strong>{translate(`${I18N_BASE_PATH}.branch`)}</strong>
                            <p>{process.lawBranch || '-'}</p>
                          </Col>
                          <Col span={8}>
                            <strong>{translate(`${I18N_BASE_PATH}.segment`)}</strong>
                            <p>{process?.segment || '-'}</p>
                          </Col>
                          <Col span={8}>
                            <strong>{translate(`${I18N_BASE_PATH}.secret`)}</strong>
                            <p>{process?.isJusticeSecret ? 'Sim' : 'Não' || '-'}</p>
                          </Col>
                          <Col span={8}>
                            <strong>{translate(`${I18N_BASE_PATH}.court`)}</strong>
                            <p>{process.court || process?.courtName || '-'}</p>
                          </Col>
                          <Col span={8}>
                            <strong>{translate(`${I18N_BASE_PATH}.uf`)}</strong>
                            <p>{process?.state || '-'}</p>
                          </Col>
                          <Col span={8}>
                            <strong>{translate(`${I18N_BASE_PATH}.unity`)}</strong>
                            <p>{process?.sourceUnit || '-'}</p>
                          </Col>
                          <Col span={8}>
                            <strong>{translate(`${I18N_BASE_PATH}.status`)}</strong>
                            <p>
                              {process?.complementaryStatus || process?.status || '-'}
                            </p>
                          </Col>
                        </Row>
                      </Panel>
                      <Divider />
                    </Collapse>
                  </Col>
                </Row>

                <DataSourceCardCollapsePanel
                  icon="caf-ic_file"
                  title={translate(`${I18N_BASE_PATH}.matters`)}
                  customId="legalInformation"
                  toPrint={toPrint}
                  className="mrg-top-20"
                >
                  {process?.subjects?.length > 0 ? (
                    process?.subjects?.map((matters) => (
                      <Row>
                        <Col span={24} className="col-ant mrg-left-20 mrg-btm-5">
                          <strong>{matters}</strong>
                        </Col>
                      </Row>
                    ))
                  ) : (
                    <Row>
                      <Col span={24} className="col-ant mrg-left-20 mrg-btm-5">
                        <strong>{translate(`${I18N_BASE_PATH}.empty`)}</strong>
                      </Col>
                    </Row>
                  )}
                </DataSourceCardCollapsePanel>
                <DataSourceCardCollapsePanel
                  icon="caf-ic_news"
                  title={translate(`${I18N_BASE_PATH}.moviments`)}
                  customId="legalInformation"
                  toPrint={toPrint}
                  className="mrg-top-20"
                >
                  {process?.moves?.length > 0 ? (
                    process?.moves?.map((moves) => (
                      <Row>
                        <Col span={24} className="col-ant mrg-left-20 mrg-btm-5">
                          <strong>{translate(`${I18N_BASE_PATH}.date`)}</strong>
                          <p>
                            {(moves.date && i18nFormatDateOnly(moves.date, 1)) || '-'}
                          </p>
                          <strong>{translate(`${I18N_BASE_PATH}.description`)}</strong>
                          <p>{moves.description || '-'}</p>
                        </Col>
                      </Row>
                    ))
                  ) : (
                    <Row>
                      <Col span={24} className="col-ant mrg-left-20 mrg-btm-5">
                        <strong>{translate(`${I18N_BASE_PATH}.empty`)}</strong>
                      </Col>
                    </Row>
                  )}
                </DataSourceCardCollapsePanel>
                <DataSourceCardCollapsePanel
                  icon="caf-ic_minimize"
                  title={translate(`${I18N_BASE_PATH}.passives`)}
                  className="mrg-top-20"
                  toPrint={toPrint}
                >
                  {process?.passives?.length > 0 ? (
                    process?.passives?.map((passives) => (
                      <div>
                        <Row className="passives mrg-top-40">
                          <Col span={8}>
                            <strong>{translate(`${I18N_BASE_PATH}.name`)}</strong>
                            <p>{passives?.name || '-'}</p>
                          </Col>
                          <Col span={8}>
                            <strong>{translate(`${I18N_BASE_PATH}.cpf`)}</strong>
                            <p>{passives?.cpf || '-'}</p>
                          </Col>
                          <Col span={8}>
                            <strong>{translate(`${I18N_BASE_PATH}.type`)}</strong>
                            <p>{passives?.type || '-'}</p>
                          </Col>
                        </Row>
                        <Col span={24} className="mrg-top-20 mrg-btm-30">
                          <strong>{translate(`${I18N_BASE_PATH}.lawyers`)}</strong>
                        </Col>
                        {passives?.lawyers?.length > 0 ? (
                          passives?.lawyers?.map((lawyers) => (
                            <Row className="passives">
                              <Col span={8} className="col-ant mrg-btm-5">
                                <strong>{translate(`${I18N_BASE_PATH}.oab`)}</strong>
                                <p>{lawyers.oab || '-'}</p>
                              </Col>
                              <Col span={8} className="col-ant  mrg-btm-5">
                                <strong>{translate(`${I18N_BASE_PATH}.name`)}</strong>
                                <p>{lawyers.name || '-'}</p>
                              </Col>
                              <Col span={8} className="col-ant mrg-btm-5">
                                <strong>{translate(`${I18N_BASE_PATH}.cpf`)}</strong>
                                <p>{lawyers.cpf || '-'}</p>
                              </Col>
                            </Row>
                          ))
                        ) : (
                          <>-</>
                        )}
                        <Divider />
                      </div>
                    ))
                  ) : (
                    <Row>
                      <Col span={24} className="col-ant mrg-left-20 mrg-btm-5">
                        <strong>{translate(`${I18N_BASE_PATH}.empty`)}</strong>
                      </Col>
                    </Row>
                  )}
                </DataSourceCardCollapsePanel>
                <DataSourceCardCollapsePanel
                  icon="caf-ic_plus"
                  title={translate(`${I18N_BASE_PATH}.actives`)}
                  customId="legalInformation"
                  toPrint={toPrint}
                  className="mrg-top-20"
                >
                  {process?.isMoreThan30 && toPrint && (
                    <span className="gx-text-danger">
                      {translate(`${I18N_BASE_PATH}.isMoreThan30`)}
                    </span>
                  )}
                  {process?.actives?.length > 0 ? (
                    process?.actives?.map((actives) => (
                      <div>
                        <Row className="passives mrg-top-40">
                          <Col span={8}>
                            <strong>{translate(`${I18N_BASE_PATH}.name`)}</strong>
                            <p>{actives?.name || '-'}</p>
                          </Col>
                          <Col span={8}>
                            <strong>{translate(`${I18N_BASE_PATH}.cpf`)}</strong>
                            <p>{actives?.cpf || '-'}</p>
                          </Col>
                          <Col span={8}>
                            <strong>{translate(`${I18N_BASE_PATH}.type`)}</strong>
                            <p>{actives?.type || '-'}</p>
                          </Col>
                        </Row>
                        <Col span={24} className="mrg-top-20 mrg-btm-30">
                          <strong>{translate(`${I18N_BASE_PATH}.lawyers`)}</strong>
                        </Col>
                        {actives?.lawyers?.length > 0 ? (
                          actives?.lawyers?.map((lawyers) => (
                            <Row className="passives">
                              <Col span={8} className="col-ant mrg-btm-5">
                                <strong>{translate(`${I18N_BASE_PATH}.oab`)}</strong>
                                <p>{lawyers.oab || '-'}</p>
                              </Col>
                              <Col span={8} className="col-ant  mrg-btm-5">
                                <strong>{translate(`${I18N_BASE_PATH}.name`)}</strong>
                                <p>{lawyers.name || '-'}</p>
                              </Col>
                              <Col span={8} className="col-ant mrg-btm-5">
                                <strong>{translate(`${I18N_BASE_PATH}.cpf`)}</strong>
                                <p>{lawyers.cpf || '-'}</p>
                              </Col>
                            </Row>
                          ))
                        ) : (
                          <>-</>
                        )}
                        <Divider />
                      </div>
                    ))
                  ) : (
                    <Row>
                      <Col span={24} className="col-ant mrg-left-20 mrg-btm-5">
                        <strong>{translate(`${I18N_BASE_PATH}.empty`)}</strong>
                      </Col>
                    </Row>
                  )}
                </DataSourceCardCollapsePanel>
                <DataSourceCardCollapsePanel
                  icon="caf-ic_more-horizontal"
                  title={translate(`${I18N_BASE_PATH}.others`)}
                  className="mrg-top-20"
                  toPrint={toPrint}
                >
                  {process?.otherParties?.length > 0 ? (
                    process?.otherParties?.map((otherParty) => (
                      <div>
                        <Row className="passives mrg-top-40">
                          <Col span={8}>
                            <strong>{translate(`${I18N_BASE_PATH}.name`)}</strong>
                            <p>{otherParty?.name || '-'}</p>
                          </Col>
                          <Col span={8}>
                            <strong>{translate(`${I18N_BASE_PATH}.cpf`)}</strong>
                            <p>{otherParty?.cpf || '-'}</p>
                          </Col>
                          <Col span={8}>
                            <strong>{translate(`${I18N_BASE_PATH}.type`)}</strong>
                            <p>{otherParty?.type || '-'}</p>
                          </Col>
                        </Row>
                        <Col span={24} className="mrg-top-20 mrg-btm-30">
                          <strong>{translate(`${I18N_BASE_PATH}.lawyers`)}</strong>
                        </Col>
                        {otherParty?.lawyers?.length > 0 ? (
                          otherParty?.lawyers?.map((lawyers) => (
                            <Row className="passives">
                              <Col span={8} className="col-ant mrg-btm-5">
                                <strong>{translate(`${I18N_BASE_PATH}.oab`)}</strong>
                                <p>{lawyers.oab || '-'}</p>
                              </Col>
                              <Col span={8} className="col-ant  mrg-btm-5">
                                <strong>{translate(`${I18N_BASE_PATH}.name`)}</strong>
                                <p>{lawyers.name || '-'}</p>
                              </Col>
                              <Col span={8} className="col-ant mrg-btm-5">
                                <strong>{translate(`${I18N_BASE_PATH}.cpf`)}</strong>
                                <p>{lawyers.cpf || '-'}</p>
                              </Col>
                            </Row>
                          ))
                        ) : (
                          <>-</>
                        )}
                        <Divider />
                      </div>
                    ))
                  ) : (
                    <Row>
                      <Col span={24} className="col-ant mrg-left-20 mrg-btm-5">
                        <strong>{translate(`${I18N_BASE_PATH}.empty`)}</strong>
                      </Col>
                    </Row>
                  )}
                </DataSourceCardCollapsePanel>
                {(process.courtType || process.authorType || process.authorParty) && (
                  <Row className="processes-row-tags reset-mrg mrg-top-20">
                    {(process.courtType || process.lawBranch) && (
                      <ProcessTag
                        title={translate(
                          courtInfos(process.courtType || process.lawBranch)?.name
                        )}
                        type={courtInfos(process.courtType || process.lawBranch)?.type}
                      />
                    )}
                    {process.authorType && (
                      <ProcessTag
                        title={translate(statusInfos(process.authorType)?.name)}
                        type={statusInfos(process.authorType)?.type}
                      />
                    )}
                    {process.authorParty && hasPersonProcesses && (
                      <ProcessTag
                        title={translate(partyInfos(process.authorParty).name)}
                        type={partyInfos(process.authorParty).type}
                      />
                    )}
                  </Row>
                )}
              </DataSourceCardCollapsePanel>
            </Col>
          ))}
      </Row>
      {!toPrint && displayValue && data?.length > displayValue && (
        <Row>
          <ViewMore
            type="link"
            title={translate(`${I18N_BASE_PATH}.viewMore.title`, { value: data?.length })}
            onClick={() => openAllProcessesModal()}
          />
        </Row>
      )}
    </div>
  );
};

export default ProcessesList;
