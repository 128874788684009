import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col, Button, Divider } from 'antd';

import EmptyState from 'components/EmptyState';
import EmptyExecutionState from 'components/EmptyExecutionState';

import ContactAndRelationCard from 'pages/private/Profiles/components/ContactAndRelationCard';

import { useExecution } from 'hooks/execution';
import BasicInfosCard from '../BasicInfosCard';

const ContactContent = ({ dataSources, toPrint }) => {
  const { t: translate } = useTranslation();

  const [isPhonesPanelOpened, setIsPhonesPanelOpened] = useState(false);
  const [isEmailsPanelOpened, setIsEmailsPanelOpened] = useState(false);

  const { execution } = useExecution();

  const phones = useMemo(() => {
    if (!execution) return undefined;

    const pfPhones = execution?.sections?.pfPhones;

    if (!pfPhones) return 'no-recurrence';
    if (
      pfPhones &&
      pfPhones?.statusCode !== '00' &&
      pfPhones?.statusCode !== '01' &&
      !pfPhones?.available
    )
      return 'query-failed';
    if (pfPhones && pfPhones?.data?.length <= 0) return 'no-data';

    return pfPhones?.data || [];
  }, [execution]);

  const emails = useMemo(() => {
    if (!execution) return undefined;

    const pfEmails = execution?.sections?.pfEmails;

    if (
      pfEmails &&
      pfEmails?.statusCode !== '01' &&
      pfEmails?.statusCode !== '00' &&
      !pfEmails?.available
    )
      return 'query-failed';
    if (pfEmails && pfEmails?.data?.length <= 0) return 'no-data';

    return pfEmails?.data || [];
  }, [execution]);

  const onClickHandler = useCallback((param) => {
    if (param === 'phones') {
      setIsPhonesPanelOpened((oldState) => !oldState);
    }
    if (param === 'emails') {
      setIsEmailsPanelOpened((oldState) => !oldState);
    }
  }, []);

  const parsedPhonesList = useMemo(() => {
    if (!Array.isArray(phones) || !phones?.length > 0) return <></>;

    const el = [];
    const _size = phones?.length;

    if (_size) {
      for (let i = 0; i < _size; i += 3) {
        el.push(
          <Row className="contact-data-row">
            {phones?.slice(i, i + 3)?.map((item) => (
              <ContactAndRelationCard
                key={item?.number}
                span={8}
                type={item?.type}
                content={`(${item?.areaCode || '-'}) ${item?.number || '-'}`}
                className="pdd-vertical-10 pdd-horizontal-0"
              />
            ))}
          </Row>
        );
      }
    }

    if (el.length > 0) {
      const parsedEl = isPhonesPanelOpened || toPrint ? el : el.splice(0, 1);
      return parsedEl;
    }

    return <>-</>;
  }, [phones, isPhonesPanelOpened, toPrint]);

  const parsedEmailsList = useMemo(() => {
    if (!Array.isArray(emails) || !emails?.length > 0) return <></>;

    const el = [];
    const _size = emails?.length;

    if (_size) {
      for (let i = 0; i < _size; i += 2) {
        el.push(
          <Row className="contact-data-row">
            {emails?.slice(i, i + 2)?.map((item) => (
              <ContactAndRelationCard
                key={item?.number}
                span={12}
                content={item?.email || '-'}
                type={item?.type}
                className="pdd-vertical-10 pdd-horizontal-0"
              />
            ))}
          </Row>
        );
      }
    }

    if (el.length > 0) {
      const parsedEl = isEmailsPanelOpened || toPrint ? el : el.splice(0, 1);
      return parsedEl;
    }

    return <>-</>;
  }, [emails, isEmailsPanelOpened, toPrint]);

  return (
    <BasicInfosCard
      icon="caf-ic_contact_"
      title={translate(
        'pages.private.executions.components.dataSourceCards.basicInfos.components.person.contactContent.contactContent.basicInfosCard.title'
      )}
      dataIndex="contacts"
      customQuery={false}
      customId="basic-infos-contacts"
    >
      <Row className="contact-data-container gx-w-100">
        {dataSources.includes('pfPhones') && (
          <Col span={24} className="contact-data-col">
            <p className="contact-section-title">
              {translate(
                'pages.private.executions.components.dataSourceCards.basicInfos.components.person.contactContent.contactContent.basicInfosCard.phones.title'
              )}
            </p>

            {phones === 'no-data' && (
              <EmptyState
                type="empty-success"
                description={translate(
                  'pages.private.executions.components.dataSourceCards.basicInfos.components.person.contactContent.contactContent.basicInfosCard.phones.emptyState.description'
                )}
              />
            )}

            {phones === 'query-failed' && (
              <EmptyExecutionState
                statusCode={execution?.sections?.pfPhones?.statusCode}
              />
            )}

            {parsedPhonesList}

            {!toPrint && Array.isArray(phones) && phones.length > 3 && (
              <Row className="mrg-top-5 reset-mrg">
                <Button
                  type="link"
                  className="no-mrg no-pdd"
                  onClick={() => onClickHandler('phones')}
                >
                  <i
                    className={`mrg-right-5
                    caf-ic_${isPhonesPanelOpened ? 'close' : 'plus'}`}
                  />
                  Outros telefones ({phones?.length - 3})
                </Button>
              </Row>
            )}

            {dataSources.includes('pfEmails') && <Divider />}
          </Col>
        )}

        {dataSources.includes('pfEmails') && (
          <Col span={24} className="contact-data-col">
            <p className="contact-section-title">
              {translate(
                'pages.private.executions.components.dataSourceCards.basicInfos.components.person.contactContent.contactContent.basicInfosCard.emails.title'
              )}
            </p>

            {emails === 'no-data' && (
              <EmptyState
                type="empty-success"
                description={translate(
                  'pages.private.executions.components.dataSourceCards.basicInfos.components.person.contactContent.contactContent.basicInfosCard.emails.emptyState.description'
                )}
              />
            )}

            {emails === 'query-failed' && (
              <EmptyExecutionState
                statusCode={execution?.sections?.pfEmails?.statusCode}
              />
            )}

            {parsedEmailsList}

            {!toPrint && Array.isArray(emails) && emails.length > 2 && (
              <Row className="mrg-top-5 reset-mrg">
                <Button
                  type="link"
                  className="no-mrg no-pdd"
                  onClick={() => onClickHandler('emails')}
                >
                  <i
                    className={`mrg-right-5
                    caf-ic_${isEmailsPanelOpened ? 'close' : 'plus'}`}
                  />
                  Outros emails ({emails?.length - 2})
                </Button>
              </Row>
            )}
          </Col>
        )}

        {!dataSources.includes('pfEmails') &&
          !dataSources.includes('pfPhones') &&
          emails !== 'no-data' &&
          emails !== 'query-failed' && (
            <Col span={24} className="contact-data-col">
              -
            </Col>
          )}
      </Row>
    </BasicInfosCard>
  );
};

ContactContent.propTypes = {
  person: PropTypes.shape({
    basicData: PropTypes.shape({
      phoneNumber: PropTypes.string,
      companyEmail: PropTypes.string,
    }),
    sections: PropTypes.shape({
      pfPhones: PropTypes.shape({
        data: PropTypes.shape({}),
        statusCode: PropTypes.string,
        lastConsultation: PropTypes.string,
      }),

      pfEmails: PropTypes.shape({
        data: PropTypes.shape({}),
        statusCode: PropTypes.string,
        lastConsultation: PropTypes.string,
      }),
    }),
    status: PropTypes.string,
  }),
  dataSources: PropTypes.arrayOf(PropTypes.string),
  toPrint: PropTypes.bool,
};

ContactContent.defaultProps = {
  person: undefined,
  dataSources: [],
  toPrint: false,
};

export default ContactContent;
