import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Radio } from 'antd';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;

const ExecutionFraudFilter = ({ value, title, onChange }) => {
  const { t: translate } = useTranslation();

  return (
    <Collapse
      ghost
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <div>
          <i className={isActive ? 'caf-ic_arrow_up' : 'caf-ic_arrow_down'} />
        </div>
      )}
    >
      <Panel header={title} key="fraud">
        <Radio.Group
          defaultValue=""
          value={value || ''}
          onChange={(e) => onChange(e.target.value)}
        >
          <Radio value="">
            {translate('components.filters.fraudFilter.collapse.panel.radioGroup.all')}
          </Radio>
          <Radio value="death">
            {translate('components.filters.fraudFilter.collapse.panel.radioGroup.death')}
          </Radio>
          <Radio value="documentscopy">
            {translate(
              'components.filters.fraudFilter.collapse.panel.radioGroup.documentscopy'
            )}
          </Radio>
          <Radio value="officialData">
            {translate(
              'components.filters.fraudFilter.collapse.panel.radioGroup.officialData'
            )}
          </Radio>
          <Radio value="false">
            {translate('components.filters.fraudFilter.collapse.panel.radioGroup.false')}
          </Radio>
        </Radio.Group>
      </Panel>
    </Collapse>
  );
};

ExecutionFraudFilter.propTypes = {
  value: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ExecutionFraudFilter;
