const I18N_BASE_PATH = 'pages.private.routines.detail.config';

export const options = [
  {
    field: 'createdAt',
    fieldName: `${I18N_BASE_PATH}.fields.createdAt`,
    valueType: 'date',
    operators: [
      {
        operator: 'greater',
        operatorName: `${I18N_BASE_PATH}.operators.greater`,
      },
      {
        operator: 'greater_equal',
        operatorName: `${I18N_BASE_PATH}.operators.greater_equal`,
      },
      {
        operator: 'equal',
        operatorName: `${I18N_BASE_PATH}.operators.equal`,
      },
      {
        operator: 'not_equal',
        operatorName: `${I18N_BASE_PATH}.operators.not_equal`,
      },
      {
        operator: 'less_than',
        operatorName: `${I18N_BASE_PATH}.operators.less_than`,
      },
      {
        operator: 'less_than_equal',
        operatorName: `${I18N_BASE_PATH}.operators.less_than_equal`,
      },
    ],
  },
  {
    field: 'status',
    fieldName: `${I18N_BASE_PATH}.fields.status`,
    valueType: 'select',
    operators: [
      {
        operator: 'not_equal',
        operatorName: `${I18N_BASE_PATH}.operators.not_equal`,
      },
      {
        operator: 'equal',
        operatorName: `${I18N_BASE_PATH}.operators.equal`,
      },
    ],
    values: [
      {
        value: 'APPROVED',
        valueName: `${I18N_BASE_PATH}.values.approved`,
      },
      {
        value: 'PROCESSING',
        valueName: `${I18N_BASE_PATH}.values.processing`,
      },
      {
        value: 'REPROVED',
        valueName: `${I18N_BASE_PATH}.values.reproved`,
      },
    ],
  },
  {
    field: 'tags',
    fieldName: `${I18N_BASE_PATH}.fields.tags`,
    valueType: 'tags',
    operators: [
      {
        operator: 'not_includes',
        operatorName: `${I18N_BASE_PATH}.operators.not_includes`,
      },
      {
        operator: 'includes',
        operatorName: `${I18N_BASE_PATH}.operators.includes`,
      },
    ],
  },
  {
    field: 'transactionTemplates',
    fieldName: `${I18N_BASE_PATH}.fields.profileTemplates`,
    valueType: 'profile-templates',
    operators: [
      {
        operator: 'not_includes',
        operatorName: `${I18N_BASE_PATH}.operators.not_includes`,
      },
      {
        operator: 'includes',
        operatorName: `${I18N_BASE_PATH}.operators.includes`,
      },
    ],
  },
];

export const newRoutineDefault = {
  active: true,
  filters: [
    {
      field: 'status',
      operator: null,
      value: null,
    },
  ],
  frequency: {},
  services: [],
  validations: [],
};
