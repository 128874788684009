import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Modal, Form, Checkbox, Select } from 'antd';
import { useFetch } from 'services/hooks';
import InputMask from 'components/Form/InputMask';
import Button from 'components/Button';
import PageTitle from 'components/PageTitle';

const I18N_BASE_PATH =
  'pages.private.restrictions.restrictionList.components.DocumentCreateModal.index';

const CreateDocument = ({ refreshList }) => {
  const { t: translate } = useTranslation();

  const [form] = Form.useForm();
  const { post, put, loading } = useFetch();
  const [modalVisible, setModalVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [register, setRegister] = useState({});
  const [documentType, setDocumentType] = useState(form?.getFieldsValue()?.type || 'CPF');
  const [blockDocument, setBlockDocument] = useState(form?.getFieldsValue?.isBlocked);

  const clearFields = useCallback(() => {
    form.setFieldsValue({});
    form.resetFields();
  }, [form]);

  const closeModal = useCallback(() => {
    setRegister({});
    clearFields();
    setModalVisible(false);
  }, [clearFields]);

  const openModal = useCallback(
    ({ isNew, data } = {}) => {
      setIsEdit(!isNew);

      if (!isNew) {
        setRegister(data);
        setDocumentType(data?.type);
        setBlockDocument(!!data?.isBlocked);

        clearFields();
        form.setFieldsValue({
          type: data?.type || 'CPF',
          documentNumber: data?.documentNumber || '',
          isBlocked: !!data?.isBlocked,
        });
      }

      setModalVisible(true);
    },
    [form, clearFields]
  );

  const DocumentInput = useMemo(
    () =>
      documentType === 'CNPJ' ? (
        <InputMask mask="99.999.999/9999-99" />
      ) : (
        <InputMask mask="999.999.999-99" />
      ),
    [documentType]
  );

  const handleSubmit = useCallback(async () => {
    const values = await form.validateFields();
    const { documentNumber, type } = values;

    const payload = {
      documentNumber,
      isBlocked: !!blockDocument,
      documentType: type,
    };

    if (isEdit) {
      await put({
        url: `/blocked-documents/${register?.id}`,
        payload,
      });
    } else {
      await post({
        url: `/blocked-documents`,
        payload,
      });
    }

    if (refreshList?.current) refreshList.current();
    closeModal();
  }, [form, blockDocument, isEdit, refreshList, closeModal, put, register, post]);

  const BlockedDocumentsCreateModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeModal}
        footer={null}
        width={589}
        destroyOnClose
      >
        <Row>
          <Col span={24}>
            <PageTitle
              title={
                isEdit
                  ? translate(`${I18N_BASE_PATH}.formContent.pageTitle.title.edit`)
                  : translate(`${I18N_BASE_PATH}.formContent.pageTitle.title.create`)
              }
              subtitle={
                isEdit
                  ? translate(`${I18N_BASE_PATH}.formContent.pageTitle.subtitle.edit`)
                  : translate(`${I18N_BASE_PATH}.formContent.pageTitle.subtitle.create`)
              }
            />
          </Col>

          <Col span={24} className="mrg-vertical-20">
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              onFinish={handleSubmit}
            >
              <Form.Item
                label={translate(`${I18N_BASE_PATH}.formContent.form.documentType.label`)}
                name="type"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="CPF/CNPJ"
                  onChange={(value) => setDocumentType(value)}
                >
                  <Select.Option value="CPF">CPF</Select.Option>
                  <Select.Option value="CNPJ">CNPJ</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={translate(
                  `${I18N_BASE_PATH}.formContent.form.documentNumber.label`
                )}
                name="documentNumber"
                rules={[{ required: true }]}
              >
                {DocumentInput}
              </Form.Item>
              <Form.Item name="isBlocked">
                <Checkbox
                  defaultChecked={!!register?.isBlocked || !isEdit}
                  onChange={(e) => setBlockDocument(e?.target?.checked)}
                >
                  <span className="text-bold">
                    {translate(`${I18N_BASE_PATH}.formContent.form.isBlocked.label`)}
                  </span>
                </Checkbox>
              </Form.Item>
            </Form>
          </Col>

          <Col span={24} className="flex end">
            <Button type="button" htmlType="button" onClick={closeModal}>
              {translate(`${I18N_BASE_PATH}.formContent.buttons.cancel`)}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => handleSubmit()}
              disabled={loading}
            >
              {translate(`${I18N_BASE_PATH}.formContent.buttons.confirm`)}
            </Button>
          </Col>
        </Row>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      isEdit,
      translate,
      form,
      handleSubmit,
      DocumentInput,
      register,
      loading,
    ]
  );

  return { openModal, closeModal, BlockedDocumentsCreateModal };
};

export default CreateDocument;
