import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col, Button, Collapse, Tag } from 'antd';

import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';

import EmptyState from 'components/EmptyState';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import { useExecution } from 'hooks/execution';
import QSAPartnerDetails from './QSAPartnerDetails';
import SerasaExperianPartnerDetails from './SerasaExperianPartnerDetails';
import QSAParametersPartnerDetails from './QSAParametersPartnerDetails';
import BasicInfosCard from '../BasicInfosCard';
import AttorneyDetails from './AttorneyDetails';

const { Panel } = Collapse;

const QSAInfosContent = ({ toPrint }) => {
  const { t: translate } = useTranslation();

  const { execution } = useExecution();

  const [isPanelOpened, setIsPanelOpened] = useState(false);

  const QSAItems = useMemo(() => execution?.sections?.pjData?.data?.QSA, [execution]);

  const attorneyItems = useMemo(() => {
    const attorneys = execution.parameters?.qsa?.filter(
      (qsa) => qsa?.attorney?.isAttorney
    );
    return attorneys || [];
  }, [execution]);

  const serasaExperianItems = useMemo(
    () => execution?.sections?.pjSerasaExperian?.data?.partners,
    [execution]
  );
  const QSAParametersItems = useMemo(() => execution?.parameters?.partners, [execution]);

  const QSAIsProcessing = useMemo(
    () => verifySourcesProcessing(execution?.sections?.pjData?.data, 'QSA'),
    [execution]
  );
  const serasaExperianIsProcessing = useMemo(
    () =>
      verifySourcesProcessing(
        execution?.sections?.pjSerasaExperian?.data,
        'serasaExperian'
      ),
    [execution]
  );

  const QSAParametersProcessing = useMemo(
    () =>
      verifySourcesProcessing(
        execution?.sections?.pjSerasaExperian?.data,
        'serasaExperian'
      ),
    [execution]
  );

  const QSAPartnersCount = useMemo(() => {
    if (QSAItems?.length === 1) {
      return translate(
        'pages.private.executions.components.dataSourceCards.basicInfos.components.company.qsaInfosContent.QSAPartnersCount.oneItem'
      );
    }
    if (QSAItems?.length > 1) {
      return translate(
        'pages.private.executions.components.dataSourceCards.basicInfos.components.company.qsaInfosContent.QSAPartnersCount.moreThanOneItem',
        { value: QSAItems?.length }
      );
    }

    return <></>;
  }, [QSAItems, translate]);

  const serasaExperianPartnersCount = useMemo(() => {
    if (serasaExperianItems?.length === 1) {
      return translate(
        'pages.private.executions.components.dataSourceCards.basicInfos.components.company.qsaInfosContent.serasaExperianPartnersCount.oneItem'
      );
    }

    if (serasaExperianItems?.length > 1) {
      return translate(
        'pages.private.executions.components.dataSourceCards.basicInfos.components.company.qsaInfosContent.serasaExperianPartnersCount.moreThanOneItem',
        { value: serasaExperianItems?.length }
      );
    }

    return <></>;
  }, [serasaExperianItems, translate]);

  const QSAParametersPartnersCount = useMemo(() => {
    if (QSAParametersItems?.length === 1) {
      return translate(
        'pages.private.executions.components.dataSourceCards.basicInfos.components.company.qsaInfosContent.QSAParametersPartnersCount.oneItem'
      );
    }

    if (QSAParametersItems?.length > 1) {
      return translate(
        'pages.private.executions.components.dataSourceCards.basicInfos.components.company.qsaInfosContent.QSAParametersPartnersCount.moreThanOneItem',
        { value: QSAParametersItems?.length }
      );
    }

    return <></>;
  }, [QSAParametersItems, translate]);

  const onClickHandler = useCallback(() => {
    setIsPanelOpened((oldState) => !oldState);
  }, []);

  const renderPartnerToPrint = (partner) => (
    <div key={`${partner.name}`} className="legal-representative-container">
      <span>
        {partner.name} ({partner.function}){' '}
      </span>
      {partner.isLegalRepresentative === true && (
        <Tag className="legal-representative-tag">
          {translate(
            'pages.private.executions.components.dataSourceCards.basicInfos.components.company.qsaInfosContent.renderPartnerToPrint.legalRepresentative'
          )}
        </Tag>
      )}
    </div>
  );

  return (
    <BasicInfosCard
      customId="execution-qsa-content"
      icon="caf-ic_user_active"
      id="execution-qsa-content"
      title={translate(
        'pages.private.executions.components.dataSourceCards.basicInfos.components.company.qsaInfosContent.qsaInfosContent.basicInfosCard.title'
      )}
      data={{
        ...(execution?.sections?.pjData?.data?.QSA || {}),
        status: execution?.sections?.pjData?.data?.status,
      }}
      dataIndex="items"
    >
      <Row>
        <ColumnTitleValue
          span={24}
          className="mrg-btm-30"
          title={translate(
            'pages.private.executions.components.dataSourceCards.basicInfos.components.company.qsaInfosContent.qsaInfosContent.basicInfosCard.columnTitleValue.QSAPartners.title'
          )}
          value={QSAPartnersCount}
        >
          {!QSAItems && QSAIsProcessing && <EmptyState type="processing" />}

          {!QSAItems && !QSAIsProcessing && <EmptyState type="waiting_consult" />}

          {QSAItems?.length > 0 && (
            <>
              <div className="executions-card-partners-content">
                {!toPrint &&
                  QSAItems?.slice(0, 6)?.map((partner) => (
                    <QSAPartnerDetails partner={partner} />
                  ))}

                {!toPrint && QSAItems.length > 6 && (
                  <Collapse
                    activeKey={Number(isPanelOpened)}
                    className="ghost-collapse"
                    ghost
                  >
                    <Panel key={1} className="mrg-top-10">
                      {QSAItems?.slice(6, QSAItems.length)?.map((partner) => (
                        <QSAPartnerDetails partner={partner} />
                      ))}
                    </Panel>
                  </Collapse>
                )}

                {toPrint && QSAItems?.map((partner) => renderPartnerToPrint(partner))}
              </div>

              {!toPrint && QSAItems.length > 6 && (
                <Col span={24}>
                  <Button type="link" className="no-mrg no-pdd" onClick={onClickHandler}>
                    <i
                      className={`caf-ic_${isPanelOpened ? 'close' : 'plus'} mrg-right-5`}
                    />
                    {translate(
                      'pages.private.executions.components.dataSourceCards.basicInfos.components.company.qsaInfosContent.qsaInfosContent.basicInfosCard.columnTitleValue.QSAPartners.showMorePartners'
                    )}{' '}
                    ({QSAItems?.length - 6})
                  </Button>
                </Col>
              )}
            </>
          )}
        </ColumnTitleValue>
        {serasaExperianItems?.length > 0 && (
          <ColumnTitleValue
            span={24}
            title={translate(
              'pages.private.executions.components.dataSourceCards.basicInfos.components.company.qsaInfosContent.qsaInfosContent.basicInfosCard.columnTitleValue.serasaExperian.title'
            )}
            className="mrg-btm-30"
            value={serasaExperianPartnersCount}
          >
            {!serasaExperianItems && serasaExperianIsProcessing && (
              <EmptyState type="processing" />
            )}

            {!serasaExperianItems && !serasaExperianIsProcessing && (
              <EmptyState type="waiting_consult" />
            )}

            <div className="executions-card-partners-content">
              {!toPrint &&
                serasaExperianItems
                  ?.slice(0, 6)
                  ?.map((partner) => <SerasaExperianPartnerDetails partner={partner} />)}

              {!toPrint && serasaExperianItems.length > 6 && (
                <Collapse
                  activeKey={Number(isPanelOpened)}
                  className="ghost-collapse"
                  ghost
                >
                  <Panel key={1} className="mrg-top-10">
                    {serasaExperianItems
                      ?.slice(6, serasaExperianItems.length)
                      ?.map((partner) => (
                        <SerasaExperianPartnerDetails partner={partner} />
                      ))}
                  </Panel>
                </Collapse>
              )}
            </div>

            {!toPrint && serasaExperianItems.length > 6 && (
              <Col span={24}>
                <Button type="link" className="no-mrg no-pdd" onClick={onClickHandler}>
                  <i
                    className={`caf-ic_${isPanelOpened ? 'close' : 'plus'} mrg-right-5`}
                  />
                  {translate(
                    'pages.private.executions.components.dataSourceCards.basicInfos.components.company.qsaInfosContent.qsaInfosContent.basicInfosCard.columnTitleValue.serasaExperian.showMorePartners'
                  )}{' '}
                  ({serasaExperianItems?.length - 6})
                </Button>
              </Col>
            )}
          </ColumnTitleValue>
        )}
        {QSAParametersItems?.length > 0 && (
          <ColumnTitleValue
            span={24}
            title={translate(
              'pages.private.executions.components.dataSourceCards.basicInfos.components.company.qsaInfosContent.qsaInfosContent.basicInfosCard.columnTitleValue.QSAParameters.title'
            )}
            className="mrg-btm-30"
            value={QSAParametersPartnersCount}
          >
            {!QSAParametersItems && QSAParametersProcessing && (
              <EmptyState type="processing" />
            )}

            {!QSAParametersItems && !QSAParametersProcessing && (
              <EmptyState type="waiting_consult" />
            )}

            <div className="card-partners-content">
              {!toPrint &&
                QSAParametersItems?.slice(0, 6)?.map((partner) => (
                  <QSAParametersPartnerDetails partner={partner} />
                ))}

              {!toPrint && QSAParametersItems.length > 6 && (
                <Collapse
                  activeKey={Number(isPanelOpened)}
                  className="ghost-collapse"
                  ghost
                >
                  <Panel key={1} className="mrg-top-10">
                    {QSAParametersItems?.slice(6, QSAParametersItems.length)?.map(
                      (partner) => (
                        <QSAParametersPartnerDetails partner={partner} />
                      )
                    )}
                  </Panel>
                </Collapse>
              )}
            </div>

            {!toPrint && QSAParametersItems.length > 6 && (
              <Col span={24}>
                <Button type="link" className="no-mrg no-pdd" onClick={onClickHandler}>
                  <i
                    className={`caf-ic_${isPanelOpened ? 'close' : 'plus'} mrg-right-5`}
                  />
                  {translate(
                    'pages.private.executions.components.dataSourceCards.basicInfos.components.company.qsaInfosContent.qsaInfosContent.basicInfosCard.columnTitleValue.QSAParameters.showMorePartners'
                  )}{' '}
                  ({QSAParametersItems?.length - 6})
                </Button>
              </Col>
            )}
          </ColumnTitleValue>
        )}
      </Row>
      {!!attorneyItems.length && (
        <Row>
          <ColumnTitleValue
            span={24}
            className="mrg-btm-30"
            title={translate(
              'pages.private.executions.components.dataSourceCards.basicInfos.components.company.qsaInfosContent.qsaInfosContent.basicInfosCard.attorney'
            )}
          >
            {attorneyItems?.map((attorney) => {
              return <AttorneyDetails partner={attorney} />;
            })}
          </ColumnTitleValue>
        </Row>
      )}
    </BasicInfosCard>
  );
};

QSAInfosContent.propTypes = {
  company: PropTypes.shape({
    sections: PropTypes.shape({
      pjData: PropTypes.shape({
        data: PropTypes.shape({
          QSA: PropTypes.arrayOf(PropTypes.shape({})),
          status: PropTypes.string,
        }),
      }),
      pjSerasaExperian: PropTypes.shape({
        data: PropTypes.shape({
          partners: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      }),
    }),
  }),
  toPrint: PropTypes.bool,
};

QSAInfosContent.defaultProps = {
  company: undefined,
  toPrint: false,
};

export default QSAInfosContent;
