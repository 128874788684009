/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { toMaskedCpf } from 'utils/formatters';

import PageTitle from 'components/PageTitle';

import SourcesList from '../../../Lists/SourcesList';

import './styles.less';

const OwnersItems = ({ data, lastConsultation, toPrint, toPrintDetails }) => {
  const { t: translate } = useTranslation();

  const customTitle = useMemo(() => {
    return toPrint
      ? translate(
          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.owners.ownersItems.index.customTitle.hasToPrint'
        )
      : translate(
          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.owners.ownersItems.index.customTitle.hasNotToPrint'
        );
  }, [toPrint, translate]);

  return (
    <div id="owners-items">
      <PageTitle title={customTitle} subtitle={toMaskedCpf(data?.cpf) || '-'} />

      <SourcesList
        personalInfo
        data={data}
        lastConsultationPersonal={lastConsultation}
        toPrint={toPrint}
        toPrintDetails={toPrintDetails}
      />
    </div>
  );
};

export default OwnersItems;
