import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import ChartTooltip from 'components/ChartTooltip';

import countries from './countries.json';
import brStates from './br-states.json';

import './styles.less';

const MapChart = (props) => {
  const { highlighted, national } = props;

  const [coords, setCoords] = useState();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const highlightedIds = useMemo(() => highlighted.map(({ id }) => id), [highlighted]);

  const handleMouseEnter = (event, geo) => {
    if (highlightedIds.includes(geo.id)) {
      setCoords({
        x: event.nativeEvent.layerX,
        y: event.nativeEvent.layerY,
        name: geo.properties.name,
        value: highlighted.find(({ id }) => id === geo.id).value,
      });
      setTooltipVisible(true);
    }
  };

  const handleMouseLeave = (event) => {
    setCoords({
      x: event.nativeEvent.layerX,
      y: event.nativeEvent.layerY,
    });
    setTooltipVisible(false);
  };

  return (
    <>
      <ComposableMap
        className="map"
        projectionConfig={{
          center: national ? [-54.5, -14.5] : [0, 0],
          scale: national ? 600 : 0,
        }}
        width={800}
        height={500}
      >
        <Geographies geography={national ? brStates : countries}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const isHighlighted = highlightedIds.includes(geo.id);
              return (
                <Geography
                  onMouseEnter={(e) => {
                    handleMouseEnter(e, geo);
                  }}
                  className="geo"
                  key={geo.rsmKey}
                  onMouseLeave={handleMouseLeave}
                  geography={geo}
                  fill={isHighlighted ? '#34D690' : '#F4F4F4'}
                  stroke="#444444"
                  strokeWidth={1.5}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
      <div
        tabIndex={-1}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          pointerEvents: 'none',
          zIndex: 100,
          visibility: tooltipVisible ? 'visible' : 'hidden',
          transform: `translate(${coords?.x ?? 0}px, ${coords?.y ?? 0}px)`,
          transition: 'all 0.3s ease-out',
        }}
      >
        {coords && tooltipVisible && (
          <ChartTooltip subtitle={`${coords.name}: ${coords.value}`} />
        )}
      </div>
    </>
  );
};

MapChart.propTypes = {
  highlighted: PropTypes.arrayOf(PropTypes.string).isRequired,
  national: PropTypes.bool,
};

MapChart.defaultProps = {
  national: false,
};

export default MapChart;
