/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { Row, Col } from 'antd';

import EmptyState from 'components/EmptyState';
import EmptyExecutionState from 'components/EmptyExecutionState';
import TitleDivider from 'components/Divider/TitleDivider';
import DottedLabel from 'components/DottedLabel';

import { nationalList, internationalList } from '../../utils/sanctionsDetails';

import SourceCard from '../../components/Sources/SourcesCard';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.restrictiveLists.lists.sourcesList.index';

const SourcesList = ({ source = {}, personalInfo, toPrint = false, toPrintDetails }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate, i18nNumberToFormattedString } = useI18nFormatters();

  const parsedData = useMemo(() => source.data, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const querySucceeded = useMemo(
    () =>
      source?.statusCode !== '02' &&
      (source?.statusCode === '01' ||
        source?.available === true ||
        Object.keys(source?.data || {}).length ||
        (Array.isArray(source?.data) && source?.data?.length)),
    [source]
  );

  const mappedData = useMemo(() => {
    if (!parsedData) return {};

    const nationalSources = {};
    const internationalSources = {};

    const sanctionsHistory = parsedData?.sanctionsHistory || {};

    Object.keys(sanctionsHistory).forEach((key) => {
      const value = sanctionsHistory[key];
      const parsedValue = { [key]: value };

      if (Object.keys(sanctionsHistory).includes('INDEFINIDO')) {
        sanctionsHistory.OTHERS = sanctionsHistory.INDEFINIDO;
        delete sanctionsHistory.INDEFINIDO;
      }

      if (nationalList.includes(key)) {
        Object.assign(nationalSources, parsedValue);
      }
      if (internationalList.includes(key)) {
        Object.assign(internationalSources, parsedValue);
      }
    });

    return { nationalSources, internationalSources };
  }, [parsedData]);

  const customSourcesTop = useMemo(() => {
    if (!personalInfo) {
      if (!source) return '0px';
      return '25px';
    }
    return '5px';
  }, [personalInfo, source]);

  const parsedPEPList = useMemo(() => {
    if (!parsedData || !parsedData?.pepHistory?.length > 0) return <></>;

    const el = [];
    const _size = parsedData?.pepHistory?.length;

    if (_size) {
      for (let i = 0; i < _size; i += 2) {
        el.push(
          <Row className="sources-list-row reset-mrg">
            {parsedData?.pepHistory?.slice(i, i + 2)?.map((sources) => (
              <Col key={sources} span={12} className="sources-list-data">
                <SourceCard
                  pep
                  data={sources}
                  pepSource={parsedData?.pepHistory}
                  toPrint={toPrint}
                  toPrintDetails={toPrintDetails}
                />
              </Col>
            ))}
          </Row>
        );
      }
    }
    return el.length > 0 ? el : <></>;
  }, [parsedData, toPrint, toPrintDetails]);

  const parsedNationalList = useMemo(() => {
    if (!parsedData) return <></>;

    const el = [];
    const _size = nationalList.length;

    if (_size) {
      for (let i = 0; i < _size; i += 2) {
        el.push(
          <Row className="sources-list-row reset-mrg">
            {nationalList?.slice(i, i + 2)?.map((sources) => (
              <Col key={sources} span={12} className="sources-list-data">
                <SourceCard
                  data={sources}
                  others={sources === 'OTHERS'}
                  sanctionsSource={parsedData?.sanctionsHistory[sources]}
                  lastConsultation={parsedData?.lastConsultation?.date}
                  national
                  toPrint={toPrint}
                  toPrintDetails={toPrintDetails}
                />
              </Col>
            ))}
          </Row>
        );
      }
    }
    return el.length > 0 ? (
      el
    ) : (
      <EmptyState
        type="empty-success"
        description={translate(
          `${I18N_BASE_PATH}.parsedNationalList.emptyState.description`
        )}
        className="mrg-btm-25 mrg-top-35"
      />
    );
  }, [parsedData, toPrint, toPrintDetails, translate]);

  const parsedInternationalList = useMemo(() => {
    if (!parsedData) return <></>;

    const el = [];
    const _size = Object.keys(mappedData?.internationalSources).length;

    if (_size) {
      for (let i = 0; i < _size; i += 2) {
        el.push(
          <Row className="sources-list-row reset-mrg">
            {Object.keys(mappedData?.internationalSources)
              ?.slice(i, i + 2)
              ?.map((sources) => (
                <Col key={sources} span={12} className="sources-list-data">
                  <SourceCard
                    data={sources}
                    others={sources === 'OTHERS'}
                    sanctionsSource={parsedData?.sanctionsHistory[sources]}
                    lastConsultation={parsedData?.lastConsultation?.date}
                    international
                    toPrint={toPrint}
                    toPrintDetails={toPrintDetails}
                  />
                </Col>
              ))}
          </Row>
        );
      }
    }
    return el.length > 0 ? (
      el
    ) : (
      <EmptyState
        type="empty-success"
        description={translate(
          `${I18N_BASE_PATH}.parsedInternationalList.emptyState.description`
        )}
        className="mrg-btm-25 mrg-top-35"
      />
    );
  }, [mappedData, parsedData, toPrint, toPrintDetails, translate]);

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_lawier"
      title={translate(`${I18N_BASE_PATH}.sourcesList.title`)}
      customId="restrictiveLists"
      toPrint={toPrint}
    >
      <Row id="sources-list-container">
        {querySucceeded ? (
          <>
            <Row className="mrg-left-10">
              <Col span={12} className="mrg-btm-10">
                <DottedLabel
                  type={parsedData?.isCurrentlyPEP ? 'danger' : 'primary'}
                  description={
                    parsedData?.isCurrentlyPEP
                      ? translate(`${I18N_BASE_PATH}.sourcesList.isCurrentlyPEP.true`)
                      : translate(`${I18N_BASE_PATH}.sourcesList.isCurrentlyPEP.false`)
                  }
                />
                {parsedData?.isCurrentlyPEP ? (
                  <span>
                    {translate(
                      `${I18N_BASE_PATH}.sourcesList.isCurrentlyPEP.description.true`
                    )}
                  </span>
                ) : (
                  <span>
                    {translate(
                      `${I18N_BASE_PATH}.sourcesList.isCurrentlyPEP.description.false`
                    )}
                  </span>
                )}
              </Col>
              <Col span={12} className="mrg-btm-10">
                <DottedLabel
                  type={
                    parsedData?.totalSanctions && parsedData?.totalSanctions > 0
                      ? 'danger'
                      : 'primary'
                  }
                  description={
                    parsedData?.totalSanctions && parsedData?.totalSanctions > 0
                      ? translate(
                          `${I18N_BASE_PATH}.sourcesList.isCurrentlySanctioned.true`
                        )
                      : translate(
                          `${I18N_BASE_PATH}.sourcesList.isCurrentlySanctioned.false`
                        )
                  }
                />
                {parsedData?.totalSanctions && parsedData?.totalSanctions > 0 ? (
                  <span>
                    {translate(
                      `${I18N_BASE_PATH}.sourcesList.isCurrentlySanctioned.description.true`
                    )}
                  </span>
                ) : (
                  <span>
                    {translate(
                      `${I18N_BASE_PATH}.sourcesList.isCurrentlySanctioned.description.false`
                    )}
                  </span>
                )}
              </Col>
              {parsedData?.isCurrentlyElectoralDonor && (
                <Col span={12} className="mrg-btm-10">
                  <DottedLabel
                    type={parsedData?.isCurrentlyElectoralDonor ? 'danger' : 'primary'}
                    description={
                      parsedData?.isCurrentlyElectoralDonor
                        ? translate(
                            `${I18N_BASE_PATH}.sourcesList.isCurrentlyElectoralDonor.true`
                          )
                        : translate(
                            `${I18N_BASE_PATH}.sourcesList.isCurrentlyElectoralDonor.false`
                          )
                    }
                  />
                </Col>
              )}
              {parsedData?.isHistoricalElectoralDonor && (
                <Col span={12} className="mrg-btm-10">
                  <DottedLabel
                    type={parsedData?.isHistoricalElectoralDonor ? 'danger' : 'primary'}
                    description={
                      parsedData?.isHistoricalElectoralDonor
                        ? translate(
                            `${I18N_BASE_PATH}.sourcesList.isHistoricalElectoralDonor.true`
                          )
                        : translate(
                            `${I18N_BASE_PATH}.sourcesList.isHistoricalElectoralDonor.false`
                          )
                    }
                  />
                </Col>
              )}
              {(parsedData?.totalElectoralDonations ||
                parsedData?.totalElectoralDonations > 0) && (
                <Col span={12} className="mrg-btm-10">
                  <span>
                    <strong>
                      {translate(`${I18N_BASE_PATH}.sourcesList.totalElectoralDonations`)}
                      :{' '}
                    </strong>
                    {parsedData?.totalElectoralDonations}
                  </span>
                </Col>
              )}
              {(parsedData?.totalElectoralDonationAmount ||
                parsedData?.totalElectoralDonationAmount > 0) && (
                <Col span={12} className="mrg-btm-10">
                  <span>
                    <strong>
                      {translate(
                        `${I18N_BASE_PATH}.sourcesList.totalElectoralDonationAmount`
                      )}
                      :{' '}
                    </strong>
                    {i18nNumberToFormattedString(
                      parsedData?.totalElectoralDonationAmount
                    )}
                  </span>
                </Col>
              )}
              {(parsedData?.totalElectoralDonationsLastTwoElections ||
                parsedData?.totalElectoralDonationsLastTwoElections > 0) && (
                <Col span={12} className="mrg-btm-10">
                  <span>
                    <strong>
                      {translate(
                        `${I18N_BASE_PATH}.sourcesList.totalElectoralDonationsLastTwoElections`
                      )}
                      :{' '}
                    </strong>
                    {parsedData?.totalElectoralDonationsLastTwoElections}
                  </span>
                </Col>
              )}
              {(parsedData?.totalElectoralDonationAmountLastTwoElections ||
                parsedData?.totalElectoralDonationAmountLastTwoElections > 0) && (
                <Col span={12} className="mrg-btm-10">
                  <span>
                    <strong>
                      {translate(
                        `${I18N_BASE_PATH}.sourcesList.totalElectoralDonationAmountLastTwoElections`
                      )}
                      :{' '}
                    </strong>
                    {i18nNumberToFormattedString(
                      parsedData?.totalElectoralDonationAmountLastTwoElections
                    )}
                  </span>
                </Col>
              )}
              {parsedData?.firstPEPOccurenceDate && (
                <Col span={12} className="mrg-btm-10">
                  <span>
                    <strong>
                      {translate(`${I18N_BASE_PATH}.sourcesList.firstPEPOccurenceDate`)}:{' '}
                    </strong>
                    {i18nFormatDate(parsedData?.firstPEPOccurenceDate, 1)}
                  </span>
                </Col>
              )}
              {parsedData?.lastPEPOccurenceDate && (
                <Col span={12} className="mrg-btm-10">
                  <span>
                    <strong>
                      {translate(`${I18N_BASE_PATH}.sourcesList.lastPEPOccurenceDate`)}:{' '}
                    </strong>
                    {i18nFormatDate(parsedData?.lastPEPOccurenceDate, 1)}
                  </span>
                </Col>
              )}
              {(parsedData?.lastYearPEPOccurence ||
                parsedData?.lastYearPEPOccurence > 0) && (
                <Col span={12} className="mrg-btm-10">
                  <span>
                    <strong>
                      {translate(`${I18N_BASE_PATH}.sourcesList.lastYearPEPOccurence`)}:{' '}
                    </strong>
                    {parsedData?.lastYearPEPOccurence}
                  </span>
                </Col>
              )}
              {(parsedData?.last3YearsPEPOccurence ||
                parsedData?.last3YearsPEPOccurence > 0) && (
                <Col span={12} className="mrg-btm-10">
                  <span>
                    <strong>
                      {translate(`${I18N_BASE_PATH}.sourcesList.last3YearsPEPOccurence`)}:{' '}
                    </strong>
                    {parsedData?.last3YearsPEPOccurence}
                  </span>
                </Col>
              )}
              {(parsedData?.last5YearsPEPOccurence ||
                parsedData?.last5YearsPEPOccurence > 0) && (
                <Col span={12} className="mrg-btm-10">
                  <span>
                    <strong>
                      {translate(`${I18N_BASE_PATH}.sourcesList.last5YearsPEPOccurence`)}:{' '}
                    </strong>
                    {parsedData?.last5YearsPEPOccurence}
                  </span>
                </Col>
              )}
              {(parsedData?.last5PlusYearsPEPOccurence ||
                parsedData?.last5PlusYearsPEPOccurence > 0) && (
                <Col span={12} className="mrg-btm-10">
                  <span>
                    <strong>
                      {translate(
                        `${I18N_BASE_PATH}.sourcesList.last5PlusYearsPEPOccurence`
                      )}
                      :{' '}
                    </strong>
                    {parsedData?.last5PlusYearsPEPOccurence}
                  </span>
                </Col>
              )}
              {(parsedData?.last30DaysSanctions ||
                parsedData?.last30DaysSanctions > 0) && (
                <Col span={12} className="mrg-btm-10">
                  <span>
                    <strong>
                      {translate(`${I18N_BASE_PATH}.sourcesList.last30DaysSanctions`)}:{' '}
                    </strong>
                    {parsedData?.last30DaysSanctions}
                  </span>
                </Col>
              )}
              {(parsedData?.last90DaysSanctions ||
                parsedData?.last90DaysSanctions > 0) && (
                <Col span={12} className="mrg-btm-10">
                  <span>
                    <strong>
                      {translate(`${I18N_BASE_PATH}.sourcesList.last90DaysSanctions`)}:{' '}
                    </strong>
                    {parsedData?.last90DaysSanctions}
                  </span>
                </Col>
              )}
              {(parsedData?.last180DaysSanctions ||
                parsedData?.last180DaysSanctions > 0) && (
                <Col span={12} className="mrg-btm-10">
                  <span>
                    <strong>
                      {translate(`${I18N_BASE_PATH}.sourcesList.last180DaysSanctions`)}:{' '}
                    </strong>
                    {parsedData?.last180DaysSanctions}
                  </span>
                </Col>
              )}
              {(parsedData?.last365DaysSanctions ||
                parsedData?.last365DaysSanctions > 0) && (
                <Col span={12} className="mrg-btm-10">
                  <span>
                    <strong>
                      {translate(`${I18N_BASE_PATH}.sourcesList.last365DaysSanctions`)}:{' '}
                    </strong>
                    {parsedData?.last365DaysSanctions}
                  </span>
                </Col>
              )}
            </Row>
            {personalInfo && (
              <Col span={24} className="sources-list-col">
                <TitleDivider
                  title={translate(
                    `${I18N_BASE_PATH}.sourcesList.personalInfo.titleDivider.title`
                  )}
                  tooltipInfo={translate(
                    `${I18N_BASE_PATH}.sourcesList.personalInfo.titleDivider.tooltipInfo`
                  )}
                  toPrint={toPrint}
                  customTop="0px"
                  customBottom="40px"
                />

                {!parsedData && (
                  <EmptyExecutionState
                    statusCode={statusCode}
                    message={source?.message || parsedData?.message}
                    className="mrg-btm-25 mrg-top-35"
                  />
                )}

                {parsedData && !parsedData?.pepHistory?.length > 0 && (
                  <EmptyState
                    type="empty-success"
                    description={translate(
                      `${I18N_BASE_PATH}.sourcesList.personalInfo.emptyState.description`
                    )}
                    className="mrg-btm-25 mrg-top-35"
                  />
                )}

                {parsedPEPList}
              </Col>
            )}

            {mappedData && (
              <>
                <Col span={24} className="sources-list-col">
                  <TitleDivider
                    title={translate(
                      `${I18N_BASE_PATH}.sourcesList.hasKycComplianceData.titleDivider.title.national`
                    )}
                    toPrint={toPrint}
                    customTop={customSourcesTop}
                    customBottom="50px"
                  />

                  {parsedNationalList}
                </Col>

                <Col span={24} className="sources-list-col">
                  <TitleDivider
                    title={translate(
                      `${I18N_BASE_PATH}.sourcesList.hasKycComplianceData.titleDivider.title.international`
                    )}
                    toPrint={toPrint}
                    customTop="5px"
                    customBottom="50px"
                  />

                  {parsedInternationalList}
                </Col>
              </>
            )}

            {!parsedData && (
              <Col span={24} className="sources-list-col">
                <TitleDivider
                  title={translate(
                    `${I18N_BASE_PATH}.sourcesList.hasNotKycComplianceData.titleDivider.title`
                  )}
                  toPrint={toPrint}
                  customTop={customSourcesTop}
                  customBottom="50px"
                />

                <EmptyExecutionState
                  statusCode={statusCode}
                  message={source?.message || parsedData?.message}
                  className="mrg-btm-25 mrg-top-35"
                />
              </Col>
            )}
          </>
        ) : (
          <EmptyExecutionState
            statusCode={statusCode}
            message={source?.message || parsedData?.message}
            className="mrg-btm-25 mrg-top-35"
          />
        )}
      </Row>
    </DataSourceCardCollapsePanel>
  );
};

export default SourcesList;
