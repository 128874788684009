export const actionTypes = [
  {
    label: 'pages.private.securityAndPrivacy.auditLog.index.actionTypes.CREATE',
    value: 'CREATE',
  },
  {
    label: 'pages.private.securityAndPrivacy.auditLog.index.actionTypes.READ',
    value: 'READ',
  },
  {
    label: 'pages.private.securityAndPrivacy.auditLog.index.actionTypes.UPDATE',
    value: 'UPDATE',
  },
  {
    label: 'pages.private.securityAndPrivacy.auditLog.index.actionTypes.DELETE',
    value: 'DELETE',
  },
];

export const resourcesTypes = [
  {
    label: 'pages.private.securityAndPrivacy.auditLog.index.resourceTypes.USERS',
    value: 'USERS',
  },
  {
    label: 'pages.private.securityAndPrivacy.auditLog.index.resourceTypes.EXECUTIONS',
    value: 'EXECUTIONS',
  },
  {
    label:
      'pages.private.securityAndPrivacy.auditLog.index.resourceTypes.ONBOARDING_LINKS',
    value: 'ONBOARDING_LINKS',
  },
  {
    label: 'pages.private.securityAndPrivacy.auditLog.index.resourceTypes.PROFILES',
    value: 'PROFILES',
  },
  {
    label:
      'pages.private.securityAndPrivacy.auditLog.index.resourceTypes.QUERY_TEMPLATES',
    value: 'QUERY_TEMPLATES',
  },
  {
    label: 'pages.private.securityAndPrivacy.auditLog.index.resourceTypes.ROUTINES',
    value: 'ROUTINES',
  },
  {
    label: 'pages.private.securityAndPrivacy.auditLog.index.resourceTypes.ACCESS_KEYS',
    value: 'ACCESS_KEYS',
  },
  {
    label: 'pages.private.securityAndPrivacy.auditLog.index.resourceTypes.COMPANIES',
    value: 'COMPANIES',
  },
  {
    label:
      'pages.private.securityAndPrivacy.auditLog.index.resourceTypes.SECURITY_PARAMS',
    value: 'SECURITY_PARAMS',
  },
  {
    label:
      'pages.private.securityAndPrivacy.auditLog.index.resourceTypes.TRANSACTION_RECORDS',
    value: 'TRANSACTION_RECORDS',
  },
];
