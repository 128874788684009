import React, { createContext, useCallback, useContext, useState } from 'react';

import { useFetch } from 'services/hooks';
import { useAuth } from 'hooks/auth';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';

const PersonContext = createContext({});

const PersonProvider = ({ children }) => {
  const {
    data: person,
    loading: loadingPerson,
    get,
    clearData: clearPerson,
  } = useFetch();

  const { user } = useAuth();

  const [reloadPerson, setReloadPerson] = useState(false);

  const loadPerson = useCallback(
    async ({ personId, clearData }) => {
      if (hasFeatureFlag('isWorkflowBuilder', user)) {
        await get({
          url: `global/profiles/profile/${personId}/full`,
          clearData,
        });
      } else {
        await get({
          url: `people/${personId}`,
          clearData,
        });
      }
      setReloadPerson(false);
    },
    [get, user]
  );

  const refreshPerson = useCallback(() => {
    setReloadPerson(true);
  }, []);

  return (
    <PersonContext.Provider
      value={{
        person: person?.data,
        loadingPerson,
        loadPerson,
        refreshPerson,
        reloadPerson,
        clearPerson,
      }}
    >
      {children}
    </PersonContext.Provider>
  );
};

function usePerson() {
  const context = useContext(PersonContext);

  if (!context) {
    throw new Error('usePerson must be used within an PersonContext');
  }

  return context;
}

export { PersonProvider, usePerson };
