import React, { useState, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Modal, Checkbox, Row, Col, Switch, Divider, Tooltip, Tag } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { useCompany } from 'hooks/company';
import { usePerson } from 'hooks/person';
import { useAuth } from 'hooks/auth';

import * as pjDataSources from 'utils/pjDataSource';
import * as pfDataSources from 'utils/pfDataSource';
import * as executionDataSource from 'utils/executionDataSource';

import PageTitle from 'components/PageTitle';
import Button from 'components/Button';

import { hasRequiredPermissions } from 'routes/permissionFunctions';

import { checkboxOptions } from './utils/checkboxOptions';
import { backgroudCheckingOptions } from './utils/backgroudCheckingOptions';
import { legacyOptions } from './utils/legacyOptions';

import ToPrint from './components/ToPrint';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.profiles.components.dossierPrint.index';

const DossierPrint = () => {
  const { t: translate } = useTranslation();

  const componentRef = useRef();
  const { personId, companyId } = useParams();

  const { company } = useCompany();
  const { person } = usePerson();
  const { user } = useAuth();

  const [modalVisible, setModalVisible] = useState(false);
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [loadingActivities, setLoadingActivities] = useState(false);

  const [checkAll, setCheckAll] = useState(false);
  const [checkAllBackgroud, setCheckAllBackgroud] = useState(false);
  const [checkAllLegacy, setCheckAllLegacy] = useState(false);

  const [showDetails, setShowDetails] = useState(false);

  const [checkedList, setCheckedList] = useState([]);
  const [checkedBackgroudList, setCheckedBackgroudList] = useState([]);
  const [checkedLegacyList, setCheckedLegacyList] = useState([]);

  const closeModal = useCallback(() => {
    setCheckedList([]);
    setCheckAll(false);
    setShowDetails(false);
    setLoadingPrint(false);
    setModalVisible(false);
    setLoadingActivities(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const customIndependentConsultants = useMemo(() => {
    return person?.independentConsultants;
  }, [person]);

  const requiredPermissions = useMemo(() => {
    return personId ? ['people:read'] : ['companies:read'];
  }, [personId]);

  const blockedPermissions = useMemo(() => {
    const mappedPermissions = [];

    const criminalInformation = hasRequiredPermissions(user, requiredPermissions, [
      'criminal:*',
    ]);
    const legalInformation = hasRequiredPermissions(user, requiredPermissions, [
      'justice:*',
    ]);
    const restrictiveLists = personId
      ? hasRequiredPermissions(user, ['people:read'], ['others:kycCompliance:*'])
      : hasRequiredPermissions(
          user,
          ['companies:read'],
          ['others:kycCompliance:*', 'others:kycComplianceOwners:*'],
          true
        );

    if (!criminalInformation) mappedPermissions.push('criminal_information');
    if (!legalInformation) mappedPermissions.push('legal_information');
    if (!restrictiveLists) mappedPermissions.push('restrictive_lists');

    return mappedPermissions;
  }, [user, requiredPermissions, personId]);

  const blockedDataSources = useMemo(() => {
    const mappedDataSources = [];

    if (companyId) {
      const dataSources =
        Object.keys(
          company?.sources || company?.profileTemplate?.backgroundChecking?.services || {}
        )?.map((source) => source) || [];
      if (!pjDataSources.hasFinancialInformation(company, dataSources))
        mappedDataSources.push('financial_information');
      if (!pjDataSources.hasRestrictiveListsInformation(company, dataSources))
        mappedDataSources.push('restrictive_lists');
      if (!pjDataSources.hasLaborInformation(company, dataSources))
        mappedDataSources.push('labor_information');
      if (!pjDataSources.hasLegalInformation(company, dataSources))
        mappedDataSources.push('legal_information');
      if (
        !executionDataSource.hasBasicInformation(company, dataSources) &&
        !executionDataSource.hasPjSintegraData(company, dataSources)
      )
        mappedDataSources.push('basic_infos');
      if (!executionDataSource.hasContactsInformation(company, dataSources))
        mappedDataSources.push('contact');
      if (!executionDataSource.hasFinancialInformation(company, dataSources))
        mappedDataSources.push('finance');
      if (!executionDataSource.hasLegalInformation(company, dataSources))
        mappedDataSources.push('justice');
      if (!executionDataSource.hasRestrictiveListsInformation(company, dataSources))
        mappedDataSources.push('restrictive_lists_backgroud_checking');
      if (!executionDataSource.hasLaborInformation(company, dataSources))
        mappedDataSources.push('work');
      if (!executionDataSource.hasMediaExposureInformation(company, dataSources))
        mappedDataSources.push('media');
      if (!executionDataSource.hasDriverInformation(company, dataSources))
        mappedDataSources.push('driver');
    }

    if (personId) {
      const dataSources =
        Object.keys(
          person?.sources || person?.profileTemplate?.backgroundChecking?.services || {}
        )?.map((source) => source) || [];

      if (!pfDataSources.hasAddressInformation(person, dataSources))
        mappedDataSources.push('address');
      if (!pfDataSources.hasContactsInformation(person, dataSources))
        mappedDataSources.push('contacts');
      if (!pfDataSources.hasCriminalInformation(person, dataSources))
        mappedDataSources.push('criminal_information');
      if (!pfDataSources.hasFinancialInformation(person, dataSources))
        mappedDataSources.push('financial_information');
      if (!pfDataSources.hasRestrictiveListsInformation(person, dataSources))
        mappedDataSources.push('restrictive_lists');
      if (!pfDataSources.hasLaborInformation(person, dataSources))
        mappedDataSources.push('labor_information');
      if (!pfDataSources.hasDriverInformation(person, dataSources))
        mappedDataSources.push('driver_information');
      if (!pfDataSources.hasLegalInformation(person, dataSources))
        mappedDataSources.push('legal_information');
      if (!pfDataSources.hasRelationshipInformation(person, dataSources))
        mappedDataSources.push('relationships');
      if (!pfDataSources.hasMediaExposureInformation(person, dataSources))
        mappedDataSources.push('media_exposure');
      if (!executionDataSource.hasBasicInformation(person, dataSources))
        mappedDataSources.push('basic_infos');
      if (!executionDataSource.hasContactsInformation(person, dataSources))
        mappedDataSources.push('contact');
      if (!executionDataSource.hasFinancialInformation(person, dataSources))
        mappedDataSources.push('finance');
      if (!executionDataSource.hasLegalInformation(person, dataSources))
        mappedDataSources.push('justice');
      if (!executionDataSource.hasRestrictiveListsInformation(person, dataSources))
        mappedDataSources.push('restrictive_lists_backgroud_checking');
      if (!executionDataSource.hasDriverInformation(person, dataSources))
        mappedDataSources.push('driver');
      if (!executionDataSource.hasLaborInformation(person, dataSources))
        mappedDataSources.push('work');
      if (!executionDataSource.hasMediaExposureInformation(person, dataSources))
        mappedDataSources.push('media');
      if (!executionDataSource.hasCriminalInformation(person, dataSources))
        mappedDataSources.push('criminal_background');
    }

    return mappedDataSources;
  }, [companyId, company, personId, person]);

  const formattedOptions = useMemo(() => {
    const type = personId
      ? translate(`${I18N_BASE_PATH}.type.person`)
      : translate(`${I18N_BASE_PATH}.type.company`);
    const options = checkboxOptions(type) || [];

    let parsedOptions = options
      .filter((op) => !blockedPermissions.includes(op._id))
      .filter((op) => !blockedDataSources.includes(op._id));

    if (!customIndependentConsultants) {
      parsedOptions = parsedOptions.filter((op) => op._id !== 'independent_consultants');
    }

    const optionsId = parsedOptions.map((option) => option._id);

    return { options: parsedOptions, optionsId };
  }, [
    personId,
    blockedPermissions,
    blockedDataSources,
    customIndependentConsultants,
    translate,
  ]);

  const formattedBackgroudChekingOptions = useMemo(() => {
    const type = personId
      ? translate(`${I18N_BASE_PATH}.type.person`)
      : translate(`${I18N_BASE_PATH}.type.company`);
    const options = backgroudCheckingOptions(type) || [];

    let parsedOptions = options
      .filter((op) => !blockedPermissions.includes(op._id))
      .filter((op) => !blockedDataSources.includes(op._id));

    if (!customIndependentConsultants) {
      parsedOptions = parsedOptions.filter((op) => op._id !== 'independent_consultants');
    }

    const optionsId = parsedOptions.map((option) => option._id);

    return { options: parsedOptions, optionsId };
  }, [
    personId,
    blockedPermissions,
    blockedDataSources,
    customIndependentConsultants,
    translate,
  ]);

  const formattedLegacyOptions = useMemo(() => {
    const type = personId
      ? translate(`${I18N_BASE_PATH}.type.person`)
      : translate(`${I18N_BASE_PATH}.type.company`);
    const options = legacyOptions(type) || [];

    let parsedOptions = options
      .filter((op) => !blockedPermissions.includes(op._id))
      .filter((op) => !blockedDataSources.includes(op._id));

    if (!customIndependentConsultants) {
      parsedOptions = parsedOptions.filter((op) => op._id !== 'independent_consultants');
    }

    const optionsId = parsedOptions.map((option) => option._id);

    return { options: parsedOptions, optionsId };
  }, [
    personId,
    blockedPermissions,
    blockedDataSources,
    customIndependentConsultants,
    translate,
  ]);

  const profileData = useMemo(() => {
    return personId ? person : company;
  }, [personId, person, company]);

  const formattedDocumentName = useMemo(() => {
    const officialName = profileData?.basicData?.officialName;
    const name = profileData?.basicData?.name;

    const parsedOfficialName = officialName?.split(' ').join('_');
    const parsedName = name?.split(' ').join('_');

    return parsedOfficialName || parsedName || '-';
  }, [profileData]);

  const onChangeList = useCallback(
    (values) => {
      setCheckedList(values);
      setCheckAll(values.length === formattedOptions.optionsId.length);
    },
    [formattedOptions]
  );

  const onChangeBackgroudCheckingList = useCallback(
    (values) => {
      setCheckedBackgroudList(values);
      setCheckAllBackgroud(
        values.length === formattedBackgroudChekingOptions.optionsId.length
      );
    },
    [formattedBackgroudChekingOptions]
  );

  const onChangeLegacyList = useCallback(
    (values) => {
      setCheckedLegacyList(values);
      setCheckAllLegacy(values.length === formattedLegacyOptions.optionsId.length);
    },
    [formattedLegacyOptions]
  );

  const onChangeCheckAllBackgroundChecking = useCallback(
    (event) => {
      onChangeBackgroudCheckingList(
        event.target.checked ? formattedBackgroudChekingOptions.optionsId : []
      );
      setCheckAllBackgroud(event.target.checked);
    },
    [formattedBackgroudChekingOptions, onChangeBackgroudCheckingList]
  );

  const onChangeCheckAllLegacy = useCallback(
    (event) => {
      onChangeLegacyList(event.target.checked ? formattedLegacyOptions.optionsId : []);
      setCheckAllLegacy(event.target.checked);
    },
    [formattedLegacyOptions, onChangeLegacyList]
  );

  const onChangeCheckAll = useCallback(
    (event) => {
      setCheckedList(event.target.checked ? formattedOptions.optionsId : []);
      setCheckAll(event.target.checked);
      onChangeBackgroudCheckingList(
        event.target.checked ? formattedBackgroudChekingOptions.optionsId : []
      );
      setCheckAllBackgroud(event.target.checked);
    },
    [formattedOptions, onChangeBackgroudCheckingList, formattedBackgroudChekingOptions]
  );

  const onChangeShowDetails = useCallback(() => {
    setShowDetails((oldState) => !oldState);
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => closeModal(),
    onBeforeGetContent: () => setLoadingPrint(true),
    documentTitle: `Trust-Monitor_${formattedDocumentName}`,
  });

  const DossierPrintModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal dossier-print-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        footer={null}
        width={700}
        onCancel={closeModal}
      >
        <div className="no-mrg no-pdd">
          <PageTitle
            title={translate(`${I18N_BASE_PATH}.dossierPrintModal.pageTitle.title`)}
            subtitle={translate(`${I18N_BASE_PATH}.dossierPrintModal.pageTitle.subtitle`)}
          />

          <div hidden>
            <ToPrint
              ref={componentRef}
              data={profileData}
              checkedList={checkedList}
              checkedBackgroudList={checkedBackgroudList}
              checkedLegacyList={checkedLegacyList}
              showDetails={showDetails}
              onLoadingChange={(loading) => setLoadingActivities(loading)}
            />
          </div>

          <Checkbox onChange={onChangeCheckAll} checked={checkAll}>
            {translate(`${I18N_BASE_PATH}.dossierPrintModal.checkbox.all`)}
          </Checkbox>

          <Checkbox.Group value={checkedList} onChange={onChangeList}>
            <Row>
              {formattedOptions.options.map((option) => (
                <Col key={option._id} span={12}>
                  <Checkbox value={option._id}>
                    <i className={option.icon} /> {translate(option.label)}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>

          <Divider />

          <Checkbox
            onChange={onChangeCheckAllBackgroundChecking}
            checked={checkAllBackgroud}
          >
            {translate(`${I18N_BASE_PATH}.dossierPrintModal.checkbox.backgroundChecking`)}
          </Checkbox>
          <Checkbox.Group
            value={checkedBackgroudList}
            onChange={onChangeBackgroudCheckingList}
          >
            <Row>
              {formattedBackgroudChekingOptions.options.map((option) => (
                <Col key={option._id} span={12}>
                  <Checkbox value={option._id}>
                    <i className={option.icon} /> {translate(option.label)}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>

          <Divider />

          <Checkbox onChange={onChangeCheckAllLegacy} checked={checkAllLegacy}>
            {translate(`${I18N_BASE_PATH}.dossierPrintModal.checkbox.legacy`)}
            <Tooltip
              placement="top"
              title={translate(`${I18N_BASE_PATH}.dossierPrintModal.tooltip.legacy`)}
              trigger={['hover']}
            >
              <QuestionCircleOutlined className="mrg-left-10" />
            </Tooltip>
            <Tag className="tag-legacy">
              {translate(`${I18N_BASE_PATH}.dossierPrintModal.tags.legacy`)}
            </Tag>
          </Checkbox>
          <Checkbox.Group value={checkedLegacyList} onChange={onChangeLegacyList}>
            <Row>
              {formattedLegacyOptions.options.map((option) => (
                <Col key={option._id} span={12}>
                  <Checkbox value={option._id}>
                    <i className={option.icon} /> {translate(option.label)}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>

          <div className="dossier-print-footer">
            <div className="dossier-print-show-details">
              <Switch checked={showDetails} onChange={onChangeShowDetails} />{' '}
              <span>{translate(`${I18N_BASE_PATH}.dossierPrintModal.details`)}</span>
              <Tooltip
                placement="top"
                title={translate(
                  `${I18N_BASE_PATH}.dossierPrintModal.tooltip.switchButton`
                )}
                trigger={['hover']}
              >
                <QuestionCircleOutlined />
              </Tooltip>
              <Tooltip
                placement="top"
                title={translate(`${I18N_BASE_PATH}.dossierPrintModal.tooltip.betaTag`)}
                trigger={['hover']}
              >
                <Tag className="tag-show-details">
                  {translate(`${I18N_BASE_PATH}.dossierPrintModal.tags.beta`)}
                </Tag>
              </Tooltip>
            </div>

            <div className="dossier-print-btns">
              <Button type="default" className="btn-cancel" onClick={closeModal}>
                {translate(`${I18N_BASE_PATH}.dossierPrintModal.button.cancel`)}
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                onClick={() => handlePrint()}
                disabled={
                  (!checkedList.length > 0 &&
                    !checkedBackgroudList.length > 0 &&
                    !checkedLegacyList.length > 0) ||
                  loadingPrint ||
                  loadingActivities
                }
              >
                {translate(
                  `${I18N_BASE_PATH}.dossierPrintModal.button.submit.${
                    loadingPrint || loadingActivities ? 'loading' : 'default'
                  }`
                )}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      translate,
      profileData,
      checkedList,
      checkedBackgroudList,
      checkedLegacyList,
      showDetails,
      onChangeCheckAll,
      checkAll,
      onChangeList,
      formattedOptions,
      onChangeCheckAllBackgroundChecking,
      checkAllBackgroud,
      onChangeBackgroudCheckingList,
      formattedBackgroudChekingOptions,
      onChangeCheckAllLegacy,
      checkAllLegacy,
      onChangeLegacyList,
      formattedLegacyOptions,
      onChangeShowDetails,
      loadingPrint,
      loadingActivities,
      handlePrint,
    ]
  );

  return { openModal, DossierPrintModal };
};

export default DossierPrint;
