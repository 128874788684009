import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import PersonLaborInformation from 'pages/private/Profiles/components/DataSourceCards/LaborInformation/People';
import CompanyLaborInformation from 'pages/private/Profiles/components/DataSourceCards/LaborInformation/Companies';

import CNDTDebtsItems from 'pages/private/Profiles/components/DataSourceCards/LaborInformation/Companies/components/CNDT/CNDTDebtsItems';
import PersonalLaborAndFinancialInformation from 'pages/private/Profiles/components/DataSourceModals/PersonalLaborAndFinancialInformation/components/Items';

import './styles.less';

const DocumentLaborInformation = ({ data, showDetails }) => {
  const { t: translate } = useTranslation();

  const { personId, companyId } = useParams();

  const cndtData = useMemo(() => {
    const cndt = data?.certificates?.cndt;
    return cndt;
  }, [data]);

  const civilServantData = useMemo(() => {
    const civilServant = data?.work?.civilServant;
    return civilServant;
  }, [data]);

  const workHistoryData = useMemo(() => {
    const workHistory = data?.work?.history;
    return workHistory;
  }, [data]);

  const classOrganizationData = useMemo(() => {
    const classOrganization = data?.work?.classOrganization;
    return classOrganization;
  }, [data]);

  return (
    <div id="to-print-labor-information">
      {personId && <PersonLaborInformation data={data} toPrint />}
      {companyId && <CompanyLaborInformation data={data} toPrint />}

      {showDetails && cndtData?.lawsuitsList?.length > 0 && (
        <CNDTDebtsItems data={cndtData} toPrint />
      )}

      {showDetails && workHistoryData?.professions?.length > 0 && (
        <PersonalLaborAndFinancialInformation
          data={workHistoryData?.professions}
          title={translate(
            'pages.private.profiles.components.dossierPrint.components.documentLaborInformation.index.documentLaborInformation.workHistoryData.title'
          )}
        />
      )}

      {showDetails && civilServantData?.professions?.length > 0 && (
        <PersonalLaborAndFinancialInformation
          data={civilServantData?.professions}
          title={translate(
            'pages.private.profiles.components.dossierPrint.components.documentLaborInformation.index.documentLaborInformation.civilServantData.title'
          )}
        />
      )}

      {showDetails && classOrganizationData?.items?.length > 0 && (
        <PersonalLaborAndFinancialInformation
          data={classOrganizationData?.items}
          title={translate(
            'pages.private.profiles.components.dossierPrint.components.documentLaborInformation.index.documentLaborInformation.classOrganizationData.title'
          )}
          classOrganization
        />
      )}
    </div>
  );
};

DocumentLaborInformation.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  showDetails: PropTypes.bool,
};

DocumentLaborInformation.defaultProps = {
  showDetails: false,
};

export default DocumentLaborInformation;
