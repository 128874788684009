import { RudderAnalytics } from '@rudderstack/analytics-js';

const rudderAnalytics = new RudderAnalytics();

const WRITE_KEY = process.env.REACT_APP_RUDDERSTACK_WRITE_KEY;
const DATA_PLANE_URL = process.env.REACT_APP_RUDDERSTACK_DATA_PLANE_URL;

rudderAnalytics.load(WRITE_KEY, DATA_PLANE_URL, {});

export { rudderAnalytics };
