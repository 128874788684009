import React from 'react';
import { useTranslation } from 'react-i18next';
import EmptyMessage from '../EmptyMessage';
import './styles.less';

const NotAvailable = ({ card = false, title = '', ...rest }) => {
  const { t: translate } = useTranslation();
  if (card) {
    return (
      <EmptyMessage
        title={title}
        description={translate('components.notAvailable.label', 'Not available')}
        {...rest}
      />
    );
  }
  return (
    <span className="not-available" title={title} {...rest}>
      {translate('components.notAvailable.label', 'Not available')}
    </span>
  );
};
export default NotAvailable;
