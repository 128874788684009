/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Card } from 'antd';

import { useFetch } from 'services/hooks';

import { useI18nFormatters } from 'hooks/i18nFormatters';
import { useTags } from 'hooks/tags';

import { generalStatus } from 'utils/handlerStatus';
import { toMaskedCpf } from 'utils/formatters';

import IconStatus from 'pages/private/Profiles/components/IconStatus';
import CardExecutionTag from 'pages/private/Profiles/components/ProfileTag/CardExecutionTag';

import profileDefault from 'assets/images/profile-default.png';

import copyTextToClipboard from 'utils/copyTextToClipboard';
import PermissionWrapper from 'routes/PermissionWrapper';

import './styles.less';

const PersonCard = ({
  data,
  customModal: openShareNewLinkModal,
  refreshList,
  allProfileTemplates,
}) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const { customAllTags: tagsList, loadingAllTags: loadingTags } = useTags();

  const parsedTags = useMemo(() => {
    return data?.tags?.filter((tag) => tag !== null) || [];
  }, [data]);

  const { get: getPerson } = useFetch();

  const refreshListCallback = useCallback(() => refreshList(), [refreshList]);

  const profileTemplateName = useMemo(() => {
    const name = allProfileTemplates?.find(
      (profileTemplate) => profileTemplate?._id === data?.profileTemplateId
    )?.name;

    return name || '-';
  }, [allProfileTemplates, data]);

  const handleCreateLinkModal = useCallback(async () => {
    const person = await getPerson({
      url: `/people/${data._id}`,
    });

    person.data.type = translate(
      'pages.private.profiles.components.list.components.personCard.index.type.person'
    );

    openShareNewLinkModal(person.data, refreshListCallback);
  }, [data, getPerson, openShareNewLinkModal, refreshListCallback, translate]);

  const content = useMemo(() => {
    return (
      <Card>
        <div className="container-card-body">
          <div
            className={`${!data?.image ? 'grayscale-95' : ''} card-image-profile`}
            style={{ backgroundImage: `url(${data?.image || profileDefault})` }}
          />
          <div className="card-body">
            <div className="flex-column card-body-infos">
              <p className="font-size-11 mrg-btm-5 text-semibold profile-template-name">
                {profileTemplateName}
              </p>

              <p title={data?.name || '-'} className="card-title max-lines-1">
                {data?.name || '-'}
              </p>

              <div className="card-info">
                <i className="caf-ic_person" />
                <span className={`${!data?.cpf && 'gx-font-italic'} max-lines-1`}>
                  {toMaskedCpf(data?.cpf) || '-'}
                </span>
              </div>
            </div>

            {data?.status && (
              <div className="card-info-status">
                <IconStatus status={data?.status} />
                <div className="card-info-status-content">
                  <strong>{translate(generalStatus(data?.status)?.value)}</strong>
                  <span className="font-size-12">
                    {translate(
                      'pages.private.profiles.components.list.components.personCard.index.content.updatedAt'
                    )}{' '}
                    {i18nFormatDate(data?.updatedAt || data?.createdAt, 8)}
                  </span>
                  {data?.status === 'WAITING_DOCUMENTS' && (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        if (data?.onboardingUrl) {
                          copyTextToClipboard(
                            data.onboardingUrl,
                            translate(
                              'pages.private.profiles.components.list.components.personCard.index.content.copyTextToClipboard.success'
                            ),
                            translate(
                              'pages.private.profiles.components.list.components.personCard.index.content.copyTextToClipboard.error'
                            )
                          );
                        } else {
                          handleCreateLinkModal();
                        }
                      }}
                      className="copy-link-button"
                    >
                      {translate(
                        `pages.private.profiles.components.list.components.personCard.index.content.copyTextToClipboard.text.${
                          data?.onboardingUrl ? 'hasOnboardingUrl' : 'hasNotOnboardingUrl'
                        }`
                      )}
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {parsedTags?.length > 0 && (
          <div className="flex start-center pdd-top-15 pdd-btm-5 pdd-horizon-15 card-tags-wrapper">
            {!loadingTags && !_.isEmpty(tagsList) && (
              <div id="card-tags">
                {parsedTags?.map((tag) => {
                  return (
                    <CardExecutionTag
                      key={tag}
                      name={tagsList[tag]?.title}
                      color={tagsList[tag]?.color}
                      special={tagsList[tag]?.special}
                      actionDescription={tagsList[tag]?.actionDescription}
                    />
                  );
                })}
              </div>
            )}
          </div>
        )}
      </Card>
    );
  }, [
    data,
    handleCreateLinkModal,
    loadingTags,
    parsedTags,
    tagsList,
    translate,
    i18nFormatDate,
    profileTemplateName,
  ]);

  return (
    <PermissionWrapper
      requiredPermissions={['people:read:details']}
      forbiddenContent={
        <div
          id="card-people-component"
          className="overflow-hidden document-card mrg-btm-20"
        >
          {content}
        </div>
      }
    >
      <Link
        to={`/profile/people/${data._id}`}
        id="card-people-component"
        className="overflow-hidden document-card mrg-btm-20"
      >
        {content}
      </Link>
    </PermissionWrapper>
  );
};

export default PersonCard;
