import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import { uniqBy } from 'lodash';

import { useProfileTemplate } from 'hooks/profileTemplate';

import CollapseTitle from 'components/CollapseHeader/CollapseTitle';
import Tag from 'components/Tag';
import ToggleIcon from 'components/CollapseHeader/ToggleIcon';

import servicesCategories from 'utils/data/templateServicesCategories.json';

import useRenderPanel from './RenderPanel';

import './styles.less';

const { Panel } = Collapse;

const BackgroundChecking = ({
  dataSources,
  profileType,
  disabled,
  formRef,
  onValuesChange,
}) => {
  const { t: translate } = useTranslation();

  const { RenderPanelContent } = useRenderPanel();
  const { checkedServices } = useProfileTemplate();

  const formattedDataSources = useMemo(() => {
    if (!dataSources?.length) return [];

    // const categoryKeys = dataSources.map((d) => d.category).flat();
    const categoryKeys = dataSources
      .filter((ds) => ds.productType === 'source')
      .map((d) => d.category)
      .flat();
    const uniqueKeys = uniqBy(categoryKeys);

    return Object.keys(servicesCategories)
      .filter((category) => uniqueKeys.includes(category))
      .map((category) => {
        // Filtra por categoria e pelo profileType do modelo
        const sources = dataSources.filter(
          (source) => source.category.includes(category) && profileType === source.type
        );

        return {
          category: servicesCategories[category],
          docs: sources,
        };
      });
  }, [dataSources, profileType]);

  const _onValuesChange = useCallback(
    (value) => {
      const data = {
        backgroundCheckingServices: value,
      };

      formRef.setFieldsValue(data);
      if (onValuesChange) onValuesChange(data);
    },
    [formRef, onValuesChange]
  );

  const numberOfDataSources = useMemo(() => {
    const checkedDataSources = checkedServices?.filter(
      ({ category }) => category !== 'onboarding'
    );

    return formattedDataSources.reduce((acc, cur) => {
      acc[cur.category.id] = checkedDataSources.filter(
        ({ category }) => category === cur.category.id
      ).length;
      return acc;
    }, {});
  }, [checkedServices, formattedDataSources]);
  return (
    <section
      id="profile-model-background-checking-component"
      className="profile-model-recurrence-props gx-w-100 edit-mode"
    >
      <div className="profile-model-collapse-container flex-column">
        {/* <div className="flex mrg-btm-15 mrg-left-10 mrg-right-25 space-between">
          <section>
            <span>
              {translate(
                'pages.private.profiles.components.manualQuery.components.steps.backgroundChecking.index.backgroundChecking.checkAll.title'
              )}
            </span>
          </section>
          <section className="flex center mrg-left-10">
            <span className="mrg-right-20">
              {translate(
                'pages.private.profiles.components.manualQuery.components.steps.backgroundChecking.index.backgroundChecking.checkAll.description'
              )}
            </span>
            <Form.Item name="checkAllSources">
              <Checkbox
                checked={}
                indeterminate={
                  formRef?.getFieldsValue()?.backgroundCheckingServices &&
                  Object.values(
                    formRef?.getFieldsValue()?.backgroundCheckingServices
                  )?.some((bc) => bc !== undefined)
                }
                onClick={(e) => {
                  e.stopPropagation();

                  formattedDataSources?.map(({ category }) => {
                    document.querySelector(`#${category.id}-category-checkbox`)?.click();

                    return null;
                  });

                  const isAllCategoriesChecked = formattedDataSources
                    ?.map(({ category }) => {
                      document
                        .querySelector(`#${category.id}-category-checkbox`)
                        ?.click();

                      return null;
                    })
                    ?.every((c) => c);

                }}
              />
            </Form.Item>
          </section>
        </div> */}
        <Collapse
          expandIcon={({ isActive }) => <ToggleIcon isActive={isActive} />}
          className="flex-1 gx-w-100 radius-8"
          defaultActiveKey="basic_info"
          accordion
        >
          {formattedDataSources.map(({ category, docs }) => {
            const qtd = `${`0${numberOfDataSources[category.id] || 0}`.slice(
              -2
            )} ${translate(
              `pages.private.profiles.components.manualQuery.components.steps.backgroundChecking.index.backgroundChecking.consult.${
                numberOfDataSources[category.id] === 1 ? 'singular' : 'plural'
              }`
            )}`;

            return (
              docs?.length && (
                <Panel
                  forceRender
                  key={category?.id}
                  className="profile-model-collapse"
                  showArrow={false}
                  header={
                    <CollapseTitle
                      icon={category?.icon}
                      title={translate(category?.title)}
                      extra={
                        numberOfDataSources[category.id] > 0 && (
                          <Tag color="success">{qtd}</Tag>
                        )
                      }
                    />
                  }
                >
                  <RenderPanelContent
                    formRef={formRef}
                    docs={docs || []}
                    disabled={disabled}
                    allServices={dataSources}
                    categoryRef={category.id}
                    onValuesChange={_onValuesChange}
                  />
                </Panel>
              )
            );
          })}
        </Collapse>
      </div>
    </section>
  );
};

BackgroundChecking.propTypes = {
  dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
  profileType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onValuesChange: PropTypes.func,
};

BackgroundChecking.defaultProps = {
  disabled: false,
  onValuesChange: null,
};

export default BackgroundChecking;
