// registrationStatusCode (números) e registrationStatusMessage (texto)
export const cpfTranslation = {
  0: {
    type: 'primary',
    description: 'utils.personalTranslations.cpfTranslation.0.description',
  },
  1: {
    type: 'danger',
    description: 'utils.personalTranslations.cpfTranslation.1.description',
  },
  2: {
    type: 'warning',
    description: 'utils.personalTranslations.cpfTranslation.2.description',
  },
  3: {
    type: 'danger',
    description: 'utils.personalTranslations.cpfTranslation.3.description',
  },
  4: {
    type: 'warning',
    description: 'utils.personalTranslations.cpfTranslation.4.description',
  },
  'PENDENTE DE REGULARIZAÇÃO': {
    type: 'warning',
    description:
      'utils.personalTranslations.cpfTranslation.PENDENTE_DE_REGULARIZAÇÃO.description',
  },
  'PENDENTE DE REGULARIZACAO': {
    type: 'warning',
    description:
      'utils.personalTranslations.cpfTranslation.PENDENTE_DE_REGULARIZACAO.description',
  },
  "BIRTH DATE AND DOCUMENT DOESN'T MATCH": {
    type: 'danger',
    description:
      'utils.personalTranslations.cpfTranslation.BIRTH_DATE_AND_DOCUMENT_DOESNT_MATCH.description',
  },
  CANCELADA: {
    type: 'danger',
    description: 'utils.personalTranslations.cpfTranslation.CANCELADA.description',
  },
  'CANCELADA DE OFÍCIO': {
    type: 'danger',
    description: 'utils.personalTranslations.cpfTranslation.CANCELADA.description',
  },
  'CANCELADA POR MULTIPLICIDADE': {
    type: 'danger',
    description: 'utils.personalTranslations.cpfTranslation.CANCELADA.description',
  },
  'CPF incorreto': {
    type: 'danger',
    description: 'utils.personalTranslations.cpfTranslation.CPF_incorreto.description',
  },
  'CPF não encontrado': {
    type: 'danger',
    description:
      'utils.personalTranslations.cpfTranslation.CPF_não_encontrado.description',
  },
  'Data de nascimento informada está divergente da constante na base de dados da Secretaria da Receita Federal do Brasil': {
    type: 'danger',
    description:
      'utils.personalTranslations.cpfTranslation.Data_de_nascimento_informada_está_divergente_da_constante_na_base_de_dados_da_Secretaria_da_Receita_Federal_do_Brasil.description',
  },
  NULA: {
    type: 'default',
    description: 'utils.personalTranslations.cpfTranslation.NULA.description',
  },
  REGULAR: {
    type: 'primary',
    description: 'utils.personalTranslations.cpfTranslation.REGULAR.description',
  },
  SUSPENSA: {
    type: 'warning',
    description: 'utils.personalTranslations.cpfTranslation.SUSPENSA.description',
  },
  'TITULAR FALECIDO': {
    type: 'danger',
    description: 'utils.personalTranslations.cpfTranslation.TITULAR_FALECIDO.description',
  },
};

export const cnhTranslation = {
  true: {
    type: 'primary',
    description: 'utils.personalTranslations.cnhTranslation.true.description',
  },
  false: {
    type: 'danger',
    description: 'utils.personalTranslations.cnhTranslation.false.description',
  },
  unregistered: {
    type: 'default',
    description: 'utils.personalTranslations.cnhTranslation.unregistered.description',
  },
  NULO: {
    type: 'default',
    description: 'utils.personalTranslations.cnhTranslation.NULO.description',
  },
};

export const electoralSituationTranslation = {
  CANCELADO: {
    type: 'danger',
    description:
      'utils.personalTranslations.electoralSituationTranslation.status.CANCELADO.description',
  },
  REGULAR: {
    type: 'primary',
    description:
      'utils.personalTranslations.electoralSituationTranslation.status.REGULAR.description',
  },
  NULO: {
    type: 'default',
    description:
      'utils.personalTranslations.electoralSituationTranslation.status.NULO.description',
  },
};

export const federalCriminalBackgroundTranslation = {
  CONSTA: {
    type: 'danger',
    description:
      'utils.personalTranslations.federalCriminalBackgroundTranslation.data.CONSTA.description',
  },
  INDETERMINADO: {
    type: 'default',
    description:
      'utils.personalTranslations.federalCriminalBackgroundTranslation.data.INDETERMINADO.description',
  },
  NULO: {
    type: 'default',
    description:
      'utils.personalTranslations.federalCriminalBackgroundTranslation.data.NULO.description',
  },
  'NADA CONSTA': {
    type: 'primary',
    description:
      'utils.personalTranslations.federalCriminalBackgroundTranslation.data.NADA_CONSTA.description',
  },
  'NADA CONSTAR': {
    type: 'primary',
    description:
      'utils.personalTranslations.federalCriminalBackgroundTranslation.data.NADA_CONSTAR.description',
  },
  'NENHUM REGISTRO DE ANTECEDENTE CRIMINAL FOI ENCONTRADO EM DESFAVOR DA PESSOA ACIMA QUALIFICADA.': {
    type: 'primary',
    description:
      'utils.personalTranslations.federalCriminalBackgroundTranslation.data.NENHUM_REGISTRO_DE_ANTECEDENTE_CRIMINAL_FOI_ENCONTRADO_EM_DESFAVOR_DA_PESSOA_ACIMA_QUALIFICADA.description',
  },
  'NÃO CONSTAR': {
    type: 'primary',
    description:
      'utils.personalTranslations.federalCriminalBackgroundTranslation.data.NÃO_CONSTAR.description',
  },
};

export const nadaConstaTRFTranslation = {
  CONSTA: {
    type: 'danger',
    description:
      'utils.personalTranslations.nadaConstaTRFTranslation.data.CONSTA.description',
  },
  'NÃO CONSTA': {
    type: 'primary',
    description:
      'utils.personalTranslations.nadaConstaTRFTranslation.data.NÃO_CONSTA.description',
  },
  NULO: {
    type: 'default',
    description:
      'utils.personalTranslations.nadaConstaTRFTranslation.data.NULO.description',
  },
};

export const nadaConstaLaborDebtsTranslation = {
  CONSTA: {
    type: 'danger',
    description:
      'utils.personalTranslations.nadaConstaLaborDebtsTranslation.data.CONSTA.description',
  },
  'NÃO CONSTA': {
    type: 'primary',
    description:
      'utils.personalTranslations.nadaConstaLaborDebtsTranslation.data.NÃO_CONSTA.description',
  },
  'NAO CONSTA': {
    type: 'primary',
    description:
      'utils.personalTranslations.nadaConstaLaborDebtsTranslation.data.NÃO_CONSTA.description',
  },
  'NADA CONSTA': {
    type: 'primary',
    description:
      'utils.personalTranslations.nadaConstaLaborDebtsTranslation.data.NÃO_CONSTA.description',
  },
  NULO: {
    type: 'default',
    description:
      'utils.personalTranslations.nadaConstaLaborDebtsTranslation.data.NULO.description',
  },
};

export const stateCriminalBackgroundTranslation = {
  INDETERMINADO: {
    type: 'default',
    description:
      'utils.personalTranslations.stateCriminalBackgroundTranslation.data.INDETERMINADO.description',
  },
  'NADA CONSTA': {
    type: 'primary',
    description:
      'utils.personalTranslations.stateCriminalBackgroundTranslation.data.NADA_CONSTA.description',
  },
  NULO: {
    type: 'default',
    description:
      'utils.personalTranslations.stateCriminalBackgroundTranslation.data.NULO.description',
  },
};

export const executionCpfErrosTranslation = {
  '01': {
    type: 'default',
    description: 'utils.personalTranslations.executionCpfErrosTranslation.01.description',
    info: 'utils.personalTranslations.executionCpfErrosTranslation.01.info',
  },
  '02': {
    type: 'default',
    description: 'utils.personalTranslations.executionCpfErrosTranslation.02.description',
    info: 'utils.personalTranslations.executionCpfErrosTranslation.02.info',
  },
  89: {
    type: 'default',
    description: 'utils.personalTranslations.executionCpfErrosTranslation.89.description',
    info: 'utils.personalTranslations.executionCpfErrosTranslation.89.info',
  },
  99: {
    type: 'info',
    description: 'utils.personalTranslations.executionCpfErrosTranslation.99.description',
    info: 'utils.personalTranslations.executionCpfErrosTranslation.99.info',
  },
};

export const nadaConstaPGFNTranslation = {
  CONSTA: {
    type: 'danger',
    description:
      'utils.personalTranslations.nadaConstaPGFNTranslation.data.CONSTA.description',
  },
  'NÃO CONSTA': {
    type: 'primary',
    description:
      'utils.personalTranslations.nadaConstaPGFNTranslation.data.NÃO_CONSTA.description',
  },
  'NAO CONSTA': {
    type: 'primary',
    description:
      'utils.personalTranslations.nadaConstaPGFNTranslation.data.NÃO_CONSTA.description',
  },
  'NADA CONSTA': {
    type: 'primary',
    description:
      'utils.personalTranslations.nadaConstaPGFNTranslation.data.NÃO_CONSTA.description',
  },
  NULO: {
    type: 'default',
    description:
      'utils.personalTranslations.nadaConstaPGFNTranslation.data.NULO.description',
  },
  INDETERMINADO: {
    type: 'default',
    description:
      'utils.personalTranslations.nadaConstaPGFNTranslation.data.NULO.description',
  },
};

export const nadaConstaArrestWarrantTranslation = {
  CONSTA: {
    type: 'danger',
    description:
      'utils.personalTranslations.nadaConstaArrestWarrantTranslation.data.CONSTA.description',
  },
  'NADA CONSTA': {
    type: 'primary',
    description:
      'utils.personalTranslations.nadaConstaArrestWarrantTranslation.data.NAO_CONSTA.description',
  },
  NULO: {
    type: 'default',
    description:
      'utils.personalTranslations.nadaConstaArrestWarrantTranslation.data.NULO.description',
  },
};
