import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam } from 'use-query-params';
import { Row, Col, Typography } from 'antd';
import _ from 'lodash';

import useInfiniteScroll from 'components/List/hooks/useInfiniteScroll';

import { toFormatDate } from 'utils/formatters';

import PageTitle from 'components/PageTitle';

import Button from 'components/Button';
import Filter from './components/Filter';
import WhiteListCard from './components/WhiteListCard';
import EmptyPage from './components/EmptyPage';

import './styles.less';

const { Text } = Typography;

const I18N_BASE_PATH = 'pages.private.liveness.whiteListTab.index';

const WhiteList = () => {
  const { t: translate, i18n } = useTranslation();
  const refreshListRef = useRef(() => {});

  const [lastUpdatedAt, setLastUpdatedAt] = useState(null);

  const [query] = useQueryParams({
    personId: StringParam,
    trackingId: StringParam,
    _order: StringParam,
    _sort: StringParam,
    createdAt: StringParam,
  });

  const mapFilters = useCallback(() => {
    const filters = {};

    if (query.createdAt) {
      switch (query.createdAt) {
        case 'last24Hours':
          filters.startCreatedAt = new Date().getTime() - 24 * 60 * 60 * 1000;
          filters.endCreatedAt = new Date().getTime();
          break;
        case 'last7Days':
          filters.startCreatedAt = new Date().getTime() - 24 * 60 * 60 * 1000 * 7;
          filters._endCreatedAt = new Date().getTime();
          break;
        case 'last30Days':
          filters.startCreatedAt = new Date().getTime() - 24 * 60 * 60 * 1000 * 30;
          filters.endCreatedAt = new Date().getTime();
          break;
        case 'lastYear':
          filters.startCreatedAt = new Date().setFullYear(new Date().getFullYear() - 1);
          filters.endCreatedAt = new Date().getTime();
          break;
        default:
          if (query.createdAt.substr(0, 6) === 'custom') {
            const [startDate, endDate] = query.createdAt.substr(7).split('-');
            filters.startCreatedAt = startDate;
            filters.endCreatedAt = endDate;
          }
          break;
      }
      filters.startCreatedAt = filters.startCreatedAt
        ? new Date(parseInt(filters.startCreatedAt, 10))
        : null;
      filters.endCreatedAt = filters.endCreatedAt
        ? new Date(parseInt(filters.endCreatedAt, 10))
        : null;
    }

    if (query._sort && query._order) {
      filters._sort = query._sort;
      filters._order = query._order;
    }

    if (query.trackingId) {
      filters.trackingId = query.trackingId;
    }

    if (query.personId) {
      filters.personId = query.personId;
    }

    filters._sort = 'desc';

    return filters;
  }, [query]);

  const {
    componentList,
    loading,
    reloadList,
    setFilterData: setWhiteListFilter,
  } = useInfiniteScroll({
    getParams: {
      url: '/white-list',
      config: {
        params: {
          ...mapFilters(),
        },
      },
    },
    rowKey: 'id',
    component: WhiteListCard,
    hiddenLoading: true,
    customScroll: true,
    emptyComponent: EmptyPage,
  });

  useEffect(() => {
    refreshListRef.current = reloadList;
    setLastUpdatedAt(toFormatDate(new Date().toString(), i18n?.language, translate));
  }, [i18n.language, reloadList, translate]);

  const handleUpdateList = useCallback(async () => {
    reloadList();
    setLastUpdatedAt(toFormatDate(new Date().toString(), i18n?.language, translate));
  }, [i18n.language, reloadList, translate]);

  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      const filters = {};

      if (values.creationDate) {
        switch (values.creationDate) {
          case 'last24Hours':
            filters.startCreatedAt = new Date().getTime() - 24 * 60 * 60 * 1000;
            filters.endCreatedAt = new Date().getTime();
            break;
          case 'last7Days':
            filters.startCreatedAt = new Date().getTime() - 24 * 60 * 60 * 1000 * 7;
            filters.endCreatedAt = new Date().getTime();
            break;
          case 'last30Days':
            filters.startCreatedAt = new Date().getTime() - 24 * 60 * 60 * 1000 * 30;
            filters.endCreatedAt = new Date().getTime();
            break;
          case 'lastYear':
            filters.startCreatedAt = new Date().setFullYear(new Date().getFullYear() - 1);
            filters.endCreatedAt = new Date().getTime();
            break;
          default:
            if (
              values.creationDate.substr(0, 6) === 'custom' &&
              values.creationDateCustom
            ) {
              const startDate = values.creationDateCustom[0]
                .utcOffset(0)
                .set({ second: 0, millisecond: 0 })
                .valueOf();
              const endDate = values.creationDateCustom[1]
                .utcOffset(0)
                .set({ second: 59, millisecond: 0 })
                .valueOf();

              filters.startCreatedAt = startDate;
              filters.endCreatedAt = endDate;
            }
            break;
        }
        filters.startCreatedAt = filters.startCreatedAt
          ? new Date(parseInt(filters.startCreatedAt, 10))
          : null;
        filters.endCreatedAt = filters.endCreatedAt
          ? new Date(parseInt(filters.endCreatedAt, 10))
          : null;
      }
      setWhiteListFilter({
        ...filters,
        personId: values?.personId
          ? values.personId.join().replace(/[-|.]/g, '')
          : undefined,
        trackingId: values?.trackingId ? values.trackingId.join() : undefined,
        _sort: 'desc',
      });
    }, 500),
    [query]
  );

  return (
    <div id="white-list-component">
      <div className="gx-flex-row">
        <div id="list">
          <Row align="middle" justify="space-between" className="no-mrg">
            <Col span={16}>
              <PageTitle
                title={translate(`${I18N_BASE_PATH}.pageTitle.title`)}
                subtitle={translate(`${I18N_BASE_PATH}.pageTitle.subtitle`)}
              />
            </Col>
            <Col span={6}>
              <div className="flex-column align-center between-center">
                <Text className="text-center mrg-btm-10">
                  {lastUpdatedAt && !loading
                    ? `${translate(`${I18N_BASE_PATH}.lastUpdate`)} ${lastUpdatedAt}`
                    : `${translate(`${I18N_BASE_PATH}.updating`)}`}
                </Text>
                <Button
                  onClick={handleUpdateList}
                  loading={loading}
                  disabled={loading}
                  type="primary"
                >
                  {translate(`${I18N_BASE_PATH}.refresh`)}
                </Button>
              </div>
            </Col>
          </Row>
          <div className="list-content">{componentList}</div>
        </div>
        <div id="filter" className="hide-scroll">
          <Filter handleFormChange={handleFormChange} />
        </div>
      </div>
    </div>
  );
};

export default WhiteList;
