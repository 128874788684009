import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Divider, Typography } from 'antd';
import _ from 'lodash';

import CircleIcon from 'components/Icon/CircleIcon';
import EmptyState from 'components/EmptyState';

import './styles.less';

const { Title } = Typography;

const BasicInfosCard = ({
  data,
  dataIndex,
  // customQuery,
  icon,
  title,
  showDivider,
  children,
  customId,
}) => {
  const emptyMessage = useMemo(() => {
    if (!data || !dataIndex) return undefined;

    if (data?.status === 'PROCESSING' && _.isEmpty(data[dataIndex])) {
      return <EmptyState type="processing" />;
    }

    // if (data?.status !== 'PROCESSING') {
    //   if (customQuery && !data[dataIndex]) {
    //     return (
    //       <EmptyState
    //         type="failed"
    //         description="Houve algum problema com a fonte desta consulta."
    //       />
    //     );
    //   }

    //   const dataCopy = { ...(data[dataIndex] || {}) };
    //   delete dataCopy.lastConsultation;
    //   delete dataCopy.lastUpdate;

    //   if (customQuery && _.isEmpty(dataCopy)) {
    //     return (
    //       <EmptyState
    //         type="empty-success"
    //         description="Não encontramos nenhum resultado para esta consulta."
    //       />
    //     );
    //   }
    // }
    return undefined;
  }, [data, dataIndex]);

  const customIds = useMemo(() => {
    return customId || 'basic-infos-card';
  }, [customId]);

  return (
    <>
      <Row id={customIds}>
        <Col flex="0 1" className="flex center-start mrg-right-10">
          <CircleIcon icon={icon} />
        </Col>

        <Col flex="0.95 1" className="basic-infos-content">
          <Title level={4} className="basic-infos-title">
            {title}
          </Title>

          {emptyMessage || children}
        </Col>
      </Row>

      {showDivider && <Divider />}
    </>
  );
};

BasicInfosCard.propTypes = {
  data: PropTypes.object, // eslint-disable-line
  dataIndex: PropTypes.string,
  // customQuery: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  showDivider: PropTypes.bool,
  customId: PropTypes.string,
};

BasicInfosCard.defaultProps = {
  data: undefined,
  dataIndex: undefined,
  // customQuery: true,
  showDivider: true,
  customId: undefined,
};

export default BasicInfosCard;
