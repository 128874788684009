import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Card, Form, Select, DatePicker, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PageTitle from 'components/PageTitle';
import { i18nMomentFormats } from 'i18n/utils/i18nFormats';
import PropTypes from 'prop-types';

const I18N_BASE_PATH = 'pages.private.routines.detail.components.routineFrequency';

const { Option } = Select;

const options = [
  {
    value: 'yearly',
    name: `${I18N_BASE_PATH}.form.frequencies.yearly`,
  },
  {
    value: 'montly',
    name: `${I18N_BASE_PATH}.form.frequencies.montly`,
  },
  {
    value: 'weekly',
    name: `${I18N_BASE_PATH}.form.frequencies.weekly`,
  },
];

const RoutineFrequency = ({ frequency, onChange }) => {
  const { t: translate, i18n } = useTranslation();

  const [selectedFrequency, setSelectedFrequency] = useState();
  const [frequencyValue, setFrequencyValue] = useState();

  const onChangeFrequency = useCallback((value) => {
    setFrequencyValue(null);
    setSelectedFrequency(value);
  }, []);

  const onChangeValue = useCallback(
    (value) => {
      if (value === null) {
        return;
      }

      const newFrequency = {
        day: '0',
        minute: '0',
        month: '0',
        hour: '0',
        weekday: '*',
      };

      switch (selectedFrequency) {
        case 'yearly':
          newFrequency.day = `${value.date()}`;
          newFrequency.month = `${value.month() + 1}`;
          break;
        case 'montly':
          newFrequency.day = `${value}`;
          newFrequency.month = '*';
          break;
        case 'weekly':
          newFrequency.day = '*';
          newFrequency.month = '*';
          newFrequency.weekday = value;
          break;
        default:
          break;
      }

      onChange(newFrequency);
    },
    [onChange, selectedFrequency]
  );

  const onLoad = useCallback(() => {
    if (frequency.weekday !== '*') {
      setSelectedFrequency('weekly');
      setFrequencyValue(frequency.weekday);
    } else if (frequency.month !== '*') {
      setSelectedFrequency('yearly');
      setFrequencyValue(
        moment()
          .date(frequency.day)
          .month(parseInt(frequency.month, 10) - 1)
      );
    } else {
      setSelectedFrequency('montly');
      setFrequencyValue(parseInt(frequency.day, 10));
    }
  }, [frequency, setSelectedFrequency, setFrequencyValue]);

  useEffect(() => {
    onLoad();
  }, [onLoad, frequency]);

  const frequencyField = useMemo(
    () => (
      <Select onChange={onChangeFrequency} value={selectedFrequency}>
        {options.map((option) => (
          <Option value={option.value}>{translate(option.name)}</Option>
        ))}
      </Select>
    ),
    [onChangeFrequency, selectedFrequency, translate]
  );

  const frequencyValueField = useMemo(() => {
    switch (selectedFrequency) {
      case 'yearly':
        return (
          <DatePicker
            onChange={onChangeValue}
            format={i18nMomentFormats[3][i18n?.language]}
            suffixIcon={<i className="caf-ic_arrow_down" />}
            defaultValue={frequencyValue}
            style={{ width: '100%' }}
          />
        );
      case 'montly':
        return (
          <Select onChange={onChangeValue} defaultValue={frequencyValue}>
            {Array.from(Array(28)).map((x, i) => (
              <Option value={i + 1}>
                {translate(`${I18N_BASE_PATH}.form.day`)} {i + 1}
              </Option>
            ))}
          </Select>
        );
      case 'weekly':
        return (
          <Select onChange={onChangeValue} defaultValue={frequencyValue}>
            <Option value="0">{translate(`${I18N_BASE_PATH}.form.weeks.sun`)}</Option>
            <Option value="1">{translate(`${I18N_BASE_PATH}.form.weeks.mon`)}</Option>
            <Option value="2">{translate(`${I18N_BASE_PATH}.form.weeks.tue`)}</Option>
            <Option value="3">{translate(`${I18N_BASE_PATH}.form.weeks.wed`)}</Option>
            <Option value="4">{translate(`${I18N_BASE_PATH}.form.weeks.thu`)}</Option>
            <Option value="5">{translate(`${I18N_BASE_PATH}.form.weeks.fri`)}</Option>
            <Option value="6">{translate(`${I18N_BASE_PATH}.form.weeks.sat`)}</Option>
          </Select>
        );
      default:
        return <Input />;
    }
  }, [selectedFrequency, onChangeValue, i18n.language, frequencyValue, translate]);

  return (
    <>
      <Row align="middle" className="flex no-mrg mrg-top-50">
        <Col className="flex-1 page-title">
          <PageTitle
            title={translate(`${I18N_BASE_PATH}.title`)}
            subtitle={translate(`${I18N_BASE_PATH}.subtitle`)}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card className="pdd-left-50 pdd-right-50">
            <Row gutter={50}>
              <Col span={12}>
                <Form.Item
                  label={translate(`${I18N_BASE_PATH}.form.frequency`)}
                  rules={[{ required: true }]}
                >
                  {frequencyField}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={translate(`${I18N_BASE_PATH}.form.frequencyValue`)}
                  rules={[{ required: true }]}
                >
                  {frequencyValueField}
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

RoutineFrequency.propTypes = {
  frequency: PropTypes.shape({
    weekday: PropTypes.string,
    month: PropTypes.string,
    day: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RoutineFrequency;
