import { differenceInDays, parseISO, formatISO, formatDistance, isAfter } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { toFormatDate } from './formatters';

export const fromToDate = (startDate, endDate, language, translate, _default) => {
  try {
    if (!startDate && !endDate)
      return (
        _default ||
        translate('utils.customDate.fromToDate.hasNeitherStartDateEitherEndDate')
      );

    const _isAfter = isAfter(new Date(endDate), new Date());

    const _isAfterData = {
      before: translate('utils.customDate.fromToDate._isAfter.before'),
      inside: translate('utils.customDate.fromToDate._isAfter.inside'),
      after: translate('utils.customDate.fromToDate._isAfter.after'),
    };

    const defaultData = {
      before: translate('utils.customDate.fromToDate.default.before'),
      inside: translate('utils.customDate.fromToDate.default.inside'),
      after: translate('utils.customDate.fromToDate.default.after'),
      thisMoment: translate('utils.customDate.fromToDate.default.thisMoment'),
    };

    if (_isAfter) {
      return `${_isAfterData.before} ${toFormatDate(
        startDate,
        language,
        translate,
        'dd/MM/yyyy',
        _isAfterData.inside
      )} ${_isAfterData.after}`;
    }

    return `${defaultData.before} ${toFormatDate(
      startDate,
      language,
      translate,
      'dd/MM/yyyy',
      defaultData.inside
    )} ${defaultData.after} ${toFormatDate(
      endDate,
      language,
      translate,
      'dd/MM/yyyy',
      defaultData.thisMoment
    )}`;
  } catch (error) {
    return _default || '-';
  }
};

export const relativeDate = (date, _default) => {
  try {
    if (!date) return _default || '-';

    const currentDate = parseISO(formatISO(new Date()));
    const dateToCompare = parseISO(formatISO(new Date(date)));

    const difference = differenceInDays(currentDate, dateToCompare);

    if (difference === 1) {
      return 'ontem';
    }

    if (difference > 1 && difference < 60) {
      return `há ${difference} dias`;
    }

    if (difference > 60) {
      return `${formatDistance(dateToCompare, currentDate, {
        locale: ptBR,
        addSuffix: true,
      })}`;
    }

    return 'hoje';
  } catch (error) {
    return _default || '-';
  }
};
