/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Divider } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import { handlerAssociateRegister } from 'utils/associateRegisters';
import { toFormatDocument } from 'utils/formatters';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

import PageTitle from 'components/PageTitle';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import './styles.less';

const ProtestsItems = ({ data, toPrint }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatCurrency, i18nFormatDate } = useI18nFormatters();

  const customTitle = useMemo(() => {
    return toPrint
      ? translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.shared.protests.protestsItems.index.customTitle.hasToPrint'
        )
      : translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.shared.protests.protestsItems.index.customTitle.hasNotToPrint'
        );
  }, [toPrint, translate]);

  const handlerTotalValue = useMemo(() => {
    if (!data) return null;

    const values = [];
    const protests = data?.items || [];

    protests.forEach((protest) => {
      values.push(Number(protest.value || protest.protestValue));
    });

    const total = values.reduce((acc, cur) => acc + cur, 0);

    return i18nFormatCurrency(total, 'pt-BR');
  }, [data, i18nFormatCurrency]);

  return (
    <div id="protests-items">
      <PageTitle
        title={customTitle}
        subtitle={`${
          data?.lastUpdate?.date
            ? `${translate(
                'pages.private.profiles.components.dataSourceCards.financialInformation.shared.protests.protestsItems.index.protestsItems.pageTitle.subtitle.lastUpdateDate'
              )} ${i18nFormatDate(data?.lastUpdate?.date, 'default')}`
            : translate(
                'pages.private.profiles.components.dataSourceCards.financialInformation.shared.protests.protestsItems.index.protestsItems.pageTitle.subtitle.default'
              )
        }`}
      />

      <Row className="protests-items-title reset-mrg">
        <Col span={16}>
          <strong>
            {handlerAssociateRegister(
              data?.items?.length,
              translate(
                'pages.private.profiles.components.dataSourceCards.financialInformation.shared.protests.protestsItems.index.protestsItems.protests'
              ),
              translate
            )}
          </strong>
        </Col>

        <Col span={8} className="end">
          <strong>
            {translate(
              'pages.private.profiles.components.dataSourceCards.financialInformation.shared.protests.protestsItems.index.protestsItems.total'
            )}{' '}
            {handlerTotalValue || '-'}
          </strong>
        </Col>
      </Row>

      <Divider className="custom-divider" />

      {data?.items?.length > 0 &&
        data?.items?.map((protest) => (
          <Row
            key={`${protest?.debtorDocument}-${protest?.registrationDate}`}
            className="protests-items-data reset-mrg"
          >
            <ColumnLabel
              description={protest?.cartorio?.description || '-'}
              type="danger"
              span={16}
            >
              {capitalizeFirstLetter(protest?.cartorio?.address) || '-'}
            </ColumnLabel>
            <Col span={8} className="protest-item-col end">
              <span className="font-size-15 bold">
                {i18nFormatCurrency(Number(protest?.value, 'pt-BR')) ||
                  i18nFormatCurrency(Number(protest?.protestValue, 'pt-BR')) ||
                  '-'}
              </span>
            </Col>
            <Col span={12} className="protest-item-col">
              <strong className="mrg-left-15">
                {translate(
                  'pages.private.profiles.components.dataSourceCards.financialInformation.shared.protests.protestsItems.index.protestsItems.debtorDocument'
                )}
              </strong>{' '}
              <span>
                {toFormatDocument(
                  protest?.debtorDocument,
                  translate(
                    'pages.private.profiles.components.dataSourceCards.financialInformation.shared.protests.protestsItems.index.protestsItems.hasNotDebtorDocument'
                  ),
                  true
                )}
              </span>
            </Col>
            <Col span={6} className="protest-item-col">
              <strong>
                {translate(
                  'pages.private.profiles.components.dataSourceCards.financialInformation.shared.protests.protestsItems.index.protestsItems.protestDate'
                )}
              </strong>{' '}
              <span>{i18nFormatDate(protest?.protestDate, 1)}</span>
            </Col>
            <Col span={6} className="protest-item-col end">
              <strong>
                {translate(
                  'pages.private.profiles.components.dataSourceCards.financialInformation.shared.protests.protestsItems.index.protestsItems.dueDate'
                )}
              </strong>{' '}
              <span>{i18nFormatDate(protest?.dueDate, 1)}</span>
            </Col>
          </Row>
        ))}
    </div>
  );
};

export default ProtestsItems;
