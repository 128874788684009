/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Spin } from 'antd';

import { useValidations } from 'hooks/validations';

import EmptyMessage from 'components/EmptyMessage';

import ProfileWidget from 'pages/private/Profiles/components/ProfileWidget';
import CheckpointModel from 'pages/private/Profiles/components/CheckpointModel';

import useProfileTemplate from 'pages/private/Profiles/components/ProfileTemplate';
import useManualQuery from 'pages/private/Profiles/components/ManualQuery';

import servicesCategories from 'utils/data/templateServicesCategories.json';

import TrustItem from './components/TrustItem';

const TrustValidations = ({ profile, toPrint, url, type }) => {
  const { t: translate } = useTranslation();

  const profileTemplate = useMemo(() => profile?.profileTemplate, [profile]);

  const {
    validations: profileValidations,
    loadingValidations: loading,
  } = useValidations();

  const {
    openModal: openProfileTemplateModal,
    ProfileTemplateModal,
  } = useProfileTemplate({
    profile,
    url,
    type,
  });
  const { openModal: openManualQueryModal, ManualQueryModal } = useManualQuery({
    profileTemplate,
  });

  const backgroundCheckingDetails = useMemo(() => {
    if (!profileValidations) return undefined;

    const _validations = profileValidations?.validations;

    if (!_validations?.length) return undefined;

    const rule = _validations.find((v) => v.rule === 'valid_background_checking');

    if (!rule) return undefined;

    return rule?.data?.backgroundChecking || [];
  }, [profileValidations]);

  const subtitle = useMemo(() => {
    return translate(
      `pages.private.profiles.components.trustValidations.index.subtitle.${
        type ===
        translate('pages.private.profiles.components.trustValidations.index.type.person')
          ? 'person'
          : 'company'
      }`
    );
  }, [type, translate]);

  return (
    <ProfileWidget
      title={translate(
        'pages.private.profiles.components.trustValidations.index.trustValidations.profileWidget.title'
      )}
      subtitle={subtitle}
      icon="caf-ic_combateafraude"
      customClassName={
        !loading && backgroundCheckingDetails?.length > 0 ? 'reset-border-top' : ''
      }
    >
      {ProfileTemplateModal}
      {ManualQueryModal}

      {loading && (
        <div className="custom-loading-spin trust-validations">
          <Spin />
        </div>
      )}

      {!loading && !backgroundCheckingDetails?.length > 0 && (
        <EmptyMessage
          description={translate(
            'pages.private.profiles.components.trustValidations.index.trustValidations.emptyMessage.description'
          )}
          type="empty"
          style={{ padding: 15 }}
        />
      )}

      {!loading && (
        <>
          {backgroundCheckingDetails?.length > 0 && (
            <>
              {backgroundCheckingDetails?.map((group) => (
                <React.Fragment key={group.title}>
                  <div className="compliance-group-title">
                    <span>{group?.title || '-'}</span>
                  </div>

                  {group?.items?.length > 0 ? (
                    group?.items?.map((item) => (
                      <div
                        key={item.category || item.reportId}
                        className="personal-data-info bordered"
                      >
                        <TrustItem
                          title={
                            item.category
                              ? translate(servicesCategories[item?.category]?.title)
                              : item?.name
                          }
                          status={item.status}
                          // rule={item.rule}
                          data={item}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="personal-data-info bordered">
                      <span>
                        {translate(
                          'pages.private.profiles.components.trustValidations.index.trustValidations.hasNoData'
                        )}
                      </span>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </>
          )}

          <footer>
            {profileTemplate ? (
              <CheckpointModel
                hasModel
                openSettings={openProfileTemplateModal}
                openManualQuery={openManualQueryModal}
                title={translate(
                  'pages.private.profiles.components.trustValidations.index.trustValidations.checkpointModel.hasProfileTemplate.title'
                )}
                description={profileTemplate?.name}
                status={profileTemplate?.status}
                isProfileTemplateActive={profileTemplate?.active}
              />
            ) : (
              <CheckpointModel
                openSettings={openProfileTemplateModal}
                title={translate(
                  'pages.private.profiles.components.trustValidations.index.trustValidations.checkpointModel.hasNotProfileTemplate.title'
                )}
                description={translate(
                  'pages.private.profiles.components.trustValidations.index.trustValidations.checkpointModel.hasNotProfileTemplate.description'
                )}
              />
            )}
          </footer>
        </>
      )}

      {toPrint && <Divider className="custom-divider" />}
    </ProfileWidget>
  );
};

export default TrustValidations;
