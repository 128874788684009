import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select } from 'antd';

import languages from './utils/languages';

const { Option } = Select;

const LanguageSelector = () => {
  const { t: translate } = useTranslation();

  return (
    <Form.Item
      label={translate('components.i18n.index.form.label')}
      name="language"
      className="preferred-language"
    >
      <Select>
        {languages?.map((language) => (
          <Option key={language?.value} value={language?.value}>
            {language?.title}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default LanguageSelector;
