import React from 'react';
import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import NoResults from 'components/NoResults';
import NotAvailable from 'components/NotAvailable';
import CollapseList from '../../../../CollapseList';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companyDetails.subPages.people.businessIdentity';

const RegisteredAgentsContent = ({
  registeredAgents,
  collapsePrintSuffix,
  collapseOpened,
  print = false,
}) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDateOnly } = useI18nFormatters();

  if (!registeredAgents.length) {
    return <NoResults />;
  }

  return (
    <CollapseList
      print={print}
      collapsePrintSuffix={collapsePrintSuffix}
      collapseOpened={collapseOpened}
      columns={[
        {
          prop: 'name',
          title: translate(
            `${I18N_BASE_PATH}.tabs.registeredAgents.columns.name`,
            'Name'
          ),
          span: 6,
        },
        {
          prop: 'dateOfBirth',
          title: translate(
            `${I18N_BASE_PATH}.tabs.registeredAgents.columns.dateOfBirth`,
            'Date Of Birth'
          ),
          span: 6,
        },
        {
          prop: 'endDate',
          title: translate(
            `${I18N_BASE_PATH}.tabs.registeredAgents.columns.endDate`,
            'End date'
          ),
          span: 6,
        },
        {
          prop: 'identifiers',
          title: translate(
            `${I18N_BASE_PATH}.tabs.registeredAgents.columns.identifiers`,
            'Identifiers'
          ),
          span: 6,
        },
      ]}
      list={registeredAgents?.map((registeredAgent, index) => {
        return {
          header: {
            index,
            name: registeredAgent?.name || <NotAvailable />,
            dateOfBirth: i18nFormatDateOnly(registeredAgent?.birthdate, 1, null) || (
              <NotAvailable />
            ),
            endDate: i18nFormatDateOnly(registeredAgent?.toDate, 1, null) || (
              <NotAvailable />
            ),
            identifiers: !registeredAgent?.identifiers?.length ? (
              <NotAvailable />
            ) : (
              registeredAgent?.identifiers
            ),
          },
          body: [
            {
              title: translate(
                `${I18N_BASE_PATH}.tabs.registeredAgents.collapse.pep`,
                'PEP'
              ),
              content: registeredAgent?.pep
                ? translate(`${I18N_BASE_PATH}.tabs.registeredAgents.yes`, 'Yes')
                : translate(`${I18N_BASE_PATH}.tabs.registeredAgents.no`, 'No'),
            },
            {
              title: translate(
                `${I18N_BASE_PATH}.tabs.registeredAgents.collapse.sanctioned`,
                'Sanctioned'
              ),
              content: registeredAgent?.sanctioned
                ? translate(`${I18N_BASE_PATH}.tabs.registeredAgents.yes`, 'Yes')
                : translate(`${I18N_BASE_PATH}.tabs.registeredAgents.no`, 'No'),
            },
            {
              title: translate(
                `${I18N_BASE_PATH}.tabs.registeredAgents.collapse.basel_aml`,
                'Basel AML Score'
              ),
              content: registeredAgent?.riskData?.basel_aml?.value,
            },
            {
              title: translate(
                `${I18N_BASE_PATH}.tabs.registeredAgents.collapse.cpi_score`,
                'CPI Score'
              ),
              content: registeredAgent?.riskData?.cpi_score?.value,
            },
            {
              title: translate(
                `${I18N_BASE_PATH}.tabs.registeredAgents.collapse.former`,
                'Former'
              ),
              content: registeredAgent?.former
                ? translate(`${I18N_BASE_PATH}.tabs.registeredAgents.yes`, 'Yes')
                : translate(`${I18N_BASE_PATH}.tabs.registeredAgents.no`, 'No'),
            },
            {
              title: translate(
                `${I18N_BASE_PATH}.tabs.registeredAgents.collapse.latest_adress`,
                'Latest adress'
              ),
              content: registeredAgent?.addresses,
            },
          ],
        };
      })}
    />
  );
};

export default RegisteredAgentsContent;
