/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Input, Divider, Radio, Button as ButtonAntd, Spin, Modal } from 'antd';
import _ from 'lodash';

import { tagColors } from 'utils/tagColors';
import { useTags } from 'hooks/tags';

import { isValidLength } from './utils/isValidLength';
import ProfileTag from '../ProfileTag';
import './styles.less';

const ModalAddTags = ({ url }) => {
  const { t: translate } = useTranslation();

  const [form] = Form.useForm();
  const inputRef = useRef();

  const {
    loadAllTags,
    createTag,
    addTag,
    allTagsList,
    loadingAllTags,
    loadingAddTag,
    loadingCreateTag,
    openTagsMenu,
    closeTagsMenu,
  } = useTags();

  const [selectTagColorMode, setSelectTagColorMode] = useState(false);
  const [invalidTagTitle, setInvalidTagTitle] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const [hasSearchChange] = useState(true);
  const [searchValue, setSearchValue] = useState(null);

  const openModal = useCallback(() => {
    setModalVisible(true);
    openTagsMenu();
    loadAllTags();

    if (!inputRef.current) return;

    setTimeout(() => {
      inputRef.current.focus();
    }, 100);
  }, [loadAllTags, openTagsMenu]);

  const closeModal = useCallback(() => {
    closeTagsMenu();

    setSearchValue(null);
    setSelectTagColorMode(false);

    form.resetFields();
    setModalVisible(false);
  }, [closeTagsMenu, form]);

  const handleChangeInputValue = useCallback(
    _.debounce((__, values) => {
      setSearchValue(values.title.trim());

      loadAllTags(values.title, hasSearchChange);
      setInvalidTagTitle(false);
    }, 300),
    []
  );

  const handleChangeSelectTagColorMode = useCallback(() => {
    setSelectTagColorMode(true);
  }, []);

  const handleBackSelectMode = useCallback(() => {
    setSelectTagColorMode(false);
  }, []);

  const handleAddTag = useCallback(
    async (data) => {
      addTag(url, data);
    },
    [addTag, url]
  );

  const handleCreateTag = useCallback(
    async (payload) => {
      if (!isValidLength(payload.title)) {
        setInvalidTagTitle(true);
        return;
      }

      if (!payload.color) {
        setSelectTagColorMode(true);
        return;
      }

      createTag(url, payload);
    },
    [createTag, url]
  );

  const AddTagsModal = useMemo(
    () => (
      <Modal
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        visible={modalVisible}
        footer={null}
        width={480}
        onCancel={closeModal}
      >
        <div className="add-tags-menu">
          <Form
            layout="vertical"
            form={form}
            onFinish={handleCreateTag}
            onValuesChange={handleChangeInputValue}
          >
            <Form.Item
              name="title"
              label={translate(
                'pages.private.profiles.components.addTags.index.addTagsMenu.form.title.label'
              )}
              className="mrg-btm-15"
              validateStatus={invalidTagTitle ? 'error' : ''}
            >
              <Input
                ref={inputRef}
                suffix={<i className="caf-ic_search" />}
                value={searchValue}
                maxLength={30}
                minLength={3}
              />
            </Form.Item>

            <span className="validate-tag-title" hidden={!invalidTagTitle}>
              {translate(
                'pages.private.profiles.components.addTags.index.addTagsMenu.description'
              )}
            </span>

            {!selectTagColorMode &&
              !loadingAllTags &&
              !loadingAddTag &&
              allTagsList.map((tag) => {
                return (
                  <button
                    key={tag?._id}
                    type="button"
                    className="add-tag-btn-container"
                    onClick={() => handleAddTag(tag)}
                  >
                    <ProfileTag
                      addType
                      name={tag?.title}
                      color={tag?.color}
                      special={tag?.special}
                      actionDescription={tag?.actionDescription}
                    />
                  </button>
                );
              })}

            {isValidLength(searchValue) &&
              !selectTagColorMode &&
              !loadingAllTags &&
              !loadingAddTag &&
              allTagsList.length === 0 && (
                <>
                  <div className="empty-result-tags">
                    <span className="mrg-btm-5 mrg-top-5">
                      {translate(
                        'pages.private.profiles.components.addTags.index.addTagsMenu.hasNotTag.before'
                      )}{' '}
                      &quot;{searchValue}&quot;{' '}
                      {translate(
                        'pages.private.profiles.components.addTags.index.addTagsMenu.hasNotTag.after'
                      )}
                    </span>
                  </div>

                  <Divider className="mrg-top-5 mrg-btm-10" />

                  <ButtonAntd
                    type="dashed"
                    color="blue"
                    className="flex-1 gx-w-100 mrg-top-5"
                    onClick={handleChangeSelectTagColorMode}
                  >
                    {translate(
                      'pages.private.profiles.components.addTags.index.addTagsMenu.newTag'
                    )}{' '}
                    &quot;{searchValue}&quot;{' '}
                  </ButtonAntd>
                </>
              )}

            {!selectTagColorMode && (loadingAllTags || loadingAddTag) && (
              <div className="loading-tags-container">
                <Spin />
              </div>
            )}

            {selectTagColorMode && (
              <>
                <div className="add-tags-details">
                  <span className="tag-colors-group-title">
                    {translate(
                      'pages.private.profiles.components.addTags.index.addTagsMenu.tagColor'
                    )}
                  </span>

                  <Form.Item name="color" initialValue={tagColors[0].color}>
                    <Radio.Group className="tag-colors-group" defaultValue="#25b161">
                      {tagColors.map((color) => (
                        <Radio.Button
                          key={color.color}
                          value={color.color}
                          style={{ background: `#${color.color}` }}
                        >
                          <i className="caf-ic_checkmark" />
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </div>

                <Divider className="mrg-top-5 mrg-btm-10" />

                <div className="add-tags-footer-btns">
                  <ButtonAntd
                    ghost
                    className="add-tag-back-btn"
                    onClick={handleBackSelectMode}
                  >
                    {translate(
                      'pages.private.profiles.components.addTags.index.addTagsMenu.button.return'
                    )}
                  </ButtonAntd>

                  <ButtonAntd
                    ghost
                    className="add-tag-create-btn"
                    htmlType="submit"
                    disabled={loadingCreateTag}
                  >
                    {translate(
                      `pages.private.profiles.components.addTags.index.addTagsMenu.button.submit.${
                        loadingCreateTag ? 'loading' : 'default'
                      }`
                    )}
                  </ButtonAntd>
                </div>
              </>
            )}

            {!isValidLength(searchValue) && !selectTagColorMode && (
              <>
                <Divider className="mrg-top-5 mrg-btm-10" />
                <span className="add-tags-info">
                  {translate(
                    'pages.private.profiles.components.addTags.index.addTagsMenu.footer'
                  )}
                </span>
              </>
            )}
          </Form>
        </div>
      </Modal>
    ),
    [
      allTagsList,
      closeModal,
      form,
      handleAddTag,
      handleBackSelectMode,
      handleChangeInputValue,
      handleChangeSelectTagColorMode,
      handleCreateTag,
      invalidTagTitle,
      loadingAddTag,
      loadingAllTags,
      loadingCreateTag,
      modalVisible,
      searchValue,
      selectTagColorMode,
      translate,
    ]
  );

  return { AddTagsModal, openModal };
};

ModalAddTags.propTypes = {
  url: PropTypes.string.isRequired,
};

export default ModalAddTags;
