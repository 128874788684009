import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown, Menu } from 'antd';
import PropTypes from 'prop-types';

import './styles.less';
import { hasRequiredPermissions } from 'routes/permissionFunctions';
import { useAuth } from 'hooks/auth';

const I18N_BASE_PATH =
  'pages.private.blockedDocuments.blockedDocumentsList.components.DropdownOptions.index';
const DropdownRestrictionOptions = ({ register, openCreateDocumentModal }) => {
  const { t: translate } = useTranslation();
  const { user: loggedUser } = useAuth();

  const notHasEditPermission = useMemo(
    () =>
      !hasRequiredPermissions(
        loggedUser,
        ['blocked-documents:update'],
        [],
        false,
        'trust'
      ),
    [loggedUser]
  );

  return (
    <>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item
              disabled={notHasEditPermission}
              key="1"
              onClick={() => openCreateDocumentModal({ data: register, isNew: false })}
            >
              <i className="caf-ic_edit" />
              <span>{translate(`${I18N_BASE_PATH}.Menu.edit`)}</span>
            </Menu.Item>
          </Menu>
        }
        trigger={['click']}
        placement="bottomRight"
      >
        <div style={{ marginLeft: '75%' }} className="btn-more-icon">
          <i className="caf-ic_more-vertical" />
        </div>
      </Dropdown>
    </>
  );
};

DropdownRestrictionOptions.propTypes = {
  register: PropTypes.oneOfType({}).isRequired,
  openCreateDocumentModal: PropTypes.oneOfType({}).isRequired,
};

export default DropdownRestrictionOptions;
