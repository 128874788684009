import React from 'react';
import { useTranslation } from 'react-i18next';
import DossierCard from 'pages/private/GlobalProfiles/components/DossierCard';
import { LoupeDocumentSearch } from '@combateafraude/icons/general';
import ExecutionDossier from 'components/ExecutionDossier';

const BackgroundChecks = () => {
  const { t: translate } = useTranslation();
  return (
    <DossierCard
      title={translate(
        'pages.private.globalProfiles.components.tabs.components.backgroundChecks.title',
        'Background checks'
      )}
      subtitle={translate(
        'pages.private.globalProfiles.components.tabs.components.backgroundChecks.subtitle',
        'All data source queries performed with this profile'
      )}
      icon={<LoupeDocumentSearch />}
    >
      <ExecutionDossier toPrint />
    </DossierCard>
  );
};

export default BackgroundChecks;
