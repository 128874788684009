import React from 'react';
import { Modal, Row, Col } from 'antd';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const ConfirmModal = ({ visible, onClose, onConfirm, loading, partner }) => {
  const { t: translate } = useTranslation();

  return (
    <Modal visible={visible} footer={null} zIndex={51}>
      <Row justify="center" className="mrg-vertical-20">
        <Col>
          <span className="text-bold h1">
            {translate(
              'pages.private.profiles.components.tabs.components.basicInfos.components.company.linkPartnerModal.confirmTitle'
            )}
          </span>
        </Col>
      </Row>
      <Row justify="center" style={{ width: 480, textAlign: 'center', margin: 'auto' }}>
        <Col span={24}>
          <span className="mrg-top-20 h5">
            {translate(
              'pages.private.profiles.components.tabs.components.basicInfos.components.company.linkPartnerModal.confirmMessage'
            )}
          </span>
        </Col>
      </Row>
      <Row align="middle" justify="end" style={{ width: '100%' }} className="mrg-top-25">
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Button outlined={false} onClick={onClose}>
            {translate(
              'pages.private.profiles.components.tabs.components.basicInfos.components.company.linkPartnerModal.cancelAction'
            )}
          </Button>
          <Button type="primary" disabled={loading || !partner} onClick={onConfirm}>
            {translate(
              'pages.private.profiles.components.tabs.components.basicInfos.components.company.linkPartnerModal.confirmAction'
            )}
          </Button>
        </div>
      </Row>
    </Modal>
  );
};
ConfirmModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  partner: PropTypes.object.isRequired,
};

export default ConfirmModal;
