/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import { renderIncomeEstimate } from 'utils/financeFormatters';
import { toMaskedCnpj } from 'utils/formatters';

import PageTitle from 'components/PageTitle';
import DottedLabel from 'components/DottedLabel';

import {
  renderWorkStatus,
  translateAssociationType,
  translateWorkLevel,
} from '../../utils/renderLaborInformation';

import './styles.less';

const PersonalLaborAndFinancialInformation = ({
  data,
  title,
  classOrganization,
  socialAssistance,
}) => {
  const { t: translate } = useTranslation();
  const { i18nFromToDate, i18nFormatCurrency } = useI18nFormatters();

  return (
    <div id="personal-labor-and-financial-information-items">
      <PageTitle title={title} />

      {data?.length > 0 &&
        data?.map((item) => (
          <Row className="personal-labor-and-financial-information-data reset-mrg">
            <Col span={16}>
              <strong>
                {classOrganization &&
                  `${
                    item?.category ||
                    translate(
                      'pages.private.profiles.components.dataSourceModals.personalLaborAndFinancialInformation.components.items.index.items.hasNotCategory'
                    )
                  }:`}

                {socialAssistance &&
                  (item?.programName?.toUpperCase() ||
                    translate(
                      'pages.private.profiles.components.dataSourceModals.personalLaborAndFinancialInformation.components.items.index.items.hasNotProgramName'
                    ))}

                {!classOrganization &&
                  !socialAssistance &&
                  `${translate(translateWorkLevel(item?.level))}:`}
              </strong>

              {!socialAssistance && (
                <strong>
                  {classOrganization &&
                    (item?.organizationName ||
                      translate(
                        'pages.private.profiles.components.dataSourceModals.personalLaborAndFinancialInformation.components.items.index.items.hasNotOrganizationName'
                      ))}

                  {!classOrganization &&
                    (item?.companyName ||
                      translate(
                        'pages.private.profiles.components.dataSourceModals.personalLaborAndFinancialInformation.components.items.index.items.hasNotCompanyName'
                      ))}
                </strong>
              )}

              {item?.level === 'SELF-EMPLOYED' && (
                <strong>CNPJ: {toMaskedCnpj(item?.companyIdNumber)}</strong>
              )}

              <span className="mrg-top-10">
                {i18nFromToDate(
                  item?.startDate ||
                    item?.membershipStartDate ||
                    item?.assistanceStartDate,
                  item?.endDate || item?.membershipEndDate || item?.assistanceEndDate
                )}
              </span>
            </Col>

            <Col span={8} className="personal-labor-and-financial-information-type-infos">
              <strong>
                {classOrganization &&
                  translate(
                    'pages.private.profiles.components.dataSourceModals.personalLaborAndFinancialInformation.components.items.index.items.classOrganization'
                  )}
                {socialAssistance &&
                  translate(
                    'pages.private.profiles.components.dataSourceModals.personalLaborAndFinancialInformation.components.items.index.items.socialAssistance'
                  )}
                {!classOrganization &&
                  !socialAssistance &&
                  translate(
                    'pages.private.profiles.components.dataSourceModals.personalLaborAndFinancialInformation.components.items.index.items.hasNotClassOrganizationAndSocialAssistance'
                  )}
              </strong>

              <span className={`mrg-btm-10 ${socialAssistance && 'mrg-top-10'}`}>
                {classOrganization &&
                  translate(translateAssociationType(item?.organizationType))}

                {socialAssistance &&
                  (i18nFormatCurrency(item?.assistanceAmount, 'pt-BR') ||
                    translate(
                      'pages.private.profiles.components.dataSourceModals.personalLaborAndFinancialInformation.components.items.index.items.assistanceAmountDefault'
                    ))}

                {!classOrganization && !socialAssistance && (
                  <>
                    {item?.income
                      ? i18nFormatCurrency(item?.income, 'pt-BR')
                      : renderIncomeEstimate(item?.incomeRange, translate)}
                  </>
                )}
              </span>

              {!socialAssistance && (
                <DottedLabel
                  description={translate(renderWorkStatus(item?.status)?.title)}
                  type={renderWorkStatus(item?.status)?.type}
                />
              )}
            </Col>
          </Row>
        ))}
    </div>
  );
};

export default PersonalLaborAndFinancialInformation;
