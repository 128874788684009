import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Breadcrumb, Divider, Dropdown, Menu, message, Tooltip } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';
import { useAuth } from 'hooks/auth';
import { useCompany } from 'hooks/company';
import { useFetch } from 'services/hooks';
import { usePerson } from 'hooks/person';
import { useScroll } from 'hooks/scroll';
import { useTags } from 'hooks/tags';
import { useValidations } from 'hooks/validations';

import Button from 'components/Button';
import useModalUpload from 'components/ModalUpload';

// import PermissionWrapper from 'routes/PermissionWrapper';
import { hasRequiredPermissions } from 'routes/permissionFunctions';

import useModalAddTags from 'pages/private/Profiles/components/ModalAddTags';
import useDossierPrint from 'pages/private/Profiles/components/DossierPrint';
// import useManualUploadDocuments from 'pages/private/Profiles/components/ManualUploadDocuments';
import useManualQueryModal from 'pages/private/Profiles/components/ManualQuery';
import useModalProfilesApiGuide from 'components/ModalProfilesApiGuide';
import useRestrictionCreateModal from 'pages/private/Restrictions/components/RestrictionCreateModal';
import { useOnboardingTemplate } from 'hooks/onboardingTemplate';

// import usePeopleUpdate from 'pages/private/Profiles/People/PeopleUpdate';

import profileDefault from 'assets/images/profile-default.png';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.profiles.components.profileSubheader.index';

const ProfileSubheader = ({ profile }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const { company, loadingCompany } = useCompany();
  const { person, loadingPerson } = usePerson();
  const { isAtTop } = useScroll();
  const { loadingValidations } = useValidations();
  const { loadingCustomTags } = useTags();
  const { companyId, personId } = useParams();
  const { loadOnboardingTemplates } = useOnboardingTemplate();
  const { openModal, ProfileApiGuideModal } = useModalProfilesApiGuide();
  const { user } = useAuth();
  const { post: postOnboardingExt } = useFetch();
  const { post: postCallWebhook } = useFetch();

  const matchPeople = useRouteMatch('/profile/people');

  const profileTemplate = useMemo(() => {
    return profile?.profileTemplate;
  }, [profile]);

  const isProfileTemplateActive = useMemo(() => {
    return profileTemplate?.active;
  }, [profileTemplate]);

  // const profileTemplateHasOnboardingServices = useMemo(() => {
  //   return profileTemplate?.onboarding?.services?.length > 0;
  // }, [profileTemplate]);

  const { AddTagsModal, openModal: openAddTagsModal } = useModalAddTags({
    url: `${companyId ? `/companies/${companyId}` : `/people/${personId}`}`,
  });

  const { openModal: openManualQueryModal, ManualQueryModal } = useManualQueryModal({
    profileTemplate,
    user,
    matchPeople,
  });

  // const {
  //   openModal: openManualUploadDocumentsModal,
  //   ManualUploadDocumentsModal,
  // } = useManualUploadDocuments();

  const { openModal: openCardUploadModal, CardUploadModal } = useModalUpload();
  const { openModal: openDossierPrintModal, DossierPrintModal } = useDossierPrint();
  const {
    openModal: openAddToDenyList,
    RestrictionCreateModal,
  } = useRestrictionCreateModal({
    docs: {
      type: profile?.cpf ? 'PF' : 'PJ',
      name: profile?.basicData?.name,
      identification: profile?.cpf || profile?.cnpj,
    },
    inProfile: true,
    url: 'deny-list',
  });

  const hasWebhook = useMemo(
    () => isProfileTemplateActive && profileTemplate?.profileWebhook,
    [isProfileTemplateActive, profileTemplate]
  );

  const handleGenerateLink = useCallback(async () => {
    if (!profile?.profileTemplateId) {
      message.error(
        translate(
          `${I18N_BASE_PATH}.handleGenerateLink.messages.error.hasNotProfileTemplateId`
        )
      );
    } else if (
      profile?.profileTemplate?.type === 'PJ' &&
      !profile?.profileTemplate?.qsaProfileTemplateId
    ) {
      message.warn(
        translate(
          `${I18N_BASE_PATH}.handleGenerateLink.messages.warn.hasNotQsaProfileTemplateId`
        ),
        6
      );
    } else {
      try {
        message.loading({
          content: translate(`${I18N_BASE_PATH}.handleGenerateLink.messages.loading`),
          duration: 0,
          key: 'copyLink',
        });

        const defaultTemplate = await loadOnboardingTemplates();

        const response = await postOnboardingExt({
          url: '/onboardings',
          payload: {
            noExpire: false,
            trustTemplateId: profile?.profileTemplateId || '',
            qsaTemplateId: profile?.profileTemplate?.qsaProfileTemplateId || '',
            templateId:
              profile?.profileTemplate?.onboardingTemplateId ||
              defaultTemplate[profile?.profileTemplate?.type]?._id,
            email: '',
            type: !profile?.cpf
              ? translate(`${I18N_BASE_PATH}.type.company`)
              : translate(`${I18N_BASE_PATH}.type.person`),
            smsPhoneNumber: '',
            attributes: {
              cpf: profile?.cpf || '',
              cnpj: profile?.cnpj || '',
            },
            metadata: { origin: 'TRUST' },
          },
          showMessage: false,
        });

        try {
          navigator.clipboard.writeText(response.onboardingUrl);

          message.success({
            content: translate(`${I18N_BASE_PATH}.handleGenerateLink.messages.success`),
            duration: 2.5,
            key: 'copyLink',
          });
        } catch (error) {
          message.warn({
            content: translate(
              `${I18N_BASE_PATH}.handleGenerateLink.messages.warn.copyLinkWarn`
            ),
            key: 'copyLink',
          });
        }
      } catch (e) {
        message.error({
          content: translate(
            `${I18N_BASE_PATH}.handleGenerateLink.messages.error.copyLinkError`
          ),
          duration: 2.5,
          key: 'copyLink',
        });
      }
    }
  }, [loadOnboardingTemplates, postOnboardingExt, profile, translate]);

  const handleCallWebhook = useCallback(async () => {
    try {
      const baseUrl = `${companyId ? `/companies/${companyId}` : `/people/${personId}`}`;

      await postCallWebhook({
        url: `${baseUrl}/call-webhook`,
        payload: {},
        showMessage: true,
        showError: true,
      });

      message.success(translate(`${I18N_BASE_PATH}.handleCallWebhook.messages.success`));
    } catch (error) {
      //
    }
  }, [companyId, personId, postCallWebhook, translate]);

  const profileData = useMemo(() => {
    return companyId ? company : person;
  }, [companyId, company, person]);

  const profileName = useMemo(() => {
    const officialName = profileData?.basicData?.officialName;
    const fantasyName = profileData?.basicData?.fantasyName;
    const name = profileData?.basicData?.name;

    return officialName || fantasyName || name || '-';
  }, [profileData]);

  const isAtTopClass = useMemo(() => {
    return isAtTop ? 'expand' : 'expand';
  }, [isAtTop]);

  const isLoading = useMemo(() => {
    const loadingProfile = personId ? loadingPerson : loadingCompany;

    return loadingProfile || loadingValidations || loadingCustomTags;
  }, [personId, loadingPerson, loadingCompany, loadingValidations, loadingCustomTags]);

  const standardBreadcrumbs = useMemo(
    () => (
      <Breadcrumb separator="/">
        <Breadcrumb.Item className="breadcrumbs-color">
          <Link
            className="gx-link"
            to={`/profile/${
              personId
                ? `people?_type=${translate(`${I18N_BASE_PATH}.type.person`)}`
                : `companies?_type${translate(`${I18N_BASE_PATH}.type.company`)}`
            }`}
          >
            {translate(
              `${I18N_BASE_PATH}.standardBreadcrumbs.${personId ? 'people' : 'companies'}`
            )}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="breadcrumbs-color">
          {translate(`${I18N_BASE_PATH}.standardBreadcrumbs.footer`)}
        </Breadcrumb.Item>
      </Breadcrumb>
    ),
    [personId, translate]
  );

  const webhookItemContent = useMemo(() => {
    if (hasWebhook) {
      return (
        <>
          <i className="caf-ic_link" />
          {translate(`${I18N_BASE_PATH}.profileSubheader.dropdown.menu.9`)}
        </>
      );
    }

    return (
      <Tooltip
        placement="left"
        title={translate(`${I18N_BASE_PATH}.handleCallWebhook.messages.disabled`)}
      >
        <i className="caf-ic_link" />
        {translate(`${I18N_BASE_PATH}.profileSubheader.dropdown.menu.9`)}
      </Tooltip>
    );
  }, [hasWebhook, translate]);

  // const handleOpenUpdateModal = useCallback(() => {
  //   openPeopleUpdateModal();
  // }, [openPeopleUpdateModal]);

  return (
    <section id="profile-subheader">
      {AddTagsModal}
      {CardUploadModal}
      {DossierPrintModal}
      {/* {ManualUploadDocumentsModal} */}
      {ManualQueryModal}
      {RestrictionCreateModal}
      {ProfileApiGuideModal}
      {/* {CompanyUpdateModal} {PeopleUpdateModal} */}
      <div
        className={`gx-header-horizontal-top ${personId && 'custom-horizontal-pdd'}
        ${isAtTopClass}`}
      >
        <div>
          <div className="profile-subheader-content">
            {personId && (
              // <div
              //   className={`profile-img ${isAtTopClass} ${
              //     !profileData?.image ? 'grayscale-95' : ''
              //   }`}
              //   style={{
              //     backgroundImage: `url(${profileData?.image || profileDefault})`,
              //   }}
              // />
              <img
                className="profile-img"
                src={profileData?.image || profileDefault}
                alt="no file"
              />
            )}

            <div
              className={`profile-info ${personId && 'custom-breadcrumbs'}
              ${isAtTopClass} ant-breadcrumb-separator`}
            >
              {standardBreadcrumbs}
              <h5 className="profile-name max-lines-2">{profileName}</h5>

              <span className="profile-last-update">
                {translate(`${I18N_BASE_PATH}.profileSubheader.updatedAt`)}{' '}
                {i18nFormatDate(
                  profileData?.updatedAt || profileData?.createdAt,
                  'default'
                )}
              </span>
            </div>

            {/* <PermissionWrapper
              requiredPermissions={matchPeople ? ['people:update'] : ['companies:update']}
            >
              <AddTags
                url={`${companyId ? `/companies/${companyId}` : `/people/${personId}`}`}
              />
            </PermissionWrapper> */}

            <Dropdown
              trigger={['click']}
              overlay={
                <Menu id="actions-dropdown-menu-content">
                  {profileTemplate &&
                    hasRequiredPermissions(
                      user,
                      matchPeople ? ['people:update'] : ['companies:update'],
                      []
                    ) && (
                      <>
                        <Menu.Item
                          key="1"
                          disabled={!isProfileTemplateActive}
                          onClick={() => openManualQueryModal()}
                        >
                          <i className="caf-ic_processing_line" />
                          {translate(
                            `${I18N_BASE_PATH}.profileSubheader.dropdown.menu.1`
                          )}
                        </Menu.Item>
                        <Divider className="custom-divider-menu" />
                      </>
                    )}
                  {/* {matchPeople && hasRequiredPermissions(user, ['people:update'], []) && (
                    <Menu.Item
                      key="2"
                      disabled={
                        !isProfileTemplateActive || !profileTemplateHasOnboardingServices
                      }
                      onClick={openManualUploadDocumentsModal}
                    >
                      <i className="caf-ic_onboardind_big" />
                      {translate(
                        `${I18N_BASE_PATH}.profileSubheader.dropdown.menu.2.${
                          profile?.documents?.length > 0
                            ? 'hasDocument'
                            : 'hasNotDocument'
                        }`
                      )}
                    </Menu.Item>
                  )} */}
                  {hasRequiredPermissions(
                    user,
                    matchPeople ? ['people:read'] : ['companies:read'],
                    []
                  ) && (
                    <>
                      <Menu.Item
                        key="callWebhook"
                        disabled={!hasWebhook}
                        onClick={() => handleCallWebhook()}
                      >
                        {webhookItemContent}
                      </Menu.Item>
                      <Divider className="custom-divider-menu" />
                    </>
                  )}
                  {hasRequiredPermissions(
                    user,
                    matchPeople ? ['people:update'] : ['companies:update'],
                    ['files:create']
                  ) && (
                    <Menu.Item key="3" onClick={openCardUploadModal}>
                      <i className="caf-ic_attach" />
                      {translate(`${I18N_BASE_PATH}.profileSubheader.dropdown.menu.3`)}
                    </Menu.Item>
                  )}
                  {hasRequiredPermissions(
                    user,
                    matchPeople ? ['people:update'] : ['companies:update'],
                    []
                  ) && (
                    <Menu.Item key="4" onClick={openAddTagsModal}>
                      <div>
                        <i className="caf-ic_tag" />
                        {translate(
                          'pages.private.profiles.components.addTags.index.dropdownTags.title'
                        )}
                      </div>
                    </Menu.Item>
                  )}
                  {hasRequiredPermissions(user, ['restrictive-list:create'], []) && (
                    <Menu.Item key="7" disabled={isLoading} onClick={openAddToDenyList}>
                      <i className="caf-ic_x_close" />
                      {translate(`${I18N_BASE_PATH}.profileSubheader.dropdown.menu.7`)}
                    </Menu.Item>
                  )}
                  {hasRequiredPermissions(user, ['integration-guide:read'], []) && (
                    <Menu.Item key="8" disabled={isLoading} onClick={openModal}>
                      <i className="caf-ic_code" />
                      {translate(`${I18N_BASE_PATH}.profileSubheader.dropdown.menu.8`)}
                    </Menu.Item>
                  )}
                  {hasRequiredPermissions(
                    user,
                    matchPeople ? ['people:update'] : ['companies:update'],
                    []
                  ) && <Divider className="custom-divider-menu" />}
                  <Menu.Item
                    disabled={!isProfileTemplateActive}
                    key="5"
                    onClick={handleGenerateLink}
                  >
                    <i className="caf-ic_link" />
                    {translate(`${I18N_BASE_PATH}.profileSubheader.dropdown.menu.5`)}
                  </Menu.Item>
                  <Menu.Item key="6" disabled={isLoading} onClick={openDossierPrintModal}>
                    <i className="caf-ic_printer" />
                    {translate(`${I18N_BASE_PATH}.profileSubheader.dropdown.menu.6`)}
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
            >
              <Button type="primary">
                {translate(`${I18N_BASE_PATH}.profileSubheader.button`)}{' '}
                <i className="caf-ic_more-horizontal mrg-left-5" />
              </Button>
            </Dropdown>

            {/* <Button
              className="dossier-print-btn"
              disabled={isLoading}
              onClick={openDossierPrintModal}
            >
              <strong>Imprimir</strong>
            </Button> */}
          </div>
        </div>
      </div>
    </section>
  );
};

ProfileSubheader.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ProfileSubheader;
