import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Col, Modal, Row } from 'antd';
import { useFetch } from 'services/hooks';

import PageTitle from 'components/PageTitle';
import FileUpload from '../Files/FileUpload';

const I18N = 'components.ModalImportProfileTemplate';

const ModalImportProfileTemplate = ({ refreshListRef }) => {
  const { t: translate } = useTranslation();
  const { post, loading } = useFetch();

  const [modalVisible, setModalVisible] = useState(false);
  const [fileUrl, setFileUrl] = useState();

  const openModal = useCallback((_data) => {
    setModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleSubmit = useCallback(async () => {
    await post({
      url: `/profile-templates`,
      payload: {
        importFile: fileUrl,
      },
    });
    closeModal();
    if (refreshListRef) refreshListRef?.current();
  }, [closeModal, fileUrl, post, refreshListRef]);

  const ImportProfileTemplateModal = useMemo(
    () => (
      <Modal
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        visible={modalVisible}
        footer={null}
        width={700}
        onCancel={closeModal}
      >
        <Row>
          <Col span={24}>
            <PageTitle
              title={translate(`${I18N}.PageTitle.title`)}
              subtitle={translate(`${I18N}.PageTitle.subtitle`)}
              style={{ marginBottom: 20 }}
            />
          </Col>
          <Col span={24} className="mrg-vertical-20">
            <FileUpload
              title={translate(`${I18N}.formContent.fileUpload.title`)}
              file={fileUrl}
              onChangeFile={setFileUrl}
              supportedFiles={['.json']}
            />
          </Col>
          <Col span={24} className="flex end">
            <Button
              type="primary"
              htmlType="button"
              onClick={handleSubmit}
              disabled={!fileUrl || loading}
            >
              {translate(`${I18N}.formContent.button`)}
            </Button>
          </Col>
        </Row>
      </Modal>
    ),
    [closeModal, fileUrl, handleSubmit, loading, modalVisible, translate]
  );

  return { openModal, ImportProfileTemplateModal };
};

export default ModalImportProfileTemplate;
