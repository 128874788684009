import moment from 'moment';
import { i18nMomentFormats as momentDateFormats } from 'i18n/utils/i18nFormats';

export function normalizeInvoiceDetails(details, language, companyName) {
  const { invoice, companyNumber, address } = details;

  let formattedAddress = '';
  if (address) {
    const { city, country, line1, postalCode, state } = address;

    if (line1) {
      formattedAddress += line1;
    }
    if (postalCode) {
      if (line1) {
        formattedAddress += ', ';
      }
      formattedAddress += postalCode;
    }
    if (city) {
      if (line1 || postalCode) {
        formattedAddress += ', ';
      }
      formattedAddress += city;
    }
    if (state) {
      if (line1 || postalCode || city) {
        formattedAddress += '/';
      }
      formattedAddress += state;
    }
    if (country) {
      if (line1 || postalCode || city || state) {
        formattedAddress += ', ';
      }
      formattedAddress += country;
    }
  }

  return {
    date: moment(`${invoice.year}-${invoice.month}-${invoice.day}`, 'YYYY-MM-DD')
      .utc()
      .format(momentDateFormats[2][language]),
    companyInfo: {
      address: formattedAddress,
      document: companyNumber,
      name: companyName,
    },
    currencyCode: invoice.paymentCurrencyInfo?.paymentCurrency,
    planName: invoice.planName,
    exportUrl: invoice.exportUrl,
    status: invoice.status,
    products: invoice.items.map((product) => ({
      id: product.productItemKey,
      name: product.productItemName,
      amount: product.totalBill.meterUnits,
      price: product.totalBill?.price,
    })),
    total: invoice.invoiceBillInBaseCurrency?.totalPrice,
    id: invoice.id,
    period: {
      start: moment.utc(invoice.invoiceStartTime).format('MM/DD/YYYY HH:mm'),
      end: moment.utc(invoice.invoiceEndTime).format('MM/DD/YYYY HH:mm'),
    },
  };
}
