/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import DossierCard from 'pages/private/Profiles/components/DossierCard';

import FGTS from './components/FGTS';
import Sintegra from './components/Sintegra';
import CNDT from './components/CNDT';

const BusinessLaborInformation = ({ data, toPrint }) => {
  const { t: translate } = useTranslation();

  const dataSources = useMemo(
    () =>
      data?.profileTemplate?.backgroundChecking?.services?.map(({ source }) => source) ||
      [],
    [data]
  );

  const showFGTS = useMemo(
    () => dataSources.includes('pj_fgts') || data?.certificates?.fgts,
    [data, dataSources]
  );
  const showSintegra = useMemo(
    () => dataSources.includes('pj_sintegra') || data?.certificates?.sintegra,
    [data, dataSources]
  );
  const showCNDT = useMemo(
    () => dataSources.includes('pj_cndt') || data?.certificates?.cndt,
    [data, dataSources]
  );

  return (
    <DossierCard
      customId="labor-infos-component"
      title={translate(
        'pages.private.profiles.components.dataSourceCards.laborInformation.companies.index.companies.dossierCard.title'
      )}
      subtitle={translate(
        'pages.private.profiles.components.dataSourceCards.laborInformation.companies.index.companies.dossierCard.subtitle'
      )}
      icon="caf-ic_awards"
    >
      <Row>
        {showFGTS && <FGTS profile={data} /* urlId={urlId} */ toPrint={toPrint} />}
        {showSintegra && (
          <Sintegra profile={data} /* urlId={urlId} toPrint={toPrint} */ />
        )}
        {showCNDT && <CNDT profile={data} /* urlId={urlId} */ toPrint={toPrint} />}
      </Row>
    </DossierCard>
  );
};

export default BusinessLaborInformation;
