import React, { createContext, useCallback } from 'react';
import { useFetch } from 'services/hooks';

export const WorkflowContext = createContext({});

export const WorkflowProvider = ({ children }) => {
  const { get, loading, data } = useFetch();

  const getWorkflowsData = useCallback(
    async ({ tenantId, _limit }) => {
      await get({
        url: `${process.env.REACT_APP_BASE_URL_WORKFLOW_API}/workflows`,
        config: {
          params: {
            tenantId,
            _limit,
          },
        },
        showMessage: false,
      });
    },
    [get]
  );

  return (
    <WorkflowContext.Provider value={{ getWorkflowsData, loading, data }}>
      {children}
    </WorkflowContext.Provider>
  );
};
