/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';

import { toMaskedCpf } from 'utils/formatters';

import DottedLabel from 'components/DottedLabel';
import useOwnersModal from '../OwnersModal';

import './styles.less';

const OwnersCard = ({ profile, data }) => {
  const { t: translate } = useTranslation();

  const { openModal, OwnersModal } = useOwnersModal({ profile, data });

  const sanctionStatus = useMemo(() => {
    if (!data?.sanctionsHistory) {
      return 'empty';
    }

    if (data?.totalSanctions > 0) {
      return 'danger';
    }

    return 'primary';
  }, [data]);

  const pepStatus = useMemo(() => {
    if (!data?.pepHistory) {
      return 'empty';
    }

    if (data?.pepHistory?.length > 0) {
      return 'danger';
    }

    return 'primary';
  }, [data]);

  return (
    <>
      {OwnersModal}

      <Card id="owners-card-container">
        <div className="owners-card-infos">
          <span className="max-lines-1">{toMaskedCpf(data?.cpf)}</span>

          <div className="owners-card-labels">
            <DottedLabel
              type={pepStatus}
              description={translate(
                'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.owners.ownersCard.index.ownersCard.dottedLabel.pep'
              )}
            />
            <DottedLabel
              type={sanctionStatus}
              description={translate(
                'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.owners.ownersCard.index.ownersCard.dottedLabel.sanction'
              )}
            />
          </div>
        </div>

        <button
          type="button"
          className="owners-card-arrow reset-btn"
          onClick={() => openModal()}
        >
          <i className="caf-ic_arrow_right" />
        </button>
      </Card>
    </>
  );
};

export default OwnersCard;
