import { useMemo } from 'react';

export default function usePagination({ pagination, items }) {
  const paginatedItems = useMemo(() => {
    const startIndex = (pagination.currentPage - 1) * pagination.pageSize;
    const endIndex = startIndex + pagination.pageSize;

    return items.slice(startIndex, endIndex);
  }, [items, pagination]);

  const paginationStart = useMemo(
    () => (pagination.currentPage - 1) * pagination.pageSize + 1,
    [pagination]
  );

  const paginationEnd = useMemo(() => {
    const itemTo = paginationStart + pagination.pageSize - 1;
    return items.length < itemTo ? items.length : itemTo;
  }, [pagination, paginationStart, items]);

  return { paginatedItems, paginationStart, paginationEnd };
}
