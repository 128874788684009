import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { message, Modal, Button } from 'antd';
import PageTitle from 'components/PageTitle';

import api from 'services/api';

const RemoveGroup = ({ group, refreshListRef }) => {
  const { t: translate } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const onRemoveGroup = useCallback(async () => {
    try {
      setLoading(true);
      await api.delete(
        `${process.env.REACT_APP_BASE_URL_AUTH_API}/users-groups/${group?.id}`
      );
      refreshListRef.current();
      closeModal();
      message.success(
        translate(
          'pages.private.users.components.groupsActions.removeGroup.onRemoveGroup.messageSuccess'
        )
      );
    } catch (err) {
      message.error(
        translate(
          'pages.private.users.components.groupsActions.removeGroup.onRemoveGroup.messageError'
        )
      );
    } finally {
      setLoading(false);
    }
  }, [group, refreshListRef, closeModal, translate]);

  const RemoveGroupModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        footer={null}
        width={550}
        closable={false}
        wrapClassName="caf-modal"
        onCancel={closeModal}
      >
        <div className="no-mrg no-pdd">
          <PageTitle
            title={translate(
              'pages.private.users.components.groupsActions.removeGroup.pageTitle.title'
            )}
            align="center"
          />

          <div className="flex center mrg-top-25 mrg-btm-35">
            <span className="text-center font-size-16">
              {translate(
                'pages.private.users.components.groupsActions.removeGroup.spanContent'
              )}
              <strong className="display-block mrg-top-10 text-dark font-size-16">
                {group?.name}
              </strong>
            </span>
          </div>

          <div className="flex center-end">
            <Button
              type="default"
              onClick={closeModal}
              className="btn-cancel"
              disabled={loading}
            >
              {translate(
                'pages.private.users.components.groupsActions.removeGroup.buttons.cancel'
              )}
            </Button>
            <Button
              type="danger"
              onClick={onRemoveGroup}
              disabled={loading}
              loading={loading}
            >
              {loading
                ? translate(
                    'pages.private.users.components.groupsActions.removeGroup.buttons.loading'
                  )
                : translate(
                    'pages.private.users.components.groupsActions.removeGroup.buttons.okText'
                  )}
            </Button>
          </div>
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, group, loading, onRemoveGroup, translate]
  );

  return { openModal, closeModal, RemoveGroupModal };
};

export default RemoveGroup;
