import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Row } from 'antd';

import PageTitle from 'components/PageTitle';

import ContactAndRelationCard from 'pages/private/Profiles/components/ContactAndRelationCard';

const I18N_BASE_PATH =
  'components.dataSourceCards.laborInformation.people.components.businessRelationships.modal';
const AllPersonalAndEconomicRelationships = ({ data }) => {
  const { t: translate } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const AllPersonalAndEconomicRelationshipsModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        onCancel={closeModal}
        footer={null}
        style={{ top: 40 }}
        width={700}
      >
        <div className="custom-dossier-modal no-mrg no-pdd">
          <PageTitle
            title={translate(`${I18N_BASE_PATH}.pageTitle.title`)}
            subtitle={translate(`${I18N_BASE_PATH}.pageTitle.subtitle`, {
              value: data?.length,
            })}
          />

          <Row className="contact-data-container mrg-vertical-5">
            {data?.length > 0 &&
              data?.map((relationship) => (
                <ContactAndRelationCard
                  key={relationship?.entityName || relationship?.relatedEntityName}
                  span={24}
                  relation
                  name={relationship?.entityName || relationship?.relatedEntityName}
                  type={relationship?.relationshipType || relationship?.relationshipType}
                  level={
                    relationship?.relationshipLevel || relationship?.relationshipLevel
                  }
                  taxIdNumber={relationship?.relatedEntityTaxIdNumber}
                  startDate={relationship?.relationshipStartDate}
                  endDate={relationship?.relationshipEndDate}
                  lastUpdateDate={relationship?.lastUpdateDate}
                  className="mrg-top-20 mrg-btm-20 pdd-horizontal-0"
                />
              ))}
          </Row>
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, data, translate]
  );

  return { openModal, closeModal, AllPersonalAndEconomicRelationshipsModal };
};

export default AllPersonalAndEconomicRelationships;
