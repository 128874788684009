/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useMemo, useState } from 'react';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parseISO, formatISO, differenceInHours } from 'date-fns';
import { Tooltip, Card, Row, Col, message } from 'antd'; // message
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  PROFILE_RULES_INITIAL_VALUE,
  QSA_RULES_INITIAL_VALUE,
} from 'hooks/profileTemplate';

import { useFetch } from 'services/hooks';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.notificationCenter.notificationLogger';

const actionTranslatorTitle = {
  CREATE: `${I18N_BASE_PATH}.actionTranslatorTitle.create`,
  UPDATE: `${I18N_BASE_PATH}.actionTranslatorTitle.update`,
  ACTIVATE: `${I18N_BASE_PATH}.actionTranslatorTitle.activate`,
  INACTIVATE: `${I18N_BASE_PATH}.actionTranslatorTitle.inactivate`,
  DOWNLOAD: `${I18N_BASE_PATH}.actionTranslatorTitle.download`,
  WARNING: `${I18N_BASE_PATH}.actionTranslatorTitle.warning`,
};
const recurrenceTranslator = {
  NONE: `${I18N_BASE_PATH}.recurrenceTranslator.none.label`,
  DAILY: `${I18N_BASE_PATH}.recurrenceTranslator.daily.label`,
  WEEKLY: `${I18N_BASE_PATH}.recurrenceTranslator.weekly.label`,
  MONTHLY: `${I18N_BASE_PATH}.recurrenceTranslator.monthly.label`,
  BIMONTHLY: `${I18N_BASE_PATH}.recurrenceTranslator.bimonthly.label`,
  QUARTERLY: `${I18N_BASE_PATH}.recurrenceTranslator.quarterly.label`,
  SEMIANNUALLY: `${I18N_BASE_PATH}.recurrenceTranslator.semiannually.label`,
};

const actionWhenInvalidTranslator = {
  PENDING_SUBMISSION: `${I18N_BASE_PATH}.validationsFormSelector.type.person`,
  REPROVE: `${I18N_BASE_PATH}.validationsFormSelector.actionWhenInvalidOptions.profileRules.default.title.reprove`,
  PENDING: `${I18N_BASE_PATH}.validationsFormSelector.actionWhenInvalidOptions.profileRules.default.title.pending`,
};

const titleTranslator = {
  sources: `${I18N_BASE_PATH}.titleTranslator.sources`,
  backgroundChecking: `${I18N_BASE_PATH}.titleTranslator.backgroundChecking`,
  complianceRules: `${I18N_BASE_PATH}.titleTranslator.complianceRules`,
  profileRules: `${I18N_BASE_PATH}.titleTranslator.profileRules`,
  name: `${I18N_BASE_PATH}.titleTranslator.name`,
  qsaProfileTemplate: `${I18N_BASE_PATH}.titleTranslator.qsaProfileTemplate`,
  templateOnboarding: `${I18N_BASE_PATH}.titleTranslator.onboardingTemplate`,
};

const statusClassName = {
  UPDATE: 'info-link caf-ic_edit_3',
  ADD: 'create-link caf-ic_add',
  REMOVE: 'removed-link caf-ic_remove',
  CREATE: 'create-link caf-ic_add',
};

const NotificationLogger = ({ action, type, data, children, reloadList }) => {
  const { t: translate } = useTranslation();

  const { patch, loading } = useFetch();

  const [isRead, setIsRead] = useState(data?.isRead);

  const isFileExpired = useMemo(() => {
    if (!data?.createdAt) return false;

    const currentDate = parseISO(formatISO(new Date()));
    const dateToCompare = parseISO(formatISO(new Date(data?.createdAt)));

    const difference = differenceInHours(currentDate, dateToCompare);

    if (difference >= 24) {
      return true;
    }

    return false;
  }, [data]);

  const menuItemNotificationItemClasses = useMemo(() => {
    return !isRead ? 'unread' : '';
  }, [isRead]);

  const handleReadNotification = useCallback(async () => {
    await patch({
      url: `/notifications/${data?._id}`,
      payload: {
        isRead: !isRead,
      },
      showMessage: false,
    });

    reloadList();
    setIsRead(!isRead);
  }, [data, patch, isRead, reloadList]);

  const displayDownloads = useMemo(() => {
    return (
      <div
        id="menu-item-notifications-center"
        className={menuItemNotificationItemClasses}
      >
        <div className="notification-item">
          <span className="notification-description">{data?.description || '-'}</span>

          {data?.file && !isFileExpired && (
            <a
              className="notification-download-file"
              href={data.file}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="caf-ic_download" />
              <span>
                {translate(`${I18N_BASE_PATH}.displayDownloads.hasFileAndIsNotExpired`)}
              </span>
            </a>
          )}

          {data?.file && isFileExpired && (
            <div className="notification-expired-file">
              <i className="caf-ic_close" />
              <span>
                {translate(`${I18N_BASE_PATH}.displayDownloads.hasFileAndIsExpired`)}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }, [
    data.description,
    data.file,
    isFileExpired,
    menuItemNotificationItemClasses,
    translate,
  ]);

  const displayTransactionWarning = useMemo(() => {
    let transactionUrl = '';

    if (type === 'TRANSACTION_WARNING') {
      transactionUrl = `${process.env.REACT_APP_BASE_URL_TRUST_WEB_NEW}/executions/${data.data?.after?.transactionId}`;
    }
    const validations = data?.data?.validations;
    return (
      <div
        id="menu-item-notifications-center"
        className={menuItemNotificationItemClasses}
      >
        <div className="notification-item">
          <span className="notification-description">
            {translate(`${I18N_BASE_PATH}.displayTransactionWarning.subtitle.prefix`)}{' '}
            <a href={transactionUrl}>
              {translate(
                `${I18N_BASE_PATH}.displayTransactionWarning.subtitle.transaction`
              )}
            </a>{' '}
            {translate(`${I18N_BASE_PATH}.displayTransactionWarning.subtitle.suffix`)}
          </span>
          {validations &&
            validations.map((validation) => (
              <>
                <strong>
                  {translate(`${I18N_BASE_PATH}.displayTransactionWarning.rules`)}
                </strong>
                <span>{validation.ruleName}</span>
              </>
            ))}
        </div>
      </div>
    );
  }, [data, menuItemNotificationItemClasses, type, translate]);

  const profileRulesInitialValue = useMemo(() => {
    return PROFILE_RULES_INITIAL_VALUE(translate);
  }, [translate]);
  const qsaRulesInitialValue = useMemo(() => {
    return QSA_RULES_INITIAL_VALUE(translate);
  }, [translate]);

  const displayOnProfileCreation = useCallback((d) => {
    const hasOld =
      d?.old?.onProfileCreation === false || d?.old?.onProfileCreation === true;
    const hasNew =
      d?.new?.onProfileCreation === false || d?.new?.onProfileCreation === true;
    return (
      <>
        {d?.old?.onProfileCreation === true && (
          <>
            <i className="caf-ic_checkmark create-link mrg-right-10" /> Sim
          </>
        )}
        {d?.old?.onProfileCreation === false && (
          <>
            <i className="caf-ic_close removed-link mrg-right-10" /> Não
          </>
        )}
        {hasOld && hasNew && d?.old?.onProfileCreation !== d?.new?.onProfileCreation && (
          <i className="caf-ic_arrow-right mrg-horizon-5" />
        )}
        {d?.new?.onProfileCreation === true &&
          d?.old?.onProfileCreation !== d?.new?.onProfileCreation && (
            <>
              <i className="caf-ic_checkmark create-link mrg-right-10" /> Sim
            </>
          )}
        {d?.new?.onProfileCreation === false &&
          d?.old?.onProfileCreation !== d?.new?.onProfileCreation && (
            <>
              <i className="caf-ic_close removed-link mrg-right-10" /> Não
            </>
          )}
      </>
    );
  }, []);

  const displayCustomStatus = useMemo(() => {
    let hasCustomStatus = false;
    data?.data?.complianceRules?.forEach((rule) => {
      if (rule?.new?.customStatus || rule?.old?.customStatus) hasCustomStatus = true;
    });
    data?.data?.profileRules?.forEach((rule) => {
      if (rule?.new?.customStatus || rule?.old?.customStatus) hasCustomStatus = true;
    });
    return hasCustomStatus;
  }, [data]);

  const displayNameChange = useMemo(() => {
    return (
      <div className="mrg-btm-20">
        <Row className="text-dark no-pdd-horizon no-mrg-horizon mrg-btm-5">
          <Col span={24} className="no-pdd-horizon no-mrg-horizon">
            <strong>{translate(titleTranslator?.name)}</strong>
          </Col>
        </Row>
        <Row className="mrg-btm-10 pdd-top-10 no-pdd-horizon no-mrg-horizon">
          <Col span={24} className="flex start-center no-pdd-horizon no-mrg-horizon">
            <i className={`${statusClassName[action]} mrg-right-10`} />
            <span>
              {action === 'UPDATE' && (
                <>
                  {data?.data?.name?.old}
                  <i className="caf-ic_arrow-right mrg-horizon-5" />
                </>
              )}
              {data?.data?.name?.new}
            </span>
          </Col>
        </Row>
      </div>
    );
  }, [action, data, translate]);

  const displayQsaProfileChange = useMemo(() => {
    return (
      <div className="mrg-btm-20">
        <Row className="text-dark no-pdd-horizon no-mrg-horizon mrg-btm-5">
          <Col span={24} className="no-pdd-horizon no-mrg-horizon">
            <strong>{translate(titleTranslator?.qsaProfileTemplate)}</strong>
          </Col>
        </Row>
        <Row className="mrg-btm-10 pdd-top-10 no-pdd-horizon no-mrg-horizon">
          <Col span={24} className="flex start-center no-pdd-horizon no-mrg-horizon">
            <i className={`${statusClassName[action]} mrg-right-10`} />
            <span>
              {action === 'UPDATE' && (
                <>
                  {data?.data?.qsaProfileTemplate?.old ||
                    translate(`${I18N_BASE_PATH}.noQsaOnboarding`)}
                  <i className="caf-ic_arrow-right mrg-horizon-5" />
                </>
              )}
              {data?.data?.qsaProfileTemplate?.new}
            </span>
          </Col>
        </Row>
      </div>
    );
  }, [action, data, translate]);

  const displayTemplateOnboarding = useMemo(() => {
    return (
      <div className="mrg-btm-20">
        <Row className="text-dark no-pdd-horizon no-mrg-horizon mrg-btm-5">
          <Col span={24} className="no-pdd-horizon no-mrg-horizon">
            <strong>{translate(titleTranslator?.templateOnboarding)}</strong>
          </Col>
        </Row>
        <Row className="mrg-btm-10 pdd-top-10 no-pdd-horizon no-mrg-horizon">
          <Col span={24} className="flex start-center no-pdd-horizon no-mrg-horizon">
            <i className={`${statusClassName[action]} mrg-right-10`} />
            <span>
              {action === 'UPDATE' && (
                <>
                  {data?.data?.onboardingTemplate?.old}
                  <i className="caf-ic_arrow-right mrg-horizon-5" />
                </>
              )}
              {data?.data?.onboardingTemplate?.new}
            </span>
          </Col>
        </Row>
      </div>
    );
  }, [action, data, translate]);

  const displayOnboardings = useMemo(() => {
    return (
      <div className="mrg-btm-20">
        <Row className="text-dark no-pdd-horizon no-mrg-horizon mrg-btm-5">
          <Col span={24} className="no-pdd-horizon no-mrg-horizon">
            <strong>{translate(titleTranslator?.sources)}</strong>
          </Col>
        </Row>
        {data?.data?.onboardings?.map((onb, index) => (
          <Row
            className={`mrg-btm-10 pdd-top-10 no-pdd-horizon no-mrg-horizon ${
              index !== 0 && 'border-divider'
            }`}
          >
            <Col span={24} className="flex start-center no-pdd-horizon no-mrg-horizon">
              <i className={`${statusClassName[onb?.action]} mrg-right-10`} />
              {onb?.title}
            </Col>
          </Row>
        ))}
      </div>
    );
  }, [data, translate]);

  const displayComplianceRules = useMemo(() => {
    return (
      <div className="mrg-btm-20">
        <Row className="text-dark no-pdd-horizon no-mrg-horizon mrg-btm-5">
          <Col span={12} className="no-pdd-horizon no-mrg-horizon">
            <strong>{translate(titleTranslator?.complianceRules)}</strong>
          </Col>
          <Col span={6} className="no-pdd-horizon no-mrg-horizon">
            <strong>{translate(`${I18N_BASE_PATH}.columns.action`)}</strong>
          </Col>
          {displayCustomStatus && (
            <Col span={6} className="no-pdd-horizon no-mrg-horizon">
              <strong>{translate(`${I18N_BASE_PATH}.columns.customStatus`)}</strong>
            </Col>
          )}
        </Row>
        {data?.data?.complianceRules?.map((rule, index) => (
          <Row
            className={`mrg-btm-10 pdd-top-10 no-pdd-horizon no-mrg-horizon ${
              index !== 0 && 'border-divider'
            }`}
          >
            <Col span={12} className="flex start-center no-pdd-horizon no-mrg-horizon">
              <i className={`${statusClassName[rule?.action]} mrg-right-10`} />
              {rule?.title}
            </Col>
            <Col span={6} className="flex start-center no-pdd-horizon no-mrg-horizon">
              {!!rule?.old?.actionWhenInvalid &&
                translate(actionWhenInvalidTranslator[rule?.old?.actionWhenInvalid])}
              {!!rule?.old?.actionWhenInvalid &&
                !!rule?.new?.actionWhenInvalid &&
                rule?.old?.actionWhenInvalid !== rule?.new?.actionWhenInvalid && (
                  <i className="caf-ic_arrow-right mrg-horizon-5" />
                )}
              {!!rule?.new?.actionWhenInvalid &&
                rule?.old?.actionWhenInvalid !== rule?.new?.actionWhenInvalid &&
                translate(actionWhenInvalidTranslator[rule?.new?.actionWhenInvalid])}
            </Col>
            {displayCustomStatus && (
              <Col span={6} className="flex start-center no-pdd-horizon no-mrg-horizon">
                {!!rule?.old?.customStatus && rule?.old?.customStatus}
                {!!rule?.old?.customStatus &&
                  !!rule?.new?.customStatus &&
                  rule?.old?.customStatus !== rule?.new?.customStatus && (
                    <i className="caf-ic_arrow-right mrg-horizon-5" />
                  )}
                {!!rule?.new?.customStatus &&
                  rule?.old?.customStatus !== rule?.new?.customStatus &&
                  rule?.new?.customStatus}
              </Col>
            )}
          </Row>
        ))}
      </div>
    );
  }, [data, displayCustomStatus, translate]);

  const displayProfileRules = useMemo(() => {
    return (
      <div className="mrg-btm-20">
        <Row className="text-dark no-pdd-horizon no-mrg-horizon mrg-btm-5">
          <Col span={12} className="no-pdd-horizon no-mrg-horizon">
            <strong>{translate(titleTranslator?.profileRules)}</strong>
          </Col>
          <Col span={6} className="no-pdd-horizon no-mrg-horizon">
            <strong>{translate(`${I18N_BASE_PATH}.columns.action`)}</strong>
          </Col>
          {displayCustomStatus && (
            <Col span={6} className="no-pdd-horizon no-mrg-horizon">
              <strong>{translate(`${I18N_BASE_PATH}.columns.customStatus`)}</strong>
            </Col>
          )}
        </Row>
        {data?.data?.profileRules?.map((rule, index) => (
          <Row
            className={`mrg-btm-10 pdd-top-10 no-pdd-horizon no-mrg-horizon ${
              index !== 0 && 'border-divider'
            }`}
          >
            <Col span={12} className="flex start-center no-pdd-horizon no-mrg-horizon">
              <i className={`${statusClassName[rule?.action]} mrg-right-10`} />
              {profileRulesInitialValue.find((pr) => pr?.rule === rule.title)?.title ||
                qsaRulesInitialValue.find((pr) => pr?.rule === rule.title)?.title}
            </Col>
            <Col span={6} className="flex start-center no-pdd-horizon no-mrg-horizon">
              {!!rule?.old?.actionWhenInvalid &&
                translate(actionWhenInvalidTranslator[rule?.old?.actionWhenInvalid])}
              {!!rule?.old?.actionWhenInvalid &&
                !!rule?.new?.actionWhenInvalid &&
                rule?.old?.actionWhenInvalid !== rule?.new?.actionWhenInvalid && (
                  <i className="caf-ic_arrow-right mrg-horizon-5" />
                )}
              {!!rule?.new?.actionWhenInvalid &&
                rule?.old?.actionWhenInvalid !== rule?.new?.actionWhenInvalid &&
                translate(actionWhenInvalidTranslator[rule?.new?.actionWhenInvalid])}
            </Col>
            {displayCustomStatus && (
              <Col span={6} className="flex start-center no-pdd-horizon no-mrg-horizon">
                {!!rule?.old?.customStatus && rule?.old?.customStatus}
                {!!rule?.old?.customStatus &&
                  !!rule?.new?.customStatus &&
                  rule?.old?.customStatus !== rule?.new?.customStatus && (
                    <i className="caf-ic_arrow-right mrg-horizon-5" />
                  )}
                {!!rule?.new?.customStatus &&
                  rule?.old?.customStatus !== rule?.new?.customStatus &&
                  rule?.new?.customStatus}
              </Col>
            )}
          </Row>
        ))}
      </div>
    );
  }, [
    data,
    displayCustomStatus,
    profileRulesInitialValue,
    qsaRulesInitialValue,
    translate,
  ]);

  const displayBackgroundChecking = useMemo(() => {
    return (
      <div className="mrg-btm-20">
        <Row className="text-dark no-pdd-horizon no-mrg-horizon mrg-btm-5">
          <Col span={12} className="no-pdd-horizon no-mrg-horizon">
            <strong>Background Checking:</strong>
          </Col>
          <Col span={6} className="no-pdd-horizon no-mrg-horizon">
            <strong>{translate(`${I18N_BASE_PATH}.columns.recurrence`)}</strong>
          </Col>
          <Col span={6} className="no-pdd-horizon no-mrg-horizon">
            <strong>{translate(`${I18N_BASE_PATH}.columns.onCreation`)}</strong>
          </Col>
        </Row>
        {data?.data?.backgroundChecking?.map((bac, ind) => (
          <>
            {bac?.items?.map((item, index) => (
              <Row
                className={`mrg-btm-10 pdd-top-10 no-pdd-horizon no-mrg-horizon ${
                  (index !== 0 || ind !== 0) && 'border-divider'
                }`}
              >
                <Col
                  span={12}
                  className="flex start-center no-pdd-horizon no-mrg-horizon"
                >
                  <i className={`${statusClassName[item?.action]} mrg-right-10`} />
                  {item?.title}
                </Col>
                <Col span={6} className="flex start-center no-pdd-horizon no-mrg-horizon">
                  <span>
                    {!!item?.old?.recurrence &&
                      translate(recurrenceTranslator[item?.old?.recurrence])}
                    {!!item?.old?.recurrence &&
                      !!item?.new?.recurrence &&
                      item?.new?.recurrence !== item?.old?.recurrence && (
                        <i className="caf-ic_arrow-right mrg-horizon-5" />
                      )}
                    {!!item?.new?.recurrence &&
                      item?.new?.recurrence !== item?.old?.recurrence &&
                      translate(recurrenceTranslator[item?.new?.recurrence])}
                  </span>
                </Col>
                <Col span={6} className="flex start-center no-pdd-horizon no-mrg-horizon">
                  {displayOnProfileCreation(item)}
                </Col>
              </Row>
            ))}
          </>
        ))}
      </div>
    );
  }, [data, translate, displayOnProfileCreation]);

  const displayContentTransactionTemplate = useMemo(() => {
    return (
      <div className="flex flex-column">
        <div>
          {!!data?.data?.name && displayNameChange}
          {!!data?.data?.onboardingTemplate && displayTemplateOnboarding}
          {!!data?.data?.onboardings?.length && displayOnboardings}
          {!!data?.data?.backgroundChecking?.length && displayBackgroundChecking}
          {!!data?.data?.complianceRules?.length && displayComplianceRules}
          {!!data?.data?.qsaProfileTemplate && displayQsaProfileChange}
          {!!data?.data?.profileRules?.length && displayProfileRules}
          {action === 'UPDATE' &&
            !data?.data?.name &&
            !data?.data?.qsaProfileTemplate &&
            !data?.data?.onboardings?.length &&
            !data?.data?.backgroundChecking?.length &&
            !data?.data?.complianceRules?.length &&
            !data?.data?.profileRules?.length &&
            !data?.data?.onboardingTemplate &&
            translate(`${I18N_BASE_PATH}.noChanges`)}
        </div>
      </div>
    );
  }, [
    action,
    data,
    displayBackgroundChecking,
    displayComplianceRules,
    displayNameChange,
    displayOnboardings,
    displayProfileRules,
    displayQsaProfileChange,
    displayTemplateOnboarding,
    translate,
  ]);

  const displayContentOnboarding = useMemo(() => {
    return (
      <div>
        <Row className="no-pdd-horizon no-mrg-horizon">
          <Col
            span={24}
            className={`${
              action === 'CREATE' ? 'create-link' : 'info-link'
            } flex start-center no-pdd-horizon no-mrg-horizon`}
          >
            <i
              className={`${
                action === 'CREATE' ? 'caf-ic_add' : 'caf-ic_processing_line'
              } mrg-right-10`}
            />
            <a href={data?.data?.url}>{data?.data?.url}</a>
            <CopyToClipboard
              text={data?.data?.url}
              onCopy={() =>
                message.success(
                  translate(`${I18N_BASE_PATH}.copyToClipboard.onCopy.message`)
                )
              }
            >
              <strong className="mrg-left-10 font-size-14 text-bold pointer">
                {translate(`${I18N_BASE_PATH}.copyToClipboard.text`)}
              </strong>
            </CopyToClipboard>
          </Col>
        </Row>
      </div>
    );
  }, [action, data, translate]);

  const displayContents = useMemo(() => {
    let display;
    if (type === 'ONBOARDING') {
      display = <div className="mrg-top-20">{displayContentOnboarding}</div>;
    }
    if (type === 'DOWNLOAD') {
      display = <div className="mrg-top-20">{displayDownloads}</div>;
    }
    if (type === 'profile_exported') {
      display = <div className="mrg-top-20">{displayDownloads}</div>;
    }
    if (type === 'executions_exported') {
      display = <div className="mrg-top-20">{displayDownloads}</div>;
    }
    if (type === 'TRANSACTION_TEMPLATE') {
      display = <div className="mrg-top-20">{displayContentTransactionTemplate}</div>;
    }
    if (type === 'TRANSACTION_WARNING') {
      display = <div className="mrg-top=20">{displayTransactionWarning}</div>;
    }

    return display;
  }, [
    displayContentOnboarding,
    displayContentTransactionTemplate,
    displayDownloads,
    displayTransactionWarning,
    type,
  ]);
  const translateValidation = useMemo(() => {
    if (data?.type === 'ONBOARDING') {
      if (data?.action === 'CREATE') return 'onboarding';
      return 'onboardingUpdated';
    }

    if (data?.type === 'TRANSACTION_TEMPLATE') {
      if (data?.action === 'CREATE') return 'queryTemplate';
      return 'queryTemplateUpdated';
    }

    if (data?.type === 'profile_exported') {
      if (data?.title.includes('pessoas')) return 'exportedPF';
      return 'exportedPJ';
    }

    if (data?.type === 'restrictive_list_imported') return 'exportRestrictive';

    if (data?.type === 'executions_exported') return 'exportedExecution';
    if (data?.type === 'onboarding_statistics_exported') {
      return 'exportedOnboardingStatistics';
    }
    if (data?.type === 'TRANSACTION_WARNING') return 'transactionWarning';
    if (data?.type === 'dashboard_exported') return 'exportedDashboard';

    return '';
  }, [data]);
  return (
    <div id="menu-item-notifications-center" className={menuItemNotificationItemClasses}>
      <Card className="card-new-notification-model">
        <div className="content">
          <Row className="card-new-notification-header no-pdd-horizon no-mrg-horizon">
            <Col span={24} className="no-pdd-horizon no-mrg-horizon">
              <span className="title">{translate(actionTranslatorTitle[action])}</span>
              <strong className="subtitle">
                {translate(
                  `${I18N_BASE_PATH}.notificationTitle.${translateValidation}.title`
                )}
              </strong>
            </Col>
          </Row>

          <Row className="no-pdd-horizon no-mrg-horizon">
            <Col span={22} className="no-pdd-horizon no-mrg-horizon">
              {displayContents}
            </Col>
            <Col span={2} className="col-button">
              <div className="notification-item">
                <div className="notification-title">
                  <span />
                  <Tooltip
                    placement="top"
                    title={translate(
                      `${I18N_BASE_PATH}.notificationItem.tooltip.title.${
                        data?.isRead ? 'isRead' : 'unread'
                      }`
                    )}
                  >
                    <button
                      type="button"
                      className="reset-btn"
                      onClick={handleReadNotification}
                    >
                      {loading && <i className="caf-ic_rotate-cw loading-icon" />}
                      {!loading && (
                        <i className={isRead ? 'caf-ic_check_d' : 'caf-ic_eye'} />
                      )}
                    </button>
                  </Tooltip>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {children}
      </Card>
    </div>
  );
};

NotificationLogger.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  type: PropTypes.string.isRequired,
  reloadList: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.any),
};

NotificationLogger.defaultProps = {
  title: undefined,
  action: undefined,
  reloadList: undefined,
  data: {},
};

export default NotificationLogger;
