import React from 'react';
import ReactSpeedometer from 'react-d3-speedometer';

const SpeedometerRisk = ({ value }) => (
  <ReactSpeedometer
    width={200}
    height={80}
    segmentColors={['#39C560', '#F8B239', '#E21B45']}
    segments={3}
    maxSegmentLabels={0}
    dimensionUnit="px"
    needleHeightRatio={0.7}
    maxValue={3}
    minValue={1}
    value={value}
    paddingHorizontal={4}
    paddingVertical={34}
    forceRender
    needleTransitionDuration={0}
    needleColor="#77787c"
    currentValueText=""
    currentValuePlaceholderStyle=""
  />
);

export default SpeedometerRisk;
