/* eslint-disable no-unused-vars */
import React from 'react';
import { CardWrapper } from 'components/CardWrapper';

import Wrapper from '../wrapper';

import './styles.less';

const SecurityAndPrivacy = ({ children }) => {
  return (
    <Wrapper id="security-and-privacy-component">
      <CardWrapper>
        <div className="site-card-border-less-wrapper">{children}</div>
      </CardWrapper>
    </Wrapper>
  );
};

export default SecurityAndPrivacy;
