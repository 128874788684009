import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import EmptyMessage from 'components/EmptyMessage';
import { useTranslation } from 'react-i18next';

const XLSTable = ({ src }) => {
  const { t: translate } = useTranslation();

  const officeappsSrc = useMemo(() => {
    if (!src) return null;

    return `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(src)}`;
  }, [src]);

  if (!officeappsSrc)
    return <EmptyMessage description={translate(`components.PreviewModal.noData`)} />;

  return (
    <iframe
      title="XLS Preview"
      src={officeappsSrc}
      width="100%"
      height="565px"
      frameBorder="0"
    />
  );
};

XLSTable.propTypes = {
  src: PropTypes.string,
};

XLSTable.defaultProps = {
  src: null,
};

export default XLSTable;
