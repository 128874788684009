const I18N_BASE_PATH = 'components.dataSourceCards.vehicleInfosModal.columns';

/**
 *  Mapear os campos que deseja exibir,
 *  por padrao as labels sao mapeadas com a mesma key no i18n
 * */

export const FIRST_SECTION_FIELDS = [
  'manufactureYear',
  'modelYear',
  'cmc',
  'cmt',
  'bodyworkTypeId',
  'bodyworkTypeDescription',
  'categoryId',
  'categoryDescription',
  'chassiSerial',
  'engineCapacity',
  'fuelId',
  'fuelDescription',
  'colorId',
  'colorDescription',
  'speciesId',
  'speciesDescription',
  'alarmIndication',
  'burglaryTheftIndicator',
  'vehicleCapacity',
];

export const SECOND_SECTION_FIELDS = [
  'power',
  'vehicleTypeType',
  'vehicleTypeDescription',
  'stateJurisdiction',
  'limitDate',
  'licensePlateMunicipalityId',
  'quantityAxles',
  'pbt',
  'plate',
  'licensePlateMunicipalityDescription',
];

export const THIRD_SECTION_FIELDS = [
  'salesCommunicationIndicator',
  'publicSaleIndicator',
  'trafficTicketRenainfIndicator',
  'pendingIssueIndicator',
  'origin',
  'tenantTypeId',
  'bodyworkNumber',
  'chassisMarkupId',
  'chassisMarkupDescription',
  'dischargeDate',
  'crvIssueDate',
  'lastUpdateDate',
  'mreUpdateDate',
  'importDistDate',
  'rfbPartId',
  'rfbPartDescription',
  'auxiliaryAxeNumber',
  'rearAxeNumber',
  'billOrigin',
  'stateBilling',
];

const ALL_MAPPED_FIELDS = [
  ...FIRST_SECTION_FIELDS,
  ...SECOND_SECTION_FIELDS,
  ...THIRD_SECTION_FIELDS,
];

export function isMapped(key) {
  if (ALL_MAPPED_FIELDS.includes(key)) return `${I18N_BASE_PATH}.${key}`;
  return null;
}
