import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useQueryParams, StringParam, withDefault, ArrayParam } from 'use-query-params';
import _ from 'lodash';

import SearchFilter from 'components/Filters/SearchFilter';
import TagsFilter from 'components/Filters/TagsFilter';
import UpdatedAtFilter from 'components/Filters/UpdatedAtFilter';
import ResetFilters from 'components/Filters/ResetFilters';
import StatusFilter from 'components/Filters/StatusFilter';
import RestrictiveListFilter from 'components/Filters/RestrictiveListFilter';
import ProfileTemplatesFilter from 'components/Filters/ProfileTemplatesFilter';

import CustomerCustomizationWrapper from 'components/CustomerCustomizationWrapper';

import { UnderscoreObjectParam } from 'utils/customObjectParam';

import './styles.less';

const Filter = ({ matchPeople }) => {
  const { t: translate } = useTranslation();

  const [query, setQuery] = useQueryParams({
    updatedDate: StringParam,
    search: withDefault(ArrayParam, []),
    tags: withDefault(ArrayParam, []),
    status: withDefault(ArrayParam, []),
    profileTemplates: withDefault(UnderscoreObjectParam, {}),
    _order: StringParam,
    _sort: StringParam,
    _internalRestrictiveList: StringParam,
  });

  const setFilter = useCallback(
    (filterName, event) => {
      let queryParams = {
        [filterName]: event && !_.isEmpty(event) ? event : undefined,
      };

      if (filterName === 'updatedDate' && Array.isArray(event)) {
        const startDate = event[0]
          .utcOffset(0)
          .set({ second: 0, millisecond: 0 })
          .valueOf();
        const endDate = event[1]
          .utcOffset(0)
          .set({ second: 59, millisecond: 0 })
          .valueOf();

        queryParams = {
          [filterName]: `custom:${startDate}-${endDate}`,
        };
      }

      setQuery(queryParams);
    },
    [setQuery]
  );

  const resetFilters = useCallback(() => {
    setQuery({
      updatedDate: undefined,
      search: undefined,
      tags: undefined,
      status: undefined,
      _internalRestrictiveList: undefined,
      profileTemplates: undefined,
    });
  }, [setQuery]);

  return (
    <div id="profile-filter-component">
      <SearchFilter
        placeholder={translate(
          `pages.private.profiles.components.list.components.filter.index.filter.searchFilter.placeholder.${
            matchPeople ? 'person' : 'company'
          }`
        )}
        value={query.search}
        className="profile-select"
        onChange={(values) => setFilter('search', values)}
      />

      <ProfileTemplatesFilter
        value={query.profileTemplates}
        title={translate(
          'pages.private.profiles.components.list.components.filter.index.filter.profileTemplatesFilter.title'
        )}
        onChange={(values) => setFilter('profileTemplates', values)}
      />

      <UpdatedAtFilter
        title={translate(
          'pages.private.profiles.components.list.components.filter.index.filter.updatedAtFilter.title'
        )}
        value={query.updatedDate}
        onChange={(values) => setFilter('updatedDate', values)}
      />

      <TagsFilter value={query.tags} onChange={(values) => setFilter('tags', values)} />

      <StatusFilter
        value={query.status}
        onChange={(values) => setFilter('status', values)}
        matchPeople={matchPeople}
      />

      <CustomerCustomizationWrapper customer="SKY" extraCondition={matchPeople}>
        <RestrictiveListFilter
          value={query._internalRestrictiveList}
          onChange={(values) => setFilter('_internalRestrictiveList', values)}
        />
      </CustomerCustomizationWrapper>

      <ResetFilters onClick={resetFilters} />
    </div>
  );
};

Filter.propTypes = {
  matchPeople: PropTypes.bool.isRequired,
};

export default Filter;
