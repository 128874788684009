/* eslint-disable react/forbid-prop-types */
import { Collapse } from 'antd';
import React from 'react';
import CollapseTitle from 'components/CollapseHeader/CollapseTitle';
import PropTypes from 'prop-types';

function DataSourceCardCollapsePanel({
  children,
  title,
  icon,
  panelProps,
  toPrint,
  extra,
  classNameCustom,
  customIcon,
  customHeader = undefined,
  ...props
}) {
  return (
    <Collapse
      className="mrg-top-10 mrg-btm-10"
      expandIconPosition="right"
      {...props}
      defaultActiveKey={toPrint ? ['1'] : null}
    >
      <Collapse.Panel
        {...panelProps}
        header={
          customHeader || (
            <CollapseTitle
              title={title}
              icon={icon}
              customIcon={customIcon}
              extra={extra}
              classNameCustom={classNameCustom}
            />
          )
        }
        key="1"
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  );
}

DataSourceCardCollapsePanel.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  panelProps: PropTypes.any,
  extra: PropTypes.any,
  toPrint: PropTypes.bool,
  classNameCustom: PropTypes.string,
  customHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

DataSourceCardCollapsePanel.defaultProps = {
  title: undefined,
  icon: undefined,
  panelProps: {},
  extra: undefined,
  toPrint: false,
  customHeader: undefined,
  classNameCustom: '',
};

export default DataSourceCardCollapsePanel;
