import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { useHistory } from 'react-router-dom';
import { CardWrapper } from 'components/CardWrapper';

import Wrapper from '../wrapper';
import UsersList from '../UserManagement/UsersList';
import GroupsList from '../GroupsManagement/GroupsList';

const { TabPane } = Tabs;

const UsersAndGroupsTab = () => {
  const history = useHistory();
  const { t: translate } = useTranslation();

  const tabHeader = useCallback(
    (title, icon) => (
      <div className="flex align-center text-dark">
        <i className={`${icon} font-size-22 mrg-right-5`} />
        <strong className="text-semibold font-size-16">{title}</strong>
      </div>
    ),
    []
  );

  const handleTabClick = useCallback(
    (key) => {
      history.push(`${key}`);
    },
    [history]
  );

  return (
    <>
      <Wrapper id="user-and-group-tab">
        <CardWrapper hasPadding>
          <Tabs onChange={handleTabClick} defaultActiveKey={history.location.pathname}>
            <TabPane
              tab={tabHeader(
                translate('pages.private.usersAndGroupsTab.index.users'),
                'caf-ic_users'
              )}
              key="/users"
            >
              <UsersList />
            </TabPane>
            <TabPane
              tab={tabHeader(
                translate('pages.private.usersAndGroupsTab.index.groups'),
                'caf-ic_users'
              )}
              key="/groups"
            >
              <GroupsList />
            </TabPane>
          </Tabs>
        </CardWrapper>
      </Wrapper>
    </>
  );
};

export default UsersAndGroupsTab;
