import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import currencyFormatter from 'utils/currencyFormatter';
import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { i18nMomentFormats as dateFormats } from 'i18n/utils/i18nFormats';
import { ArrowRight1 } from '@combateafraude/icons/general';
import { Link } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';

import { List, Button, DatePicker } from 'antd';

import useAmberflo from '../../hooks/useAmberflo';

import './styles.less';

const Tags = {
  OPEN: 'open',
  ACTION_REQUIRED: 'actionRequired',
  PENDING: 'pending',
  FAILED: 'failed',
  SETTLED: 'settled',
  CANCELED: 'canceled',
};

const { RangePicker } = DatePicker;

const InvoiceList = ({ hidePrice }) => {
  const [invoicesList, setInvoicesList] = useState([]);
  const [selectedDates, setSelectedDates] = useState();
  const [date, setDate] = useState([
    moment().startOf('month').subtract(3, 'months'),
    moment().startOf('month'),
  ]);
  const { t: translate, i18n } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const { fetchInvoicesList, loadingInvoicesList } = useAmberflo();

  const disabledDate = useCallback(
    (current) => {
      if (!current) return;

      if (current > moment()) {
        return true;
      }

      if (date) {
        return (
          (date[0] && current > moment(date[0]).add(12, 'months')) ||
          (date[1] && current < moment(date[1]).subtract(12, 'months'))
        );
      }

      if (selectedDates) {
        return (
          (selectedDates[0] && current > moment(selectedDates[0]).add(12, 'months')) ||
          (selectedDates[1] && current < moment(selectedDates[1]).subtract(12, 'months'))
        );
      }
    },
    [date, selectedDates]
  );

  const handleRangeSelectDateChange = (dates) => {
    setSelectedDates(dates);
  };

  const handleRangeSelectOpenChange = (open) => {
    if (open) {
      setSelectedDates([null, null]);
    } else {
      setSelectedDates(null);
    }
  };

  useEffect(() => {
    if (date) {
      fetchInvoicesList(
        moment(date[0]).format('YYYY-MM-DD'),
        moment(date[1]).format('YYYY-MM-DD')
      ).then((result) => setInvoicesList(result));
    }
  }, [date, fetchInvoicesList]);

  return (
    <div
      className={classNames('invoice-list', {
        'invoice-list_hide-price': hidePrice,
      })}
    >
      <div className="invoice-list__filter">
        <RangePicker
          value={date}
          onChange={setDate}
          disabledDate={disabledDate}
          onCalendarChange={handleRangeSelectDateChange}
          format={dateFormats[4][i18n.language]}
          picker="month"
          onOpenChange={handleRangeSelectOpenChange}
        />
      </div>
      <List
        className="invoice-list__list"
        dataSource={invoicesList}
        itemLayout="horizontal"
        split={false}
        bordered={false}
        loading={loadingInvoicesList}
        header={
          <List.Item className="invoice-list__list-header">
            <p>
              <strong>
                {translate('pages.private.consumption.invoicesList.invoiceDate')}
              </strong>
            </p>
            {!hidePrice && (
              <p>
                <strong>
                  {translate('pages.private.consumption.invoicesList.totalAmount')}
                </strong>
              </p>
            )}
            <p id="period">
              <strong>
                {translate('pages.private.consumption.invoicesList.period')}
              </strong>
            </p>
            {/* {!hidePrice && (
              <p>
                <strong>
                  {translate('pages.private.consumption.invoicesList.status')}
                </strong>
              </p>
            )} */}
          </List.Item>
        }
        renderItem={(item) => (
          <List.Item key={item.id} className="invoice-list__item ">
            <p>{item.date}</p>
            {!hidePrice && (
              <p>
                {currencyFormatter.toCurrency(
                  item.totalAmount,
                  currencyFormatter.currencyCodeToSymbol(item.currencyCode),
                  currencyFormatter.currencyCodeToLocale(item.currencyCode)
                )}
              </p>
            )}
            <p id="period">
              {i18nFormatDate(item.period.start, 1)} -{' '}
              {i18nFormatDate(item.period.end, 1)}
            </p>
            {/* {!hidePrice && (
              <>
                {Tags[item.status] ? (
                  <p
                    className={`invoice-list__tag invoice-list__tag_${Tags[item.status]}`}
                  >
                    {translate(
                      `pages.private.consumption.invoicesList.status.${Tags[item.status]}`
                    )}
                  </p>
                ) : (
                  <p>-</p>
                )}
              </>
            )} */}
            {item.layout === 'NEW' ? (
              <p className="invoice-list__tag invoice-list__tag_layout-tag">
                {translate('pages.private.consumption.invoicesList.newLayout')}
              </p>
            ) : (
              <p />
            )}
            <Link to={`/consumption/${item.id}`}>
              <Button
                icon={<ArrowRight1 />}
                type="link"
                className="invoice-list__button invoice-list__button_hide-sm invoice-list__button_reverse"
              >
                {translate('pages.private.consumption.invoicesList.openInvoice')}
              </Button>
            </Link>
          </List.Item>
        )}
      />
    </div>
  );
};

InvoiceList.propTypes = {
  hidePrice: PropTypes.bool,
};

InvoiceList.defaultProps = {
  hidePrice: false,
};

export default InvoiceList;
