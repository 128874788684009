import { Card, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import copyTextToClipboard from 'utils/copyTextToClipboard';
import { useI18nFormatters } from 'hooks/i18nFormatters';

import { toMaskedCpf, toFormatDate } from 'utils/formatters';

import Tag from 'components/Tag';

import './styles.less';
import Button from 'components/Button';
import useRemoveWhiteListModal from './components/RemoveWhiteListModal';

const I18N_BASE_PATH =
  'pages.private.liveness.whiteListTab.components.cardWhiteList.index';

const WhiteListCard = ({ data, reloadList }) => {
  const { t: translate, i18n } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const {
    openModal: openRemoveWhiteListModal,
    RemoveWhiteListModal,
  } = useRemoveWhiteListModal({ data, reloadList });

  return (
    <>
      {RemoveWhiteListModal}
      <Card className="white-list-card" type="inner">
        <div className="card-info-content">
          <div className="card-info">
            <div className="card-info-data">
              <div className="card-info-simple font-size-22 text-bold">
                <span className="">{translate(`${I18N_BASE_PATH}.name`)}:</span>
                <Tooltip title={data?.name}>
                  <span className="pdd-left-5 card-info-simple-content">
                    {data?.name}
                  </span>
                </Tooltip>
              </div>

              <div className="card-info-simple">
                <span>{translate(`${I18N_BASE_PATH}.cpf`)}:</span>
                <span className={`pdd-left-5 ${data?.personId ? 'text-bold' : ''}`}>
                  {toMaskedCpf(data?.personId) || translate(`${I18N_BASE_PATH}.noCpf`)}
                </span>
              </div>

              {data?.trackingId && (
                <div className="card-info-simple">
                  <span>{translate(`${I18N_BASE_PATH}.trackingID`)}:</span>
                  <Tooltip title={data?.trackingId}>
                    <span className="pdd-left-5 text-bold card-info-simple-content">
                      {data?.trackingId}
                    </span>
                  </Tooltip>

                  <Tooltip title="Copiar Traking ID">
                    <Button
                      type="link"
                      style={{ paddingLeft: 0 }}
                      onClick={() =>
                        copyTextToClipboard(
                          data?.trackingId,
                          translate(`${I18N_BASE_PATH}.copyButton.trackingCopySuccess`),
                          translate(`${I18N_BASE_PATH}.copyButton.trackingCopyFail`)
                        )
                      }
                    >
                      <i className="caf-ic_duplicate" />
                    </Button>
                  </Tooltip>
                </div>
              )}
            </div>

            <div className="card-info-status">
              <Tag className="tag-expiration-white-list">
                <i className="caf-ic_clock" />
                <span>
                  {data?.expireDate
                    ? translate(`${I18N_BASE_PATH}.tag.whiteListExpires`, {
                        time: toFormatDate(data?.expireDate, i18n?.language, translate),
                      })
                    : translate(`${I18N_BASE_PATH}.tag.whiteListTimeLess`)}
                </span>
              </Tag>
              <div className="card-info-status-content">
                <span className="font-size-12">{`${translate(
                  `${I18N_BASE_PATH}.validatedAt`
                )} ${i18nFormatDate(data?.createdAt, 4)}`}</span>
              </div>
              <Button
                className="btn-remove"
                type="text"
                onClick={openRemoveWhiteListModal}
              >
                {translate(`${I18N_BASE_PATH}.button.removeWhiteList`)}
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

WhiteListCard.propTypes = {
  data: PropTypes.node.isRequired,
  reloadList: PropTypes.func.isRequired,
};

export default WhiteListCard;
