import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';

import { Row, Card } from 'antd';
import Labelcol from '../components/Labelcol';
import { status } from '../utils/documentAnalysisStatus';

const I18N_BASE_PATH =
  'pages.private.executions.components.tabs.components.documentsValidations.components.shared.validationsDocumentscopyCard.index.validationsDocumentscopyCard';

const ManualReview = ({ data = {} }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const showReasons = useMemo(
    () =>
      data?.evaluation?.length > 0 && data?.evaluation?.some((reason) => reason.message),
    [data]
  );

  return (
    <Row
      gutter={[32, 0]}
      className={showReasons ? 'no-border-right-col-4' : 'no-border-right-col-3'}
    >
      <Labelcol
        span={8}
        title={translate(`${I18N_BASE_PATH}.tableInfoCard.seem.title`)}
        result={translate(status(data?.status)?.title)}
        type={status(data?.status).type}
      />
      <Labelcol
        span={8}
        title={translate(`${I18N_BASE_PATH}.tableInfoCard.fraud.title`)}
        result={translate(
          `${I18N_BASE_PATH}.tableInfoCard.fraud.content.${data?.fraud ? 'yes' : 'no'}`
        )}
      />
      <Labelcol
        span={8}
        title={translate(`${I18N_BASE_PATH}.tableInfoCard.date.title`)}
        result={i18nFormatDate(data?.reviewDate, 1)}
      />
      {showReasons && (
        <Labelcol
          span={24}
          title={translate(`${I18N_BASE_PATH}.tableInfoCard.reasons.title`)}
          result={
            <Card id="validations-documentscopy-card">
              {data?.evaluation?.map((reason) => (
                <div className="validations-documentscopy-data">
                  <span className="no-mrg">{reason?.message}</span>
                </div>
              ))}
            </Card>
          }
        />
      )}
    </Row>
  );
};

export default ManualReview;
