/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Row, Typography } from 'antd';

import DottedLabel from 'components/DottedLabel';
import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';

import { toFormatDistance } from 'utils/formatters';
import { mapSuspiciousPlacesStatus } from './utils';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

import './styles.less';

const { Text } = Typography;

const I18N_BASE_PATH = 'components.dataSourceCards.suspiciousPlaces.index';

const SuspiciousPlaces = ({ data = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const source = useMemo(() => data?.sections?.suspiciousPlaces, [data]);

  const parameters = useMemo(() => data?.parameters?.coordinates, [data]);

  const sourceValidation = useMemo(
    () => data?.validations?.find((v) => v.code === 'is_close_to_suspicious_places'),
    [data]
  );

  return (
    <DataSourceCardCollapsePanel
      title={translate(`${I18N_BASE_PATH}.title`)}
      icon="caf-ic_map_pin"
      customId="restrictiveLists"
      toPrint={toPrint}
    >
      <div id="suspicious-places-content">
        <Text>{translate(`${I18N_BASE_PATH}.description`)}</Text>
        <Row className="mrg-top-40">
          <Col flex="100px">
            <ColumnTitleValue
              className="single-line"
              title={translate(`${I18N_BASE_PATH}.columns.latitude`)}
              value={parameters?.latitude}
              span={24}
            />
          </Col>
          <Col flex="100px">
            <ColumnTitleValue
              className="single-line"
              title={translate(`${I18N_BASE_PATH}.columns.longitude`)}
              value={parameters?.longitude}
              span={24}
            />
          </Col>
          <Col flex="auto">
            <DottedLabel
              className="text-bold float-right"
              type={mapSuspiciousPlacesStatus(sourceValidation?.resultStatus).type}
              description={translate(
                `${I18N_BASE_PATH}.status.${
                  mapSuspiciousPlacesStatus(sourceValidation?.resultStatus)?.status
                }`
              )}
            />
          </Col>
        </Row>
        {source?.data?.isNearTo && (
          <>
            <Divider className="mrg-top-5 mrg-btn-5" />
            <Row className="mrg-top-10">
              <ColumnTitleValue
                title={source.data.name}
                span={24}
                style={{ fontSize: '18px' }}
              />
              <ColumnTitleValue
                className="single-line"
                title={translate(`${I18N_BASE_PATH}.columns.latitude`)}
                value={source?.data?.latitude}
                span={24}
              />
              <ColumnTitleValue
                className="single-line"
                title={translate(`${I18N_BASE_PATH}.columns.longitude`)}
                value={source?.data?.longitude}
                span={24}
              />
              <ColumnTitleValue
                className="single-line"
                title={translate(`${I18N_BASE_PATH}.columns.uf`)}
                value={source?.data?.state}
                span={24}
              />
              <ColumnTitleValue
                className="single-line"
                title={translate(`${I18N_BASE_PATH}.columns.distance`)}
                value={toFormatDistance(source?.data?.distance, translate)}
                span={24}
              />
            </Row>
          </>
        )}
      </div>
    </DataSourceCardCollapsePanel>
  );
};

export default SuspiciousPlaces;
