import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CompanyRow,
  CompanyCard,
  CompanyLink,
  CompanySubtitle1,
  CompanyComment,
} from 'pages/private/KYBCompany/CompanyDetailsV2/components';
import PropTypes from 'prop-types';
import NotAvailable from 'components/NotAvailable';

const I18N_BASE_PATH = 'pages.private.KYBCompany.pages';
const SUMMARY = `${I18N_BASE_PATH}.companyDetails.subPages.summary`;
const COMMENTS = `${SUMMARY}.cards.comments`;

const Comments = ({ summary, disabled, lg = 8, print = false }) => {
  const { t: translate } = useTranslation();

  return (
    <Col xs={print ? lg : 24} lg={lg}>
      <CompanyCard className="kyb-company-summary-group-card kyb-company-print-break-avoid">
        <Row>
          <Col span={24}>
            <CompanySubtitle1>
              {translate(`${COMMENTS}.title`)} ({summary?.comments?.length || '0'})
            </CompanySubtitle1>
          </Col>
        </Row>
        {summary.comments?.length ? (
          <>
            <CompanyRow className="kyb-company-summary-group-content">
              {summary?.comments?.slice(0, 2).map((comments) => (
                <CompanyComment
                  col={[4, 20]}
                  type={comments?.indicator}
                  text={comments?.text}
                />
              ))}
            </CompanyRow>
            <Row justify="end">
              <Col className="kyb-company-summary-footer">
                <CompanyLink to="?tab=comments">
                  {translate(`${I18N_BASE_PATH}.companyDetails.mainCardLink`)}
                </CompanyLink>
              </Col>
            </Row>
          </>
        ) : (
          <NotAvailable card />
        )}
      </CompanyCard>
    </Col>
  );
};

Comments.propTypes = {
  summary: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  print: PropTypes.bool.isRequired,
  lg: PropTypes.number.isRequired,
};

export default Comments;
