import React, { useCallback, useMemo, useState } from 'react';
import { Button, Modal, Typography } from 'antd';
import { useFetch } from 'services/hooks';
import { useAuth } from 'hooks/auth';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const I18N_BASE_PATH =
  'components.modalUpdateProfile.updateProfileModal.form.button.changePassword.modalConfirm';

const { Title, Text } = Typography;

const ChangePassword = () => {
  const { post, loading } = useFetch();
  const { user, signOut } = useAuth();
  const { history } = useHistory();

  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(false);

  const openModal = useCallback(() => setIsVisible(true), []);
  const closeModal = useCallback(() => setIsVisible(false), []);

  const resetPassword = useCallback(async () => {
    await post({
      url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/forgot-password`,
      payload: { email: user?.username },
    });

    signOut();

    window.location.assign(
      `${process.env.REACT_APP_BASE_URL_AUTH_WEB_NEW}/change-password`
    );
  }, [user, post, signOut]);

  const ChangePasswordModal = useMemo(
    () => (
      <Modal
        closable
        centered
        visible={isVisible}
        onCancel={closeModal}
        width={600}
        footer={false}
      >
        <div align="middle" className="mrg-top-80 mrg-horizon-40">
          <Title className="font-size-32">{t(`${I18N_BASE_PATH}.title`)}</Title>
          <Text>{t(`${I18N_BASE_PATH}.content`)}</Text>
        </div>

        <div className="gx-flex-row gx-justify-content-end mrg-top-40">
          <Button type="default" className="no-border" onClick={closeModal}>
            {t(`${I18N_BASE_PATH}.cancelText`)}
          </Button>
          <Button type="primary" loading={loading} onClick={resetPassword}>
            {t(`${I18N_BASE_PATH}.okText`)}
          </Button>
        </div>
      </Modal>
    ),

    [closeModal, isVisible, resetPassword, t, loading]
  );

  return { openModal, ChangePasswordModal };
};

export default ChangePassword;
