const I18N_BASE_PATH =
  'pages.private.profiles.components.dossierPrint.utils.checkboxOptions.backgroudChecking';
const peopleOptions = [
  {
    _id: 'basic_infos',
    label: `${I18N_BASE_PATH}.basicInfos`,
    icon: 'caf-ic_face',
    order: 1,
  },
  {
    _id: 'contact',
    label: `${I18N_BASE_PATH}.contact`,
    icon: 'caf-ic_contact_',
    order: 2,
  },
  {
    _id: 'finance',
    label: `${I18N_BASE_PATH}.finance`,
    icon: 'caf-ic_dollar_sign',
    order: 3,
  },
  {
    _id: 'justice',
    label: `${I18N_BASE_PATH}.justice`,
    icon: 'caf-ic_law',
    order: 4,
  },
  {
    _id: 'criminal_background',
    label: `${I18N_BASE_PATH}.criminalBackground`,
    icon: 'caf-ic_criminal',
    order: 5,
  },
  {
    _id: 'restrictive_lists_backgroud_checking',
    label: `${I18N_BASE_PATH}.restrictiveLists`,
    icon: 'caf-ic_lawier',
    order: 6,
  },
  {
    _id: 'driver',
    label: `${I18N_BASE_PATH}.driver`,
    icon: 'caf-ic_driver',
    order: 7,
  },
  {
    _id: 'work',
    label: `${I18N_BASE_PATH}.work`,
    icon: 'caf-ic_awards',
    order: 8,
  },
  {
    _id: 'media',
    label: `${I18N_BASE_PATH}.media`,
    icon: 'caf-ic_news',
    order: 9,
  },
];

const companiesOptions = [
  {
    _id: 'basic_infos',
    label: `${I18N_BASE_PATH}.basicInfos`,
    icon: 'caf-ic_face',
    order: 1,
  },
  {
    _id: 'contact',
    label: `${I18N_BASE_PATH}.contact`,
    icon: 'caf-ic_contact_',
    order: 2,
  },
  {
    _id: 'finance',
    label: `${I18N_BASE_PATH}.finance`,
    icon: 'caf-ic_dollar_sign',
    order: 3,
  },
  {
    _id: 'justice',
    label: `${I18N_BASE_PATH}.justice`,
    icon: 'caf-ic_law',
    order: 4,
  },
  {
    _id: 'restrictive_lists_backgroud_checking',
    label: `${I18N_BASE_PATH}.restrictiveLists`,
    icon: 'caf-ic_lawier',
    order: 5,
  },
  {
    _id: 'driver',
    label: `${I18N_BASE_PATH}.driver`,
    icon: 'caf-ic_driver',
    order: 6,
  },
  {
    _id: 'work',
    label: `${I18N_BASE_PATH}.work`,
    icon: 'caf-ic_awards',
    order: 7,
  },
  {
    _id: 'media',
    label: `${I18N_BASE_PATH}.media`,
    icon: 'caf-ic_news',
    order: 8,
  },
];

export const backgroudCheckingOptions = (type) => {
  return type === 'PF' ? peopleOptions : companiesOptions;
};
