/* eslint-disable react/forbid-prop-types */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Collapse, Pagination, Row } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import usePagination from 'customHooks/usePagination';
import NotAvailable from 'components/NotAvailable';
import NoResults from 'components/NoResults';
import CompanySeparator from '../../../../../../CompanyDetails/components/Separator';
import EntityHeader from './components/EntityHeader';
import EntityBody from './components/EntityBody';
import ExportDropdown from './components/ExportDropdown';

import './styles.less';
import { CompanyBody1, CompanySubtitle1, CompanySubtitle2 } from '../../../..';

const I18N_BASE_PATH = 'pages.private.KYBCompany.pages.companyDetails.subPages.records';

const RecordDetails = ({
  record,
  companyName,
  goToList,
  pageSize = 10,
  collapseOpened = false,
}) => {
  const { t } = useTranslation();
  const { i18nFormatDateOnly } = useI18nFormatters();

  const topRef = useRef(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize,
  });

  const entities = record?.entities?.data || [];

  const { paginatedItems, paginationStart, paginationEnd } = usePagination({
    pagination,
    items: entities,
  });

  const handleBackToList = (e) => {
    e.preventDefault();
    goToList();
  };

  const handleChangePage = (page) => {
    setPagination((old) => ({ ...old, currentPage: page }));
    topRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Row
        justify="space-between"
        className="mrg-top-20 font-size-14 text-dark company-print-no-render"
      >
        <Col span={18} className="company-record-details-breadcrumb">
          <button type="button" onClick={handleBackToList} className="no-pdd">
            {t(`${I18N_BASE_PATH}.title`, 'Records')}
          </button>
          {' / '}
          <span>{record.label}</span>
        </Col>
        <Col span={6} className="text-right company-print-no-render">
          <ExportDropdown record={record} />
        </Col>
      </Row>
      <Row className="text-bold font-size-24 text-dark company-print-no-render">
        <Col span={24}>{record.label || <NotAvailable />}</Col>
      </Row>
      <Row className="mrg-top-20">
        <Col span={24}>
          {t(`${I18N_BASE_PATH}.recordInformation`, 'RECORD INFORMATION')}
        </Col>
      </Row>
      <Row className="mrg-top-20 text-dark" gutter={[0, 15]}>
        <Col span={12} sm={12} md={12}>
          <CompanySubtitle2>
            {t(`${I18N_BASE_PATH}.publicationDate`, 'Publication date')}
          </CompanySubtitle2>
          <CompanyBody1>
            {record.publicationDate ? (
              i18nFormatDateOnly(record.publicationDate, 1)
            ) : (
              <NotAvailable />
            )}
          </CompanyBody1>
        </Col>
        <Col span={12} sm={12} md={12}>
          <CompanySubtitle2>{t(`${I18N_BASE_PATH}.source`, ' Source')}</CompanySubtitle2>
          <CompanyBody1>{record.source?.name || <NotAvailable />}</CompanyBody1>
        </Col>

        <Col span={12} sm={12} md={12}>
          <CompanySubtitle2>
            {t(`${I18N_BASE_PATH}.acquisitionDate`, 'Acquisition date')}
          </CompanySubtitle2>
          <CompanyBody1>
            {record.acquisitionDate ? (
              i18nFormatDateOnly(record.acquisitionDate, 1)
            ) : (
              <NotAvailable />
            )}
          </CompanyBody1>
        </Col>

        <Col span={12} sm={12} md={12}>
          <CompanySubtitle2>
            {t(`${I18N_BASE_PATH}.sourceURL`, ' Source URL')}
          </CompanySubtitle2>
          <CompanyBody1>
            <a href={record.sourceUrl} rel="noreferrer" target="_blank">
              {t(`${I18N_BASE_PATH}.originalSourceWebsite`, 'Original source website')}
            </a>
          </CompanyBody1>
        </Col>
      </Row>
      <CompanySeparator />
      <Row className="company-record-entities-mentioned" ref={topRef}>
        <Col span={24} className="text-dark">
          <CompanySubtitle1 print={collapseOpened}>
            {t(`${I18N_BASE_PATH}.entitiesMentioned`, 'Entities Mentioned')}
          </CompanySubtitle1>
          <span className="font-size-12">
            <Trans
              i18nKey={`${I18N_BASE_PATH}.entitiesMentionedSubtitle`}
              values={{ companyName }}
              components={{ b: <b />, i: <i /> }}
            />
          </span>
        </Col>
      </Row>
      {entities.length <= 0 && (
        <Row className="mrg-top-30 text-dark gx-font-italic">
          <Col span={24}>
            <NoResults />
          </Col>
        </Row>
      )}
      {entities.length > 0 && (
        <>
          <Row className="mrg-top-30">
            <Col span={24}>
              {paginatedItems.map((entity) => (
                <Collapse
                  key={entity.entity.id}
                  defaultActiveKey={collapseOpened ? entity.entity.id : null}
                  className="mrg-btm-15 company-collapse page-break-inside-avoid"
                  expandIconPosition="right"
                >
                  <Collapse.Panel
                    key={entity.entity.id}
                    header={<EntityHeader entity={entity.entity} />}
                  >
                    <EntityBody entity={entity.entity} />
                  </Collapse.Panel>
                </Collapse>
              ))}
            </Col>
          </Row>
          <Row className="mrg-top-5 center text-dark company-print-no-render">
            <Col span={12}>
              <span>
                {t(`${I18N_BASE_PATH}.pagination`, {
                  start: paginationStart,
                  end: paginationEnd,
                  total: entities.length,
                })}
              </span>
            </Col>
            <Col span={12}>
              <Pagination
                current={pagination.currentPage}
                onChange={handleChangePage}
                pageSize={pagination.pageSize}
                total={entities.length}
                defaultCurrent={1}
                showSizeChanger={false}
                showSorterTooltip={false}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default RecordDetails;

RecordDetails.propTypes = {
  record: PropTypes.any.isRequired,
  goToList: PropTypes.func.isRequired,
  companyName: PropTypes.string.isRequired,
  pageSize: PropTypes.number,
  collapseOpened: PropTypes.bool,
};

RecordDetails.defaultProps = {
  pageSize: 10,
  collapseOpened: false,
};
