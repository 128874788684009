import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col, Divider, Collapse, Button } from 'antd';

import { toMaskedCnpj } from 'utils/formatters';
import { labelStatus } from 'utils/handlerStatus';

import DottedLabel from 'components/DottedLabel';

import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';
import BasicInfosCard from '../BasicInfosCard';

const { Panel } = Collapse;

const I18N_BASE_PATH =
  'pages.private.executions.components.dataSourceCards.basicInfos.components.company.registrationInfosContent';

const RegistrationInfosContent = ({ company, toPrint }) => {
  const { t: translate } = useTranslation();

  const [isPanelOpened, setIsPanelOpened] = useState(false);

  const basicData = useMemo(() => company?.sections?.pjData?.data, [company]);

  const secondaryActivitiesFormatted = useMemo(() => {
    const secondaryActivities =
      company?.sections?.pjData?.data?.secondaryActivities || [];

    const filtered = secondaryActivities.filter(
      (activity) =>
        activity.code !==
          translate(`${I18N_BASE_PATH}.secondaryActivitiesFormatted.activityCode`) &&
        activity.code !== '********'
    );

    return filtered;
  }, [company, translate]);

  const onClickHandler = useCallback(() => {
    setIsPanelOpened((oldState) => !oldState);
  }, []);

  return (
    <BasicInfosCard
      icon="caf-ic_file"
      title={translate(`${I18N_BASE_PATH}.registrationInfosContent.basicInfosCard.title`)}
      data={company}
      dataIndex="basicData"
      customId="registration-infos"
    >
      <Row className="pdd-top-10">
        <ColumnTitleValue
          span={12}
          // className="mrg-btm-20"
          title={translate(
            `${I18N_BASE_PATH}.registrationInfosContent.basicInfosCard.columnTitleValue.officialName.title`
          )}
          value={basicData?.officialName || '-'}
        />

        <ColumnTitleValue
          span={12}
          title={translate(
            `${I18N_BASE_PATH}.registrationInfosContent.basicInfosCard.columnTitleValue.fantasyName.title`
          )}
          value={basicData?.fantasyName || '-'}
        />

        <Divider className="custom-divider" />

        <ColumnTitleValue
          span={8}
          title={translate(
            `${I18N_BASE_PATH}.registrationInfosContent.basicInfosCard.columnTitleValue.cnpj.title`
          )}
          value={toMaskedCnpj(company?.parameters?.cnpj) || '-'}
        />

        <ColumnTitleValue
          span={6}
          title={translate(
            `${I18N_BASE_PATH}.registrationInfosContent.basicInfosCard.columnTitleValue.openingDate.title`
          )}
          value={basicData?.openingDate || '-'}
        />

        <ColumnTitleValue
          span={5}
          title={translate(
            `${I18N_BASE_PATH}.registrationInfosContent.basicInfosCard.columnTitleValue.companySize.title`
          )}
          value={basicData?.companySize || '-'}
        />

        <ColumnTitleValue
          span={5}
          title={translate(
            `${I18N_BASE_PATH}.registrationInfosContent.basicInfosCard.columnTitleValue.status.title`
          )}
          value={
            <DottedLabel
              description={basicData?.status}
              type={basicData && labelStatus(basicData?.status).type}
            />
          }
          className="situation-col"
        />

        <Divider className="custom-divider" />

        <ColumnTitleValue
          span={10}
          title={translate(
            `${I18N_BASE_PATH}.registrationInfosContent.basicInfosCard.columnTitleValue.legalNature.title`
          )}
          value={basicData?.legalNature || '-'}
        />

        <ColumnTitleValue
          span={8}
          // className="mrg-btm-20"
          title={translate(
            `${I18N_BASE_PATH}.registrationInfosContent.basicInfosCard.columnTitleValue.jointStock.title`
          )}
          value={basicData?.jointStock || '-'}
        />

        <ColumnTitleValue
          span={4}
          title={translate(
            `${I18N_BASE_PATH}.registrationInfosContent.basicInfosCard.columnTitleValue.mei.title`
          )}
          value={
            basicData?.isMei
              ? translate(
                  `${I18N_BASE_PATH}.registrationInfosContent.basicInfosCard.columnTitleValue.mei.isMei`
                )
              : translate(
                  `${I18N_BASE_PATH}.registrationInfosContent.basicInfosCard.columnTitleValue.mei.notMei`
                ) || '-'
          }
        />

        <Divider className="custom-divider" />

        <ColumnTitleValue
          span={24}
          title={translate(
            `${I18N_BASE_PATH}.registrationInfosContent.basicInfosCard.columnTitleValue.mainActivity.title`
          )}
          value={basicData?.mainActivity || '-'}
        />

        {toPrint && (
          <ColumnTitleValue
            span={24}
            title={translate(
              `${I18N_BASE_PATH}.registrationInfosContent.basicInfosCard.columnTitleValue.secondaryActivities.title`
            )}
          >
            {secondaryActivitiesFormatted.length > 0 && (
              <>
                {secondaryActivitiesFormatted?.map((activity) => (
                  <span key={activity?.code} className="custom-value">
                    {activity?.code} - {activity.description}
                  </span>
                ))}
              </>
            )}

            {!secondaryActivitiesFormatted.length > 0 && <span className="value">-</span>}
          </ColumnTitleValue>
        )}

        {!toPrint && secondaryActivitiesFormatted.length > 0 && (
          <Col span={24}>
            <Button type="link" className="no-mrg no-pdd" onClick={onClickHandler}>
              <i
                className={`caf-ic_${
                  isPanelOpened ? 'arrow_up' : 'arrow_down'
                } mrg-right-5`}
              />
              {translate(
                `${I18N_BASE_PATH}.registrationInfosContent.basicInfosCard.columnTitleValue.secondaryActivities.button`
              )}
            </Button>

            <Collapse activeKey={Number(isPanelOpened)} className="ghost-collapse" ghost>
              <Panel key={1}>
                <ul>
                  {secondaryActivitiesFormatted?.map((activity) => (
                    <li key={activity?.code} className="text-dark">
                      {activity?.code} - {activity.description}
                    </li>
                  ))}
                </ul>
              </Panel>
            </Collapse>
          </Col>
        )}
      </Row>
    </BasicInfosCard>
  );
};

RegistrationInfosContent.propTypes = {
  company: PropTypes.shape({
    sections: PropTypes.shape({
      pjData: PropTypes.shape({
        data: PropTypes.shape({
          secondaryActivities: PropTypes.arrayOf(
            PropTypes.shape({
              code: PropTypes.string,
              description: PropTypes.string,
            })
          ),
        }),
      }),
      pfPgfnDebtors: PropTypes.shape({
        data: PropTypes.shape({
          debtValue: PropTypes.string,
          hasDebts: PropTypes.bool,
        }),
      }),
    }),
    parameters: PropTypes.shape({
      cnpj: PropTypes.string,
    }),
  }),
  toPrint: PropTypes.bool,
};

RegistrationInfosContent.defaultProps = {
  company: undefined,
  toPrint: false,
};

export default RegistrationInfosContent;
