import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Row, Col, Button, Collapse } from 'antd';

import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

import PageTitle from 'components/PageTitle';
import TooltipInfo from 'components/TooltipInfo';
import EmptyMessage from 'components/EmptyMessage';

import defaultImage from 'assets/images/profile-default.png';

import {
  eyeAndHairTranslator,
  sanctionType,
  pepLevel,
} from '../../../utils/handlerSanctions';

import './styles.less';

const SourceInfos = ({ data, pep, others, title, subtitle }) => {
  const { t: translate } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const [isPanelOpened, setIsPanelOpened] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const showTooltipInfo = useMemo(() => {
    return (
      pep &&
      subtitle !== '-' &&
      subtitle !==
        translate(
          'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.showTooltipInfo'
        )
    );
  }, [pep, subtitle, translate]);

  const { Panel } = Collapse;

  const SourceInfosModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        onCancel={closeModal}
        footer={null}
        style={{ top: 40 }}
        width={700}
      >
        <div className="no-mrg no-pdd">
          <Row
            className={`source-infos-modal-title reset-mrg ${!title && 'custom-title'} ${
              !subtitle && 'custom-subtitle'
            }`}
          >
            <PageTitle
              title={
                title ||
                translate(
                  'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.pageTitle.title'
                )
              }
              subtitle={subtitle}
              info={
                showTooltipInfo && (
                  <TooltipInfo
                    title={translate(
                      'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.pageTitle.info'
                    )}
                  />
                )
              }
            />
          </Row>

          <Row className="mrg-top-10 mrg-btm-10 reset-mrg">
            {data?.length > 0 &&
              data?.map((sourceDetailItem) => (
                <Col span={12} className="information-source-data-details">
                  {pep ? (
                    <>
                      {others && (
                        <span>
                          <strong>
                            {translate(
                              'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.source'
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.source ||
                            translate(
                              'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.hasNotSource'
                            )}
                        </span>
                      )}
                      {sourceDetailItem?.level && (
                        <span>
                          <strong>
                            {translate(
                              'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.level'
                            )}{' '}
                          </strong>
                          {pepLevel(sourceDetailItem?.level, translate)?.description}
                        </span>
                      )}
                      {sourceDetailItem?.jobTitle &&
                        sourceDetailItem?.jobTitle !== 'FAMILIAR' && (
                          <span>
                            <strong>
                              {translate(
                                'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.jobTitle'
                              )}{' '}
                            </strong>
                            {capitalizeFirstLetter(sourceDetailItem?.jobTitle)}
                          </span>
                        )}
                      {sourceDetailItem?.department && (
                        <span>
                          <strong>
                            {translate(
                              'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.department'
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.department}
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      {others && (
                        <span>
                          <strong>
                            {translate(
                              'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.source'
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.source ||
                            translate(
                              'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.hasNotSource'
                            )}
                        </span>
                      )}
                      {sourceDetailItem?.matchRate && (
                        <span>
                          <strong>
                            {translate(
                              'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.matchRate'
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.matchRate || '100'} %
                        </span>
                      )}
                      {sourceDetailItem?.type && (
                        <span>
                          <strong>
                            {translate(
                              'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.type'
                            )}{' '}
                          </strong>
                          {translate(sanctionType(sourceDetailItem?.type))}
                        </span>
                      )}
                      {sourceDetailItem?.sanctionName && (
                        <span>
                          <strong>
                            {translate(
                              'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.sanctionName'
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.sanctionName?.toUpperCase() || '-'}
                        </span>
                      )}
                      {sourceDetailItem?.description && (
                        <span>
                          <strong>
                            {translate(
                              'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.description'
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.description}
                        </span>
                      )}
                      {sourceDetailItem?.nationalities && (
                        <span>
                          <strong>
                            {translate(
                              'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.nationalities'
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.nationalities}
                        </span>
                      )}
                      {sourceDetailItem?.eyes && (
                        <span>
                          <strong>
                            {translate(
                              'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.eyes'
                            )}{' '}
                          </strong>
                          {translate(eyeAndHairTranslator(sourceDetailItem?.eyes))}
                        </span>
                      )}
                      {sourceDetailItem?.hair && (
                        <span>
                          <strong>
                            {translate(
                              'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.hair'
                            )}{' '}
                          </strong>
                          {translate(eyeAndHairTranslator(sourceDetailItem?.hair))}
                        </span>
                      )}
                      {sourceDetailItem?.considerations && (
                        <span>
                          <strong>
                            {translate(
                              'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.considerations'
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.considerations}
                        </span>
                      )}
                      {sourceDetailItem?.distinguishingMarks && (
                        <span>
                          <strong>
                            {translate(
                              'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.distinguishingMarks'
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.distinguishingMarks}
                        </span>
                      )}
                      {sourceDetailItem?.department && (
                        <span>
                          <strong>
                            {translate(
                              'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.department'
                            )}{' '}
                          </strong>
                          {sourceDetailItem?.department}
                        </span>
                      )}
                      {sourceDetailItem?.image && (
                        <div className="information-source-data-image">
                          <Button
                            type="link"
                            className="no-mrg no-pdd"
                            onClick={() => setIsPanelOpened(!isPanelOpened)}
                          >
                            {isPanelOpened
                              ? translate(
                                  'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.button.isPanelOpened'
                                )
                              : translate(
                                  'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.button.isNotPanelOpened'
                                )}
                          </Button>
                          <Collapse
                            activeKey={Number(isPanelOpened)}
                            className="ghost-collapse"
                            ghost
                          >
                            <Panel key={1}>
                              <img
                                className={!sourceDetailItem?.image ? 'grayscale-95' : ''}
                                src={sourceDetailItem?.image || defaultImage}
                                alt={translate(
                                  'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.collapse.image.alt'
                                )}
                              />
                            </Panel>
                          </Collapse>
                        </div>
                      )}
                    </>
                  )}
                </Col>
              ))}

            {!data?.length > 0 && (
              <div className="empty-data-container">
                <EmptyMessage
                  type="empty"
                  description={translate(
                    'pages.private.profiles.components.dataSourceCards.restrictiveLists.components.sources.sourceInfosModal.index.sourceInfosModal.emptyMessage.description'
                  )}
                  style={{ padding: 0, fontSize: 15 }}
                />
              </div>
            )}
          </Row>
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      data,
      pep,
      others,
      title,
      subtitle,
      isPanelOpened,
      showTooltipInfo,
      translate,
    ]
  );

  return { openModal, closeModal, SourceInfosModal };
};

export default SourceInfos;
