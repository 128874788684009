/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import EmptyState from 'components/EmptyState';
import TitleDivider from 'components/Divider/TitleDivider';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import { nationalList, internationalList } from '../../utils/sanctionsDetails';

import SourceCard from '../../components/Sources/SourcesCard';

const SourcesList = ({ profile, dataSources, personalInfo, toPrint, toPrintDetails }) => {
  const { t: translate } = useTranslation();

  const kycCompliance = useMemo(() => profile?.others?.kycCompliance, [profile]);

  const isProcessing = useMemo(() => verifySourcesProcessing(profile, 'kycCompliance'), [
    profile,
  ]);

  const mappedData = useMemo(() => {
    if (!kycCompliance) return {};

    const nationalSources = {};
    const internationalSources = {};

    const sanctionsHistory = kycCompliance?.sanctionsHistory || {};

    Object.keys(sanctionsHistory).forEach((key) => {
      const value = sanctionsHistory[key];
      const parsedValue = { [key]: value };

      if (nationalList.includes(key)) {
        Object.assign(nationalSources, parsedValue);
      }
      if (internationalList.includes(key)) {
        Object.assign(internationalSources, parsedValue);
      }
    });

    return { nationalSources, internationalSources };
  }, [kycCompliance]);

  const customSourcesTop = useMemo(() => {
    if (!personalInfo) {
      if (
        !dataSources.includes('pj_kyc_compliance_owners') &&
        !profile?.others?.kycComplianceOwners
      )
        return '0px';
      return '25px';
    }
    return '5px';
  }, [personalInfo, dataSources, profile]);

  const parsedPEPList = useMemo(() => {
    if (!kycCompliance || !kycCompliance?.pepHistory?.length > 0) return <></>;

    const el = [];
    const _size = kycCompliance?.pepHistory?.length;

    if (_size) {
      for (let i = 0; i < _size; i += 2) {
        el.push(
          <Row className="sources-list-row reset-mrg">
            {kycCompliance?.pepHistory?.slice(i, i + 2)?.map((source) => (
              <Col key={source} span={12} className="sources-list-data">
                <SourceCard
                  pep
                  data={source}
                  pepSource={kycCompliance?.pepHistory}
                  toPrint={toPrint}
                  toPrintDetails={toPrintDetails}
                />
              </Col>
            ))}
          </Row>
        );
      }
    }
    return el.length > 0 ? el : <></>;
  }, [kycCompliance, toPrint, toPrintDetails]);

  const parsedNationalList = useMemo(() => {
    if (!kycCompliance) return <></>;

    const el = [];
    const _size = Object.keys(mappedData?.nationalSources).length;

    if (_size) {
      for (let i = 0; i < _size; i += 2) {
        el.push(
          <Row className="sources-list-row reset-mrg">
            {Object.keys(mappedData?.nationalSources)
              ?.slice(i, i + 2)
              ?.map((source) => (
                <Col key={source} span={12} className="sources-list-data">
                  <SourceCard
                    data={source}
                    others={source === 'OTHERS'}
                    sanctionsSource={kycCompliance?.sanctionsHistory[source]}
                    lastConsultation={kycCompliance?.lastConsultation?.date}
                    national
                    toPrint={toPrint}
                    toPrintDetails={toPrintDetails}
                  />
                </Col>
              ))}
          </Row>
        );
      }
    }
    return el.length > 0 ? el : <></>;
  }, [kycCompliance, mappedData, toPrint, toPrintDetails]);

  const parsedInternationalList = useMemo(() => {
    if (!kycCompliance) return <></>;

    const el = [];
    const _size = Object.keys(mappedData?.internationalSources).length;

    if (_size) {
      for (let i = 0; i < _size; i += 2) {
        el.push(
          <Row className="sources-list-row reset-mrg">
            {Object.keys(mappedData?.internationalSources)
              ?.slice(i, i + 2)
              ?.map((source) => (
                <Col key={source} span={12} className="sources-list-data">
                  <SourceCard
                    data={source}
                    others={source === 'OTHERS'}
                    sanctionsSource={kycCompliance?.sanctionsHistory[source]}
                    lastConsultation={kycCompliance?.lastConsultation?.date}
                    international
                    toPrint={toPrint}
                    toPrintDetails={toPrintDetails}
                  />
                </Col>
              ))}
          </Row>
        );
      }
    }
    return el.length > 0 ? el : <></>;
  }, [kycCompliance, mappedData, toPrint, toPrintDetails]);

  return (
    <Row id="sources-list-container">
      {personalInfo && (
        <Col span={24} className="sources-list-col">
          <TitleDivider
            title={translate(
              'pages.private.profiles.components.dataSourceCards.restrictiveLists.lists.sourcesList.index.titleDivider.pep.title'
            )}
            tooltipInfo={translate(
              'pages.private.profiles.components.dataSourceCards.restrictiveLists.lists.sourcesList.index.titleDivider.pep.tooltipInfo'
            )}
            toPrint={toPrint}
            customTop="0px"
            customBottom="40px"
          />

          {!kycCompliance && isProcessing && (
            <EmptyState type="processing" className="mrg-btm-25 mrg-top-35" />
          )}

          {!kycCompliance && !isProcessing && (
            <EmptyState type="waiting_consult" className="mrg-btm-25 mrg-top-35" />
          )}

          {kycCompliance && !kycCompliance?.pepHistory?.length > 0 && (
            <EmptyState
              type="empty-success"
              description={translate(
                'pages.private.profiles.components.dataSourceCards.restrictiveLists.lists.sourcesList.index.emptyState.description'
              )}
              className="mrg-btm-25 mrg-top-35"
            />
          )}

          {parsedPEPList}
        </Col>
      )}

      {kycCompliance && (
        <>
          <Col span={24} className="sources-list-col">
            <TitleDivider
              title={translate(
                'pages.private.profiles.components.dataSourceCards.restrictiveLists.lists.sourcesList.index.titleDivider.hasKycCompliance.title'
              )}
              toPrint={toPrint}
              customTop={customSourcesTop}
              customBottom="50px"
            />

            {parsedNationalList}
          </Col>

          <Col span={24} className="sources-list-col">
            <TitleDivider
              title={translate(
                'pages.private.profiles.components.dataSourceCards.restrictiveLists.lists.sourcesList.index.titleDivider.international.title'
              )}
              toPrint={toPrint}
              customTop="5px"
              customBottom="50px"
            />

            {parsedInternationalList}
          </Col>
        </>
      )}

      {!kycCompliance && (
        <Col span={24} className="sources-list-col">
          <TitleDivider
            title={translate(
              'pages.private.profiles.components.dataSourceCards.restrictiveLists.lists.sourcesList.index.titleDivider.hasNotKycCompliance.title'
            )}
            toPrint={toPrint}
            customTop={customSourcesTop}
            customBottom="50px"
          />

          {isProcessing && (
            <EmptyState
              type="processing"
              className={`${!toPrint && 'mrg-top-25'} mrg-btm-25 mrg-top-35`}
            />
          )}

          {!isProcessing && (
            <EmptyState
              type="waiting_consult"
              className={`${!toPrint && 'mrg-top-25'} mrg-btm-25 mrg-top-35`}
            />
          )}
        </Col>
      )}
    </Row>
  );
};

export default SourcesList;
