import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Tag, Alert, Dropdown, Menu, Divider, Form } from 'antd';
import { useQueryParams, StringParam } from 'use-query-params';
import _ from 'lodash';

import { useTable } from 'components/List';

import Button from 'components/Button';
import PageTitle from 'components/PageTitle';
import SearchInput from 'components/Form/SearchInput';
import PermissionWrapper from 'routes/PermissionWrapper';

import { toFormatDate, toMaskedCnpj, toMaskedCpf } from 'utils/formatters';
import OutsideClickHandler from 'react-outside-click-handler';
import useImportCSVModal from './components/ImportCSVModal';
import useRestrictionCreateModal from '../components/RestrictionCreateModal';
import DropdownRestrictionOptions from './components/DropdownRestrictionOptions';
import './styles.less';

const I18N_BASE_PATH = 'pages.private.restrictions.denyList.index';

const DenyList = () => {
  const { t: translate, i18n } = useTranslation();

  const [error, setError] = useState();
  const refreshListRef = useRef(() => {});

  const [isDropdownOpened, setIsDropdownOpened] = useState();

  const [query] = useQueryParams({
    _order: StringParam,
    _sort: StringParam,
    _search: StringParam,
    _status: StringParam,
  });

  const statusTranslation = {
    ERROR: {
      color: 'red',
      title: translate(`${I18N_BASE_PATH}.statusTranslation.ERROR.title`, 'Error'),
    },
    DEFAULT: {
      color: 'default',
      title: translate(
        `${I18N_BASE_PATH}.statusTranslation.DEFAULT.title`,
        'Unavailable'
      ),
    },
    PROCESSING: {
      color: 'blue',
      title: translate(
        `${I18N_BASE_PATH}.statusTranslation.PROCESSING.title`,
        'Processing'
      ),
    },
    SUCCESS: {
      color: 'green',
      title: translate(`${I18N_BASE_PATH}.statusTranslation.SUCCESS.title`, 'Success'),
    },
  };

  const columns = [
    {
      key: 'identification',
      title: translate(
        `${I18N_BASE_PATH}.columns.identification.title`,
        'Brazillian Person or Company ID'
      ),
      dataIndex: ['identification'],
      render: (record) => {
        const onlyNumbersID = record.replace(/\D/g, '');
        const parsedNumber =
          onlyNumbersID.length > 11
            ? onlyNumbersID.padStart(14, '0')
            : onlyNumbersID.padStart(11, '0');

        if (parsedNumber.length === 11) {
          return toMaskedCpf(parsedNumber);
        }
        if (parsedNumber.length === 14) {
          return toMaskedCnpj(parsedNumber);
        }

        return parsedNumber;
      },
    },
    {
      key: 'name',
      title: translate(`${I18N_BASE_PATH}.columns.name.title`, 'Name'),
      dataIndex: ['name'],
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
      key: 'reason',
      title: translate(`${I18N_BASE_PATH}.columns.reason.title`, 'Inclusion reason'),
      dataIndex: ['reason'],
    },
    {
      key: 'createdAt',
      title: translate(`${I18N_BASE_PATH}.columns.createdAt.title`, 'Inserted at'),
      render: (__, docs) =>
        toFormatDate(docs?.createdAt || docs?.updatedAt, i18n.language, translate),
      sorter: (a, b) => a?.createdAt?.localeCompare(b?.createdAt),
    },
    {
      key: 'options',
      title: '',
      render: (__, docs) => (
        <DropdownRestrictionOptions docs={docs} refreshList={refreshListRef} />
      ),
    },
  ];

  const { tableContent, refreshList, data, setFilterData } = useTable({
    getParams: {
      url: '/deny-list',
      config: {
        params: {},
      },
    },
    queryParams: {
      _search: StringParam,
      _status: StringParam,
    },
    defaultFilter: {
      _order: query?._order || '1',
      _sort: query?._sort || 'name',
    },
    columns,
  });

  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        ...values,
        _search: values._search ? values._search.trim() : undefined,
      });
    }, 500),
    []
  );

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  const { openModal: openImportCSVModal, ImportCSVModal } = useImportCSVModal({
    refreshList: refreshListRef,
  });
  const {
    openModal: openRestrictionCreateModal,
    RestrictionCreateModal,
  } = useRestrictionCreateModal({
    refreshList: refreshListRef,
    url: 'deny-list',
  });

  const title = useMemo(() => {
    setError(undefined);
    const msg = translate(`${I18N_BASE_PATH}.title.msg`, 'Deny List');
    const docs = data?.statusImport?.docs;

    // console.log(data);

    if (!docs?.length) return msg;

    const status = docs[0];

    // console.log(status);
    let translatedStatus = statusTranslation.DEFAULT;
    if (status?.status?.toUpperCase() === 'ERROR') {
      setError(status.message);
      translatedStatus = statusTranslation.ERROR;
    } else if (status?.status?.toUpperCase() === 'PROCESSING') {
      translatedStatus = statusTranslation.PROCESSING;
    } else if (status?.status?.toUpperCase() === 'SUCCESS') {
      return msg;
    }

    return (
      <div className="flex start-center">
        {msg}
        <Tag color={translatedStatus?.color} className="mrg-left-10">
          {translatedStatus?.title}
        </Tag>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, translate]);

  const addRegisterDropdownContent = useMemo(() => {
    return (
      <Menu>
        <PermissionWrapper requiredPermissions={['deny-list:create']}>
          <Menu>
            <Menu.Item key="1" onClick={() => openRestrictionCreateModal()}>
              <i className="caf-ic_edit_3" />
              {translate(
                `${I18N_BASE_PATH}.addRegisterDropdownContent.Menu.Item.addManualy`,
                'Add manualy'
              )}
            </Menu.Item>
          </Menu>
        </PermissionWrapper>
        <Divider className="reset-mrg mrg-top-5 mrg-btm-5" />
        <PermissionWrapper requiredPermissions={['deny-list:create']}>
          <Menu>
            <Menu.Item key="2" onClick={() => openImportCSVModal('include')}>
              <i className="caf-ic_add" />
              {translate(
                `${I18N_BASE_PATH}.addRegisterDropdownContent.Menu.Item.includeCSV`,
                'Include by CSV'
              )}
            </Menu.Item>
          </Menu>
        </PermissionWrapper>
        <PermissionWrapper requiredPermissions={['deny-list:create']}>
          <Menu>
            <Menu.Item key="3" onClick={() => openImportCSVModal('replace')}>
              <i className="caf-ic_processing_line" />
              {translate(
                `${I18N_BASE_PATH}.addRegisterDropdownContent.Menu.Item.replaceCSV`,
                'Replace by CSV'
              )}
            </Menu.Item>
          </Menu>
        </PermissionWrapper>
        <PermissionWrapper requiredPermissions={['deny-list:delete']}>
          <Menu>
            <Menu.Item key="4" onClick={() => openImportCSVModal('remove')}>
              <i className="caf-ic_trash" />
              {translate(
                `${I18N_BASE_PATH}.addRegisterDropdownContent.Menu.Item.removeCSV`,
                'Remove by CSV'
              )}
            </Menu.Item>
          </Menu>
        </PermissionWrapper>
      </Menu>
    );
  }, [openImportCSVModal, openRestrictionCreateModal, translate]);

  return (
    <Row id="deny-list-container">
      {RestrictionCreateModal}
      {ImportCSVModal}
      <Col span={24}>
        <Row align="middle" justify="center" className="no-mrg mrg-btm-15">
          <Col flex={1}>
            <PageTitle
              title={title}
              subtitle={translate(
                `${I18N_BASE_PATH}.wrapperPageTitle.subTitle`,
                'Add here the CPFs or CNPJs of people or companies related to whom you may be registering, so that we can consult the possible relationship between them. If the person registering has a relationship with any CPF on this list, the validation will indicate to you.'
              )}
            />
          </Col>

          <Col>
            <Form
              layout="inline"
              onValuesChange={handleFormChange}
              initialValues={{ _search: query?._search }}
            >
              <SearchInput
                placeholder={translate(
                  `${I18N_BASE_PATH}.searchInput.placeholder`,
                  'Search by name or Brazillian Person/Company ID'
                )}
                style={{ width: 300 }}
              />
            </Form>
          </Col>

          <PermissionWrapper
            or
            requiredPermissions={['deny-list:create', 'deny-list:delete']}
          >
            <OutsideClickHandler
              onOutsideClick={() => setIsDropdownOpened(false)}
              disabled={!isDropdownOpened}
            >
              <Dropdown
                overlay={addRegisterDropdownContent}
                trigger={['click']}
                visible={isDropdownOpened}
                placement="bottomRight"
              >
                <Button
                  shape="round"
                  type="primary"
                  className="flex center"
                  onClick={() => setIsDropdownOpened(true)}
                >
                  {translate(`${I18N_BASE_PATH}.wrapperButton`, 'Add')}
                  <i className="caf-ic_add font-size-18 mrg-left-5" />{' '}
                </Button>
              </Dropdown>
            </OutsideClickHandler>
          </PermissionWrapper>
        </Row>

        <Row>
          {error && (
            <Col span={24}>
              <Alert message={error} type="error" className="no-mrg mrg-top-20" />
            </Col>
          )}
          <Col span={24}>{tableContent}</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DenyList;
