/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { Col, Row, Tag } from 'antd';
import CountryFlag from 'components/CountryFlag';
import CompanyBlueIcon from 'assets/images/company/company-blue.svg';
import Icons from './Icons';

import './styles.less';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companySearching.components.consultedCompanies.companyCard';

const CompanyCard = ({ data: execution, allWorkflows }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  // in beta all the executions came from mongodb so we also need look inside the sections
  const businessIdentity = useMemo(
    () => execution.sections.kybBusinessIdentity?.data || {},
    [execution]
  );

  const creditReport = useMemo(() => execution.sections.kybCreditReport?.data || {}, [
    execution,
  ]);

  const companyName = useMemo(
    () =>
      execution?.data?.name ||
      creditReport?.companySummary?.businessName ||
      businessIdentity?.label,
    [execution, creditReport, businessIdentity]
  );
  const companyCountry = useMemo(
    () =>
      execution?.data?.country ||
      creditReport?.companySummary?.country ||
      businessIdentity?.countries?.[0],
    [execution, creditReport, businessIdentity]
  );
  const companyAddress = useMemo(
    () =>
      execution?.data?.address ||
      creditReport?.contactInformation?.mainAddress?.simpleValue ||
      businessIdentity?.addresses?.[0],
    [execution, creditReport, businessIdentity]
  );
  const companyNumber = useMemo(
    () =>
      execution?.data?.identifiers?.find(
        (identifier) => identifier.type === 'COMPANY_NUMBER'
      )?.value ||
      creditReport?.companyIdentification?.basicInformation?.companyRegistrationNumber ||
      businessIdentity?.identifiers?.[0]?.value,
    [execution, creditReport, businessIdentity]
  );

  const companyStatus = useMemo(() => {
    const status = (
      execution?.data?.company?.status ||
      creditReport?.companyIdentification?.basicInformation?.companyStatus?.status ||
      businessIdentity?.attributes?.status?.data?.[0]?.properties?.value ||
      ''
    )?.toLowerCase();

    switch (status) {
      case 'active':
        return {
          description: translate(`${I18N_BASE_PATH}.status.active`, 'Active'),
          color: 'green',
        };
      case 'nonactive':
      case 'closed':
      case 'expired':
      case 'inactive':
      case 'terminated':
        return {
          description: translate(`${I18N_BASE_PATH}.status.inactive`, 'Inactive'),
          color: 'red',
        };
      case 'pending':
      case 'other':
      case 'closing':
      case 'expanded':
      case 'incorporated':
      case 'registered':
      case 'under_external_control':
        return {
          description: translate(`${I18N_BASE_PATH}.status.other`, 'Other'),
          color: 'yellow',
        };
      default:
        return {
          description: '-',
          color: '',
        };
    }
  }, [execution, creditReport, businessIdentity, translate]);

  const creditReportId = useMemo(
    () =>
      execution?.parameters?.services?.creditReportId ||
      execution?.parameters?.creditReportId,
    [execution]
  );

  const businessIdentityId = useMemo(
    () =>
      execution?.parameters?.services?.businessIdentityId ||
      execution?.parameters?.businessIdentityId,
    [execution]
  );

  const workflowName = useMemo(() => {
    if (execution?.workflowId || execution?.metadata?.workflowId) {
      const executionWorkflowId =
        execution?.workflowId || execution?.metadata?.workflowId;

      const workflow = allWorkflows?.docs?.find(
        (workflow) => workflow._id === executionWorkflowId
      );

      const workflowName = workflow?.definition?.name;

      return workflowName;
    }
    return null;
  }, [allWorkflows, execution]);

  const cardContent = useMemo(
    () => (
      <div className="card">
        <Row className="card-component overflow-hidden">
          <Col span={7} className="card-section first">
            <h6 className="workflow-name">{workflowName || '-'}</h6>
            <div>
              <h3 className="name">{companyName || '-'}</h3>
              <Tag color={companyStatus.color}>{companyStatus.description}</Tag>
            </div>
            <span className="company-number">
              {companyNumber && (
                <div className="wrapper-company-number">
                  <img src={CompanyBlueIcon} alt="company number icon" />
                  <span>
                    {translate(`${I18N_BASE_PATH}.companyNumber`, 'Company number')}
                    {companyNumber}
                  </span>
                </div>
              )}
            </span>
          </Col>
          <Col span={12} className="card-section second">
            <Col span={2} className="flex no-pdd">
              {execution?.status === 'PROCESSING' ? (
                '-'
              ) : (
                <CountryFlag country={companyCountry} />
              )}
            </Col>
            <Col span={14} className="address">
              {companyAddress || '-'} <br />
            </Col>
            <Col span={8} className="dates">
              <div>
                <p>{translate(`${I18N_BASE_PATH}.queryDate`, 'Dated created')}</p>
                {i18nFormatDate(execution?.createdAt, 4)}
              </div>
              <div>
                <p>{translate(`${I18N_BASE_PATH}.updateDate`, 'Last modified')}</p>
                {i18nFormatDate(execution?.updatedAt, 4)}
              </div>
            </Col>
          </Col>
          <Col span={3} className="card-section third">
            <div className="sources-found">
              {creditReportId && <span>Credit report</span>}
              {businessIdentityId && <span>Business identity</span>}
              {!creditReportId && !businessIdentityId && (
                <span>{translate(`${I18N_BASE_PATH}.manualInput`)}</span>
              )}
            </div>
          </Col>
          {execution?.status && (
            <Col span={2} className="card-section">
              <Icons status={execution?.status} />
            </Col>
          )}
        </Row>
      </div>
    ),
    [
      execution,
      companyStatus,
      i18nFormatDate,
      companyAddress,
      companyCountry,
      companyName,
      companyNumber,
      creditReportId,
      businessIdentityId,
      translate,
    ]
  );

  return <Link to={`/company/${execution?._id || execution.id}`}>{cardContent}</Link>;
};

export default CompanyCard;
