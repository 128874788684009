import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import GaugeComponent from 'react-gauge-component';
import { DollarSign } from '@combateafraude/icons/general';
import { Row, Col, Tag } from 'antd';
import EmptyExecutionState from 'components/EmptyExecutionState';
import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';
import './styles.less';

const SCORE_COLORS = {
  LOW: '#E21B45',
  MEDIUM: '#FF9500',
  GOOD: '#0BAA43',
};

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.shared.creditScoreSimple';
const ScoreIndicator = ({ source }) => {
  const { t: translate } = useTranslation();
  const tagScore = useMemo(() => {
    if (!source?.data?.score) {
      return null;
    }
    if (source?.data?.score > 666) {
      return (
        <Tag bordered={false} color="#DAFEE5">
          <span className="span-score good-score" />
          <span style={{ color: SCORE_COLORS.GOOD }}>
            {translate(`${I18N_BASE_PATH}.positiveScore`)}
          </span>
        </Tag>
      );
    }
    if (source?.data?.score > 333) {
      return (
        <Tag bordered={false} color="#FFF1C5">
          <span className="span-score medium-score" />
          <span style={{ color: SCORE_COLORS.MEDIUM }}>
            {translate(`${I18N_BASE_PATH}.medianScore`)}
          </span>
        </Tag>
      );
    }

    return (
      <Tag bordered={false} color="#FFE4E6">
        <span className="span-score low-score" />
        <span style={{ color: SCORE_COLORS.LOW }}>
          {translate(`${I18N_BASE_PATH}.lowScore`)}
        </span>
      </Tag>
    );
  }, [source, translate]);
  return (
    <div id="score-indicator">
      <Row>
        {source?.data?.score ? (
          <Col xl={12} xs={24}>
            <GaugeComponent
              type="semicircle"
              arc={{
                cornerRadius: 4,
                subArcs: [
                  { color: SCORE_COLORS.LOW },
                  { color: SCORE_COLORS.MEDIUM },
                  { color: SCORE_COLORS.GOOD },
                ],
              }}
              labels={{
                valueLabel: {
                  matchColorWithArc: true,
                  formatTextValue: (value) => `${value * 10}`,
                  style: { textShadow: 'none', fontWeight: 500 },
                },
                tickLabels: {
                  hideMinMax: false,
                  defaultTickValueConfig: {
                    formatTextValue: (value) => `${value * 10}`,
                  },
                },
              }}
              pointer={{
                type: 'blob',
                animationDelay: 0,
                width: 20,
                animate: false,
                elastic: true,
              }}
              value={source?.data?.score / 10}
            />
          </Col>
        ) : (
          <></>
        )}
        <Col span={source?.data?.score ? 12 : 24}>
          <div className="score-container">
            <p>{tagScore}</p>
            <p className="text-dark">{source?.data?.scoreMessage}</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ScoreIndicator;
