/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'antd';

import useSourceInfosModal from '../SourceInfosModal';

import './styles.less';

const SourcesDetails = ({ data, pep, others, title, subtitle }) => {
  const { t: translate } = useTranslation();

  const sourcesList = data?.sanctions || data;

  const { openModal, SourceInfosModal } = useSourceInfosModal({
    data: sourcesList,
    pep,
    others,
    title,
    subtitle,
  });

  const sourcesCount = useMemo(() => {
    if (sourcesList?.length === 1) {
      return {
        registers: translate(
          'components.dataSourceCards.restrictiveLists.components.sources.sourcesDetails.index.sourcesCount.oneRegister'
        ),
        styles: 'danger',
      };
    }
    if (sourcesList?.length > 1) {
      return {
        registers: translate(
          'components.dataSourceCards.restrictiveLists.components.sources.sourcesDetails.index.sourcesCount.moreThanOneRegister',
          { value: data?.sanctions?.length || data?.length }
        ),
        styles: 'danger',
      };
    }
    return {
      registers: translate(
        'components.dataSourceCards.restrictiveLists.components.sources.sourcesDetails.index.sourcesCount.default'
      ),
      styles: 'success',
    };
  }, [data, sourcesList, translate]);

  return (
    <>
      {SourceInfosModal}

      {sourcesCount.registers ===
        translate(
          'components.dataSourceCards.restrictiveLists.components.sources.sourcesDetails.index.sourcesDetails.sourcesCountRegistersValue'
        ) && (
        <Tooltip
          placement="top"
          title={translate(
            'components.dataSourceCards.restrictiveLists.components.sources.sourcesDetails.index.sourcesDetails.tooltip.title'
          )}
        >
          <Button
            type="button"
            id="executions-information-source-details-btn"
            className={`reset-btn ${sourcesCount.styles}`}
          >
            {sourcesCount.registers}
          </Button>
        </Tooltip>
      )}

      {sourcesCount.registers !==
        translate(
          'components.dataSourceCards.restrictiveLists.components.sources.sourcesDetails.index.sourcesDetails.sourcesCountRegistersValue'
        ) && (
        <Button
          type="button"
          id="executions-information-source-details-btn"
          className={`reset-btn ${sourcesCount.styles}`}
          onClick={() => openModal()}
        >
          {sourcesCount.registers}
        </Button>
      )}
    </>
  );
};

export default SourcesDetails;
