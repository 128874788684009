import React, { Suspense, useEffect, useState } from 'react';
import { Button } from 'antd';
import { DoubleArrow, BulletListText } from '@combateafraude/icons/general';
import { useFetch } from 'services/hooks';
import TabTitle from 'pages/private/KYBCompany/CompanyDetails/components/TabTitle';
import { useTranslation } from 'react-i18next';
import KYBPeople from 'assets/images/company/people.svg';
import Loading from 'components/Loading';
import NotAvailable from 'components/NotAvailable';
import RelationshipList from 'pages/private/KYBCompany/CompanyDetailsV2/components/BusinessIdentityRelationships/RelationshipList';

import './styles.less';

const RelationshipTreeLazy = React.lazy(() =>
  import(
    'pages/private/KYBCompany/CompanyDetailsV2/components/BusinessIdentityRelationships/RelationshipTree'
  )
);

const PAGE = 'pages.private.KYBCompany.pages';
const BASE = `${PAGE}.companyDetails.subPages.uboStructureReport`;

const UboStructureReport = ({ transactionId, peopleData }) => {
  const { t: translate } = useTranslation();
  const { ready } = useTranslation('kyb-business-identity-relationship');

  const { post: postMethod, loading: fetchLoading, error } = useFetch();
  const [apiResponse, setApiResponse] = useState(null);

  const [isTreeView, setIsTreeView] = useState(false);

  const handleToggle = () => {
    setIsTreeView(!isTreeView);
  };

  const loading = fetchLoading || !ready;
  const { entityType, businessIdentityId } = peopleData.businessIdentity;

  const fetchAPISayari = async (offset = 0) => {
    return postMethod({
      url: '/company-ubo',
      payload: {
        transactionId,
        parameters: {
          businessIdentityId,
          filters: {
            limit: 50,
            offset,
          },
        },
      },
    });
  };

  useEffect(() => {
    async function fetchData() {
      if (!businessIdentityId || !transactionId) return [];
      const response = await fetchAPISayari();

      setApiResponse(response.data);
    }

    fetchData();
  }, [businessIdentityId, transactionId]);

  return (
    <section className="kyb-company-ubo-structure-report-main">
      <TabTitle
        icon={KYBPeople}
        title={translate(`${BASE}.title`, 'UBO Structure Report')}
      />

      <div className="kyb-ubo-structure-report-button-container">
        <Button
          type="primary"
          shape="default"
          onClick={handleToggle}
          className={`kyb-more-relationship-toggle-button ${isTreeView ? 'toggled' : ''}`}
        >
          <span className="toggle-icons">
            <BulletListText className="icon-left" />
            <DoubleArrow className="icon-right" />
          </span>
        </Button>
      </div>
      {loading && <Loading />}
      {!error ? null : <NotAvailable card />}
      {!error && !loading && !isTreeView ? (
        <RelationshipList payload={apiResponse?.data} entityType={entityType} />
      ) : null}
      {!error && !loading && isTreeView ? (
        <Suspense fallback={<Loading />}>
          <div>
            <RelationshipTreeLazy
              peopleData={peopleData}
              payload={apiResponse?.data || []}
            />
          </div>
        </Suspense>
      ) : null}
    </section>
  );
};

export default UboStructureReport;
