import React, { useCallback, useState } from 'react';
import { Alert, Col, Modal, Row } from 'antd';

import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/auth';

const ModalIproovNotification = () => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();

  const [iproovNotification, setIproovNotification] = useState(
    Cookies.get('iproov-notification') === 'true' &&
      user?.accountData?.featureFlags?.noficationIproovMigration &&
      user?.usersGroupId === '6112a54246c2514b138bc35e'
  );

  const closeModal = useCallback(() => {
    setIproovNotification(false);
    Cookies.set('iproov-notification', false, { domain: '.caf.io' });
  }, []);

  return (
    <Modal
      visible={iproovNotification ?? false}
      okText={translate('pages.private.iproovModal.button')}
      onOk={closeModal}
      onCancel={closeModal}
      width={680}
      cancelButtonProps={{ style: { display: 'none' } }}
      centered
    >
      <Row justify="center" align="middle" className="mrg-vertical-20">
        <Col>
          <i className="top-icon caf-ic_alert-triangle font-size-40 warning warning-color" />
        </Col>
      </Row>
      <Row justify="center" className="mrg-vertical-20">
        <Col>
          <span className="text-bold h1">
            {translate('pages.private.iproovModal.title')}
          </span>
        </Col>
      </Row>
      <Row justify="center" style={{ width: 480, textAlign: 'center', margin: 'auto' }}>
        <Col span={24}>
          <span className="mrg-top-20 h5">
            {translate('pages.private.iproovModal.description')}
          </span>
        </Col>
      </Row>
      <Row justify="center" className="mrg-top-25">
        <Col>
          <div className="flex center text-bold text-dark">
            <i className="caf-ic_phone_call mrg-right-5" />
            <span>+55 51 99385-1101</span>
          </div>
        </Col>
      </Row>
      <Row justify="center" className="mrg-btm-25 mrg-top-15">
        <Col>
          <div className="flex center text-bold text-dark">
            <i className="caf-ic_mail mrg-right-5" />
            <span>customer.care@caf.io</span>
          </div>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={24} style={{ maxWidth: 580, margin: 'auto' }}>
          <Alert
            message={translate('pages.private.iproovModal.alert.1')}
            type="warning"
            showIcon
            className="h6"
            icon={<i className="top-icon caf-ic_alert-triangle" />}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col span={24} style={{ maxWidth: 580, margin: 'auto' }}>
          <Alert
            message={translate('pages.private.iproovModal.alert.2')}
            type="warning"
            showIcon
            className="h6"
            icon={<i className="top-icon caf-ic_alert-triangle" />}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalIproovNotification;
