import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Typography } from 'antd';

import { useFetch } from 'services/hooks';

import Button from 'components/Button';

const { Title, Paragraph } = Typography;

const I18N_BASE_PATH =
  'pages.private.restrictions.denyList.components.RestrictionDeleteModal.index';

const RestrictionDelete = ({ refreshList, docs }) => {
  const { t: translate } = useTranslation();

  const [form] = Form.useForm();
  const { delete: deleteRestriction, loading } = useFetch();

  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);

    setTimeout(() => {
      form.resetFields();
    }, 500);
  }, [form]);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const handleSubmit = useCallback(async () => {
    await form.validateFields();

    await deleteRestriction({
      url: `/deny-list/${docs?._id}`,
    });

    if (refreshList?.current) refreshList.current();
    closeModal();
  }, [closeModal, deleteRestriction, docs, form, refreshList]);

  const content = useMemo(() => {
    return (
      <div className="flex center fd-column mrg-vertical-20">
        <Title level={3}>{translate(`${I18N_BASE_PATH}.warningContent.title`)}</Title>
        <Paragraph>{translate(`${I18N_BASE_PATH}.warningContent.paragraph`)}</Paragraph>

        <div span={24} className="flex center fd-row mrg-top-15">
          <Button
            type="default"
            className="btn-cancel"
            htmlType="button"
            onClick={closeModal}
          >
            {translate(`${I18N_BASE_PATH}.warningContent.buttons.cancel`)}
          </Button>
          <Button
            type="danger"
            htmlType="button"
            onClick={handleSubmit}
            disabled={loading}
            loading={loading}
          >
            {translate(
              `${I18N_BASE_PATH}.warningContent.buttons.${
                loading ? 'loading' : 'continue'
              }`
            )}
          </Button>
        </div>
      </div>
    );
  }, [closeModal, handleSubmit, loading, translate]);

  const RestrictionDeleteModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeModal}
        footer={null}
        width={589}
      >
        {content}
      </Modal>
    ),
    [modalVisible, closeModal, content]
  );

  return { openModal, closeModal, RestrictionDeleteModal };
};

export default RestrictionDelete;
