import React from 'react';
import PropTypes from 'prop-types';

import { Col } from 'antd';

import './styles.less';

const ColumnTitleValue = ({ span, title, value, children, ...props }) => {
  return (
    <Col id="column-title-value-container" span={span} {...props}>
      {typeof title === 'string' ? <span className="title">{title}</span> : title}
      {typeof value === 'string' ? <span className="value">{value}</span> : value}

      {children}
    </Col>
  );
};

ColumnTitleValue.propTypes = {
  span: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

ColumnTitleValue.defaultProps = {
  span: 8,
};

export default ColumnTitleValue;
