/* eslint-disable prettier/prettier */
export const createRegexFor = (policyOrResource) => {
  return policyOrResource === '*' || policyOrResource === '*:*'
    ? new RegExp('.*')
    : new RegExp(policyOrResource);
};

export const mapUserPolicies = (policies) => {
  return policies?.reduce((acc, cur) => {
    cur?.actions?.forEach((action) => {
      cur?.resources?.forEach((resource) => {
        acc.push({ effect: cur.effect, action, resource });
      });
    });
    return acc;
  }, []);
};

export const hasRequiredPermissions = (
  user,
  requiredActions,
  requiredResources,
  or = false,
  product = 'trust'
) => {
  const userPolicies = user?.products?.[product]?.policyGroupData?.policies || [];
  const mappedUserPolicies = mapUserPolicies(userPolicies);
  const requiredPermissions = [];

  requiredActions?.forEach((action) =>
    requiredResources?.length > 0
      ? requiredResources?.forEach((resource) =>
        requiredPermissions?.push({ action, resource })
      )
      : requiredPermissions?.push({ action, resource: '' })
  );

  const newRequiredPermissions = requiredPermissions.reduce((acc, cur) => {
    const compatibleActions = mappedUserPolicies.filter((x) =>
      createRegexFor(x.action).test(cur.action)
    );

    const compatibleDeniedResources = compatibleActions.filter(
      (x) => createRegexFor(x.resource).test(cur.resource) && x.effect === 'Deny'
    );

    const compatibleAllowedResources = compatibleActions.filter(
      (x) => createRegexFor(x.resource).test(cur.resource) && x.effect === 'Allow'
    );

    if (compatibleActions.length === 0) {
      acc.push(cur);
    } else if (compatibleDeniedResources.length > 0) {
      acc.push(cur);
    } else if (compatibleAllowedResources.length === 0) {
      acc.push(cur);
    }
    return acc;
  }, []);
  // In case you want an or operation
  if (or) {
    return newRequiredPermissions.length !== requiredPermissions.length;
  }
  // If there are still elements on the array, return permission denied
  return newRequiredPermissions.length === 0;
};
