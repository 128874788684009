import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import CountryFlag from 'components/CountryFlag';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import {
  CompanyBody1,
  CompanySubtitle2,
} from 'pages/private/KYBCompany/CompanyDetailsV2/components';
import ExpandIcon from 'assets/images/company/expand-tree-table-icon.svg';
import UnexpandIcon from 'assets/images/company/unexpand-tree-table-icon.svg';
import DisabledIcon from 'assets/images/company/disable-tree-table-icon.svg';
import classNames from 'classnames';
import './styles.less';
import listToTree from './listToTree';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companyDetails.subPages.companyLinks.groupStructure.treeTable';

const NestedList = ({ items, first = false }) => {
  const { i18nFormatDateOnly } = useI18nFormatters();

  const handleClick = (e) => {
    const button = e.target.closest('button');

    if (button.querySelector('.folder.disabled')) return;

    e.target.closest('.row-wrapper').querySelector('.nested').classList.toggle('active');

    button.querySelector('.folder.unexpand')?.classList?.toggle('active');
    button.querySelector('.folder.expand')?.classList?.toggle('active');
  };

  const CompanyNameButton = ({ item }) => (
    <button type="button" onClick={handleClick}>
      <div
        className="company-name"
        style={{
          paddingLeft: item.level * 20,
        }}
      >
        <img
          src={ExpandIcon}
          className="folder expand"
          alt="click for expanding the line"
        />
        {item.childrens.length > 0 && (
          <img
            src={UnexpandIcon}
            className="folder unexpand active"
            alt="click for unexpanding the line"
          />
        )}
        {!item.childrens.length && (
          <img src={DisabledIcon} className="folder disabled active" alt="folder icon" />
        )}
        <CompanyBody1>{item?.name}</CompanyBody1>
      </div>
    </button>
  );

  return (
    <div className={classNames(!first && 'nested', 'active', 'toggle-transition')}>
      {items.map((item) => {
        return (
          <div className="row-wrapper" key={item.id}>
            <div className="row">
              <Row>
                <Col span={10}>
                  <CompanyNameButton item={item} />
                </Col>
                <Col span={2} className="flex">
                  {item?.country && <CountryFlag country={item.country} />}
                </Col>
                <Col span={8}>
                  <CompanyBody1>{item?.address?.fullAddress}</CompanyBody1>
                </Col>
                <Col span={4}>
                  <CompanyBody1>
                    {i18nFormatDateOnly(item.latestAnnualAccounts, 1)}
                  </CompanyBody1>
                </Col>
              </Row>
            </div>
            {item.childrens && <NestedList items={item.childrens} />}
          </div>
        );
      })}
    </div>
  );
};

export const TreeTable = ({ groupStructure }) => {
  const { t: translate } = useTranslation();

  const treeData = listToTree(groupStructure);

  return (
    <>
      <Row className="company-link-tree-table-header">
        <Col span={10}>
          <CompanySubtitle2>
            {translate(`${I18N_BASE_PATH}.columns.companyName`, 'Company name')}
          </CompanySubtitle2>
        </Col>
        <Col span={2}>
          <CompanySubtitle2>
            {translate(`${I18N_BASE_PATH}.columns.country`, 'Country')}
          </CompanySubtitle2>
        </Col>
        <Col span={8}>
          <CompanySubtitle2>
            {translate(`${I18N_BASE_PATH}.columns.address`, 'Address')}
          </CompanySubtitle2>
        </Col>
        <Col span={4}>
          <CompanySubtitle2>
            {translate(
              `${I18N_BASE_PATH}.columns.latestFfinancials`,
              'Latest financials'
            )}
          </CompanySubtitle2>
        </Col>
      </Row>
      <NestedList items={treeData} first />
    </>
  );
};

const TreeTableCompanyLinks = ({ groupStructure }) => {
  return (
    groupStructure.length > 0 && (
      <div className="company-link-tree-table">
        {groupStructure.length > 0 && <TreeTable groupStructure={groupStructure} />}
      </div>
    )
  );
};

export default TreeTableCompanyLinks;
