import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Typography } from 'antd';
import IconSuccess from 'assets/images/icon-success.svg';

import copyTextToClipboard from 'utils/copyTextToClipboard';

import './styles.less';

const { Title } = Typography;

const ModalCopyLink = () => {
  const { t: translate } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const [onboardingUrl, setOnboardingUrl] = useState('');
  const [originModal, setOriginModal] = useState('');

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback((url, origin) => {
    setOnboardingUrl(url);
    setOriginModal(origin);
    setModalVisible(true);
  }, []);

  const CardCopyLinkModal = useMemo(
    () => (
      <Modal
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        visible={modalVisible}
        footer={null}
        width={700}
        onCancel={closeModal}
      >
        <div id="content-container">
          <img src={IconSuccess} alt="" className="mrg-btm-30" />
          <Title level={2}>
            {translate(
              `components.modalCopyLink.index.cardCopyLinkModal.title.${
                originModal === 'share' ? 'share' : 'default'
              }`
            )}
          </Title>
          <div className="copy-link-container">
            <div className="copy-link-text">{onboardingUrl}</div>
            <button
              type="button"
              className="copy-link-button font-size-15"
              onClick={() =>
                copyTextToClipboard(
                  onboardingUrl,
                  translate(
                    'components.modalCopyLink.index.cardCopyLinkModal.button.copyTextToClipboard.success'
                  ),
                  translate(
                    'components.modalCopyLink.index.cardCopyLinkModal.button.copyTextToClipboard.error'
                  )
                )
              }
            >
              {translate('components.modalCopyLink.index.cardCopyLinkModal.button.text')}
            </button>
          </div>
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, originModal, onboardingUrl, translate]
  );

  return { openModal, CardCopyLinkModal };
};

export default ModalCopyLink;
