import React, { useMemo } from 'react';
import EmptyExecutionState from 'components/EmptyExecutionState';
import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';

import { Row, Col, Collapse, Divider } from 'antd';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import './styles.less';

import facebook from 'assets/images/Facebook.svg';
import google from 'assets/images/Google.svg';
import apple from 'assets/images/Apple.svg';
import microsoft from 'assets/images/Windows.svg';
import defaultApp from 'assets/images/Email.svg';
import spotify from 'assets/images/Spotify.svg';
import tumblr from 'assets/images/Tumblr.svg';
import linkedin from 'assets/images/Linkedin.svg';
import github from 'assets/images/Github.svg';
import skype from 'assets/images/Skype.svg';
import discord from 'assets/images/Discord.svg';
import twitter from 'assets/images/Twitter.svg';
import instagram from 'assets/images/Instagram.svg';
import weibo from 'assets/images/Weibo.svg';
import flickr from 'assets/images/Flickr.svg';
import foursquare from 'assets/images/Foursquare.svg';
import lastfm from 'assets/images/Last.fm.svg';
import pinterest from 'assets/images/Pinterest.svg';
import kakao from 'assets/images/KAKAO.svg';

const I18N_BASE_PATH = 'components.dataSourceCards.basicInfos.components';
const { Panel } = Collapse;

const PfEmailAnalysis = ({
  source = {},
  toPrint = false,
  statusCodePfEmailAnalysis = '',
}) => {
  const { t: translate } = useTranslation();
  const parsedData = useMemo(() => source?.data || source, [source]);
  const { i18nFormatDate } = useI18nFormatters();
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);
  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_cloud"
      title={translate(`${I18N_BASE_PATH}.basicInfosCard.sources.emailAnalysis`)}
      toPrint={toPrint}
    >
      {statusCode !== '01' && statusCodePfEmailAnalysis !== '01' ? (
        <EmptyExecutionState
          statusCode={statusCode}
          message={source?.message || source?.data?.message}
        />
      ) : (
        <Row id="email-id">
          <Col span={10} className="mrg-btm-25 mrg-top-10 col-ant">
            <strong>{translate(`${I18N_BASE_PATH}.emailAnalysis.fields.email`)}</strong>
            <span className="text-style">
              {parsedData?.email ||
                translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
            </span>
          </Col>
          <Col span={7} className="mrg-btm-25 col-ant mrg-top-10">
            <strong>{translate(`${I18N_BASE_PATH}.emailAnalysis.fields.score`)}</strong>
            <span className="text-style">
              {parsedData?.score ||
                translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
            </span>
          </Col>
          <Col span={7} className="mrg-btm-25 col-ant mrg-top-10">
            <strong>
              {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.deliverable`)}
            </strong>
            <span className="text-style">
              {parsedData?.deliverable
                ? 'True'
                : 'False' || translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
            </span>
          </Col>
          <Col
            span={24}
            className="col-ant"
            style={{ marginLeft: '0px', paddingLeft: '0px' }}
          >
            <Collapse defaultActiveKey={['1', '2', '3']} expandIconPosition="right" ghost>
              <Panel
                header={
                  <b>{translate(`${I18N_BASE_PATH}.emailAnalysis.fields.titleDomain`)}</b>
                }
                key="1"
                showArrow
                className="mrg-top-30"
              >
                {parsedData?.domainDetails ? (
                  <>
                    <Row id="email-id">
                      <Col span={8} className="mrg-btm-25">
                        <strong>
                          {translate(
                            `${I18N_BASE_PATH}.emailAnalysis.fields.domainDetails.domain`
                          )}
                        </strong>
                        <span className="text-style">
                          {parsedData?.domainDetails?.domain ||
                            translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                        </span>
                      </Col>
                      <Col span={8} className="mrg-btm-25">
                        <strong>
                          {translate(
                            `${I18N_BASE_PATH}.emailAnalysis.fields.domainDetails.tld`
                          )}
                        </strong>
                        <span className="text-style">
                          {parsedData?.domainDetails?.tld ||
                            translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                        </span>
                      </Col>
                      <Col span={8} className="mrg-btm-25">
                        <strong>
                          {translate(
                            `${I18N_BASE_PATH}.emailAnalysis.fields.domainDetails.createdAt`
                          )}
                        </strong>
                        <span className="text-style">
                          {parsedData?.domainDetails?.createdAt
                            ? i18nFormatDate(parsedData?.domainDetails?.createdAt, 3)
                            : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                        </span>
                      </Col>
                      <Col span={8} className="mrg-btm-25">
                        <strong>
                          {translate(
                            `${I18N_BASE_PATH}.emailAnalysis.fields.domainDetails.registrant`
                          )}
                        </strong>
                        <span className="text-style">
                          {parsedData?.domainDetails?.registrant ||
                            translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                        </span>
                      </Col>
                      <Col span={8} className="mrg-btm-25">
                        <strong>
                          {translate(
                            `${I18N_BASE_PATH}.emailAnalysis.fields.domainDetails.registeredTo`
                          )}
                        </strong>
                        <span className="text-style">
                          {parsedData?.domainDetails?.registeredTo ||
                            translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                        </span>
                      </Col>
                      <Col span={8} className="mrg-btm-25">
                        <strong>
                          {translate(
                            `${I18N_BASE_PATH}.emailAnalysis.fields.domainDetails.updatedAt`
                          )}
                        </strong>
                        <span className="text-style">
                          {i18nFormatDate(parsedData?.domainDetails?.updatedAt, 3) ||
                            translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                        </span>
                      </Col>
                      <Col span={8} className="mrg-btm-25">
                        <strong>
                          {translate(
                            `${I18N_BASE_PATH}.emailAnalysis.fields.domainDetails.free`
                          )}
                        </strong>
                        <span className="text-style">
                          {parsedData?.domainDetails?.free
                            ? 'True'
                            : 'False' ||
                              translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                        </span>
                      </Col>
                      <Col span={8} className="mrg-btm-25">
                        <strong>
                          {translate(
                            `${I18N_BASE_PATH}.emailAnalysis.fields.domainDetails.custom`
                          )}
                        </strong>
                        <span className="text-style">
                          {parsedData?.domainDetails?.custom
                            ? 'True'
                            : 'False' ||
                              translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                        </span>
                      </Col>
                      <Col span={8} className="mrg-btm-25">
                        <strong>
                          {translate(
                            `${I18N_BASE_PATH}.emailAnalysis.fields.domainDetails.expireDate`
                          )}
                        </strong>
                        <span className="text-style">
                          {i18nFormatDate(parsedData?.domainDetails?.expireDate, 3) ||
                            translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                        </span>
                      </Col>
                      <Col span={8} className="mrg-btm-25">
                        <strong>
                          {translate(
                            `${I18N_BASE_PATH}.emailAnalysis.fields.domainDetails.spfStrict`
                          )}
                        </strong>
                        <span className="text-style">
                          {parsedData?.domainDetails?.spfStrict
                            ? 'True'
                            : 'False' ||
                              translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                        </span>
                      </Col>
                      <Col span={8} className="mrg-btm-25">
                        <strong>
                          {translate(
                            `${I18N_BASE_PATH}.emailAnalysis.fields.domainDetails.validMX`
                          )}
                        </strong>
                        <span className="text-style">
                          {parsedData?.domainDetails?.validMX
                            ? 'True'
                            : 'False' ||
                              translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                        </span>
                      </Col>
                      <Col span={8} className="mrg-btm-25">
                        <strong>
                          {translate(
                            `${I18N_BASE_PATH}.emailAnalysis.fields.domainDetails.registered`
                          )}
                        </strong>
                        <span className="text-style">
                          {parsedData?.domainDetails?.registered
                            ? 'True'
                            : 'False' ||
                              translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                        </span>
                      </Col>
                      <Col span={8} className="mrg-btm-25">
                        <strong>
                          {translate(
                            `${I18N_BASE_PATH}.emailAnalysis.fields.domainDetails.suspiciousTld`
                          )}
                        </strong>
                        <span className="text-style">
                          {parsedData?.domainDetails?.suspiciousTld
                            ? 'True'
                            : 'False' ||
                              translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                        </span>
                      </Col>
                      <Col span={8} className="mrg-btm-25">
                        <strong>
                          {translate(
                            `${I18N_BASE_PATH}.emailAnalysis.fields.domainDetails.websiteExists`
                          )}
                        </strong>
                        <span className="text-style">
                          {parsedData?.domainDetails?.websiteExists
                            ? 'True'
                            : 'False' ||
                              translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                        </span>
                      </Col>
                      <Col span={8} className="mrg-btm-25">
                        <strong>
                          {translate(
                            `${I18N_BASE_PATH}.emailAnalysis.fields.domainDetails.disposable`
                          )}
                        </strong>
                        <span className="text-style">
                          {parsedData?.domainDetails?.disposable
                            ? 'True'
                            : 'False' ||
                              translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                        </span>
                      </Col>
                      <Col span={8} className="mrg-btm-25">
                        <strong>
                          {translate(
                            `${I18N_BASE_PATH}.emailAnalysis.fields.domainDetails.dmarcEnforced`
                          )}
                        </strong>
                        <span className="text-style">
                          {parsedData?.domainDetails?.dmarcEnforced
                            ? 'True'
                            : 'False' ||
                              translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                        </span>
                      </Col>
                      <Col span={8} className="mrg-btm-25">
                        <strong>
                          {translate(
                            `${I18N_BASE_PATH}.emailAnalysis.fields.domainDetails.acceptAll`
                          )}
                        </strong>
                        <span className="text-style">
                          {parsedData?.domainDetails?.acceptAll
                            ? 'True'
                            : 'False' ||
                              translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <> {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}</>
                )}
              </Panel>
              <Divider />
              <Panel
                header={
                  <b>{translate(`${I18N_BASE_PATH}.emailAnalysis.fields.titleRules`)}</b>
                }
                key="2"
                showArrow
                className="mrg-top-60"
              >
                {parsedData?.appliedRules?.length > 0 ? (
                  <>
                    {parsedData?.appliedRules?.map((rules) => (
                      <>
                        <Row id="phone-id">
                          <Col span={8} className="mrg-btm-25">
                            <strong>
                              {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.id`)}
                            </strong>
                            <span className="text-style">{rules.id}</span>
                          </Col>
                          <Col span={8} className="mrg-btm-25">
                            <strong>
                              {translate(
                                `${I18N_BASE_PATH}.emailAnalysis.fields.operation`
                              )}
                            </strong>
                            <span className="text-style">{rules.operation}</span>
                          </Col>
                          <Col span={8} className="mrg-btm-25">
                            <strong>
                              {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.score`)}
                            </strong>
                            <span className="text-style">{rules.score}</span>
                          </Col>
                          <Col span={24} className="mrg-btm-25">
                            <strong>
                              {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.name`)}
                            </strong>
                            <span className="text-style">{rules.name}</span>
                          </Col>
                        </Row>
                      </>
                    ))}
                  </>
                ) : (
                  <> {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}</>
                )}
              </Panel>
              <Divider />
              <Panel
                header={
                  <b>{translate(`${I18N_BASE_PATH}.emailAnalysis.fields.titleFlags`)}</b>
                }
                key="3"
                showArrow
                className="mrg-top-60"
              >
                {parsedData?.id ? (
                  <>
                    <strong>
                      {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.id`)}
                    </strong>
                    <span className="text-style">{parsedData?.id}</span>
                  </>
                ) : (
                  <> {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}</>
                )}
              </Panel>
              <Divider />
            </Collapse>
            <Divider />
          </Col>
          <Col span={24} className="mrg-btm-70 mrg-top-60 col-ant">
            <strong>
              {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.titleApps`)}
            </strong>
          </Col>
          {parsedData?.accountDetails?.facebook?.registered && (
            <>
              <Col span={24} className="col-ant">
                <img src={facebook} alt="" />
                <strong className="mrg-left-10 pdd-top-80 titleApp">FACEBOOK</strong>
              </Col>
              <Col span={12} className="mrg-btm-25 mrg-top-40 col-ant">
                <strong>{translate(`${I18N_BASE_PATH}.emailAnalysis.fields.url`)}</strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.facebook?.url
                    ? parsedData?.accountDetails?.facebook?.url
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={12} className="mrg-btm-25 col-ant mrg-top-40">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.name`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.facebook?.name
                    ? parsedData?.accountDetails?.facebook?.name
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={24} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.photo`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.facebook?.photo ? (
                    <a href={parsedData?.accountDetails?.facebook?.photo} target="blank">
                      {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.visualize`)}
                    </a>
                  ) : (
                    translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)
                  )}
                </span>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.google.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={google} alt="" />
                <strong className="mrg-left-10  titleApp">GOOGLE</strong>
              </Col>
              <Col span={12} className="mrg-btm-25 mrg-top-40 col-ant">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.photo`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.google?.photo ? (
                    <a href={parsedData?.accountDetails?.google?.photo} target="blank">
                      {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.visualize`)}
                    </a>
                  ) : (
                    translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)
                  )}
                </span>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.apple?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={apple} alt="" />
                <strong className="mrg-left-10  titleApp">APPLE</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.microsoft?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={microsoft} alt="" />
                <strong className="mrg-left-10  titleApp">MICROSOFT</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.gravatar?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={defaultApp} alt="" />
                <strong className="mrg-left-10  titleApp">GRAVATAR</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.ebay?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={defaultApp} alt="" />
                <strong className="mrg-left-10  titleApp">EBAY</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.spotify?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={spotify} alt="" />
                <strong className="mrg-left-10  titleApp">SPOTIFY</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.tumblr?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={tumblr} alt="" />
                <strong className="mrg-left-10  titleApp">TUMBLR</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.linkedin?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={linkedin} alt="" />
                <strong className="mrg-left-10  titleApp">LINKEDIN</strong>
              </Col>
              <Col span={24} className="mrg-btm-25 mrg-top-40 col-ant">
                <strong>{translate(`${I18N_BASE_PATH}.emailAnalysis.fields.url`)}</strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.linkedin?.url
                    ? parsedData?.accountDetails?.linkedin?.url
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={24} className="mrg-btm-25 mrg-top-10 col-ant">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.name`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.linkedin?.name
                    ? parsedData?.accountDetails?.linkedin?.name
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={24} className="mrg-btm-25 mrg-top-20 col-ant">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.title`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.linkedin?.title
                    ? parsedData?.accountDetails?.linkedin?.title
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={24} className="mrg-btm-25 mrg-top-20 col-ant">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.website`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.linkedin?.website
                    ? parsedData?.accountDetails?.linkedin?.website
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={24} className="mrg-btm-25 mrg-top-20 col-ant">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.twitter`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.linkedin?.twitter
                    ? parsedData?.accountDetails?.linkedin?.twitter
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={12} className="mrg-btm-25 mrg-top-20 col-ant">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.photo`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.linkedin?.photo ? (
                    <a href={parsedData?.accountDetails?.linkedin?.photo} target="blank">
                      {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.visualize`)}
                    </a>
                  ) : (
                    translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)
                  )}
                </span>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.github?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={github} alt="" />
                <strong className="mrg-left-10  titleApp">GITHUB</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.vimeo?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={defaultApp} alt="" />
                <strong className="mrg-left-10  titleApp">VIMEO</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.skype?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={skype} alt="" />
                <strong className="mrg-left-10  titleApp">SKYPE</strong>
              </Col>
              <Col span={8} className="mrg-btm-25 mrg-top-40 col-ant">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.country`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.skype?.country
                    ? parsedData?.accountDetails?.skype?.country
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-40">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.city`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.skype?.city
                    ? parsedData?.accountDetails?.skype?.city
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-40">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.gender`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.skype?.gender
                    ? parsedData?.accountDetails?.skype?.gender
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 mrg-top-7 col-ant">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.name`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.skype?.name
                    ? parsedData?.accountDetails?.skype?.name
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>{translate(`${I18N_BASE_PATH}.emailAnalysis.fields.id`)}</strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.skype?.id
                    ? parsedData?.accountDetails?.skype?.id
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.handle`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.skype?.handle
                    ? parsedData?.accountDetails?.skype?.handle
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 mrg-top-7 col-ant">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.biography`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.skype?.biography
                    ? parsedData?.accountDetails?.skype?.biography
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>{translate(`${I18N_BASE_PATH}.emailAnalysis.fields.age`)}</strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.skype?.age
                    ? parsedData?.accountDetails?.skype?.age
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.language`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.skype?.language
                    ? parsedData?.accountDetails?.skype?.language
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={24} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.state`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.skype?.state
                    ? parsedData?.accountDetails?.skype?.state
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={24} className="mrg-btm-25 mrg-top-7 col-ant">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.photo`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.linkedin?.photo ? (
                    <a href={parsedData?.accountDetails?.linkedin?.photo} target="blank">
                      {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.visualize`)}
                    </a>
                  ) : (
                    translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)
                  )}
                </span>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.discord?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={discord} alt="" />
                <strong className="mrg-left-10  titleApp">DISCORD</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.amazon?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={defaultApp} alt="" />
                <strong className="mrg-left-10  titleApp">AMAZON</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.twitter?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={twitter} alt="" />
                <strong className="mrg-left-10  titleApp">TWITTER</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.yahoo?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={defaultApp} alt="" />
                <strong className="mrg-left-10  titleApp">YAHOO</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.instagram?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={instagram} alt="" />
                <strong className="mrg-left-10  titleApp">INSTAGRAM</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.weibo?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={weibo} alt="" />
                <strong className="mrg-left-10  titleApp">WEIBO</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.flickr?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={flickr} alt="" />
                <strong className="mrg-left-10  titleApp">FLICKR</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.foursquare?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={foursquare} alt="" />
                <strong className="mrg-left-10  titleApp">FOURSQUARE</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.lastfm?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={lastfm} alt="" />
                <strong className="mrg-left-10  titleApp">LASTFM</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.myspace?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={defaultApp} alt="" />
                <strong className="mrg-left-10  titleApp">MY SPACE</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.pinterest?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={pinterest} alt="" />
                <strong className="mrg-left-10  titleApp">PINTEREST</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {!parsedData?.accountDetails?.ok?.registered && (
            <>
              <Col span={24} className="col-ant">
                <img src={defaultApp} alt="" />
                <strong className="mrg-left-10 pdd-top-80 titleApp">OK</strong>
              </Col>
              <Col span={12} className="mrg-btm-25 mrg-top-40 col-ant">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.city`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.ok?.city
                    ? parsedData?.accountDetails?.ok?.city
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={12} className="mrg-btm-25 col-ant mrg-top-40">
                <strong>{translate(`${I18N_BASE_PATH}.emailAnalysis.fields.age`)}</strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.ok?.age
                    ? parsedData?.accountDetails?.ok?.age
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={24} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.dateJoined`)}
                </strong>
                <span className="text-style">
                  {parsedData?.ok?.dateJoined
                    ? i18nFormatDate(parsedData?.ok?.dateJoined, 3)
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.kakao?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={kakao} alt="" />
                <strong className="mrg-left-10  titleApp">KAKAO</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.booking?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={defaultApp} alt="" />
                <strong className="mrg-left-10  titleApp">BOOKING</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.qzone?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={defaultApp} alt="" />
                <strong className="mrg-left-10  titleApp">QZONE</strong>
              </Col>
              <Col span={24} className="col-ant mrg-top-40 mrg-btm-10">
                <strong className="default">
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                </strong>
              </Col>
              <Divider />
            </>
          )}
          {parsedData?.accountDetails?.airbnb?.registered && (
            <>
              <Col span={24} className="col-ant mrg-top-20">
                <img src={defaultApp} alt="" />
                <strong className="mrg-left-10  titleApp">AIRBNB</strong>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-40">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.about`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.airbnb?.about
                    ? parsedData?.accountDetails?.airbnb?.about
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-40">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.createdAt`)}
                </strong>
                <span className="text-style">
                  {parsedData?.airbnb?.createdAt
                    ? i18nFormatDate(parsedData?.airbnb?.createdAt, 3)
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-40">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.name`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.airbnb?.firstName
                    ? parsedData?.accountDetails?.airbnb?.firstName
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.identityVerified`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.airbnb?.identityVerified
                    ? parsedData?.accountDetails?.airbnb?.identityVerified
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.localization`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.airbnb?.location
                    ? parsedData?.accountDetails?.airbnb?.location
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.photo`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.airbnb?.image ? (
                    <a href={parsedData?.accountDetails?.airbnb?.image} target="blank">
                      {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.visualize`)}
                    </a>
                  ) : (
                    translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)
                  )}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.revieweeCount`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.airbnb?.revieweeCount
                    ? parsedData?.accountDetails?.airbnb?.revieweeCount
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.trips`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.airbnb?.trips
                    ? parsedData?.accountDetails?.airbnb?.trips
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Col span={8} className="mrg-btm-25 col-ant mrg-top-7">
                <strong>
                  {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.work`)}
                </strong>
                <span className="text-style">
                  {parsedData?.accountDetails?.airbnb?.work
                    ? parsedData?.accountDetails?.airbnb?.work
                    : translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                </span>
              </Col>
              <Divider />
            </>
          )}
          <Col span={24} style={{ marginLeft: '0px', paddingLeft: '0px' }}>
            <Collapse expandIconPosition="right" ghost>
              <Panel
                header={
                  <b>
                    {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.titleRegister`)}
                  </b>
                }
                key="1"
                showArrow
                className="mrg-top-30"
              >
                {!parsedData?.accountDetails?.facebook?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={facebook} alt="" />
                      <strong className="mrg-left-10  titleApp">FACEBOOK</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.google?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={google} alt="" />
                      <strong className="mrg-left-10  titleApp">GOOGLE</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.instagram?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={instagram} alt="" />
                      <strong className="mrg-left-10  titleApp">INSTAGRAM</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.twitter?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={twitter} alt="" />
                      <strong className="mrg-left-10  titleApp">TWITTER</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.kakao?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={kakao} alt="" />
                      <strong className="mrg-left-10  titleApp">KAKAO</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.ok?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={defaultApp} alt="" />
                      <strong className="mrg-left-10  titleApp">OK</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.microsoft?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={microsoft} alt="" />
                      <strong className="mrg-left-10  titleApp">MICROSOFT</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.skype?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={skype} alt="" />
                      <strong className="mrg-left-10  titleApp">SKYPE</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.apple?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={apple} alt="" />
                      <strong className="mrg-left-10  titleApp">APPLE</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.yahoo?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={defaultApp} alt="" />
                      <strong className="mrg-left-10  titleApp">YAHOO</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.ebay?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={defaultApp} alt="" />
                      <strong className="mrg-left-10  titleApp">EBAY</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.gravatar?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={defaultApp} alt="" />
                      <strong className="mrg-left-10  titleApp">GRAVATAR</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.spotify?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={spotify} alt="" />
                      <strong className="mrg-left-10  titleApp">SPOTIFY</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.tumblr?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={tumblr} alt="" />
                      <strong className="mrg-left-10  titleApp">TUMBLR</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.linkedin?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={linkedin} alt="" />
                      <strong className="mrg-left-10  titleApp">LINKEDIN</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.weibo?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={weibo} alt="" />
                      <strong className="mrg-left-10  titleApp">WEIBO</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.github?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={github} alt="" />
                      <strong className="mrg-left-10  titleApp">GITHUB</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.vimeo?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={defaultApp} alt="" />
                      <strong className="mrg-left-10  titleApp">VIMEO</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.flickr?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={flickr} alt="" />
                      <strong className="mrg-left-10  titleApp">FLICKR</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.foursquare?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={foursquare} alt="" />
                      <strong className="mrg-left-10  titleApp">FOURSQUARE</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.lastfm?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={lastfm} alt="" />
                      <strong className="mrg-left-10  titleApp">LASTFM</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.myspace?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={defaultApp} alt="" />
                      <strong className="mrg-left-10  titleApp">MYSPACE</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.pinterest?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={pinterest} alt="" />
                      <strong className="mrg-left-10  titleApp">PINTEREST</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.discord?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={discord} alt="" />
                      <strong className="mrg-left-10  titleApp">DISCORD</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.booking?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={defaultApp} alt="" />
                      <strong className="mrg-left-10  titleApp">BOOKING</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.airbnb?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={defaultApp} alt="" />
                      <strong className="mrg-left-10  titleApp">AIRBNB</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.amazon?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={defaultApp} alt="" />
                      <strong className="mrg-left-10  titleApp">AMAZON</strong>
                    </Col>
                    <Divider />
                  </>
                )}
                {!parsedData?.accountDetails?.qzone?.registered && (
                  <>
                    <Col
                      span={24}
                      className="mrg-top-20"
                      style={{
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        paddingBottom: '20px',
                      }}
                    >
                      <img src={defaultApp} alt="" />
                      <strong className="mrg-left-10  titleApp">QZONE</strong>
                    </Col>
                    <Divider />
                  </>
                )}
              </Panel>
              <Divider />
            </Collapse>
          </Col>
          <Col
            span={24}
            className="col-ant"
            style={{ marginLeft: '0px', paddingLeft: '0px' }}
          >
            <Collapse defaultActiveKey={['1']} expandIconPosition="right" ghost>
              <Panel
                header={
                  <b>
                    {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.breachDetails`)}
                  </b>
                }
                key="1"
                showArrow
                className="mrg-top-30"
              >
                {parsedData?.breachDetails ? (
                  <>
                    <Row id="phone-id">
                      <Col span={24} className="mrg-btm-25">
                        <strong>
                          {translate(
                            `${I18N_BASE_PATH}.emailAnalysis.fields.haveIBeenPwnedListed`
                          )}
                        </strong>
                        <span className="text-style">
                          {parsedData?.breachDetails?.haveIBeenPwnedListed
                            ? 'True'
                            : 'False'}
                        </span>
                      </Col>
                      <Col span={24} className="mrg-btm-25">
                        <strong>
                          {translate(
                            `${I18N_BASE_PATH}.emailAnalysis.fields.numberOfBreaches`
                          )}
                        </strong>
                        <span className="text-style">
                          {parsedData?.breachDetails?.numberOfBreaches ||
                            translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                        </span>
                      </Col>
                      <Col span={24} className="mrg-btm-25">
                        <strong>
                          {translate(
                            `${I18N_BASE_PATH}.emailAnalysis.fields.firstBreach`
                          )}
                        </strong>
                        <span className="text-style">
                          {i18nFormatDate(parsedData?.breachDetails?.firstBreach, 1) ||
                            translate(`${I18N_BASE_PATH}.emailAnalysis.fields.default`)}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <span>
                    {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.defaultError`)}
                  </span>
                )}
                {parsedData?.breachDetails?.breaches?.length > 0 && (
                  <>
                    <Collapse expandIconPosition="right" ghost>
                      <Panel
                        header={
                          <b>
                            {translate(`${I18N_BASE_PATH}.emailAnalysis.fields.breach`)}
                          </b>
                        }
                        key="2"
                        showArrow
                        className="mrg-top-30"
                      >
                        {parsedData?.breachDetails?.breaches?.map((breach) => (
                          <>
                            <Row id="phone-id">
                              <Col span={24} className="mrg-btm-25">
                                <strong>
                                  {translate(
                                    `${I18N_BASE_PATH}.emailAnalysis.fields.name`
                                  )}
                                </strong>
                                <span className="text-style">
                                  {breach.name ||
                                    translate(
                                      `${I18N_BASE_PATH}.emailAnalysis.fields.default`
                                    )}
                                </span>
                              </Col>
                              <Col span={24} className="mrg-btm-25">
                                <strong>
                                  {translate(
                                    `${I18N_BASE_PATH}.emailAnalysis.fields.domain`
                                  )}
                                </strong>
                                <span className="text-style">
                                  {breach.domain ||
                                    translate(
                                      `${I18N_BASE_PATH}.emailAnalysis.fields.default`
                                    )}
                                </span>
                              </Col>
                              <Col span={24} className="mrg-btm-25">
                                <strong>
                                  {translate(
                                    `${I18N_BASE_PATH}.emailAnalysis.fields.date`
                                  )}
                                </strong>
                                <span className="text-style">
                                  {i18nFormatDate(breach.date, 1) ||
                                    translate(
                                      `${I18N_BASE_PATH}.emailAnalysis.fields.default`
                                    )}
                                </span>
                              </Col>
                              <Divider />
                            </Row>
                          </>
                        ))}
                      </Panel>
                    </Collapse>
                  </>
                )}
              </Panel>
            </Collapse>
          </Col>
        </Row>
      )}
    </DataSourceCardCollapsePanel>
  );
};

export default PfEmailAnalysis;
