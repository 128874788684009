/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import { isEmpty } from 'utils/isObjectEmpty';
import { labelStatus } from 'utils/handlerStatus';

import EmptyExecutionState from 'components/EmptyExecutionState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

const FGTS = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  return (
    <DataSourceCardCollapsePanel
      title={translate(
        'components.dataSourceCards.laborInformation.companies.components.fgts.index.fgts.columnLabel.title'
      )}
      customId="labor-infos-component"
      icon="caf-ic_awards"
      toPrint={toPrint}
    >
      <ColumnLabel
        title={translate(
          'components.dataSourceCards.laborInformation.companies.components.fgts.index.fgts.columnLabel.title'
        )}
        type={queryWorked && labelStatus(parsedData?.status)?.type}
        description={
          queryWorked && parsedData?.status
            ? translate(labelStatus(parsedData?.status)?.description)
            : parsedData?.message
        }
        info={
          !toPrint &&
          translate(
            'components.dataSourceCards.laborInformation.companies.components.fgts.index.fgts.columnLabel.info'
          )
        }
      >
        {queryWorked ? (
          <>
            {!isEmpty(parsedData) ? (
              <>
                {parsedData?.numberCertificate && (
                  <span>
                    {translate(
                      'components.dataSourceCards.laborInformation.companies.components.fgts.index.fgts.columnLabel.querySucceeded.hasData.numberCertificate',
                      { value: parsedData?.numberCertificate }
                    )}
                  </span>
                )}
                {parsedData?.dueDate && i18nFormatDate(parsedData?.dueDate, 1) !== '-' && (
                  <span>
                    {translate(
                      'components.dataSourceCards.laborInformation.companies.components.fgts.index.fgts.columnLabel.querySucceeded.hasData.dueDate'
                    )}{' '}
                    {i18nFormatDate(parsedData?.dueDate, 1)}
                  </span>
                )}
              </>
            ) : (
              <span>
                {translate(
                  'components.dataSourceCards.laborInformation.companies.components.fgts.index.fgts.columnLabel.querySucceeded.hasNotData'
                )}
              </span>
            )}
          </>
        ) : (
          <EmptyExecutionState
            statusCode={statusCode}
            message={source?.message || parsedData?.message}
          />
        )}
      </ColumnLabel>
    </DataSourceCardCollapsePanel>
  );
};

export default FGTS;
