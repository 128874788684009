/* eslint-disable react/prop-types */
import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactJsonView from 'react-json-view';
import { Card, Modal } from 'antd';

import PageTitle from 'components/PageTitle';

const ScoreDetails = () => {
  const { t: translate } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const [scoreCheck, setScoreCheck] = useState({});

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback((data) => {
    setModalVisible(true);
    setScoreCheck(data);
  }, []);

  const ScoreDetailsModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeModal}
        footer={null}
        style={{ top: 40 }}
        width={900}
      >
        <PageTitle
          title={translate(
            'components.dataSourceCards.financialInformation.shared.zoop.scoreDetailsModal.index.scoreDetailsModal.pageTitle.title'
          )}
        />
        <Card id="spc-details-modal" className="no-mrg no-pdd">
          <ReactJsonView
            name={false}
            iconStyle="triangle"
            indentWidth={2}
            collapsed={1}
            theme="shapeshifter:inverted"
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
            src={scoreCheck?.scoreDetails || {}}
          />
        </Card>
      </Modal>
    ),
    [modalVisible, closeModal, scoreCheck, translate]
  );

  return { openModal, closeModal, ScoreDetailsModal };
};

export default ScoreDetails;
