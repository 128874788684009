import { Col, Collapse, Row, Divider } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DottedLabel from 'components/DottedLabel';

import { useI18nFormatters } from 'hooks/i18nFormatters';

// import { Container } from './styles';

const { Panel } = Collapse;

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.companies.components.spcReportParticipationScore.otherInfos';

function FinancialPending({ source = {}, toPrint = false }) {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const parsedData = useMemo(() => source?.data || source, [source]);

  const financialPending = useMemo(() => parsedData?.financialPending || {}, [
    parsedData,
  ]);

  return (
    <Collapse className="mrg-top-10" defaultActiveKey={toPrint ? ['1'] : null}>
      <Panel
        key="1"
        disabled={!financialPending?.details?.length}
        header={
          <Col>
            <Row align="middle" justify="space-between">
              <Col span={17}>
                <span className="text-dark text-bold">
                  {translate(`${I18N_BASE_PATH}.index.financialPending.title`)}
                </span>
              </Col>
              <Col className="mrg-right-15">
                <>
                  <DottedLabel
                    description={`(${financialPending?.total || 0}) ${translate(
                      `${I18N_BASE_PATH}.index.restrictions`
                    )}`}
                    type={+financialPending?.total > 0 ? 'danger' : 'primary'}
                  />
                </>
              </Col>
            </Row>
            <Row align="middle" className="mrg-top-10" justify="space-between">
              <Col span={24}>
                <span className="collapse-label">
                  {translate(
                    `${I18N_BASE_PATH}.index.financialPending.columns.lastOccurenceDate`
                  )}
                  :
                </span>
                <span>
                  {i18nFormatDate(financialPending?.lastOccurenceDate, 3) || '-'}
                </span>
              </Col>
            </Row>
          </Col>
        }
      >
        {financialPending?.details?.map((register) => (
          <>
            <Col span={24}>
              <Row align="middle">
                <span className="text-dark text-bold">
                  {i18nFormatDate(register?.occurenceDate, 1)} - {register?.origin}
                </span>
              </Row>
              <Row align="middle" className="mrg-top-8" justify="space-between">
                <Col>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.financialPending.columns.pendencyTitle`
                    )}
                    :
                  </span>{' '}
                  <span>{register?.occurenceTitle || '-'}</span>
                </Col>
              </Row>
              <Row align="middle" className="mrg-top-8" justify="space-between">
                <Col>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.financialPending.columns.contract`
                    )}
                    :
                  </span>{' '}
                  <span>{register?.contract || '-'}</span>
                </Col>
                <Col>
                  <span className="collapse-label">
                    {translate(
                      `${I18N_BASE_PATH}.index.financialPending.columns.guarantor.label`
                    )}
                    :
                  </span>{' '}
                  <span>
                    {translate(
                      `${I18N_BASE_PATH}.index.financialPending.columns.guarantor.values.${!!register.guarantor}`
                    )}
                  </span>
                </Col>
                <Col>
                  <span className="collapse-label">
                    {translate(`${I18N_BASE_PATH}.index.financialPending.columns.city`)}:
                  </span>{' '}
                  <span>{register?.city || '-'}</span>
                </Col>
                <Col>
                  <span className="collapse-label">
                    {translate(`${I18N_BASE_PATH}.index.financialPending.columns.city`)}:
                  </span>{' '}
                  <span>{register?.state || '-'}</span>
                </Col>
              </Row>
            </Col>
            <Divider />
          </>
        ))}
      </Panel>
    </Collapse>
  );
}

export default FinancialPending;
