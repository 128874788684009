import { React, useMemo } from 'react';
import './styles.less';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import EmptyExecutionState from 'components/EmptyExecutionState';

const I18N_BASE_PATH = 'components.dataSourceCards.basicInfos.components';

const SimSwapAlert = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_phone_call"
      title={translate(`${I18N_BASE_PATH}.basicInfosCard.sources.simSwapAlert`)}
      toPrint={toPrint}
    >
      {!queryWorked || parsedData?.message.includes('Erro') ? (
        <EmptyExecutionState
          className="mrg-top-10"
          statusCode={statusCode}
          message={parsedData?.message}
        />
      ) : (
        <Row id="sim-swap-id">
          <Col span={24} className="mrg-btm-25 col-ant">
            <span className="text-style">
              <strong>{translate(`${I18N_BASE_PATH}.simSwapAlert.fields.date`)}:</strong>
            </span>
            <span>
              <span className="span-style">
                {parsedData?.swapDate
                  ? parsedData?.swapDate
                  : translate(`${I18N_BASE_PATH}.simSwapAlert.fields.notFound`)}{' '}
              </span>
            </span>
          </Col>
          <Col span={24} className="mrg-btm-25 col-ant">
            <span className="text-style">
              <strong>{translate(`${I18N_BASE_PATH}.simSwapAlert.fields.hour`)}:</strong>
            </span>
            <span className="span-style">
              {parsedData?.swapTime
                ? parsedData?.swapTime
                : translate(`${I18N_BASE_PATH}.simSwapAlert.fields.notFound`)}
            </span>
          </Col>
          <Col span={24} className="mrg-btm-25 col-ant">
            <span className="text-style">
              <strong>
                {translate(`${I18N_BASE_PATH}.simSwapAlert.fields.indicator`)}:
              </strong>
              <span className="span-style">
                {parsedData?.riskIndicator >= 0
                  ? parsedData?.riskIndicator
                  : translate(`${I18N_BASE_PATH}.simSwapAlert.fields.notFound`)}
              </span>
            </span>
          </Col>
          <Col span={24} className="mrg-btm-25 col-ant">
            <span className="text-style">
              <strong>
                {translate(`${I18N_BASE_PATH}.simSwapAlert.fields.indicatorId`)}:
              </strong>
              <span className="span-style">
                {parsedData?.riskIndicatorDescription
                  ? parsedData?.riskIndicatorDescription
                  : translate(`${I18N_BASE_PATH}.simSwapAlert.fields.notFound`)}
              </span>
            </span>
          </Col>
        </Row>
      )}
    </DataSourceCardCollapsePanel>
  );
};
export default SimSwapAlert;
