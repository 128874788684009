import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import EmptyState from 'components/EmptyState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';

const ZoopScore = ({ profile }) => {
  const { t: translate } = useTranslation();

  const scoreCheck = useMemo(() => profile?.finance?.scoreCheck, [profile]);

  const handlerStatus = useMemo(() => {
    if (!scoreCheck) return { title: null, type: null };

    if (scoreCheck?.resultMessage === 'Approved') {
      return {
        title: translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.shared.zoop.score.index.handlerStatus.approved'
        ),
        type: 'primary',
      };
    }

    if (scoreCheck?.resultMessage === 'Reproved') {
      return {
        title: translate(
          'pages.private.profiles.components.dataSourceCards.financialInformation.shared.zoop.score.index.handlerStatus.reproved'
        ),
        type: 'danger',
      };
    }

    return {
      title: translate(
        'pages.private.profiles.components.dataSourceCards.financialInformation.shared.zoop.score.index.handlerStatus.unavailable'
      ),
      type: 'empty',
    };
  }, [scoreCheck, translate]);

  const isProcessing = useMemo(() => verifySourcesProcessing(profile, 'scoreCheck'), [
    profile,
  ]);

  return (
    <ColumnLabel
      title={translate(
        'pages.private.profiles.components.dataSourceCards.financialInformation.shared.zoop.score.index.score.columnLabel.title'
      )}
      description={handlerStatus?.title}
      type={handlerStatus?.type}
    >
      {!scoreCheck && isProcessing && <EmptyState type="processing" />}

      {!scoreCheck && !isProcessing && <EmptyState type="waiting_consult" />}

      {scoreCheck && String(scoreCheck?.score) && (
        <span>
          {translate(
            'pages.private.profiles.components.dataSourceCards.financialInformation.shared.zoop.score.index.score.scoreText'
          )}{' '}
          {`${scoreCheck?.score} / ${scoreCheck?.limitScore}`}
        </span>
      )}

      {scoreCheck && !String(scoreCheck?.score) && (
        <span>
          {translate(
            'pages.private.profiles.components.dataSourceCards.financialInformation.shared.zoop.score.index.score.hasNotScore'
          )}
        </span>
      )}
    </ColumnLabel>
  );
};

ZoopScore.propTypes = {
  profile: PropTypes.shape({
    finance: PropTypes.shape({
      scoreCheck: PropTypes.shape({
        score: PropTypes.number,
        limitScore: PropTypes.number,
        resultMessage: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default ZoopScore;
