/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import ViewMore from 'components/ViewMore';
import EmptyState from 'components/EmptyState';

import ContactAndRelationCard from 'pages/private/Profiles/components/ContactAndRelationCard';
import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import useAllPeopleInternalRestrictiveList from './AllPeopleInternalRestrictiveList';

const PeopleInternalRestrictiveList = ({ profile, toPrint /* urlId */ }) => {
  const { t: translate } = useTranslation();

  const peopleInternalRestrictiveList = useMemo(
    () => profile?.relationships?.peopleOccurrencesIRL,
    [profile]
  );

  const isProcessing = useMemo(
    () => verifySourcesProcessing(profile, 'peopleOccurrencesIRL'),
    [profile]
  );

  const {
    openModal,
    AllPeopleInternalRestrictiveListModal,
  } = useAllPeopleInternalRestrictiveList({
    data: profile?.relationships?.peopleOccurrencesIRL?.items,
  });

  const parsedList = useMemo(() => {
    if (
      !peopleInternalRestrictiveList ||
      !peopleInternalRestrictiveList?.items?.length > 0
    )
      return <></>;

    const el = [];
    const _size = peopleInternalRestrictiveList?.items?.length;

    if (_size) {
      for (let i = 0; i < _size; i += 2) {
        el.push(
          <Row className="contact-data-row">
            {peopleInternalRestrictiveList?.items
              ?.slice(i, i + 2)
              ?.map((relationship) => (
                <ContactAndRelationCard
                  key={relationship?.cpf}
                  span={12}
                  relation
                  warning
                  name={relationship?.name}
                  type={relationship?.relationshipType}
                  level={relationship?.relationshipLevel}
                  className="mrg-top-10 pdd-horizontal-0"
                />
              ))}
          </Row>
        );
      }
    }

    if (el.length > 0) {
      const parsedEl = toPrint ? el : el.splice(0, 1);
      return parsedEl;
    }

    return <></>;
  }, [peopleInternalRestrictiveList, toPrint]);

  return (
    <>
      {AllPeopleInternalRestrictiveListModal}

      <Row className="contact-data-container">
        <ColumnLabel
          title={translate(
            'pages.private.profiles.components.dataSourceCards.relationships.components.peopleInternalRestrictiveList.index.peopleInternalRestrictiveList.columnLabel.title'
          )}
          span={24}
        >
          {!peopleInternalRestrictiveList && isProcessing && (
            <EmptyState type="processing" />
          )}

          {!peopleInternalRestrictiveList && !isProcessing && (
            <EmptyState type="waiting_consult" />
          )}

          {peopleInternalRestrictiveList &&
            !peopleInternalRestrictiveList?.items?.length > 0 && (
              <EmptyState
                type="empty-success"
                description={translate(
                  'pages.private.profiles.components.dataSourceCards.relationships.components.peopleInternalRestrictiveList.index.peopleInternalRestrictiveList.emptyState.description'
                )}
              />
            )}

          {parsedList}
        </ColumnLabel>
        {/* </Col> */}
      </Row>

      {!toPrint && peopleInternalRestrictiveList?.items?.length > 2 && (
        <Row>
          <ViewMore
            title={translate(
              'pages.private.profiles.components.dataSourceCards.relationships.components.peopleInternalRestrictiveList.index.peopleInternalRestrictiveList.viewMore.title'
            )}
            type="link"
            onClick={() => openModal()}
          />
        </Row>
      )}
    </>
  );
};

export default PeopleInternalRestrictiveList;
