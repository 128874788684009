const I18N_BASE_PATH =
  'components.dataSourceCards.KYCAndCompliance.index.component.modal.utils.';
export const translateTypes = {
  pepClass1: {
    title: `${I18N_BASE_PATH}pepClass1`,
  },
  pepClass2: {
    title: `${I18N_BASE_PATH}pepClass2`,
  },
  pepClass3: {
    title: `${I18N_BASE_PATH}pepClass3`,
  },
  pepClass4: {
    title: `${I18N_BASE_PATH}pepClass4`,
  },
  other: {
    title: `${I18N_BASE_PATH}other`,
  },
  sanction: {
    title: `${I18N_BASE_PATH}sanctions`,
  },
  warning: {
    title: `${I18N_BASE_PATH}warnings`,
  },
};
