import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'hooks/theme';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ArrowLeft, ArrowRight } from '@combateafraude/icons/general';
import CafLogo from 'assets/images/caf-logo.svg';
import ModalNPSNotification from 'components/ModalNPSNotification';
import UserMenu from './components/UserMenu';

import './styles.less';
import NavigationMenu from './components/NavigationMenu';

const Sidebar = (props) => {
  const { handleCollapse, collapsed } = props;

  const { logo } = useTheme();

  return (
    <div
      className={classNames('sidebar-container', {
        'sidebar-container-collapsed': collapsed,
      })}
    >
      <div style={{ width: '100%' }}>
        <div className="sidebar-logo">
          <Link to="/">
            <img alt="" src={collapsed ? CafLogo : logo} />
          </Link>
        </div>
        <ModalNPSNotification />
        <UserMenu {...props} />
        <NavigationMenu {...props} />
      </div>

      <div className="sidebar-expand-button">
        {collapsed ? (
          <ArrowRight onClick={() => handleCollapse(false)} />
        ) : (
          <ArrowLeft onClick={() => handleCollapse(true)} />
        )}
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  handleCollapse: PropTypes.func.isRequired,
  activeMenu: PropTypes.string.isRequired,
  config: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    Icon: PropTypes.element,
    path: PropTypes.string,
    onClick: PropTypes.func,
    permissions: PropTypes.shape({
      requiredPermissions: PropTypes.arrayOf(PropTypes.string),
      requiredResources: PropTypes.arrayOf(PropTypes.string),
      or: PropTypes.bool,
      product: PropTypes.string,
    }),
    children: PropTypes.arrayOf({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      Icon: PropTypes.element,
      path: PropTypes.string,
      onClick: PropTypes.func,
      permissions: PropTypes.shape({
        requiredPermissions: PropTypes.arrayOf(PropTypes.string),
        requiredResources: PropTypes.arrayOf(PropTypes.string),
        or: PropTypes.bool,
        product: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default Sidebar;
