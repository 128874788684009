import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Col, Tooltip } from 'antd';
import { parseISO, formatISO, differenceInHours, formatRelative } from 'date-fns';

import { useFetch } from 'services/hooks';
import { useI18nFormatters } from 'hooks/i18nFormatters';

const I18N_BASE_PATH = 'components.notifications.components.notificationItem';

const actionTranslatorTitle = {
  CREATE: `${I18N_BASE_PATH}.actionTranslatorTitle.create`,
  UPDATE: `${I18N_BASE_PATH}.actionTranslatorTitle.update`,
  ACTIVATE: `${I18N_BASE_PATH}.actionTranslatorTitle.activate`,
  INACTIVATE: `${I18N_BASE_PATH}.actionTranslatorTitle.inactivate`,
};

const NotificationItem = ({ data }) => {
  const { t: translate, i18n } = useTranslation();
  const { i18nGetDateFnsLocale } = useI18nFormatters();

  const { patch, loading } = useFetch();

  const [isRead, setIsRead] = useState(data?.isRead);

  const isFileExpired = useMemo(() => {
    if (!data?.createdAt) return false;

    const currentDate = parseISO(formatISO(new Date()));
    const dateToCompare = parseISO(formatISO(new Date(data?.createdAt)));

    const difference = differenceInHours(currentDate, dateToCompare);

    if (difference >= 24) {
      return true;
    }

    return false;
  }, [data]);

  const menuItemNotificationItemClasses = useMemo(() => {
    return !isRead ? 'unread' : '';
  }, [isRead]);

  const translateValidation = useMemo(() => {
    if (data?.type === 'ONBOARDING') {
      if (data?.action === 'CREATE') return 'onboarding';
      return 'onboardingUpdated';
    }

    if (data?.type === 'TRANSACTION_TEMPLATE') {
      if (data?.action === 'CREATE') return 'queryTemplate';
      return 'queryTemplateUpdated';
    }

    if (data?.type === 'profile_exported') {
      if (data?.title.includes('pessoas')) return 'exportedPF';
      return 'exportedPJ';
    }

    if (data?.type === 'restrictive_list_imported') return 'exportRestrictive';

    if (data?.type === 'executions_exported') return 'exportedExecution';

    if (data?.type === 'faceliveness_exported') return 'exportedFaceliveness';

    if (data?.type === 'TRANSACTION_WARNING') return 'transactionWarning';

    if (data?.type === 'onboarding_statistics_exported') {
      return 'exportedOnboardingStatistics';
    }

    if (data?.type === 'dashboard_exported') return 'exportedDashboard';
    return '';
  }, [data]);

  const displayTransactionWarning = useMemo(() => {
    let transactionUrl = '';

    if (data?.type === 'TRANSACTION_WARNING') {
      transactionUrl = `${process.env.REACT_APP_BASE_URL_TRUST_WEB_NEW}/executions/${data.data?.after?.transactionId}`;
    }
    const validations = data?.data?.after?.validations;
    return (
      <div
        id="menu-item-notifications-center"
        className={menuItemNotificationItemClasses}
      >
        <div className="notification-item">
          <span className="notification-description">
            {translate(`${I18N_BASE_PATH}.displayTransactionWarning.subtitle.prefix`)}{' '}
            <a href={transactionUrl}>
              {translate(
                `${I18N_BASE_PATH}.displayTransactionWarning.subtitle.transaction`
              )}
            </a>{' '}
            {translate(`${I18N_BASE_PATH}.displayTransactionWarning.subtitle.suffix`)}
          </span>
          {validations &&
            validations.map((validation) => (
              <>
                <strong>
                  {translate(`${I18N_BASE_PATH}.displayTransactionWarning.rules`)}
                </strong>
                <span>{validation.ruleName}</span>
              </>
            ))}
        </div>
      </div>
    );
  }, [data, menuItemNotificationItemClasses, translate]);

  const handleReadNotification = useCallback(async () => {
    await patch({
      url: `/notifications/${data?._id}`,
      payload: {
        isRead: !isRead,
      },
      showMessage: false,
    });

    setIsRead(!isRead);
  }, [data, patch, isRead]);

  return (
    <div id="menu-item-notifications" className={menuItemNotificationItemClasses}>
      <div className="notification-item">
        <div className="notification-title">
          <span>{translate(`${I18N_BASE_PATH}.${translateValidation}.title`)}</span>
          <Tooltip
            placement="top"
            title={translate(
              `${I18N_BASE_PATH}.tooltip.title.${data?.isRead ? 'isRead' : 'unread'}`
            )}
          >
            <button type="button" className="reset-btn" onClick={handleReadNotification}>
              {loading && <i className="caf-ic_rotate-cw loading-icon" />}
              {!loading && <i className={isRead ? 'caf-ic_check_d' : 'caf-ic_eye'} />}
            </button>
          </Tooltip>
        </div>
        <div>
          {data?.type === 'ONBOARDING' || data?.type === 'TRANSACTION_TEMPLATE' ? (
            <Col className="no-pdd mrg-top-5 mrg-btm-5" span={24}>
              {data?.type === 'TRANSACTION_TEMPLATE' ? (
                <span>
                  {translate(`${I18N_BASE_PATH}.transactionTemplate.prefix`)}
                  <strong>{data?.data?.after?.name}</strong>
                  {translate(`${I18N_BASE_PATH}.transactionTemplate.suffix`, {
                    action: translate(actionTranslatorTitle[data?.action]),
                  })}
                </span>
              ) : (
                <span>{translate(`${I18N_BASE_PATH}.description.default`)}</span>
              )}
            </Col>
          ) : (
            <div>
              {data?.type === 'TRANSACTION_WARNING' ? (
                <>{displayTransactionWarning}</>
              ) : (
                <>
                  <span className="notification-description">
                    {translate(`${I18N_BASE_PATH}.description.defaultDownload`) || ''}
                  </span>
                  <>
                    {data?.file && !isFileExpired && (
                      <a
                        className="notification-download-file"
                        href={data.file}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="caf-ic_download" />
                        <span>
                          {translate(
                            `${I18N_BASE_PATH}.information.hasFileAndIsNotExpired`
                          )}
                        </span>
                      </a>
                    )}

                    {data?.file && isFileExpired && (
                      <div className="notification-expired-file">
                        <i className="caf-ic_close" />
                        <span>
                          {translate(`${I18N_BASE_PATH}.information.hasFileAndIsExpired`)}
                        </span>
                      </div>
                    )}
                  </>
                </>
              )}
            </div>
          )}
        </div>
        <span className="notification-date">
          {formatRelative(new Date(data?.createdAt), new Date(), {
            locale: i18nGetDateFnsLocale(),
          })}
        </span>
      </div>
    </div>
  );
};

NotificationItem.propTypes = {
  data: PropTypes.shape(PropTypes.string),
};

NotificationItem.defaultProps = {
  data: null,
};

export default NotificationItem;
