import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
import { Waypoint } from 'react-waypoint';
import { Spin } from 'antd';
import _ from 'lodash';

import { useFetch } from 'services/hooks';

import EmptyMessage from 'components/EmptyMessage';

import { initialFilterData } from '../utils';

const INITIAL_PAGE_SIZE = 5;
const INITIAL_CURRENT = 1;

const useInfiniteScroll = ({
  component: Component,
  emptyComponent: EmptyComponent,
  hiddenLoading,
  rowKey,
  getParams,
  queryParams,
  customDataParser,
  customEmptyMessage,
  customScroll,
  toPrint,
}) => {
  const { t: translate } = useTranslation();

  const { data, loading, get } = useFetch();
  const [query] = useQueryParams({
    page: NumberParam,
    _order: StringParam,
    _sort: StringParam,
    ...queryParams,
  });

  const [formattedData, setFormattedData] = useState([]);
  const [params, setParams] = useState(getParams);
  const [pagination, setPagination] = useState({
    current: query?.page || INITIAL_CURRENT,
    pageSize: INITIAL_PAGE_SIZE,
    hasMore: true,
  });
  const [filterData, setFilterData] = useState(() => {
    return initialFilterData({ query, queryParams });
  });

  const fetch = useCallback(
    async (current, clearData = false) => {
      if (!params || (!pagination.hasMore && _.isEmpty(filterData) && !clearData)) return;

      const pageSize = pagination.pageSize || INITIAL_PAGE_SIZE;

      await get({
        url: params.url,
        config: {
          params: {
            _limit: pageSize,
            _offset: pageSize * (current - 1),
            ...filterData,
            ...params.config.params,
          },
        },
        showMessage: false,
        clearData: clearData || pagination.current === INITIAL_CURRENT,
      });
    },
    [get, params, pagination, filterData]
  );

  const refreshList = useCallback(() => {
    fetch(INITIAL_CURRENT);
  }, [fetch]);

  const reloadList = useCallback(() => {
    setPagination((oldState) => ({ ...oldState, current: 1, hasMore: true }));
    fetch(INITIAL_CURRENT, true);
  }, [fetch]);

  const customClasses = useMemo(() => {
    return customScroll
      ? 'scroll-component-list-container custom-scrollbar gx-position-relative mrg-top-10'
      : 'hide-scroll gx-position-relative mrg-top-10';
  }, [customScroll]);

  // coloca itens na lista
  useEffect(() => {
    if (!data) return;

    if (!data?.docs?.length) {
      // caso não retorne mais nenhuma data, não vai continuar carregando
      setPagination((oldState) => {
        setFormattedData((oldData) =>
          oldState.current === INITIAL_CURRENT ? [] : oldData
        );

        return {
          current: INITIAL_PAGE_SIZE,
          hasMore: false,
        };
      });

      return;
    }

    setPagination((oldPagination) => {
      setFormattedData((oldData) => {
        // verifica se não tem uma forma custom
        if (customDataParser) {
          return customDataParser({
            INITIAL_CURRENT,
            oldPagination,
            oldData,
            newData: data,
          });
        }
        return oldPagination.current === INITIAL_CURRENT
          ? data.docs
          : [...oldData, ...data.docs];
      });

      return {
        ...oldPagination,
        current: oldPagination.current + 1,
        hasMore: true,
      };
    });
  }, [data, customDataParser]);

  useEffect(() => {
    if ((pagination.current === INITIAL_CURRENT || !data) && !_.isEmpty(filterData)) {
      refreshList();
      return;
    }

    setPagination((state) => ({
      ...state,
      current: INITIAL_CURRENT,
    }));

    fetch(INITIAL_CURRENT);
  }, [params, filterData]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateParams = useCallback((newParams) => {
    setParams(newParams);
  }, []);

  const componentList = useMemo(
    () => (
      <div className={customClasses}>
        {formattedData?.length > 0 ? (
          <>
            {!hiddenLoading && loading && !toPrint && (
              <div className="flex center">
                <Spin className="mrg-top-20 mrg-btm-25 mrg-horizon-25" />
              </div>
            )}
            {formattedData?.map((d) => (
              <Component
                key={d[rowKey]}
                data={d}
                refreshList={refreshList}
                reloadList={reloadList}
                toPrint={toPrint}
              />
            ))}

            <Waypoint onEnter={() => fetch(pagination.current)} />
          </>
        ) : (
          <>
            {EmptyComponent && <EmptyComponent show={!loading} />}

            {!EmptyComponent && (
              <EmptyMessage
                withCard
                show={!loading}
                style={{ margin: 0 }}
                description={
                  customEmptyMessage ||
                  translate(
                    'components.list.hooks.useInfiniteScroll.emptyMessage.description'
                  )
                }
              />
            )}
          </>
        )}

        {!hiddenLoading && loading && !toPrint && (
          <div className="flex center">
            <Spin className="mrg-top-20 mrg-btm-25 mrg-horizon-25" />
          </div>
        )}
      </div>
    ),
    [
      customClasses,
      formattedData,
      hiddenLoading,
      loading,
      toPrint,
      EmptyComponent,
      customEmptyMessage,
      translate,
      rowKey,
      refreshList,
      reloadList,
      fetch,
      pagination,
    ]
  );

  return {
    componentList,
    loading,
    data,
    refreshList,
    reloadList,
    setFilterData,
    updateParams,
  };
};

export default useInfiniteScroll;
