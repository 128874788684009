/* eslint-disable no-unused-vars */
import React from 'react';

import { useTranslation } from 'react-i18next';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import { isObject } from 'lodash';
import CardContent from './components/CardContent';
import UserInformation from './components/UserInformation';

import './styles.less';
import ItemInformation from './components/ItemInformation';

const matchTypeCodeMap = {
  '1MT': 'MATCH',
  '3MT': 'PARTIAL',
  '4MT': 'NON_MATCH',
};

const autonomousUnitCodeMap = {
  '1AU': 'MATCH',
  '3AU': 'PARTIAL',
  '2AU': 'NON_MATCH',
};

const colors = {
  MATCH: '#39c560',
  PARTIAL: `#f8b239`,
  NON_MATCH: `#e21b45`,
};

const IdentityVerificationKyc = ({ source = {}, toPrint = false, is2x2 = false }) => {
  const { t: translate } = useTranslation();
  const {
    data,
    query: { data: fields },
  } = source;

  let { address } = fields || '';

  if (isObject(address)) {
    const availableAddresOptions = [
      'street',
      'number',
      'complement',
      'neighborhood',
      'city',
      'zipCode',
      'state',
      'country',
    ];
    const addressArray = [];

    availableAddresOptions.forEach((option) => {
      if (Object.prototype.hasOwnProperty.call(address, option)) {
        addressArray.push(address[option]);
      }
    });

    address = addressArray.join(', ');
  }
  const parsedMessages = [];

  data?.messages?.forEach((message) => {
    const splitted = message.code.split('-');
    const code = splitted[0];
    if (Object.keys(matchTypeCodeMap).includes(code)) {
      const datasource = splitted[2];
      const field = splitted[3];

      if (!parsedMessages[field]) {
        parsedMessages[field] = {
          field,
          matchTypeCodes: [
            {
              datasource,
              code: matchTypeCodeMap[code],
            },
          ],
        };
      } else {
        parsedMessages[field].matchTypeCodes.push({
          datasource,
          code: matchTypeCodeMap[code],
        });
      }
    } else if (Object.keys(autonomousUnitCodeMap).includes(code)) {
      const field = splitted[2];
      if (!parsedMessages[field]) {
        parsedMessages[field] = {
          field,
          autonomousUnitCode: autonomousUnitCodeMap[code],
          matchTypeCodes: [],
        };
      } else {
        parsedMessages[field].autonomousUnitCode = autonomousUnitCodeMap[code];
      }
    }
  });

  const getColorByScore = (score) => {
    return colors[score] ? colors[score] : '#bbb';
  };
  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_news"
      toPrint={toPrint}
      title={
        !is2x2
          ? translate(
              'components.dataSourceCards.basicInfos.components.identityVerificationKyc.title1x1'
            )
          : translate(
              'components.dataSourceCards.basicInfos.components.identityVerificationKyc.title2x2'
            )
      }
    >
      <CardContent name={fields?.name} reliability={data.reliability}>
        <div className="container-user-information">
          {fields?.name && (
            <ItemInformation
              fieldName="name"
              fieldValue={fields?.name}
              reliabilityCode={parsedMessages?.COMPLETENAME?.autonomousUnitCode}
            />
          )}
          {fields?.address && (
            <ItemInformation
              fieldName="address"
              fieldValue={address}
              reliabilityCode={parsedMessages?.ADDRESS?.autonomousUnitCode}
            />
          )}
          {fields?.birthDate && (
            <ItemInformation
              fieldName="birthDate"
              fieldValue={fields?.birthDate}
              reliabilityCode={parsedMessages?.DATEOFBIRTH?.autonomousUnitCode}
            />
          )}
          {fields?.documentNumber && (
            <ItemInformation
              fieldName="documentNumber"
              fieldValue={fields?.documentNumber}
              reliabilityCode={parsedMessages?.NATIONALID?.autonomousUnitCode}
            />
          )}
          {fields?.phoneNumber && (
            <ItemInformation
              fieldName="phoneNumber"
              fieldValue={fields?.phoneNumber}
              reliabilityCode={parsedMessages?.PHONENUMBER?.autonomousUnitCode}
            />
          )}
        </div>
      </CardContent>
    </DataSourceCardCollapsePanel>
  );
};
export default IdentityVerificationKyc;
