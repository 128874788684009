import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useQueryParams, StringParam } from 'use-query-params';
import { Button, Timeline } from 'antd';

import { useValidations } from 'hooks/validations';
import { usePerson } from 'hooks/person';
import { useCompany } from 'hooks/company';

import { scrollToElement } from 'utils/scroll';

import servicesCategories from 'utils/data/templateServicesCategoriesPj.json';

import CheckpointStatus from 'pages/private/Profiles/components/CheckpointStatus';
import usePendingReviewModal from 'pages/private/Profiles/components/ProfileValidations/components/PendingReviewModal';
// import usePendingOcrModal from 'pages/private/Profiles/components/ProfileValidations/components/PendingOcrModal';
import useComplianceRulesModal from 'pages/private/Profiles/components/ProfileValidations/components/ComplianceRulesModal';

import PermissionWrapper from 'routes/PermissionWrapper';
import TrustItem from '../TrustItem';

import './styles.less';

const ValidationsTimeline = () => {
  const { t: translate } = useTranslation();

  const { validations } = useValidations();
  const { companyId } = useParams();

  const matchPeople = useRouteMatch('/profile/people');

  const [query, setQuery] = useQueryParams({
    tab: StringParam,
  });

  const { company, loading: loadingCompany } = useCompany();
  const { person, loading: loadingPerson } = usePerson();

  const { openPendingReviewModal, PendingReviewModalContent } = usePendingReviewModal();
  // const { openModal: openPendingOcrModal, PendingOcrModalContent } = usePendingOcrModal();
  const {
    openComplianceRulesModal,
    ComplianceRulesValidationsContent,
  } = useComplianceRulesModal();

  const profileData = useMemo(() => {
    return companyId ? company : person;
  }, [companyId, company, person]);

  const allExecutions = useMemo(() => {
    const allExecutionsData = [];

    profileData?.migrationReports?.map((report) => {
      return allExecutionsData.push(report);
    });

    profileData?.compliances?.map((report) => {
      return allExecutionsData.push(report);
    });

    profileData?.executions?.map((report) => {
      return allExecutionsData.push(report);
    });

    const backgroundCheckingValidations = Object.entries(
      profileData?.backgroundChecking?.services || {}
    );

    backgroundCheckingValidations.map(([_, value]) => {
      return allExecutionsData.push(value);
    });

    return allExecutionsData;
  }, [profileData]);

  const pendingReviewExecutions = useMemo(
    () => allExecutions?.filter((execution) => execution?.status === 'PENDING'),
    [allExecutions]
  );

  // const pendingOcrExecutions = useMemo(
  //   () => allExecutions?.filter((execution) => execution?.status === 'PENDING_OCR'),
  //   [allExecutions]
  // );

  const complianceRules = useMemo(() => {
    if (!validations) return undefined;

    const _validations = validations?.validations;

    if (!_validations?.length) return undefined;

    let complianceRulesData = _validations.filter(
      (v) =>
        v.rule === 'valid_company_register' ||
        v.rule === 'valid_background_checking' ||
        v.rule === 'valid_company_qsa_onboarding' ||
        v.rule === 'valid_migration_reports' ||
        v.rule === 'valid_identity' ||
        v.rule === 'valid_document' ||
        v.rule === 'valid_compliance_rules'
    );

    const valid_compliance_rules = complianceRulesData
      .filter((v) => v.rule === 'valid_compliance_rules')[0]
      ?.data?.mappedValidations?.identity?.some((v) => v?.status === 'INVALID');

    if (valid_compliance_rules) {
      complianceRulesData = complianceRulesData.map((v) => {
        if (v.rule === 'valid_compliance_rules') {
          return {
            description:
              'pages.private.profiles.components.profileValidations.index.profileValidations.profileWidget.validComplianceRules.descriptions.validationsInvalid',
            rule: 'valid_compliance_rules',
            status: 'INVALID',
            title:
              'pages.private.profiles.components.profileValidations.index.profileValidations.profileWidget.validComplianceRules.title',
            data: v?.data,
          };
        }
        return v;
      });
    }

    return complianceRulesData;
  }, [validations]);

  const scrollToComponent = useCallback(
    (rule) => {
      const switchTo = {};

      switch (rule?.rule) {
        case 'valid_background_checking':
          switchTo.tab = 'activities';
          switchTo.element = 'recurrence-container';
          break;

        case 'valid_identity':
          switchTo.tab = 'documents';
          switchTo.element = 'verified-document-card';
          break;

        case 'valid_document':
          switchTo.tab = 'documents';
          switchTo.element = 'authentic-document-card';
          break;

        case 'valid_company_qsa_onboarding':
          switchTo.tab = 'infos';
          switchTo.element = 'qsa-content';
          break;

        case 'valid_compliance_rules':
          openComplianceRulesModal();
          break;

        case 'valid_migration_reports':
          switchTo.tab = 'activities';
          switchTo.element = 'recurrence-container';
          break;

        default:
          switchTo.tab = query.tab;
          switchTo.element = 'profile-content';
          break;
      }

      setTimeout(() => {
        setQuery({ tab: switchTo.tab });
        scrollToElement(switchTo.element);
      }, 100);
    },
    [query, setQuery, openComplianceRulesModal]
  );

  return (
    <>
      {ComplianceRulesValidationsContent}
      {PendingReviewModalContent}
      {/* {PendingOcrModalContent} */}
      <Timeline className="checkpoint-status-container mrg-top-5">
        {complianceRules?.map((complianceRule) => (
          <CheckpointStatus
            key={complianceRule?.rule}
            status={complianceRule?.status}
            title={translate(complianceRule?.title)}
            description={translate(complianceRule?.description, {
              value: complianceRule.value,
            })}
            rule={complianceRule?.rule}
            onClick={() => scrollToComponent(complianceRule)}
          >
            {complianceRule?.status !== 'NONE' &&
              (complianceRule?.rule === 'valid_background_checking' ||
                complianceRule?.rule === 'valid_migration_reports') &&
              complianceRule?.data?.items?.map((item) => {
                return (
                  <div className="validation-list-item">
                    <TrustItem
                      title={
                        item.category
                          ? translate(servicesCategories[item?.category]?.title)
                          : item?.name
                      }
                      status={item.status}
                      data={item}
                    />
                  </div>
                );
              })}
          </CheckpointStatus>
        ))}
      </Timeline>
      {!loadingPerson &&
        !loadingCompany &&
        !!pendingReviewExecutions?.length &&
        profileData?.status === 'PENDING' && (
          <PermissionWrapper
            requiredPermissions={matchPeople ? ['people:update'] : ['companies:update']}
          >
            <Button
              className="flex pending-button center no-border"
              onClick={() => openPendingReviewModal()}
            >
              <i className="caf-ic_edit_3 pdd-right-10" />
              {translate(
                'pages.private.profiles.components.profileValidations.components.validationsTimeline.index.button'
              )}
            </Button>
          </PermissionWrapper>
        )}
      {/* {!loadingPerson &&
        !loadingCompany &&
        !!pendingOcrExecutions?.length &&
        profileData?.status === 'PENDING' && (
          <div className="validation-list-item">
            <Button
              className="flex pending-button center no-border"
              onClick={() => openPendingOcrModal()}
            >
              <i className="caf-ic_edit_3 pdd-right-10" />
              Confirmar dados
            </Button>
          </div>
        )} */}
    </>
  );
};

export default ValidationsTimeline;
