import React, { useState, useCallback, useMemo } from 'react';
import { Person } from '@combateafraude/icons/users';
import { Company, Smartphone, Consultas } from '@combateafraude/icons/general';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Modal, Form, Row, Typography } from 'antd';
import { useAuth } from 'hooks/auth';

import PermissionWrapper from 'routes/PermissionWrapper';

import RectangleButton from 'components/Button/RectangleButton';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';

import Button from '../Button';

import './styles.less';

const registerTypes = {
  execution: 'execution',
  onboardings: 'onboardings',
};

const modalSteps = {
  validationType: 'validationType',
  company: 'company',
  person: 'person',
  default: 'default',
};

const validationTypes = {
  company: 'company',
  person: 'person',
};

const I18N_BASE_PATH = 'components.modalProfiles.selectProfileTypeCard';

const ModalServices = ({
  openExecutionRegisterModal,
  openOnboardingsModal,
  setValidationType,
  validationType,
}) => {
  const { t: translate } = useTranslation();
  const { user: loggedUser } = useAuth();
  const history = useHistory();

  const [form] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);
  const [registerType, setRegisterType] = useState();
  const [modalContent, setModalContent] = useState();

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setValidationType(null);
    setRegisterType(null);
    setModalContent(
      hasFeatureFlag('isWorkflowBuilder', loggedUser)
        ? modalSteps.validationType
        : modalSteps.default
    );

    setModalVisible(true);
  }, [loggedUser, setValidationType]);

  const handleSubmit = useCallback(async () => {
    if (modalContent === modalSteps.validationType) {
      setModalContent(validationType);
    } else {
      if (registerType === registerTypes.execution) {
        if (validationType === modalSteps.company) {
          history.push('/company?tab=companySearch');
        } else {
          openExecutionRegisterModal();
        }
      } else if (registerType === registerTypes.onboardings) openOnboardingsModal();

      closeModal();
    }
  }, [
    modalContent,
    validationType,
    registerType,
    openOnboardingsModal,
    closeModal,
    history,
    openExecutionRegisterModal,
  ]);

  const handleGoBack = useCallback(() => {
    if (
      modalContent === modalSteps.validationType ||
      modalContent === modalSteps.default
    ) {
      closeModal();
    } else {
      setModalContent(
        hasFeatureFlag('isWorkflowBuilder', loggedUser)
          ? modalSteps.validationType
          : modalSteps.default
      );
    }
  }, [closeModal, loggedUser, modalContent]);

  const renderTitleByModalContent = useCallback(() => {
    if (modalContent === modalSteps.validationType) {
      return translate(
        `${I18N_BASE_PATH}.selectProfileTypeCard.form.validationType.title`
      );
    }
    return translate(`${I18N_BASE_PATH}.selectProfileTypeCard.form.title`);
  }, [modalContent, translate]);

  const renderContentByModalContent = useCallback(() => {
    if (modalContent === modalSteps.validationType) {
      return (
        <>
          <PermissionWrapper requiredPermissions={['companies:create']}>
            <RectangleButton
              title={translate(
                `${I18N_BASE_PATH}.selectProfileTypeCard.form.buttonType.person.title`
              )}
              Icon={Person}
              selected={validationType === validationTypes.person}
              onClick={() => setValidationType(validationTypes.person)}
            />
          </PermissionWrapper>
          <PermissionWrapper requiredPermissions={['companies:create']}>
            <RectangleButton
              title={translate(
                `${I18N_BASE_PATH}.selectProfileTypeCard.form.buttonType.company.title`
              )}
              Icon={Company}
              selected={validationType === validationTypes.company}
              onClick={() => setValidationType(validationTypes.company)}
            />
          </PermissionWrapper>
        </>
      );
    }

    return (
      <>
        <PermissionWrapper requiredPermissions={['executions:create']}>
          <RectangleButton
            title={translate(
              `${I18N_BASE_PATH}.selectProfileTypeCard.form.buttonType.execution.title`
            )}
            Icon={Consultas}
            selected={registerType === registerTypes.execution}
            onClick={() => setRegisterType(registerTypes.execution)}
            description={translate(
              `${I18N_BASE_PATH}.selectProfileTypeCard.form.buttonType.execution.buttonDescription`,
              validationType && {
                type: translate(
                  `${I18N_BASE_PATH}.selectProfileTypeCard.form.buttonType.execution.buttonDescription.${validationType}`
                ),
              }
            )}
          />
        </PermissionWrapper>
        <PermissionWrapper requiredPermissions={['onboardings:create']}>
          <RectangleButton
            title={translate(
              `${I18N_BASE_PATH}.selectProfileTypeCard.form.buttonType.onboardings.title`
            )}
            Icon={Smartphone}
            selected={registerType === registerTypes.onboardings}
            onClick={() => setRegisterType(registerTypes.onboardings)}
            description={translate(
              `${I18N_BASE_PATH}.selectProfileTypeCard.form.buttonType.onboardings.buttonDescription`,
              validationType && {
                type: translate(
                  `${I18N_BASE_PATH}.selectProfileTypeCard.form.buttonType.execution.buttonDescription.${validationType}`
                ),
              }
            )}
          />
        </PermissionWrapper>
      </>
    );
  }, [modalContent, registerType, setValidationType, translate, validationType]);

  const isSubmitButtonDisabled = useMemo(() => {
    if (modalContent === modalSteps.validationType) {
      return !validationType;
    }

    return !registerType;
  }, [modalContent, registerType, validationType]);

  const ServicesModal = useMemo(
    () => (
      <Modal
        wrapClassName="caf-modal archives-upload-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        visible={modalVisible}
        footer={null}
        width={720}
        onCancel={closeModal}
      >
        <div className="no-mrg no-pdd">
          <Form form={form} layout="vertical" className="mrg-top-42">
            <Row align="middle" justify="center">
              <Typography className="gx-modal-title">
                {renderTitleByModalContent()}
              </Typography>
            </Row>
            <Row className="mrg-vertical-40 mrg-horizon-10 gx-flex-row gx-justify-content-center">
              <div className="modal-services-content">
                {renderContentByModalContent()}
              </div>
            </Row>
            <div className="gx-flex-row gx-justify-content-end">
              <Button type="default" onClick={handleGoBack} className="btn-cancel">
                {translate(`${I18N_BASE_PATH}.selectProfileTypeCard.form.button.goBack`)}
              </Button>
              <Button
                type="primary"
                onClick={handleSubmit}
                disabled={isSubmitButtonDisabled}
              >
                {translate(`${I18N_BASE_PATH}.submitButtonText.default`)}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      form,
      renderTitleByModalContent,
      renderContentByModalContent,
      handleGoBack,
      translate,
      handleSubmit,
      isSubmitButtonDisabled,
    ]
  );

  return { openModal, ServicesModal };
};

export default ModalServices;
