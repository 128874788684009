export const genderTranslate = (gender) => {
  if (!gender) return '';

  switch (gender.toUpperCase()) {
    case 'MALE':
      return 'utils.translations.genderTranslate.male';
    case 'M':
      return 'utils.translations.genderTranslate.male';
    case 'FEMALE':
      return 'utils.translations.genderTranslate.female';
    case 'F':
      return 'utils.translations.genderTranslate.female';
    default:
      return 'utils.translations.genderTranslate.default';
  }
};

export const documentTypeTranslate = (type) => {
  if (!type) return '';

  switch (type.toUpperCase()) {
    case 'CNH':
      return 'utils.translations.documentTypeTranslate.cnh';
    case 'RG':
      return 'utils.translations.documentTypeTranslate.rg';
    default:
      return 'utils.translations.documentTypeTranslate.default';
  }
};

export const isGroupAdmin = (id) => {
  return id === '6112a54246c2514b138bc35e';
};
