import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Archive } from '@combateafraude/icons/general';

import './styles.less';

/*
variant = data | filter | search | search.fallback
*/
const NoResults = ({ className = '', variant = 'data', showShadow = false }) => {
  const { t: translate } = useTranslation();

  return (
    <div
      className={classNames(
        'flex-column center no-results',
        { shadow: showShadow },
        className
      )}
    >
      <Archive />
      <span className="mrg-top-10 font-size-16">
        {translate(`components.noResults.variant.${variant}`)}
      </span>
    </div>
  );
};

export default NoResults;
