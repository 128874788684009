/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import CountryFlag from 'components/CountryFlag';
import NotAvailable from 'components/NotAvailable';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.KYBCompany.pages.companyDetails.subPages.records';

const EntityBody = ({ entity }) => {
  const { t } = useTranslation();

  const {
    identifiers = [],
    addresses = [],
    countries = [],
    pep,
    sanctioned,
    counts,
    registrationDate,
  } = entity;

  return (
    <div className="text-dark pdd-vertical-10 pdd-horizon-10">
      <Row>
        <Col span={4} className="text-bold">
          {t(`${I18N_BASE_PATH}.identifiers`, 'Identifiers')}
        </Col>
        <Col span={20}>
          {identifiers.length ? (
            identifiers.map((identifier) => (
              <React.Fragment key={identifier.label}>
                {identifier.label} - {identifier.value} <br />
              </React.Fragment>
            ))
          ) : (
            <NotAvailable />
          )}
        </Col>
      </Row>
      <Row className="mrg-top-10">
        <Col span={4} className="text-bold">
          {t(`${I18N_BASE_PATH}.PEP`, 'PEP')}
        </Col>
        <Col span={20}>
          {pep
            ? t(`${I18N_BASE_PATH}.true`, 'True')
            : t(`${I18N_BASE_PATH}.false`, 'False')}
        </Col>
      </Row>
      <Row className="mrg-top-10">
        <Col span={4} className="text-bold">
          {t(`${I18N_BASE_PATH}.sanctioned`, 'Sanctioned')}
        </Col>
        <Col span={20}>
          {sanctioned
            ? t(`${I18N_BASE_PATH}.true`, 'True')
            : t(`${I18N_BASE_PATH}.false`, 'False')}
        </Col>
      </Row>
      <Row className="mrg-top-10">
        <Col span={4} className="text-bold">
          {t(`${I18N_BASE_PATH}.sourceCount`, 'Source count')}
        </Col>
        <Col span={20}>
          {counts.sources?.length ? (
            counts.sources.map((source) => (
              <React.Fragment key={source.label}>
                {source.label} ({source.count}) <br />
              </React.Fragment>
            ))
          ) : (
            <NotAvailable />
          )}
        </Col>
      </Row>
      <Row className="mrg-top-10">
        <Col span={4} className="text-bold">
          {t(`${I18N_BASE_PATH}.addresses`, 'Addresses')}
        </Col>
        <Col span={20}>
          {addresses.length ? (
            addresses.map((address) => (
              <React.Fragment key={address}>
                {address} <br />
              </React.Fragment>
            ))
          ) : (
            <NotAvailable />
          )}
        </Col>
      </Row>
      <Row className="mrg-top-10">
        <Col span={4} className="text-bold">
          {t(`${I18N_BASE_PATH}.dateOfBirth`, 'Date of birth')}
        </Col>
        <Col span={20}>{registrationDate || <NotAvailable />}</Col>
      </Row>
      <Row className="mrg-top-10">
        <Col span={4} className="text-bold">
          {t(`${I18N_BASE_PATH}.countries`, 'Countries')}
        </Col>
        <Col span={20}>
          {countries.length ? (
            countries.map((country) => (
              <CountryFlag
                key={country}
                className="company-record-country-flag mrg-right-10"
                country={country}
                showTooltip
              />
            ))
          ) : (
            <NotAvailable />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EntityBody;

EntityBody.propTypes = {
  entity: PropTypes.any.isRequired,
};
