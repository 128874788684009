import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import EmptyMessage from 'components/EmptyMessage';
import { useTranslation } from 'react-i18next';

import './styles.less';

const CSVTable = ({ src }) => {
  const { t: translate } = useTranslation();

  const [tableSections, setTableSections] = useState([]);

  const normalizeData = useCallback((data) => {
    const rows = data.split('\n').map((row) => row.split(','));

    const sections = [];
    let section = [];
    rows.forEach((row) => {
      if (row.every((cell) => cell === '')) {
        sections.push(section);
        section = [];
      } else {
        section.push(row);
      }
    });
    sections.push(section);

    return sections;
  }, []);

  const getCSVData = useCallback(async () => {
    const response = await fetch(src);
    const text = await response.text();

    setTableSections(normalizeData(text));
  }, [normalizeData, src]);

  useEffect(getCSVData, [getCSVData]);

  if (!src)
    return (
      <EmptyMessage
        description={translate(
          `components.PreviewModal.noData`,
          'Could not load preview'
        )}
      />
    );

  return (
    <table>
      {tableSections.map((section, i) => (
        <React.Fragment key={`section-${i + 1}`}>
          <thead>
            <tr>
              {section[0].map((cell, i) => (
                <th key={`tr-${cell}-${i + 1}`}>{cell}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {section.slice(1).map((row, i) => (
              <tr key={`section-row-${i + 1}`}>
                {row.map((cell, i) => (
                  <td key={`td-${cell}-${i + 1}`}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </React.Fragment>
      ))}
    </table>
  );
};

CSVTable.propTypes = {
  src: PropTypes.string,
};

CSVTable.defaultProps = {
  src: null,
};

export default CSVTable;
