/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Input, Form, Col, Button, message } from 'antd';
import { useParams } from 'react-router-dom';

import InputMask from 'components/Form/InputMask';

import { useExecution } from 'hooks/execution';

import { useFetch } from 'services/hooks';

import uppercaseObject from 'utils/uppercaseObject';

const OcrData = ({ data, type }) => {
  const { t: translate } = useTranslation();

  const { executionId } = useParams();
  const { loadExecution } = useExecution();

  const { post, loading } = useFetch();

  const handleSubmit = useCallback(
    async (values) => {
      try {
        const payload = uppercaseObject(values);

        await post({
          url: `/executions/${executionId}/manual-ocr`,
          payload,
          showMessage: false,
        });
        message.success(
          translate(
            'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.handleSubmit.messages.success'
          )
        );

        loadExecution({
          executionId,
          clearData: true,
        });
      } catch (error) {
        message.error(
          translate(
            'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.handleSubmit.messages.error'
          )
        );
      }
    },
    [executionId, loadExecution, post, translate]
  );

  const renderOptionalLabel = useCallback(
    (label) => {
      return (
        <>
          {label}
          <i className="font-size-10" style={{ marginLeft: 2 }}>
            -{' '}
            {translate(
              'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.renderOptionalLabel.text'
            )}
          </i>
        </>
      );
    },
    [translate]
  );

  return (
    <Form
      layout="vertical"
      className="uppercase-form"
      onFinish={handleSubmit}
      initialValues={data}
    >
      <Row className="custom-validations-data">
        <Col span={6}>
          <Form.Item
            label={translate(
              'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.cpf.label'
            )}
            name={translate(
              'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.cpf.name'
            )}
            rules={[{ required: true }]}
          >
            <InputMask
              mask={translate(
                'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.cpf.input.mask'
              )}
              placeholder={translate(
                'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.cpf.input.placeholder'
              )}
              className="uppercase-input"
            />
          </Form.Item>
        </Col>
        <Col span={6} rules={[{ required: true }]}>
          <Form.Item
            label={translate(
              'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.birthDate.label'
            )}
            name="birthDate"
          >
            <InputMask
              mask={translate(
                'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.birthDate.input.mask'
              )}
              placeholder={translate(
                'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.birthDate.input.placeholder'
              )}
              className="uppercase-input"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={renderOptionalLabel(
              translate(
                'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.name.label'
              )
            )}
            name="name"
          >
            <Input className="uppercase-input" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={renderOptionalLabel(
              translate(
                'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.motherName.label'
              )
            )}
            name="motherName"
          >
            <Input className="uppercase-input" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={renderOptionalLabel(
              translate(
                'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.fatherName.label'
              )
            )}
            name="fatherName"
          >
            <Input className="uppercase-input" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        {['RG'].includes(type?.toUpperCase()) && (
          <Col span={8}>
            <Form.Item
              label={renderOptionalLabel(
                translate(
                  'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.rgIssueDate.label'
                )
              )}
              name={translate(
                'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.rgIssueDate.name'
              )}
            >
              <InputMask
                mask={translate(
                  'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.rgIssueDate.input.mask'
                )}
                placeholder={translate(
                  'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.rgIssueDate.input.placeholder'
                )}
                className="uppercase-input"
              />
            </Form.Item>
          </Col>
        )}
        {['CNH'].includes(type?.toUpperCase()) && (
          <Col span={8}>
            <Form.Item
              label={renderOptionalLabel(
                translate(
                  'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.cnhIssueDate.label'
                )
              )}
              name={translate(
                'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.cnhIssueDate.name'
              )}
            >
              <InputMask
                mask={translate(
                  'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.cnhIssueDate.input.mask'
                )}
                placeholder={translate(
                  'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.cnhIssueDate.input.placeholder'
                )}
                className="uppercase-input"
              />
            </Form.Item>
          </Col>
        )}
        {['RG'].includes(type?.toUpperCase()) && (
          <Col span={8}>
            <Form.Item
              label={renderOptionalLabel(
                translate(
                  'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.rgIssuingAuthority.label'
                )
              )}
              name={translate(
                'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.rgIssuingAuthority.name'
              )}
            >
              <Input className="uppercase-input" />
            </Form.Item>
          </Col>
        )}
        {['CNH'].includes(type?.toUpperCase()) && (
          <Col span={8}>
            <Form.Item
              label={renderOptionalLabel(
                translate(
                  'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.issuingAuthority.label'
                )
              )}
              name={translate(
                'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.issuingAuthority.name'
              )}
            >
              <Input className="uppercase-input" />
            </Form.Item>
          </Col>
        )}
        {['CNH'].includes(type?.toUpperCase()) && (
          <Col span={8}>
            <Form.Item
              label={translate(
                'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.cnhIssueState.label'
              )}
              name={translate(
                'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.cnhIssueState.name'
              )}
            >
              <Input className="uppercase-input" />
            </Form.Item>
          </Col>
        )}
        {['RG'].includes(type?.toUpperCase()) && (
          <Col span={8}>
            <Form.Item
              label={renderOptionalLabel(
                translate(
                  'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.issueState.label'
                )
              )}
              name={translate(
                'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.issueState.name'
              )}
            >
              <Input className="uppercase-input" />
            </Form.Item>
          </Col>
        )}
        {['RG', 'CNH'].includes(type?.toUpperCase()) && (
          <Col span={12}>
            <Form.Item
              label={renderOptionalLabel(
                translate(
                  'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.rg.label'
                )
              )}
              name={translate(
                'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.rg.name'
              )}
            >
              <Input className="uppercase-input" />
            </Form.Item>
          </Col>
        )}
        {['CNH'].includes(type?.toUpperCase()) && (
          <Col span={12}>
            <Form.Item
              label={renderOptionalLabel(
                translate(
                  'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.registrationNumber.label'
                )
              )}
              name={translate(
                'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.registrationNumber.name'
              )}
            >
              <Input className="uppercase-input" />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row
        style={{ display: 'flex', justifyContent: 'flex-end' }}
        className="no-mrg-right"
      >
        <Col>
          <Button htmlType="submit" type="primary" loading={loading}>
            {translate(
              'pages.private.executions.components.tabs.components.documentsValidations.components.shared.ocrData.index.ocrData.form.button'
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default OcrData;
