/* eslint-disable react/prop-types */
import React, { useEffect, useCallback, useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { Spin } from 'antd';

import { useAuth } from 'hooks/auth';
import { useTags } from 'hooks/tags';
import { useActivities } from 'hooks/activities';
import { useFetch } from 'services/hooks';

import { hasRequiredPermissions } from 'routes/permissionFunctions';

import emptyTags from 'assets/images/empty-tags.svg';

import ProfileWidget from '../ProfileWidget';
import ProfileTag from '../ProfileTag';

import './styles.less';

const TagsList = ({ url }) => {
  const { t: translate } = useTranslation();
  const matchPeople = useRouteMatch('/profile/people');
  const { get, loading } = useFetch();

  const { user } = useAuth();
  const { refreshActivities } = useActivities();
  const { deleteTag, success, fillCustomAllTags } = useTags();

  const [tagsList, setTagsList] = useState([]);

  const fetch = useCallback(
    async ({ ALL_DATA }) => {
      try {
        const response = await get({
          url: `${url}/tags`,
          showMessage: false,
        });

        setTagsList(response?.tags);

        if (ALL_DATA) {
          fillCustomAllTags(response?.tags);
        }
      } catch (error) {
        //
      }
    },
    [get, url, fillCustomAllTags]
  );

  const firstLoad = useRef(true);
  useEffect(() => {
    fetch({ ALL_DATA: firstLoad.current });
    firstLoad.current = false;
  }, [fetch]);

  const handleDeleteTag = useCallback(
    (id) => {
      deleteTag(url, id);
    },
    [deleteTag, url]
  );

  useEffect(() => {
    if (success !== 0) {
      refreshActivities();
      fetch({ ALL_DATA: false });
    }
  }, [success, fetch]); // eslint-disable-line react-hooks/exhaustive-deps

  const permissions = useMemo(
    () => (matchPeople ? ['people:update'] : ['companies:update']),
    [matchPeople]
  );

  const isClosable = useMemo(() => hasRequiredPermissions(user, permissions, []), [
    permissions,
    user,
  ]);

  return (
    <ProfileWidget
      title={translate(
        'pages.private.profiles.components.tagsList.index.tagsList.profileWidget.title'
      )}
      subtitle={translate(
        'pages.private.profiles.components.tagsList.index.tagsList.profileWidget.subtitle'
      )}
      icon="caf-ic_tag"
    >
      <div className="personal-data-info">
        {loading && (
          <div className="custom-loading-spin tags-list">
            <Spin />
          </div>
        )}

        {!loading && (
          <>
            {tagsList?.length > 0 &&
              tagsList?.map((data) => {
                return (
                  <ProfileTag
                    key={data?._id}
                    onClose={() => handleDeleteTag(data?._id)}
                    isClosable={isClosable}
                    name={data?.title}
                    color={data?.color}
                    special={data?.special}
                    actionDescription={data?.actionDescription}
                  />
                );
              })}

            {!tagsList?.length > 0 && (
              <div className="empty-tags-container">
                <img className="grayscale-95" src={emptyTags} alt="Vetor etiquetas" />
                <span>
                  {translate(
                    'pages.private.profiles.components.tagsList.index.tagsList.hasNoTags'
                  )}
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </ProfileWidget>
  );
};

export default TagsList;
