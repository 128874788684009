/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Tag } from 'antd';

import PermissionWrapper from 'routes/PermissionWrapper';

import '../../styles.less';

const SwitchRenderer = ({ data, openModal }) => {
  const { t: translate } = useTranslation();

  const { active, _id } = data;

  return (
    <div
      id="switch-renderer"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100px',
      }}
    >
      <PermissionWrapper
        forbiddenContent={
          <Tag className={active ? 'tag-active' : 'tag-inactive'}>
            {translate(
              `${
                active
                  ? 'pages.private.profileModels.profileModelList.components.switchRenderer.tag.active'
                  : 'pages.private.profileModels.profileModelList.components.switchRenderer.tag.inactive'
              }`
            )}
          </Tag>
        }
        requiredPermissions={['profile-templates:delete']}
      >
        <Switch
          style={{
            minWidth: 42,
            height: 23,
            minHeight: 23,
          }}
          checked={!!active}
          checkedChildren={translate(
            'pages.private.profileModels.profileModelList.components.switchRenderer.switch.active'
          )}
          unCheckedChildren={translate(
            'pages.private.profileModels.profileModelList.components.switchRenderer.switch.inactive'
          )}
          onChange={(checked) => openModal(checked, _id)}
        />
      </PermissionWrapper>
    </div>
  );
};

export default SwitchRenderer;
