import React from 'react';
import { Modal as ANTDModal, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';

import XLSTable from './XLSTable';
import CSVTable from './CSVTable';

import './styles.less';

const PreviewModal = ({ isOpen, handleClose, type, previewUrl, isLoading }) => {
  const { t: translate } = useTranslation();

  const handleDownload = () => {
    window.open(previewUrl, '_blank');
    handleClose();
  };

  if (!isOpen) return null;

  return (
    <ANTDModal
      visible
      footer={null}
      onCancel={handleClose}
      width={1000}
      centered
      closable={false}
    >
      <div className="preview-modal">
        <h1>{translate('components.PreviewModal.title', 'Export Preview')}</h1>
        {isLoading ? (
          <Loader size="60px" strokeWidth={4} />
        ) : (
          <main>
            {type === 'xls' && <XLSTable src={previewUrl} />}
            {type === 'csv' && <CSVTable src={previewUrl} />}
          </main>
        )}
        <footer>
          <Button onClick={handleClose} size="small" type="default">
            {translate('components.PreviewModal.buttons.goBack', 'Go Back')}
          </Button>
          <Button
            disabled={!previewUrl}
            onClick={handleDownload}
            size="small"
            type="primary"
          >
            {translate('components.PreviewModal.buttons.download', 'Download')}
          </Button>
        </footer>
      </div>
    </ANTDModal>
  );
};

PreviewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  previewUrl: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default PreviewModal;
