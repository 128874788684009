/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Row, Col } from 'antd';

import ViewMore from 'components/ViewMore';
import EmptyState from 'components/EmptyState';
import EmptyExecutionState from 'components/EmptyExecutionState';

import ContactAndRelationCard from 'pages/private/Profiles/components/ContactAndRelationCard';
import DottedLabel from 'components/DottedLabel';

// import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import useAllPeopleInternalRestrictiveList from './AllPeopleInternalRestrictiveList';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

import './styles.less';

const I18N_BASE_PATH =
  'components.dataSourceCards.relationships.components.peopleInternalRestrictiveList.index.peopleInternalRestrictiveList';

const PeopleInternalRestrictiveList = ({ source = {}, toPrint = false /* urlId */ }) => {
  const { t: translate } = useTranslation();

  const peopleInternalRestrictiveList = useMemo(() => source?.data, [source]);
  const [danger, setDanger] = useState(false);

  const QuerySucceeded = useMemo(
    () =>
      source?.statusCode === '01' ||
      source?.available === true ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [source]
  );

  const {
    openModal,
    AllPeopleInternalRestrictiveListModal,
  } = useAllPeopleInternalRestrictiveList({
    data: danger
      ? peopleInternalRestrictiveList?.occurrences
      : peopleInternalRestrictiveList?.relationships,
    danger,
  });

  const parsedList = useMemo(() => {
    if (
      !peopleInternalRestrictiveList ||
      !peopleInternalRestrictiveList?.relationships?.length > 0
    )
      return <></>;

    const el = [];
    const _size = peopleInternalRestrictiveList?.relationships?.length;

    if (_size) {
      for (let i = 0; i < _size; i += 2) {
        el.push(
          <Row className="contact-data-row">
            {peopleInternalRestrictiveList?.relationships
              ?.slice(i, i + 2)
              ?.map((relationship) => (
                <ContactAndRelationCard
                  key={relationship?.cpf}
                  span={12}
                  relation
                  primary
                  name={relationship?.name}
                  type={relationship?.relationshipType}
                  level={relationship?.relationshipLevel}
                  className="mrg-top-10 pdd-horizontal-0"
                />
              ))}
          </Row>
        );
      }
    }

    if (el.length > 0) {
      const parsedEl = toPrint ? el : el.splice(0, 1);
      return parsedEl;
    }

    return <></>;
  }, [peopleInternalRestrictiveList, toPrint]);

  const parsedOccurenceList = useMemo(() => {
    if (
      !peopleInternalRestrictiveList ||
      !peopleInternalRestrictiveList?.occurrences?.length > 0
    )
      return <></>;

    const el = [];
    const _size = peopleInternalRestrictiveList?.occurrences?.length;

    if (_size) {
      for (let i = 0; i < _size; i += 2) {
        el.push(
          <Row className="contact-data-row">
            {peopleInternalRestrictiveList?.occurrences
              ?.slice(i, i + 2)
              ?.map((occurrences) => (
                <ContactAndRelationCard
                  key={occurrences?.cpf}
                  span={12}
                  relation
                  danger
                  name={occurrences?.name}
                  type={occurrences?.relationshipType}
                  level={occurrences?.relationshipLevel}
                  className="mrg-top-10 pdd-horizontal-0"
                />
              ))}
          </Row>
        );
      }
    }

    if (el.length > 0) {
      const parsedEl = toPrint ? el : el.splice(0, 1);
      return parsedEl;
    }

    return <></>;
  }, [peopleInternalRestrictiveList, toPrint]);

  return (
    <>
      {AllPeopleInternalRestrictiveListModal}
      <DataSourceCardCollapsePanel
        icon="caf-ic_file"
        title={translate(`${I18N_BASE_PATH}.columnLabel.title`)}
        customId="restrictiveLists"
        toPrint={toPrint}
      >
        <Row
          id="internal-restrictive-lists"
          className="contact-data-container mrg-left-10"
        >
          {!QuerySucceeded && (
            <EmptyExecutionState
              statusCode={source?.data?.statusCode || source?.statusCode}
              message={source?.data?.message || source?.message}
            />
          )}

          <DottedLabel
            className="mrg-top-25 mrg-left-10 mrg-btm-25"
            type={
              peopleInternalRestrictiveList?.occurrences?.length > 0
                ? 'danger'
                : 'primary'
            }
            description={
              peopleInternalRestrictiveList?.occurrences?.length > 0
                ? translate(`${I18N_BASE_PATH}.dottedLabelTrue`, {
                    value: peopleInternalRestrictiveList?.occurrences?.length,
                  })
                : translate(`${I18N_BASE_PATH}.dottedLabelFalse`)
            }
          />

          {peopleInternalRestrictiveList?.occurrences &&
            peopleInternalRestrictiveList?.occurrences?.length > 0 && (
              <>{parsedOccurenceList}</>
            )}
          {!toPrint && peopleInternalRestrictiveList?.occurrences?.length > 2 && (
            <Col span={24} className="ant-col-lists">
              <ViewMore
                title={translate(`${I18N_BASE_PATH}.viewMore.title`)}
                type="link"
                onClick={() => {
                  setDanger(true);
                  openModal();
                }}
              />
            </Col>
          )}
          <Divider />
          <strong className="title-text">
            {translate(`${I18N_BASE_PATH}.retrictiveListInfo`)}
          </strong>
          {peopleInternalRestrictiveList?.relationships &&
            !peopleInternalRestrictiveList?.relationships?.length > 0 && (
              <EmptyState
                type="empty-success"
                description={translate(
                  `${I18N_BASE_PATH}.columnLabel.emptyState.description`
                )}
              />
            )}

          {parsedList}
          {/* </Col> */}
        </Row>

        {!toPrint && peopleInternalRestrictiveList?.relationships?.length > 2 && (
          <Row className="mrg-left-5">
            <ViewMore
              title={translate(`${I18N_BASE_PATH}.viewMore.title`)}
              type="link"
              onClick={() => {
                setDanger(false);
                openModal();
              }}
            />
          </Row>
        )}
      </DataSourceCardCollapsePanel>
    </>
  );
};

export default PeopleInternalRestrictiveList;
