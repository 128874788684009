import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/auth';
import { Menu } from 'antd';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { hasRequiredPermissions } from 'routes/permissionFunctions';

import './overrides.less';
import './styles.less';

const NavigationMenu = (props) => {
  const { collapsed, config, activeMenu } = props;

  const [openKeys, setOpenKeys] = useState([]);
  const history = useHistory();
  const { user } = useAuth();

  const handleItemClick = (path, onClick, id) => {
    if (path) {
      history.push(path);
    } else if (onClick) {
      onClick();
    }
  };

  const handleOpenChange = (keys) => {
    if (openKeys.length === 0 || keys.length === 0) {
      setOpenKeys(keys);
    } else {
      setOpenKeys([keys[keys.length - 1]]);
    }
  };

  const renderMenuItem = ({ label, Icon, path, children, onClick, id }) => {
    if (collapsed && !children) {
      return (
        <Menu.SubMenu
          key={id}
          className={classNames('sidebar-submenu-item}', {
            'sidebar-menu-item-active': path && id === activeMenu,
            collapsed,
          })}
          title={
            <span
              className={classNames('sidebar-menu-item', {
                collapsed,
              })}
            >
              {!!Icon && <Icon />}
            </span>
          }
        >
          <Menu.Item
            key={`${id}-sub`}
            id={`click_menu_${id}`}
            onClick={() => handleItemClick(path, onClick, id)}
            className={classNames(
              'sidebar-menu-item sidebar-submenu-sub-item collapsed',
              {
                'sidebar-submenu-item-active': path && id === activeMenu,
                collapsed,
              }
            )}
          >
            <Icon />
            <span>{label}</span>
          </Menu.Item>
        </Menu.SubMenu>
      );
    }

    if (!children) {
      return (
        <Menu.Item
          key={label}
          onClick={() => handleItemClick(path, onClick, id)}
          className={classNames('sidebar-menu-item', {
            'sidebar-menu-item-active': path && id === activeMenu,
            collapsed,
          })}
        >
          <Icon />
          {!collapsed && <span>{label}</span>}
        </Menu.Item>
      );
    }

    return (
      <Menu.SubMenu
        key={id}
        className={classNames('sidebar-submenu-item', {
          'sidebar-menu-item-active': children.some(
            ({ path: childPath, id: childId }) => childPath && childId === activeMenu
          ),
          collapsed,
        })}
        title={
          <span
            className={classNames('sidebar-menu-item', {
              collapsed,
            })}
          >
            {!!Icon && <Icon />}
            {!collapsed && <span>{label}</span>}
          </span>
        }
      >
        {collapsed && (
          <Menu.Item selectable={false} className="sidebar-submenu-title">
            <span>{label}</span>
          </Menu.Item>
        )}
        {children
          .filter(
            ({ permissions }) =>
              (!!permissions &&
                hasRequiredPermissions(
                  user,
                  permissions.requiredPermissions,
                  permissions.requiredResources,
                  permissions.or,
                  permissions.product
                )) ||
              !permissions
          )
          .map(
            ({
              id: childId,
              label: childLabel,
              Icon: ChildIcon,
              path: childPath,
              onClick: childOnClick,
            }) => (
              <Menu.Item
                key={childId}
                onClick={() => handleItemClick(childPath, childOnClick, childId)}
                className={classNames(
                  'sidebar-menu-item sidebar-submenu-sub-item collapsed',
                  {
                    'sidebar-submenu-item-active': childPath && childId === activeMenu,
                    collapsed,
                  }
                )}
              >
                {!!ChildIcon && <ChildIcon />}
                <span>{childLabel}</span>
              </Menu.Item>
            )
          )}
      </Menu.SubMenu>
    );
  };

  return (
    <Menu
      className={classNames('sidebar-menu', {
        collapsed,
      })}
      mode="vertical"
      onOpenChange={handleOpenChange}
      openKeys={openKeys}
    >
      {config
        .filter(
          ({ permissions }) =>
            (!!permissions &&
              hasRequiredPermissions(
                user,
                permissions.requiredPermissions,
                permissions.requiredResources,
                permissions.or,
                permissions.product
              )) ||
            !permissions
        )
        .map(renderMenuItem)}
    </Menu>
  );
};

export default NavigationMenu;

NavigationMenu.propTypes = {
  config: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    Icon: PropTypes.element,
    path: PropTypes.string,
    onClick: PropTypes.func,
    permissions: PropTypes.shape({
      requiredPermissions: PropTypes.arrayOf(PropTypes.string),
      requiredResources: PropTypes.arrayOf(PropTypes.string),
      or: PropTypes.bool,
      product: PropTypes.string,
    }),
    children: PropTypes.arrayOf({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      Icon: PropTypes.element,
      path: PropTypes.string,
      onClick: PropTypes.func,
      permissions: PropTypes.shape({
        requiredPermissions: PropTypes.arrayOf(PropTypes.string),
        requiredResources: PropTypes.arrayOf(PropTypes.string),
        or: PropTypes.bool,
        product: PropTypes.string,
      }),
    }),
  }).isRequired,
  collapsed: PropTypes.bool.isRequired,
  activeMenu: PropTypes.string.isRequired,
};
