import { Collapse, Table } from 'antd';
import React from 'react';
import { CompanyBody1 } from 'pages/private/KYBCompany/CompanyDetailsV2/components';
import { useTranslation } from 'react-i18next';

const CollapseTransparent = ({ key, columns, rows, loading }) => {
  const { t } = useTranslation();

  if (!rows?.length) return null;

  return (
    <Collapse
      className="kyb-company-collapse kyb-company-collapse-transparent"
      expandIconPosition="right"
      bordered={false}
      showArrow
    >
      <Collapse.Panel
        key={key}
        header={
          <CompanyBody1>
            <b>{t('components.infoNotAvailable.label', 'Informations not available')}</b>
          </CompanyBody1>
        }
      >
        <Table
          className="KYB-financial-info-table KYB-financial-info-table-not-available"
          columns={columns}
          dataSource={rows}
          pagination={false}
          loading={loading}
          size="small"
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export default CollapseTransparent;
