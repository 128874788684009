import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Money } from '@combateafraude/icons/financial';
import { ArrowDown, ArrowUp } from '@combateafraude/icons/general';

import { ExportMenu } from 'components/DataHeader/components/ExportOptions';

import { Button, Popover } from 'antd';

import './styles.less';

const BillingHeader = ({ companyName, exportOptions, loading, hidePrice }) => {
  const { t: translate } = useTranslation();

  return (
    <div className="billing-header">
      <div className="billing-header__content">
        <span aria-hidden className="billing-header__icon">
          <Money />
        </span>
        <hgroup>
          <h2>{translate('pages.private.consumption.billingHeader.title')}</h2>
          <h3>
            {translate('pages.private.consumption.billingHeader.subtitle')}{' '}
            <span>{companyName}</span>
          </h3>
        </hgroup>
      </div>
      {!hidePrice && (
        <ExportButton
          exportOptions={exportOptions}
          loading={loading}
          label={translate('pages.private.consumption.billingHeader.export')}
        />
      )}
    </div>
  );
};

export const ExportButton = ({ exportOptions, label, loading }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  if (!exportOptions || exportOptions.length === 0) return null;

  return (
    <Popover
      open={popoverOpen}
      onVisibleChange={setPopoverOpen}
      content={
        <ExportMenu className="billing-header__export-menu" options={exportOptions} />
      }
      placement="bottomRight"
      trigger="click"
    >
      <Button
        loading={loading}
        icon={popoverOpen ? <ArrowUp /> : <ArrowDown />}
        type="primary"
        className="billing-header__button"
      >
        {label}
      </Button>
    </Popover>
  );
};

ExportButton.propTypes = {
  exportOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

ExportButton.defaultProps = {
  exportOptions: [],
  loading: false,
};

BillingHeader.propTypes = {
  companyName: PropTypes.string.isRequired,
  exportOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  loading: PropTypes.bool,
  hidePrice: PropTypes.bool,
};

BillingHeader.defaultProps = {
  loading: false,
  hidePrice: false,
};

export default BillingHeader;
