import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useFetch } from 'services/hooks';

export const SecurityOptionsContext = createContext({});

const DEFAULT_MAX_NUMBER_OF_ATTEMPTS = 5;
const ONE_HOUR_IN_MINUTES = 60;
const TWELVE_HOURS_IN_MINUTES = 720;
const DEFAULT_MFA_TIMEOUT_IN_DAYS = 2;
const DEFAULT_PASSWORD_EXPIRATION_PERIOD = 90;
const DEFAULT_SUSPENSION_TIMEOUT_IN_DAYS = 45;

export const SecurityOptionsProvider = ({ children }) => {
  const { patch, get, loading } = useFetch();

  const [maxNumberOfAttempts, setMaxNumberOfAttempts] = useState(
    DEFAULT_MAX_NUMBER_OF_ATTEMPTS
  );
  const [idleTimeoutInMinutes, setIdleTimeoutInMinutes] = useState(ONE_HOUR_IN_MINUTES);
  const [suspensionByInactivityInDays, setSuspensionByInactivityInDays] = useState(
    DEFAULT_SUSPENSION_TIMEOUT_IN_DAYS
  );
  const [accessTokenExpirationInMinutes, setAccessTokenExpirationInMinutes] = useState(
    TWELVE_HOURS_IN_MINUTES
  );
  const [allowEmailAlerts, setAllowEmailAlerts] = useState(false);
  const [allowMultipleLogins, setAllowMultipleLogins] = useState(false);
  const [multifactorAuthentication, setMultifactorAuthentication] = useState(false);
  const [mfaAuthenticationPeriodInDays, setMfaAuthenticationPeriodInDays] = useState(
    DEFAULT_MFA_TIMEOUT_IN_DAYS
  );
  const [mfaUpdatedAt, setMfaUpdatedAt] = useState(null);
  const [mfaUserLastModification, setMfaUserLastModification] = useState(null);
  const [passwordExpiration, setPasswordExpiration] = useState(true);
  const [passwordExpirationPeriod, setPasswordExpirationPeriod] = useState(
    DEFAULT_PASSWORD_EXPIRATION_PERIOD
  );

  const patchSessionSettings = useCallback(
    async ({ paramName, timeParameter, activeParameter, showMessage = true }) => {
      await patch({
        url: `security-params/${paramName}`,
        payload: {
          timeParameter,
          activeParameter,
        },
        showMessage,
      });
    },
    [patch]
  );

  const doPatchAllowMultipleLogins = useCallback(async () => {
    await patchSessionSettings({
      paramName: 'allowMultipleLogins',
      activeParameter: !allowMultipleLogins,
    });
  }, [patchSessionSettings, allowMultipleLogins]);

  const doPatchAllowEmailAlerts = useCallback(async () => {
    await patchSessionSettings({
      paramName: 'allowEmailAlerts',
      activeParameter: !allowEmailAlerts,
    });
  }, [patchSessionSettings, allowEmailAlerts]);

  const doInactivationConfigPatch = useCallback(async () => {
    await patchSessionSettings({
      paramName: 'idleTimeoutInMinutes',
      timeParameter: idleTimeoutInMinutes,
    });
    await patchSessionSettings({
      paramName: 'suspensionByInactivityInDays',
      timeParameter: suspensionByInactivityInDays,
    });
  }, [patchSessionSettings, idleTimeoutInMinutes, suspensionByInactivityInDays]);

  const doPatchAccessTokenExpirationInMinutes = useCallback(async () => {
    await patchSessionSettings({
      paramName: 'accessTokenExpirationInMinutes',
      timeParameter: accessTokenExpirationInMinutes,
    });
  }, [patchSessionSettings, accessTokenExpirationInMinutes]);

  const doPatchMaxNumberOfAttempts = useCallback(async () => {
    await patchSessionSettings({
      paramName: 'maxQtdAttempts',
      timeParameter: maxNumberOfAttempts,
    });
  }, [patchSessionSettings, maxNumberOfAttempts]);

  const doPatchMultifactorAuthentication = useCallback(async () => {
    if (!multifactorAuthentication) {
      await patchSessionSettings({
        paramName: 'mfaAuthenticationPeriodInDays',
        timeParameter: 2,
      });
    }
    if (multifactorAuthentication) {
      await patchSessionSettings({
        paramName: 'passwordExpiration',
        activeParameter: true,
      });
    }
    await patchSessionSettings({
      paramName: 'mfaUserLastModification',
      timeParameter: mfaUserLastModification,
    });
    await patchSessionSettings({
      paramName: 'mfaUpdatedAt',
      timeParameter: mfaUpdatedAt,
    });
    await patchSessionSettings({
      paramName: 'mfaActivated',
      activeParameter: !multifactorAuthentication,
    });
  }, [
    patchSessionSettings,
    multifactorAuthentication,
    mfaUserLastModification,
    mfaUpdatedAt,
  ]);

  const doPatchMfaAuthenticationPeriodInDays = useCallback(async () => {
    await patchSessionSettings({
      paramName: 'mfaAuthenticationPeriodInDays',
      timeParameter: mfaAuthenticationPeriodInDays,
    });
    await patchSessionSettings({
      paramName: 'mfaUserLastModification',
      timeParameter: mfaUserLastModification,
    });
    await patchSessionSettings({
      paramName: 'mfaUpdatedAt',
      timeParameter: mfaUpdatedAt,
    });
  }, [
    patchSessionSettings,
    mfaAuthenticationPeriodInDays,
    mfaUserLastModification,
    mfaUpdatedAt,
  ]);

  const doPatchPasswordExpiration = useCallback(async () => {
    await patchSessionSettings({
      paramName: 'passwordExpiration',
      activeParameter: !passwordExpiration,
    });
  }, [patchSessionSettings, passwordExpiration]);

  const doPatchPasswordExpirationPeriod = useCallback(async () => {
    await patchSessionSettings({
      paramName: 'passwordExpirationPeriod',
      timeParameter: passwordExpirationPeriod,
    });
  }, [patchSessionSettings, passwordExpirationPeriod]);

  useEffect(() => {
    const updateData = async () => {
      const response = await get({
        url: 'security-params',
      });

      setIdleTimeoutInMinutes(response?.idleTimeoutInMinutes ?? ONE_HOUR_IN_MINUTES);
      setAccessTokenExpirationInMinutes(
        response?.accessTokenExpirationInMinutes ?? TWELVE_HOURS_IN_MINUTES
      );
      setMaxNumberOfAttempts(response?.maxQtdAttempts ?? DEFAULT_MAX_NUMBER_OF_ATTEMPTS);
      setAllowMultipleLogins(response?.allowMultipleLogins ?? false);
      setAllowEmailAlerts(response?.allowEmailAlerts ?? false);
      setMultifactorAuthentication(response?.mfaActivated ?? false);
      setMfaAuthenticationPeriodInDays(
        !response?.mfaAuthenticationPeriodInDays
          ? 0
          : response?.mfaAuthenticationPeriodInDays
      );
      setMfaUserLastModification(response?.mfaUserLastModification);
      setMfaUpdatedAt(response?.mfaUpdatedAt);
      setPasswordExpiration(
        response?.passwordExpiration ?? !response?.multifactorAuthentication
      );
      setPasswordExpirationPeriod(
        response?.passwordExpirationPeriod ?? DEFAULT_PASSWORD_EXPIRATION_PERIOD
      );
      setSuspensionByInactivityInDays(
        response?.suspensionByInactivityInDays ?? DEFAULT_SUSPENSION_TIMEOUT_IN_DAYS
      );
    };
    updateData();
  }, [get]);

  return (
    <SecurityOptionsContext.Provider
      value={{
        loading,
        idleTimeoutInMinutes,
        accessTokenExpirationInMinutes,
        allowMultipleLogins,
        setAllowMultipleLogins,
        setAccessTokenExpirationInMinutes,
        setIdleTimeoutInMinutes,
        doPatchAccessTokenExpirationInMinutes,
        doPatchAllowMultipleLogins,
        doInactivationConfigPatch,
        doPatchAllowEmailAlerts,
        allowEmailAlerts,
        setAllowEmailAlerts,
        doPatchMaxNumberOfAttempts,
        maxNumberOfAttempts,
        setMaxNumberOfAttempts,
        multifactorAuthentication,
        setMultifactorAuthentication,
        doPatchMultifactorAuthentication,
        mfaAuthenticationPeriodInDays,
        setMfaAuthenticationPeriodInDays,
        doPatchMfaAuthenticationPeriodInDays,
        mfaUpdatedAt,
        setMfaUpdatedAt,
        mfaUserLastModification,
        setMfaUserLastModification,
        passwordExpirationPeriod,
        doPatchPasswordExpiration,
        doPatchPasswordExpirationPeriod,
        setPasswordExpirationPeriod,
        passwordExpiration,
        setPasswordExpiration,
        suspensionByInactivityInDays,
        setSuspensionByInactivityInDays,
      }}
    >
      {children}
    </SecurityOptionsContext.Provider>
  );
};
