import React from 'react';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import { Button } from 'antd';

import ExecutionStatusFilter from 'components/Filters/ExecutionStatusFilter';
import CreatedAtFilter from '../../../../Executions/components/List/components/Filter/CreatedAtFilter';
import UpdatedAtFilter from '../../../../Executions/components/List/components/Filter/UpdatedAtFilter';

import './styles.less';

const Filter = ({ resetFilters, setFilter, translate, isLoading }) => {
  const [query] = useQueryParams({
    _sort: StringParam,
    byStatus: ArrayParam,
    updatedDate: StringParam,
    createdDate: StringParam,
  });

  return (
    <div id="execution-filter-component-KYB">
      <CreatedAtFilter
        disabled={isLoading}
        title={translate(
          'pages.private.executions.components.list.components.filter.index.filter.createdAtFilter.title'
        )}
        value={query.createdDate}
        onChange={(values) => setFilter('createdDate', values)}
      />

      <UpdatedAtFilter
        disabled={isLoading}
        title={translate(
          'pages.private.executions.components.list.components.filter.index.filter.updatedAtFilter.title'
        )}
        value={query.updatedDate}
        onChange={(values) => setFilter('updatedDate', values)}
      />
      <ExecutionStatusFilter
        disabled={isLoading}
        value={query.byStatus}
        onChange={(values) => setFilter('byStatus', values)}
        isKYBStatus
      />
      <div className="mrg-right-15 mrg-top-20">
        <Button
          disabled={isLoading}
          type="default"
          block
          onClick={resetFilters}
          className="reset-filters-button"
        >
          {translate('components.filters.resetFilters.button')}
        </Button>
      </div>
    </div>
  );
};

export default Filter;
