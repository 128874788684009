import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';
import { cpfTranslation, executionCpfErrosTranslation } from 'utils/personalTranslations';
import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';
import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.basicInfos.components.person.registrationInfosContent.registrationInfosContent.basicInfosCard';

/**
 * @see https://docs.combateafraude.com/docs/api/secoes-validacoes/background-checking-pf/#consulta-de-cpf-na-receita-federal-pf2
 */
export const PfCpfData = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDateOnly } = useI18nFormatters();

  const data = useMemo(() => source.data, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const translatedMessage = useMemo(
    () =>
      statusCode !== '01' && !data?.taxIdStatus
        ? executionCpfErrosTranslation[statusCode] || executionCpfErrosTranslation['01']
        : cpfTranslation[(data?.taxIdStatus || '').trim()] || cpfTranslation.NULA,
    [data, statusCode]
  );

  const parsedBirthDate = useMemo(() => {
    if (data?.birthDate)
      return i18nFormatDateOnly(data?.birthDate.split('/').reverse().join('-'), 1);
    return '-';
  }, [data, i18nFormatDateOnly]);

  const hasMinorAge = useMemo(() => {
    if (data?.message?.includes('O CPF pertence a um menor de idade')) {
      return `${I18N_BASE_PATH}.columnLabel.hasMinorAge.true`;
    }

    if (data?.birthDate) {
      const year = new Date().getFullYear();
      const birthYear = new Date(
        data?.birthDate.split('/').reverse().join('-')
      ).getFullYear();

      if (year - birthYear < 18) {
        return `${I18N_BASE_PATH}.columnLabel.hasMinorAge.true`;
      }
    }

    return `${I18N_BASE_PATH}.columnLabel.hasMinorAge.false`;
  }, [data]);

  const hasObitIndication = useMemo(() => {
    let hasObitIndication;

    if (data?.name) {
      hasObitIndication = `${I18N_BASE_PATH}.columnLabel.hasObitIndication.false`;
    }

    if (data?.hasObitIndication) {
      return `${I18N_BASE_PATH}.columnLabel.hasObitIndication.true`;
    }

    return hasObitIndication;
  }, [data]);

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_file"
      title={translate(
        'components.dataSourceCards.basicInfos.components.basicInfosCard.sources.pfCpfData'
      )}
      dataIndex="basicData"
      toPrint={toPrint}
    >
      <Row className="pdd-top-10 ">
        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.name.title`)}
        >
          <span className="text-dark">{data?.name || '-'}</span>
        </ColumnLabel>

        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.birthDate.title`)}
        >
          <span className="text-dark">{parsedBirthDate}</span>
        </ColumnLabel>

        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.hasMinorAge.title`)}
        >
          <span className="text-dark">{translate(hasMinorAge)}</span>
        </ColumnLabel>
        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.hasObitIndication.title`)}
        >
          <span className="text-dark">{translate(hasObitIndication) || '-'}</span>
        </ColumnLabel>

        <ColumnLabel
          title={translate(`${I18N_BASE_PATH}.columnLabel.status.title`)}
          description={translate(translatedMessage?.description)}
          type={translatedMessage?.type}
          span={8}
        />
      </Row>
    </DataSourceCardCollapsePanel>
  );
};
