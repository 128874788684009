import React, { createContext, useCallback, useContext, useState } from 'react';

import { useFetch } from 'services/hooks';

const CompanyContext = createContext({});

const CompanyProvider = ({ children }) => {
  const {
    data: company,
    loading: loadingCompany,
    get,
    clearData: clearCompany,
  } = useFetch();

  const [reloadCompany, setReloadCompany] = useState(false);

  const loadCompany = useCallback(
    async ({ companyId, clearData }) => {
      await get({
        url: `companies/${companyId}`,
        clearData,
      });

      setReloadCompany(false);
    },
    [get]
  );

  const refreshCompany = useCallback(() => {
    setReloadCompany(true);
  }, []);

  return (
    <CompanyContext.Provider
      value={{
        company: company?.data,
        loadingCompany,
        reloadCompany,
        loadCompany,
        refreshCompany,
        clearCompany,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

function useCompany() {
  const context = useContext(CompanyContext);

  if (!context) {
    throw new Error('useCompany must be used within an CompanyContext');
  }

  return context;
}

export { CompanyProvider, useCompany };
