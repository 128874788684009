import React, { createContext, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ptBRAnt from 'antd/es/locale/pt_BR';
import enUSAnt from 'antd/es/locale/en_US';
import esMXAnt from 'antd/es/locale/es_ES';

import {
  isAfter,
  format as formatDateFns,
  formatDistance,
  formatISO,
  parseISO,
  differenceInDays,
} from 'date-fns';
import ptBRDateFns from 'date-fns/locale/pt-BR';
import enUSDateFns from 'date-fns/locale/en-US';
import esDateFns from 'date-fns/locale/es';

import validateMessages from 'utils/formValidateMessages';

import { i18nDateFormats, i18nCurrencyFormats } from 'i18n/utils/i18nFormats';

const I18nFormattersContext = createContext({});

const I18nFormattersProvider = ({ children }) => {
  const { t: translate, i18n } = useTranslation();

  const i18nSetAntFormValidateMessages = useCallback(() => {
    switch (i18n?.language) {
      case 'pt-BR':
        return validateMessages['pt-BR'];

      case 'en-US':
        return validateMessages['en-US'];

      case 'es-MX':
        return validateMessages['es-MX'];

      default:
        return validateMessages['pt-BR'];
    }
  }, [i18n]);

  const i18nSetAntLocale = useCallback(() => {
    switch (i18n?.language) {
      case 'pt-BR':
        return ptBRAnt;

      case 'en-US':
        return enUSAnt;

      case 'es-MX':
        return esMXAnt;

      default:
        return ptBRAnt;
    }
  }, [i18n]);

  const i18nGetDateFnsLocale = useCallback(() => {
    switch (i18n?.language) {
      case 'pt-BR':
        return ptBRDateFns;

      case 'en-US':
        return enUSDateFns;

      case 'es-MX':
        return esDateFns;

      default:
        return ptBRDateFns;
    }
  }, [i18n]);

  const i18nFormatDate = useCallback(
    (date, _format, _default) => {
      try {
        const dateFormatted = formatDateFns(
          new Date(date),
          i18nDateFormats[_format][i18n?.language] ||
            i18nDateFormats.default[i18n?.language],
          { locale: i18nGetDateFnsLocale() }
        );

        return dateFormatted;
      } catch (error) {
        return _default || '-';
      }
    },
    [i18n, i18nGetDateFnsLocale]
  );

  const i18nFormatDateOnly = useCallback(
    (date, _format, _default) => {
      try {
        const dt = new Date(date);
        const dtOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
        const dateFormatted = formatDateFns(
          dtOnly,
          i18nDateFormats[_format][i18n?.language] ||
            i18nDateFormats.default[i18n?.language],
          { locale: i18nGetDateFnsLocale() }
        );

        return dateFormatted;
      } catch (error) {
        return _default || '-';
      }
    },
    [i18n, i18nGetDateFnsLocale]
  );

  const i18nFromToDate = useCallback(
    (startDate, endDate, _default) => {
      try {
        if (!startDate && !endDate)
          return (
            _default ||
            translate('utils.customDate.fromToDate.hasNeitherStartDateEitherEndDate')
          );

        const _isAfter = isAfter(new Date(endDate), new Date());

        const _isAfterData = {
          before: translate('utils.customDate.fromToDate._isAfter.before'),
          inside: translate('utils.customDate.fromToDate._isAfter.inside'),
          after: translate('utils.customDate.fromToDate._isAfter.after'),
        };

        const defaultData = {
          before: translate('utils.customDate.fromToDate.default.before'),
          inside: translate('utils.customDate.fromToDate.default.inside'),
          after: translate('utils.customDate.fromToDate.default.after'),
          thisMoment: translate('utils.customDate.fromToDate.default.thisMoment'),
        };

        if (_isAfter) {
          return `${_isAfterData.before} ${i18nFormatDateOnly(
            startDate,
            1,
            _isAfterData.inside
          )} ${_isAfterData.after}`;
        }

        return `${defaultData.before} ${i18nFormatDateOnly(
          startDate,
          1,
          defaultData.inside
        )} ${defaultData.after} ${i18nFormatDateOnly(
          endDate,
          1,
          defaultData.thisMoment
        )}`;
      } catch (error) {
        return _default || '-';
      }
    },
    [i18nFormatDateOnly, translate]
  );

  const i18nRelativeUpdate = useCallback(
    (date) => {
      try {
        if (!date) return null;

        const currentDate = parseISO(formatISO(new Date()));
        const dateToCompare = parseISO(formatISO(new Date(date)));

        const difference = differenceInDays(currentDate, dateToCompare);

        if (difference === 1) {
          return translate(
            'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.relativeUpdate.updatedYesterday'
          );
        }

        if (difference > 1 && difference < 60) {
          return translate(
            'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.relativeUpdate.updatedSoManyDaysAgo',
            { difference }
          );
        }

        if (difference > 60) {
          return `${translate(
            'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.relativeUpdate.updated'
          )} ${formatDistance(dateToCompare, currentDate, {
            locale: i18nGetDateFnsLocale(),
            addSuffix: true,
          })}`;
        }

        return translate(
          'pages.private.profiles.components.dataSourceCards.legalInformation.components.processes.utils.relativeUpdate.updatedToday'
        );
      } catch (error) {
        return '-';
      }
    },
    [i18n, i18nGetDateFnsLocale, translate]
  );

  const i18nFormatCurrency = useCallback(
    (value, language, currency = 'BRL', overrideOptions = {}) => {
      if (!value && value !== 0) return '';

      const formater = new Intl.NumberFormat(language || i18n?.language, {
        style: 'currency',
        currency,
        ...overrideOptions,
      });

      return formater.format(parseFloat(value));
    },
    [i18n]
  );

  const i18nFormatNumber = useCallback(
    (value, language) => {
      if (!value && value !== 0) return '';

      const formater = new Intl.NumberFormat(language || i18n?.language);

      return formater.format(parseFloat(value));
    },
    [i18n]
  );

  const i18nNumberToFormattedString = (value) =>
    `${i18nCurrencyFormats[i18n?.language]} ${
      value
        ? value.toLocaleString(i18n?.language, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : ''
    }`;

  return (
    <I18nFormattersContext.Provider
      value={{
        i18nSetAntFormValidateMessages,
        i18nSetAntLocale,
        i18nGetDateFnsLocale,
        i18nFormatDate,
        i18nFormatDateOnly,
        i18nFromToDate,
        i18nRelativeUpdate,
        i18nFormatCurrency,
        i18nNumberToFormattedString,
        i18nFormatNumber,
      }}
    >
      {children}
    </I18nFormattersContext.Provider>
  );
};

function useI18nFormatters() {
  const context = useContext(I18nFormattersContext);

  if (!context) {
    throw new Error('useI18nFormatters must be used within an I18nFormattersContext');
  }

  return context;
}

export { I18nFormattersProvider, useI18nFormatters };
