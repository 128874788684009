import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col, Button, Divider } from 'antd';

import EmptyState from 'components/EmptyState';

import ContactAndRelationCard from 'pages/private/Profiles/components/ContactAndRelationCard';

import verifySourcesProcessing from 'utils/verifySourcesProcessing';

import BasicInfosCard from '../BasicInfosCard';

const ContactContent = ({ person = {}, toPrint }) => {
  const { t: translate } = useTranslation();

  const [isPhonesPanelOpened, setIsPhonesPanelOpened] = useState(false);
  const [isEmailsPanelOpened, setIsEmailsPanelOpened] = useState(false);

  const phones = useMemo(() => {
    if (!person) return undefined;

    const phonesData = person?.contact?.phones || person?.sources?.pfPhones?.data;

    if (!phonesData) return 'no-recurrence';
    if (phonesData && !phonesData?.items && !phonesData) return 'query-failed';
    if (phonesData && !phonesData?.items?.length > 0 && !phonesData?.length > 0)
      return 'no-data';

    return phonesData?.items || phonesData || [];
  }, [person]);

  const emails = useMemo(() => {
    if (!person) return undefined;

    const emailsData = person?.contact?.emails || person?.sources?.pfEmails?.data;

    if (!emailsData) return 'no-recurrence';
    if (emailsData && !emailsData?.items && !emailsData) return 'query-failed';
    if (emailsData && !emailsData?.items?.length > 0 && !emailsData?.length > 0)
      return 'no-data';

    return emailsData?.items || emailsData || [];
  }, [person]);

  const formattedData = useMemo(() => {
    const contact = person?.contact;

    if (!contact) {
      return {
        status: person?.status,
      };
    }

    return {
      status: person?.status,
      contacts: {
        ...(contact?.emails || person?.sources?.pfEmails?.data || {}),
        ...(contact?.phones || person?.sources?.pfPhones?.data || {}),
        lastConsultation:
          person?.contact?.phones?.lastConsultation ||
          person?.contact?.emails?.lastConsultation ||
          person?.sources?.pfPhones?.data?.lastConsultation ||
          person?.sources?.pfEmails?.data?.lastConsultation,
      },
    };
  }, [person]);

  const onClickHandler = useCallback((param) => {
    if (param === 'phones') {
      setIsPhonesPanelOpened((oldState) => !oldState);
    }
    if (param === 'emails') {
      setIsEmailsPanelOpened((oldState) => !oldState);
    }
  }, []);

  const parsedPhonesList = useMemo(() => {
    if (!Array.isArray(phones) || !phones?.length > 0) return <></>;

    const el = [];
    const _size = phones?.length;

    if (_size) {
      for (let i = 0; i < _size; i += 3) {
        el.push(
          <Row className="contact-data-row">
            {phones?.slice(i, i + 3)?.map((item) => (
              <ContactAndRelationCard
                key={item?.number}
                span={8}
                type={item?.type}
                content={`(${item?.areaCode || '-'}) ${item?.number || '-'}`}
                className="pdd-vertical-10 pdd-horizontal-0"
              />
            ))}
          </Row>
        );
      }
    }

    if (el.length > 0) {
      const parsedEl = isPhonesPanelOpened || toPrint ? el : el.splice(0, 1);
      return parsedEl;
    }

    return <></>;
  }, [phones, isPhonesPanelOpened, toPrint]);

  const parsedEmailsList = useMemo(() => {
    if (!Array.isArray(emails) || !emails?.length > 0) return <></>;

    const el = [];
    const _size = emails?.length;

    if (_size) {
      for (let i = 0; i < _size; i += 2) {
        el.push(
          <Row className="contact-data-row" style={{ flexDirection: 'column' }}>
            {emails?.slice(i, i + 2)?.map((item) => (
              <ContactAndRelationCard
                key={item?.number}
                span={12}
                content={item?.email || '-'}
                type={item?.type}
                className="pdd-vertical-10 pdd-horizontal-0"
              />
            ))}
          </Row>
        );
      }
    }

    if (el.length > 0) {
      const parsedEl = isEmailsPanelOpened || toPrint ? el : el.splice(0, 1);
      return parsedEl;
    }

    return <></>;
  }, [emails, isEmailsPanelOpened, toPrint]);

  const isProcessingPhones = useMemo(() => verifySourcesProcessing(person, 'phones'), [
    person,
  ]);

  const isProcessingEmails = useMemo(() => verifySourcesProcessing(person, 'emails'), [
    person,
  ]);

  return (
    <BasicInfosCard
      icon="caf-ic_contact_"
      title={translate(
        'pages.private.profiles.components.tabs.components.basicInfos.components.person.contactContent.contactContent.basicInfosCard.title'
      )}
      showDivider={toPrint}
      data={formattedData}
      dataIndex="contacts"
      customQuery={false}
      customId="basic-infos-contacts"
    >
      <Row className="contact-data-container gx-w-100">
        <Col span={24} className="contact-data-col">
          <p className="contact-section-title">
            {translate(
              'pages.private.profiles.components.tabs.components.basicInfos.components.person.contactContent.contactContent.phones.title'
            )}
          </p>

          {/* {phones === 'no-recurrence' && (
            <EmptyState
              type="empty-recurrence"
              hiddenAction={toPrint}
              urlId={person?.recurrenceModel?._id}
            />
          )} */}

          {phones === 'no-recurrence' && isProcessingPhones && (
            <EmptyState type="processing" />
          )}

          {phones === 'no-recurrence' && !isProcessingPhones && (
            <EmptyState type="waiting_consult" />
          )}

          {phones === 'no-data' && (
            <EmptyState
              type="empty-success"
              description={translate(
                'pages.private.profiles.components.tabs.components.basicInfos.components.person.contactContent.contactContent.phones.emptyState.description.noData'
              )}
            />
          )}

          {phones === 'query-failed' && (
            <EmptyState
              type="failed"
              description={translate(
                'pages.private.profiles.components.tabs.components.basicInfos.components.person.contactContent.contactContent.phones.emptyState.description.queryFailed'
              )}
            />
          )}

          {parsedPhonesList}

          {!toPrint && Array.isArray(phones) && phones.length > 3 && (
            <Row className="mrg-top-5 reset-mrg">
              <Button
                type="link"
                className="no-mrg no-pdd"
                onClick={() => onClickHandler('phones')}
              >
                <i
                  className={`mrg-right-5
                    caf-ic_${isPhonesPanelOpened ? 'close' : 'plus'}`}
                />
                {translate(
                  'pages.private.profiles.components.tabs.components.basicInfos.components.person.contactContent.contactContent.phones.button'
                )}{' '}
                ({phones?.length - 3})
              </Button>
            </Row>
          )}

          {!!parsedEmailsList && <Divider />}
        </Col>

        <Col span={24} className="contact-data-col">
          <p className="contact-section-title">
            {translate(
              'pages.private.profiles.components.tabs.components.basicInfos.components.person.contactContent.contactContent.emails.title'
            )}
          </p>

          {/* {emails === 'no-recurrence' && (
            <EmptyState
              type="empty-recurrence"
              hiddenAction={toPrint}
              urlId={person?.recurrenceModel?._id}
            />
          )} */}

          {emails === 'no-recurrence' && isProcessingEmails && (
            <EmptyState type="processing" />
          )}

          {emails === 'no-recurrence' && !isProcessingEmails && (
            <EmptyState type="waiting_consult" />
          )}

          {emails === 'no-data' && (
            <EmptyState
              type="failed"
              description={translate(
                'pages.private.profiles.components.tabs.components.basicInfos.components.person.contactContent.contactContent.emails.emptyState.description.noData'
              )}
            />
          )}

          {emails === 'query-failed' && (
            <EmptyState
              type="failed"
              description={translate(
                'pages.private.profiles.components.tabs.components.basicInfos.components.person.contactContent.contactContent.emails.emptyState.description.queryFailed'
              )}
            />
          )}

          {parsedEmailsList}

          {!toPrint && Array.isArray(emails) && emails.length > 2 && (
            <Row className="mrg-top-5 reset-mrg">
              <Button
                type="link"
                className="no-mrg no-pdd"
                onClick={() => onClickHandler('emails')}
              >
                <i
                  className={`mrg-right-5
                    caf-ic_${isEmailsPanelOpened ? 'close' : 'plus'}`}
                />
                {translate(
                  'pages.private.profiles.components.tabs.components.basicInfos.components.person.contactContent.contactContent.emails.button'
                )}{' '}
                ({emails?.length - 2})
              </Button>
            </Row>
          )}
        </Col>
      </Row>
    </BasicInfosCard>
  );
};

ContactContent.propTypes = {
  person: PropTypes.shape({
    basicData: PropTypes.shape({
      phoneNumber: PropTypes.string,
      companyEmail: PropTypes.string,
    }),
    contact: PropTypes.shape({
      phones: PropTypes.shape({
        lastConsultation: PropTypes.objectOf(),
      }),
      emails: PropTypes.shape({
        lastConsultation: PropTypes.objectOf(),
      }),
    }),
    status: PropTypes.string,
  }),
  toPrint: PropTypes.bool,
};

ContactContent.defaultProps = {
  person: undefined,
  toPrint: false,
};

export default ContactContent;
