/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';

import EmptyState from 'components/EmptyState';
import EmptyExecutionState from 'components/EmptyExecutionState';

import ProcessesList from '../../Lists/ProcessesList';
import './styles.less';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.legalInformation.componentes.processes.index.processes';

const ProcessesWithKeyWords = ({
  source = {},
  type,
  toPrint = false,
  hasPersonProcesses,
}) => {
  const { t: translate } = useTranslation();

  const processesWithKeyWords = useMemo(() => {
    if (toPrint) {
      source?.data?.results?.map((result) => {
        if (result?.actives?.length > 30) {
          result.actives = result.actives.slice(0, 30);
          result.isMoreThan30 = true;
          return result;
        }
        return result;
      });
    }

    return source.data;
  }, [source, toPrint]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const processesQuerySucceeded = useMemo(
    () =>
      source?.statusCode === '01' ||
      source?.available === true ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [source]
  );

  const hasContent = useMemo(() => processesWithKeyWords?.results?.length > 0, [
    processesWithKeyWords,
  ]);

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_file"
      title={translate(`${I18N_BASE_PATH}.processesWithKeyWordsTitle`)}
      toPrint={toPrint}
    >
      <div id="executions-legal-information-processes-container">
        <Col span={24}>
          {ProcessesWithKeyWords && (
            <>
              {processesQuerySucceeded ? (
                <>
                  {hasContent ? (
                    <ProcessesList
                      data={processesWithKeyWords?.results}
                      toPrint={toPrint}
                      displayValue={3}
                      hasPersonProcesses={hasPersonProcesses}
                    />
                  ) : (
                    <EmptyState
                      type="empty-success"
                      description={translate(
                        `${I18N_BASE_PATH}.processesAltQuerySucceeded.emptyState.description`,
                        { type }
                      )}
                      className="mrg-btm-20 mrg-top-0"
                    />
                  )}
                </>
              ) : (
                <EmptyExecutionState
                  statusCode={statusCode}
                  message={source?.message || processesWithKeyWords?.message}
                />
              )}
            </>
          )}
        </Col>
      </div>
    </DataSourceCardCollapsePanel>
  );
};

export default ProcessesWithKeyWords;
