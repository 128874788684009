import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown } from 'antd';

import { hasRequiredPermissions } from 'routes/permissionFunctions';
import PermissionWrapper from 'routes/PermissionWrapper';
import { useAuth } from 'hooks/auth';

import useRemoveToken from './RemoveToken';

const ActionsList = ({ data, refreshListRef }) => {
  const { t: translate } = useTranslation();
  const { openModal: openModalRemove, RemoveTokenModal } = useRemoveToken({
    data,
    refreshListRef,
  });
  const { openModal: openModalEdit, EditTokenModal } = useRemoveToken({
    data,
    refreshListRef,
  });
  const { user } = useAuth();

  return (
    <>
      {RemoveTokenModal}
      {EditTokenModal}
      <PermissionWrapper
        requiredPermissions={['access-keys:delete']}
        product="management"
      >
        <Dropdown
          overlay={
            <Menu className="pdd-vertical-5">
              {hasRequiredPermissions(
                user,
                ['access-keys:delete'],
                [],
                false,
                'management'
              ) && (
                <>
                  <Menu.Item
                    key="deleteKey"
                    className="text-danger"
                    onClick={openModalRemove}
                  >
                    <i className="caf-ic_trash mrg-right-10" />
                    <span className="text-semibold">
                      {translate(
                        'pages.private.accessKeys.components.accessKeysCard.components.actionsList.delete'
                      )}
                    </span>
                  </Menu.Item>
                </>
              )}
              {hasRequiredPermissions(
                user,
                ['access-keys:edit'],
                [],
                false,
                'management'
              ) && (
                <>
                  <Menu.Item
                    key="editKey"
                    className="text-danger"
                    onClick={openModalEdit}
                  >
                    <i className="caf-ic_edit mrg-right-10" />
                    <span className="text-semibold">
                      {translate(
                        'pages.private.accessKeys.components.accessKeysCard.components.actionsList.edit'
                      )}
                    </span>
                  </Menu.Item>
                </>
              )}
            </Menu>
          }
          trigger={['click']}
          placement="bottomRight"
        >
          <div className="btn-more-icon btn-more-icon-hover">
            <i className="caf-ic_more-vertical" />
          </div>
        </Dropdown>
      </PermissionWrapper>
    </>
  );
};

export default ActionsList;
