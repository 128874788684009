/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Spin } from 'antd';

import { isEmpty } from 'utils/isObjectEmpty';
import { toFormatPercentage } from 'utils/formatters';

import DossierCard from 'pages/private/Profiles/components/DossierCard';
import TableInfoCard from 'pages/private/Profiles/components/TableInfoCard/Column';

import { genderTranslate } from '../../utils/translations';

import ValidationsImagesCard from '../Shared/ValidationsImagesCard';
import EmptyDocuments from '../Shared/EmptyDocuments';

const VerifiedIdentity = ({ data, loading, status }) => {
  const { t: translate } = useTranslation();

  const statusDetails = useMemo(() => {
    if (!status) return null;

    switch (status?.toUpperCase()) {
      case 'VALID':
        return {
          type: 'primary',
          icon: 'caf-ic_checkmark',
          default: translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.verifiedIdentity.index.statusDetails.valid'
          ),
        };
      case 'INVALID':
        return {
          type: 'danger',
          icon: 'caf-ic_close',
          default: translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.verifiedIdentity.index.statusDetails.invalid'
          ),
        };
      default:
        return null;
    }
  }, [status, translate]);

  return (
    <DossierCard
      title={translate(
        'pages.private.profiles.components.tabs.components.documentsValidations.components.verifiedIdentity.index.verifiedIdentity.dossierCard.title'
      )}
      subtitle={translate(
        'pages.private.profiles.components.tabs.components.documentsValidations.components.verifiedIdentity.index.verifiedIdentity.dossierCard.subtitle'
      )}
      icon="caf-ic_user_active"
      customId="verified-document-card"
    >
      {loading && (
        <div className="custom-document-loading">
          <Spin />
        </div>
      )}

      {!loading && (
        <>
          {(!data || isEmpty(data)) && <EmptyDocuments type="verified-identity" />}

          {data && !isEmpty(data) && (
            <>
              {status && (
                <Row
                  className={`document-validation-status reset-mrg ${statusDetails?.type}`}
                >
                  <i className={statusDetails?.icon} />{' '}
                  <span>
                    {translate(data?.statusMessage, { value: data?.value }) ||
                      statusDetails?.default}
                  </span>
                </Row>
              )}

              <ValidationsImagesCard
                firstImageDescription={translate(
                  'pages.private.profiles.components.tabs.components.documentsValidations.components.verifiedIdentity.index.verifiedIdentity.validationsImagesCard.firstImageDescription'
                )}
                firstImage={data?.front}
                secondImageDescription={translate(
                  'pages.private.profiles.components.tabs.components.documentsValidations.components.verifiedIdentity.index.verifiedIdentity.validationsImagesCard.secondImageDescription'
                )}
                secondImage={data?.selfie}
                customTop={status ? '15px' : '25px'}
              />

              <Row className="custom-validations-title reset-mrg">
                <strong>
                  {translate(
                    'pages.private.profiles.components.tabs.components.documentsValidations.components.verifiedIdentity.index.verifiedIdentity.title'
                  )}
                </strong>
              </Row>

              <Row gutter={[32, 16]} className="custom-validations-data">
                <TableInfoCard
                  span={6}
                  title={translate(
                    'pages.private.profiles.components.tabs.components.documentsValidations.components.verifiedIdentity.index.verifiedIdentity.tableInfoCard.identical.title'
                  )}
                  content={translate(
                    `pages.private.profiles.components.tabs.components.documentsValidations.components.verifiedIdentity.index.verifiedIdentity.tableInfoCard.identical.content.${
                      data?.facematch?.identical ? 'yes' : 'no'
                    }`
                  )}
                  bordered={false}
                />
                <TableInfoCard
                  span={6}
                  title={translate(
                    'pages.private.profiles.components.tabs.components.documentsValidations.components.verifiedIdentity.index.verifiedIdentity.tableInfoCard.confidence.title'
                  )}
                  content={toFormatPercentage(data?.facematch?.confidence, translate)}
                  bordered={false}
                />
                <TableInfoCard
                  span={6}
                  title={translate(
                    'pages.private.profiles.components.tabs.components.documentsValidations.components.verifiedIdentity.index.verifiedIdentity.tableInfoCard.gender.title'
                  )}
                  content={translate(genderTranslate(data?.facematch?.gender))}
                  bordered={false}
                />
                <TableInfoCard
                  span={6}
                  title={translate(
                    'pages.private.profiles.components.tabs.components.documentsValidations.components.verifiedIdentity.index.verifiedIdentity.tableInfoCard.age.title'
                  )}
                  content={
                    data?.facematch?.ageRange &&
                    translate(
                      'pages.private.profiles.components.tabs.components.documentsValidations.components.verifiedIdentity.index.verifiedIdentity.tableInfoCard.age.content',
                      { ageRange: data?.facematch?.ageRange }
                    )
                  }
                  bordered={false}
                />
              </Row>
            </>
          )}
        </>
      )}
    </DossierCard>
  );
};

export default VerifiedIdentity;
