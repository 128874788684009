import React, { useCallback } from 'react';
import { Col, Row, Spin, Form } from 'antd';
import { StringParam, useQueryParams } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import Button from 'components/Button';
import { useComponentList } from 'components/List';
import SearchInput from 'components/Form/SearchInput';

import useModalTagUpdate from '../Modals/ModalTagUpdate';
import TagCard from '../TagCard';

const I18N_BASE_PATH = 'pages.private.tagsAndReasons.components.TagsList';

function TagList() {
  const { t: translate } = useTranslation();
  const [query] = useQueryParams({
    _search: StringParam,
  });

  const { openModal: openUpdateTagModal, TagUpdateModal } = useModalTagUpdate();

  const { componentList, loading, setFilterData, refreshList } = useComponentList({
    component: TagCard,
    rowKey: '_id',
    getParams: {
      url: '/tags',
      config: {
        params: {},
      },
    },
    queryParams: {
      _search: StringParam,
    },
  });

  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        ...values,
        _search: values._search ? values._search.trim() : undefined,
      });
    }, 500),
    []
  );

  return loading ? (
    <Row align="middle" justify="center">
      <Spin />
    </Row>
  ) : (
    <>
      {TagUpdateModal}
      <Row align="middle" justify="end">
        <Col className="flex end flex-1">
          <Form
            layout="inline"
            onValuesChange={handleFormChange}
            initialValues={{ _search: query?._search }}
          >
            <SearchInput
              name="_search"
              placeholder={translate(`${I18N_BASE_PATH}.searchInputPlaceholder`)}
              style={{ minWidth: 350 }}
            />
          </Form>
        </Col>
        <Col span={3}>
          <Button
            className="flex center"
            onClick={() => openUpdateTagModal({ refreshList, isNew: true })}
          >
            <span>{translate(`${I18N_BASE_PATH}.createButtonLabel`)}</span>
            <i className="caf-ic_add mrg-left-10" />
          </Button>
        </Col>
      </Row>
      <div>{componentList}</div>
    </>
  );
}

export default TagList;
