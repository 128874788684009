/* eslint-disable no-unused-expressions */
import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';
import { Collapse, Form, Input, Checkbox, Spin } from 'antd';

import { useTags } from 'hooks/tags';

import EmptyMessage from 'components/EmptyMessage';
import ViewMore from 'components/ViewMore';
import SpecialTag from 'components/Tag/SpecialTag';

const { Panel } = Collapse;

const TagsFilter = ({ value, onChange }) => {
  const { t: translate } = useTranslation();

  const [form] = Form.useForm();

  const firstLoad = useRef(true);

  const {
    loadAllTags: get,
    fillCustomAllTags,
    allTagsList: data,
    loadingAllTags: loading,
  } = useTags();

  const [allData, setAllData] = useState([]);

  const [searchValue, setSearchValue] = useState(null);
  const [auxSearchValue, setAuxSearchValue] = useState(null);
  const [allTagsVisible, setAllTagsVisible] = useState(false);
  const [hasSearchChange, setHasSearchChange] = useState(false);

  const fetch = useCallback(
    async ({ ALL_DATA }) => {
      const response = await get(searchValue, hasSearchChange);

      if (ALL_DATA) {
        setAllData(response);
        fillCustomAllTags(response);
      }
    },
    [get, searchValue, hasSearchChange, fillCustomAllTags]
  );

  const onTagSearch = useCallback(
    _.debounce(async (__, values) => {
      setHasSearchChange(true);
      setAuxSearchValue(values.tags);

      if (values.tags.trim().length < 2) {
        setSearchValue(null);
        return;
      }

      setSearchValue(values.tags);
    }, 500),
    [get]
  );

  const refreshField = useCallback(() => {
    if (searchValue) {
      setSearchValue(null);
      setAuxSearchValue(null);
      form.setFieldsValue({ tags: '' });
    }
  }, [searchValue, form]);

  const hasData = useMemo(() => {
    if (hasSearchChange) return data?.length > 0;
    return allData?.length > 0;
  }, [data, allData, hasSearchChange]);

  useEffect(() => {
    if (hasSearchChange) {
      fetch({ ALL_DATA: false });
    }
  }, [searchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!firstLoad.current) return;
    firstLoad.current = false;

    fetch({ ALL_DATA: true });
  }, [fetch]);

  const showSearch = useMemo(() => {
    if (!loading) {
      if (hasData) return true;

      if (!hasData) {
        if (auxSearchValue) return true;
        if (!auxSearchValue) return false;
      }
    }
    if (loading && hasSearchChange && auxSearchValue) return true;

    return false;
  }, [loading, hasData, hasSearchChange, auxSearchValue]);

  return (
    <Collapse
      defaultActiveKey={['tags']}
      ghost
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <div>
          <i className={isActive ? 'caf-ic_arrow_up' : 'caf-ic_arrow_down'} />
        </div>
      )}
      className="collapse-tags-filter"
    >
      <Panel
        header={translate('components.filters.tagsFilter.collapse.panel.header')}
        key="tags"
      >
        {showSearch && (
          <Form form={form} layout="vertical" onValuesChange={onTagSearch}>
            <Form.Item name="tags">
              <Input
                className="custom-search-input"
                placeholder={translate(
                  'components.filters.tagsFilter.collapse.panel.form.tags.input.placeholder'
                )}
                value={searchValue}
                suffix={<i className="caf-ic_search" />}
              />
            </Form.Item>
          </Form>
        )}

        {loading && (
          <div className="checkbox-tags-loading">
            <Spin indicator={<LoadingOutlined />} />
          </div>
        )}

        {!loading && !hasData && searchValue && (
          <div className="checkbox-empty-state">
            <EmptyMessage
              type="search"
              // description={`Nenhuma etiqueta com "${searchValue}" encontrada.`}
              description={translate(
                'components.filters.tagsFilter.collapse.panel.emptyMessage.description.hasNotDataAndHasSearchValue',
                { searchValue: `"${searchValue}"` }
              )}
            />
          </div>
        )}

        {!loading && !hasData && !searchValue && (
          <div className="checkbox-empty-state">
            <EmptyMessage
              type="empty"
              description={translate(
                'components.filters.tagsFilter.collapse.panel.emptyMessage.description.hasNotDataAndHasNotSearchValue'
              )}
            />
          </div>
        )}

        {!loading && hasData && (
          <div className="checkbox-tags-filter">
            <Checkbox.Group
              className="checkbox-tags-group"
              onChange={(values) => {
                refreshField();
                onChange(values);
              }}
              value={value}
            >
              {allData?.map((item) => {
                const includes = data?.find((tag) => tag._id === item._id);
                const index = data?.findIndex((tag) => tag._id === item._id);

                const checkboxClass =
                  ((!allTagsVisible && index >= 7) || !includes) && 'hidden';

                return (
                  <Checkbox key={item._id} value={item._id} className={checkboxClass}>
                    <span className="max-lines-1">
                      {item.title?.toUpperCase() || '-'}
                    </span>
                    {item.special && <SpecialTag description={item.actionDescription} />}
                  </Checkbox>
                );
              })}
            </Checkbox.Group>

            {data?.length > 7 && (
              <div className="checkbox-tags-filter-btn">
                <ViewMore
                  title={
                    !allTagsVisible
                      ? translate(
                          'components.filters.tagsFilter.collapse.panel.viewMore.title.allTagsIsVisible'
                        )
                      : translate(
                          'components.filters.tagsFilter.collapse.panel.viewMore.title.allTagsIsNotVisible'
                        )
                  }
                  icon={!allTagsVisible ? 'caf-ic_plus' : 'caf-ic_minimize'}
                  onClick={() => setAllTagsVisible((oldState) => !oldState)}
                />
              </div>
            )}
          </div>
        )}
      </Panel>
    </Collapse>
  );
};

TagsFilter.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TagsFilter;
