/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import DossierCard from 'pages/private/Profiles/components/DossierCard';

import PermissionWrapper from 'routes/PermissionWrapper';

import SourcesList from './Lists/SourcesList';
import OwnersList from './Lists/OwnersList';

const RestrictiveLists = ({ data, toPrint, toPrintDetails }) => {
  const { t: translate } = useTranslation();

  const { companyId } = useParams();

  const dataSources = useMemo(
    () =>
      data?.profileTemplate?.backgroundChecking?.services?.map(({ source }) => source) ||
      [],
    [data]
  );

  const showKycCompliance = useMemo(
    () =>
      dataSources.includes(companyId ? 'pj_kyc_compliance' : 'pf_kyc_compliance') ||
      data?.others?.kycCompliance,
    [companyId, data, dataSources]
  );

  const showKycComplianceOwners = useMemo(
    () =>
      dataSources.includes('pj_kyc_compliance_owners') ||
      data?.others?.kycComplianceOwners,
    [data, dataSources]
  );

  return (
    <DossierCard
      title={translate(
        'pages.private.profiles.components.dataSourceCards.restrictiveLists.index.restrictiveLists.dossierCard.title'
      )}
      subtitle={translate(
        'pages.private.profiles.components.dataSourceCards.restrictiveLists.index.restrictiveLists.dossierCard.subtitle'
      )}
      icon="caf-ic_lawier"
    >
      <div className="gx-w-100 no-mrg no-pdd">
        {companyId ? (
          <>
            {showKycComplianceOwners && (
              <PermissionWrapper
                requiredPermissions={['companies:read']}
                requiredResources={['others:kycComplianceOwners:*']}
              >
                <OwnersList
                  profile={data}
                  /* urlId={emptyStateUrl} */
                  /* toPrint={toPrint} */
                />
              </PermissionWrapper>
            )}

            {showKycCompliance && (
              <PermissionWrapper
                requiredPermissions={['companies:read']}
                requiredResources={['others:kycCompliance:*']}
              >
                <SourcesList
                  profile={data}
                  dataSources={dataSources}
                  /* urlId={emptyStateUrl} */
                  toPrint={toPrint}
                  toPrintDetails={toPrintDetails}
                />
              </PermissionWrapper>
            )}
          </>
        ) : (
          <>
            {showKycCompliance && (
              <SourcesList
                personalInfo
                profile={data}
                /* urlId={emptyStateUrl} */
                // lastConsultationPersonal={kycLastConsultation}
                toPrint={toPrint}
                toPrintDetails={toPrintDetails}
              />
            )}
          </>
        )}
      </div>
    </DossierCard>
  );
};

export default RestrictiveLists;
