import { encodeObject, decodeObject } from 'use-query-params';

export const UnderscoreObjectParam = {
  encode: (obj) => encodeObject(obj, '->', '_'),
  decode: (objStr) => decodeObject(objStr, '->', '_'),
};

export const HashObjectParam = {
  encode: (obj) => encodeObject(obj, '->', '#'),
  decode: (objStr) => decodeObject(objStr, '->', '#'),
};
