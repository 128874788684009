import { useCallback, useMemo } from 'react';

const useCompanyRecords = ({ data, sourceToSearch, savedRecords }) => {
  const getSourceById = useCallback(
    (sourceId) => data.sources.find((source) => source.id === sourceId),
    [data]
  );

  const records = useMemo(
    () =>
      data.records
        .filter((record) => (sourceToSearch ? record.sourceId === sourceToSearch : true))
        .map((record) => ({
          ...record,
          source: getSourceById(record.sourceId),
          saved: savedRecords.find(
            (item) => item.data.id === encodeURIComponent(record.id)
          )?.data,
        })),
    [data, savedRecords, sourceToSearch, getSourceById]
  );

  const availableSources = useMemo(() => {
    const recordsSourcesSet = new Set(data.records.map((record) => record.sourceId));
    const recordsSources = Array.from(recordsSourcesSet);

    return data.sources.filter((source) => recordsSources.includes(source.id));
  }, [data]);

  return { availableSources, records, getSourceById };
};

export default useCompanyRecords;
