import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card, Breadcrumb } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { useAuth } from 'hooks/auth';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';
import { Calendar } from '@combateafraude/icons/general';
import { useReactToPrint } from 'react-to-print';

import BarChart from './components/Chart/BarChart';
import InvoiceDetails from './components/InvoiceDetails';
import { ExportButton } from './components/BillingHeader';
import Wrapper from '../wrapper';

import './styles.less';
import useAmberflo, { cafDetails } from './hooks/useAmberflo';
import InvoicePDF from './components/InvoicePDF';

const ConsumptionDetails = () => {
  const [details, setDetails] = useState();

  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();
  const { fetchInvoiceDetails, loadingInvoiceDetails } = useAmberflo();
  const { id } = useParams();
  const { user } = useAuth();

  const hidePrice = hasFeatureFlag('costPrices', user);
  const contentToPrint = useRef(null);

  const normalizedChartData = useMemo(() => {
    if (!details?.products)
      return {
        products: [],
        productMap: {},
        data: [],
      };

    const sortedData = details.products.sort((a, b) => a.amount - b.amount);

    return {
      products: sortedData.map(({ id }) => id),
      productMap: sortedData.reduce((acc, item) => {
        return {
          ...acc,
          [item.id]: item.name,
        };
      }, {}),
      data: sortedData.map((product) => ({
        [product.id]: {
          amount: product.amount,
          price: product.price,
        },
      })),
    };
  }, [details]);

  useEffect(() => {
    fetchInvoiceDetails(id).then(setDetails);
  }, [fetchInvoiceDetails, id]);

  const printToPdf = useReactToPrint({
    content: () => contentToPrint.current,
    bodyClass: 'print-body',
    documentTitle: translate('pages.private.consumption.details.pdf.title', {
      invoiceId: id,
    }),
  });

  const handlePrintToPdf = () => {
    if (details?.exportUrl) {
      window.open(details.exportUrl, '_blank');
    } else {
      printToPdf();
    }
  };

  return (
    <Wrapper>
      <Card>
        <div className="consumption-details">
          <div className="consumption-details__header">
            <div>
              <Breadcrumb className="consumption-details__breadcrumb">
                <Breadcrumb.Item>
                  <Link to="/consumption?tab=2">
                    {translate(
                      'pages.private.consumption.details.breadcrumb.pastInvoices'
                    )}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  {translate(
                    'pages.private.consumption.details.breadcrumb.invoiceDetails'
                  )}
                </Breadcrumb.Item>
              </Breadcrumb>
              <hgroup>
                <h2>{translate('pages.private.consumption.details.title')}</h2>
                {details?.period && (
                  <h3>
                    <Calendar aria-hidden />
                    {i18nFormatDate(details.period.start, 1)}
                    {' - '}
                    {i18nFormatDate(details.period.end, 1)}
                  </h3>
                )}
              </hgroup>
            </div>
            {!hidePrice && (
              <ExportButton
                loading={loadingInvoiceDetails}
                exportOptions={[
                  {
                    label: translate('components.exportOptions.options.pdf'),
                    onClick: handlePrintToPdf,
                  },
                ]}
                label={translate('pages.private.consumption.billingHeader.export')}
              />
            )}
          </div>
          <div>
            <BarChart
              loading={loadingInvoiceDetails}
              currencyCode={details?.currencyCode}
              hidePrice={hidePrice}
              chartData={normalizedChartData}
            />
          </div>
          <div>
            {details?.products && (
              <InvoiceDetails
                currencyCode={details.currencyCode}
                data={details.products}
                total={details?.total ?? 0}
                hidePrice={hidePrice}
              />
            )}
          </div>
        </div>
      </Card>
      {details && !details.exportUrl && !hidePrice && (
        <div
          style={{
            display: 'none',
          }}
          aria-hidden
        >
          <InvoicePDF
            printRef={contentToPrint}
            clientDetails={details.companyInfo}
            companyDetails={cafDetails}
            currencyCode={details.currencyCode}
            invoiceDetails={{
              data: details.products,
              total: details.total,
            }}
            invoiceMetadata={{
              date: details.date,
              period: {
                start: details.period.start,
                end: details.period.end,
              },
              pricingPlan: details.planName,
            }}
          />
        </div>
      )}
    </Wrapper>
  );
};

export default ConsumptionDetails;
