import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusCircleFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';

import Labelcol from '../components/Labelcol';

const I18N_BASE_PATH =
  'pages.private.executions.components.tabs.components.documentsValidations.newOcrScreen';

const CategoryInformation = ({ fields, executionData, toPrint = false }) => {
  const { t: translate } = useTranslation();
  const [viewMoreDetails, setView] = useState(toPrint);

  return (
    <>
      <Row gutter={[32, 0]} className="no-border-right-col-4">
        {fields?.map(
          (field, index) =>
            index <= 7 && (
              <Labelcol
                key={field}
                span={6}
                title={translate(
                  `${I18N_BASE_PATH}.fields.${field}`,
                  executionData?.[field]
                )}
                result={
                  executionData?.[field] ||
                  translate(`${I18N_BASE_PATH}.categoryInformation.noData`)
                }
              />
            )
        )}
        {viewMoreDetails &&
          fields
            ?.slice(8)
            .map((field) => (
              <Labelcol
                key={field}
                span={6}
                title={translate(
                  `${I18N_BASE_PATH}.fields.${field}`,
                  executionData?.[field]
                )}
                result={
                  executionData?.[field] ||
                  translate(`${I18N_BASE_PATH}.categoryInformation.noData`)
                }
              />
            ))}
      </Row>
      {fields?.length > 8 && !toPrint && (
        <Row style={{ marginBottom: '2rem' }}>
          <Col span={24} className="no-pdd no-mrg">
            <button
              type="button"
              onClick={() => {
                setView(!viewMoreDetails);
              }}
              id="more-details-button"
            >
              <PlusCircleFilled />
              <strong>
                {translate(`${I18N_BASE_PATH}.showMoreButton.${viewMoreDetails}`)}
              </strong>
            </button>
          </Col>
        </Row>
      )}
    </>
  );
};
CategoryInformation.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  executionData: PropTypes.objectOf(PropTypes.string),
  toPrint: PropTypes.bool,
};

CategoryInformation.defaultProps = {
  executionData: undefined,
  toPrint: false,
};
export default CategoryInformation;
