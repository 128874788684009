import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select, Row, Col, Divider, Alert } from 'antd';
import inputs from './inputs.json';
import InputInfo from '../InputInfo';
import { allCountries } from './utils';
import CountryFlag from '../CountryFlag';
import './styles.less';

const uploadInputs = ['selfie', 'front', 'back'];

const { Option } = Select;

const IntlAdditionalInfo = ({
  requiredInputs,
  optionalInputs,
  files,
  setFiles,
  countryCodes,
  missingFiles,
  setFilesRequired,
  form,
  children,
}) => {
  const { t: translate } = useTranslation();
  const [countrySelected, setCountrySelected] = useState(null);
  const inputsRequired = useMemo(() => {
    const rInputs = requiredInputs?.default?.concat(
      requiredInputs[countrySelected]?.default
    );
    const inputsUndefined = rInputs?.filter(
      (value) => value !== undefined && value !== null
    );
    setFilesRequired({
      front: inputsUndefined?.includes('front'),
      back: inputsUndefined?.includes('back'),
      selfie: inputsUndefined?.includes('selfie'),
    });
    return {
      inputs: inputsUndefined?.filter(
        (value, i) =>
          inputsUndefined?.indexOf(value) === i && !uploadInputs?.includes(value)
      ) || ['name', 'birthDate', 'address', 'nationalId'],
      uploads: inputsUndefined?.filter(
        (value, i) =>
          inputsUndefined?.indexOf(value) === i && uploadInputs?.includes(value)
      ),
    };
  }, [countrySelected, requiredInputs, setFilesRequired]);

  const inputsOptional = useMemo(() => {
    const oInputs = optionalInputs?.default?.concat(
      optionalInputs[countrySelected]?.default
    );
    const filteredOptional = oInputs?.filter((value, i) => oInputs?.indexOf(value) === i);
    return {
      inputs: filteredOptional?.filter(
        (value, i) =>
          value !== undefined &&
          value !== null &&
          !inputsRequired?.inputs?.includes(value) &&
          !uploadInputs?.includes(value)
      ),
      uploads: filteredOptional?.filter(
        (value, i) =>
          value !== undefined &&
          value !== null &&
          !inputsRequired?.uploads?.includes(value) &&
          uploadInputs?.includes(value)
      ),
    };
  }, [countrySelected, inputsRequired, optionalInputs]);

  const countryCodesTemp = useMemo(() => {
    if (countryCodes) {
      const countryCodesWithNoUndefined = countryCodes;
      Object.keys(countryCodes)?.forEach((cc) => {
        if (
          countryCodesWithNoUndefined[cc]?.find(
            (val) => val !== undefined && val !== null
          )
        ) {
          return;
        }
        countryCodesWithNoUndefined[cc] = allCountries;
      });
      return countryCodesWithNoUndefined;
    }
    return allCountries;
  }, [countryCodes]);

  const selectCountryCodes = useMemo(() => {
    // get object values
    const objectValues = Object.values(countryCodesTemp).reduce(
      (list, sub) => list.concat(sub),
      []
    );
    // delete duplicates
    const arrWithoutDuplicates = objectValues.filter(
      (value, i) => objectValues.indexOf(value) === i
    );
    const arrWithoutNull = arrWithoutDuplicates.filter((value) => value != null);
    let arrayCountries = [];
    if (arrWithoutNull?.length) {
      arrayCountries = arrWithoutNull?.map((a) => {
        return { value: a, label: translate(`countries.${a}`) };
      });
    } else {
      arrayCountries = allCountries?.map((a) => {
        return { value: a, label: translate(`countries.${a}`) };
      });
    }
    return arrayCountries;
  }, [countryCodesTemp, translate]);

  const serviceHasNoSupport = useMemo(() => {
    const arrayNotSupport = [];
    Object.keys(countryCodesTemp)?.forEach((service) => {
      if (!countryCodesTemp[service].includes(countrySelected)) {
        arrayNotSupport.push(service);
      }
    });
    return arrayNotSupport;
  }, [countryCodesTemp, countrySelected]);

  const servicesMapping = useMemo(() => {
    if (serviceHasNoSupport?.length === 1) {
      return serviceHasNoSupport[0];
    }
    let string = '';
    serviceHasNoSupport?.forEach((serv, index) => {
      if (index === serviceHasNoSupport?.length - 1) {
        string += ` ${translate('components.intlAdditionalInfo.index.and')} ${serv}`;
        return;
      }
      if (index === serviceHasNoSupport?.length - 2) {
        string += ` ${serv}`;
        return;
      }
      string += ` ${serv},`;
    });
    return string;
  }, [serviceHasNoSupport, translate]);
  return (
    <>
      <Row id="intl-additional-info">
        <Col span={16}>{children}</Col>
        <Col span={8}>
          <Form.Item
            name="country"
            label={translate('components.intlAdditionalInfo.index.country')}
            rules={[
              {
                required: true,
                message: translate('components.intlAdditionalInfo.index.required'),
              },
            ]}
            className="no-mrg no-pdd"
          >
            <Select
              onSelect={(value) => {
                setCountrySelected(value);
              }}
              showSearch
              disabled={!countryCodes}
              filterOption={(input, option) => {
                return option?.key
                  ?.normalize('NFD')
                  ?.replace(/[\u0300-\u036f]/g, '')
                  ?.toLowerCase()
                  .includes(
                    input
                      ?.normalize('NFD')
                      ?.replace(/[\u0300-\u036f]/g, '')
                      ?.toLowerCase()
                  );
              }}
              placeholder={translate('components.intlAdditionalInfo.index.selectCountry')}
            >
              {selectCountryCodes
                .sort((optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                )
                .map((cc) => (
                  <Option value={cc?.value} key={cc?.label}>
                    <div className="flex center">
                      <CountryFlag
                        country={cc?.value}
                        style={{ height: 15, width: 15 }}
                        className="mrg-right-5"
                      />
                      {cc?.label}
                    </div>
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {countrySelected && (
        <>
          {!!serviceHasNoSupport?.length && (
            <Alert
              className="mrg-top-10"
              type="warning"
              message={`${translate(
                'components.intlAdditionalInfo.index.alert'
              )} ${servicesMapping}`}
              showIcon
            />
          )}
          <Row>
            {!!inputsRequired?.uploads?.length && (
              <Divider className="text-bold mrg-btm-5 mrg-top-5">
                {translate('components.intlAdditionalInfo.index.requiredUploads')}
              </Divider>
            )}
            {inputsRequired?.uploads?.map((inp) => {
              return (
                <InputInfo
                  {...inputs[inp]}
                  rules={[
                    {
                      required: true,
                      message: translate('components.intlAdditionalInfo.index.required'),
                    },
                  ]}
                  country={countrySelected}
                  form={form}
                  files={files}
                  setFiles={setFiles}
                  missingFiles={missingFiles}
                />
              );
            })}
            {!!inputsOptional?.uploads?.length && (
              <Divider className="text-bold mrg-vertical-5">
                {translate('components.intlAdditionalInfo.index.optionalUploads')}
              </Divider>
            )}
            {inputsOptional?.uploads?.map((inp) => {
              return (
                <InputInfo
                  {...inputs[inp]}
                  country={countrySelected}
                  form={form}
                  files={files}
                  setFiles={setFiles}
                  missingFiles={missingFiles}
                />
              );
            })}
            {!!inputsRequired?.inputs?.length && (
              <Divider className="text-bold mrg-vertical-5">
                {translate(
                  `components.modalCreateExecution.index.CreateExecution.form.additionalInfo.title`
                )}
              </Divider>
            )}
            {inputsRequired?.inputs?.map((inp) => {
              return (
                <InputInfo
                  {...inputs[inp]}
                  rules={[
                    {
                      required: true,
                      message: translate('components.intlAdditionalInfo.index.required'),
                    },
                  ]}
                  country={countrySelected}
                  form={form}
                  files={files}
                  setFiles={setFiles}
                  missingFiles={missingFiles}
                />
              );
            })}
          </Row>
          {!!inputsOptional?.inputs?.length && (
            <Divider className="text-bold mrg-vertical-10">
              {translate('components.intlAdditionalInfo.index.optionalInputs')}
            </Divider>
          )}
          <Row>
            {inputsOptional?.inputs?.map((inp) => {
              return (
                <InputInfo
                  {...inputs[inp]}
                  country={countrySelected}
                  form={form}
                  files={files}
                  setFiles={setFiles}
                  missingFiles={missingFiles}
                />
              );
            })}
          </Row>
        </>
      )}
    </>
  );
};

export default IntlAdditionalInfo;
