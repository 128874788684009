/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import DossierCard from 'pages/private/Profiles/components/DossierCard';
import Processes from './components/Processes';

const LegalInformation = ({ data, toPrint }) => {
  const { t: translate } = useTranslation();

  const { companyId } = useParams();

  const profileType = useMemo(() => {
    return companyId
      ? translate(
          'pages.private.profiles.components.dataSourceCards.legalInformation.index.profileType.company'
        )
      : translate(
          'pages.private.profiles.components.dataSourceCards.legalInformation.index.profileType.person'
        );
  }, [companyId, translate]);

  return (
    <DossierCard
      customId="processess-infos-component"
      title={translate(
        'pages.private.profiles.components.dataSourceCards.legalInformation.index.legalInformation.dossierCard.title'
      )}
      subtitle={translate(
        'pages.private.profiles.components.dataSourceCards.legalInformation.index.legalInformation.dossierCard.subtitle',
        { profileType }
      )}
      icon="caf-ic_law"
    >
      <div className="flex flex-column no-mrg no-pdd">
        <Processes data={data} toPrint={toPrint} type={profileType} />
      </div>
    </DossierCard>
  );
};

export default LegalInformation;
