import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Spin, Avatar, Form, Input, Typography, message } from 'antd';

import PageTitle from 'components/PageTitle';
import ProfileDefault from 'assets/images/profile-default.png';
import { useAuth } from 'hooks/auth';
import { useFetch } from 'services/hooks';
import { toMaskedCnpj, toMaskedCep } from 'utils/formatters';
import numberToFormattedString from 'utils/numberToFormattedString';
import RegistrationFiles from './components/RegistrationFiles';

import Wrapper from '../wrapper';

import './styles.less';

const { Text } = Typography;

const I18N_BASE_PATH = 'pages.private.registrationInfo.page';

const RegistrationInfo = () => {
  const { t: translate } = useTranslation();
  const { user: loggedUser } = useAuth();
  const { get } = useFetch();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const getRegistrationData = useCallback(async () => {
    try {
      const response = await get({
        url: `${process.env.REACT_APP_BASE_URL_MANAGEMENT_API}/registration-info`,
      });

      setData(response);
      setLoading(false);
    } catch (err) {
      const errMessage = err?.response?.data?.message;
      message.error(errMessage);
    }
  }, [get]);

  useEffect(() => getRegistrationData(), [getRegistrationData]);

  const userInfo = useMemo(() => data?.data?.docs, [data]);

  return (
    <div id="registration-info">
      <Wrapper>
        {loading ? (
          <Spin className="spin-position" />
        ) : (
          <Row>
            <Col className="flex-1 page-title mrg-right-25">
              <PageTitle
                title={translate(`${I18N_BASE_PATH}.title`)}
                subtitle={translate(`${I18N_BASE_PATH}.subtitle`)}
              />
              <Form layout="vertical">
                <Row className="row-spaced">
                  <Col>
                    <div className="update-profile-pointer mrg-top-10">
                      <Avatar
                        src={loggedUser?.profileImageUrl || ProfileDefault}
                        size={120}
                      />
                    </div>
                  </Col>
                  <Col span={19} className="flex-1 mrg-left-45">
                    <Form.Item
                      name="fantasyName"
                      label={translate(`${I18N_BASE_PATH}.form.item.fantasyName`)}
                    >
                      <Input
                        className="input-style"
                        readOnly="readonly"
                        placeholder={userInfo?.fantasyName || ''}
                      />
                    </Form.Item>
                    <Form.Item
                      name="username"
                      label={translate(`${I18N_BASE_PATH}.form.item.username`)}
                    >
                      <Input readOnly="readonly" placeholder={loggedUser?.username} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="row-spaced">
                  <Col span={24}>
                    <div className="mrg-btm-25">
                      <Text className="text-subtitles">
                        {translate(`${I18N_BASE_PATH}.form.formTitles.charge`)}
                      </Text>
                    </div>
                    <Form.Item
                      name="companyName"
                      label={translate(`${I18N_BASE_PATH}.form.item.companyName`)}
                    >
                      <Input
                        className="input-style"
                        readOnly="readonly"
                        placeholder={userInfo?.companyName || ''}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mrg-right-25">
                  <Col span={12}>
                    <Form.Item
                      name="cnpj"
                      label={translate(`${I18N_BASE_PATH}.form.item.cnpj`)}
                    >
                      <Input
                        className="input-style"
                        readOnly="readonly"
                        placeholder={toMaskedCnpj(userInfo?.cnpj || '')}
                      />
                    </Form.Item>
                    <Form.Item
                      name="emailInvoice"
                      label={translate(`${I18N_BASE_PATH}.form.item.emailInvoice`)}
                    >
                      <Input
                        readOnly="readonly"
                        placeholder={userInfo?.emailInvoice || ''}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="phone"
                      label={translate(`${I18N_BASE_PATH}.form.item.phone`)}
                    >
                      <Input
                        className="input-style"
                        readOnly="readonly"
                        placeholder={userInfo?.phone || ''}
                      />
                    </Form.Item>
                    <Form.Item
                      name="codeNibo"
                      label={translate(`${I18N_BASE_PATH}.form.item.codeNibo`)}
                    >
                      <Input readOnly="readonly" placeholder={userInfo?.codeNibo || ''} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="row-spaced">
                  <Col span={24}>
                    <div className="mrg-btm-25">
                      <Text className="text-subtitles">
                        {translate(`${I18N_BASE_PATH}.form.formTitles.revenue`)}
                      </Text>
                    </div>
                  </Col>
                  <Col className="flex-1" span={8}>
                    <Form.Item
                      name="platformMaintenanceAmount"
                      label={translate(`${I18N_BASE_PATH}.form.item.maintenanceAmount`)}
                    >
                      <Input
                        className="input-style"
                        readOnly="readonly"
                        placeholder={numberToFormattedString(
                          userInfo?.platformMaintenanceAmount,
                          translate(`${I18N_BASE_PATH}.form.item.currencyPrefix`)
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="flex-1" span={8}>
                    <Form.Item
                      name="minimumInvoiceAmount"
                      label={translate(
                        `${I18N_BASE_PATH}.form.item.minimumInvoiceAmount`
                      )}
                    >
                      <Input
                        className="input-style"
                        readOnly="readonly"
                        placeholder={numberToFormattedString(
                          userInfo?.minimumInvoiceAmount,
                          translate(`${I18N_BASE_PATH}.form.item.currencyPrefix`)
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="flex-1" span={8}>
                    <Form.Item
                      name="invoiceDaysToExpire"
                      label={translate(`${I18N_BASE_PATH}.form.item.invoiceDaysToExpire`)}
                    >
                      <Input
                        className="input-style"
                        readOnly="readonly"
                        placeholder={userInfo?.invoiceDaysToExpire}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="row-spaced">
                  <Col span={24}>
                    <div className="mrg-btm-25">
                      <Text className="text-subtitles">
                        {translate(`${I18N_BASE_PATH}.form.formTitles.address`)}
                      </Text>
                    </div>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name="zip_code"
                      label={translate(`${I18N_BASE_PATH}.form.item.zipCode`)}
                    >
                      <Input
                        className="input-style"
                        readOnly="readonly"
                        placeholder={toMaskedCep(userInfo?.address?.zip_code || '')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={14}>
                    <Form.Item
                      name="street"
                      label={translate(`${I18N_BASE_PATH}.form.item.street`)}
                    >
                      <Input
                        className="input-style"
                        readOnly="readonly"
                        placeholder={userInfo?.address?.street || ''}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      name="district"
                      label={translate(`${I18N_BASE_PATH}.form.item.district`)}
                    >
                      <Input
                        className="input-style"
                        readOnly="readonly"
                        placeholder={userInfo?.address?.district || ''}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="number"
                      label={translate(`${I18N_BASE_PATH}.form.item.number`)}
                    >
                      <Input
                        className="input-style"
                        readOnly="readonly"
                        placeholder={userInfo?.address?.number || ''}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="row-spaced">
                  <Col span={24}>
                    <div className="mrg-btm-25">
                      <Text className="text-subtitles">
                        {translate(`${I18N_BASE_PATH}.form.formTitles.additionalInfo`)}
                      </Text>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="additionalInfo"
                      label={translate(`${I18N_BASE_PATH}.form.item.additionalInfo`)}
                    >
                      <Input.TextArea
                        className="input-style"
                        readOnly
                        placeholder={userInfo?.additionalInfo || ''}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col span={8}>
              <div className="mrg-btm-15">
                <PageTitle
                  title={translate(`${I18N_BASE_PATH}.registrationFiles.title`)}
                />
              </div>
              <RegistrationFiles />
            </Col>
          </Row>
        )}
      </Wrapper>
    </div>
  );
};
export default RegistrationInfo;
