import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import RestrictiveLists from 'pages/private/Profiles/components/DataSourceCards/RestrictiveLists';
import OwnersItems from 'pages/private/Profiles/components/DataSourceCards/RestrictiveLists/components/Owners/OwnersItems';

import './styles.less';

const DocumentRestrictiveLists = ({ data, showDetails }) => {
  const kycComplianceOwnersData = useMemo(() => {
    const kycComplianceOwners = data?.others?.kycComplianceOwners;
    return kycComplianceOwners;
  }, [data]);

  return (
    <div id="to-print-restrictive-lists">
      <RestrictiveLists data={data} toPrint toPrintDetails={showDetails} />

      {showDetails &&
        kycComplianceOwnersData?.items?.length > 0 &&
        kycComplianceOwnersData?.items?.map((owner) => (
          <OwnersItems
            key={owner?._id}
            data={owner}
            lastConsultation={kycComplianceOwnersData?.lastConsultation?.date}
            toPrint
            toPrintDetails={showDetails}
          />
        ))}
    </div>
  );
};

DocumentRestrictiveLists.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  showDetails: PropTypes.bool.isRequired,
};

export default DocumentRestrictiveLists;
