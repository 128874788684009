import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col, Collapse, Button } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';
import { useAuth } from 'hooks/auth';

import { validationStatus } from 'utils/handlerStatus';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';

import DottedLabel from 'components/DottedLabel';

const { Panel } = Collapse;

const ComplianceRulesList = ({ validations, lastConsultationDate }) => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const { i18nFormatDate } = useI18nFormatters();

  return (
    <div id="compliance-rules-collapse">
      <Collapse expandIconPosition="right">
        {validations?.map((value) => (
          <Panel
            key={translate(`validations.${value?.rule}.title`, value?.title)}
            header={
              <Row
                key={translate(`validations.${value?.rule}.title`, value?.title)}
                align="middle"
                justify="center"
                className="compliance-rules-status reset-mrg"
              >
                <Col span={8} className="reset-mrg no-pdd">
                  <span className="rule-title">
                    {translate(`validations.${value?.rule}.title`, value?.title)}
                  </span>
                </Col>
                <Col span={1}>
                  <i
                    style={{
                      color: validationStatus(
                        value?.history?.filter((v) => v?.rule === value?.rule)[0]?.status
                      ).color,
                    }}
                    className={`status-icon ${
                      validationStatus(
                        value?.history?.filter((v) => v?.rule === value?.rule)[0]?.status
                      ).icon
                    }`}
                  />
                </Col>
                <Col span={15}>
                  {translate(
                    `validations.${value?.rule}.description`,
                    value?.description
                  )}
                </Col>
              </Row>
            }
          >
            {value?.history?.map((v) => (
              <Row align="middle" className="mrg-top-10 mrg-btm-10">
                <Col className="history-consult-date" span={12}>
                  <i className="caf-ic_clock" />
                  <span>
                    {translate(
                      'pages.private.profiles.components.profileValidations.components.complianceRulesModal.components.complianceRulesList.consultDate'
                    )}{' '}
                    {(v?.createdAt && i18nFormatDate(v?.createdAt, 7)) ||
                      (v?.updatedAt && i18nFormatDate(v?.updatedAt, 7)) ||
                      (v?.lastUpdate && i18nFormatDate(v?.lastUpdate, 7))}
                  </span>
                </Col>
                <Col span={8}>
                  <DottedLabel
                    type={validationStatus(v?.resultStatus || value?.status).type}
                    description={translate(
                      validationStatus(v?.resultStatus || v?.status).title
                    )}
                  />
                </Col>
                <Col span={4}>
                  <Button
                    className="no-pdd no-mrg lh-1"
                    style={{ height: 14 }}
                    type="link"
                    onClick={() =>
                      window.open(
                        `${window.location.origin}${
                          hasFeatureFlag('isWorkflowBuilder', user)
                            ? '/person'
                            : '/executions'
                        }/${v?.executionId}`
                      )
                    }
                  >
                    {translate(
                      'pages.private.profiles.components.profileValidations.components.complianceRulesModal.components.complianceRulesList.viewConsult'
                    )}
                  </Button>
                </Col>
              </Row>
            ))}
          </Panel>
        ))}
      </Collapse>
      <footer>
        <i className="caf-ic_clock" />
        <span>
          {translate(
            'pages.private.profiles.components.profileValidations.components.complianceRulesModal.components.complianceRulesList.date'
          )}{' '}
          {i18nFormatDate(lastConsultationDate, 7)}
        </span>
      </footer>
    </div>
  );
};

ComplianceRulesList.propTypes = {
  validations: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      description: PropTypes.string,
      title: PropTypes.string,
      rule: PropTypes.string,
    })
  ).isRequired,
  lastConsultationDate: PropTypes.string.isRequired,
};

export default ComplianceRulesList;
