import React, { useCallback, useMemo, useState, useContext, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import OutsideClickHandler from 'react-outside-click-handler';
import _ from 'lodash';

import Button from 'components/Button';

const { Text, Title, Paragraph } = Typography;

const PopoverContext = createContext({});

const PopoverRemoveValidationsProvider = ({ children, type }) => {
  const { t: translate } = useTranslation();

  const [isVisible, setIsVisible] = useState(false);
  const [validationsToDelete, setValidationsToDelete] = useState();
  const [data, setData] = useState({
    service: null,
    formRef: null,
    onApply: () => {},
  });

  const open = useCallback(
    (e, { service, formRef, allServices }, onApply) => {
      setData({ service, formRef, onApply });

      // Fontes selecionadas no form
      const formService = formRef.getFieldValue([type, 'services']) || {};
      // Chaves das fontes do form
      const formServiceKeys = Object.keys(formService).filter((k) => k);
      // .flat();

      // Todas as regras possíveis baseado nas chaves encontradas
      const allRelatedRules = allServices
        .filter(({ source }) => formServiceKeys.includes(source))
        .map(({ relatedRules }) => relatedRules)
        .flat();

      // Validations selecionadas no form
      const formValidations = formRef.getFieldValue([type, 'validations']);
      // Fonte do checkbox desselecionado
      const serviceRelatedRules = service?.relatedRules || [];
      // Quais as validations desse customData que estão no form
      const intersection = _.intersectionBy(serviceRelatedRules, formValidations, 'rule');

      // Quantidade de vezes que uma determinada validation aparece nos customData possíveis
      const ruleOccurrences = _.countBy(allRelatedRules, 'rule');
      // Verificando se tem alguma validação com ocorrência <= 1
      // Caso for maior, significa que se tirar essa customData ainda sim vai ser possível
      // selecionar a validação
      const newValidationsToDelete = intersection.filter((i) => {
        return ruleOccurrences[i.rule] <= 1;
      });

      if (newValidationsToDelete.length > 0) {
        e.stopPropagation();

        setValidationsToDelete(intersection);
        setIsVisible(true);
      } else {
        onApply();
      }
    },
    [type]
  );

  const close = useCallback(() => {
    // changeAllowedValidations();
    setValidationsToDelete(undefined);
    setIsVisible(false);
  }, []);

  const applyRelatedRules = useCallback(() => {
    data.onApply();
    data.formRef.setFieldsValue({
      [type]: {
        validations: _.differenceBy(
          data.formRef.getFieldValue([type, 'validations']),
          validationsToDelete,
          'rule'
        ),
      },
    });
    close();
  }, [data, type, validationsToDelete, close]);

  const content = useMemo(() => {
    return (
      <OutsideClickHandler onOutsideClick={close}>
        <i className="top-icon warning caf-ic_alert-triangle" />

        <Title level={4}>
          {translate(
            'pages.private.profileModels.components.popoverRemoveValidationsProvider.content.title'
          )}
        </Title>

        {type === 'backgroundChecking' && (
          <Paragraph className="custom-card-paragraph">
            {translate(
              'pages.private.profileModels.components.popoverRemoveValidationsProvider.paragraph.backgroundChecking.before'
            )}{' '}
            <Text type="primary" className="text-bold">
              {data?.service?.title || data?.service?.source}
            </Text>{' '}
            {translate(
              'pages.private.profileModels.components.popoverRemoveValidationsProvider.paragraph.backgroundChecking.after'
            )}
          </Paragraph>
        )}

        {type === 'onboarding' && (
          <Paragraph className="custom-card-paragraph">
            {translate(
              'pages.private.profileModels.components.popoverRemoveValidationsProvider.paragraph.onboarding.before'
            )}{' '}
            <Text type="primary" className="text-bold">
              {data?.service?.title || data?.service?.source}
            </Text>{' '}
            {translate(
              'pages.private.profileModels.components.popoverRemoveValidationsProvider.paragraph.onboarding.after'
            )}
          </Paragraph>
        )}

        <div className="remove-validations-list">
          {validationsToDelete &&
            validationsToDelete?.map(({ rule, title }) => (
              <div key={rule} className="flex-row center-start">
                <Text className="text-center text-dark">
                  <i className="caf-ic_circle_false" />
                  <span>{title}</span>
                </Text>
              </div>
            ))}
        </div>

        <Button
          htmlType="button"
          type="warning"
          className="no-mrg"
          onClick={applyRelatedRules}
        >
          {type === 'backgroundChecking' &&
            translate(
              'pages.private.profileModels.components.popoverRemoveValidationsProvider.button.remove.backgroundChecking'
            )}
          {type === 'onboarding' &&
            translate(
              'pages.private.profileModels.components.popoverRemoveValidationsProvider.button.remove.onboarding'
            )}
        </Button>

        <Button type="text" onClick={close} className="mrg-top-10">
          {translate(
            'pages.private.profileModels.components.popoverRemoveValidationsProvider.button.maintain'
          )}
        </Button>
      </OutsideClickHandler>
    );
  }, [close, type, data.service, validationsToDelete, applyRelatedRules, translate]);

  return (
    <PopoverContext.Provider
      value={{
        open,
        content,
        isVisible,
      }}
    >
      {children}
    </PopoverContext.Provider>
  );
};

function usePopoverRemoveValidations() {
  const context = useContext(PopoverContext);

  if (!context) {
    throw new Error('usePopoverRemoveValidations must be used within an PopoverContext');
  }

  return context;
}

PopoverRemoveValidationsProvider.propTypes = {
  type: PropTypes.oneOf(['backgroundChecking', 'onboarding']).isRequired,
};

export { PopoverRemoveValidationsProvider, usePopoverRemoveValidations };
