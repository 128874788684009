import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, Row, Col, Tag, Tooltip } from 'antd';
import CardExecutionTag from 'pages/private/Profiles/components/ProfileTag/CardExecutionTag';
import DottedLabel from 'components/DottedLabel';
import IconStatus from 'pages/private/Profiles/components/IconStatus';
import { labelColor, executionStatus } from 'utils/handlerStatus';
import { ArrowRight1 } from '@combateafraude/icons/general';

import './styles.less';

const CardNewAction = ({ data, type, title, children }) => {
  const { t: translate } = useTranslation();

  const { executionId } = useParams();

  const actionType = useMemo(() => type?.toUpperCase() || '', [type]);

  const description = useMemo(() => {
    if (actionType === 'ADD_TAG' || actionType === 'REMOVE_TAG') {
      const preffix =
        actionType === 'ADD_TAG'
          ? translate(
              'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.addTag'
            )
          : translate(
              'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.removeTag'
            );

      return (
        <>
          <strong className="subtitle">{preffix}</strong>

          <CardExecutionTag
            key={data?.tag?._id}
            name={data?.tag?.title}
            color={data?.tag?.color}
            special={data?.tag?.special}
            actionDescription={data?.tag?.actionDescription}
          />
        </>
      );
    }

    if (
      actionType === 'ADD_RECURRENCE_MODEL' ||
      actionType === 'REMOVE_RECURRENCE_MODEL'
    ) {
      const preffix =
        actionType === 'ADD_RECURRENCE_MODEL'
          ? translate(
              'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.addRecurrenceModel'
            )
          : translate(
              'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.removeRecurrenceModel'
            );

      const recurrence =
        actionType === 'ADD_RECURRENCE_MODEL'
          ? data?.newRecurrenceModel
          : data?.oldRecurrenceModel;

      return (
        <>
          <strong className="subtitle">{preffix}</strong>

          <Tooltip placement="top" title={recurrence?.name}>
            <Tag className="action-tag">
              <i className="caf-ic_processing_line" />
              <span className="max-lines-1">{recurrence?.name}</span>
            </Tag>
          </Tooltip>
        </>
      );
    }

    if (actionType === 'ADD_FILE') {
      return (
        <>
          <strong className="subtitle">
            {translate(
              'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.addFile'
            )}
          </strong>

          <Tooltip placement="top" title={data?.file?.fileName}>
            <Tag className="action-tag">
              <span className="max-lines-1">{data?.file?.fileName}</span>
            </Tag>
          </Tooltip>
        </>
      );
    }

    if (actionType === 'DELETE_FILE') {
      return (
        <>
          <strong className="subtitle">
            {translate(
              'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.deleteFile'
            )}
          </strong>

          <Tooltip placement="top" title={data?.file?.fileName}>
            <Tag className="action-tag-danger">
              <span className="max-lines-1">{data?.file?.fileName}</span>
            </Tag>
          </Tooltip>
        </>
      );
    }

    if (actionType === 'MANUAL_REVIEW') {
      const status = translate(
        `pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.manualReview.${
          ['APPROVED', 'APROVADO'].includes(data?.status?.toUpperCase())
            ? 'approved'
            : 'reproved'
        }`
      );
      const reportName = data?.reportName || '-';

      return (
        <strong className="subtitle">
          <DottedLabel
            type={
              status ===
              translate(
                'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.supportTrustOrStatusChange.status.approved'
              )
                ? 'primary'
                : 'danger'
            }
            description={
              <span className="text-dark text-bold">
                {translate(
                  'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.manualReview.text',
                  { reportName, status }
                )}{' '}
                {data?.hasMissingOnboarding &&
                  translate(
                    'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.manualReview.hasMissingOnboarding'
                  )}
              </span>
            }
          />
        </strong>
      );
    }

    if (actionType === 'ONBOARDING_INTERNAL') {
      return (
        <strong className="subtitle">
          {translate(
            'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.onboardingInternal'
          )}
        </strong>
      ); // Envio de documentos para análise
    }

    if (actionType === 'MANUAL_RELATIONSHIP') {
      return (
        <strong className="subtitle">
          {translate(
            'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.manualRelationship',
            {
              cpf: data?.cpf,
              socioName: data?.partnerNamePjData,
            }
          )}
        </strong>
      );
    }

    if (actionType === 'MANUAL_REVISION') {
      return (
        <strong className="subtitle">
          {translate(
            'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.manualRevision',
            {
              status: translate(
                `components.filters.executionStatusFilter.collapse.panel.checkbox.${data?.newStatus.toLowerCase()}.value`
              ),
            }
          )}
        </strong>
      );
    }

    if (actionType === 'MANUAL_OCR') {
      return (
        <strong className="subtitle">
          {executionId
            ? translate(
                'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.manualOcrAlt'
              )
            : translate(
                'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.manualOcr'
              )}
        </strong>
      ); // Confirmação de dados manual
    }

    if (
      ['SUPPORT_STATUS_CHANGE', 'TRUST_STATUS_CHANGE', 'STATUS_CHANGE'].includes(
        actionType
      )
    ) {
      const status = ['APPROVED', 'APROVADO'].includes(data?.status?.toUpperCase())
        ? translate(
            'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.supportTrustOrStatusChange.status.approved'
          )
        : translate(
            'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.supportTrustOrStatusChange.status.reproved'
          );

      const userThatChanges = data?.user?.email || null;

      const changedBy =
        // eslint-disable-next-line no-nested-ternary
        actionType === 'SUPPORT_STATUS_CHANGE'
          ? translate(
              'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.supportTrustOrStatusChange.changeBySupportAnalyst'
            )
          : ['TRUST_STATUS_CHANGE', 'STATUS_CHANGE'].includes(actionType) &&
            userThatChanges
          ? `${translate(
              'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.supportTrustOrStatusChange.changeBy'
            )} ${userThatChanges}`
          : '';

      return (
        <strong className="subtitle">
          <DottedLabel
            type={
              status ===
              translate(
                'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.supportTrustOrStatusChange.status.approved'
              )
                ? 'primary'
                : 'danger'
            }
            description={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className="text-dark text-bold">
                  {translate(
                    'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.supportTrustOrStatusChange.dottedLabel.description.before'
                  )}{' '}
                  {status}{' '}
                  {translate(
                    'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.supportTrustOrStatusChange.dottedLabel.description.inside'
                  )}{' '}
                  {changedBy}
                </span>
              </div>
            }
          />
        </strong>
      );
    }

    if (actionType === 'DOCUMENTSCOPY_FINALIZED') {
      const docStatus = ['APPROVED', 'APROVADO'].includes(data?.status?.toUpperCase())
        ? translate(
            'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.documentoscopyFinalized.status.approved'
          )
        : translate(
            'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.documentoscopyFinalized.status.reproved'
          );

      return (
        <strong className="subtitle">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="text-dark text-bold">
              {translate(
                'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.documentoscopyFinalized.dottedLabel.description.before'
              )}{' '}
              {docStatus}{' '}
              {translate(
                'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.documentoscopyFinalized.dottedLabel.description.inside'
              )}
            </span>
          </div>
        </strong>
      );
    }

    if (actionType === 'EXECUTION_FINALIZED') {
      return (
        <strong className="subtitle">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="text-dark text-bold">
              {translate(
                'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.executionFinalized.description'
              )}
            </span>
          </div>
        </strong>
      );
    }

    if (actionType === 'MANUAL_RULE_CHANGE') {
      return (
        <strong className="subtitle">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="text-dark text-bold">
              {translate(`validations.${data?.ruleId}.title`, data?.ruleId)}
            </span>
          </div>
        </strong>
      );
    }

    return (
      <strong className="subtitle">
        {translate(
          'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.description.default'
        )}
      </strong>
    );
  }, [actionType, translate, data, executionId]);

  const content = useMemo(() => {
    if (
      [
        'MANUAL_REVIEW',
        'TRUST_STATUS_CHANGE',
        'SUPPORT_STATUS_CHANGE',
        'STATUS_CHANGE',
      ].includes(actionType)
    ) {
      if (data?.reason) {
        return (
          <span>
            {translate(
              'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.content.manualReview'
            )}
            {data?.reason}
          </span>
        );
      }
    }

    if (['MANUAL_RULE_CHANGE'].includes(actionType)) {
      return (
        <>
          <div className="card-info-status-manual-rule">
            <div className="wrapper-icon-text-manual-rule">
              {data?.oldStatus && (
                <>
                  <IconStatus status={data?.oldStatus} />
                  <span style={{ color: labelColor[data.oldStatus] }}>
                    {translate(executionStatus(data?.oldStatus)?.value)}
                  </span>
                  <ArrowRight1 fontSize={15} style={{ margin: '0px 8px' }} />
                </>
              )}
              <IconStatus status={data?.status} />
              <span style={{ color: labelColor[data.status] }}>
                {translate(executionStatus(data?.status)?.value)}
              </span>
            </div>
          </div>
          {data?.justification && (
            <div className="card-justification">
              <span>
                {translate(
                  'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.content.manualReview'
                )}
              </span>

              {data?.justification}
            </div>
          )}
        </>
      );
    }

    return null;
  }, [data, actionType, translate]);

  const cardTitle = useMemo(() => {
    if (title) return title;
    if (executionId)
      return translate(
        'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.cardNewAction.title.execution'
      );
    return translate(
      'pages.private.profiles.components.tabs.components.recurrenceList.components.cardRecurrenceEvent.components.cardNewAction.cardNewAction.title.profile'
    );
  }, [executionId, title, translate]);

  return (
    <Card className="card-new-activity">
      <div className="content">
        <Row className="card-new-activity-header">
          <Col span={24}>
            <span className="title">{cardTitle}</span>
            <div className="action-description">{description}</div>
          </Col>
        </Row>

        {content && (
          <Row>
            <Col span={24}>{content}</Col>
          </Row>
        )}
      </div>

      {children}
    </Card>
  );
};

CardNewAction.propTypes = {
  data: PropTypes.shape({
    tag: PropTypes.objectOf(PropTypes.string),
    newRecurrenceModel: PropTypes.objectOf(PropTypes.string, PropTypes.shape),
    oldRecurrenceModel: PropTypes.objectOf(PropTypes.string, PropTypes.shape),
    file: PropTypes.objectOf(PropTypes.string),
    reason: PropTypes.string,
    status: PropTypes.string,
    reportName: PropTypes.string,
    user: PropTypes.shape({
      email: PropTypes.string,
    }),
    evaluation: PropTypes.arrayOf({
      fraud: PropTypes.bool,
      message: PropTypes.string,
    }),
    reviewDate: PropTypes.string,
    hasMissingOnboarding: PropTypes.bool,
    justification: undefined,
    oldStatus: undefined,
    ruleId: undefined,
    partnerNamePjData: PropTypes.string,
    cpf: PropTypes.string,
    newStatus: PropTypes.string,
  }),
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
};

CardNewAction.defaultProps = {
  data: {
    tag: undefined,
    newRecurrenceModel: undefined,
    oldRecurrenceModel: undefined,
    file: undefined,
    reason: undefined,
    status: undefined,
    reportName: undefined,
    user: {
      email: undefined,
    },
    evaluation: undefined,
    reviewDate: undefined,
    hasMissingOnboarding: undefined,
    justification: undefined,
    oldStatus: undefined,
    ruleId: undefined,
  },
  title: undefined,
};

export default CardNewAction;
