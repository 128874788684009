import React from 'react';
import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { Col, Row } from 'antd';
import NoResults from 'components/NoResults';
import NotAvailable from 'components/NotAvailable';
import CollapseList from '../../../../CollapseList';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companyDetails.subPages.people.creditReport';

const PersonWithSignificantControlContent = ({
  creditReportData,
  activePersonsWithSignificantControlLength,
  active,
  ceased,
  total,
  collapsePrintSuffix,
  collapsePrintList,
  collapseOpened,
  print = false,
}) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDateOnly } = useI18nFormatters();
  const personsWithSignificantControlActive =
    creditReportData?.personsWithSignificantControl?.active;

  if (!activePersonsWithSignificantControlLength) {
    return <NoResults />;
  }

  return (
    <>
      <Row className="wrapper-person-control-cards no-mrg">
        <Col span={8}>
          <div className="card-person-control">
            {print ? null : (
              <strong>
                {translate(
                  `${I18N_BASE_PATH}.tabs.directors.personWithSignificantControl.cardCount.title`,
                  'Person with Significant Control'
                )}
              </strong>
            )}
            <div className="wrapper-status">
              <div className="content-status">
                <strong>{active}</strong>
                <div className="group-content-and-status">
                  <div className="active-status" />
                  <span>
                    {translate(
                      `${I18N_BASE_PATH}.tabs.directors.personWithSignificantControl.cardCount.active`,
                      'Active'
                    )}
                  </span>
                </div>
              </div>
              <div className="content-status">
                <strong>{ceased}</strong>
                <div className="group-content-and-status">
                  <div className="active-ceased" />
                  <span>
                    {translate(
                      `${I18N_BASE_PATH}.tabs.directors.personWithSignificantControl.cardCount.ceased`,
                      'Ceased'
                    )}
                  </span>
                </div>
              </div>
              <div className="content-status">
                <strong>{total}</strong>
                <div className="group-content-and-status">
                  <div className="active-total" />
                  <span>
                    {translate(
                      `${I18N_BASE_PATH}.tabs.directors.personWithSignificantControl.cardCount.total`,
                      'Total'
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col span={8} />
        <Col span={8} />
      </Row>
      <div className="current-tab-infos mrg-btm-20">
        <h4>
          {translate(
            `${I18N_BASE_PATH}.tabs.directors.personWithSignificantControl.activePersonsWithSignificantControl.title`,
            'Active Persons With a Significant Control'
          )}
        </h4>
      </div>
      <CollapseList
        print={print}
        collapsePrintSuffix={collapsePrintSuffix}
        collapsePrintList={collapsePrintList}
        collapseOpened={collapseOpened}
        columns={[
          {
            prop: 'name',
            title: translate(
              `${I18N_BASE_PATH}.tabs.directors.personWithSignificantControl.activePersonsWithSignificantControl.columns.name`,
              'Name'
            ),
            span: 14,
          },
          {
            prop: 'natureOfControl',
            title: translate(
              `${I18N_BASE_PATH}.tabs.directors.personWithSignificantControl.activePersonsWithSignificantControl.columns.natureOfTheControl`,
              'Nature of the control'
            ),
            span: 8,
          },
        ]}
        list={personsWithSignificantControlActive?.map((person, index) => {
          return {
            header: {
              id: index,
              name: person?.name || <NotAvailable />,
              natureOfControl: person?.natureOfControl,
            },
            body: [
              {
                title: translate(
                  `${I18N_BASE_PATH}.tabs.directors.personWithSignificantControl.activePersonsWithSignificantControl.collapse.notifiedOn`,
                  'Notified on'
                ),
                content: i18nFormatDateOnly(person?.notifiedOn, 1),
              },
              {
                title: translate(
                  `${I18N_BASE_PATH}.tabs.directors.personWithSignificantControl.activePersonsWithSignificantControl.collapse.postcode`,
                  'Postcode'
                ),
                content: person?.address?.postalCode,
              },
              {
                title: translate(
                  `${I18N_BASE_PATH}.tabs.directors.personWithSignificantControl.activePersonsWithSignificantControl.collapse.countryRegistred`,
                  'Country Registered'
                ),
                content: person?.countryOfRegistration,
              },
              {
                title: translate(
                  `${I18N_BASE_PATH}.tabs.directors.personWithSignificantControl.activePersonsWithSignificantControl.collapse.authority`,
                  'Authority'
                ),
                content: person?.governingLaw,
              },
              {
                title: translate(
                  `${I18N_BASE_PATH}.tabs.directors.personWithSignificantControl.activePersonsWithSignificantControl.collapse.address`,
                  'Address'
                ),
                content: person?.address?.simpleValue,
              },
              {
                title: translate(
                  `${I18N_BASE_PATH}.tabs.directors.personWithSignificantControl.activePersonsWithSignificantControl.collapse.kind`,
                  'Kind'
                ),
                content: person?.kind,
              },
              {
                title: translate(
                  `${I18N_BASE_PATH}.tabs.directors.personWithSignificantControl.activePersonsWithSignificantControl.collapse.legalForm`,
                  'Legal Form'
                ),
                content: person?.legalForm,
              },
              {
                title: translate(
                  `${I18N_BASE_PATH}.tabs.directors.personWithSignificantControl.activePersonsWithSignificantControl.collapse.placeRegistred`,
                  'Place registered'
                ),
                content: person?.placeRegistered,
              },
              {
                title: translate(
                  `${I18N_BASE_PATH}.tabs.directors.personWithSignificantControl.activePersonsWithSignificantControl.collapse.registrerNumber`,
                  'Registered number'
                ),
                content: person?.registrationNumber,
              },
            ],
          };
        })}
      />
    </>
  );
};

export default PersonWithSignificantControlContent;
