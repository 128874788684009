import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';
import { useExecution } from 'hooks/execution';
import BasicInfosCard from '../BasicInfosCard';

const I18N_BASE_PATH =
  'components.dataSourceCards.basicInfos.components.person.registrationInfosContent.registrationInfosContent.basicInfosCard';

const InternationalRegistrationInfosContent = () => {
  const { t: translate } = useTranslation();

  const { execution } = useExecution();

  const parameters = execution?.parameters || {};

  const hasPersonId = useMemo(() => {
    return !!(
      execution?.data?.cpf ||
      execution?.parameters?.cpf ||
      execution?.sections?.pfBasicData?.data?.taxIdNumber ||
      execution?.sections?.ocr?.cpf ||
      parameters?.personId ||
      parameters?.nationalId ||
      parameters?.documentNumber ||
      parameters?.rg
    );
  }, [execution, parameters]);

  const parsedBirthDate = useMemo(() => {
    if (execution?.data?.birthDate) return execution?.data?.birthDate;
    if (parameters?.birthDate) return parameters.birthDate;
    return '-';
  }, [execution, parameters]);

  return (
    <BasicInfosCard
      icon="caf-ic_file"
      title={translate(`${I18N_BASE_PATH}.title`)}
      data={execution}
      dataIndex="basicData"
    >
      <Row className="pdd-top-10 ">
        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.name.title`)}
        >
          <span>
            {execution?.data?.name ||
              parameters.name ||
              execution?.sections?.globalDocVerification?.data?.name ||
              (execution?.type?.toUpperCase() !== 'CRLV' || execution?.sections?.ocr?.cpf
                ? execution?.sections?.ocr?.name
                : null) ||
              '-'}
          </span>
        </ColumnLabel>

        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.birthDate.title`)}
        >
          <span>{parsedBirthDate}</span>
        </ColumnLabel>

        <ColumnLabel
          title={translate(`${I18N_BASE_PATH}.columnLabel.docId.title`)}
          span={8}
        >
          <span>
            {hasPersonId
              ? execution?.data?.cpf ||
                parameters?.cpf ||
                execution?.sections?.globalDocVerification?.personId ||
                parameters?.personId ||
                parameters?.rg ||
                parameters?.nationalId ||
                parameters?.documentNumber
              : '-'}
          </span>
        </ColumnLabel>
        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.documentIssueState.title`)}
        >
          <span>{parameters?.rgIssueState || parameters?.documentIssueState || '-'}</span>
        </ColumnLabel>
        <ColumnLabel
          span={8}
          className="mrg-btm-20"
          title={translate(`${I18N_BASE_PATH}.columnLabel.documentIssueDate.title`)}
        >
          <span>{parameters?.rgIssueDate || parameters?.documentIssueDate || '-'}</span>
        </ColumnLabel>
      </Row>
    </BasicInfosCard>
  );
};

export default InternationalRegistrationInfosContent;
