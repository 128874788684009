import React, { useCallback, useEffect, useState } from 'react';
import { Card, Typography, DatePicker, Button, Row, Select, Spin } from 'antd';
import { useQueryParams, StringParam } from 'use-query-params';
import PermissionWrapper from 'routes/PermissionWrapper';
import { useFetch } from 'services/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SecurityAndPrivacyWrapper from '../index';
// import { actionTypes } from './utils';
import './styles.less';
import LogsTable from './components/LogsTable';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const I18N_BASE_PATH = 'pages.private.securityAndPrivacy.auditLog';

const AuditLog = () => {
  const { t: translate } = useTranslation();

  const { get, loading } = useFetch();

  const [loaded, setLoaded] = useState(false);
  const [users, setUsers] = useState(null);
  const [groups, setGroups] = useState(null);
  const [logs, setLogs] = useState([]);
  const [lastKey, setLastKey] = useState(undefined);

  const [query, setQuery] = useQueryParams({
    userId: StringParam,
    action: StringParam,
    groupId: StringParam,
    startDate: StringParam,
    endDate: StringParam,
  });

  const fetchAuditLog = useCallback(
    async (_query = {}, _lastKey = undefined) => {
      const result = await get({
        url: '/audit-log',
        config: {
          params: {
            ..._query,
            lastKey: _lastKey,
          },
        },
        showMessage: false,
      });

      if (result.lastKey) setLastKey(result.lastKey);

      const newLogs =
        result?.docs?.map((item) => {
          const relatedUser = users.find((u) => u.id === item.userId);
          const relatedGroup = groups.find((g) => g.id === item.groupId);
          return { ...item, user: relatedUser, group: relatedGroup };
        }) || [];

      if (_lastKey) {
        setLogs(logs.concat(newLogs));
      } else {
        setLogs(newLogs);
      }
    },
    [get, groups, logs, users]
  );

  const loadFilterData = useCallback(
    async (usersResult = {}, groupsResult = {}) => {
      if (!usersResult?.docs || usersResult?.LastEvaluatedKey) {
        const { docs, LastEvaluatedKey } = await get({
          url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/users/all`,
          config: {
            params: {
              active: true,
              ...usersResult?.LastEvaluatedKey,
            },
          },
        });
        usersResult.docs = (usersResult.docs ?? []).concat(docs);
        usersResult.LastEvaluatedKey = LastEvaluatedKey;
      }

      if (!groupsResult?.docs) {
        const { docs } = await get({
          url: '/groups',
          config: {
            params: {
              defaultPolicies: true,
            },
          },
        });
        groupsResult.docs = docs;
      }

      if (usersResult.LastEvaluatedKey) {
        loadFilterData(usersResult, groupsResult);
      } else {
        setUsers(usersResult.docs);
        setGroups(groupsResult.docs);
      }
    },
    [get]
  );

  const setFilter = useCallback(
    (newQueryValue) => {
      setQuery(newQueryValue);
      setLastKey(undefined);
      fetchAuditLog(newQueryValue);
    },
    [fetchAuditLog, setQuery]
  );

  const onDateChange = useCallback(
    (event) => {
      if (!event) {
        setFilter({ ...query, startDate: undefined, endDate: undefined });
        return;
      }
      const startDate = new Date(
        event[0].utcOffset(0).set({ second: 0, millisecond: 0 }).valueOf()
      ).toISOString();
      const endDate = new Date(
        event[1].utcOffset(0).set({ second: 59, millisecond: 0 }).valueOf()
      ).toISOString();

      if (startDate && endDate) {
        setFilter({ ...query, startDate, endDate });
      }
    },
    [query, setFilter]
  );

  useEffect(() => {
    loadFilterData();
  }, [loadFilterData]);

  useEffect(() => {
    if (!loaded && users && groups) {
      setLoaded(true);
      fetchAuditLog();
    }
  }, [fetchAuditLog, groups, lastKey, loaded, logs, users]);

  return (
    <SecurityAndPrivacyWrapper>
      <Card style={{ width: '100%' }}>
        <Row className="export-options">
          {/* <Button className="btn-primary">Exportar</Button> */}
        </Row>
        <PermissionWrapper requiredPermissions={['audit-log:update']}>
          <div className="select-options">
            <div>
              <Select
                id="click_audit_log_user_filter"
                showSearch
                allowClear
                style={{ width: 250 }}
                placeholder={translate(`${I18N_BASE_PATH}.index.user`)}
                optionFilterProp="children"
                onChange={(value) => setFilter({ ...query, userId: value })}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={users?.map((u) => {
                  return { label: u.name, value: u.id };
                })}
                value={query.userId}
              />
            </div>
            {/* <div className="dropdowns-container">
            <Select
              showSearch
              allowClear
              style={{ width: 200 }}
              bordered={false}
              placeholder={translate(`${I18N_BASE_PATH}.index.action`)}
              optionFilterProp="children"
              onChange={(value) => setFilter({ ...query, action: value })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={actionTypes}
              value={query.action}
            />
            <Select
              showSearch
              allowClear
              style={{ width: 200 }}
              bordered={false}
              placeholder={translate(`${I18N_BASE_PATH}.index.group`)}
              optionFilterProp="children"
              onChange={(value) => setFilter({ ...query, groupId: value })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={groups?.map((g) => {
                return { label: g.name, value: g.id };
              })}
              value={query.groupId}
            />
          </div> */}
            <RangePicker
              id="click_audit_log_date_filter"
              showTime
              width={200}
              disabledDate={(current) => {
                return current && current > moment().endOf('day');
              }}
              format="DD/MM/YYYY HH:mm"
              onChange={(value) => onDateChange(value)}
              value={[
                query.startDate ? moment(query.startDate) : undefined,
                query.endDate ? moment(query.endDate) : undefined,
              ]}
            />
          </div>
        </PermissionWrapper>

        {loaded && <LogsTable datasource={logs} />}
        <div className="see-more-content">
          {loading && lastKey && <Spin />}
          {!loaded && <Spin />}
          {!loading && lastKey && (
            <Button
              id="click_audit_log_show_more"
              disabled={loading}
              className="btn-primary"
              onClick={() => fetchAuditLog(query, lastKey)}
            >
              {translate(`${I18N_BASE_PATH}.index.showMore`)}
            </Button>
          )}
        </div>
      </Card>
    </SecurityAndPrivacyWrapper>
  );
};

export default AuditLog;
