export function hasFeatureFlag(featureFlag, user) {
  if (
    user?.accountData?.featureFlags !== undefined &&
    user?.accountData?.featureFlags[featureFlag]
  ) {
    return true;
  }

  return false;
}
