/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Divider } from 'antd';
import { useI18nFormatters } from 'hooks/i18nFormatters';

import { handlerAssociateRegister } from 'utils/associateRegisters';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import './styles.less';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.shared.spcRestrictionsScore.spcItems';

const SPCItems = ({ data, spcScoreRange }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatCurrency, i18nFormatDate } = useI18nFormatters();

  const filteredItems = useMemo(() => {
    if (spcScoreRange === 'total') {
      return data?.spc?.details || [];
    }

    return data[spcScoreRange]?.details;
  }, [data, spcScoreRange]);

  const totalDebtsValue = useCallback(() => {
    const debts = filteredItems?.map((d) => d?.value || 0);

    return debts?.reduce((acc, cur) => acc + cur, 0) || 0;
  }, [filteredItems]);

  return (
    <div id="executions-report-spc-items">
      <Row className="spc-items-title reset-mrg">
        <Col span={16}>
          <strong>
            {handlerAssociateRegister(
              filteredItems?.length || 0,
              translate(`${I18N_BASE_PATH}.index.occurences`),
              translate
            )}
          </strong>
        </Col>

        <Col span={8} className="end">
          <strong>
            {translate(`${I18N_BASE_PATH}.index.totalAmount`)}:{' '}
            {i18nFormatCurrency(totalDebtsValue(), 'pt-BR') || '-'}
          </strong>
        </Col>
      </Row>

      <Divider className="custom-divider" />

      {filteredItems?.length > 0 &&
        filteredItems?.map((ocurrence) => (
          <>
            <Row
              justify="space-between"
              key={ocurrence}
              className="spc-items-data reset-mrg"
            >
              <ColumnLabel
                span={20}
                description={ocurrence?.associateName || '-'}
                type="danger"
              />
              <Col className=" reset-mrg bold">
                {i18nFormatCurrency(ocurrence?.value, 'pt-BR') || '-'}
              </Col>
            </Row>
            <Row
              align="middle"
              justify="flex-start"
              key={ocurrence}
              className="reset-mrg dates-row"
            >
              <Col>
                {ocurrence?.inclusionDate && (
                  <>
                    <span className="text-bold">
                      {translate(`${I18N_BASE_PATH}.index.inclusionDate`)}
                      {': '}
                    </span>
                    <span>{i18nFormatDate(ocurrence?.inclusionDate, 1)}</span>
                  </>
                )}
              </Col>
              <Col style={{ marginLeft: 12 }}>
                {ocurrence?.expirationDate && (
                  <>
                    <span className="text-bold">
                      {translate(`${I18N_BASE_PATH}.index.expirationDate`)}
                      {': '}
                    </span>
                    <span>{i18nFormatDate(ocurrence?.expirationDate, 1)}</span>
                  </>
                )}
              </Col>
            </Row>
          </>
        ))}
    </div>
  );
};

export default SPCItems;
