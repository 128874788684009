/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import * as executionDataSources from 'utils/executionDataSource';

import { useExecution } from 'hooks/execution';
import { usePerson } from 'hooks/person';
import { useCompany } from 'hooks/company';

import { DenatranDriverViolations } from './components/denatranDriverViolations';
import { DenatranVehicleViolations } from './components/denatranVehicleViolations';
import Detran from './components/Detran';
import Antt from './components/Antt';

import DataSourceCardCollapsePanel from '../DataSourceCardCollapsePanel';

const DriverInfos = ({ toPrint = false }) => {
  const { t: translate } = useTranslation();

  const { execution } = useExecution();
  const { company } = useCompany();
  const { person } = usePerson();

  const findSourceSection = useCallback(
    (section) =>
      executionDataSources.findSourceSection(execution, section) ||
      executionDataSources.findSourceSection(company, section) ||
      executionDataSources.findSourceSection(person, section),
    [company, execution, person]
  );

  const detran = useMemo(() => findSourceSection('detran'), [findSourceSection]);

  const antt = useMemo(() => findSourceSection('antt'), [findSourceSection]);

  const denatranDriverViolations = useMemo(
    () => findSourceSection('denatranDriverViolations'),
    [findSourceSection]
  );

  const denatranVehicleViolations = useMemo(
    () => findSourceSection('denatranVehicleViolations'),
    [findSourceSection]
  );

  return (
    <DataSourceCardCollapsePanel
      id="driver-infos-component"
      title={translate(
        'components.dataSourceCards.driverInformation.index.driverInformation.dossierCard.title'
      )}
      icon="caf-ic_driver"
      toPrint={toPrint}
    >
      {detran && <Detran source={detran} toPrint={toPrint} />}

      {antt && <Antt source={antt} toPrint={toPrint} />}

      {denatranDriverViolations && (
        <DenatranDriverViolations source={denatranDriverViolations} toPrint={toPrint} />
      )}
      {denatranVehicleViolations && (
        <DenatranVehicleViolations source={denatranVehicleViolations} toPrint={toPrint} />
      )}
    </DataSourceCardCollapsePanel>
  );
};

export default DriverInfos;
