/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, Row, Col, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  PROFILE_RULES_INITIAL_VALUE,
  QSA_RULES_INITIAL_VALUE,
} from 'hooks/profileTemplate';

const actionTranslator = {
  CREATE:
    'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.actionTranslator.create',
  UPDATE:
    'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.actionTranslator.update',
  ACTIVATE:
    'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.actionTranslator.activate',
  INACTIVATE:
    'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.actionTranslator.inactivate',
};

const actionTranslatorTitle = {
  CREATE:
    'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.actionTranslatorTitle.create',
  UPDATE:
    'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.actionTranslatorTitle.update',
  ACTIVATE:
    'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.actionTranslatorTitle.activate',
  INACTIVATE:
    'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.actionTranslatorTitle.inactivate',
};

const typeTranslator = {
  ONBOARDING:
    'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.typeTranslator.onboarding',
  TRANSACTION_TEMPLATE:
    'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.typeTranslator.transactionTemplate',
};

const titleTranslator = {
  sources:
    'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.titleTranslator.sources',
  backgroundChecking:
    'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.titleTranslator.backgroundChecking',
  complianceRules:
    'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.titleTranslator.complianceRules',
  profileRules:
    'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.titleTranslator.profileRules',
  name:
    'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.titleTranslator.name',
  qsaProfileTemplate:
    'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.titleTranslator.qsaProfileTemplate',
  templateOnboarding:
    'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.titleTranslator.onboardingTemplate',
};

const recurrenceTranslator = {
  NONE:
    'pages.private.profileModels.components.backgroundChecking.recurrenceSelect.recurrenceSelect.select.options.NONE.label',
  DAILY:
    'pages.private.profileModels.components.backgroundChecking.recurrenceSelect.recurrenceSelect.select.options.DAILY.label',
  WEEKLY:
    'pages.private.profileModels.components.backgroundChecking.recurrenceSelect.recurrenceSelect.select.options.WEEKLY.label',
  MONTHLY:
    'pages.private.profileModels.components.backgroundChecking.recurrenceSelect.recurrenceSelect.select.options.MONTHLY.label',
  BIMONTHLY:
    'pages.private.profileModels.components.backgroundChecking.recurrenceSelect.recurrenceSelect.select.options.BIMONTHLY.label',
  QUARTERLY:
    'pages.private.profileModels.components.backgroundChecking.recurrenceSelect.recurrenceSelect.select.options.QUARTERLY.label',
  SEMIANNUALLY:
    'pages.private.profileModels.components.backgroundChecking.recurrenceSelect.recurrenceSelect.select.options.SEMIANNUALLY.label',
};

const actionWhenInvalidTranslator = {
  PENDING_SUBMISSION:
    'pages.private.profileModels.components.complianceRules.validationsFormSelector.type.person',
  REPROVE:
    'pages.private.profileModels.components.complianceRules.validationsFormSelector.actionWhenInvalidOptions.profileRules.default.title.reprove',
  PENDING:
    'pages.private.profileModels.components.complianceRules.validationsFormSelector.actionWhenInvalidOptions.profileRules.default.title.pending',
};

const statusClassName = {
  UPDATE: 'info-link caf-ic_processing_line',
  ADD: 'create-link caf-ic_add',
  REMOVE: 'removed-link caf-ic_remove',
  CREATE: 'create-link caf-ic_add',
};

const CardLogger = ({ title, action, data, children, type, transactionTemplate }) => {
  const { t: translate } = useTranslation();
  const profileRulesInitialValue = useMemo(() => {
    return PROFILE_RULES_INITIAL_VALUE(translate);
  }, [translate]);
  const qsaRulesInitialValue = useMemo(() => {
    return QSA_RULES_INITIAL_VALUE(translate);
  }, [translate]);
  const displayedTitle = useMemo(() => {
    if (type === 'TRANSACTION_TEMPLATE') {
      return (
        <span>
          {translate(
            'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.actionTranslatorTitle.model'
          )}{' '}
          <Link
            className="link-transaction-template"
            to={`profile-models/${transactionTemplate?.id}?type=${transactionTemplate?.type}`}
          >
            <span>{transactionTemplate?.name}</span>
          </Link>{' '}
          {translate(actionTranslatorTitle[action])}
        </span>
      );
    }

    return `${translate(
      'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.actionTranslatorTitle.onboarding'
    )} ${translate(actionTranslatorTitle[action])}`;
  }, [action, transactionTemplate, translate, type]);

  const displayOnProfileCreation = useCallback((d) => {
    const hasOld =
      d?.old?.onProfileCreation === false || d?.old?.onProfileCreation === true;
    const hasNew =
      d?.new?.onProfileCreation === false || d?.new?.onProfileCreation === true;
    return (
      <>
        {d?.old?.onProfileCreation === true && (
          <>
            <i className="caf-ic_checkmark create-link mrg-right-10" /> Sim
          </>
        )}
        {d?.old?.onProfileCreation === false && (
          <>
            <i className="caf-ic_close removed-link mrg-right-10" /> Não
          </>
        )}
        {hasOld && hasNew && d?.old?.onProfileCreation !== d?.new?.onProfileCreation && (
          <i className="caf-ic_arrow-right mrg-horizon-5" />
        )}
        {d?.new?.onProfileCreation === true &&
          d?.old?.onProfileCreation !== d?.new?.onProfileCreation && (
            <>
              <i className="caf-ic_checkmark create-link mrg-right-10" /> Sim
            </>
          )}
        {d?.new?.onProfileCreation === false &&
          d?.old?.onProfileCreation !== d?.new?.onProfileCreation && (
            <>
              <i className="caf-ic_close removed-link mrg-right-10" /> Não
            </>
          )}
      </>
    );
  }, []);

  const displayCustomStatus = useMemo(() => {
    let hasCustomStatus = false;
    data?.complianceRules?.forEach((rule) => {
      if (rule?.new?.customStatus || rule?.old?.customStatus) hasCustomStatus = true;
    });
    data?.profileRules?.forEach((rule) => {
      if (rule?.new?.customStatus || rule?.old?.customStatus) hasCustomStatus = true;
    });
    return hasCustomStatus;
  }, [data]);

  const displayNameChange = useMemo(() => {
    return (
      <div className="mrg-btm-20">
        <Row className="text-dark no-pdd-horizon no-mrg-horizon mrg-btm-5">
          <Col span={24} className="no-pdd-horizon no-mrg-horizon">
            <strong>{translate(titleTranslator?.name)}</strong>
          </Col>
        </Row>
        <Row className="mrg-btm-10 pdd-top-10 no-pdd-horizon no-mrg-horizon">
          <Col span={24} className="flex start-center no-pdd-horizon no-mrg-horizon">
            <i className={`${statusClassName[action]} mrg-right-10`} />
            <span>
              {action === 'UPDATE' && (
                <>
                  {data?.name?.old}
                  <i className="caf-ic_arrow-right mrg-horizon-5" />
                </>
              )}
              {data?.name?.new}
            </span>
          </Col>
        </Row>
      </div>
    );
  }, [action, data, translate]);

  const displayQsaProfileChange = useMemo(() => {
    return (
      <div className="mrg-btm-20">
        <Row className="text-dark no-pdd-horizon no-mrg-horizon mrg-btm-5">
          <Col span={24} className="no-pdd-horizon no-mrg-horizon">
            <strong>{translate(titleTranslator?.qsaProfileTemplate)}</strong>
          </Col>
        </Row>
        <Row className="mrg-btm-10 pdd-top-10 no-pdd-horizon no-mrg-horizon">
          <Col span={24} className="flex start-center no-pdd-horizon no-mrg-horizon">
            <i className={`${statusClassName[action]} mrg-right-10`} />
            <span>
              {action === 'UPDATE' && (
                <>
                  {data?.qsaProfileTemplate?.old ||
                    translate(
                      'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.noQsaOnboarding'
                    )}
                  <i className="caf-ic_arrow-right mrg-horizon-5" />
                </>
              )}
              {data?.qsaProfileTemplate?.new}
            </span>
          </Col>
        </Row>
      </div>
    );
  }, [action, data, translate]);

  const displayTemplateOnboarding = useMemo(() => {
    return (
      <div className="mrg-btm-20">
        <Row className="text-dark no-pdd-horizon no-mrg-horizon mrg-btm-5">
          <Col span={24} className="no-pdd-horizon no-mrg-horizon">
            <strong>{translate(titleTranslator?.templateOnboarding)}</strong>
          </Col>
        </Row>
        <Row className="mrg-btm-10 pdd-top-10 no-pdd-horizon no-mrg-horizon">
          <Col span={24} className="flex start-center no-pdd-horizon no-mrg-horizon">
            <i className={`${statusClassName[action]} mrg-right-10`} />
            <span>
              {action === 'UPDATE' && (
                <>
                  {data?.onboardingTemplate?.old}
                  <i className="caf-ic_arrow-right mrg-horizon-5" />
                </>
              )}
              {data?.onboardingTemplate?.new}
            </span>
          </Col>
        </Row>
      </div>
    );
  }, [action, data, translate]);

  const displayOnboardings = useMemo(() => {
    return (
      <div className="mrg-btm-20">
        <Row className="text-dark no-pdd-horizon no-mrg-horizon mrg-btm-5">
          <Col span={24} className="no-pdd-horizon no-mrg-horizon">
            <strong>{translate(titleTranslator?.sources)}</strong>
          </Col>
        </Row>
        {data?.onboardings?.map((onb, index) => (
          <Row
            className={`mrg-btm-10 pdd-top-10 no-pdd-horizon no-mrg-horizon ${
              index !== 0 && 'border-divider'
            }`}
          >
            <Col span={24} className="flex start-center no-pdd-horizon no-mrg-horizon">
              <i className={`${statusClassName[onb?.action]} mrg-right-10`} />
              {onb?.title}
            </Col>
          </Row>
        ))}
      </div>
    );
  }, [data, translate]);

  const displayComplianceRules = useMemo(() => {
    return (
      <div className="mrg-btm-20">
        <Row className="text-dark no-pdd-horizon no-mrg-horizon mrg-btm-5">
          <Col span={12} className="no-pdd-horizon no-mrg-horizon">
            <strong>{translate(titleTranslator?.complianceRules)}</strong>
          </Col>
          <Col span={6} className="no-pdd-horizon no-mrg-horizon">
            <strong>
              {translate(
                'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.columns.action'
              )}
            </strong>
          </Col>
          {displayCustomStatus && (
            <Col span={6} className="no-pdd-horizon no-mrg-horizon">
              <strong>
                {translate(
                  'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.columns.customStatus'
                )}
              </strong>
            </Col>
          )}
        </Row>
        {data?.complianceRules?.map((rule, index) => (
          <Row
            className={`mrg-btm-10 pdd-top-10 no-pdd-horizon no-mrg-horizon ${
              index !== 0 && 'border-divider'
            }`}
          >
            <Col span={12} className="flex start-center no-pdd-horizon no-mrg-horizon">
              <i className={`${statusClassName[rule?.action]} mrg-right-10`} />
              {rule?.title}
            </Col>
            <Col span={6} className="flex start-center no-pdd-horizon no-mrg-horizon">
              {!!rule?.old?.actionWhenInvalid &&
                translate(actionWhenInvalidTranslator[rule?.old?.actionWhenInvalid])}
              {!!rule?.old?.actionWhenInvalid &&
                !!rule?.new?.actionWhenInvalid &&
                rule?.old?.actionWhenInvalid !== rule?.new?.actionWhenInvalid && (
                  <i className="caf-ic_arrow-right mrg-horizon-5" />
                )}
              {!!rule?.new?.actionWhenInvalid &&
                rule?.old?.actionWhenInvalid !== rule?.new?.actionWhenInvalid &&
                translate(actionWhenInvalidTranslator[rule?.new?.actionWhenInvalid])}
            </Col>
            {displayCustomStatus && (
              <Col span={6} className="flex start-center no-pdd-horizon no-mrg-horizon">
                {!!rule?.old?.customStatus && rule?.old?.customStatus}
                {!!rule?.old?.customStatus &&
                  !!rule?.new?.customStatus &&
                  rule?.old?.customStatus !== rule?.new?.customStatus && (
                    <i className="caf-ic_arrow-right mrg-horizon-5" />
                  )}
                {!!rule?.new?.customStatus &&
                  rule?.old?.customStatus !== rule?.new?.customStatus &&
                  rule?.new?.customStatus}
              </Col>
            )}
          </Row>
        ))}
      </div>
    );
  }, [data, displayCustomStatus, translate]);

  const displayProfileRules = useMemo(() => {
    return (
      <div className="mrg-btm-20">
        <Row className="text-dark no-pdd-horizon no-mrg-horizon mrg-btm-5">
          <Col span={12} className="no-pdd-horizon no-mrg-horizon">
            <strong>{translate(titleTranslator?.profileRules)}</strong>
          </Col>
          <Col span={6} className="no-pdd-horizon no-mrg-horizon">
            <strong>
              {translate(
                'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.columns.action'
              )}
            </strong>
          </Col>
          {displayCustomStatus && (
            <Col span={6} className="no-pdd-horizon no-mrg-horizon">
              <strong>
                {translate(
                  'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.columns.customStatus'
                )}
              </strong>
            </Col>
          )}
        </Row>
        {data?.profileRules?.map((rule, index) => (
          <Row
            className={`mrg-btm-10 pdd-top-10 no-pdd-horizon no-mrg-horizon ${
              index !== 0 && 'border-divider'
            }`}
          >
            <Col span={12} className="flex start-center no-pdd-horizon no-mrg-horizon">
              <i className={`${statusClassName[rule?.action]} mrg-right-10`} />
              {profileRulesInitialValue.find((pr) => pr?.rule === rule.title)?.title ||
                qsaRulesInitialValue.find((pr) => pr?.rule === rule.title)?.title}
            </Col>
            <Col span={6} className="flex start-center no-pdd-horizon no-mrg-horizon">
              {!!rule?.old?.actionWhenInvalid &&
                translate(actionWhenInvalidTranslator[rule?.old?.actionWhenInvalid])}
              {!!rule?.old?.actionWhenInvalid &&
                !!rule?.new?.actionWhenInvalid &&
                rule?.old?.actionWhenInvalid !== rule?.new?.actionWhenInvalid && (
                  <i className="caf-ic_arrow-right mrg-horizon-5" />
                )}
              {!!rule?.new?.actionWhenInvalid &&
                rule?.old?.actionWhenInvalid !== rule?.new?.actionWhenInvalid &&
                translate(actionWhenInvalidTranslator[rule?.new?.actionWhenInvalid])}
            </Col>
            {displayCustomStatus && (
              <Col span={6} className="flex start-center no-pdd-horizon no-mrg-horizon">
                {!!rule?.old?.customStatus && rule?.old?.customStatus}
                {!!rule?.old?.customStatus &&
                  !!rule?.new?.customStatus &&
                  rule?.old?.customStatus !== rule?.new?.customStatus && (
                    <i className="caf-ic_arrow-right mrg-horizon-5" />
                  )}
                {!!rule?.new?.customStatus &&
                  rule?.old?.customStatus !== rule?.new?.customStatus &&
                  rule?.new?.customStatus}
              </Col>
            )}
          </Row>
        ))}
      </div>
    );
  }, [
    data,
    displayCustomStatus,
    profileRulesInitialValue,
    qsaRulesInitialValue,
    translate,
  ]);

  const displayBackgroundChecking = useMemo(() => {
    return (
      <div className="mrg-btm-20">
        <Row className="text-dark no-pdd-horizon no-mrg-horizon mrg-btm-5">
          <Col span={12} className="no-pdd-horizon no-mrg-horizon">
            <strong>Background Checking:</strong>
          </Col>
          <Col span={6} className="no-pdd-horizon no-mrg-horizon">
            <strong>
              {translate(
                'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.columns.recurrence'
              )}
            </strong>
          </Col>
          <Col span={6} className="no-pdd-horizon no-mrg-horizon">
            <strong>
              {translate(
                'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.columns.onCreation'
              )}
            </strong>
          </Col>
        </Row>
        {data?.backgroundChecking?.map((bac, ind) => (
          <>
            {bac?.items?.map((item, index) => (
              <Row
                className={`mrg-btm-10 pdd-top-10 no-pdd-horizon no-mrg-horizon ${
                  (index !== 0 || ind !== 0) && 'border-divider'
                }`}
              >
                <Col
                  span={12}
                  className="flex start-center no-pdd-horizon no-mrg-horizon"
                >
                  <i className={`${statusClassName[item?.action]} mrg-right-10`} />
                  {item?.title}
                </Col>
                <Col span={6} className="flex start-center no-pdd-horizon no-mrg-horizon">
                  <span>
                    {!!item?.old?.recurrence &&
                      translate(recurrenceTranslator[item?.old?.recurrence])}
                    {!!item?.old?.recurrence &&
                      !!item?.new?.recurrence &&
                      item?.new?.recurrence !== item?.old?.recurrence && (
                        <i className="caf-ic_arrow-right mrg-horizon-5" />
                      )}
                    {!!item?.new?.recurrence &&
                      item?.new?.recurrence !== item?.old?.recurrence &&
                      translate(recurrenceTranslator[item?.new?.recurrence])}
                  </span>
                </Col>
                <Col span={6} className="flex start-center no-pdd-horizon no-mrg-horizon">
                  {displayOnProfileCreation(item)}
                </Col>
              </Row>
            ))}
          </>
        ))}
      </div>
    );
  }, [data, translate, displayOnProfileCreation]);

  const displayContentTransactionTemplate = useMemo(() => {
    return (
      <div className="flex flex-column">
        <div>
          {!!data?.name && displayNameChange}
          {!!data?.onboardingTemplate && displayTemplateOnboarding}
          {!!data?.onboardings?.length && displayOnboardings}
          {!!data?.backgroundChecking?.length && displayBackgroundChecking}
          {!!data?.complianceRules?.length && displayComplianceRules}
          {!!data?.qsaProfileTemplate && displayQsaProfileChange}
          {!!data?.profileRules?.length && displayProfileRules}
          {action === 'UPDATE' &&
            !data?.name &&
            !data?.qsaProfileTemplate &&
            !data?.onboardings?.length &&
            !data?.backgroundChecking?.length &&
            !data?.complianceRules?.length &&
            !data?.profileRules?.length &&
            !data?.onboardingTemplate &&
            translate(
              'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.noChanges'
            )}
        </div>
      </div>
    );
  }, [
    action,
    data,
    displayBackgroundChecking,
    displayComplianceRules,
    displayNameChange,
    displayOnboardings,
    displayProfileRules,
    displayQsaProfileChange,
    displayTemplateOnboarding,
    translate,
  ]);

  const displayContentOnboarding = useMemo(() => {
    return (
      <div>
        <Row className="no-pdd-horizon no-mrg-horizon">
          <Col
            span={24}
            className={`${
              action === 'CREATE' ? 'create-link' : 'info-link'
            } flex start-center no-pdd-horizon no-mrg-horizon`}
          >
            <i
              className={`${
                action === 'CREATE' ? 'caf-ic_add' : 'caf-ic_processing_line'
              } mrg-right-10`}
            />
            <a href={data?.url}>{data?.url}</a>
            <CopyToClipboard
              text={data?.url}
              onCopy={() =>
                message.success(
                  translate(
                    'pages.private.profileModels.profileModelList.components.links.components.cardLink.index.cardLink.copyToClipboard.onCopy.message' // => Link de onboarding copiado para a área de transferência
                  )
                )
              }
            >
              <strong className="mrg-left-10 font-size-14 text-bold pointer">
                {translate(
                  'pages.private.profileModels.profileModelList.components.links.components.cardLink.index.cardLink.copyToClipboard.text' // => Copiar
                )}
              </strong>
            </CopyToClipboard>
          </Col>
        </Row>
      </div>
    );
  }, [action, data, translate]);

  return (
    <Card className="card-new-activity-model">
      <div className="content">
        <Row className="card-new-activity-header no-pdd-horizon no-mrg-horizon">
          <Col span={24} className="no-pdd-horizon no-mrg-horizon">
            <span className="title">
              {title ||
                `${
                  typeTranslator[type]
                    ? translate(typeTranslator[type])
                    : translate(
                        'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.typeTranslator.onboarding'
                      )
                } / ${
                  actionTranslator[action]
                    ? translate(actionTranslator[action])
                    : translate(
                        'pages.private.profileModels.profileModelList.components.activities.components.cardRecurrenceEvent.components.cardLogger.actionTranslator.create'
                      )
                }`}
            </span>
            <strong className="subtitle">{displayedTitle}</strong>
          </Col>
        </Row>

        <Row className="no-pdd-horizon no-mrg-horizon">
          <Col span={24} className="no-pdd-horizon no-mrg-horizon">
            {type === 'TRANSACTION_TEMPLATE' ? (
              <div
                className={action === 'UPDATE' || action === 'CREATE' ? 'mrg-top-20' : ''}
              >
                {displayContentTransactionTemplate}
              </div>
            ) : (
              <div className="mrg-top-20">{displayContentOnboarding}</div>
            )}
          </Col>
        </Row>
      </div>
      {children}
    </Card>
  );
};

CardLogger.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  transactionTemplate: PropTypes.objectOf(PropTypes.any),
};

CardLogger.defaultProps = {
  title: undefined,
  action: undefined,
  data: {},
  transactionTemplate: {},
};

export default CardLogger;
