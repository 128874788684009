/* eslint-disable react/prop-types */
import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, message } from 'antd';
import { useFetch } from 'services/hooks';

import PageTitle from 'components/PageTitle';

const I18N_BASE_PATH =
  'pages.private.liveness.whiteListTab.components.cardWhiteList.components.removeWhiteListModal.index';

const RemoveWhiteList = ({ data, reloadList }) => {
  const { t: translate } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const { delete: _delete, loading } = useFetch();

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      await _delete({
        url: `/white-list/${data.id}`,
      });
      reloadList();
      closeModal();

      message.success(translate(`${I18N_BASE_PATH}.message.success`));
    } catch (error) {
      //
    }
  }, [_delete, closeModal, data, reloadList, translate]);

  const RemoveWhiteListModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        onCancel={closeModal}
        footer={null}
        style={{ top: 40 }}
        width={700}
      >
        <div className="no-mrg no-pdd">
          <PageTitle
            title={translate(`${I18N_BASE_PATH}.pageTitle.title`)}
            subtitle={translate(`${I18N_BASE_PATH}.pageTitle.subtitle`)}
          />
        </div>
        <div className="gx-flex-row gx-justify-content-end mrg-top-50">
          <Button type="default" onClick={closeModal} className="btn-cancel">
            {translate(`${I18N_BASE_PATH}.buttons.cancel`)}
          </Button>

          <Button
            type="primary"
            onClick={handleSubmit}
            loading={loading}
            disabled={loading}
          >
            {translate(`${I18N_BASE_PATH}.buttons.remove`)}
          </Button>
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, translate, handleSubmit, loading]
  );

  return { openModal, closeModal, RemoveWhiteListModal };
};

export default RemoveWhiteList;
