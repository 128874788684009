import React from 'react';
import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import NoResults from 'components/NoResults';
import NotAvailable from 'components/NotAvailable';
import { Divider } from 'antd';
import CollapseList from '../../../../CollapseList';
import PreviousDirectors from './PreviousDirectors';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companyDetails.subPages.people.creditReport';

const DirectorsContent = ({
  currentDirectorsLength,
  previousDirectorsLength,
  previousDirectorsList,
  creditReportData,
  collapsePrintSuffix,
  collapseOpened,
  print = false,
}) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDateOnly } = useI18nFormatters();

  if (!currentDirectorsLength && !previousDirectorsLength) {
    return <NoResults />;
  }

  return (
    <>
      {currentDirectorsLength > 0 && (
        <>
          {print ? null : (
            <div className="current-tab-infos">
              <h4>
                {translate(
                  `${I18N_BASE_PATH}.tabs.directors.currentDirectors.title`,
                  'Current Directors'
                )}
              </h4>
            </div>
          )}
          <CollapseList
            print={print}
            collapsePrintSuffix={collapsePrintSuffix}
            collapseOpened={collapseOpened}
            columns={[
              {
                prop: 'name',
                title: translate(
                  `${I18N_BASE_PATH}.tabs.directors.currentDirectors.columns.name`,
                  'Name'
                ),
                span: 10,
              },
              {
                prop: 'dateOfBirth',
                title: translate(
                  `${I18N_BASE_PATH}.tabs.directors.currentDirectors.columns.dateOfBirth`,
                  'Date of birth'
                ),
                span: 4,
              },
              {
                prop: 'gender',
                title: translate(
                  `${I18N_BASE_PATH}.tabs.directors.currentDirectors.columns.gender`,
                  'Gender'
                ),
                span: 4,
              },
              {
                prop: 'identifiers',
                title: translate(
                  `${I18N_BASE_PATH}.tabs.directors.currentDirectors.columns.identifiers`,
                  'Identifiers'
                ),
                span: 6,
              },
            ]}
            list={creditReportData?.currentDirectors?.map((director, index) => {
              return {
                header: {
                  index,
                  id: director?.id,
                  name: director?.name || <NotAvailable />,
                  gender: director?.gender || <NotAvailable />,
                  dateOfBirth: i18nFormatDateOnly(director?.birthDate, 1) || (
                    <NotAvailable />
                  ),
                  identifiers: [director?.id, director?.nationality],
                },
                body: [
                  {
                    title: translate(
                      `${I18N_BASE_PATH}.tabs.directors.currentDirectors.collapse.title`,
                      'Title'
                    ),
                    content: director?.title,
                  },
                  {
                    title: translate(
                      `${I18N_BASE_PATH}.tabs.directors.currentDirectors.collapse.postcode`,
                      'Postcode'
                    ),
                    content: director?.address?.postalCode,
                  },
                  {
                    title: translate(
                      `${I18N_BASE_PATH}.tabs.directors.currentDirectors.collapse.function`,
                      'Function'
                    ),
                    content: director?.additionalData?.occupation,
                  },
                  {
                    title: translate(
                      `${I18N_BASE_PATH}.tabs.directors.currentDirectors.collapse.nationality`,
                      'Nationality'
                    ),
                    content: director?.nationality,
                  },
                  {
                    title: translate(
                      `${I18N_BASE_PATH}.tabs.directors.currentDirectors.collapse.occupation`,
                      'Occupation'
                    ),
                    content: director?.additionalData?.positions?.[0]?.name,
                  },
                  {
                    title: translate(
                      `${I18N_BASE_PATH}.tabs.directors.currentDirectors.collapse.appointmentDate`,
                      'Appointment date'
                    ),
                    content: i18nFormatDateOnly(
                      director?.additionalData?.positions?.[0]?.dateAppointed,
                      1
                    ),
                  },
                  {
                    title: translate(
                      `${I18N_BASE_PATH}.tabs.directors.currentDirectors.collapse.presentAppointments`,
                      'Present appointments'
                    ),
                    content: director?.additionalData?.presentAppointments,
                  },
                  {
                    title: translate(
                      `${I18N_BASE_PATH}.tabs.directors.currentDirectors.collapse.latestAdress`,
                      'Latest address'
                    ),
                    content: director?.address?.fullAddress,
                  },
                ],
              };
            })}
          />
        </>
      )}
      <Divider />
      {previousDirectorsLength > 0 && (
        <>
          <div className="current-tab-infos">
            <h4>
              {translate(
                `${I18N_BASE_PATH}.tabs.directors.previousDirectors.title`,
                'Previous Directors'
              )}
            </h4>
          </div>
          <PreviousDirectors
            previousDirectorsList={previousDirectorsList}
            print={print}
          />
        </>
      )}
    </>
  );
};

export default DirectorsContent;
