/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Divider, Collapse } from 'antd';

import EmptyState from 'components/EmptyState';
import EmptyExecutionState from 'components/EmptyExecutionState';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

const { Panel } = Collapse;

const I18N_BASE_PATH =
  'components.dataSourceCards.legalInformation.componentes.ofacSanctionsList.index';

const I18N_BASE_PATH_MODAL =
  'components.dataSourceCards.legalInformation.componentes.ofacSanctionsList.components.modalSanctionsList';

const OFACSanctionsList = ({ source, type, toPrint = false }) => {
  const { t: translate } = useTranslation();
  const sanctionsList = useMemo(() => source.data, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const processesQuerySucceeded = useMemo(
    () =>
      source?.statusCode !== '50' &&
      (source?.statusCode === '01' ||
        source?.available === true ||
        Object.keys(source?.data || {}).length ||
        (Array.isArray(source?.data) && source?.data?.length)),
    [source]
  );

  const hasContent = useMemo(() => sanctionsList?.sanctionsList?.length > 0, [
    sanctionsList,
  ]);

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_file"
      title={translate(`${I18N_BASE_PATH}.title`)}
      toPrint={toPrint}
    >
      <div id="executions-legal-information-processes-container">
        <Col span={24}>
          {sanctionsList && (
            <>
              {processesQuerySucceeded ? (
                <>
                  {hasContent ? (
                    <>
                      {sanctionsList?.sanctionsList?.map((sanction, index) => (
                        <div>
                          <div className="mrg-btm-10">
                            <b className="text-dark font-size-16">
                              {sanction?.entityName
                                ? sanction?.entityName
                                : `${sanction?.firstName || sanction?.firtName} ${
                                    sanction?.lastName
                                  }`}
                            </b>
                          </div>
                          <Collapse defaultActiveKey={['1', '2', '3', '4']} ghost>
                            <Panel
                              header={
                                <b>
                                  <i className="caf-ic_file" />{' '}
                                  {translate(`${I18N_BASE_PATH_MODAL}.details`)}
                                </b>
                              }
                              key="1"
                              showArrow={false}
                            >
                              {!sanction?.entityName ? (
                                <Row>
                                  <Col span={12}>
                                    <b>{translate(`${I18N_BASE_PATH_MODAL}.type`)}</b>
                                    <span>{sanction?.type}</span>
                                  </Col>
                                  <Col span={12}>
                                    <b>{translate(`${I18N_BASE_PATH_MODAL}.list`)}</b>
                                    <span>{sanction?.list}</span>
                                  </Col>
                                  <Col span={12}>
                                    <b>
                                      {translate(`${I18N_BASE_PATH_MODAL}.birthDate`)}
                                    </b>
                                    {Array.isArray(sanction?.dateOfBirth) &&
                                    sanction?.dateOfBirth?.length > 1 ? (
                                      sanction?.dateOfBirth?.map((value) => (
                                        <span>{value?.dateOfBirth} </span>
                                      ))
                                    ) : (
                                      <span>{sanction?.dateOfBirth}</span>
                                    )}
                                  </Col>
                                  <Col span={12}>
                                    <b>
                                      {translate(`${I18N_BASE_PATH_MODAL}.nationality`)}
                                    </b>
                                    <span>{sanction?.nationality || '-'}</span>
                                  </Col>
                                  <Col span={12}>
                                    <b>
                                      {translate(`${I18N_BASE_PATH_MODAL}.placeOfBirth`)}
                                    </b>
                                    {Array.isArray(sanction?.placeOfBirth) &&
                                    sanction?.placeOfBirth?.length > 1 ? (
                                      sanction?.placeOfBirth?.map((value) => (
                                        <span>{value?.placeOfBirth} </span>
                                      ))
                                    ) : (
                                      <span>{sanction?.placeOfBirth}</span>
                                    )}
                                  </Col>
                                  <Col span={12}>
                                    <b>{translate(`${I18N_BASE_PATH_MODAL}.program`)}</b>
                                    <span>
                                      {sanction?.program ||
                                        sanction?.programs?.map((program) => (
                                          <span>{program}</span>
                                        )) ||
                                        '-'}
                                    </span>
                                  </Col>
                                  <Col span={12}>
                                    <b>
                                      {translate(`${I18N_BASE_PATH_MODAL}.citizenship`)}
                                    </b>
                                    <span>{sanction?.citizenship || '-'}</span>
                                  </Col>
                                  <Col span={12}>
                                    <b>{translate(`${I18N_BASE_PATH_MODAL}.remarks`)}</b>
                                    <span>{sanction?.remarks || '-'}</span>
                                  </Col>
                                </Row>
                              ) : (
                                <Row>
                                  <Col span={12}>
                                    <b>{translate(`${I18N_BASE_PATH_MODAL}.type`)}</b>
                                    <span>{sanction?.type}</span>
                                  </Col>
                                  <Col span={12}>
                                    <b>{translate(`${I18N_BASE_PATH_MODAL}.list`)}</b>
                                    <span>{sanction?.list}</span>
                                  </Col>
                                  <Col span={12}>
                                    <b>{translate(`${I18N_BASE_PATH_MODAL}.program`)}</b>
                                    <span>{sanction?.program}</span>
                                  </Col>
                                  <Col span={12}>
                                    <b>{translate(`${I18N_BASE_PATH_MODAL}.remarks`)}</b>
                                    <span>{sanction?.remarks}</span>
                                  </Col>
                                </Row>
                              )}
                            </Panel>
                            {sanction?.identifications && (
                              <Panel
                                header={
                                  <b>
                                    <i className="caf-ic_onboardind_big" />{' '}
                                    {translate(`${I18N_BASE_PATH_MODAL}.identifications`)}
                                  </b>
                                }
                                key="2"
                                showArrow={false}
                              >
                                {sanction?.identifications?.map(
                                  (identification, indexIdentifications) => (
                                    <Row>
                                      <Col span={12}>
                                        <b>{translate(`${I18N_BASE_PATH_MODAL}.type`)}</b>
                                        <span>
                                          {identification?.type ||
                                            identification?.idType ||
                                            '-'}
                                        </span>
                                      </Col>
                                      <Col span={12}>
                                        <b>
                                          {translate(`${I18N_BASE_PATH_MODAL}.number`)}
                                        </b>
                                        <span>
                                          {identification?.number ||
                                            identification?.idNumber ||
                                            '-'}
                                        </span>
                                      </Col>
                                      <Col span={12}>
                                        <b>
                                          {translate(
                                            `${I18N_BASE_PATH_MODAL}.originCountry`
                                          )}
                                        </b>
                                        <span>
                                          {sanction?.country ||
                                            identification?.idCountry ||
                                            '-'}
                                        </span>
                                      </Col>
                                      <Col span={12}>
                                        <b>
                                          {translate(`${I18N_BASE_PATH_MODAL}.issueDate`)}
                                        </b>
                                        <span>{sanction?.issueDate || '-'}</span>
                                      </Col>
                                      <Col span={12}>
                                        <b>
                                          {translate(
                                            `${I18N_BASE_PATH_MODAL}.expireDate`
                                          )}
                                        </b>
                                        <span>{sanction?.expireDate || '-'}</span>
                                      </Col>
                                      {indexIdentifications + 1 <
                                      sanction?.identifications?.length ? (
                                        <Divider />
                                      ) : (
                                        <></>
                                      )}
                                    </Row>
                                  )
                                )}
                              </Panel>
                            )}
                            {sanction?.aliases && (
                              <Panel
                                header={
                                  <b>
                                    <i className="caf-ic_person" />{' '}
                                    {translate(`${I18N_BASE_PATH_MODAL}.aliases`)}
                                  </b>
                                }
                                key="3"
                                showArrow={false}
                              >
                                {sanction?.aliases?.map((alias, indexAlias) => (
                                  <Row>
                                    <Col span={24}>{alias?.name}</Col>
                                    <Col span={12}>
                                      <b>{translate(`${I18N_BASE_PATH_MODAL}.type`)}</b>
                                      <span>{alias?.type}</span>
                                    </Col>
                                    <Col span={12}>
                                      <b>
                                        {translate(`${I18N_BASE_PATH_MODAL}.category`)}
                                      </b>
                                      <span>{alias?.category}</span>
                                    </Col>
                                    {indexAlias + 1 < sanction?.aliases?.length ? (
                                      <Divider />
                                    ) : (
                                      <></>
                                    )}
                                  </Row>
                                ))}
                              </Panel>
                            )}
                            {sanction?.addresses && (
                              <Panel
                                header={
                                  <b>
                                    <i className="caf-ic_map_pin" />{' '}
                                    {translate(`${I18N_BASE_PATH_MODAL}.addresses`)}
                                  </b>
                                }
                                key="4"
                                showArrow={false}
                              >
                                {sanction?.addresses?.map((address, indexAddress) => (
                                  <>
                                    <Row>
                                      <Col span={24} className="mrg-btm-10">
                                        <b className="text-dark">
                                          {address?.address || address?.address1 || '-'}
                                        </b>
                                      </Col>
                                      <Col span={12}>
                                        <b>{translate(`${I18N_BASE_PATH_MODAL}.city`)}</b>
                                        <span>{address?.city || '-'}</span>
                                      </Col>
                                      <Col span={12}>
                                        <b>
                                          {translate(
                                            `${I18N_BASE_PATH_MODAL}.stateOrProvince`
                                          )}
                                        </b>
                                        <span>{address?.stateOrProvince || '-'}</span>
                                      </Col>
                                      <Col span={12}>
                                        <b>
                                          {translate(
                                            `${I18N_BASE_PATH_MODAL}.postalCode`
                                          )}
                                        </b>
                                        <span>{address?.postalCode || '-'}</span>
                                      </Col>
                                      <Col span={12}>
                                        <b>
                                          {translate(`${I18N_BASE_PATH_MODAL}.country`)}
                                        </b>
                                        <span>{address?.country || '-'}</span>
                                      </Col>
                                    </Row>
                                    {indexAddress + 1 < sanction?.addresses?.length ? (
                                      <Divider />
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ))}
                              </Panel>
                            )}
                          </Collapse>
                          {index + 1 < sanctionsList?.sanctionsList?.length ? (
                            <Divider />
                          ) : (
                            <></>
                          )}
                        </div>
                      ))}
                    </>
                  ) : (
                    <EmptyState
                      type="empty-success"
                      description={translate(
                        `components.dataSourceCards.legalInformation.componentes.processes.index.processes.processesAltQuerySucceeded.emptyState.description`,
                        { type }
                      )}
                      className="mrg-btm-20 mrg-top-0"
                    />
                  )}
                </>
              ) : (
                <EmptyExecutionState
                  statusCode={statusCode}
                  message={source?.message || sanctionsList?.message}
                />
              )}
            </>
          )}
        </Col>
      </div>
    </DataSourceCardCollapsePanel>
  );
};

export default OFACSanctionsList;
