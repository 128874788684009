import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Statistic } from 'antd';
import PropTypes from 'prop-types';

import './styles.less';

const { Countdown } = Statistic;

const CustomCountdown = ({
  prefix,
  suffix,
  onFinish,
  amountOfSeconds = 45,
  format = 'ss',
}) => {
  const [countdown, setCountdown] = useState(Date.now() + amountOfSeconds * 1000);
  const { t: translate } = useTranslation();

  const handleFinish = () => {
    onFinish();
    setCountdown(Date.now() + amountOfSeconds * 1000);
  };

  return (
    <span id="countdown-to-update">
      {translate(prefix)}
      <Countdown value={countdown} format={format} onFinish={handleFinish} />
      {translate(suffix)}
    </span>
  );
};

CustomCountdown.propTypes = {
  prefix: PropTypes.string.isRequired,
  suffix: PropTypes.string.isRequired,
  format: PropTypes.string,
  amountOfSeconds: PropTypes.number,
  onFinish: PropTypes.func.isRequired,
};

CustomCountdown.defaultProps = {
  format: 'ss',
  amountOfSeconds: 45,
};

export default CustomCountdown;
