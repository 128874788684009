/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import { isEmpty } from 'utils/isObjectEmpty';
import { toFormatPercentage } from 'utils/formatters';

import TableInfoCard from 'pages/private/Profiles/components/TableInfoCard/Column';

const ValidationsPfBiometriaSerasa = ({ data }) => {
  const { t: translate } = useTranslation();

  const pfBiometriaSerasa = useMemo(() => data?.sections?.pfBiometriaSerasa?.data || {}, [
    data,
  ]);

  return (
    <Row gutter={[32, 24]}>
      {(!pfBiometriaSerasa || isEmpty(pfBiometriaSerasa)) && (
        <Col span={24}>
          <span className="text-dark gx-font-italic font-size-15">
            {translate(
              'pages.private.executions.components.tabs.components.documentsValidations.components.shared.validationspfBiometriaSerasa.index.pfBiometriaSerasa.title'
            )}
          </span>
        </Col>
      )}

      {pfBiometriaSerasa && !isEmpty(pfBiometriaSerasa) && (
        <>
          <TableInfoCard
            span={8}
            title="Ranking"
            content={pfBiometriaSerasa?.Rank}
            bordered={false}
          />
          <TableInfoCard
            span={8}
            title="Liveness"
            content={toFormatPercentage(pfBiometriaSerasa?.Liveness, translate)}
            bordered={false}
          />
        </>
      )}
    </Row>
  );
};

export default ValidationsPfBiometriaSerasa;
