export const download = async (uri, name, newTab = false) => {
  if (!uri) return Promise.reject(new Error('URI not found'));
  return new Promise((resolve, reject) => {
    try {
      const a = document.createElement('a');
      a.href = uri;
      a.setAttribute('download', name);
      if (newTab) a.setAttribute('target', '_blank');
      a.click();
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
  // return fetch(uri)
  //   .then((file) => file.blob())
  //   .then((blob) => {
  //     const a = document.createElement('a');
  //     a.href = URL.createObjectURL(blob);
  //     a.setAttribute('download', name);
  //     a.click();
  //   });
};
