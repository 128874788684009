/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Divider } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import { handlerAssociateRegister } from 'utils/associateRegisters';
import { toFormatDocument } from 'utils/formatters';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

import PageTitle from 'components/PageTitle';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import './styles.less';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.shared.protests.protestsItems.index';

const ProtestsItems = ({ data: protestsSection, toPrint }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatCurrency, i18nFormatDate } = useI18nFormatters();

  const customTitle = useMemo(() => {
    return toPrint
      ? translate(`${I18N_BASE_PATH}.customTitle.hasToPrint`)
      : translate(`${I18N_BASE_PATH}.customTitle.hasNotToPrint`);
  }, [toPrint, translate]);

  const notaries = useMemo(() => protestsSection?.data?.notaries || [], [
    protestsSection,
  ]);

  const protests = useMemo(() => {
    if (!notaries || notaries?.length === 0) return [];
    const protestsArray = [];

    notaries?.forEach((n) => n?.protests?.forEach((p) => protestsArray.push(p)));
    return protestsArray;
  }, [notaries]);

  const handlerTotalValue = useMemo(() => {
    if (!protests) return null;

    const values = [];

    protests?.forEach((protest) =>
      values.push(Number(protest?.value || protest?.protestValue))
    );

    const total = values.reduce((acc, cur) => acc + cur, 0);

    return i18nFormatCurrency(total, 'pt-BR');
  }, [i18nFormatCurrency, protests]);

  return (
    <div id="executions-protests-items">
      <PageTitle
        title={customTitle}
        subtitle={`${
          protestsSection?.query?.endAt
            ? `${translate(
                `${I18N_BASE_PATH}.protestsItems.pageTitle.subtitle.lastUpdateDate`
              )} ${i18nFormatDate(protestsSection?.query?.endAt, 'default')}`
            : `${translate(`${I18N_BASE_PATH}.protestsItems.pageTitle.subtitle.default`)}`
        }`}
      />

      <Row className="protests-items-title reset-mrg">
        <Col span={16}>
          <strong>
            {handlerAssociateRegister(
              protests?.length,
              translate(`${I18N_BASE_PATH}.protestsItems.protests`),
              translate
            )}
          </strong>
        </Col>

        <Col span={8} className="end">
          <strong>
            {translate(`${I18N_BASE_PATH}.protestsItems.handlerTotalValue`)}:{' '}
            {handlerTotalValue || '-'}
          </strong>
        </Col>
      </Row>

      <Divider className="custom-divider" />

      {notaries?.length > 0 &&
        notaries?.map((notary) =>
          notary?.protests?.map((protest) => (
            <Row
              key={`${protest?.debtorDocument}-${protest?.registrationDate}`}
              className="protests-items-data reset-mrg"
            >
              <ColumnLabel
                description={notary?.description || '-'}
                type="danger"
                span={16}
              >
                {`${capitalizeFirstLetter(notary?.address)}, ${notary?.city}, ${
                  notary?.state
                }` || '-'}
              </ColumnLabel>
              <Col span={8} className="protest-item-col end">
                <span className="font-size-15 bold">
                  {i18nFormatCurrency(Number(protest?.value, 'pt-BR')) ||
                    i18nFormatCurrency(Number(protest?.protestValue, 'pt-BR')) ||
                    '-'}
                </span>
              </Col>
              <Col span={12} className="protest-item-col">
                <strong className="mrg-left-15">
                  {translate(`${I18N_BASE_PATH}.protestsItems.debtorsDocument`)}:
                </strong>{' '}
                <span>
                  {toFormatDocument(
                    protest?.debtorDocument,
                    translate(`${I18N_BASE_PATH}.protestsItems.debtorDocument`),
                    true
                  )}
                </span>
              </Col>
              <Col span={6} className="protest-item-col">
                <strong>{translate(`${I18N_BASE_PATH}.protestsItems.inclusion`)}:</strong>{' '}
                <span>{i18nFormatDate(protest?.date, 1)}</span>
              </Col>
              {/* <Col span={6} className="protest-item-col end">
                <strong>Validade:</strong>{' '}
                <span>{i18nFormatDate(protest?.dueDate, 1)}</span>
              </Col> */}
            </Row>
          ))
        )}
    </div>
  );
};

export default ProtestsItems;
