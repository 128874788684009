import React from 'react';
import PropTypes from 'prop-types';

import './styles.less';

const translator = {
  ONBOARDING: 'caf-ic_link',
  TRANSACTION_TEMPLATE: 'caf-ic_admin',
};

const IconStatus = ({ type, action }) => {
  return (
    <div id="icon-status-content">
      <i className={`${action} ${translator[type]} ` || `${action} caf-ic_link `} />
    </div>
  );
};

IconStatus.propTypes = {
  type: PropTypes.string,
  action: PropTypes.string.isRequired,
};

IconStatus.defaultProps = {
  type: 'ONBOARDING',
};

export default IconStatus;
