import React from 'react';
import { InputDataMetadata, InputDataParameters } from '../../TabsComponent/InputData';

const CompanyInputDataPrint = ({ data, itemsToExport }) => {
  return (
    <div>
      {itemsToExport.items.parameters.value && (
        <InputDataParameters
          origin={data?.metadata?.origin}
          parameters={data?.parameters}
        />
      )}

      {itemsToExport.items.metadata.value && (
        <InputDataMetadata origin={data?.metadata?.origin} metadata={data?.metadata} />
      )}
    </div>
  );
};

export default CompanyInputDataPrint;
