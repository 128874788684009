/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// import { useExecution } from 'hooks/execution';

import EmptyExecutionState from 'components/EmptyExecutionState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.people.components.jointDebts.index';

const JointDebts = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  // const { execution } = useExecution();

  // const joinDebts = useMemo(
  //   () =>
  //     execution?.sections?.pjJointDebtsCertificate ||
  //     execution?.sections?.pfJointDebtsCertificate,
  //   [execution]
  // );
  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const handlerInfos = useMemo(() => {
    if (!queryWorked) return { title: null, type: null };

    if (parsedData?.hasDebts)
      return {
        title: translate(`${I18N_BASE_PATH}.handlerInfos.hasDebts.title`),
        type: 'danger',
      };

    return {
      title: translate(`${I18N_BASE_PATH}.handlerInfos.default.title`),
      type: 'primary',
    };
  }, [parsedData, queryWorked, translate]);

  return (
    <DataSourceCardCollapsePanel
      title={translate(`${I18N_BASE_PATH}.jointDebts.columnLabel.title`)}
      icon="caf-ic_company"
      toPrint={toPrint}
      customId="financialInfo"
    >
      <ColumnLabel
        title={translate(`${I18N_BASE_PATH}.jointDebts.columnLabel.title`)}
        description={handlerInfos?.title}
        type={handlerInfos?.type}
      >
        {queryWorked ? (
          <>
            {parsedData &&
              (!parsedData?.jointDebts || parsedData?.jointDebts?.length === 0) && (
                <span>
                  {translate(`${I18N_BASE_PATH}.jointDebts.columnLabel.hasNotJoinDebts`)}
                </span>
              )}
          </>
        ) : (
          <EmptyExecutionState
            type="empty-recurrence"
            statusCode={statusCode}
            urlId={parsedData?.message || parsedData?.dat?.message}
          />
        )}
      </ColumnLabel>
    </DataSourceCardCollapsePanel>
  );
};

export default JointDebts;
