import React, { useCallback, useRef, useMemo } from 'react';
import { Col, Row, Card, Dropdown, Menu, message } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useFetch } from 'services/hooks';

import useModalConfirmAction from 'components/ModalConfirmAction';
import useModalReasonUpdate from './Modals/ModalReasonUpdate';

const I18N_BASE_PATH = 'pages.private.tagsAndReasons.components.ReasonCard';

const ReasonCard = ({ data, refreshList }) => {
  const { t: translate } = useTranslation();
  const { delete: _delete, loading } = useFetch();
  const { ReasonUpdateModal } = useModalReasonUpdate();

  const refreshListRef = useRef({});
  useMemo(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  const handleDeleteReason = useCallback(async () => {
    try {
      await _delete({ url: `reasons/${data?._id}` });
      message.success(translate(`${I18N_BASE_PATH}.handleDeleteMessage.success`));
    } catch (error) {
      message.error(translate(`${I18N_BASE_PATH}.handleDeleteMessage.error`));
    }
    refreshList();
  }, [_delete, data, refreshList, translate]);

  const { openModal: openConfirmModal, ConfirmActionModal } = useModalConfirmAction({
    action: handleDeleteReason,
    refreshListRef: refreshListRef?.current,
    loading,
    danger: true,
    okButton: translate(`${I18N_BASE_PATH}.confirmDeleteModal.okButton`),
    cancelButton: translate(`${I18N_BASE_PATH}.confirmDeleteModal.cancelButton`),
    title: translate(`${I18N_BASE_PATH}.confirmDeleteModal.title`),
    subtitle: translate(`${I18N_BASE_PATH}.confirmDeleteModal.subtitle`),
  });

  return (
    <>
      {ReasonUpdateModal}
      {ConfirmActionModal}
      <Card className="reset-mrg" loading={loading}>
        <Row align="middle" justify="space-between">
          <Col span={20}>
            <Col>
              <span className="text-dark text-bold">{data?.title || '-'}</span>
            </Col>
          </Col>
          <Col span={4}>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="2" onClick={openConfirmModal}>
                    <i className="caf-ic_trash opt-cancel" />
                    <span className="opt-cancel">
                      {translate(`${I18N_BASE_PATH}.dropdownMenuOptions.delete`)}
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
              placement="bottomRight"
            >
              <div style={{ marginLeft: '75%' }} className="btn-more-icon mrg-top-10">
                <i className="caf-ic_more-vertical" />
              </div>
            </Dropdown>
          </Col>
          <Col span={20}>
            <Col>
              <span>{data?.description || '-'}</span>
            </Col>
          </Col>
        </Row>
      </Card>
    </>
  );
};

ReasonCard.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  refreshList: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
};

export default ReasonCard;
