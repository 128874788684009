import React, { createContext, useCallback, useContext, useState } from 'react';

const ActivitiesContext = createContext({});

const ActivitiesProvider = ({ children }) => {
  const [reloadActivities, setReloadActivities] = useState(0);

  const refreshActivities = useCallback(() => {
    setReloadActivities((curState) => curState + 1);
  }, []);

  return (
    <ActivitiesContext.Provider
      value={{
        reloadActivities,
        refreshActivities,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};

function useActivities() {
  const context = useContext(ActivitiesContext);

  if (!context) {
    throw new Error('useActivities must be used within an ActivitiesContext');
  }

  return context;
}

export { ActivitiesProvider, useActivities };
