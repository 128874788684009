import React from 'react';

import Wrapper from 'pages/private/wrapper';
import { CardWrapper } from 'components/CardWrapper';

import List from './components/List';
import Filter from './components/List/components/Filter';

import './styles.less';

const Executions = () => {
  return (
    <Wrapper id="executions-wrapper">
      <div className="executions-list-grid">
        <div className="executions-list-grid-1">
          <CardWrapper hasPadding>
            <List />
          </CardWrapper>
        </div>
        <div className="executions-list-grid-2">
          <CardWrapper hasPadding>
            <Filter />
          </CardWrapper>
        </div>
      </div>
    </Wrapper>
  );
};

export default Executions;
