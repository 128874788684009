import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';
import currencyFormatter from 'utils/currencyFormatter';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import './styles.less';

const InvoiceDetails = ({ data, total, title, hidePrice, currencyCode }) => {
  const { t: translate } = useTranslation();

  const sortedData = useMemo(() => {
    if (!data) return [];

    if (hidePrice) {
      return data.sort((a, b) => b.amount - a.amount);
    }

    return data.sort((a, b) => b.price - a.price);
  }, [data, hidePrice]);

  return (
    <div className="invoice-details">
      <h2 className="invoice-details__title">
        {title ?? translate('pages.private.consumption.invoiceDetails.title')}
      </h2>
      <List
        itemLayout="horizontal"
        header={
          <List.Item
            className={classNames('invoice-details__header invoice-details__item', {
              'invoice-details__item_hide-price': hidePrice,
            })}
          >
            <p className="invoice-details__item_break-word">
              <strong>
                {translate('pages.private.consumption.invoiceDetails.productItem')}
              </strong>
            </p>
            <p>
              <strong>
                {translate('pages.private.consumption.invoiceDetails.quantity')}
              </strong>
            </p>
            {!hidePrice && (
              <p>
                <strong>
                  {translate('pages.private.consumption.invoiceDetails.total')}
                </strong>
              </p>
            )}
          </List.Item>
        }
        footer={
          !hidePrice && (
            <List.Item className="invoice-details__footer">
              <p>
                <strong>
                  {translate('pages.private.consumption.invoiceDetails.total')}
                </strong>
              </p>
              <p>
                <strong>
                  {currencyFormatter.toCurrency(
                    total,
                    currencyFormatter.currencyCodeToSymbol(currencyCode),
                    currencyFormatter.currencyCodeToLocale(currencyCode)
                  )}
                </strong>
              </p>
            </List.Item>
          )
        }
        dataSource={sortedData}
        renderItem={({ id, name, price, amount }) => (
          <List.Item
            key={id}
            className={classNames('invoice-details__item', {
              'invoice-details__item_hide-price': hidePrice,
            })}
          >
            <p className="invoice-details__item_break-word">{name}</p>
            <p>{amount}</p>
            {!hidePrice && (
              <p>
                {currencyFormatter.toCurrency(
                  price,
                  currencyFormatter.currencyCodeToSymbol(currencyCode),
                  currencyFormatter.currencyCodeToLocale(currencyCode)
                )}
              </p>
            )}
          </List.Item>
        )}
      />
    </div>
  );
};

export const invoiceDetailsPropTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
    })
  ).isRequired,
  total: PropTypes.number.isRequired,
  title: PropTypes.string,
  hidePrice: PropTypes.bool,
  currencyCode: PropTypes.string.isRequired,
};

InvoiceDetails.propTypes = invoiceDetailsPropTypes;

InvoiceDetails.defaultProps = {
  title: undefined,
  hidePrice: false,
};

export default InvoiceDetails;
