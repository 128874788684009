/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyExecutionState from 'components/EmptyExecutionState';

import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import useAllProtestsModal from './AllProtestsModal';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.shared.protests.index';

const Protests = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const protests = useMemo(() => source, [source]);

  const notaries = useMemo(() => parsedData?.notaries || [], [parsedData]);

  const protestItems = useMemo(() => {
    if (!notaries || notaries?.length === 0) return [];
    const protestsArray = [];

    notaries?.forEach((n) => n.protests?.forEach((p) => protestsArray.push(p)));
    return protestsArray;
  }, [notaries]);

  const { openModal, AllProtestsModal } = useAllProtestsModal({
    data: protests || {},
  });

  const renderProtestoMessage = useCallback(
    (message) => {
      if (!message) return '';
      if (
        message.toUpperCase() === 'NAO CONSTAM PROCESSOS NOS CARTORIOS PARTICIPANTES' ||
        message.toUpperCase() === 'NÃO CONSTAM PROTESTOS NOS CARTÓRIOS PARTICIPANTES'
      ) {
        return translate(
          `${I18N_BASE_PATH}.renderProtestoMessage.NAO_CONSTAM_PROCESSOS_NOS_CARTORIOS_PARTICIPANTES`
        );
      }
      if (
        message.toUpperCase() === 'CONSTAM PROCESSOS NOS CARTORIOS PARTICIPANTES' ||
        message.toUpperCase() === 'CONSTAM PROTESTOS NOS CARTÓRIOS PARTICIPANTES'
      ) {
        return translate(
          `${I18N_BASE_PATH}.renderProtestoMessage.CONSTAM_PROCESSOS_NOS_CARTORIOS_PARTICIPANTES`
        );
      }
      return message;
    },
    [translate]
  );

  const handlerQuantity = useMemo(() => {
    if (!parsedData || !queryWorked) return { title: null, type: null };

    if (['50', '70'].includes(statusCode)) {
      return {
        title: translate(`${I18N_BASE_PATH}.protests.columnLabel.failedQuery`),
        type: 'secondary',
      };
    }

    if (statusCode === '05') {
      return {
        title: translate(`${I18N_BASE_PATH}.protests.columnLabel.queryInProgress`),
        type: 'info',
      };
    }

    if (!parsedData?.notaries || protestItems.length === 0) {
      return {
        title: translate(`${I18N_BASE_PATH}.handlerQuantity.notaries.title`),
        type: 'primary',
      };
    }

    if (protestItems?.length === 1) {
      return {
        title: translate(`${I18N_BASE_PATH}.handlerQuantity.hasOneProtestItem.title`),
        type: 'danger',
      };
    }

    if (protestItems?.length > 1) {
      return {
        title: translate(
          `${I18N_BASE_PATH}.handlerQuantity.hasMoreThanOneProtestItems.title`,
          { value: protestItems?.length }
        ),
        type: 'danger',
      };
    }

    return {
      title: translate(`${I18N_BASE_PATH}.handlerQuantity.default.title`),
      type: 'primary',
    };
  }, [parsedData, queryWorked, protestItems, translate]);

  const handleOpenModal = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <>
      {AllProtestsModal}
      <DataSourceCardCollapsePanel
        title={translate(`${I18N_BASE_PATH}.protests.columnLabel.title`)}
        icon="caf-ic_file"
        toPrint={toPrint}
        customId="financialInfo"
      >
        <ColumnLabel
          title={translate(`${I18N_BASE_PATH}.protests.columnLabel.title`)}
          description={handlerQuantity?.title}
          type={handlerQuantity?.type}
          onClick={handlerQuantity?.type === 'danger' && handleOpenModal}
        >
          {queryWorked ? (
            <>
              <span>{renderProtestoMessage(parsedData?.baseStatus)}</span>
              {!toPrint && parsedData?.notaries?.length > 0 && (
                <span>
                  {translate(`${I18N_BASE_PATH}.protests.columnLabel.description`)}
                </span>
              )}
            </>
          ) : (
            <EmptyExecutionState
              statusCode={statusCode}
              message={source?.message || parsedData?.message}
            />
          )}
        </ColumnLabel>
      </DataSourceCardCollapsePanel>
    </>
  );
};

export default Protests;
