import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

import { useFetch } from 'services/hooks';

import DottedLabel from 'components/DottedLabel';

import { validationStatus } from 'utils/handlerStatus';
import { toMaskedCpf } from 'utils/formatters';

import defaultImage from 'assets/images/profile-default.png';

function QSAPartnerDetails({ partner }) {
  const { t: translate } = useTranslation();

  const { get } = useFetch();
  const [partnerDetails, setPartnerDetails] = useState({});
  const [loading, setLoading] = useState(false);

  useMemo(async () => {
    setLoading(true);
    if (partner?.profileId) {
      const response = await get({
        url: `/people/${partner?.profileId}`,
      });

      setPartnerDetails(response.data);
    } else {
      setPartnerDetails(partner);
    }
    setLoading(false);
  }, [get, partner]);

  return partner?.profileId ? (
    <Link
      to={`/profile/people/${partner?.profileId}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="partner-card">
        <div className="partner-card-content">
          <img
            className={`partner-image ${!partnerDetails?.image ? 'grayscale-95' : ''}`}
            src={!loading && partnerDetails?.image ? partnerDetails?.image : defaultImage}
            alt={partner?.name || ''}
          />
          <div className="partner-info-container">
            <div className="partner-info">
              <div className="partner-name-function">
                {partner?.name || '-'} {partner?.function && `(${partner?.function})`}
              </div>
              {partner?.onboardingId ? (
                <DottedLabel
                  className="partner-status"
                  key={partner?.profileId}
                  description={translate(
                    validationStatus(partner?.status || 'PROCESSING').title
                  )}
                  type={validationStatus(partner?.status || 'PROCESSING').type}
                />
              ) : (
                <></>
              )}
            </div>
            <span className="partner-cpf">
              {partner?.cpf ? (
                toMaskedCpf(partner?.cpf || '00000000000')
              ) : (
                <p className="gx-font-italic">
                  {translate(
                    'components.dataSourceCards.basicInfos.components.company.qsaPartnerDetails.qsaPartnerDetails.hasPartnerProfileId.hasNotPartnerCpf'
                  )}
                </p>
              )}
            </span>
          </div>
          {partner.profileId && (
            <Tooltip className="partner-open-profile-icon" title="Abrir perfil">
              <i className="caf-ic_arrow_right" />
            </Tooltip>
          )}
        </div>
      </div>
    </Link>
  ) : (
    <div className="partner-card">
      <div className="partner-card-content">
        <img
          className={`partner-image ${!partnerDetails?.image ? 'grayscale-95' : ''}`}
          src={!loading && partnerDetails?.image ? partnerDetails?.image : defaultImage}
          alt={partner?.name || ''}
        />
        <div className="partner-info-container">
          <div className="partner-info">
            <div className="partner-name-function">
              <span>{partner?.name || '-'}</span>
              <span className="partner-fuction">
                {partner?.function && `(${partner?.function})`}
              </span>
            </div>
            {partner?.onboardingId ? (
              <DottedLabel
                className="partner-status"
                key={partner?.profileId}
                description={translate(
                  validationStatus(partner?.status || 'WAITING_DOCUMENTS').title
                )}
                type={validationStatus(partner?.status || 'WAITING_DOCUMENTS').type}
              />
            ) : (
              <></>
            )}
          </div>
          <span className="partner-cpf">
            {partner?.cpf ? (
              toMaskedCpf(partner?.cpf || '00000000000')
            ) : (
              <p className="gx-font-italic">
                {translate(
                  'components.dataSourceCards.basicInfos.components.company.qsaPartnerDetails.qsaPartnerDetails.hasNotPartnerProfileId.hasNotPartnerCpf'
                )}
              </p>
            )}
          </span>
        </div>
        {partner.profileId && (
          <Tooltip className="partner-open-profile-icon" title="Abrir perfil">
            <i className="caf-ic_arrow_right" />
          </Tooltip>
        )}
      </div>
    </div>
  );
}

QSAPartnerDetails.propTypes = {
  partner: PropTypes.shape({
    profileId: PropTypes.string,
    function: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    cpf: PropTypes.string,
    status: PropTypes.string,
  }),
};

QSAPartnerDetails.defaultProps = {
  partner: PropTypes.shape({}),
};

export default QSAPartnerDetails;
