/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import DossierCard from 'pages/private/Profiles/components/DossierCard';

import PersonalFinance from './components/PersonalFinance';
import JointDebts from './components/JointDebts';
import SocialAssistance from './components/SocialAssistance';
import UnemploymentInsurance from './components/UnemploymentInsurance';

import SPC from '../Shared/SPC';
import PGFN from '../Shared/PGFN';
import Protests from '../Shared/Protests';
import ZoopScore from '../Shared/Zoop/Score';

const PersonalFinancialInformation = ({ data, toPrint, toPrintDetails }) => {
  const { t: translate } = useTranslation();

  const dataSources = useMemo(
    () =>
      data?.profileTemplate?.backgroundChecking?.services?.map(({ source }) => source) ||
      [],
    [data]
  );

  const showPersonalFinance = useMemo(
    () =>
      dataSources.includes('pf_finantial_data') ||
      dataSources.includes('pf_salesbox') ||
      dataSources.includes('pf_spc_income') ||
      data?.finance?.incomeEstimates ||
      data?.finance?.totalAssets,
    [data, dataSources]
  );

  const showSPC = useMemo(() => dataSources.includes('pf_spc') || data?.finance?.spc, [
    data,
    dataSources,
  ]);

  const showProtest = useMemo(
    () => dataSources.includes('pf_protesto') || data?.finance?.protest,
    [data, dataSources]
  );

  const showPGFN = useMemo(
    () => dataSources.includes('pf_pgfn') || data?.certificates?.pgfn,
    [data, dataSources]
  );

  const showJointDebts = useMemo(
    () =>
      dataSources.includes('pf_joint_debts_certificate') ||
      data?.certificates?.jointDebts,
    [data, dataSources]
  );

  const showSocialAssistance = useMemo(
    () => dataSources.includes('pf_social_assistance') || data?.finance?.socialAssistance,
    [data, dataSources]
  );

  const showUnemploymentInsurance = useMemo(
    () =>
      dataSources.includes('pf_seguro_desemprego') || data?.work?.unemploymentInsurance,
    [data, dataSources]
  );

  const showZoopScore = useMemo(
    () => dataSources.includes('pfScoreCheck') || data?.finance?.scoreCheck,
    [data, dataSources]
  );

  return (
    <DossierCard
      customId="financial-infos-component"
      title={translate(
        'pages.private.profiles.components.dataSourceCards.financialInformation.people.index.people.dossierCard.title'
      )}
      subtitle={translate(
        'pages.private.profiles.components.dataSourceCards.financialInformation.people.index.people.dossierCard.subtitle'
      )}
      icon="caf-ic_dollar_sign"
    >
      {showPersonalFinance && (
        <PersonalFinance
          profile={data}
          dataSources={dataSources}
          /* urlId={urlId} */
          /* toPrint={toPrint} */
        />
      )}

      {showSPC && (
        <Row>
          <SPC
            profile={data}
            /* urlId={urlId} */
            toPrint={toPrint}
            toPrintDetails={toPrintDetails}
          />
        </Row>
      )}

      <Row className="finance-infos-group">
        {showProtest && (
          <Protests
            profile={data}
            /* urlId={urlId} */ type={translate(
              'pages.private.profiles.components.dataSourceCards.financialInformation.people.index.people.type'
            )}
            toPrint={toPrint}
          />
        )}
        {showPGFN && (
          <PGFN
            profile={data}
            /* urlId={urlId} */ type={translate(
              'pages.private.profiles.components.dataSourceCards.financialInformation.people.index.people.type'
            )}
            toPrint={toPrint}
          />
        )}
        {showJointDebts && (
          <JointDebts profile={data} /* urlId={urlId} toPrint={toPrint} */ />
        )}
        {showSocialAssistance && (
          <SocialAssistance
            profile={data}
            /* urlId={urlId} */
            toPrint={toPrint}
          />
        )}
        {showUnemploymentInsurance && (
          <UnemploymentInsurance
            profile={data}
            /* urlId={urlId} toPrint={toPrint} */
          />
        )}
        {showZoopScore && <ZoopScore profile={data} />}
      </Row>
    </DossierCard>
  );
};

export default PersonalFinancialInformation;
