import React, { useState } from 'react';
import { Dropdown, Menu, Divider } from 'antd';

import { Download, OpenLink } from '@combateafraude/icons/general';

import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import './styles.less';

export const ExportMenu = ({ options, className }) => {
  return (
    <Menu className={classNames('export-menu', className)} selectable={false}>
      {options.map(({ label, onClick, key }, index) => (
        <>
          <Menu.Item
            key={`export-menu-${label}`}
            onClick={onClick}
            id={`click_export_${key}`}
          >
            <button type="button" className="export-btn reset-btn export-menu-btn">
              <Download />
              <span> {label}</span>
            </button>
          </Menu.Item>
          {index !== options.length - 1 && <Divider className="no-mrg" />}
        </>
      ))}
    </Menu>
  );
};

const ExportOptions = ({ options }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { pathname } = useLocation();

  return (
    <Dropdown
      visible={isVisible}
      onVisibleChange={setIsVisible}
      overlay={
        <ExportMenu
          options={options.map((option) => ({
            label: option.label,
            onClick: () => {
              setIsVisible(false);
              option.onClick();
            },
          }))}
        />
      }
      trigger={['click']}
      placement="bottomRight"
    >
      <button
        id={`click_export_${pathname.substring(1)}`}
        type="button"
        className="export-btn reset-btn"
        onClick={() => setIsVisible(true)}
      >
        <OpenLink />
      </button>
    </Dropdown>
  );
};

const options = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  })
);

ExportOptions.propTypes = {
  options: options.isRequired,
};

ExportMenu.propTypes = {
  options: options.isRequired,
  className: PropTypes.string,
};

ExportMenu.defaultProps = {
  className: '',
};

export default ExportOptions;
