import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import EmptyExecutionState from 'components/EmptyExecutionState';
import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import ViewMore from 'components/ViewMore';
import CollapseTitle from 'components/CollapseHeader/CollapseTitle';
import useScoreDetailsModal from '../ScoreDetailsModal';

import DataSourceCardCollapsePanel from '../../../../DataSourceCardCollapsePanel';
import SpeedometerRisk from '../../../Components/Speedometer/idex';

const ZoopScore = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(
    () => source?.data?.scoreCheck?.data || source?.data || source,
    [source]
  );

  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const { openModal, ScoreDetailsModal } = useScoreDetailsModal();

  const handlerStatus = useMemo(() => {
    if (!parsedData || !queryWorked) return { title: null, type: null };

    if (parsedData?.resultMessage === 'Approved') {
      return {
        title: translate(
          'components.dataSourceCards.financialInformation.shared.zoop.score.index.handlerStatus.approved.title'
        ),
        type: 'primary',
      };
    }

    if (
      parsedData?.resultMessage === 'Reproved' ||
      parsedData?.resultMessage === 'Not Approved'
    ) {
      return {
        title: translate(
          'components.dataSourceCards.financialInformation.shared.zoop.score.index.handlerStatus.reproved.title'
        ),
        type: 'danger',
      };
    }

    return {
      title: translate(
        'components.dataSourceCards.financialInformation.shared.zoop.score.index.handlerStatus.default.title'
      ),
      type: 'empty',
    };
  }, [parsedData, queryWorked, translate]);

  const riskValue = useMemo(() => {
    if (!parsedData || !queryWorked) return 0;

    const riskLabels = {
      LOW: 1.4,
      MID: 2,
      HIGH: 2.7,
    };

    return riskLabels[parsedData?.risk] || 0;
  }, [parsedData, queryWorked]);

  const riskDetails = useMemo(() => {
    const riskDetailsArray = Object?.entries(parsedData?.riskDetails ?? {});

    return riskDetailsArray?.map((riskData) => {
      const value = riskData[1]?.toUpperCase();

      let valueColor = '';

      if (value === 'LOW') {
        valueColor = 'success';
      } else if (value === 'MID') {
        valueColor = 'warning';
      } else if (value === 'HIGH') {
        valueColor = 'danger';
      } else {
        valueColor = 'gray';
      }

      return (
        <Col className="flex">
          <span>
            {riskData[0]}:{' '}
            <span className={`gx-text-${valueColor} text-bold`}>
              {translate(
                `components.dataSourceCards.financialInformation.shared.zoop.score.index.risk.${
                  value ?? 'unknown'
                }`
              )}
            </span>
          </span>
        </Col>
      );
    });
  }, [parsedData, translate]);

  return (
    <>
      {ScoreDetailsModal}
      <DataSourceCardCollapsePanel
        title={translate(
          'components.dataSourceCards.financialInformation.shared.zoop.score.index.score.columnLabel.title'
        )}
        icon="caf-ic_company"
        toPrint={toPrint}
        customId="financialInfo"
      >
        <ColumnLabel description={handlerStatus?.title} type={handlerStatus?.type}>
          {queryWorked ? (
            <>
              {parsedData && parsedData?.limitScore && String(parsedData?.score) && (
                <span>
                  {translate(
                    'components.dataSourceCards.financialInformation.shared.zoop.score.index.score.columnLabel.description'
                  )}
                  : {`${parsedData?.score} / ${parsedData?.limitScore}`}
                </span>
              )}
              {parsedData && parsedData?.risk && (
                <Col className="flex" span={32}>
                  <Col className="flex flex-column no-mrg no-pdd center" span={8}>
                    <SpeedometerRisk value={riskValue} />
                    <span className="center">
                      {translate(
                        'components.dataSourceCards.financialInformation.shared.zoop.score.index.risk'
                      )}
                      {': '}
                      {translate(
                        `components.dataSourceCards.financialInformation.shared.zoop.score.index.risk.${parsedData?.risk}`
                      ) || 'Desconhecido'}
                    </span>
                  </Col>
                  {parsedData?.riskDetails &&
                    Object.keys(parsedData.riskDetails).length > 0 && (
                      <Col className="mrg-left-30 width-100" span={24}>
                        <Col className="no-mrg no-pdd width-100">
                          <CollapseTitle
                            span={8}
                            title={translate(
                              'components.dataSourceCards.financialInformation.shared.zoop.score.index.risk.details'
                            )}
                          />
                          {riskDetails}
                        </Col>
                      </Col>
                    )}
                </Col>
              )}

              {parsedData && Object.keys(parsedData?.scoreDetails || {})?.length > 0 && (
                <Row>
                  <ViewMore
                    type="link"
                    title={translate(
                      'components.dataSourceCards.financialInformation.shared.zoop.score.index.score.columnLabel.viewMore.title'
                    )}
                    onClick={() => openModal(parsedData)}
                  />
                </Row>
              )}
              {parsedData && !String(parsedData?.score) && (
                <span>
                  {translate(
                    'components.dataSourceCards.financialInformation.shared.zoop.score.index.score.columnLabel.emptyDescription'
                  )}
                </span>
              )}
            </>
          ) : (
            <EmptyExecutionState
              statusCode={statusCode}
              message={source?.message || parsedData?.message}
            />
          )}
        </ColumnLabel>
      </DataSourceCardCollapsePanel>
    </>
  );
};

export default ZoopScore;
