/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Divider, Typography } from 'antd';

import { nadaConstaArrestWarrantTranslation } from 'utils/personalTranslations';

import EmptyExecutionState from 'components/EmptyExecutionState';
import DottedLabel from 'components/DottedLabel';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH = 'components.dataSourceCards.criminalInformation.index';

const { Title, Text } = Typography;

const PfArrestWarrant = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const parsedData = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const pfArrestWarrant = useMemo(() => {
    const arrestWarrantStatus = parsedData?.status;

    const translatedStatus = arrestWarrantStatus
      ? nadaConstaArrestWarrantTranslation[arrestWarrantStatus] ||
        nadaConstaArrestWarrantTranslation.NULO
      : nadaConstaArrestWarrantTranslation.NULO;

    return { ...source, translatedStatus };
  }, [parsedData, source]);

  return (
    <DataSourceCardCollapsePanel
      customId="criminal-background-component"
      title={translate(
        `${I18N_BASE_PATH}.criminalInformation.dossierCard.pfArrestWarrant.title`
      )}
      icon="caf-ic_criminal"
      toPrint={toPrint}
    >
      <Row>
        {parsedData && (
          <>
            {!queryWorked ? (
              <EmptyExecutionState
                className="mrg-top-10"
                statusCode={statusCode}
                message={parsedData?.message}
              />
            ) : (
              <>
                {parsedData?.status === 'NADA CONSTA' ? (
                  <>
                    <Col className="col-antd-warrant no-pdd" />
                    <DottedLabel
                      className="mrg-top-25 mrg-left-15"
                      type={pfArrestWarrant?.translatedStatus.type}
                      description={translate(
                        pfArrestWarrant?.translatedStatus?.description
                      )}
                    />
                    <span className="col-antd-warrant">{parsedData?.message}</span>
                  </>
                ) : (
                  <>
                    <Col className="col-antd-warrant">
                      <Text strong>
                        {parsedData?.warrants[0]?.name?.toUpperCase() || '-'}
                      </Text>
                    </Col>
                    <DottedLabel
                      className="mrg-top-25"
                      type={pfArrestWarrant?.translatedStatus.type}
                      description={translate(
                        pfArrestWarrant?.translatedStatus?.description
                      )}
                    />
                    {parsedData?.warrants.map((warrant) => (
                      <Row className="row-antd-warrant">
                        <Divider span={23} className="mrg-left-15" />
                        <Col span={15}>
                          <Title className="title-color" span={12} level={5}>
                            {warrant.piece.toUpperCase() || '-'}
                          </Title>
                        </Col>
                        <Col span={9}>
                          <DottedLabel
                            type={
                              warrant.status === 'Pendente de Cumprimento'
                                ? 'danger'
                                : 'info'
                            }
                            description={warrant.status}
                          />
                        </Col>
                        <Col span={24}>
                          <span>
                            <strong>
                              {translate(
                                `${I18N_BASE_PATH}.criminalInformation.dossierCard.pfArrestWarrant.warrantInfo.name`
                              )}
                            </strong>
                            {warrant.name}
                          </span>
                        </Col>
                        <Col span={24}>
                          <span>
                            <strong>
                              {translate(
                                `${I18N_BASE_PATH}.criminalInformation.dossierCard.pfArrestWarrant.warrantInfo.issuingAuthority`
                              )}
                            </strong>
                            {warrant.issuingAuthority}
                          </span>
                        </Col>
                        <Col span={24}>
                          <span>
                            <strong>
                              {translate(
                                `${I18N_BASE_PATH}.criminalInformation.dossierCard.pfArrestWarrant.warrantInfo.warrantNumber`
                              )}
                            </strong>
                            {warrant.warrantNumber}
                          </span>
                        </Col>
                        <Col span={24}>
                          <span>
                            <strong>
                              {translate(
                                `${I18N_BASE_PATH}.criminalInformation.dossierCard.pfArrestWarrant.warrantInfo.date`
                              )}
                            </strong>
                            {warrant.date}
                          </span>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </>
            )}
            <Divider />
          </>
        )}
      </Row>
    </DataSourceCardCollapsePanel>
  );
};

export default PfArrestWarrant;
