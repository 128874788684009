const uppercaseObject = (obj) => {
  const formattedValues = {};
  Object.keys(obj).forEach((key) => {
    formattedValues[key] = obj[key] ? String(obj[key]).toUpperCase() : undefined;
  });

  return formattedValues;
};

export default uppercaseObject;
