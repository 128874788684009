import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Menu, Dropdown } from 'antd';

import { hasRequiredPermissions } from 'routes/permissionFunctions';
import PermissionWrapper from 'routes/PermissionWrapper';
import { useAuth } from 'hooks/auth';
import useChangePassword from './ChangePassword';

const ActionsList = ({ user }) => {
  const { t: translate } = useTranslation();
  const { openModal: openModalChangePassword, ChangePasswordModal } = useChangePassword({
    user,
  });
  const { user: loggedUser } = useAuth();
  return (
    <>
      {ChangePasswordModal}
      <PermissionWrapper requiredPermissions={['users-pdv:update', 'users-pdv:delete']}>
        <Dropdown
          overlay={
            <Menu className="pdd-vertical-5">
              {hasRequiredPermissions(
                loggedUser,
                ['users-pdv:update'],
                [],
                false,
                'management'
              ) && (
                <Menu.Item key="changePassword" onClick={openModalChangePassword}>
                  <i className="caf-lock mrg-right-10" />
                  <span className="text-semibold">
                    {translate(
                      'pages.private.users.components.usersPDVActions.actionsList.redefinePassword'
                    )}
                  </span>
                </Menu.Item>
              )}
            </Menu>
          }
          trigger={['click']}
          placement="bottomRight"
        >
          <div className="btn-more-icon btn-more-icon-hover">
            <i className="caf-ic_more-vertical" />
          </div>
        </Dropdown>
      </PermissionWrapper>
    </>
  );
};

ActionsList.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  refreshListRef: PropTypes.shape({
    current: PropTypes.func.isRequired,
  }).isRequired,
};

ActionsList.defaultProps = {
  user: {},
};

export default ActionsList;
